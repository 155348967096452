import React,{useEffect} from 'react'
import LoadingIndicator from '../../FormComponent/LoadingIndicator';

function OfficialWeb() {
  useEffect(() => {
    window.location.href = "https://cmpfo.gov.in/";
    
  }, []);



  return (
    <LoadingIndicator/>
  )
}

export default OfficialWeb;
