import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  CircularProgress,
} from "@mui/material";
//import formatDate from "../../FormComponent/DateFormatting";
import CustomTextField from "../../FormComponent/CustomTextArea";
//import { format, parseISO } from "date-fns";
import LabelText from "../../FormComponent/LabelText";
import ValueText from "../../FormComponent/ValueText";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { PDFExport } from "@progress/kendo-react-pdf";
import CmpfoLogo from "../../images/cmpfo.png";
import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SucessAlert from "../../FormComponent/SuccessAlert";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import axios from "axios";
//   import "./pdfStyles.css";
const index = 0;

// const data = {
//   para: "63(1)(a)",
//   fullName: "E Rajanna",
//   returnofcontincase: "One time Settlement",
//   RgUnNew: "1001000091",
//   RgUnOld: "HYD/8",
//   fatherHusbandName: "E Rajamallu",
//   Adress: "GADDAM BIKSHAPATHI 20-2-135 BELLAMPALLI",
//   cssno: 2000011145,
//   arrearfrom: " 01.06.2023 ",
//   arrearto: " 31.07.2023 ",
//   recoveryamount: "00.00",
//   arrearamount: "40,328.00",
//   unitName: "M.D.Office",
//   joiningDate: "25-10-1990",
//   cmpfAccNo: "HYD/170/840",
//   dateOfCessation: "2023-05-31",
//   tothserv: "0 yrs.  0 months.",
//   TPServ: "33 Years",
//   para15: "15(1)(a)",
//   kopension: "ALIVE",
//   Fserv: " 32 yrs.  6 months. ",
//   dateOfAppointment: "1990-10-25",
//   emoluments: "80,654.78",
//   dateOfDeath: "00-00-0000",
//   membershipPeriod: "32 Years 07 Months",
//   ledgerBalance: 2231862.0,
//   lemployed: "STORIES",
//   caseno: "Alive Case",
//   PayNo: "1070012220",
//   monPay: "20,164",
//   dateOfClaim: "",
//   dateOfBirth: "1987-10-12",
//   causeOfCessation: "SuperAnnuation",
//   claimTypDesc: "SuperAnnuation",
//   name: "BaijunathSharma",
//   relationName: "Brother",
//   sanctionedAmt: 0,
//   brokenPeriodInterestRate: 8,
//   brokenPeriodInterestPayable: 247043,
//   brokenPeriodContri: 180804,
//   brokenPeriodContriDet: null,

//   modeOfRemittance: "A/C PAYEE CHEQUE/BANK TRANSFER",
//   acc: "52196074233",
//   ifsc: "SBIN0020909",
//   bankName: "STATE BANK OF INDIA",
//   bankkey: "SBIN0020120",
//   bankAddress: "Branch KALYANKHANI, SRINAGAR, GARHWAL, UTTARAKHAND, 246174",
//   sbaccno: 52103824630,
//   postalAddress: "C/O KK-1, MANDAMARRI, MMR MANCHERIAL, Telangana,INDIA",
//   // feilds to be calculated
//   age: "60",
//   dateUptoWhichInterestIsPayable: "31-07-2023",
//   closingBalance: 2231862.0,
//   dateFrom: "",
//   dateTo: "31.03.2023",
//   rateOfInterest: 8.0,
//   brokenPeriodInterestPayableFrom: "01-04-2023",
//   brokenPeriodInterestPayableTo: "31-07-2023",
//   brokenPeriodContribution: 180804.0,
//   brokenPeriodInterestPayable: 247043.0,
//   totalAmountpayable: 2659709.0,
// };
// const newData = {
//   pp_no: 3601101503,
//   acc_no: "HYD/69/256",
//   name: "Sri/Smt K.Vanketeshwar Rao",
//   so_of: "S/o or W/o RAMULU ",
//   co: "C/O All EXECUTIVES , Corporate , CORPORATE AREA  BHADRADRIKOTHAGUDEM",
//   app_no: "CRP/PER/ATB/CMPF/809",
//   under_after: "CMPS 1998",
//   stp_date: "01/10/2023", //date of commencement
//   exit_date: "30/09/2023", //date of Cessation
//   pri_name: "K.VANKETESHWAR RAO", //fullname
//   pp_month: 73986,
//   arr_pension: 73986,
//   recovery: 0,
//   net_arr: 73986,
//   dob: "10/09/1963",
//   aadhar: 277550007881,
//   pan: "ACCPK0365M",
//   mobile: 949144240,
//   bank: "STATE BANK OF INDIA",
//   bank_acc_no: 52200595639,
//   ifsc: "SBIN0020708",
//   branch: "GODAVARIKHANI dsncjsbdb jsdbv",
//   roname: "",
//   rocode: "",
//   kindOfPension: "Widow/Widower Pension (After the Death of the Member)",
//   causeOfCessation: "",
//   para: "",
//   familyMemberName: "",
//   familyMemberMobileNO: "",
//   familyMemberEmailId: "",
//   familyMemberRelationName: "",
//   familyMemberAadhaarNO: "",
//   familyMemberPanNO: "",
//   modeofRemmitance: "",
//   familyMemberDOB: "",

//   //agr backend se aa rhe ho to map kr dege field bna kr

//   regionalOfficeCode: null,
//   regionalOfficeName: null,
//   regionalOfficeEmailId: null,
// };

//for the formatting of the date

// const formatDate = (date) => {
//   return format(parseISO(date), "dd/MM/yyyy");
//   // Replace 'MM/dd/yyyy' with the desired pattern
// };

const Ppo = () => {
  const { state } = useLocation();
  const newData = state;

  const [track, setTrack] = useState([]);
  const claimId = newData.claimId;
  const fetchtrack = async () => {
    try {
      const url =
        process.env.REACT_APP_SERVER_URL +
        "/get/officer/hirearchy/for/dpo/po/pension";
      const formData = new FormData();
      formData.append("claimId", claimId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const trackdata = response.data.data;

      setTrack(trackdata); // Assuming setAssignToOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };
  console.log("trackdata", track);

  useEffect(() => {
    fetchtrack();
  }, []);
  const desiredSequence = [
    "UDA",
    "UAO",
    "AAO",
    "IDS",
    "AGM",
    "DA",
    "SI",
    "BO",
    "RH",
  ];
  const filteredAndSortedTrack = desiredSequence
    .filter((role) => track.some((item) => item.roleName === role))
    .map((role) => track.find((item) => item.roleName === role));

  console.log("data", newData);

  const pdfExportComponent = useRef(null);
  const downloadPDF = () => {
    const capture = document.querySelector(".actual-receipt");
    html2canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL("img/png");
      const doc = new jsPDF("p", "pt", "a4");
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(
        imgData,
        "PNG",
        10,
        1,
        componentWidth - 20,
        componentHeight - 20
      );
      doc.save("receipt.pdf");
    });
  };

  return (
    <div>
      <div className="actual-receipt">
        <Box mt={8}>
          {/* Dikhane ke baad ek try or alag alag 2 container bna kr 1 grid me  */}

          <Grid container item lg={12} xs={6} md={12} sm={6} margin={"0.5%"}>
            <Grid
              container
              spacing={2}
              // justifyContent="center"
              // style={{ margin: "6px" }}
              id="printableArea">
              <Grid
                item
                xs={1}
                justifyContent="flex-end"
                container
                direction="column">
                {/* <Typography variant="body1">Grievance ID</Typography> */}
                <img
                  src={CmpfoLogo}
                  alt="Regional Commissioner Office"
                  style={{ width: "100px", height: "100px" }}
                />
                <Typography
                  variant="h8"
                  gutterBottom
                  style={{ textAlign: "left" }}>
                  <span
                    style={{
                      fontFamily: "monospace",
                    }}>
                    {newData.processId}
                  </span>
                </Typography>
              </Grid>

              <Grid
                item
                xs={11}
                container
                justifyContent={"start"}
                direction="column">
                {/* <Typography variant="body1">{printableData.grievanceId}</Typography> */}
                <Typography variant="h6" gutterBottom>
                  <span style={{ textDecoration: "underline" }}>
                    OFFICE OF THE REGIONAL COMMISSIONER <br />
                  </span>
                </Typography>

                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    textDecoration: "underline",
                    fontFamily: "monospace",
                    fontWeight: "bold",
                  }}>
                  {`COAL MINES PROVIDENT FUND,${newData.roname} `}
                </Typography>

                {/* <Typography variant="body2" paragraph>
                  CMPF,Burma Camp , KOTHAGUDEM(Telangana) - 507 101
                  <br />
                </Typography> */}

                {/* <Typography variant="body2" paragraph>
                  Tel.No : 08744-247096 - 242794 - 09441173622, Fax.No :
                  08744-247827
                </Typography> */}

                <Typography variant="body2" paragraph>
                  {newData.roemail}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              marginBottom={"4%"}
              alignContent={"center"}
              marginLeft={"35%"}>
              <Typography variant="h8" gutterBottom>
                <span
                  style={{
                    fontFamily: "monospace",
                    fontWeight: "bold",
                  }}>
                  PPO NO : {newData.pp_no}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CMPF A/C No. : {newData.acc_no}
                </span>
              </Typography>
            </Grid>

            <Grid item xs={12} marginTop={"15%"} style={{ margin: "6px" }}>
              <Grid container spacing={2}>
                <Grid item xs={6} lg={6}>
                  {/* Content for the left part */}
                  {/* yha pr div ke place pr grid use kr */}
                  {/* <div style={{ display: "flex" }}> */}
                  <Grid item xs={6} lg={8} alignContent={"flex-start"}>
                    {/* <div style={{ flex: "0 0 50%" }}> */}
                    {/* 45Space in TO */}
                    {/* <p>
                                 <div>To,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br/></div>
                                {newData.name}<br />
                                S/o or W/o  &nbsp;{newData.so_of}<br />
                                {newData.co}
                            </p> */}
                    <ValueText title={"To,"} />
                    <div style={{ marginLeft: "10%" }}>
                      <ValueText title={newData.name} />
                      <ValueText title={newData.so_of} />
                      <ValueText title={newData.co} />
                    </div>
                    {/* </div> */}
                  </Grid>
                  {/* </div> */}
                </Grid>
                <Grid item xs={6} lg={4} style={{ border: "2px solid black" }}>
                  <Grid item xs={3} lg={3} marginLeft={"30%"} marginTop={"9%"}>
                    <p> Joint Photograph </p>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item xs={12} lg={12}>
                <Grid item xs={10} lg={12}>
                  <Grid lg={2} marginTop={"1%"}>
                    <ValueText title="Sir/Madam ," />
                  </Grid>
                  <Grid lg={10} alignContent={"flex-start"}>
                    <p style={{ textAlign: "justify" }}>
                      With refrence to your application <b>{newData.app_no}</b>{" "}
                      for payment of the above benefit under{" "}
                      {newData.under_after}, we are glad to inform that your
                      claim has been setteled for payment vide PPO No.{" "}
                      <b>{newData.pp_no}</b>
                    </p>
                  </Grid>
                </Grid>
                {/* <Grid item xs={2} lg={2}></Grid> */}
              </Grid>

              <Grid
                container
                item
                xs={12}
                lg={12}
                style={{ margin: "6px" }}
                justifyContent={"center"}>
                <Grid>
                  <p style={{ textAlign: "center", fontWeight: "bold" }}>
                    {" "}
                    Monthly Pension will start from{" "}
                    <u>
                      {" "}
                      &nbsp; &nbsp; &nbsp; &nbsp; {newData.stp_date} &nbsp;
                      &nbsp; &nbsp; &nbsp;{" "}
                    </u>{" "}
                    as per details below
                  </p>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                lg={12}
                style={{ margin: "6px" }}
                justifyContent={"center"}>
                <Grid lg={4} textAlign={"left"}>
                  <p>Member Pension</p>
                </Grid>
                <Grid lg={4}>
                  <p> Exit Date from Service - {newData.exit_date} </p>
                </Grid>
                <Grid lg={4}>
                  <p> Validity - Till Death </p>
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="space-between"
                lg={12}
                xs={12}
                md={12}
                sm={12}>
                {/* <Grid item>
                    <Typography variant="body2" className="labelForPdf">
                      Details of Family member as on the date of cessation
                    </Typography>
                  </Grid> */}
                {/* Table yha aavegi */}
                <TableContainer>
                  <Table style={{ borderCollapse: "collapse" }}>
                    <TableHead>
                      <TableRow>
                        {/* Headers */}
                        {[
                          "Name of Pensioner",
                          "Pension/Per Month",
                          "Arrear of Pension/ROC",
                          " Recovery (if any) ",
                          "Net Arrears Payable/ROC",
                        ].map((header, index) => (
                          <TableCell
                            key={index}
                            style={{ border: "1px dashed black", padding: 8 }}>
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {/* Row 1 */}
                      <TableRow>
                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.pri_name}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.pp_month}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.arr_pension}
                        </TableCell>

                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.recovery}
                        </TableCell>

                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.net_arr}
                        </TableCell>
                      </TableRow>

                      {/* Row 3 (Merged) */}
                    </TableBody>

                    {/* 2nd Table yha s start hovegi */}
{/* table for spouse */}
                    <TableHead>
                      <TableRow>
                        {/* Headers */}
                        {[
                          "Date of Birth",
                          "Aadhar Number",
                          "Pan No.",
                          " Mobile No ",
                          "Email ID",
                        ].map((header, index) => (
                          <TableCell
                            key={index}
                            style={{ border: "1px dashed black", padding: 8 }}>
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Row 1 */}
                      <TableRow>
                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.dob}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.aadhar}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.pan}
                        </TableCell>

                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.mobile}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {""}
                        </TableCell>
                      </TableRow>
                    </TableBody>

                    {/* 3rd Table start from here  */}
                    <TableHead>
                      <TableRow>
                        {/* Headers */}
                        {[
                          "Bank Name",
                          "Account Number",
                          "IFSC Code",
                          ,
                          "",
                        ].map((header, index) => (
                          <TableCell
                            key={index}
                            style={{ border: "1px dashed black", padding: 8 }}>
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Row 1 */}
                      <TableRow>
                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.bank}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.bank_acc_no}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.ifsc}
                        </TableCell>

                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {/* {newData.branch} */}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {""}
                        </TableCell>
                      </TableRow>
                    </TableBody>

                    {/* Last Heading */}
                    <TableHead
                      style={{
                        border: "1px dashed black",
                        padding: 8,
                        textAlign: "center",
                        fontSize: "8",
                      }}>
                      {/* GODAVARIKHANI,TEH.PEDDPALLI,505209,KARIMNAGAR(ANDHRA
                      PRADESH) */}
                      {newData.branch}
                    </TableHead>
                  </Table>
                </TableContainer>
              </Grid>

              {/* 2nd  Maal Yha Hai */}

              {/* Nayi 2nd Table yha se suru hogi */}
              <Grid
                container
                justifyContent="space-between"
                lg={12}
                md={12}
                xs={12}
                sm={12}>
                {/* <Grid item>
                    <Typography variant="body2" className="labelForPdf">
                      Details of Family member as on the date of cessation
                    </Typography>
                  </Grid> */}
                {/* Table yha aavegi */}
                <Grid
                  container
                  item
                  xs={12}
                  lg={12}
                  justifyContent={"center"}
                  marginTop={"2%"}>
                  <Grid>
                    <p style={{ textAlign: "center", fontWeight: "bold" }}>
                      {" "}
                      {newData.kindOfPension}{" "}
                    </p>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12} justifyContent={"center"}>
                  <Grid>
                    <p style={{ textAlign: "center", fontWeight: "bold" }}>
                      {" "}
                      Validity - Till Death or re-marriage which ever is earlier{" "}
                    </p>
                  </Grid>
                </Grid>
                <TableContainer>
                  <Table style={{ borderCollapse: "collapse" }}>
                    <TableHead>
                      <TableRow>
                        {/* Headers */}
                        {[
                          "Name of Spouse",
                          "Pension/Per Month",
                          "Arrears + O/s Benefit",
                          " Recovery (if any) ",
                          "Net Arrears Payable",
                        ].map((header, index) => (
                          <TableCell
                            key={index}
                            style={{ border: "1px dashed black", padding: 8 }}>
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {/* Row 1 */}
                      <TableRow>
                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.familyMemberName}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.spouseMonthlyPension}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.arr_pension_spouse}
                        </TableCell>

                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.recovery}
                        </TableCell>

                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.net_arr_spouse}
                        </TableCell>
                      </TableRow>

                      {/* Row 3 (Merged) */}
                    </TableBody>

                    {/* 2nd Table yha s start hovegi */}

                    <TableHead>
                      <TableRow>
                        {/* Headers */}
                        {[
                          "Date of Birth",
                          "Aadhar Number",
                          "Pan No.",
                          " Mobile No ",
                          "Email ID",
                        ].map((header, index) => (
                          <TableCell
                            key={index}
                            style={{ border: "1px dashed black", padding: 8 }}>
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Row 1 */}
                      <TableRow>
                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.familyMemberDOB}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.familyMemberAadhaarNO}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.familyMemberPanNO}
                        </TableCell>

                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.familyMemberMobileNO}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {""}
                        </TableCell>
                      </TableRow>
                    </TableBody>

                    {/* 3rd Table start from here  */}
                    <TableHead>
                      <TableRow>
                        {/* Headers */}
                        {[
                          "Bank Name",
                          "Account Number",
                          "IFSC Code",
                          // " Branch Name ",
                          "",
                        ].map((header, index) => (
                          <TableCell
                            key={index}
                            style={{ border: "1px dashed black", padding: 8 }}>
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Row 1 */}
                      <TableRow>
                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.bank}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.bank_acc_no}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {newData.ifsc}
                        </TableCell>

                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {/* {newData.branch} */}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {""}
                        </TableCell>
                      </TableRow>
                    </TableBody>

                    {/* Last Heading */}
                    <TableHead
                      style={{
                        border: "1px dashed black",
                        padding: 8,
                        textAlign: "center",
                      }}>
                      {/* GODAVARIKHANI,TEH.PEDDPALLI,505209,KARIMNAGAR(ANDHRA
                      PRADESH) */}
                      {newData.branch}
                    </TableHead>
                  </Table>
                </TableContainer>
              </Grid>

              {/* <List dense sx={{ listStyle: "lower-alpha", pl: 4 }}> */}

              <List>
                <ListItem sx={{ display: "list-item" }}>
                  <Grid container display="flex" justifyContent="space-between">
                    <Grid item lg={12}>
                      <Typography variant="body1" className="labelForPdf">
                        You are requested to furnish annual life cerificate and
                        non-re-marriage certificate(in case of widow or widower
                        pension) or non-marriage certificate (in case of
                        daughter) through JEEVAN PRAMAAN PORTAL or any branch of
                        STATE BANK OF INDIA or NODAL BANK STATE BANK OF INDIA ,
                        Main Br, Dhanbad or through email on
                        cmpf.pension@sbi.co.in. In all future references to the
                        regional CMPF Office please mention your PPO No.
                        {newData.pp_no} and CMPF A/C No. {newData.acc_no}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              </List>

              {/* Enclosures Code Here */}

              <Grid container justifyContent="space-between" marginTop={"2%"}>
                <Grid item>
                  <Typography variant="body1" className="labelForPdf">
                    Enclosures :
                  </Typography>
                </Grid>
                {/* Subcategory of  Pensionable Services : */}
                <Grid
                  container
                  rowSpacing={1}
                  // columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                  style={{ paddingLeft: 1 }}>
                  <Grid item xs={12} lg={12}>
                    <List dense sx={{ listStyle: "lower-roman", pl: 4 }}>
                      <ListItem sx={{ display: "list-item" }}>
                        <Grid
                          container
                          display="flex"
                          justifyContent="space-between">
                          <Grid item lg={6} xs={6}>
                            <Typography variant="body1" className="labelForPdf">
                              Specimen Signature of Pensioner
                            </Typography>
                          </Grid>
                          <Grid item lg={6} xs={6}>
                            <Typography
                              variant="body1"
                              fontWeight="500"
                              className="valueForPdf"
                              justifyContent={"left"}>
                              {" "}
                              {/* yha pr blank chod do aise hi */}
                            </Typography>
                          </Grid>
                        </Grid>
                      </ListItem>

                      <ListItem sx={{ display: "list-item" }}>
                        <Grid
                          container
                          display="flex"
                          justifyContent="space-between">
                          <Grid item lg={6} xs={6}>
                            <Typography variant="body1" className="labelForPdf">
                              Undertaking by the Pensioner
                            </Typography>
                          </Grid>
                          <Grid item lg={6} xs={6}>
                            <Typography
                              variant="body1"
                              fontWeight="500"
                              className="valueForPdf"
                              justifyContent={"left"}>
                              {" "}
                              {/* yha pr blank chod do aise hi */}
                            </Typography>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container justifyContent="space-between" marginTop={"2%"}>
                <Grid item>
                  <Typography variant="body1" className="labelForPdf">
                    Copy to :
                  </Typography>
                </Grid>
                {/* Subcategory of  Pensionable Services : */}
                <Grid
                  container
                  rowSpacing={1}
                  // columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                  style={{ paddingLeft: 1 }}>
                  <Grid item xs={12} lg={12}>
                    <List dense sx={{ listStyle: "decimal", pl: 4 }}>
                      <ListItem sx={{ display: "list-item" }}>
                        <Grid
                          container
                          display="flex"
                          justifyContent="space-between">
                          <Grid item lg={6} xs={6}>
                            <Typography variant="body1" className="labelForPdf">
                              State Bank of India , Main Branch , Dhanbad ,
                              Jharkhand
                            </Typography>
                          </Grid>
                          <Grid item lg={6} xs={6}>
                            <Typography
                              variant="body1"
                              fontWeight="500"
                              className="valueForPdf"
                              justifyContent={"left"}>
                              {" "}
                              {/* yha pr blank chod do aise hi */}
                            </Typography>
                          </Grid>
                        </Grid>
                      </ListItem>
                      <Grid container item lg={12} xs={12} textAlign="center">
                        <Typography
                          component="div"
                          style={{ display: "flex", alignItems: "center" }}>
                          {filteredAndSortedTrack.map((item, index) => (
                            <React.Fragment key={index}>
                              <Typography
                                variant="body1"
                                component="span"
                                fontFamily="monospace">
                                <b>{`${item.roleName}:`}</b>
                                {` ${item.name}`}
                              </Typography>
                              {index !== filteredAndSortedTrack.length - 1 && (
                                <Typography variant="body1" component="span">
                                  &rarr;
                                </Typography>
                              )}
                            </React.Fragment>
                          ))}
                        </Typography>
                      </Grid>

                      {/* <ListItem sx={{ display: "list-item" }}>
                        <Grid
                          container
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Grid item lg={6} xs={6}>
                            <Typography variant="body1" className="labelForPdf">
                              The Manager , ALL EXECUTIVES , CORPORATE AREA ,
                              CORPORATE , BHADRADRIKOTHAGUDEM
                            </Typography>
                          </Grid>
                          <Grid item lg={6} xs={6}>
                            <Typography
                              variant="body1"
                              fontWeight="500"
                              className="valueForPdf"
                              justifyContent={"left"}
                            >
                              {" "}
                              {/* yha pr blank chod do aise hi */}
                      {/* </Typography>
                          </Grid>
                        </Grid>
                      </ListItem>  */}
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>

      {/* <div marginBottom={"3%"} color="#ff0000">
        <Button
          title="Update"
          variant="contained"
          color="primary"
          onClick={downloadPDF}
        >
          Generate PPO
        </Button>
      </div> */}
    </div>
  );
};
const GeneratePpo = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const { state } = useLocation();
  const data = state;
  console.log("daaata", state);
  const role = data.fromLoginId.substring(0, 2);
  //console.log("roloooooooooe", role);
  let status = false;
  let url = "";

  let linksAndRoutes = {
    submitUrl: "",
    cancelRoute: "",
    successRoute: "",
    status: false,
  };

  const forBO = {
    submitUrl: "/verification/pf/claim/officers/bo",
    cancelRoute: "/bo/pfset/viewpfclaimbo",
    successRoute: "/bo/pfset",
    status: false,
  };

  const forRH = {
    submitUrl: "/pension/claims/assign/by/rh/ipds/doc/upload",
    cancelRoute: "/rh/penset/viewpfclaimrh",
    successRoute: "/rh/penset",
    status: true,
  };

  if (role === "BO") {
    linksAndRoutes = { ...forBO };
  } else if (role === "RH") {
    linksAndRoutes = { ...forRH };
  }
  let poNo = "PO" + data.claimId;
  console.log("poNoooo", poNo);
  console.log("roleeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", role);
  console.log("linkssssandroutessssss", linksAndRoutes);

  const [base64, setBase64] = useState();
  const AdvPORefElement = useRef(null);

  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(linksAndRoutes.cancelRoute, {
      state: { passingId: data.claimId },
    });
  };
  const exportPDF = () => {
    html2canvas(AdvPORefElement.current).then((canvas) => {
      const imageData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imageData, "PNG", 15, 15, 180, 180);
      // pdf.save("AdvPO.pdf");

      // Convert the PDF to base64
      const base64PDF = pdf.output("datauristring");
      let base64PDFf = base64PDF.replace(
        /^data:.+;base64,/,
        `data:application/pdf;base64,JVBERi0xLjQKJdPr6eEKMSAwIG9iago8PC9`
      );

      // Now you can use the base64PDF data as needed
      console.log("base64PDF", base64PDFf);
      setBase64(base64PDFf);
      // navigate("/roda/pfset/viewpfclaimroda", {
      //   state: { passingId: data.claimId, pdf: base64PDFf },
      // });
    });
  };

  // const objectTobeSentToCh = {
  //   claimId: data.claimId,
  //   fromLoginId: data.fromLoginId,
  //   toLoginId: data.toLoginId,
  //   remarks: data.remarks,
  //   poDate: "",
  //   payOrderNo: poNo,
  //   payOrderDocument: base64,
  //   status: false,
  // };
  const handleSuccess = () => {
    // setIsSuccess(false);
    navigate(linksAndRoutes.successRoute);
  };

  const handleErrorAlert = () => {
    setIsError(false);
    navigate(linksAndRoutes.cancelRoute, {
      state: { passingId: data.claimId },
    });
  };

  const handleApprove = async () => {
    //  await exportPDF(); // Ensure that exportPDF function is fully executed before proceeding
    setIsSubmitting(true);
    try {
      const compressionQuality = 0.2; // adjust the quality level
      const canvas = await html2canvas(AdvPORefElement.current, {
        scale: 2.2,
        allowTaint: true,
      });
      const imageData = canvas.toDataURL("image/jpeg", compressionQuality);
      const pdf = new jsPDF("p", "mm", "a4");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imageData, "JPEG", 15, 15, pageWidth - 30, pageHeight - 30);
      //pdf.addImage(imageData, "JPEG", 15, 15, 180, 180);
      // ... (rest of the PDF generation code)
       pdf.save("po.pdf");
      const base64PDF = pdf.output("datauristring");
      let base64PDFf = base64PDF.replace(
        /^data:.+;base64,/,
        `data:application/pdf;base64,JVBERi0xLjQKJdPr6eEKMSAwIG9iago8PC9`
      );
      // const dateformat = data.currentDate.split("T");
      // console.log("dateeeee", dateformat[0]);

      const objectTobeSentToCh = {
        claimId: data.claimId,
        fromLoginId: data.fromLoginId,
        toLoginId: data.toLoginId,
        remarks: data.remarks,
        // ppoDate: dateformat[0],
        payOrderNo: poNo,
        payOrderDocument: base64PDFf,
      };
      console.log("objectTobeSentToCh", objectTobeSentToCh);

      const url = process.env.REACT_APP_SERVER_URL + linksAndRoutes.submitUrl;
      const headers = {
        "Content-Type": "application/json",
      };

      const response = await axios.post(url, objectTobeSentToCh, { headers });
      console.log("response at submit", response);

      setIsSubmitting(false);
      setIsSuccess(true);
      setSuccessMessage("Claim Forwarded Successfully.");
    } catch (error) {
      setIsError(true);
      setIsSubmitting(false);
      setErrorMessage("Sorry, could not proceed. Please try again later.");
      console.error("Error during submission:", error);
    }
  };

  /// error alert handler

  return (
    <div>
      <SucessAlert
        handleOk={handleSuccess}
        open={isSuccess}
        title="Success"
        body={successMessage}
        buttonName="OK"
      />
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error!"
        body={errorMessage}
        buttonName="Ok"
      />
      <div style={{ justifyContent: "flex-end" }}>
        <div ref={AdvPORefElement}>
          <Ppo />
        </div>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid
            item
            // marginTop={3}
            // marginBottom={3}
            // marginRight="20px"
            // style={{ display: "flex", justifyContent: "center" }}
          >
            <Button onClick={handleCancel} variant="contained" color="primary">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleApprove}
              variant="contained"
              color="success"
              disabled={isSubmitting}>
              {isSubmitting ? <CircularProgress /> : "Approve"}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default GeneratePpo;
