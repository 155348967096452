import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Grid,
  Paper,
  Box,
  Button,
  Divider,
  CircularProgress,
} from "@mui/material";
import GrievanceTable from "./GrievanceTable";
import { memberModel } from "../../Models/defaultModel";
import SucessAlert from "../../FormComponent/SuccessAlert";
import ValueText from "../../FormComponent/ValueText";
import TitleText from "../../FormComponent/TitleText";
import LabelText from "../../FormComponent/LabelText";
import CustomTextField from "../../FormComponent/CustomTextField";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import GroupedRadioButton from "../../FormComponent/GroupedRadioButton";
import SubtitleText from "../../FormComponent/SubtitleText";
import CustomTextArea from "../../FormComponent/CustomTextArea";
import LoadingIndicator from "../../FormComponent/LoadingIndicator";
import FormDivider from "../../FormComponent/FormDivider";
import { faClosedCaptioning } from "@fortawesome/free-solid-svg-icons";
import { Link, Typography } from "@mui/material";
import CustomConfirmationDialogue from "../../FormComponent/CustomConfirmationDialogue";
import TextareaAutosize from "@mui/material/TextareaAutosize"; //autosize text area
import axios from "axios";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import { getToken } from "../../UiManager/SessionChecker";
import jwtDecode from "jwt-decode";

function GrievanceEditMember() {
  const handleGoBack = () => {
    navigate(-1); //navigate back to the previous page
    window.scrollTo(0, 0); // scroll to the top of the page
  };

  const defaultData = {
    grievanceId: "",
    grievanceCategDesc: "",
    grievanceSubCatDesc: "",
    grievanceDesc: "",
    upldedDoc: "",
    docTypeDesc: "",
    submitDate: "",
    grievanceStatusDesc: "",
  };

  const [confirmation, setConfirmation] = useState(false);
  const handleDocumentDescriptionChange = (e) => {
    // setDocumentDescription(event.target.value); // Update the Document Description state on change
    setGrievanceDescription({ ...grievanceDesc, docTypeDesc: e.target.value });
  }; // i am not using this code right now

  // const [editableGrievanceDescription, setEditableGrievanceDescription] = useState(grievanceDescription);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [grievanceDetails, setGrievanceDetails] = useState({ defaultData }); // set the response data in this state
  const [editableGrievanceDescription, setEditableGrievanceDescription] =
    useState(grievanceDetails.grievanceDesc);
  const [isLoading, setIsLoading] = useState(false);
  const [submittedOn, setSubmittedOn] = useState(""); //submitted on
  const [grievanceType, setGrievanceType] = useState(""); //grievance type
  const [subtype, setSubtype] = useState(""); //subtype
  const [documentUrl, setDocumentUrl] = useState("");
  const [grievanceDescription, setGrievanceDescription] = useState(""); //grievanceDescription
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [raiseGrievance, setRaiseGrievance] = useState("");
  const [isSubmitSuccess, setIsSubmitSuccess] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  //raise Grievance Description config  start from here
  const grievanceDesc = {
    name: "grievanceDesc",
    type: "text",
    helperText: "Maximum 500 words",
    placeholder: "Grievance Description",
    defaultValue: grievanceDetails.grievanceDesc,
    errorMessage:
      "First Name must be characters only and not allowed special characters except white space.",
    // label: "First Name",
    pattern: "",
    required: false,
    multiline: true,
    maxLength: "500",
    //disabled: readOnly,
  };
  //raise Grievance Description config  ends here

  //raise Document Description start from here
  const docTypeDesc = {
    name: "docTypeDesc",
    type: "text",
    placeholder: "Document Description",
    defaultValue: grievanceDetails.docTypeDesc,
    errorMessage:
      "First Name must be characters only and not allowed special characters except white space.",
    // label: "First Name",
    pattern: "",
    required: false,
    // disabled: readOnly,
    multiline: true,
    maxLength: "80",
    helperText: "Maximum 80 words",
  };
  //raise Document Description ends here

  //  note this line  start from here
  const { state } = useLocation();
  const grievanceId = state.passedData.grievanceId;
  // const grievanceId = "";
  // note this line ends here

  // fetchapi start from here
  const fetchGrievanceDetails = async () => {
    try {
      // console.log("grience Id", grievanceId);
      // console.log("state Id one ", state);
      const formData = new FormData();
      formData.append("grievanceId", grievanceId);
      const url =
        process.env.REACT_APP_SERVER_URL + "/showAllGrievanceToMember";
      // console.log("url", url);
      const response = await axios.post(url, formData);
      // console.log("response", response.data.data);
      const data = response.data.data;
      setGrievanceDetails(data[0]); // Set the fetched data to the tablePreview state // we are taking data[0] as the response recevied is an array of object and we need only the first element
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching table data: ", error);
    }
  };
  useEffect(() => {
    fetchGrievanceDetails();
    // setIsProgramLoaded(false);
  }, []);

  //from here i am going to add all the conversion on the upload document
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    // console.log("this is new", selectedFile);
    const allowedTypes = ["application/pdf", "image/jpg"];
    if (
      !selectedFile ||
      !allowedTypes.some((type) => selectedFile.type.includes(type))
    ) {
      // console.error('Please select a PDF file.');
      // <p> plz select required format </p>
      // alert('Please select a PDF file.');
      event.target.value = null;
      setIsError(true);
      setErrorMessage("Please select a PDF file.");
      return;
    }

    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
    if (selectedFile && selectedFile.size > maxSizeInBytes) {
      // console.error('File size exceeds the maximum limit of 2 MB.');
      // alert('Please  select a PDF file.');
      event.target.value = null;
      setIsError(true);
      setErrorMessage("File size exceeds the maximum limit of 2 MB.");
      return;
    }
    setGrievanceDetails({
      ...grievanceDetails,
      file: selectedFile,
    });
  };
  const handleUpload = () => {
    setConfirmation(true);
    setConfirmation(false);
    setIsDisabled(true);
    setIsDisabled(false);
    // console.log("handle upload is working");
    // console.log("uploadeddoc", grievanceDetails);
    const file = grievanceDetails.file ? grievanceDetails.file : "";
    // console.log("file", file);
    // if (!grievanceDetails.grievanceCategDesc) {
    //   // Display an error message or handle the validation as per your UI/UX requirements
    //   setIsErrorAlert(true);
    //   // console.error("Please select a grievance category before submitting.");
    //   return;
    // }

    //   if(!isGrievanceSubCategorySelected)
    //  {
    //   setIsErrorAlertsub(true);
    //   console.error("Please select a grievance subcategory before submitting.");
    //   return;
    //  }
    // if (!grievanceDetails.grievanceSubCatDesc) {
    //   setIsErrorAlertsub(true);
    //   console.error("Please select a grievance subcategory before submitting.");
    //   return;
    // }
    // if (!file)
    // {
    //   setIsError(true);
    //   setErrorMessage(" Please select a file ");
    //   return;
    // }
    // if(!file)
    // {
    //   console.log("slect file")
    // }
    if (file) {
      let base64Data = "";
      // console.log("File Conversion Working");
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        base64Data = reader.result;
        let mimeType;
        if (file.type === "application/pdf") {
          mimeType = "application/pdf";
        } else if (file.type === "image/jpeg") {
          mimeType = "image/jpeg";
        } else if (file.type === "image/png") {
          mimeType = "image/png";
        } else {
          // Handle other file types if necessary
        }
        // base64Data = base64Data.replace(/^data:.+;base64,/, data:${mimeType};base64,JVBERi0xLjQKJdPr6eEKMSAwIG9iago8PC9);
        base64Data = base64Data.replace(
          /^data:.+;base64,/,
          `data:${mimeType};base64,JVBERi0xLjQKJdPr6eEKMSAwIG9iago8PC9`
        );
        // console.log("base64Data inside the file", base64Data);
        // console.log("base64Data is getting converted", base64Data);
        handleSubmit(base64Data);
      };
      //console.log("base64Data is getting converted", base64Data);
      // handleSubmit(base64Data);
    } else {
      // console.log("in else");
      // let documentUrl = atob(grievanceDetails.upldedDoc);
      handleSubmit("");
    }

    // setIsLoading(true);
    //  };
    // reader.onerror = (error) => {
    //   console.error("Error converting file to base64", error);
    // };
  };

  const handleSubmit = async (base64Data) => {
    setIsLoading(true);
    try {
      const url = process.env.REACT_APP_SERVER_URL + "/member/edit/grievences";

      const grievanceSubCategId = grievanceDetails.grievanceSubCatDesc;

      let token = getToken();
      // console.log("token on aao", token);
      if (!token) {
        setIsLoading(false);
        //setIsFailed(true);
        return;
      }
      token = token.replace('"', "").replace('"', "");
      const cmpfAccNo = jwtDecode(token).user;
      // console.log("loginId on aao", cmpfAccNo);

      const objectToBeSent = {
        grievanceId: grievanceId,
        grievanceDesc: grievanceDetails.grievanceDesc
          ? grievanceDetails.grievanceDesc
          : "",
        //upldedDoc: raiseGrievance.upldedDoc,
        upldedDoc: base64Data,
        docTypeDesc: grievanceDetails.docTypeDesc
          ? grievanceDetails.docTypeDesc
          : "",
      };
      // console.log("objectToBeSent", objectToBeSent);
      const response = await axios.post(url, objectToBeSent);
      // console.log("response Status", response.status);
      if (response.status === 200) {
        // console.log("status wala response", response);
        setIsSubmitSuccess(true);
        setIsLoading(false);
      } else {
      }
    } catch (error) {
      console.error("Error in submitting grievance: ", error);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage(error.response.data.error_message);
      // console.log(" error by vidhuri ", error.response.data.error_message);
      // setIsSubmitSuccess(true);
      // setIsErrorAlert(true);
      // Handle error
    }
  };

  const handleSubmitSucess = () => {
    setIsSubmitSuccess(false);
    setIsDisabled(false);
    navigate("/member/grievances");
  };

  // fetch api ends here
  const navigate = useNavigate();

  const [readOnly, setReadOnly] = useState(false);

  const [isProgramLoaded, setIsProgramLoaded] = useState(true);

  const onChange = (e) => {
    // console.log("onchange", e.target.value);
    setGrievanceDetails({
      ...grievanceDetails,
      [e.target.name]: e.target.value,
    });
  };
  const handleUpdate = () => {
    setIsUpdated(false);
    navigate("/member/grievances");
  };

  const [loading, setLoading] = useState(false);
  const [documentContent, setDocumentContent] = useState(null);
  const [documentType, setDocumentType] = useState(null);
  const handleViewDocument = async () => {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/showAllGrievanceToMember?grievanceId=${grievanceId}`;
      const response = await axios.post(url);
      const base64Data = response.data.data[0].upldedDoc;
      // console.log(base64Data);
      let type = "pdf"; // Change const to let for type
      let utf8Data = atob(base64Data);
      // console.log(utf8Data);
      if (utf8Data.includes("data:image/jpeg;base64")) {
        type = "jpg";
      } else if (utf8Data.includes("data:image/png;base64")) {
        type = "png";
      }
      let base;
      if (type === "pdf") {
        base = utf8Data.replace(
          "data:application/pdf;base64,JVBERi0xLjQKJdPr6eEKMSAwIG9iago8PC9",
          "data:application/pdf;base64,"
        );
      } else {
        base = utf8Data.replace(
          `data:image/${type};base64,JVBERi0xLjQKJdPr6eEKMSAwIG9iago8PC9`,
          `data:image/${type};base64,`
        );
      }

      // Open the document in a new window
      const newWindow = window.open();
      newWindow.document.write(
        `<html><head><title>Document</title></head><body><${
          type === "pdf" ? "iframe" : "img"
        } src="${base}" width="100%" height="100%" /></body></html>`
      );
    } catch (error) {
      console.error("Error fetching document:", error);
    } finally {
      setLoading(false);
    }
  };
  // Function to render the document based on its type
  const renderDocument = () => {
    switch (documentType) {
      case "pdf":
        return (
          <iframe
            title="PDF Viewer"
            src={documentContent}
            width="50%"
            height="400"
          />
        );
      case "jpg":
      case "png":
        return (
          <img
            src={`data:image/${documentType};base64,${btoa(documentContent)}`}
            alt="Document"
          />
        );
      default:
        return <p>Unsupported document type</p>;
    }
  };
  const handleErrorAlert = () => {
    setIsError(false);
    setIsLoading(false);
    setIsDisabled(false);
  };

  const handleDialogueCancel = () => {
    // setIsDisabled(true);
    setConfirmation(false);
  };
  const handleConfirmation = () => {
    setConfirmation(true);
  };
  return (
    <>
      <SucessAlert
        handleOk={handleUpdate}
        open={isUpdated}
        title="Success"
        body="Grievance Updated Successfully"
        buttonName="Ok"
      />

      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Alert!"
        body={errorMessage}
        buttonName="Ok"
      />

      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            {!isProgramLoaded ? (
              <LoadingIndicator />
            ) : (
              <>
                <CustomConfirmationDialogue
                  handleOk={handleUpload}
                  handleCancel={handleDialogueCancel}
                  title="Confirmation"
                  body="Are you sure you want to update?"
                  open={confirmation}
                  isDisabled={isDisabled}
                  buttonName="Yes"
                  buttonName2="No"
                />
                <SucessAlert
                  handleOk={handleSubmitSucess}
                  open={isSubmitSuccess}
                  title="Success"
                  body="Grievance Updated Successfully"
                  buttonName="Ok"
                />
                <ErrorAlert
                  handleOk={handleErrorAlert}
                  open={isError}
                  title="Alert!"
                  body={errorMessage}
                  buttonName="Ok"
                />
                {/* grievance back button start form here  */}
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      align="left"
                      onClick={handleGoBack}
                      //sx={{ backgroundColor: "darkviolet" }}
                      style={{ marginTop: "-30px" }}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
                {/* grievance back button ends here  */}

                <Grid item xs={12}>
                  <TitleText title="Edit Grievance" />
                </Grid>

                <FormDivider />

                {/* <Form> */}
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="start"
                  rowGap={2}
                >
                  {/* section 1  */}

                  {/* column 1  */}

                  <Grid item xs={12} sm={5}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                      columnGap={1}
                    >
                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title="Grievance ID" />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}
                      >
                        :
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ValueText title={grievanceDetails.grievanceId} />{" "}
                        {/* Displaying the fetched grievance Id */}
                      </Grid>
                      {/* secound row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title="Grievance Category" />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}
                      >
                        :
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <ValueText
                          title={grievanceDetails.grievanceCategDesc}
                        />
                      </Grid>

                      {/* row  */}

                      <Grid item xs={12} sm={4}>
                        <LabelText title="Document Description" />
                      </Grid>

                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}
                      >
                        :
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        {/* overflowY="auto" */}
                        {/* <ValueText title={grievanceType} /> */}
                        <ValueText title={grievanceDetails.docTypeDesc} />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* column 2  */}

                  <Grid item xs={12} sm={5}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                      columnGap={1}
                    >
                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title=" Submitted On" />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}
                      >
                        :
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <ValueText title={grievanceDetails.submitDate} />
                      </Grid>

                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title="Grievance SubCategory" />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}
                      >
                        :
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ValueText
                          title={grievanceDetails.grievanceSubCatDesc}
                        />
                      </Grid>

                      {/* row */}

                      {grievanceDetails.upldedDoc && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            style={{ marginBottom: "2%" }}
                          >
                            <LabelText title="Document Uploaded" />
                          </Grid>
                          <Grid
                            item
                            sm={1}
                            sx={{ display: { xs: "none", sm: "initial" } }}
                            style={{ marginBottom: "2%" }}
                          >
                            :
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{ textAlignLast: "left" }}
                          >
                            <Button
                              //variant="contained"
                              onClick={handleViewDocument}
                              disabled={loading}
                            >
                              View Document
                            </Button>
                            {loading && <CircularProgress />}{" "}
                            {/* Show loading spinner while fetching the document */}
                            {documentContent && renderDocument()}
                          </Grid>
                        </>
                      )}

                      <Grid container>
                        {/* Your existing content goes here */}
                        <Grid item xs={12}>
                          {/* Content */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* ************** */}
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="start"
                    rowGap={3}
                  >
                    {/* column */}
                    <Grid item xs={12} sm={3}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* rows */}
                        <Grid item xs={12} sm={7} style={{ marginTop: "5%" }}>
                          <LabelText title="Grievance Description" />
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          sx={{
                            display: { xs: "none", sm: "flex" },
                            justifyContent: "flex-start",
                            marginLeft: "-13.5%",
                          }}
                          style={{ marginTop: "5%" }}
                        >
                          :
                        </Grid>
                        <Grid item sm={1}></Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        columnGap={1}
                      ></Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                          maxHeight: "150px",
                          overflowY: "auto",
                          marginTop: "15px",
                          marginLeft: { sm: "-18%" },
                        }}
                      >
                        <CustomTextArea
                          {...grievanceDesc}
                          onchange={onChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* grievance description */}

                  <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      Upload Document
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                      columnGap={1}
                    >
                      {/* row */}

                      <Grid
                        item
                        xs={12}
                        sm={4}
                        lg={4}
                        style={{ marginBottom: "4%" }}
                      >
                        <LabelText title="Document Description" />
                      </Grid>
                      <Grid
                        style={{ marginTop: "-1%", marginLeft: "1%" }}
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}
                      >
                        :
                      </Grid>
                      <Grid item xs={12} sm={7} lg={6}>
                        {/* <CustomTextField
                        name="docTypeDesc"
                        type="text"
                        helperText=""
                        placeholder="Document Description"
                       // initialValue={documentDescription.docTypeDesc} // Controlled by state
                       defaultValue={grievanceDetails.docTypeDesc} // Controlled by state

                        onChange={handleDocumentDescriptionChange} // Handle change and update state
                      /> */}
                        <CustomTextArea {...docTypeDesc} onchange={onChange} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                      columnGap={1}
                    >
                      {/* row */}
                      <Grid item xs={12} sm={4} style={{ paddingTop: "30px" }}>
                        <LabelText title="Select Document" />
                      </Grid>
                      <Grid item xs={12} sm={7} style={{ marginTop: "2.5%" }}>
                        <input
                          type="file"
                          name="file"
                          onChange={handleFileChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      title="Update"
                      variant="contained"
                      color="primary"
                      onClick={handleConfirmation}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <CircularProgress color="secondary" />
                      ) : (
                        <>
                          &nbsp;Update
                          {/* Render the icon when isLoading is false */}
                        </>
                      )}
                      {/* Update */}
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default GrievanceEditMember;
