import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Paper, Button } from "@mui/material";
import { memberModel, memberData } from "../../../Models/defaultModel";
import UploadDocument from "../../../SettlementComponents/UploadDocument";
import TitleText from "../../../FormComponent/TitleText";
import LabelText from "../../../FormComponent/LabelText";
import CustomTextField from "../../../FormComponent/CustomTextField";
import CustomDropdown from "../../../FormComponent/CustomDropdown";
import CustomDropdownWithValue from "../../../FormComponent/CustomDropdownWithValue";
import GroupedRadioButton from "../../../FormComponent/GroupedRadioButton";
import SubtitleText from "../../../FormComponent/SubtitleText";
import CustomTextArea from "../../../FormComponent/CustomTextArea";
import LoadingIndicator from "../../../FormComponent/LoadingIndicator";
import FormDivider from "../../../FormComponent/FormDivider";
import axios from "axios";
import PreviewPage from "./PreviewPageMember";
import { formPatternValidation } from "../../../Utility/UtilityMethods";
import ErrorAlert from "../../../FormComponent/ErrorAlert";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import "../../../App.css";
import ValueText from "../../../FormComponent/ValueText";

function UpdateMemberDetails() {
  const navigate = useNavigate();

  const defaultData = memberData;
  const defaultMemData = {
    unitCode: "",
    titleName: "",
    religionName: "",
  };

  const defaultcc = {
    unitName: "",
    coalCompName: "",
  };
  const [ccData, setCcData] = useState(defaultcc);
  const [member, setMember] = useState(defaultData);
  const [memberDet, setMemberDet] = useState(defaultMemData);

  const [editable, setEditable1] = useState(true);
  const [editable2, setEditable2] = useState(true);
  const [editable3, setEditable3] = useState(true);
  const [editablePf, setEditablePf] = useState(true);
  const [showPreview, setShowPreview] = useState(false);
  const [isProgramLoaded, setIsProgramLoaded] = useState(true);
  const [message, setMessage] = useState();
  const [error, setErrorAlert] = useState();
  const [validationFailed, setValidationFailed] = useState(false);
  const [validationFailedMsg, setValidationFailedMsg] = useState("");
  //for document upload
  // const [documentTypes, setDocumentTypes] = useState([]);
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // for state autocomplete
  const [stateLoading, setStateLoading] = useState(false);
  const [stateList, setStateList] = useState([]);
  //for dropdown of title
  const [titleList, setTitleList] = useState([]);
  //for religion list
  const [religionList, setReligionList] = useState([]);
  //for unit list
  const [unitList, setUnitList] = useState([]);

  const handleOk = () => {
    // Perform any additional logic here if needed
    // For now, let's navigate back to the previous page
    navigate(-1);
  };

  const handleBackPreview = () => {
    setShowPreview(false);
  };

  const documentTypes = [
    { id: 1, title: "Aadhaar Card" },
    { id: 2, title: "Pan Card" },
    { id: 3, title: "Voter Id" },
    { id: 4, title: "Passport" },
    { id: 5, title: "Bank Statement" },
  ];

  //Handle save for documemt upload
  const handleSave = async (documentData) => {
    const requestBody = {
      cmpfAcNo: cmpfAccNo,
      upldDocsMemDto1: documentData.map((upload) => ({
        upldDocProof: upload.uploadedDocument,
        docTypeId: upload.selectedDocumentType,
        remarks: "",
      })),
    };
    setUploadedDocs(requestBody.upldDocsMemDto1);
  };

  //Here we are taking cmpfoAccNo from SearchMember.jsx and send it in the (from data) of UpdateMemberDetails.jsx to get the details of member and its families.
  const location = useLocation();
  const { pathname } = location;

  const {
    state: { passedData, passingId },
  } = location;
  // depending upon what has arrived passed data or passingId the familymemberId to be passed on the api request is to be changed
  const cmpfAccNum = passedData ? passedData.cmpfAccNo : passingId;
  //console.log("cmpfAccNum", cmpfAccNum);

  // api request to  updateMemberDetails
  const defaultValue = {
    firstID: "",
    stateName: "",
    value: "",
    // firstID: "A",
    // stateName: "ANDHRA PRADESH",
    // value: 1,
  };

  const defaultValueUnit = {
    firstID: "",
    stateName: "",
    value: "",
    // firstID: "A",
    // stateName: "ANDHRA PRADESH",
    // value: 1,
  };
  const [selectedState, setSelectedState] = useState(defaultValue);
  const [selectedStatePf, setSelectedStatePf] = useState(defaultValue);
  const [selectedStatePen, setSelectedStatePen] = useState(defaultValue);
  const [selectedUnitCode, setSelectedUnitCode] = useState(defaultValue);
  const updateMemberDetails = async () => {
    //setIsLoading(true);
    //session related uncomment for session
    // if (!(await sessionExist())) {
    //   console.log("session extending having trouble");
    //   setIsFailed(true);
    //   setIsLoading(false);
    //   return;
    // }

    // let token = localStorage.getItem("jwt");

    // if (!token) {
    //   setIsFailed(true);
    //   setIsLoading(false);
    //   return;
    // }

    // token = token.replace('"', "").replace('"', "");

    const url =
      process.env.REACT_APP_SERVER_URL + "/search/editablefields/forUDA";
    const formData = new FormData();
    // formData.append("searchFieldValue", jwtDecode(token).user);
    formData.append("searchFieldValue", cmpfAccNum);
    const config = {
      headers: {
        //  Authorization: Bearer ${token},
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        //setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          setMember(data[0]);
          setSelectedState({
            firstID: data[0].state.charAt(0),
            stateName: data[0].state,
            value: getStateId(data[0].state, stateList),
          });
          setSelectedStatePf({
            firstID: data[0].statePf.charAt(0),
            stateName: data[0].statePf,
            value: getStateId(data[0].statePf, stateList),
          });
          setSelectedStatePen({
            firstID: data[0].statePension.charAt(0),
            stateName: data[0].statePension,
            value: getStateId(data[0].statePension, stateList),
          });
          setSelectedUnitCode({
            firstID: data[0].unitCode.charAt(0),
            unitCode: data[0].unitCode,
            value: data[0].unitId,
          });
          return;
        }
        // setIsFailed(true);
      })
      .catch(function (error) {
        // setIsLoading(false);
        //setIsFailed(true);
        console.log("error", error);
      });
  };
  const fetchStates = async () => {
    try {
      // console.log("in fetch states");
      const statesListSamples = await getStates();
      // console.log("statesListSamples", statesListSamples);

      const options = statesListSamples.map((option) => {
        //const firstID = option.match(/[0-9]/) ? "0-9" : option.charAt(0); // Get the first letter
        // console.log("option", option);
        const firstID = option.stateName;
        //console.log("firstId", firstID);

        return {
          firstID: /[0-9]/.test(firstID.charAt(0)) ? "0-9" : firstID.charAt(0),
          stateName: option.stateName.toUpperCase(),
          value: option.stateId,
        };
      });

      setStateLoading(false);
      setStateList(options);
      //console.log("unit options", options);
    } catch (error) {
      // Handle error if needed.
    }
  };
  const getStates = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + "/get/all/states";
      const response = await axios.get(url);
      //console.log("states", response);
      return response.data;
    } catch (error) {
      console.log("error - states", error);
      return [];
    }
  };

  useEffect(() => {
    fetchStates();
    fetchTitleList();
    fetchReligionList();
    // fetchCCHeads();
    // fetchCCDesig();
  }, []);

  useEffect(() => {
    updateMemberDetails();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) {
      //alert("Please Fill out all the required fields.");
      setMessage("Kindly fill all fields!!");
      setMessage("Please fill all the required fields.");
      setErrorAlert(true);
      //console.log("Validation failed");
      return;
    } else {
      console.log("member object", member);
      console.log("memberdet object", memberDet);
      setShowPreview(true);
    }
    //console.log("jaii");
  };
  //Configuraton of Personal Information
  const titleName = {
    name: "titleName",
    placeholder:"Title",
    nameId: "titleId",
    // label: "salutation",
    helperText: "",
    disabled: editable,
    initialValue: member.titleId,
    options: titleList,
  };

  const religionName = {
    name: "religionName",
    nameId: "religionId",
    placeholder: "Religion",
    helperText: "",
    disabled: editable,
    initialValue: member.religionId,
    options: religionList,
  };

  const unitCode = {
    name: "unitCode",
    nameId: "unitId",
    placeholder: "Colliery Code",
    helperText: "",
    disabled: editable2,
    initialValue: member.unitId,
    options: unitList,
  };

  const gender = {
    options: [
      { value: "M", label: "Male" },
      { value: "F", label: "Female" },
      { value: "O", label: "Other" },
    ],
    name: "gender",
    initialValue: member.gender,
    disabled: editable,
  };

  const maritalStatus = {
    name: "maritalStatus",
    placeholder: "Marital Status",
    helperText: "",
    disabled: editable,
    initialValue: member.maritalStatus,
    options: [
      { id: "M", title: "Married" },
      { id: "U", title: "Unmarried" },
      { id: "W", title: "Widow/Widower" },
      { id: "D", title: "Divorced" },
    ],
  };

  const firstName = {
    name: "firstName",
    type: "text",
    helperText: "",
    placeholder: "First Name",
    defaultValue: member.firstName,
    errorMessage:
      "First Name must be characters only and not allowed special characters except white space.",
    // label: "First Name",
    pattern: "^[a-zA-Z ]{1,100}$",
    required: false,
    disabled: editable,
  };

  const middleName = {
    name: "middleName",
    type: "text",
    helperText: "",
    defaultValue: member.middleName,
    placeholder: "Middle Name",
    errorMessage:
      "Middle Name must be characters only and not allowed special characters except white space.",
    // label: "Middle Name",
    pattern: "^[a-zA-Z ]{0,64}$",
    required: false,
    disabled: editable,
  };

  const lastName = {
    name: "lastName",
    type: "text",
    helperText: "",
    placeholder: "Last Name",
    defaultValue: member.lastName,
    errorMessage:
      "Last Name must be characters only and not allowed special characters except white space.",
    // label: "Last Name",
    pattern: "^[a-zA-Z ]{0,64}$",
    required: false,
    disabled: editable,
  };

  const dateOfBirth = {
    name: "dateOfBirth",
    type: "date",
    helperText: "",
    defaultValue: member.dateOfBirth,
    errorMessage: "Date Of Birth",
    // label: "Date Of Birth",
    pattern: "",
    required: false,
    disabled: editable,
  };

  const panNo = {
    name: "panNo",
    type: "text",
    placeholder: "Pan",
    helperText: "",
    defaultValue: member.panNo,
    errorMessage: "Pan should be 12 digis without spaces",
    // label: "Pan ID",
    pattern: "^[A-Z]{5}[0-9]{4}[A-Z]{1}$",
    required: false,
    disabled: editable,
  };

  const aadhaarNumber = {
    name: "aadhaarNumber",
    type: "text",
    placeholder: "Aadhaar No",
    helperText: "",
    defaultValue: member.aadhaarNumber,
    errorMessage: "Aadhaar should be 12 digits without spaces",
    // label: "Aadhar ID",
    pattern: "^[1-9][0-9]{11}$",
    required: false,
    disabled: editable,
  };

  const fatherHusbandName = {
    name: "fatherHusbandName",
    type: "text",
    placeholder: "Father/Hus Name",
    helperText: "",
    defaultValue: member.fatherHusbandName,
    errorMessage:
      "Father/Husband name must be characters only and not allowed special characters except white space.",
    // label: "pension_percentage",
    pattern: "^[a-zA-Z. ]{1,100}$",
    required: false,
    disabled: editable,
  };

  const email = {
    name: "email",
    type: "text",
    placeholder: "Email ID",
    helperText: "",
    defaultValue: member.email,
    errorMessage: "Invalid email should be example@mail.com",

    pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$",
    required: false,
    disabled: editable,
  };

  const mobileNo = {
    name: "mobileNo",
    type: "text",
    placeholder: "Mobile Number",
    helperText: "",
    defaultValue: member.mobileNo,
    errorMessage: "Mobile number should be 10 digits long.",
    // label: "mobile_no",
    pattern: "^(?!s*$)[6-9][0-9]{9}$",
    required: true,
    disabled: editable,
  };

  const landNo = {
    name: "landNo",
    type: "text",
    placeholder: "LandLine Number",
    helperText: "",
    // defaultValue: member.landNo ? member.landNo : "0",
    defaultValue: member.landNo,
    // errorMessage: "Number should be 12 digits long",
    // label: "mobile_no",
    pattern: "^d{12}$|^s*$",
    required: false,
    disabled: editable,
  };
  const addressL1 = {
    name: "addressL1",
    placeholder: "Address Line1",
    helperText: "",
    defaultValue: member.addressL1,
    errorMessage:
      "Invalid characters in the address. Please use only letters, digits, dot, commas, dash, and spaces.",
    // label: "pension_percentage",
    pattern: "^[a-zA-Z0-9., -]+$",
    required: false,
    disabled: editable,
  };
  const addressL2 = {
    name: "addressL2",
    type: "text",
    helperText: "",
    placeholder: "Address Line2",
    defaultValue: member.addressL2,
    errorMessage:
      "Invalid characters in the address. Please use only letters, digits, dot, commas, dash, and spaces.",
    pattern: "^[a-zA-Z0-9., -]+$",
    // transformInput: trimStringUtility,
    required: false,
    disabled: editable,
  };
  const city = {
    name: "city",
    type: "text",
    helperText: "",
    placeholder: "City",
    defaultValue: member.city,
    errorMessage: "Invalid Input",
    pattern: "^[a-zA-Z ]{1,64}$",
    required: false,
    disabled: editable,
  };
  const pincode = {
    name: "pincode",
    type: "number",
    helperText: "",
    placeholder: "Pincode",
    defaultValue: member.pincode,
    errorMessage: "Invalid Pincode.",
    pattern: "^[0-9]{6}$",
    required: false,
    disabled: editable,
  };
  const country = {
    name: "country",
    type: "text",
    helperText: "",
    placeholder: "Country",
    defaultValue: member.country,
    errorMessage: "Invalid Input",
    pattern: "^[A-Za-zs]{1,50}$",
    required: false,
    disabled: editable,
  };
  const village = {
    name: "village",
    type: "text",
    helperText: "",
    placeholder: "Village",
    defaultValue: member.village,
    errorMessage: "Invalid Input",
    pattern: "^[a-zA-Z0-9., -]+$",
    required: false,
    disabled: editable,
  };
  const district = {
    name: "district",
    type: "text",
    helperText: "",
    placeholder: "District",
    defaultValue: member.district,
    errorMessage: "Invalid Input",
    pattern: "^[a-zA-Z0-9., -]+$",
    required: false,
    disabled: editable,
  };

  //configuration of Employment Details
  const cmpfAccNo = {
    name: "cmpfAccNo",
    type: "text",
    placeholder: "CMPF Account Number",
    helperText: "",
    defaultValue: member.cmpfAccNo,
    errorMessage: "Invalid Account number",
    // label: "Pan ID",
    pattern: "^[A-Z0-9/]{1,20}$",
    required: false,
    disabled: editable2,
  };
  const employeeId = {
    name: "employeeId",
    type: "text",
    placeholder: "Employee Id",
    helperText: "",
    defaultValue: member.employeeId,
    errorMessage: "Invalid Employee Id",
    // label: "Pan ID",
    pattern: "^[a-zA-Z0-9., ()/_-]+$",
    required: false,
    disabled: editable2,
  };

  const pfEndDate = {
    name: "pfEndDate",
    type: "date",
    helperText: "",
    defaultValue: member.pfEndDate,
    errorMessage: "Enter End Date of PF",
    // label: "Date Of Birth",
    pattern: "",
    required: false,
    disabled: editable2,
  };
  const pfStartDate = {
    name: "pfStartDate",
    type: "date",
    helperText: "",
    defaultValue: member.pfStartDate,
    errorMessage: "Enter Start Date of PF",
    // label: "Date Of Birth",
    pattern: "",
    required: false,
    disabled: editable2,
  };

  const joiningDate = {
    name: "joiningDate",
    type: "date",
    helperText: "",
    defaultValue: member.joiningDate,
    errorMessage: "Invalid Joining Date",
    // label: "Date Of Birth",
    pattern: "",
    required: false,
    disabled: editable2,
  };

  //configuration of Account Details of PF
  const addressL1Pf = {
    name: "addressL1Pf",
    type: "text",
    placeholder: "Address Line1",
    helperText: "",
    defaultValue: member.addressL1Pf,
    errorMessage:
      "Invalid characters in the address. Please use only letters, digits, dot, commas, dash, and spaces.",
    // label: "pension_percentage",
    pattern: "^[a-zA-Z0-9., -]+$",
    required: false,
    disabled: editablePf,
  };

  const addressL2Pf = {
    name: "addressL2Pf",
    type: "text",
    helperText: "",
    placeholder: "Address Line2",
    defaultValue: member.addressL2Pf,
    errorMessage:
      "Invalid characters in the address. Please use only letters, digits, dot, commas, dash, and spaces.",
    pattern: "^[a-zA-Z0-9., -]+$",
    //transformInput: trimStringUtility,
    required: false,
    disabled: editablePf,
  };
  const districtPf = {
    name: "districtPf",
    type: "text",
    helperText: "",
    placeholder: "District",
    defaultValue: member.districtPf,
    errorMessage: "Invalid Input",
    pattern: "^[a-zA-Z0-9., -]+$",
    required: false,
    disabled: editablePf,
  };
  const cityPf = {
    name: "cityPf",
    type: "text",
    helperText: "",
    placeholder: "City",
    defaultValue: member.cityPf,
    errorMessage: "Invalid Input",
    pattern: "^[a-zA-Z0-9., -]+$",
    required: false,
    disabled: editablePf,
  };
  const bankNamePf = {
    name: "bankNamePf",
    type: "text",
    helperText: "",
    placeholder: "Bank Name",
    defaultValue: member.bankNamePf,
    errorMessage: "Invalid Input",
    pattern: "^[a-zA-Z ]{1,64}$",
    required: false,
    disabled: editablePf,
  };
  const pfBankIfsc = {
    name: "pfBankIfsc",
    type: "text",
    helperText: "",
    defaultValue: member.pfBankIfsc,
    errorMessage: "Invalid IFSC",
    // label: "Date Of Birth",
    pattern: "^[A-Z]{4}0[A-Z0-9]{6}$",
    required: false,
    disabled: editablePf,
  };
  const bankAccNoPf = {
    name: "bankAccNoPf",
    type: "text",
    placeholder: "Bank Account Number",
    helperText: "",
    defaultValue: member.bankAccNoPf,
    errorMessage: "Bank account No. not valid",
    // label: "Pan ID",
    pattern: "^[0-9]{9,18}$",
    required: false,
    disabled: editablePf,
  };

  const pincodePf = {
    name: "pincodePf",
    type: "text",
    helperText: "",
    placeholder: "Pincode",
    defaultValue: member.pincodePf,
    errorMessage: "Invalid Pincode.",
    pattern: "^[0-9]{6}$",
    required: false,
    disabled: editablePf,
  };
  //configuration of Account Details of Pension
  const penBankIfsc = {
    name: "penBankIfsc",
    type: "text",
    helperText: "",
    defaultValue: member.penBankIfsc,
    errorMessage: "Invalid IFSC should be 11 digit long",
    // label: "Date Of Birth",
    pattern: "^[A-Z]{4}0[A-Z0-9]{6}$",
    required: false,
    disabled: editable3,
  };
  const bankAccNoPen = {
    name: "bankAccNoPen",
    type: "text",
    placeholder: "Bank Account Number",
    helperText: "",
    defaultValue: member.bankAccNoPen,
    errorMessage: "Bank account No. not valid.",
    // label: "Pan ID",
    pattern: "^[0-9]{9,18}$",
    required: false,
    disabled: editable3,
  };
  const addressL1Pension = {
    name: "addressL1Pension",
    placeholder: "Address Line1",
    helperText: "",
    defaultValue: member.addressL1Pension,
    errorMessage:
      "Invalid characters in the address. Please use only letters, digits, dot, commas, dash, and spaces.",

    pattern: "^[a-zA-Z0-9., -]+$",

    required: false,
    disabled: editable3,
  };

  const addressL2Pension = {
    name: "addressL2Pension",
    type: "text",
    helperText: "",
    placeholder: "Address Line2",
    defaultValue: member.addressL2Pension,
    errorMessage:
      "Invalid characters in the address. Please use only letters, digits, dot, commas, dash, and spaces.",
    pattern: "^[a-zA-Z0-9., -]+$",
    // transformInput: trimStringUtility,
    required: false,
    disabled: editable3,
  };
  const districtPension = {
    name: "districtPension",
    type: "text",
    helperText: "",
    placeholder: "District",
    defaultValue: member.districtPension,
    errorMessage: "Invalid Input",
    pattern: "^[a-zA-Z0-9., -]+$",
    required: false,
    disabled: editable3,
  };
  const cityPension = {
    name: "cityPension",
    type: "text",
    helperText: "",
    placeholder: "City",
    defaultValue: member.cityPension,
    errorMessage: "Invalid Input",
    pattern: "^[a-zA-Z0-9., -]+$",
    required: false,
    disabled: editable3,
  };

  const pincodePension = {
    name: "pincodePension",
    type: "text",
    helperText: "",
    placeholder: "Pincode",
    defaultValue: member.pincodePension,
    errorMessage: "Invalid Pincode.",
    pattern: "^[0-9]{6}$",
    required: false,
    disabled: editable3,
  };
  const bankNamePen = {
    name: "bankNamePen",
    type: "text",
    helperText: "",
    placeholder: "Bank Name",
    defaultValue: member.bankNamePen,
    errorMessage: "Invalid Input",
    pattern: "^[a-zA-Z ]{1,64}$",
    required: false,
    disabled: editable3,
  };
  const onChange = (e) => {
    setMember({ ...member, [e.target.name]: e.target.value });
  };

  const onHandleChange = (e) => {
    const { name, value, id, nameId } = e;
    //console.log("jay", e);
    setMember((prevState) => ({ ...prevState, [nameId]: id }));
    setMemberDet((prevState) => ({ ...prevState, [name]: value }));
    // if (nameId === "unitId") {
    //   fetchccname(value);
    // }
  };
  // console.log("jayy", memberDet);
  const handleEdit1 = () => {
    setEditable1(false);
  };
  const handleEdit2 = () => {
    setEditable2(false);
  };
  const handleEdit3 = () => {
    setEditable3(false);
  };
  const handleEditPf = () => {
    setEditablePf(false);
  };

  const handleGoBack = () => {
    navigate(-1); //navigate back to the previous page

    //window.scrollTo(0, 0); // scroll to the top of the page
  };

  const handleValidationFailedAlert = () => {
    setValidationFailed(false);
    setIsLoading(false);
  };
  const handleStateSearch = (newValue, fieldName) => {
    //console.log("state", newValue);
    //console.log("event on state change", fieldName);
    // setMember({ ...member, state: newValue.value });
    setMember({ ...member, [fieldName]: newValue.stateName });
  };

  const handleUnitSearch = (newValue, fieldName) => {
    console.log("handle unit search is");
    console.log("Unit HERE", newValue);
    console.log("event on Unit change", fieldName);

    setMember({
      ...member,
      unitId: newValue.value,
      unitCode: newValue.unitCode,
    });
    //setMember({ ...member, [fieldName]: newValue.UnitName });
  };

  //console.log("cmpfAccNo", cmpfAccNo);

  const validate = () => {
    // validation of required field of member
    const requiredFields = [
      {
        field: "Title",
        value: memberDet.titleName ? memberDet.titleName : member.titleName,
      },
      { field: "TitleId", value: member.titleId },
      { field: "First Name ", value: member.firstName },
      { field: "Aadhaar", value: member.aadhaarNumber },
      { field: "PAN", value: member.panNo },
      { field: "Address Line1", value: member.addressL1 },
      { field: "State", value: member.state },
      { field: "Pincode", value: member.pincode },
      { field: "Country", value: member.country },
      { field: "Date Of Birth", value: member.dateOfBirth },
      { field: "Father/Husband Name ", value: member.fatherHusbandName },
      { field: "Marital Status ", value: member.maritalStatus },
      {
        field: "Religion",
        value: memberDet.religionName
          ? memberDet.religionName
          : member.religionName,
      },
      { field: "Mobile Number ", value: member.mobileNo },
      // { field: "Email ID", value: member.email },
      { field: "Gender", value: member.gender },
      //for Employment Details
      { field: "CMPF Account Number ", value: member.cmpfAccNo },
      { field: "Employee ID ", value: member.employeeId },

      {
        field: "Colliery Code",
        value: memberDet.unitCode ? memberDet.unitCode : member.unitCode,
      },
      // { field: "Colliery Code ", value: member.unitCode },
      // { field: "Colliery Name ", value: ccData.unitName },
      // { field: "Coal Company Name ", value: ccData.coalCompName },
      { field: "Joining Date ", value: member.joiningDate },
      { field: "PF Start Date ", value: member.pfStartDate },
      { field: "PF End Date ", value: member.pfEndDate },

      //for PF
      { field: "Name of Bank of PF", value: member.bankNamePf },
      { field: "IFSC PF of PF", value: member.pfBankIfsc },
      { field: "Account No of PF", value: member.bankAccNoPf },
      { field: "Address Line1 of PF", value: member.addressL1Pf },
      { field: "City of PF", value: member.cityPf },
      { field: "District of PF", value: member.districtPf },
      { field: "Pincode of PF", value: member.pincodePf },
      { field: "State of PF", value: member.statePf },
      // for Pension
      { field: "Name of Bank of Pension", value: member.bankNamePen },
      { field: "IFSC PF of Pension", value: member.penBankIfsc },
      { field: "Account No of Pension", value: member.bankAccNoPen },
      {
        field: "Address Line1 for bank pension of Pension",
        value: member.addressL1Pension,
      },
      { field: "City of Pension", value: member.cityPension },
      { field: "District of Pension", value: member.districtPension },
      { field: "Pincode of Pension", value: member.pincodePension },
      { field: "State of Pension", value: member.statePension },
    ];
    for (const fieldData of requiredFields) {
      const { field, value } = fieldData;
      if (!value) {
        // alert("kindly fill all the required field");
        setValidationFailed(true);
        setValidationFailedMsg(`${field} is required.`);

        return false; // Return false if any required field is empty
      }
    }
    // Validation of Personal Information
    if (!formPatternValidation(member.firstName, "^[a-zA-Z ]{1,64}$")) {
      // console.log("In validate off  nameOfField", member.firstName);
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries. Please Check First Name field.`
      );
      return false;
    }
    if (!formPatternValidation(member.middleName, "^[a-zA-Z ]{0,64}$")) {
      // console.log("In validate off  middleName", member.middleName);
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries. Please Check Middle Name field.`
      );
      return false;
    }
    if (!formPatternValidation(member.lastName, "^[a-zA-Z ]{0,64}$")) {
      //console.log("In validate off  lastName");
      setValidationFailed(true);
      setValidationFailedMsg(`Invalid  Entries. Please Check lastName field.`);
      return false;
    }
    // if (
    //   !formPatternValidation(
    //     member.dateOfBirth,
    //     "^(19|20)d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$"
    //   )
    // ) {
    //   console.log("dateOfBirth", member.dateOfBirth);
    //   console.log("In validate off  dateOfBirth");
    //   return false;
    // }
    if (!formPatternValidation(member.panNo, "^[A-Z]{5}[0-9]{4}[A-Z]{1}$")) {
      //console.log("In validate off  panNo");
      setValidationFailed(true);
      setValidationFailedMsg(`Invalid  Entries. Please Check Pan field.`);
      return false;
    }
    if (!formPatternValidation(member.aadhaarNumber, "^[1-9][0-9]{11}$")) {
      //console.log("In validate off  aadhaarNumber");
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries. Please Check Aadhaar Number field.`
      );
      return false;
    }
    if (
      !formPatternValidation(member.fatherHusbandName, "^[a-zA-Z. ]{1,100}$")
    ) {
      // console.log("In validate off  fatherHusbandName");
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries. Please Check Name of Father/Husband fields.`
      );
      return false;
    }
    // if (
    //   !formPatternValidation(
    //     member.email,
    //     "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
    //   )
    // ) {
    //   // console.log("In validate off  email");
    //   // setValidationFailed(true);
    //   // setValidationFailedMsg(`Invalid  Entries. Please Check Email fields.`);
    //   return false;
    // }
    //  if (!formPatternValidation(family.nameOfField, "regax pattern")) {
    //    console.log("In validate off  nameOfField");
    //    return false;
    //  }
    if (!formPatternValidation(member.mobileNo, "^(?!s*$)[6-9][0-9]{9}$")) {
      // console.log("In validate off  mobileNo");
      setValidationFailed(true);
      setValidationFailedMsg(`Invalid  Entries. Please Mobile No. field.`);
      return false;
    }
    // if (!formPatternValidation(member.landNo, "^d{12}$|^s*$")) {
    //   console.log("In validate off  landNo", member.landNo);
    // setValidationFailed(true);
    // setValidationFailedMsg(`Invalid  Entries. Please Check all the fields.`);
    //   return false;
    // }
    if (!formPatternValidation(member.addressL1, "^[a-zA-Z0-9., -]+$")) {
      //console.log("In validate off  addressL1");
      setValidationFailed(true);
      setValidationFailedMsg(`Invalid  Entries. Please check addressL1 field.`);
      return false;
    }
    // if (!formPatternValidation(member.addressL2, "^[a-zA-Z0-9., -]+$")) {
    //   setValidationFailed(true);
    //   setValidationFailedMsg(`Invalid  Entries. Please check addressL2 field.`);
    //   return false;
    // }
    if (member.addressL2) {
      if (!formPatternValidation(member.addressL2, "^[a-zA-Z0-9., -]+$")) {
        setValidationFailed(true);
        setValidationFailedMsg(
          `Invalid Entries. Please Check Address Line2 field.`
        );
        return false;
      }
    }
    if (!formPatternValidation(member.city, "^[a-zA-Z ]{1,64}$")) {
      setValidationFailed(true);
      setValidationFailedMsg(`Invalid  Entries. Please check city field.`);
      return false;
    }
    if (!formPatternValidation(member.pincode, "^[0-9]{6}$")) {
      setValidationFailed(true);
      setValidationFailedMsg(`Invalid  Entries. Please check pincode field.`);
      return false;
    }
    if (!formPatternValidation(member.country, "^[A-Za-zs]{1,50}$")) {
      setValidationFailed(true);
      setValidationFailedMsg(`Invalid  Entries. Please check country field.`);
      return false;
    }
    if (!formPatternValidation(member.village, "^[a-zA-Z0-9., -]+$")) {
      setValidationFailed(true);
      setValidationFailedMsg(`Invalid  Entries. Please check village field.`);
      return false;
    }
    if (!formPatternValidation(member.district, "^[a-zA-Z0-9., -]+$")) {
      setValidationFailed(true);
      setValidationFailedMsg(`Invalid  Entries. Please check district field.`);
      return false;
    }
    // if (!formPatternValidation(member.state, "^[A-Za-zs]{1,50}$")) {
    //   console.log("In validate off  state");
    // setValidationFailed(true);
    // setValidationFailedMsg(`Invalid  Entries. Please Check all the fields.`);
    //   return false;
    // }

    // Validation of Employment Details

    if (!formPatternValidation(member.cmpfAccNo, "^[A-Z0-9/]{1,20}$")) {
      setValidationFailed(true);
      setValidationFailedMsg(`Invalid  Entries. Please check cmpfAccNo field.`);
      return false;
    }
    if (!formPatternValidation(member.employeeId, "^[a-zA-Z0-9., ()/_-]+$")) {
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries. Please check employeeId field.`
      );
      return false;
    }
    // if (!formPatternValidation(ccData.unitName, "^[a-zA-Z0-9., ()/_-]+$")) {
    //   setValidationFailed(true);
    //   setValidationFailedMsg(`Invalid  Entries. Please check unit Name field.`);
    //   return false;
    // }
    // if (!formPatternValidation(ccData.coalCompName, "^[a-zA-Z ]{1,200}$")) {
    //   setValidationFailed(true);
    //   setValidationFailedMsg(
    //     `Invalid  Entries.Please check coal company name field.`
    //   );
    //   return false;
    // }
    // if (
    //   !formPatternValidation(
    //     member.pfEndDate,
    //   "^(19|20)\d\d/(0[1-9]|1[0-2])/(0[1-9]|[12]\d|3[01])|$"
    //   )
    // ) {
    //   console.log("pfEndDate", pfEndDate);
    //   console.log("In validate off  pfEndDate");
    //   return false;
    // }
    // if (
    //   !formPatternValidation(
    //     member.pfStartDate,
    //     "^(19|20)\d\d/(0[1-9]|1[0-2])/(0[1-9]|[12]\d|3[01])|$"
    //   )
    // ) {
    //   console.log("In validate off  pfStartDate");
    //   return false;
    // }
    // if (
    //   !formPatternValidation(
    //     member.joiningDate,
    //     "^(19|20)\d\d/(0[1-9]|1[0-2])/(0[1-9]|[12]\d|3[01])|$"
    //   )
    // ) {
    //   console.log("In validate off  joiningDate");
    //   return false;
    // }
    // Validation of Account Details of PF
    if (!formPatternValidation(member.addressL1Pf, "^[a-zA-Z0-9., -]+$")) {
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries. Please check Address Line1 field of PF.`
      );
      return false;
    }
    // if (!formPatternValidation(member.addressL2Pf, "^[a-zA-Z0-9., -]+$")) {
    //   setValidationFailed(true);
    //   setValidationFailedMsg(
    //     `Invalid  Entries. Please check Address Line2 field of PF.`
    //   );
    //   return false;
    // }
    if (member.addressL2Pf) {
      if (!formPatternValidation(member.addressL2Pf, "^[a-zA-Z0-9., -]+$")) {
        setValidationFailed(true);
        setValidationFailedMsg(
          `Invalid  Entries. Please Check  Address Line2 field of PF`
        );
        return false;
      }
    }
    if (!formPatternValidation(member.districtPf, "^[a-zA-Z0-9., -]+$")) {
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries. Please check District field of PF.`
      );
      return false;
    }
    if (!formPatternValidation(member.cityPf, "^[a-zA-Z0-9., -]+$")) {
      setValidationFailed(true);
      setValidationFailedMsg(`Invalid  Entries. Please Check City field of PF`);
      return false;
    }
    if (!formPatternValidation(member.bankNamePf, "^[a-zA-Z ]{1,64}$")) {
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries. Please Name of Bank field of PF.`
      );
      return false;
    }
    if (!formPatternValidation(member.pfBankIfsc, "^[A-Z]{4}0[A-Z0-9]{6}$")) {
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries. Please Check IFSC field of PF.`
      );
      return false;
    }
    if (!formPatternValidation(member.bankAccNoPf, "^[0-9]{9,18}$")) {
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries. Please Check Account No field of PF.`
      );
      return false;
    }
    // // if (!formPatternValidation(member.statePf, "^[A-Za-zs]{1,50}$")) {
    // //   console.log("In validate off  statePf");
    // //   return false;
    // // }
    if (!formPatternValidation(member.pincodePf, "^[0-9]{6}$")) {
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries. Please Check Pincode field of PF.`
      );
      return false;
    }
    //validation of Account Details of Pension

    if (!formPatternValidation(member.addressL1Pension, "^[a-zA-Z0-9., -]+$")) {
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries.Please check Address Line1 field of Pension.`
      );
      return false;
    }
    // if (!formPatternValidation(member.addressL2Pension, "^[a-zA-Z0-9., -]+$")) {
    //   setValidationFailed(true);
    //   setValidationFailedMsg(
    //     `Invalid  Entries. Please Check  Address Line2 field of Pension`
    //   );
    //   return false;
    // }

    if (member.addressL2Pension) {
      if (
        !formPatternValidation(member.addressL2Pension, "^[a-zA-Z0-9., -]+$")
      ) {
        setValidationFailed(true);
        setValidationFailedMsg(
          `Invalid  Entries. Please Check  Address Line2 field of Pensio`
        );
        return false;
      }
    }
    if (!formPatternValidation(member.districtPension, "^[a-zA-Z0-9., -]+$")) {
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries. Please Check District field of Pension.`
      );
      return false;
    }
    if (!formPatternValidation(member.cityPension, "^[a-zA-Z0-9., -]+$")) {
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries. Please Check City field of Pension.`
      );
      return false;
    }
    if (!formPatternValidation(member.bankNamePen, "^[a-zA-Z ]{1,64}$")) {
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries. Please Check Name of Bank field of Pension.`
      );
      return false;
    }
    if (!formPatternValidation(member.penBankIfsc, "^[A-Z]{4}0[A-Z0-9]{6}$")) {
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries.Please Check IFSC field of Pension.`
      );
      return false;
    }
    if (!formPatternValidation(member.bankAccNoPen, "^[0-9]{9,18}$")) {
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries. Please Check Account No field of Pension.`
      );
      return false;
    }
    // if (!formPatternValidation(member.statePension, "^[A-Za-zs]{1,50}$")) {
    //   console.log("In validate off  statePension");
    //   return false;
    // }
    if (!formPatternValidation(member.pincodePension, "^[0-9]{6}$")) {
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries. Please check Pincode field of Pension.`
      );
      return false;
    } else return true;
  };

  function getStateId(stateName) {
    const selectedState = stateList.find(
      (state) => state.stateName === stateName
    ); // Find the state object with the stateName

    if (selectedState) {
      return selectedState.value; // Return the ID of the selected state
    } else {
      return null; // Return null if the state is not found in the state list
    }
  }

  const fetchTitleList = async () => {
    const url = process.env.REACT_APP_SERVER_URL + "/get/all/title";

    axios.get(url).then((response) => {
      const titleListSamples = response.data;
      console.log("response in ccCodeList", response);
      const options = titleListSamples.map((option) => ({
        id: option.titleId,
        title: option.titleName,
      }));
      console.log("ccHeadList", options);
      setTitleList(options);
    });
  };
  const fetchReligionList = async () => {
    const url = process.env.REACT_APP_SERVER_URL + "/get/all/religion";
    axios.get(url).then((response) => {
      const religionListSamples = response.data;
      console.log("response in ccCodeList", response);
      const options = religionListSamples.map((option) => ({
        id: option.religionId,
        title: option.religionName,
      }));
      console.log("ccHeadList", options);
      setReligionList(options);
    });
  };
  const fetchccname = async (unitCode) => {
    //setIsLoading(true);
    //session related uncomment for session
    // if (!(await sessionExist())) {
    //   console.log("session extending having trouble");
    //   setIsFailed(true);
    //   setIsLoading(false);
    //   return;
    // }

    // let token = localStorage.getItem("jwt");

    // if (!token) {
    //   setIsFailed(true);
    //   setIsLoading(false);
    //   return;
    // }

    // token = token.replace('"', "").replace('"', "");

    const url =
      process.env.REACT_APP_SERVER_URL +
      "/find/unit/name/cc/name/for/update/member/details";
    const formData = new FormData();
    // formData.append("searchFieldValue", jwtDecode(token).user);
    formData.append("unitCode", unitCode);
    const config = {
      headers: {
        //  Authorization: Bearer ${token},
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        //setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          setCcData(data[0]);
          return;
        }
        // setIsFailed(true);
      })
      .catch(function (error) {
        // setIsLoading(false);
        //setIsFailed(true);
        console.log("error", error);
      });
  };

  const getUnit = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + "/allunits";
      const response = await axios.get(url);
      const data = response.data.data;
      // const options = data.map((option) => ({
      //   id: option.unitId,
      //   title: option.unitCode,
      // }));
      const options = data.map((option) => {
        //const firstID = option.match(/[0-9]/) ? "0-9" : option.charAt(0); // Get the first letter
        // console.log("optionUNits", option);
        const firstID = option.unitCode;
        //console.log("firstIdUnit", firstID);

        return {
          firstID: /[0-9]/.test(firstID.charAt(0)) ? "0-9" : firstID.charAt(0),
          unitCode: option.unitCode.toUpperCase(),
          value: option.unitId,
        };
      });
      setUnitList(options);
      // console.log("unitlistINGET", response.data.data);
      // console.log("OPTIONS uNITS", options);

      // return response.data.data;
    } catch (error) {
      console.log("error - unit", error);
      return [];
    }
  };

  useEffect(() => {
    getUnit();
  }, []);
  //console.log("unitList", unitList);
  return (
    <>
      <ErrorAlert
        handleOk={handleValidationFailedAlert}
        open={validationFailed}
        title="Error!"
        body={validationFailedMsg}
        buttonName="OK"
      />

      {/* <ErrorAlert
        handleOk={handleValidationFailedAlert}
        open={validationFailed}
        title="Error!"
        body={validationFailedMsg}
        buttonName="OK"
      /> */}

      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            {!isProgramLoaded ? (
              <LoadingIndicator />
            ) : showPreview ? (
              <PreviewPage
                member={member}
                memberDet={memberDet}
                uploadedDocs={uploadedDocs}
                ccData={ccData}
                onOk={handleOk}
                onBackPreview={handleBackPreview}
              />
            ) : (
              <>
                {/*Button with back functionality */}
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      align="left"
                      onClick={handleGoBack}
                      // sx={{ backgroundColor: "darkviolet" }}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <TitleText title="Update Member Details" />
                </Grid>
                <FormDivider />
                {/* <Form> */}
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="start"
                  rowGap={2}>
                  {/* section 1  */}
                  <Paper
                    elevation={3}
                    sx={{ padding: "3rem", borderRadius: "8px" }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="start"
                      rowGap={2}>
                      <Grid item xs={12} sm={12}>
                        <SubtitleText title="Personal Information" />
                      </Grid>
                      {/* column 1  */}
                      <Grid item xs={12} sm={5}>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          spacing={2}
                          columnGap={1}>
                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            {/* <LabelText title="Title *" /> */}

                            <LabelText title="Title" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomDropdownWithValue
                              {...titleName}
                              onchange={onHandleChange}
                            />
                          </Grid>
                          {/* row  */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="First Name" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...firstName}
                              onchange={onChange}
                            />
                          </Grid>
                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Middle Name" />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...middleName}
                              onchange={onChange}
                            />
                          </Grid>
                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Last Name" />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...lastName}
                              onchange={onChange}
                            />
                          </Grid>
                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Date of Birth" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...dateOfBirth}
                              onchange={onChange}
                            />
                          </Grid>
                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Pan" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField {...panNo} onchange={onChange} />
                          </Grid>
                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Aadhaar Number" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...aadhaarNumber}
                              onchange={onChange}
                            />
                          </Grid>
                          {/* row */}
                          <Grid item xs={12} sm={3}>
                            <LabelText title="Address Line1" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextArea
                              {...addressL1}
                              onchange={onChange}
                            />
                          </Grid>
                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Village" />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextArea {...village} onchange={onChange} />
                          </Grid>
                          {/* row  */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="District" />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...district}
                              onchange={onChange}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <LabelText title="State" required={true} />
                          </Grid>

                          <Grid item xs={12} sm={7}>
                            <Autocomplete
                              sx={{
                                minWidth: "150px",
                              }}
                              ListboxProps={{
                                style: {
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  maxHeight: 180, // Change this to your desired height
                                },
                              }}
                              // freeSolo
                              // inputValue={cc.state}
                              value={selectedState}
                              disabled={editable}
                              disableClearable
                              onChange={(e, newValue) => {
                                setSelectedState(newValue);
                                handleStateSearch(newValue, "state");
                              }}
                              options={stateList.sort(
                                (a, b) => -b.firstID.localeCompare(a.firstID)
                              )}
                              groupBy={(stateList) => stateList.firstID}
                              getOptionLabel={(stateList) =>
                                stateList.stateName
                              }
                              loading={stateLoading} // Set loading prop
                              renderInput={(params) => (
                                <TextField
                                  name="state"
                                  sx={{
                                    "& .MuiFormHelperText-root": {
                                      marginLeft: 0,
                                      // fix based on your screen
                                      fontSize: {
                                        xs: "9px",
                                        sm: "9px,",
                                        md: "9px",
                                        lg: "12px",
                                        xl: "14px",
                                      },
                                      fontWeight: "400",
                                      color: "black",
                                    },
                                    "& .MuiInputBase-input": {
                                      minWidth: "80px",
                                      borderRadius: "5px",
                                      color: "black",
                                      background: "#ffffff",
                                      // fix based on your screen
                                      fontSize: {
                                        xs: 12,
                                        sm: 12,
                                        md: 13,
                                        lg: 14,
                                        xl: 16,
                                      },
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      borderRadius: "5px",
                                      "& fieldset": {
                                        borderColor: "#474E68",
                                        // borderRadius:'0px'
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "#6B728E",
                                        // borderRadius: '0px'
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#0F3460",
                                        borderWidth: "0.5px",
                                        // borderRadius: '0px'
                                      },
                                    },
                                  }}
                                  autoComplete="off"
                                  // size='small'

                                  {...params}
                                  placeholder="Enter State"
                                  // label="Unit No."

                                  fullWidth
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {stateLoading && (
                                          <CircularProgress
                                            color="inherit"
                                            size={20}
                                          />
                                        )}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                  // helperText={
                                  //   <div style={{ height: "18px" }}></div>
                                  // }
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* column 2  */}

                      <Grid item xs={12} sm={5}>
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                          columnGap={1}>
                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Religion" required={true} />
                          </Grid>
                          {/* {editable ? (
                            <Grid item xs={12} sm={7}>
                              <CustomDropdown
                                {...religionName}
                                onchange={onChange}
                              />
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={7}>
                              <ValueText title={member.religionName} />
                            </Grid>
                          )} */}
                          <Grid item xs={12} sm={7}>
                            <CustomDropdownWithValue
                              {...religionName}
                              onchange={onHandleChange}
                            />
                          </Grid>

                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Gender" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <GroupedRadioButton
                              {...gender}
                              onchange={onChange}
                            />
                          </Grid>

                          {/* row */}
                          <Grid item xs={12} sm={2}>
                            <LabelText
                              title="Father/Husband Name"
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...fatherHusbandName}
                              onchange={onChange}
                            />
                          </Grid>

                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Email ID" />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField {...email} onchange={onChange} />
                          </Grid>

                          {/* row */}
                          <Grid item xs={12} sm={3}>
                            <LabelText title="Mobile Number" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...mobileNo}
                              onchange={onChange}
                            />
                          </Grid>

                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Landline Number" />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField {...landNo} onchange={onChange} />
                          </Grid>
                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Marital Status" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomDropdown
                              {...maritalStatus}
                              onchange={onChange}
                            />
                          </Grid>
                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Address Line2" />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextArea
                              {...addressL2}
                              onchange={onChange}
                            />
                          </Grid>
                          {/* row  */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="City" />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField {...city} onchange={onChange} />
                          </Grid>
                          {/* row  */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Pincode" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField {...pincode} onchange={onChange} />
                          </Grid>
                          {/* row  */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Country" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField {...country} onchange={onChange} />
                          </Grid>
                          {/* row */}
                          {/* <Grid item xs={12} sm={4}>
                            <LabelText title="Permanant Address" />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextArea
                              {...addressL1}
                              onchange={onChange}
                            />
                          </Grid> */}
                        </Grid>
                        <Grid item marginLeft="79%" marginTop="4%">
                          <Button
                            onClick={handleEdit1}
                            variant="contained"
                            color="primary"
                            align="right"
                            // sx={{ marginTop: 8, marginLeft: 35 }}
                          >
                            Edit
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>

                  {/* ************** */}

                  {/* section 2  */}
                  <Paper
                    elevation={3}
                    sx={{ padding: "3rem", borderRadius: "8px" }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="start"
                      rowGap={2}>
                      <Grid item xs={12} sm={12}>
                        <SubtitleText title="Employment Details" />
                      </Grid>

                      {/* column 1  */}

                      <Grid item xs={12} sm={5}>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          spacing={2}
                          columnGap={1}>
                          {/* row  */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="CMPF Account Number" />
                          </Grid>
                          <Grid
                            item
                            sm={1}
                            sx={{ display: { xs: "none", sm: "initial" } }}>
                            :
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <ValueText title={cmpfAccNum} />
                          </Grid>

                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Colliery Code" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            {/* <CustomDropdownWithValue
                              {...unitCode}
                              onchange={onHandleChange}
                            /> */}
                            <Autocomplete
                              sx={{
                                minWidth: "150px",
                              }}
                              ListboxProps={{
                                style: {
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  maxHeight: 180, // Change this to your desired height
                                },
                              }}
                              value={selectedUnitCode}
                              disabled={editable2}
                              disableClearable
                              onChange={(e, newValue) => {
                                setSelectedUnitCode(newValue);
                                fetchccname(newValue.unitCode);

                                handleUnitSearch(newValue, "unitCode");
                              }}
                              options={unitList.sort(
                                (a, b) => -b.firstID.localeCompare(a.firstID)
                              )}
                              groupBy={(unitList) => unitList.firstID}
                              getOptionLabel={(unitList) => unitList.unitCode}
                              //loading={stateLoading} // Set loading prop
                              renderInput={(params) => (
                                <TextField
                                  // value={member.statePf}
                                  name="unitCode"
                                  sx={{
                                    "& .MuiFormHelperText-root": {
                                      marginLeft: 0,
                                      // fix based on your screen
                                      fontSize: {
                                        xs: "9px",
                                        sm: "9px,",
                                        md: "9px",
                                        lg: "12px",
                                        xl: "14px",
                                      },
                                      fontWeight: "400",
                                      color: "black",
                                    },
                                    "& .MuiInputBase-input": {
                                      minWidth: "80px",
                                      borderRadius: "5px",
                                      color: "black",
                                      background: "#ffffff",
                                      // fix based on your screen
                                      fontSize: {
                                        xs: 12,
                                        sm: 12,
                                        md: 13,
                                        lg: 14,
                                        xl: 16,
                                      },
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      borderRadius: "5px",
                                      "& fieldset": {
                                        borderColor: "#474E68",
                                        // borderRadius:'0px'
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "#6B728E",
                                        // borderRadius: '0px'
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#0F3460",
                                        borderWidth: "0.5px",
                                        // borderRadius: '0px'
                                      },
                                    },
                                  }}
                                  autoComplete="off"
                                  // size='small'

                                  {...params}
                                  Fplaceholder="Enter Unit Code"
                                  // label="Unit No."

                                  fullWidth
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {stateLoading && (
                                          <CircularProgress
                                            color="inherit"
                                            size={20}
                                          />
                                        )}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                  // helperText={
                                  //   <div style={{ height: "18px" }}></div>
                                  // }
                                />
                              )}
                            />
                          </Grid>

                          {/* row */}
                          <Grid item xs={12} sm={3}>
                            <LabelText title="Coal Company" />
                          </Grid>
                          <Grid
                            item
                            sm={1}
                            sx={{ display: { xs: "none", sm: "initial" } }}>
                            :
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            {ccData && ccData.coalCompName ? (
                              <ValueText title={ccData.coalCompName} />
                            ) : (
                              <ValueText title={member.name} />
                            )}
                          </Grid>

                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="PF Start Date" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...pfStartDate}
                              onchange={onChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* column 2  */}

                      <Grid item xs={12} sm={5}>
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                          columnGap={1}>
                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Employee ID" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...employeeId}
                              onchange={onChange}
                            />
                          </Grid>

                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Colliery Name" required={true} />
                          </Grid>
                          <Grid
                            item
                            sm={1}
                            sx={{ display: { xs: "none", sm: "initial" } }}>
                            :
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            {ccData && ccData.unitName ? (
                              <ValueText title={ccData.unitName} />
                            ) : (
                              <ValueText title={member.unitName} />
                            )}
                          </Grid>
                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Joining Date" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...joiningDate}
                              onchange={onChange}
                            />
                          </Grid>

                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="PF End Date" required={true}/>
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...pfEndDate}
                              onchange={onChange}
                            />
                          </Grid>
                          <Grid item marginLeft="79%">
                            <Button
                              onClick={handleEdit2}
                              variant="contained"
                              color="primary"
                              align="right"
                              // sx={{ marginTop: 3, marginLeft: 35 }}
                            >
                              Edit
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                  {/* ************** */}
                  {/* section 3  */}
                  <Paper
                    elevation={3}
                    sx={{ padding: "3rem", borderRadius: "8px" }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      rowGap={2}>
                      <Grid item xs={12} sm={12}>
                        <SubtitleText title="Account Details of PF" />
                      </Grid>
                      {/* column 1  */}

                      <Grid item xs={12} sm={5}>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          spacing={2}
                          columnGap={1}>
                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            <LabelText
                              title="Name of the Bank"
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...bankNamePf}
                              onchange={onChange}
                            />
                          </Grid>

                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Account No" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...bankAccNoPf}
                              onchange={onChange}
                            />
                          </Grid>
                          {/* row  */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Address Line2" />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextArea
                              {...addressL2Pf}
                              onchange={onChange}
                            />
                          </Grid>
                          {/* row  */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="District" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...districtPf}
                              onchange={onChange}
                            />
                          </Grid>
                          {/* row  */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="State" required={true} />
                          </Grid>

                          <Grid item xs={12} sm={7}>
                            <Autocomplete
                              sx={{
                                minWidth: "150px",
                              }}
                              ListboxProps={{
                                style: {
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  maxHeight: 180, // Change this to your desired height
                                },
                              }}
                              // freeSolo
                              //inputValue={member.statePf}
                              // value={member.statePf}
                              value={selectedStatePf}
                              disabled={editablePf}
                              disableClearable
                              onChange={(e, newValue) => {
                                setSelectedStatePf(newValue);
                                handleStateSearch(newValue, "statePf");
                              }}
                              options={stateList.sort(
                                (a, b) => -b.firstID.localeCompare(a.firstID)
                              )}
                              groupBy={(stateList) => stateList.firstID}
                              getOptionLabel={(stateList) =>
                                stateList.stateName
                              }
                              loading={stateLoading} // Set loading prop
                              renderInput={(params) => (
                                <TextField
                                  // value={member.statePf}
                                  name="statePf"
                                  sx={{
                                    "& .MuiFormHelperText-root": {
                                      marginLeft: 0,
                                      // fix based on your screen
                                      fontSize: {
                                        xs: "9px",
                                        sm: "9px,",
                                        md: "9px",
                                        lg: "12px",
                                        xl: "14px",
                                      },
                                      fontWeight: "400",
                                      color: "black",
                                    },
                                    "& .MuiInputBase-input": {
                                      minWidth: "80px",
                                      borderRadius: "5px",
                                      color: "black",
                                      background: "#ffffff",
                                      // fix based on your screen
                                      fontSize: {
                                        xs: 12,
                                        sm: 12,
                                        md: 13,
                                        lg: 14,
                                        xl: 16,
                                      },
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      borderRadius: "5px",
                                      "& fieldset": {
                                        borderColor: "#474E68",
                                        // borderRadius:'0px'
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "#6B728E",
                                        // borderRadius: '0px'
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#0F3460",
                                        borderWidth: "0.5px",
                                        // borderRadius: '0px'
                                      },
                                    },
                                  }}
                                  autoComplete="off"
                                  // size='small'

                                  {...params}
                                  Fplaceholder="Enter State"
                                  // label="Unit No."

                                  fullWidth
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {stateLoading && (
                                          <CircularProgress
                                            color="inherit"
                                            size={20}
                                          />
                                        )}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                  // helperText={
                                  //   <div style={{ height: "18px" }}></div>
                                  // }
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* column 2  */}

                      <Grid item xs={12} sm={5}>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          spacing={2}
                          columnGap={1}>
                          {/* row  */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title=" IFSC " required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...pfBankIfsc}
                              onchange={onChange}
                            />
                          </Grid>

                          {/* row */}

                          <Grid item xs={12} sm={3}>
                            <LabelText title="Address Line1" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextArea
                              {...addressL1Pf}
                              onchange={onChange}
                            />
                          </Grid>
                          {/* row  */}
                          <Grid item xs={12} sm={2}>
                            <LabelText title="City" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField {...cityPf} onchange={onChange} />
                          </Grid>
                          {/* row  */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Pincode" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...pincodePf}
                              onchange={onChange}
                            />
                          </Grid>
                        </Grid>
                        <Grid item marginLeft="79%">
                          <Button
                            onClick={handleEditPf}
                            variant="contained"
                            color="primary"
                            align="right"
                            sx={{ marginTop: 3 }}>
                            Edit
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>

                  {/* ************** */}
                  {/* section 4  */}
                  <Paper
                    elevation={3}
                    sx={{ padding: "3rem", borderRadius: "8px" }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      rowGap={2}>
                      <Grid item xs={12} sm={12}>
                        <SubtitleText title="Account Details of Pension" />
                      </Grid>
                      {/* column 1  */}

                      <Grid item xs={12} sm={5}>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          spacing={2}
                          columnGap={1}>
                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Name of the Bank" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...bankNamePen}
                              onchange={onChange}
                            />
                          </Grid>

                          {/* row */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Account No" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...bankAccNoPen}
                              onchange={onChange}
                            />
                          </Grid>
                          {/* row  */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Address Line2" />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextArea
                              {...addressL2Pension}
                              onchange={onChange}
                            />
                          </Grid>
                          {/* row  */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="District" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...districtPension}
                              onchange={onChange}
                            />
                          </Grid>
                          {/* row  */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="State" required={true} />
                          </Grid>

                          <Grid item xs={12} sm={7}>
                            <Autocomplete
                              sx={{
                                minWidth: "150px",
                              }}
                              ListboxProps={{
                                style: {
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  maxHeight: 180, // Change this to your desired height
                                },
                              }}
                              // freeSolo
                              // inputValue={cc.state}
                              value={selectedStatePen}
                              disableClearable
                              disabled={editable3}
                              onChange={(e, newValue) => {
                                setSelectedStatePen(newValue);
                                handleStateSearch(newValue, "statePension");
                              }}
                              options={stateList.sort(
                                (a, b) => -b.firstID.localeCompare(a.firstID)
                              )}
                              groupBy={(stateList) => stateList.firstID}
                              getOptionLabel={(stateList) =>
                                stateList.stateName
                              }
                              loading={stateLoading} // Set loading prop
                              renderInput={(params) => (
                                <TextField
                                  name="statePension"
                                  sx={{
                                    "& .MuiFormHelperText-root": {
                                      marginLeft: 0,
                                      // fix based on your screen
                                      fontSize: {
                                        xs: "9px",
                                        sm: "9px,",
                                        md: "9px",
                                        lg: "12px",
                                        xl: "14px",
                                      },
                                      fontWeight: "400",
                                      color: "black",
                                    },
                                    "& .MuiInputBase-input": {
                                      minWidth: "80px",
                                      borderRadius: "5px",
                                      color: "black",
                                      background: "#ffffff",
                                      // fix based on your screen
                                      fontSize: {
                                        xs: 12,
                                        sm: 12,
                                        md: 13,
                                        lg: 14,
                                        xl: 16,
                                      },
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      borderRadius: "5px",
                                      "& fieldset": {
                                        borderColor: "#474E68",
                                        // borderRadius:'0px'
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "#6B728E",
                                        // borderRadius: '0px'
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#0F3460",
                                        borderWidth: "0.5px",
                                        // borderRadius: '0px'
                                      },
                                    },
                                  }}
                                  autoComplete="off"
                                  // size='small'

                                  {...params}
                                  placeholder="Enter State"
                                  // label="Unit No."

                                  fullWidth
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {stateLoading && (
                                          <CircularProgress
                                            color="inherit"
                                            size={20}
                                          />
                                        )}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                  // helperText={
                                  //   <div style={{ height: "18px" }}></div>
                                  // }
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* column 2  */}

                      <Grid item xs={12} sm={5}>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          spacing={2}
                          columnGap={1}>
                          {/* row  */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title=" IFSC " required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...penBankIfsc}
                              onchange={onChange}
                            />
                          </Grid>
                          {/* row  */}
                          <Grid item xs={12} sm={3}>
                            <LabelText title="Address Line1" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextArea
                              {...addressL1Pension}
                              onchange={onChange}
                            />
                          </Grid>
                          {/* row  */}
                          <Grid item xs={12} sm={2}>
                            <LabelText title="City" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...cityPension}
                              onchange={onChange}
                            />
                          </Grid>
                          {/* row  */}
                          <Grid item xs={12} sm={4}>
                            <LabelText title="Pincode" required={true} />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <CustomTextField
                              {...pincodePension}
                              onchange={onChange}
                            />
                          </Grid>
                        </Grid>
                        <Grid item marginLeft="79%">
                          <Button
                            onClick={handleEdit3}
                            variant="contained"
                            color="primary"
                            align="right"
                            sx={{ marginTop: 3 }}>
                            Edit
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>

                  {/* <Grid
                  item>
                  <Button
                    onClick={handleEdit}
                    variant="contained"
                    color="primary"
                    align="center"
                    sx={{ marginTop: 3, marginLeft: 53}}
                  >
                    Submit
                  </Button>
                </Grid> */}
                  {/* ************** */}
                  {/* section 2 */}
                  <Grid item xs={12} sm={6} md={12}>
                    <Paper
                      elevation={3}
                      sx={{ padding: "3rem", borderRadius: "8px" }}>
                      <Grid item xs={12}>
                        <SubtitleText title="Upload Documents" />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <UploadDocument
                          documentTypes={documentTypes}
                          onSave={handleSave}
                        />
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Button
                      onClick={handleSubmit}
                      variant="contained"
                      color="success"
                      align="right"
                      // sx={{ marginTop: 3, marginLeft: "50%" }}
                    >
                      preview
                    </Button>
                  </Grid>
                </Grid>
              </>
              //  )}
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default UpdateMemberDetails;
