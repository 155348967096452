export const schedule2 = {
  0: 100,
  1: 97,
  2: 94,
  3: 91,
  4: 89,
  5: 86,
  6: 83,
  7: 81,
  8: 78,
  9: 76,
  10: 73,
};

export const schedule3 = {
  1: 0.6,
  2: 1.23,
  3: 1.86,
  4: 2.51,
  5: 3.17,
  6: 3.84,
  7: 4.52,
  8: 5.22,
  9: 5.45,
};
