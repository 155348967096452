import React, { Fragment, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import CustomDropdown from "../../FormComponent/CustomDropdown";

import LabelText from "../../FormComponent/LabelText";
import CustomTextField from "../../FormComponent/CustomTextField";
import SubtitleText from "../../FormComponent/SubtitleText";
import LoadingIndicator from "../../FormComponent/LoadingIndicator";
// import axios from "axios";

const AdditionalAdvPf = (props) => {
  const { location, updateAddField, dependentFamily } = props;
  const [isProgramLoaded, setIsProgramLoaded] = useState(true);

  const [minimumadvpf1, setMinimumadvpf1] = useState();
  const claimantNameList = dependentFamily.map((member) => ({
    id: member.familyMemId,
    title: member.nameFamily,
  }));
  claimantNameList.push({ id: 0, title: "Self" });

  const defaultAddFields = {
    claimType: "",
    plotNo: "",
    subPlotNo: "",
    village: "",
    postOffice: "",
    policeStation: "",
    district: "",
    state: "",
    nameOfBnak: "",
    sbAccNo: "",
    address: "",
    addressline1: "",
    addressline2: "",
    pincode: "",
    city: "",
    requiredAmount: "",
    employeeGrossAmount: "",
    estimatedCost: "",
    dependentId: "",
    stateLocation: "",
    saleAgreementAmount: "",
    locationaddressline1: "",
    villageCity: "",
    locationaddressline2: "",
    districtLocation: "",
    pinCodeLocation: "",
    anticptdDateOfMarriage: "",
    nameOfStudent: "",
    examYear: "",
    examPassed: "",
    feesPaid: "",
    amountAllowed: "",
    amountRequiredDate: "",
    areaUnit: "",
    area: "",
  };

  const [addFields, setAddFields] = useState(defaultAddFields);
  const onChange = (e) => {
    setAddFields({ ...addFields, [e.target.name]: e.target.value });
    updateAddField(e.target.name, e.target.value);
  };

  const plotNo = {
    name: "plotNo",
    type: "text",
    helperText: "",
    placeholder: "",
    value: addFields.plotNo,
    errorMessage: "Invalid",
    pattern: "",
  };
  const subPlotNo = {
    name: "subPlotNo",
    type: "text",
    helperText: "",
    placeholder: "",
    value: addFields.subPlotNo,
    errorMessage: "Invalid",
    pattern: "",
  };

  const area = {
    name: "area",
    type: "number",
    helperText: "",
    placeholder: "",
    value: addFields.area,
    errorMessage: "Invalid",
    pattern: "^[0-9]*$",
  };

  const areaUnit = {
    name: "areaUnit",
    placeholder: "Select unit",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "1",
    required: true,
    options: [
      { id: "1", title: "Sq. ft." },
      { id: "2", title: "Sq. yd." },
    ],
    //options: claimTypeList,
  };

  // const postOffice = {
  //   name: "village",
  //   type: "text",
  //   helperText: "",
  //   placeholder: "",
  //   value: sampleLocationDetails.postOffice,
  //   errorMessage: "Invalid",
  //   pattern: "",
  // };
  const district = {
    name: "district",
    type: "text",
    helperText: "",
    placeholder: "",
    value: addFields.district,
    errorMessage: "Invalid",
    pattern: "",
  };

  const state = {
    name: "state",
    type: "text",
    helperText: "",
    placeholder: "",
    value: addFields.state,
    errorMessage: "Invalid",
    pattern: "",
  };

  // const nameofBank = {
  //   name: "nameofBank",
  //   type: "text",
  //   helperText: "",
  //   placeholder: "",
  //   value: sampleLocationDetails.nameofBank,
  //   errorMessage: "Invalid",
  //   pattern: "",
  // };

  // const sbAccNo = {
  //   name: "sbAccNo",
  //   type: "text",
  //   helperText: "",
  //   placeholder: "",
  //   value: sampleLocationDetails.sbAccNo,
  //   errorMessage: "Invalid",
  //   pattern: "",
  // };

  // const address = {
  //   name: "address",
  //   type: "text",
  //   helperText: "",
  //   placeholder: "",
  //   value: sampleLocationDetails.address,
  //   errorMessage: "Invalid",
  //   pattern: "",
  // };

  const addressline1 = {
    name: "addressline1",
    type: "text",
    helperText: "",
    placeholder: "",
    value: addFields.addressline1,
    errorMessage: "Invalid",
    pattern: "",
  };

  //**********Location******* */
  const locationaddressline1 = {
    name: "locationaddressline1",
    type: "text",
    helperText: "",
    placeholder: "",
    value: addFields.locationaddressline1,
    errorMessage: "Invalid",
    pattern: "",
  };

  const locationaddressline2 = {
    name: "locationaddressline2",
    type: "text",
    helperText: "",
    placeholder: "",
    value: addFields.locationaddressline2,
    errorMessage: "Invalid",
    pattern: "",
  };

  const villageCity = {
    name: "villageCity",
    type: "text",
    helperText: "",
    placeholder: "",
    value: addFields.villageCity,
    errorMessage: "Invalid",
    pattern: "",
  };

  const districtLocation = {
    name: "districtLocation",
    type: "text",
    helperText: "",
    placeholder: "",
    value: addFields.districtLocation,
    errorMessage: "Invalid",
    pattern: "",
  };

  const stateLocation = {
    name: "stateLocation",
    type: "text",
    helperText: "",
    placeholder: "",
    value: addFields.stateLocation,
    errorMessage: "Invalid",
    pattern: "",
  };

  const pinCodeLocation = {
    name: "pinCodeLocation",
    type: "number",
    helperText: "",
    placeholder: "",
    value: addFields.pinCodeLocation,
    errorMessage: "Invalid. Please enter a valid six-digit pincode.",
    pattern: "^[0-9]{6}$",
  };

  //*************Location**end********** */

  const dependentId = {
    name: "dependentId",
    placeholder: "",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: addFields.dependentId,
    required: true,
    options: claimantNameList,
  };

  const addressline2 = {
    name: "addressline2",
    type: "text",
    helperText: "",
    placeholder: "",
    value: addFields.addressline2,
    errorMessage: "Invalid",
    pattern: "",
  };

  const pincode = {
    name: "pincode",
    type: "number",
    helperText: "",
    placeholder: "",
    value: addFields.pincode,
    errorMessage: "Invalid. Please enter a valid six-digit pincode.",
    pattern: "^[0-9]{6}$",
  };

  const city = {
    name: "city",
    type: "text",
    helperText: "",
    placeholder: "",
    value: addFields.city,
    errorMessage: "Invalid",
    pattern: "",
  };

  const requiredAmount = {
    name: "requiredAmount",
    type: "number",
    helperText: "",
    placeholder: "",
    value: addFields.requiredAmount,
    errorMessage: "Invalid",
    pattern: "^[0-9]*$",
    // disabled: readOnly,
  };
  const employeeGrossAmount = {
    name: "employeeGrossAmount",
    type: "number",
    helperText: "",
    placeholder: "",
    value: addFields.employeeGrossAmount,
    errorMessage: "Invalid",
    pattern: "^[0-9]*$",
    // disabled: readOnly,
  };
  const estimatedCost = {
    name: "estimatedCost",
    type: "number",
    helperText: "",
    placeholder: "",
    value: addFields.estimatedCost,
    errorMessage: "Invalid",
    pattern: "^[0-9]*$",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const saleAgreementAmount = {
    name: "saleAgreementAmount",
    type: "number",
    helperText: "",
    placeholder: "",
    value: addFields.saleAgreementAmount,
    errorMessage: "Invalid",
    pattern: "^[0-9]*$",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const anticptdDateOfMarriage = {
    name: "anticptdDateOfMarriage",
    type: "date",
    helperText: "",
    placeholder: "",
    value: addFields.anticptdDateOfMarriage,
    errorMessage: "Invalid",
    pattern: "",
    // disabled: readOnly,
  };

  const nameOfStudent = {
    name: "nameOfStudent",
    placeholder: "",
    helperText: "",
    value: addFields.nameOfStudent,
    errorMessage: "Invalid",
    required: true,
    options: claimantNameList,
  };

  const examPassed = {
    name: "examPassed",
    type: "text",
    helperText: "",
    placeholder: "",
    value: addFields.examPassed,
    errorMessage: "Invalid",
    pattern: "",
    // disabled: readOnly,
  };

  const examYear = {
    name: "examYear",
    type: "Date",
    helperText: "",
    placeholder: "",
    value: addFields.examYear,
    errorMessage: "Invalid",
    pattern: "",
    // pattern: "^[0-9]*$",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const feesPaid = {
    name: "feesPaid",
    type: "number",
    helperText: "",
    placeholder: "",
    value: addFields.feesPaid,
    errorMessage: "Invalid",
    pattern: "^[0-9]*$",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const amountRequiredDate = {
    name: "amountRequiredDate",
    type: "date",
    helperText: "",
    placeholder: "Select Date ",
    defaultValue: addFields.amountRequiredDate,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  // //**************************************************** Calculation *************************************************************** */
  // let addFieldsship;
  // let addFieldsShare;
  // let emoloyerShare;
  // let interestAmount;
  const solve = {
    memShare: location.memberShare,
    emplShare: location.empShare,
    closingBalance: location.closingBalance,
  };

  useEffect(() => {
    let totalShare;
    let totalEmoluments;
    let minimumadvpf1 = 0;

    if (
      location.claim_type == 1 ||
      location.claim_type == 2 ||
      location.claim_type == 3
    ) {
      totalShare = solve.memShare + 0.75 * solve.emplShare;
      totalEmoluments = 36 * addFields.employeeGrossAmount;
      minimumadvpf1 = Math.min(
        totalShare,
        totalEmoluments,
        addFields.saleAgreementAmount,
        addFields.requiredAmount
      );
    } else if (location.claim_type == 5) {
      totalShare = solve.memShare;
      totalEmoluments = 6 * addFields.employeeGrossAmount;
      minimumadvpf1 = Math.min(
        totalShare,
        totalEmoluments,
        addFields.estimatedCost,
        addFields.requiredAmount
      );
    } else if (location.claim_type == 6) {
      totalShare = solve.memShare * 0.5;
      minimumadvpf1 = Math.min(totalShare, addFields.requiredAmount);
    } else if (location.claim_type == 7) {
      totalShare = solve.memShare * 0.5;
      totalEmoluments = 3 * addFields.employeeGrossAmount;
      minimumadvpf1 = Math.min(
        totalShare,
        totalEmoluments,
        addFields.feesPaid,
        addFields.requiredAmount
      );
    } else if (location.claim_type == 8) {
      minimumadvpf1 = solve.closingBalance;
    }

    setMinimumadvpf1(minimumadvpf1);
  }, [
    location.claim_type,
    addFields.saleAgreementAmount,
    addFields.requiredAmount,
    addFields.employeeGrossAmount,
    addFields.estimatedCost,
    solve.memShare,
    solve.emplShare,
    solve.closingBalance,
    addFields.feesPaid,
  ]);

  useEffect(() => {
    updateAddField("amountAllowed", minimumadvpf1);
  }, [minimumadvpf1]);
  //******************************************************************************************************************************** */

  // useEffect(() => {
  //   const checkAmountRequiredDate = (amountRequiredDate) => {
  //     if (amountRequiredDate !== null) {
  //       const parsedAmountRequiredDate = new Date(amountRequiredDate);

  //       const currentDate = new Date();
  //       const threeMonthsFromNow = new Date();
  //       threeMonthsFromNow.setMonth(currentDate.getMonth() + 3);

  //       if (parsedAmountRequiredDate < threeMonthsFromNow) {
  //         setIsError(true);
  //         setErrorMessage("Amount Required Date cannot be less than 3 months.");
  //       } else {
  //         // Reset error state if the date is valid
  //         setIsError(false);
  //         setErrorMessage("");
  //       }
  //     }
  //   };

  //   // Example usage:
  //   const amountRequiredDate = addFields.amountRequiredDate; // Replace with your actual date
  //   checkAmountRequiredDate(amountRequiredDate);
  // }, [addFields.amountRequiredDate]); // Add dependencies to the useEffect hook

  /// error alert handler
  // const handleErrorAlert = () => {
  //   // setAddField("amountRequiredDate":null);
  //   setAddFields({ ...addFields, amountRequiredDate: "" });

  //   setIsError(false);
  // };
  return (
    <>
      {/* <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error"
        body={errorMessage}
        buttonName="Ok"
      /> */}
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/* <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}> */}
          {!isProgramLoaded ? (
            <LoadingIndicator />
          ) : (
            <>
              {/* <Grid item xs={12}>
                <TitleText title="Update Member Details" />
              </Grid> */}

              {/* <FormDivider /> */}

              {/* <Form> */}
              <Grid
                container
                justifyContent="space-between"
                alignItems="start"
                rowGap={2}
              >
                {/* ************************************** */}
                {/* section 1  */}

                {/* column 1  */}

                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                    columnGap={1}
                  >
                    {/* row  */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Required Amount " />
                    </Grid>
                    <Grid mt={2}>:</Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField
                        {...requiredAmount}
                        onchange={onChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {location.claim_type === "1" ||
                location.claim_type === "2" ||
                location.claim_type === "3" ||
                location.claim_type === "4" ||
                location.claim_type === "5" ||
                location.claim_type === "7" ? (
                  <>
                    {/* column 2  */}

                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Member Notional Salary " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...employeeGrossAmount}
                            onchange={onChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    {/* <Typography>No data available for the selected claim type.</Typography> */}
                  </Grid>
                )}
                {/* ************************************** */}
                {location.claim_type === "1" ||
                location.claim_type === "2" ||
                location.claim_type === "3" ||
                location.claim_type === "4" ||
                location.claim_type === "5" ? (
                  <>
                    {/* column 1  */}

                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Estimated Cost " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...estimatedCost}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Area " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField {...area} onchange={onChange} />
                        </Grid>
                        {location.claim_type === "2" ? (
                          <>
                            <Grid item xs={12} sm={4}>
                              <LabelText title="Plot No. " />
                            </Grid>
                            <Grid mt={2}>:</Grid>
                            <Grid item xs={12} sm={7}>
                              <CustomTextField
                                {...plotNo}
                                onchange={onChange}
                              />
                            </Grid>
                          </>
                        ) : (
                          <Grid item xs={12}>
                            {/* <Typography>No data available for the selected claim type.</Typography> */}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {/* column 2  */}
                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Sale Agreement Amount " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...saleAgreementAmount}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Area Unit " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomDropdown {...areaUnit} onchange={onChange} />
                        </Grid>

                        {location.claim_type === "2" ? (
                          <>
                            <Grid item xs={12} sm={4}>
                              <LabelText title="Sub-Plot No. " />
                            </Grid>
                            <Grid mt={2}>:</Grid>
                            <Grid item xs={12} sm={7}>
                              <CustomTextField
                                {...subPlotNo}
                                onchange={onChange}
                              />
                            </Grid>
                          </>
                        ) : (
                          <Grid item xs={12}>
                            {/* <Typography>No data available for the selected claim type.</Typography> */}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {/* section 2  */}

                    <Grid item xs={12} sm={12} mt={-1}>
                      <SubtitleText title="Location Details" />
                    </Grid>

                    {/* column 1  */}

                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Address Line1  " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...locationaddressline1}
                            onchange={onChange}
                          />
                        </Grid>

                        {/* row  */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Village/City " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...villageCity}
                            onchange={onChange}
                          />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="State " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...stateLocation}
                            onchange={onChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* column 2  */}

                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title=" Address Line2 " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...locationaddressline2}
                            onchange={onChange}
                          />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="District " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...districtLocation}
                            onchange={onChange}
                          />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Pin Code " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...pinCodeLocation}
                            onchange={onChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : location.claim_type === "6" ? (
                  <>
                    {/* section 2  */}
                    {/* column 1  */}

                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Anticipated Date of Marriage " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...anticptdDateOfMarriage}
                            onchange={onChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* column 2  */}

                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title=" For Marriage of " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomDropdown
                            {...dependentId}
                            onchange={onChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : location.claim_type === "7" ? (
                  <>
                    {/* section 2  */}
                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Name of Student " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomDropdown
                            {...dependentId}
                            onchange={onChange}
                          />
                        </Grid>
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Year of Passing Exam " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField {...examYear} onchange={onChange} />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* column 2  */}
                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Examination Passed " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...examPassed}
                            onchange={onChange}
                          />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title=" Fee Paid " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField {...feesPaid} onchange={onChange} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    {/* <Typography>No data available for the selected claim type.</Typography> */}
                  </Grid>
                )}
                {/* ----------------------------------------------------------------------------------------- */}
                {/* ************************************** */}
                {/* section 3  */}
                {/* <Grid item xs={12} sm={12}>
                  <SubtitleText title="Remittance Details" />
                </Grid> */}

                {/* column 1  */}

                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                    columnGap={1}
                  >
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Amount Required Date " />
                    </Grid>
                    <Grid mt={2}>:</Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField
                        {...amountRequiredDate}
                        onchange={onChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* column 2  */}

                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    columnGap={1}
                  >
                    {/* row */}
                    {/* <Grid item xs={12} sm={4}>
                      <LabelText title="Address :" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...address} onchange={onChange} />
                    </Grid> */}

                    {/* row */}
                    {/* <Grid item xs={12} sm={4}>
                      <LabelText title="IFSC Code :" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...address} onchange={onChange} />
                    </Grid> */}
                  </Grid>
                </Grid>

                {/* ************************************** */}
                {/* section 3  */}
                <Grid item xs={12} sm={12} mt={2}>
                  <SubtitleText title="Correspondence Address" />
                </Grid>
                {/* column 1  */}

                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                    columnGap={1}
                  >
                    {/* row  */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Address Line1" />
                    </Grid>
                    <Grid mt={2}>:</Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...addressline1} onchange={onChange} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Address Line2 " />
                    </Grid>
                    <Grid mt={2}>:</Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...addressline2} onchange={onChange} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Pin Code " />
                    </Grid>
                    <Grid mt={2}>:</Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...pincode} onchange={onChange} />
                    </Grid>
                  </Grid>
                </Grid>
                {/* column 2  */}
                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                    columnGap={1}
                  >
                    {/* row  */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="City " />
                    </Grid>
                    <Grid mt={2}>:</Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...city} onchange={onChange} />
                    </Grid>
                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="District " />
                    </Grid>
                    <Grid mt={2}>:</Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...district} onchange={onChange} />
                    </Grid>
                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="State " />
                    </Grid>
                    <Grid mt={2}>:</Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...state} onchange={onChange} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {/* </Paper> */}
        </Grid>
      </Grid>
    </>
  );
};

export default AdditionalAdvPf;
