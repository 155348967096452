import React from 'react';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LabelText from '../../FormComponent/LabelText';

function BackButton() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleGoBack}>
          Back
        </Button>
      </Grid>
    </Grid>
  );
}

export default BackButton;
