import { useState, createContext } from 'react';


const UserContext = createContext();

const UserProvider = (props) => {

  const {childrens} = props;


  const [user, setUser] = useState({
    status:false,
    cmpfoAccNo: '',
    name: '',
    role:'',
    token: '',
  });

const updateUser = (userObj)=>{
  setUser((user) => ({ ...user, ...userObj }));
  // console.log("userObj passed as", userObj)
}

  return (
    <UserContext.Provider value={{currentUser:user, updateUser: updateUser}}>
        {childrens}
    </UserContext.Provider>
  );
};

export{UserProvider,UserContext};
