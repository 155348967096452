import { Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';
import { useState, useEffect } from 'react';

function YearDropdown(props) {

    const {onChange} = props;

    const currentYear = new Date().getFullYear();
    const [startYear, setStartYear] = useState('');
    const [endYear, setEndYear] = useState('');

    const years = Array.from({ length: currentYear - 1960 }, (_, index) => currentYear - index);
    const endYears = startYear ? years.slice(0, years.indexOf(Number(startYear)) + 1) : [];

    const handleStartYearChange = (event) => {
        const selectedStartYear = event.target.value;
        setStartYear(selectedStartYear);
        setEndYear('');
        onChange(event)
    };

    const handleEndYearChange = (event) => {
        const selectedEndYear = event.target.value;
        setEndYear(selectedEndYear);
        onChange(event)
    };

    useEffect(() => {
        setEndYear('');
    }, [startYear]);

    return (
        <Grid container columnSpacing={5}>
            <Grid item >
                <FormControl fullWidth sx={{ minWidth: 120 }}>
                    <InputLabel labelId="start-year-label">Start Year</InputLabel>
                    <Select
                      label='Start Year'
                     labelId="start-year-label"
                        value={startYear}
                        name='start_date'
                        onChange={handleStartYearChange}
                        MenuProps={{
                            MenuListProps: {
                                style: {
                                    minWidth:'60',
                                    maxHeight: '250px',
                                    overflowY: 'auto',
                                },
                            },
                        }}
                    >
                        {years.map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl disabled={!startYear} fullWidth sx={{ minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-label">End Year</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name='end_date'
                      label='End Year'
                        value={endYear}
                        onChange={handleEndYearChange}
                        MenuProps={{
                            MenuListProps: {
                                style: {
                                    minWidth: '60',
                                    maxHeight: '100px', // Adjust the max height as needed
                                    // overflowY: 'auto',
                                },
                            },
                        }}
                    >
                        {endYears.map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}

export default YearDropdown;
