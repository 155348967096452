
import React from 'react';
import { Typography } from '@mui/material';

const Purpose = () => {
  return (
    <div id="purpose-of-the-document">
   
   
      <Typography variant="h6" align='justify'>PURPOSE OF THE DOCUMENT</Typography>
      <Typography variant="body1" align='justify'>
        
         
       <p>
       The primary objective of this document is to facilitate convenient and efficient access to the portal for CMPFO and its stakeholders including the CIL Subsidiaries.
       
       </p>

      </Typography>
    
    </div>
    
  );
};

export default Purpose ;
