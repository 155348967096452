import { Grid } from "@mui/material";
import TableWithLinkAndButton from "../../FormComponent/TableWithLinkAndButton";
import React, { useState } from "react";
import SubtitleText from "../../FormComponent/SubtitleText";

function UpdatedFieldTableForMem(props) {
  const { memUpdReqdetails } = props;
  const { tUpdMemDetls } = memUpdReqdetails;
  //   console.log("tUpdMemDetls", tUpdMemDetls);
  const { fieldName, newFieldVal, oldField_val } = tUpdMemDetls;
  //   console.log("ja", fieldName);
  //   console.log("jai", newFieldVal);
  //   console.log("jaii", oldField_val);
  // console.log("memUpdReqdetails", memUpdReqdetails);

  const [tableData, setTableData] = useState({
    fieldName: fieldName,
    oldField_val: oldField_val, // Fix this line
    newFieldVal: newFieldVal, // Fix this line
  });

  const memberCreatePreviewData = () => {
    if (fieldName && oldField_val && newFieldVal) {
      const fieldsToRemove = [
        "titleId",
        "religionId",
        "unitId",
        "panId",
        "relationId",
        "aadhaarId",
      ];

      const cleanedData = fieldName
        .map((field, index) => ({
          "Field Value": field.replace(/"/g, ""),
          "Old Value": oldField_val[index].replace(/"/g, ""),
          "New Value": newFieldVal[index].replace(/"/g, ""),
        }))
        .filter((data) => !fieldsToRemove.includes(data["Field Value"]));

      // console.log("fieldName", cleanedData);
      return cleanedData;
    } else {
      return [];
    }
  };

  return (
    <div>
      <Grid container justifyContent="center" sx={{ wordWrap: "break-word" }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12}>
            <SubtitleText title="Updated Details" />
          </Grid>
          <Grid item sm={12} mt={3}>
            {memUpdReqdetails ? (
              <TableWithLinkAndButton
                preview={memberCreatePreviewData()}
                data={tableData}
                passingId="memberId"
                actions={""}
                viewLink=""
                alignment="flex-start"
              />
            ) : (
              <p>No Member Details Changed....</p>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
export default UpdatedFieldTableForMem;
