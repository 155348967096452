import React from "react";
import { Typography } from "@mui/material";
import  ErrorMessage from '../../../../images/ErrorMessage.png';
const ChangePasswordGuidelines=()=>{
    return(<>
     <Typography variant="h6">CHANGE PASSWORD GUIDELINES </Typography>
<Typography variant="body1" align='justify'>
        <ol type="1">
            <li>
            New Password and Old Password cannot be same. If so error message pops up. (Figure 32)
            <p align="center"> <img  className="bordered-image" src={ErrorMessage} height="250px" width="75%"/><br />
           Figure 32: Error Message</p>
            </li>
            <li>
            For setting New Password, follow the password format described earlier in section 2.3.1.
            </li>
            <li>For any queries regarding OTP verification, refer to section 2.3.1 (steps 3 & 4).</li>
        </ol>
    
    </Typography>
    </>);
}
export default ChangePasswordGuidelines;