// utility methods
import numberToWords from "number-to-words";

export const trimStringUtility = (inputValue) => {
  /**
   * Remove white spaces from both end of string. DEFUALT .
   *
   * @param {string} inputValue - The input parameter.
   * @returns {string} - The formatted string with out spaces at end.
   */

  return inputValue.trim();
};

export const formPatternValidation = (input, pattern) => {
  /**
   * Remove white spaces from both end of string. DEFUALT .
   *
   * @param {string} input - The input parameter.
   * @param {string} pattern - pattern of the input field.
   *
   * @returns {bool} - true for success validation.
   */
  if (!pattern) {
    return true;
  }

  if (!new RegExp(pattern).test(input)) {
    return false;
  }
  return true;
};

// function to convert the date from time stamp to dd/mm/yyyy
export const dateConvert = (date) => {
  if (date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(date).toLocaleDateString("en-GB", options);

    return formattedDate;
  } else {
    return "";
  }
};
// function to get address concatinated
export function getAddress(member) {
  return `
    ${member.addressL1 ? member.addressL1 + "," : ""}
  
    ${member.addressL2 ? member.addressL2 + "," : ""}
  
    ${member.village ? member.village + "," : ""}
  
    ${member.city ? member.city + "," : ""}
  
    ${member.district ? member.district + "," : ""}
  
    ${member.state ? member.state + "," : ""}
  
    ${member.country ? member.country + "," : ""}
  
    ${member.pincode ? member.pincode : ""}
  `;
}

// function to calculate currency period ending year form the given date

export function calculateCurrencyEnding(dateOfCessation) {
  const yyyy = dateOfCessation.getFullYear();
  const fromDate = new Date(yyyy, 2, 31); // 2 corresponds to March (as month indices are zero-based)

  if (dateOfCessation > fromDate) {
    return `31/03/${yyyy}`;
  } else {
    return `31/03/${yyyy - 1}`;
  }
}

export function getFiscalYear(dateString) {
  // Parse the date string in YYYY-MM-DD format to a Date object
  const dateParts = dateString.split("-");
  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1; // JavaScript months are 0-11
  const day = parseInt(dateParts[2], 10);

  const date = new Date(year, month, day);

  let fiscalYearStart = year;
  let fiscalYearEnd = (year % 100) + 1; // Only taking last two digits of the year

  // If it's between January and March, decrement the start year
  if (month < 3) {
    fiscalYearStart--;
    fiscalYearEnd--;
  }

  return `${fiscalYearStart}-${fiscalYearEnd}`;
}

export function generateRandomString(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; // Define the characters to be used
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length); // Generate a random index
    result += characters.charAt(randomIndex); // Append the character at the random index to the result
  }
  return result;
}

/// to convert given amount in words
export const toWords = (number) => {
  const rupeesWords = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];

  const tensWords = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  if (number === 0) return "Zero";

  let words = "";
  if (number > 10000000) {
    const croreParts = Math.floor(number / 10000000);
    number %= 10000000;
    words += toWords(croreParts) + " Crore ";
  }

  if (number > 100000) {
    const lakhParts = Math.floor(number / 100000);
    number %= 100000;
    words += toWords(lakhParts) + " Lakh ";
  }

  if (number > 1000) {
    const thousandParts = Math.floor(number / 1000);
    number %= 1000;
    words += toWords(thousandParts) + " Thousand ";
  }

  if (number > 0) {
    if (words !== "") {
      words += " ";
    }

    if (number < 20) {
      words += rupeesWords[number];
    } else if (number < 100) {
      words +=
        tensWords[Math.floor(number / 10)] + " " + rupeesWords[number % 10];
    } else {
      words += rupeesWords[Math.floor(number / 100)] + " Hundred ";
      number %= 100;

      if (number > 0) {
        if (words !== "") {
          words += " ";
        }

        if (number < 20) {
          words += rupeesWords[number];
        } else if (number < 100) {
          words +=
            tensWords[Math.floor(number / 10)] + " " + rupeesWords[number % 10];
        }
      }
    }
  }

  return words.trim();
};
