import React from 'react';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';

function FormDivider() {
    const theme = useTheme();
  return (
      <Divider sx={{ backgroundColor: theme.palette.primary.main, height: '3px', margin: 3}} />
  );
}

export default FormDivider;
