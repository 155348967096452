import React, { useState, useEffect } from 'react';
import { MenuItem, FormControl, FormHelperText, InputLabel, Select, Box } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import { DisplaySettings } from '@mui/icons-material';





function CustomDropdown(props) {

  const { options, disabled, initialValue, onchange, label, helperText, name ,placeholder} = props;

  const [selectedValue, setSelectedValue] = useState(initialValue);


  useEffect(() => {
    setSelectedValue(initialValue);
  }, [initialValue]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onchange(event);
  };


  return (
    <FormControl fullWidth  >
        {/* <InputLabel shrink='false' id="demo-simple-select-standard-label">{label}</InputLabel> */}
        {selectedValue === '' && (
        <InputLabel htmlFor="demo-simple-select-standard" shrink={false}    
          >
          {placeholder}
       
        </InputLabel>
      )}
       
        <Select
         
          sx={{
          
          '& .MuiInputBase-input': {
            // fix based on your screen 
            fontSize: {
              xs: 12,
              sm: 12,
              md: 13,
              lg: 14,
              xl: 16,
            },
            padding: {
              xs: '15px 20px 15px 10px',
            //   sm: '8px 20px 10px 10px',
            //   md: '8px 20px 8px 10px',
            //   lg: '13px 25px 13px 15px',
            //   xl: '18px 20px 18px 20px',
            },
          },

            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#474E68', // Change this to your desired border color
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#6B728E', // Change this to your desired hover border color
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#0F3460', // Change this to your desired focused border color
            },
          }}
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard-label"
          name={name}
          value={selectedValue}
          onChange={handleChange}
          disabled={disabled}
         
        inputProps={{
          sx: {
            textAlign: 'left',
          },
        }}
        >
          {options.map((option) => ( 
            <MenuItem key={option.id} value={option.id}>
              {option.title}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    
  );
};

export default CustomDropdown;
