import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import Modal from "@mui/material/Modal";
import SubtitleText from "../../FormComponent/SubtitleText";
import MemberDetails from "../UDAPages/MemberDetatils";
import CaptionText from "../../FormComponent/CaptionText";
import BackButton from "../../components/button/BackButton";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ViewDocuments from "../../SettlementComponents/ViewDocuments";
import CustomTextArea from "../../FormComponent/CustomTextArea";
import LabelText from "../../FormComponent/LabelText";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import Reloader from "../../FormComponent/Reloader";
import LoadingIndicator from "../../FormComponent/LoadingIndicator";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import SucessAlert from "../../FormComponent/SuccessAlert";
import ConfirmationDialogue from "../../FormComponent/ConfirmationDialogue";
import ClaimDetailsPf from "../../SettlementComponents/ClaimDetailsPf";
import ClaimDetailsPen from "../../SettlementComponents/ClaimDetailsPen";
import jwtDecode from "jwt-decode";
import { getToken } from "../../UiManager/SessionChecker";

function ViewPfClaimSi() {
  const { state } = useLocation();
  const claimId = state.passingId;
  // const claimId = "PF1000003";
  const claimType = claimId.substring(0, 2);
  console.log("jai", claimType);

  const navigate = useNavigate();
  const sampleMemberData = {
    // This is the sample claimData data to be displed for demo purpose only
    title: "",
    fullName: "",
    dateOfBirth: "",
    panNo: "",
    aadhaarNumber: "",
    maritalStatus: "",
    religionName: "",
    gender: "",
    fatherHusbandName: "",
    email: "",
    mobileNo: "",
    landNo: "",
    addressL1: "",
    addressL2: "",
    village: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  };

  const sampleDocumentData = {
    data: {
      0: {
        DocTypeid: "",
        Document: "",
      },
      1: {
        DocTypeid: "",
        Document: "",
      },
    },
  };

  const sampleClaimPf = {
    claimDetails: [
      {
        claimId: "",
        fullName: "",
        cmpfAccNo: "",
        unitCode: "",
        submissionDate: "",
        remarks: "",
        nameAadhaar: "",
        nameBank: "",
        namePan: "",
        dobInAadhaar: "",
        dobInPan: "",
        sfwhName: "",
        sfwhRelationDesc: "",
        pfClaimType: "",
        pfClaimStatus: "",
        claimantId: "",
      },
    ],
    officerRemarks: [
      {
        remarks: "",
        status: "",
        fromLoginId: "",
      },
    ],
  };

  const [pfClaimData, setPfClaimData] = useState(sampleClaimPf);
  const [memberData, setMemberData] = useState(sampleMemberData);
  const [isMemberFound, setIsMemberFound] = useState(" ");
  const [documents, setDocuments] = useState([]);
  const [assignToOption, setAssignToOption] = useState([]);
  //**********for integration************* */
  const [isLoading, setIsLoading] = useState(true); // set this to true
  const [isLoadingDoc, setIsLoadingDoc] = useState(true); // set this to true
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isFailedDoc, setIsFailedDoc] = useState(false);
  const [isReturning, setIsReturning] = useState(false);
  const [isReturnClicked, setIsReturnClicked] = useState(false);
  const [isReturnClickedToRh, setIsReturnClickedToRh] = useState(false);
  const [boOfficerList, setBoOfficerList] = useState([]);
  const [transferToOption, setTransferToOption] = useState([]);
  const [rhToOption, setRhToOption] = useState([]);
  const [showingDropdown, setShowingDropdown] = useState(0);
  const [isTransfer, setIsTransfer] = useState(false);

  // const [remarks, setRemarks] = useState("");
  const defaultClaim = {
    remarks: "",
    returnToBo: "",
    returnToRh: "",
    transferTo: "",
    assignToBo: "",
    assignToRh: "",
  };

  // declaring the default api object

  let api = {
    claimUrl: "",
    docsUrl: "",
    SiDropdownUrl: "",
    BoDropdownUrl: "",
    RhDropdownUrl: "",
    assignToBoUrl: "",
    assignToRhUrl: "",
    transferToUrl: "",
    returnToBoUrl: "",
    returnToRhUrl: "",
  };
  // object for pfapis
  //let loginId = "SI-RO-OFF-513"; // Assuming a default loginId
  //let loginId = "SI-RO-OFF-513"; // Assuming a default loginId
  //let loginId = "SI-RO-OFF-504"; // Assuming a default loginId
  //  let token = localStorage.getItem("jwt");
  const [loginId, setLoginId] = useState();

  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (token) {
      token = token.replace('"', "").replace('"', "");
      let loginId = jwtDecode(token).user; // Assuming a default loginId
      setLoginId(loginId);
      console.log("loooooooooooooooooooooginId", loginId);
      // Add any further logic here based on the loginId
    } else {
      // Handle the case when token is not present
      // setIsFailed(true);
      // setIsLoading(false);
    }
  }, []);

  //  //  if (!token) {
  //  //    setIsFailed(true);
  //  //    setIsLoading(false);
  //  //    return;
  //  //  }

  //   token = token.replace('"', "").replace('"', "");
  // let loginId = jwtDecode(token).user; // Assuming a default loginId

  console.log("loooooooooooooooooooooginId", loginId);
  const pfApis = {
    claimUrl: "/show/pf/claim/details/to/SI/on/claimid",
    docsUrl: "/show/pf/claim/uploaded/documents/to/SI/on/claimid",
    SiDropdownUrl: "/get/allOtherSI/on/SI",
    BoDropdownUrl:
      "/dropdown/for/show/next/authorized/offcr/adv/pf/pen/si/to/bo",
    RhDropdownUrl: "/dropdown/for/show/next/authorized/offcr/adv/pf/pen/si",
    transferToUrl: "/transfer/to/different/SI",
    assignToBoUrl: "/verified/by/SI/to/BO",
    assignToRhUrl: "/verified/by/SI/to/RH",
    returnToBoUrl: "/pf/return/claims/si/to/bo",
    returnToRhUrl: "/pf/return/claims/si/to/rh",
  };

  const penApis = {
    claimUrl: "/show/pen/claim/on/claimid",
    docsUrl: "/show/submitted/documents/pen/claim",
    SiDropdownUrl: "/get/allOtherSI/on/SI",
    BoDropdownUrl:
      "/dropdown/for/show/next/authorized/offcr/adv/pf/pen/si/to/bo",
    RhDropdownUrl: "/dropdown/for/show/next/authorized/offcr/adv/pf/pen/si",
    transferToUrl: "/pension/claims/transfer/by/si/to/si",
    assignToBoUrl: "/pension/claims/assign/by/si/bo",
    assignToRhUrl: "/pension/claims/assign/by/si/rh",
    returnToBoUrl: "/pension/return/claims/si/to/bo",
    returnToRhUrl: "/pension/return/claims/si/to/rh",
  };
  let title;
  if (claimType === "PF") {
    api = { ...pfApis };
    title = "PF";
  } else if (claimType === "PN") {
    api = { ...penApis };
    title = "Pension";
  }

  const [claim, setClaim] = useState(defaultClaim);
  const assignToBo = {
    name: "assignToBo",
    placeholder: "Select BO to Assign",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "",
    required: true,
    options: boOfficerList,
  };

  const returnToBo = {
    name: "returnToBo",
    placeholder: "Select BO to Return",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "",
    required: true,
    options: boOfficerList,
  };

  const transferTo = {
    name: "transferTo",
    placeholder: "Select SI to Transfer",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "",
    required: true,
    options: transferToOption,
  };

  const assignToRh = {
    name: "assignToRh",
    placeholder: "Select RH to Assign",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "",
    required: true,
    options: rhToOption,
  };

  const returnToRh = {
    name: "returnToRh",
    placeholder: "Select RH to Return",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "",
    required: true,
    options: rhToOption,
  };

  const remarks = {
    name: "remarks",
    type: "text",
    multiline: true,
    helperText: "Enter your remarks (maximum 200 words)",
    placeholder: "Enter your remarks here",
    defaultValue: claim.remarks,
    errorMessage: "Cannot exceed 200 characters",
    pattern: "^.{0,200}$",
    maxLength: 200, // Limiting to 200
  };

  //********DEFAULT for family details*********/
  const familyDefault = {
    familyMemId: "",
    name: "",
    gender: "",
    relationName: "",
    dateOfBirth: "",
    pfNomSharePerc: "",
  };

  const [nomineeDetails, setNomineeDetails] = useState([familyDefault]);

  // ******** setting props for the TableWithLinkAndButton component*******

  // const viewLink = "/familydetails"; // Link for family members details

  // Advance claim type integration]

  //****************Dropdown for BO******************* */
  const fetchBoOfficer = async () => {
    try {
      setIsLoading(true);
      let token = getToken();
      console.log("token on fetchBoOfficer", token);
      if (!token) {
        setIsLoading(false);
        //setIsFailed(true);
        return;
      }
      token = token.replace('"', "").replace('"', "");
      const loginId = jwtDecode(token).user;
      console.log("loginId on fetchBoOfficer", loginId);
      const url = process.env.REACT_APP_SERVER_URL + api.BoDropdownUrl;
      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      const options = data.map((option) => ({
        id: option.toOfficerlogin,
        title: `${option.Name} (${option.toOfficerlogin})`,
      }));
      setBoOfficerList(options); // Assuming setAssignToOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchBoOfficer();
  }, [loginId]);

  //****************Dropdown for RH******************* */
  const fetchRhOfficer = async () => {
    try {
      setIsLoading(true);
      let token = getToken();
      console.log("token on rh off", token);
      if (!token) {
        setIsLoading(false);
        //setIsFailed(true);
        return;
      }
      token = token.replace('"', "").replace('"', "");
      const loginId = jwtDecode(token).user;
      console.log("loginId on rh off", loginId);
      const url = process.env.REACT_APP_SERVER_URL + api.RhDropdownUrl;
      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      const options = data.map((option) => ({
        id: option.toOfficerlogin,
        title: `${option.Name} (${option.toOfficerlogin})`,
      }));
      setRhToOption(options); // Assuming setRhToOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchRhOfficer();
  }, [loginId]);

  //****************Dropdown for SI ******************* */

  const transferOfficer = async () => {
    try {
      setIsLoading(true);
      let token = getToken();
      console.log("token on si", token);
      if (!token) {
        setIsLoading(false);
        //setIsFailed(true);
        return;
      }
      token = token.replace('"', "").replace('"', "");
      const loginId = jwtDecode(token).user;
      console.log("loginId on si", loginId);
      const url = process.env.REACT_APP_SERVER_URL + api.SiDropdownUrl;
      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      const options = data.map((option) => ({
        id: option.loginId,
        title: `${option.name} (${option.loginId})`,
      }));
      setTransferToOption(options); // Assuming setTransferToOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    transferOfficer();
  }, []);

  const fetchClaimDetailAdv = async () => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    // const url = process.env.REACT_APP_SERVER_URL + "/show/pf/claim/on/claimid";
    const url = process.env.REACT_APP_SERVER_URL + api.claimUrl;

    const formData = new FormData();
    formData.append("claimId", claimId);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          setPfClaimData(data);
          fetchMemberDetail(data.claimDetails[0].cmpfAccNo);
          fetchDocuments(data.claimDetails[0].claimantId);
          return;
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
      });
  };

  const fetchAllClaimDetail = () => {
    fetchClaimDetailAdv();
  };

  const fetchDocuments = async (claimantId) => {
    try {
      // const url = `${process.env.REACT_APP_SERVER_URL}/show/pf/claim/uploaded/documents/claimid`;
      const url = process.env.REACT_APP_SERVER_URL + api.docsUrl;

      const formData = new FormData();
      formData.append("claimId", claimId);
      formData.append("claimantId", claimantId);

      const config = {
        headers: {
          // Include the Authorization header if req uired
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(url, formData, config);

      setIsLoadingDoc(false);
      setIsFailedDoc(false);

      if (response.status === 200) {
        const data = response.data.data;
        setDocuments(data);
      } else {
        setIsFailed(true);
      }
    } catch (error) {
      // Corrected syntax for the catch block
      setIsLoading(false);
      setIsFailed(true);
    }
  };

  useEffect(() => {
    fetchAllClaimDetail();
    // fetchDocumnetAdv();
  }, []);

  //! adding DPO AND PO
  const [combinedData, setCombinedData] = useState([]);
  const { claimDetails } = pfClaimData;
  let draftPayOrder = claimDetails[0]?.draftPayOrder; // Use optional chaining here
  let payOrder = claimDetails[0]?.payOrder;
  // Combine data from the two APIs
  useEffect(() => {
    if (draftPayOrder) {
      const updatedCombinedData = [
        ...documents,
        {
          docTypeDesc: claimType === "PF" ? "DPO" : "PENSET",
          uploadedDoc: draftPayOrder,
        },
      ];

      // Assuming advClaimData.advClaim.purchaseOrder is the document from the second API
      if (payOrder) {
        updatedCombinedData.push({
          docTypeDesc: claimType === "PF" ? "PO" : "PPO",
          uploadedDoc: payOrder,
        });
      }

      setCombinedData(updatedCombinedData);
    }
  }, [documents, pfClaimData]);

  // cmpf account number is fetched on useEffect with a delay of 3sec to avoid multiple api hits
  const onChange = (e) => {
    setClaim({ ...claim, [e.target.name]: e.target.value });
  };

  /// ******** on assign to bo function
  let objectTobeSent = {};
  console.log("loginId on object to be sent", loginId);
  if (claimType === "PF") {
    objectTobeSent = {
      fromLoginId: loginId,
      remarks: claim.remarks,
      toLoginId: claim.assignToBo,
      claimId: claimId,
      approvalStatus: true,
    };
  } else if (claimType === "PN") {
    objectTobeSent = {
      claimId: claimId,
      fromOffcrLoginId: loginId,
      toOffcrLoginId: claim.assignToBo,
      remarks: claim.remarks,
    };
  }

  const handleForAssignToBo = (e) => {
    e.preventDefault();
    if (claimType === "PF") {
      if (!objectTobeSent.toLoginId) {
        // Show message in dialog: "Please select an officer to assign"
        //alert("Please select an officer to assign");
        setIsError(true);
        setErrorMessage("Please Select an Officer to Assign.");
        return;
      }
    } else if (claimType === "PN") {
      if (!objectTobeSent.toOffcrLoginId) {
        // Show message in dialog: "Please select an officer to assign"
        //alert("Please select an officer to assign");
        setIsError(true);
        setErrorMessage("Please Select an Officer to Assign.");
        return;
      }
    }
    setIsSubmitting(true);

    const url = process.env.REACT_APP_SERVER_URL + api.assignToBoUrl;
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSent, { headers })
      .then(function (response) {
        setIsSubmitting(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Forwarded Successfully.");
      })
      .catch((error) => {
        setIsError(true);
        setIsSubmitting(false);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
        console.log("error on submit", error);
      });
  };

  // ****************** assign to RH
  let objectTobeSentRh;
  if (claimType === "PF") {
    objectTobeSentRh = {
      fromLoginId: loginId,
      remarks: claim.remarks,
      toLoginId: claim.assignToRh,
      claimId: claimId,
      approvalStatus: true,
    };
  } else if (claimType === "PN") {
    objectTobeSentRh = {
      claimId: claimId,
      fromOffcrLoginId: loginId,
      toOffcrLoginId: claim.assignToRh,
      remarks: claim.remarks,
    };
  }

  const handleForAssignToRh = (e) => {
    e.preventDefault();

    if (claimType === "PF") {
      if (!objectTobeSentRh.toLoginId) {
        // Show message in dialog: "Please select an officer to assign"
        //alert("Please select an officer to assign");
        setIsError(true);
        setErrorMessage("Please Select an Officer to Assign.");
        return;
      }
    } else if (claimType === "PN") {
      if (!objectTobeSentRh.toOffcrLoginId) {
        // Show message in dialog: "Please select an officer to assign"
        //alert("Please select an officer to assign");
        setIsError(true);
        setErrorMessage("Please Select an Officer to Assign.");
        return;
      }
    }
    setIsSubmitting(true);

    const url = process.env.REACT_APP_SERVER_URL + api.assignToRhUrl;
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSentRh, { headers })
      .then(function (response) {
        setIsSubmitting(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Forwarded Successfully.");
      })
      .catch((error) => {
        setIsError(true);
        setIsSubmitting(false);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
        console.log("error on submit", error);
      });
  };

  /// ******** on Return to BO function
  let objectTobeSentOnReturn;
  if (claimType === "PF") {
    objectTobeSentOnReturn = {
      fromLoginId: loginId,
      remarks: claim.remarks,
      claimId: claimId,
      toLoginId: claim.returnToBo,
    };
  } else {
    objectTobeSentOnReturn = {
      fromOffcrLoginId: loginId,
      remarks: claim.remarks,
      claimId: claimId,
      toOffcrLoginId: claim.returnToBo,
    };
  }

  const handleForReturnToBo = (e) => {
    e.preventDefault();

    if (!claim.remarks.trim()) {
      // Show message in dialog: "Please enter remarks"
      // alert("Please enter remarks");
      setIsError(true);
      setErrorMessage("Please enter Remarks.");
      return;
    }

    if (!objectTobeSentOnReturn.toLoginId) {
      // Show message in dialog: "Please select an officer to assign"
      //alert("Please select an officer to assign");
      setIsError(true);
      setErrorMessage("Please Select an Officer to Return.");
      return;
    }

    setIsReturning(true);
    setIsReturnClicked(true);
  };

  const handleReturnClaim = (e) => {
    // e.preventDefault();

    const url = process.env.REACT_APP_SERVER_URL + api.returnToBoUrl;
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSentOnReturn, { headers })
      .then(function (response) {
        setIsReturning(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Returned Successfully.");

        //console.log("Claim Returned Successfully");
        setIsReturnClicked(false);
      })
      .catch((error) => {
        console.log("error on submit", error);
        setIsSubmitting(false);
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
      });
  };

  /// ******** on Return to RH function

  let objectTobeSentOnReturnToRh;
  if (claimType === "PF") {
    objectTobeSentOnReturnToRh = {
      fromLoginId: loginId,
      remarks: claim.remarks,
      claimId: claimId,
      toLoginId: claim.returnToRh,
    };
  } else {
    objectTobeSentOnReturnToRh = {
      fromOffcrLoginId: loginId,
      remarks: claim.remarks,
      claimId: claimId,
      toOffcrLoginId: claim.returnToRh,
    };
  }

  const handleForReturnToRh = (e) => {
    e.preventDefault();

    if (!claim.remarks.trim()) {
      // Show message in dialog: "Please enter remarks"
      // alert("Please enter remarks");
      setIsError(true);
      setErrorMessage("Please enter Remarks.");
      return;
    }
    if (claimType === "PF") {
      if (!objectTobeSentOnReturnToRh.toLoginId) {
        // Show message in dialog: "Please select an officer to assign"
        //alert("Please select an officer to assign");
        setIsError(true);
        setErrorMessage("Please Select an Officer to Return.");
        return;
      }
    } else if (claimType === "PN") {
      if (!objectTobeSentOnReturnToRh.toOffcrLoginId) {
        // Show message in dialog: "Please select an officer to assign"
        //alert("Please select an officer to assign");
        setIsError(true);
        setErrorMessage("Please Select an Officer to Return.");
        return;
      }
    }

    setIsReturning(true);
    setIsReturnClickedToRh(true);
  };

  const handleReturnClaimToRh = (e) => {
    // e.preventDefault();

    const url = process.env.REACT_APP_SERVER_URL + api.returnToRhUrl;
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSentOnReturnToRh, { headers })
      .then(function (response) {
        setIsReturning(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Returned Successfully.");

        //console.log("Claim Returned Successfully");
        setIsReturnClickedToRh(false);
      })
      .catch((error) => {
        console.log("error on submit", error);
        setIsError(true);
        setIsSubmitting(false);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
      });
  };

  /// ******** on transfer function
  let objectTobeTreansfer;
  if (claimType === "PF") {
    objectTobeTreansfer = {
      loginId: loginId,
      remarks: claim.remarks,
      claimId: claimId,
      toOffcrLoginId: claim.transferTo,
    }; //toOffcrLoginId
  } else {
    objectTobeTreansfer = {
      fromOffcrLoginId: loginId,
      remarks: claim.remarks,
      claimId: claimId,
      toOffcrLoginId: claim.transferTo,
    };
  }
  console.log("jai", claim.transferTo);
  const handleForTransferToSi = (e) => {
    e.preventDefault();
    if (!claim.remarks.trim()) {
      // Show message in dialog: "Please enter remarks"
      // alert("Please enter remarks");
      setIsError(true);
      setErrorMessage("Please enter Remarks.");
      return;
    }

    if (!claim.transferTo) {
      // Show message in dialog: "Please select an officer to assign"
      //alert("Please select an officer to assign");
      setIsError(true);
      setErrorMessage("Please Select an Officer to Transfer.");
      return;
    }
    setIsTransfer(true);

    const url = process.env.REACT_APP_SERVER_URL + api.transferToUrl;
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeTreansfer, { headers })
      .then(function (response) {
        setIsTransfer(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Transfer Successfully.");
      })
      .catch((error) => {
        setIsError(true);
        setIsSubmitting(false);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
        console.log("error on submit", error);
      });
  };

  ///****************integration started********************* */

  const fetchMemberDetail = async (accNo) => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + "/member/details/for/pf";
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          if (data.length !== 0) {
            setMemberData(data[0]);
            setIsMemberFound("true");
            fetchNomineeDetail(data[0].cmpfAccNo);
          }
          setIsMemberFound("false");
          return;
        }
        setIsFailed(true);
        setIsMemberFound("false");
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        setIsMemberFound("false");
      });
  };

  /***** function to get all nominee details**************** */
  const fetchNomineeDetail = async (accNo) => {
    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");

    const url = process.env.REACT_APP_SERVER_URL + "/get/all/nominee/details";
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo); // check what formdata is to be sent
    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsLoading(false);
        setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          if (data.length !== 0) {
            setNomineeDetails(data);
          }

          return;
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
      });
  };
  /// error alert handler
  const handleErrorAlert = () => {
    setIsError(false);
  };

  const handleSuccess = () => {
    if (claimType === "PF") {
      navigate("/si/pfset");
    } else if (claimType === "PN") {
      navigate("/si/penset");
    }
  };

  const handleCancelReturn = () => {
    setIsReturnClicked(false);
  };

  const handleCancelRhReturn = () => {
    setIsReturnClickedToRh(false);
  };

  const handleReturnOk = () => {
    handleReturnClaimToRh();
  };

  const handleReturnToBoOk = () => {
    handleReturnClaim();
  };

  // conditions fro display the dropdown
  const handleReturnToBo = (e) => {
    setShowingDropdown(1);
  };

  const handleReturnToRh = (e) => {
    setShowingDropdown(2);
  };

  const handleTransferToSi = (e) => {
    setShowingDropdown(3);
  };

  const handleAssignBo = (e) => {
    setShowingDropdown(4);
  };

  const handleAssignRh = (e) => {
    setShowingDropdown(5);
  };

  return (
    <div>
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error!"
        body={errorMessage}
        buttonName="Ok"
      />
      <SucessAlert
        handleOk={handleSuccess}
        open={isSuccess}
        title="Success"
        body={successMessage}
        buttonName="OK"
      />

      <ConfirmationDialogue
        handleOk={handleReturnOk}
        handleCancel={handleCancelRhReturn}
        open={isReturnClickedToRh}
        title="Confirm Return"
        body="Are you sure you want to return this claim ?"
        buttonName="Confirm"
      />
      <ConfirmationDialogue
        handleOk={handleReturnToBoOk}
        handleCancel={handleCancelReturn}
        open={isReturnClicked}
        title="Confirm Return"
        body="Are you sure you want to return this claim ?"
        buttonName="Confirm"
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "10px" }}>
            {isFailed || isFailedDoc ? (
              <Reloader refreshHandler={fetchAllClaimDetail} />
            ) : (
              <>
                {isLoading || isLoadingDoc ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        <TitleText title={`${title} Settlement `} />
                      </Grid>
                    </Grid>
                    <FormDivider />
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={1} mb={3}>
                        <BackButton />
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="start"
                        rowGap={3}
                      >
                        {/* Member Details*/}
                        {/* section 1  */}
                        <Paper
                          elevation={3}
                          sx={{ padding: "3rem", borderRadius: "10px" }}
                        >
                          <Grid item xs={12}>
                            <MemberDetails member={memberData} />
                          </Grid>
                        </Paper>
                        {/* claim Details*/}
                        {/* section 2  */}
                        <Paper
                          elevation={3}
                          sx={{ padding: "3rem", borderRadius: "10px" }}
                        >
                          <Grid item xs={12}>
                            <ClaimDetailsPf pfclaimdetails={pfClaimData} />
                          </Grid>
                        </Paper>
                        {/* Document Details*/}
                        {/* section 3  */}
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={12}>
                            <Paper
                              elevation={3}
                              sx={{ padding: "3rem", borderRadius: "10px" }}
                            >
                              <Grid item xs={12}>
                                {draftPayOrder ? (
                                  <ViewDocuments documents={combinedData} />
                                ) : (
                                  <ViewDocuments documents={documents} />
                                )}
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>

                        {/* ************************************ Remarks ***************************************** */}
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={12}>
                            <Paper
                              elevation={3}
                              sx={{ padding: "2rem", borderRadius: "10px" }}
                            >
                              <SubtitleText title="Remarks" />
                              <Grid item xs={12} sm={6} md={8}>
                                <CustomTextArea
                                  {...remarks}
                                  onchange={onChange}
                                />
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>

                        {/* *************** Submit Button ********* */}
                        <Grid container justifyContent="end">
                          <Grid item xs={12}>
                            <Grid
                              container
                              justifyContent="end"
                              alignItems="center"
                              columnGap={2}
                            >
                              <Grid item md={2}>
                                <Button
                                  onClick={handleReturnToBo}
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  // disabled={isReturnClicked}
                                  fullWidth
                                >
                                  Return to bo
                                </Button>
                              </Grid>
                              <Grid item md={2}>
                                <Button
                                  onClick={handleReturnToRh}
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  // disabled={isReturnClicked}
                                  fullWidth
                                >
                                  Return to rh
                                </Button>
                              </Grid>
                              <Grid item md={2}>
                                <Button
                                  onClick={handleTransferToSi}
                                  variant="contained"
                                  color="success"
                                  type="submit"
                                  // disabled={isReturnClicked}
                                  fullWidth
                                >
                                  Transfer to si
                                </Button>
                              </Grid>
                              <Grid item md={2}>
                                <Button
                                  onClick={handleAssignBo}
                                  variant="contained"
                                  color="success"
                                  type="submit"
                                  //style={{ marginLeft: 8 }}
                                  // disabled={isSubmitting}
                                  fullWidth
                                >
                                  Assign to bo
                                </Button>
                              </Grid>
                              <Grid item md={2}>
                                <Button
                                  onClick={handleAssignRh}
                                  variant="contained"
                                  color="success"
                                  type="submit"
                                  //style={{ marginLeft: 8 }}
                                  // disabled={isSubmitting}
                                  fullWidth
                                >
                                  Assign to rh
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        {showingDropdown === 1 && (
                          <Grid
                            container
                            justifyContent="end"
                            alignItems={"center"}
                            spacing={2}
                          >
                            <Grid item xs={12} sm={4}>
                              <CustomDropdown
                                {...returnToBo}
                                onchange={onChange}
                              />
                            </Grid>
                            <Grid item md={1}>
                              <Button
                                onClick={handleForReturnToBo}
                                variant="contained"
                                color="success"
                                type="submit"
                                disabled={isSubmitting}
                                fullWidth
                              >
                                {isSubmitting ? <CircularProgress /> : "OK"}
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                        {showingDropdown === 2 && (
                          <Grid
                            container
                            justifyContent="end"
                            alignItems={"center"}
                            spacing={2}
                          >
                            <Grid item xs={12} sm={4}>
                              <CustomDropdown
                                {...returnToRh}
                                onchange={onChange}
                              />
                            </Grid>
                            <Grid item md={1}>
                              <Button
                                onClick={handleForReturnToRh}
                                variant="contained"
                                color="success"
                                type="submit"
                                disabled={isSubmitting}
                                fullWidth
                              >
                                {isSubmitting ? <CircularProgress /> : "OK"}
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                        {showingDropdown === 3 && (
                          <Grid
                            container
                            justifyContent="end"
                            alignItems={"center"}
                            spacing={2}
                          >
                            <Grid item xs={12} sm={4}>
                              <CustomDropdown
                                {...transferTo}
                                onchange={onChange}
                              />
                            </Grid>
                            <Grid item md={1}>
                              <Button
                                onClick={handleForTransferToSi}
                                variant="contained"
                                color="success"
                                type="submit"
                                disabled={isSubmitting}
                                fullWidth
                              >
                                {isSubmitting ? <CircularProgress /> : "OK"}
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                        {showingDropdown === 4 && (
                          <Grid
                            container
                            justifyContent="end"
                            alignItems={"center"}
                            spacing={2}
                          >
                            <Grid item xs={12} sm={4}>
                              <CustomDropdown
                                {...assignToBo}
                                onchange={onChange}
                              />
                            </Grid>
                            <Grid item md={1}>
                              <Button
                                onClick={handleForAssignToBo}
                                variant="contained"
                                color="success"
                                type="submit"
                                disabled={isSubmitting}
                                fullWidth
                              >
                                {isSubmitting ? <CircularProgress /> : "OK"}
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                        {showingDropdown === 5 && (
                          <Grid
                            container
                            justifyContent="end"
                            alignItems={"center"}
                            spacing={2}
                          >
                            <Grid item xs={12} sm={4}>
                              <CustomDropdown
                                {...assignToRh}
                                onchange={onChange}
                              />
                            </Grid>
                            <Grid item md={1}>
                              <Button
                                onClick={handleForAssignToRh}
                                variant="contained"
                                color="success"
                                type="submit"
                                disabled={isSubmitting}
                                fullWidth
                              >
                                {isSubmitting ? <CircularProgress /> : "OK"}
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default ViewPfClaimSi;
