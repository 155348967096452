import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Autocomplete,
  TextField,
  CircularProgress,
  Button,
  Typography,
} from "@mui/material";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { UserContext } from "../../UiManager/UserProvider";
import { useContext } from "react";
import FormDivider from "../../FormComponent/FormDivider";
import TitleText from "../../FormComponent/TitleText";
import LabelText from "../../FormComponent/LabelText";
import AddTaskIcon from "@mui/icons-material/AddTask";
import ConfirmationDialogue from "../../FormComponent/ConfirmationDialogue";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import SucessAlert from "../../FormComponent/SuccessAlert";
import {
  faLessThanEqual,
  faListSquares,
} from "@fortawesome/free-solid-svg-icons";
import { validateSession } from "../../session/EventManager";

function AssignRoles() {
  function sleep(delay = 0) {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  }
  const [officerLoading, setOfficerLoading] = useState(true);
  const [officerList, setOfficerList] = useState([]);
  const [hasRole, setHasRole] = useState(false);
  const [alreadyAssignedRole, setAlreadyAssignedRole] = useState(false);
  const [isRoleAssigned, setIsRoleAssigned] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const defaultOfficer = {
    officerId: "",
    officerRole: "",
  };
  const [officer, setOfficer] = useState(defaultOfficer);

  const [designLoading, setDesignLoading] = useState(false);
  const [designList, setDesignList] = useState([]);

  const sampleOfficerData = [
    {
      officerId: "RO-OFF-101",
      name: "Amit Sharma",
    },
    {
      officerId: "RO-OFF-102",
      name: "Rahul Dutta",
    },
    {
      officerId: "RO-OFF-102",
      name: "Rama Singh",
    },
  ];
  const sampleRoles = [
    "Section Officer",
    "Branch Officer",
    "Unit Dealing Assistant",
    "Nodal Officer",
  ];

  // const rolesDropdown = {
  //   name: "officerRole",
  //   // label: "salutation",
  //   helperText: "",
  //   disabled: false,
  //   initialValue: "1",
  //   options: [
  //     { id: "1", title: "Section Officer" },
  //     { id: "2", title: "Branch Officer" },
  //   ],

  //  };
  const rolesDropdown = {
    name: "officerRole",
    // label: "salutation",
    helperText: "",
    disabled: false,
    initialValue: "",
    options: rolesList,
  };
  const getRoRoles = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + "/get/all/ro/roles";
      const response = await axios.get(url);
      console.log("rolist", response.data);
      return response.data;
    } catch (error) {
      console.log("error - roRolesError", error);
      return [];
    }
  };
  const getCCRoles = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + "/get/all/cc/roles";
      const response = await axios.get(url);
      //console.log("unitlist", response.data.data);
      return response.data;
    } catch (error) {
      console.log("error - ccRolesError", error);
      return [];
    }
  };

  const sessionExist = async () => {
    const status = await validateSession();
    console.log("session status", status);
    return status;
  };
  // const getOfficers = async () => {
  //   try {
  //     const url = process.env.REACT_APP_SERVER_URL + "/display/all/officer";
  //     // const headers = {
  //     //   Authorization: `Bearer ${token}`,
  //     //   "Content-Type": "application/x-www-form-urlencoded",
  //     // };

  //     const data = new URLSearchParams();
  //     data.append("loginId", "Artika");
  //     //data.append("loginId", jwtDecode(token).user);
  //     await sleep(1e3); // For demo purposes.

  //     axios.post(url, data).then((response) => {
  //       console.log("response", response);
  //       return response.data.data;
  //     });
  //   } catch (error) {
  //     console.log("error - unit", error);
  //     return [];
  //   }
  // };
  useEffect(() => {
    (async () => {
      setIsRefresh(true);

      if (!(await sessionExist())) {
        console.log("session extending having trouble");
        // setIsFailed({ status: true, method: fetchYear });
        setIsRefresh(false);
        return;
      }

      let token = localStorage.getItem("jwt");

      if (!token) {
        setIsRefresh(false);
        return;
      }
      token = token.replace('"', "").replace('"', "");

      const url = process.env.REACT_APP_SERVER_URL + "/display/all/officer";
      const headers = {
        Authorization: `Bearer ${token}`,

        "Content-Type": "application/x-www-form-urlencoded",
      };

      const data = new URLSearchParams();
      // data.append("loginId", "Vijay");
      data.append("loginId", jwtDecode(token).user);
      await sleep(1e3); // For demo purposes.

      axios.post(url, data, { headers }).then((response) => {
        console.log("response", response);
        const options = response.data.data.map((option) => {
          const firstID = option.name[0]; // Get the first letter
          // const unitName = option.split('/')[0]; // Extract the city name
          return {
            firstID: /[0-9]/.test(firstID) ? "0-9" : firstID,
            officerName: option.name,
            value: option.offcrId,
            empID: option.empId,
          };
        });
        setOfficerLoading(false);
        setOfficerList(options);
      });
      if (jwtDecode(token).role === "RH") {
        console.log("token in RH", jwtDecode(token).role);
        //call api for Ro roles
        const roRoles = await getRoRoles();
        console.log("roRoles", roRoles);
        const options = roRoles.map((option) => {
          const firstID = option.roleName[0];
          // console.log("option roles are here", option.roleName[0]);
          // Get the first letter
          // const unitName = option.split('/')[0]; // Extract the city name
          console.log("firstId in designation ", firstID);

          return {
            firstID: /[0-9]/.test(firstID) ? "0-9" : firstID,
            designName: option.roleName,
            value: option.roleId,
          };
        });
        console.log("desigList", options);

        setDesignLoading(false);
        setDesignList(options);
      } else if (
        jwtDecode(token).role === "CCH" ||
        jwtDecode(token).role === "AGM"
      ) {
        //call api for Ro roles
        const ccRoles = await getCCRoles();
        console.log("ccRoles", ccRoles);
        const options = ccRoles.map((option) => {
          const firstID = option.roleName[0]; // Get the first letter
          // const unitName = option.split('/')[0]; // Extract the city name
          console.log("firstId in designation ", firstID);

          return {
            firstID: /[0-9]/.test(firstID) ? "0-9" : firstID,
            designName: option.roleName,
            value: option.roleId,
          };
        });

        setDesignLoading(false);
        setDesignList(options);
      }
    })();
    // const officerSamples = await getOfficers();
    // console.log("officerSamples", officerSamples);

    // const options = sampleOfficerData.map((option) => {
    // const options = officerSamples.map((option) => {
    //   const firstID = option.name[0]; // Get the first letter
    //   // const unitName = option.split('/')[0]; // Extract the city name
    //   return {
    //     firstID: /[0-9]/.test(firstID) ? "0-9" : firstID,
    //     officerName: option.name,
    //     value: option.officerId,
    //   };
    // });

    //   setOfficerLoading(false);
    //   setOfficerList(options);
    // })();

    // return () => {
    //   setOfficerLoading(true);
    // };
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     //  await sleep(1e3); // For demo purposes.
  //     const url = process.env.REACT_APP_SERVER_URL + "/get/all/roles";

  //     axios.get(url).then((response) => {
  //       const officerRoleList = response.data;
  //       console.log("response in roles", response);
  //       const options = officerRoleList.map((option) => ({
  //         id: option.roleId,
  //         title: option.roleName,
  //       }));
  //       console.log("rolesList", options);
  //       setRolesList(options);
  //     });
  //   })();
  // }, []);
  const handleOfficerSearch = (event, newValue) => {
    setOfficer({
      ...officer,
      officerId: newValue.value ? newValue.value : "",
    });
    console.log("officerId", newValue);
  };
  const handleDesignSearch = (event, newValue) => {
    console.log("officer role", newValue);

    setOfficer({ ...officer, officerRole: newValue.value });
    // setSearchControl({ ...searchControl, "searchParam": newValue ? newValue.value : '' });
  };
  const onChange = (e) => {
    setOfficer({
      ...officer,
      [e.target.name]: e.target.value,
    });

    console.log("value changed", e.target.name, e.target.value);
    console.log("Officer object", officer);
  };
  const validate = () => {
    if (officer.officerId === "" || officer.officerRole === "") {
      setValidationError(true);
      console.log("in validation");
      return false;
    }
    return true;
  };
  const setOfficerRole = async () => {
    setIsRefresh(true);
    //setIsLoading(true);

    if (!(await sessionExist())) {
      console.log("session extending having trouble");
      // setIsFailed({ status: true, method: fetchYear });
      setIsRefresh(false);
      return;
    }

    let token = localStorage.getItem("jwt");

    if (!token) {
      setIsRefresh(false);
      return;
    }
    token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + "/set/officer/roles";
    const data = {
      roleId: officer.officerRole,
      assigned_date: "",
      ceasedDate: "",
      loginId: "",
      offcrId: officer.officerId,
      userId: "",
    };

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    axios
      .post(url, data, { headers })
      .then(function (response) {
        console.log("setOFficer response ", response);
        if (response.status === 200) {
          let responseData = response.data.data[0];
          console.log("response in officer ", responseData);
          setIsRoleAssigned(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("error on set OFficer", error);
        if (error.response.data.http_status_code === 406) {
          setAlreadyAssignedRole(true);
          setErrorMessage(error.response.data.error_message);
        } else {
          setAlreadyAssignedRole(true);
          setErrorMessage("Something went wrong");
        }
        setIsLoading(false);
      });
  };
  const handleAssign = async () => {
    if (!validate()) {
      setValidationError(true);
      return;
    }
    setIsLoading(true);
    setIsRefresh(true);

    if (!(await sessionExist())) {
      console.log("session extending having trouble");
      // setIsFailed({ status: true, method: fetchYear });
      setIsRefresh(false);
      return;
    }

    let token = localStorage.getItem("jwt");

    if (!token) {
      setIsRefresh(false);
      return;
    }
    token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + "/check/officer/role";
    const formData = new FormData();
    formData.append("officerId", officer.officerId);

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };

    axios
      .post(url, formData, { headers })
      .then(function (response) {
        console.log("response check officer", response);
        if (response.data.data.length === 0) {
          console.log("response data empty");
          setOfficerRole();
        } else {
          console.log("officer has roles");
          let rolesAssigned = "";

          const roles = response.data.data.map((item) => item.role);
          rolesAssigned += roles.join(", ");
          let rolesMsg = `The officer has already been assigned with roles: ${rolesAssigned}.      Do you still wish to continue? `;
          rolesAssigned += "Do you still wish to continue?";

          console.log(rolesAssigned);
          setErrorMessage(rolesMsg);
          setHasRole(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("eeeor", error);
        setAlreadyAssignedRole(true);
        setErrorMessage("Something went wrong");
      });
  };
  const handleOkAssign = () => {
    setHasRole(false);
    setOfficerRole();
    setIsLoading(true);
  };
  const handleOkAlert = () => {
    setAlreadyAssignedRole(false);
  };
  const handleCancel = () => {
    setHasRole(false);
    setIsLoading(false);
  };
  const handleSuccessOk = () => {
    setIsRoleAssigned(false);
  };
  const handleValidationErrorAlert = () => {
    setValidationError(false);
  };

  //code for the fetching officer name
  //code inserted by vidhuri here
  const userContext = useContext(UserContext);
  const fetchOfficerData = async (signal) => {
    // setIsLoading(true); isko define bhi krna hai
    if (!(await sessionExist())) {
      console.log("session extending having trouble");
      // setIsLoading(false);
      // setIsFailed(true);
      //inko bhi define krna hai
      return;
    }

    let token = localStorage.getItem("jwt");
    console.log("token", token);
    if (!token) {
      return;
    }
    token = token.replace('"', "").replace('"', "");
    console.log("tokentoken", token);
    console.log("jwtDecode(token).user", jwtDecode(token).user);
    const formData = new FormData();
    formData.append("loginId", jwtDecode(token).user);
    const url =
      process.env.REACT_APP_SERVER_URL + "/get/offcr/details/dashboard";

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      signal: signal,
    };
    try {
      const response = await axios.post(url, formData);
      const data = response.data.data[0];
      console.log("data hai ye mere wala", data.name);
      updateUser(data);

      // data.currPeriodEnd = new Date(data.currPeriodEnd).getFullYear();
      // setAccountSummary(data);
      // setIsLoading(false);
      // setIsFailed(false);
      console.log("Vidhuri JI", response.data.data);
    } catch {}
  };
  useEffect(() => {
    fetchOfficerData();
  }, []);

  function updateUser(data) {
    const currentUser = useContext.currentUser;
    userContext.updateUser({
      ...currentUser,
      name: `${data.name} (${data.shName}) `,
    });
  }

  return (
    <div>
      <ConfirmationDialogue
        handleOk={handleOkAssign}
        handleCancel={handleCancel}
        open={hasRole}
        title="Officer already has roles"
        body={errorMessage}
        buttonName="Continue"
      />
      <ErrorAlert
        handleOk={handleOkAlert}
        open={alreadyAssignedRole}
        title="Error"
        body={errorMessage}
        buttonName="Ok"
      />
      <ErrorAlert
        handleOk={handleValidationErrorAlert}
        open={validationError}
        title="Error"
        body="Please Select both the fields"
        buttonName="Ok"
      />
      <SucessAlert
        handleOk={handleSuccessOk}
        open={isRoleAssigned}
        title="Success"
        body="Role Assigned Successfully"
        buttonName="Ok"
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            <Grid item xs={12}>
              <TitleText title="Roles Assignment  " />
            </Grid>
            <FormDivider />
            {/* Form Wrapper grid */}
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              rowGap={2}
            >
              {/* Form Divider main Grid */}
              <Grid
                container
                justifyContent="space-between"
                alignItems="flex-start"
                rowGap={2}
              >
                {/* section 1 column 1 - select Officer*/}
                <Grid item xs={12} md={4}>
                  <Grid container justifyContent="space-between" rowGap={2}>
                    {/* row */}
                    <Grid item xs={12} md={3}>
                      <LabelText title="Select Officer" />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Autocomplete
                        onChange={handleOfficerSearch}
                        options={officerList.sort(
                          (a, b) => -b.firstID.localeCompare(a.firstID)
                        )}
                        groupBy={(officerList) => officerList.firstID}
                        getOptionLabel={(officerList) =>
                          `${officerList.officerName} (${officerList.empID})`
                        }
                        loading={officerLoading} // Set loading prop
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              "& .MuiFormHelperText-root": {
                                marginLeft: 0,
                                // fix based on your screen
                                fontSize: {
                                  xs: "9px",
                                  sm: "9px,",
                                  md: "9px",
                                  lg: "12px",
                                  xl: "14px",
                                },
                                fontWeight: "400",
                                color: "black",
                              },
                              "& .MuiInputBase-input": {
                                borderRadius: "5px",
                                color: "black",
                                background: "#ffffff",
                                // fix based on your screen
                                fontSize: {
                                  xs: 12,
                                  sm: 12,
                                  md: 13,
                                  lg: 14,
                                  xl: 16,
                                },
                              },
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "5px",
                                "& fieldset": {
                                  borderColor: "#474E68",
                                  // borderRadius:'0px'
                                },
                                "&:hover fieldset": {
                                  borderColor: "#6B728E",
                                  // borderRadius: '0px'
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#0F3460",
                                  // borderWidth: "0.5px",
                                  // borderRadius: '0px'
                                },
                              },
                            }}
                            autoComplete="off"
                            // size='small'

                            {...params}
                            placeholder="Search Officer"
                            // label="Unit No."

                            fullWidth
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {officerLoading && (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  )}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                            // helperText={<div style={{ height: "18px" }}></div>}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* section 1 column 2 - officer role*/}
                <Grid item xs={12} md={4}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    rowGap={2}
                  >
                    <Grid item xs={12} md={3}>
                      <LabelText title="Select Role" />
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Autocomplete
                        sx={{
                          minWidth: "150px",
                        }}
                        ListboxProps={{
                          style: {
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            maxHeight: 180, // Change this to your desired height
                          },
                        }}
                        // freeSolo
                        // inputValue={newValue.designName}
                        disableClearable
                        // onChange={(e, newValue) =>
                        //   handleDesignSearch(e, newValue)
                        // }
                        onChange={handleDesignSearch}
                        options={designList.sort(
                          (a, b) => -b.firstID.localeCompare(a.firstID)
                        )}
                        //groupBy={(designList) => designList.firstID}
                        getOptionLabel={(designList) => designList.designName}
                        //getOptionLabel={designList.roleName}
                        loading={designLoading} // Set loading prop
                        renderInput={(params) => (
                          <TextField
                            name="officerRole"
                            sx={{
                              "& .MuiFormHelperText-root": {
                                marginLeft: 0,
                                // fix based on your screen
                                fontSize: {
                                  xs: "9px",
                                  sm: "9px,",
                                  md: "9px",
                                  lg: "12px",
                                  xl: "14px",
                                },
                                fontWeight: "400",
                                color: "black",
                              },
                              "& .MuiInputBase-input": {
                                minWidth: "80px",
                                borderRadius: "5px",
                                color: "black",
                                background: "#ffffff",
                                // fix based on your screen
                                fontSize: {
                                  xs: 12,
                                  sm: 12,
                                  md: 13,
                                  lg: 14,
                                  xl: 16,
                                },
                              },
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "5px",
                                "& fieldset": {
                                  borderColor: "#474E68",
                                  // borderRadius:'0px'
                                },
                                "&:hover fieldset": {
                                  borderColor: "#6B728E",
                                  // borderRadius: '0px'
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#0F3460",
                                  borderWidth: "0.5px",
                                  // borderRadius: '0px'
                                },
                              },
                            }}
                            autoComplete="off"
                            // size='small'

                            {...params}
                            placeholder="Enter Role"
                            // label="Unit No."

                            fullWidth
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {designLoading && (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  )}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                            // helperText={
                            //   <div style={{ height: "18px" }}></div>
                            // }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* section 1 column 3 - Assign*/}
                <Grid item xs={12} md={3}>
                  <Grid
                    container
                    justifyContent="space-between"
                    e
                    alignItems="center"
                    rowGap={2}
                  >
                    {/* <Grid item xs={12} md={1}></Grid> */}
                    <Grid mt={1} marginLeft={2} item xs={12} md={7}>
                      <Button
                        //disabled={isSearching}
                        onClick={handleAssign}
                        variant="contained"
                        // startIcon={<AddTaskIcon />}
                        color="success"
                        disabled={isLoading}
                        fullWidth
                        type="submit"
                      >
                        {isLoading ? (
                          <CircularProgress color="secondary" />
                        ) : (
                          <>
                            <AddTaskIcon /> &nbsp; Assign
                            {/* Render the icon when isLoading is false */}
                          </>
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {/* 
                <Grid item xs={12} md={6}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    rowGap={2}
                  >
                    <Grid item xs={12} md={7}></Grid>
                    <Grid item xs={12} md={3}>
                      <Button
                        //disabled={isSearching}
                        onClick={handleAssign}
                        variant="contained"
                        startIcon={<AddTaskIcon />}
                        color="success"
                        // disabled={true}
                        fullWidth
                        type="submit"
                      >
                       
                        Assign
                      </Button>
                    </Grid>
                  </Grid>
                </Grid> */}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default AssignRoles;
