import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";
import TablePagination from "@mui/material/TablePagination";
import uuid from "react-uuid";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#526D82",
    color: theme.palette.common.white,
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.3,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    userSelect: "none", //disable text selection
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF9FF",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 1,
    backgroundColor: "#FFFFFF",
  },
}));

export default function FormTable(props) {
  const { titles, leftAlign, comma, data } = props;
  const [tableData, setTableData] = useState(data);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setTableData(data);
    setPage(0); // Reset page when the data changes
  }, [data]);

  const tableHeaders = Object.keys(tableData[0]);

  function createHeader() {
    return titles.map((title) => (
      <StyledTableCell key={uuid()} align="left">
        {title.replace(/([A-Z])/g, "$1")}
      </StyledTableCell>
    ));
  }

  function createRows() {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    return tableData.slice(startIndex, endIndex).map((row, index) => {
      return (
        <StyledTableRow key={index}>
          {tableHeaders.map((key) => (
            <StyledTableCell
              key={uuid()}
              align={leftAlign.includes(key) ? "left" : "right"}
            >
              {row[key] === null
                ? "-"
                : comma.includes(key)
                ? Number(row[key]).toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                  })
                : row[key]}
            </StyledTableCell>
          ))}
        </StyledTableRow>
      );
    });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>{createHeader()}</TableRow>
        </TableHead>
        <TableBody>{createRows()}</TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}
