import React from 'react'
import { Card, CardContent, CardMedia, Typography, } from '@mui/material';
import '../../styles/stylesheet.css'
import modi from '../../images/modi.jpg'
import joshis from '../../images/joshis.jpg'
import rao from '../../images/rao.jpg'

const LeaderBoard = () => {
    return (
        <div className='leader' id='leader-board'>
            <div className='codeblock'>
                <h1>PORTFOLIO</h1>
                <p className='ptag'>A good municipality keeps it’s people, stakeholders, employees and members of the <br /> public well informed.</p>

                <div className='cardblock'>
                    <Card sx={{ marginRight: "25px" }}>
                        <CardMedia
                            component="img"
                            height="250"
                            image={modi}
                            alt="Image 1"
                        />
                        <CardContent sx={{ height: '250px', padding: "20px", color: "white", backgroundColor: "black" }}>
                            <Typography variant="body2" sx={{ margin: "10px 0 " }}>
                                Hon'ble Prime Minister
                            </Typography>
                            <Typography variant="h4" component="div" sx={{ fontFamily: "Inter, Arial, sans-serif", fontWeight: "bold" }}>
                                Shri Narendra Modi
                            </Typography>
                           
                        </CardContent>
                    </Card>
                    <Card sx={{ marginRight: "25px" }} >
                        <CardMedia
                            component="img"
                            height="250"
                            image={joshis}
                            alt="Image 1"
                        />
                        <CardContent sx={{ height: '250px', padding: "20px", color: "white", backgroundColor: "black" }}>
                            <Typography variant="body2" sx={{ margin: "10px 0 " }}>
                                Minister of Coal, Mines & Parliamentary Affairs
                            </Typography>
                            <Typography variant="h4" component="div" sx={{ fontFamily: "Inter, Arial, sans-serif", fontWeight: "bold" }}>
                                Shri Pralhad Joshi
                            </Typography>
                           
                        </CardContent>
                    </Card>
                    <Card sx={{ marginRight: "25px" }}>
                        <CardMedia
                            component="img"
                            height="250"
                            image={rao}
                            alt="Image 1"
                        />
                        <CardContent sx={{ height: '250px', padding: "20px", color: "white", backgroundColor: "black" }}>
                            <Typography variant="body2" sx={{ margin: "10px 0 " }}>                            
                                Hon'ble MoS for Rail, Coal & Mines
                            </Typography>
                            <Typography variant="h4" component="div" sx={{ fontFamily: "Inter, Arial, sans-serif", fontWeight: "bold" }}>
                                Shri Raosaheb Patil Danve
                            </Typography>
                           
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default LeaderBoard