import React from "react";
import { Typography } from "@mui/material";
import  ForgotPassword from '../../../../images/ForgotPassword.png';
const PasswordGuidelines=()=>{
    return(<>
    <Typography variant="h6">FORGOT PASSWORD GUIDELINES</Typography>
    <Typography varient="body1" align="justify">
    If member forgets the password, it can be reset using forget password feature.


    <ol type="1">

        <li>
        New Password and Confirm Password should be same. If not error message is displayed (Figure 39).
        <p align="center"><img  className="bordered-image" src={ ForgotPassword} height="350px" width="70%"/><br />
            Figure 39: Forgot Password</p>
        </li>
        <li>
        For setting New Password, follow the password format described earlier in section 2.3.1.
       
        </li>
    </ol>
    </Typography>
    </>);
}
export default PasswordGuidelines;