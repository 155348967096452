import React,{useState, useEffect} from 'react';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(0),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 20px 10px 0px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
        },
    },
    '& select': {
        width: '100px',
        textAlign: 'left'
    },
   
}));





export default function CustomizedSelects(props) {


  
    const { options, disabled, initialValue, onchange, label, helperText, name } = props;

    const [selectedValue, setSelectedValue] = useState(initialValue);


    useEffect(() => {
        setSelectedValue(initialValue);
    }, [initialValue]);

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        onchange(event);
    };



    return (
        <div>    
            <FormControl  variant="standard">
                <NativeSelect
                    id="demo-customized-select-native"
                    name={name}
                    value={selectedValue}
                    onChange={handleChange}
                    disabled={disabled}
                    input={<BootstrapInput />}
                    inputProps={{
                        sx: {
                            textAlign: 'left',
                        },
                    }}
                >
                    {options.map((option) => (
                        <option key={option.id} value={option.id}> {option.title}</option>
                    ))}
                </NativeSelect>
            </FormControl>
        </div>
    );
}