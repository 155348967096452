import React from "react";
import { Typography } from "@mui/material";
import passwordMissmatch from '../../../../images/passwordMissmatch.png';
import invalidOtp from '../../../../images/invalidOtp.png';
import resendOtp from '../../../../images/resendOtp.png';
const MemberEnrollmentGuidelines=()=>{
    return(<>
    <Typography varient="h6"><b>MEMBER ENROLMENT GUIDELINES</b></Typography>
    <Typography variant="body1" align='justify'>
        <ol type="1">
        <li>For setting password, a standard format should be followed which mandates:<br />
        “Password must contain at least 8 characters and maximum of 20 characters, including uppercase, lowercase, number and special character except space.”
        </li>
        <li>New Password and Confirm Password should be same, or else “Passwords do not match” error is shown. (Figure 8)
        <p align="center"><img  className="bordered-image" src={passwordMissmatch} height="500px" width="75%"/></p>
            <p align="center">Figure 8: Password Mismatch</p>
            </li>
        <li>
        Member can make maximum of 3 attempts to enter OTP correctly, after which account gets blocked for 10 minutes. (Figure 9).
        <p align="center"><img  className="bordered-image" src={invalidOtp} height="350px" width="75%"/></p>
            <p align="center">Figure 9: Invalid OTP</p>

        </li>
        <li>If a member does not receive OTP, the member can request to resend OTP only two times. If after 2 attempts, Resend button is pressed, the account gets blocked for 10 minutes and no new OTP is sent. (Figure 10)
        <p align="center"> <img  className="bordered-image" src={ resendOtp} height="350px" width="75%"/></p>
            <p align="center">Figure 10: Resend OTP</p>

            </li></ol>
    </Typography>
    </>);
}
export default MemberEnrollmentGuidelines;