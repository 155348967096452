const AdvanceMinimumAmount = ({
  claimTypeNum,
  emplGrossAmount,
  saleAgreeAmount,
  reqAmount,
  estimatedCost,
  feesPaid,
  memberShare,
  employeeShare,
  totalClosingBalance,
}) => {
  // console.log("claimTypeNum", claimTypeNum);
  // console.log("emplGrossAmount", emplGrossAmount);
  // console.log("saleAgreeAmount", saleAgreeAmount);
  // console.log("reqAmount", reqAmount);
  // console.log("estimatedCost", estimatedCost);
  // console.log("feesPaid", feesPaid);
  // console.log("memberShare", memberShare);
  // console.log("employeeShare", employeeShare);
  // console.log("totalClosingBalance", totalClosingBalance);

  let totalShare;
  let totalEmoluments;
  let minimumadvpf1 = 0;

  if (claimTypeNum === 1 || claimTypeNum === 2) {
    totalShare = memberShare + 0.75 * employeeShare;
    totalEmoluments = emplGrossAmount; // Assuming this is correct
    minimumadvpf1 = Math.min(
      totalShare,
      totalEmoluments,
      saleAgreeAmount,
      reqAmount
    );
  } else if (claimTypeNum === 3) {
    totalShare = memberShare + 0.75 * employeeShare;
    totalEmoluments = emplGrossAmount; // Assuming this is correct
    minimumadvpf1 = Math.min(
      totalShare,
      totalEmoluments,
      estimatedCost,
      reqAmount
    );
  } else if (claimTypeNum === 5) {
    totalShare = memberShare;
    totalEmoluments = emplGrossAmount; // Assuming this is correct
    minimumadvpf1 = Math.min(
      totalShare,
      totalEmoluments / 6,
      estimatedCost,
      reqAmount
    );
  } else if (claimTypeNum === 6) {
    totalShare = memberShare * 0.5;
    minimumadvpf1 = Math.min(totalShare, reqAmount);
  } else if (claimTypeNum === 7) {
    totalShare = memberShare * 0.5;
    totalEmoluments = emplGrossAmount; // Assuming this is correct
    minimumadvpf1 = Math.min(
      totalShare,
      totalEmoluments / 12,
      feesPaid,
      reqAmount
    );
  } else if (claimTypeNum === 8) {
    minimumadvpf1 = totalClosingBalance;
  }

  return minimumadvpf1;
};

export default AdvanceMinimumAmount;
