import React from "react";
import { Typography } from "@mui/material";
import PFStatementImage from '../../../../images/PFStatementImage.png';
import DetailedPF from '../../../../images/DetailedPF.png';
const PFStatementFirst=()=>{
    return(<>
    <Typography variant="h6">PF STATEMENT</Typography>
<Typography variant="body1" align='justify'>
To view the PF Statement, member clicks on PF Statement tab on the side app bar. The member is redirected to the PF Statement page. By default last 10 transactions are displayed (Figure 22). To view more transactions, member can enter start and end year and click on View Statement, as shown in (figure 23.) 


<p align="center"> <img  className="bordered-image" src={PFStatementImage} height="75%" width="90%"/><br />
            Figure 22: PF Statement</p>


            <p align="center"> <img  className="bordered-image" src={DetailedPF} height="75%" width="90%"/><br />
            Figure 23: Detailed PF</p>


    
    </Typography>
    </>);
}
export default PFStatementFirst;