import { Alert, Button, CircularProgress, Grid, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import SubtitleText from "../../FormComponent/SubtitleText";
import MemberDetails from "../UDAPages/MemberDetatils";
import BackButton from "../../components/button/BackButton";
import axios from "axios";
import AdvClaimDetails from "../../SettlementComponents/AdvClaimDetails";
import { useLocation, useNavigate } from "react-router-dom";
import ViewDocuments from "../../SettlementComponents/ViewDocuments";
import CustomTextArea from "../../FormComponent/CustomTextArea";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import LoadingIndicator from "../../FormComponent/LoadingIndicator";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import SucessAlert from "../../FormComponent/SuccessAlert";
import ConfirmationDialogue from "../../FormComponent/ConfirmationDialogue";
import TableWithLinkAndButton from "../../FormComponent/TableWithLinkAndButton";
import jwtDecode from "jwt-decode";
import AdvanceCalculation from "../RoDaPages/AdvanceCalculation";
import AdvanceMinimumAmount from "../RoDaPages/AdvanceMinimumAmount";

function ViewAdvClaimRoda() {
  const { state } = useLocation();
  // let loginId = "DA-RO-OFF-376"; //! Assuming a default loginId
  const [loginId, setLoginId] = useState();

  ///********fetching and setting token********* */
  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (token) {
      token = token.replace('"', "").replace('"', "");
      let loginId = jwtDecode(token).user; // Assuming a default loginId
      setLoginId(loginId);
      // console.log("loooooooooooooooooooooginId", loginId);
      // Add any further logic here based on the loginId
    } else {
      // Handle the case when token is not present
      setIsFailed(true);
      setIsLoading(false);
    }
  }, []);
  const claimId = state.passingId;
  const dpoPdf = state.pdf;

  const claimType = claimId.substring(0, 2);
  const navigate = useNavigate();
  const sampleMemberData = {
    // This is the sample claimData data to be displed for demo purpose only
    title: "",
    fullName: "",
    dateOfBirth: "",
    panNo: "",
    aadhaarNumber: "",
    maritalStatus: "",
    religionName: "",
    gender: "",
    fatherHusbandName: "",
    email: "",
    mobileNo: "",
    landNo: "",
    addressL1: "",
    addressL2: "",
    village: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  };

  const sampleClaimData = {
    advClaim: {
      advClaimSrNo: "",
      claimId: "",
      memberId: "",
      udaLoginId: "",
      nameAadhaar: "",
      namePan: "",
      nameBank: "",
      nmAadhaarMatch: "",
      nmPanMatch: "",
      nmBankMatch: "",
      dobAadhaar: "",
      dobPan: "",
      sfwhName: "",
      sfwhRelationType: "",
      advClaimTypId: "",
      declarationUda: "",
      aaoConfrmPayment: "",
      dateReceived: "",
      advClaimStatusId: "",
      rodaLoginId: "",
      emolumentsLast36m: "",
      ledgerBalance: "",
      memShare: "",
      employerShare: "",
      amountApplied: "",
      amountAllowed: "",
      correspondanceAddress: "",
      periodOfMembship: "",
      poNo: "",
      settlementDate: "",
      ledgerBalDate: "",
      draftPayOrder: "",
      payOrder: "",
      dpoDate: "",
      advReqdDate: "",
      poDated: "",
    },
    tAdvSpecificDetails: {
      advSpecificDetSrno: "",
      advClaimSrno: "",
      amtPaid: "",
      sanctionedAmtDet: "",
      estimatedCost: "",
      saleAgreeAmt: "",
      locationDet: "",
      anticptdDateOfMarriage: "",
      examPassed: "",
      examYear: "",
      feesPaid: "",
      dependentId: "",
      siteMeasurementUnit: "",
      siteMeasurementVal: "",
      plotNo: "",
      subPlotNo: "",
    },
    claimType: "",
    paraNumber: "",
    formattedRemarks: [
      {
        remark: "",
        status: "",
      },
    ],
    cmpfAcNo: "",
  };

  const [advClaimData, setAdvClaimData] = useState(sampleClaimData);
  const [memberData, setMemberData] = useState(sampleMemberData);
  const [isMemberFound, setIsMemberFound] = useState(" ");
  const [documents, setDocuments] = useState([]);

  const [assignToOption, setAssignToOption] = useState([]);
  //**********for integration************* */
  const [isLoading, setIsLoading] = useState(false); // set this to tru
  const [isLoadingDoc, setIsLoadingDoc] = useState(false); // set this to true
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isFailedDoc, setIsFailedDoc] = useState(false);
  const [isReturning, setIsReturning] = useState(false);
  const [isReturnClicked, setIsReturnClicked] = useState(false);
  const [isRevertClicked, setIsRevertClicked] = useState(false);
  const [showingDropdown, setShowingDropdown] = useState(0);
  const [selectedOfficerName, setSelectedOfficerName] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [combinedData, setCombinedData] = useState([]);
  const [cmpfAccNum, setCmpfAccNum] = useState();
  const [lazerData, setLazerData] = useState();

  const defaultClaim = {
    remarks: "",
    assignTo: "",
  };
  const [claim, setClaim] = useState(defaultClaim);

  // we have set a value beforeids = 0 for show some field till ids
  let beforeids = 1;
  // declaring the default api object

  let api = {
    claimUrl: "",
    docsUrl: "",
    returnUrl: "",
    forwardUrl: "",
    forwordAgmUrl: "",
    officerDropdownUrl: "",
    agmDropdown: "",
    memberDetailsUrl: "",
    familyMemDetails: "",
    dpoUrl: "",
    calculationUrl: "",
    ldgerUrl: "",
  };
  // object for pfapis

  const advApis = {
    claimUrl: "/fetch/advance/pf/claim/details/by/id",
    docsUrl: "/fetch/advance/pf/claim/Documents/by/id",
    returnUrl: "/return/adv/pf/claim/roda/to/si",
    revertUrl: "/revert/adv/pf/claim/to/ids",
    forwardUrl: "/forward/advance/pf/claim/RODA/to/SI",
    officerDropdownUrl:
      "/dropdown/for/show/next/authorized/offcr/adv/pf/pen/roda",
    dpoUrl: "/upload/dpo/pdf",
    calculationUrl: "/update/ledger/details/for/advance/in/tadv/claim",
    ldgerUrl: "/get/pf/ledger/details",
    memberDetailsUrl: "/member/details/for/pf",
    familyMemDetails: "/show/all/existing/family/member",
  };

  if (claimType === "AD") {
    api = { ...advApis };
  }

  const assignTo = {
    name: "assignTo",
    placeholder: "Select Officer ",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "",
    required: true,
    options: assignToOption,
  };

  const remarks = {
    name: "remarks",
    type: "text",
    multiline: true,
    helperText: "Enter your remarks (maximum 200 words)",
    placeholder: "Enter your remarks here",
    defaultValue: claim.remarks,
    errorMessage: "Cannot exceed 200 characters",
    pattern: "^.{0,200}$",
    maxLength: 200, // Limiting to 200
  };

  //********DEFAULT for family details*********/
  const familyDefault = {
    familyMemId: "",
    name: "",
    gender: "",
    relationName: "",
    dateOfBirth: "",
    pfNomSharePerc: "",
  };

  const [nomineeDetails, setNomineeDetails] = useState([familyDefault]);

  const viewLink = "familydetails"; // Link for family members details

  //****************Dropdown******************* */
  const fetchAssignOfficer = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + api.officerDropdownUrl;
      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      const options = data.map((option) => ({
        id: option.OffcrId,
        title: `${option.Name} (${option.OffcrId})`,
      }));
      setAssignToOption(options); // Assuming setAssignToOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchAssignOfficer();
  }, [loginId]);

  const fetchClaimDetailAdv = async () => {
    // setIsLoading(true);// uncomment

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + api.claimUrl;
    const formData = new FormData();
    formData.append("claimId", claimId);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          setAdvClaimData(data);
          fetchMemberDetail(data.cmpfAcNo);
          setCmpfAccNum(data.cmpfAcNo);
          fetchLazerData(data.cmpfAcNo);
          fetchDocuments();

          return;
        }
        // setIsFailed(true); uncomment this
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true); //uncomment this
      });
  };

  const fetchAllClaimDetail = () => {
    fetchClaimDetailAdv();
  };

  const fetchDocuments = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + api.docsUrl;
      const formData = new FormData();
      formData.append("claimId", claimId);

      const config = {
        headers: {
          // Include the Authorization header if required
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(url, formData, config);

      setIsLoadingDoc(false);
      setIsFailedDoc(false);

      if (response.status === 200) {
        const data = response.data.data;
        setDocuments(data.data);
      } else {
        // setIsFailed(true); // uncomment this
      }
    } catch (error) {
      // Corrected syntax for the catch block
      setIsLoading(false);
      //setIsFailed(true); // uncomment this
    }
  };

  const fetchLazerData = async (accNo) => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + "/get/pf/ledger/details";
      const formData = new FormData();
      formData.append("cmpfAcNO", accNo);
      const config = {
        headers: {
          // Include the Authorization header if req uired
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      setLazerData(data); // Set the fetched data to the tablePreview state // we are taking data[0] as the response recevied is an array of object and we need only the first element
      //   setIsLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    fetchAllClaimDetail();
  }, []);

  const { advClaim } = advClaimData;
  let draftPayOrder = advClaim?.draftPayOrder; // Use optional chaining here
  let payOrder = advClaim?.payOrder;
  // Combine data from the two APIs
  useEffect(() => {
    if (draftPayOrder) {
      const updatedCombinedData = [
        ...documents,
        {
          docTypeDesc: "DPO",
          uploadedDoc: draftPayOrder,
        },
      ];

      // Assuming advClaimData.advClaim.purchaseOrder is the document from the second API
      if (payOrder) {
        updatedCombinedData.push({
          docTypeDesc: "PO",
          uploadedDoc: payOrder,
        });
      }

      setCombinedData(updatedCombinedData);
    }
  }, [documents, advClaimData]);

  ///**************************CALCULATION**************************** */
  // let count = 0;
  // // count = Object.keys(lazerData).length;
  // let closingBalance = 0;
  // let ledgerBalDate;
  // if (count > 0) {
  //   closingBalance = lazerData[count].closingBal;
  //   ledgerBalDate = lazerData[count].currPeriodEnd;
  // } else {
  //   setIsError(true);
  //   setErrorMessage("No ledger data found. Please update ledger.");
  // }

  const [claimTypeNum, setClaimTypeNum] = useState("");
  const [emplGrossAmount, setEmplGrossAmount] = useState("");
  const [saleAgreeAmount, setSaleAgreeAmount] = useState("");
  const [reqAmount, setReqAmount] = useState("");
  const [estimatedCost, setEstimatedCost] = useState("");
  const [feesPaid, setFeesPaid] = useState("");

  useEffect(() => {
    // Assuming the rest of the code properly initializes advClaimData
    if (advClaimData) {
      setClaimTypeNum(advClaimData.advClaim.advClaimTypId);
      setEmplGrossAmount(advClaimData.advClaim.emolumentsLast36m);
      setSaleAgreeAmount(advClaimData.tAdvSpecificDetails.saleAgreeAmt);
      setReqAmount(advClaimData.advClaim.amountApplied);
      setEstimatedCost(advClaimData.tAdvSpecificDetails.estimatedCost);
      setFeesPaid(advClaimData.tAdvSpecificDetails.feesPaid);
    }
  }, [advClaimData]);

  // Assuming AdvanceCalculation is a function that returns an object with memberShare, employeeShare, and totalClosingBalance
  const { memberShare, employeeShare, totalClosingBalance } =
    AdvanceCalculation(cmpfAccNum);

  const minimumadvpf1 = AdvanceMinimumAmount({
    claimTypeNum,
    emplGrossAmount,
    saleAgreeAmount,
    reqAmount,
    estimatedCost,
    feesPaid,
    memberShare,
    employeeShare,
    totalClosingBalance,
  });
  // Update advClaimData with memberShare
  let count = 0;
  if (lazerData) {
    count = lazerData.length;
  }
  let closingBalance;
  let ledgerBalDate;
  if (count > 0) {
    // const row = findRow(array, 29);
    const lastRow = lazerData[lazerData.length - 1];
    console.log("lastRow", lastRow);
    closingBalance = lastRow.closingBal;
    ledgerBalDate = lastRow.currPeriodEnd;
  }
  console.log("closingBalance", closingBalance);
  if (
    advClaimData &&
    advClaimData.advClaim.memShare !== Math.floor(memberShare)
  ) {
    setAdvClaimData((prevState) => ({
      ...prevState,
      advClaim: {
        ...prevState.advClaim,
        memShare: Math.floor(memberShare),
        employerShare: Math.floor(employeeShare),
        amountAllowed: Math.floor(minimumadvpf1),
        ledgerBalance: closingBalance,
      },
    }));
  }

  //! cmpf account number is fetched on useEffect with a delay of 3sec to avoid multiple api hits
  const onChange = (e) => {
    if (e.target.name === "assignTo") {
      const selectedOption = assignToOption.find(
        (option) => option.id === e.target.value
      );
      if (selectedOption) {
        setSelectedOfficerName(selectedOption.title); // Set the selected officer's name in the state
      }
    }
    setClaim({ ...claim, [e.target.name]: e.target.value });
  };

  /// ******** on submit function
  const objectTobeSent = {
    fromOffcrLoginId: loginId,
    toOffcrId: claim.assignTo,
    claimId: claimId,
    Remarks: claim.remarks,
  };

  const dpoTobeSent = {
    draftPayOrder: dpoPdf,
    claimId: claimId,
  };

  useEffect(() => {
    if (dpoTobeSent.draftPayOrder) {
      <Alert severity="success">This is a success Alert.</Alert>;
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 10000); // Dismiss after 10 seconds
    }
  }, []);

  const handleSubmitOk = () => {
    if (showingDropdown === 1) {
      if (!dpoTobeSent.draftPayOrder) {
        // Show message in dialog: "Please select an officer to assign"
        //alert("Please select an officer to assign");
        setIsError(true);
        setErrorMessage("DPO is not generated.    ");
        return;
      }
      if (!objectTobeSent.toOffcrId) {
        // Show message in dialog: "Please select an officer to assign"
        // alert("Please select an officer to assign");
        setIsError(true);
        setErrorMessage("Please Select an Officer to Assign.");
      } else {
        setIsSubmitClicked(true);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // if (!objectTobeSent.toOffcrId) {
    //   // Show message in dialog: "Please select an officer to assign"
    //   //alert("Please select an officer to assign");
    //   setIsError(true);
    //   setErrorMessage("Please Select an Officer to Assign.");
    //   return;
    // }
    // if (!dpoTobeSent.draftPayOrder) {
    //   // Show message in dialog: "Please select an officer to assign"
    //   //alert("Please select an officer to assign");
    //   setIsError(true);
    //   setErrorMessage("DPO is not generated.    ");
    //   return;
    // }
    setIsSubmitting(true);

    const url = process.env.REACT_APP_SERVER_URL + api.forwardUrl;
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSent, { headers })
      .then(function (response) {
        setIsSubmitting(false);
        setIsSuccess(true);
        handleSubmitDpo();
        handleSubmitCalaculation();
        setSuccessMessage("Claim Forwarded Successfully.");
      })
      .catch((error) => {
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
        console.log("error on submit", error);
      });
  };

  const handleSubmitDpo = async () => {
    const url = process.env.REACT_APP_SERVER_URL + api.dpoUrl;
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, dpoTobeSent, { headers })
      .then(function (response) {
        setIsSubmitting(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Forwarded Successfully.");
      })
      .catch((error) => {
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
        console.log("error on submit", error);
      });
  };

  const calculationTobeSent = {
    ledgerBalance: closingBalance,
    memShare: memberShare,
    employerShare: employeeShare,
    amountApplied: advClaimData.advClaim.amountApplied,
    amountAllowed: minimumadvpf1,
    claimId: claimId,
    advClaimTypeId: claimTypeNum,
    memberId: advClaimData.advClaim.memberId,
    ledgerBalDate: ledgerBalDate,
  };

  console.log("calculationTobeSent", calculationTobeSent);
  const handleSubmitCalaculation = async () => {
    const url = process.env.REACT_APP_SERVER_URL + api.calculationUrl;
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, calculationTobeSent, { headers })
      .then(function (response) {
        setIsSubmitting(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Forwarded Successfully.");
      })
      .catch((error) => {
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
        console.log("error on submit", error);
      });
  };

  /// ******** on Return function

  const objectTobeSentOnReturn = {
    fromOffcrLoginId: loginId,
    Remarks: claim.remarks,
    toOffcrId: claim.assignTo,
    ClaimId: claimId,
  };

  const handleReturnToSi = () => {
    if (!claim.remarks.trim()) {
      // Show message in dialog: "Please enter remarks"
      // alert("Please enter remarks");
      setIsError(true);
      setErrorMessage("Please enter Remarks.");
      return;
    }

    if (!objectTobeSentOnReturn.toOffcrId) {
      // Show message in dialog: "Please select an officer to assign"
      //alert("Please select an officer to assign");
      setIsError(true);
      setErrorMessage("Please Select an Officer to Return.");
      return;
    }
    setIsReturnClicked(true);
  };

  // const handleReturnClick = (e) => {
  //   e.preventDefault();

  //   setIsReturning(true);
  // };
  const handleReturnClaim = (e) => {
    // e.preventDefault();
    setIsReturning(true);
    const url = process.env.REACT_APP_SERVER_URL + api.returnUrl;
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSentOnReturn, { headers })
      .then(function (response) {
        setIsReturning(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Returned Successfully.");

        setIsReturnClicked(false);
      })
      .catch((error) => {
        console.log("error on submit", error);
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
      });
  };

  // todo handleAssignToOk calling handle submit on click of ok confirmation dialouge box
  const handleReturnToOk = (e) => {
    handleReturnClaim(e);
    setIsReturnClicked(false);
  };

  const handleReturnToCancel = () => {
    setIsReturnClicked(false);
  };

  /// ******** on Revert function

  const objectTobeSentOnRevert = {
    loginId: loginId,
    remark: claim.remarks,
    claimId: claimId,
  };
  const handleRevertClick = (e) => {
    e.preventDefault();

    if (!claim.remarks.trim()) {
      // Show message in dialog: "Please enter remarks"
      // alert("Please enter remarks");
      setIsError(true);
      setErrorMessage("Please enter Remarks.");
      return;
    }
    // setIsRevert(true);

    setIsRevertClicked(true);
  };

  const handleRevertClaim = (e) => {
    // e.preventDefault();

    const url = process.env.REACT_APP_SERVER_URL + api.revertUrl;
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    axios
      .post(url, objectTobeSentOnRevert, { headers })
      .then(function (response) {
        setIsReturning(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Revert Successfully.");

        setIsRevertClicked(false);
      })
      .catch((error) => {
        console.log("error on revert", error);
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
      });
  };

  ///****************integration started********************* */

  const fetchMemberDetail = async (accNo) => {
    // setIsLoading(true); // uncomment

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + api.memberDetailsUrl;
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          if (data.length !== 0) {
            setMemberData(data[0]);
            setIsMemberFound("true");
            fetchNomineeDetail(data[0].cmpfAccNo);
          }
          setIsMemberFound("false");
          return;
        }
        setIsFailed(true);
        setIsMemberFound("false");
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        setIsMemberFound("false");
      });
  };

  /***** function to get all nominee details**************** */
  const fetchNomineeDetail = async (accNo) => {
    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");

    const url = process.env.REACT_APP_SERVER_URL + api.familyMemDetails;
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo); // check what formdata is to be sent
    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsLoading(false);
        setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;

          if (data.length !== 0) {
            setNomineeDetails(data);
          }

          return;
        }
        //setIsFailed(true); //uncomment this
      })
      .catch(function (error) {
        setIsLoading(false);
        // setIsFailed(true); // uncomment this
      });
  };

  //******************************************************************************************* */

  /// error alert handler
  const handleErrorAlert = () => {
    setIsError(false);
  };
  const handleErrorA = () => {
    navigate("/roda/advset");
  };
  const handleSuccess = () => {
    // setIsSuccess(false);
    navigate("/roda/advset");
  };

  const handleRevertOk = () => {
    handleRevertClaim();
  };
  const handleCancelRevert = () => {
    setIsRevertClicked(false);
  };

  const handleAssign = (e) => {
    setShowingDropdown(1);
  };

  const handleReturn = (e) => {
    setShowingDropdown(2);
  };

  const maritalStatus = [
    { id: "M", title: "Married" },
    { id: "U", title: "Unmarried" },
    { id: "W", title: "Widow/Widower" },
    { id: "D", title: "Divorced" },
  ];

  const gender = [
    { id: "M", title: "Male" },
    { id: "F", title: "Female" },
    { id: "O", title: "Other" },
  ];

  const createTablePreviewDataReturn = () => {
    console.log("from preview", nomineeDetails);
    // the functions that creates a table preview
    //const tableSamples = familyMemberSamples; //familyMemberSamples must be replaced witht he response data recevied on the api request
    const tableSamples = nomineeDetails;
    let tablePreviewData = [];
    tableSamples.map((member) => {
      tablePreviewData.push({
        "Family Member ID": member.familyMemId,
        "Name of Nominee": member.name,
        Gender: gender.find((item) => item.id === member.gender)?.title,
        Relation: member.relationName,
        "Date of Birth": member.dateOfBirth,
        "Marital Status": maritalStatus.find(
          (item) => item.id === member.maritalStatus
        )?.title,
      });
    });
    return tablePreviewData;
  };

  // function on previewDpo
  let advClaimTypId = advClaimData.advClaim.advClaimTypId;
  const handlePreviewDpo = () => {
    if (advClaimTypId == 6) {
      navigate("advmarrigedpo", { state: { advClaimData, memberData } });
    } else {
      navigate("advdpo", { state: { advClaimData, memberData } });
    }
  };

  const handleAssignToOk = (e) => {
    if (showingDropdown === 1) {
      handleSubmit(e);
    }

    setIsSubmitClicked(false);
  };
  const handleAssignToCancel = () => {
    setIsSubmitClicked(false);
  };

  return (
    <div>
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error!"
        body={errorMessage}
        buttonName="Ok"
      />
      <ErrorAlert
        handleOk={handleErrorA}
        open={isAlert}
        title="Alert!"
        body={alertMessage}
        buttonName="Ok"
      />
      <SucessAlert
        handleOk={handleSuccess}
        open={isSuccess}
        title="Success"
        body={successMessage}
        buttonName="OK"
      />
      <ConfirmationDialogue
        handleOk={handleReturnToOk}
        handleCancel={handleReturnToCancel}
        open={isReturnClicked}
        title="Confirm Return"
        body={`Are you sure you want to return the claim to ${selectedOfficerName} ?`}
        buttonName="Confirm"
      />
      <ConfirmationDialogue
        handleOk={handleAssignToOk}
        handleCancel={handleAssignToCancel}
        open={isSubmitClicked}
        title="Confirm Assignment"
        body={`Are you sure you want to assign the claim to ${selectedOfficerName} ?`}
        buttonName="Confirm"
      />
      <ConfirmationDialogue
        handleOk={handleRevertOk}
        handleCancel={handleCancelRevert}
        open={isRevertClicked}
        title="Confirm Return"
        body="Are you sure you want to revert this claim ?"
        buttonName="Confirm"
      />
      {showSuccessMessage && (
        <SucessAlert
          handleOk={() => setShowSuccessMessage(false)}
          open={showSuccessMessage}
          title="Success"
          body="Draft Pay Order is generated successfully."
          buttonName="OK"
        />
      )}

      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "10px" }}>
            {/* {isFailed || isFailedDoc ? (
              <Reloader refreshHandler={fetchAllClaimDetail} />
            ) : ( */}
            <>
              {isLoading || isLoadingDoc ? (
                <LoadingIndicator />
              ) : (
                <>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item xs={12}>
                      <TitleText title="Advance PF Claim" />
                    </Grid>
                  </Grid>
                  <FormDivider />
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={1} mb={3}>
                      <BackButton />
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="start"
                      rowGap={3}>
                      {/* Member Details*/}
                      {/* section 1  */}
                      <Paper
                        elevation={3}
                        sx={{ padding: "3rem", borderRadius: "10px" }}>
                        <Grid item xs={12}>
                          <MemberDetails member={memberData} />
                        </Grid>
                        <Grid item xs={12}>
                          <TableWithLinkAndButton
                            preview={createTablePreviewDataReturn()}
                            data={nomineeDetails}
                            passingId="familyMemberid"
                            actions=""
                            viewLink={viewLink}
                          />
                        </Grid>
                      </Paper>
                      {/* claim Details*/}
                      {/* section 2  */}
                      <Paper
                        elevation={3}
                        sx={{ padding: "3rem", borderRadius: "10px" }}>
                        <Grid item xs={12}>
                          <AdvClaimDetails
                            advclaimdet={advClaimData}
                            beforeids={beforeids}
                          />
                        </Grid>
                      </Paper>
                      {/* Document Details*/}
                      {/* section 3  */}
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={12}>
                          <Paper
                            elevation={3}
                            sx={{ padding: "3rem", borderRadius: "10px" }}>
                            <Grid item xs={12}>
                              {draftPayOrder ? (
                                <ViewDocuments documents={combinedData} />
                              ) : (
                                <ViewDocuments documents={documents} />
                              )}
                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid>

                      {/* ************************************ Remarks ***************************************** */}
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={12}>
                          <Paper
                            elevation={3}
                            sx={{ padding: "2rem", borderRadius: "10px" }}>
                            <SubtitleText title="Remarks" />
                            <Grid item xs={12} sm={6} md={8}>
                              <CustomTextArea
                                {...remarks}
                                onchange={onChange}
                              />
                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid>
                      {/* *************** Submit Button ********* */}
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems={"center"}>
                        <Grid item md={3}>
                          <Grid container justifyContent="start">
                            <Grid
                              item
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}>
                              <Button
                                onClick={handlePreviewDpo}
                                variant="contained"
                                color="success"
                                type="submit"
                                style={{ marginLeft: 8 }}
                                disabled={isSubmitting}>
                                Preview DPO
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* *************** Submit Button ********* */}
                        <Grid item md={6}>
                          <Grid
                            container
                            justifyContent="end"
                            alignItems="center"
                            columnGap={1}>
                            <Grid item md={12}>
                              <Grid
                                container
                                justifyContent="end"
                                alignItems="center"
                                columnGap={1}>
                                <Grid item md={2}>
                                  <Button
                                    onClick={handleRevertClick}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isRevertClicked}
                                    fullWidth>
                                    {isRevertClicked ? (
                                      <CircularProgress />
                                    ) : (
                                      "Revert"
                                    )}
                                  </Button>
                                </Grid>
                                <Grid item md={2}>
                                  <Button
                                    onClick={handleReturn}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isReturnClicked}
                                    fullWidth>
                                    {/* {isReturnClicked ? (
                                      <CircularProgress />
                                    ) : ( */}
                                    Return
                                    {/* )} */}
                                  </Button>
                                </Grid>
                                <Grid item md={2}>
                                  <Button
                                    onClick={handleAssign}
                                    variant="contained"
                                    color="success"
                                    type="submit"
                                    //style={{ marginLeft: 8 }}
                                    // disabled={isSubmitting}
                                    fullWidth>
                                    Assign
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {showingDropdown === 1 && (
                        <Grid container justifyContent="end" spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <CustomDropdown {...assignTo} onchange={onChange} />
                          </Grid>
                          <Grid item md={1}>
                            <Button
                              onClick={handleSubmitOk}
                              variant="contained"
                              color="success"
                              type="submit"
                              disabled={isSubmitting}
                              fullWidth>
                              {isSubmitting ? <CircularProgress /> : "OK"}
                            </Button>
                          </Grid>
                        </Grid>
                      )}

                      {showingDropdown === 2 && (
                        <Grid container justifyContent="end" spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <CustomDropdown {...assignTo} onchange={onChange} />
                          </Grid>
                          <Grid item md={1}>
                            <Button
                              onClick={handleReturnToSi}
                              variant="contained"
                              color="success"
                              type="submit"
                              disabled={isReturnClicked}
                              fullWidth>
                              {isReturnClicked ? <CircularProgress /> : "OK"}
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </>
            {/* )} */}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default ViewAdvClaimRoda;
