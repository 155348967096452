import React, { useState, useEffect } from "react";
import {
  Grid,
  Autocomplete,
  CircularProgress,
  Paper,
  IconButton,
  Button,
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import TitleText from "../../FormComponent/TitleText";
import LabelText from "../../FormComponent/LabelText";
import GroupedRadioButton from "../../FormComponent/GroupedRadioButton";
import CustomTextField from "../../FormComponent/CustomTextField";
import FormTable from "../../FormComponent/FormTable";
import FormDivider from "../../FormComponent/FormDivider";
import YearDropdown from "../../FormComponent/YearDropdown";
import LoadingIndicator from "../../FormComponent/LoadingIndicator";
import axios from "axios";
import jwtDecode from "jwt-decode";
import AmountFormatter from "../../FormComponent/AccountFormatter";
import Reloader from "../../FormComponent/Reloader";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import { validateSession } from "../../session/EventManager";
import ValueText from "../../FormComponent/ValueText";
import CaptionText from "../../FormComponent/CaptionText";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomTextArea from "../../FormComponent/CustomTextArea";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import SucessAlert from "../../FormComponent/SuccessAlert";
import ConfirmationDialogue from "../../FormComponent/ConfirmationDialogue";
import "../../App.css";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function DynamicTable(props) {
  const defaultTableFormRow = {
    from: "",
    to: "",
    unit: "",
    addedUnit: "",
    ro: "",
    remarks: "",
  };

  const [rows, setRows] = useState([defaultTableFormRow]);

  const [unitNoLoading, setunitNoLoading] = useState(true);
  const [unitNumberList, setUnitNumberList] = useState([]);
  const [roLoading, setRoLoading] = useState(true);
  const [roList, setRoList] = useState([]);
  const [consentInvalid, setConsentInvalid] = useState(false);
  const [consentInvalidMessage, setConsentInvalidMessage] = useState("");
  //const [formVisible, setFormVisible] = useState(true);

  const from = {
    name: "from",
    type: "date",
    placeholder: "from",
    errorMessage: "",
    pattern: "",
    required: false,
    disabled: false,
  };

  const to = {
    name: "to",
    type: "date",
    placeholder: "to",
    errorMessage: "",
    pattern: "",
    required: false,
    disabled: false,
  };

  // const unit = {
  //     name: "unit",
  //     type: "text",
  //     placeholder: "Unit Id",
  //     errorMessage:
  //         "",
  //     pattern: "",
  //     required: false,
  //     disabled: false,
  // };

  const ro = {
    name: "ro",
    type: "text",
    placeholder: "Regional Office Name",
    errorMessage: "",
    pattern: "",
    required: false,
    disabled: false,
  };
  const remarks = {
    name: "remarks",
    placeholder: "Should not exceed ",

    errorMessage: "",
    // label: "Message",
    pattern: "",
    multiline: true,
    minrows: 4,
    required: false,
    disabled: false,
  };

  const checkForEmptyFields = (index) => {
    if (
      !rows[index].from ||
      !rows[index].to ||
      !rows[index].unit ||
      !rows[index].ro
    ) {
      console.log("emptyStatus true");
      return true;
    } else {
      return false;
    }
  };

  const checkForInvalidDateRange = (index) => {
    if (rows[index].from > rows[index].to) {
      return true;
    } else return false;
  };

  const checkForUnitCode = (index) => {
    if (!rows[index].unit) {
      return true;
    } else if (!/^[A-Za-z]{3,4}\/\d{1,4}$/.test(rows[index].unit)) {
      return true;
    } else return false;
  };

  const consentValidate = (index) => {
    console.log("dates", rows[index].from, rows[index].to);

    if (checkForEmptyFields(index)) {
      console.log("unitcode", rows[index].unit);
      setConsentInvalid(true);
      setConsentInvalidMessage("Please fill all the required fields");

      //alert("Please fill all the required fields");

      return false;
    } else if (checkForInvalidDateRange(index)) {
      setConsentInvalid(true);
      setConsentInvalidMessage("Invalid Date Range");
      //alert("from date greater than to date");
      console.log("from date greater than to date");
      return false;
      // } else if (checkForUnitCode(index)) {
      //   setConsentInvalid(true);
      //   setConsentInvalidMessage("Please Enter Valid Unit Code");
      //   return false;
    }

    return true;
  };

  const handleInvalidConsentAlert = () => {
    setConsentInvalid(false);
  };

  const handleAddRow = () => {
    if (!consentValidate(rows.length - 1)) {
      return;
    }
    setRows([...rows, defaultTableFormRow]);

    console.log("rows", rows);
  };

  const handleDeleteRow = (index) => {
    console.log("deleted index", index);
    if (rows.length !== 1) {
      const updatedRows = [...rows];
      updatedRows.splice(index, 1);

      console.log("updated row", updatedRows);
      setRows(updatedRows);
      props.consentData(updatedRows);
    }
  };

  const handleTextChange = (index, e) => {
    const updatedRows = [...rows];
    updatedRows[index][e.target.name] = e.target.value;
    setRows(updatedRows);
    props.consentData(updatedRows);
  };

  const handleUnitNoSearchFreeSolo = (index, e) => {
    const updatedRows = [...rows];

    updatedRows[index]["unit"] = e.target.value.toUpperCase();

    setRows(updatedRows);
    props.consentData(updatedRows);
  };

  // const handlePrint = (e) => {
  //     console.log("row data", rows);
  // }
  // const getUnit = () => {
  //   const url = process.env.REACT_APP_SERVER_URL + "/allunits";
  //   axios
  //     .get(url)
  //     .then((response) => {
  //       console.log("unitlist", response.data.data);
  //       return response.data.data; // as a list only
  //     })
  //     .catch((error) => {
  //       console.log("error - unit", error);
  //       return [];
  //     });
  // };

  const getUnit = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + "/allunits";
      const response = await axios.get(url);
      //console.log("unitlist", response.data.data);
      return response.data.data;
    } catch (error) {
      console.log("error - unit", error);
      return [];
    }
  };

  const getRo = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + "/allro";
      const response = await axios.get(url);
      //console.log("rolist", response.data.data);
      return response.data.data;
    } catch (error) {
      console.log("error - ro", error);
      return [];
    }
  };
  // const getRo = () => {
  //   const url = process.env.REACT_APP_SERVER_URL + "/allro";
  //   axios
  //     .get(url)
  //     .then((response) => {
  //       console.log("rolist", response.data.data);
  //       return response.data.data; // this should be a list
  //     })
  //     .catch((error) => {
  //       console.log("error -ro list", error);
  //       return [];
  //     });
  // };

  // const fetchUnitNo = async () => {
  //   (async () => {
  //await sleep(1e3); // For demo purposes.

  // const unitNumberListSamples = await getUnit();
  // console.log("unitListSample", unitNumberListSamples);

  // const unitNumberListSamples = [
  //   "HYD/12",
  //   "HYD/15",
  //   "NGP/12",
  //   "KJP/12",
  //   "KJD",
  //   "HGD",
  //   "JJU",
  //   "GSD",
  //   "GGD",
  // ];

  //     const options = unitNumberListSamples.map((option) => {
  //       const firstID = option[0]; // Get the first letter
  //       // const unitName = option.split('/')[0]; // Extract the city name
  //       return {
  //         firstID: /[0-9]/.test(firstID) ? "0-9" : firstID,
  //         unitName: option,
  //         value: option,
  //       };
  //     });

  //     setunitNoLoading(false);
  //     setUnitNumberList(options);
  //   })();
  // };
  ////// parsing unitcode from the unitcode-unitname dropdown value ////
  function parseunit(unitCombined) {
    const [unitCode, unitName] = unitCombined.split("-");
    console.log(unitCode);
    return unitCode;
  }
  /////////////
  const createUnitList = async () => {
    try {
      const data = await getUnit();
      //console.log(data);
      const concatenatedArray = data.map(
        (item) => `${item.unitCode}-${item.unitName}`
      );
      //console.log(concatenatedArray);
      return concatenatedArray;
    } catch (error) {}
  };

  const fetchUnitNo = async () => {
    try {
      const unitNumberListSamples = await createUnitList();
      //console.log("unitListSample", unitNumberListSamples);

      const options = unitNumberListSamples.map((option) => {
        //const firstID = option.match(/[0-9]/) ? "0-9" : option.charAt(0); // Get the first letter
        const firstID = option[0];

        return {
          firstID: /[0-9]/.test(firstID) ? "0-9" : firstID,
          unitName: option,
          value: option,
        };
      });

      setunitNoLoading(false);
      setUnitNumberList(options);
    } catch (error) {
      // Handle error if needed.
    }
  };

  // const fetchRo = () => {
  //   (async () => {
  //     // await sleep(1e3); // For demo purposes.
  //     // getRo();

  //     const roListSamples = await getRo();
  //     // const roListSamples = [
  //     //   "Bilaspur",
  //     //   "Nagpur",
  //     //   "Dhanbad",
  //     //   "Sigraulli",
  //     //   "Asansol",
  //     // ];

  //     const options = roListSamples.map((option) => {
  //       const firstID = option[0]; // Get the first letter
  //       // const unitName = option.split('/')[0]; // Extract the city name
  //       return {
  //         firstID: /[0-9]/.test(firstID) ? "0-9" : firstID,
  //         roName: option,
  //         value: option,
  //       };
  //     });

  //     setRoLoading(false);
  //     setRoList(options);
  //   })();
  // };

  const fetchRo = async () => {
    try {
      // await sleep(1e3); // For demo purposes.
      // getRo();

      const roListSamples = await getRo();
      //console.log("roListSample", roListSamples);
      // const roListSamples = [
      //   "Bilaspur",
      //   "Nagpur",
      //   "Dhanbad",
      //   "Sigraulli",
      //   "Asansol",
      // ];

      const options = roListSamples
        .filter((option) => typeof option === "string")
        .map((option) => {
          const firstID = option[0]; // Get the first letter
          // const unitName = option.split('/')[0]; // Extract the city name
          return {
            firstID: /[0-9]/.test(firstID) ? "0-9" : firstID,
            roName: option,
            value: option,
          };
        });

      setRoLoading(false);
      setRoList(options);
    } catch (error) {
      // catch error if any
    }
  };

  // unitNo serach
  useEffect(() => {
    fetchUnitNo();
    fetchRo();
  }, []);

  const handleUnitNoSearch = (index, event, newValue) => {
    console.log("search index", index, newValue.value);
    const updatedRows = [...rows];

    updatedRows[index]["unit"] = newValue ? newValue.value : "";

    setRows(updatedRows);
    props.consentData(updatedRows);
    // setSearchControl({ ...searchControl, "searchParam": newValue ? newValue.value : '' });
  };

  const handleRoSearch = (index, event, newValue) => {
    console.log("search index", index, newValue.value);
    const updatedRows = [...rows];
    updatedRows[index]["ro"] = newValue ? newValue.value : "";
    setRows(updatedRows);
    props.consentData(updatedRows);
    // setSearchControl({ ...searchControl, "searchParam": newValue ? newValue.value : '' });
  };

  return (
    <div>
      <ErrorAlert
        handleOk={handleInvalidConsentAlert}
        open={consentInvalid}
        title="Error!"
        body={consentInvalidMessage}
        buttonName="Ok"
      />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                From Date<span className="red-asterisk">*</span>
              </TableCell>
              <TableCell>
                To Date<span className="red-asterisk">*</span>
              </TableCell>
              <TableCell>
                Unit Code-Unit Name<span className="red-asterisk">*</span>
              </TableCell>
              <TableCell>
                Regional Office<span className="red-asterisk">*</span>
              </TableCell>
              <TableCell>Remarks</TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleAddRow}
                >
                  Add Row
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <CustomTextField
                    {...from}
                    defaultValue={row.from}
                    onchange={(e) => handleTextChange(index, e)}
                    helperText={<div style={{ height: "18px" }}></div>}
                  />
                </TableCell>
                <TableCell>
                  <CustomTextField
                    {...to}
                    defaultValue={row.to}
                    onchange={(e) => handleTextChange(index, e)}
                    helperText={<div style={{ height: "18px" }}></div>}
                  />
                </TableCell>
                <TableCell>
                  <Autocomplete
                    sx={{
                      minWidth: "160px",
                    }}
                    ListboxProps={{
                      style: {
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        maxHeight: 180, // Change this to your desired height
                        width: 180,
                        fontSize: 12,
                      },
                    }}
                    //freeSolo
                    inputValue={row.unit}
                    disableClearable
                    onChange={(e, newValue) =>
                      handleUnitNoSearch(index, e, newValue)
                    }
                    options={unitNumberList.sort(
                      (a, b) => -b.firstID.localeCompare(a.firstID)
                    )}
                    groupBy={(unitNumberList) => unitNumberList.firstID}
                    getOptionLabel={(unitNumberList) => unitNumberList.unitName}
                    loading={unitNoLoading} // Set loading prop
                    renderInput={(params) => (
                      <TextField
                        name="unit"
                        onChange={(e) => handleUnitNoSearchFreeSolo(index, e)}
                        sx={{
                          "& .MuiFormHelperText-root": {
                            marginLeft: 0,
                            // fix based on your screen
                            fontSize: {
                              xs: "9px",
                              sm: "9px,",
                              md: "9px",
                              lg: "12px",
                              xl: "14px",
                            },
                            fontWeight: "400",
                            color: "black",
                          },
                          "& .MuiInputBase-input": {
                            minWidth: "80px",
                            borderRadius: "5px",
                            color: "black",
                            background: "#ffffff",
                            // fix based on your screen
                            fontSize: {
                              xs: 12,
                              sm: 12,
                              md: 13,
                              lg: 14,
                              xl: 16,
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "5px",
                            "& fieldset": {
                              borderColor: "#474E68",
                              // borderRadius:'0px'
                            },
                            "&:hover fieldset": {
                              borderColor: "#6B728E",
                              // borderRadius: '0px'
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#0F3460",
                              borderWidth: "0.5px",
                              // borderRadius: '0px'
                            },
                          },
                        }}
                        autoComplete="off"
                        // size='small'

                        {...params}
                        placeholder="Unit Code-Unit Name"
                        // label="Unit No."

                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {unitNoLoading && (
                                <CircularProgress color="inherit" size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        // error={
                        //   !/^[A-Za-z]{3,4}\/\d{1,4}$/.test(
                        //     params.inputProps.value
                        //   ) && params.inputProps.value !== ""
                        // }
                        helperText={
                          <div style={{ height: "18px" }}>
                            {/* {!/^[A-Za-z]{3,4}\/\d{1,4}$/.test(
                              params.inputProps.value
                            ) && params.inputProps.value !== ""
                              ? "Invalid format"
                              : ""} */}
                          </div>
                        }
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  {/* <CustomTextField {...ro} defaultValue={row.ro} onchange={(e) => handleTextChange(index, e)} /> */}
                  <Autocomplete
                    sx={{
                      minWidth: "150px",
                    }}
                    ListboxProps={{
                      style: {
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        maxHeight: 180, // Change this to your desired height
                      },
                    }}
                    // freeSolo
                    inputValue={row.ro}
                    disableClearable
                    onChange={(e, newValue) =>
                      handleRoSearch(index, e, newValue)
                    }
                    options={roList.sort(
                      (a, b) => -b.firstID.localeCompare(a.firstID)
                    )}
                    groupBy={(roList) => roList.firstID}
                    getOptionLabel={(roList) => roList.roName}
                    loading={roLoading} // Set loading prop
                    renderInput={(params) => (
                      <TextField
                        name="ro"
                        // onChange={(e) => handleUnitNoSearchFreeSolo(index, e)}
                        sx={{
                          "& .MuiFormHelperText-root": {
                            marginLeft: 0,
                            // fix based on your screen
                            fontSize: {
                              xs: "9px",
                              sm: "9px,",
                              md: "9px",
                              lg: "12px",
                              xl: "14px",
                            },
                            fontWeight: "400",
                            color: "black",
                          },
                          "& .MuiInputBase-input": {
                            minWidth: "80px",
                            borderRadius: "5px",
                            color: "black",
                            background: "#ffffff",
                            // fix based on your screen
                            fontSize: {
                              xs: 12,
                              sm: 12,
                              md: 13,
                              lg: 14,
                              xl: 16,
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "5px",
                            "& fieldset": {
                              borderColor: "#474E68",
                              // borderRadius:'0px'
                            },
                            "&:hover fieldset": {
                              borderColor: "#6B728E",
                              // borderRadius: '0px'
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#0F3460",
                              borderWidth: "0.5px",
                              // borderRadius: '0px'
                            },
                          },
                        }}
                        autoComplete="off"
                        // size='small'

                        {...params}
                        placeholder="Enter RO Name."
                        // label="Unit No."

                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {roLoading && (
                                <CircularProgress color="inherit" size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        helperText={<div style={{ height: "18px" }}></div>}
                      />
                    )}
                  />
                </TableCell>

                <TableCell>
                  <CustomTextArea
                    {...remarks}
                    helperText={<div style={{ height: "18px" }}></div>}
                    defaultValue={row.remarks}
                    placeholder="Add Remarks "
                    onchange={(e) => handleTextChange(index, e)}
                  />
                </TableCell>

                <TableCell>
                  {rows.length !== 1 && (
                    <IconButton
                      aria-label="delete"
                      size="small"
                      color="warning"
                      onClick={() => {
                        handleDeleteRow(index);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Button variant="contained" color="primary" onClick={handlePrint}>
                print 
            </Button> */}
    </div>
  );
}

function PfStatementView() {
  // inital loader
  const [isLoading, setIsLoading] = useState(true);

  //table loader
  const [isRefresh, setIsRefresh] = useState(false);

  const [isButtonHided, setIsButtonHided] = useState(true);
  const [pfStatement, setPfStatement] = useState([]);

  //handling api failure
  const [isFailed, setIsFailed] = useState({ status: false, method: null });
  const [message, setMessage] = useState("");

  //invalid date range alert handler
  const [isInvalidDate, setInvalidDate] = useState(false);
  const [formVisible, setFormVisible] = useState(false);

  const defaultFormate = {
    radio_control: "",
    start_date: "",
    end_date: "",
  };

  const [queryFormate, setQueryFormate] = useState({
    radio_control: "",
    start_date: "",
    end_date: "",
  });

  const defaultDeclartionState = {
    declaration_radio_control: "true",
    // remarks: "",
    data: [
      {
        from: "",
        to: "",
        unit: "",
        addedUnit: "",
        ro: "",
        remarks: "",
      },
    ],
  };

  const [consent, setConsent] = useState(defaultDeclartionState);
  const [consentSubmitting, setConsentSubmitting] = useState(false);
  const [isConsentSuccess, setIsConsentSuccess] = useState(false);
  const [consentInvalid, setConsentInvalid] = useState(false);
  const [consentInvalidMessage, setConsentInvalidMessage] = useState("");
  //const [isConsentSubmitClicked, setIsConsentSubmitClicked] = useState("");
  //const [consentConfirmMsg, setConsentConfirmMsg] = useState("");

  const remarks = {
    name: "remarks",
    placeholder: "Remarks",
    defaultValue: consent.remarks,
    errorMessage: "",
    // label: "Message",
    pattern: "",
    multiline: true,
    minrows: 4,
    required: false,
    disabled: false,
  };

  const start_date = {
    name: "start_date",
    type: "date",
    placeholder: "Start date",
    helperText: "provide start date",
    defaultValue: queryFormate.start_date,
    errorMessage: "",
    // label: "pension_percentage",
    pattern: "",
    required: false,
    disabled: false,
  };

  const end_date = {
    name: "end_date",
    type: "date",
    placeholder: "End Date",
    helperText: "provide end date",
    defaultValue: queryFormate.end_date,
    errorMessage: "",
    // label: "pension_percentage",
    pattern: "",
    required: false,
    disabled: false,
  };

  const radio_control = {
    options: [
      { value: "Yearly", label: "Yearly" },
      { value: "Monthly", label: "Monthly" },
    ],
    name: "radio_control",
    initialValue: queryFormate.radio_control,
    disabled: false,
  };

  const declaration_radio_control = {
    options: [
      { value: "true", label: "Correct" },
      { value: "false", label: "Incorrect" },
    ],
    name: "declaration_radio_control",
    initialValue: consent.declaration_radio_control,
    disabled: false,
  };

  const clickAlert = "Your data is correct and upto date";

  const tableTitles = [
    "Currency Period Ending (DD/MM/YYYY)",
    "Unit Code",
    "Opening Balance (\u20B9)",
    "Member Contribution (\u20B9)",
    "Employer Contribution (\u20B9)",
    "Voluntary Contribution (\u20B9)",
    "Rate of Interest (%)",
    "Interest (\u20B9)",
    "Withdrawal Amount(\u20B9)",
    "Withdrawal Details",
    "Closing Balance (\u20B9)",
  ];

  const leftAlign = ["currPeriodEnd", "unitId", "withTypDesc"];
  const comma = [
    "closingBal",
    "openingBalance",
    "employerPfContri",
    "interestAmt",
    "memPfContri",
    "volContri",
    "withdrwlAmt",
  ];

  const sessionExist = async () => {
    const status = await validateSession();
    console.log("session status", status);
    return status;
  };

  const fetchYear = async () => {
    setIsRefresh(true);

    if (!(await sessionExist())) {
      console.log("session extending having trouble");
      setIsFailed({ status: true, method: fetchYear });
      setIsRefresh(false);
      return;
    }

    let token = localStorage.getItem("jwt");

    if (!token) {
      setIsFailed({ status: true, method: fetchYear });
      setIsRefresh(false);
      return;
    }
    token = token.replace('"', "").replace('"', "");
    const url =
      process.env.REACT_APP_SERVER_URL + "/getledgerpfyearbetweenyearrange";
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
    };

    const data = new URLSearchParams();
    data.append("cmpfAccNo", jwtDecode(token).user);
    data.append("fromYear", queryFormate.start_date);
    data.append("toYear", queryFormate.end_date);

    axios
      .post(url, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;
          const options = { day: "2-digit", month: "2-digit", year: "numeric" };
          data.map((elem) => {
            elem.currPeriodEnd = new Date(
              elem.currPeriodEnd
            ).toLocaleDateString("en-GB", options);
            if (elem.withdrwlDate !== null) {
              elem = elem.currPeriodEnd = new Date(
                elem.withdrwlDate
              ).toLocaleDateString("en-GB", options);
            }
            delete elem.withdrwlDate;
            delete elem.consentStatus;
          });
          response.data.data.length === 0
            ? setMessage(
                `No records found: ${queryFormate.start_date} - ${queryFormate.end_date}`
              )
            : setMessage(
                `Showing transaction from ${queryFormate.start_date} to ${queryFormate.end_date}.`
              );
          setPfStatement((pfStatement) => (pfStatement = data));
          setQueryFormate((queryFormate) => ({
            ...queryFormate,
            ...defaultFormate,
          }));

          setIsFailed({ status: false, method: fetchYear });
          setIsRefresh(false);
          return;
        }
        setPfStatement([]);
        setMessage("");
        setQueryFormate((queryFormate) => ({
          ...queryFormate,
          ...defaultFormate,
        }));
        setIsFailed({ status: true, method: fetchYear });
        setIsRefresh(false);
      })
      .catch(function (error) {
        setIsFailed({ status: true, method: fetchYear });
        setIsRefresh(false);
        setMessage("");
        // setQueryFormate(defaultFormate);
        // show a pop up message
        console.log("error", error);
      });
  };

  const fetchData = async () => {
    console.log("pf fetchData called");
    setIsLoading(true);

    if (!(await sessionExist())) {
      console.log("session extending having trouble");
      setIsFailed({ status: true, method: fetchData });
      setIsLoading(false);
      return;
    }

    let token = localStorage.getItem("jwt");
    token = token.replace('"', "").replace('"', "");
    if (!token) {
      setIsFailed({ status: true, method: fetchData });
      setIsLoading(false);
      return;
    }

    const url = process.env.REACT_APP_SERVER_URL + "/getledgerpfsummaryear";
    const formData = new FormData();
    formData.append("cmpfAccNo", jwtDecode(token).user);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data.data;

          response.data.data.length === 0
            ? setMessage("No records found")
            : setMessage("Showing records from last 10 transactions.");

          const options = { day: "2-digit", month: "2-digit", year: "numeric" };
          data.forEach((elem) => {
            elem.currPeriodEnd = new Date(
              elem.currPeriodEnd
            ).toLocaleDateString("en-GB", options);

            if (elem.withdrwlDate !== null) {
              elem = elem.currPeriodEnd = new Date(
                elem.withdrwlDate
              ).toLocaleDateString("en-GB", options);
            }
            delete elem.withdrwlDate;
            // checking consent status
            if (elem.consentStatus === "NS" || elem.consentStatus === "NE") {
              setFormVisible(true);
            }

            delete elem.consentStatus;
          });

          setPfStatement(data);
          setIsFailed({ status: false, method: fetchData });

          setIsLoading(false);
          setIsRefresh(false);
          setQueryFormate(defaultFormate);
          return;
        }
        // failed with 404
        setIsLoading(false);
        setIsRefresh(false);
        setMessage("");
        setIsFailed({ status: true, method: fetchData });
        setQueryFormate(defaultFormate);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsRefresh(false);
        setMessage("");
        setIsFailed({ status: true, method: fetchData });
        setQueryFormate(defaultFormate);
        // show a pop up message
        console.log("error", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onChange = (e) => {
    if (e.target.name === "radio_control") {
      setIsButtonHided(true);
      setQueryFormate({
        ...queryFormate,
        [e.target.name]: e.target.value,
        start_date: "",
        end_date: "",
      });
      return;
    }
    setQueryFormate({ ...queryFormate, [e.target.name]: e.target.value });
    if (queryFormate.start_date || queryFormate.end_date) {
      setIsButtonHided(false);
      return;
    }
    setIsButtonHided(true);
  };

  const consentOnChange = (e) => {
    console.log(typeof e.target.value);
    if (e.target.value === "true") {
      setConsent(defaultDeclartionState);

      return;
    }
    setConsent({ ...consent, [e.target.name]: e.target.value });
  };

  const setDeclartionData = (row) => {
    setConsent({ ...consent, data: row });
  };

  const handleInvalidDateAlert = () => {
    setInvalidDate(false);
  };

  const validate = () => {
    if (!queryFormate.start_date && !queryFormate.end_date) {
      setInvalidDate(true);
      return false;
    }
    if (queryFormate.start_date > queryFormate.end_date) {
      setInvalidDate(true);
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    if (queryFormate.radio_control === "Yearly") {
      fetchYear(e);
      setIsRefresh(true);
      setIsButtonHided(true);
      // setIsFailed({ status: true, method: fetchYear })
      return;
    }
    if (queryFormate.radio_control === "Monthly") {
      setIsButtonHided(true);
      setPfStatement([]);
      setMessage(
        `No records found: ${queryFormate.start_date} - ${queryFormate.end_date} `
      );
      setIsRefresh(false); // make this true when api available
      setQueryFormate(defaultFormate); // remove this when api available
      // call api for month
    }
  };

  ////////////validations for on Submit consent declaration from/////

  // Function to check if a string matches the unit pattern
  // function isValidUnit(unit) {
  //   return /^[A-Z]{1,4}\/\d{1,4}$/.test(unit);
  // }

  // Function to check if two date ranges overlap
  function doDateRangesOverlap(range1, range2) {
    const [start1, end1] = range1;
    const [start2, end2] = range2;
    return start1 <= end2 && start2 <= end1;
  }

  // Function to validate each object
  function validateObject(obj, index, array) {
    if (!obj.from || !obj.to || !obj.unit || !obj.ro) {
      return `Row ${index + 1} has missing fields.`;
    }

    // if (!isValidUnit(obj.unit)) {
    //   return `Row ${index + 1} has an invalid unit format.`;
    // }

    const fromDate = new Date(obj.from);

    const toDate = new Date(obj.to);

    if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
      return `Row ${index + 1} has invalid date format.`;
    }

    if (toDate <= fromDate) {
      return `Row ${
        index + 1
      } has 'to' date greater than or same as 'from' date.`;
    }

    for (let i = 0; i < array.length; i++) {
      if (i !== index) {
        const otherFromDate = new Date(array[i].from);
        const otherToDate = new Date(array[i].to);

        if (
          doDateRangesOverlap(
            [fromDate, toDate],
            [otherFromDate, otherToDate]
          ) ||
          doDateRangesOverlap([otherFromDate, otherToDate], [fromDate, toDate])
        ) {
          return `Row ${index + 1} has overlapping date range with row ${
            i + 1
          }.`;
        }
      }
    }

    return null;
  }

  // Validate each object in the array
  function validateFormData() {
    console.log("in validate");

    const validationErrors = consent.data
      .map(validateObject)
      .filter((error) => error !== null);
    console.log("consent.data", consent.data);

    if (validationErrors.length === 0) {
      console.log("All objects passed validation.");
      return true;
    } else {
      console.log("Validation errors:");
      console.log(validationErrors);
      setConsentInvalid(true);
      setConsentInvalidMessage(validationErrors[0]);
      return false;
    }
  }
  ////////
  function parseunit(unitCombined) {
    const [unitCode, unitName] = unitCombined.split("-");
    console.log(unitCode);
    return unitCode;
  }

  const postIncorrect = (id) => {
    let token = localStorage.getItem("jwt");
    const url = process.env.REACT_APP_SERVER_URL + "/ledgercorrectionconsent";
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const newData = consent.data.map((item) => {
      return {
        consentId: id,
        fromDate: item.from,
        toDate: item.to,
        unitCode: parseunit(item.unit),
        memRemarks: item.remarks,
        newUnitName: item.addedUnit,
        roName: item.ro,
      };
    });

    // create a post request here
    axios
      .post(url, newData, { headers })
      .then((response) => {
        // console.log("payload", newData);

        console.log("incorrect response post", response);
        if (response.status === 200) {
          setIsConsentSuccess(true);
          setConsentSubmitting(false);
          setFormVisible(false);
          // alert("Consent Submmited successfully");
        }
      })
      .catch((error) => {});
  };

  const postDeclarationStatus = () => {
    let token = localStorage.getItem("jwt");
    if (!token) {
      //setIsFailed({ status: true, method: fetchYear });
      //setIsRefresh(false);
      console.log("token doesnt exist");
      return;
    }
    token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + "/consentcorrectorincorrect";
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const declarationData = {
      cmpfAccNo: jwtDecode(token).user,
      consentType: consent.declaration_radio_control,
      consentDate: "",
    };
    axios
      .post(url, declarationData, { headers })
      .then((response) => {
        if (response.status === 200) {
          console.log("response correct incorrect", response);
          if (declarationData.consentType === "false") {
            const consentId = response.data.data;
            postIncorrect(consentId);
          } else {
            //alert("Consent Submmited successfully");
            setIsConsentSuccess(true);
            setConsentSubmitting(false);

            setFormVisible(false);
          }
        }
      })
      .catch(function (error) {});
  };

  const handleConsentSubmit = (e) => {
    e.preventDefault();

    if (consent.declaration_radio_control === "true") {
      setConsentSubmitting(true);
      postDeclarationStatus();
    } else {
      if (validateFormData()) {
        setConsentSubmitting(true);
        postDeclarationStatus();
      }
    }
  };
  const submitConsent = (e) => {
    if (consent.declaration_radio_control === "true") {
      setConsentSubmitting(true);
      postDeclarationStatus();
    } else {
      if (validateFormData()) {
        setConsentSubmitting(true);
        postDeclarationStatus();
      }
    }
  };
  // const handleSubmitClicked = () => {
  //   setIsConsentSubmitClicked(true);
  //   setConsentConfirmMsg("You are submitting your Declaration of Data ");
  // };
  const handleInvalidConsentAlert = () => {
    setConsentInvalid(false);
  };

  const handleConsentSuccess = () => {
    setIsConsentSuccess(false);
  };

  // const handleConsentSubmitAlertOk = (e) => {
  //   setIsConsentSubmitClicked(false);
  //   handleConsentSubmit(e);
  // };
  // const handleConsentSubmitAlertCancel = () => {
  //   setIsConsentSubmitClicked(false);
  // };

  return (
    <div>
      <ErrorAlert
        handleOk={handleInvalidDateAlert}
        open={isInvalidDate}
        title="Error!"
        body="Invalid Date Range."
        buttonName="Ok"
      />
      <ErrorAlert
        handleOk={handleInvalidConsentAlert}
        open={consentInvalid}
        title="Error!"
        body={consentInvalidMessage}
        buttonName="Ok"
      />
      {/* <ConfirmationDialogue
        handleOk={handleConsentSubmitAlertOk}
        handleCancel={handleConsentSubmitAlertCancel}
        open={isConsentSubmitClicked}
        title="Confirm Submission"
        body="By submitting this, you confirm that the displayed PF details are correct and complete."
        buttonName="Confirm"
      /> */}
      <SucessAlert
        handleOk={handleConsentSuccess}
        open={isConsentSuccess}
        title="Success"
        body="Consent Submitted Successfully."
        buttonName="OK"
      />
      <Grid
        container
        justifyContent="center"
        sx={{ wordWrap: "break-word" }}
        rowGap={2}
      >
        {formVisible && (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper
                elevation={2}
                sx={{
                  background:
                    "linear-gradient(90deg, rgba(209,37,37,1) 0%, rgba(219,126,69,1) 100%);",
                  padding: "2rem",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <TitleText
                      title="Data Verification and Consent"
                      color="white"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ValueText
                      title="You can now review and verify your data on C-Cares platform. This is an important step to ensure the accuracy of your information. Please take a moment to carefully review the data and provide your consent at the bottom of this page."
                      color="white"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper
            elevation={2}
            sx={{ padding: "3rem", borderRadius: "8px 8px 0px 0px" }}
          >
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <>
                <Grid item xs={12}>
                  <TitleText title="PF Statement" />
                </Grid>
                <FormDivider />

                <Grid
                  container
                  spacing={2}
                  justifyContent={{ xs: "flex-start", sm: "center" }}
                  alignItems="center"
                >
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Select Statement Period:" />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <GroupedRadioButton
                      {...radio_control}
                      onchange={onChange}
                    />
                  </Grid>

                  {queryFormate.radio_control === "Yearly" && (
                    <>
                      <Grid item xs={12} sm={4}>
                        <LabelText title="Date Range:" />
                      </Grid>

                      <Grid item xs={12} sm={8}>
                        <YearDropdown onChange={onChange} />
                      </Grid>
                    </>
                  )}

                  {queryFormate.radio_control === "Monthly" && (
                    <>
                      <Grid item xs={12} sm={4}>
                        <LabelText title="Date Range:" />
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <CustomTextField {...start_date} onchange={onChange} />
                      </Grid>

                      <Grid item xs={4} sm={4}>
                        <CustomTextField {...end_date} onchange={onChange} />
                      </Grid>
                    </>
                  )}

                  {!isButtonHided && (
                    <Grid item xs={5} sm={4}>
                      <Button
                        variant="contained"
                        color="success"
                        fullWidth
                        onClick={handleSubmit}
                        type="submit"
                      >
                        View Statement
                      </Button>
                    </Grid>
                  )}

                  {!isRefresh && !isFailed.status && (
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "Arial sans-serif",
                          fontSize: {
                            xs: "10px",
                            sm: "11px",
                            md: "12px",
                            lg: "14px",
                            xl: "15px",
                          },
                          fontWeight: "500",
                        }}
                        color={"#1c632f"}
                        align="left"
                      >
                        {message}
                      </Typography>
                    </Grid>
                  )}

                  {isRefresh && (
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        color={"#17B169"}
                        align="left"
                      >
                        Loading....
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Paper>

          {/* if length is 0 show no data */}

          {isFailed.status ? (
            <Reloader refreshHandler={isFailed.method} />
          ) : (
            <>
              {!isLoading && (
                <Grid item xs={12} sm={12}>
                  <Paper elevation={3} sx={{ borderRadius: 0 }}>
                    {pfStatement.length !== 0 && (
                      <FormTable
                        titles={tableTitles}
                        leftAlign={leftAlign}
                        comma={comma}
                        data={pfStatement}
                      />
                    )}
                  </Paper>
                </Grid>
              )}
            </>
          )}
        </Grid>
        {formVisible && (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper elevation={2} sx={{ padding: "2rem" }}>
                <Grid container gap={1} alignItems="center">
                  <Grid item xs={12}>
                    <TitleText title="Declaration of Correctness" />
                  </Grid>
                  {/* <Grid item xs={12}>
                <CaptionText title="You can now review and verify your data on our platform. This is an important step to ensure the accuracy of your information. Please take a moment to carefully review the data and provide your consent below." />
              </Grid> */}
                  <Grid item xs={12} sm={5}>
                    <ValueText
                      title="I certify that the PF details shown above are"
                      color="#1c632f"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <GroupedRadioButton
                      {...declaration_radio_control}
                      onchange={consentOnChange}
                    />
                  </Grid>

                  {/* form start*/}

                  {consent.declaration_radio_control === "false" && (
                    <>
                      <Grid item xs={12}>
                        <CaptionText title="Please provide your data mentioning FROM and TO dates and the corresponding Place of Posting (UNIT ID and REGIONAL OFFICE NAME) for the missing/incorrect period." />
                      </Grid>
                      <Grid item xs={12}>
                        <DynamicTable consentData={setDeclartionData} />
                      </Grid>
                      {/* <Grid item xs={12} sm={2}>
                    <ValueText title="Remarks" />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <CustomTextArea {...remarks} onchange={consentOnChange} />
                  </Grid> */}
                    </>
                  )}

                  <Grid item xs={12} sm={2}>
                    {/* empty */}
                  </Grid>

                  {/* form end */}
                </Grid>

                <Grid item>
                  <Button
                    disabled={consentSubmitting}
                    variant="contained"
                    color="success"
                    onClick={handleConsentSubmit}
                  >
                    {consentSubmitting ? <CircularProgress /> : "Submit"}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

export default PfStatementView;
