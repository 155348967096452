import React, { Fragment, useState, useEffect } from "react";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Grid,
  Paper,
  Box,
  Button,
  Divider,
  CircularProgress,
} from "@mui/material";
import { memberModel } from "../../Models/defaultModel";
import { Card, CardMedia } from "@mui/material";
import TitleText from "../../FormComponent/TitleText";
import { ReactToPrint } from "react-to-print";
import LabelText from "../../FormComponent/LabelText";
import CustomTextField from "../../FormComponent/CustomTextField";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import GroupedRadioButton from "../../FormComponent/GroupedRadioButton";
import SubtitleText from "../../FormComponent/SubtitleText";
import CustomTextArea from "../../FormComponent/CustomTextArea";
import LoadingIndicator from "../../FormComponent/LoadingIndicator";
import FormDivider from "../../FormComponent/FormDivider";
import { faClosedCaptioning } from "@fortawesome/free-solid-svg-icons";
import ValueText from "../../FormComponent/ValueText";
import jwtDecode from "jwt-decode";
import axios from "axios";
import Reloader from "../../FormComponent/Reloader";
import { validateSession } from "../../session/EventManager";
import TableWithLinkAndButton from "../../FormComponent/TableWithLinkAndButton";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { Navigate } from "react-router-dom";

function GrievaceViewMember() {
  // const [member, setMember] = useState();

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); //navigate back to the previous page
    window.scrollTo(0, 0); // scroll to the top of the page
  };

  const defaultData = {
    grievanceId: "",
    grievanceCategDesc: "",
    grievanceSubCatDesc: "",
    grievanceDesc: "",
    upldedDoc: "",
    docTypeDesc: "",
    submitDate: "",
    grievanceStatusDesc: "",
  };
  const componentRef = useRef(); // Ref for printing

  const [grievanceDetails, setGrievanceDetails] = useState(defaultData); // use 'defaultData' directly instead of { defaultData }
  // set the response data in this state

  const [isLoading, setIsLoading] = useState(true);

  const [isFailed, setIsFailed] = useState(false);

  const { state } = useLocation();

  const grievanceId = state.passingId;
  // const grievanceId = "";

  const fetchGrievanceDetails = async () => {
    try {
      // console.log("grience Id", grievanceId);
      // console.log("state Id", state);
      const formData = new FormData();
      formData.append("grievanceId", grievanceId);
      const url =
        process.env.REACT_APP_SERVER_URL + "/showAllGrievanceToMember";
      // console.log("url", url);
      const response = await axios.post(url, formData);
      // console.log("response", response.data.data);
      const data = response.data.data;
      setGrievanceDetails(data[0]); // Set the fetched data to the tablePreview state // we are taking data[0] as the response recevied is an array of object and we need only the first element

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching table data: ", error);
    }
  };

  useEffect(() => {
    fetchGrievanceDetails();
  }, []);

  //view document start from here
  const [loading, setLoading] = useState(false);
  const [documentContent, setDocumentContent] = useState(null);
  const [documentType, setDocumentType] = useState(null);
  const handleViewDocument = async () => {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/showAllGrievanceToMember?grievanceId=${grievanceId}`;
      const response = await axios.post(url);
      const base64Data = response.data.data[0].upldedDoc;
      // console.log(base64Data);
      let type = "pdf"; // Change const to let for type
      let utf8Data = atob(base64Data);
      // console.log(utf8Data);
      if (utf8Data.includes("data:image/jpeg;base64")) {
        type = "jpg";
      } else if (utf8Data.includes("data:image/png;base64")) {
        type = "png";
      }
      let base;
      if (type === "pdf") {
        base = utf8Data.replace(
          "data:application/pdf;base64,JVBERi0xLjQKJdPr6eEKMSAwIG9iago8PC9",
          "data:application/pdf;base64,"
        );
      } else {
        base = utf8Data.replace(
          `data:image/${type};base64,JVBERi0xLjQKJdPr6eEKMSAwIG9iago8PC9`,
          `data:image/${type};base64,`
        );
      }

      // Open the document in a new window
      const newWindow = window.open();
      newWindow.document.write(
        `<html><head><title>Document</title></head><body><${
          type === "pdf" ? "iframe" : "img"
        } src="${base}" width="100%" height="100%" /></body></html>`
      );
    } catch (error) {
      console.error("Error fetching document:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to render the document based on its type

  const renderDocument = () => {
    switch (documentType) {
      case "pdf":
        return (
          <iframe
            title="PDF Viewer"
            src={documentContent}
            width="50%"
            height="400"
          />
        );
      case "jpg":
      case "png":
        return (
          <img
            src={`data:image/${documentType};base64,${btoa(documentContent)}`}
            alt="Document"
          />
        );
      default:
        return <p>Unsupported document type</p>;
    }
  };

  return (
    <>
      {/*Button with back functionality */}

      <Grid container justifyContent="initial">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ wordWrap: "break-word" }}
          id="print-content"
        >
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  align="left"
                  onClick={handleGoBack}
                  //sx={{ backgroundColor: "darkviolet" }}
                  style={{ marginTop: "-30px" }}
                >
                  Back
                </Button>
              </Grid>
            </Grid>

            <Grid container alignItems="center">
              <Grid item xs={6}>
                <TitleText title="Grievance Details" />
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <span style={{ color: "green" }}>
                  <span style={{ color: "black", fontWeight: "bold" }}>
                    Status:
                  </span>{" "}
                  {grievanceDetails.grievanceStatusDesc}
                </span>
              </Grid>
            </Grid>

            <FormDivider />
            {/* {/* <Form> */}
            <Grid
              container
              justifyContent="space-between"
              alignItems="start"
              rowGap={3}
            >
              {/* column 1  */}

              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                  columnGap={1}
                >
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Grievance ID" /> {/* Display the label */}
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValueText title={grievanceDetails.grievanceId} />
                    {/* Display the Grievance Id */}
                  </Grid>

                  {/* row */}

                  <Grid item xs={12} sm={4}>
                    <LabelText title="Grievance Category" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValueText title={grievanceDetails.grievanceCategDesc} />
                  </Grid>

                  {/* row */}

                  <Grid item xs={12} sm={4}>
                    <LabelText title="Document Description" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValueText title={grievanceDetails.docTypeDesc} />
                  </Grid>
                  {/* row  */}
                </Grid>
              </Grid>

              {/* clumn 2 */}
              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  columnGap={1}
                >
                  {/* row */}

                  <Grid item xs={12} sm={4}>
                    <LabelText title="Submitted On" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValueText title={grievanceDetails.submitDate} />
                    {/* Display the "submitted on" data */}
                  </Grid>
                  {/* row */}

                  <Grid item xs={12} sm={4}>
                    <LabelText title="Grievance SubCategory" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={grievanceDetails.grievanceSubCatDesc} />
                  </Grid>

                  {/* row */}
                  {/* document start form here */}

                  <Grid item xs={12} sm={4} style={{ marginBottom: "2%" }}>
                    <LabelText title="Document Uploaded" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                    style={{ marginBottom: "2%" }}
                  >
                    :
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ textAlignLast: "left" }}>
                    <Button
                      //variant="contained"
                      onClick={handleViewDocument}
                      disabled={loading}
                    >
                      View Document
                    </Button>
                    {loading && <CircularProgress />}{" "}
                    {/* Show loading spinner while fetching the document */}
                    {documentContent && renderDocument()}
                  </Grid>
                  {/* ends here  */}
                </Grid>
              </Grid>

              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  columnGap={1}
                ></Grid>
              </Grid>
            </Grid>

            {/* )}
            </>
          )} */}

            {/* //describe grievance here  */}

            {/* <Form> */}
            <Grid
              container
              justifyContent="space-between"
              alignItems="start"
              rowGap={3}
            >
              {/* column */}
              <Grid item xs={12} sm={3}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                  columnGap={1}
                >
                  {/* rows */}
                  <Grid item xs={12} sm={7} style={{ marginTop: " -6% " }}>
                    <LabelText title="Grievance Description" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{
                      display: { xs: "none", sm: "flex" },
                      justifyContent: "flex-start",
                      marginLeft: "-13.5%",
                    }}
                    style={{ marginTop: " -6% " }}
                  >
                    :
                  </Grid>
                  <Grid item sm={1}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                  columnGap={1}
                ></Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    maxHeight: "150px",
                    overflowY: "auto",
                    marginTop: "15px",
                    marginLeft: { sm: "-18%" },
                  }}
                  style={{ marginTop: "0% " }}
                >
                  <ValueText title={grievanceDetails.grievanceDesc} />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
export default GrievaceViewMember;
