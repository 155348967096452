import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Paper,
  } from "@mui/material";
  import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import SubtitleText from "../../FormComponent/SubtitleText";
// import MemberDetails from "../UDAPages/MemberDetatils";
import TableWithLinkAndButton from "../../FormComponent/TableWithLinkAndButton";
import CaptionText from "../../FormComponent/CaptionText";
import ClaimDetails from "../../SettlementComponents/ClaimDetails";
import { useParams } from 'react-router-dom';

const TestDoc = () => {
  const [docs, setDocs] = useState([]); 
  const grievanceId = 'GRIEV_131'; // Replace with the actual account number

  useEffect(() => {
    const fetchDocuments = async () => {
      // Assuming this is your API endpoint to fetch the documents
      const response = await axios.get(`http://localhost:8080/showAllGrievanceToMember?grievanceId=${grievanceId}`);
      setDocs(response.data.data);
    };
    fetchDocuments();
  }, [grievanceId]);

//   return (
//     <div>
//       <h2>Documents</h2>
//       {docs.map((doc, index) => {
//         return (
//           <div key={index}>
//             <img src={'data:image/jpeg;base64,' + doc} alt={`Document ${index + 1}`} />
//             <br />
//             <Link to={`/convert/${index}`}>Convert</Link>
//           </div>
//         );
//       })}
//     </div>
//   );
  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            <Grid item xs={12}>
              <TitleText title="PF Claim Details" />
              <FormDivider />

              {/* Member Details*/}

              <Grid
                container
                justifyContent="space-between"
                alignItems="start"
                rowGap={3}
              >
                {/* section 1  */}
                <Grid item xs={12}>
                  <ClaimDetails />
                </Grid>

                {/* **********************View Documents************ */}
                
                <Grid item xs={12} sm={6}>
                    <SubtitleText title="Documents" />
                    {docs.map((doc, index) => {
                        return (
                            <div key={index}>
                                <img src={'data:image/jpeg;base64,' + doc.upldedDoc} alt={`Document ${index + 1}`} />
                                {/* <Link to={`/convert/${index}`}>Convert</Link> */}
                            </div>
                        );  
                    })}
                </Grid>
                
                {/* *************** Declaration ********* */}
                <Grid item xs={12}>
                  <Paper
                    elevation={3}
                    sx={{ padding: "3rem", borderRadius: "8px" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      {/* <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked}
                            onChange={handleCheck}
                            color="success"
                          />
                        }
                        // label={<ValueText title="I Agree" />}
                      /> */}
                      <SubtitleText title="Declaration" />
                      <FormDivider />
                      <CaptionText title="All documents are verified from All documents are verified and the claim & the claimant are genuine." />

                      {/* *************** Submit Button ********* */}

                      {/* <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="success"
                        //disabled={true}
                        //fullWidth
                        type="submit"
                      >
                        Forward to AAO
                      </Button> */}

                      {/* *************** Return Button ********* */}
                      {/* Add a remark on Click of this button */}

                      {/* <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        //disabled={true}
                        //fullWidth
                        type="submit"
                      >
                        Return
                      </Button> */}
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default TestDoc;
