import { Button, Grid, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import MemberDetails from "../UDAPages/MemberDetatils";
import BackButton from "../../components/button/BackButton";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ViewDocuments from "../../SettlementComponents/ViewDocuments";
import Reloader from "../../FormComponent/Reloader";
import LoadingIndicator from "../../FormComponent/LoadingIndicator";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import SucessAlert from "../../FormComponent/SuccessAlert";
import ConfirmationDialogue from "../../FormComponent/ConfirmationDialogue";
import ClaimDetailsPf from "../../SettlementComponents/ClaimDetailsPf";
import ClaimDetailsPen from "../../SettlementComponents/ClaimDetailsPen";
import jwtDecode from "jwt-decode";

function RevokePfClaimRh() {
  const { state } = useLocation();
  const claimId = state.passingId;
  const claimType = claimId.substring(0, 2);

  const navigate = useNavigate();
  const sampleMemberData = {
    // This is the sample claimData data to be displed for demo purpose only
    title: "",
    fullName: "",
    dateOfBirth: "",
    panNo: "",
    aadhaarNumber: "",
    maritalStatus: "",
    religionName: "",
    gender: "",
    fatherHusbandName: "",
    email: "",
    mobileNo: "",
    landNo: "",
    addressL1: "",
    addressL2: "",
    village: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  };
  const sampleClaimPf = {
    claimDetails: [
      {
        claimId: "",
        fullName: "",
        cmpfAccNo: "",
        unitCode: "",
        submissionDate: "",
        remarks: "",
        nameAadhaar: "",
        nameBank: "",
        namePan: "",
        dobInAadhaar: "",
        dobInPan: "",
        sfwhName: "",
        sfwhRelationDesc: "",
        pfClaimType: "",
        pfClaimStatus: "",
        claimantId: "",
      },
    ],
    officerRemarks: [
      {
        remarks: "",
        status: "",
        fromLoginId: "",
      },
    ],
  };

  const [pfClaimData, setPfClaimData] = useState(sampleClaimPf);
  const [memberData, setMemberData] = useState(sampleMemberData);
  const [isMemberFound, setIsMemberFound] = useState(" ");
  const [documents, setDocuments] = useState([]);

  //**********for integration************* */
  const [isLoading, setIsLoading] = useState(true); // set this to true
  const [isLoadingDoc, setIsLoadingDoc] = useState(true); // set this to true
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isFailedDoc, setIsFailedDoc] = useState(false);
  const [isReturning, setIsReturning] = useState(false);
  const [isReturnClicked, setIsReturnClicked] = useState(false);

  // declaring the default api object
  let api = {
    claimUrl: "",
    docsUrl: "",
    revokeUrl: "",
  };
  // object for pfapis
  // let loginId = "AAO-CC-OFF-3053"; // Assuming a default loginId
  // let token = token.replace('"', "").replace('"', "");
  // let loginId = jwtDecode(token).user; // Assuming a default loginId

  // console.log("loooooooooooooooooooooginId",loginId);

  const [loginId, setLoginId] = useState();

  /********fetching and setting token********* */
  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (token) {
      token = token.replace('"', "").replace('"', "");
      let loginId = jwtDecode(token).user; // Assuming a default loginId
      setLoginId(loginId);
      console.log("loooooooooooooooooooooginId", loginId);
      // Add any further logic here based on the loginId
    } else {
      // Handle the case when token is not present
      setIsLoading(false);
      setIsFailed(true);
    }
  }, []);
  // object
  const pfApis = {
    claimUrl: "/show/pf/claim/details/to/SI/on/claimid",
    docsUrl: "/show/pf/claim/uploaded/documents/claimid",
    confirmUrl: "/revoke/pf/claim/officers/rh",
  };

  const penApis = {
    claimUrl: "/show/pen/claim/on/claimid",
    docsUrl: "/show/submitted/documents/pen/claim",
    confirmUrl: "/pension/revoke/claims/rh",
  };
  let title;
  if (claimType === "PF") {
    api = { ...pfApis };
    title = "PF";
  } else if (claimType === "PN") {
    api = { ...penApis };
    title = "Pension";
  }

  //********DEFAULT for family details*********/
  const familyDefault = {
    familyMemId: "",
    name: "",
    gender: "",
    relationName: "",
    dateOfBirth: "",
    pfNomSharePerc: "",
  };

  const [nomineeDetails, setNomineeDetails] = useState([familyDefault]);

  // ******** setting props for the TableWithLinkAndButton component*******

  // const viewLink = "/familydetails"; // Link for family members details

  // Advance claim type integration]

  const fetchClaimDetailAdv = async () => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    // const url = process.env.REACT_APP_SERVER_URL + "/show/pf/claim/on/claimid";
    const url = process.env.REACT_APP_SERVER_URL + api.claimUrl;

    const formData = new FormData();
    formData.append("claimId", claimId);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          setPfClaimData(data);
          fetchMemberDetail(data.claimDetails[0].cmpfAccNo);
          fetchDocuments(data.claimDetails[0].claimantId);
          return;
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
      });
  };

  const fetchAllClaimDetail = () => {
    fetchClaimDetailAdv();
  };

  const fetchDocuments = async (claimantId) => {
    try {
      // const url = `${process.env.REACT_APP_SERVER_URL}/show/pf/claim/uploaded/documents/claimid`;
      const url = process.env.REACT_APP_SERVER_URL + api.docsUrl;

      const formData = new FormData();
      formData.append("claimId", claimId);
      formData.append("claimantId", claimantId);

      const config = {
        headers: {
          // Include the Authorization header if req uired
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(url, formData, config);

      setIsLoadingDoc(false);
      setIsFailedDoc(false);

      if (response.status === 200) {
        const data = response.data.data;
        setDocuments(data);
      } else {
        setIsFailed(true);
      }
    } catch (error) {
      // Corrected syntax for the catch block
      setIsLoading(false);
      setIsFailed(true);
    }
  };

  useEffect(() => {
    fetchAllClaimDetail();
  }, []);

  //! adding DPO AND PO
  const [combinedData, setCombinedData] = useState([]);
  const { claimDetails } = pfClaimData;
  let draftPayOrder = claimDetails[0]?.draftPayOrder; // Use optional chaining here
  let payOrder = claimDetails[0]?.payOrder;
  // Combine data from the two APIs
  useEffect(() => {
    if (draftPayOrder) {
      const updatedCombinedData = [
        ...documents,
        {
          docTypeDesc: claimType === "PF" ? "DPO" : "PENSET",
          uploadedDoc: draftPayOrder,
        },
      ];

      // Assuming advClaimData.advClaim.purchaseOrder is the document from the second API
      if (payOrder) {
        updatedCombinedData.push({
          docTypeDesc: claimType === "PF" ? "PO" : "PPO",
          uploadedDoc: payOrder,
        });
      }

      setCombinedData(updatedCombinedData);
    }
  }, [documents, pfClaimData]);

  /// ******** on Forwarded function

  // const objectTobeSent = {
  //   fromLoginId: loginId,
  //   claimId: claimId,
  //   approvalStatus: true,
  // };

  let objectTobeSent = {};
  if (claimType === "PF") {
    objectTobeSent = {
      fromLoginId: loginId,
      toLoginId: "",
      claimId: claimId,
      approvalStatus: true,
      remarks: "",
    };
  } else if (claimType === "PN") {
    objectTobeSent = {
      claimId: claimId,
      fromOffcrLoginId: loginId,
      toOffcrLoginId: "",
      remarks: "",
    };
  }
  const handleCancel = () => {
    navigate("");
  };
  const handleReturnClick = (e) => {
    e.preventDefault();

    setIsReturning(true);
    setIsReturnClicked(true);
  };
  const handleReturnClaim = (e) => {
    const url = process.env.REACT_APP_SERVER_URL + api.confirmUrl;
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSent, { headers })
      .then(function (response) {
        setIsReturning(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Revoked Successfully.");
        setIsReturnClicked(false);
      })
      .catch((error) => {
        console.log("error on submit", error);
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
      });
  };

  ///****************integration started********************* */

  const fetchMemberDetail = async (accNo) => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + "/member/details/for/pf";
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          if (data.length !== 0) {
            setMemberData(data[0]);
            setIsMemberFound("true");
            fetchNomineeDetail(data[0].cmpfAccNo);
          }
          setIsMemberFound("false");
          return;
        }
        setIsFailed(true);
        setIsMemberFound("false");
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        setIsMemberFound("false");
      });
  };

  /***** function to get all nominee details**************** */
  const fetchNomineeDetail = async (accNo) => {
    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");

    const url = process.env.REACT_APP_SERVER_URL + "/get/all/nominee/details";
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo); // check what formdata is to be sent
    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsLoading(false);
        setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          if (data.length !== 0) {
            setNomineeDetails(data);
          }

          return;
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
      });
  };
  /// error alert handler
  const handleErrorAlert = () => {
    setIsError(false);
  };

  const handleSuccess = () => {
    if (claimType === "PF") {
      navigate("/rh/pfset");
    } else if (claimType === "PN") {
      navigate("/rh/penset");
    }
  };

  const handleCancelReturn = () => {
    setIsReturnClicked(false);
  };
  const handleReturnOk = () => {
    handleReturnClaim();
  };

  return (
    <div>
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error!"
        body={errorMessage}
        buttonName="Ok"
      />
      <SucessAlert
        handleOk={handleSuccess}
        open={isSuccess}
        title="Success"
        body={successMessage}
        buttonName="OK"
      />
      <ConfirmationDialogue
        handleOk={handleReturnOk}
        handleCancel={handleCancelReturn}
        open={isReturnClicked}
        title="Confirmation"
        body="Are you sure you want to revoke this claim ?"
        buttonName="Confirm"
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "10px" }}>
            {isFailed || isFailedDoc ? (
              <Reloader refreshHandler={fetchAllClaimDetail} />
            ) : (
              <>
                {isLoading || isLoadingDoc ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        <TitleText title={`${title} Settlement`} />
                      </Grid>
                    </Grid>
                    <FormDivider />
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={1} mb={3}>
                        <BackButton />
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="start"
                        rowGap={3}
                      >
                        {/* Member Details*/}
                        {/* section 1  */}
                        <Paper
                          elevation={3}
                          sx={{ padding: "3rem", borderRadius: "10px" }}
                        >
                          <Grid item xs={12}>
                            <MemberDetails member={memberData} />
                          </Grid>
                        </Paper>
                        {/* claim Details*/}
                        {/* section 2  */}
                        <Paper
                          elevation={3}
                          sx={{ padding: "3rem", borderRadius: "10px" }}
                        >
                          <Grid item xs={12}>
                            <ClaimDetailsPf pfclaimdetails={pfClaimData} />
                          </Grid>
                        </Paper>
                        {/* Document Details*/}
                        {/* section 3  */}
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={12}>
                            <Paper
                              elevation={3}
                              sx={{ padding: "3rem", borderRadius: "10px" }}
                            >
                              <Grid item xs={12}>
                                {draftPayOrder ? (
                                  <ViewDocuments documents={combinedData} />
                                ) : (
                                  <ViewDocuments documents={documents} />
                                )}
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>
                        {/* *************** Approve ********* */}
                        <Grid container justifyContent="end">
                          <Grid item xs={12}>
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              columnGap={2}
                            >
                              {/* <Grid item md={1.2}>
                                <Button
                                  onClick={handleCancel}
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  // disabled={isReturnClicked}
                                  fullWidth
                                >
                                  Cancel
                                </Button>
                              </Grid> */}
                              <Grid item md={1.2}>
                                <Button
                                  onClick={handleReturnClick}
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  // disabled={isReturnClicked}
                                  fullWidth
                                >
                                  Revoke
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default RevokePfClaimRh;
