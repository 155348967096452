import React from 'react';
import { Global, css } from '@emotion/react';

const GlobalFontStyle = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'Fira Sans Condensed';
        font-style: normal;
        font-weight: 400;
        src: local('Fira Sans Condensed Regular'), url(../fonts/FiraSansCondensed-Regular.ttf) format('truetype');
      }
      @font-face {
        font-family: 'Fira Sans Condensed';
        font-style: normal;
        font-weight: 500;
        src: local('Fira Sans Condensed Medium'), url(../fonts/FiraSansCondensed-Medium.ttf) format('truetype');
      }
    `}
  />
);

export default GlobalFontStyle;