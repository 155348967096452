import { Button, Grid, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import MemberDetails from "../UDAPages/MemberDetatils";
import BackButton from "../../components/button/BackButton";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ViewDocuments from "../../SettlementComponents/ViewDocuments";
import Reloader from "../../FormComponent/Reloader";
import LoadingIndicator from "../../FormComponent/LoadingIndicator";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import SucessAlert from "../../FormComponent/SuccessAlert";
import ConfirmationDialogue from "../../FormComponent/ConfirmationDialogue";
import AdvClaimDetails from "../../SettlementComponents/AdvClaimDetails";
import jwtDecode from "jwt-decode";
import TableWithLinkAndButton from "../../FormComponent/TableWithLinkAndButton";
import SubtitleText from "../../FormComponent/SubtitleText";

function AdvFinallSettlement() {
  const { state } = useLocation();
  const claimId = state.passingId;
  const claimType = claimId.substring(0, 2);

  const navigate = useNavigate();
  const sampleMemberData = {
    // This is the sample claimData data to be displed for demo purpose only
    title: "",
    fullName: "",
    dateOfBirth: "",
    panNo: "",
    aadhaarNumber: "",
    maritalStatus: "",
    religionName: "",
    gender: "",
    fatherHusbandName: "",
    email: "",
    mobileNo: "",
    landNo: "",
    addressL1: "",
    addressL2: "",
    village: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  };

  const sampleClaimData = {
    advclaim: {
      claimDetails: {
        aaoConfrmPayment: "",
        advClaimStatusId: "",
        advClaimTypeId: "",
        amountApplied: "",
        claimId: "",
        claimType: "",
        cmpfAccNo: "",
        correspondanceAddress: "",
        dateReceived: "",
        declarationUda: "",
        dobAadhaar: "",
        dobPan: "",
        emolumentsLast36m: "",
        memberId: "",
        nameAadhaar: "",
        nameBank: "",
        namePan: "",
        nmAadhaarMatch: "",
        nmBankMatch: "",
        nmPanMatch: "",
        periodOfMembship: "",
        settlementDate: "",
        sfwhName: "",
        sfwhRelationType: "",
        udaLoginId: "",
      },
      tAdvSpecificDetails: {
        advClaimSrno: "",
        advSpecificDetSrno: "",
        amtAlrdyPaid: "",
        anticptdDateOfMarriage: "",
        dependentId: "",
        estimatedCost: "",
        examPassed: "",
        examYear: "",
        feesPaid: "",
        locationDet: "",
        saleAgreeAmt: "",
        sanctionedAmtDet: "",
        siteMeasurementUnit: "",
        siteMeasurementVal: "",
      },
    },
  };

  const [advClaimData, setAdvClaimData] = useState(sampleClaimData);
  const [memberData, setMemberData] = useState(sampleMemberData);
  const [isMemberFound, setIsMemberFound] = useState(" ");
  const [documents, setDocuments] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  //**********for integration************* */
  const [isLoading, setIsLoading] = useState(true); // set this to true
  const [isLoadingDoc, setIsLoadingDoc] = useState(true); // set this to true
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isFailedDoc, setIsFailedDoc] = useState(false);
  const [isReturning, setIsReturning] = useState(false);
  const [isReturnClicked, setIsReturnClicked] = useState(false);

  let beforeids = 1; //Todo we have set a value beforeids = 0 for doesn't show some field till ids
  // declaring the default api object
  let api = {
    claimUrl: "",
    docsUrl: "",
    confirmUrl: "",
  };
  // object for pfapis
  //let loginId = "CA-RO-OFF-369"; // Assuming a default loginId
  const [loginId, setLoginId] = useState();

  ///********fetching and setting token********* */
  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (token) {
      token = token.replace('"', "").replace('"', "");
      let loginId = jwtDecode(token).user; // Assuming a default loginId
      setLoginId(loginId);
      console.log("loooooooooooooooooooooginId", loginId);
      // Add any further logic here based on the loginId
    } else {
      // Handle the case when token is not present
      setIsFailed(true);
      setIsLoading(false);
    }
  }, []);
  const advApis = {
    claimUrl: "/fetch/advance/pf/claim/details/by/id",
    docsUrl: "/fetch/advance/pf/claim/Documents/by/id",
    confirmUrl: "/ca/clicks/on/ready/for/payment",
  };

  if (claimType === "AD") {
    api = { ...advApis };
  }

  //********DEFAULT for family details*********/
  const familyDefault = {
    familyMemId: "",
    name: "",
    gender: "",
    relationName: "",
    dateOfBirth: "",
    pfNomSharePerc: "",
  };

  const [nomineeDetails, setNomineeDetails] = useState([familyDefault]);

  // ******** setting props for the TableWithLinkAndButton component*******

  const viewLink = "familydetails"; // Link for family members details

  // Advance claim type integration]

  const fetchClaimDetailAdv = async () => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    // const url = process.env.REACT_APP_SERVER_URL + "/show/pf/claim/on/claimid";
    const url = process.env.REACT_APP_SERVER_URL + api.claimUrl;

    const formData = new FormData();
    formData.append("claimId", claimId);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          setAdvClaimData(data);
          fetchMemberDetail(data.cmpfAcNo);
          fetchDocuments();
          return;
        }
        // setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
      });
  };

  const fetchAllClaimDetail = () => {
    fetchClaimDetailAdv();
  };

  const fetchDocuments = async () => {
    try {
      // const url = `${process.env.REACT_APP_SERVER_URL}/show/pf/claim/uploaded/documents/claimid`;
      const url = process.env.REACT_APP_SERVER_URL + api.docsUrl;

      const formData = new FormData();
      formData.append("claimId", claimId);

      const config = {
        headers: {
          // Include the Authorization header if req uired
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(url, formData, config);

      setIsLoadingDoc(false);
      setIsFailedDoc(false);

      if (response.status === 200) {
        const data = response.data.data;
        setDocuments(data.data);
      } else {
        setIsFailed(true);
      }
    } catch (error) {
      // Corrected syntax for the catch block
      setIsLoading(false);
      setIsFailed(true);
    }
  };

  useEffect(() => {
    fetchAllClaimDetail();
    // fetchDocumnetAdv();
  }, []);

  const { advClaim } = advClaimData;
  let draftPayOrder = advClaim?.draftPayOrder; // Use optional chaining here
  let payOrder = advClaim?.payOrder;
  // Combine data from the two APIs
  useEffect(() => {
    if (draftPayOrder) {
      const updatedCombinedData = [
        ...documents,
        {
          docTypeDesc: "DPO",
          uploadedDoc: draftPayOrder,
        },
      ];

      // Assuming advClaimData.advClaim.purchaseOrder is the document from the second API
      if (payOrder) {
        updatedCombinedData.push({
          docTypeDesc: "PO",
          uploadedDoc: payOrder,
        });
      }

      setCombinedData(updatedCombinedData);
    }
  }, [documents, advClaimData]);
  console.log("combinedData", combinedData);
  /// ******** on Revoke function

  const objectTobeSentOnReturn = {
    loginId: loginId,
    claimId: claimId,
    remark: "",
  };

  const handleReturnClick = (e) => {
    e.preventDefault();

    setIsReturning(true);
    setIsReturnClicked(true);
  };
  const handleReturnClaim = (e) => {
    const url = process.env.REACT_APP_SERVER_URL + api.confirmUrl;
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSentOnReturn, { headers })
      .then(function (response) {
        setIsReturning(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Settlement Successfully.");
        setIsReturnClicked(false);
      })
      .catch((error) => {
        console.log("error on submit", error);
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
      });
  };

  ///****************integration started********************* */

  const fetchMemberDetail = async (accNo) => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + "/member/details/for/pf";
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          if (data.length !== 0) {
            setMemberData(data[0]);
            setIsMemberFound("true");
            fetchNomineeDetail(data[0].cmpfAccNo);
          }
          setIsMemberFound("false");
          return;
        }
        setIsFailed(true);
        setIsMemberFound("false");
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        setIsMemberFound("false");
      });
  };

  /***** function to get all nominee details**************** */
  const fetchNomineeDetail = async (accNo) => {
    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");

    const url =
      process.env.REACT_APP_SERVER_URL + "/show/all/existing/family/member";
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo); // check what formdata is to be sent
    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsLoading(false);
        setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          if (data.length !== 0) {
            setNomineeDetails(data);
          }

          return;
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
      });
  };
  /// error alert handler
  const handleErrorAlert = () => {
    setIsError(false);
  };

  const handleSuccess = () => {
    // setIsSuccess(false);
    navigate("/cr/Advset");
  };

  const handleCancelReturn = () => {
    setIsReturnClicked(false);
  };
  const handleReturnOk = () => {
    handleReturnClaim();
  };

  const maritalStatus = [
    { id: "M", title: "Married" },
    { id: "U", title: "Unmarried" },
    { id: "W", title: "Widow/Widower" },
    { id: "D", title: "Divorced" },
  ];

  const gender = [
    { id: "M", title: "Male" },
    { id: "F", title: "Female" },
    { id: "O", title: "Other" },
  ];

  const createTablePreviewDataReturn = () => {
    console.log("from preview", nomineeDetails);
    // the functions that creates a table preview
    //const tableSamples = familyMemberSamples; //familyMemberSamples must be replaced witht he response data recevied on the api request
    const tableSamples = nomineeDetails;
    let tablePreviewData = [];
    tableSamples.map((member) => {
      tablePreviewData.push({
        "Family Member ID": member.familyMemId,
        "Name of Nominee": member.name,
        Gender: gender.find((item) => item.id === member.gender)?.title,
        Relation: member.relationName,
        "Date of Birth": member.dateOfBirth,
        "Marital Status": maritalStatus.find(
          (item) => item.id === member.maritalStatus
        )?.title,
      });
    });
    return tablePreviewData;
  };

  return (
    <div>
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error!"
        body={errorMessage}
        buttonName="Ok"
      />
      <SucessAlert
        handleOk={handleSuccess}
        open={isSuccess}
        title="Success"
        body={successMessage}
        buttonName="OK"
      />
      <ConfirmationDialogue
        handleOk={handleReturnOk}
        handleCancel={handleCancelReturn}
        open={isReturnClicked}
        title="Confirmation"
        body="Are you sure you want to settle this claim ?"
        buttonName="Confirm"
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "10px" }}>
            {isFailed || isFailedDoc ? (
              <Reloader refreshHandler={fetchAllClaimDetail} />
            ) : (
              <>
                {isLoading || isLoadingDoc ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center">
                      <Grid item xs={12}>
                        <TitleText title="Advance PF Claim" />
                      </Grid>
                    </Grid>
                    <FormDivider />
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={1} mb={3}>
                        <BackButton />
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="start"
                        rowGap={3}>
                        {/* Member Details*/}
                        {/* section 1  */}
                        <Paper
                          elevation={3}
                          sx={{ padding: "3rem", borderRadius: "10px" }}>
                          <Grid item xs={12}>
                            <MemberDetails member={memberData} />
                          </Grid>
                          <Grid item xs={12} mt={4}>
                            <SubtitleText title="Nomination Status" />
                          </Grid>
                          <Grid item xs={12}>
                            <TableWithLinkAndButton
                              preview={createTablePreviewDataReturn()}
                              data={nomineeDetails}
                              passingId="familyMemberid"
                              actions=""
                              viewLink={viewLink}
                            />
                          </Grid>
                        </Paper>
                        {/* claim Details*/}
                        {/* section 2  */}
                        <Paper
                          elevation={3}
                          sx={{ padding: "3rem", borderRadius: "10px" }}>
                          <Grid item xs={12}>
                            <AdvClaimDetails
                              advclaimdet={advClaimData}
                              beforeids={beforeids}
                            />
                          </Grid>
                        </Paper>
                        {/* Document Details*/}
                        {/* section 3  */}
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={12}>
                            <Paper
                              elevation={3}
                              sx={{ padding: "3rem", borderRadius: "10px" }}>
                              <Grid item xs={12}>
                                {draftPayOrder ? (
                                  <ViewDocuments documents={combinedData} />
                                ) : (
                                  <ViewDocuments documents={documents} />
                                )}
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>
                        {/* *************** Ready for payment ********* */}
                        <Grid container justifyContent="end">
                          <Grid item xs={12}>
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              columnGap={2}>
                              <Grid item md={3}>
                                <Button
                                  onClick={handleReturnClick}
                                  variant="contained"
                                  color="success"
                                  type="submit"
                                  // disabled={isReturnClicked}
                                  fullWidth>
                                  Ready for payment
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default AdvFinallSettlement;
