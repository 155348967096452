import React from "react";
import PFStatementFirst from "./PFStatementPages/PFStatementFirst";
import DecelarationOfCorrectness from "./PFStatementPages/DecelarationOfCorrectness";

const PFStatement=()=>{
    return(<div id="pf-statement">
    <PFStatementFirst />
    <DecelarationOfCorrectness />
    </div>);
}
export default PFStatement;