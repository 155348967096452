import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Typography, Container, Grid, Paper, Box } from '@mui/material';
import TableWithLinkAndButton from '../../FormComponent/TableWithLinkAndButton';
import TitleText from '../../FormComponent/TitleText';
import FormDivider from '../../FormComponent/FormDivider';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { validateSession } from "../../session/EventManager"; //added by vidhuri
import { UserContext } from '../../UiManager/UserProvider';
import { useContext } from 'react';
const GoAllGrievances = () => {
  const defaultGreviences = {
    grievanceId: '',
    category: '',
    submittedOn: '',
    submittedBy: '',
  };

  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [tabData, setTabData] = useState({
    received: [defaultGreviences],
    assigned: [defaultGreviences],
    resolved: [defaultGreviences],
    closed: [defaultGreviences],
  });

//   const role = "gch"; // You can dynamically determine the role based on your application logic
// const loginId ="GCH-RO-OFF-521"

const [loginId, setLoginId] = useState();
const [role, setRole] = useState();


/********fetching and setting token********* */
useEffect(() => {
  let token = localStorage.getItem("jwt");
  if (token) {
    token = token.replace('"', "").replace('"', "");
    let loginId = jwtDecode(token).user; // Assuming a default loginId
    let role = jwtDecode(token).role; // Assuming a default loginId
    const roleInLowerCase = role.toLowerCase();
    console.log(roleInLowerCase); 
    
    setLoginId(loginId);
    setRole(roleInLowerCase);
    console.log("loooooooooooooooooooooginId", loginId);
    // Add any further logic here based on the loginId
  } else {
    // Handle the case when token is not present
    setIsLoading(false);
    console.log("token not found");
    // setIsFailed(true);
  }
}, []);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    fetchReceived();
  }, [loginId]);

  const fetchReceived = async () => {
    try {
      const formData = new FormData();
      formData.append('loginId', loginId);

      const url = `${process.env.REACT_APP_SERVER_URL}/view/received/grievances/by/${role}`;
      console.log("api running");
      const response = await axios.post(url, formData);

      setTabData((prevTabData) => ({
        ...prevTabData,
        received: response.data.data,
      }));
      setIsLoading(false);
    } catch (error) {
      console.log('error - received', error);
      return [];
    }
  };

  // useEffect(() => {
  //   fetchAssigned();
  // }, [loginId]);

  // const fetchAssigned = async () => {
  //   try {
  //     const formData = new FormData();
  //     formData.append('loginId', loginId);
  //     const url = `${process.env.REACT_APP_SERVER_URL}/view/assigned/grievances/by/${role}`;
  //     const response = await axios.post(url, formData);

  //     setTabData((prevTabData) => ({
  //       ...prevTabData,
  //       assigned: response.data.data,
  //     }));
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log('error - assigned', error);
  //     return [];
  //   }
  // };

  useEffect(() => {
    fetchResolved();
  }, [loginId]);

  const fetchResolved = async () => {
    try {
      const formData = new FormData();
      formData.append('loginId', loginId);
      const url = `${process.env.REACT_APP_SERVER_URL}/view/resolved/grievances/by/${role}`;
      const response = await axios.post(url, formData);

      setTabData((prevTabData) => ({
        ...prevTabData,
        resolved: response.data.data,
      }));
      setIsLoading(false);
    } catch (error) {
      console.log('error - resolved', error);
      return [];
    }
  };

  useEffect(() => {
    fetchClosed();
  }, [loginId]);

  const fetchClosed = async () => {
    try {
      const formData = new FormData();
      formData.append('loginId', loginId);
      const url = `${process.env.REACT_APP_SERVER_URL}/view/closed/grievances/by/${role}`;
      const response = await axios.post(url, formData);

      setTabData((prevTabData) => ({
        ...prevTabData,
        closed: response.data.data,
      }));
      setIsLoading(false);
    } catch (error) {
      console.log('error - closed', error);
      return [];
    }
  };

  const createTablePreviewData = () => {
    let tablePreviewData = [];
    const currentDate = new Date();

    selectedTabData.forEach((grievance) => {
      const submittedOnDate = new Date(grievance.submittedOn);
      const daysDifference = Math.floor((currentDate - submittedOnDate) / (1000 * 60 * 60 * 24));
      const isOlderThan7Days = daysDifference > 7;
      const isOlderThan14Days = daysDifference > 14;

  //     tablePreviewData.push({
  //       'Grievance ID': grievance.grievanceId,
  //       'Grievance Type': (
  //         <span style={{ fontWeight: isOlderThan14Days ? 'bold' : 'normal', color: isOlderThan14Days ? 'red' : 'black' }}>
  //           {grievance.category}
  //         </span>
  //       ),
  //       'Submitted On': (
  //         <span style={{ fontWeight: isOlderThan14Days ? 'bold' : 'normal', color: isOlderThan14Days ? 'red' : 'black' }}>
  //           {grievance.submittedOn}
  //         </span>
  //       ),
  //       'Submitted By': (
  //         <span style={{ fontWeight: isOlderThan14Days ? 'bold' : 'normal', color: isOlderThan14Days ? 'red' : 'black' }}>
  //           {grievance.submittedBy}
  //         </span>
  //       ),
  //       'CMPF A/C NO.': (
  //         <span style={{ fontWeight: isOlderThan14Days ? 'bold' : 'normal', color: isOlderThan14Days ? 'red' : 'black' }}>
  //           {grievance.cmpfAcNo}
  //         </span>
  //       ),
  //     });
  //   });

  //   return tablePreviewData;
  // };

  // const selectedTabData = tabData[
  //   value === 0 ? 'received' : value === 1 ? 'assigned' : value === 2 ? 'resolved' : value === 3 ? 'closed' : ''
  // ] || [];
  tablePreviewData.push({
    'Grievance ID': grievance.grievanceId,
    'Grievance Type': 
      // <span style={{ fontWeight: isOlderThan14Days ? 'bold' : 'normal', color: isOlderThan14Days ? 'red' : 'black' }}>
        grievance.category
      // </span>
    ,
    'Submitted On': 
      // <span style={{ fontWeight: isOlderThan14Days ? 'bold' : 'normal', color: isOlderThan14Days ? 'red' : 'black' }}>
        grievance.submittedOn,
     
    'Submitted By': 
    
        grievance.submittedBy
    
    ,
    'CMPF A/C NO.':
        grievance.cmpfAcNo
      
    
  });
});

return tablePreviewData;
};

const selectedTabData = tabData[
value === 0 ? 'received' :  value === 1 ? 'resolved' : value === 2 ? 'closed' : ''
] || [];
  // Rest of the code...
  

  //Changes done by vidhuri here 

   //code for the fetching officer name
  //code inserted by vidhuri here 
  
  const sessionExist = async () => {
    const status = await validateSession();
    console.log("session status", status);
    return status;
};
  const userContext =  useContext(UserContext); 
  const fetchOfficerData = async(signal) => 
  {
    // setIsLoading(true); isko define bhi krna hai 
    if(!(await sessionExist())){
      console.log("session extending having trouble")
      // setIsLoading(false);
      // setIsFailed(true);
      //inko bhi define krna hai 
            return;
    }


    let token = localStorage.getItem('jwt');
        console.log("token",token);
        if (!token) {
            
            return;
        }
        token = token.replace("\"", "").replace("\"", "");
        console.log("tokentoken",token);
        console.log("jwtDecode(token).user",jwtDecode(token).user);
        const formData =  new FormData();
        formData.append('loginId',jwtDecode(token).user);
        const url = process.env.REACT_APP_SERVER_URL + "/get/offcr/details/dashboard";
        
        
        const config = {
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
          },
          signal: signal,
        };
        try {
          const response = await axios.post(url,formData)
          const data = response.data.data[0];
          console.log("data hai ye mere wala",data.name);
          updateUser(data);

          // data.currPeriodEnd = new Date(data.currPeriodEnd).getFullYear();
          // setAccountSummary(data);
          // setIsLoading(false);
          // setIsFailed(false);
          console.log("Vidhuri JI",response.data.data);
          }
        catch {

              }
  }
  useEffect(() => {
    fetchOfficerData();
  }, []);
  
  function updateUser(data)
  {
    const currentUser = useContext.currentUser;
    userContext.updateUser({...currentUser, name: `${data.name} (${data.shName})`});
  }

  //changes done by vidhuri here 
  

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: '3rem', borderRadius: '8px' }}>
            <Grid item xs={12}>
              <TitleText title="Grievances" />
            </Grid>
            <FormDivider />
          
              <Container>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
                   <Tab label="Received " />
                   <Tab label="Resolved " />
                   <Tab label="Closed "  />
                  </Tabs>
                  </Grid>
                  <>
                  {isLoading ? (
              <p>Loading...</p>
            ) : (
                  <Grid item xs={12}>
                    <Paper elevation={3}>
                      <Box p={3}>
                        <TableWithLinkAndButton
                          preview={createTablePreviewData()}
                          data={selectedTabData}
                          passingId="Grievance ID"
                          viewLink="grievancebyid"
                          parentData={value}
                          size="small"
                        />
                      </Box>
                    </Paper>
                  </Grid>
                   )}
                  </>
                </Grid>
              </Container>
           
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default GoAllGrievances;
