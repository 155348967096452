import React from "react";
import { Fragment, useState, useEffect } from "react";
import { Grid, Box, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
// import ConfirmationDialogue from "../../FormComponent/ConfirmationDialogue";
import CustomConfirmationDialogue from "../../FormComponent/CustomConfirmationDialogue";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import axios from "axios";
import { useLocation } from "react-router-dom";
import SucessAlert from "../../FormComponent/SuccessAlert";
import ErrorAlert from "../../FormComponent/ErrorAlert";
const RevokeGriv = () => {
  // const [isRevoke, setIsRevoke] = useState(false);
  const [issubmitSucess, setIsSubmmitSucess] = useState(false); // creating  a state for submit button
  const [isUpdatedSuccess, setIsUpdatedSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const[isLoading,setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false);
  const printableData = {
    grievanceId: "",
    grievanceCategDesc: "",
    grievanceSubCatDesc: "",
    grievanceDesc: "",
    upldedDoc: "",
    docTypeDesc: "",
    submitDate: "",
    grievanceStatusDesc: "",
  };

  const [grievanceDetails, setGrievanceDetails] = useState({ printableData });
  const [isLoading, setIsLoading] = useState(false);

  //  note this line  start from here
  const { state } = useLocation();
  // console.log("state log", state);
  const grievanceId = state.passedData.grievanceId;
  // const grievanceId = "";
  // note this line ends here

  const fetchGrievanceDetails = async () => {
    try {
      // console.log("grience Id", grievanceId);
      // console.log("state Id one ", state);
      const formData = new FormData();
      formData.append("grievanceId", grievanceId);
      const url =
        process.env.REACT_APP_SERVER_URL + "/showAllGrievanceToMember";
      // console.log("url", url);
      const response = await axios.post(url, formData);
      // console.log("response", response.data.data);
      const data = response.data.data;
      setGrievanceDetails(data[0]); // Set the fetched data to the tablePreview state // we are taking data[0] as the response recevied is an array of object and we need only the first element
      setIsLoading(false);
      // console.log(" response data status ", response.status);
      // if (response.status === 400) {
      //   setIsError(true);
      //   setErrorMessage("Resolved/Closed grievances can not be revoked.");
      // }
      //  else {
      //   setIsError(true);
      //   setErrorMessage("Something Went Wrong!");
      // }
    } catch (error) {
      console.error("Error fetching table data: ", error);
    }
  };
  useEffect(() => {
    fetchGrievanceDetails();
    // setIsProgramLoaded(false);
  }, []);

  const onChange = (e) => {
    // console.log("onchange", e.target.value);
    setGrievanceDetails({
      ...grievanceDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitSucess = () => {
    setIsSubmmitSucess(true);
  };

  const handleRevokeGrievance = async () => {
    try {
      setIsSubmmitSucess(false);
      setIsSubmmitSucess(true);
      setIsDisabled(true);
      // console.log("grievance ID", grievanceId);
      // Make an API call to revoke the grievance based on the grievanceId
      const formData = new FormData();
      formData.append("grievanceId", grievanceId);
      const url = `${process.env.REACT_APP_SERVER_URL}/member/revoke/grievences`;
      const response = await axios.post(url, formData);
      // Handle the response based on the API call result
      // console.log("Grievance revoked successfully:", response.data);
      // console.log(" Grievance Status ", response.data);
      setIsUpdatedSuccess(true);
      // if (response.status === 400)
      // {
      //   setIsError(true);
      //   setErrorMessage(" Resolve or closed grievance can not be revoked.");
      // }

      // Refresh the table data after revoking the grievance
      //fetchTableData(); // Assuming fetchTableData is a function that fetches and updates the table data state
      //setIsSubmmitSucess(true); // Assuming setIsSubmitSuccess is a function that sets a state indicating the success of the submission
      // if (response.status === 200) {
      //   console.log("status wala response" , response);
      //   setIsSubmmitSucess(true);
      // }
      // else {
      // }
    } catch (error) {
      setIsSubmmitSucess(true); // Set the submit success state to false if an error occurs
      setIsError(true);
      setIsSubmmitSucess(false);
      setErrorMessage(error.response.data.error_message);
      // console.error(
      //   "Error revoking grievance: ",
      //   error.response.data.error_message
      // );
    }
  };

  const navigate = useNavigate();

  const handleDialogueCancel = () => {
    // setIsDisabled(true);
    setIsSubmmitSucess(false);
  };

  const handleGoBack = () => {
    navigate(-1); //navigate back to the previous page
    window.scrollTo(0, 0); // scroll to the top of the page
  };

  const handleUpdateSucess = () => {
    setIsUpdatedSuccess(false);
    navigate("/member/grievances");
  };

  /// error alert handler
  const handleErrorAlert = () => {
    setIsError(false);
    setIsLoading(false);
  };

  return (
    <div>
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Alert!"
        body={errorMessage}
        buttonName="Ok"
      />
      {/* <SucessAlert
        handleOk={handleSubmitSucess} 
        // handlefunction
        open={isRevoke}
        // handlestate
        title="Success"
        body="Grievance Submitted Successfully"
        buttonName="Ok"
      /> */}
      {/* <SucessAlert
        handleOk={handleRevokeGrievance}
        open={issubmitSucess}
        title="Success"
        body="Are You Sure You Want to Revoke"
        buttonName="Yes" */}
      {/* /> */}
      <CustomConfirmationDialogue
        handleOk={handleRevokeGrievance}
        handleCancel={handleDialogueCancel}
        title=""
        body="Are you sure, you want to Revoke the Grievance?"
        open={issubmitSucess}
        // isDisabled={isDisabled}
        buttonName="Yes"
        buttonName2="No"
      />

      <SucessAlert
        handleOk={handleUpdateSucess}
        open={isUpdatedSuccess}
        title="Success"
        body="Grievance Revoked Successfully"
        buttonName="Ok"
      />

      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  align="left"
                  onClick={handleGoBack}
                  // style={{ marginTop: "3px" }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmitSucess}
                >
                  Revoke
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {/* <TitleText title="PF Claim Submission" /> */}
            </Grid>
            <FormDivider />

            <div sx={{ wordWrap: "break-word" }} id="print-content">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <h3>Grievance Details</h3>
                      </TableCell>
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        Grievance ID
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </TableCell>
                      <TableCell>
                        :&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                        {grievanceDetails.grievanceId}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Submitted On
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </TableCell>
                      <TableCell>
                        :&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                        {grievanceDetails.submitDate}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Grievance Category
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </TableCell>
                      <TableCell>
                        :&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                        {grievanceDetails.grievanceCategDesc}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Grievance Subcategory
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </TableCell>
                      <TableCell>
                        :&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                        {grievanceDetails.grievanceSubCatDesc}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        Document Description
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </TableCell>
                      <TableCell>
                        :&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                        {grievanceDetails.docTypeDesc}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        Grievance Status
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </TableCell>
                      <TableCell>
                        :&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                        {grievanceDetails.grievanceStatusDesc}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        Grievance Description
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </TableCell>
                      <TableCell>{grievanceDetails.grievanceDesc}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
export default RevokeGriv;
