import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import LabelText from "../../FormComponent/LabelText";
import ValueText from "../../FormComponent/ValueText";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { PDFExport } from "@progress/kendo-react-pdf";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import "./pdfStyles.css";
const lineStyle = {
  width: "100%",
  marginLeft: "10px",
  marginRight: "20px",
  border: "1.5px solid black", // Dark color, you can customize this to your preferred dark shade
};

//   para: "Para 63(1)(a)",
//   videItemNo: "0000",
//   payOrderNo: "01234",
//   fullName: "E Rajanna",
//   fatherHusbandName: "E Rajamallu",
//   unitName: "M.D.Office",
//   joiningDate: "25-10-1990",
//   cmpfAccNo: "HYD/170/840",
//   dateOfCessation: "31-05-2023",
//   dateOfAppointment: "25-10-1990",
//   dateOfDeath: "00-00-0000",
//   membershipPeriod: "32 Years 07 Months",
//   ledgerBalance: 2231862.0,
//   payeeAddress: "test",
//   dateOfClaim: "23-08-2023",
//   dateOfBirth: "1987-10-12",
//   causeOfCessation: "SuperAnnuation",
//   claimTypDesc: "SuperAnnuation",
//   name: "BaijunathSharma",
//   relationName: "Brother",
//   sanctionedAmt: 0,
//   brokenPeriodInterestRate: 8,
//   brokenPeriodInterestPayable: 247043,
//   brokenPeriodContri: 180804,
//   brokenPeriodContriDet: null,

//   modeOfRemittance: "A/C PAYEE CHEQUE/BANK TRANSFER",
//   acc: "52196074233",
//   ifsc: "SBIN0020909",
//   bankName: "STATE BANK OF INDIA",
//   bankAddress: "Branch KALYANKHANI, SRINAGAR, GARHWAL, UTTARAKHAND, 246174",
//   postalAddress: "C/O KK-1, MANDAMARRI, MMR MANCHERIAL, Telangana,INDIA",
//   // feilds to be calculated
//   age: "60",
//   dateUptoWhichInterestIsPayable: "31-07-2023",
//   closingBalance: 2231862.0,
//   dateFrom: "",
//   dateTo: "31.03.2023",
//   rateOfInterest: 8.0,
//   brokenPeriodInterestPayableFrom: "01-04-2023",
//   brokenPeriodInterestPayableTo: "31-07-2023",
//   brokenPeriodContribution: 180804.0,
//   brokenPeriodInterestPayable: 247043.0,
//   amountBeforeDistribution: 0,
//   totalAmountpayable: 2659709.0,
//   refundDueDate,
// };

const PfDpo = () => {
  const { state } = useLocation();
  const data = state;
  console.log("data on dpo", data);
  let totalAmount = data.sanctionedAmt;
  console.log("ja");
  // Format the number using Indian numbering system
  const formattedNumber = totalAmount.toLocaleString("en-IN");

  return (
    <Box mt={8}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",

          marginBottom: "20px",
        }}
      >
        <Typography
          variant="body2"
          gutterBottom
          fontWeight="bolder"
          fontFamily="monospace"
        >
          .
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          fontWeight="bolder"
          fontFamily="monospace"
        >
          .
        </Typography>
      </Box>
      <Typography variant="h6" gutterBottom>
        Draft Pay Order
      </Typography>
      <Box mt={4} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: "60px",
          marginRight: "60px",
          marginBottom: "20px",
        }}
      >
        <Typography
          variant="body2"
          gutterBottom
          fontWeight="bolder"
          fontFamily="monospace"
        >
          Form No: -Ref.2
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          fontWeight="bolder"
          fontFamily="monospace"
        >
          Date: {data.dateOfClaim}
        </Typography>
      </Box>
      <Typography variant="h6" gutterBottom>
        <span>Coal Mines Provident Fund Organization</span>
      </Typography>
      <Typography variant="h6" gutterBottom>
        <span
          style={{
            textDecoration: "underline",
            fontFamily: "monospace",
            fontWeight: "bold",
          }}
        >
          {` OFFICE OF THE REGIONAL COMMISSIONER, ${data.roName}`}
        </span>
      </Typography>
      <Typography variant="body2" paragraph>
        Refund made under: Para{" "}
        <span style={{ fontWeight: "bold" }}>{data.para}</span>
      </Typography>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
        style={{ paddingLeft: 100 }}
      >
        <Grid item xs={8}>
          <Box justifyContent={"start"}>
            <Typography
              variant="body1"
              className="labelForPdf"
              justifyContent="center"
              marginLeft={-75}
            >
              1.
            </Typography>
          </Box>

          <List dense sx={{ listStyle: "lower-alpha", pl: 4 }}>
            <ListItem sx={{ display: "list-item" }}>
              <Grid container display="flex" justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    Member's Name
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                    textAlign={"left"}
                  >
                    {" "}
                    {data.fullName}{" "}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem
              sx={{ display: "list-item" }}
              justifyContent="space-between"
            >
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    Father/Husband Name
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                  >
                    {" "}
                    {data.fatherHusbandName}{" "}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem
              sx={{ display: "list-item" }}
              justifyContent="space-between"
            >
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    CMPF A/C No.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                    textAlign={"left"}
                  >
                    {" "}
                    {data.cmpfAccNo}{" "}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem
              sx={{ display: "list-item" }}
              justifyContent="space-between"
            >
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    Name of Regd. Unit
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                  >
                    {" "}
                    {data.unitName}{" "}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem
              sx={{ display: "list-item" }}
              justifyContent="space-between"
            >
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    Unit Code
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                  >
                    {" "}
                    {data.unitCode}{" "}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={8}>
          <Box justifyContent={"start"}>
            <Typography
              fontFamily="monospace"
              variant="body1"
              className="labelForPdf"
              justifyContent="center"
              marginLeft={-75}
            >
              2.
            </Typography>
          </Box>

          <List dense sx={{ listStyle: "lower-alpha", pl: 4 }}>
            <ListItem sx={{ display: "list-item" }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    Date of Appointment
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                  >
                    {" "}
                    {data.dateOfAppointment}{" "}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    variant="body2"
                    className="labelForPdf"
                    fontFamily="monospace"
                  >
                    Date of exit from service
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                  >
                    {" "}
                    {data.dateOfCessation}{" "}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    variant="body2"
                    className="labelForPdf"
                    fontFamily="monospace"
                  >
                    Date of Claim
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                  >
                    {" "}
                    {data.dateOfClaim}{" "}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    variant="body2"
                    className="labelForPdf"
                    fontFamily="monospace"
                  >
                    Date of Death
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                  >
                    {" "}
                    {data.dateOfDeath}{" "}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    Reason of Leaving Service
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                    textAlign={"left"}
                  >
                    {" "}
                    {data.causeOfCessation}{" "}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    Name of Claimant
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                  >
                    {" "}
                    {data.name}{" "}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    Relationship with deceased member
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                  >
                    {" "}
                    {/* {data.relationName}{" "} */}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    Age at the time of leaving service
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                  >
                    {" "}
                    {data.age}{" "}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    Date when refund becomes due
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                  >
                    {data.refundDueDate}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    Date upto which interest is payable
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                  >
                    {data.dateUptoWhichInterestIsPayable}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    {/* Period of membership is 32 years 7 months: */}
                    Membership Period is {data.membershipPeriod}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={8}>
          <Box justifyContent={"start"}>
            <Typography
              fontFamily="monospace"
              variant="body1"
              className="labelForPdf"
              justifyContent="center"
              marginLeft={-42}
              mb={2}
            >
              <span style={{ textDecoration: "underline" }}>
                3. AMOUNT PAYABLE IS AS UNDER
              </span>
            </Typography>
          </Box>
        </Grid>
        <TableContainer>
          <Table style={{ borderCollapse: "collapse" }}>
            <TableHead>
              <TableRow>
                {/* Headers */}
                {[
                  "Closing Balance as on previous completed year",
                  "Date from",
                  "Date to",
                  "Rate of Interest",
                  "Broken Period Interest Payable From",
                  "Broken Period Interest Payable To",
                  "Broken Period Interest Payable",
                  "Broken Period Contribution",
                ].map((header, index) => (
                  <TableCell
                    key={index}
                    style={{ border: "1px dashed black", padding: 8 }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Row 1 */}
              <TableRow>
                <TableCell style={{ border: "1px dashed black", padding: 8 }}>
                  {data.closingBalance}
                </TableCell>
                <TableCell style={{ border: "1px dashed black", padding: 8 }}>
                  {data.dateFrom}
                </TableCell>
                <TableCell style={{ border: "1px dashed black", padding: 8 }}>
                  {data.dateTo}
                </TableCell>
                <TableCell style={{ border: "1px dashed black", padding: 8 }}>
                  {data.brokenPeriodInterestRate}
                </TableCell>
                <TableCell style={{ border: "1px dashed black", padding: 8 }}>
                  {data.brokenPeriodInterestPayableFrom}
                </TableCell>
                <TableCell style={{ border: "1px dashed black", padding: 8 }}>
                  {data.brokenPeriodInterestPayableTo}
                </TableCell>
                <TableCell style={{ border: "1px dashed black", padding: 8 }}>
                  {data.brokenPeriodInterestPayable}
                </TableCell>
                <TableCell style={{ border: "1px dashed black", padding: 8 }}>
                  {data.brokenPeriodContri}
                </TableCell>
              </TableRow>

              {/* Row 3 (Merged) */}
              <TableRow>
                <TableCell
                  colSpan={8}
                  style={{ border: "1px dashed black", padding: 8 }}
                >
                  Net Amount Payable :{" "}
                  {data.sanctionedAmt.toLocaleString("en-IN")}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item>
          <Typography textAlign={"left"} fontFamily={"monospace"}>
            Draft pay order prepared for Rs.{" "}
            {data.sanctionedAmt.toLocaleString("en-IN")} in favour of{" "}
            {data.name}
            {""} who is Himself / Herself member of the Fund/Nominee/Surviving
            Member/Natural guardian/ De-Facto Guardian of the minor Nominee
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

////////////////////////////////////////////////////
const PfDpo1 = () => {
  const { state } = useLocation();
  const data = state;
  console.log("data on dpo", data);
  let totalAmount = data.sanctionedAmt;
  console.log("ja");
  // Format the number using Indian numbering system
  const formattedNumber = totalAmount.toLocaleString("en-IN");

  return (
    <Box mt={10}>
      {/* <Box mt={8}> */}
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
        style={{ paddingLeft: 100 }}
      >
        <Grid item xs={8}>
          <List dense>
            <ListItem sx={{ display: "list-item" }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    Ledger Balance before distribution :
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                  >
                    Rs. {data.amountBeforeDistribution}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={8}>
          <Typography fontFamily={"monospace"} textAlign={"left"}>
            <u>Details of Share distribution</u>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <List dense>
            <ListItem sx={{ display: "list-item" }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    DETAILS OF THE PAYEE :
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                  >
                    {data.name}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={8}>
          <Typography fontFamily={"monospace"} textAlign={"left"}>
            <u>Bank Details</u>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <List dense>
            <ListItem sx={{ display: "list-item" }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    SB Account No :
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                  >
                    {data.acc}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={8} mt={-2}>
          <List dense>
            <ListItem sx={{ display: "list-item" }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    IFSC Code :
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                  >
                    {" "}
                    {data.ifsc}{" "}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={10} mt={-2}>
          <List dense>
            <ListItem sx={{ display: "list-item" }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    Bank Address :
                  </Typography>
                </Grid>
                <Grid item md={5}>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                  >
                    {" "}
                    {data.bankAddress}{" "}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={10} mt={-2}>
          <List dense>
            <ListItem sx={{ display: "list-item" }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    Payee Address :
                  </Typography>
                </Grid>
                <Grid item md={5}>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                  >
                    {" "}
                    {data.payeeAddress}{" "}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={8} mt={-2}>
          <List dense>
            <ListItem sx={{ display: "list-item" }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    Amount to be refunded :
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                  >
                    Rs. {formattedNumber}{" "}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={10} mt={-2}>
          <List dense>
            <ListItem sx={{ display: "list-item" }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    {/* Pay order no. : {data.payOrderNo} */}
                    Pay Order No. : {""}
                  </Typography>
                </Grid>
                <Grid item md={5}>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    Pay Order Date : {data.dateOfClaim}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <hr style={lineStyle} />
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
        style={{ paddingLeft: 100 }}
      >
        <Grid item xs={8}>
          <List dense>
            <ListItem sx={{ display: "list-item" }}>
              <Grid container justifyContent="start">
                <Grid item md={1.5}>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    Dated :
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                  >
                    {data.dateOfClaim}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Grid>
      </Grid>
      {/* </Box> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",

          marginBottom: "20px",
        }}
      >
        <Typography
          variant="body2"
          gutterBottom
          fontWeight="bolder"
          fontFamily="monospace"
        ></Typography>
        <Typography
          variant="body2"
          gutterBottom
          fontWeight="bolder"
          fontFamily="monospace"
        >
          .
        </Typography>
      </Box>
    </Box>
  );
};

// const GeneratePDFButton = () => {
//   const pdfExportComponent = useRef(null);

//   const exportPDF = () => {
//     if (pdfExportComponent.current) {
//       pdfExportComponent.current.save();
//     }
//   };

//   return (
//     <div style={{ justifyContent: "flex-end" }}>
//       <PDFExport ref={pdfExportComponent}>
//         <PfDpo />
//       </PDFExport>
//       <Grid item marginTop={3}>
//         <Button onClick={exportPDF} variant="contained">
//           Generate DPO
//         </Button>
//       </Grid>
//     </div>
//   );
// }

// export default GeneratePDFButton;

const GenerateAdvPO = () => {
  const { state } = useLocation();
  const data = state;
  const [base64, setBase64] = useState();
  const AdvPORefElement = useRef(null);
  const AdvPORefElement1 = useRef(null);
//! dataFromPreview is at 2 places please make the changes at both places 
  const navigate = useNavigate();
  let dataFromPreview = {};
  dataFromPreview = {
    sanctionedAmt: data.sanctionedAmt * 1,

    brokenPeriodInterestPayable: data.brokenPeriodInterestPayable * 1,
    brokenPeriodInterestRate: data.brokenPeriodInterestRate * 1,
    brokenPeriodContri: data.brokenPeriodContri * 1,
    uploadedDocs: data.uploadedDocs,
    noOfMonthsWorked: data.noOfMonthsWorked,
    processId: data.processId,
    draftPayOrder: "",
  };
  //  console.log("dataFromPreview", dataFromPreview);
  const handleCancel = () => {
    navigate("/roda/pfset/viewpfclaimroda", {
      state: {
        passingId: data.claimId,
      },
    });
  };
  const exportPDF = () => {
    const compressionQuality = 0.4; // adjust the quality level
    // Capture content of first HTML element
    html2canvas(AdvPORefElement.current, {
      scale: 2.2,
      allowTaint: true,
    }).then((canvas1) => {
      const imageData1 = canvas1.toDataURL("image/jpeg", compressionQuality);

      // Capture content of second HTML element
      html2canvas(AdvPORefElement1.current, {
        scale: 2.2,
        allowTaint: true,
      }).then((canvas2) => {
        const imageData2 = canvas2.toDataURL("image/jpeg", compressionQuality);

        // Create a new PDF
        const pdf = new jsPDF("p", "mm", "a4");
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        // Add first image to the PDF
        pdf.addImage(
          imageData1,
          "JPEG",
          15,
          15,
          pageWidth - 30,
          pageHeight - 50
        );

        // Add a new page for the second image
        pdf.addPage();
        // Add second image to the PDF
        pdf.addImage(
          imageData2,
          "JPEG",
          15,
          15,
          pageWidth - 30,
          pageHeight - 180
        );
        //  pdf.save("dpo_combined_pdf.pdf");
        // Get the base64 data of the PDF
        const base64PDF = pdf.output("datauristring");
        let base64PDFf = base64PDF.replace(
          /^data:.+;base64,/,
          `data:application/pdf;base64,JVBERi0xLjQKJdPr6eEKMSAwIG9iago8PC9`
        );
        // Now you can use the base64PDF data as needed
        // console.log("base64PDF", base64PDFf);
        // setBase64(base64PDF);
        dataFromPreview = {
          sanctionedAmt: data.sanctionedAmt * 1,

          brokenPeriodInterestPayable: data.brokenPeriodInterestPayable * 1,
          brokenPeriodInterestRate: data.brokenPeriodInterestRate * 1,
          brokenPeriodContri: data.brokenPeriodContri * 1,
          uploadedDocs: data.uploadedDocs,
          noOfMonthsWorked: data.noOfMonthsWorked,
          draftPayOrder: base64PDFf,
           processId: data.processId,
        };
        // console.log("data sending from dpo", dataFromPreview);
        navigate("/roda/pfset/viewpfclaimroda", {
          state: {
            passingId: data.claimId,
            dataFromPreview: dataFromPreview,
            sentState: true,
            // ledgerUpdate: true,
          },
        });
      });
    });
  };

  // const handleApprove = () => {
  //   const url =
  //     process.env.REACT_APP_SERVER_URL + "/forward/advance/pf/claim/BO/to/CA";
  //   const headers = {
  //     //'Authorization': `Bearer ${token}`,
  //     "Content-Type": "application/json",
  //   };
  //   axios
  //     .post(url, objectTobeSentToCh, { headers })
  //     .then(function (response) {
  //       setIsSubmitting(false);
  //       setIsSuccess(true);
  //       setSuccessMessage("Claim Forwarded Successfully.");
  //     })
  //     .catch((error) => {
  //       setIsError(true);
  //       setIsSubmitting(false);
  //       setErrorMessage("Sorry Could not Proceed!! Please try again later");
  //       console.log("error on submit", error);
  //     });
  // };

  /// error alert handler

  return (
    <div>
      <div style={{ justifyContent: "flex-end" }}>
        <div ref={AdvPORefElement}>
          <PfDpo />
        </div>
        <div ref={AdvPORefElement1}>
          <PfDpo1 />
        </div>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid
            item
            // marginTop={3}
            // marginBottom={3}
            // marginRight="20px"
            // style={{ display: "flex", justifyContent: "center" }}
          >
            <Button onClick={handleCancel} variant="contained">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={exportPDF} variant="contained" color="success">
              Generate dpo
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default GenerateAdvPO;
