import React from 'react'
import { Grid, Typography, Paper, Box, CardMedia } from '@mui/material';

import '../../styles/stylesheet.css'

const About = () => {
  return (
    <div className='aboutcontainer' id='about'>
      <Grid container style={{ display: 'flex' }}>
        {/* First Column */}
        <Grid item xs={12}  sm={6} style={{ display: 'flex' }}>
          <div className='about' style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
            <div className='aboutcontent' style={{ flex: '1' }}>
              <p className='about-header'>Coal Mines Provident Fund Organisation</p>
              <p className='about-subheader'>A STATUTORY BODY UNDER THE CONTROL OF MINISTRY OF COAL, GOVT. OF INDIA</p>
              <div className='line'></div>
              <p className='about-para'>The Coal Mines Provident Fund Organisation has been entrusted with the responsibility of administering the Coal Mines Provident Fund and Miscellaneous Provision Act, 1948 and different schemes framed there under. It is an autonomous organisation governed by a Board of Trustees and functions under the overall supervision of Ministry of Coal, Government of India.</p>
              {/* <p className='about-para'>The Coal Mines Provident Fund Organisation has been entrusted with the responsibility of administering the Coal Mines Provident Fund and Miscellaneous Provision Act, 1948 and different schemes framed there under. It is an autonomous organisation governed by a Board of Trustees and functions under the overall supervision of Ministry of Coal, Government of India.</p> */}

            </div>
          </div>
        </Grid>

        {/* Second Column */}
        <Grid item xs={12} sm={6} style={{ display: 'flex' }}>
          <Paper elevation={3} style={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CardMedia
                component="img"
                image="https://webneel.com/daily/sites/default/files/images/daily/08-2018/1-nature-photography-spring-season-mumtazshamsee.jpg"
                alt="Image"
                style={{ height: '100%', objectFit: 'cover' }}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default About;
