import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider1 from "../../images/slider12.jpeg";
import Slider2 from "../../images/slider2.jpg";
import Slider3 from "../../images/slider3.jpg";

const CarouselCmpfo = () => {
  //const images = [Slider1, Slider2, Slider3];
  const images = [Slider1];

  return (
    <div>
      <Carousel autoPlay swipeable swipeScrollTolerance={40} showThumbs={false}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselCmpfo;
