import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import LabelText from "../../FormComponent/LabelText";
import CustomTextField from "../../FormComponent/CustomTextField";
import SubtitleText from "../../FormComponent/SubtitleText";
import ValueText from "../../FormComponent/ValueText";
import MemberDetails from "./MemberDetatils";
import TableWithLinkAndButton from "../../FormComponent/TableWithLinkAndButton";
import ClaimForm from "./ClaimForm";
import CaptionText from "../../FormComponent/CaptionText";
import ClaimFormPF from "./ClaimFormPF";
import ClaimFormPension from "./ClaimFormPension";

import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect } from "react";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import { useLocation, useNavigate } from "react-router-dom";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import PostAddRoundedIcon from "@mui/icons-material/PostAddRounded";
import PeopleIcon from "@mui/icons-material/People";
import LimitedMemberDetatils from "./LimitedMemberDetatils";

function NewAddFam() {
  const [accNo, setAccNo] = useState("");
  const [cmpfAccNo, setcmpfAccNo] = useState("");
  const [member, setMember] = useState({});

  //********DEFAULT for family details*********/
  //   const familyDefault = {
  //     familyMemId: "",
  //     name: "",
  //     gender: "",
  //     relationName: "",
  //     dateOfBirth: "",
  //     pfNomSharePerc: "",
  //   };
  const defaultFamilyMemberDetailsData = {
    cmpfAccNo: "",
    familyMemId: "",
    fullName: "",
    relationName: "",
    age: "",
    maritalStatus: "",
    famMemStatus: "",
  };

  // const [nomineeDetails, setNomineeDetails] = useState([familyDefault]);
  const [familyMembersDetails, setFamilyMembersDetails] = useState([
    defaultFamilyMemberDetailsData,
  ]);

  //const [memberFound, setMemberFound] = useState(" ");
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  // ******* for declaration ******
  const [checked, setChecked] = useState(false);

  //**********for Hiding details************* */

  const [isMemberFound, setIsMemberFound] = useState("");

  //**********for integration************* */
  const [isLoading, setIsLoading] = useState(false); // set this to true
  const [isFailed, setIsFailed] = useState(false);

  //**********for Error Alerts************* */
  const [bankErrors, setBankErrors] = useState(false); // the same state is used for set errors if family member is not present in pension
  const [bankErrorMessage, setBankErrorMessage] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [familyErrorMessage, setFamilyErrorMessage] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;
  const path = pathname.split("/"); // spliting the path to check weather the page came form the pension path or the pf path from app.js
  let type = path[2];
  let title = type === "pfset" ? "PF" : "Pension";
  console.log("title is here", title);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleBankErrorAlert = () => {
    setBankErrors(false);
    navigate(-1);
  };

  // ******** setting props for the TableWithLinkAndButton component*******

  const viewLink = "familydetails"; // Link for family members details
  const maritalStatusOptions = {
    U: "Unmarried",
    M: "Married",
    W: "Widow/Widower",
    // Add more status options here
  };

  const activeOptions = {
    A: "Active",
    I: "Inactive",

    // Add more status options here
  };

  const familyMemberCreatePreviewData = () => {
    let tablePreviewData = [];
    // const familydata = familyMemberSamples;
    const familydata = familyMembersDetails;

    familydata.map((family) => {
      tablePreviewData.push({
        " Family Member Id": family.familyMemId,
        " Full Name": family.name,
        " Relation With Member": family.relationName,
        " Date of Birth": family.dateOfBirth
          ? new Date(family.dateOfBirth).toLocaleDateString("en-GB")
          : "",
        // "Marital Status": maritalStatusOptions[family.maritalStatus] || "",
        // "Family Member Status": activeOptions[family.famMemStatus] || "",
      });
    });

    return tablePreviewData;
  };

  const handleAccSearch = () => {
    setIsSearching(true);
    fetchMemberDetail();
  };

  const onChange = (e) => {
    const value = e.target.value.toUpperCase().trim();
    setcmpfAccNo(value);
    setAccNo(value);
  };

  // cmpf account number is fetched on useEffect with a delay of 3sec to avoid multiple api hits

  const sampleMemberData = {
    // This is the sample member data to be displed for demo purpose only
    title: "Miss",
    fullName: "Shreya",
    dateOfBirth: "",
    panNo: "",
    aadhaarNumber: "",
    maritalStatus: "",
    religionName: "",
    gender: "",
    fatherHusbandName: "",
    email: "",
    mobileNo: "",
    landNo: "",
    addressL1: "",
    addressL2: "",
    village: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  };
  ///****************integration started********************* */

  const fetchMemberDetail = async () => {
    setIsLoading(true);
    // console.log("in fetch Member detail");

    //  if (!(await sessionExist())) {
    //    console.log("session extending having trouble");
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");

    const url = process.env.REACT_APP_SERVER_URL + "/member/details/for/pf";
    const formData = new FormData();
    // formData.append("cmpfAccNo", accNo);
    formData.append("cmpfAccNo", cmpfAccNo);
    //console.log("formData", accNo);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        console.log("api is getting called", response);
        setIsLoading(false);
        setIsFailed(false);

        if (response.status === 200) {
          const data = response.data.data;
          console.log("response in member", response.data.data.length);
          if (data.length !== 0) {
            setIsMemberFound("true");
            if (type === "pfset") {
              if (!data[0].pfBankId) {
                setBankErrors(true);
                setErrorTitle("NOT FOUND! ");
                setBankErrorMessage(
                  "Bank account details for PF are not found. Please update the same from Update Member Details and then proceed."
                );
              }
            } else if (type === "penset") {
              if (!data[0].penBankId) {
                setBankErrors(true);
                setErrorTitle("NOT FOUND! ");
                setBankErrorMessage(
                  "Bank account details for Pension are not found. Please update the same from Update Member Details and then proceed."
                );
              }
            } else {
              if (!data[0].penBankId || !data[0].penBankId) {
                setBankErrors(true);
                setErrorTitle("NOT FOUND! ");
                setBankErrorMessage(
                  "Bank account details for PF or Pension are not available. Please update the same from Update Member Details and then proceed."
                );
              }
            }
            fetchFamilyMembersDetail();
            setMember(data[0]);
            setIsSearching(false);
            return;
          }
          setIsMemberFound("false");
          setIsSearching(false);

          return;
        }
        setIsFailed(true);
        setIsSearching(false);

        setIsMemberFound("false");
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsSearching(false);

        setIsFailed(true);
        console.log("error", error);
        setIsMemberFound("false");
      });
  };

  /***** function to get all family member details**************** */
  const fetchFamilyMembersDetail = async () => {
    setIsLoading(true);
    // console.log("in fetch Member detail");

    //  if (!(await sessionExist())) {
    //    console.log("session extending having trouble");
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");

    const url =
      process.env.REACT_APP_SERVER_URL + "/show/all/existing/family/member";
    const formData = new FormData();
    // formData.append("cmpfAccNo", accNo); // check what formdata is to be sent
    formData.append("cmpfAccNo", cmpfAccNo); // check what formdata is to be sent
    console.log("formData", formData);
    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsLoading(false);
        setIsFailed(false);
        console.log("api is getting called");
        if (response.status === 200) {
          const data = response.data.data;
          if (data.length !== 0) {
            // setNomineeDetails(data);
            setFamilyMembersDetails(data);
            // if (!data.aadhaarNo) {
            //   setBankErrors(true);
            //   setErrorTitle("NOT FOUND!");
            //   setBankErrorMessage(
            //     "Sorry claim cannot proceed. Aadhaar No for this Nominee is not present ."
            //   );

            // }

            if (type === "penset") {
              console.log("in aadhaar mapppp ");

              data.map((nominee) => {
                console.log("aadhaar no is", nominee.aadhaarNo);
                if (!nominee.aadhaarNo && nominee.pensionNominee === true) {
                  setBankErrors(true);
                  setErrorTitle("NOT FOUND!");
                  setBankErrorMessage(
                    `Sorry claim cannot proceed. Aadhaar No for ${nominee.name} not present .`
                  );
                }
              });
            }
          } else {
            if (type === "penset") {
              setBankErrors(true);
              setErrorTitle("NOT FOUND!");
              setBankErrorMessage(
                "Sorry claim cannot proceed. Nominee details for this member are not present."
              );
              return;
            }
          }
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        console.log("error", error);
      });
  };

  const handleSubmitfNewClaim = () => {
    navigate("addfammem", { state: { cmpfAccNo: cmpfAccNo } });
  };

  return (
    <div>
      <ErrorAlert
        handleOk={handleBankErrorAlert}
        open={bankErrors}
        title={errorTitle}
        body={bankErrorMessage}
        buttonName="Ok"
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            <Grid item xs={12}>
              <TitleText title={`Add Family Member`} />
              <FormDivider />
              {isMemberFound !== "true" && (
                <Grid
                  container
                  spacing={2}
                  columnGap={2}
                  justifyContent="center"
                  alignItems="center">
                  {/* ---cmpf accno textfield--- */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title=" Enter CMPF Account Number" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* not using custom textbox as i need to call specfic functions on Blur and onChange which is n ot possible with CustomTextFeild component */}
                    <TextField
                      sx={{
                        "& .MuiFormHelperText-root": {
                          marginLeft: 0,
                          // fix based on your screen
                          fontSize: {
                            xs: "9px",
                            sm: "9px,",
                            md: "9px",
                            lg: "12px",
                            xl: "14px",
                          },
                          fontWeight: "400",
                          // color: error
                          //   ? color
                          //     ? color
                          //     : "red"
                          //   : color
                          //   ? color
                          //   : "black",
                        },
                        "& .MuiInputBase-input": {
                          minWidth: "80px",
                          borderRadius: "5px",
                          color: "black",
                          background: "#ffffff",
                          // fix based on your screen
                          fontSize: {
                            xs: 12,
                            sm: 12,
                            md: 13,
                            lg: 14,
                            xl: 16,
                          },
                        },
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "5px",
                          "& fieldset": {
                            borderColor: "#474E68",
                            // borderRadius:'0px'
                          },
                          "&:hover fieldset": {
                            borderColor: "#6B728E",
                            // borderRadius: '0px'
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#0F3460",
                            borderWidth: "0.5px",
                            // borderRadius: '0px'
                          },
                        },
                      }}
                      autoComplete="off"
                      // id="userName"
                      //   value={accNo}
                      value={cmpfAccNo}
                      // onBlur={onAccBlur}
                      // label="Username"
                      //   name="accNo"
                      name="cmpfAccNo"
                      onChange={onChange}
                      variant="outlined"
                      placeholder="CMPF Account Number "
                      // error={Boolean(userNameError)}
                      // helperText={
                      //   Boolean(userNameError)
                      //     ? userNameError
                      //     : "Account number cannot exceed 20 characters."
                      // }
                      fullWidth
                      required
                      inputProps={{
                        maxLength: 20,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Button
                      variant="contained"
                      startIcon={<SearchIcon />}
                      sx={{ "& .MuiButton-startIcon": { margin: "0 auto" } }}
                      onClick={handleAccSearch}
                      disabled={isLoading}>
                      {isSearching ? <CircularProgress /> : ""}
                    </Button>
                  </Grid>
                </Grid>
              )}
              {/* --- cmpf acc no field end --- */}

              {isMemberFound === "true" && (
                <>
                  {/* Member Details*/}

                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="start"
                    rowGap={3}>
                    {/* section 1  */}
                    <Grid item xs={12}>
                      <LimitedMemberDetatils member={member} />
                    </Grid>

                    {/* ************************************** */}
                    {/* Existing Family Member Details  */}

                    {/* section 3  */}

                    <Grid item xs={6} sm={3} marginLeft="75%">
                      <Button
                        //disabled={isSearching}
                        onClick={handleSubmitfNewClaim}
                        variant="contained"
                        startIcon={<PeopleIcon />}
                        color="success"
                        disabled={isLoading}
                        fullWidth
                        type="submit">
                        Add Family Member
                      </Button>
                    </Grid>

                    <Grid item xs={12}>
                      <SubtitleText title="Family Members Details" />
                    </Grid>
                    {/* 
                    <Grid item xs={12} sm={12}>
                      <TableWithLinkAndButton
                        preview={createTablePreviewDataReturn()}
                        data={nomineeDetails}
                        passingId="familyMemberid"
                        actions=""
                       
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={12}>
                      <TableWithLinkAndButton
                        preview={familyMemberCreatePreviewData()}
                        data={familyMembersDetails}
                        passingId="familyMemId"
                        actions=""
                        viewLink={viewLink}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              {isMemberFound === "false" && (
                <Typography color="red">Member not found</Typography>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default NewAddFam;
