import { Button, CircularProgress, Grid, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import SubtitleText from "../../FormComponent/SubtitleText";
import MemberDetails from "../UDAPages/MemberDetatils";
import BackButton from "../../components/button/BackButton";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ViewDocuments from "../../SettlementComponents/ViewDocuments";
import CustomTextArea from "../../FormComponent/CustomTextArea";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import SucessAlert from "../../FormComponent/SuccessAlert";
import ConfirmationDialogue from "../../FormComponent/ConfirmationDialogue";
import UpdatedFieldTableForMem from "../UaoPages/UpdatedFieldTableForMem";
import jwtDecode from "jwt-decode";

function ViewUpdMemAao() {
  const { state } = useLocation();
  const memId = state.passingId;
  const memType = memId.substring(0, 3);

  const navigate = useNavigate();
  const sampleMemberData = {
    // This is the sample claimData data to be displed for demo purpose only
    titleName: "",
    firstName: "",
    middleName: "",
    lastName: "",
    fatherHusbandName: "",
    gender: "",
    maritalStatus: "",
    religionName: "",
    dateOfBirth: "",
    addressL1: "",
    addressL2: "",
    mobileNo: "",
    landNo: "",
    email: "",
    panNo: "",
    aadhaarNumber: "",
    titleId: "",
    religionId: "",
    aadharId: "",
    panId: "",
    city: "",
    district: "",
    state: "",
    pinCode: "",
    village: "",
    country: "",
    employeeId: "",
    unitId: "",
    unitName: "",
    name: "",
    joiningDate: "",
    pfStartDate: "",
    pfEndDate: "",
    ifscPf: "",
    bankAccNoPf: "",
    ifscPen: "",
    bankAccNoPen: "",
    cmpfAccNo: "",
    bankAddressPf: "",
    bankAddressPen: "",
    pfBankName: "",
    penBankName: "",
    addressL1Pf: "",
    addressL2Pf: "",
    cityPf: "",
    districtPf: "",
    statePf: "",
    pincodePf: "",
    addressL1Pension: "",
    addressL2Pension: "",
    cityPension: "",
    districtPension: "",
    statePension: "",
    pincodePension: "",
  };

  const sampleClaimData = {
    tUpdMemDetls: {
      updMemId: "",
      verifyStatus: "",
      verifyDate: "",
      updStatus: "",
      updatedDate: "",
      updReqAckId: "",
      fieldName: "",
      fieldsUpdated: "",
      oldField_val: "",
      newFieldVal: "",
      updateRemarks: "",
      loginId: "",
      memProfId: "",
      nameProof: "",
      dobProof: "",
      miscProof: null,
      maritalProof: "",
      addressProof: "",
      updReqTrkNo: "",
      statusId: "",
    },
    cmpfAcNo: "",
  };

  const [memUpdReqdetails, setMemUpdReqdetails] = useState(sampleClaimData);
  const [memberData, setMemberData] = useState(sampleMemberData);
  const [memberUpdData, setMemberUpdData] = useState(sampleMemberData);

  const [isMemberFound, setIsMemberFound] = useState(" ");
  const [documents, setDocuments] = useState([]);
  const [assignToOption, setAssignToOption] = useState([]);

  //**********for integration************* */
  const [isLoading, setIsLoading] = useState(true); // set this to true
  const [isLoadingDoc, setIsLoadingDoc] = useState(true); // set this to true
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isFailedDoc, setIsFailedDoc] = useState(false);
  const [isReturning, setIsReturning] = useState(false);
  const [isReturnClicked, setIsReturnClicked] = useState(false);
  const [showingDropdown, setShowingDropdown] = useState(0);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [selectedOfficerName, setSelectedOfficerName] = useState("");

  const defaultClaim = {
    remarks: "",
  };

  // declaring the default api object

  let api = {
    memUrl: "",
    docsUrl: "",
    returnUrl: "",
    forwardUrl: "",
    forwordAgmUrl: "",
    officerDropdownUrl: "",
    memberDetailsUrl: "",
  };
  // object for pfapis
  //let loginId = "AAO-CC-OFF-1047"; // Assuming a default loginId
  const [loginId, setLoginId] = useState();

  /********fetching and setting token********* */
  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (token) {
      token = token.replace('"', "").replace('"', "");
      let loginId = jwtDecode(token).user; // Assuming a default loginId
      setLoginId(loginId);
      // console.log("loooooooooooooooooooooginId", loginId);
      // Add any further logic here based on the loginId
    } else {
      // Handle the case when token is not present
      setIsLoading(false);
      setIsFailed(true);
    }
  }, []);
  const memApis = {
    memUrl: "/get/update/member/details/by/id",
    docsUrl: "/fetch/update/member/details/docs/service",
    returnUrl: "/return/update/member/details/aao/to/uao",
    forwardUrl: "/aprove/update/member/details",
    officerDropdownUrl: "/dropdown/for/update/member/details/by/uao",
    memberDetailsUrl: "/member/details/for/pf",
  };

  if (memType === "MEM") {
    api = { ...memApis };
  }

  const [claim, setClaim] = useState(defaultClaim);

  const remarks = {
    name: "remarks",
    type: "text",
    multiline: true,
    helperText: "Enter your remarks (maximum 200 words)",
    placeholder: "Enter your remarks here",
    defaultValue: claim.remarks,
    errorMessage: "Cannot exceed 200 characters",
    pattern: "^.{0,200}$",
    maxLength: 200, // Limiting to 200
  };

  //****************Dropdown******************* */
  const fetchAssignOfficer = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + api.officerDropdownUrl;
      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      const options = data.map((option) => ({
        id: option.loginId,
        title: `${option.name} (${option.loginId})`,
      }));
      setAssignToOption(options); // Assuming setAssignToOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchAssignOfficer();
    fetchAllClaimDetail();
  }, [loginId]);

  const fetchMemUpdDetail = async () => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    // const url = process.env.REACT_APP_SERVER_URL + "/show/pf/claim/on/claimid";
    const url = process.env.REACT_APP_SERVER_URL + api.memUrl;

    const formData = new FormData();
    formData.append("updReqAckId", memId);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          console.log("dataaaaa", data);
          setMemUpdReqdetails(data);
          fetchMemberDetail(data.cmpfAcNo);
          fetchupdMember(data.cmpfAcNo);
          fetchDocuments(data.tUpdMemDetls.memProfId);
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
      });
  };

  const fetchAllClaimDetail = () => {
    fetchMemUpdDetail();
  };

  const fetchDocuments = async (memberId) => {
    try {
      // const url = `${process.env.REACT_APP_SERVER_URL}/show/pf/claim/uploaded/documents/claimid`;
      const url = process.env.REACT_APP_SERVER_URL + api.docsUrl;

      const formData = new FormData();
      formData.append("memberId", memberId);
      const config = {
        headers: {
          // Include the Authorization header if req uired
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(url, formData, config);

      setIsLoadingDoc(false);
      setIsFailedDoc(false);

      if (response.status === 200) {
        const data = response.data.data;
        setDocuments(data);
      } else {
        setIsFailed(true);
      }
    } catch (error) {
      // Corrected syntax for the catch block
      setIsLoading(false);
      setIsFailed(true);
    }
  };

  useEffect(() => {
    fetchAllClaimDetail();
    // fetchDocumnetAdv();
  }, []);

  const fetchMemberDetail = async (accNo) => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + api.memberDetailsUrl;
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          if (data.length !== 0) {
            setMemberData(data[0]);
            setIsMemberFound("true");
          }
          setIsMemberFound("false");
          return;
        }
        setIsFailed(true);
        setIsMemberFound("false");
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        setIsMemberFound("false");
      });
  };
  const fetchupdMember = async (accNo) => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url =
      process.env.REACT_APP_SERVER_URL + "/search/editablefields/forUDA";
    const formData = new FormData();
    formData.append("searchFieldValue", accNo);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          // console.log("dataaa", data);
          if (data.length !== 0) {
            setMemberUpdData(data[0]);
            setIsMemberFound("true");
          }
          setIsMemberFound("false");
          return;
        }
        setIsFailed(true);
        setIsMemberFound("false");
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        setIsMemberFound("false");
      });
  };
  // cmpf account number is fetched on useEffect with a delay of 3sec to avoid multiple api hits
  const onChange = (e) => {
    if (e.target.name === "assignTo") {
      const selectedOption = assignToOption.find(
        (option) => option.id === e.target.value
      );
      if (selectedOption) {
        setSelectedOfficerName(selectedOption.title); // Set the selected officer's name in the state
        // console.log("selctess", selectedOption.title);
      }
    }

    setClaim({ ...claim, [e.target.name]: e.target.value });
  };

  const { tUpdMemDetls } = memUpdReqdetails;
  const { fieldsUpdated, newFieldVal } = tUpdMemDetls;
  console.log("fieldsUpdated", fieldsUpdated);
  console.log("newFieldVal", newFieldVal);
  const [updateMem, setUpdateMem] = useState([]);
  const memberCreatePreviewData = () => {
    if (fieldsUpdated && newFieldVal) {
      const cleanedData = fieldsUpdated.map((field, index) => ({
        FieldName: field.replace(/"/g, ""),
        NewValue: newFieldVal[index].replace(/"/g, ""),
      }));

      setUpdateMem(cleanedData);
    } else {
      return [];
    }
  };
  useEffect(() => {
    memberCreatePreviewData();
  }, [fieldsUpdated, newFieldVal]);

  /// ******** on forword to AAO function

  const objectTobeSent = {
    loginId: loginId,
    remarks: claim.remarks,
    updReqAckId: memId,
    updateRemark: "",
    toOffcrloginId: claim.assignTo,
    titleName: "",
    firstName: "",
    middleName: "",
    lastName: "",
    fullName: "",
    fatherHusbandName: "",
    gender: "",
    maritalStatus: "",
    religionName: "",
    dateOfBirth: "",
    dob: "",
    addressL1: "",
    addressL2: "",
    mobileNo: "",
    landNo: "",
    email: "",
    panNo: "",
    aadhaarNumber: "",
    titleId: "",
    religionId: "",
    aadharId: "",
    panId: "",
    city: "",
    district: "",
    state: "",
    pincode: "",
    village: "",
    country: "",
    employeeId: "",
    unitId: "",
    unitIdd: "",
    unitName: "",
    name: "",
    joiningDate: "",
    pfStartDate: "",
    pfEndDate: "",
    ifscPf: "",
    bankAccNoPf: "",
    ifscPen: "",
    bankAccNoPen: "",
    cmpfAccNo: "",
    bankAddressPf: "",
    bankAddressPen: "",
    bankNamePf: "",
    bankNamePen: "",
    addressL1Pf: "",
    addressL2Pf: "",
    cityPf: "",
    districtPf: "",
    statePf: "",
    pincodePf: "",
    addressL1Pension: "",
    addressL2Pension: "",
    cityPension: "",
    districtPension: "",
    statePension: "",
    pincodePension: "",
  };
  // Populate bankAddressPf and bankAddressPen after objectTobeSent is declared

  // Iterate over updateMem and update objectTobeSent
  // Iterate over objectTobeSent and update missing fields from memberUpdData
  Object.keys(objectTobeSent).forEach((key) => {
    if (!updateMem.hasOwnProperty(key) && memberUpdData.hasOwnProperty(key)) {
      // If the field is not in updateMem, fetch it from memberUpdData
      objectTobeSent[key] = memberUpdData[key];
      // console.log(key, memberUpdData[key], updateMem[key]);
    }
  });

  // Iterate over updateMem and update objectTobeSent
  Object.keys(updateMem).forEach((key) => {
    const updateField = updateMem[key].FieldName;
    const newValue = updateMem[key].NewValue;

    if (objectTobeSent.hasOwnProperty(updateField)) {
      // Update the field in objectTobeSent with the new value
      objectTobeSent[updateField] = newValue;
    }
  });

  objectTobeSent.bankAddressPf = JSON.stringify({
    addr_l1: objectTobeSent.addressL1Pf,
    addr_l2: objectTobeSent.addressL2Pf,
    "village/town/city": objectTobeSent.cityPf,
    district: objectTobeSent.districtPf,
    state: objectTobeSent.statePf,
    pin_code: objectTobeSent.pincodePf,
  });

  objectTobeSent.bankAddressPen = JSON.stringify({
    addr_l1: objectTobeSent.addressL1Pension,
    addr_l2: objectTobeSent.addressL2Pension,
    "village/town/city": objectTobeSent.cityPension,
    district: objectTobeSent.districtPension,
    state: objectTobeSent.statePension,
    pin_code: objectTobeSent.pincodePension,
  });
  // objectTobeSent.dob = objectTobeSent.dateOfBirth;
  if (!objectTobeSent.dob) {
    objectTobeSent.dob = objectTobeSent.dateOfBirth;
  }
  // objectTobeSent.unitIdd = objectTobeSent.unitId;
  // Now objectTobeSent is updated with the values from updateMem and memberData
  console.log("memberUpdData", memberUpdData);
  console.log("objectTobeSent to", objectTobeSent);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    const url = process.env.REACT_APP_SERVER_URL + api.forwardUrl;

    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSent, { headers })
      .then(function (response) {
        setIsSubmitting(false);
        setIsSuccess(true);
        setSuccessMessage("Member Update Request Approved Successfully");
      })
      .catch((error) => {
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
        console.log("error on submit", error);
      });
  };

  /// ******** on Return function

  const objectTobeSentOnReturn = {
    fromOffcrLoginId: loginId,
    remarks: claim.remarks,
    toOffcrLoginId: claim.assignTo,
    updReqAckId: memId,
  };

  const handleReturnClick = (e) => {
    e.preventDefault();

    if (!claim.remarks.trim()) {
      // Show message in dialog: "Please enter remarks"
      // alert("Please enter remarks");
      setIsError(true);
      setErrorMessage("Please enter Remarks.");
      return;
    }

    setIsReturning(true);
    setIsReturnClicked(true);
  };

  const handleReturnClaim = (e) => {
    // e.preventDefault();

    // const url =
    //   process.env.REACT_APP_SERVER_URL + "/verification/pf/claim/officers";
    const url = process.env.REACT_APP_SERVER_URL + api.returnUrl;
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSentOnReturn, { headers })
      .then(function (response) {
        setIsReturning(false);
        setIsSuccess(true);
        setSuccessMessage("Member Update Request Returned Successfully.");
        setIsReturnClicked(false);
      })
      .catch((error) => {
        console.log("error on submit", error);
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
      });
  };

  ///****************integration started********************* */

  /// error alert handler
  const handleErrorAlert = () => {
    setIsError(false);
    setIsLoading(false);
  };

  const handleSuccess = () => {
    // setIsSuccess(false);
    navigate("/aao/updmemreq");
  };

  const handleCancelReturn = () => {
    setIsReturnClicked(false);
  };
  const handleReturnOk = () => {
    handleReturnClaim();
  };

  const handleIdsOk = (e) => {
    setIsSubmitClicked(true);
  };

  // todo handleAssignToOk calling handle submit on click of ok confirmation dialouge box
  const handleAssignToOk = (e) => {
    handleSubmit(e);
    setIsSubmitClicked(false);
  };

  const handleAssignToCancel = () => {
    setIsSubmitClicked(false);
  };

  return (
    <div>
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error!"
        body={errorMessage}
        buttonName="Ok"
      />
      <SucessAlert
        handleOk={handleSuccess}
        open={isSuccess}
        title="Success"
        body={successMessage}
        buttonName="OK"
      />
      <ConfirmationDialogue
        handleOk={handleAssignToOk}
        handleCancel={handleAssignToCancel}
        open={isSubmitClicked}
        title="Confirm Approval"
        body={`Are you sure you want to approve Member Update Request?`}
        buttonName="Confirm"
      />

      <ConfirmationDialogue
        handleOk={handleReturnOk}
        handleCancel={handleCancelReturn}
        open={isReturnClicked}
        title="Confirm Return"
        body="Are you sure you want to return this Member Update Request?"
        buttonName="Confirm"
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "10px" }}>
            {/* {isFailed || isFailedDoc ? (
              <Reloader refreshHandler={fetchAllClaimDetail} />
            ) : (
              <>
                {isLoading || isLoadingDoc ? (
                  <LoadingIndicator />
                ) : ( */}
            <>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <TitleText title="Update Member Details Approval" />
                </Grid>
              </Grid>
              <FormDivider />
              <Grid container spacing={1}>
                <Grid item xs={12} md={1} mb={3}>
                  <BackButton />
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="start"
                  rowGap={3}
                >
                  {/* Member Details*/}
                  {/* section 1  */}
                  <Paper
                    elevation={3}
                    sx={{ padding: "3rem", borderRadius: "10px" }}
                  >
                    <Grid item xs={12}>
                      <MemberDetails member={memberData} />
                    </Grid>
                  </Paper>
                  {/* claim Details*/}
                  {/* section 2  */}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={12}>
                      <Paper
                        elevation={3}
                        sx={{ padding: "3rem", borderRadius: "10px" }}
                      >
                        <Grid item xs={12}>
                          <UpdatedFieldTableForMem
                            memUpdReqdetails={memUpdReqdetails}
                          />
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                  {/* Document Details*/}
                  {/* section 3  */}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={12}>
                      <Paper
                        elevation={3}
                        sx={{ padding: "3rem", borderRadius: "10px" }}
                      >
                        <Grid item xs={12}>
                          <ViewDocuments documents={documents} />
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>

                  {/* ************************************ Remarks ***************************************** */}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={12}>
                      <Paper
                        elevation={3}
                        sx={{ padding: "2rem", borderRadius: "10px" }}
                      >
                        <SubtitleText title="Remarks" />
                        <Grid item xs={12} sm={6} md={8}>
                          <CustomTextArea {...remarks} onchange={onChange} />
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                  {/* *************** Submit Button ********* */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Grid item>
                      <Button
                        onClick={handleReturnClick}
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isReturnClicked}
                      >
                        {isReturnClicked ? <CircularProgress /> : "Return"}
                      </Button>
                    </Grid>
                    <Grid item md={6}>
                      <Grid container justifyContent="end" mb={2} spacing={2}>
                        <Grid item md={5}>
                          <Button
                            onClick={handleIdsOk}
                            variant="contained"
                            color="success"
                            type="submit"
                            disabled={isSubmitting}
                            fullWidth
                          >
                            {isSubmitting ? <CircularProgress /> : "approve"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default ViewUpdMemAao;
