import * as React from "react";
import { useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Navigate, useHistory, useLocation } from "react-router-dom";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import Diversity3RoundedIcon from "@mui/icons-material/Diversity3Rounded";
import ContactPageRoundedIcon from "@mui/icons-material/ContactPageRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { Button, CircularProgress, ListItemButton } from "@mui/material";
import MenuList from "@mui/material/MenuList";

import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";

import { useMediaQuery } from "@mui/material";
import {
  AccountCircle,
  Mail,
  Notifications,
  Pets,
  PowerSettingsNewRounded,
} from "@mui/icons-material";
import { Avatar, Badge, InputBase, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import TimelineIcon from "@mui/icons-material/Timeline";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";

import TableChartRoundedIcon from "@mui/icons-material/TableChartRounded";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";

import Zoom from "@mui/material/Zoom";

import { CSSTransition } from "react-transition-group";

import { keyframes } from "@mui/material";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "../styles/stylesheet.css";
import Header from "../components/Header";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import BadgeIcon from "@mui/icons-material/Badge";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { UserContext } from "../UiManager/UserProvider";
import LockResetIcon from "@mui/icons-material/LockReset";
import SubtitleText from "../FormComponent/SubtitleText";
import jwtDecode from "jwt-decode";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import axios from "axios";
import {
  trackUserActivity,
  clearUserActivityTracking,
  startSessionTimeout,
} from "../session/EventManager";
import ErrorAlert from "../FormComponent/ErrorAlert";
import LogoutAlert from "../FormComponent/LogOutAlert";
import { Container } from "react-bootstrap";

const drawerWidth = 180; //280

// linear - gradient(0deg, rgba(52, 221, 122, 1) 16 %, rgba(35, 130, 74, 1) 61 %)  greenish gradient

// style constants
const appbarColor =
  "linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(235,68,68,1) 50%, rgba(252,176,69,1) 96%)";
const sidebarTextColor = "#fff";
const sidebarColor =
  "linear-gradient(180deg, rgba(131,58,180,1) 0%, rgba(235,68,68,1) 50%, rgba(252,176,69,1) 96%)";
const sidebarIconColor = "#fff";
const appbarTextColor = "#fff";
const navActiveColor = "rgba(255, 255, 255, 0.5)";
const drawerHeaderColor =
  "linear-gradient(0deg, rgba(228,112,112,1) 0%, rgba(131,58,180,1) 100%)";

const menuItems = [
  {
    text: "Account Summary",
    icon: <BadgeIcon style={{ color: sidebarIconColor }} />,
    path: "",
    state: {},
  },
  {
    text: "PF Statement",
    icon: <AccountBalanceIcon style={{ color: sidebarIconColor }} />,
    path: "pf",
    state: {},
  },
  {
    text: "Pension Statement",
    icon: <AccountBalanceWalletIcon style={{ color: sidebarIconColor }} />,
    path: "pension",
    state: {},
  },

  {
    text: "Change Password",
    icon: <LockResetIcon style={{ color: sidebarIconColor }} />,
    path: "changePassword",
    state: {},
  },
  {
    text: "View Profile",
    icon: <PersonPinIcon style={{ color: sidebarIconColor }} />,
    path: "profile",
    state: {},
  },
  // {
  //   text: "Add Family Member",
  //   icon: <PersonAddIcon style={{ color: sidebarIconColor }} />,
  //   path: "newfam",
  //   state: { edit: false, view: false },
  // },
  // {
  //   text: "View Family Members",
  //   icon: <SupervisorAccountIcon style={{ color: sidebarIconColor }} />,
  //   path: "famlist",
  //   state: {},
  // },
  {
    text: "Grievances",
    icon: <SupervisorAccountIcon style={{ color: sidebarIconColor }} />,
    path: "grievances",
    state: {},
  },
  {
    text: "Track Claim",
    icon: <TimelineIcon color="secondary" />,
    path: "track",
    state: {},
  },

  {
    text: "Track Grievance",
    icon: <TimelineIcon color="secondary" />,
    path: "trackTableGriv",
    state: {},
  },
];

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(5),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,

    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: drawerHeaderColor,
  padding: theme.spacing(0, 2),
  ...theme.mixins.toolbar,
  justifyContent: "center", // Default justifyContent value

  [theme.breakpoints.up("sm")]: {
    justifyContent: "flex-end", // Custom justifyContent value for 'sm' and above breakpoints
  },

  "@media all": {
    minHeight: 45,
    maxHeight: 65,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  "@media all": {
    minHeight: 45,
    maxHeight: 65,
  },
}));

const Icons = styled(Box)(({ theme }) => ({
  display: "none",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

const UserBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const Search = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  padding: "0 10px",
  borderRadius: theme.shape.borderRadius,
  width: "40%",
}));

export default function MemberDbLayout(props) {
  const userContext = useContext(UserContext);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [isBooting, setIsBooting] = useState(true);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();

  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [isLogoutClicked, setIsLogOutClicked] = useState(false);
  const [isSessionTimeOut, setIsSessionTimeout] = useState(false);
  const [isLogouting, setIsLouting] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  const setVisitorCountApi = async () => {
    const url =
      process.env.REACT_APP_SERVER_URL + "/increasevisitorcountafterlogin";

    const requestBody = new FormData();
    requestBody.append("vId", parseInt(1));

    console.log("passed data", requestBody);

    try {
      const response = await axios.post(url, requestBody); // Use axios.post instead of fetch
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const checkUserAuth = () => {
    const requiredRoles = ["MEM"];
    const token = localStorage.getItem("jwt");
    if (!token) {
      navigate("/cmpfo", { replace: true });
      return;
    }
    const data_decoded = jwtDecode(token);
    if (!requiredRoles.includes(data_decoded.role)) {
      navigate("/accessdenied", { replace: true });
      return;
    }
    setIsBooting(false);
  };

  useEffect(() => {
    setVisitorCountApi();
    checkUserAuth();
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let headerText = "C-CARES";

  const FireNav = styled(List)({
    "& .MuiListItemButton-root": {
      paddingLeft: 12,
      paddingRight: 12,
      justifyContent: "center",
    },
    "& .MuiListItemIcon-root": {
      minWidth: 0,
      marginRight: 8,
      alignItems: "center",
    },
    "& .MuiSvgIcon-root": {
      fontSize: 18,
    },
  });

  const drawer = (
    <div>
      {/* <Divider style={{ backgroundColor: '#ffffff', height: '2px' }}/> */}
      <FireNav component="nav" disablePadding align="center">
        {menuItems.map((item, index) => (
          <ListItemButton
            key={index}
            alignItems="center"
            onClick={() => {
              console.log(
                location.pathname
                  .split("/")
                  .slice(location.pathname.split("/").indexOf("member") + 1)
                  .join("/")
              );
              handleDrawerToggle();
              navigate(item.path, { replace: true, state: item.state });
            }}
            className="py-2"
            style={
              location.pathname
                .split("/")
                .slice(location.pathname.split("/").indexOf("member") + 1)
                .join("/") === item.path
                ? { background: navActiveColor }
                : { background: "" }
            }
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="body1"
                  align="left"
                  style={{
                    color: "#fff",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  {item.text}
                </Typography>
              }
            />
          </ListItemButton>
        ))}
      </FireNav>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  React.useEffect(() => {
    if (isMatch) {
      setOpen(true);
    } else {
      //setOpen(false)
    }
  }, [isMatch]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenuNew = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const tooltipContent = (
    <div style={{ backgroundColor: "blue", color: "white", padding: "10px" }}>
      <span style={{ fontSize: "16px" }}>John</span>
    </div>
  );

  const flipInHorBottom = keyframes`
    0% {
        -webkit-transform: rotateX(80deg);
                transform: rotateX(80deg);
        opacity: 0;
      }
      100% {
        -webkit-transform: rotateX(0);
                transform: rotateX(0);
        opacity: 1;
      }ainBackgroundColor 
`;

  const loggoutServer = () => {
    //logout axios
    let token = localStorage.getItem("jwt");
    if (!token) {
      return;
    }
    token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + "/member/logout";

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios.post(url, null, config).then((resp) => {
      console.log(resp.data.data);
    });
  };

  // const handleExit = () => {
  //     setIsLogOutClicked(false);
  //     loggoutServer();
  //     localStorage.clear();
  //     sessionStorage.clear();
  //     navigate('/', { replace: true })
  // }

  // session handler initilize useeffect
  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (!token) {
      navigate("/cmpfo", { replace: true });
      return;
    }
    const data_decoded = jwtDecode(token);
    console.log("idle time", data_decoded);

    const activityEvents = [
      "mousemove",
      "click",
      "keydown",
      "scroll",
      "touchstart",
    ];
    // const sessionTimeout = 1 * 60 * 1000; // 2 minutes session
    const sessionTimeout = data_decoded.idealTime * 60 * 1000; // default minutes session

    const handleSessionTimeout = () => {
      setIsSessionTimeout(true);
      loggoutServer();
      localStorage.clear();
      sessionStorage.clear();
      // Perform the timeout action specific to this dashboard (e.g., redirect to login page)
      console.log("Session expired for this dashboard");
    };

    trackUserActivity(activityEvents, sessionTimeout, handleSessionTimeout);
    setIsBooting(false);
    return () => {
      clearUserActivityTracking(activityEvents);
    };
  }, []);

  const handleSessionTimeoutAlertClick = () => {
    setIsSessionTimeout(false);
    navigate("/cmpfo", { replace: true });
  };

  const handleDialogueCancel = () => {
    setIsLogOutClicked(false);
  };

  const handleLogOut = () => {
    //logout button clicked show popup
    setIsLogOutClicked(true);
  };

  const handleLogoutCancel = () => {
    setIsLogOutClicked(false);
  };

  return (
    <div>
      {isBooting ? (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ height: "100vh" }}
        >
          <Grid item>
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <>
          <Header />
          {isMatch ? (
            <Box sx={{ display: "flex", position: "absolute", width: "100%" }}>
              <CssBaseline />
              <AppBar
                style={{ background: appbarColor, color: appbarTextColor }}
                position="absolute"
                sx={{
                  width: { sm: `calc(100 % - ${drawerWidth}px)` },
                  ml: { sm: `${drawerWidth}px` },
                }}
              >
                <StyledToolbar>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: "none" } }}
                  >
                    <MenuIcon />
                  </IconButton>

                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{
                      color: "#fff",
                      fontSize: {
                        xs: "13px",
                        sm: "13px",
                        md: "14px",
                        lg: "15px",
                        xl: "16px",
                      },
                      fontWeight: "500",
                    }}
                  >
                    {headerText}
                  </Typography>
                  {/* <Search>
                                <InputBase placeholder="search..." />
                            </Search> */}
                  <Button
                    variant="outlined"
                    onClick={handleLogOut}
                    sx={{
                      color: "white",
                      borderColor: "white",
                      "&:hover": {
                        borderColor: "white",
                        background: "red",
                      },
                    }}
                    startIcon={
                      <PowerSettingsNewRounded
                        sx={{
                          color: "white",
                        }}
                      />
                    }
                  >
                    <Typography
                      variant="h6"
                      noWrap
                      component="div"
                      sx={{
                        color: "#fff",
                        fontSize: {
                          xs: "10px",
                          sm: "10px",
                          md: "12px",
                          lg: "14px",
                          xl: "15px",
                        },
                        fontWeight: "600",
                      }}
                    >
                      LogOut
                    </Typography>
                  </Button>
                </StyledToolbar>
              </AppBar>
              <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
              >
                <Drawer
                  container={container}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                  sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                      background: sidebarColor,
                      boxSizing: "border-box",
                      width: drawerWidth,
                    },
                  }}
                >
                  <DrawerHeader>
                    {/* {headerText} */}
                    <Typography
                      variant="h6"
                      noWrap
                      component="div"
                      sx={{
                        color: "#fff",
                        fontSize: {
                          xs: "13px",
                          sm: "13px",
                          md: "14px",
                          lg: "15px",
                          xl: "16px",
                        },
                        fontWeight: "500",
                      }}
                    >
                      {headerText}
                    </Typography>
                  </DrawerHeader>
                  {drawer}
                </Drawer>
                <Drawer
                  variant="permanent"
                  sx={{
                    display: { xs: "none", sm: "block" },
                    "& .MuiDrawer-paper": {
                      background: sidebarColor,
                      boxSizing: "border-box",
                      width: drawerWidth,
                    },
                  }}
                  open
                >
                  <DrawerHeader>
                    <Typography
                      variant="h6"
                      noWrap
                      component="div"
                      sx={{
                        color: "#fff",
                        fontSize: {
                          xs: "13px",
                          sm: "13px",
                          md: "14px",
                          lg: "15px",
                          xl: "16px",
                        },
                        fontWeight: "500",
                      }}
                    >
                      {headerText}
                    </Typography>
                  </DrawerHeader>
                  {drawer}
                </Drawer>
              </Box>
              <Main open={open}>
                <StyledToolbar />
                <ErrorAlert
                  handleOk={handleSessionTimeoutAlertClick}
                  open={isSessionTimeOut}
                  title="You have been signed out"
                  body="Your organisation's policy enforces automatic sign out after a period of inactivity or on page refresh on C-CARES web application."
                  buttonName="Sign In"
                />
                {/* <ConfirmationDialogue handleOk={handleExit} handleCancel={handleDialogueCancel} title="Confirm Exit..!" body="Are you sure, you want to exit?" open={isLogoutClicked} buttonName='Yes'/> */}
                <LogoutAlert
                  closeAlert={handleLogoutCancel}
                  open={isLogoutClicked}
                  title="Are you sure?"
                  body="Do you want to exit?"
                  user="member"
                />
                <Outlet />
              </Main>
            </Box>
          ) : (
            <Box sx={{ display: "flex", position: "absolute", width: "100%" }}>
              <AppBar
                style={{
                  background: appbarColor,
                  color: appbarTextColor,
                }}
                position="absolute"
                open={open}
              >
                <StyledToolbar>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{ mr: 2, ...(open && { display: "none" }) }}
                  >
                    <MenuIcon />
                  </IconButton>

                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{
                      color: "#fff",
                      fontSize: {
                        xs: "13px",
                        sm: "13px",
                        md: "14px",
                        lg: "15px",
                        xl: "16px",
                      },
                      fontWeight: "500",
                    }}
                  >
                    {headerText}
                  </Typography>

                  <UserBox onClick={(e) => setOpenMenu(true)}>
                    <Typography
                      variant="h6"
                      noWrap
                      component="div"
                      sx={{
                        color: "#fff",
                        fontSize: {
                          xs: "13px",
                          sm: "13px",
                          md: "14px",
                          lg: "15px",
                          xl: "16px",
                        },
                        fontWeight: "500",
                      }}
                    >
                      {userContext.currentUser.name}
                    </Typography>

                    <Button
                      variant="outlined"
                      onClick={handleLogOut}
                      sx={{
                        color: "white",
                        borderColor: "white",
                        "&:hover": {
                          borderColor: "white",
                          background: "red",
                        },
                      }}
                      startIcon={
                        <PowerSettingsNewRounded
                          sx={{
                            color: "white",
                          }}
                        />
                      }
                    >
                      <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{
                          color: "#fff",
                          fontSize: {
                            xs: "10px",
                            sm: "10px",
                            md: "12px",
                            lg: "14px",
                            xl: "15px",
                          },
                          fontWeight: "600",
                        }}
                      >
                        LogOut
                      </Typography>
                    </Button>
                  </UserBox>
                </StyledToolbar>
              </AppBar>

              <Drawer
                sx={{
                  height: "100vh",
                  width: drawerWidth,
                  flexShrink: 0,

                  "& .MuiDrawer-paper": {
                    background: sidebarColor,
                    position: "absolute",
                    width: drawerWidth,
                    boxSizing: "border-box",
                    height: "100%",
                  },
                }}
                variant="persistent"
                anchor="left"
                open={open}
              >
                <DrawerHeader>
                  <IconButton
                    style={{ color: sidebarTextColor, fontSize: "10px" }}
                    onClick={handleDrawerClose}
                  >
                    {theme.direction === "ltr" ? (
                      <ArrowBackIosNewOutlinedIcon
                        style={{ color: sidebarTextColor, fontSize: 20 }}
                      />
                    ) : (
                      <ChevronRightIcon style={{ color: sidebarTextColor }} />
                    )}
                  </IconButton>
                </DrawerHeader>
                {drawer}
              </Drawer>
              <Main open={open}>
                <StyledToolbar />
                <ErrorAlert
                  handleOk={handleSessionTimeoutAlertClick}
                  open={isSessionTimeOut}
                  title="You have been signed out"
                  body="Your organisation's policy enforces automatic sign out after a period of inactivity or on page refresh on C-CARES web application."
                  buttonName="Sign In"
                />
                <LogoutAlert
                  closeAlert={handleLogoutCancel}
                  open={isLogoutClicked}
                  title="Are you sure?"
                  body="Do you want to exit?"
                  user="member"
                />
                {/* <ConfirmationDialogue handleOk={handleExit} handleCancel={handleDialogueCancel} title="Confirm Exit..!" body="Are you sure, you want to exit?" open={isLogoutClicked} buttonName='Yes' /> */}
                <Outlet />
              </Main>
            </Box>
          )}
        </>
      )}
    </div>
  );
}
