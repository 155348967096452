import React, { useState, useEffect } from 'react'
import { Grid, Paper, Button, Divider, Typography } from '@mui/material';
import TitleText from '../../FormComponent/TitleText';
import LabelText from '../../FormComponent/LabelText';
import GroupedRadioButton from '../../FormComponent/GroupedRadioButton';
import CustomTextField from '../../FormComponent/CustomTextField';
import FormTable from '../../FormComponent/FormTable';
import FormDivider from '../../FormComponent/FormDivider';
import YearDropdown from '../../FormComponent/YearDropdown';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import LoadingIndicator from '../../FormComponent/LoadingIndicator';
import Reloader from '../../FormComponent/Reloader';
import ErrorAlert from '../../FormComponent/ErrorAlert';
import { validateSession } from '../../session/EventManager';





function PensionStatementView() {


    const [isLoading, setIsLoading] = useState(true);
    const [isRefresh, setIsRefresh] = useState(false);
    const [isButtonHided, setIsButtonHided] = useState(true);
    const [pensionStatement, setPensionStatement] = useState([]);

    //handling api failure
    const [isFailed, setIsFailed] = useState({ status: false, method: null });
    const [message, setMessage] = useState('');

    //invalid date range alert handler
    const [isInvalidDate, setInvalidDate] = useState(false);


    const defaultFormate = {
        radio_control: '',
        start_date: '',
        end_date: '',
    }

    const [queryFormate, setQueryFormate] = useState({
        radio_control: '',
        start_date: '',
        end_date: '',
    });

    const start_date = {
        name: "start_date",
        type: "date",
        placeholder: "Start date",
        helperText: 'provide start date',
        defaultValue: queryFormate.start_date,
        errorMessage:
            "email should be correct format",
        // label: "pension_percentage",
        pattern: "",
        required: false,
        disabled: false,
    };

    const end_date = {
        name: "end_date",
        type: "date",
        placeholder: "End Date",
        helperText: 'provide end date',
        defaultValue: queryFormate.end_date,
        errorMessage:
            "end should be correct format",
        // label: "pension_percentage",
        pattern: "",
        required: false,
        disabled: false,
    };


    const radio_control = {
        options: [
            { value: 'Monthly', label: 'Monthly' },
            { value: 'Yearly', label: 'Yearly' },
        ],
        name: 'radio_control',
        // label: 'Name of Father/Husband',
        initialValue: queryFormate.radio_control,
        disabled: false,
    };


    const tableTitles = ['Currency Period (dd/mm/yyyy)', 'Reckonable Period', 'Member Contribution (\u20B9)', 'Employer Contribution (\u20B9)']
    const leftAlign = ['currPeriodEnd', 'reckonablePrd', 'totEmplyrContri', 'totMemContri'];
    const comma = [];

    const sessionExist = async () => {
        const status = await validateSession();
        console.log("session status", status);
        return status;
    };


    const fetchData = async() => {
        setIsLoading(true);

        if (!(await sessionExist())) {
            console.log('session extending having trouble');
            setIsLoading(false);
            setIsFailed({ status: true, method: fetchData });
            return;
        }


        let token = localStorage.getItem('jwt')
        if (!token) {
            setIsLoading(false);
            setIsFailed({ status: true, method: fetchData });
            return;
        }
        token = token.replace("\"", "").replace("\"", "")

        const url = process.env.REACT_APP_SERVER_URL + '/getledgerpensionyear';

        const formData = new FormData();
        formData.append('cmpfAccNo', jwtDecode(token).user);

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        };

        axios.post(url, formData, config)
            .then(function (response) {
                if (response.status === 200) {
                    const data = response.data.data;
                    data.map(
                        (elem) => {
                            elem.currPeriodEnd = new Date(elem.currPeriodEnd).toLocaleDateString()
                        }
                    )
                    response.data.data.length === 0 ? setMessage("No records found") : setMessage("Showing records from last 10 transactions.");
                    setPensionStatement(data);
                    setIsLoading(false);
                    setIsRefresh(false);
                    setIsFailed({ status: false, method: fetchData });
                    setQueryFormate(defaultFormate);
                    return;
                }
                setIsLoading(false);
                setIsRefresh(false);
                setQueryFormate(defaultFormate);
                setIsFailed({ status: true, method: fetchData });
            })
            .catch(function (error) {
                setIsLoading(false);
                setIsRefresh(false);
                setQueryFormate(defaultFormate);
                setIsFailed({ status: true, method: fetchData });
                // show a pop up message
                console.log('error', error);
            });
    }


    useEffect(() => {
        // setQueryFormate({ ...queryFormate, radio_control: 'Monthly' })
        fetchData();
    }, []);




    const onChange = (e) => {
        if (e.target.name === 'radio_control') {
            setIsButtonHided(true);
            setQueryFormate({ ...queryFormate, [e.target.name]: e.target.value, start_date: '', end_date: '' });
            return;
        }
        setQueryFormate({ ...queryFormate, [e.target.name]: e.target.value });
        if (queryFormate.start_date || queryFormate.end_date) {
            setIsButtonHided(false);
            return;
        }
        setIsButtonHided(true);

    };

    const handleInvalidDateAlert = () => {
        setInvalidDate(false);
    }


    const validate = () => {
        if (!queryFormate.start_date && !queryFormate.end_date) {
            setInvalidDate(true);
            return false;
        }
        if (queryFormate.start_date > queryFormate.end_date) {
            setInvalidDate(true);
            return false;
        }
        return true;
    }




    const fetchYear = async() => {
        console.log('fetch year called');
        setIsRefresh(true);

        if (!(await sessionExist())) {
            console.log('session extending having trouble');
            setIsRefresh(false);
            setIsFailed({ status: true, method: fetchYear });
            return;
        }


        let token = localStorage.getItem('jwt')
        if (!token) {
            setIsRefresh(false);
            setIsFailed({ status: true, method: fetchYear });
            return;
        }
        token = token.replace("\"", "").replace("\"", "")

        const url = process.env.REACT_APP_SERVER_URL + "/getledgerpensionyearbetweenyearrange";
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        const data = new URLSearchParams();
        data.append("cmpfAccNo", jwtDecode(token).user);
        data.append("fromYear", queryFormate.start_date);
        data.append("toYear", queryFormate.end_date);

        axios.post(url, data, { headers })
            .then(response => {
                if (response.status === 200) {
                    setIsLoading(true);
                    let data = response.data.data;
                    data.map(
                        (elem) => {
                            elem.currPeriodEnd = new Date(elem.currPeriodEnd).toLocaleDateString()
                            //   elem = elem.currPeriodEnd = moment(elem.currPeriodEnd , 'YYYY-MM-DD HH:mm:ss.S').format('DD/MM/YYYY').toString();
                            // console.log(  elem.currPeriodEnd);

                        }
                    )
                    response.data.data.length === 0 ? setMessage(`No records found: ${queryFormate.start_date} - ${queryFormate.end_date}`) : setMessage(`Showing transaction from ${queryFormate.start_date} to ${queryFormate.end_date}.`);
                    setPensionStatement((pensionStatement) => pensionStatement = data);
                    setIsLoading(false);
                    setIsRefresh(false);
                    setIsFailed({ status: false, method: fetchYear });
                    setQueryFormate(defaultFormate);
                    return;
                }
                setPensionStatement([]);
                setMessage('');
                setQueryFormate((queryFormate) => ({ ...queryFormate, ...defaultFormate }));
                setIsFailed({ status: false, method: fetchYear });
                setIsRefresh(false);

            })
            .catch(function (error) {
                setIsFailed({ status: true, method: fetchYear });
                setIsLoading(false);
                setMessage('');
                setIsRefresh(false);
                setQueryFormate(defaultFormate);
                // show a pop up message
                console.log('error', error);
            });
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validate()) {
            return;
        }
        if (queryFormate.radio_control === 'Yearly') {
            fetchYear(e);
            setIsRefresh(true)
            setIsButtonHided(true)
            return;
        }
        if (queryFormate.radio_control === 'Monthly') {
            setIsButtonHided(true)
            setPensionStatement([]);
            setMessage(`No records found: ${queryFormate.start_date} - ${queryFormate.end_date} `);
            setIsRefresh(false)   // make this true when api available
            setQueryFormate(defaultFormate); // remove this when api available

            // call api for month
        }
    }

    return (
        <div>
            <ErrorAlert handleOk={handleInvalidDateAlert} open={isInvalidDate} title='Error!' body='Invalid Date Range.' buttonName='Ok' />
            <Grid container justifyContent="center" sx={{ wordWrap: 'break-word' }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Paper elevation={2} sx={{ padding: '3rem', borderRadius: "8px 8px 0 0" }}>


                        {
                            isLoading ? <LoadingIndicator /> :
                                <>

                                    <Grid item xs={12}>
                                        <TitleText title='Pension Statement' />
                                    </Grid>



                                    <FormDivider />


                                    <Grid container spacing={2} justifyContent={{ xs: 'flex-start', sm: 'center' }} alignItems="center" >


                                        {/* row */}
                                        <Grid item xs={12} sm={4}>
                                            <LabelText title="Select Statement Period:" />
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <GroupedRadioButton {...radio_control} onchange={onChange} />
                                        </Grid>


                                        {

                                            queryFormate.radio_control === 'Yearly' &&
                                            <>
                                                <Grid item xs={12} sm={4}>
                                                    <LabelText title="Date Range:" />
                                                </Grid>

                                                <Grid item xs={12} sm={8}>
                                                    <YearDropdown onChange={onChange} />
                                                </Grid>
                                            </>
                                        }

                                        {

                                            queryFormate.radio_control === 'Monthly' &&
                                            <>
                                                <Grid item xs={12} sm={4}>
                                                    <LabelText title="Date Range:" />
                                                </Grid>
                                                <Grid item xs={4} sm={4}>
                                                    <CustomTextField {...start_date} onchange={onChange} />
                                                </Grid>

                                                <Grid item xs={4} sm={4}>
                                                    <CustomTextField {...end_date} onchange={onChange} />
                                                </Grid>
                                            </>
                                        }


                                        {
                                            !isButtonHided && <Grid item xs={5} sm={4}>
                                                <Button
                                                    variant="contained"
                                                    color="success"
                                                    fullWidth
                                                    onClick={handleSubmit}
                                                    type="submit"
                                                >
                                                    View Statement
                                                </Button>
                                            </Grid>
                                        }




                                        {
                                            (!isRefresh && !isFailed.status) &&
                                            <Grid item xs={12}>
                                                <Typography variant="body2" sx={{
                                                    fontFamily:'Arial sans-serif',
                                                    fontSize: {
                                                        xs: '10px',
                                                        sm: '11px',
                                                        md: '12px',
                                                        lg: '14px',
                                                        xl: '15px',
                                                    },
                                                    fontWeight:'500'
                                                }} color={'#1c632f'} align='left'>
                                                    {message}
                                                </Typography>
                                            </Grid>
                                        }

                                        {
                                            isRefresh &&
                                            <Grid item xs={12} >
                                                <Typography variant="body2" color={'#1c632f'} align='left'>
                                                    Loading....
                                                </Typography>
                                            </Grid>
                                        }


                                    </Grid>
                                </>
                        }
                    </Paper>

                    {/* {
                        !isLoading &&
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{ borderRadius: 0, }}>
                                {pensionStatement.length !== 0 && <FormTable titles={tableTitles} leftAlign={leftAlign} comma={[]}  data={pensionStatement} />}
                            </Paper>
                        </Grid>
                    } */}

                    {
                        isFailed.status ? <Reloader refreshHandler={isFailed.method} /> :
                            <>
                                {
                                    !isLoading &&
                                    <Grid item xs={12}>

                                        <Paper elevation={3} sx={{ borderRadius: 0, }}>

                                            {(pensionStatement.length !== 0) && <FormTable titles={tableTitles} leftAlign={leftAlign} comma={comma} data={pensionStatement} />}
                                        </Paper>
                                    </Grid>
                                }
                            </>
                    }

                </Grid>
            </Grid>
        </div>
    );
}

export default PensionStatementView;



