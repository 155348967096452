
import React, { useState, useEffect } from "react";
import axios from "axios";
import TableWithLinkAndButton from "../../FormComponent/TableWithLinkAndButton";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import { Tabs, Tab, Container, Grid, Paper, Box, Button } from "@mui/material";
import PostAddRoundedIcon from "@mui/icons-material/PostAddRounded";
import { useLocation, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { getToken } from "../../UiManager/SessionChecker";
import BorderColorIcon from "@mui/icons-material/BorderColor";

const UpdateMemberDetailsUda = () => {
  const location = useLocation();
  const defaultClaims = {
    // claimId: "",
    // cmpfAcNo: "",
    // name: "",
    // unitCode: "",

    // activityDate: "",
    // remarks: "",
    // updReqAckId: "",
    updReqAckId: "",
    unitCode: "",
    name: "",
    joiningDate: "",
    mobileNo: "",
  };
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [tabData, setTabData] = useState({
    received: [defaultClaims],
    returned: [defaultClaims],
    // closed: [defaultClaims],
  });
  const [isFailed, setIsFailed] = useState(false);
  const [titleName, setTitleName] = useState("");

  //   const [assignToOption, setAssignToOption] = useState([]);
  //   const [returnToOption, setReturnToOption] = useState([]);
  // const [revertToOption, setRevertToOption] = useState([]);
  const [pageLink, setPageLink] = useState("proceedmemupdrq");
  //let loginId = "UDA-CC-OFF-101"; // Assuming a default
  // let token = localStorage.getItem("jwt");

  //  if (!token) {
  //    setIsFailed(true);
  //    setIsLoading(false);
  //    return;
  //  }

  // token = token.replace('"', "").replace('"', "");
  // let loginId = jwtDecode(token).user; // Assuming a default loginId

  //console.log("loooooooooooooooooooooginId", loginId);

  const handleSubmitfNewClaim = () => {
    // navigate("/uda/pfset/newpfclaim");
    navigate("searchmem");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (newValue === 0) {
        
      setPageLink("proceedmemupdrq");
    } else if (newValue === 1) {
      setPageLink("proceedmemupdrq");
    }
    //else if (newValue === 2) {
    //   setPageLink("viewadvclaimuao");
    // }
  };
  let type = "";

  // if (location.pathname === "/uda/pfset") {
  //   type = "PF";
  // } else if (location.pathname === "/uda/penset") {
  //   type = "PN";
  // }

  let api = {
    claimUrlrecevied: "/processed/list/of/update/member/details",
    claimUrlreturned: "/returned/list/of/update/member/details",
  };

  // const pfApi = {
  //   claimUrlrecevied: "/show/all/pf/claim/to/uda",
  //   claimUrlreturned: "/show/all/pf/claim/to/all/officers",
  // };
  // const penApi = {
  //   claimUrlrecevied: "/show/pen/claim/to/uda",
  //   claimUrlreturned: "/show/all/pen/claim/to/all/officers",
  // };
  // const comboApi = {
  //   claimUrl: "/show/combo/combo/on/claimid",
  //   claimUrlreturn: "/show/all/combo/claim/to/all/officers",
  // };

  // useEffect(() => {
  //   let newTitle = "";
  //   if (type === "PF") {
  //     api = { ...pfApi };
  //     newTitle = "PF";
  //   } else if (type === "PN") {
  //     api = { ...penApi };
  //     newTitle = "Pension";
  //   } else if (type === "PP") {
  //     api = { ...comboApi };
  //     newTitle = "PF & Pension";
  //   }
  //   setTitleName(newTitle);
  //   // ... other logic related to the API
  // }, [type]); // Don't forget to include type as a dependency to ensure the useEffect runs when type changes

  useEffect(() => {
    console.log("useEffect is working");
    fetchReceived();
    fetchReturned();
  }, [location.pathname]);

  const fetchReceived = async () => {
    try {
      setIsLoading(true);
      let token = getToken();
      console.log("token on uda", token);
      if (!token) {
        setIsLoading(false);
        setIsFailed(true);
        return;
      }
      token = token.replace('"', "").replace('"', "");
      const loginId = jwtDecode(token).user;
      // const loginId = "UDA-CC-OFF-101";
      console.log("loginId on uda", loginId);

      const url = process.env.REACT_APP_SERVER_URL + api.claimUrlrecevied;
      //const url = process.env.REACT_APP_SERVER_URL +"";
      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          // Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      console.log("response", response.data.data);

      if (response.status === 200) {
        setTabData((prevTabData) => ({
          ...prevTabData,
          // received: response.data.data.AssignedList,
          // processed: response.data.data.ForwardList,
          // returned: response.data.data.ReturnList,
          received: response.data.data,
          // processed: response.data.data,
          // returned: response.data.data,
        }));
      } else {
        console.error("Failed to fetch claims. Status: " + response.status);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error - received", error);
      return [];
    }
  };

  const fetchReturned = async () => {
    try {
      setIsLoading(true);
      let token = getToken();
      console.log("token on uda", token);
      if (!token) {
        setIsLoading(false);
        setIsFailed(true);
        return;
      }
      token = token.replace('"', "").replace('"', "");
      const loginId = jwtDecode(token).user;
      // const loginId = "UDA-CC-OFF-101";
      console.log("loginId on uda", loginId);
      const url = process.env.REACT_APP_SERVER_URL + api.claimUrlreturned;
      //const url = process.env.REACT_APP_SERVER_URL +"";
      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          // Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);

      console.log("response returned", response.data.data.ReturnList);
      console.log("tabData here", tabData);
      //setTabData(...tabData, { returned: response.data.data.ReturnList });
      if (response.status === 200) {
        setTabData((prevTabData) => ({
          ...prevTabData,
          // received: response.data.data.AssignedList,
          // processed: response.data.data.ForwardList,
          // returned: response.data.data.ReturnList[],
          returned: response.data.data,
        }));
      } else {
        console.error("Failed to fetch claims. Status: " + response.status);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error - received", error);
      return [];
    }
  };

  const createTablePreviewData = () => {
    let tablePreviewData = [];

    selectedTabData.forEach((mem) => {
      tablePreviewData.push({
        "Upd Req Ack Id": mem.updReqAckId,
        "Unit Code": mem.unitCode,
        Name: mem.name,
        "Joining Date": mem.joiningDate,
        "Mobile No": mem.mobileNo,
        "Submission Date": mem.date,
        //Remarks: mem.remarks,
      });
    });

    return tablePreviewData;
  };

  const selectedTabData = tabData[value === 0 ? "received" : "returned"] || [];

  // Rest of the code...

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            <Grid item xs={12}>
              <TitleText title={titleName + "Update Member "} />
            </Grid>
            <FormDivider />
            <Grid
              container
              spacing={2}
              justifyContent={{ xs: "center", sm: "space-between" }}
              alignItems="center"
            >
              {/* row */}
              <Grid item xs={12} sm={4}>
                {/* <LabelText title="New Claim:" /> */}
              </Grid>
              <Grid item xs={6} sm={3}>
                <Button
                  //disabled={isSearching}
                  onClick={handleSubmitfNewClaim}
                  variant="contained"
                  startIcon={<BorderColorIcon />}
                  color="success"
                  disabled={isLoading}
                  fullWidth
                  type="submit"
                >
                  Update
                </Button>
              </Grid>
            </Grid>
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <Container>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="#831f76"
                      textColor="primary"
                    >
                      <Tab
                        label="Submitted"
                        style={{
                          backgroundColor: value === 0 ? "#831f76" : "", //bisqu
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          color: value === 0 ? "#ffffff" : "#000000", // Set text color to white when selected
                        }}
                      />
                      <Tab
                        label="Returned"
                        style={{
                          backgroundColor: value === 1 ? "#831f76" : "",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          color: value === 1 ? "#ffffff" : "#000000", // Set text color to white when selected
                        }}
                      />
                      {/* <Tab
                        label="Returned"
                        style={{
                          backgroundColor: value === 2 ? "#831f76" : "",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          color: value === 2 ? "#ffffff" : "#000000", // Set text color to white when selected
                        }}
                      /> */}
                    </Tabs>
                  </Grid>
                  <Grid item xs={12} marginTop={-2}>
                    <Box
                      p={3}
                      style={{
                        backgroundColor: "#fffaff",
                        border: "2px solid #831f76",
                      }}
                    >
                      <TableWithLinkAndButton
                        preview={createTablePreviewData()}
                        data={selectedTabData}
                        passingId="Pf ID"
                        viewLink={pageLink}
                        parentData={value}
                        size="small"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default UpdateMemberDetailsUda;
