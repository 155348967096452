import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FormDivider from "../FormComponent/FormDivider";
import TitleText from "../FormComponent/TitleText";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import Box from "@mui/material/Box";
import ErrorAlert from "../FormComponent/ErrorAlert";
// import TitleText from '../FormComponent/TitleText';
import ValueText from "../FormComponent/ValueText";
import LabelText from "../FormComponent/LabelText";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";

import LinearProgress from "@mui/material/LinearProgress";
import BackButton from "../components/button/BackButton";
import { makeStyles } from "@mui/styles";
import Alert from "@mui/material/Alert";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Table,
  Modal,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Grid,
  IconButton,
} from "@mui/material";
import SucessAlert from "../FormComponent/SuccessAlert";
import axios from "axios";
import ConfirmationDialogue from "../FormComponent/ConfirmationDialogue";
import { styled } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  hoverableCell: {
    position: "relative",
    transition: "background-color 0.3s ease", // Add transition for background color change
    "&:hover": {
      background:
        "linear-gradient(90deg, #FBB870,  #FCEEC5,#FCEEC5,#FCEEC5, #FCEEC5,#FCEEC5, #FBB870)" /* Example gradient */,
      "&::before": {
        content: '"Edit"',
        position: "absolute",
        top: "-1.5em",
        left: "80%",
        transform: "translateX(-100%)",
        color: "#f0f0f0",
        backgroundColor: "#808080",
        padding: "0.2em 0.5em",
        borderRadius: "2px",
        fontSize: "0.7em",
      },
    },
  },
}));

const SampleTable = () => {
  const location = useLocation();
  const { state } = location;
  const { cmpfAcNO } = state;

  const cmpfno = state;

  console.log("ghghgaaaall", cmpfno);
  // const [cmpfAcNO, setCmpfAcNO] = useState("");
  const [isSuccessAlertOpen, setSuccessAlertOpen] = useState(false);
  const [isErrorAlertOpen, setErrorAlertOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const newLedgerDetailsRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [newLedgerDetailsVisible, setNewLedgerDetailsVisible] = useState(false);

  const [ledgerDetails, setLedgerDetails] = useState([]);
  const [intrestObject, setIntrestObject] = useState({});
  const [editAdvanceDetails, setEditAdvanceDetails] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [editingCell, setEditingCell] = React.useState(null);
  const [memberData, setMemberData] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessAlertOpen1, setSuccessAlertOpen1] = useState(false);
  const [successMessage1, setSuccessMessage1] = useState(null);

  const handleEditAdvanceCell = (rowIndex, colName) => {
    setEditAdvanceDetails({ rowIndex, colName });
  };
  const uc = ledgerDetails.length > 0 ? ledgerDetails[0].unitCode : 0;
  const date = "-03-31";
  const navigate = useNavigate();
  const [newLedgerDetails, setNewLedgerDetails] = useState([
    {
      interestAmt: 0,
      employerPfContri: 0,
      closingBal: 0,
      memPfContri: 0,
      volContri: 0,
      adminChg: 0,
      ledgerDetId: 0,
      noOfMonthWorked: 0,
      currPeriodEnd: "yyyy-03-31",
      unitCode: "",
      totalEmol: 0,
      interestPerc: 0,
      unitId: 0,
      openingBalance: 0,
      memberId: 0,
    },
  ]);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [advanceDetails, setAdvanceDetails] = useState([]);
  const [editLedgerDetails, setEditLedgerDetails] = useState(null);

  const [editNewLedgerDetails, setEditNewLedgerDetails] = useState(null);
  const [firstSubmit, setFirstSubmit] = useState(null);
  const [isAddRowVisible, setAddRowVisible] = useState(true);
  const [isSubmitVisible, setSubmitVisible] = useState(true);
  const [isCancelVisible, setCancelVisible] = useState(true);
  const [memberIdToDelete, setMemberIdToDelete] = useState();
  const [ledgerIdToDelete, setLedgerIdToDelete] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpen1, setDialogOpen1] = useState(false);

  const roundOffToTwoDecimals = (value) => {
    if (typeof value === "number") {
      return isNaN(value) ? "00" : value.toFixed(2);
    }
    return value;
  };
  const handleOk = () => {
    // Handle OK action
    // For example, you can perform some logic or call a function
    handleSubmit();
    handleCloseDialog();
  };
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOk1 = () => {
    // Handle OK action
    // For example, you can perform some logic or call a function
    handleSubmit1();
    handleCloseDialog1();
  };
  const handleOpenDialog1 = () => {
    setDialogOpen1(true);
  };
  const handleCloseDialog1 = () => {
    setDialogOpen1(false);
  };

  const handleOpenDeleteDialog = async (memberId, advanceId) => {
    setDeleteDialogOpen(true);
    setMemberIdToDelete(memberId);
    setLedgerIdToDelete(advanceId);
  };
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };
  const handleOkDelete = () => {
    handleDeleteLedgerRow(memberIdToDelete, ledgerIdToDelete);

    setDeleteDialogOpen(false);
  };

  const roundOffToTwoDecimals1 = (value) => {
    if (typeof value === "number") {
      return isNaN(value) ? "00" : Math.round(value).toString();
    }
    return value;
  };

  //...................................................................................................

  useEffect(() => {
    const fetchMemDetails = async () => {
      try {
        // Create FormData object
        const formData = new FormData();
        formData.append("cmpfAcNo", cmpfno);
        const memurl =
          process.env.REACT_APP_SERVER_URL +
          "/get/member/id/unit/code/by/cmpf/acno";

        // Make API request using Axios
        const response = await axios.post(memurl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        // Update state with response data
        console.log("mmeemm", response.data.data);
        const memData = response.data.data;
        setMemberData(memData);
        console.log("member data", memberData);
      } catch (error) {
        // Handle error
        console.log("errrrrorrrr");
      }
    };

    // Only trigger API call when cmpfaccno changes
    if (cmpfno) {
      fetchMemDetails();
    }
  }, [cmpfno]);
  //..........................................................................................................
  const classes = useStyles();

  const columnLabels = {
    currPeriodEnd: "Currency Period Ending",
    unitCode: "Unit Code",
    openingBalance: "Opening Balance",
    memPfContri: "Member Contribution",
    employerPfContri: "Employer Contribution",
    volContri: "Voluntary Contribution",
    interestPerc: "Rate of Interest",
    interestAmt: "Interest Amount(EC+MC)",
    // memopeningbalance: "mem open",
    // empopeningbalance: "emp open",
    // memclosingbalance: "mem close",
    // empclosingbalance: "emp close  ",

    closingBal: "Closing Balance",
    withdrwlAmt: "Withdrawal Amount",
    withdrwlTyp: "Withdrawal Details",
    // withdrwlDate: "Withdrawal Date",
  };
  const nav = useNavigate;
  const handleAddAdvance = () => {
    // Replace the current page with the add advance page
    // window.location.replace('/AdvanceForm');
    const passingmemberid = ledgerDetails[0].memberId;
    navigate("/roda/viewledger/updateldgr/adv", {
      state: { cmpfno, passingmemberid },
    });
  };

  //temporary

  const handleSuccessAlertOk = () => {
    // Close the success alert
    setSuccessAlertOpen(false);
    fetchAPI();

    // Delay execution of handleSubmitagain by 2 seconds
  };
  const handleSuccessAlertOk1 = () => {
    // Close the success alert
    setNewLedgerDetailsVisible(false);
    setSuccessAlertOpen1(false);
    fetchAPI();

    // Delay execution of handleSubmitagain by 2 seconds
  };

  const handleErrorAlertOk = () => {
    // Close the success alert
    setErrorAlertOpen(false);

    // Reload the page
  };
  const handleCancel2 = () => {
    setAddRowVisible(true);
    setNewLedgerDetailsVisible(false);
    setSubmitVisible(true);
    setCancelVisible(true);
  };
  const updateLedgerAndAdvanceDetails = async (
    allLedgerDetails,
    updatedAdvanceDetails
  ) => {
    try {
      openModal();
      setDialogOpen(true);
      setCancelVisible(false);
      setAddRowVisible(false);
      setSubmitVisible(false);

      // Update Ledger
      console.log("Data to be sent to API (Ledger):", allLedgerDetails);
      const ledgerUrl =
        process.env.REACT_APP_SERVER_URL + "/update/pf/ledger/details";
      const ledgerResponse = await axios.post(ledgerUrl, allLedgerDetails);
      console.log("API Response (Ledger):", ledgerResponse.data);

      // Check if the first API call was successful
      if (ledgerResponse.status === 200) {
        // Update Advance only if the first API was successful
        console.log("Data to be sent to API (Advance):", updatedAdvanceDetails);
        const advanceUrl =
          process.env.REACT_APP_SERVER_URL +
          "/update/pf/Advance/Withdrawl/details";
        const advanceResponse = await axios.post(
          advanceUrl,
          updatedAdvanceDetails
        );
        console.log("API Response (Advance):", advanceResponse.data);

        // Both APIs were successful
        setSuccessMessage("Ledger and Advance Updated Successfully");
        closeModal();
        setCancelVisible(true);
        setAddRowVisible(true);
        setSubmitVisible(true);
        setSuccessAlertOpen(true);
      } else {
        // If the first API fails
        setIsLoading(false);
        setErrorMessage("Failed to update Ledger");
        setErrorAlertOpen(true);
        closeModal();
        setCancelVisible(true);
        setAddRowVisible(true);
        setSubmitVisible(true);
      }
    } catch (error) {
      // Handle errors for both APIs
      console.error("API Error:", error);
      setErrorMessage("Something Went Wrong");
      setIsLoading(false);
      setErrorAlertOpen(true);
      closeModal();
      setCancelVisible(true);
      setAddRowVisible(true);
      setSubmitVisible(true);
    }
  };

  const handleDeleteLedgerRow = (memberId, ledgerDetailId) => {
    console.log("Member ID:", memberId);
    console.log("Ledger Detail ID:", ledgerDetailId);
    try {
      // Create FormData object
      const formData = new FormData();
      formData.append("memberId", memberId);
      formData.append("ledgerDetId", ledgerDetailId);
      const memurl = process.env.REACT_APP_SERVER_URL + "/delete/ledger";

      // Make API request using Axios
      const response = axios.post(memurl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Update state with response data
      console.log("response", response);
      setSuccessMessage("Ledger row deleted successfully");
      setSuccessAlertOpen(true);
    } catch (error) {
      // Handle error
      console.log("errrrrorrrr");
    }

    // Here you can implement the logic to delete the ledger row
  };

  // Example usage:
  // updateLedgerAndAdvanceDetails(allLedgerDetails, updatedAdvanceDetails);

  // Example usage:
  // updateLedgerAndAdvanceDetails(updatedLedgerDetails, updatedAdvanceDetails);

  // const updateLedgerDetails = (updatedLedgerDetails) => {
  //   setIsLoading(true);
  //   console.log('Data to be sent to API:', updatedLedgerDetails);

  //   const url = process.env.REACT_APP_SERVER_URL +'/update/pf/ledger/details';

  //   axios
  //     .post(url, updatedLedgerDetails)
  //     .then((response) => {
  //       console.log('API Response:', response.data);
  //       setSuccessMessage("Ledger Updated Successfully");
  //       setIsLoading(false);
  //       setSuccessAlertOpen(true);

  //       // Handle success if needed
  //     })
  //     .catch((error) => {
  //       console.error('API Error:', error);
  //         // setErrorMessage("Something Went Wrong");
  //         // setErrorAlertOpen(true);
  //       // Handle error if needed

  //     });
  // };

  // const updateAdvanceDetails = (updatedAdvanceDetails) => {
  //   console.log('Data to be sent to API (Advance Details):', updatedAdvanceDetails);
  //   const url = process.env.REACT_APP_SERVER_URL +'/update/pf/Advance/Withdrawl/details';

  //   axios
  //     .post(url, updatedAdvanceDetails)
  //     .then((response) => {
  //       console.log('API Response (Advance Details):', response.data);
  //       setSuccessMessage("ADVANCE Updated Successfully");
  //       setSuccessAlertOpen(true);
  //       // Handle success if needed
  //     })
  //     .catch((error) => {
  //       console.error('API Error (Advance Details):', error);
  //       setErrorMessage("Something Went Wrong");
  //       setErrorAlertOpen(true);
  //       // Handle error if needed
  //     });
  // };
  const addLedgerRow = async (allLedgerDetails) => {
    try {
      openModal();
      setCancelVisible(false);
      setAddRowVisible(false);
      setSubmitVisible(false);

      // Update Ledger
      console.log("Data to be sent to API (Ledger):", allLedgerDetails);
      const ledgerUrl =
        process.env.REACT_APP_SERVER_URL + "/update/pf/ledger/details";
      const ledgerResponse = await axios.post(ledgerUrl, allLedgerDetails);
      console.log("API Response (Ledger):", ledgerResponse.data);

      // Update Advance
      // console.log('Data to be sent to API (Advance):', updatedAdvanceDetails);
      // const advanceUrl = process.env.REACT_APP_SERVER_URL + '/update/pf/Advance/Withdrawl/details';
      // const advanceResponse = await axios.post(advanceUrl, updatedAdvanceDetails);
      // console.log('API Response (Advance):', advanceResponse.data);

      // Both APIs were successful
      setSuccessMessage1("Row Added Successfully");
      closeModal();
      setCancelVisible(true);
      setAddRowVisible(true);
      setSubmitVisible(true);
      setSuccessAlertOpen1(true);
    } catch (error) {
      // Handle errors for both APIs
      console.error("API Error:", error);
      if (error.response && error.response.status === 406) {
        console.log("Invalid data");
        setErrorMessage("Ledger for the entered year already existsssss");
        setErrorAlertOpen(true);
        closeModal();
      } else {
        setErrorMessage("Something Went Wrong");
        setErrorAlertOpen(true);
      }
    }
  };

  // Example usage:
  // updateLedgerAndAdvanceDetails1(allLedgerDetails, updatedAdvanceDetails);

  // Example usage:
  // updateLedgerAndAdvanceDetails1(updatedLedgerDetails, updatedAdvanceDetails);

  // const updateLedgerDetails1 = (updatedLedgerDetails) => {

  //   console.log('Data to be sent to API:', updatedLedgerDetails);

  //   const url = process.env.REACT_APP_SERVER_URL +'/update/pf/ledger/details';

  //   axios
  //     .post(url, updatedLedgerDetails)
  //     .then((response) => {
  //       console.log('API Response:', response.data);

  //       setSuccessMessage("Row Added Successfully");
  //       setSuccessAlertOpen(true);
  //       // Handle success if needed
  //     })
  //     .catch((error) => {
  //       console.error('API Error:', error);
  //       setErrorMessage("Something Went Wrong");
  //       setErrorAlertOpen(true);
  //       // Handle error if needed

  //     });
  // };

  // const updateAdvanceDetails1 = (updatedAdvanceDetails) => {
  //   console.log('Data to be sent to API (Advance Details):', updatedAdvanceDetails);
  //   const url = process.env.REACT_APP_SERVER_URL +'/update/pf/Advance/Withdrawl/details';

  //   axios
  //     .post(url, updatedAdvanceDetails)
  //     .then((response) => {
  //       console.log('API Response (Advance Details):', response.data);
  //       setSuccessMessage("Row Added Successfully")
  //       setSuccessAlertOpen(true);
  //       // Handle success if needed
  //     })
  //     .catch((error) => {
  //       console.error('API Error (Advance Details):', error);
  //       setErrorMessage("Something Went Wrong");
  //       setErrorAlertOpen(true);
  //       // Handle error if needed
  //     });
  // };

  const handleSubmit = () => {
    // Log edited and updated ledger details
    console.log("Edited and Updated Ledger Details:", ledgerDetails);

    // Extract memberId from any ledger in the fetched details
    const memberIdForNewLedgers = memberData.member_id;

    // Extract only the necessary properties for updating ledger details
    const updatedLedgerDetails = ledgerDetails.map(
      ({ advanceDetails, ...ledger }) => {
        return ledger;
      }
    );

    // Extract only the necessary properties for new ledger details if "Add Row" was clicked
    const newLedgerDetailsToSend = newLedgerDetailsVisible
      ? newLedgerDetails
          .filter((row) =>
            Object.values(row).some(
              (value) => value !== "" && value !== null && value !== undefined
            )
          )
          .map(({ ledgerDetId, ...newLedger }) => ({
            ...newLedger,
            memberId: memberIdForNewLedgers,
          }))
      : [];

    // Perform checks on entered year only if new ledger is visible
    if (newLedgerDetailsVisible) {
      const enteredYear = newLedgerDetails[0]?.currPeriodEnd
        ? new Date(newLedgerDetails[0].currPeriodEnd).getFullYear()
        : null;
      console.log("enentered", enteredYear);

      const dateRegex = /^\d{4}-\d{2}-\d{2}$/; // Adjust the regex based on your date format
      if (!dateRegex.test(newLedgerDetails[0].currPeriodEnd)) {
        console.error("Error: Invalid date format.");
        setErrorMessage("Invalid Date Format");
        setErrorAlertOpen(true);
        return;
      }

      console.log(
        "Currency Period Ending of New Ledger:",
        newLedgerDetails[0].currPeriodEnd
      );
      const yearnow = new Date().getFullYear();
      console.log("hello", yearnow);
      const compareyear = new Date(enteredYear);
      console.log("compare year", compareyear);
      if (compareyear > yearnow) {
        setErrorMessage("You cannot enter ledger for future year");
        setErrorAlertOpen(true);
        return;
      }

      const currentYear = new Date().getFullYear();

      if (
        ledgerDetails.some(
          (ledger) =>
            new Date(ledger.currPeriodEnd).getFullYear() === enteredYear
        ) &&
        enteredYear !== currentYear
      ) {
        console.error("Error: Ledger for the entered year already exists.");
        setErrorMessage("Ledger for the entered year already exists.");
        setErrorAlertOpen(true);
        return;
      }
    }

    // Concatenate newly added ledger details only if "Add Row" was clicked
    const allLedgerDetailstosend = [
      ...updatedLedgerDetails,
      ...newLedgerDetailsToSend,
    ];

    const allLedgerDetails = allLedgerDetailstosend.map((ledgerDetail) => ({
      ...ledgerDetail,
      unitId: ledgerDetail.unitId || 0,
    }));

    // Now, allLedgerDetailsToSend contains the ledger details with unitid assigned 0 only if it was originally null

    // Call the API to update all ledger details
    // updateLedgerDetails(allLedgerDetails);

    // Extract only the necessary properties for updating advance details
    const updatedAdvanceDetails = advanceDetails.map(
      ({ key, withMemShare, ...advance }) => ({
        ...advance,
        pfWthdrwlId: key,
        withMemShare: withMemShare || 0,
        withEmplrShare: advance.withEmplrShare || 0,
      })
    );

    console.log("haha", allLedgerDetails);
    // Call the API to update advance details
    // updateAdvanceDetails(updatedAdvanceDetails);
    updateLedgerAndAdvanceDetails(allLedgerDetails, updatedAdvanceDetails);
  };
  const handleSubmit1 = () => {
    // Display a confirmation dialog

    // Log edited and updated ledger details
    console.log("Edited and Updated Ledger Details:", ledgerDetails);

    // Extract memberId from any ledger in the fetched details
    const memberIdForNewLedgers = memberData.member_id;
    const unitcodefornewledgers = memberData.unit_code;
    console.log(
      "ledgerDetails.length > 0 ? ledgerDetails[0].unitCode : 0;",
      unitcodefornewledgers
    );
    const unitidfornewledgers =
      ledgerDetails.length > 0 ? ledgerDetails[0].unitId : 0;

    // Extract only the necessary properties for updating ledger details
    const updatedLedgerDetails = ledgerDetails.map(
      ({ advanceDetails, ...ledger }) => {
        return ledger;
      }
    );

    // Extract only the necessary properties for new ledger details if "Add Row" was clicked
    const newLedgerDetailsToSend = newLedgerDetailsVisible
      ? newLedgerDetails
          .filter((row) =>
            Object.values(row).some(
              (value) => value !== "" && value !== null && value !== undefined
            )
          )
          .map(({ ledgerDetId, ...newLedger }) => ({
            ...newLedger,
            memberId: memberIdForNewLedgers,
            unitId: unitidfornewledgers,
            unitCode: unitcodefornewledgers,
          }))
      : [];

    // Perform checks on entered year only if new ledger is visible
    if (newLedgerDetailsVisible) {
      const enteredYear = newLedgerDetails[0]?.currPeriodEnd
        ? new Date(newLedgerDetails[0].currPeriodEnd).getFullYear()
        : null;

      const dateRegex = /^\d{4}-\d{2}-\d{2}$/; // Adjust the regex based on your date format
      if (!dateRegex.test(newLedgerDetails[0].currPeriodEnd)) {
        console.error("Error: Invalid date format.");
        setErrorMessage("Invalid Date Format");
        setErrorAlertOpen(true);
        // alert('Error: Invalid date format.');
        return;
      }
      const newDate = new Date();
      const yearr = newDate.getFullYear();
      if (newLedgerDetails[0].currPeriodEnd > yearr) {
        setErrorMessage("Ledger for this year cannot be added.");
        setErrorAlertOpen(true);
        return;
      }

      console.log(
        "Currency Period Ending of New Ledger:",
        newLedgerDetails[0].currPeriodEnd
      );
      const yearnow = new Date().getFullYear();
      console.log("hello", yearnow);
      const compareyear = new Date(enteredYear);
      console.log("compare year", compareyear);
      if (compareyear > yearnow) {
        setErrorMessage("You cannot enter ledger for future year");
        setErrorAlertOpen(true);
        return;
      }
      const currentYear = new Date().getFullYear();

      if (
        ledgerDetails.some(
          (ledger) =>
            new Date(ledger.currPeriodEnd).getFullYear() === enteredYear
        ) &&
        enteredYear !== currentYear
      ) {
        console.error("Error: Ledger for the entered year already exists.");
        setErrorMessage("Ledger for the entered year already exists.");
        setErrorAlertOpen(true);
        return;
      }
    }

    // Concatenate newly added ledger details only if "Add Row" was clicked
    const allLedgerDetails = [...newLedgerDetailsToSend];

    // Call the API to update all ledger details
    // updateLedgerDetails1(allLedgerDetails);

    // Extract only the necessary properties for updating advance details
    const updatedAdvanceDetails = advanceDetails.map(
      ({ key, withMemShare, ...advance }) => ({
        ...advance,
        pfWthdrwlId: key,
        withMemShare: withMemShare || 0,
        withEmplrShare: advance.withEmplrShare || 0,
      })
    );

    // Call the API to update advance details
    // updateAdvanceDetails1(updatedAdvanceDetails);
    addLedgerRow(allLedgerDetails);
  };

  const fetchAPI = async () => {
    setIsLoading(true);
    console.log("fetch api running");
    const formdata = new FormData();
    formdata.append("cmpfAcNO", cmpfno);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    const url1 = process.env.REACT_APP_SERVER_URL + "/get/pf/ledger/details";
    const url2 =
      process.env.REACT_APP_SERVER_URL + "/get/pf/advance/withd/details";
    const url3 = process.env.REACT_APP_SERVER_URL + "/get/intrest/with/yr";
    const [
      { data: ledgerDetails },
      { data: advanceDetails },
      { data: interestDetail },
    ] = await Promise.all([
      fetch(url1, requestOptions).then((response) => response.json()),

      fetch(url2, requestOptions).then((response) => response.json()),
      fetch(url3, requestOptions).then((response) => response.json()),
      closeModal(),
    ]);
    console.log("interest detail", ledgerDetails);
    let intrestObject = interestDetail.reduce((acc, item) => {
      const year = new Date(item.intYear).getFullYear();
      acc[year] = item.intPercentage;
      return acc;
    }, {});
    // const intrestObject = interestDetail.map((item) => ({
    //   interestDetail: item.interestPerc
    // }));
    console.log("intrestObject", intrestObject);

    setIntrestObject(intrestObject);
    setLedgerDetails(ledgerDetails);
    setAdvanceDetails(
      advanceDetails.map((detail) => {
        return {
          ...detail,
          key: detail.pfWthdrwlId,
        };
      })
    );
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAPI();
  }, [cmpfno]);

  useEffect(() => {
    const newTableData = attachAdvanceDetailsToLedgers(
      ledgerDetails,
      advanceDetails
    );

    if (JSON.stringify(newTableData) !== JSON.stringify(ledgerDetails)) {
      setLedgerDetails(newTableData);
    }
  }, [ledgerDetails, advanceDetails]);

  const attachAdvanceDetailsToLedgers = (ledgerDetails, advanceDetails) => {
    console.log("attaching advance details to ledger");
    const initialYear = new Date(ledgerDetails[0]?.currPeriodEnd).getFullYear();
    const finalYear = new Date(
      ledgerDetails[ledgerDetails.length - 1]?.currPeriodEnd
    ).getFullYear();
    const existingLedgersMap = new Map(
      ledgerDetails.map((ledger) => [
        new Date(ledger.currPeriodEnd).getFullYear(),
        true,
      ])
    );

    const updatedData = ledgerDetails.map((ledger, index) => {
      let ledgerFinancialYear = 0;
      if (ledger.noOfMonthWorked >= 12) {
        ledgerFinancialYear = new Date(ledger.currPeriodEnd).getFullYear();
      } else {
        ledgerFinancialYear =
          new Date(ledger.currPeriodEnd).getMonth() <= 2
            ? new Date(ledger.currPeriodEnd).getFullYear()
            : new Date(ledger.currPeriodEnd).getFullYear() + 1;
      }

      const advancesForYear = advanceDetails.filter((advance) => {
        const advanceDate = new Date(advance.withdrwlDate);
        const advanceFinancialYear =
          advanceDate.getMonth() <= 2
            ? advanceDate.getFullYear()
            : advanceDate.getFullYear() + 1;

        return advanceFinancialYear === ledgerFinancialYear;
      });
      let noofmonthsworked = 0;
      if (ledger.noOfMonthWorked === 0) {
        noofmonthsworked = 12;
      } else {
        noofmonthsworked = parseFloat(ledger.noOfMonthWorked);
      }

      let x = roundOffToTwoDecimals1(
        (parseFloat(ledger.memopeningbalance || 0) *
          parseFloat(ledger.interestPerc) *
          parseFloat(noofmonthsworked)) /
          1200
      );
      let y = roundOffToTwoDecimals1(
        (parseFloat(ledger.empopeningbalance || 0) *
          parseFloat(ledger.interestPerc) *
          parseFloat(noofmonthsworked)) /
          1200
      );
      let int_disp = parseFloat(x) + parseFloat(y);

      let member_closing_balance =
        parseFloat(ledger.memopeningbalance || 0) +
        parseFloat(x) +
        parseFloat(ledger.memPfContri) +
        parseFloat(ledger.volContri);

      let emp_closing_balance =
        parseFloat(ledger.empopeningbalance || 0) +
        parseFloat(y) +
        parseFloat(ledger.employerPfContri);

      for (const advance of advancesForYear) {
        const amount = advance.withdrwlAmt;
        let reducevalue = 0;

        // const monthOfAdvance = new Date(advance.withdrwlDate).getMonth() + 1;
        // let monthRemaining = 12 - monthOfAdvance + 3;

        // if (monthRemaining === 12) {
        //   reducevalue = 0;
        // } else if (monthRemaining === 13) {
        //   reducevalue = 1;
        // } else if (monthRemaining === 14) {
        //   reducevalue = 2;
        // } else {
        //   reducevalue = monthRemaining;
        // }

        // if(advance.withdrwlTypId === 8){
        //   reducevalue += 1;
        // }else{
        //   reducevalue = reducevalue;
        // }

        if (ledger.noOfMonthWorked === 0 && advance.withdrwlTypId !== 8) {
          console.log(
            "entered in condition 1",
            ledger.currPeriodEnd,
            advance.withdrwlDate
          );

          reducevalue =
            (new Date(ledger.currPeriodEnd).getFullYear() -
              new Date(advance.withdrwlDate).getFullYear()) *
              12 +
            (new Date(ledger.currPeriodEnd).getMonth() -
              new Date(advance.withdrwlDate).getMonth());
        }
        // else if (ledger.noOfMonthWorked === 0 && advance.withdrwlTypId === 8 ) {
        //   console.log(
        //     "entered in condition 1",
        //     ledger.currPeriodEnd,
        //     advance.withdrwlDate
        //   );

        //   reducevalue =
        //     (new Date(ledger.currPeriodEnd).getFullYear() -
        //       new Date(advance.withdrwlDate).getFullYear()) *
        //       12 + 1 +
        //     (new Date(ledger.currPeriodEnd).getMonth() -
        //       new Date(advance.withdrwlDate).getMonth());
        // }
        else if (advance.withdrwlTypId === 8) {
          console.log("entered in condition 8");

          reducevalue =
            (new Date(ledger.currPeriodEnd).getFullYear() -
              new Date(advance.withdrwlDate).getFullYear()) *
              12 +
            1 +
            (new Date(ledger.currPeriodEnd).getMonth() -
              new Date(advance.withdrwlDate).getMonth());
        } else if (
          ledger.noOfMonthWorked !== 0 &&
          advance.withdrwlTypId !== 8
        ) {
          reducevalue =
            (new Date(ledger.currPeriodEnd).getFullYear() -
              new Date(advance.withdrwlDate).getFullYear()) *
              12 +
            (new Date(ledger.currPeriodEnd).getMonth() -
              new Date(advance.withdrwlDate).getMonth()) -
            1;
        } else {
          reducevalue =
            (new Date(ledger.currPeriodEnd).getFullYear() -
              new Date(advance.withdrwlDate).getFullYear()) *
              12 +
            (new Date(ledger.currPeriodEnd).getMonth() -
              new Date(advance.withdrwlDate).getMonth());
        }

        const interestRate = ledger.interestPerc;

        const interest_deduct = roundOffToTwoDecimals1(
          (parseFloat(advance.withdrwlAmt) *
            interestRate *
            (reducevalue / 12)) /
            100
        );
        console.log(
          "interest kata hai ",
          reducevalue,
          "mahine ka ",
          ledger.currPeriodEnd,
          "mein"
        );
        console.log("interest on broken period", interest_deduct);
        int_disp -= interest_deduct;

        const total_deduct = parseFloat(amount) + parseFloat(interest_deduct);
        if (
          advance.withdrwlTypId === 1 ||
          advance.withdrwlTypId === 2 ||
          advance.withdrwlTypId === 3 ||
          advance.withdrwlTypId === 4
        ) {
          member_closing_balance -= (4 / 7) * parseFloat(total_deduct);
          emp_closing_balance -= (3 / 7) * parseFloat(total_deduct);
        }
        // else if (advance.withdrwlTypId === 8) {
        //     member_closing_balance = 0;
        //     emp_closing_balance = 0;
        else if (advance.withdrwlTypId === 8) {
          console.log("dusra1", member_closing_balance);
          const empandmem = member_closing_balance + emp_closing_balance;
          const percentformem = (member_closing_balance / empandmem) * 100;
          const percentforemp = 100 - parseFloat(percentformem);
          console.log(
            "amount from mem",
            (parseFloat(percentformem) * parseFloat(total_deduct)) / 100
          );
          console.log(
            "amount from emp",
            (parseFloat(percentforemp) * parseFloat(total_deduct)) / 100
          );
          member_closing_balance -= parseFloat(
            (parseFloat(percentformem) * parseFloat(total_deduct)) / 100
          );
          emp_closing_balance -= parseFloat(
            (parseFloat(percentforemp) * parseFloat(total_deduct)) / 100
          );

          // member_closing_balance = 0;
          // emp_closing_balance = 0;
          console.log(
            "dusra2",
            member_closing_balance,
            advance.withdrwlAmt,
            total_deduct
          );
        } else {
          member_closing_balance -= parseFloat(total_deduct);
        }
      }

      let tdsamountinitial = 0;
      let tdsamountfinal = 0;

      if (
        new Date(ledger.currPeriodEnd).getFullYear() > 2021 &&
        parseFloat(ledger.memPfContri) + parseFloat(ledger.volContri) > 250000
      ) {
        tdsamountinitial += roundOffToTwoDecimals1(
          (parseFloat(ledger.interestPerc) *
            (parseFloat(ledger.memPfContri) +
              parseFloat(ledger.volContri) -
              250000) *
            parseFloat(noofmonthsworked)) /
            1200
        );
        tdsamountfinal += roundOffToTwoDecimals1(
          (10 * parseFloat(tdsamountinitial)) / 100
        );
        tdsamountfinal = roundOffToTwoDecimals1(tdsamountfinal);
      }

      member_closing_balance -= tdsamountfinal;
      let closing =
        parseFloat(member_closing_balance) + parseFloat(emp_closing_balance);
      let closingBal = closing;

      if (index < ledgerDetails.length - 1) {
        ledgerDetails[index + 1].openingBalance = closingBal;
        ledgerDetails[index + 1].memopeningbalance = ledger.memclosingbalance;
        ledgerDetails[index + 1].empopeningbalance = ledger.empclosingbalance;
      }

      return {
        ...ledger,
        advanceDetails: advancesForYear,
        interestAmt: int_disp,
        closingBal: closingBal,
        memclosingbalance: member_closing_balance,
        empclosingbalance: emp_closing_balance,
      };
    });

    updatedData.sort(
      (a, b) => new Date(a.currPeriodEnd) - new Date(b.currPeriodEnd)
    );

    for (let year = initialYear + 1; year < finalYear; year++) {
      if (!existingLedgersMap.has(year)) {
        const blankLedger = {
          currPeriodEnd: `${year}-03-31`,
          interestAmt: 0,
          employerPfContri: 0,
          closingBal: 0,
          memPfContri: 0,
          volContri: 0,
          adminChg: 0.0,
          noOfMonthWorked: 0,
          totalEmol: 0,
          interestPerc:
            intrestObject[year] !== undefined ? intrestObject[year] : 0,
          unitId: ledgerDetails[0].unitId,
          unitCode: ledgerDetails[0].unitCode,
          openingBalance: 0,
          memberId: ledgerDetails[0].memberId,
        };
        console.log("blank ledgers created");

        updatedData.push(blankLedger);
      }
    }

    return updatedData;
  };

  const handleEditLedgerCell = (rowIndex, colName) => {
    setEditLedgerDetails({ rowIndex, colName });
  };

  const handleEditNewLedgerCell = (rowIndex, colName) => {
    setEditNewLedgerDetails({ rowIndex, colName });
  };

  // const handleCancelEdit = () => {
  //   const isConfirmed = window.confirm(
  //     "Are you sure you want to cancel? Any unsaved changes will be lost."
  //   );

  //   if (isConfirmed) {
  //     // Reset the page
  //     window.location.reload();
  //   }
  // };

  const handleLedgerValueChange = (rowIndex, colName, value) => {
    if (!/^\d*\.?\d*$/.test(value)) {
      return; // Exit the function if the input is not numeric
    }
    const newValue = value.trim() === "" ? "" : value;

    const newData = [...ledgerDetails];
    newData[rowIndex][colName] = newValue;

    setLedgerDetails((prevData) => {
      const updatedData = prevData.map((ledger, index) => {
        if (index === rowIndex) {
          const advancesForYear = advanceDetails.filter((advance) => {
            // Filter advances for the corresponding financial year
            const advanceDate = new Date(advance.withdrwlDate);
            const advanceFinancialYear =
              advanceDate.getMonth() <= 2
                ? advanceDate.getFullYear()
                : advanceDate.getFullYear() + 1;
            const ledgerFinancialYear =
              new Date(ledger.currPeriodEnd).getMonth() <= 2
                ? new Date(ledger.currPeriodEnd).getFullYear()
                : new Date(ledger.currPeriodEnd).getFullYear() + 1;

            return advanceFinancialYear === ledgerFinancialYear;
          });

          // Recalculate interest and update other fields
          const interestAmt = calculateInterestAmt(advancesForYear, ledger);
          const totalInterestAmt = parseFloat(interestAmt);

          let noofmonthsworked = 0;
          if (ledger.noOfMonthWorked === 0) {
            noofmonthsworked = 12;
          } else {
            noofmonthsworked = parseFloat(ledger.noOfMonthWorked);
          }

          let x = roundOffToTwoDecimals1(
            (parseFloat(ledger.memopeningbalance || 0) *
              parseFloat(ledger.interestPerc) *
              parseFloat(noofmonthsworked)) /
              1200
          );
          let y = roundOffToTwoDecimals1(
            (parseFloat(ledger.empopeningbalance || 0) *
              parseFloat(ledger.interestPerc) *
              parseFloat(noofmonthsworked)) /
              1200
          );
          let member_closing_balance =
            parseFloat(ledger.memopeningbalance || 0) +
            parseFloat(x) +
            parseFloat(ledger.memPfContri) +
            parseFloat(ledger.volContri);

          let emp_closing_balance =
            parseFloat(ledger.empopeningbalance || 0) +
            parseFloat(y) +
            parseFloat(ledger.employerPfContri);

          for (const advance of advancesForYear) {
            const amount = advance.withdrwlAmt;
            let reducevalue = 0;

            // const monthOfAdvance =
            //   new Date(advance.withdrwlDate).getMonth() + 1;
            // let monthRemaining = 12 - monthOfAdvance + 3;

            // if (monthRemaining === 12) {
            //   reducevalue = 0;
            // } else if (monthRemaining === 13) {
            //   reducevalue = 1;
            // } else if (monthRemaining === 14) {
            //   reducevalue = 2;
            // } else {
            //   reducevalue = monthRemaining;
            // }
            if (ledger.noOfMonthWorked === 0) {
              reducevalue =
                (new Date(ledger.currPeriodEnd).getFullYear() -
                  new Date(advance.withdrwlDate).getFullYear()) *
                  12 +
                (new Date(ledger.currPeriodEnd).getMonth() -
                  new Date(advance.withdrwlDate).getMonth());
            } else if (advance.withdrwlTypId === 8) {
              reducevalue =
                (new Date(ledger.currPeriodEnd).getFullYear() -
                  new Date(advance.withdrwlDate).getFullYear()) *
                  12 +
                (new Date(ledger.currPeriodEnd).getMonth() -
                  new Date(advance.withdrwlDate).getMonth());
            } else if (
              ledger.noOfMonthWorked !== 0 &&
              advance.withdrwlTypId !== 8
            ) {
              reducevalue =
                (new Date(ledger.currPeriodEnd).getFullYear() -
                  new Date(advance.withdrwlDate).getFullYear()) *
                  12 +
                (new Date(ledger.currPeriodEnd).getMonth() -
                  new Date(advance.withdrwlDate).getMonth()) -
                1;
            } else {
              reducevalue =
                (new Date(ledger.currPeriodEnd).getFullYear() -
                  new Date(advance.withdrwlDate).getFullYear()) *
                  12 +
                (new Date(ledger.currPeriodEnd).getMonth() -
                  new Date(advance.withdrwlDate).getMonth());
            }

            const interestRate = ledger.interestPerc;

            const interest_deduct = roundOffToTwoDecimals1(
              (parseFloat(advance.withdrwlAmt) *
                interestRate *
                (reducevalue / 12)) /
                100
            );

            const total_deduct =
              parseFloat(amount) + parseFloat(interest_deduct);

            if (
              advance.withdrwlTypId === 1 ||
              advance.withdrwlTypId === 2 ||
              advance.withdrwlTypId === 3 ||
              advance.withdrwlTypId === 4
            ) {
              member_closing_balance -= (4 / 7) * parseFloat(total_deduct);
              emp_closing_balance -= (3 / 7) * parseFloat(total_deduct);
            }
            // else if (advance.withdrwlTypId === 8) {
            //     member_closing_balance = 0;
            //     emp_closing_balance = 0;
            else if (advance.withdrwlTypId === 8) {
              console.log("dusra1", member_closing_balance);
              const empandmem = member_closing_balance + emp_closing_balance;
              const percentformem = (member_closing_balance / empandmem) * 100;
              const percentforemp = 100 - parseFloat(percentformem);
              console.log(
                "amount from mem",
                (parseFloat(percentformem) * parseFloat(total_deduct)) / 100
              );
              console.log(
                "amount from emp",
                (parseFloat(percentforemp) * parseFloat(total_deduct)) / 100
              );
              member_closing_balance -= parseFloat(
                (parseFloat(percentformem) * parseFloat(total_deduct)) / 100
              );
              emp_closing_balance -= parseFloat(
                (parseFloat(percentforemp) * parseFloat(total_deduct)) / 100
              );

              // member_closing_balance = 0;
              // emp_closing_balance = 0;
              console.log(
                "dusra2",
                member_closing_balance,
                advance.withdrwlAmt,
                total_deduct
              );
            } else {
              member_closing_balance -= parseFloat(total_deduct);
            }
          }

          let tdsamountinitial = 0;
          let tdsamountfinal = 0;

          if (
            new Date(ledger.currPeriodEnd).getFullYear() > 2021 &&
            parseFloat(ledger.memPfContri) + parseFloat(ledger.volContri) >
              250000
          ) {
            tdsamountinitial += roundOffToTwoDecimals1(
              (parseFloat(ledger.interestPerc) *
                (parseFloat(ledger.memPfContri) +
                  parseFloat(ledger.volContri) -
                  250000) *
                parseFloat(noofmonthsworked)) /
                1200
            );
            tdsamountfinal += (10 * tdsamountinitial) / 100;
            tdsamountfinal = roundOffToTwoDecimals1(tdsamountfinal);
          }

          member_closing_balance -= tdsamountfinal;
          let closing =
            parseFloat(member_closing_balance) +
            parseFloat(emp_closing_balance);

          ledger.memclosingbalance = parseFloat(member_closing_balance);
          ledger.empclosingbalance = parseFloat(emp_closing_balance);

          let closingBal = closing;

          return {
            ...ledger,
            advanceDetails: advancesForYear,
            interestAmt: roundOffToTwoDecimals(totalInterestAmt),
            closingBal: roundOffToTwoDecimals(closingBal),
          };
        } else if (index === rowIndex + 1) {
          // Update the opening balance of the next ledger
          return {
            ...ledger,
            openingBalance: roundOffToTwoDecimals(newValue),
          };
        }
        return ledger;
      });

      return [...updatedData];
    });
  };

  const handleNewLedgerValueChange = (rowIndex, colName, value) => {
    // const newValue = value.trim() === '' ? '0' : value;;
    setNewLedgerDetails((prevData) => {
      const updatedData = prevData.map((ledger, index) => {
        if (index === rowIndex) {
          const updatedLedger = { ...ledger };

          // Convert the value to a date if the column is 'currPeriodEnd'
          if (colName === "currPeriodEnd") {
            // Assuming value is a valid date string, you might want to add additional validation
            updatedLedger[colName] = value;
          } else {
            // Convert the value to a float and then round it to two decimals
            const newValue = parseFloat(
              value.trim() === "" ? "0" : value
            ).toFixed(2);

            // Convert the value to an integer if it represents a numeric value
            updatedLedger[colName] = isNaN(newValue)
              ? (newValue = 0)
              : parseFloat(newValue);
          }

          return updatedLedger;
        }
        return ledger;
      });

      return [...updatedData];
    });
  };

  // ... (existing code)

  // const calculateInterestAmt = (advancesForYear, ledger) => {
  //   let totalInterestAmt = 0;
  //   let reducevalue = 0;

  //   if (advancesForYear.length > 0) {
  //     advancesForYear.forEach((advance) => {
  //       const monthOfAdvance = new Date(advance.withdrwlDate).getMonth() + 1;
  //       let monthRemaining = 12 - monthOfAdvance + 3;
  //       if (monthRemaining === 12) {
  //         reducevalue = 0;
  //       } else if (monthRemaining === 13) {
  //         reducevalue = 1;
  //       } else if (monthRemaining === 14) {
  //         reducevalue = 2;
  //       } else {
  //         reducevalue = monthRemaining;
  //       }

  //       const interestRate = ledger.interestPerc;

  //       const advanceInterestAmt =
  //         (parseFloat(advance.withdrwlAmt) * interestRate * (reducevalue / 12)) / 100;

  //       totalInterestAmt += roundOffToTwoDecimals1(isNaN(advanceInterestAmt) ? 0 : advanceInterestAmt);
  //       console.log("ttttooootttt", totalInterestAmt);
  //     });
  //   }

  //   const totalInterestAmt1 =

  //     (((parseFloat(ledger.openingBalance) || 0) * (ledger.interestPerc || 0)) / 100) - totalInterestAmt;
  //     console.log("finally", totalInterestAmt1);

  //   return isNaN(totalInterestAmt1) ? 0 : roundOffToTwoDecimals1(totalInterestAmt1);

  // };

  const calculateInterestAmt = (advancesForYear, ledger) => {
    let totalInterestAmtt = 0;
    let reducevalue = 0;

    if (advancesForYear.length > 0) {
      advancesForYear.forEach((advance) => {
        const monthOfAdvance = new Date(advance.withdrwlDate).getMonth() + 1;
        let monthRemaining = 12 - monthOfAdvance + 3;

        if (monthRemaining === 12) {
          reducevalue = 0;
        } else if (monthRemaining === 13) {
          reducevalue = 1;
        } else if (monthRemaining === 14) {
          reducevalue = 2;
        } else {
          reducevalue = monthRemaining;
        }

        const interestRate = ledger.interestPerc;

        const advanceInterestAmt = roundOffToTwoDecimals1(
          (parseFloat(advance.withdrwlAmt) *
            interestRate *
            (reducevalue / 12)) /
            100
        );

        totalInterestAmtt += roundOffToTwoDecimals1(
          isNaN(advanceInterestAmt) ? 0 : advanceInterestAmt
        );
        console.log("tttttttoooooooooottttttal", totalInterestAmtt);
      });
    }

    const previousYearLedger = ledgerDetails.find((prevLedger) => {
      const prevYear = new Date(prevLedger.currPeriodEnd).getFullYear();
      const currentYear = new Date(ledger.currPeriodEnd).getFullYear();

      return prevYear === currentYear - 1;
    });

    if (previousYearLedger) {
      // console.log("ooooooo", previousYearLedger.openingBalance);
      const mem_int = roundOffToTwoDecimals1(
        (parseFloat(
          previousYearLedger.memPfContri +
            parseFloat(previousYearLedger.volContri)
        ) *
          parseFloat(ledger.interestPerc)) /
          100
      );
      console.log("ooooooo", previousYearLedger);
      console.log("ooomember", mem_int);

      //........................................................
      const emp_int = roundOffToTwoDecimals1(
        (parseFloat(previousYearLedger.employerPfContri) *
          parseFloat(ledger.interestPerc)) /
          100
      );
      console.log("oooemployer", emp_int);
      // ...........................................................
      // const open_int = roundOffToTwoDecimals1(

      //   (parseFloat(previousYearLedger.openingBalance )*parseFloat(ledger.interestPerc))/100

      //   );
      //  console.log("oooopen", open_int);

      const int_int = roundOffToTwoDecimals1(
        (parseFloat(previousYearLedger.interestAmt) *
          parseFloat(ledger.interestPerc)) /
          100
      );
      console.log("oooint", int_int);

      //.......................................
      const more_int = roundOffToTwoDecimals1(
        parseFloat(
          ((parseFloat(previousYearLedger.closingBal) -
            parseFloat(previousYearLedger.memPfContri) -
            parseFloat(previousYearLedger.employerPfContri) -
            parseFloat(previousYearLedger.volContri)) *
            ledger.interestPerc) /
            100
        )
      );

      // const int_int = roundOffToTwoDecimals1(
      //   (previousYearLedger.interestAmt * ledger.interestPerc) / 100
      // );
      console.log("tototot", totalInterestAmtt);
      const interestAmt =
        parseFloat(mem_int) +
        parseFloat(emp_int) +
        parseFloat(more_int) -
        parseFloat(totalInterestAmtt);
      console.log("interest amount finally", interestAmt);

      return roundOffToTwoDecimals(interestAmt);
    }

    return "0";
  };

  // const calculateInterestAmt = (advancesForYear, ledger) => {
  //   let totalInterestAmtt = 0;
  //   let reducevalue = 0;

  //   if (advancesForYear.length > 0) {
  //     advancesForYear.forEach((advance) => {
  //       const monthOfAdvance = new Date(advance.withdrwlDate).getMonth() + 1;
  //       let monthRemaining = 12 - monthOfAdvance + 3;

  //       if (monthRemaining === 12) {
  //         reducevalue = 0;
  //       } else if (monthRemaining === 13) {
  //         reducevalue = 1;
  //       } else if (monthRemaining === 14) {
  //         reducevalue = 2;
  //       } else {
  //         reducevalue = monthRemaining;
  //       }

  //       const interestRate = ledger.interestPerc;

  //       const advanceInterestAmt =
  //        roundOffToTwoDecimals1( (parseFloat(advance.withdrwlAmt) * interestRate * (reducevalue / 12)) / 100);

  //       totalInterestAmtt += roundOffToTwoDecimals1(isNaN(advanceInterestAmt) ? 0 : advanceInterestAmt);
  //       console.log("tttttttoooooooooottttttal", totalInterestAmtt);

  //     });
  //   }

  //   const previousYearLedger = ledgerDetails.find((prevLedger) => {
  //     const prevYear = new Date(prevLedger.currPeriodEnd).getFullYear();
  //     const currentYear = new Date(ledger.currPeriodEnd).getFullYear();

  //     return prevYear === currentYear - 1;
  //   });

  //   if (previousYearLedger) {

  //     // console.log("ooooooo", previousYearLedger.openingBalance);
  //     const mem_int = roundOffToTwoDecimals1(

  //       (parseFloat(previousYearLedger.memPfContri + parseFloat(previousYearLedger.volContri ))*parseFloat(ledger.interestPerc))/100

  //       );
  //     console.log("ooooooo", previousYearLedger);
  //     console.log("ooomember", mem_int);

  //     //........................................................
  //     const emp_int = roundOffToTwoDecimals1(

  //        (parseFloat(previousYearLedger.employerPfContri )*parseFloat(ledger.interestPerc))/100

  //        );
  //        console.log("oooemployer", emp_int);
  //     // ...........................................................
  //     // const open_int = roundOffToTwoDecimals1(

  //     //   (parseFloat(previousYearLedger.openingBalance )*parseFloat(ledger.interestPerc))/100

  //     //   );
  //     //  console.log("oooopen", open_int);

  //     const int_int = (parseFloat(previousYearLedger.interestAmt)*ledger.interestPerc)/100;

  //     //.......................................
  //     const more_int = roundOffToTwoDecimals1(parseFloat(((parseFloat(previousYearLedger.closingBal)-
  //     parseFloat(previousYearLedger.memPfContri)-
  //     parseFloat(previousYearLedger.employerPfContri)-
  //     parseFloat(previousYearLedger.volContri))*ledger.interestPerc)/100));

  //     // const int_int = roundOffToTwoDecimals1(
  //     //   (previousYearLedger.interestAmt * ledger.interestPerc) / 100
  //     // );
  //     console.log("tototot", totalInterestAmtt)
  //     const interestAmt =  (parseFloat(mem_int) + parseFloat(emp_int) + parseFloat(more_int)  - parseFloat(totalInterestAmtt));
  //     console.log("interest amount finally", interestAmt)

  //     return roundOffToTwoDecimals(interestAmt);
  //   }

  //   return '0';
  // };

  const handleAddRow = () => {
    setNewLedgerDetailsVisible((val) => !val);
    setAddRowVisible(false); // Hide the "Add Row" button
    setSubmitVisible(false);
    setCancelVisible(false);
  };

  const handleAdvanceValueChange = (key, colName, value) => {
    // Trim the incoming value
    const trimmedValue = value.trim();

    // If the trimmed value is empty, set it to '0'
    const newValue = trimmedValue === "" ? "" : trimmedValue;

    console.log("key, colName, value", key, colName, newValue);

    setAdvanceDetails((prevData) => {
      const updatedData = prevData.map((advance, index) => {
        if (advance.key === key) {
          return {
            ...advance,
            [colName]: newValue,
          };
        }
        return advance;
      });
      return [...updatedData];
    });
  };

  const newTableData = attachAdvanceDetailsToLedgers(
    ledgerDetails,
    advanceDetails
  );
  const cellHoverStyle = {
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  };
  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "1rem", borderRadius: "4px" }}>
            <Grid item xs={12}>
              <TitleText title="Update Ledger " />
            </Grid>
            <FormDivider />
            {/* Success and Error Alerts */}
            <SucessAlert
              open={isSuccessAlertOpen}
              title="Success!"
              body={successMessage}
              buttonName="OK"
              handleOk={handleSuccessAlertOk}
            />
            <SucessAlert
              open={isSuccessAlertOpen1}
              title="Success!"
              body={successMessage1}
              buttonName="OK"
              handleOk={handleSuccessAlertOk1}
            />

            <ErrorAlert
              open={isErrorAlertOpen}
              title="Error!"
              body={errorMessage}
              buttonName="OK"
              handleOk={handleErrorAlertOk}
            />
            <ConfirmationDialogue
              open={dialogOpen}
              title="Update Ledger"
              body="Are you sure you want to update the ledger?"
              buttonName="OK"
              handleOk={handleOk}
              handleCancel={handleCloseDialog}
            />
            <ConfirmationDialogue
              open={dialogOpen1}
              title="Add Row?"
              body="Are you sure you want to add this row??"
              buttonName="OK"
              handleOk={handleOk1}
              handleCancel={handleCloseDialog1}
            />
            <ConfirmationDialogue
              open={deleteDialogOpen}
              title="Delete Row"
              body="Are you sure you want to delete this row?"
              buttonName="OK"
              handleOk={handleOkDelete}
              handleCancel={handleCloseDeleteDialog}
            />
            <div>
              <Modal
                open={isModalOpen}
                onClose={closeModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description">
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}>
                  <CircularProgress color="secondary" size={80} />
                </div>
              </Modal>
            </div>

            {/* Main Grid Container */}
            <Grid container justifyContent="center">
              {/* Grid Item for Paper */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {/* Paper Component with Elevation and Styling */}
                <Paper
                  elevation={3}
                  sx={{ padding: "1rem", borderRadius: "4px" }}>
                  <Grid item xs={6} alignContent={"center"}>
                    <TitleText
                      title={`Member Name : ${memberData.name || " "}`}
                    />
                  </Grid>
                  {/* Grid Container for Form Elements */}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {/* Grid Container for Header Elements */}
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center">
                        {/* Back Button Component */}
                        <Grid item>
                          <BackButton />
                        </Grid>
                        {/* TextField for cmpfAcNO */}
                        <Grid item>
                          <Alert variant="filled" severity="error">
                            <b>
                              Make sure to click "SAVE" to save the updated
                              details before leaving the page
                            </b>
                          </Alert>
                        </Grid>
                        {/* Button to Add Advance */}
                        <Grid item>
                          <Button
                            variant="contained"
                            color="success"
                            onClick={handleAddAdvance}>
                            Add / Edit Advance
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Grid Item for Ledger Details Table */}
                  <Grid item xs={12} style={{ marginTop: "20px" }}>
                    {/* Fragment for Ledger and Advance Details */}
                    <>
                      {/* ClickAwayListener for Ledger Details */}
                      <ClickAwayListener
                        onClickAway={() => setEditLedgerDetails(null)}>
                        {/* TableContainer for Ledger Details */}
                        <TableContainer component={Paper}>
                          {/* Table for Ledger Details */}
                          <Table>
                            {/* TableHead for Column Labels */}
                            <TableHead>
                              <TableRow>
                                {/* Mapping Column Labels */}
                                {Object.keys(columnLabels).map((colName) => (
                                  <TableCell key={colName}>
                                    {/* LabelText Component for Column Label */}
                                    <LabelText title={columnLabels[colName]} />
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            {/* TableBody for Ledger Details */}

                            <TableBody>
                              {isLoading ? (
                                <Box sx={{ width: "1325%" }}>
                                  {" "}
                                  {/* Adjusted width */}
                                  <LinearProgress />
                                </Box>
                              ) : (
                                <>
                                  {/* Mapping Ledger Details Rows */}
                                  {ledgerDetails.map((row, rowIndex) => (
                                    <React.Fragment key={`ledger_${rowIndex}`}>
                                      {/* Unique key for ledger rows */}
                                      {/* Mapping Advance Details for each Ledger */}
                                      {row.advanceDetails?.map(
                                        (advance, advanceIndex) => (
                                          <TableRow
                                            key={`advance_${rowIndex}_${advanceIndex}`}
                                            style={{
                                              backgroundColor: "#f0f0f0",
                                            }}>
                                            {/* Mapping Cells in Advance Details Row */}
                                            {Object.keys(columnLabels).map(
                                              (colName) => (
                                                <TableCell
                                                  key={`cell_${rowIndex}_${advanceIndex}_${colName}`}>
                                                  {/* Conditional Rendering based on the column name */}
                                                  {colName ===
                                                  "currPeriodEnd" ? (
                                                    // Render Currency Period Ending with withdrawal date
                                                    <div>
                                                      {advance.withdrwlDate
                                                        ? new Date(
                                                            advance.withdrwlDate
                                                          ).toLocaleDateString(
                                                            "en-GB",
                                                            {
                                                              day: "2-digit",
                                                              month: "short",
                                                              year: "numeric",
                                                            }
                                                          )
                                                        : "-"}
                                                    </div>
                                                  ) : colName ===
                                                    "withdrwlAmt" ? (
                                                    // Render uneditable withdrwlAmt field using ValueText component
                                                    <div>
                                                      <ValueText
                                                        title={
                                                          advance[colName] !==
                                                          undefined
                                                            ? roundOffToTwoDecimals(
                                                                advance[colName]
                                                              )
                                                            : "-"
                                                        }
                                                      />
                                                    </div>
                                                  ) : (
                                                    // Render non-editable fields using ValueText component
                                                    <div>
                                                      <ValueText
                                                        title={
                                                          advance[colName] !==
                                                          undefined
                                                            ? roundOffToTwoDecimals(
                                                                advance[colName]
                                                              )
                                                            : "-"
                                                        }
                                                      />
                                                    </div>
                                                  )}
                                                </TableCell>
                                              )
                                            )}
                                          </TableRow>
                                        )
                                      )}

                                      {/* TableRow for Ledger Details */}
                                      <TableRow
                                        key={`ledger_row_${rowIndex}`}
                                        style={{
                                          border:
                                            new Date(
                                              row.currPeriodEnd
                                            ).getMonth() !== 2
                                              ? "3px solid orange"
                                              : "none",
                                        }}>
                                        {/* Unique key for ledger row */}
                                        {/* Mapping Cells in a Row */}
                                        {Object.keys(columnLabels).map(
                                          (colName) => (
                                            <TableCell
                                              key={`ledger_cell_${rowIndex}_${colName}`}
                                              className={
                                                [
                                                  "memPfContri",
                                                  "employerPfContri",
                                                  "volContri",
                                                  "interestPerc",
                                                ].includes(colName)
                                                  ? classes.hoverableCell
                                                  : ""
                                              }
                                              style={{
                                                whiteSpace: "nowrap",
                                                minWidth:
                                                  colName === "withdrwlDate"
                                                    ? "120px"
                                                    : colName === "withdrwlTyp"
                                                    ? "250px"
                                                    : "auto",
                                              }}>
                                              {/* Conditional Rendering based on Column Name */}
                                              {colName === "withdrwlTyp" ||
                                              colName === "withdrwlAmt" ||
                                              colName === "currPeriodEnd" ||
                                              colName === "unitCode" ||
                                              colName === "withdrwlDate" ||
                                              colName === "openingBalance" ||
                                              colName === "closingBal" ||
                                              colName === "interestAmt" ? (
                                                <div
                                                  style={{
                                                    whiteSpace: "nowrap",
                                                  }}>
                                                  {colName ===
                                                    "currPeriodEnd" &&
                                                  row[colName] !== undefined
                                                    ? // Format currPeriodEnd date to dd/mm/yyyy
                                                      new Date(
                                                        row[colName]
                                                      ).toLocaleDateString(
                                                        "en-GB",
                                                        {
                                                          day: "2-digit",
                                                          month: "short",
                                                          year: "numeric",
                                                        }
                                                      )
                                                    : roundOffToTwoDecimals(
                                                        row[colName]
                                                      )}
                                                </div>
                                              ) : (
                                                <React.Fragment>
                                                  {/* Conditional Rendering based on Edit Mode */}
                                                  {editLedgerDetails &&
                                                  editLedgerDetails.rowIndex ===
                                                    rowIndex &&
                                                  editLedgerDetails.colName ===
                                                    colName ? (
                                                    <TextField
                                                      value={
                                                        row[colName] === "0"
                                                          ? ""
                                                          : row[colName]
                                                      }
                                                      onChange={(e) =>
                                                        handleLedgerValueChange(
                                                          rowIndex,
                                                          colName,
                                                          e.target.value === ""
                                                            ? "0"
                                                            : e.target.value
                                                        )
                                                      }
                                                    />
                                                  ) : (
                                                    <div
                                                      onClick={() =>
                                                        handleEditLedgerCell(
                                                          rowIndex,
                                                          colName
                                                        )
                                                      }>
                                                      {/* ValueText Component for Displaying Value */}
                                                      <ValueText
                                                        title={
                                                          row[colName] !==
                                                          undefined
                                                            ? roundOffToTwoDecimals(
                                                                row[colName]
                                                              )
                                                            : "-"
                                                        }
                                                      />
                                                    </div>
                                                  )}
                                                </React.Fragment>
                                              )}
                                            </TableCell>
                                          )
                                        )}
                                        {/* Delete icon cell */}
                                        <TableCell>
                                          <IconButton
                                            onClick={() =>
                                              handleOpenDeleteDialog(
                                                row.memberId,
                                                row.ledgerDetId
                                              )
                                            }>
                                            <DeleteIcon />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    </React.Fragment>
                                  ))}
                                  {/* Displaying Advance Details again excluding those already attached to ledgers */}
                                  {advanceDetails
                                    .filter(
                                      (advance) =>
                                        !ledgerDetails.some((row) =>
                                          row.advanceDetails?.some(
                                            (a) => a.key === advance.key
                                          )
                                        )
                                    )
                                    .map((advance, advanceIndex) => (
                                      <TableRow
                                        key={`remaining_advance_${advanceIndex}`}
                                        style={{ backgroundColor: "#f0f0f0" }}>
                                        {Object.keys(columnLabels).map(
                                          (colName) => (
                                            <TableCell
                                              key={`remaining_advance_cell_${advanceIndex}_${colName}`}>
                                              {colName === "currPeriodEnd" ? (
                                                <div>
                                                  {advance.withdrwlDate
                                                    ? new Date(
                                                        advance.withdrwlDate
                                                      ).toLocaleDateString(
                                                        "en-GB",
                                                        {
                                                          day: "2-digit",
                                                          month: "short",
                                                          year: "numeric",
                                                        }
                                                      )
                                                    : "-"}
                                                </div>
                                              ) : colName === "withdrwlAmt" ? (
                                                <div>
                                                  <ValueText
                                                    title={
                                                      advance[colName] !==
                                                      undefined
                                                        ? roundOffToTwoDecimals(
                                                            advance[colName]
                                                          )
                                                        : "-"
                                                    }
                                                  />
                                                </div>
                                              ) : (
                                                <div>
                                                  <ValueText
                                                    title={
                                                      advance[colName] !==
                                                      undefined
                                                        ? roundOffToTwoDecimals(
                                                            advance[colName]
                                                          )
                                                        : "-"
                                                    }
                                                  />
                                                </div>
                                              )}
                                            </TableCell>
                                          )
                                        )}
                                      </TableRow>
                                    ))}
                                </>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </ClickAwayListener>

                      {/* New Ledger Details */}
                      {newLedgerDetailsVisible && (
                        <Paper>
                          <ClickAwayListener
                            onClickAway={() => setEditNewLedgerDetails(null)}>
                            <TableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    {Object.keys(columnLabels).map(
                                      (colName) => (
                                        <TableCell
                                          key={colName}
                                          className={[
                                            "memPfContri",
                                            "employerPfContri",
                                            "volContri",
                                            "interestPerc",
                                          ]}>
                                          <LabelText
                                            title={columnLabels[colName]}
                                          />
                                        </TableCell>
                                      )
                                    )}
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {newLedgerDetails.map((row, rowIndex) => (
                                    <React.Fragment key={rowIndex}>
                                      <TableRow>
                                        {Object.keys(columnLabels).map(
                                          (colName) => (
                                            <TableCell
                                              key={colName}
                                              className={
                                                colName === "interestPerc"
                                                  ? classes.hoverableCell
                                                  : ""
                                              }>
                                              {colName === "interestPerc" ? (
                                                <TextField
                                                  type="number"
                                                  inputProps={{ step: "0.10" }}
                                                  value={
                                                    row[colName] === 0
                                                      ? ""
                                                      : row[colName]
                                                  }
                                                  onChange={(e) =>
                                                    handleNewLedgerValueChange(
                                                      rowIndex,
                                                      colName,
                                                      e.target.value === ""
                                                        ? ""
                                                        : e.target.value
                                                    )
                                                  }
                                                />
                                              ) : colName === "unitCode" ||
                                                colName === "withdrwlAmt" ||
                                                colName === "withdrwlTyp" ||
                                                colName === "withdrwlDate" ||
                                                colName === "openingBalance" ||
                                                colName === "closingBal" ||
                                                colName === "interestAmt" ? (
                                                <div
                                                  style={{
                                                    whiteSpace: "nowrap",
                                                  }}>
                                                  {row[colName] !== undefined
                                                    ? roundOffToTwoDecimals(
                                                        row[colName]
                                                      )
                                                    : "-"}
                                                </div>
                                              ) : (
                                                <>
                                                  {editNewLedgerDetails &&
                                                  editNewLedgerDetails.rowIndex ===
                                                    rowIndex &&
                                                  editNewLedgerDetails.colName ===
                                                    colName ? (
                                                    <TextField
                                                      value={
                                                        row[colName] === 0
                                                          ? ""
                                                          : row[colName]
                                                      }
                                                      onChange={(e) =>
                                                        handleNewLedgerValueChange(
                                                          rowIndex,
                                                          colName,
                                                          e.target.value === ""
                                                            ? ""
                                                            : e.target.value // Set to empty string instead of "0"
                                                        )
                                                      }
                                                    />
                                                  ) : (
                                                    <div
                                                      onClick={() =>
                                                        handleEditNewLedgerCell(
                                                          rowIndex,
                                                          colName
                                                        )
                                                      }>
                                                      <ValueText
                                                        title={
                                                          row[colName] !==
                                                          undefined
                                                            ? roundOffToTwoDecimals(
                                                                row[colName]
                                                              )
                                                            : "-"
                                                        }
                                                      />
                                                    </div>
                                                  )}
                                                </>
                                              )}
                                            </TableCell>
                                          )
                                        )}
                                      </TableRow>
                                      {row.advanceDetails &&
                                        row.advanceDetails.map(
                                          (advance, advanceIndex) => (
                                            <TableRow
                                              key={`advance_${rowIndex}_${advanceIndex}`}>
                                              {Object.keys(columnLabels).map(
                                                (colName) => (
                                                  <TableCell key={colName}>
                                                    <ValueText
                                                      title={
                                                        advance[colName] !==
                                                        undefined
                                                          ? roundOffToTwoDecimals(
                                                              advance[colName]
                                                            )
                                                          : "-"
                                                      }
                                                    />
                                                  </TableCell>
                                                )
                                              )}
                                            </TableRow>
                                          )
                                        )}
                                    </React.Fragment>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </ClickAwayListener>
                          <Button
                            variant="contained"
                            color="success"
                            onClick={handleOpenDialog1}
                            style={{
                              marginRight: "10px",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}>
                            Add
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCancel2}
                            style={{
                              marginRight: "10px",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}>
                            cancel
                          </Button>
                        </Paper>
                      )}
                    </>

                    {/* Button to Add Row */}

                    {/* Container for Cancel and Submit Buttons */}
                    <div style={{ marginTop: "10px", textAlign: "right" }}>
                      {isAddRowVisible && (
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={handleAddRow}
                          style={{ marginRight: "10px" }}>
                          Add Row
                        </Button>
                      )}
                      {/* Button to Cancel Edit */}
                      {/* {isCancelVisible && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleCancelEdit}
                        >
                          Cancel
                        </Button>
                      )} */}
                      {/* Button to Submit Changes */}
                      {isSubmitVisible && (
                        <Button
                          variant="contained"
                          color="success"
                          onClick={handleOpenDialog}
                          style={{ marginLeft: "10px" }}>
                          Save
                        </Button>
                      )}
                    </div>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
export default SampleTable;
