import React, { useState, useEffect } from 'react';
import {Box, Radio, Grid, RadioGroup, FormControlLabel, FormGroup, FormControl, FormLabel, Typography } from '@mui/material';
import '../styles/stylesheet.css';



function GroupedRadioButton(props) {

 

  const { options, label, initialValue, disabled, onchange, name } = props;

  const [selectedValue, setSelectedValue] = useState(initialValue);


  useEffect(() => {
    setSelectedValue(initialValue);
  }, [initialValue]);

  const handleChange = (event) => {
    if (!disabled) {
      const value = event.target.value;
      setSelectedValue(value);
      onchange(event);
    }
  };



  return (
   <Box
      sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
   >
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
        <RadioGroup id="demo-radio-buttons-group-label" row name={name} value={selectedValue} onChange={handleChange} >
          {/* <Grid container spacing={2}> */}
            {options.map((option) => (
              // <Grid item sm={4} key={option.value}>
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio readOnly={disabled} />}
                  label={
                    <Typography variant="caption" sx={{
                      fontSize: {
                        xs: '10px',
                        sm: '12px',
                        md: '12px',
                        lg: '14px',
                        xl: '16px',
}}}>
                      {option.label}
                    </Typography>
                  }
                />
              // </Grid>
            ))}
          {/* </Grid> */}
        </RadioGroup>
      </FormControl>
   </Box>
  );
};

export default GroupedRadioButton;
