import { Grid, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import MemberDetails from "../UDAPages/MemberDetatils";
import BackButton from "../../components/button/BackButton";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ViewDocuments from "../../SettlementComponents/ViewDocuments";
import Reloader from "../../FormComponent/Reloader";
import LoadingIndicator from "../../FormComponent/LoadingIndicator";
import ClaimDetailsPf from "../../SettlementComponents/ClaimDetailsPf";
import ClaimDetailsPen from "../../SettlementComponents/ClaimDetailsPen";
import ClaimFormPensionPrefilled from "./ClaimFormPensionPrefilled";
import ErrorAlert from "../../FormComponent/ErrorAlert";

function EditPen() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const claimId = state.passingId;
  const claimType = claimId.substring(0, 2);
  const sampleMemberData = {
    // This is the sample claimData data to be displed for demo purpose only
    title: "",
    fullName: "",
    dateOfBirth: "",
    panNo: "",
    aadhaarNumber: "",
    maritalStatus: "",
    religionName: "",
    gender: "",
    fatherHusbandName: "",
    email: "",
    mobileNo: "",
    landNo: "",
    addressL1: "",
    addressL2: "",
    village: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  };

  // const sampleClaimPf = {
  //   // This is the sample member data to be displed for demo purpose only
  //   claimId: "",
  //   fullName: "",
  //   cmpfAccNo: "",
  //   unitCode: "",
  //   submissionDate: "",
  //   remarks: "",
  //   nameAadhaar: "",
  //   nameBank: "",
  //   namePan: "",
  //   dobInAadhaar: "",
  //   dobInPan: "",
  //   sfwhName: "",
  //   sfwhRelationDesc: "",
  //   pfClaimType: "",
  //   pfClaimStatus: "",
  // };

  const sampleClaimPf = {
    claimDetails: [
      {
        claimId: "",
        fullName: "",
        cmpfAccNo: "",
        unitCode: "",
        submissionDate: "",
        remarks: "",
        nameAadhaar: "",
        nameBank: "",
        namePan: "",
        dobInAadhaar: "",
        dobInPan: "",
        sfwhName: "",
        sfwhRelationDesc: "",
        pfClaimType: "",
        pfClaimStatus: "",
        claimantId: "",
      },
    ],
    officerRemarks: [
      {
        remarks: "",
        status: "",
        fromLoginId: "",
      },
    ],
  };
  const [pfClaimData, setPfClaimData] = useState(sampleClaimPf);
  const [memberData, setMemberData] = useState(sampleMemberData);
  const [isMemberFound, setIsMemberFound] = useState(" ");
  const [documents, setDocuments] = useState([]);

  //**********for integration************* */
  const [isLoading, setIsLoading] = useState(false); // set this to true
  const [isLoadingDoc, setIsLoadingDoc] = useState(false); // set this to true
  const [isFailed, setIsFailed] = useState(false);

  //**********for Error Alerts************* */
  const [bankErrors, setBankErrors] = useState(false); // the same state is used for set errors if family member is not present in pension
  const [bankErrorMessage, setBankErrorMessage] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);
  const [isFailedDoc, setIsFailedDoc] = useState(false);

  const [nameData, setNameData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  // declaring the default api objectiugv/

  let api = {
    claimUrl: "",
    docsUrl: "",
  };
  const pfApis = {
    claimUrl: "/show/pf/claim/details/to/SI/on/claimid",
    docsUrl: "/show/pf/claim/uploaded/documents/claimid",
  };
  const penApis = {
    claimUrl: "/show/pen/claim/on/claimid",
    //docsUrl: "/show/pen/claim/uploaded/documents/claimid",
    docsUrl: "/show/submitted/documents/pen/claim",
  };
  let title = "";
  if (claimType === "PF") {
    api = { ...pfApis };
    title = "PF";
  } else if (claimType === "PN") {
    api = { ...penApis };
    title = "Pension";
  }

  console.log("api objict", api);

  // object for pen apis

  //********DEFAULT for family details*********/
  const familyDefault = {
    familyMemId: "",
    name: "",
    gender: "",
    relationName: "",
    dateOfBirth: "",
    pfNomSharePerc: "",
  };

  const [nomineeDetails, setNomineeDetails] = useState([familyDefault]);

  const handleBankErrorAlert = () => {
    setBankErrors(false);
    navigate(-1);
  };

  // ******** setting props for the TableWithLinkAndButton component*******

  // const viewLink = "/familydetails"; // Link for family members details
  useEffect(() => {
    fetchAllClaimDetail();
  }, []);
  const fetchClaimDetailAdv = async () => {
    console.log("Claim ID", claimId);
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    // const url = process.env.REACT_APP_SERVER_URL + "/show/pf/claim/on/claimid";

    const url = process.env.REACT_APP_SERVER_URL + api.claimUrl;
    const api2 = "/generate/penset";
    const api3 = "/show/returned/data/for/pen/at/uda";
    const url2 = process.env.REACT_APP_SERVER_URL + api2;
    const url3 = process.env.REACT_APP_SERVER_URL + api3;

    console.log("Claim ID", claimId);
    const formData = new FormData();
    formData.append("claimId", claimId);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      const response = await axios.post(url, formData, config);
      console.log("55555", response);
      if (response.status === 200) {
        const data = response.data.data;
        console.log("data", data);
        setPfClaimData(data);
        console.log("claimdetailsssss", data.claimDetails[0]);
        fetchMemberDetail(data.claimDetails[0].cmpfAccNo);
        fetchDocuments(data.claimDetails[0].claimantId);
      }
      // setIsFailed(true);

      const response2 = await axios.post(url2, formData, config);
      console.log("Response from url2:", response2);

      if (response2.status === 200) {
        const data2 = response2.data.data;
        setAdditionalData(data2);
        console.log("Additional data", additionalData[0]);
      }
      const response3 = await axios.post(url3, formData, config);
      console.log("Response from url3:", response3);

      if (response3.status === 200) {
        const data3 = response3.data.data;
        setNameData(data3);
        console.log("Names data", nameData);
      }
    } catch (error) {
      console.log("error on claimfetch", error);
      setIsLoading(false);
      //setIsFailed(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllClaimDetail = () => {
    fetchClaimDetailAdv();
  };
  const fetchDocuments = async (claimantId) => {
    try {
      // const url = `${process.env.REACT_APP_SERVER_URL}/show/pf/claim/uploaded/documents/claimid`;
      const url = process.env.REACT_APP_SERVER_URL + api.docsUrl;

      const formData = new FormData();
      formData.append("claimId", claimId);
      formData.append("claimantId", claimantId);
      const config = {
        headers: {
          // Include the Authorization header if req uired
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(url, formData, config);

      setIsLoadingDoc(false);
      setIsFailedDoc(false);

      if (response.status === 200) {
        const data = response.data.data;
        console.log("documents", data);
        setDocuments(data);
      } else {
        // setIsFailed(true);
      }
    } catch (error) {
      // Corrected syntax for the catch block
      setIsLoading(false);
      // setIsFailed(true);
    }
  };

  const { claimDetails } = pfClaimData;
  let draftPayOrder = claimDetails[0]?.draftPayOrder; // Use optional chaining here
  let payOrder = claimDetails[0]?.payOrder;
  // Combine data from the two APIs
  useEffect(() => {
    if (draftPayOrder) {
      const updatedCombinedData = [
        ...documents,
        {
          docTypeDesc: claimType === "PF" ? "DPO" : "PENSET",
          uploadedDoc: draftPayOrder,
        },
      ];

      // Assuming advClaimData.advClaim.purchaseOrder is the document from the second API
      if (payOrder) {
        updatedCombinedData.push({
          docTypeDesc: claimType === "PF" ? "PO" : "PPO",
          uploadedDoc: payOrder,
        });
      }

      setCombinedData(updatedCombinedData);
    }
  }, [documents, pfClaimData]);

  ///****************integration started********************* */

  const fetchMemberDetail = async (accNo) => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + "/member/details/for/pf";
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          if (data.length !== 0) {
            setMemberData(data[0]);
            setIsMemberFound("true");

            if (!data[0].maritalStatus) {
              // console.log("maritalStatus is null")
              setBankErrors(true);
              setErrorTitle("NOT FOUND! ");
              setBankErrorMessage(
                "Marital Status  of the Member is not found. Please update the same from Update Member Details and then proceed."
              );
              return;
            }

            if (data[0].penBankId === 1) {
              setBankErrors(true);
              setErrorTitle("NOT FOUND! ");
              setBankErrorMessage(
                "Bank account details for Pension are not found. Please update the same from Update Member Details and then proceed."
              );
              return;
            }

            fetchNomineeDetail(data[0].cmpfAccNo, data[0].maritalStatus);
          }
          setIsMemberFound("false");
          return;
        }
        setIsFailed(true);
        setIsMemberFound("false");
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        setIsMemberFound("false");
      });
  };

  /***** function to get all nominee details**************** */
  const fetchNomineeDetail = async (accNo, maritalStatus) => {
    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");

    const url =
      process.env.REACT_APP_SERVER_URL + "/get/all/nominee/details/pension";
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo); // check what formdata is to be sent
    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsLoading(false);
        setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          if (maritalStatus === "M") {
            console.log("memberIsMarried");
            if (data.length !== 0) {
              setNomineeDetails(data);

              data.map((nominee) => {
                console.log("aadhaar no is", nominee.aadhaarNo);
                if (!nominee.aadhaarNo) {
                  setBankErrors(true);
                  setErrorTitle("NOT FOUND!");
                  setBankErrorMessage(
                    `Sorry claim cannot proceed. Aadhaar No for spouse (${nominee.name}) not present . Please Update Family Member Details`
                  );
                }
              });
            } else {
              setBankErrors(true);
              setErrorTitle("NOT FOUND!");
              setBankErrorMessage(
                "Sorry claim cannot proceed. Spouse details for this member is not present."
              );
            }
          } else {
            if (data.length !== 0) {
              setNomineeDetails(data);
            }
          }

          return;
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
      });
  };
  console.log("ccllmm", claimDetails[0]);
  return (
    <div>
      <ErrorAlert
        handleOk={handleBankErrorAlert}
        open={bankErrors}
        title={errorTitle}
        body={bankErrorMessage}
        buttonName="Ok"
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "10px" }}>
            {isFailed || isFailedDoc ? (
              <Reloader refreshHandler={fetchAllClaimDetail} />
            ) : (
              <>
                {isLoading || isLoadingDoc ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center">
                      <Grid item xs={12}>
                        <TitleText title={`${title} Settlement`} />
                      </Grid>
                    </Grid>
                    <FormDivider />
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={1} mb={3}>
                        <BackButton />
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="start"
                        rowGap={3}>
                        {/* Member Details*/}
                        {/* section 1  */}
                        <Paper
                          elevation={3}
                          sx={{ padding: "3rem", borderRadius: "10px" }}>
                          <Grid item xs={12}>
                            <MemberDetails member={memberData} />
                          </Grid>
                        </Paper>
                        {/* claim Details*/}
                        {/* section 2  */}
                        <Paper
                          elevation={3}
                          sx={{ padding: "3rem", borderRadius: "10px" }}>
                          <Grid item xs={12}>
                            <ClaimDetailsPf pfclaimdetails={pfClaimData} />
                          </Grid>
                        </Paper>
                        {/* Document Details*/}
                        {/* section 3  */}
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={12}>
                            <Paper
                              elevation={3}
                              sx={{ padding: "3rem", borderRadius: "10px" }}>
                              <Grid item xs={12}>
                                {draftPayOrder ? (
                                  <ViewDocuments documents={combinedData} />
                                ) : (
                                  <ViewDocuments documents={documents} />
                                )}
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>
                        {/* <Paper
                            elevation={3}
                            sx={{ padding: "3rem", borderRadius: "10px" }}
                            > */}
                        <Grid item xs={12}>
                          <ClaimFormPensionPrefilled
                            // cmpfAccNo={accNo}
                            memberData={memberData}
                            claimDetails={claimDetails[0]}
                            // nomineeDetails={nomineeDetails}
                            nomineeDetails={nomineeDetails}
                            additionalData={additionalData[0]}
                            nameData={nameData}
                            //   nameData = {nameData}
                          />
                        </Grid>
                        {/* </Paper> */}
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default EditPen;
