import React, { useState, useEffect, useRef } from 'react';
import { Container, Grid, Typography, Box, ThemeProvider,useTheme,SwipeableDrawer, IconButton, Paper } from '@mui/material';
// import TableOfContents from './TABLEOfCONTENTS';
import TableOfContents from './TableOfContents';
import AllSectionsContent from './AllSectionsContent';
import UserGuideTheme from './UserGuideTheme';
import { AppBar } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme} from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Header from '../components/Header';

const sections = [
  {
    title: 'AN OVERVIEW OF C--CARES',
    details: ['Purpose of the Document', 'System Overview', 'Contact'],
  },
  {
    title: 'GETTING STARTED',
    details: ['System Requirements', 'Accessing C-CARES Portal', 'Member Enrolment'
      
    , 'Member Login'],
  },
  {
    title: 'MEMBER DASHBOARD',
    details: [
      'Account Summary',
      'PF Statement',
      'Change Password',
      'View Profile',
      'Add Family Member',
      'View Family Member',
      'Forgot Password',
    ],
  },

  {
    title: 'APPENDIX--A', 
    details: [],
  },
];

function UserGuide() {
  const [selectedSection, setSelectedSection] = useState(null);
  const contentRef = useRef(null);
 
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
// For drawer
const [drawerOpen, setDrawerOpen] = useState(false);
const handleDrawerToggle = () => {
 
  setDrawerOpen(!drawerOpen);
};
// For AppBar toggle
const [top] = useState(false);
  
// const toggleDrawer = (anchor, open) => (event) => {
//   if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
//   setState({ [anchor]: open });
// };
<IconButton edge="start" onClick={handleDrawerToggle} style={{ marginRight: '20px' }}><MenuIcon /></IconButton>

// create a function to render side drawer on small screens
const renderDrawer = () => (
  <SwipeableDrawer anchor={'left'} open={drawerOpen} onClose={handleDrawerToggle} onOpen={handleDrawerToggle}>
    <Box
      style={{ width: '250px', height: `100%`, overflowY: 'auto', padding: '10px',background: 'linear-gradient(180deg, hsla(297, 59%, 34%, 1) 0%, hsla(345, 74%, 58%, 1) 68%, hsla(22, 87%, 56%, 1) 100%)', }}
    >
      <Typography variant="h6" color="secondary" sx={{ marginTop: '5px' }}>TABLE OF CONTENTS</Typography>
      <TableOfContents sections={sections} selectedSection={selectedSection} onSectionSelect={handleSectionSelect} />
    </Box>
  </SwipeableDrawer>
);

  useEffect(() => {
    if (selectedSection && contentRef.current) {
      console.log('selectedSection',selectedSection);
      const escapedSectionId = selectedSection
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-');
        console.log('escapedSectionId',escapedSectionId)
      const selectedSectionElement = contentRef.current.querySelector(`#${escapedSectionId}`);
      if (selectedSectionElement) {
        // selectedSectionElement.scrollIntoView({ behavior: 'smooth' });
        const scrollTop = selectedSectionElement.offsetTop - 150;
        // Set the scrollTop of the content div
        contentRef.current.scrollTop = scrollTop;
      }
    }
  }, [selectedSection]);

  const handleSectionSelect = (section) => {
    console.log("Selected section:", section);
    const isDetailsSection = sections.some((sec) => sec.details.includes(section));
  
    if (isDetailsSection) {
      // If it's a "details" section, close the drawer
      setDrawerOpen(false);
    }
    setSelectedSection(section === selectedSection ? null : section);

    
   
  };

  return (
    <div>
     <ThemeProvider theme={UserGuideTheme}>
     <Grid container> 
       <Grid item xs={12} >
          <Header/>
        <Grid/>
        <Grid item xs={12} >
          <AppBar 
            // sx={{
            //   // background: 'linear-gradient(180deg, hsla(297, 59%, 34%, 1) 0%, hsla(345, 74%, 58%, 1) 68%, hsla(22, 87%, 56%, 1) 100%)',
            //    background: 'linear-gradient(180deg, hsla(297, 59%, 34%, 1) 90%, hsla(345, 74%, 58%, 1) 90%, hsla(22, 87%, 56%, 1) 90%)',
            //    paddingBottom:".4%"
            // }}>
            sx={{
              position:'static',
              
              height: "50px",
              background:
                "linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(235,68,68,1) 50%, rgba(252,176,69,1) 96%)",
                paddingBottom:".4%"
            }}>
            <Grid container>  
              {isMobile && (
              <Grid item xs={1} sm={1} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                {/* <IconButton edge="start" onClick={handleDrawerToggle} style={{ marginRight: '20px' }}> */}
                <IconButton edge="start" onClick={handleDrawerToggle} style={{ marginLeft: '10px' }}>
                 <MenuIcon style={{ color: 'white' }} />
                </IconButton>
              </Grid>
                )}
              <Grid item xs={11} sm={11} md={12} sx={{ display: 'flex',  alignItems: 'center', justifyContent: isMobile ? 'flex-end' : 'center'  }}>
                <Typography variant="h6" component="div" sx={{paddingTop:"8px",fontWeight:"600" ,flexGrow: 1 ,marginRight: isMobile ? '20px' : '0' }} align={ isMobile ? 'right' : 'center'}>
                   Users' Manual
                </Typography>
              </Grid>
            </Grid>  
          </AppBar>
        </Grid> 
      </Grid> 
      </Grid> 

 
     

     
      {/* <Container sx={{margin:"0.6%"}}  > */}
      <Container sx={{margin:"0.6%"}}  >
        <Grid container spacing={2} rowSpacing={5}>
        
        
         
          {/* <Grid item xs={3}>
          //for making responsive */}
          {isMobile ? renderDrawer() :
          <Grid item xs={12} sm={3} md={3}>
            <Box
              style={{
                 height: `calc(100vh - 25px)`, 
                overflowY: 'auto',
                 position: 'sticky',
           
                 padding: '0px',
                background: 'linear-gradient(180deg, hsla(297, 59%, 34%, 1) 0%, hsla(345, 74%, 58%, 1) 68%, hsla(22, 87%, 56%, 1) 100%)',
                 textAlign: 'center',

               
              }}
            >
              <Typography variant="h6" color="secondary" sx={{ marginTop: '5px' ,textAlign: 'center'}}>TABLE OF CONTENTS</Typography>
              <TableOfContents
                sections={sections}
                selectedSection={selectedSection}
                onSectionSelect={handleSectionSelect}
              />
            </Box>
          </Grid>}
          {/* <Grid item xs={9} >
          //for making responsive */}
          <Grid item xs={12} sm={9} md={9} >
            <div ref={contentRef}
            style={{
                // marginTop: '20px',
                height: `calc(100vh - 20px)`, // Adjust the height to account for the gap
                overflowY: 'auto',
                //padding: '10px',
           
               
              }}>
                
                 <AllSectionsContent />
            </div>

          </Grid>
        </Grid>
      </Container>
    
  
    </ThemeProvider> 
    
    </div>
  );
}

export default UserGuide;
