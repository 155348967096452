import { Typography } from "@mui/material";
import React from "react";
import GlobalFontStyle from "./GlobalFontStyle";

function SubtitleText(props) {
  return (
    <>
      <GlobalFontStyle />
      <Typography
        variant="subtitle1"
        align="left"
        sx={{
          //color: "#2C394B",
          color: "#6b112e",
          //color: "#4f8c62",
          // fontFamily: 'Fira Sans Condensed',
          fontSize: {
            xs: "13px",
            sm: "14px",
            md: "15px",
            lg: "16px",
            xl: "17px",
          },
          fontWeight: "600",
        }}
      >
        {props.title}
      </Typography>
    </>
  );
}

export default SubtitleText;
