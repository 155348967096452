import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Paper, Box, Button, Divider } from "@mui/material";
import { memberModel } from "../Models/defaultModel";

import TitleText from "../FormComponent/TitleText";
import LabelText from "../FormComponent/LabelText";
import CustomTextField from "../FormComponent/CustomTextField";
import CustomDropdown from "../FormComponent/CustomDropdown";
import GroupedRadioButton from "../FormComponent/GroupedRadioButton";
import SubtitleText from "../FormComponent/SubtitleText";
import CustomTextArea from "../FormComponent/CustomTextArea";
import LoadingIndicator from "../FormComponent/LoadingIndicator";
import FormDivider from "../FormComponent/FormDivider";
import { faClosedCaptioning } from "@fortawesome/free-solid-svg-icons";
import ValueText from "../FormComponent/ValueText";
import jwtDecode from "jwt-decode";
import axios from "axios";
import Reloader from "../FormComponent/Reloader";
// import { validateSession } from "../../session/EventManager";

function ClaimDetailsPen(props) {
  const { pfclaimdetails } = props;
  const { claimDetails, officerRemarks } = pfclaimdetails;
  const [claimData] = claimDetails;
  //const extractedRemarks = officerRemarks.map((remark) => remark.remarks);
  const extractedRemarks = officerRemarks.map(
    (remark) => remark.status + ": " + remark.remarks
  );

  console.log("claimData", claimData);

  const parseName = (name) => {
    try {
      //console.log("name", claimData);
      const parsedName = JSON.parse(name);
      const { firstname, middlename, lastname } = parsedName;
      const fullName = `${firstname} ${
        middlename ? middlename + " " : ""
      }${lastname}`;
      return fullName;
    } catch (error) {
      console.error("Error parsing name:", error);
      return "";
    }
  };

  return (
    <Grid container justifyContent="center" sx={{ wordWrap: "break-word" }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <>
          {/* ************** */}
          {/* section 1  */}
          <Grid
            container
            justifyContent="space-between"
            alignItems="start"
            rowGap={3}
          >
            <Grid item xs={12} sm={12}>
              <SubtitleText title="Claim Details" />
            </Grid>

            {/* column 1  */}
            <Grid item xs={12} sm={5}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
                columnGap={1}
              >
                {/* Rows for Claim Details */}
                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Claim ID" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}
                >
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText title={claimData.claimId} />
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Name as per Aadhaar Card" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}
                >
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* <ValueText title={parseName(claimData.nameAadhaar)} /> */}
                  <ValueText title={parseName(claimData.nameAadhaar)} />
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Name as per Bank " />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}
                >
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText title={parseName(claimData.nameBank)} />
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="DOB as per Aadhaar Card" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}
                >
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText title={claimData.dobInAadhaar} />
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Remarks" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}
                >
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText title={extractedRemarks} />
                </Grid>
              </Grid>
            </Grid>

            {/* column 2  */}
            <Grid item xs={12} sm={5}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                columnGap={1}
              >
                {/* Rows for claim Details */}

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Submission Date" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}
                >
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText
                    title={
                      claimData.submissionDate
                        ? new Date(claimData.submissionDate).toLocaleDateString(
                            "en-GB"
                            // options
                          )
                        : ""
                    }
                  />
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Name as per Pan Card" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}
                >
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText title={parseName(claimData.namePan)} />
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}
                ></Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText title="" />
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="DOB as per Pan" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}
                >
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText title={claimData.dobInPan} />
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Claim Type" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}
                >
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText title={claimData.penClaimType} />
                </Grid>
              </Grid>
            </Grid>
            {/* ************** */}
          </Grid>
        </>
        {/* </Paper> */}
      </Grid>
    </Grid>
  );
}

export default ClaimDetailsPen;
