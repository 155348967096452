import React, { useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Button,
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";
import {
  ccEnrolmentModel,
  ccEnrolmentMandatoryFields,
} from "../../Models/defaultModel";
import {
  formPatternValidation,
  trimStringUtility,
} from "../../Utility/UtilityMethods";
import LabelText from "../../FormComponent/LabelText";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import CustomTextField from "../../FormComponent/CustomTextField";
import SubtitleText from "../../FormComponent/SubtitleText";
import axios from "axios";
import CustomDropdown from "../../FormComponent/CustomDropdown";

function CCEnrolment() {
  const ccDefaultModel = ccEnrolmentModel;
  const [cc, setCC] = useState(ccDefaultModel);
  const [readOnly] = useState(false);
  // for state autocomplete
  const [stateLoading, setStateLoading] = useState(false);
  const [stateList, setStateList] = useState([]);
  // for CC rep auto complete
  const [ccRepLoading, setCCRepLoading] = useState(false);
  const [ccRepNameList, setCCRepNameList] = useState([]);
  // for Coal Company list
  const [ccCodeList, setCCcodeList] = useState([]);
  const [ccNameList, setCCNameList] = useState([]);

  const [ccHeadList, setCCHeadList] = useState([]);
  const [ccDesignList, setCCDesignList] = useState([]);

  // const cc_name = {
  //   name: "cc_name",
  //   type: "text",
  //   helperText: '',
  //   placeholder: 'Coal Company Name*',
  //   defaultValue: cc.cc_name,
  //   errorMessage:
  //     "Should contain only AlphaNumeric Characters.underscore is accepted",
  //   pattern: '^[a-zA-Z0-9-]{0,25}$',
  //   required: ccEnrolmentMandatoryFields.includes('cc_name'),
  //   transformInput: trimStringUtility,
  //   disabled: readOnly,
  // };
  // dropdown
  const cc_name = {
    name: "cc_name",
    placeholder: "Coal Company Name",
    //label: "Select CC",
    helperText: "",
    disabled: readOnly,
    initialValue: cc.cc_name,
    required: ccEnrolmentMandatoryFields.includes("cc_name"),
    //options: [{ id: '1', title: "Hindu" }, { id: '2', title: 'Christian' }, { id: '3', title: 'Muslim' }, { id: '4', title: 'Jain' }, { id: '5', title: 'Sikh' }, { id: 6, title: 'Buddhist' }, { id: 7, title: 'Other' }],
    options: ccNameList,
  };

  // const cc_code = {
  //   name: "cc_code",
  //   type: "text",
  //   helperText: '',
  //   placeholder: 'Coal Company Code*',
  //   defaultValue: cc.cc_code,
  //   errorMessage:
  //     "Should contain only AlphaNumeric Characters.underscore is accepted",
  //   pattern: '^[a-zA-Z0-9-]{0,25}$',
  //   required: ccEnrolmentMandatoryFields.includes('cc_code'),
  //   transformInput: trimStringUtility,
  //   disabled: readOnly,
  // };
  // dropdown
  const cc_code = {
    name: "cc_code",
    placeholder: "Coal Company Code",
    //label: "Select CC",
    helperText: "",
    disabled: readOnly,
    initialValue: cc.cc_code,
    //options: [{ id: '1', title: "Hi
    required: ccEnrolmentMandatoryFields.includes("cc_code"),

    options: ccCodeList,
  };
  // dropdown
  const cc_head_name = {
    name: "cc_head_name",
    placeholder: "Coal Company Head Name",
    // label: "Select CC head",
    helperText: "",
    disabled: readOnly,
    initialValue: cc.cc_head_name,
    required: ccEnrolmentMandatoryFields.includes("cc_head_name"),
    //options: [{ id: '1', title: "Hindu" }, { id: '2', title: 'Christian' }, { id: '3', title: 'Muslim' }, { id: '4', title: 'Jain' }, { id: '5', title: 'Sikh' }, { id: 6, title: 'Buddhist' }, { id: 7, title: 'Other' }],
    options: ccHeadList,
  };

  // const cc_head_name = {
  //   name: "cc_head_name",
  //   type: "text",
  //   helperText: '',
  //   placeholder: 'CC Head Name',
  //   defaultValue: cc.cc_head_name,
  //   errorMessage:
  //     "Invalid Name.",
  //   pattern: "^[a-zA-Z- ]+$",
  //   required: ccEnrolmentMandatoryFields.includes('cc_head_name'),
  //   transformInput: trimStringUtility,
  //   disabled: readOnly,
  // };

  const cc_head_email = {
    name: "cc_head_email",
    type: "email",
    helperText: "",
    placeholder: "CC Head Official Email",
    defaultValue: cc.cc_head_email,
    errorMessage: "Invalid Email.",
    pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$",
    required: ccEnrolmentMandatoryFields.includes("cc_head_email"),
    transformInput: trimStringUtility,
    disabled: readOnly,
  };

  const cc_rep_name = {
    name: "cc_rep_name",
    type: "text",
    helperText: "",
    placeholder: "Name",
    defaultValue: cc.cc_rep_name,
    errorMessage: "Invalid Name",
    pattern: "^[a-zA-Z- ]+$",
    required: ccEnrolmentMandatoryFields.includes("cc_rep_name"),
    disabled: readOnly,
  };

  // const cc_rep_designation = {
  //   name: "cc_rep_designation",
  //   type: "text",
  //   helperText: '',
  //   placeholder: 'Designation',
  //   defaultValue: cc.cc_rep_designation,
  //   errorMessage:
  //     "First Name must be characters only and not allowed special characters except white space.",
  //   pattern: "",
  //   required: ccEnrolmentMandatoryFields.includes('cc_rep_designation'),
  //   disabled: readOnly,
  // };
  // dropdown
  const cc_rep_designation = {
    name: "cc_rep_designation",
    placeholder: "Enter Designation",
    label: "Select Designation",
    helperText: "",
    disabled: readOnly,
    initialValue: cc.cc_rep_designation,
    required: ccEnrolmentMandatoryFields.includes("cc_rep_designation"),

    //options: [{ id: '1', title: "Hindu" }, { id: '2', title: 'Christian' }, { id: '3', title: 'Muslim' }, { id: '4', title: 'Jain' }, { id: '5', title: 'Sikh' }, { id: 6, title: 'Buddhist' }, { id: 7, title: 'Other' }],
    options: ccDesignList,
  };

  const address_l1 = {
    name: "address_l1",
    type: "text",
    helperText: "",
    placeholder: "Street Address1*",
    defaultValue: cc.address_l1,
    errorMessage:
      "Invalid characters in the address. Please use only letters, digits, dot, commas, dash, and spaces.",
    pattern: "^[a-zA-Z0-9., -]+$",
    transformInput: trimStringUtility,
    required: ccEnrolmentMandatoryFields.includes("address_l1"),

    disabled: readOnly,
  };

  const address_l2 = {
    name: "address_l2",
    type: "text",
    helperText: "",
    placeholder: "Street Address2",
    defaultValue: cc.address_l2,
    errorMessage:
      "Invalid characters in the address. Please use only letters, digits, dot, commas, dash, and spaces.",
    pattern: "^[a-zA-Z0-9., -]+$",
    transformInput: trimStringUtility,
    required: ccEnrolmentMandatoryFields.includes("address_l2"),

    disabled: readOnly,
  };

  const no_of_areas = {
    name: "no_of_areas",
    type: "text",
    helperText: "",
    placeholder: "No Of Areas Under CC",
    defaultValue: cc.no_of_areas,
    errorMessage:
      "First Name must be characters only and not allowed special characters except white space.",
    pattern: "",
    required: ccEnrolmentMandatoryFields.includes("no_of_areas"),

    disabled: readOnly,
  };

  const city = {
    name: "city",
    type: "text",
    helperText: "",
    placeholder: "City",
    defaultValue: cc.city,
    errorMessage: "Invalid Input",
    pattern: "^[A-Za-zs]{1,50}$",
    required: ccEnrolmentMandatoryFields.includes("city"),

    disabled: readOnly,
  };

  // const state = {
  //   name: "state",
  //   type: "text",
  //   helperText: "",
  //   placeholder: "State",
  //   defaultValue: cc.state,
  //   errorMessage:
  //     "First Name must be characters only and not allowed special characters except white space.",
  //   pattern: "",
  //   required: ccEnrolmentMandatoryFields.includes("state"),

  //   disabled: readOnly,
  // };
  // using autocomplete component for states

  const pincode = {
    name: "pincode",
    type: "text",
    helperText: "",
    placeholder: "Pincode",
    defaultValue: cc.pincode,
    errorMessage: "Invalid Pincode.",
    pattern: "^[0-9]{6}$",
    required: ccEnrolmentMandatoryFields.includes("pincode"),

    disabled: readOnly,
  };

  const off_email = {
    name: "off_email",
    type: "email",
    helperText: "",
    placeholder: "Official Email*",
    defaultValue: cc.off_email,
    errorMessage: "Invalid Email",
    pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$",
    required: ccEnrolmentMandatoryFields.includes("off_email"),
    disabled: readOnly,
  };

  const off_contact_no = {
    name: "off_contact_no",
    type: "text",
    helperText: "",
    placeholder: "Official Contact",
    defaultValue: cc.off_contact_no,
    errorMessage: "Invalid number",
    pattern: "^[0-9]{0,12}$",
    required: ccEnrolmentMandatoryFields.includes("off_contact_no"),

    disabled: readOnly,
  };

  const mob_no = {
    name: "mob_no",
    type: "text",
    helperText: "",
    placeholder: "Mobile Number",
    defaultValue: cc.mob_no,
    errorMessage: "Invalid Mobile No.",
    pattern: "^[0-9]{10}$",
    required: ccEnrolmentMandatoryFields.includes("mob_no"),

    disabled: readOnly,
  };
  useEffect(() => {
    fetchStates();
    fetchCCreps();
    fetchCCcodes();
    fetchCCHeads();
    fetchCCDesig();
  }, []);

  const getStates = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + "/cc/states";
      const response = await axios.get(url);
      console.log("states", response);
      return response.data.data;
    } catch (error) {
      console.log("error - states", error);
      return [];
    }
  };
  const getCCReps = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + "/cc/ccrep";
      const response = await axios.get(url);
      console.log("ccreps", response);
      return response.data.data;
    } catch (error) {
      console.log("error - ccreps", error);
      return [];
    }
  };

  const fetchStates = async () => {
    try {
      console.log("in fetch states");
      const statesListSamples = await getStates();
      console.log("statesListSamples", statesListSamples);

      const options = statesListSamples.map((option) => {
        //const firstID = option.match(/[0-9]/) ? "0-9" : option.charAt(0); // Get the first letter
        console.log("option", option);
        const firstID = option.stateName;
        console.log("firstId", firstID);

        return {
          firstID: /[0-9]/.test(firstID.charAt(0)) ? "0-9" : firstID.charAt(0),
          stateName: option.stateName,
          value: option.stateId,
        };
      });

      setStateLoading(false);
      setStateList(options);
      //console.log("unit options", options);
    } catch (error) {
      // Handle error if needed.
    }
  };
  const fetchCCreps = async () => {
    try {
      console.log("in fetch ccreps");
      const ccRepsListSamples = await getCCReps();
      console.log("ccRepsListSamples", ccRepsListSamples);

      const options = ccRepsListSamples.map((option) => {
        //const firstID = option.match(/[0-9]/) ? "0-9" : option.charAt(0); // Get the first letter
        console.log("option", option);
        const firstID = option.name;
        console.log("firstId", firstID);

        return {
          firstID: /[0-9]/.test(firstID.charAt(0)) ? "0-9" : firstID.charAt(0),
          name: option.name,
          value: option.loginId,
        };
      });

      setCCRepLoading(false);
      setCCRepNameList(options);
      console.log("ccrep options", options);
    } catch (error) {
      // Handle error if needed.
    }
  };

  const fetchCCcodes = async () => {
    const url = process.env.REACT_APP_SERVER_URL + "/cc/coalcompany";

    axios.get(url).then((response) => {
      const ccCodeListSamples = response.data.data;
      console.log("response in ccCodeList", response);
      const options = ccCodeListSamples.map((option) => ({
        id: option.ccId,
        title: option.ccCode,
      }));
      console.log("cc code List", options);
      setCCcodeList(options);
      const ccNameOptions = ccCodeListSamples.map((option) => ({
        id: option.ccId,
        title: option.name,
      }));
      console.log("cc name List", ccNameOptions);
      setCCNameList(ccNameOptions);
    });
  };

  const fetchCCHeads = async () => {
    const url = process.env.REACT_APP_SERVER_URL + "/cc/head";

    axios.get(url).then((response) => {
      const ccHeadListSamples = response.data.data;
      console.log("response in ccCodeList", response);
      const options = ccHeadListSamples.map((option) => ({
        id: option.cCHeadId,
        title: option.cCHeadName,
      }));
      console.log("ccHeadList", options);
      setCCHeadList(options);
    });
  };

  const fetchCCDesig = async () => {
    const url = process.env.REACT_APP_SERVER_URL + "/cc/designation";

    axios.get(url).then((response) => {
      const ccHeadListSamples = response.data.data;
      console.log("response in ccDesigList", response);
      const options = ccHeadListSamples.map((option) => ({
        id: option.designId,
        title: option.designation,
      }));
      console.log("ccDesignList", options);
      setCCDesignList([{ id: "", title: "Select Company Head" }, ...options]);
    });
  };

  const handleStateSearch = (event, newValue) => {
    console.log("state", newValue);
    setCC({ ...cc, state: newValue.value });
  };

  const handleCCRepNameSearch = (event, newValue) => {
    console.log("cc rep name", newValue);
    setCC({ ...cc, cc_rep_name: newValue.value });
  };

  const onChange = (e) => {
    setCC({ ...cc, [e.target.name]: e.target.value });
  };
  // to set the cc name on selecting cc code
  const handleCcCodeSelect = (e) => {
    setCC({ ...cc, cc_code: e.target.value, cc_name: e.target.value });
  };

  const validate = () => {
    if (!formPatternValidation(cc.mob_no, "^[0-9]{10}$")) {
      console.log("Invalid mob");
      return false;
    }

    if (
      !formPatternValidation(
        cc.off_email,
        "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
      )
    ) {
      console.log("In validate off email");
      return false;
    }
    if (!formPatternValidation(cc.off_contact_no, "^[0-9]{0,12}$")) {
      console.log("In validate off contact");
      return false;
    }
    if (!formPatternValidation(cc.address_l1, "^[a-zA-Z0-9., -]+$")) {
      console.log("In validate off  address1");
      return false;
    }
    if (!formPatternValidation(cc.address_l2, "^[a-zA-Z0-9., -]+$")) {
      console.log("In validate off  address2");
      return false;
    }
    if (
      !formPatternValidation(
        cc.cc_head_email,
        "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
      )
    ) {
      console.log("In validate off  cc_head_email");
      return false;
    }
    if (!formPatternValidation(cc.pincode, "^[0-9]{6}$")) {
      console.log("In validate off  pincode");
      return false;
    }
    if (!formPatternValidation(cc.city, "^[A-Za-zs]{1,50}$")) {
      console.log("In validate off city");
      return false;
    }
    return true;
  };
  const RequiredFeilds = {};

  const checkRequiredFields = () => {};

  const formData = {
    addressL1: cc.address_l1,
    addressL2: cc.address_l2,
    officialContactNo: cc.off_contact_no,
    cCHeadId: cc.cc_head_name,
    cCRepLoginId: cc.cc_rep_name,
    officialEmail: cc.off_email,
    mobile: cc.mob_no,
    stateId: cc.state,
    cCId: cc.cc_code,
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("save button Clicked data=>):", cc);
    if (!validate()) {
      console.log("validation falied");
      return;
    }

    console.log("fromdata to be sent", formData);
    const url = process.env.REACT_APP_SERVER_URL + "/enrolcc";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, formData, { headers })
      .then(function (response) {
        console.log(response);
      })
      .catch((error) => {
        console.log("error on submit", error);
      });
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
          <Grid item xs={12}>
            <TitleText title="Coal Company Enrolment" />
          </Grid>
          <FormDivider />

          {/* Form Wrapper grid */}
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            rowGap={2}
          >
            {/* Form Divider main Grid */}
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-start"
              rowGap={2}
            >
              <Grid item xs={12} sm={12}>
                <SubtitleText title="Coal Company Details" />
              </Grid>

              {/* section 1 column 1 - regional*/}
              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  rowGap={2}
                  columnGap={2}
                >
                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Company Name" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomDropdown
                      {...cc_name}
                      onchange={handleCcCodeSelect}
                    />
                  </Grid>

                  {/* row  */}
                  {/* no of areas commented if required to be uncommented remember to change the pincode field to the next Grid */}
                  {/* <Grid item xs={12} sm={4}>
                    <LabelText title="No.of Areas" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomTextField {...no_of_areas} onchange={onChange} />
                  </Grid> */}

                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Official Email" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomTextField {...off_email} onchange={onChange} />
                  </Grid>

                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Official Contact" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomTextField {...off_contact_no} onchange={onChange} />
                  </Grid>

                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="State" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Autocomplete
                      sx={{
                        minWidth: "150px",
                      }}
                      ListboxProps={{
                        style: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          maxHeight: 180, // Change this to your desired height
                        },
                      }}
                      // freeSolo
                      // inputValue={cc.state}
                      disableClearable
                      onChange={(e, newValue) => handleStateSearch(e, newValue)}
                      options={stateList.sort(
                        (a, b) => -b.firstID.localeCompare(a.firstID)
                      )}
                      groupBy={(stateList) => stateList.firstID}
                      getOptionLabel={(stateList) => stateList.stateName}
                      loading={stateLoading} // Set loading prop
                      renderInput={(params) => (
                        <TextField
                          name="state"
                          sx={{
                            "& .MuiFormHelperText-root": {
                              marginLeft: 0,
                              // fix based on your screen
                              fontSize: {
                                xs: "9px",
                                sm: "9px,",
                                md: "9px",
                                lg: "12px",
                                xl: "14px",
                              },
                              fontWeight: "400",
                              color: "black",
                            },
                            "& .MuiInputBase-input": {
                              minWidth: "80px",
                              borderRadius: "5px",
                              color: "black",
                              background: "#ffffff",
                              // fix based on your screen
                              fontSize: {
                                xs: 12,
                                sm: 12,
                                md: 13,
                                lg: 14,
                                xl: 16,
                              },
                            },
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "5px",
                              "& fieldset": {
                                borderColor: "#474E68",
                                // borderRadius:'0px'
                              },
                              "&:hover fieldset": {
                                borderColor: "#6B728E",
                                // borderRadius: '0px'
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#0F3460",
                                borderWidth: "0.5px",
                                // borderRadius: '0px'
                              },
                            },
                          }}
                          autoComplete="off"
                          // size='small'

                          {...params}
                          placeholder="Enter State"
                          // label="Unit No."

                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {stateLoading && (
                                  <CircularProgress color="inherit" size={20} />
                                )}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                          // helperText={
                          //   <div style={{ height: "18px" }}></div>
                          // }
                        />
                      )}
                    />
                  </Grid>
                  {/* row  */}
                  {/* comment this if no of areas is to be uncommented */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Pincode" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomTextField {...pincode} onchange={onChange} />
                  </Grid>
                </Grid>
              </Grid>

              {/* section 1 column 2 */}
              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  rowGap={2}
                  columnGap={2}
                >
                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Company Code" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomDropdown
                      {...cc_code}
                      onchange={handleCcCodeSelect}
                    />
                  </Grid>

                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="City" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomTextField {...city} onchange={onChange} />
                  </Grid>

                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Street Address1" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomTextField {...address_l1} onchange={onChange} />
                  </Grid>

                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Street Address2" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomTextField {...address_l2} onchange={onChange} />
                  </Grid>

                  {/* row  */}
                  {/* uncomment this if no of areas is to be uncommented */}
                  {/* <Grid item xs={12} sm={4}>
                    <LabelText title="Pincode" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomTextField {...pincode} onchange={onChange} />
                  </Grid> */}
                </Grid>
              </Grid>

              {/* ******************** CC Head ********************* */}

              <Grid item xs={12} sm={12}>
                <SubtitleText title="Coal Company Head Details" />
              </Grid>

              {/* section 2 column 1 - ro head*/}
              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  rowGap={2}
                  columnGap={2}
                >
                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Head Name" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    {/* <CustomTextField {...cc_head_name} onchange={onChange} /> */}
                    <CustomDropdown {...cc_head_name} onchange={onChange} />
                  </Grid>
                </Grid>
              </Grid>

              {/* section 2 column 2 */}
              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  rowGap={2}
                  columnGap={2}
                >
                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Head Email" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomTextField {...cc_head_email} onchange={onChange} />
                  </Grid>
                </Grid>
              </Grid>

              {/* ******************   Rep details ******************* */}
              <Grid item xs={12} sm={12}>
                <SubtitleText title="Coal Company Rep Details" />
              </Grid>

              {/* section 3 column 1 - nodal*/}
              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  rowGap={2}
                  columnGap={2}
                >
                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Officer Name" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    {/* <CustomTextField {...cc_rep_name} onchange={onChange} /> */}
                    <Autocomplete
                      sx={{
                        minWidth: "150px",
                      }}
                      ListboxProps={{
                        style: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          maxHeight: 180, // Change this to your desired height
                        },
                      }}
                      // freeSolo
                      // inputValue={cc.cc_rep_name}
                      disableClearable
                      onChange={(e, newValue) => {
                        handleCCRepNameSearch(e, newValue);
                        console.log("newValue", newValue);
                      }}
                      options={ccRepNameList.sort(
                        (a, b) => -b.firstID.localeCompare(a.firstID)
                      )}
                      groupBy={(ccRepNameList) => ccRepNameList.firstID}
                      getOptionLabel={(ccRepNameList) => ccRepNameList.name}
                      loading={ccRepLoading} // Set loading prop
                      renderInput={(params) => (
                        <TextField
                          name="cc_rep_name"
                          // onChange={(e) => handleUnitNoSearchFreeSolo(index, e)}
                          sx={{
                            "& .MuiFormHelperText-root": {
                              marginLeft: 0,
                              // fix based on your screen
                              fontSize: {
                                xs: "9px",
                                sm: "9px,",
                                md: "9px",
                                lg: "12px",
                                xl: "14px",
                              },
                              fontWeight: "400",
                              color: "black",
                            },
                            "& .MuiInputBase-input": {
                              minWidth: "80px",
                              borderRadius: "5px",
                              color: "black",
                              background: "#ffffff",
                              // fix based on your screen
                              fontSize: {
                                xs: 12,
                                sm: 12,
                                md: 13,
                                lg: 14,
                                xl: 16,
                              },
                            },
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "5px",
                              "& fieldset": {
                                borderColor: "#474E68",
                                // borderRadius:'0px'
                              },
                              "&:hover fieldset": {
                                borderColor: "#6B728E",
                                // borderRadius: '0px'
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#0F3460",
                                borderWidth: "0.5px",
                                // borderRadius: '0px'
                              },
                            },
                          }}
                          autoComplete="off"
                          // size='small'

                          {...params}
                          placeholder="CC Representative's Name"
                          // label="Unit No."

                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {ccRepLoading && (
                                  <CircularProgress color="inherit" size={20} />
                                )}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                          // helperText={
                          //   <div style={{ height: "18px" }}></div>
                          // }
                        />
                      )}
                    />
                  </Grid>

                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Mobile Number" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomTextField {...mob_no} onchange={onChange} />
                  </Grid>
                </Grid>
              </Grid>

              {/* section 3 column 2 */}
              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  rowGap={2}
                  columnGap={2}
                >
                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Officer Designation" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomDropdown
                      {...cc_rep_designation}
                      onchange={onChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* ***************** save button left aligned  *********************/}
            <Grid item xs={12} sm="auto">
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="success"
                //disabled={true}
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default CCEnrolment;
