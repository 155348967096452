import React from "react";
import { Typography } from "@mui/material";
// import cmpfoWebsite from '../../../images/cmpfoWebsite.png';
import cmpfoWebsite from '../../../images/cmpfoWebsite.png';
const AccessingPortal=()=>{
return(

<div id="accessing-c-cares-portal">
    

<Typography variant="h6">ACCESSING C-CARES PORTAL</Typography>
<Typography variant="body1" align='justify'  >
C-CARES Portal can be accessed easily by any user. To access the C-CARES Portal, open any internet browser (i.e. Chrome, Microsoft Edge, Safari etc.) and type the following URL https://cmpfo.gov.in on the browser’s address bar. (Figure 1)
</Typography>
   <p align="center">
    <img  className="bordered-image" src={cmpfoWebsite} height="25%" width="75%"/></p> 

<p align="center">Figure 1: URL of CMPFO website</p>
</div>
);
}
export default AccessingPortal;