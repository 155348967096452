import React from 'react';
import { Grid, Typography, Backdrop, Paper } from '@mui/material';

const UnderDev = () => {
  return (
      <Paper sx={{padding:'3rem'}}>
      <Grid container justifyContent='center' alignItems='center' direction='column'>
        <Grid item>
          <Typography variant="h4" align="center">
            Under Development
          </Typography>
        </Grid>
        <Grid item>
          <  Typography variant="body1" align="center">
            We are almost there.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UnderDev;
