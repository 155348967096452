import { Paper } from "@mui/material";
import React from "react";
import TableWithLinkAndButton from "../FormComponent/TableWithLinkAndButton";
import axios from "axios";

function ClaimsTable(props) {
  const { apiEndpoint } = props;

  const viewLink = "/claimdetails";
  console.log("apiendpint", apiEndpoint);

  const claimsTableSamples = [
    {
      claim_id: 1,
      claimant_name: "Baijunath Sharma",
      cmpf_acc_no: "A/1/1",
      unit_code: "A/1",
      claim_submission_date: "22-09-2023",
      remarks: "The data found is in order and is OK.",
    },
    {
      claim_id: 2,
      claimant_name: "Artika Singh",
      cmpf_acc_no: "B/1/1",
      unit_code: "B/1",
      claim_submission_date: "12-12-2022",
      remarks: "The data found is in order and is OK.",
    },
    {
      claim_id: 3,
      claimant_name: "Sangeeta Singh",
      cmpf_acc_no: "A/1/1",
      unit_code: "A/1/2",
      claim_submission_date: "20-08-2023",
      remarks: "The data found is in order and is OK.",
    },
    {
      claim_id: 4,
      claimant_name: "JaiSurya",
      cmpf_acc_no: "A/1/1",
      unit_code: "A/1/2",
      claim_submission_date: "20-08-2023",
      remarks: "The data found is in order and is OK.",
    },
    {
      claim_id: 5,
      claimant_name: "Pranav D",
      cmpf_acc_no: "A/1/1",
      unit_code: "A/1/2",
      claim_submission_date: "20-08-2023",
      remarks: "The data found is in order and is OK.",
    },
    {
      claim_id: 6,
      claimant_name: "Pankaj Sahu",
      cmpf_acc_no: "A/1/1",
      unit_code: "A/1/2",
      claim_submission_date: "20-08-2023",
      remarks: "The data found is in order and is OK.",
    },
    {
      claim_id: 7,
      claimant_name: "Shivani Parhad",
      cmpf_acc_no: "A/1/1",
      unit_code: "A/1/2",
      claim_submission_date: "20-08-2023",
      remarks: "The data found is in order and is OK.",
    },
    {
      claim_id: 8,
      claimant_name: "Shouvik Sarkar",
      cmpf_acc_no: "A/1/1",
      unit_code: "A/1/2",
      claim_submission_date: "20-08-2023",
      remarks: "The data found is in order and is OK.",
    },
    {
      claim_id: 9,
      claimant_name: "Akash Vidhuri",
      cmpf_acc_no: "A/1/1",
      unit_code: "A/1/2",
      claim_submission_date: "20-08-2023",
      remarks: "The data found is in order and is OK.",
    },
    {
      claim_id: 10,
      claimant_name: "Nandu C",
      cmpf_acc_no: "A/1/1",
      unit_code: "A/1/2",
      claim_submission_date: "20-08-2023",
      remarks: "The data found is in order and is OK.",
    },
    {
      claim_id: 11,
      claimant_name: "Yash",
      cmpf_acc_no: "A/1/1",
      unit_code: "A/1/2",
      claim_submission_date: "20-08-2023",
      remarks: "The data found is in order and is OK.",
    },
    {
      claim_id: 13,
      claimant_name: "Deepak",
      cmpf_acc_no: "A/1/1",
      unit_code: "A/1/2",
      claim_submission_date: "20-08-2023",
      remarks: "The data found is in order and is OK.",
    },
  ];

  const createTablePreviewDataSubmit = () => {
    const tableSamples = claimsTableSamples;

    const getTableData = async () => {
      try {
        const url = process.env.REACT_APP_SERVER_URL + apiEndpoint;
        const response = await axios.get(url);
        console.log("claimData", response.data);
        return response.data;
      } catch (error) {
        console.log("error - roRolesError", error);
        return [];
      }
    };

    let tablePreviewData = [];
    tableSamples.map((claim) => {
      tablePreviewData.push({
        "Claim ID": claim.claim_id,
        "Claimant Name": claim.claimant_name,
        "CMPF Account No": claim.cmpf_acc_no,
        "Unit Code": claim.unit_code,
        "Claim Submission Date": claim.claim_submission_date,
        Remarks: claim.remarks,
      });
    });
    return tablePreviewData;
  };

  return (
    <Paper elevation={3} sx={{ borderRadius: 0 }}>
      <TableWithLinkAndButton
        preview={createTablePreviewDataSubmit()}
        data={claimsTableSamples}
        passingId="claim_id"
        actions=""
        viewLink={viewLink}
        size="small"
      />
    </Paper>
  );
}

export default ClaimsTable;
