import React from "react";
import { Typography } from "@mui/material";
import ChangePassword from '../../../../images/ChangePassword.png';
import OTPVerificationCHPASS from '../../../../images/OTPVerificationCHPASS.png';
import VerifyOTPCHPASS from '../../../../images/VerifyOTPCHPASS.png';
import  PasswordSuccess from '../../../../images/PasswordSuccess.png';
const ChangePasswordFirst=()=>{
    return(<>
    <Typography variant="h6">CHANGE PASSWORD </Typography>
<Typography variant="body1" align='justify'>
        <ol type="1">
            <li>
            Login to the member account (follow steps in section 2.4). Click on Change Password tab. Change Password page is displayed. (Figure 28)
            </li>
            <li>
            On entering details correctly and clicking CHANGE PASSWORD button, OTP verification page is displayed. (Figure 29)
           <p align="center"> <img  className="bordered-image" src={ChangePassword} height="55%" width="90%"/><br />
            Figure 28: Change Password</p>
            <p align="center"> <img  className="bordered-image" src={OTPVerificationCHPASS} height="350px" width="75%"/><br />
           Figure 29: OTP Verification</p>
            </li>
            <li>
            Enter the OTP correctly and hit the VERIFY button. (Figure 30)  <br />
            <p align="center"> <img  className="bordered-image" src={VerifyOTPCHPASS} height="350px" width="75%"/><br />
            Figure 30: Verify OTP</p>
            </li>
            <li>
            On successful OTP verification, pop up message comes for successful password change. (Figure 31)
            <p align="center"> <img  className="bordered-image" src={ PasswordSuccess} height="45%" width="55%"/>  <br />
            Figure 31: Password Change Success message</p>
            After successful password change, the member is logged out of his/her account and is redirected to home page and an email is sent on the registered email id of the member for successful password change.
            
            </li>


        </ol>

   
   

    
    </Typography>
    
    
    </>);
}
export default ChangePasswordFirst;