import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import Modal from "@mui/material/Modal";
import SubtitleText from "../../FormComponent/SubtitleText";
import MemberDetails from "../UDAPages/MemberDetatils";
import CaptionText from "../../FormComponent/CaptionText";
import BackButton from "../../components/button/BackButton";
import axios from "axios";
import AdvClaimDetails from "../../SettlementComponents/AdvClaimDetails";
import { useLocation, useNavigate } from "react-router-dom";
import ViewDocuments from "../../SettlementComponents/ViewDocuments";
import CustomTextArea from "../../FormComponent/CustomTextArea";
import LabelText from "../../FormComponent/LabelText";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import Reloader from "../../FormComponent/Reloader";
import LoadingIndicator from "../../FormComponent/LoadingIndicator";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import SucessAlert from "../../FormComponent/SuccessAlert";
import ConfirmationDialogue from "../../FormComponent/ConfirmationDialogue";
import ClaimDetailsPen from "../../SettlementComponents/ClaimDetailsPen";
import ValueText from "../../FormComponent/ValueText";
import CustomTextField from "../../FormComponent/CustomTextField";
import {
  calBrokenInterestPayable,
  customRounding,
  netAmountPayable,
  totalBrokenPeriodContribution,
  totalPfAmount,
} from "./PFcalculation";
import {
  calculateCurrencyEnding,
  dateConvert,
  getAddress,
} from "../../Utility/UtilityMethods";
import UploadDocuments from "./../../SettlementComponents/UploadDocument";
import {
  disablementPension,
  lastDayOfPreviousMonth,
  monthlyPensionSuperannuation,
  pensionableService,
  resignationPension,
} from "./PensionCalculation";
import jwtDecode from "jwt-decode";
import ClaimDetailsPf from "../../SettlementComponents/ClaimDetailsPf";
import { getToken } from "../../UiManager/SessionChecker";

function ViewPenClaimRoda() {
  const { state } = useLocation();
  console.log("state", state);
  const claimId = state.passingId;
  const passingloginId = state.passingloginId;
  const dpoPdf = state.pdf;
  console.log("claimId", claimId);
  //let loginId = "DA-RO-OFF-378";
  const [loginId, setLoginId] = useState();

  /********fetching and setting token********* */
  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (token) {
      token = token.replace('"', "").replace('"', "");
      let loginId = jwtDecode(token).user; // Assuming a default loginId
      setLoginId(loginId);
      console.log("loooooooooooooooooooooginId", loginId);
      // Add any further logic here based on the loginId
    } else {
      // Handle the case when token is not present
      setIsLoading(false);
      setIsFailed(true);
    }
  }, []);
  const claimType = claimId.substring(0, 2);
  const navigate = useNavigate();
  const sampleMemberData = {
    // This is the sample claimData data to be displed for demo purpose only
    title: "",
    fullName: "",
    dateOfBirth: "",
    panNo: "",
    aadhaarNumber: "",
    maritalStatus: "",
    religionName: "",
    gender: "",
    fatherHusbandName: "",
    email: "",
    mobileNo: "",
    landNo: "",
    addressL1: "",
    addressL2: "",
    village: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  };

  const sampleClaimData = {
    claimId: claimId,
    claimantId: 0,
    sanctionedAmt: 0,
    returnTo: loginId,
    assignTo: "",
    remarks: "",
    approvalStatus: 1,
    brokenPeriodInterestPayable: 0,
    brokenPeriodInterestRate: 0,
    brokenPeriodContri: 0,
    draftPayOrder: "",
  };

  const samplePfClaimData = {
    claimDetails: [
      {
        claimId: "",
        fullName: "",
        cmpfAccNo: "",
        unitCode: null,
        submissionDate: "",
        nameAadhaar: '{"firstname":"","middlename":"","lastname":"',
        nameBank: '{"firstname":"","middlename":"","lastname":""}',
        namePan: '{"firstname":"","middlename":"","lastname":""}',
        dobInAadhaar: "",
        dobInPan: "",
        sfwhName: "",
        sfwhRelationDesc: "",
        penClaimType: "",
        pfClaimStatus: null,
        remarks: null,
      },
    ],
    officerRemarks: [
      {
        remarks: "forwarded to RODA",
        status: "Assigned to RODA",
      },
      {
        remarks: "ytf",
        status: "Verified by RODA",
      },
      {
        remarks: "ytft",
        status: "Verified by RODA",
      },
    ],
  };

  const sampleDocumentData = {
    data: {
      0: {
        DocTypeid: "",
        Document: "",
      },
      1: {
        DocTypeid: "",
        Document: "",
      },
    },
  };
  const [pfClaimData, setPfClaimData] = useState(samplePfClaimData);

  const [memberData, setMemberData] = useState(sampleMemberData);
  const [isMemberFound, setIsMemberFound] = useState(" ");
  const [documents, setDocuments] = useState([]);
  const pensetDataDefault = {
    fullName: "",
    fatherHusbandName: "",
    cmpfAccNo: "",
    addressL1: "",
    addressL2: "",
    joiningDate: "",
    dateOfBirth: "",
    penClaimSrno: 0,
    dateOfCessation: "",
    serviceYearsUpto89: 0,
    serviceMonthsUpto89: 0,
    serviceYearsAfter89: 0,
    serviceMonthsAfter89: 0,
    averagEmoluments: 0,
    monthly_pension: null,
    kindOfPension: "",
    causeOfCessation: "",
    settlementParaId: "",
    unitName: "",
    optionParaNumber: "15(1) a",
    reducedPen: 0,
    arrearAmount: 0,
    recoveryAmount: 0,
    remmitBankDetails: '{"bank":"","ifsc":"","account_no":"","address":""}',
    currentDate: "",
    memberAadhaarNo: "",
    memberPanNo: "",
    memberMobileNo: 0,
    memberEmailId: null,
    regionalOfficeCode: "",
    regionalOfficeName: "",
    regionalOfficeEmailId: "",
    familyMemberName: null,
    familyMemberMobileNO: null,
    familyMemberEmailId: null,
    familyMemberRelationName: null,
    familyMemberAadhaarNO: null,
    familyMemberPanNO: null,
    modeofRemmitance: "",
    chequeNO: null,
    familyMemberDOB: null,
    unitCode: "BKR/44",
    settlementParaDesc: "",
    settlementParaNumber: "",
    processId: null,
  };
  const [fetchedCalculationData, setFetchedCalculationData] = useState([
    pensetDataDefault,
  ]);
  const [calculatedData, setCalculatedData] = useState({});

  const [assignToOption, setAssignToOption] = useState([]);
  //**********for integration************* */
  const [isLoading, setIsLoading] = useState(false); // set this to tru
  const [isLoadingDoc, setIsLoadingDoc] = useState(false); // set this to true
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isFailedDoc, setIsFailedDoc] = useState(false);
  const [isReturning, setIsReturning] = useState(false);
  const [isReturnClicked, setIsReturnClicked] = useState(false);
  const [checked, setChecked] = useState(false);
  const [dpoGenrated, setDpoGenrated] = useState(false);
  const [open, setOpen] = useState(false);
  const [showingDropdown, setShowingDropdown] = useState(0);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [isRevertClicked, setIsRevertClicked] = useState(false);
  const [maritalError, setMaritalError] = useState(false);
  const [maritalErrorMessage, setMaritalErrorMessage] = useState("");
  // Assuming a default loginId
  let beforeids = 0; // we have set a value beforeids = 0 for doesn't show some field till ids
  // const [remarks, setRemarks] = useState("");

  const [claim, setClaim] = useState(sampleClaimData);

  // const documentTypes = [{ id: "1", title: "" }];

  const assignTo = {
    name: "assignTo",
    placeholder: "Select an Officer to Assign",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "",
    required: true,
    options: assignToOption,
  };

  const returnTo = {
    name: "returnTo",
    placeholder: "Select an Officer to Return",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "",
    required: true,
    options: assignToOption,
  };

  const brokenPeriodInterestRate = {
    name: "brokenPeriodInterestRate",
    type: "text",
    helperText: "",
    placeholder: "Enter Rate of Interest (%) ",
    defaultValue: claim.brokenPeriodInterestRate,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const remarks = {
    name: "remarks",
    type: "text",
    multiline: true,
    helperText: "Enter your remarks (maximum 200 words)",
    placeholder: "Enter your remarks here",
    defaultValue: claim.remarks,
    errorMessage: "Cannot exceed 200 characters",
    pattern: "^.{0,200}$",
    maxLength: 200, // Limiting to 200
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveRemarks = () => {
    // Add logic to save the remarks
    handleClose();
    //    history.push('/other-page');
  };

  // const handleRemarksChange = (event) => {
  //   setRemarks(event.target.value);
  // };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  //********DEFAULT for family details*********/
  const familyDefault = {
    familyMemId: "",
    name: "",
    gender: "",
    relationName: "",
    dateOfBirth: "",
    pfNomSharePerc: "",
  };

  const [nomineeDetails, setNomineeDetails] = useState([familyDefault]);

  // ******** setting props for the TableWithLinkAndButton component*******

  // const viewLink = "/familydetails"; // Link for family members details

  // Advance claim type integration]

  //******************************these are pension calculations******************************** */
  //********************************clecking the Type of claim************************************ */

  //********* ************ */
  const {
    serviceYearsUpto89,
    serviceMonthsUpto89,
    serviceYearsAfter89,
    serviceMonthsAfter89,
  } = fetchedCalculationData;
  // const serviceYearsUpto89 = 5;
  // const serviceMonthsUpto89 = 5;
  // const serviceYearsAfter89 = 30;
  // const serviceMonthsAfter89 = 4;

  const calulatePensionableService = pensionableService(
    serviceYearsUpto89,
    serviceMonthsUpto89,
    serviceYearsAfter89,
    serviceMonthsAfter89
  );

  console.log("pensionableService", calulatePensionableService);

  //*********************** */

  const pensionClaimType = pfClaimData.claimDetails[0].penClaimType;

  let monthlyPension = 0;
  console.log("pensionClaimType", pfClaimData);

  if (calulatePensionableService > 10) {
    if (pensionClaimType.includes("Superannuation")) {
      monthlyPension = monthlyPensionSuperannuation(
        calulatePensionableService,
        fetchedCalculationData.averagEmoluments
      );
      console.log(`monthly pension ${pensionClaimType}`, monthlyPension);
    }

    if (pensionClaimType.includes("Disablement")) {
      monthlyPension = disablementPension(
        fetchedCalculationData.averagEmoluments
      );
      console.log(`monthly pension ${pensionClaimType}`, monthlyPension);
    }

    // if (pensionClaimType.includes("Resign/VRS")) {
    //   monthlyPension = resignationPension(
    //     fetchedCalculationData.averagEmoluments,
    //     calulatePensionableService,
    //     fetchedCalculationData.dateOfCessation,
    //     fetchedCalculationData.dateOfBirth
    //   );
    //   console.log(`monthly pension ${pensionClaimType}`, monthlyPension);
    // }
  }

  // if (pensionClaimType.includes("Death on Roll")) {
  //   monthlyPension = monthlyPensionSuperannuation(
  //     calulatePensionableService,
  //     fetchedCalculationData.averagEmoluments
  //   );
  //   console.log("monthly pension", fetchedCalculationData.averagEmoluments);
  // }

  //_____________no of mothlknkld__________________//
  const currentDate = new Date();
  const dateOfCessation = new Date(fetchedCalculationData.dateOfCessation);
  let monthsDifference =
    (currentDate.getFullYear() - dateOfCessation.getFullYear()) * 12 +
    (currentDate.getMonth() - dateOfCessation.getMonth() - 1);
  if (monthsDifference <= 0) {
    monthsDifference = 0;
  }
  console.log("monthsDifference", monthsDifference);
  const dateOfCommencement = dateOfCessation.setDate(
    dateOfCessation.getDate() + 1
  );
  const arrearAmount = Math.round(monthsDifference * monthlyPension);
  // ------------------------------------------------------------
  const netAmountPayble = arrearAmount + monthlyPension;

  const dateOfJoining = new Date(fetchedCalculationData.joiningDate);
  //****************Dropdown******************* */
  const fetchAssignOfficer = async () => {
    try {
      setIsLoading(true);
      let token = getToken();
      console.log("token on fetchOfficer", token);
      if (!token) {
        setIsLoading(false);
        //setIsFailed(true);
        return;
      }
      token = token.replace('"', "").replace('"', "");
      const loginId = jwtDecode(token).user;
      console.log("loginId on fetch officer", loginId);
      const url =
        process.env.REACT_APP_SERVER_URL +
        "/dropdown/for/show/next/authorized/offcr/adv/pf/pen/roda";
      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      const options = data.map((option) => ({
        id: option.toOfficerlogin,
        title: `${option.Name} (${option.toOfficerlogin})`,
      }));
      setAssignToOption(options); // Assuming setAssignToOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchAssignOfficer();
  }, [loginId]);

  // Function to fetch document types using API
  const fetchDocumentTypes = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + "/documents/show/at/roda";
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.get(url, config);
      const data = response.data.data;
      setDocumentTypes(data); // Assuming the data is an array of document types
      console.log("data", data);
      // Handle error
    } catch (error) {
      console.error("Error fetching document types:", error);
    }
  };

  // Fetch document types when the component mounts
  useEffect(() => {
    fetchDocumentTypes();
  }, []);

  const fetchClaimDetailAdv = async () => {
    // setIsLoading(true);// uncomment

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + "/show/pen/claim/on/claimid";
    const formData = new FormData();
    formData.append("claimId", claimId);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          setPfClaimData(data);
          fetchMemberDetail(data.claimDetails[0].cmpfAccNo);
          fetchDocuments(data.claimDetails[0].claimantId);
          console.log("claim details", response);

          return;
        }
        // setIsFailed(true); uncomment this
      })
      .catch(function (error) {
        setIsLoading(false);
        //setIsFailed(true); uncomment this
      });
  };

  const fetchDetailsForDpo = async () => {
    // setIsLoading(true);// uncomment

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + "/generate/penset";
    const formData = new FormData();
    formData.append("claimId", claimId);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          const wifeObject = data.find(
            (member) =>
              member.familyMemberRelationName === "Wife" ||
              member.familyMemberRelationName === "Husband"
          );
          if (wifeObject) {
            console.log("wifeObject", wifeObject);
            setFetchedCalculationData(wifeObject);
          } else {
            const dataFetched = response.data.data[0];
            console.log("fetchedDataonPension", dataFetched);
            dataFetched.monthly_pension = 0;
            dataFetched.familyMemberName = null;
            dataFetched.familyMemberMobileNO = null;
            dataFetched.familyMemberEmailId = null;
            dataFetched.familyMemberRelationName = null;
            dataFetched.familyMemberAadhaarNO = null;
            dataFetched.familyMemberPanNO = null;

            setFetchedCalculationData(dataFetched);
          }
        }

        // setIsFailed(true); uncomment this
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          setMaritalError(true);
          setIsError(true);
          setErrorMessage(error.response.data.error_message);
          setMaritalErrorMessage(error.response.data.error_message);
        } else if (error.response.status === 400) {
          setMaritalError(true);
          setIsError(true);
          setErrorMessage(error.response.data.error_message);
          setMaritalErrorMessage(error.response.data.error_message);
        }

        console.log("error penset", error);
        setIsLoading(false);
        //setIsFailed(true); uncomment this
      });
  };

  const fetchAllClaimDetail = () => {
    fetchClaimDetailAdv();
    fetchDetailsForDpo();
  };

  const fetchDocuments = async (claimantId) => {
    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/show/submitted/documents/pen/claim`;
      const formData = new FormData();
      formData.append("claimId", claimId);
      formData.append("claimantId", claimantId);

      const config = {
        headers: {
          // Include the Authorization header if required
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(url, formData, config);

      setIsLoadingDoc(false);
      setIsFailedDoc(false);

      if (response.status === 200) {
        const data = response.data.data;
        console.log("documents fetched in roda", data);
        setDocuments(data);
      } else {
        // setIsFailed(true); // uncomment this
      }
    } catch (error) {
      // Corrected syntax for the catch block
      setIsLoading(false);
      //setIsFailed(true); // uncomment this
    }
  };

  useEffect(() => {
    fetchAllClaimDetail();
    // fetchDocumnetAdv();
  }, []);

  /*********** Fetching all the details  for calculations and to generate dpo ***********/

  // const onChange = (e) => {
  //   const value = e.target.value.toUpperCase().trim();
  //   setAccNo(value);
  // };

  // cmpf account number is fetched on useEffect with a delay of 3sec to avoid multiple api hits
  const onChange = (e) => {
    setClaim({ ...claim, [e.target.name]: e.target.value });
  };

  //--------------------------------------------------------------------------------------------
  const proccessId = `CMPFO/${fetchedCalculationData.regionalOfficeCode}/2023-24/${claimId}`;
  // function on previewDpo
  const handlePreviewDpo = () => {
    const todaysDate = new Date(fetchedCalculationData.currentDate);
    console.log("todays date form frontend", todaysDate);
    //console.log("todays date form backend",fetchedCalculationData.currentDate);

    // const todaysDate = fetchedCalculationData.currentDate;

    // Extract month and year
    const month = todaysDate.toLocaleString("en-US", { month: "long" });
    const year = todaysDate.getFullYear();

    const expirationMonth = month + ", " + year;

    const refundDueDate = new Date(fetchedCalculationData.dateOfCessation);
    refundDueDate.setDate(refundDueDate.getDate() + 1);

    // finding the date till which the interest is payable
    // Set the date to the first day of the current month

    // Subtract one day to get the last day of the previous month
    const firstDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      1
    );
    const todate = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), 0);
    ///_________________________________//
    // finding the from date that is the date of cessastion
    const brokenPeriodFromDate = new Date();
    const dateOfCessation = new Date(fetchedCalculationData.dateOfCessation); // Replace with your actual date of cessation

    if (
      dateOfCessation.getDate() ===
      new Date(
        dateOfCessation.getFullYear(),
        dateOfCessation.getMonth() + 1,
        0
      ).getDate()
    ) {
      // If dateOfCessation is the last day of the month
      brokenPeriodFromDate.setFullYear(
        dateOfCessation.getFullYear(),
        dateOfCessation.getMonth() + 1,
        1
      );
    } else {
      // If dateOfCessation is any other day
      brokenPeriodFromDate.setFullYear(
        dateOfCessation.getFullYear(),
        dateOfCessation.getMonth(),
        0
      );
    }

    const formattedBrokenPeriodFromDate =
      brokenPeriodFromDate.toLocaleDateString("en-GB");
    const formattedToDate = todate.toLocaleDateString("en-GB");
    //-----------------------------------------------------------//
    //*** caluculate age 'it would be sent form backend' */
    function calculateAge(dateOfBirth) {
      const today = new Date();
      const birthDate = new Date(dateOfBirth);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      return age;
    }
    // ______________________________things for dpo___________________________________________//
    // const [pensionableService, setPensionableService] = useState();

    // const [monthlyPension, setMonthlyPension] = useState();

    let arrearto;
    if (arrearAmount == 0) {
      arrearto = "";
    } else {
      arrearto = lastDayOfPreviousMonth(currentDate);
    }

    const membershipUpto =
      Math.round(
        (2 / 3) *
          (fetchedCalculationData.serviceYearsUpto89 +
            fetchedCalculationData.serviceMonthsUpto89 / 12) *
          100
      ) / 100;

    const membershipFrom =
      Math.round(
        (fetchedCalculationData.serviceYearsAfter89 +
          fetchedCalculationData.serviceMonthsAfter89 / 12) *
          100
      ) / 100;

    const totalPensionableService = membershipFrom + membershipUpto;

    const calculateMembershipPeriodInYearsMonths = () => {};
    // //_____________________________________________________________________/
    const periodOfMemberShip = calculateMembershipPeriodInYearsMonths(
      dateOfJoining,
      dateOfCessation
    );

    let spouseMonthlyPension;
    if (fetchedCalculationData.familyMemberName) {
      if (fetchedCalculationData.causeOfCessation === "Alive") {
        spouseMonthlyPension = Math.round(0.6 * monthlyPension);
      } else {
        spouseMonthlyPension = Math.round(monthlyPension);
      }
    } else {
      spouseMonthlyPension = 0;
    }

    const objectTobeInserted = {
      // fromOffcrLoginId: loginId,
      // Remarks: claim.remarks,
      // toOffcrId: claim.assignTo,
      // claimId: claimId,
      claimId: claimId,
      fromOffcrLoginId: loginId,
      toOffcrLoginId: claim.assignTo,
      remarks: claim.remarks,
      monthly_pension: 1 * monthlyPension,
      reduced_pen: 0,
      arrear_amount: arrearAmount.toFixed(2) * 1,
      recovery_amount: 0,
      one_time_payment: 0,
    };
    let formattedPenAddress = null;
    if (memberData.penAddress) {
      try {
        const add = JSON.parse(memberData.penAddress);
        formattedPenAddress = `${add.addr_l1}, ${add.addr_l2}, ${add["village/town/city"]}, ${add.district}, ${add.state}, ${add.pin_code}`;
      } catch (error) {}
    }
    const data = {
      claimId: claimId,
      para: "",
      payOrderNo: "",
      fullName: pfClaimData.claimDetails[0].fullName,
      fatherHusbandName: memberData.fatherHusbandName,
      unitName: memberData.unitName,
      joiningDate: dateConvert(memberData.joiningDate),
      unitCode: pfClaimData.claimDetails[0].unitCode,
      cmpfAccNo: pfClaimData.claimDetails[0].cmpfAccNo,
      dateOfCessation: dateConvert(fetchedCalculationData.dateOfCessation),
      dateOfAppointment: dateConvert(memberData.joiningDate),
      dateOfDeath: dateConvert(pfClaimData.claimDetails[0].dateOfDeath),
      //membershipPeriod: `${periodOfMemberShip.years} years and ${periodOfMemberShip.months}months`,
      //membershipPeriod: periodOfMemberShip,

      //ledgerBalance: fetchedCalculationData.ledgerBalance,
      arrearamount: arrearAmount.toFixed(2),
      arrearfrom: dateConvert(dateOfCommencement),
      arrearto: arrearto,
      membershipUpto: membershipUpto,
      membershipFrom: membershipFrom,
      totalPensionableService: totalPensionableService.toFixed(2),
      dateOfCommencement: dateConvert(dateOfCommencement),
      emoluments: fetchedCalculationData.averagEmoluments,
      pensionableService: calulatePensionableService,
      monthlyPension: monthlyPension.toFixed(2),
      kindOfPension: fetchedCalculationData.kindOfPension,
      dateOfClaim: dateConvert(todaysDate),
      dateOfBirth: dateConvert(memberData.dateOfBirth),
      expirationMonth: expirationMonth,
      causeOfCessation: fetchedCalculationData.causeOfCessation,
      claimTypDesc: fetchedCalculationData.claimTypDesc,
      name: fetchedCalculationData.name,
      relationName: fetchedCalculationData.relationName,
      sanctionedAmt: calculatedData.netAmtPayable,
      brokenPeriodInterestRate: claim.brokenPeriodInterestRate,
      brokenPeriodInterestPayable: calculatedData.brokenPeriodInterest,
      brokenPeriodContri: calculatedData.totalBrokenPeriodContri,
      amountBeforeDistribution: calculatedData.totalPfContriAmount,
      modeOfRemittance: "Bank Transfer",
      acc: memberData.bankAccNoPen,
      ifsc: memberData.penBankIfsc,
      bankName: memberData.penBankName,
      bankAddress: formattedPenAddress,
      postalAddress: getAddress(memberData),
      relationShip:
        pfClaimData.claimDetails[0].claimantId === 0
          ? "Self"
          : fetchedCalculationData.relationName,
      //causeOfCessation:""

      // feilds to be calculated
      age: calculateAge(memberData.dateOfBirth),
      dateUptoWhichInterestIsPayable: dateConvert(todate),
      //closingBalance: fetchedCalculationData.ledgerBalance,
      dateFrom: "",
      dateTo: calculateCurrencyEnding(dateOfCessation),
      // rateOfInterest: 8.0,
      brokenPeriodInterestPayableFrom: formattedBrokenPeriodFromDate,
      brokenPeriodInterestPayableTo: formattedToDate,
      // brokenPeriodContribution: 180804.0,
      // brokenPeriodInterestPayable: 247043.0,
      // amountBeforeDistribution: 0,
      // totalAmountpayable: 2659709.0,
      refundDueDate: dateConvert(refundDueDate),
      payeeAddress: getAddress(memberData), // to be taken from
      dataTobeInsertedOnDPO: objectTobeInserted,
      fromOffcrLoginId: loginId,
      regionalOfficeName: fetchedCalculationData.regionalOfficeName,
      settlementParaNumber: fetchedCalculationData.settlementParaNumber,
      familyMemberName: fetchedCalculationData.familyMemberName,
      familyMemberMobileNO: fetchedCalculationData.familyMemberMobileNO,
      familyMemberEmailId: fetchedCalculationData.familyMemberEmailId,
      familyMemberRelationName: fetchedCalculationData.familyMemberRelationName,
      familyMemberAadhaarNO: fetchedCalculationData.familyMemberAadhaarNO,
      familyMemberPanNO: fetchedCalculationData.familyMemberPanNO,
      familyMemberDOB: dateConvert(fetchedCalculationData.familyMemberDOB),
      spouseMonthlyPension: spouseMonthlyPension,
      arr_pension_spouse: 0,
    };
    console.log("view dpoe");

    navigate("pendpo", { state: data });
  };

  /// ******** on submit function
  const objectTobeSent = {
    fromOffcrLoginId: loginId,
    // Remarks: claim.remarks,
    // toOffcrId: claim.assignTo,
    // claimId: claimId,
    claimId: claimId,
    // fromOffcrLoginId: passingloginId,
    toOffcrLoginId: claim.assignTo,
    // remarks: "TestClaim Assignment from RODA to SI",
    remarks: claim.remarks,
    //approvalStatus: "true",
    monthly_pension: 1 * monthlyPension.toFixed(2),
    reduced_pen: 0,
    arrear_amount: 1 * arrearAmount.toFixed(2),
    recovery_amount: 0,
    one_time_payment: 0,
    ex_gratia_payment: 0,
    draftPayOrder: dpoPdf,
    processId: proccessId,
  };

  console.log("objectTobeSent", objectTobeSent);
  console.log("draftPayOrder", dpoPdf);
  const handleSubmit = (e) => {
    e.preventDefault();
    // if (!dpoGenrated) {
    //   // Show message in dialog: "Please select check Box"
    //   // alert("Please select the check box");
    //   setIsError(true);
    //   setErrorMessage("Please Generate DPO.");
    //   return;
    // }

    // }

    if (maritalError) {
      console.log("marital error", maritalError);
      setIsError(true);
      setErrorMessage(maritalErrorMessage);
      return;
    }

    if (!objectTobeSent.toOffcrLoginId) {
      // Show message in dialog: "Please select an officer to assign"
      //alert("Please select an officer to assign");
      setIsError(true);
      setErrorMessage("Please Select an Officer to Assign.");
      return;
    }
    if (!objectTobeSent.draftPayOrder) {
      // Show message in dialog: "Please select an officer to assign"
      //alert("Please select an officer to assign");
      setIsError(true);
      setErrorMessage("DPO is not generated.    ");
      return;
    }
    setIsSubmitting(true);

    const url =
      process.env.REACT_APP_SERVER_URL +
      "/pension/claims/insert/calculator/data/by/roda";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSent, { headers })
      .then(function (response) {
        setIsSubmitting(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Forwarded Successfully.");
      })
      .catch((error) => {
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
        console.log("error on submit", error);
      });
  };

  /// ******** on Return function

  const objectTobeSentOnReturn = {
    fromOffcrLoginId: loginId,
    remarks: claim.remarks,
    toOffcrLoginId: claim.assignTo,
    claimId: claimId,
  };
  console.log("objectTobeSentOnReturn", objectTobeSentOnReturn);
  const handleReturnClick = (e) => {
    e.preventDefault();

    if (!claim.remarks.trim()) {
      // Show message in dialog: "Please enter remarks"
      // alert("Please enter remarks");
      setIsError(true);
      setErrorMessage("Please enter Remarks.");
      return;
    }

    if (!objectTobeSentOnReturn.toOffcrLoginId) {
      // Show message in dialog: "Please select an officer to assign"
      //alert("Please select an officer to assign");
      setIsError(true);
      setErrorMessage("Please Select an Officer to Return.");
      return;
    }

    setIsReturning(true);
    setIsReturnClicked(true);
  };
  const handleReturnClaim = (e) => {
    // e.preventDefault();

    const url =
      process.env.REACT_APP_SERVER_URL + "/pension/return/claims/roda/to/si";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSentOnReturn, { headers })
      .then(function (response) {
        setIsReturning(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Returned Successfully.");

        //console.log("Claim Returned Successfully");
        setIsReturnClicked(false);
      })
      .catch((error) => {
        console.log("error on submit", error);
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
      });
  };

  /// ******** on Revert function

  const objectTobeSentOnRevert = {
    loginId: loginId,
    remark: claim.remarks,
    claimId: claimId,
  };

  const handleRevertClick = (e) => {
    e.preventDefault();

    if (!claim.remarks.trim()) {
      // Show message in dialog: "Please enter remarks"
      // alert("Please enter remarks");
      setIsError(true);
      setErrorMessage("Please enter Remarks.");
      return;
    }

    setIsRevertClicked(true);
  };
  const handleRevertClaim = (e) => {
    // e.preventDefault();

    const url = process.env.REACT_APP_SERVER_URL + "/revert/pen/claim/to/ids";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSentOnRevert, { headers })
      .then(function (response) {
        setIsReturning(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Revert Successfully.");

        //console.log("Claim Returned Successfully");
        setIsRevertClicked(true);
      })
      .catch((error) => {
        console.log("error on submit", error);
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
      });
  };

  ///****************integration started********************* */

  const fetchMemberDetail = async (accNo) => {
    // setIsLoading(true); // uncomment

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + "/member/details/for/pf";
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          console.log("member data", data);
          if (data.length !== 0) {
            setMemberData(data[0]);
            setIsMemberFound("true");
            fetchNomineeDetail(data[0].cmpfAccNo);
          }
          setIsMemberFound("false");
          return;
        }
        setIsFailed(true);
        setIsMemberFound("false");
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        setIsMemberFound("false");
      });
  };

  /***** function to get all nominee details**************** */
  const fetchNomineeDetail = async (accNo) => {
    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");

    const url = process.env.REACT_APP_SERVER_URL + "/get/all/nominee/details";
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo); // check what formdata is to be sent
    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsLoading(false);
        setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          console.log("nominee details", data);
          if (data.length !== 0) {
            setNomineeDetails(data);
          }

          return;
        }
        //setIsFailed(true); //uncomment this
      })
      .catch(function (error) {
        setIsLoading(false);
        // setIsFailed(true); // uncomment this
      });
  };
  //****************************** Calculations for PF***************************************** */

  const caluculatePf = () => {
    const pfCalucationData = {
      //closingBalance: fetchedCalculationData.ledgerBalance,
      interestRate: claim.brokenPeriodInterestRate,
      brokenPeriodEmplyrShareContri:
        fetchedCalculationData.brokenPeriodEmplyrShareContri,
      brokenPeriodMemShareContri:
        fetchedCalculationData.brokenPeriodMemShareContri,
      brokenPeriodVolContri: fetchedCalculationData.brokenPeriodVolContri,
      brokenPeriodMonths: fetchedCalculationData.brokenPeriodMonths,
      disbursementPer: fetchedCalculationData.totalDisbursedPer,
    };

    const totalBrokenPeriodContri = totalBrokenPeriodContribution(
      pfCalucationData.brokenPeriodEmplyrShareContri,
      pfCalucationData.brokenPeriodMemShareContri,
      pfCalucationData.brokenPeriodVolContri
    );
    const brokenPeriodInterest = calBrokenInterestPayable(
      pfCalucationData.closingBalance,
      pfCalucationData.interestRate,
      pfCalucationData.brokenPeriodMonths
    );

    const totalPfContriAmount = totalPfAmount(
      pfCalucationData.closingBalance,
      brokenPeriodInterest,
      totalBrokenPeriodContri
    );

    const netAmtPayable = netAmountPayable(
      totalPfContriAmount,
      pfCalucationData.disbursementPer
    );

    setCalculatedData({
      totalBrokenPeriodContri: customRounding(totalBrokenPeriodContri),
      brokenPeriodInterest: customRounding(brokenPeriodInterest),

      totalPfContriAmount: customRounding(totalPfContriAmount),

      netAmtPayable: customRounding(netAmtPayable),
    });

    console.log(
      "totalBrokenPeriodContri , brokenPeriodInterest, totalPfAmount,",
      totalBrokenPeriodContri,
      brokenPeriodInterest,
      totalPfContriAmount,
      netAmtPayable
    );
  };

  //*************************Calculations for pension**************************** */

  //******************************************************************************************* */
  useEffect(() => {
    caluculatePf();
  }, [claim.brokenPeriodInterestRate]);
  /// error alert handler
  const handleErrorAlert = () => {
    setIsError(false);
  };

  const handleSuccess = () => {
    // setIsSuccess(false);
    navigate("/roda/penset");
  };

  const handleCancelReturn = () => {
    setIsReturnClicked(false);
  };
  const handleReturnOk = () => {
    handleReturnClaim();
  };

  const handleAssign = (e) => {
    setShowingDropdown(1);
  };

  // const handleRevert = (e) => {
  //   setShowingDropdown(2);
  // };

  const handleReturn = (e) => {
    setShowingDropdown(3);
  };
  const handleRevertOk = () => {
    handleRevertClaim();
  };
  const handleCancelRevert = () => {
    setIsRevertClicked(false);
  };

  return (
    <div>
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error!"
        body={errorMessage}
        buttonName="Ok"
      />
      <SucessAlert
        handleOk={handleSuccess}
        open={isSuccess}
        title="Success"
        body={successMessage}
        buttonName="OK"
      />
      <ConfirmationDialogue
        handleOk={handleReturnOk}
        handleCancel={handleCancelReturn}
        open={isReturnClicked}
        title="Confirm Return"
        body="Are you sure you want to return this claim ?"
        buttonName="Confirm"
      />
      <ConfirmationDialogue
        handleOk={handleRevertOk}
        handleCancel={handleCancelRevert}
        open={isRevertClicked}
        title="Confirm Revert"
        body="Are you sure you want to revert this claim ?"
        buttonName="Confirm"
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "10px" }}>
            {/* {isFailed || isFailedDoc ? (
              <Reloader refreshHandler={fetchAllClaimDetail} />
            ) : ( */}
            <>
              {isLoading || isLoadingDoc ? (
                <LoadingIndicator />
              ) : (
                <>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item xs={12}>
                      <TitleText title="Pension Settlement" />
                    </Grid>
                  </Grid>
                  <FormDivider />
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={1} mb={3}>
                      <BackButton />
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="start"
                      rowGap={3}>
                      {/* Member Details*/}
                      {/* section 1  */}
                      <Paper
                        elevation={3}
                        sx={{ padding: "3rem", borderRadius: "10px" }}>
                        <Grid item xs={12}>
                          <MemberDetails member={memberData} />
                        </Grid>
                      </Paper>
                      {/* claim Details*/}
                      {/* section 2  */}
                      <Paper
                        elevation={3}
                        sx={{ padding: "3rem", borderRadius: "10px" }}>
                        <Grid item xs={12}>
                          {/* <ClaimDetailsPf pfclaimdetails={pfClaimData} /> */}
                          <ClaimDetailsPf pfclaimdetails={pfClaimData} />
                        </Grid>
                      </Paper>
                      {/* Document Details*/}
                      {/* section 3  */}
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={12}>
                          <Paper
                            elevation={3}
                            sx={{ padding: "3rem", borderRadius: "10px" }}>
                            <Grid item xs={12}>
                              <ViewDocuments documents={documents} />
                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid>
                      {/* ************************************Broken period and calculation details ***************************************** */}
                      <Grid item xs={12}>
                        <Paper
                          elevation={3}
                          sx={{ padding: "2rem", borderRadius: "10px" }}>
                          <SubtitleText title="Pension Calculation" />

                          <Grid
                            container
                            spacing={1}
                            columnGap={2}
                            justifyContent="space-between"
                            mt={2}>
                            {/* here we are creating two columns as the label and textfield were not aligning properly */}
                            {/* Column 1 */}
                            <Grid item xs={12} md={5}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={4}>
                                  <LabelText title="Pensionable Service  " />
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                  <ValueText
                                    title={calulatePensionableService}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                  <LabelText title="Arrear Amount" />
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                  {" "}
                                  <ValueText
                                  // title={
                                  //   arrearAmount
                                  //     ? arrearAmount.Math.round()
                                  //     : 0
                                  // }
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={5}>
                                  <LabelText title="Date of Cessation  " />
                                </Grid>
                                <Grid item xs={12} sm={12} md={7}>
                                  <ValueText
                                    title={
                                      fetchedCalculationData
                                        ? dateConvert(
                                            fetchedCalculationData.dateOfCessation
                                          )
                                        : ""
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* Column 2 */}
                            <Grid item xs={12} md={5}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={4}>
                                  <LabelText title="Monthly Pension  " />
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                  <ValueText
                                    title={
                                      monthlyPension
                                        ? Math.round(monthlyPension)
                                        : ""
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                  <LabelText title="No of Months Arrear Amount is Payable " />
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                  <ValueText title={monthsDifference} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                  <LabelText title="Net Amount Payable " />
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                  <ValueText
                                    title={
                                      netAmountPayble
                                        ? Math.round(netAmountPayble)
                                        : 0
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                      {/* *****
{/* ************************************ upload documents ***************************************** */}
                      {/* <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={12}>
                          <Paper
                            elevation={3}
                            sx={{ padding: "3rem", borderRadius: "10px" }}
                          >
                            <Grid item xs={12}>
                              <SubtitleText title="Upload Documents" />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <UploadDocuments
                                documentTypes={documentTypes}
                                // onSave={handleStateUpdate}
                              />
                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid> */}

                      {/* ************************************ Remarks ***************************************** */}
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={12}>
                          <Paper
                            elevation={3}
                            sx={{ padding: "2rem", borderRadius: "10px" }}>
                            <SubtitleText title="Remarks" />
                            <Grid item xs={12} sm={6} md={8}>
                              <CustomTextArea
                                {...remarks}
                                onchange={onChange}
                              />
                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid>
                      {/* *************** Submit Button ********* */}
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems={"center"}>
                        <Grid item md={3}>
                          <Grid container justifyContent="start">
                            <Grid
                              item
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}>
                              <Button
                                onClick={handlePreviewDpo}
                                variant="contained"
                                color="success"
                                type="submit"
                                style={{ marginLeft: 8 }}
                                disabled={isSubmitting}>
                                Preview PENSET
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* *************** Submit Button ********* */}
                        <Grid item md={6}>
                          <Grid
                            container
                            justifyContent="end"
                            alignItems="center"
                            columnGap={1}>
                            <Grid item md={12}>
                              <Grid
                                container
                                justifyContent="end"
                                alignItems="center"
                                columnGap={1}>
                                <Grid item md={2}>
                                  <Button
                                    onClick={handleRevertClick}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isReturnClicked}
                                    fullWidth>
                                    Revert
                                  </Button>
                                </Grid>
                                <Grid item md={2}>
                                  <Button
                                    onClick={handleReturn}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isReturnClicked}
                                    fullWidth>
                                    Return
                                  </Button>
                                </Grid>
                                <Grid item md={2}>
                                  <Button
                                    onClick={handleAssign}
                                    variant="contained"
                                    color="success"
                                    type="submit"
                                    //style={{ marginLeft: 8 }}
                                    disabled={isSubmitting}
                                    fullWidth>
                                    {isSubmitting ? (
                                      <CircularProgress />
                                    ) : (
                                      "Assign"
                                    )}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* <Grid container justifyContent="start" spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <CustomDropdown {...assignTo} onchange={onChange} />
                          </Grid>
                          <Grid item md={1}>
                            <Button
                              onClick={handleReturnClick}
                              variant="contained"
                              color="primary"
                              type="submit"
                              disabled={isReturnClicked}
                              fullWidth
                            >
                              {isReturnClicked ? <CircularProgress /> : "OK"}
                            </Button>
                          </Grid>
                        </Grid> */}
                      {showingDropdown === 1 && (
                        <Grid container justifyContent="end" spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <CustomDropdown {...assignTo} onchange={onChange} />
                          </Grid>
                          <Grid item md={1}>
                            <Button
                              onClick={handleSubmit}
                              variant="contained"
                              color="success"
                              type="submit"
                              disabled={isSubmitting}
                              fullWidth>
                              {isSubmitting ? <CircularProgress /> : "OK"}
                            </Button>
                          </Grid>
                        </Grid>
                      )}

                      {/* {showingDropdown === 2 && (
                          <Grid container justifyContent="end" spacing={2}>
                            <Grid item xs={12} sm={4}>
                              <CustomDropdown
                                {...revertTo}
                                onChange={onChange}
                              />
                            </Grid>
                            <Grid item md={1}>
                              <Button
                                onClick={handleReturnClick}
                                variant="contained"
                                color="success"
                                type="submit"
                                disabled={isReturnClicked}
                                fullWidth
                              >
                                {isReturnClicked ? <CircularProgress /> : "OK"}
                              </Button>
                            </Grid>
                          </Grid>
                        )} */}

                      {showingDropdown === 3 && (
                        <Grid container justifyContent="end" spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <CustomDropdown {...assignTo} onchange={onChange} />
                          </Grid>
                          <Grid item md={1}>
                            <Button
                              onClick={handleReturnClick}
                              variant="contained"
                              color="success"
                              type="submit"
                              disabled={isReturnClicked}
                              fullWidth>
                              {isReturnClicked ? <CircularProgress /> : "OK"}
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </>
            {/* )} */}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default ViewPenClaimRoda;
