import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import Modal from "@mui/material/Modal";
import SubtitleText from "../../FormComponent/SubtitleText";
import MemberDetails from "../UDAPages/MemberDetatils";
import CaptionText from "../../FormComponent/CaptionText";
import BackButton from "../../components/button/BackButton";
import axios from "axios";
import AdvClaimDetails from "../../SettlementComponents/AdvClaimDetails";
import { useLocation, useNavigate } from "react-router-dom";
import ViewDocuments from "../../SettlementComponents/ViewDocuments";
import CustomTextArea from "../../FormComponent/CustomTextArea";
import LabelText from "../../FormComponent/LabelText";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import Reloader from "../../FormComponent/Reloader";
import LoadingIndicator from "../../FormComponent/LoadingIndicator";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import SucessAlert from "../../FormComponent/SuccessAlert";
import ConfirmationDialogue from "../../FormComponent/ConfirmationDialogue";
import ClaimDetailsPf from "../../SettlementComponents/ClaimDetailsPf";
import ValueText from "../../FormComponent/ValueText";
import CustomTextField from "../../FormComponent/CustomTextField";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import {
  calBrokenInterestPayable,
  customRounding,
  netAmountPayable,
  totalBrokenPeriodContribution,
  totalPfAmount,
  tdsCalculations,
  calculateWithdrawalInterest,
} from "./PFcalculation";
import {
  calculateCurrencyEnding,
  dateConvert,
  getAddress,
} from "../../Utility/UtilityMethods";
import UploadDocuments from "./../../SettlementComponents/UploadDocument";
import jwtDecode from "jwt-decode";
import { getToken } from "../../UiManager/SessionChecker";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { getFiscalYear } from "../../Utility/UtilityMethods";

function ViewPfClaimRoda() {
  const { state } = useLocation();
  console.log("state", state);
  const claimId = state.passingId;

    const [isLedgerUpdated, setIsLedgerUpdated] = useState(false);

  // useEffect(() => {
  //   updateLedgerRoda ();
  // },[])

  useEffect(() => {
    console.log("inside useEffect api update");
    const url =
      process.env.REACT_APP_SERVER_URL + "/find/last/financial/year/details";
    const formData = new FormData();
    formData.append("claimId", claimId);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(url, formData, config)
      .then((response) => {
        console.log("reponse for check financial yr", response);
        updateLedgerRoda();
      })
      .catch((error) => {
        console.log("error on check finacial year", error);
        const status = error.response.status;
        if (status === 400) {
          setIsLedgerError(true);
          setLedgerErrorMessage(
            "Previous year contributions are not present in Ledger. Kindly Update Ledger before proceeding."
          );
        setIsLedgerUpdated(true);
          
        }
      });
  }, []);

  const updateLedgerRoda = () => {
    console.log("inside useEffect api update");
    const url =
      process.env.REACT_APP_SERVER_URL +
      "/update/ledger/balance/on/claimId/Roda";
    const formData = new FormData();
    formData.append("claimId", claimId);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const response = axios.post(url, formData, config).then((response) => {
      console.log(response);
      fetchDetailsForDpo();
    });
  };

  let dataFromPreview = {
    sanctionedAmt: "",

    brokenPeriodInterestPayable: "",
    brokenPeriodInterestRate: "",
    brokenPeriodContri: "",
    draftPayOrder: "",
    uploadedDocs: {},
    processId: "",
  };
  if (state.sentState) {
    dataFromPreview = state.dataFromPreview;
  }
  console.log("claimId", claimId);
  console.log("dataFromPreview", state.dataFromPreview);

  //let loginId = "DA-RO-OFF-504";
  // let token = localStorage.getItem("jwt");
  // let loginId = "DA-RO-OFF-513";

  //  if (!token) {
  //    setIsFailed(true);
  //    setIsLoading(false);
  //    return;
  //  }

  // token = token.replace('"', "").replace('"', "");
  const [loginId, setLoginId] = useState();

  ///********fetching and setting token********* */
  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (token) {
      token = token.replace('"', "").replace('"', "");
      let loginId = jwtDecode(token).user; // Assuming a default loginId
      setLoginId(loginId);
      console.log("loooooooooooooooooooooginId", loginId);
      // Add any further logic here based on the loginId
    } else {
      // Handle the case when token is not present
      setIsFailed(true);
      setIsLoading(false);
    }
  }, []);
  // object fo

  console.log("loooooooooooooooooooooginId", loginId);
  // const claimType = claimId.substring(0, 2);
  const navigate = useNavigate();
  const sampleMemberData = {
    // This is the sample claimData data to be displed for demo purpose only
    title: "",
    fullName: "",
    dateOfBirth: "",
    panNo: "",
    aadhaarNumber: "",
    maritalStatus: "",
    religionName: "",
    gender: "",
    fatherHusbandName: "",
    email: "",
    mobileNo: "",
    landNo: "",
    addressL1: "",
    addressL2: "",
    village: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  };

  const sampleClaimData = {
    claimId: claimId,

    sanctionedAmt: 0,
    returnTo: loginId,
    assignTo: "",
    remarks: "",
    approvalStatus: 1,
    brokenPeriodInterestPayable: 0,
    brokenPeriodInterestRate: 0,
    brokenPeriodContri: 0,
    draftPayOrder: "",
  };

  const samplePfClaimData = {
    claimDetails: [
      {
        claimId: "",
        fullName: "",
        cmpfAccNo: "",
        unitCode: null,
        submissionDate: "",
        nameAadhaar: '{"firstname":"","middlename":"","lastname":"',
        nameBank: '{"firstname":"","middlename":"","lastname":""}',
        namePan: '{"firstname":"","middlename":"","lastname":""}',
        dobInAadhaar: "",
        dobInPan: "",
        claimantId: 0,
        sfwhName: "",
        sfwhRelationDesc: "",
        pfClaimType: "",
        pfClaimStatus: null,
        remarks: null,
      },
    ],
    officerRemarks: [
      {
        remarks: "",
        status: "",
        fromLoginId: "",
      },
    ],
  };

  // const sampleDocumentData = {
  //   data: {
  //     0: {
  //       DocTypeid: "",
  //       Document: "",
  //     },
  //     1: {
  //       DocTypeid: "",
  //       Document: "",
  //     },
  //   },
  // };
  const [pfClaimData, setPfClaimData] = useState(samplePfClaimData);

  const [memberData, setMemberData] = useState(sampleMemberData);
  const [isMemberFound, setIsMemberFound] = useState(" ");
  const [documents, setDocuments] = useState([]);
  const [uploadedDocs, setUploadedDocs] = useState({});

  const [fetchedCalculationData, setFetchedCalculationData] = useState({});
  const [calculatedData, setCalculatedData] = useState({});

  const [assignToOption, setAssignToOption] = useState([]);
  //**********for integration************* */
  const [isLoading, setIsLoading] = useState(false); // set this to tru
  const [isLoadingDoc, setIsLoadingDoc] = useState(false); // set this to true
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLedgerError, setIsLedgerError] = useState(false);
  const [ledgerErrorMessage, setLedgerErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isFailedDoc, setIsFailedDoc] = useState(false);
  const [isReturning, setIsReturning] = useState(false);
  const [isReturnClicked, setIsReturnClicked] = useState(false);
  // const [checked, setChecked] = useState(false);
  // const [dpoGenrated, setDpoGenrated] = useState(false);
  // const [open, setOpen] = useState(false);
  const [showingDropdown, setShowingDropdown] = useState(0);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [withdrawalDetails, setWithdrawalDetails] = useState([]);
  const [selectedOfficerName, setSelectedOfficerName] = useState("");
  const [isRevertClicked, setIsRevertClicked] = useState(false);
  const [noOfMonthsWorked, setNoOfMonthsWorked] = useState(0);
  // const [legderUpdated, setLedgerUpdated] = useState(state.ledgerUpdate);

  // Assuming a default loginId
  // let beforeids = 0; // we have set a value beforeids = 0 for doesn't show some field till ids
  // const [remarks, setRemarks] = useState("");

  const [claim, setClaim] = useState(sampleClaimData);
  const [withdrawalReturnAmt, setWithdrawalReturnAmt] = useState(0);

  // const documentTypes = [{ id: "1", title: "" }];

  const assignTo = {
    name: "assignTo",
    placeholder: "Select an Officer to Assign",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "",
    required: true,
    options: assignToOption,
  };

  // const returnTo = {
  //   name: "returnTo",
  //   placeholder: "Select an Officer to Return",
  //   //label: "Select CC",
  //   helperText: "",
  //   //disabled: readOnly,
  //   initialValue: "",
  //   required: true,
  //   options: assignToOption,
  // };

  const brokenPeriodInterestRate = {
    name: "brokenPeriodInterestRate",
    type: "text",
    helperText: "",
    placeholder: "Enter Rate of Interest (%) ",
    defaultValue: claim.brokenPeriodInterestRate,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const remarks = {
    name: "remarks",
    type: "text",
    multiline: true,
    helperText: "Enter your remarks (maximum 200 words)",
    placeholder: "Enter your remarks here",
    defaultValue: claim.remarks,
    errorMessage: "Cannot exceed 200 characters",
    pattern: "^.{0,200}$",
    maxLength: 200, // Limiting to 200
  };
  let brokenPeriodMonths = 0;
  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleSaveRemarks = () => {
  //   // Add logic to save the remarks
  //   handleClose();
  //   //    history.push('/other-page');
  // };

  // const handleRemarksChange = (event) => {
  //   setRemarks(event.target.value);
  // };

  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  // };

  //********DEFAULT for family details*********/
  const familyDefault = {
    familyMemId: "",
    name: "",
    gender: "",
    relationName: "",
    dateOfBirth: "",
    pfNomSharePerc: "",
  };

  const [nomineeDetails, setNomineeDetails] = useState([familyDefault]);

  // ******** setting props for the TableWithLinkAndButton component*******

  // const viewLink = "/familydetails"; // Link for family members details

  // Advance claim type integration]
  //****************Dropdown******************* */
  const fetchAssignOfficer = async () => {
    try {
      setIsLoading(true);
      let token = getToken();
      console.log("token on fetchOfficer", token);
      if (!token) {
        setIsLoading(false);
        //setIsFailed(true);
        return;
      }
      token = token.replace('"', "").replace('"', "");
      const loginId = jwtDecode(token).user;
      console.log("loginId on fetch officer", loginId);
      const url =
        process.env.REACT_APP_SERVER_URL +
        "/dropdown/for/show/next/authorized/offcr/adv/pf/pen/roda";
      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      const options = data.map((option) => ({
        id: option.toOfficerlogin,
        title: `${option.Name} (${option.toOfficerlogin})`,
      }));
      setAssignToOption(options); // Assuming setAssignToOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchAssignOfficer();
  }, [loginId, dataFromPreview.draftPayOrder]);

  // Function to fetch document types using API
  const fetchDocumentTypes = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + "/documents/show/at/roda";
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.get(url, config);
      const data = response.data.data;
      setDocumentTypes(data); // Assuming the data is an array of document types
      console.log("fetched document types", data);
      // Handle error
    } catch (error) {
      console.error("Error fetching document types:", error);
    }
  };
  const fetchWithdrawalDetails = async () => {
    // setIsLoading(true);// uncomment

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url =
      // process.env.REACT_APP_SERVER_URL + "/show/pf/claim/details/on/claimid";
      ///show/pf/claim/details/to/SI/on/claimid
      process.env.REACT_APP_SERVER_URL +
      "/get/withdrawal/data/for/broken/period";

    const formData = new FormData();
    formData.append("claimId", claimId);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          setWithdrawalDetails(data);

          console.log("withdrawal details", response);

          return;
        }
        // setIsFailed(true); uncomment this
      })
      .catch(function (error) {
        setIsLoading(false);
        //setIsFailed(true); uncomment this
      });
  };

  // Fetch document types when the component mounts
  useEffect(() => {
    fetchDocumentTypes();
    fetchWithdrawalDetails();
  }, []);

  const fetchClaimDetailAdv = async () => {
    // setIsLoading(true);// uncomment

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url =
      // process.env.REACT_APP_SERVER_URL + "/show/pf/claim/details/on/claimid";
      ///show/pf/claim/details/to/SI/on/claimid
      process.env.REACT_APP_SERVER_URL +
      "/show/pf/claim/details/to/SI/on/claimid";

    const formData = new FormData();
    formData.append("claimId", claimId);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          setPfClaimData(data);

          fetchMemberDetail(data.claimDetails[0].cmpfAccNo);
          fetchDocuments(data.claimDetails[0].claimantId);

          if (
            data.claimDetails[0].pfClaimType === "Withdrawal Before Retirement"
          ) {
            getWithdrawalAmount(data.claimDetails[0].claimId);
          }

          console.log("claim details", response);

          return;
        }
        // setIsFailed(true); uncomment this
      })
      .catch(function (error) {
        setIsLoading(false);
        //setIsFailed(true); uncomment this
      });
  };

  const fetchDetailsForDpo = async () => {
    // setIsLoading(true);// uncomment

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + "/get/data/to/generate/dpo";
    const formData = new FormData();
    formData.append("claimId", claimId);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data[0];
          console.log("fetchedCalculationData", data);
          setFetchedCalculationData(data);

          return;
        }
        // setIsFailed(true); uncomment this
      })
      .catch(function (error) {
        setIsLoading(false);
        //setIsFailed(true); uncomment this
      });
  };

  const fetchAllClaimDetail = () => {
    fetchClaimDetailAdv();
  };

  const fetchDocuments = async (claimantId) => {
    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/show/pf/claim/uploaded/documents/on/claimid`;
      const formData = new FormData();
      formData.append("claimId", claimId);
      formData.append("claimantId", claimantId);

      const config = {
        headers: {
          // Include the Authorization header if required
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(url, formData, config);

      setIsLoadingDoc(false);
      setIsFailedDoc(false);

      if (response.status === 200) {
        const data = response.data.data;
        console.log("documents fetched in roda", data);
        setDocuments(data);
      } else {
        // setIsFailed(true); // uncomment this
      }
    } catch (error) {
      // Corrected syntax for the catch block
      setIsLoading(false);
      //setIsFailed(true); // uncomment this
    }
  };

  useEffect(() => {
    fetchAllClaimDetail();
    // fetchDocumnetAdv();
  }, []);

  /*********** Fetching all the details  for calculations and to generate dpo ***********/

  // const onChange = (e) => {
  //   const value = e.target.value.toUpperCase().trim();
  //   setAccNo(value);
  // };

  //! cmpf account number is fetched on useEffect with a delay of 3sec to avoid multiple api hits
  const onChange = (e) => {
    if (e.target.name === "assignTo") {
      const selectedOption = assignToOption.find(
        (option) => option.id === e.target.value
      );
      if (selectedOption) {
        setSelectedOfficerName(selectedOption.title); // Set the selected officer's name in the state
      }
    }
    setClaim({ ...claim, [e.target.name]: e.target.value });
  };

  // Handler function to update the DOCS from child
  const handleStateUpdate = (documentData) => {
    const requestBody = {
      cmpfAcNo: "",
      uploadAdvPfDocumentsDTO: documentData.map((upload) => ({
        fileContent: upload.uploadedDocument,
        docTypeId: upload.selectedDocumentType,
      })),
    };
    setUploadedDocs(requestBody.uploadAdvPfDocumentsDTO);
    // console.log("requestBody", requestBody);
  };
  let processId = "";
  // const currencyYear = getFiscalYear(fetchedCalculationData.dateOfCessation);
  //! the value of 23-24 is hardcoded need to fix this
  // const processId = `CMPFO/${fetchedCalculationData.roCode}/2023-24/${claimId}`;
  // console.log("thisYear", fetchedCalculationData.dateOfCessation);

  // function on previewDpo
  const handlePreviewDpo = () => {
    if(isLedgerUpdated){
        setIsLedgerError(true);
    
          setLedgerErrorMessage(
            "Previous year contributions are not present in Ledger. Kindly Update Ledger before proceeding."
          );
          return;
      }
    const todaysDate = new Date(fetchedCalculationData.currentDate);
    const refundDueDate = new Date(fetchedCalculationData.dateOfCessation);
    refundDueDate.setDate(refundDueDate.getDate() + 1);

    // finding the date till which the interest is payable
    // Set the date to the first day of the current month

    // Subtract one day to get the last day of the previous month
    // const firstDate = new Date(
    //   todaysDate.getFullYear(),
    //   todaysDate.getMonth(),
    //   1
    // );
    const thisYear = getFiscalYear(fetchedCalculationData.currentDate);
    console.log("thisYear", thisYear);

    processId = `CMPFO/${fetchedCalculationData.roCode}/${thisYear}/${claimId}`;
    console.log("processId", processId);
    const todate = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), 0);
    ///_________________________________//
    // finding the from date that is the date of cessastion
    const brokenPeriodFrom = new Date();
    const dateOfCessation = new Date(fetchedCalculationData.dateOfCessation); // Replace with your actual date of cessation

    if (dateOfCessation.getMonth() <= 2) {
      // Months are 0-indexed, so March is index 2
      // If dateOfCessation is before March 31st
      brokenPeriodFrom.setFullYear(dateOfCessation.getFullYear() - 1, 3, 1); // Setting the brokenPeriodFromDate to April 1st of the previous year
    } else {
      // If dateOfCessation is on or after March 31st
      brokenPeriodFrom.setFullYear(dateOfCessation.getFullYear(), 3, 1); // Setting the brokenPeriodFromDate to April 1st of the current year
    }

    const formattedBrokenPeriodFromDate =
      brokenPeriodFrom.toLocaleDateString("en-GB");

    const formattedToDate = todate.toLocaleDateString("en-GB");

    console.log("formattedBrokenPeriodFromDate", formattedBrokenPeriodFromDate);

    //-----------------------------------------------------------//
    //*** caluculate age 'it would be sent form backend' */
    function calculateAge(dateOfBirth) {
      const today = new Date();
      const birthDate = new Date(dateOfBirth);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      return age;
    }
    // _________________________________________________________________________
    //********* Calculate the membership period ************ */
    const dateOfJoining = new Date(fetchedCalculationData.joiningDate);

    function calculateMembershipPeriodInYearsMonths(
      dateOfJoining,
      dateOfCessation
    ) {
      const diffInMonths =
        (dateOfCessation.getFullYear() - dateOfJoining.getFullYear()) * 12 +
        (dateOfCessation.getMonth() - dateOfJoining.getMonth());
      const years = Math.floor(diffInMonths / 12);
      const months = diffInMonths % 12;
      return { years, months };
    }
    // //_____________________________________________________________________/
    const periodOfMemberShip = calculateMembershipPeriodInYearsMonths(
      dateOfJoining,
      dateOfCessation
    );

    const ppoNo = `PO${claimId}`;

    let formattedPfAddress = null;
    if (memberData.pfAddress) {
      try {
        const add = JSON.parse(memberData.pfAddress);
        formattedPfAddress = `${add.addr_l1}, ${add.addr_l2}, ${add["village/town/city"]}, ${add.district}, ${add.state}, ${add.pin_code}`;
      } catch (error) {}
    }
    let data;
    console.log("processId in super", processId);
    const dataForSuperannuation = {
      claimId: claimId,
      para: fetchedCalculationData.settlementParaNumber,
      payOrderNo: ppoNo,
      processId: processId,
      fullName: pfClaimData.claimDetails[0].fullName,
      fatherHusbandName: memberData.fatherHusbandName,
      unitName: memberData.unitName,
      unitCode: memberData.unitId,
      joiningDate: dateConvert(memberData.joiningDate),

      cmpfAccNo: pfClaimData.claimDetails[0].cmpfAccNo,
      dateOfCessation: dateConvert(fetchedCalculationData.dateOfCessation),
      dateOfAppointment: dateConvert(memberData.joiningDate),
      dateOfDeath: dateConvert(fetchedCalculationData.dateOfDeath),
      membershipPeriod: `${periodOfMemberShip.years} years and ${periodOfMemberShip.months} months`,
      //membershipPeriod: periodOfMemberShip,
      roName: fetchedCalculationData.roName,
      roCode: fetchedCalculationData.roCode,
      ledgerBalance: fetchedCalculationData.ledgerBalance,

      dateOfClaim: dateConvert(todaysDate),
      dateOfBirth: dateConvert(memberData.dateOfBirth),
      causeOfCessation: fetchedCalculationData.claimTypDesc,
      claimTypDesc: fetchedCalculationData.claimTypDesc,
      name:
        pfClaimData.claimDetails[0].claimantId === 0
          ? pfClaimData.claimDetails[0].fullName
          : fetchedCalculationData.name,
      relationName: fetchedCalculationData.relationName,
      sanctionedAmt: calculatedData.netAmtPayable,
      brokenPeriodInterestRate: claim.brokenPeriodInterestRate,
      // brokenPeriodInterestPayable: calculatedData.brokenPeriodInterest,
      brokenPeriodInterestPayable: calculatedData.deductedIntrest,

      brokenPeriodContri: calculatedData.totalBrokenPeriodContri,
      amountBeforeDistribution: calculatedData.totalPfContriAmount,
      modeOfRemittance: "A/C PAYEE CHEQUE/BANK TRANSFER",
      acc: memberData.bankAccNoPf,
      ifsc: memberData.pfBankIfsc,
      bankName: memberData.pfBankName,
      bankAddress: formattedPfAddress,
      postalAddress: getAddress(memberData),

      // feilds to be calculated
      age: calculateAge(memberData.dateOfBirth),
      dateUptoWhichInterestIsPayable: dateConvert(todate),
      closingBalance: fetchedCalculationData.ledgerBalance,
      dateFrom: "",
      dateTo: calculateCurrencyEnding(dateOfCessation),
      // rateOfInterest: 8.0,
      brokenPeriodInterestPayableFrom: formattedBrokenPeriodFromDate,
      brokenPeriodInterestPayableTo: formattedToDate,
      // brokenPeriodContribution: 180804.0,
      // brokenPeriodInterestPayable: 247043.0,
      // amountBeforeDistribution: 0,
      // totalAmountpayable: 2659709.0,
      refundDueDate: dateConvert(refundDueDate),
      payeeAddress: getAddress(memberData), // to be taken from
      uploadedDocs: uploadedDocs,
      noOfMonthsWorked: noOfMonthsWorked,
    };
    console.log("superannuationobject", dataForSuperannuation);
    const dataForWBR = {
      claimId: claimId,
      para: fetchedCalculationData.settlementParaNumber,
      payOrderNo: ppoNo,
      processId: processId,
      fullName: pfClaimData.claimDetails[0].fullName,
      fatherHusbandName: memberData.fatherHusbandName,
      unitName: memberData.unitName,
      unitCode: memberData.unitId,
      joiningDate: dateConvert(memberData.joiningDate),

      cmpfAccNo: pfClaimData.claimDetails[0].cmpfAccNo,
      dateOfCessation: dateConvert(fetchedCalculationData.dateOfCessation),
      dateOfAppointment: dateConvert(memberData.joiningDate),
      dateOfDeath: dateConvert(pfClaimData.claimDetails[0].dateOfDeath),
      membershipPeriod: `${periodOfMemberShip.years} years and ${periodOfMemberShip.months} months`,
      //membershipPeriod: periodOfMemberShip,
      roName: fetchedCalculationData.roName,
      roCode: fetchedCalculationData.roCode,
      ledgerBalance: fetchedCalculationData.ledgerBalance,

      dateOfClaim: dateConvert(todaysDate),
      dateOfBirth: dateConvert(memberData.dateOfBirth),
      causeOfCessation: fetchedCalculationData.claimTypDesc,
      claimTypDesc: fetchedCalculationData.claimTypDesc,
      name:
        pfClaimData.claimDetails[0].claimantId === 0
          ? pfClaimData.claimDetails[0].fullName
          : fetchedCalculationData.name,
      relationName: fetchedCalculationData.relationName,
      sanctionedAmt: withdrawalReturnAmt,
      brokenPeriodInterestRate: 0,
      brokenPeriodInterestPayable: 0,
      brokenPeriodContri: 0,
      amountBeforeDistribution: withdrawalReturnAmt,
      modeOfRemittance: "A/C PAYEE CHEQUE/BANK TRANSFER",
      acc: memberData.bankAccNoPf,
      ifsc: memberData.pfBankIfsc,
      bankName: memberData.pfBankName,
      bankAddress: formattedPfAddress,
      postalAddress: getAddress(memberData),

      // feilds to be calculated
      age: calculateAge(memberData.dateOfBirth),
      dateUptoWhichInterestIsPayable: dateConvert(todate),
      closingBalance: fetchedCalculationData.ledgerBalance,
      dateFrom: "",
      dateTo: calculateCurrencyEnding(dateOfCessation),
      // rateOfInterest: 8.0,
      brokenPeriodInterestPayableFrom: "",
      brokenPeriodInterestPayableTo: "",
      // brokenPeriodContribution: 180804.0,
      // brokenPeriodInterestPayable: 247043.0,
      // amountBeforeDistribution: 0,
      // totalAmountpayable: 2659709.0,
      refundDueDate: dateConvert(refundDueDate),
      payeeAddress: getAddress(memberData), // to be taken from
      uploadedDocs: uploadedDocs,
    };

    console.log("view dpoe");

    if (
      pfClaimData.claimDetails[0].pfClaimType === "Withdrawal Before Retirement"
    ) {
      data = dataForWBR;
    } else {
      data = dataForSuperannuation;
      
      if (!dataForSuperannuation.brokenPeriodInterestRate) {
        // Show message in dialog: "Please select an officer to assign"
        //alert("Please select an officer to assign");
        setIsError(true);
        setErrorMessage("Please Enter Interest Rate for the Broken Period");
        return;
      }
    }

    navigate("pfdpo", { state: data });
  };

  const postUploadedDoc = () => {
    const uploadDocData = {
      claimId: claimId,
      claimFlag: "PF",
      claimantId: pfClaimData.claimDetails[0].claimantId,
      uploadDocumentsByRoda: dataFromPreview.uploadedDocs,
    };
    console.log("uploadDocData", uploadDocData);
    const url = process.env.REACT_APP_SERVER_URL + "/documents/upload/by/roda";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, uploadDocData, { headers })
      .then(function (response) {
        console.log("Submit response", response);
        setIsSubmitting(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Forwarded Successfully.");
      })
      .catch((error) => {
        setIsError(true);

        setErrorMessage(
          "Sorry Error while submitting Documents. Please Try again."
        );

        console.log("error on submit documents", error);
      });
  };
  //  console.log("noOfMonthsWorked", noOfMonthsWorked);
  /// ******** on submit function
  // const objectTobeSent = {
  //   // fromOffcrLoginId: loginId,
  //   // Remarks: claim.remarks,
  //   // toOffcrId: claim.assignTo,
  //   // claimId: claimId,
  //   claimId: claimId,
  //   sanctionedAmt: calculatedData.netAmtPayable * 1,
  //   fromLoginId: loginId,
  //   toLoginId: claim.assignTo,
  //   remarks: claim.remarks,
  //   approvalStatus: true,
  //   brokenPeriodInterestPayable: calculatedData.brokenPeriodInterest * 1,
  //   brokenPeriodInterestRate: claim.brokenPeriodInterestRate * 1,
  //   brokenPeriodContri: calculatedData.totalBrokenPeriodContri * 1,
  //   draftPayOrder: dpoPdf,
  // };
  const objectTobeSent = {
    // fromOffcrLoginId: loginId,
    // Remarks: claim.remarks,
    // toOffcrId: claim.assignTo,
    // claimId: claimId,
    claimId: claimId,
    sanctionedAmt: dataFromPreview.sanctionedAmt,

    fromLoginId: loginId,
    toLoginId: claim.assignTo,
    remarks: claim.remarks,
    approvalStatus: true,
    brokenPeriodInterestPayable: dataFromPreview.brokenPeriodInterestPayable,
    brokenPeriodInterestRate: dataFromPreview.brokenPeriodInterestRate,
    brokenPeriodContri: dataFromPreview.brokenPeriodContri,
    draftPayOrder: dataFromPreview.draftPayOrder,
    processId: dataFromPreview.processId,
  };
  console.log("objectTobeSent", objectTobeSent);
  //console.log("draftPayOrder", dpoPdf);
  const handleSubmit = (e) => {
    e.preventDefault();

    // if (!legderUpdated) {
    //   // Show message in dialog: "Please select an officer to assign"
    //   //alert("Please select an officer to assign");
    //   setIsError(true);
    //   setErrorMessage("Please Update Ledger before proceeding.");
    //   return;
    // }

    // if (!dpoGenrated) {
    //   // Show message in dialog: "Please select check Box"
    //   // alert("Please select the check box");
    //   setIsError(true);
    //   setErrorMessage("Please Generate DPO.");
    //   return;
    // }

    // setIsSubmitting(true);

    const url =
      process.env.REACT_APP_SERVER_URL + "/verification/pf/claim/roda";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSent, { headers })
      .then(function (response) {
        console.log("ooooooooouuuuuuuuuu", uploadedDocs);
        if (Object.keys(dataFromPreview.uploadedDocs).length !== 0) {
          postUploadedDoc();
        } else {
          setIsSubmitting(false);
          setIsSuccess(true);
          setSuccessMessage("Claim Forwarded Successfully.");
          //   setLedgerUpdated(false);
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
        console.log("error on submit claims", error);
      });
  };

  // function to add broken period row on ledger
  const brokenPeriodRowToAdd = {
    claimId: claimId,
    currPeriodEnd: "", // keep it null
    noOfMonthWorked: dataFromPreview.noOfMonthsWorked,
    openingBalance: null,
    memPfContri: fetchedCalculationData.brokenPeriodMemShareContri,
    employerPfContri: fetchedCalculationData.brokenPeriodEmplyrShareContri,
    volContri: fetchedCalculationData.brokenPeriodVolContri,
    interestPerc: dataFromPreview.brokenPeriodInterestRate / 100,
    interestAmt: dataFromPreview.brokenPeriodInterestPayable,
    closingBalance: dataFromPreview.sanctionedAmt,
  };
  console.log("brokenPeriodRowToAdd", brokenPeriodRowToAdd);

  const addBrokenPeriodRowToLedger = (e) => {
    if (!objectTobeSent.toLoginId) {
      // Show message in dialog: "Please select an officer to assign"
      //alert("Please select an officer to assign");
      setIsError(true);
      setErrorMessage("Please Select an Officer to Assign.");
      return;
    }
    if (!objectTobeSent.draftPayOrder) {
      // Show message in dialog: "Please select an officer to assign"
      //alert("Please select an officer to assign");
      setIsError(true);
      setErrorMessage("DPO is not generated.");
      return;
    }
    setIsSubmitting(true);

    if (brokenPeriodRowToAdd.noOfMonthWorked === 0) {
      handleSubmit(e);
      // console.log("month is zero not row added ");
      return;
    } else {
      //console.log("row is added to the ledger ");
      //console.log("months worked ", brokenPeriodRowToAdd.noOfMonthWorked);

      const url =
        process.env.REACT_APP_SERVER_URL + "/insert/broken/period/ledger";
      const headers = {
        //'Authorization': `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      axios
        .post(url, brokenPeriodRowToAdd, { headers })
        .then(function (response) {
          //setIsSubmitting(false);
          //setLedgerUpdated(true);
          //setIsSuccess(true);
          // setSuccessMessage("Broken Period Details Inserted Successfully.");
          handleSubmit(e);
        })
        .catch((error) => {
          setIsSubmitting(false);
          setIsError(true);
          setErrorMessage("Sorry Could not Proceed!! Error on updating ledger");
          console.log("error on updating ledger", error);
        });
    }
  };

  /// ******** on Return function

  const objectTobeSentOnReturn = {
    fromLoginId: loginId,
    remarks: claim.remarks,
    toLoginId: claim.assignTo,
    claimId: claimId,
  };

  const handleReturnToSi = (e) => {
    e.preventDefault();

    if (!claim.remarks.trim()) {
      // Show message in dialog: "Please enter remarks"
      // alert("Please enter remarks");
      setIsError(true);
      setErrorMessage("Please enter Remarks.");
      return;
    }

    if (!objectTobeSentOnReturn.toLoginId) {
      // Show message in dialog: "Please select an officer to assign"
      //alert("Please select an officer to assign");
      setIsError(true);
      setErrorMessage("Please Select an Officer to Return.");
      return;
    }

    setIsReturning(true);
    setIsReturnClicked(true);
  };
  const handleReturnClaim = (e) => {
    // e.preventDefault();

    const url =
      process.env.REACT_APP_SERVER_URL + "/pf/return/claims/roda/to/si";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSentOnReturn, { headers })
      .then(function (response) {
        setIsReturning(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Returned Successfully.");

        //console.log("Claim Returned Successfully");
        setIsReturnClicked(false);
      })
      .catch((error) => {
        console.log("error on submit", error);
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
      });
  };

  /// ******** on Revert function

  // const objectTobeSentOnRevert = {
  //   loginId: loginId,
  //   remark: claim.remarks,
  //   claimId: claimId,
  // };

  const handleRevertClick = (e) => {
    e.preventDefault();

    if (!claim.remarks.trim()) {
      // Show message in dialog: "Please enter remarks"
      // alert("Please enter remarks");
      setIsError(true);
      setErrorMessage("Please enter Remarks.");
      return;
    }
    // setIsRevert(true);

    setIsRevertClicked(true);
  };

  const handleRevertClaim = (e) => {
    // e.preventDefault();

    const url =
      process.env.REACT_APP_SERVER_URL + "/revert/to/IDS/for/reallocation";
    const formData = new FormData();
    formData.append("loginId", loginId);
    formData.append("remarks", claim.remarks);
    formData.append("claimId", claimId);
    const config = {
      headers: {
        // Include the Authorization header if required
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsReturning(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Revert Successfully.");

        //console.log("Claim Returned Successfully");
        setIsRevertClicked(false);
      })
      .catch((error) => {
        console.log("error on submit", error);
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
      });
  };

  ///****************integration started********************* */

  // function that returns closing balance of the penultimate year if the claim type is withdrawal before retirement
  const getWithdrawalAmount = (claimId) => {
    // setIsLoading(true); // uncomment

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url =
      process.env.REACT_APP_SERVER_URL + "/get/closing/balance/for/wbr";
    const formData = new FormData();
    formData.append("claimId", claimId);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          console.log("closing wbr response", data);
          setWithdrawalReturnAmt(data);
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
      });
  };

  const fetchMemberDetail = async (accNo) => {
    // setIsLoading(true); // uncomment

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + "/member/details/for/pf";
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          console.log("member data", data);
          if (data.length !== 0) {
            setMemberData(data[0]);
            setIsMemberFound("true");
            fetchNomineeDetail(data[0].cmpfAccNo);
          }
          setIsMemberFound("false");
          return;
        }
        setIsFailed(true);
        setIsMemberFound("false");
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        setIsMemberFound("false");
      });
  };

  /***** function to get all nominee details**************** */
  const fetchNomineeDetail = async (accNo) => {
    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");

    const url = process.env.REACT_APP_SERVER_URL + "/get/all/nominee/details";
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo); // check what formdata is to be sent
    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsLoading(false);
        setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          console.log("nominee details", data);
          if (data.length !== 0) {
            setNomineeDetails(data);
          }

          return;
        }
        //setIsFailed(true); //uncomment this
      })
      .catch(function (error) {
        setIsLoading(false);
        // setIsFailed(true); // uncomment this
      });
  };
  //****************************** Calculations for PF ***************************************** */

  const caluculatePf = () => {
    //************calculate broken period payable********************** *

    const currentDate = new Date(fetchedCalculationData.currentDate); // Replace with the current date
    // const todate = new Date(
    //   currentDate.getFullYear(),
    //   currentDate.getMonth(),
    //   0
    // );

    const brokenPeriodFrom = new Date();
    const dateOfCessation = new Date(fetchedCalculationData.dateOfCessation);
    console.log("dateofcessation", dateOfCessation);

    //** I am replacing the dataOfCessation with current date as row for each row would be added now by RODA in ledger and broken period now corresponds to the april of the current month of the year */
    
    if (currentDate.getMonth() <= 2) {
      // Months are 0-indexed, so March is index 2
      // If dateOfCessation is before March 31st
      brokenPeriodFrom.setFullYear(currentDate.getFullYear() - 1, 3, 1); // Setting the brokenPeriodFromDate to April 1st of the previous year
    } else {
      // If dateOfCessation is on or after March 31st
      brokenPeriodFrom.setFullYear(currentDate.getFullYear(), 3, 1); // Setting the brokenPeriodFromDate to April 1st of the current year
    }
    console.log("brokenPeriodFrom", brokenPeriodFrom);
    const formattedBrokenPeriodFromDate =
      brokenPeriodFrom.toLocaleDateString("en-GB");
    // const formattedToDate = todate.toLocaleDateString("en-GB");

    console.log(
      "formattedBrokenPeriodFromDatecallll",
      formattedBrokenPeriodFromDate
    );

    // Calculate the broken period months from the start month to the current month
    brokenPeriodMonths =
      (currentDate.getFullYear() - brokenPeriodFrom.getFullYear()) * 12 +
      currentDate.getMonth() -
      brokenPeriodFrom.getMonth();
    console.log(
      "Year ",
      (currentDate.getFullYear() - brokenPeriodFrom.getFullYear()) * 12
    );
    console.log(
      "Year here ",
      currentDate.getFullYear() - brokenPeriodFrom.getFullYear()
    );

    console.log("CurrentDatemonths: ", currentDate.getMonth());
    console.log("brokenPeriodFromMonths: ", brokenPeriodFrom.getMonth());

    // Print the result
    console.log("Number of broken period months: ", brokenPeriodMonths);
    setNoOfMonthsWorked(brokenPeriodMonths);

    const pfCalucationData = {
      closingBalance: fetchedCalculationData.ledgerBalance,
      interestRate: claim.brokenPeriodInterestRate,
      brokenPeriodEmplyrShareContri:
        fetchedCalculationData.brokenPeriodEmplyrShareContri,
      brokenPeriodMemShareContri:
        fetchedCalculationData.brokenPeriodMemShareContri,
      brokenPeriodVolContri: fetchedCalculationData.brokenPeriodVolContri,
      brokenPeriodMonths: brokenPeriodMonths,
      disbursementPer: fetchedCalculationData.totalDisbursedPer,
    };
    console.log("pfCalucationData", pfCalucationData);

    //** withdrwal related calculations */
    let totalWithdrawalAmt = 0;
    let totalInterestOnWithdrawal = 0;
    // calculation of total wihtdrawal amt to be deducted if withdrawal is present
    if (withdrawalDetails.length !== 0) {
      totalWithdrawalAmt = withdrawalDetails.reduce(
        (acc, obj) => acc + obj.withdrwlAmt,
        0
      );
      totalInterestOnWithdrawal = calculateWithdrawalInterest(
        withdrawalDetails,
        pfCalucationData.interestRate,
        fetchedCalculationData.currentDate
      );
      console.log("totalInterestOnWithdrawal", totalInterestOnWithdrawal);
    }

    const totalBrokenPeriodContri = totalBrokenPeriodContribution(
      pfCalucationData.brokenPeriodEmplyrShareContri,
      pfCalucationData.brokenPeriodMemShareContri,
      pfCalucationData.brokenPeriodVolContri
    );

    const brokenPeriodInterest = calBrokenInterestPayable(
      pfCalucationData.closingBalance,
      pfCalucationData.interestRate,
      pfCalucationData.brokenPeriodMonths
    );
    console.log(
      "chekc this",
      pfCalucationData.closingBalance,
      pfCalucationData.interestRate,
      pfCalucationData.brokenPeriodMonths
    );

    console.log("brokenPeriodInteresti", brokenPeriodInterest);
    console.log("totalBrokenPeriodContri", totalBrokenPeriodContri);
    console.log("closingBalance", pfCalucationData.closingBalance);
    console.log("totalInterestOnWithdrawal", totalInterestOnWithdrawal);
    console.log("totalWithdrawalAmt", totalWithdrawalAmt);

    let totalPfContriAmount = totalPfAmount(
      pfCalucationData.closingBalance,
      brokenPeriodInterest,
      totalBrokenPeriodContri,
      totalInterestOnWithdrawal,
      totalWithdrawalAmt
    );

    console.log("totalPfContriAmount", totalPfContriAmount);
    console.log(
      "before deduction of tds",
      totalPfAmount(
        pfCalucationData.closingBalance,
        brokenPeriodInterest,
        totalBrokenPeriodContri,
        totalInterestOnWithdrawal,
        totalWithdrawalAmt
      )
    );

    const totContri =
      pfCalucationData.brokenPeriodMemShareContri +
      pfCalucationData.brokenPeriodVolContri;

    console.log("tot Contribution", totContri);

    if (totContri > 250000) {
      const tds = tdsCalculations(
        totContri,
        pfCalucationData.interestRate,
        pfCalucationData.brokenPeriodMonths
      );
      totalPfContriAmount -= tds;
      console.log("tds deducted", tds);
    }

    const netAmtPayable = netAmountPayable(
      totalPfContriAmount,
      pfCalucationData.disbursementPer
    );

    const deductedIntrest = brokenPeriodInterest - totalInterestOnWithdrawal;
    console.log("deductedIntrest", deductedIntrest);

    setCalculatedData({
      totalBrokenPeriodContri: customRounding(totalBrokenPeriodContri),
      brokenPeriodInterest: customRounding(brokenPeriodInterest),

      totalPfContriAmount: totalPfContriAmount.toLocaleString("en-IN"),
      deductedIntrest: customRounding(deductedIntrest),
      netAmtPayable: customRounding(netAmtPayable) * 1,
    });

    console.log(
      "totalBrokenPeriodContri , brokenPeriodInterest, totalPfAmount,",
      totalBrokenPeriodContri,
      brokenPeriodInterest,
      totalPfContriAmount,
      netAmtPayable
    );
  };

  //******************************************************************************************* */
  useEffect(() => {
    caluculatePf();
  }, [claim.brokenPeriodInterestRate]);
  /// error alert handler
  const handleErrorAlert = () => {
    setIsError(false);
  };
  const handleLedgerErrorAlert = () => {
    setIsLedgerError(false);
    // navigate("/roda/pfset");
  };
  const handleSuccess = () => {
    // setIsSuccess(false);
    // if (legderUpdated) {
    //   setIsSuccess(false);
    // } else {
    navigate("/roda/pfset");
    // }
  };

  const handleAssign = (e) => {
    setShowingDropdown(1);
  };

  const handleReturn = (e) => {
    setShowingDropdown(3);
  };

  // todo handleAssignToOk calling handle submit on click of ok confirmation dialouge box
  const handleReturnToOk = (e) => {
    handleReturnClaim(e);
    setIsReturnClicked(false);
  };

  const handleReturnToCancel = () => {
    setIsReturnClicked(false);
  };
  const handleRevertOk = () => {
    handleRevertClaim();
  };
  const handleCancelRevert = () => {
    setIsRevertClicked(false);
  };

  return (
    <div>
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error!"
        body={errorMessage}
        buttonName="Ok"
      />
      <ErrorAlert
        handleOk={handleLedgerErrorAlert}
        open={isLedgerError}
        title="Contributions Missing!"
        body={ledgerErrorMessage}
        buttonName="Ok"
      />
      <SucessAlert
        handleOk={handleSuccess}
        open={isSuccess}
        title="Success"
        body={successMessage}
        buttonName="OK"
      />
      <ConfirmationDialogue
        handleOk={handleReturnToOk}
        handleCancel={handleReturnToCancel}
        open={isReturnClicked}
        title="Confirm Return"
        body={`Are you sure you want to return the claim to ${selectedOfficerName} ?`}
        buttonName="Confirm"
      />
      <ConfirmationDialogue
        handleOk={handleRevertOk}
        handleCancel={handleCancelRevert}
        open={isRevertClicked}
        title="Confirm Revert"
        body="Are you sure you want to revert this claim ?"
        buttonName="Confirm"
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "10px" }}>
            {/* {isFailed || isFailedDoc ? (
              <Reloader refreshHandler={fetchAllClaimDetail} />
            ) : ( */}
            <>
              {isLoading || isLoadingDoc ? (
                <LoadingIndicator />
              ) : (
                <>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item xs={12}>
                      <TitleText title="PF Settlement" />
                    </Grid>
                  </Grid>
                  <FormDivider />
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={1} mb={3}>
                      <BackButton />
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="start"
                      rowGap={3}>
                      {/* Member Details*/}
                      {/* section 1  */}
                      <Paper
                        elevation={3}
                        sx={{ padding: "3rem", borderRadius: "10px" }}>
                        <Grid item xs={12}>
                          <MemberDetails member={memberData} />
                        </Grid>
                      </Paper>
                      {/* claim Details*/}
                      {/* section 2  */}
                      <Paper
                        elevation={3}
                        sx={{ padding: "3rem", borderRadius: "10px" }}>
                        <Grid item xs={12}>
                          <ClaimDetailsPf pfclaimdetails={pfClaimData} />
                        </Grid>
                      </Paper>
                      {/* Document Details*/}
                      {/* section 3  */}
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={12}>
                          <Paper
                            elevation={3}
                            sx={{ padding: "3rem", borderRadius: "10px" }}>
                            <Grid item xs={12}>
                              <ViewDocuments documents={documents} />
                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid>
                      {/* ************************************Broken period and calculation details ***************************************** */}
                      <Grid item xs={12}>
                        <Paper
                          elevation={3}
                          sx={{ padding: "2rem", borderRadius: "10px" }}>
                          <SubtitleText title="PF Calculation" />

                          <Grid
                            container
                            spacing={1}
                            columnGap={2}
                            justifyContent="space-between"
                            mt={2}>
                            {/* here we are creating two columns as the label and textfield were not aligning properly */}
                            {/* Column 1 */}
                            <Grid item xs={12} md={5}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={4}>
                                  <LabelText title="Ledger Closing Balance  " />
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                  <ValueText
                                    title={fetchedCalculationData.ledgerBalance}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                  <LabelText title="Rate of Interest  " />
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                  <CustomTextField
                                    {...brokenPeriodInterestRate}
                                    onchange={onChange}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={5}>
                                  <LabelText title="Disbursement Percentage  " />
                                </Grid>
                                <Grid item xs={12} sm={12} md={7}>
                                  <ValueText
                                    title={
                                      fetchedCalculationData.disbursementPer
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* Column 2 */}
                            <Grid item xs={12} md={5}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={4}>
                                  <LabelText title="Broken Period Contribution  " />
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                  <ValueText
                                    title={
                                      calculatedData.totalBrokenPeriodContri
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                  <LabelText title="Interest Payable on Broken Period  " />
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                  <ValueText
                                    title={calculatedData.deductedIntrest}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                  <LabelText title="Net Amount Payable " />
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                  <ValueText
                                    title={calculatedData.netAmtPayable}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* <Grid
                              container
                              // spacing={2}
                              //justifyContent={""}
                              alignItems={"end"}
                              direction="column"
                            >
                              <Grid item xs={12} sm={12} md={2} mt={2}>
                                {legderUpdated ? (
                                  <Typography>
                                    <CheckCircleIcon
                                      style={{
                                        color: "green",
                                        verticalAlign: "middle",
                                      }}
                                    />
                                    {"Updated"}
                                  </Typography>
                                ) : (
                                  <Button
                                    onClick={addBrokenPeriodRowToLedger}
                                    variant="contained"
                                    color="success"
                                    type="submit"
                                    //style={{ marginLeft: 8 }}
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting ? (
                                      <CircularProgress />
                                    ) : (
                                      "Add to Ledger"
                                    )}
                                  </Button>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} mt={2}>
                                <Typography
                                  // variant="body1"
                                  sx={{
                                    fontFamily: "Arial sans-serif",
                                    fontSize: {
                                      xs: "10px",
                                      sm: "11px",
                                      md: "12px",
                                      lg: "14px",
                                      xl: "15px",
                                    },
                                    fontWeight: "500",
                                  }}
                                  color={"#EF0107"}
                                  align="left"
                                >
                                  Kindly click the button to add the broken
                                  period details to the ledger card.
                                </Typography>
                              </Grid>
                            </Grid> */}
                          </Grid>
                        </Paper>
                      </Grid>
                      {/* {legderUpdated && (
                        <> */}
                      {/* *****
{/* ************************************ upload documents ***************************************** */}
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={12}>
                          <Paper
                            elevation={3}
                            sx={{ padding: "3rem", borderRadius: "10px" }}>
                            <Grid item xs={12}>
                              <SubtitleText title="Upload Documents" />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <UploadDocuments
                                documentTypes={documentTypes}
                                onSave={handleStateUpdate}
                              />
                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid>
                      {/* ************************************ Remarks ***************************************** */}
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={12}>
                          <Paper
                            elevation={3}
                            sx={{ padding: "2rem", borderRadius: "10px" }}>
                            <SubtitleText title="Remarks" />
                            <Grid item xs={12} sm={6} md={8}>
                              <CustomTextArea
                                {...remarks}
                                onchange={onChange}
                              />
                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid>
                      {/* *************** Submit Button ********* */}
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems={"center"}>
                        <Grid item md={3}>
                          <Grid container justifyContent="start">
                            <Grid
                              item
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}>
                              <Button
                                onClick={handlePreviewDpo}
                                variant="contained"
                                color="success"
                                type="submit"
                                style={{ marginLeft: 8 }}
                                disabled={isSubmitting}>
                                Preview DPO
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* *************** Submit Button ********* */}
                        <Grid item md={6}>
                          <Grid
                            container
                            justifyContent="end"
                            alignItems="center"
                            columnGap={1}>
                            <Grid item md={12}>
                              <Grid
                                container
                                justifyContent="end"
                                alignItems="center"
                                columnGap={1}>
                                <Grid item md={2}>
                                  <Button
                                    onClick={handleRevertClick}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isReturnClicked}
                                    fullWidth>
                                    Revert
                                  </Button>
                                </Grid>
                                <Grid item md={2}>
                                  <Button
                                    onClick={handleReturn}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isReturnClicked}
                                    fullWidth>
                                    Return
                                  </Button>
                                </Grid>
                                <Grid item md={2}>
                                  <Button
                                    onClick={handleAssign}
                                    variant="contained"
                                    color="success"
                                    type="submit"
                                    //style={{ marginLeft: 8 }}
                                    disabled={isSubmitting}
                                    fullWidth>
                                    {isSubmitting ? (
                                      <CircularProgress />
                                    ) : (
                                      "Assign"
                                    )}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {showingDropdown === 1 && (
                        <Grid container justifyContent="end" spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <CustomDropdown {...assignTo} onchange={onChange} />
                          </Grid>
                          <Grid item md={1}>
                            <Button
                              onClick={addBrokenPeriodRowToLedger}
                              variant="contained"
                              color="success"
                              type="submit"
                              disabled={isSubmitting}
                              fullWidth>
                              {isSubmitting ? <CircularProgress /> : "OK"}
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                      {showingDropdown === 3 && (
                        <Grid container justifyContent="end" spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <CustomDropdown {...assignTo} onchange={onChange} />
                          </Grid>
                          <Grid item md={1}>
                            <Button
                              onClick={handleReturnToSi}
                              variant="contained"
                              color="success"
                              type="submit"
                              disabled={isReturnClicked}
                              fullWidth>
                              {isReturnClicked ? <CircularProgress /> : "OK"}
                            </Button>
                          </Grid>
                        </Grid>
                        //   )}
                        // </>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </>
            {/* )} */}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default ViewPfClaimRoda;
