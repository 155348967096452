import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import Modal from "@mui/material/Modal";
import SubtitleText from "../../FormComponent/SubtitleText";
import MemberDetails from "../UDAPages/MemberDetatils";
import CaptionText from "../../FormComponent/CaptionText";
import BackButton from "../../components/button/BackButton";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ViewDocuments from "../../SettlementComponents/ViewDocuments";
import CustomTextArea from "../../FormComponent/CustomTextArea";
import LabelText from "../../FormComponent/LabelText";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import Reloader from "../../FormComponent/Reloader";
import LoadingIndicator from "../../FormComponent/LoadingIndicator";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import SucessAlert from "../../FormComponent/SuccessAlert";
import ConfirmationDialogue from "../../FormComponent/ConfirmationDialogue";
import ClaimDetailsPf from "../../SettlementComponents/ClaimDetailsPf";
import {
  calculateCurrencyEnding,
  dateConvert,

  getAddress,
  getFiscalYear,
} from "../../Utility/UtilityMethods";
import ClaimDetailsPen from "../../SettlementComponents/ClaimDetailsPen";
import jwtDecode from "jwt-decode";

function ViewPfClaimBo() {
  const { state } = useLocation();
  const claimId = state.passingId;
  const claimType = claimId.substring(0, 2);

  const navigate = useNavigate();
  const sampleMemberData = {
    // This is the sample claimData data to be displed for demo purpose only
    title: "",
    fullName: "",
    dateOfBirth: "",
    panNo: "",
    aadhaarNumber: "",
    maritalStatus: "",
    religionName: "",
    gender: "",
    fatherHusbandName: "",
    email: "",
    mobileNo: "",
    landNo: "",
    addressL1: "",
    addressL2: "",
    village: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  };

  const sampleDocumentData = {
    data: {
      0: {
        DocTypeid: "",
        Document: "",
      },
      1: {
        DocTypeid: "",
        Document: "",
      },
    },
  };

  const samplePfClaimData = {
    claimDetails: [
      {
        claimId: "",
        fullName: "",
        cmpfAccNo: "",
        unitCode: null,
        submissionDate: "",
        nameAadhaar: '{"firstname":"","middlename":"","lastname":"',
        nameBank: '{"firstname":"","middlename":"","lastname":""}',
        namePan: '{"firstname":"","middlename":"","lastname":""}',
        dobInAadhaar: "",
        dobInPan: "",
        sfwhName: "",
        sfwhRelationDesc: "",
        pfClaimType: "",
        pfClaimStatus: null,
        remarks: null,
      },
    ],
    officerRemarks: [
      {
        remarks: "",
        status: "",
        fromLoginId: "",
      },
    ],
  };

  const [pfClaimData, setPfClaimData] = useState(samplePfClaimData);
  const [memberData, setMemberData] = useState(sampleMemberData);
  const [isMemberFound, setIsMemberFound] = useState(" ");
  const [documents, setDocuments] = useState([]);
  const [assignToOption, setAssignToOption] = useState([]);
  const [assignToChOption, setAssignToChOption] = useState([]);
  const [fetchedCalculationData, setFetchedCalculationData] = useState([]);
  //**********for integration************* */
  const [isLoading, setIsLoading] = useState(true); // set this to true
  const [isLoadingDoc, setIsLoadingDoc] = useState(true); // set this to true
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isFailedDoc, setIsFailedDoc] = useState(false);
  const [isReturning, setIsReturning] = useState(false);
  const [isReturnClicked, setIsReturnClicked] = useState(false);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [showingDropdown, setShowingDropdown] = useState(0);
  const [isReturnRodaClicked, setIsReturnRodaClicked] = useState(false);

  // const [remarks, setRemarks] = useState("");
  const defaultClaim = {
    remarks: "",
    assignTo: "",
    assignToCh: "",
  };

  // declaring the default api object

  let api = {
    claimUrl: "",
    docsUrl: "",
    returnUrlRoda: "",
    returnUrlAao: "",
    forwardUrl: "",
    officerDropdownUrl: "",
  };
  // object for pfapis
  // let loginId = "BO-RO-OFF-450"; // Assuming a default loginId
  // let token = token.replace('"', "").replace('"', "");
  // let loginId = jwtDecode(token).user; // Assuming a default loginId

  // console.log("loooooooooooooooooooooginId", loginId);
  const [loginId, setLoginId] = useState();

  /********fetching and setting token********* */
  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (token) {
      token = token.replace('"', "").replace('"', "");
      let loginId = jwtDecode(token).user; // Assuming a default loginId
      setLoginId(loginId);
      console.log("loooooooooooooooooooooginId", loginId);
      // Add any further logic here based on the loginId
    } else {
      // Handle the case when token is not present
      setIsLoading(false);
      setIsFailed(true);
    }
  }, []);
  const pfApis = {
    claimUrl: "/show/pf/claim/details/to/SI/on/claimid  ",
    docsUrl: "/show/pf/claim/uploaded/documents/claimid",
    returnUrlRoda: "/return/pf/claim/officers/bo",
    returnUrlAao: "/return/pf/claim/officers/bo",
    forwardUrl: "/verification/pf/claim/officers/bo",
    officerDropdownUrl: "/show/next/autorized/officer/pf",
    fetchpopenset: "/get/data/to/generate/dpo",
  };

  const penApis = {
    claimUrl: "/show/pen/claim/details/to/si/on/claimid",
    docsUrl: "/show/submitted/documents/pen/claim",
    returnUrlRoda: "/pension/return/claims/bo/to/roda",
    returnUrlAao: "/pension/return/claims/bo/to/aao",
    forwardUrl: "/pension/claims/assign/by/bo/rh",
    officerDropdownUrl: "/show/next/autorized/officer/pension",
    fetchpopenset: "/generate/penset",
  };
  let title;
  if (claimType === "PF") {
    api = { ...pfApis };
    title = "PF";
  } else if (claimType === "PN") {
    api = { ...penApis };
    title = "Pension";
  }

  const [claim, setClaim] = useState(defaultClaim);
  const assignTo = {
    name: "assignTo",
    placeholder: "Select Officer",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "",
    required: true,
    options: assignToOption,
  };

  const assignToCh = {
    name: "assignToCh",
    placeholder: "Select Cashier",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "",
    required: true,
    options: assignToChOption,
  };

  const remarks = {
    name: "remarks",
    type: "text",
    multiline: true,
    helperText: "Enter your remarks (maximum 200 words)",
    placeholder: "Enter your remarks here",
    defaultValue: claim.remarks,
    errorMessage: "Cannot exceed 200 characters",
    pattern: "^.{0,200}$",
    maxLength: 200, // Limiting to 200
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveRemarks = () => {
    // Add logic to save the remarks
    handleClose();
    //    history.push('/other-page');
  };

  // const handleRemarksChange = (event) => {
  //   setRemarks(event.target.value);
  // };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  //********DEFAULT for family details*********/
  const familyDefault = {
    familyMemId: "",
    name: "",
    gender: "",
    relationName: "",
    dateOfBirth: "",
    pfNomSharePerc: "",
  };

  const [nomineeDetails, setNomineeDetails] = useState([familyDefault]);

  // ******** setting props for the TableWithLinkAndButton component*******

  // const viewLink = "/familydetails"; // Link for family members details

  // Advance claim type integration]

  const handlePreviewDpoPF = () => {
    const todaysDate = new Date();
    const refundDueDate = new Date(fetchedCalculationData.dateOfCessation);
    refundDueDate.setDate(refundDueDate.getDate() + 1);

    // finding the date till which the interest is payable
    // Set the date to the first day of the current month

    // Subtract one day to get the last day of the previous month
    const firstDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      1
    );
    const todate = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), 0);
    ///_________________________________//
    // finding the from date that is the date of cessastion
    const brokenPeriodFromDate = new Date();
    const dateOfCessation = new Date(fetchedCalculationData.dateOfCessation); // Replace with your actual date of cessation

    if (
      dateOfCessation.getDate() ===
      new Date(
        dateOfCessation.getFullYear(),
        dateOfCessation.getMonth() + 1,
        0
      ).getDate()
    ) {
      // If dateOfCessation is the last day of the month
      brokenPeriodFromDate.setFullYear(
        dateOfCessation.getFullYear(),
        dateOfCessation.getMonth() + 1,
        1
      );
    } else {
      // If dateOfCessation is any other day
      brokenPeriodFromDate.setFullYear(
        dateOfCessation.getFullYear(),
        dateOfCessation.getMonth(),
        0
      );
    }

    const formattedBrokenPeriodFromDate =
      brokenPeriodFromDate.toLocaleDateString("en-GB");
    const formattedToDate = todate.toLocaleDateString("en-GB");
    //-----------------------------------------------------------//
    //*** caluculate age 'it would be sent form backend' */
    function calculateAge(dateOfBirth) {
      const today = new Date();
      const birthDate = new Date(dateOfBirth);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      return age;
    }
    // _________________________________________________________________________
    //********* Calculate the membership period ************ */
    const dateOfJoining = new Date(fetchedCalculationData.joiningDate);

    function calculateMembershipPeriodInYearsMonths(
      dateOfJoining,
      dateOfCessation
    ) {
      const diffInMonths =
        (dateOfCessation.getFullYear() - dateOfJoining.getFullYear()) * 12 +
        (dateOfCessation.getMonth() - dateOfJoining.getMonth());
      const years = Math.floor(diffInMonths / 12);
      const months = diffInMonths % 12;
      return { years, months };
    }
    // //_____________________________________________________________________/
    const periodOfMemberShip = calculateMembershipPeriodInYearsMonths(
      dateOfJoining,
      dateOfCessation
    );

    const data = {
      claimId: claimId,
      para: fetchedCalculationData.settlementParaNumber,
      payOrderNo: "",
      fullName: pfClaimData.claimDetails[0].fullName,
      fatherHusbandName: memberData.fatherHusbandName,
      unitName: memberData.unitName,
      joiningDate: dateConvert(memberData.joiningDate),

      cmpfAccNo: pfClaimData.claimDetails[0].cmpfAccNo,
      dateOfCessation: dateConvert(fetchedCalculationData.dateOfCessation),
      dateOfAppointment: dateConvert(memberData.joiningDate),
      dateOfDeath: dateConvert(pfClaimData.claimDetails[0].dateOfDeath),
      membershipPeriod: `${periodOfMemberShip.years} years and ${periodOfMemberShip.months}months`,
      //membershipPeriod: periodOfMemberShip,

      ledgerBalance: fetchedCalculationData.ledgerBalance,

      dateOfClaim: dateConvert(todaysDate),
      dateOfBirth: dateConvert(memberData.dateOfBirth),
      causeOfCessation: fetchedCalculationData.claimTypDesc,
      claimTypDesc: fetchedCalculationData.claimTypDesc,
      name: fetchedCalculationData.name,
      relationName: fetchedCalculationData.relationName,
      sanctionedAmt: pfClaimData.claimDetails[0].sanctionedAmt,
      brokenPeriodInterestRate:
        pfClaimData.claimDetails[0].brokenPeriodInterestRate,
      brokenPeriodInterestPayable:
        pfClaimData.claimDetails[0].brokenPeriodInterestPayable,
      brokenPeriodContri: pfClaimData.claimDetails[0].brokenPeriodContri,
      // amountBeforeDistribution: calculatedData.totalPfContriAmount,
      amountBeforeDistribution: "",

      modeOfRemittance: "A/C PAYEE CHEQUE/BANK TRANSFER",
      acc: "52196074233",
      ifsc: "SBIN0020909",
      bankName: "STATE BANK OF INDIA",
      bankAddress: "Branch KALYANKHANI, SRINAGAR, GARHWAL, UTTARAKHAND, 246174",
      postalAddress: "C/O KK-1, MANDAMARRI, MMR MANCHERIAL, Telangana,INDIA",

      // feilds to be calculated
      age: calculateAge(memberData.dateOfBirth),
      dateUptoWhichInterestIsPayable: dateConvert(todate),
      closingBalance: fetchedCalculationData.ledgerBalance,
      dateFrom: "",
      dateTo: calculateCurrencyEnding(dateOfCessation),
      // rateOfInterest: 8.0,
      brokenPeriodInterestPayableFrom: formattedBrokenPeriodFromDate,
      brokenPeriodInterestPayableTo: formattedToDate,
      // brokenPeriodContribution: 180804.0,
      // brokenPeriodInterestPayable: 247043.0,
      // amountBeforeDistribution: 0,
      // totalAmountpayable: 2659709.0,
      refundDueDate: dateConvert(refundDueDate),
      payeeAddress: getAddress(memberData), // to be taken from
      disbursedPercentage: fetchedCalculationData.totalDisbursedPer,
      fromLoginId: loginId,
      toLoginId: claim.assignToCh,
      remarks: claim.remarks,
    };
    console.log("view dpoe", data);

    if (!data.toLoginId) {
      // Show message in dialog: "Please select an officer to assign"
      //alert("Please select an officer to assign");
      setIsError(true);
      setErrorMessage("Please Select an Officer to Assign.");
      return;
    }
    setIsError(false);

    navigate("/bo/pfset/pfpo", { state: data });
  };

  const handlePreviewDpoPen = () => {
    const {
      fullName,
      fatherHusbandName,
      cmpfAccNo,
      addressL1,
      addressL2,
      joiningDate,
      dateOfBirth,
      penClaimSrno,
      dateOfCessation,
      serviceYearsUpto89,
      serviceMonthsUpto89,
      serviceYearsAfter89,
      serviceMonthsAfter89,
      averagEmoluments,
      monthly_pension,
      kindOfPension,
      causeOfCessation,
      settlementParaId,
      unitName,
      optionParaNumber,
      reducedPen,
      arrearAmount,
      recoveryAmount,
      remmitBankDetails,
      currentDate,
      memberAadhaarNo,
      memberPanNo,
      memberMobileNo,
      memberEmailId,
      regionalOfficeCode,
      regionalOfficeName,
      regionalOfficeEmailId,
      familyMemberName,
      familyMemberMobileNO,
      familyMemberEmailId,
      familyMemberRelationName,
      familyMemberAadhaarNO,
      familyMemberPanNO,
      modeofRemmitance,
      chequeNO,
      familyMemberDOB,
      unitCode,
    } = fetchedCalculationData;
    //**********date of commencement**************** */
    const dateOfCommencement = new Date(dateOfCessation);
    dateOfCommencement.setDate(dateOfCommencement.getDate() + 1);

    //**************ppo no generation***************** */
    const pp_no = `PPO${claimId}`;

    //*********processid genration***** */
     const thisYear = getFiscalYear(currentDate);
    //console.log("thisYear", thisYear);

    const proccessId = `CMPFO/${regionalOfficeCode}/${thisYear}/${claimId}`;

    //*************bank remitance details */
    // Parse the original JSON string into an object
    const parsedBankDetails = JSON.parse(remmitBankDetails);
    let formattedPfAddress = null;
    if (memberData.penAddress) {
      try {
        const add = JSON.parse(memberData.penAddress);
        formattedPfAddress = `${add.addr_l1}, ${add.addr_l2}, ${add["village/town/city"]}, ${add.district}, ${add.state}, ${add.pin_code}`;
      } catch (error) {}
    }

    //mapping date on ppo
    const data = {
      pp_no: pp_no,
      acc_no: cmpfAccNo,
      name: fullName,
      so_of: fatherHusbandName,
      co: `${addressL1} ,  ${addressL2}`,
      app_no: proccessId,
      under_after: "CMPS 1998",
      stp_date: dateConvert(dateOfCommencement),
      exit_date: dateConvert(dateOfCessation),
      pri_name: fullName,
      pp_month: monthly_pension,
      arr_pension: arrearAmount,
      recovery: recoveryAmount,
      net_arr: arrearAmount,
      dob: dateConvert(joiningDate),
      aadhar: memberAadhaarNo,
      pan: memberPanNo,
      mobile: memberMobileNo,
      // bank: parsedBankDetails.bank ? parsedBankDetails.bank : "",
      // bank_acc_no: parsedBankDetails.account_no
      //   ? parsedBankDetails.account_no
      //   : "",
      // ifsc: parsedBankDetails.ifsc ? parsedBankDetails.ifsc : "",
      // branch: parsedBankDetails.address ? parsedBankDetails.address : "",
      bank_acc_no: memberData.bankAccNoPen,
      ifsc: memberData.penBankIfsc,
      bank: memberData.penBankName,
      branch: formattedPfAddress,
      roname: regionalOfficeName,
      rocode: regionalOfficeCode,
      roemail: regionalOfficeEmailId,
      kindOfPension: kindOfPension,
      causeOfCessation: causeOfCessation,
      para: settlementParaId,
      familyMemberName: familyMemberName,
      familyMemberMobileNO: familyMemberEmailId,
      familyMemberEmailId: familyMemberMobileNO,
      familyMemberRelationName: familyMemberRelationName,
      familyMemberAadhaarNO: familyMemberAadhaarNO,
      familyMemberPanNO: familyMemberPanNO,
      modeofRemmitance: modeofRemmitance,
      familyMemberDOB: familyMemberDOB,
      fromLoginId: loginId,
      toLoginId: claim.assignToCh,
      remarks: claim.remarks,
    };
    console.log("view penset", data);

    if (!data.toLoginId) {
      // Show message in dialog: "Please select an officer to assign"
      //alert("Please select an officer to assign");
      setIsError(true);
      setErrorMessage("Please Select an Officer to Assign.");
      return;
    }
    setIsError(false);

    navigate("/bo/penset/ppo", { state: data });
  };

  //

  //*******************fetching dpo details***************************** */
  const fetchDetailsForDpo = async () => {
    // setIsLoading(true);// uncomment

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + api.fetchpopenset;
    const formData = new FormData();
    formData.append("claimId", claimId);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data[0];
          console.log("fetchedCalculationData", data);
          setFetchedCalculationData(data);

          return;
        }
        // setIsFailed(true); uncomment this
      })
      .catch(function (error) {
        setIsLoading(false);
        //setIsFailed(true); uncomment this
      });
  };

  //****************Dropdown******************* */
  const fetchAssignOfficer = async () => {
    try {
      const url =
        //process.env.REACT_APP_SERVER_URL + "/show/next/autorized/officer/pf";
        process.env.REACT_APP_SERVER_URL + api.officerDropdownUrl;

      // "/dropdown/for/adv/pf/claim/aao/to/ids";
      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      // const options = data.map((option) => ({
      //   id: option.toOfficerLogin,
      //   title: `${option.officerName} (${option.toOfficerLogin})`,
      // }));
      // setAssignToOption(options); // Assuming setAssignToOption is a state setter function
      // Filter officers based on the ID starting with "AGM" and "IDS"
      const agmOfficers = data
        .filter((officer) => officer.toOfficerLogin.startsWith("IPDS" || "CA"))
        .map(({ toOfficerLogin, officerName }) => ({
          id: toOfficerLogin,
          title: `${officerName} (${toOfficerLogin})`,
        }));
      const idsOfficers = data
        .filter((officer) => officer.toOfficerLogin.startsWith("RH"))
        .map(({ toOfficerLogin, officerName }) => ({
          id: toOfficerLogin,
          title: `${officerName} (${toOfficerLogin})`,
        }));

      // Set the respective state variables
      setAssignToOption(idsOfficers);
      setAssignToChOption(agmOfficers);
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchAssignOfficer();
  }, []);

  const fetchClaimDetailAdv = async () => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    // const url = process.env.REACT_APP_SERVER_URL + "/show/pf/claim/on/claimid";
    const url = process.env.REACT_APP_SERVER_URL + api.claimUrl;

    const formData = new FormData();
    formData.append("claimId", claimId);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;

          setPfClaimData(data);
          fetchMemberDetail(data.claimDetails[0].cmpfAccNo);
          fetchDocuments(data.claimDetails[0].claimantId);
          fetchDetailsForDpo();

          return;
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
      });
  };

  const fetchAllClaimDetail = () => {
    fetchClaimDetailAdv();
  };

  const fetchDocuments = async (claimantId) => {
    try {
      // const url = `${process.env.REACT_APP_SERVER_URL}/show/pf/claim/uploaded/documents/claimid`;
      const url = process.env.REACT_APP_SERVER_URL + api.docsUrl;

      const formData = new FormData();
      formData.append("claimId", claimId);
      formData.append("claimantId", claimantId);

      const config = {
        headers: {
          // Include the Authorization header if req uired
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(url, formData, config);

      setIsLoadingDoc(false);
      setIsFailedDoc(false);

      if (response.status === 200) {
        const data = response.data.data;
        setDocuments(data);
      } else {
        setIsFailed(true);
      }
    } catch (error) {
      // Corrected syntax for the catch block
      setIsLoading(false);
      setIsFailed(true);
    }
  };

  useEffect(() => {
    fetchAllClaimDetail();
  }, []);

  //! adding DPO AND PO
  const [combinedData, setCombinedData] = useState([]);
  const { claimDetails } = pfClaimData;
  let draftPayOrder = claimDetails[0]?.draftPayOrder; // Use optional chaining here
  let payOrder = claimDetails[0]?.payOrder;
  // Combine data from the two APIs
  useEffect(() => {
    if (draftPayOrder) {
      const updatedCombinedData = [
        ...documents,
        {
          docTypeDesc: claimType === "PF" ? "DPO" : "PENSET",
          uploadedDoc: draftPayOrder,
        },
      ];

      // Assuming advClaimData.advClaim.purchaseOrder is the document from the second API
      if (payOrder) {
        updatedCombinedData.push({
          docTypeDesc: claimType === "PF" ? "PO" : "PPO",
          uploadedDoc: payOrder,
        });
      }

      setCombinedData(updatedCombinedData);
    }
  }, [documents, pfClaimData]);
  // const onChange = (e) => {
  //   const value = e.target.value.toUpperCase().trim();
  //   setAccNo(value);
  // };

  // cmpf account number is fetched on useEffect with a delay of 3sec to avoid multiple api hits
  const onChange = (e) => {
    setClaim({ ...claim, [e.target.name]: e.target.value });
  };

  /// ******** on submit function
  // const objectTobeSent = {
  //   fromLoginId: loginId,
  //   remarks: claim.remarks,
  //   toLoginId: claim.assignTo,
  //   claimId: claimId,
  //   status: true,
  //   poDate: "",
  //   payOrderNo: "",
  //   payOrderDocument: "",
  // };

  let objectTobeSent = {};
  if (claimType === "PF") {
    objectTobeSent = {
      remarks: claim.remarks,
      toLoginId: claim.assignTo,
      fromLoginId: loginId,
      claimId: claimId,
      status: true,
      poDate: "",
      payOrderNo: "",
      payOrderDocument: "",
    };
  } else if (claimType === "PN") {
    objectTobeSent = {
      claimId: claimId,
      fromOffcrLoginId: loginId,
      toOffcrLoginId: claim.assignTo,
      remarks: claim.remarks,
    };
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (claimType === "PF") {
      if (!objectTobeSent.toLoginId) {
        // Show message in dialog: "Please select an officer to assign"
        //alert("Please select an officer to assign");
        setIsError(true);
        setErrorMessage("Please Select an Officer to Assign.");
        return;
      }
    } else if (claimType === "PN") {
      if (!objectTobeSent.toOffcrLoginId) {
        // Show message in dialog: "Please select an officer to assign"
        //alert("Please select an officer to assign");
        setIsError(true);
        setErrorMessage("Please Select an Officer to Assign.");
        return;
      }
    }
    setIsSubmitting(true);

    const url = process.env.REACT_APP_SERVER_URL + api.forwardUrl;
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSent, { headers })
      .then(function (response) {
        setIsSubmitting(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Forwarded Successfully.");
      })
      .catch((error) => {
        setIsError(true);
        setIsSubmitting(false);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
        console.log("error on submit", error);
      });
  };

  /// ******** on submit to ch function

  const objectTobeSentToCh = {
    fromLoginId: loginId,
    remarks: claim.remarks,
    toLoginId: claim.assignTo,
    claimId: claimId,
    status: false,
    poDate: "",
    payOrderNo: "PONPF1000005",
    payOrderDocument: "abc",
  };

  const handleApprove = (e) => {
    e.preventDefault();

    if (!objectTobeSentToCh.toOffcrId) {
      // Show message in dialog: "Please select an officer to assign"
      //alert("Please select an officer to assign");
      setIsError(true);
      setErrorMessage("Please Select an Officer to Assign.");
      return;
    }
    setIsSubmitting(true);

    const url =
      process.env.REACT_APP_SERVER_URL + "/forward/advance/pf/claim/BO/to/CA";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSentToCh, { headers })
      .then(function (response) {
        setIsSubmitting(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Forwarded Successfully.");
      })
      .catch((error) => {
        setIsError(true);
        setIsSubmitting(false);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
        console.log("error on submit", error);
      });
  };

  const handleReturnToRodaOk = () => {
    handleReturnToRodaClaim();
  };
  /// ******** on Return to RODA function

  const objectTobeSentOnReturnToRoda = {
    fromLoginId: loginId,
    remarks: claim.remarks,
    claimId: claimId,
    status: true,
  };

  const handleReturnToRodaClick = (e) => {
    e.preventDefault();

    if (!claim.remarks.trim()) {
      // Show message in dialog: "Please enter remarks"
      // alert("Please enter remarks");
      setIsError(true);
      setErrorMessage("Please enter Remarks.");
      return;
    }

    setIsReturning(true);
    setIsReturnRodaClicked(true);
  };
  const handleReturnToRodaClaim = (e) => {
    // e.preventDefault();

    const url = process.env.REACT_APP_SERVER_URL + api.returnUrlRoda;
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSentOnReturnToRoda, { headers })
      .then(function (response) {
        setIsReturning(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Return Successfully.");

        //console.log("Claim Returned Successfully");
        setIsReturnRodaClicked(false);
      })
      .catch((error) => {
        console.log("error on submit", error);
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
      });
  };

  /// ******** on Return AAO function

  const objectTobeSentOnReturn = {
    fromLoginId: loginId,
    remarks: claim.remarks,
    claimId: claimId,
    status: false,
  };

  const handleReturnClick = (e) => {
    e.preventDefault();

    if (!claim.remarks.trim()) {
      // Show message in dialog: "Please enter remarks"
      // alert("Please enter remarks");
      setIsError(true);
      setErrorMessage("Please enter Remarks.");
      return;
    }

    setIsReturning(true);
    setIsReturnClicked(true);
  };
  const handleReturnClaim = (e) => {
    // e.preventDefault();

    // const url =
    //   process.env.REACT_APP_SERVER_URL + "/verification/pf/claim/officers";
    const url = process.env.REACT_APP_SERVER_URL + api.returnUrlAao;
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSentOnReturn, { headers })
      .then(function (response) {
        setIsReturning(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Return Successfully.");
        setIsReturnClicked(false);
      })
      .catch((error) => {
        console.log("error on submit", error);
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
      });
  };

  ///****************integration started********************* */

  const fetchMemberDetail = async (accNo) => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + "/member/details/for/pf";
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          if (data.length !== 0) {
            setMemberData(data[0]);
            setIsMemberFound("true");
            fetchNomineeDetail(data[0].cmpfAccNo);
          }
          setIsMemberFound("false");
          return;
        }
        setIsFailed(true);
        setIsMemberFound("false");
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        setIsMemberFound("false");
      });
  };

  /***** function to get all nominee details**************** */
  const fetchNomineeDetail = async (accNo) => {
    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");

    const url = process.env.REACT_APP_SERVER_URL + "/get/all/nominee/details";
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo); // check what formdata is to be sent
    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsLoading(false);
        setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          if (data.length !== 0) {
            setNomineeDetails(data);
          }

          return;
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
      });
  };
  /// error alert handler
  const handleErrorAlert = () => {
    setIsError(false);
  };

  const handleSuccess = () => {
    if (claimType === "PF") {
      navigate("/bo/pfset");
    } else if (claimType === "PN") {
      navigate("/bo/penset");
    }
  };

  const handleCancelReturn = () => {
    setIsReturnClicked(false);
  };
  const handleReturnOk = () => {
    handleReturnClaim();
  };

  // conditions fro display the dropdown
  const handleProcessOk = (e) => {
    setShowingDropdown(1);
  };

  const handleApproveOk = (e) => {
    setShowingDropdown(2);
  };

  const handleCancelReturnroda = () => {
    setIsReturnRodaClicked(false);
  };

  return (
    <div>
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error!"
        body={errorMessage}
        buttonName="Ok"
      />
      <SucessAlert
        handleOk={handleSuccess}
        open={isSuccess}
        title="Success"
        body={successMessage}
        buttonName="OK"
      />
      <ConfirmationDialogue
        handleOk={handleReturnOk}
        handleCancel={handleCancelReturn}
        open={isReturnClicked}
        title="Confirm Return"
        body="Are you sure you want to return this claim ?"
        buttonName="Confirm"
      />
      <ConfirmationDialogue
        handleOk={handleReturnToRodaOk}
        handleCancel={handleCancelReturnroda}
        open={isReturnRodaClicked}
        title="Confirm Return"
        body="Are you sure you want to return this claim ?"
        buttonName="Confirm"
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "10px" }}>
            {isFailed || isFailedDoc ? (
              <Reloader refreshHandler={fetchAllClaimDetail} />
            ) : (
              <>
                {isLoading || isLoadingDoc ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        <TitleText title={`${title} Settlement `} />
                      </Grid>
                    </Grid>
                    <FormDivider />
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={1} mb={3}>
                        <BackButton />
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="start"
                        rowGap={3}
                      >
                        {/* Member Details*/}
                        {/* section 1  */}
                        <Paper
                          elevation={3}
                          sx={{ padding: "3rem", borderRadius: "10px" }}
                        >
                          <Grid item xs={12}>
                            <MemberDetails member={memberData} />
                          </Grid>
                        </Paper>
                        {/* claim Details*/}
                        {/* section 2  */}
                        <Paper
                          elevation={3}
                          sx={{ padding: "3rem", borderRadius: "10px" }}
                        >
                          <Grid item xs={12}>
                            <ClaimDetailsPf pfclaimdetails={pfClaimData} />
                          </Grid>
                        </Paper>
                        {/* Document Details*/}
                        {/* section 3  */}
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={12}>
                            <Paper
                              elevation={3}
                              sx={{ padding: "3rem", borderRadius: "10px" }}
                            >
                              <Grid item xs={12}>
                                {draftPayOrder ? (
                                  <ViewDocuments documents={combinedData} />
                                ) : (
                                  <ViewDocuments documents={documents} />
                                )}
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>

                        {/* ************************************ Remarks ***************************************** */}
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={12}>
                            <Paper
                              elevation={3}
                              sx={{ padding: "2rem", borderRadius: "10px" }}
                            >
                              <SubtitleText title="Remarks" />
                              <Grid item xs={12} sm={6} md={8}>
                                <CustomTextArea
                                  {...remarks}
                                  onchange={onChange}
                                />
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>
                        {/* *************** process and return to aao ********* */}
                        <Grid container justifyContent="end">
                          <Grid item xs={12}>
                            <Grid
                              container
                              justifyContent="end"
                              alignItems="center"
                              columnGap={2}
                            >
                              <Grid item md={2}>
                                <Button
                                  onClick={handleReturnClick}
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  // disabled={isReturnClicked}
                                  fullWidth
                                >
                                  Return to aao
                                </Button>
                              </Grid>
                              <Grid item md={2.5}>
                                <Button
                                  onClick={handleReturnToRodaClick}
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  // disabled={isReturnClicked}
                                  fullWidth
                                >
                                  Return to roda
                                </Button>
                              </Grid>
                              <Grid item md={2}>
                                <Button
                                  onClick={handleProcessOk}
                                  variant="contained"
                                  color="success"
                                  type="submit"
                                  // disabled={isReturnClicked}
                                  fullWidth
                                >
                                  Process
                                </Button>
                              </Grid>
                              <Grid item md={2}>
                                <Button
                                  onClick={handleApproveOk}
                                  variant="contained"
                                  color="success"
                                  type="submit"
                                  //style={{ marginLeft: 8 }}
                                  // disabled={isSubmitting}
                                  fullWidth
                                >
                                  Approve
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        {showingDropdown === 1 && (
                          <Grid
                            container
                            justifyContent="end"
                            alignItems={"center"}
                            spacing={2}
                          >
                            <Grid item xs={12} sm={4}>
                              <CustomDropdown
                                {...assignTo}
                                onchange={onChange}
                              />
                            </Grid>
                            <Grid item md={1}>
                              <Button
                                onClick={handleSubmit}
                                variant="contained"
                                color="success"
                                type="submit"
                                disabled={isSubmitting}
                                fullWidth
                              >
                                {isSubmitting ? <CircularProgress /> : "OK"}
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                        {showingDropdown === 2 && (
                          <Grid
                            container
                            justifyContent="end"
                            alignItems={"center"}
                            spacing={2}
                          >
                            <Grid item xs={12} sm={4}>
                              <CustomDropdown
                                {...assignToCh}
                                onchange={onChange}
                              />
                            </Grid>
                            <Grid item md={1}>
                              <Button
                                onClick={
                                  claimType === "PF"
                                    ? handlePreviewDpoPF
                                    : handlePreviewDpoPen
                                }
                                variant="contained"
                                color="success" // Change "success" to "primary" if you want the primary color
                                type="submit"
                                disabled={isSubmitting}
                                fullWidth
                              >
                                {isSubmitting ? <CircularProgress /> : "OK"}
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default ViewPfClaimBo;
