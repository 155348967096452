import React from "react";
import { Typography } from "@mui/material";
const AddFamilyMember=()=>{
    return(<div id="add-family-member">
        <Typography variant="h6">ADD FAMILY MEMBER</Typography>
    <Typography varient="body1" align="justify">
        <p>To be implemented in Phase - II</p>
    </Typography>
    </div>);
}
export default AddFamilyMember;