// default model objects and mandatory fields declaration goes here

// export const familyMemberModel = {
//   family_mem_id: "",
//   member_id: "",
//   verified_status: false,
//   title_id: "1",
//   firstName: "",
//   middleName: "",
//   lastName: "",
//   relation_id: "1",
//   address_l1: "",
//   fath_hus_name: "",
//   fath_hus_flag: "Father",
//   date_of_birth: "",
//   pan_no: "",
//   aadhaar_no: "",
//   fam_aadhaar_id: "",
//   gender: "Male",
//   marital_status: "Single",
//   religion_id: "1",
//   mobile_no: "",
//   land_no: "",
//   email_id: "",
//   pf_flag: "No",
//   pension_flag: "No",
//   pf_percentage: "0",
//   pension_percentage: "0",
// };
export const familyMemberModel = {
  familyMemId: "", //bigInt
  member_id: "", //bigInt
  verified_status: false,
  titleId: "",
  titleName: "",
  firstName: "",
  middleName: "",
  lastName: "",
  relationId: "",
  relationName: "",

  fathHusName: "",
  // fath_hus_flag: "",
  dateOfBirth: "",
  panNo: "", //bigInt
  aadhaarNo: "", //bigInt
  fam_aadhaar_id: "",
  gender: "",
  maritalStatus: "",
  religionId: "",
  religionName: "",
  mobileNo: "",
  landNo: "", // numeric(12)
  emailId: "",
  // pf_flag: "",

  // pension_flag: "",
  // pf_percentage: "",
  // pension_percentage: "",
  pfNominee: false,
  pensionNominee: false,
  pfNomShare: "",
  penNomShare: "",
  penNomSharePerc: "",
  pfNomSharePerc: "",

  addressL1: "",
  addressL2: "",
  city: "",
  village: "",
  pincode: "",
  district: "",
  country: "",
  state: "",
};

export const familyMandatoryFields = [
  "title_id",
  "firstName",
  "relation_id",
  "address",
  "gaurdian",
  "dob",
  "pan",
  "aadhar",
  "mobile",
];

// export const memberModel = {
//   member_id: "",
//   cmpf_acc_no: "",
//   title_id: "1",
//   firstName: "",
//   middleName: "",
//   lastName: "",
//   father_hus_name: "",
//   father_hus_flag: "Father",
//   marital_status: "Single",
//   religion_id: "1",
//   gender: "Male",
//   date_of_birth: "",
//   address_l1: "",
//   mobile_no: "",
//   land_no: "",
//   email_id: "",
//   pan_no: "",
//   aadhaar_no: "",
//   cmpf_acc_no: "",
//   employee_id: "",
//   colliery_id: "",
//   colliery_name: "",
//   coal_company_name: "",
//   joining_date: "",
//   pf_start_date: "",
//   pf_end_date: "",
//   retirement_date: "",
//   ifsc_pf: "",
//   ifsc_pen: "",
//   bank_acc_no_pen: "",
//   bank_acc_no_pf: "",
// };
export const memberModel = {
  member_id: "",
  cmpfAccNo: "",
  titleName: "",
  titleId: "",
  firstName: "",
  middleName: "",
  lastName: "",
  fatherHusbandName: "",
  father_hus_flag: "",
  maritalStatus: "",
  religionName: "",
  gender: "",
  dateOfBirth: "",

  pf_nom_share: "",
  pen_nom_share: "",
  uda_login_id: "",

  mobileNo: "",
  land_no: "",
  employeeId: "",
  panNo: "",
  aadhaarNumber: "",
  cmpf_acc_no: "",
  employeeId: "",
  colliery_id: "",
  colliery_name: "",
  coal_company_name: "",
  joiningDate: "",
  pfStartDate: "",
  pfEndDate: "",
  retirement_date: "",

  unitName: "",
  email: "",
  landNo: "",
  name: "",

  addressL1: "",
  addressL2: "",
  city: "",
  district: "",
  state: "",
  pincode: "",
  village: "",
  country: "",

  ifscPf: "",
  bankNamePf: "",
  bankAccNoPf: "",
  addressL1Pf: "",
  addressL2Pf: "",
  cityPf: "",
  districtPf: "",
  statePf: "",
  pincodePf: "",
  ifscPension: "",
  bankAccNoPension: "",
  bankNamePension: "",
  addressL1Pension: "",
  addressL2Pension: "",
  cityPension: "",
  districtPension: "",
  statePension: "",
  pincodePension: "",
};

export const memberData = {
  titleName: "",
  firstName: "",
  middleName: "",
  lastName: "",
  fatherHusbandName: "",
  gender: "",
  maritalStatus: "",
  religionName: "",
  dateOfBirth: "",
  addressL1: "",
  addressL2: "",
  mobileNo: "",
  landNo: "",
  email: "",
  panNo: "",
  aadhaarNumber: "",
  titleId: "",
  religionId: "",
  aadharId: "",
  updateRemark: "",
  panId: "",
  dob: "",
  pfNomineeDecl: "",
  pensionNomineeDecl: "",
  village: "",
  city: "",
  district: "",
  pincode: "",
  country: "",
  state: "",
  penNomShare: "",
  pensionNominee: "",
  pfNomShare: "",
  pfNominee: "",
  nomVerifyStatus: "",
  nomVerifyRemarks: "",
  fathHusFlag: "",
  consentStatus: "",
  employeeId: "",
  unitId: "",
  unitName: "",
  name: "",
  joiningDate: "",
  pfStartDate: "",
  pfEndDate: "",
  ifscPf: "",
  bankAccNoPf: "",
  ifscPen: "",
  bankAccNoPen: "",
  memProfId: "",
  cmpfAccNo: "",
  pfBankId: "",
  bankNamePf: "",
  pfBankIfsc: "",
  pfAddress: "",
  penBankId: "",
  bankNamePen: "",
  penBankIfsc: "",
  penAddress: "",
  addressL1Pf: "",
  addressL2Pf: "",
  cityPf: "",
  districtPf: "",
  statePf: "",
  pincodePf: "",
  addressL1Pension: "",
  addressL2Pension: "",
  cityPension: "",
  districtPension: "",
  statePension: "",
  pincodePension: "",
  memberId: "",
  unitCode: "",
};
export const memberMandatoryFields = ["firstName", "gender"];

export const roEnrolmentModel = {
  ro_name: "",
  ro_code: "",
  ro_head_name: "",
  ro_head_email: "",
  no_name: "",
  no_designation: "",
  address_l1: "",
  address_l2: "",
  city: "",
  state: "",
  pincode: "",
  off_email: "",
  off_contact_no: "",
  mob_no: "",
};

export const roEnrolmentMandatoryFields = [
  "ro_name",
  "ro_code",
  "off_email",
  "address_l1",
];

export const ccEnrolmentModel = {
  cc_name: "",
  cc_code: "",
  cc_head_name: "",
  cc_head_email: "",
  cc_rep_name: "",
  cc_rep_designation: "",
  address_l1: "",
  address_l2: "",
  no_of_areas: "",
  city: "",
  state: "",
  pincode: "",
  off_email: "",
  off_contact_no: "",
  mob_no: "",
};

export const ccEnrolmentMandatoryFields = [
  "cc_name",
  "cc_code",
  "off_email",
  "mob_no",
  "address_l1",
  "city",
  "pincode",
  "state",
];

export const officerEnrolModel = {
  login_id: "",
  password: "",
  conf_password: "",
  officer_name: "",
  designation: "",
  actor: "",
  actor_name: "",
  actor_code: "",
  email_id: "",
  mob_no: "",
};

export const officerEnrolMandatoryFields = [];

export const newClaimsModel = {
  uda_login_id: "",
  cmpf_acc_no: "",
  name_addhar: "",
  name_pan: "",
  name_bank: "",
  name_addhar_match: "",
  name_pan_match: "",
  dob_aadhar: "",
  dob_pan: "",
  sfwh_name: "",
  sfwh_relation_type: "",
  pf_clain_type_id: "",
  delclaration_ude: "",
  pf_claim_status_id: "",
};

export const newClaimsMandatoryFields = [];

export const advData = ["ro_name", "ro_code", "off_email", "address_l1"];
