import React, { useState } from "react";
import { Grid, Paper, Typography, Button, Divider, CircularProgress } from "@mui/material";
import SubtitleText from "../../FormComponent/SubtitleText";
import ValueText from "../../FormComponent/ValueText";
import LabelText from "../../FormComponent/LabelText";
import TitleText from "../../FormComponent/TitleText";
import CaptionText from "../../FormComponent/CaptionText";
import CustomTextField from '../../FormComponent/CustomTextField';
import CustomTextArea from "../../FormComponent/CustomTextArea";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { formPatternValidation } from "../../Utility/UtilityMethods";
import axios from "axios";
import  './../LoginPages/OfficerBar.css';


function Contact(props) {


  
  const [contactData, setContactData] = useState({
    cmpf_acc_no: '',
    name: '',
    cc_name: '',
    place_of_post: '',
    ro_name: '',
    mob_no: '',
    description: '',
  });

  const [errorAlert, setErrorAlert] = useState(false);

  const mandatory = ['cmpf_acc_no', 'name', 'cc_name', 'place_of_post', 'ro_name', 'mob_no', 'description'];
  const [isSending, setIsSending] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");

    const cmpf_acc_no = {
    name: "cmpf_acc_no",
    type: "text",
    helperText: '', // pass sapce character
    defaultValue: contactData.cmpf_acc_no,
    placeholder: 'CMPF Account Number',
    errorMessage:
      "Required",
    // label: "CMPF Account Number",
      pattern: "^[a-zA-Z0-9/]{1,20}$",
    required: mandatory.includes('cmpf_acc_no'),
    disabled: false,
    color: 'white'
  };


  const ro_name = {
    name: "ro_name",
    type: "text",
    helperText: 'Eg: KTS, NGP, GDK..',
    defaultValue: contactData.ro_name,
    placeholder: 'Name of Regional Office',
    errorMessage:
      "Required",
    // label: "Name of Regional Office",
    pattern: "[a-zA-Z]$",
    required: mandatory.includes('ro_name'),
    disabled: false,
    color: 'white'
  };

  const mob_no = {
    name: "mob_no",
    type: "text",
    helperText: '', // pass sapce character
    defaultValue: contactData.mob_no,
    placeholder: 'Mobile Number',
    errorMessage:
      "Required",
    // label: "Mobile Number",
    pattern: "^[0-9]{10}$",
    required: mandatory.includes('mob_no'),
    disabled: false,
    color: 'white'
  };

  const cc_name = {
    name: "cc_name",
    type: "text",
    helperText: 'Eg: BCCL, ECL, MCL..',
    defaultValue: contactData.cc_name,
    placeholder: 'Name of Coal Company',
    errorMessage:
      "Required",
    // label: "Name of Coal Comapany",
    pattern: "[a-zA-Z]$",
    required: mandatory.includes('cc_name'),
    disabled: false,
    color: 'white'
  };

  const name = {
    name: "name",
    type: "text",
    helperText: '', // pass sapce character
    defaultValue: contactData.name,
    placeholder: 'Name of the Member',
    errorMessage:
      "Required",
    // label: "Name",
    pattern: "[a-zA-Z]$",
    required: mandatory.includes('name'),
    disabled: false,
    color: 'white'
  };


  const place_of_post = {
    name: "place_of_post",
    type: "text",
    helperText: '', // pass sapce character
    defaultValue: contactData.place_of_post,
    placeholder: 'Place of Posting',
    errorMessage:
      "Required",
    // label: "Place of Posting",
    pattern: "[a-zA-Z]$",
    required: mandatory.includes('place_of_post'),
    disabled: false,
    color: 'white'
  };

  const description = {
    name: "description",
    placeholder: "Write your message...*",
    defaultValue: contactData.description,
    errorMessage: "",
    // label: "Message",
    pattern: "",
    multiline: true,
    minrows: 4,
    required: mandatory.includes('description'),
    disabled: false,
    color: 'white'
  };






  const onChange = (event) => {
    setContactData({ ...contactData, [event.target.name]: event.target.value });
  }







  const sendData = async () => {
    setIsSending(true);

    const url = process.env.REACT_APP_SERVER_URL + '/griveancemail';

    const requestBody = {
      cmpfAccNo: contactData.cmpf_acc_no,
      name: contactData.name,
      coalCompany: contactData.cc_name,
      posting: contactData.place_of_post,
      roName: contactData.ro_name,
      mobile: parseInt(contactData.mob_no),
      description: contactData.description,
    };

    console.log("input", contactData);
    console.log("passed data", requestBody);

    try {
      setIsSending(false);
      const response = await axios.post(url, requestBody); // Use axios.post instead of fetch
      const data = response.data;
      setContactData({
        cmpf_acc_no: '',
        name: '',
        cc_name: '',
        place_of_post: '',
        ro_name: '',
        mob_no: '',
        description: '',
      });
      setMessage("Your message has been sent!!");
      setErrorAlert(true);
      console.log(data);
    } catch (error) {
      setIsSending(false);
      setMessage("Your Message Failed!. Try Again");
      setErrorAlert(true);
    }
  };




  const validatePattern = (event) => {
    let flag = true;
    if (!formPatternValidation(contactData.cmpf_acc_no, cmpf_acc_no.pattern) || !formPatternValidation(contactData.mob_no, mob_no.pattern)) {
      
      flag = false;
    }
   
    return flag;
  }


  const validRequiredFilds = () => {
    let flag = true;
    for (let key in contactData) {
      if (mandatory.includes(key)) {
        if (!contactData[key]) {
          flag = false;
        }
      }
    }
    return flag;
  }

  const handleSend = (event) => {
    event.preventDefault();
    // validatePattern();
    if (!validRequiredFilds()) {
      setMessage("Kindly fill all fields!!");
      setErrorAlert(true);
      return;
    }

    if (!validatePattern()) {
      setMessage("Kindly correct your information!!");
      setErrorAlert(true);
      return;
    }
    
    sendData();
  }

  const handleErrorAlertOk = () => {
    setErrorAlert(false);
  }


  return (
<>
  <div className="pattern-overlay"></div>
    <Grid
      container
      justifyContent="center"
      spacing={2}
      columnGap={2}
      sx={{ marginTop: "20px", wordWrap: 'break-word' }}
    >
      <Grid item>
        <ErrorAlert
          handleOk={handleErrorAlertOk}
          open={errorAlert}
          title="Alert"
          body={message}
          buttonName="Ok"
        />
      </Grid>

      <Grid item xs={11} sm={11} md={10} lg={8}>
        {/* <Paper elevation={3} sx={{ borderRadius: "8px", padding: '3rem' }}> */}
          <Grid
            container
            justifyContent="flex-start"
            alignContent="center"
            columnGap={2}
            rowGap={2}
          >

            <Grid item xs={12} sm={12}>

            <Paper elevation={3} sx={{ padding: '2rem', background:'#8858b1' }}>

                <Grid container justifyContent='space-between' alignItems='flex-start' rowGap={2}>


                  <Grid item xs={12} sm={12}>
                    <TitleText title="Write to us" color="white"/>
                  <div style={{ width: '100%', backgroundColor: 'white', height: '2px', marginBottom: '8px', marginTop:'10px' }} />

                  </Grid>

                    {/* <Grid item xs={12} sm={12}>

                    </Grid> */}

                  {/* section 1 column 1 - regional*/}
                  <Grid item xs={12} sm={6}>
                  <Grid container justifyContent='space-between' alignItems='flex-start' rowGap={2}>

                      {/* row  */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title="Name" color='white' />
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <CustomTextField {...name} onchange={onChange} />
                      </Grid>

                      {/* row  */}
                      <Grid item xs={12} sm={4}>
                      <LabelText title="Place of Posting" color='white' />
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <CustomTextField {...place_of_post} onchange={onChange} />
                      </Grid>

                      {/* row  */}
                      <Grid item xs={12} sm={4}>
                      <LabelText title="Coal Company" color='white' />
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <CustomTextField {...cc_name} onchange={onChange} />
                      </Grid>


                    </Grid>
                  </Grid>

                  {/* section 1 column 2 */}
                  <Grid item xs={12} sm={5}>
                    <Grid container justifyContent='space-between' alignItems='flex-start' rowGap={2} columnGap={2}>

                      {/* row  */}
                      <Grid item xs={12} sm={4}>
                      <LabelText title="CMPF A/C No." color='white' />
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <CustomTextField {...cmpf_acc_no} onchange={onChange} />
                      </Grid>


                      {/* row  */}
                      <Grid item xs={12} sm={4}>
                      <LabelText title="Mobile No." color='white' />
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <CustomTextField {...mob_no} onchange={onChange} />
                      </Grid>

                      {/* row  */}
                      <Grid item xs={12} sm={4}>
                      <LabelText title="Regional Office" color='white' />
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <CustomTextField {...ro_name} onchange={onChange} />
                      </Grid>

                    </Grid>
                  </Grid>


                <Grid item xs={12} sm={2.5}>
                  <LabelText title="Write Your Message" color='white' />
                </Grid>
                  <Grid item xs={12} sm={9.5}>
                    <CustomTextArea {...description} onchange={onChange} />
                  </Grid>


                  <Grid item xs={12} sm={12}>
                  <Button variant="contained" sx={{ background: '#248032' }} onClick={handleSend} disabled={isSending}>
                    <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: '8px' }} />
                    {isSending ? (
                      <CircularProgress sx={{ color: 'white' }} />
                    ) : (
                        'SEND'
                    )}
                  </Button>
                  </Grid>

                </Grid>

              </Paper>



            </Grid>










            <Grid item xs={12} sm={12} >
              <Paper elevation={3} sx={{ padding: '2rem' }}>
                <Grid Container justifyContent="center" alignItems="center" >
                  {/* location info */}
                  <Grid item xs={12}>
                    <TitleText title="Location" />
                  </Grid>
                  <Grid item xs={12}>
                    <ValueText title="CMPFO Headquarters, Police Line, Hirapur, Dhanbad, Jharkhand 826014" />
                  </Grid>

                  <Grid item xs={12} mt={2} sx={{ backgroundColor: '#EEEEEE' }}>
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        paddingTop: "40%", // 9:16 aspect ratio
                      }}
                    >
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3650.320421587475!2d86.44064597419158!3d23.80720238659545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f6bc99f1400001%3A0x499cb87a3846936a!2sCoal%20Mines%20Provident%20Fund%20Organisation!5e0!3m2!1sen!2sin!4v1689325235126!5m2!1sen!2sin"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          border: 0,
                        }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Map"
                      ></iframe>
                    </div>
                  </Grid>

                </Grid>
              </Paper>
            </Grid>

          </Grid>
        {/* </Paper> */}
      </Grid >
    </Grid >
    </>
  );
}

export default Contact;
