import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
const Contact=()=>{
    return(
      <>
    <div id="contact">
   
  
      <Typography variant="h6">CONTACT</Typography>
      <Typography variant="body1" align='justify'>
       <p>
       The contents of this website are maintained by CMPFO. In case of any ambiguity or doubts, users are advised to contact on below email addresses.<br />
       </p>

      </Typography>
      <Typography component="span" sx={{ color: 'skyblue' }}>
        For Grievances:
      </Typography>{' '}
       
      <Link href="https://cares-cmpfo.in/contact" target="_blank">grievance@cmpfo.gov.in</Link> <br /> 
      <Typography component="span" sx={{ color: 'skyblue' }}>
      For Other Queries/Information:
    </Typography>{' '}
      <Link href="mailto:support@cmpfo.gov.in">support@cmpfo.gov.in</Link>
     
     </div> 
     </>
    );
}
export default Contact;