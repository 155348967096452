import React from "react";
import MemberLoginFirst from "./MemberLoginPages/MemberLoginFirst";
import MemberLoginGuidelines from "./MemberLoginPages/MemberLoginGuidelines";
const MemberLogin=()=>{
    return(
        <div id="member-login">
    <MemberLoginFirst />
    <MemberLoginGuidelines />
    </div>);
}
export default MemberLogin;