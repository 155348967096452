import React from "react";
import { useState } from "react";
import { CircularProgress, TextField } from "@mui/material";
import { Container } from "@mui/material";
import { Button } from "@mui/material";
import { Box } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import IconButton from "@mui/material/IconButton";
import { useForm } from "react-hook-form";
import Typography from "@mui/material/Typography";
//import reCAPTCHA from "react-google-recaptcha";
//import ReCaptchaV3 from "./ReCaptchaV3";
// import Axios from "axios";
//import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { keyframes } from "@mui/material";
import { useEffect } from "react";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import "../../styles/stylesheet.css";
// import { useHistory } from 'react-router-dom';
import memberImage from "../../images/member-enrol.png";
import { CaptchaProvider } from "../captcha/CaptchaProvider";
import Form from "../captcha/CaptchaForm";
import otpImage from "../../images/otp-mobile.png";
import registerImage from "../../images/register.png";
// import swal from 'sweetalert'
import { Modal } from "@mui/material";
import axios from "axios";
import qs from "qs";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import DangerousRoundedIcon from "@mui/icons-material/DangerousRounded";
import { green } from "@mui/material/colors";
import './EnrolmentBg.css'

const useStyles = makeStyles({

  imageContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "1rem",
  },
  responsiveImage: {
    width: "50px",
  },
  //otp
  otpInput: {
    // width: 40,
    width: "100%",
    maxWidth: 60,
    textAlign: "center",
    "& input": {
      textAlign: "center",
    },
  },
  modalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "90vh",
  },
});

function Member(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSendOtpDisabled, setIsSendOtpDisabled] = useState(false);
  //control member form and otp form View
  const [isMemberFormEnabled, setIsMemberFormEnabled] = useState(true);
  const navigate = useNavigate();
  //Member-Component------------------------------------------------------------
  const classes = useStyles();
  const [formData, setFormData] = useState({});
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    getValues,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      cmpf_account_number: formData.cmpf_account_number,
      password: "",
      confirm_password: "",
    },
  });
  //watch - used for onchange result
  const password = watch("password");
  const cmpfAccountNumber = watch("cmpf_account_number", "");

  const [accnumber, setaccnumber] = useState("");
  const [hello, setHello] = useState("");
  const [reachMax, setReachMax] = useState(false);

  useEffect(() => {
    if (cmpfAccountNumber.length <= 20) {
      setaccnumber(cmpfAccountNumber);
    }
  }, [cmpfAccountNumber]);

  // const [submitMessage, setSubmitMessage] = useState("");
  // password validations
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const validateConfirmPassword = (value) => {
    if (value !== password) {
      return "Passwords do not match";
    }
  };
  /*
    Captcha Validation Check
    If verified successfully it will change the state to true through handleVerifyCaptchaEnabled() which is calling inside handleSubmit function of
    CaptchaFor.jsx
    */
  const [verifyCaptcha, setVerifyCaptcha] = useState(false);

  const handleVerifyCaptchaEnabled = () => {
    setVerifyCaptcha(true);
  };
  const handleVerifyCaptchaDisabled = () => {
    setVerifyCaptcha(false);
  };

  /*
    Checking Send OTP Button is Clicked or Not
    Based on that handlesubmit of captchaform will be calling through useeffect hook defined in CaptchaForm.jsx
    */
  const [isSendOTPClicked, setIsSendOTPClicked] = useState(false);

  const disaableOTPClick = () => {
    setIsSendOTPClicked(false);
  };

  // OTP Expired Message
  const [expiredOtpMessage, setExpiredOtpMessage] = useState("");

  // API - Send OTP
  const sendOTPApi = async (cmpfAccountNumber) => {
    try {
      setReachMax(false);
      setIsSendOTPClicked(true);
      setIsLoading(true);
      const data = qs.stringify({
        cmpfoAccNO: cmpfAccountNumber,
      });
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/member/send/otp",
        data,
        config
      );
      console.log(response);
      if (response.data.data.status == true) {
        setIsLoading(false);
        setHello(response.data.message);
        setIsMemberFormEnabled(false); // based on the api response member form will be set to false - otp form will show
        setExpiredOtpMessage(""); // clearing the state value which set the expired otp message when API gives status true
      }
    } catch (error) {
      setHello(error.response.data.error_message);
      setIsLoading(false);
      // setIsSendOTPClicked(false);
      setReachMax(true);
      console.log("true error", error);

      console.log(error);
      if (error.response.data.http_status_code == 429) {
        setExpiredOtpMessage(error.response.data.error_message); // setting the otp expired message

        //
        //setIsMemberFormEnabled(false) // for checking purpose only - here it should be true always
      }
    }
  };

  /* 
    onSubmit() which will call when send otp button is clicked
    */
  const onSubmitOtp = async (data) => {
    setIsSendOtpDisabled(true);
    setIsSendOTPClicked(true); // send otp button is clicked
    setFormData(data); // Setting the form data with CMPFO Account ID, Passowrd & Confirm Password
    // checking if the captcha is verified or not
    // if verified then varifyCaptcha is true through handleVerifyCaptchaEnabled() which is passing as a prop to CaptchaForm.jsx
    console.log(verifyCaptcha);
    if (verifyCaptcha) {
      sendOTPApi(cmpfAccountNumber);
    }
  };

  // if verifycatcha is true then api will call
  // useEffect(() => {
  //     sendOTPApi(cmpfAccountNumber)
  // }, [verifyCaptcha])

  // onclick() of send otp button
  const handleIsSendOTPClicked = () => {
    setIsLoading(true);
    // resetTimerLoading();
    console.log("send otp clicked");
  };

  useEffect(() => {
    console.log("useEff 1");
    setTimeout(() => {}, 500);
  }, []);

  const [isOTPButtonDisabled, setIsOTPButtonDisabled] = useState(true);
  // const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(false)

  // for checking the cmpfo account number is present or not
  const [cmpfoAccountNumberChecked, setCmpfoAccountNumberChecked] =
    useState(false);

  const cmpfAccountNumberValue = watch("cmpf_account_number");
  // const history = useHistory();

  const handleSendOTP = () => {
    // history.push('/send-otp');
  };

  const [isFocused, setIsFocused] = useState(false);

  const otpButtonDisableFuncEnable = () => {
    setIsOTPButtonDisabled(false);
  };
  const otpButtonDisableFuncDisable = () => {
    setIsOTPButtonDisabled(true);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const rollInLeft = keyframes`
    0% {
        -webkit-transform: translateX(-800px) rotate(-540deg);
                transform: translateX(-800px) rotate(-540deg);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateX(0) rotate(0deg);
                transform: translateX(0) rotate(0deg);
        opacity: 1;
      }
    `;

  const flipInHorBottom = keyframes`
    0% {
        -webkit-transform: rotateX(80deg);
                transform: rotateX(80deg);
        opacity: 0;
      }
      100% {
        -webkit-transform: rotateX(0);
                transform: rotateX(0);
        opacity: 1;
      }
`;
  const shakeHorizontal = keyframes`
0%,
100% {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
10%,
30%,
50%,
70% {
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
}
20%,
40%,
60% {
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
}
80% {
  -webkit-transform: translateX(8px);
          transform: translateX(8px);
}
90% {
  -webkit-transform: translateX(-8px);
          transform: translateX(-8px);
}
`;
  //---------------------------------------------------------------------------------------

  //OTP-Component--------------------------------------------------------------------------
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [otpValue, setOtpValue] = useState("");
  const [performAnimation, setPerformAnimation] = useState(false);

  const handleOtpChange = (index, e) => {
    const value = e.target.value;
    if (!isNaN(value)) {
      if (value === "") {
        // Handle backspace/delete key press
        const updatedOtp = [...otp];
        updatedOtp[index] = "";
        setOtp(updatedOtp);
        if (index > 0) {
          const prevSibling = document.querySelector(`#otp-input-${index - 1}`);
          if (prevSibling) {
            prevSibling.focus();
          }
        }
      } else {
        const sanitizedValue = value.replace(/\D/g, "");
        const updatedOtp = [...otp];
        updatedOtp[index] = sanitizedValue.slice(0, 1);
        setOtp(updatedOtp);
        if (value !== "") {
          const nextSibling = document.querySelector(`#otp-input-${index + 1}`);
          if (nextSibling) {
            nextSibling.focus();
          }
        }
      }
      const joinedOtpValue = otp.join("");
      setOtpValue(joinedOtpValue);
    }
  };

  const logOtp = () => {
    // const filledDigits = otp.filter(digit => digit !== '');
    // if (filledDigits.length === otp.length) {
    //     console.log('OTP Value:', otpValue);
    // }
  };

  const [OTP, setOTP] = useState("");
  const [formCount, setFormCount] = useState(0); // to call the useeffect hook when submit button is clicked each time

  const memberEnroll = async (cmpfAccountNumber, password) => {
    try {
      const data = {
        loginId: cmpfAccountNumber,
        password: window.btoa(password),
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/member/enrollment",
        data,
        config
      );
      console.log(response);
      if (response.data.data.status == true) {
        // alert('Member Enrolled')
        setMemberEnrollCardEnable(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const verifyOtp = async (cmpfAccountNumber, OTP) => {
    try {
      const data = qs.stringify({
        cmpfoAccNO: cmpfAccountNumber,
        otp: OTP,
      });
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/member/verify/otp",
        data,
        config
      );
      console.log(response);
      if (response.data.data.status == true) {
        memberEnroll(formData.cmpf_account_number, formData.password);
      }
    } catch (error) {
      setHello(error.response.data.error_message);
      console.log(error);
    }
  };

  const handleMUIForm = () => {
    const filledDigits = otp.filter((digit) => digit !== "");
    if (filledDigits.length === otp.length) {
      // console.log('OTP:', otp.join(''));
      setOTP(otp.join(""));
      setFormCount((prevCount) => prevCount + 1); // Increment formCount
    }
    // props.setIsLoginButtonDisabledGlobal(false)
    // history.push('/muiform')
  };

  const [isInitialRender, setIsInitialRender] = useState(true);
  useEffect(() => {
    console.log("2");
    // if (OTP == '000000') {
    //     console.log(OTP);
    //     console.log(formData);
    //     console.log(cmpfAccountNumber);
    //     verifyOtp(cmpfAccountNumber, OTP);
    // } else {
    //     console.log('otp wrong');
    // }
    if (!isInitialRender && OTP) {
      verifyOtp(cmpfAccountNumber, OTP);
    } else {
      setIsInitialRender(false);
    }
  }, [OTP, formCount, isInitialRender]);

  const isVerifyButtonDisabled = otp.some((digit) => digit === "");

  const [isResendDisabled, setIsResendDisabled] = useState(false); // button disable -> true - disable , false - enable
  const [resendTimer, setResendTimer] = useState(60);
  // const [disabled, setDisabled] = useState(false);
  // const [timer, setTimer] = useState(60);

  // const resetTimerLoading = () => {};

  // useEffect(() => {
  //   console.log("hello mic testing");
  //   if (!isResendDisabled) {
  //     const interval = setInterval(() => {
  //       setResendTimer((prevTimer) => prevTimer - 1);
  //     }, 1000);

  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }
  // }, [isResendDisabled]);

  useEffect(() => {
    console.log("3");
    const timer = setInterval(() => {
      setResendTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    console.log("4");
    if (resendTimer === 0) {
      setIsResendDisabled(false);

      resendTimers();
    }
  }, [resendTimer]);

  const resendTimers = () => {
    setResendTimer(60);
  };

  // API to resend OTP
  const resendOtp = async (cmpfAccountNumber) => {
    console.log("set otp called");
    try {
      const data = qs.stringify({
        cmpfoAccNO: cmpfAccountNumber,
      });
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/member/send/otp",
        data,
        config
      );
      console.log(response);
      if (response.data.data.status == true) {
        // setIsMemberFormEnabled(false)
        setHello(response.data.message);

        // setExpiredOtpMessage('')
        console.log("resend successfully");
      }
    } catch (error) {
      console.log(error);
      setHello(error.response.data.error_message);
      setExpiredOtpMessage(error.response.data.error_message);
    }
  };

  const handleResendClick = () => {
    console.log(formData);
    resendTimers();
    // Reset the timer and disable the button
    // setResendTimer(60);
    setIsResendDisabled(true);

    // Perform API call or any other logic for resending OTP
    resendOtp(cmpfAccountNumber); // call function to resend otp

    // Simulate delay
    // setTimeout(() => {
    //     setIsResendDisabled(false);
    //     setResendTimer(60);
    // }, 30000); // 30 seconds

    // setResendTimer(60); // Reset the timer
  };

  useEffect(() => {
    console.log("5");
    resendTimers();
    setIsResendDisabled(true);
    setTimeout(() => {
      setIsResendDisabled(false);
    }, 60000);
  }, []);

  //---------------------------------------------------------------------------------------

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [maf, smaf] = useState("");

  const sendData = async (cmpfAccountNumber) => {
    const data = qs.stringify({
      cmpfoAccNO: cmpfAccountNumber,
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    axios
      .post(process.env.REACT_APP_SERVER_URL + "/member/find", data, config)
      .then((response) => {
        if (response.data.data.status == true) {
          setCmpfoAccountNumberChecked(true);
          setIsHidden(true);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.http_status_code == 404) {
          setCmpfoAccountNumberChecked(false);
          setIsHidden(false);
          smaf(error.response.data.error_message);
        } else {
          smaf("");
        }
        console.log(error.response.status);

        if (error.response.status == 409) {
          smaf(error.response.data.error_message);
        } else {
          smaf("");
        }
        console.log(error.response.status);
      });
  };

  const goToHome = (e) => {
    e.preventDefault();
    const url = "/login/member";
    const memberTab = window.open(url, "_blank");
    memberTab.focus();
    // navigate("/Login", { state: { login: "member" }});
  };

  useEffect(() => {
    if (cmpfAccountNumber.length <= 20 && cmpfAccountNumber.length > 0) {
      // console.log(cmpfAccountNumber);
      //Axios
      sendData(cmpfAccountNumber);
    } else {
      setCmpfoAccountNumberChecked(false);
      setIsHidden(false);
    }
  }, [cmpfAccountNumber]);

  // variable to toggle passwords fields
  const [isHidden, setIsHidden] = useState(false);

  const [memberEnrollCardEnable, setMemberEnrollCardEnable] = useState(false);

  //for stoping the copying the new password
  const handleCopy = (event) => {
    console.log("Hekllo");
    event.preventDefault();
  };

  const handleKeyDown = (event) => {
    if (event.code === "Space") {
      event.preventDefault();
    }
  };

  return (
    <>
     <div className="pattern-overlay"></div>
      {memberEnrollCardEnable ? (
        <Container maxWidth="sm" sx={{ marginTop: "4%" }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Paper
                elevation={21}
                sx={{
                  padding: "6%",
                  paddingTop: "10%",
                  paddingBottom: "8%",
                  width: "100%",
                  // animation: performAnimation ? `${shakeHorizontal} 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both` : `${flipInHorBottom} 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`
                  // animation: `${flipInHorBottom} 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`
                }}
              >
                <div className={classes.imageContainer}>
                  <img
                    src={registerImage}
                    alt="Responsive Image"
                    className={classes.responsiveImage}
                  />
                </div>

                <Typography
                  sx={{
                    fontWeight: "400",
                    marginBottom: "6%",
                    textAlign: "center",
                  }}
                  variant="h5"
                  color="textSecondary"
                  fontWeight="normal"
                >
                  Member Enrolment is Successful.
                </Typography>

                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "50px",
                  }}
                >
                  <Button
                    size="small"
                    variant="outlined"
                    endIcon={<HomeRoundedIcon />}
                    onClick={goToHome}
                    sx={{
                      borderWidth: "1px",
                      borderColor: "#1d00c1",
                      // borderColor: '#ff3729',
                      fontSize: "0.8rem",
                      // padding: '8px 12px',
                      textTransform: "none",
                      "&:hover": {
                        // backgroundColor: '#1d00c1',
                        backgroundColor: "#ff3729",
                        color: "#eee",
                      },
                    }}
                  >
                    GO TO LOGIN
                  </Button>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      ) : isMemberFormEnabled ? (
        <div>
          {/* Member-Component-Start------------------------------------------------------------------------------------------*/}
          <div>
            <Container
              maxWidth="sm"
              sx={{ marginTop: "25px", marginBottom: "25px" }}
            >
              {/* grid ------------------------------------*/}
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Paper
                    elevation={21}
                    sx={{
                      padding: "6%",
                      width: "100%",
                      // animation: `${flipInHorBottom} 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`
                    }}
                  >
                    <Grid item xs={12} mb={1}>
                      <div style={{ padding: "0px 8px" }}>
                        <marquee
                          direction="left"
                          scrollamount="4"
                          style={{ color: "red", fontSize: "14px" }}
                        >
                          {" "}
                          Please use the same mobile number as registered with
                          CIL/SCCL. | Member data is being uploaded in batches
                          on daily basis. If you are not able to enrol, kindly
                          try after some days.
                        </marquee>
                      </div>
                    </Grid>

                    <div className={classes.imageContainer}>
                      <img
                        src={memberImage}
                        alt="Responsive Image"
                        className={classes.responsiveImage}
                      />
                    </div>

                    <Typography
                      sx={{
                        fontWeight: "400",
                        marginBottom: "6%",
                        textAlign: "center",
                      }}
                      variant="h4"
                      color="textSecondary"
                      fontWeight="normal"
                    >
                      Member Enrolment
                    </Typography>

                    <form onSubmit={handleSubmit(onSubmitOtp)}>
                      <Box mb={2}>
                        <TextField
                          autoComplete="off"
                          variant="outlined"
                          label="CMPF Account Number"
                          name="cmpf_account_number"
                          fullWidth
                          inputProps={{
                            maxLength: 20,
                            style: { textTransform: "uppercase" },
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            setValue(
                              "cmpf_account_number",
                              value.toUpperCase()
                            );
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              cmpf_account_number: value.toUpperCase(),
                            }));
                            console.log("Member enrollment value", value);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle cmpfo account number visibility"
                                  edge="end"
                                  style={{ color: "#1d00c1" }}
                                >
                                  {cmpfoAccountNumberChecked ? (
                                    <DoneRoundedIcon />
                                  ) : (
                                    <DangerousRoundedIcon
                                      sx={{ color: "red" }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...register("cmpf_account_number", {
                            required: "Please enter your CMPF Account Number",
                            // pattern: {
                            //     value: /^[A-Z\d\/]+$/,
                            //     message: "Invalid",
                            // },
                            onChange: (e) => {
                              setValue(
                                "cmpf_account_number",
                                e.target.value.toUpperCase()
                              );
                            },
                            pattern: {
                              // value: /^[A-Z\d\/]{12}$/,
                              // message: "Please enter a valid CMPF Account Number with exactly 12 characters",
                              value: /^[A-Za-z\d\/]{1,20}$/,
                              message:
                                "Please enter a valid CMPF Account Number with up to 20 characters",
                            },
                            maxLength: {
                              value: 20,
                              message: "cannot exceed 20 characters",
                            },
                          })}
                          error={!!errors?.cmpf_account_number}
                          helperText={
                            errors?.cmpf_account_number
                              ? errors.cmpf_account_number.message
                              : "Account number cannot exceed 20 characters."
                          }
                        />
                      </Box>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "red",
                        }}
                      >
                        {maf}
                      </Typography>

                      {isHidden ? (
                        <>
                          <Box mb={2}>
                            <TextField
                              //autoComplete="off" // Disable autocomplete
                              autoComplete="off" // Disable autocomplete for password
                              label=" New Password"
                              name="password"
                              type={showPassword ? "text" : "password"}
                              fullWidth
                              required
                              inputProps={{
                                maxLength: 20,
                                onCopy: handleCopy,
                              }}
                              onKeyDown={handleKeyDown}
                              onChange={(e) => {
                                const value = e.target.value;
                                setValue("password", value);
                                setFormData((prevFormData) => ({
                                  ...prevFormData,
                                  password: value,
                                }));
                              }}
                              // helperText="Passwords must have at least 8 characters and must contain at least one uppercase letter, digit, and special characters like %!@#&*"
                              helperText={
                                (isFocused || !!errors?.password) &&
                                "Passwords must have at least 8 characters and maximum of 20 characters must contain at least one uppercase letter, digit, and special characters like %!@#&*"
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                onFocus: handleFocus,
                                onBlur: handleBlur,
                              }}
                              // {...register("password", {
                              //     required: "Please enter your password",
                              //     pattern: {
                              //         value:
                              //             /^(?=.[!@#$%^&()_+=[\]{};':"\\|,.<>/?])(?=.[A-Z])(?=.\d)(?!\s).{8,}$/i,
                              //         message:
                              //             "Passwords must have at least 8 characters and must contain at least one uppercase letter, digit, and special characters like %!@#&*",
                              //     },
                              // })}
                              {...register("password", {
                                required: "Please enter your password",
                                pattern: {
                                  value:
                                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_])[^\s]{8,20}$/,
                                  message:
                                    "Password must contain at least 8 characters and maxmimum of 20 characters, including uppercase, lowercase,number and special character except space.",
                                },
                              })}
                              error={!!errors?.password}
                            />
                          </Box>

                          <Box mb={2}>
                            <TextField
                              //autoComplete="off" // Disable autocomplete
                              autoComplete="off" // Disable autocomplete for password
                              name="confirm_password"
                              variant="outlined"
                              label="Confirm Password"
                              type="password"
                              inputProps={{ maxLength: 20, onCopy: handleCopy }}
                              onKeyDown={handleKeyDown}
                              fullWidth
                              required
                              onChange={(e) => {
                                const value = e.target.value;
                                setValue("confirm_password", value);
                                setFormData((prevFormData) => ({
                                  ...prevFormData,
                                  confirm_password: value,
                                }));
                              }}
                              //autoFocus
                              {...register("confirm_password", {
                                required: "Please enter your password",
                                validate: validateConfirmPassword,
                              })}
                              error={!!errors?.confirm_password}
                              helperText={
                                errors?.confirm_password
                                  ? errors.confirm_password.message
                                  : null
                              }
                            />
                          </Box>
                          {/* {
                                                                props.isLoginButtonDisabledGlobal ? (
                                                                    <Box mb={2}>
                                                                        <CaptchaProvider>
                                                                            <Form otpButtonDisableFuncEnable={otpButtonDisableFuncEnable} otpButtonDisableFuncDisable={otpButtonDisableFuncDisable} />
                                                                        </CaptchaProvider>
                                                                    </Box>
                                                                ) : null
                                                            } */}
                          <Box mb={2}>
                            <CaptchaProvider>
                              <Form
                                disaableOTPClick={disaableOTPClick}
                                isSendOTPClicked={isSendOTPClicked}
                                setIsMemberFormEnabled={setIsMemberFormEnabled}
                                handleVerifyCaptchaEnabled={
                                  handleVerifyCaptchaEnabled
                                }
                                handleVerifyCaptchaDisabled={
                                  handleVerifyCaptchaDisabled
                                }
                                otpButtonDisableFuncEnable={
                                  otpButtonDisableFuncEnable
                                }
                                otpButtonDisableFuncDisable={
                                  otpButtonDisableFuncDisable
                                }
                              />
                            </CaptchaProvider>
                          </Box>
                        </>
                      ) : null}
                      {expiredOtpMessage && (
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "red",
                          }}
                        >
                          {expiredOtpMessage}
                        </Typography>
                      )}

                      <Stack
                        spacing={2}
                        direction="row"
                        sx={{ marginTop: "6%" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={handleIsSendOTPClicked}
                          disabled={
                            !verifyCaptcha ||
                            !cmpfoAccountNumberChecked ||
                            isSendOTPClicked ||
                            reachMax
                          }
                          // disabled={props.isLoginButtonDisabledGlobal}
                        >
                          {isLoading ? (
                            <CircularProgress sx={{ color: green }} />
                          ) : (
                            "SEND OTP"
                          )}
                        </Button>
                      </Stack>
                    </form>
                    {/* {submitMessage} */}
                  </Paper>
                </Grid>
              </Grid>

              {/* ---------------------------------------- */}
            </Container>
          </div>
          {/* Member-Component-End----------------------------------------------------------------------------------------*/}
        </div>
      ) : (
        <div>
          {/* OTP-Component-Start----------------------------------------------------------------------------------------*/}
          <div>
            <Container maxWidth="sm" sx={{ marginTop: "25px" }}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Paper
                    elevation={21}
                    sx={{
                      padding: "6%",
                      width: "100%",
                      // animation: performAnimation ? `${shakeHorizontal} 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both` : `${flipInHorBottom} 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`
                      // animation: `${flipInHorBottom} 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`
                    }}
                  >
                    <div className={classes.imageContainer}>
                      <img
                        src={otpImage}
                        alt="Responsive Image"
                        className={classes.responsiveImage}
                      />
                    </div>

                    <Typography
                      sx={{
                        fontWeight: "400",
                        marginBottom: "6%",
                        textAlign: "center",
                      }}
                      variant="h4"
                      color="textSecondary"
                      fontWeight="normal"
                    >
                      OTP Verification
                    </Typography>

                    <span style={{ color: "red" }}>{hello}</span>

                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {otp.map((digit, index) => (
                        <Box
                          key={index}
                          sx={{
                            margin: { xs: "0.3%", sm: "1%", md: "2%" },
                          }}
                        >
                          <TextField
                            id={`otp-input-${index}`}
                            variant="outlined"
                            value={digit}
                            onChange={(e) => handleOtpChange(index, e)}
                            onFocus={logOtp} // Log OTP when input field is focused
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              maxLength: 1,
                            }}
                            className={classes.otpInput}
                          />
                        </Box>
                      ))}
                    </Box>

                    <Box marginTop={1}>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        marginY={1}
                      >
                        <Grid item xs={12} sm={6}>
                          <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            // onClick={logOtp}
                            disabled={isResendDisabled}
                            // disabled={true}
                            onClick={handleResendClick}
                            className={classes.button}
                            sx={{
                              "&:hover": {
                                backgroundColor: "#1d00c1",
                                color: "#eee",
                              },
                            }}
                          >
                            {isResendDisabled
                              ? `Resend (${resendTimer}s)`
                              : "Resend"}
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            // onClick={logOtp}
                            className={classes.button}
                            onClick={() => {
                              handleMUIForm();
                              // handleSubmit(onSubmitOtp)();
                            }}
                            disabled={isVerifyButtonDisabled}
                          >
                            {/* Verify */}
                            SUBMIT
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </div>
          {/* OTP-Component-End-----------------------------------------------------------------------------------------*/}
        </div>
      )}
    </>
  );
}

export default Member;
