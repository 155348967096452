import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import LabelText from "../../FormComponent/LabelText";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import FormDivider from "../../FormComponent/FormDivider";
import TitleText from "../../FormComponent/TitleText";
import SubtitleText from "../../FormComponent/SubtitleText";
import CustomTextField from "../../FormComponent/CustomTextField";
import UploadDocument from "../../SettlementComponents/UploadDocument";
import AdditionalAdvPfPrefilled from "./AddtionalAdvPfPrefilled";
import axios from "axios";
import CaptionText from "../../FormComponent/CaptionText";
import SucessAlert from "../../FormComponent/SuccessAlert";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import CustomTextArea from "../../FormComponent/CustomTextArea";
import ConfirmationDialogue from "../../FormComponent/ConfirmationDialogue";
import ValueText from "../../FormComponent/ValueText";
import { useNavigate } from "react-router-dom";
import ErrorInList from "../../FormComponent/ErrorInList";
import jwtDecode from "jwt-decode";

function AdvFormPrefilled(props) {
  const { memberData, mappedData, nameData, wholeData } = props;
  console.log("wholeData", wholeData);
  let claimId = wholeData.advClaim.claimId;
  let count = Object.keys(mappedData).length;
  const navigate = useNavigate();
  // ******* for declaration ******
  const familyDefault = {
    familyMemId: "",
    nameFamily: "",
  };
  const [checked, setChecked] = useState(false);
  //docs
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [showingDropdown, setShowingDropdown] = useState(0);
  const [assignToOption, setAssignToOption] = useState([]);
  const [selectedOfficerName, setSelectedOfficerName] = useState("");
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [settlementParaList, setSettlementParaList] = useState([]);
  const [mandatoryDoc, setMandatoryDoc] = useState();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorOnSubmit, setIsErrorOnSubmit] = useState(false);
  const [errorMessageOnSubmit, setErrorMessageOnSubmit] = useState("");
  const [isDocError, setIsDocError] = useState(false);
  const [docErrorMessage, setDocErrorMessage] = useState("");
  const [ismemError, setIsmemError] = useState(false);
  const [errormemMessage, setErrormemMessage] = useState("");
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [failedErrorMessage, setFailedErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [settlementPara, setSettlementPara] = useState([]);
  const [missingDocuments, setMissingDocuments] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedDocTypeIds, setSelectedDocTypeIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dependentFamily, setDependentFamily] = useState([familyDefault]);
  const [membershipPeriod, setMembershipPeriod] = useState({
    years: 0,
    months: 0,
  });
  const [corresAddress, setCorresAddress] = useState({
    addressline1: "",
    addressline2: "",
    city: "",
    district: "",
    state: "",
    pincode: "",
  });

  const [locationDetails, setLocationDetails] = useState({
    locationaddressline1: "",
    locationaddressline2: "",
    villageCity: "",
    districtLocation: "",
    stateLocation: "",
    pinCodeLocation: "",
  });
  const [loginId, setLoginId] = useState();
  // const [wholeData, setWholeData]= useState([]);

  /********fetching and setting token********* */
  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (token) {
      token = token.replace('"', "").replace('"', "");
      let loginId = jwtDecode(token).user; // Assuming a default loginId
      setLoginId(loginId);
      // Add any further logic here based on the loginId
    } else {
      // Handle the case when token is not present
      setIsLoading(false);
      setIsFailed(true);
    }
  }, []);

  useEffect(() => {
    const calculateMembershipPeriod = () => {
      const joiningDate = memberData.joiningDate
        ? new Date(memberData.joiningDate)
        : null;

      if (!joiningDate) {
        setIsmemError(true);
        setErrormemMessage(
          "Ensure your member details are up to date, as the joining date is missing."
        );
      } else {
        const currentDate = new Date();
        let yearsDiff = currentDate.getFullYear() - joiningDate.getFullYear();
        let monthsDiff = currentDate.getMonth() - joiningDate.getMonth();

        // Adjust for cases where the current month is earlier than the joining month
        if (monthsDiff < 0) {
          yearsDiff--;
          monthsDiff += 12;
        }

        setMembershipPeriod({ years: yearsDiff, months: monthsDiff });
      }
    };
    calculateMembershipPeriod(); // Call the function when the component mounts
    if (
      memberData.bankAccNoPf === null ||
      memberData.bankAccNoPf.trim() === "" ||
      memberData.pfBankName === null ||
      memberData.pfBankName.trim() === "" ||
      memberData.pfBankIfsc === null ||
      memberData.pfBankIfsc.trim() === "" ||
      memberData.pfAddress === null ||
      Object.keys(memberData.pfAddress).length === 0
    ) {
      setIsmemError(true);
      setErrormemMessage(
        "Ensure your member details are up to date, as the PF Bank details is missing."
      );
    }
  }, [memberData]); // Add memberData as a dependency to the useEffect hook

  let closingBalance = 0;
  let ledgerBalDate;
  if (count > 0) {
    closingBalance = mappedData[count].closingBal;
    ledgerBalDate = mappedData[count].currPeriodEnd;
  }

  const firstNameAAdhar =
    nameData && nameData.firstNameAdhaar ? nameData.firstNameAdhaar : "";
  const middleNameAAdhar =
    nameData && nameData.middleNameAdhaar ? nameData.middleNameAdhaar : "";
  const lastNameAAdhar =
    nameData && nameData.lastNameAdhaar ? nameData.lastNameAdhaar : "";
  const firstNamePan =
    nameData && nameData.firstNamePan ? nameData.firstNamePan : "";
  const middleNamePan =
    nameData && nameData.middleNamePan ? nameData.middleNamePan : "";
  const lastNamePan =
    nameData && nameData.lastNamePan ? nameData.lastNamePan : "";
  const firstNameBank =
    nameData && nameData.firstNameBank ? nameData.firstNameBank : "";
  const middleNameBank =
    nameData && nameData.middleNameBank ? nameData.middleNameBank : "";
  const lastNameBank =
    nameData && nameData.lastNameBank ? nameData.lastNameBank : "";
  const claimTypeId =
    wholeData && wholeData.advClaim && wholeData.advClaim.advClaimTypId
      ? wholeData.advClaim.advClaimTypId
      : "";
  const dobAdhar =
    wholeData && wholeData.dobAsAAdhar ? wholeData.dobAsAAdhar : "";
  const dateAadhar = dobAdhar ? dobAdhar.split(" ")[0] : "";

  const dobPan = wholeData && wholeData.dobAsPan ? wholeData.dobAsPan : "";

  const relationType =
    wholeData && wholeData.advClaim && wholeData.advClaim.sfwhRelationType
      ? wholeData.advClaim.sfwhRelationType
      : "";
  const relationTypeId =
    relationType === "W/O"
      ? "1"
      : relationType === "D/O"
      ? "2"
      : relationType === "S/O"
      ? "3"
      : relationType === "H/O"
      ? "4"
      : "";
  const relationName =
    wholeData && wholeData.advClaim && wholeData.advClaim.sfwhName
      ? wholeData.advClaim.sfwhName
      : "";

  const memberShare =
    wholeData && wholeData.advClaim && wholeData.advClaim.memShare
      ? wholeData.advClaim.memShare
      : 0;

  const employerShare =
    wholeData && wholeData.advClaim && wholeData.advClaim.employerShare
      ? wholeData.advClaim.employerShare
      : 0;

  const ledgerbalancedate =
    wholeData && wholeData.advClaim && wholeData.advClaim.ledgerBalDate
      ? wholeData.advClaim.ledgerBalDate
      : "";
  const ledgerbalance = wholeData.advClaim.ledgerBalance;

  const ledgerdate = ledgerbalancedate
    ? new Date(ledgerbalancedate).toISOString().split("T")[0]
    : "";
  console.log("lgrdt", ledgerdate);
  const datePan = dobPan ? dobPan.split(" ")[0] : "";
  const claimTypeIdString = claimTypeId.toString();

  const defaultClaim = {
    claim_type: claimTypeIdString,
    mem_first_name_aadhar: firstNameAAdhar,
    mem_middle_name_aadhar: middleNameAAdhar,
    mem_last_name_aadhar: lastNameAAdhar,
    mem_first_name_bank: firstNameBank,
    mem_middle_name_bank: middleNameBank,
    mem_last_name_bank: lastNameBank,
    mem_first_name_pan: firstNamePan,
    mem_middle_name_pan: middleNamePan,
    mem_last_name_pan: lastNamePan,
    mem_dob_aadhar: dateAadhar,
    mem_dob_pan: datePan,
    relation: relationTypeId,
    fhw_name: relationName,
    requiredAmount: "",
    employeeGrossAmount: "",
    estimatedCost: 500,
    remarks: "",
  };
  const [claim, setClaim] = useState(defaultClaim);

  const [advClaimTypeOption, setAdvClaimTypeOption] = useState([]);
  // state to store addition fields
  const [addField, setAddField] = useState("");

  const claim_type = {
    name: "claim_type",
    placeholder: "Select Claim Type",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: claimTypeIdString,
    required: true,
    options: advClaimTypeOption,
  };
  const relation = {
    name: "relation",
    placeholder: "Select Relation",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: claim.relation,
    required: true,
    options: [
      { id: "1", title: "W/O" },
      { id: "2", title: "D/O" },
      { id: "3", title: "S/O" },
      { id: "4", title: "H/O" },
    ],
    //options: claimTypeList,
  };

  const mem_first_name_aadhar = {
    name: "mem_first_name_aadhar",
    type: "text",
    helperText: "",
    placeholder: "First Name",
    defaultValue: claim.mem_first_name_aadhar,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_middle_name_aadhar = {
    name: "mem_middle_name_aadhar",
    type: "text",
    helperText: "",
    placeholder: "Middle Name",
    defaultValue: claim.mem_middle_name_aadhar,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_last_name_aadhar = {
    name: "mem_last_name_aadhar",
    type: "text",
    helperText: "",
    placeholder: "Last Name",
    defaultValue: claim.mem_last_name_aadhar,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_first_name_pan = {
    name: "mem_first_name_pan",
    type: "text",
    helperText: "",
    placeholder: "First Name",
    defaultValue: claim.mem_first_name_pan,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_middle_name_pan = {
    name: "mem_middle_name_pan",
    type: "text",
    helperText: "",
    placeholder: "Middle Name",
    defaultValue: claim.mem_middle_name_pan,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_last_name_pan = {
    name: "mem_last_name_pan",
    type: "text",
    helperText: "",
    placeholder: "Last Name",
    defaultValue: claim.mem_last_name_pan,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_first_name_bank = {
    name: "mem_first_name_bank",
    type: "text",
    helperText: "",
    placeholder: "First Name",
    defaultValue: claim.mem_first_name_bank,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_middle_name_bank = {
    name: "mem_middle_name_bank",
    type: "text",
    helperText: "",
    placeholder: "Middle Name",
    defaultValue: claim.mem_middle_name_bank,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_last_name_bank = {
    name: "mem_last_name_bank",
    type: "text",
    helperText: "",
    placeholder: "Last Name",
    defaultValue: claim.mem_last_name_bank,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const mem_dob_aadhar = {
    name: "mem_dob_aadhar",
    type: "date",
    helperText: "",
    placeholder: "Select Date of Birth",
    defaultValue: claim.mem_dob_aadhar,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const mem_dob_pan = {
    name: "mem_dob_pan",
    type: "date",
    helperText: "",
    placeholder: "Select Date of Birth",
    defaultValue: claim.mem_dob_pan,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const fhw_name = {
    name: "fhw_name",
    type: "text",
    helperText: "",
    placeholder: "Fahter/Husband/Wife Name",
    defaultValue: claim.fhw_name,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const remarks = {
    name: "remarks",
    type: "text",
    multiline: true,
    helperText: "Enter your remarks (maximum 200 Character)",
    placeholder: "Enter your remarks here",
    defaultValue: claim.remarks,
    errorMessage: "Invalid",
    pattern: "",
    // maxLength: 1000, // Limiting to 200 words
  };

  const assignTo = {
    name: "assignTo",
    placeholder: "Select Officer",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "",
    required: true,
    options: assignToOption,
  };

  //****************Dropdown******************* */
  const fetchAssignOfficer = async () => {
    try {
      const url =
        process.env.REACT_APP_SERVER_URL +
        "/dropdown/for/show/next/authorized/offcr/adv/pf/pen/uda";

      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      const options = data.map((option) => ({
        id: option.OffcrId,
        title: `${option.Name} (${option.OffcrId})`,
      }));
      setAssignToOption(options); // Assuming setAssignToOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchAssignOfficer();
  }, [loginId]);

  /***** function to get all dependent family member details**************** */
  const fetchNomineeDetail = async () => {
    setIsLoading(true);
    // console.log("in fetch Member detail");

    //  if (!(await sessionExist())) {
    //    console.log("session extending having trouble");
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");

    const url = process.env.REACT_APP_SERVER_URL + "/dependent/family/member";
    const formData = new FormData();
    formData.append("cmpfAccNum", wholeData.cmpfAcNo); // check what formdata is to be sent
    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsLoading(false);
        setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          setDependentFamily(data);
          return;
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        // console.log("error", error);
      });
  };
  // *****************mandatory docs****************************
  const fetchDocumentsToUploadList = async () => {
    try {
      const url =
        process.env.REACT_APP_SERVER_URL + "/advance/pf/claim/mandatory/docs";

      const formData = new FormData();
      formData.append("claimTypeId", claim.claim_type);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      setMandatoryDoc(data);
    } catch (error) {
      // Handle error
    }
  };

  //*******************Settlement Para List************************ */
  const fetchClaimTypes = async () => {
    const url = process.env.REACT_APP_SERVER_URL + "/advance/pf/claim/Type";

    axios.post(url).then((response) => {
      setSettlementParaList(response.data.data);
    });
  };

  useEffect(() => {
    fetchClaimTypes();
    fetchNomineeDetail();
    fetchDocumentsToUploadList(claim.claim_type);
  }, [claim.claim_type]);

  // useEffect(() => {
  //   const selectedClaimId = claim.claim_type;
  //   const selectedClaimType = settlementParaList.find(
  //     (claim) => claim.id == selectedClaimId
  //   );
  //   setSettlementPara(selectedClaimType ? selectedClaimType.para : "");
  // }, [claim.claim_type, settlementParaList]);

  useEffect(() => {
    const selectedClaimId = claim.claim_type;
    if (selectedClaimId) {
      const selectedClaimType = settlementParaList.find(
        (claim) => claim.id == selectedClaimId
      );
      setSettlementPara(selectedClaimType ? selectedClaimType.para : "");
    }
  }, [claim.claim_type, settlementParaList]);

  //! cmpf account number is fetched on useEffect with a delay of 3sec to avoid multiple api hits
  const onChange = (e) => {
    if (e.target.name === "assignTo") {
      const selectedOption = assignToOption.find(
        (option) => option.id === e.target.value
      );
      if (selectedOption) {
        setSelectedOfficerName(selectedOption.title); // Set the selected officer's name in the state
      }
    }
    setClaim({ ...claim, [e.target.name]: e.target.value });
  };
  // to pass to additionpf
  const updateAddField = (fieldName, value) => {
    setAddField({ ...addField, [fieldName]: value });
  };
  console.log("surya", addField);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  // Advance claim type integration]
  const fetchAdvClaimType = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + "/advance/pf/claim/Type";

      const response = await axios.post(url);
      const data = response.data.data;
      const options = data.map((option) => ({
        id: option.id.toString(),
        title: option.title,
      }));
      setAdvClaimTypeOption(options); // Set the fetched data to the tablePreview state // we are taking data[0] as the response recevied is an array of object and we need only the first element

      //   setIsLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    fetchAdvClaimType();
  }, []);

  if (wholeData.advClaim.correspondanceAddress && !corresAddress.addressline1) {
    const parsedAddress = JSON.parse(wholeData.advClaim.correspondanceAddress);
    const addressLinesArray = parsedAddress.address.split(",");
    setCorresAddress({
      addressline1: addressLinesArray[0].trim(),
      addressline2: addressLinesArray[1] ? addressLinesArray[1].trim() : "",
      city: addressLinesArray[2].trim(),
      district: addressLinesArray[3].trim(),
      state: addressLinesArray[4].trim(),
      pincode: addressLinesArray[5]?.trim(),
    });
  }

  const formatAddress = (addField, corresAddress) => {
    const addressLines = [];

    addressLines.push(
      addField.addressline1 ? addField.addressline1 : corresAddress.addressline1
    );
    addressLines.push(
      addField.addressline2 ? addField.addressline2 : corresAddress.addressline2
    );
    addressLines.push(addField.city ? addField.city : corresAddress.city);
    addressLines.push(
      addField.district ? addField.district : corresAddress.district
    );
    addressLines.push(addField.state ? addField.state : corresAddress.state);
    addressLines.push(
      addField.pincode ? addField.pincode : corresAddress.pincode
    );

    // if (addField.country) addressLines.push(addField.country);
    const addressParts = [...addressLines];
    return addressParts.join(", ");
  };

  const formattedAddress = formatAddress(addField, corresAddress);

  if (
    !wholeData &&
    wholeData.tAdvSpecificDetails.locationDet &&
    !locationDetails.locationaddressline1
  ) {
    const locationAddress = JSON.parse(
      wholeData.tAdvSpecificDetails.locationDet
    );
    const addressArray = locationAddress.address.split(",");
    setLocationDetails({
      locationaddressline1: addressArray[0].trim(),
      locationaddressline2: addressArray[1] ? addressArray[1].trim() : "",
      villageCity: addressArray[2].trim(),
      districtLocation: addressArray[3].trim(),
      stateLocation: addressArray[4].trim(),
      pinCodeLocation: addressArray[5].trim(),
    });
  }

  const formatAdd = (addField, locationDetails) => {
    const addressLines = [];

    addressLines.push(
      addField.locationaddressline1
        ? addField.locationaddressline1
        : locationDetails.locationaddressline1
    );
    addressLines.push(
      addField.locationaddressline2
        ? addField.locationaddressline2
        : locationDetails.locationaddressline2
    );
    addressLines.push(
      addField.villageCity ? addField.villageCity : locationDetails.villageCity
    );
    addressLines.push(
      addField.districtLocation
        ? addField.districtLocation
        : locationDetails.districtLocation
    );
    addressLines.push(
      addField.stateLocation
        ? addField.stateLocation
        : locationDetails.stateLocation
    );
    addressLines.push(
      addField.pinCodeLocation
        ? addField.pinCodeLocation
        : locationDetails.pinCodeLocation
    );
    // if (addField.country) addressLines.push(addField.country);
    const addressParts = [...addressLines];
    return addressParts.join(", ");
  };

  const locationAdd = formatAdd(addField, locationDetails);

  const [sfwhRelationty, setSfwhRelationType] = useState("");
  // Assume this is inside a React functional component

  useEffect(() => {
    if (claim.relation == 1) {
      setSfwhRelationType("W/O");
    } else if (claim.relation == 2) {
      setSfwhRelationType("D/O");
    } else if (claim.relation == 3) {
      setSfwhRelationType("S/O");
    } else if (claim.relation == 4) {
      setSfwhRelationType("H/O");
    } else {
      console.error("Invalid claim relation value:", claim.relation);
    }
  }, [claim.relation]); // Ensure the effect runs only when claim.relation changes

  const [areaUnitt, setAreaUnitt] = useState("Sq. ft.");
  useEffect(() => {
    if (addField.areaUnit == 2) {
      setAreaUnitt("Sq. yd.");
    } else {
      // console.error("Invalid claim relation value:", addField.areaUnit);
    }
  }, [addField.areaUnit]); // Ensure the effect runs only when claim.relation changes

  const amountAllowedToUpdate =
    addField.amountAllowed !== null && addField.amountAllowed !== undefined
      ? addField.amountAllowed
      : wholeData.advClaim.amountAllowed;

  const estimatedCostToUpdate =
    addField.estimatedCost !== null && addField.estimatedCost !== undefined
      ? addField.estimatedCost
      : wholeData.tAdvSpecificDetails.estimatedCost;

  const examPassedToUpdate =
    addField.examPassed !== null && addField.examPassed !== undefined
      ? addField.examPassed
      : wholeData.tAdvSpecificDetails.examPassed;

  // const emolumentsLast36MToUpdate =
  //   addField.emolumentsLast36m !== null &&
  //   addField.emolumentsLast36m !== undefined
  //     ? 36 *objectTobeSent.emolumentsLast36m
  //     : wholeData.advClaim.emolumentsLast36m;
  const emolumentsLast36MToUpdate = 36 * addField.emolumentsLast36m;

  const saleAgreeAmtToUpdate =
    addField.saleAgreeAmt !== null && addField.saleAgreeAmt !== undefined
      ? addField.saleAgreeAmt
      : wholeData.tAdvSpecificDetails.saleAgreeAmt;

  const anticptdDateOfMarriageToUpdate =
    addField.anticptdDateOfMarriage !== null &&
    addField.anticptdDateOfMarriage !== undefined
      ? addField.anticptdDateOfMarriage
      : wholeData.tAdvSpecificDetails.anticptdDateOfMarriage;

  const examYearToUpdate =
    addField.examYear !== null && addField.examYear !== undefined
      ? addField.examYear
      : wholeData.tAdvSpecificDetails.examYear;

  const feesPaidToUpdate =
    addField.feesPaid !== null && addField.feesPaid !== undefined
      ? addField.feesPaid
      : wholeData.tAdvSpecificDetails.feesPaid;

  const areaValue =
    addField.siteMeasurementVal !== null &&
    addField.siteMeasurementVal !== undefined
      ? addField.siteMeasurementVal
      : wholeData.tAdvSpecificDetails.siteMeasurementVal;
  const areaValueToUpdate = parseInt(areaValue);

  const dependentIdToUpdate =
    addField.dependentId !== null && addField.dependentId !== undefined
      ? addField.dependentId
      : wholeData.tAdvSpecificDetails.dependentId;

  const advReqdDateToUpdate =
    addField.advReqdDate !== null && addField.advReqdDate !== undefined
      ? addField.advReqdDate
      : wholeData.advClaim.advReqdDate;

  const plotNoToUpdate =
    addField.plotNo !== null && addField.plotNo !== undefined
      ? addField.plotNo
      : wholeData.tAdvSpecificDetails.plotNo;

  const subPlotNoToUpdate =
    addField.subPlotNo !== null && addField.subPlotNo !== undefined
      ? addField.subPlotNo
      : wholeData.tAdvSpecificDetails.subPlotNo;

  const amountAppliedToUpdate =
    addField.amountApplied !== null && addField.amountApplied !== undefined
      ? addField.amountApplied
      : wholeData.advClaim.amountApplied;

  const objectTobeSent = {
    claimId: wholeData.advClaim.claimId ? wholeData.advClaim.claimId : 0,
    udaLoginId: loginId,
    cmpfAccNo: memberData.cmpfAccNo,
    toOffcrId: claim.assignTo,
    nameAadhaar: {
      firstname: claim.mem_first_name_pan,
      middlename: claim.mem_middle_name_pan,
      lastname: claim.mem_last_name_pan,
    },
    namePan: {
      firstname: claim.mem_first_name_pan,
      middlename: claim.mem_middle_name_pan,
      lastname: claim.mem_last_name_pan,
    },
    nameBank: {
      firstname: claim.mem_first_name_bank,
      middlename: claim.mem_middle_name_bank,
      lastname: claim.mem_last_name_bank,
    },
    dobAadhaar: claim.mem_dob_aadhar,
    dobPan: claim.mem_dob_pan,
    sfwhName: claim.fhw_name,
    sfwhRelationType: sfwhRelationty,
    declarationUda: checked,
    emolumentsLast36M: emolumentsLast36MToUpdate
      ? emolumentsLast36MToUpdate
      : 0,
    ledgerBalance: ledgerbalance,
    memShare: memberShare,
    employeeShare: employerShare,
    amountAllowed: amountAllowedToUpdate,
    // addField.amountAllowed,
    correspondanceAddress: {
      address: formattedAddress,
    },
    periodOfMemship: membershipPeriod,
    ledgerBalDate: ledgerdate,
    remarks: claim.remarks,
    estimatedCost: 1 * estimatedCostToUpdate,
    saleAgreeAmt: 1 * saleAgreeAmtToUpdate,
    locationDet: {
      address: locationAdd,
    },
    anticptdDateOfMarriage: anticptdDateOfMarriageToUpdate,
    examPassed: examPassedToUpdate || "",
    examYear: examYearToUpdate || "",
    feesPaid: feesPaidToUpdate || 0,
    areaUnit: areaUnitt,
    areaValue: areaValueToUpdate || 0,
    modeOfRemmit: "A/C PAYEE CHEQUE/BANK TRANSFER",
    // remmitBankDetails: resultMap.address,
    // cheqeNo
    dependentId: dependentIdToUpdate,
    advReqdDate: advReqdDateToUpdate,
    plotNo: plotNoToUpdate || "",
    subPlotNo: subPlotNoToUpdate || "",
    advClaimTypeId: 1 * claim.claim_type,
    amountApplied: 1 * amountAppliedToUpdate,
    // to remove
    // dateReceived: "1966-08-31T18:30:00.000+00:00",
    //................
  };

  console.log("objectTobeSent", objectTobeSent);

  const handleSuccessOk = () => {
    navigate("/uda/advset");
    setIsSubmitSuccess(false);
  };

  const [isRoleAssigned, setIsRoleAssigned] = useState(false);

  // Function to fetch document types using API
  const fetchDocumentTypes = async () => {
    try {
      const url =
        process.env.REACT_APP_SERVER_URL + "/all/document/for/adv/pf/claim";
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, config);
      const data = response.data.data;
      setDocumentTypes(data); // Assuming the data is an array of document types

      // Handle error
    } catch (error) {
      console.error("Error fetching document types:", error);
    }
  };

  // Fetch document types when the component mounts
  useEffect(() => {
    fetchDocumentTypes();
  }, []);

  /// ******** on submit function*********

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const url =
      process.env.REACT_APP_SERVER_URL + "/update/advance/pf/claim/by/uda";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    axios
      .post(url, objectTobeSent, { headers })
      .then(function (response) {
        setIsSubmitting(false);
        postUploadedDoc();

        if (response.request.status === 200) {
          setIsRoleAssigned(true);
          setSuccessMessage("Claim Updated Successfully");
          // Display a generic success message or handle other status codes
          console.log("Unexpected response status:", response.status);
        }
        // Check if the response status is 208
        if (response.data.http_status_code === 208) {
          // Display the error message from the API response
          const errorMessage = response.data.error_message || "Unknown error";
          // Set the error message to be displayed in your ErrorAlert component
          setIsErrorOnSubmit(true);
          setErrorMessageOnSubmit(errorMessage);
          console.log("Error message:", errorMessage);
        }
      })
      .catch((error) => {
        if (error.response.data.http_status_code === 406) {
          // Display the error message from the API response
          const errorMessage =
            error.response.data.error_message || "Unknown error";
          // Set the error message to be displayed in your ErrorAlert component
          setIsErrorOnSubmit(true);
          setErrorMessageOnSubmit(errorMessage);
          console.log("Error message:", errorMessage);
        }
        if (error.response.data.http_status_code === 400) {
          const errorMessage =
            error.response.data.error_message || "Unknown error";
          setIsErrorOnSubmit(true);
          setErrorMessageOnSubmit(errorMessage);
          // setErrorMessage("Claim Submit Successful...");
          // Display a generic success message or handle other status codes
          console.log("Unexpected response status:", error.response.status);
        }
        console.log("error on submit", error);
      });
  };

  const handleSave = (documentData) => {
    const filteredDocuments = documentData.filter(
      (upload) => upload.uploadedDocument !== null
    );

    const requestBody = {
      cmpfAcNo: memberData.cmpfAccNo,
      uploadAdvPfDocumentsDTO: documentData.map((upload) => ({
        documentData: upload.uploadedDocument,
        docTypeId: upload.selectedDocumentType,
      })),
    };

    // Extracting docTypeIds from filtered documents and storing in state
    const docTypeIds = filteredDocuments.map(
      (upload) => upload.selectedDocumentType
    );
    setSelectedDocTypeIds(docTypeIds);
    setUploadedDocs(requestBody.uploadAdvPfDocumentsDTO);
  };

  // const handleUnmatchedTitles = (selectedDocTypeIds, mandatoryDoc) => {
  //   try {
  //     // Extracting ids from the API data
  //     const apiDocTypeIds = new Set(mandatoryDoc.map((docType) => docType.id));

  //     // Finding ids that are in API data but not in selectedDocTypeIds
  //     const unmatchedIds = [...apiDocTypeIds].filter(
  //       (id) => !selectedDocTypeIds.includes(id)
  //     );

  //     // Creating an array to store unmatched titles with serial numbers
  //     const unmatchedTitlesList = [];

  //     // Finding titles corresponding to unmatched ids and storing them in the array
  //     mandatoryDoc.forEach((docType, index) => {
  //       if (unmatchedIds.includes(docType.id)) {
  //         // const serialNumber = index + 1;
  //         // const titleWithSerial = `${serialNumber}.${docType.title}`;
  //         //const titleWithSerial = `${serialNumber}.${docType.title}`;
  //         const titleWithSerial = `${docType.title}`;

  //         unmatchedTitlesList.push(titleWithSerial);
  //       }
  //     });

  //     // Set the unmatched titles in the state or handle them as needed
  //     setMissingDocuments(unmatchedTitlesList);
  //   } catch (error) {
  //     console.error("Error processing data:", error.message);
  //     // Handle the error as needed, e.g., set an error state
  //   }
  // };

  const postUploadedDoc = () => {
    const uploadDocData = {
      cmpfAcNo: memberData.cmpfAccNo,
      uploadAdvPfDocumentsDTO: uploadedDocs,
      claimId: wholeData.advClaim.claimId ? wholeData.advClaim.claimId : 0,
    };
    const url =
      process.env.REACT_APP_SERVER_URL + "/update/advance/pf/claim/docs/by/uda";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, uploadDocData, { headers })
      .then(function (response) {
        setIsSubmitSuccess(true);
      })
      .catch((error) => {
        setIsFailed(true);
        setFailedErrorMessage("Something went Wrong!!");
        console.log("error on submit", error);
      });
  };

  //*********function to check the name in document proofs vs service records********* */
  function checkNameMatch(fullName, firstName, middleName, lastName) {
    const fullNameParts = fullName.split(" ");
    const first = firstName ? firstName.toLowerCase() : "";
    const middle = middleName ? middleName.toLowerCase() : "";
    const last = lastName ? lastName.toLowerCase() : "";

    const firstMatch =
      first === "" ||
      fullNameParts.filter((name) => name.toLowerCase() === first).length === 1;
    const middleMatch =
      middle === "" ||
      fullNameParts.filter((name) => name.toLowerCase() === middle).length ===
        1;
    const lastMatch =
      last === "" ||
      fullNameParts.filter((name) => name.toLowerCase() === last).length === 1;

    return firstMatch && middleMatch && lastMatch;
  }

  /**** checking the names ******/
  function nameCheck() {
    const fullName = memberData.fullName;
    const aadhaarCheck = checkNameMatch(
      fullName,
      claim.mem_first_name_aadhar,
      claim.mem_middle_name_aadhar,
      claim.mem_last_name_aadhar
    );
    const panCheck = checkNameMatch(
      fullName,
      claim.mem_first_name_pan,
      claim.mem_middle_name_pan,
      claim.mem_last_name_pan
    );
    const bankStateCheck = checkNameMatch(
      fullName,
      claim.mem_first_name_bank,
      claim.mem_middle_name_bank,
      claim.mem_last_name_bank
    );

    return aadhaarCheck && panCheck && bankStateCheck;
  }

  const dateOptions = { day: "2-digit", month: "2-digit", year: "numeric" };

  /*********function to check the dates enterd*********/
  function checkDateOfBirthMatch(enteredDate, givenDate) {
    if (enteredDate) {
      const enteredDateParts = enteredDate.split("-");
      const givenDateParts = givenDate.split("-");

      const enteredDateObj = new Date(
        enteredDateParts[2],
        enteredDateParts[1] - 1,
        enteredDateParts[0]
      );
      const givenDateObj = new Date(
        givenDateParts[2],
        givenDateParts[1] - 1,
        givenDateParts[0]
      );

      if (enteredDateObj.getTime() === givenDateObj.getTime()) {
        return true; // Date of birth matches
      } else {
        return false; // Date of birth does not match
      }
    } else {
      return false;
    }
  }

  /*****************checking dob from aadhaar and pan******************/
  function dobCheck() {
    const memberDob = new Date(memberData.dateOfBirth)
      .toLocaleDateString("en-GB", dateOptions)
      .split("/")
      .reverse()
      .join("-");
    const aadhaarDobCheck = checkDateOfBirthMatch(
      memberDob,
      claim.mem_dob_aadhar
    );
    const panDobCheck = checkDateOfBirthMatch(claim.mem_dob_pan, memberDob);
    return aadhaarDobCheck && panDobCheck;
  }
  const checkGenCertificate = () => {
    return uploadedDocs.some((docs) => {
      return docs.docTypeId === 18;
    });
  };

  /// error alert handler
  const handleErrorAlert = () => {
    // setAddField("amountRequiredDate":null);
    setAddField({ ...addField, amountRequiredDate: "" });

    setIsError(false);
  };

  const handleDocErrorAlert = () => {
    setIsDocError(false);
  };

  const handleErrorAlertOnSubmit = () => {
    navigate("/uda/advset");
    // isSubmitting(false);
    setIsErrorOnSubmit(false);
  };
  /// error alert handler
  const handleMemErrorAlert = () => {
    navigate("/uda/advset");
    setIsError(false);
  };

  // todo handleAssignToOk calling handle submit on click of ok confirmation dialouge box
  const handleAssignToOk = (e) => {
    handleSubmit(e);
    setIsSubmitClicked(false);
  };

  const handleAssignToCancel = () => {
    setIsSubmitClicked(false);
  };

  useEffect(() => {
    // handleUnmatchedTitles(selectedDocTypeIds, mandatoryDoc);
  }, [selectedDocTypeIds, mandatoryDoc]);

  //! final checcks
  const handleProcessOk = (e) => {
    if (!nameCheck()) {
      if (!checkGenCertificate()) {
        setIsError(true);
        setErrorMessage(
          "Name entered does not match with the service records therefore it is mandatory to upload the Genuineness Certificate for Member's name"
        );
        return;
      }
    }
    // if (
    //   !locationDetails.pincode ||
    //   locationDetails.pincode.length !== 6 ||
    //   /[eE]/.test(locationDetails.pincode)
    // ) {
    //   setIsError(true);
    //   setErrorMessage(
    //     "Please Enter correct Pincode (6 digits without 'e' or 'E')."
    //   );
    //   return;
    // }

    if (
      !objectTobeSent.amountApplied ||
      /[eE]/.test(objectTobeSent.amountApplied)
    ) {
      setIsError(true);
      setErrorMessage("Please Enter the correct Amount.");
      return;
    }
    if (!objectTobeSent.advReqdDate) {
      setIsError(true);
      setErrorMessage("Advance required date cannot be null.");
      return;
    }

    if (!claim.claim_type) {
      setIsError(true);
      setErrorMessage("Please Select claim type.");
      return;
    }
    if (
      claim.claim_type == 1 ||
      claim.claim_type == 2 ||
      claim.claim_type == 3 ||
      claim.claim_type == 5 ||
      claim.claim_type == 7
    ) {
      if (
        !objectTobeSent.emolumentsLast36M ||
        objectTobeSent.emolumentsLast36M === 0 ||
        /[eE]/.test(objectTobeSent.emolumentsLast36M)
      ) {
        setIsError(true);
        setErrorMessage("Please Enter correct Employee Gross Amount.");
        return;
      }
    }

    if (claim.claim_type == 1 || claim.claim_type == 2) {
      if (
        !objectTobeSent.saleAgreeAmt ||
        /[eE]/.test(objectTobeSent.saleAgreeAmt)
      ) {
        setIsError(true);
        setErrorMessage("Please Enter correct Sale Agreement Amount.");
        return;
      }
    }

    if (claim.claim_type == 3 || claim.claim_type == 5) {
      if (!addField.estimatedCost || /[eE]/.test(addField.estimatedCost)) {
        setIsError(true);
        setErrorMessage("Please Enter correct Estimated Cost.");
        return;
      }
    }

    if (claim.claim_type == 2) {
      if (!objectTobeSent.plotNo) {
        setIsError(true);
        setErrorMessage("Please Enter Plot Number.");
        return;
      }
      if (!objectTobeSent.subPlotNo) {
        setIsError(true);
        setErrorMessage("Please Enter Sub-Plot No .");
        return;
      }
    }

    if (claim.claim_type == 6) {
      if (!objectTobeSent.anticptdDateOfMarriage) {
        setIsError(true);
        setErrorMessage("Please Enter Date Of Marriage.");
        return;
      }
      if (!objectTobeSent.dependentId) {
        setIsError(true);
        setErrorMessage("Please select Member .");
        return;
      }
    }
    if (claim.claim_type == 7) {
      if (!objectTobeSent.dependentId) {
        setIsError(true);
        setErrorMessage("Please select Name Of Student.");
        return;
      }
      if (!objectTobeSent.feesPaid) {
        setIsError(true);
        setErrorMessage("Please enter the Fee paid.");
        return;
      }
    }

    if (!checked) {
      setIsError(true);
      setErrorMessage("Please Select the Declaration Checkbox.");
      return;
    }
    if (missingDocuments && missingDocuments.length > 0) {
      setIsDocError(true);
      setDocErrorMessage(missingDocuments.join(", "));
    } else {
      setShowingDropdown(1);
    }
  };

  const handleSubmitOk = () => {
    if (showingDropdown === 1) {
      if (!objectTobeSent.toOffcrId) {
        // Show message in dialog: "Please select an officer to assign"
        // alert("Please select an officer to assign");
        setIsError(true);
        setErrorMessage("Please Select an Officer to Assign.");
      } else {
        setIsSubmitClicked(true);
      }
    }
  };

  return (
    <>
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error"
        body={errorMessage}
        buttonName="Ok"
      />
      <ErrorInList
        handleOk={handleDocErrorAlert}
        open={isDocError}
        title="Mandatory documents missing:"
        body={docErrorMessage}
        buttonName="Ok"
      />
      <ErrorAlert
        handleOk={handleErrorAlertOnSubmit}
        open={isErrorOnSubmit}
        title="Error"
        body={errorMessageOnSubmit}
        buttonName="Ok"
      />
      <ErrorAlert
        handleOk={handleMemErrorAlert}
        open={ismemError}
        title="Error"
        body={errormemMessage}
        buttonName="Ok"
      />
      <SucessAlert
        handleOk={handleSuccessOk}
        open={isRoleAssigned}
        title="Success"
        body={successMessage}
        buttonName="Ok"
      />
      <ConfirmationDialogue
        handleOk={handleAssignToOk}
        handleCancel={handleAssignToCancel}
        open={isSubmitClicked}
        title="Confirm Assignment"
        body={`Are you sure you want to assign the claim to ${selectedOfficerName} ?`}
        buttonName="Confirm"
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                columnGap={2}
                justifyContent="space-between"
                alignItems="center">
                <Grid item xs={12} sm={4}>
                  <TitleText title="Claim Updation Form" />
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Grid
                    container
                    spacing={1}
                    //columnGap={1}
                    justifyContent="end"
                    alignItems="center">
                    <Grid
                      item
                      xs={7}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}>
                      <LabelText title="Settlement Para: " />
                    </Grid>
                    <Grid item xs={5}>
                      <ValueText title={settlementPara} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <FormDivider />
              <Grid
                container
                spacing={2}
                columnGap={2}
                justifyContent="center"
                alignItems="center">
                {/* ---claim type dropdown--- */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Select Claim type  " />
                </Grid>
                {/* <Grid mt={2}>:</Grid> */}
                <Grid item xs={12} sm={6}>
                  <CustomDropdown {...claim_type} onchange={onChange} />
                </Grid>
                {/* ------------------ */}
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    columnGap={2}
                    justifyContent="start"
                    alignItems="center">
                    <Grid item xs={12}>
                      <SubtitleText title="Enter Member Name as per " />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={2.5}>
                          <LabelText title="Aadhaar Card" />
                        </Grid>
                        {/* <Grid mt={2.5} mr={3.5}>
                            :
                          </Grid> */}
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_first_name_aadhar}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_middle_name_aadhar}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_last_name_aadhar}
                            onchange={onChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={2.5}>
                          <LabelText title="PAN Card" />
                        </Grid>
                        {/* <Grid mt={2.5} mr={3.5}>
                            :
                          </Grid> */}
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_first_name_pan}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_middle_name_pan}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_last_name_pan}
                            onchange={onChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={2.5}>
                          <LabelText title="Bank Statement" />
                        </Grid>
                        {/* <Grid mt={2.5} mr={3.5}>
                            :
                          </Grid> */}
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_first_name_bank}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_middle_name_bank}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_last_name_bank}
                            onchange={onChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* ******* Name fields ended ***** */}
                    <Grid item xs={12} mt={1}>
                      <SubtitleText title="Enter Member Date of Birth as per" />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={1}
                        columnGap={1}
                        justifyContent="space-between">
                        {/* here we are creating two columns as the label and textfield were not aligning properly */}
                        {/* Column 1 */}
                        <Grid item xs={12} md={5}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={4}>
                              <LabelText title="Aadhaar Card" />
                            </Grid>
                            <Grid mt={2.5}>:</Grid>
                            <Grid item xs={12} sm={12} md={7}>
                              <CustomTextField
                                {...mem_dob_aadhar}
                                onchange={onChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* Column 2 */}
                        <Grid item xs={12} md={5}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={4}>
                              <LabelText title="PAN Card" />
                            </Grid>
                            <Grid mt={2.5}>:</Grid>
                            <Grid item xs={12} sm={12} md={7}>
                              <CustomTextField
                                {...mem_dob_pan}
                                onchange={onChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* ********* Dob fields ends here******** */}
                    <Grid item xs={12} mt={1}>
                      <SubtitleText title="Enter S/O D/O W/O H/O as per" />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={2.5}>
                          <LabelText title="Aadhaar Card" />
                        </Grid>
                        <Grid mt={2.5}>:</Grid>
                        <Grid item xs={12} sm={12} md={9}>
                          <Grid
                            container
                            spacing={1}
                            justifyContent="end
                            ">
                            <Grid item xs={12} sm={12} md={5}>
                              <CustomDropdown
                                {...relation}
                                onchange={onChange}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                              <CustomTextField
                                {...fhw_name}
                                onchange={onChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <AdditionalAdvPfPrefilled
                        location={{
                          claim_type: claim.claim_type,
                          closingBalance: closingBalance,
                        }}
                        claimId={claimId}
                        updateAddField={updateAddField}
                        dependentFamily={dependentFamily}
                      />
                    </Grid>
                    <Grid item xs={12} mt={1}>
                      <SubtitleText title="Upload Documents" />
                    </Grid>
                    <Grid item xs={12} sm={12} mt={-3}>
                      <UploadDocument
                        documentTypes={documentTypes}
                        onSave={handleSave}
                      />
                    </Grid>
                    {/* Document Upload  */}
                  </Grid>
                  {/* ************************************ Remarks ***************************************** */}
                  <Grid item xs={12} sm={12} style={{ paddingTop: 20 }}>
                    <SubtitleText title="Remarks" />
                  </Grid>
                  <Grid item xs={12} sm={7} style={{ paddingTop: 20 }}>
                    <CustomTextArea {...remarks} onchange={onChange} />
                  </Grid>

                  {/* ******************************* Declaration ***************************************** */}
                  <Grid item xs={12} mt={3}>
                    <Paper
                      elevation={0}
                      sx={{ padding: "3rem", borderRadius: "8px" }}>
                      <TitleText title="Declaration" />
                      <FormDivider />
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center", // Center vertically
                            gap: 2, // Adjust the gap as needed
                            padding: 2, // Add padding for better visualization
                            //border: "1px solid #ccc", // Add border for better visualization
                            justifyContent: "flex-start",
                          }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checked}
                                onChange={handleChange}
                                color="success"
                                sx={{
                                  marginTop: -1, // Adjust the negative margin to move the Checkbox up
                                  marginRight: -4,
                                }}
                              />
                            }
                            // label={<ValueText title="I Agree" />}
                          />
                          <CaptionText
                            title="All details have been verified with the service record and all documents have been verified with the original documents of the claim and found correct."
                            sx={{
                              margin: 0, // Reset margin for better alignment
                            }}
                          />
                        </Box>
                      </Grid>
                    </Paper>
                  </Grid>
                  {/* *************** Submit Button ********* */}
                  <Grid
                    item
                    xs={12}
                    sm="auto"
                    sx={{ justifyContent: "center" }}>
                    <Button
                      onClick={handleProcessOk}
                      variant="contained"
                      color="success"
                      disabled={isSubmitting}
                      //fullWidth
                      type="submit">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {showingDropdown === 1 && (
              <Grid
                container
                justifyContent="end"
                alignItems={"center"}
                spacing={2}>
                <Grid item xs={12} sm={4}>
                  <CustomDropdown {...assignTo} onchange={onChange} />
                </Grid>
                <Grid item md={1}>
                  <Button
                    onClick={handleSubmitOk}
                    variant="contained"
                    color="success"
                    type="submit"
                    disabled={isSubmitting}
                    fullWidth>
                    {isSubmitting ? <CircularProgress /> : "OK"}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default AdvFormPrefilled;
