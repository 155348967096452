import React, { useState, useEffect } from "react";
import axios from "axios";
import TableWithLinkAndButton from "../../FormComponent/TableWithLinkAndButton";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import { Tabs, Tab, Container, Grid, Paper, Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ClaimViewPFPension from "../../FormComponent/ClaimViewTablePFPension";
import jwtDecode from "jwt-decode";
import { getToken } from "../../UiManager/SessionChecker";

const PfSettlementRh = (props) => {
  const defaultSubmittedClaims = {
    claimId: "",
    cmpfAcNo: "",
    name: "",
    unitCode: "",
    submissionDate: "",
    remarks: "",
  };

  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [titleName, setTitleName] = useState("");
  const [tabData, setTabData] = useState({
    received: [defaultSubmittedClaims],
    processed: [defaultSubmittedClaims],
    returned: [defaultSubmittedClaims],
    settledclaim: [defaultSubmittedClaims],
    closed: [defaultSubmittedClaims],
  });
  // let loginId = "RH-RO-OFF-450"; // Assuming a default loginId
  //const [loginId, setLoginId] = useState();

  ///********fetching and setting token********* */
  // useEffect(() => {
  //   let token = localStorage.getItem("jwt");
  //   if (token) {
  //     token = token.replace('"', "").replace('"', "");
  //     let loginId = jwtDecode(token).user; // Assuming a default loginId
  //     setLoginId(loginId);
  //     console.log("loooooooooooooooooooooginId", loginId);
  //     // Add any further logic here based on the loginId
  //   } else {
  //     // Handle the case when token is not present
  //     // setIsFailed(true);
  //     // setIsLoading(false);
  //   }
  // }, []);
  const [pageLink, setPageLink] = useState("viewpfclaimrh");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setPageLink("viewpfclaimrh");
    } else if (newValue === 1) {
      setPageLink("proceedpfclaim");
    } else if (newValue === 2) {
      setPageLink("proceedpfclaim");
    } else if (newValue === 3) {
      setPageLink("revokepfclaimrh");
    } else if (newValue === 4) {
      setPageLink("proceedpfclaim");
    }
  };

  let type = "";

  const location = useLocation();

  if (location.pathname === "/rh/pfset") {
    type = "PF";
  } else if (location.pathname === "/rh/penset") {
    type = "PN";
  }
  let api = {
    claimUrl: "",
  };

  const pfApi = {
    claimUrl: "/show/all/pf/claim/to/all/officers",
  };
  const penApi = {
    claimUrl: "/show/all/pen/claim/to/all/officers",
  };
  const comboApi = {
    claimUrl: "/show/all/combo/claim/to/all/officers",
  };

  useEffect(() => {
    let newTitle = "";
    if (type === "PF") {
      api = { ...pfApi };
      newTitle = "PF";
    } else if (type === "PN") {
      api = { ...penApi };
      newTitle = "Pension";
    } else if (type === "PP") {
      api = { ...comboApi };
      newTitle = "PF & Pension";
    }
    setTitleName(newTitle);
    // ... other logic related to the API
  }, [type]); // Don't forget to include type as a dependency to ensure the useEffect runs when type changes

  useEffect(() => {
    fetchReceived();
  }, [location.pathname]);

  const fetchReceived = async () => {
    try {
      setIsLoading(true);
      let token = getToken();
      console.log("token on rh", token);
      if (!token) {
        setIsLoading(false);
        //setIsFailed(true);
        return;
      }
      token = token.replace('"', "").replace('"', "");
      const loginId = jwtDecode(token).user;
      console.log("loginId on rh", loginId);
      const url = process.env.REACT_APP_SERVER_URL + api.claimUrl;
      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          // Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);

      if (response.status === 200) {
        if (type === "PF") {
          setTabData((prevTabData) => ({
            ...prevTabData,
            received: response.data.data.AssignedList,
            processed: response.data.data.ForwardList,
            returned: response.data.data.ReturnList,
            settledclaim: response.data.data.ReadyForPaymentByCA,
            closed: response.data.data.RevokedByRH,
          }));
        } else if (type === "PN") {
          setTabData((prevTabData) => ({
            ...prevTabData,
            received: response.data.data.AssignedList,
            processed: response.data.data.ForwardList,
            returned: response.data.data.ReturnList,
            settledclaim: response.data.data.ReadyForPaymentByIPDS,
            closed: response.data.data.RevokedByRH,
          }));
        }
      } else {
        console.error("Failed to fetch claims. Status: " + response.status);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error - received", error);
      return [];
    }
  };

  const createTablePreviewData = () => {
    let tablePreviewData = [];

    selectedTabData.forEach((pf) => {
      tablePreviewData.push({
        "Claim ID": pf.claimId,
        "Name of the Claimant": pf.fullName,
        "CMPF A/C NO.": pf.cmpfAccNo,
        "Unit Code": pf.unitCode,
        // "Claim Submission Date": pf.submissionDate,
        "Claim Submission Date": pf.submissionDate
          ? new Date(pf.submissionDate).toLocaleDateString(
              "en-GB"
              // options
            )
          : "",
        Remarks: pf.remarks,
      });
    });

    return tablePreviewData;
  };

  const selectedTabData =
    tabData[
      value === 0
        ? "received"
        : value === 1
        ? "processed"
        : value === 2
        ? "returned"
        : value === 3
        ? "settledclaim"
        : value === 4
        ? "closed"
        : ""
    ] || [];

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            <Grid item xs={12}>
              <TitleText title={titleName + " Claims"} />
            </Grid>
            <FormDivider />
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <Container>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      // indicatorColor="#000000"
                      // textColor="#000000"
                    >
                      <Tab
                        label="Received"
                        style={{
                          backgroundColor: value === 0 ? "#831f76" : "", //bisqu
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          color: value === 0 ? "#ffffff" : "#000000", // Set text color to white when selected
                        }}
                      />
                      <Tab
                        label="Processed"
                        style={{
                          backgroundColor: value === 1 ? "#831f76" : "",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          color: value === 1 ? "#ffffff" : "#000000", // Set text color to white when selected
                        }}
                      />
                      <Tab
                        label="Returned"
                        style={{
                          backgroundColor: value === 2 ? "#831f76" : "",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          color: value === 2 ? "#ffffff" : "#000000", // Set text color to white when selected
                        }}
                      />
                      <Tab
                        label="settled "
                        style={{
                          backgroundColor: value === 3 ? "#831f76" : "",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          color: value === 3 ? "#ffffff" : "#000000", // Set text color to white when selected
                        }}
                      />
                      <Tab
                        label="Revoked"
                        style={{
                          backgroundColor: value === 4 ? "#831f76" : "",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          color: value === 4 ? "#ffffff" : "#000000", // Set text color to white when selected
                        }}
                      />
                    </Tabs>
                  </Grid>
                  <Grid item xs={12} marginTop={-2}>
                    <Box
                      p={3}
                      style={{
                        backgroundColor: "#fffaff",
                        border: "1px solid #831f76",
                      }}
                    >
                      <TableWithLinkAndButton
                        preview={createTablePreviewData()}
                        data={selectedTabData}
                        passingId="Pf ID"
                        viewLink={pageLink}
                        parentData={value}
                        size="small"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default PfSettlementRh;
