/**
 * SearchMember is a page which enables search functionality access by CMPFO & CC Officials.
 * view consent(CcRepPages) copied and made required changes.
 * TableWithLinkAndButton(FormComponent) ,PfStatement and document given to add TableWithLinkAndButton.jsx to SearchMember.jsx
 */
import React, { useState, useEffect, useContext } from "react";
//imported by vidhuri
import jwtDecode from "jwt-decode";
import { UserContext } from "../../UiManager/UserProvider";
//imported by vidhuri

import {
  Grid,
  Paper,
  Autocomplete,
  TextField,
  CircularProgress,
  Button,
  Typography,
} from "@mui/material";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import SubtitleText from "../../FormComponent/SubtitleText";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import LabelText from "../../FormComponent/LabelText";
import { validateSession } from "../../session/EventManager";
import SearchIcon from "@mui/icons-material/Search";
import Search from "@mui/icons-material/Search";
import CustomTextField from "../../FormComponent/CustomTextField";
import FormTable from "../../FormComponent/FormTable";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import axios from "axios";
import { Message } from "@mui/icons-material";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import TableWithLinkAndButton from "../../FormComponent/TableWithLinkAndButton";
import MemberDetails from "./Fetch/MemberDetailsSearch";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { useLocation } from "react-router-dom";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function SearchMember() {
  const defaultController = {
    controller: "cmpfAccNo",
    searchParam: "",
  };

  const [searchControl, setSearchControl] = useState(defaultController);

  // const tableTitles = [
  //   "Title",
  //   "Full Name",
  //   "Father/Husband Name",
  //   "CMPF Account Number",
  //   " Colliery ID",
  //   "Colliery Name",
  //   "Coal Company Name",
  // ];
  // const leftAlign = [];

  const [errorMessageAlert, setErrorMessageAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isDataPresent, setIsDataPresent] = useState(false);
  const [tableMessage, setTableMessage] = useState("");
  const [isRefresh, setIsRefresh] = useState(false);
  const [errorMessageAlert1, setErrorMessageAlert1] = useState(false);
  const [errorMessage1, setErrorMessage1] = useState("");

  const defaultTableData = [
    // from postman img
    // first create this default object from postman image
    // default values must be null
    {
      titleName: "",
      fullName: "",
      fatherHusbandName: "",
      cmpfAccNo: "",
      unitId: "",
      unitName: "",
      name: "",
    },
  ];
  // this is the state where we will store the data fetched from the backend
  // and this data we are going to send to the TableWithButtonAndLink

  const [tableData, setTableData] = useState(defaultTableData);

  // Here we are defining the buttons to be displayed on the Table

  const location = useLocation();
  console.log("locationnnn", location);
  const path = location.pathname;
  console.log("path", location.pathname);

  let submitTableActions;

  if (path.includes("updatemember")) {
    submitTableActions = {
      viewAction: {
        action: "view",

        icon: (
          <VisibilityRoundedIcon
            fontSize="small"
            sx={{ color: "black", margin: "0px 5px" }}
            // viewLink="view"
          />
        ),
      },
      editAction: {
        action: "edit",
        icon: (
          <EditRoundedIcon
            fontSize="small"
            sx={{ color: "black", margin: "0px 5px" }}
          />
        ),
      },
    };
  } else {
    submitTableActions = {
      viewAction: {
        action: "view",

        icon: (
          <VisibilityRoundedIcon
            fontSize="small"
            sx={{ color: "black", margin: "0px 5px" }}
            // viewLink="view"
          />
        ),
      },
      // editAction: {
      //   action: "edit",
      //   icon: (
      //     <EditRoundedIcon
      //       fontSize="small"
      //       sx={{ color: "black", margin: "0px 5px" }}
      //     />
      //   ),
      // },
    };
  }

  // these are dummy objects that are used only till we have not integrated the backend

  const memberSample = [
    {
      titleName: "Mr",
      fullName: "E Rajanna",
      fatherHusbandName: "E Rajamallu",
      cmpfAccNo: "HYD/170/840",
      unitId: 687,
      unitName: "M.D.OFFICE",
      name: "SCCL",
    },
    {
      titleName: "Mrs",
      fullName: "geet",
      fatherHusbandName: "meet",
      cmpfAccNo: "HYD/170/841",
      unitId: 682,
      unitName: "M.D.OFFICE",
      name: "SCCL",
    },
  ];

  // this is the function we are passsing to the TableWithButtonAndLink Component it creates the Table Preview
  const memberCreatePreviewData = () => {
    let tablePreviewData = [];

    tableData.map((member) => {
      /// write tableData here as we are taking data in tableData
      tablePreviewData.push({
        Title: member.titleName,
        "Full Name": member.fullName,
        "Father/Husband Name": member.fatherHusbandName,
        "CMPF Account Number": member.cmpfAccNo,
        "Colliery Code": member.unitId,
        "Colliery Name": member.unitName,
        "Coal Company": member.name,
      });
    });

    return tablePreviewData;
  };

  // As the backed is supposed to provide title and not title ID
  // changes should be done accordingly when the backend is updated

  // const title = [
  //   { id: 1, title: "Mr" },
  //   { id: 2, title: "Mrs" },
  //   { id: 3, title: "" },
  // ];

  // the props we are sending to the CustomDropDown component

  const searchDropdown = {
    name: "controller",
    // label: "salutation",
    helperText: "",
    disabled: false,
    initialValue: searchControl.controller,
    options: [
      { id: "cmpfAccNo", title: "CMPF Account Number" },
      { id: "aadhaarNumber", title: "Aadhaar Number" },
      { id: "panNumber", title: "PAN" },
      { id: "mobileNumber", title: "Mobile Number" },
      { id: "fullName", title: "Name" },
    ],
  };

  // create text props for all the feilds
  const accountNumberField = {
    name: "searchParam",
    type: "text",
    helperText: "",
    placeholder: "Enter account number",
    defaultValue: searchControl.searchParam,
    errorMessage: "Invalid Account No.",
    //pattern: "^[a-zA-Z0-9/]{1,20}$",
    pattern: "^[A-Z0-9/]{1,14}$",

    required: false,
    disabled: false,
  };
  const aadhaarNumberField = {
    name: "searchParam",
    type: "text",
    helperText: "",
    placeholder: "Enter aadhaar number",
    defaultValue: searchControl.searchParam,
    errorMessage: "",
    pattern: "",
    required: false,
    disabled: false,
  };
  const PANField = {
    name: "searchParam",
    type: "text",
    helperText: "",
    placeholder: "Enter Pan",
    defaultValue: searchControl.searchParam,
    errorMessage: "",
    pattern: "",
    required: false,
    disabled: false,
  };
  const MobileNumberField = {
    name: "searchParam",
    type: "text",
    helperText: "",
    placeholder: "Enter Mobile Number",
    defaultValue: searchControl.searchParam,
    errorMessage: "",
    pattern: "",
    required: false,
    disabled: false,
  };
  const NameField = {
    name: "searchParam",
    type: "text",
    helperText: "",
    placeholder: "Enter Name",
    defaultValue: searchControl.searchParam,
    errorMessage: "",
    pattern: "",
    required: false,
    disabled: false,
  };

  const onChange = (e) => {
    // console.log(e.target.value);
    setIsDataPresent(false);
    setTableMessage(""); // this we would be useing later
    setSearchControl({
      ...searchControl,
      [e.target.name]: e.target.value,
    });
    //console.log("value changed", e.target.name, e.target.value);
    // }
    // setSearchControl({
    //   ...searchControl,
    //   [searchControl.searchParam]: "",
    // });

    // setSearchControl({
    //   ...searchControl,
    //   [e.target.name]: e.target.value.toUpperCase(),
    // });
  };

  const validateAccountNo = (accountNo) => {
    const regex = /^[a-zA-Z0-9/]{1,20}$/;
    return regex.test(accountNo);
    //return true when password met the criteria
  };

  const validate = () => {
    if (!searchControl.searchParam) {
      setErrorMessage("Please Enter all the Fields");
      setErrorMessageAlert(true);
      return false;
    }
    // here we would be adding validations for every feilds just change the "2" with the strings we are sending to the backend
    if (searchControl.controller === "2") {
      if (!validateAccountNo(searchControl.searchParam)) {
        console.log("invalid accno");
        setErrorMessage("Please Enter Valid Account Number");
        setErrorMessageAlert(true);
        return false;
      }
    }
    return true;
  };

  const handleAlert = () => {
    setErrorMessageAlert(false);
  };
  const handleAlert1 = () => {
    setErrorMessageAlert1(false);
    // window.location.reload();
  };

  const handleSearch = async (e) => {
    // this the fuction which is called on clicking the search button
    e.preventDefault();
    // if (validate()) {
    // console.log("validation true");
    // setIsDataPresent(false);
    // setTableMessage("");
    // setIsSearching(true);
    searchMemberDetails(); // here we are calling the method which is going to hit the api and set the data from the backend
    setIsDataPresent(true);
    // setTableData(memberSample);

    // if (searchControl.controller === "1") {
    //   //console.log("searching on unitno");
    //   fetchConsentUnitNo();
    // } else if (searchControl.controller === "2") {
    //   fetchConsentAccNo();
    //   //console.log("searching on accountNo");
    // }
    // }

    //console.log("search param", searchControl);
  };
  const searchMemberDetails = async () => {
    // if (!(await sessionExist())) {
    //   console.log("session extending having trouble");
    //   //setIsFailed(true);
    //   //setIsLoading(false);
    //   return;
    // }
    // let token = localStorage.getItem("jwt");

    // if (!token) {
    //   //setIsFailed(true);
    //   //setIsLoading(false);
    //   console.log("token not found");
    //   return;
    // }
    // token = token.replace('"', "").replace('"', "");

    const url = //here we are setting the url  for the api
      process.env.REACT_APP_SERVER_URL + "/search/searchmemberbycategory";
    // here were are setting the data we need to send to the api to get the response
    const formData = new FormData();
    formData.append("searchCategory", searchControl.controller);
    formData.append("searchFieldValue", searchControl.searchParam);

    // here  we re setting the config that is when session would be active we would be sendig the token from here
    // also we are sending what type of content we are sending to api like here we are sending multipart/formdata

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    // this is the real api request we are making with the parameters url, formData, config we set above
    axios
      .post(url, formData, config)
      // if the response is successfull the code under .then runs this is called promise (Kindly read about promises in JavaScript)
      .then(function (response) {
        console.log("response ", response); // here we are consoling how the data from the api is comming in the console
        setIsSearching(false);
        const responseData = response.data.data; // we are setting the data from the api to a temp variable the response.data.data depends upon how the data is comming from the api You need to check it from the console
        console.log("responseData", responseData);

        if (responseData.length !== 0) {
          // here we are checking weateher the data is empty or not  if not then execute the following code
          setIsDataPresent(true);

          setTableData(responseData);
        } else {
          // if the data is empty display the following message
          setTableMessage("No matching records found");
          setTableData(defaultTableData);
        }
      })

      .catch(function (error) {
        console.log("error-unitNo", error);
        setErrorMessage1("Member Not Found");
        setErrorMessageAlert1(true);
      });
  };

  const sessionExist = async () => {
    const status = await validateSession();
    console.log("session status", status);
    return status;
  };

  //code for the fetching officer name
  //code inserted by vidhuri here
  const userContext = useContext(UserContext);
  const fetchOfficerData = async (signal) => {
    // setIsLoading(true); isko define bhi krna hai
    if (!(await sessionExist())) {
      console.log("session extending having trouble");
      // setIsLoading(false);
      // setIsFailed(true);
      //inko bhi define krna hai
      return;
    }

    let token = localStorage.getItem("jwt");
    console.log("token", token);
    if (!token) {
      return;
    }
    token = token.replace('"', "").replace('"', "");
    console.log("tokentoken", token);
    console.log("jwtDecode(token).user", jwtDecode(token).user);
    const formData = new FormData();
    formData.append("loginId", jwtDecode(token).user);
    const url =
      process.env.REACT_APP_SERVER_URL + "/get/offcr/details/dashboard";

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      signal: signal,
    };
    try {
      const response = await axios.post(url, formData);
      const data = response.data.data[0];
      console.log("data hai ye mere wala", data.name);
      updateUser(data);

      // data.currPeriodEnd = new Date(data.currPeriodEnd).getFullYear();
      // setAccountSummary(data);
      // setIsLoading(false);
      // setIsFailed(false);
      console.log("Vidhuri JI", response.data.data);
    } catch {}
  };
  useEffect(() => {
    fetchOfficerData();
  }, []);

  function updateUser(data) {
    const currentUser = useContext.currentUser;
    userContext.updateUser({
      ...currentUser,
      name: `${data.name} (${data.shName})`,
    });
  }

  return (
    <>
      <ErrorAlert
        handleOk={handleAlert}
        open={errorMessageAlert}
        title="Error!"
        body={errorMessage}
        buttonName="Ok"
      />

      <ErrorAlert
        handleOk={handleAlert1}
        open={errorMessageAlert1}
        title="Error!"
        body={errorMessage1}
        buttonName="Ok"
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            <Grid item xs={12}>
              <TitleText title="Search Member's Details" />
            </Grid>
            <FormDivider />

            {/* Form Wrapper grid */}
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              rowGap={2}>
              {/* Form Divider main Grid */}
              <Grid
                container
                justifyContent="space-between"
                alignItems="flex-start"
                rowGap={2}>
                {/* section 1 column 1 - regional*/}
                <Grid item xs={12} sm={4}>
                  <Grid container justifyContent="space-between" rowGap={2}>
                    {/* row */}
                    <Grid item xs={12} sm={3}>
                      <LabelText title="Search By" />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <CustomDropdown {...searchDropdown} onchange={onChange} />
                    </Grid>
                  </Grid>
                </Grid>

                {/* section 1 column 2 - regional*/}
                <Grid item xs={12} sm={7}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    rowGap={2}>
                    {searchControl.controller === "cmpfAccNo" && (
                      <Grid item xs={12} sm={7}>
                        <CustomTextField
                          {...accountNumberField}
                          onchange={onChange}
                        />
                      </Grid>
                    )}
                    {searchControl.controller === "aadhaarNumber" && (
                      <Grid item xs={12} sm={7}>
                        <CustomTextField
                          {...aadhaarNumberField}
                          onchange={onChange}
                        />
                      </Grid>
                    )}

                    {searchControl.controller === "panNumber" && (
                      <Grid item xs={12} sm={7}>
                        <CustomTextField {...PANField} onchange={onChange} />
                      </Grid>
                    )}
                    {searchControl.controller === "mobileNumber" && (
                      <Grid item xs={12} sm={7}>
                        <CustomTextField
                          {...MobileNumberField}
                          onchange={onChange}
                        />
                      </Grid>
                    )}
                    {searchControl.controller === "fullName" && (
                      <Grid item xs={12} sm={7}>
                        <CustomTextField {...NameField} onchange={onChange} />
                      </Grid>
                    )}

                    <Grid item xs={12} sm={4}>
                      <Button
                        disabled={isSearching}
                        onClick={handleSearch}
                        variant="contained"
                        startIcon={<Search />}
                        color="primary"
                        // disabled={true}
                        fullWidth
                        type="submit">
                        {isSearching ? (
                          <CircularProgress color="secondary" />
                        ) : (
                          "Search"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* Form Divider main Grid end */}
              {isDataPresent ? (
                <Grid item xs="auto" sm={12}>
                  {/* <FormTable
                    titles={tableTitles}
                    leftAlign={leftAlign}
                    comma={[]}
                    // data={memberSample}
                    
                    
                    //data={tableData}
                  />{" "} */}
                  <TableWithLinkAndButton
                    preview={memberCreatePreviewData()}
                    data={tableData}
                    passingId="memberId"
                    actions={submitTableActions}
                    viewLink=""
                  />
                  {/* <TableWithLinkAndButton
                    preview={memberCreatePreviewData()}
                    data={tableData}
                    passingId="memberId"
                    actions={submitTableActionsUpdate}
                    viewLink=""
                  /> */}
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Arial sans-serif",
                      fontSize: {
                        xs: "10px",
                        sm: "11px",
                        md: "12px",
                        lg: "14px",
                        xl: "15px",
                      },
                      fontWeight: "500",
                    }}
                    color={"#1c632f"}
                    align="left">
                    {/* {tableMessage} */}
                  </Typography>
                </Grid>
              )}
            </Grid>
            {/* Form Wrapper grid end*/}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default SearchMember;
