import React from 'react';
import ValueText from './ValueText';

function AmountFormatter({ amount }) {
  const formattedAmount = (Number(amount)).toLocaleString('en-IN', {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'INR'
  });

  return (
   <ValueText title={formattedAmount.replace('₹', '₹ ')} />
  );
}

export default AmountFormatter;
