import React from "react";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import {
  Button,
  Grid,
  Paper,
  Tab,
  Tabs,
  Box,
  Container,
  CircularProgress,
} from "@mui/material";
import SuccessAlert from "../../FormComponent/SuccessAlert";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import { useState, useEffect } from "react";
import axios from "axios";
import TableWithLinkAndCheckbox from "../../FormComponent/TableWithLinkAndCheckbox";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import TablewithLinks from "../../FormComponent/TablewithLinks";
import ConfirmationDialogue from "../../FormComponent/ConfirmationDialogue";
import jwtDecode from "jwt-decode";
import { getToken } from "../../UiManager/SessionChecker";
import { useLocation, useNavigate } from "react-router-dom";

const PensionIds = () => {
  const defaultClaims = [
    {
      Type: "",
      activityDate: "",
      claimId: "",
      cmpfAcNo: "",
      name: "",
      remarks: null,
      statusId: 0,
      submissionDate: "",
      type: "",
      unitCode: "",
    },
  ];

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [Tabvalue, setTabvalue] = useState(0);
  const [claims, setClaims] = useState(defaultClaims);
  const [isloading, setIsLoading] = useState(true);
  // const [selectedRoda, setSelectedRoda] = useState({ id: "", title: "" });
  const [selectedRoda, setSelectedRoda] = useState({});

  const [rodaList, setRodaList] = useState([]);
  //const [selectedClaim, setSelectedClaim] = useState(null)
  const [selectedClaims, setSelectedClaims] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedRodaName, setSelectedRodaName] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [tabData, setTabData] = useState({
    received: [],
    processed: [],
  });
  const [resetSelectedClaims, setResetSelectedClaims] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  //const loginId = "IDS-RO-OFF-369";
  // let token = token.replace('"', "").replace('"', "");
  // let loginId = jwtDecode(token).user; // Assuming a default loginId
  const [loginId, setLoginId] = useState();

  /********fetching and setting token********* */
  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (token) {
      token = token.replace('"', "").replace('"', "");
      let loginId = jwtDecode(token).user; // Assuming a default loginId
      setLoginId(loginId);
      console.log("loooooooooooooooooooooginId", loginId);
      // Add any further logic here based on the loginId
    } else {
      // Handle the case when token is not present
      setIsLoading(false);
      //setIsFailed(true);
    }
  }, [location.pathname, successOpen]);

  console.log("loooooooooooooooooooooginId", loginId);
  const fetchData = async () => {
    try {
      setIsLoading(true);
      let token = getToken();
      console.log("token on ids", token);
      if (!token) {
        setIsLoading(false);
        //setIsFailed(true);
        return;
      }
      token = token.replace('"', "").replace('"', "");
      const loginId = jwtDecode(token).user;
      console.log("loginId on ids", loginId);
      //we will  Make a GET request to retrieve data from the backend
      const url =
        process.env.REACT_APP_SERVER_URL +
        "/show/all/pen/claim/to/all/officers";
      const formdata = new FormData();

      formdata.append("loginId", loginId);

      const config = {
        headers: {
          // Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formdata, config);
      if (response.status === 200) {
        setTabData((prevTabData) => ({
          ...prevTabData,
          processed: response.data.data.ForwardList,
          received: response.data.data.AssignedList,
        }));
      }
      setResetSelectedClaims(false);
      console.log("ids claims pension", response.data.data);
      // Assuming the response data is an array of claims
      //setLoading(false); // Update the loading state once data is fetched
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  useEffect(() => {
    // Load received claims
    fetchData();
  }, [location.pathname, successOpen]);

  useEffect(() => {
    fetchRodas(); // Call the fetchRodas function when the component mounts
  }, [location.pathname, successOpen]); //

  const fetchRodas = async () => {
    try {
      setIsLoading(true);
      let token = getToken();
      console.log("token on ids officer fetch", token);
      if (!token) {
        setIsLoading(false);
        //setIsFailed(true);
        return;
      }
      token = token.replace('"', "").replace('"', "");
      const loginId = jwtDecode(token).user;
      console.log("loginId on ids officer fetch", loginId);
      const url =
        process.env.REACT_APP_SERVER_URL +
        "/pension/claims/get/rodalist/for/ids";
      const formdata = new FormData();
      formdata.append("loginId", loginId);

      const response = await axios.post(url, formdata);
      const data = response.data.data;
      const rodaSamples = data.map((option) => ({
        id: option.loginId,
        title: `${option.name} (${option.loginId})`,
      }));
      setRodaList(rodaSamples);

      // setLoading(false); // Update the loading state once data is fetched
    } catch (error) {
      console.error("Error fetching RODA list:", error);
    }
  };

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const options = { day: "2-digit", month: "2-digit", year: "numeric" };

  const createTablePreviewDataSubmit = () => {
    let tablePreviewData = [];
    selectedTabData.map((claim) => {
      tablePreviewData.push({
        "Claim ID": claim.claimId,
        "Claimant Name": claim.fullName,
        "CMPF Account No": claim.cmpfAccNo,

        "Unit Code": claim.unitCode,
        "Claim Submission Date": claim.submissionDate
          ? new Date(claim.submissionDate).toLocaleDateString("en-GB", options)
          : "",

        Remarks: claim.remarks,
      });
    });

    return tablePreviewData;
  };

  const selectedTabData =
    tabData[
      Tabvalue === 0
        ? "received"
        : Tabvalue === 1
        ? "processed"
        : Tabvalue === 2
        ? "returned"
        : ""
    ] || [];

  const rodaDropdown = {
    name: "",
    // label: "salutation",
    helperText: "",
    disabled: false,
    initialValue: "",
    // options: options,
    options: rodaList,

    placeholder: "select",
  };

  const handleCheckboxChange = (claimId) => {
    setSelectedClaims((prevSelectedClaims) => {
      const isSelected = prevSelectedClaims.includes(claimId);

      if (isSelected) {
        // If claimId is already selected, remove it from the array
        return prevSelectedClaims.filter((id) => id !== claimId);
      } else {
        // If the checkbox is checked, add the claimId to the selectedClaims
        return [...prevSelectedClaims, claimId];
      }
    });
  };

  const handleSelectedChange = (selectedClaims) => {
    setSelectedClaims(selectedClaims);
  };

  const handleDropdownChange = (event) => {
    const selectedOption = rodaList.find(
      (option) => option.id === event.target.value
    );

    setSelectedRoda({
      id: event.target.value,
      title: selectedOption ? selectedOption.title : "",
    });
  };

  const handleAssign = async () => {
    if (selectedClaims.length === 0) {
      setErrorOpen(true);
      setSuccessOpen(false);
      setErrorMessage("Please select at least one claim to assign.");
      return;
    } else if (Object.keys(selectedRoda).length === 0) {
      setErrorOpen(true);
      setSuccessOpen(false);
      setErrorMessage("Please select RODA first.");
      return;
    } else {
      setIsSubmitting(true);
      // Open the confirmation dialog
      setDialogOpen(true);
    }
  };

  const handleConfirmAssign = async () => {
    // Close the confirmation dialog
    setDialogOpen(false);

    try {
      // setIsLoading(true);
      // let token = getToken();
      // console.log("token on ids submit", token);
      // if (!token) {
      //   setIsLoading(false);
      //   //setIsFailed(true);
      //   return;
      // }
      // token = token.replace('"', "").replace('"', "");
      // const loginId = jwtDecode(token).user;
      console.log("loginId on ids submit", loginId);
      const claimsIdsSelected = selectedClaims.map((claim) => claim.claimId);
      const formdata = {
        claimId: claimsIdsSelected,
        toLoginId: selectedRoda.id,
        fromLoginId: loginId,
      };
      console.log("Formdata", formdata);
      const url =
        process.env.REACT_APP_SERVER_URL + "/pension/claims/assign/by/ids/roda";
      const headers = {
        "Content-Type": "application/json",
      };
      const response = await axios.post(url, formdata, { headers });

      setIsLoading(false);
      setSuccessMessage("Claims assigned successfully.");
      setSelectedClaims("");
      setSuccessOpen(true);
      setIsSubmitting(false);
    } catch (error) {
      console.error("Error on post request:", error);
      setIsSubmitting(false);
    } finally {
      handleAlertClose();
    }
  };

  const handleAlertClose = () => {
    setErrorOpen(false);
  };
  const handleSuccessAlertClose = () => {
    setSuccessOpen(false);
    setResetSelectedClaims(true);
    navigate("/ids/penset");
  };

  const [pageLink, setPageLink] = useState("viewpenclaimids");
  const handleChange = (event, newValue) => {
    setTabvalue(newValue);

    if (newValue === 0) {
      setPageLink("viewpenclaimids");
    } else if (newValue === 1) {
      setPageLink("proceedpenclaim");
    } else if (newValue === 2) {
      setPageLink("viewpenclaimids");
    }
  };

  const handleCancelAssign = () => {
    // Close the confirmation dialog
    setDialogOpen(false);
    setIsSubmitting(false);
  };

  return (
    <div>
      <ErrorAlert
        open={errorOpen}
        handleOk={handleAlertClose}
        title="Error"
        body={errorMessage}
        buttonName="OK"
      />
      <SuccessAlert
        open={successOpen}
        handleOk={handleSuccessAlertClose}
        title="Success"
        body={successMessage}
        buttonName="OK"
      />
      <ConfirmationDialogue
        open={isDialogOpen}
        handleOk={handleConfirmAssign}
        handleCancel={handleCancelAssign}
        title="Confirmation"
        body={`Are you sure you want to assign these selected claims to ${
          selectedRoda ? `RODA: ${selectedRoda.title}` : "the selected RODA"
        }?`}
        buttonName="Confirm"
      />
      <div>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper
              elevation={2}
              sx={{ padding: "3rem", borderRadius: "8px", textAlign: "center" }}
            >
              <Grid item xs={12}>
                <TitleText title={`Pension Claims`} />
              </Grid>

              <FormDivider />
              <Grid item xs={12}>
                <Tabs
                  value={Tabvalue}
                  onChange={handleChange}
                  indicatorColor="#831f76"
                  textColor="primary"
                >
                  <Tab
                    label="Received"
                    style={{
                      backgroundColor: Tabvalue === 0 ? "#831f76" : "", //bisqu
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                      color: Tabvalue === 0 ? "#ffffff" : "#000000", // Set text color to white when selected
                    }}
                  />
                  <Tab
                    label="Processed"
                    style={{
                      backgroundColor: Tabvalue === 1 ? "#831f76" : "",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                      color: Tabvalue === 1 ? "#ffffff" : "#000000", // Set text color to white when selected
                    }}
                  />
                </Tabs>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: "#fffaff",
                  border: "2px solid #831f76",
                  padding: "24px 24px 24px 24px",
                }}
              >
                <Box elevation={3}>
                  {Tabvalue === 0 ? (
                    <TableWithLinkAndCheckbox
                      preview={createTablePreviewDataSubmit()}
                      data={selectedTabData}
                      passingId="claimId"
                      actions="Checkbox"
                      viewLink={pageLink}
                      size="small"
                      checkboxChange={handleCheckboxChange}
                      selected={selectedClaims}
                      onSelectedChange={handleSelectedChange}
                      resetSelectedClaims={resetSelectedClaims}
                    />
                  ) : Tabvalue === 1 ? (
                    <TablewithLinks
                      preview={createTablePreviewDataSubmit()}
                      data={selectedTabData}
                      passingId="claimId"
                      actions="viewLink"
                      viewLink={pageLink}
                      size="small"
                    />
                  ) : null}
                </Box>
              </Grid>
              <Grid container>
                <Grid xs={12} md={3} marginTop={"10px"}>
                  {Tabvalue === 0 && (
                    <CustomDropdown
                      {...rodaDropdown}
                      onchange={handleDropdownChange}
                      placeholder="Select RODA"
                      value={selectedRoda.id}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={1}>
                  {Tabvalue === 0 && (
                    <Button
                      fullWidth
                      sx={{ marginTop: "18px", marginLeft: "10px" }}
                      variant="contained"
                      color="success"
                      onClick={handleAssign}
                    >
                      {isSubmitting ? <CircularProgress /> : "Assign"}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default PensionIds;
