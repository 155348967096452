/**
 * @returns a table with Headers, first column as link, and buttons
 * props : preview , data, passingId,actions,viewLink
 * for table without buttons send actions prop as null form the parent
 * for table without the first column as link send viewLink as null
 * for further details refer the documentation */

import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
// import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
// import EditRoundedIcon from "@mui/icons-material/EditRounded";
// import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import Tooltip from "@mui/material/Tooltip";
import uuid from "react-uuid";
import { TablePagination, capitalize } from "@mui/material";
// import { createIconSetFromFontello } from "react-native-vector-icons";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#526D82",
    color: theme.palette.common.white,
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.3,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF9FF",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 1,
    backgroundColor: "#FFFFFF",
  },
}));

function ClaimViewPFPension(props) {
  const { preview, data, actions, isLink, size } = props;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  // const tableHeaders = Object.keys(preview[0]);
  const tableHeaders = preview && preview[0] ? Object.keys(preview[0]) : [];

  // if(actions==="") {
  //   setAreActions(false);
  // }

  function createHeader() {
    return (
      <TableRow>
        {tableHeaders.map((title) => (
          <StyledTableCell key={title} align="center">
            {title}
          </StyledTableCell>
        ))}
        {actions && <StyledTableCell align="center">Actions</StyledTableCell>}
      </TableRow>
    );
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function createRows() {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return preview.slice(startIndex, endIndex).map((row, index) => {
      let viewLink = ""; // Variable to store the value of viewLink

      // Add conditional logic to set the value of viewLink based on the first column value
      console.log("");
      if (row[tableHeaders[0]].startsWith("PF")) {
        viewLink = "/viewpfclaimaao";
      } else if (row[tableHeaders[0]].startsWith("PN")) {
        viewLink = "value2";
      } else if (row[tableHeaders[0]].startsWith("PP")) {
        viewLink = "value3";
      }
      // return preview.map((row,index) => {
      return (
        <StyledTableRow key={index}>
          {tableHeaders.map((key, i) => (
            <StyledTableCell key={uuid()} align="center">
              {i === 0 && isLink ? ( // check if it's the first column
                //   <Link to={`/claimdetails`}>{row[key]}</Link>
                //   :
                //   row[key]

                <Link to={viewLink} state={{ passingId: row[key] }}>
                  {row[key]}
                </Link>
              ) : (
                row[key]
              )}
            </StyledTableCell>
          ))}
          {actions && (
            <StyledTableCell key={uuid()} align="center">
              {Object.keys(actions).map((button) => (
                <Link
                  to={actions[button].action}
                  state={{
                    [actions[button].action]: true,
                    passedData: data[index],
                  }}
                >
                  <Tooltip title={capitalize(actions[button].action)} arrow>
                    {actions[button].icon}
                  </Tooltip>
                </Link>
              ))}
            </StyledTableCell>
          )}
          {/* <StyledTableCell key={uuid()} align="center">
                        <Link to='info' state={{ edit: false, view: true, passedData: data[index] }}><Tooltip title='View' arrow><VisibilityRoundedIcon fontSize='small' sx={{ color: 'black', margin: '0px 5px', }} /></Tooltip></Link> 
                        <Link to='edit' state={{ edit: true, view: false, passedData: data[index] }}><Tooltip title='Edit' arrow><EditRoundedIcon fontSize='small' sx={{ color: 'black', margin: '0px 5px' }} /></Tooltip></Link> 
                        <Link to='delete' ><Tooltip title='Delete' arrow><DeleteRoundedIcon fontSize='small' sx={{ color: 'red', margin: '0px 5px' }} /></Tooltip></Link> 
                    </StyledTableCell> */}
        </StyledTableRow>
      );
    });
  }

  return (
    <TableContainer>
      {/* <Table sx={{ minWidth: 700 }} aria-label="customized table"> */}
      <Table sx={{ minWidth: 650 }} size={size} aria-label="a dense table">
        <TableHead>
          {/* <TableRow> */}
          {createHeader()}
          {/* </TableRow> */}
        </TableHead>
        <TableBody>{createRows()}</TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={preview.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

export default ClaimViewPFPension;
