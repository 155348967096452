import React, { useContext, useRef, useState } from 'react';
import CaptchaBox from './CaptchaBox';
import { captchaVerifier, CaptchaVerifyContext } from './CaptchaProvider';
import { FormControl, TextField, InputLabel, Box, Grid, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    centeredLabel: {
        textAlign: "center",
    },
}));

function CaptchaForm(props) {

    const classes = useStyles();
    const captchaTextFieldRef = useRef('');
    const resetCaptchaContext = useContext(CaptchaVerifyContext);

    const [code, setCode] = useState('');
    const [resetCode, setResetCode] = useState(false);
    const captchaContext = useContext(captchaVerifier());

    const [captchaMessage, setCaptchaMessage] = useState('');
    const [captchaMessageColor, setCaptchaMessageColor] = useState(false);

    const handleResetCode = (e) => {
        setCode('');
        // reset captcha
        // e.target.value = ''
        resetCaptchaContext.resetCaptcha();
        setResetCode(true);
    };

    const handleSubmit = (e) => {
        console.log(e.target.value);
        if (!captchaContext.verifier(e.target.value)) {
            // captcha verification failed
            setCaptchaMessageColor(true);
            // if (e.target.value == '') {
            //     setCaptchaMessage('Captcha is Empty!! Please Enter Captcha');
            // } else {
            //     if ((e.target.value).length == 5) {
            //         setCaptchaMessage('Captcha is Invalid!! Please try again.');
            //         handleResetCode(e);
            //     }
            // }
            if ((e.target.value).length == 5) {
                setCaptchaMessage('Captcha is Invalid!! Please try again.');
                handleResetCode(e);
            } else if (e.target.value == '') {
                setCaptchaMessage('Captcha is Empty!! Please Enter Captcha');
            }
            else {
                setCaptchaMessage('Captcha will conatin 5 alphanumeric characters!!')
            }
            // setCaptchaMessage('Captcha is Invalid!! Please try again.');
            props.otpButtonDisableFuncDisable();
            props.handleVerifyCaptchaDisabled();
            props.disaableOTPClick()
        } else {
          
            // handleResetCode();
            // captcha verification succeeded
            setCaptchaMessageColor(false);
            setCaptchaMessage('Captcha Verified Successfully!!');
            // props.otpButtonDisableFuncEnable();
            props.handleVerifyCaptchaEnabled();
            // props.setIsMemberFormEnabled(false);
            props.buttonDisabledfunc()
        }
    };

    const handleFormInput = (e) => {
        e.preventDefault();
        setCode(e.target.value);
        // if ((e.target.value).length == 5) {
        //     console.log((e.target.value).length);
        //     console.log(e.target.value);
        //     handleSubmit(e)
        // }else{
        //     setCaptchaMessage('Captcha will conatin 5 alphanumeric characters!!')
        //     setCaptchaMessageColor(true);
        // }
        handleSubmit(e)
    }

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     if (!captchaContext.verifier(code)) {
    //         // captcha verification failed
    //         setCaptchaMessageColor(true);
    //         setCaptchaMessage('Captcha is Invalid!! Please try again.');
    //         handleResetCode();
    //         props.otpButtonDisableFuncDisable();
    //         props.handleVerifyCaptchaDisabled();
    //     } else {
    //         // captcha verification succeeded
    //         setCaptchaMessageColor(false);
    //         setCaptchaMessage('Captcha Verified Successfully!!');
    //         props.otpButtonDisableFuncEnable();
    //         props.handleVerifyCaptchaEnabled();
    //     }
    // };

    // useEffect(() => {
    //     if (props.isSendOTPClicked) {
    //         handleSubmit()
    //     }
    // }, [props.isSendOTPClicked]);

    const handleFocus = () => {
        captchaTextFieldRef.current.focus();
    }

    return (
        <Box>
            <Grid container spacing={2} columnGap={2}>
                <Grid item xs={12} sm={4} sx={{
                    display: 'flex',
                    justifyContent: { xs: 'start', sm: 'start' },
                }}>
                    <CaptchaBox width={100} height={20} />
                </Grid>

                <Grid item xs={12} sm={12} >
                    <TextField
                        autoComplete='off'
                        inputRef={captchaTextFieldRef}
                        fullWidth
                        label="Captcha"
                        placeholder='Enter Captcha'
                       variant="outlined"
                        value={code}
                        // onClick={handleFocus}
                        onChange={handleFormInput}
                        key={resetCode}
                    />
                </Grid>


                {/* <Grid item xs={12} sm={12} sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Button variant="outlined" color='primary' sx={{
                        marginTop: { xs: '1px', sm: '1px' },
                        borderRadius: '30px',
                    
                        borderWidth: '1px',
                        borderColor: '#1d00c1',
                        fontSize: '0.9rem',
                        
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: '#1d00c1',
                            color: '#eee',
                        },
                    }}
                        onClick={handleSubmit}
                    >
                        Verify
                    </Button>
                </Grid> */}
            </Grid>

            <Grid marginTop={2} sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Grid item>
                    <Typography variant="body2" color={captchaMessageColor ? '#EF0107' : '#17B169'}>
                        {/* Captcha Verified Successfully!! */}
                        {captchaMessage}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default CaptchaForm;