import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import LabelText from "../../FormComponent/LabelText";
import ValueText from "../../FormComponent/ValueText";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { PDFExport } from "@progress/kendo-react-pdf";
import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SucessAlert from "../../FormComponent/SuccessAlert";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

import CmpfoLogo from "../../images/cmpfo.png";
import { toWords } from "../../Utility/UtilityMethods";
// import "./pdfStyles.css";
const lineStyle = {
  width: "100%",
  marginLeft: "10px",
  marginRight: "20px",
  border: "1.5px solid black", // Dark color, you can customize this to your preferred dark shade
};

//   para: "Para 63(1)(a)",
//   videItemNo: "0000",
//   payOrderNo: "01234",
//   fullName: "E Rajanna",
//   fatherHusbandName: "E Rajamallu",
//   unitName: "M.D.Office",
//   joiningDate: "25-10-1990",
//   cmpfAccNo: "HYD/170/840",
//   dateOfCessation: "31-05-2023",
//   dateOfAppointment: "25-10-1990",
//   dateOfDeath: "00-00-0000",
//   membershipPeriod: "32 Years 07 Months",
//   ledgerBalance: 2231862.0,
//   payeeAddress: "test",
//   dateOfClaim: "23-08-2023",
//   dateOfBirth: "1987-10-12",
//   causeOfCessation: "SuperAnnuation",
//   claimTypDesc: "SuperAnnuation",
//   name: "BaijunathSharma",
//   relationName: "Brother",
//   sanctionedAmt: 0,
//   brokenPeriodInterestRate: 8,
//   brokenPeriodInterestPayable: 247043,
//   brokenPeriodContri: 180804,
//   brokenPeriodContriDet: null,

//   modeOfRemittance: "A/C PAYEE CHEQUE/BANK TRANSFER",
//   acc: "52196074233",
//   ifsc: "SBIN0020909",
//   bankName: "STATE BANK OF INDIA",
//   bankAddress: "Branch KALYANKHANI, SRINAGAR, GARHWAL, UTTARAKHAND, 246174",
//   postalAddress: "C/O KK-1, MANDAMARRI, MMR MANCHERIAL, Telangana,INDIA",
//   // feilds to be calculated
//   age: "60",
//   dateUptoWhichInterestIsPayable: "31-07-2023",
//   closingBalance: 2231862.0,
//   dateFrom: "",
//   dateTo: "31.03.2023",
//   rateOfInterest: 8.0,
//   brokenPeriodInterestPayableFrom: "01-04-2023",
//   brokenPeriodInterestPayableTo: "31-07-2023",
//   brokenPeriodContribution: 180804.0,
//   brokenPeriodInterestPayable: 247043.0,
//   amountBeforeDistribution: 0,
//   totalAmountpayable: 2659709.0,
//   refundDueDate,
// };

const PfDpo = () => {
  const { state } = useLocation();
  const data = state;
  console.log("data on dpo", data);

  const role = data.fromLoginId.substring(0, 2);
  const [track, setTrack] = useState([]);
  const claimId = data.claimId;
  const fetchtrack = async () => {
    try {
      const url =
        process.env.REACT_APP_SERVER_URL +
        "/get/officer/hirearchy/for/dpo/po/pf";
      const formData = new FormData();
      formData.append("claimId", claimId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const trackdata = response.data.data;

      setTrack(trackdata); // Assuming setAssignToOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };
  console.log("trackdata", track);

  useEffect(() => {
    fetchtrack();
  }, []);
  const desiredSequence = [
    "UDA",
    "UAO",
    "AAO",
    "IDS",
    "AGM",

    "DA",
    "SI",
    "BO",
    "RH",
  ];
  const filteredAndSortedTrack = desiredSequence
    .filter((role) => track.some((item) => item.roleName === role))
    .map((role) => track.find((item) => item.roleName === role));

  // console.log("roloooooooooe", role);
  return (
    <Grid container item lg={12} xs={6} style={{ border: "#de0101" }}>
      <Box mt={8}>
        {/* Dikhane ke baad ek try or alag alag 2 container bna kr 1 grid me  */}

        <Grid container item lg={12} xs={6} md={6} sm={6}>
          <Grid
            container
            spacing={2}
            // justifyContent="center"
            // style={{ margin: "6px" }}
            id="printableArea"
            // style={{ border: "2px solid black" }}
            xs={12}
            lg={12}
            md={12}
          >
            <Grid
              item
              xs={3}
              md={3}
              justifyContent="flex-end"
              container
              direction="column"
            >
              {/* <Typography variant="body1">Grievance ID</Typography> */}
              {/* <img
                src={CmpfoLogo}
                alt="Regional Commissioner Office"
                style={{ width: "100px", height: "100px" }}
              /> */}
            </Grid>

            <Grid
              item
              xs={8}
              md={8}
              container
              justifyContent={"start"}
              direction="column"
            >
              {/* <Typography variant="body1">{printableData.grievanceId}</Typography> */}
              {/* <Typography variant="h6" gutterBottom>
                    <span style={{ textDecoration: "underline" }}>
                      OFFICE OF THE REGIONAL COMMISSIONER <br />
                    </span>
                  </Typography> */}

              {/* <Typography variant="h6" gutterBottom>
                    <span
                      style={{
                        textDecoration: "underline",
                        fontFamily: "monospace",
                        fontWeight: "bold",
                      }}
                    >
                      COAL MINES PROVIDENT FUND, KOTHAGUDEM <br />
                    </span>
                  </Typography> */}

              <Typography
                variant="body2"
                paragraph
                style={{ marginLeft: "75%" }}
              >
                {/* Form No : {data.form_no} */}
                {/* {data.form_No} */}
                <br />
              </Typography>
              <Typography
                variant="body2"
                paragraph
                style={{ marginLeft: "75%" }}
              >
                {/* Date : {data.date} */}
                {/* {data.date} */}
                <br />
              </Typography>

              {/* <Typography variant="body1">{printableData.grievanceId}</Typography> */}
              <Typography variant="h6" gutterBottom>
                <span>
                  OFFICE OF THE REGIONAL COMMISSIONER <br />
                </span>
              </Typography>

              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  textDecoration: "underline",
                  fontFamily: "monospace",
                  fontWeight: "bold",
                }}
              >
                {`COAL MINES PROVIDENT FUND `}
              </Typography>

              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  textDecoration: "underline",
                  fontFamily: "monospace",
                  fontWeight: "bold",
                }}
              >
                {/* YHA REGION DALNA HAI */}
                {/* {" Asansol - II "} */}
                {data.roName}
              </Typography>
              <Typography variant="body2" paragraph>
                {"(Pay Order for refund Accumulation in C.M.P.F)"}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} marginTop={"15%"} style={{ margin: "6px" }}>
            <Grid item lg={12} textAlign={"start"}>
              <Typography
                variant="body2"
                paragraph
                style={{ marginRight: "60%" }}
              >
                Process Id : {data.proccessId}
              </Typography>
            </Grid>
            <Grid item lg={12} textAlign={"start"}>
              <Typography
                variant="body2"
                paragraph
                style={{ marginRight: "80%" }}
              >
                PO No: {data.payOrderNo}
              </Typography>
            </Grid>

            {/* yha se khel chalu bhai */}
            <Grid
              container
              item
              lg={12}
              textAlign={"center"}
              marginLeft={"15%"}
            >
              <Grid item lg={5} xs={5} md={5}>
                <LabelText title={"CMPF No."} />
              </Grid>
              <Grid item lg={1} xs={1} md={1}>
                :
              </Grid>
              <Grid item lg={6} xs={6} md={6}>
                <ValueText title={data.cmpfAccNo} />
              </Grid>

              {/* 2nd */}
              <Grid item lg={5}>
                <LabelText title={"Regd. No. of Coal Mines"} />
              </Grid>
              <Grid item lg={1}>
                :
              </Grid>
              <Grid item lg={6}>
                <ValueText title={data.unitCode} />
              </Grid>

              <Grid item lg={5}>
                <LabelText title={"Name of Unit"} />
              </Grid>
              <Grid item lg={1}>
                :
              </Grid>
              <Grid item lg={6}>
                <ValueText title={data.unitName} />
              </Grid>

              <Grid item lg={5}>
                <LabelText title={"Pay to"} />
              </Grid>
              <Grid item lg={1}>
                :
              </Grid>
              <Grid item lg={6}>
                <ValueText title={data.name} />
              </Grid>

              <Grid item lg={5}>
                <LabelText title={"Son of/Wife of/ Daughter of"} />
              </Grid>
              <Grid item lg={1}>
                :
              </Grid>
              <Grid item lg={6}>
                <ValueText title={data.fatherHusbandName} />
              </Grid>

              <Grid item lg={5}>
                <LabelText
                  title={"By credit to his/her savings Bank Account No"}
                />
              </Grid>
              <Grid item lg={1}>
                :
              </Grid>
              <Grid item lg={6}>
                <ValueText title={data.acc} />
              </Grid>

              <Grid item lg={5}>
                <LabelText title={"Sending At"} />
              </Grid>
              <Grid item lg={1}>
                :
              </Grid>
              <Grid item lg={6}>
                <ValueText title={data.bankAddress} />
              </Grid>
              <Grid item lg={5}>
                <LabelText title={"IFSC Code "} />
              </Grid>
              <Grid item lg={1}>
                :
              </Grid>
              <Grid item lg={6}>
                <ValueText title={data.ifsc} />
              </Grid>
              <Grid item lg={5}>
                <LabelText title={"1. Initial Amount"} />
              </Grid>
              <Grid item lg={1}>
                :
              </Grid>
              <Grid item lg={6}>
                <ValueText title={`Rs. ${data.sanctionedAmt.toLocaleString("en-IN")}`} />
              </Grid>

              <Grid item lg={5}>
                <LabelText title={"2. Subsequent Amount"} />
              </Grid>
              <Grid item lg={1}>
                :
              </Grid>
              <Grid item lg={6}>
                <ValueText title={data.subsequent_amount} />
                {/* < ValueText title = {}/> */}
              </Grid>

              <Grid item lg={5}>
                <LabelText title={"3. Undelivered Register's SI No dated"} />
              </Grid>
              <Grid item lg={1}>
                :
              </Grid>
              <Grid item lg={6}>
                <ValueText title={""} />
                {/* < ValueText title = {}/> */}
              </Grid>

              <Grid item lg={5}>
                <LabelText title={"4. Net Amount Payable "} />
              </Grid>
              <Grid item lg={1}>
                :
              </Grid>
              <Grid item lg={6}>
                                <ValueText title={`Rs. ${data.sanctionedAmt.toLocaleString("en-IN")}`} />

                {/* < ValueText title = {}/> */}
              </Grid>

              <Grid item lg={2}>
                <LabelText title={" Total amount in words : "} />
              </Grid>
              {/* <Grid item lg={1}>
                :
              </Grid> */}
              <Grid item lg={9}>
                <ValueText
                  title={`${toWords(data.sanctionedAmt)} rupees only`}
                />
                {/* < ValueText title = {}/> */}
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={12} xs={12} textAlign={"start"} marginLeft={" 8% "}>
            <Typography variant="h8" gutterBottom style={{ textAlign: "left" }}>
              <span
                style={{
                  fontFamily: "monospace",
                  fontWeight: "bold",
                }}
              >
                <u> Complete Address of the Payee for intimation of Payment </u>
              </span>
            </Typography>
          </Grid>

          <Grid
            container
            item
            lg={12}
            textAlign={"center"}
            marginLeft={"15%"}
            marginTop={"1%"}
          >
            {/* 2nd */}
            <Grid item lg={5}>
              <LabelText title={"Name"} />
            </Grid>
            <Grid item lg={1}>
              :
            </Grid>
            <Grid item lg={6}>
              <ValueText title={data.name} />
            </Grid>

            <Grid item lg={5}>
              <LabelText title={" Father's/Husband's Name "} />
            </Grid>
            <Grid item lg={1}>
              :
            </Grid>
            <Grid item lg={6}>
              <ValueText title={data.fatherHusbandName} />
            </Grid>

            <Grid item lg={5} xs={5} md={5}>
              <LabelText title={" Address "} />
            </Grid>
            <Grid item lg={1} xs={1} md={1}>
              :
            </Grid>
            <Grid item lg={6} xs={6} md={6}>
              <ValueText title={data.payeeAddress} />
            </Grid>
          </Grid>

          <Grid
            item
            lg={12}
            xs={12}
            textAlign={"center"}
            marginLeft={" 8% "}
            marginTop={2}
          >
            <Typography
              component="div"
              style={{ display: "flex", alignItems: "center" }}
            >
              {filteredAndSortedTrack.map((item, index) => (
                <React.Fragment key={index}>
                  <Typography
                    variant="body1"
                    component="span"
                    fontFamily="monospace"
                  >
                    <b>{`${item.roleName}:`}</b>
                    {` ${item.name}`}
                  </Typography>
                  {index !== filteredAndSortedTrack.length - 1 && (
                    <Typography variant="body1" component="span">
                      &rarr;
                    </Typography>
                  )}
                </React.Fragment>
              ))}
            </Typography>
          </Grid>
          <Grid container item lg={12} xs={12} mt={4} marginBottom={"4%"}>
            <Grid item lg={4} xs={4}>
              <Typography>Pay by Cheque No : {data.cheque_number} </Typography>
            </Grid>

            <Grid item lg={4} xs={4}>
              <Typography>Dated : {data.currentDate} </Typography>
            </Grid>

            <Grid item lg={4} xs={4}>
              <Typography> for Rs : {data.sanctionedAmt.toLocaleString("en-IN")} </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

const GeneratePfPo = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const { state } = useLocation();
  const data = state;
  console.log("daaata", state);
  const role = data.fromLoginId.substring(0, 2);
  //console.log("roloooooooooe", role);
  let status = false;
  let url = "";

  let linksAndRoutes = {
    submitUrl: "",
    cancelRoute: "",
    successRoute: "",
    status: false,
  };

  const forBO = {
    submitUrl: "/verification/pf/claim/officers/bo",
    cancelRoute: "/bo/pfset/viewpfclaimbo",
    successRoute: "/bo/pfset",
    status: false,
  };

  const forRH = {
    submitUrl: "/verification/pf/claim/officers/rh",
    cancelRoute: "/rh/pfset/viewpfclaimrh",
    successRoute: "/rh/pfset",
    status: true,
  };

  if (role === "BO") {
    linksAndRoutes = { ...forBO };
  } else if (role === "RH") {
    linksAndRoutes = { ...forRH };
  }
  let poNo = "PO" + data.claimId;
  console.log("poNoooo", poNo);
  console.log("roleeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", role);
  console.log("linkssssandroutessssss", linksAndRoutes);

  const [base64, setBase64] = useState();
  const AdvPORefElement = useRef(null);

  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(linksAndRoutes.cancelRoute, {
      state: { passingId: data.claimId },
    });
  };
  const exportPDF = () => {
    html2canvas(AdvPORefElement.current).then((canvas) => {
      const imageData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imageData, "PNG", 15, 15, 180, 180);
      // pdf.save("AdvPO.pdf");

      // Convert the PDF to base64
      const base64PDF = pdf.output("datauristring");
      let base64PDFf = base64PDF.replace(
        /^data:.+;base64,/,
        `data:application/pdf;base64,JVBERi0xLjQKJdPr6eEKMSAwIG9iago8PC9`
      );

      // Now you can use the base64PDF data as needed
      console.log("base64PDF", base64PDFf);
      setBase64(base64PDFf);
      // navigate("/roda/pfset/viewpfclaimroda", {
      //   state: { passingId: data.claimId, pdf: base64PDFf },
      // });
    });
  };

  // const objectTobeSentToCh = {
  //   claimId: data.claimId,
  //   fromLoginId: data.fromLoginId,
  //   toLoginId: data.toLoginId,
  //   remarks: data.remarks,
  //   poDate: "",
  //   payOrderNo: poNo,
  //   payOrderDocument: base64,
  //   status: false,
  // };
  const handleSuccess = () => {
    // setIsSuccess(false);
    navigate(linksAndRoutes.successRoute);
  };

  const handleErrorAlert = () => {
    setIsError(false);
    navigate(linksAndRoutes.cancelRoute, {
      state: { passingId: data.claimId },
    });
  };

  const handleApprove = () => {
    //  await exportPDF(); // Ensure that exportPDF function is fully executed before proceeding
    setIsSubmitting(true);
    const compressionQuality = 1; // adjust the quality level
    html2canvas(AdvPORefElement.current, { scale: 2, allowTaint: true }).then(
      (canvas) => {
        const imageData = canvas.toDataURL("image/jpeg", compressionQuality);
        const pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage(imageData, "JPEG", 15, 15, 180, 180);
        //pdf.save(`${data.fullName}2.pdf`);

        // Convert the PDF to base64
        const base64PDF = pdf.output("datauristring");
        let base64PDFf = base64PDF.replace(
          /^data:.+;base64,/,
          `data:application/pdf;base64,JVBERi0xLjQKJdPr6eEKMSAwIG9iago8PC9`
        );
        // Now you can use the base64PDF data as needed
        console.log("base64PDF", base64PDFf);
        //setBase64(base64PDFf);
        // navigate("/roda/pfset/viewpfclaimroda", {
        //   state: { passingId: data.claimId, pdf: base64PDFf },
        // });
        // });

        const objectTobeSentToCh = {
          claimId: data.claimId,
          fromLoginId: data.fromLoginId,
          toLoginId: data.toLoginId,
          remarks: data.remarks,
          poDate: "",
          payOrderNo: poNo,
          payOrderDocument: base64PDFf, // Access the base64 state directly here
          status: linksAndRoutes.status,
        };

        const url = process.env.REACT_APP_SERVER_URL + linksAndRoutes.submitUrl;

        const headers = {
          // Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };

        try {
          console.log("objectTobesent", objectTobeSentToCh);
          const response = axios.post(url, objectTobeSentToCh, { headers });
          console.log("reponse on apporve", response);
          setIsSubmitting(false);
          setIsSuccess(true);
          setSuccessMessage("Claim Forwarded Successfully.");
        } catch (error) {
          setIsError(true);
          setIsSubmitting(false);
          setErrorMessage("Sorry Could not Proceed!! Please try again later");
          console.log("error on submit", error);
        }
      }
    );
    //isSubmitting(true)
  };

  /// error alert handler

  return (
    <div>
      <SucessAlert
        handleOk={handleSuccess}
        open={isSuccess}
        title="Success"
        body={successMessage}
        buttonName="OK"
      />
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error!"
        body={errorMessage}
        buttonName="Ok"
      />
      <div style={{ justifyContent: "flex-end" }}>
        <div ref={AdvPORefElement}>
          <PfDpo />
        </div>
        <Grid container spacing={2} justifyContent={"center"} mt={2}>
          <Grid
            item
            // marginTop={3}
            // marginBottom={3}
            // marginRight="20px"
            // style={{ display: "flex", justifyContent: "center" }}
          >
            <Button onClick={handleCancel} variant="contained" color="primary">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleApprove}
              variant="contained"
              color="success"
              disabled={isSubmitting}
            >
              {isSubmitting ? <CircularProgress /> : "Approve"}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default GeneratePfPo;
