import React, { useState, useEffect } from "react";
import axios from "axios";
import TableWithLinkAndButton from "../../FormComponent/TableWithLinkAndButton";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import { Tabs, Tab, Container, Grid, Paper, Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { getToken } from "../../UiManager/SessionChecker";

const PfSettlementAao = (props) => {
  const defaultSubmittedClaims = {
    claimId: "",
    cmpfAcNo: "",
    name: "",
    unitCode: "",
    submissionDate: "",
    remarks: "",
  };
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isFailed, setIsFailed] = useState(false);
  const [titleName, setTitleName] = useState("");
  const [tabData, setTabData] = useState({
    received: [defaultSubmittedClaims],
    processed: [defaultSubmittedClaims],
    returned: [defaultSubmittedClaims],
    RecivedConfm: [defaultSubmittedClaims],
    confirmed: [defaultSubmittedClaims],
  });
  //let loginId = "AAO-CC-OFF-2550"; // Assuming a default loginId
  // let token = token.replace('"', "").replace('"', "");
  // let loginId = jwtDecode(token).user; // Assuming a default loginId

  // console.log("loooooooooooooooooooooginId",loginId);
  const [pageLink, setPageLink] = useState("viewpfclaimaao");

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (newValue === 0) {
      setPageLink("viewpfclaimaao");
    } else if (newValue === 1) {
      setPageLink("proceedpfclaim");
    } else if (newValue === 2) {
      setPageLink("recvdcnfrmaao");
    } else if (newValue === 3) {
      setPageLink("proceedpfclaim");
    } else if (newValue === 4) {
      setPageLink("proceedpfclaim");
    }
  };

  let type = "";

  const location = useLocation();

  if (location.pathname === "/aao/pfset") {
    type = "PF";
  } else if (location.pathname === "/aao/penset") {
    type = "PN";
  }
  let api = {
    claimUrl: "",
  };

  const pfApi = {
    claimUrl: "/show/all/pf/claim/to/all/officers",
  };
  const penApi = {
    claimUrl: "/show/all/pen/claim/to/all/officers",
  };
  const comboApi = {
    claimUrl: "/show/all/combo/claim/to/all/officers",
  };

  useEffect(() => {
    let newTitle = "";
    if (type === "PF") {
      api = { ...pfApi };
      newTitle = "PF";
    } else if (type === "PN") {
      api = { ...penApi };
      newTitle = "Pension";
    } else if (type === "PP") {
      api = { ...comboApi };
      newTitle = "PF & Pension";
    }
    setTitleName(newTitle);
    // ... other logic related to the API
  }, [type]); // Don't forget to include type as a dependency to ensure the useEffect runs when type changes

  useEffect(() => {
    fetchReceived();
  }, [location.pathname]);

  const fetchReceived = async () => {
    try {
      setIsLoading(true);
      let token = getToken();
      console.log("token on aao", token);
      if (!token) {
        setIsLoading(false);
        //setIsFailed(true);
        return;
      }
      token = token.replace('"', "").replace('"', "");
      const loginId = jwtDecode(token).user;
      console.log("loginId on aao", loginId);

      const url = process.env.REACT_APP_SERVER_URL + api.claimUrl;
      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          // Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);

      if (response.status === 200) {
        if (type === "PF") {
          setTabData((prevTabData) => ({
            ...prevTabData,
            received: response.data.data.AssignedList,
            processed: response.data.data.ForwardList,
            RecivedConfm: response.data.data.RecivedForConfirmationByCA,
            confirmed: response.data.data.ApprovedByAAO,
            returned: response.data.data.ReturnList,
          }));
        } else if (type === "PN") {
          setTabData((prevTabData) => ({
            ...prevTabData,
            received: response.data.data.AssignedList,
            processed: response.data.data.ForwardList,
            RecivedConfm: response.data.data.RecivedForConfirmationByIPDS,
            confirmed: response.data.data.ApprovedByAAO,
            returned: response.data.data.ReturnList,
          }));
        }
      } else {
        console.error("Failed to fetch claims. Status: " + response.status);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error - received", error);
      return [];
    }
  };

  const createTablePreviewData = () => {
    let tablePreviewData = [];

    selectedTabData.forEach((pf) => {
      tablePreviewData.push({
        "Claim ID": pf.claimId,
        "Name of the Claimant": pf.fullName,
        "CMPF A/C NO.": pf.cmpfAccNo,
        "Unit Code": pf.unitCode,
        "Claim Submission Date": pf.submissionDate
          ? new Date(pf.submissionDate).toLocaleDateString("en-GB")
          : " ",
        Remarks: pf.remarks,
      });
    });

    return tablePreviewData;
  };

  const selectedTabData =
    tabData[
      value === 0
        ? "received"
        : value === 1
        ? "processed"
        : value === 2
        ? "RecivedConfm"
        : value === 3
        ? "confirmed"
        : value === 4
        ? "returned"
        : ""
    ] || [];

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            <Grid item xs={12}>
              <TitleText title={titleName + " Claims"} />
            </Grid>
            <FormDivider />
            {isFailed ? (
              <p>loginid not found</p>
            ) : (
              <>
                {isLoading ? (
                  <p>Loading...</p>
                ) : (
                  <Container>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          // indicatorColor="#000000"
                          // textColor="#000000"
                        >
                          <Tab
                            label="Received"
                            style={{
                              backgroundColor: value === 0 ? "#831f76" : "", //bisqu
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                              color: value === 0 ? "#ffffff" : "#000000", // Set text color to white when selected
                            }}
                          />
                          <Tab
                            label="Processed"
                            style={{
                              backgroundColor: value === 1 ? "#831f76" : "",
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                              color: value === 1 ? "#ffffff" : "#000000", // Set text color to white when selected
                            }}
                          />
                          <Tab
                            label="Received for confirmation"
                            style={{
                              backgroundColor: value === 2 ? "#831f76" : "",
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                              color: value === 2 ? "#ffffff" : "#000000", // Set text color to white when selected
                            }}
                          />
                          <Tab
                            label="confirmed"
                            style={{
                              backgroundColor: value === 3 ? "#831f76" : "",
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                              color: value === 3 ? "#ffffff" : "#000000", // Set text color to white when selected
                            }}
                          />
                          <Tab
                            label="Returned"
                            style={{
                              backgroundColor: value === 4 ? "#831f76" : "",
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                              color: value === 4 ? "#ffffff" : "#000000", // Set text color to white when selected
                            }}
                          />
                        </Tabs>
                      </Grid>
                      <Grid item xs={12} marginTop={-2}>
                        <Box
                          p={3}
                          style={{
                            backgroundColor: "#fffaff",
                            border: "1px solid #831f76",
                          }}
                        >
                          <TableWithLinkAndButton
                            preview={createTablePreviewData()}
                            data={selectedTabData}
                            passingId="Pf ID"
                            viewLink={pageLink}
                            parentData={value}
                            size="small"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Container>
                )}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default PfSettlementAao;
