import React from "react";
import About from "./About";

import LeaderBoard from "./LeaderBoard";
import CarouselCmpfo from "./CarouselCmpfo";

function Home() {
  return (
    <div>
      <CarouselCmpfo />
      <div
        style={{
          backgroundColor: "orange",
          height: "8px",
          width: "100%",
        }}></div>
      {/* <LeaderBoard/> */}
      <About />
    </div>
  );
}

export default Home;
