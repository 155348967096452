import React from "react";
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Box,
  styled,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import cmpfologo from "../images/cmpfo.png";
import g20 from "../images/AtmaNirbharBharat.png";
import swach from "../images/viksitBharat.png";
import digitalIndia from "../images/Didita India logo.png";

const StyledTypography = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: "10px",
  fontSize: "1.2rem", // Initial font size

  [theme.breakpoints.up("xs")]: {
    fontSize: "0.77rem", // Font size for small screens and above
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "0.9rem", // Font size for small screens and above
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.2rem", // Font size for medium screens and above
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.2rem", // Font size for large screens and above
  },
}));

const StyledImage = styled("img")(({ theme }) => ({
  height: "80px",
  paddingLeft: "5px",
}));

function Header() {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const fontSize = isSmallScreen ? "9px" : isMediumScreen ? "11px" : "12px";

  return (
    <div className="container-fluid">
      {/* <div className="container-fluid">
                <section className="d-flex justify-content-center border-bottom bg-light">
                    <b>
                        <p className="justify-content-center" style={{ color: "#6e6d6d", marginBottom: "0", padding: '0px 0', fontSize: '13px' }}>
                            भारत सरकार
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ fontSize: '13px' }}>GOVERNMENT OF INDIA</span>
                        </p>
                    </b>
                </section>
            </div> */}
      <div className="container-fluid">
        <section className="d-flex justify-content-center border-bottom bg-light">
          <b>
            <p
              className="justify-content-center"
              style={{
                color: "#6e6d6d",
                marginBottom: "0",
                padding: "0px 0",
                fontSize: fontSize,
              }}
            >
              <span
                style={{ borderRight: "1px solid #ccc", paddingRight: "5px" }}
              >
                भारत सरकार
              </span>
              <span style={{ fontSize: fontSize, paddingLeft: "5px" }}>
                GOVERNMENT OF INDIA
              </span>
            </p>
          </b>
        </section>
      </div>

      <Container sx={{ marginTop: "0.3%" }}>
        <Toolbar>
          <StyledImage
            src={cmpfologo}
            alt="cmpfo logo"
            className="logo-image"
            sx={{ height: { xs: "35px", sm: "45px", md: "55px", lg: "65px" } }}
          />
          <StyledTypography variant="h6" component="div" align="left">
            <b>
              <span style={{ color: "#221a44" }}>
                कोयला खान भविष्य निधि संगठन
              </span>
              <br />
              <span
                style={{
                  color: "#221a44",
                  fontStyle: "Inter, Arial, sans-serif",
                }}
              >
                Coal Mines Provident Fund Organisation
              </span>
            </b>
          </StyledTypography>
          <StyledImage
            sx={{
              height: { xs: "10px", sm: "20px", md: "30px" },
              display: { xs: "none", sm: "block", md: "block" },
            }}
            src={g20}
            alt="cmpfo logo"
            className="logo-image"
          />

          <StyledImage
            sx={{
              height: { xs: "30px", sm: "50px", md: "60px" },
              display: { xs: "none", sm: "block", md: "block" },
            }}
            src={digitalIndia}
            alt="cmpfo logo"
            className="logo-image"
          />
          <StyledImage
            sx={{
              height: { xs: "10px", sm: "20px", md: "40px" },
              display: { xs: "none", sm: "block", md: "block" },
            }}
            src={swach}
            alt="cmpfo logo"
            className="logo-image"
          />
        </Toolbar>
      </Container>
    </div>
  );
}

export default Header;
