import { Button, CircularProgress, Grid, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import SubtitleText from "../../FormComponent/SubtitleText";
import MemberDetails from "../UDAPages/MemberDetatils";
import BackButton from "../../components/button/BackButton";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ViewDocuments from "../../SettlementComponents/ViewDocuments";
import CustomTextArea from "../../FormComponent/CustomTextArea";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import Reloader from "../../FormComponent/Reloader";
import LoadingIndicator from "../../FormComponent/LoadingIndicator";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import SucessAlert from "../../FormComponent/SuccessAlert";
import ConfirmationDialogue from "../../FormComponent/ConfirmationDialogue";
import TableWithLinkAndButton from "../../FormComponent/TableWithLinkAndButton";
import AdvClaimDetails from "../../SettlementComponents/AdvClaimDetails";
import jwtDecode from "jwt-decode";

function ViewAdvClaimRh() {
  const { state } = useLocation();
  const claimId = state.passingId;
  const claimType = claimId.substring(0, 2);
  const poPdf = state.pdf;
  const apiSubmitData = state.apiData;

  const navigate = useNavigate();
  const sampleMemberData = {
    // This is the sample claimData data to be displed for demo purpose only
    title: "",
    fullName: "",
    dateOfBirth: "",
    panNo: "",
    aadhaarNumber: "",
    maritalStatus: "",
    religionName: "",
    gender: "",
    fatherHusbandName: "",
    email: "",
    mobileNo: "",
    landNo: "",
    addressL1: "",
    addressL2: "",
    village: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  };

  const sampleClaimData = {
    advclaim: {
      claimDetails: {
        aaoConfrmPayment: "",
        advClaimStatusId: "",
        advClaimTypeId: "",
        amountApplied: "",
        claimId: "",
        claimType: "",
        cmpfAccNo: "",
        correspondanceAddress: "",
        dateReceived: "",
        declarationUda: "",
        dobAadhaar: "",
        dobPan: "",
        emolumentsLast36m: "",
        memberId: "",
        nameAadhaar: "",
        nameBank: "",
        namePan: "",
        nmAadhaarMatch: "",
        nmBankMatch: "",
        nmPanMatch: "",
        periodOfMembship: "",
        settlementDate: "",
        sfwhName: "",
        sfwhRelationType: "",
        udaLoginId: "",
      },
      tAdvSpecificDetails: {
        advClaimSrno: "",
        advSpecificDetSrno: "",
        amtAlrdyPaid: "",
        anticptdDateOfMarriage: "",
        dependentId: "",
        estimatedCost: "",
        examPassed: "",
        examYear: "",
        feesPaid: "",
        locationDet: "",
        saleAgreeAmt: "",
        sanctionedAmtDet: "",
        siteMeasurementUnit: "",
        siteMeasurementVal: "",
      },
    },
  };

  const [advClaimData, setAdvClaimData] = useState(sampleClaimData);
  const [memberData, setMemberData] = useState(sampleMemberData);
  const [isMemberFound, setIsMemberFound] = useState(" ");
  const [documents, setDocuments] = useState([]);
  const [assignToOption, setAssignToOption] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [assignToChOption, setAssignToChOption] = useState([]);
  //**********for integration************* */
  const [isLoading, setIsLoading] = useState(true); // set this to true
  const [isLoadingDoc, setIsLoadingDoc] = useState(true); // set this to true
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isFailedDoc, setIsFailedDoc] = useState(false);
  const [isReturning, setIsReturning] = useState(false);
  const [isReturnClicked, setIsReturnClicked] = useState(false);
  const [showingDropdown, setShowingDropdown] = useState(0);
  const [isReturnRodaClicked, setIsReturnRodaClicked] = useState(false);
  const [assignCalled, setAssignCalled] = useState(false);

  // we have set a value beforeids = 0 for show some field till ids
  let beforeids = 1;

  const defaultClaim = {
    remarks: "",
    assignTo: "",
  };

  // declaring the default api object

  let api = {
    claimUrl: "",
    docsUrl: "",
    returnUrl: "",
    forwardUrl: "",
    officerDropdownUrl: "",
    returnRodaUrl: "",
  };
  // object for pfapis
  //let loginId = "RH-RO-OFF-450"; // Assuming a default loginId
  // const loginOffcr = loginId.substring(0, 2);
  const advApis = {
    claimUrl: "/fetch/advance/pf/claim/details/by/id",
    docsUrl: "/fetch/advance/pf/claim/Documents/by/id",
    returnUrl: "/return/adv/pf/claim/rh/to/aao",
    returnRodaUrl: "/return/adv/pf/claim/rh/to/roda",
    forwardUrl: "/forward/advance/pf/claim/RH/to/CA",
    officerDropdownUrl:
      "/dropdown/for/show/next/authorized/offcr/adv/pf/pen/rh",
    memberDetailsUrl: "/member/details/for/pf",
    familyMemDetails: "/show/all/existing/family/member",
  };

  const [loginId, setLoginId] = useState();

  /********fetching and setting token********* */
  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (token) {
      token = token.replace('"', "").replace('"', "");
      let loginId = jwtDecode(token).user; // Assuming a default loginId
      setLoginId(loginId);
      console.log("loooooooooooooooooooooginId", loginId);
      // Add any further logic here based on the loginId
    } else {
      // Handle the case when token is not present
      setIsLoading(false);
      setIsFailed(true);
    }
  }, []);

  if (claimType === "AD") {
    api = { ...advApis };
  }

  const [claim, setClaim] = useState(defaultClaim);
  const assignTo = {
    name: "assignTo",
    placeholder: "Select Officer",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "",
    required: true,
    options: assignToOption,
  };

  const remarks = {
    name: "remarks",
    type: "text",
    multiline: true,
    helperText: "Enter your remarks (maximum 200 words)",
    placeholder: "Enter your remarks here",
    defaultValue: claim.remarks,
    errorMessage: "Cannot exceed 200 characters",
    pattern: "^.{0,200}$",
    maxLength: 200, // Limiting to 200
  };

  //********DEFAULT for family details*********/
  const familyDefault = {
    familyMemId: "",
    name: "",
    gender: "",
    relationName: "",
    dateOfBirth: "",
    pfNomSharePerc: "",
  };

  const [nomineeDetails, setNomineeDetails] = useState([familyDefault]);

  const viewLink = "familydetails"; // Link for family members details

  // Advance claim type integration]
  //****************Dropdown******************* */
  const fetchAssignOfficer = async () => {
    try {
      const url =
        //process.env.REACT_APP_SERVER_URL + "/show/next/autorized/officer/pf";
        process.env.REACT_APP_SERVER_URL + api.officerDropdownUrl;

      // "/dropdown/for/adv/pf/claim/aao/to/ids";
      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      const options = data.map((option) => ({
        id: option.OffcrId,
        title: `${option.Name} (${option.OffcrId})`,
      }));
      setAssignToOption(options); // Assuming setAssignToOption is a state setter function

      // Set the respective state variables
      setAssignToOption(options);
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchAssignOfficer();
  }, [loginId]);

  const fetchClaimDetailAdv = async () => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");

    const url = process.env.REACT_APP_SERVER_URL + api.claimUrl;

    const formData = new FormData();
    formData.append("claimId", claimId);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          setAdvClaimData(data);
          fetchMemberDetail(data.cmpfAcNo);
          fetchDocuments();
          return;
        }
        // setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
      });
  };

  const fetchAllClaimDetail = () => {
    fetchClaimDetailAdv();
  };

  const fetchDocuments = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + api.docsUrl;

      const formData = new FormData();
      formData.append("claimId", claimId);

      const config = {
        headers: {
          // Include the Authorization header if req uired
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(url, formData, config);

      setIsLoadingDoc(false);
      setIsFailedDoc(false);

      if (response.status === 200) {
        const data = response.data.data;
        setDocuments(data.data);
      } else {
        setIsFailed(true);
      }
    } catch (error) {
      // Corrected syntax for the catch block
      setIsLoading(false);
      setIsFailed(true);
    }
  };

  useEffect(() => {
    fetchAllClaimDetail();
  }, []);

  const { advClaim } = advClaimData;
  let draftPayOrder = advClaim?.draftPayOrder; // Use optional chaining here
  let payOrder = advClaim?.payOrder;
  // Combine data from the two APIs
  useEffect(() => {
    if (draftPayOrder) {
      const updatedCombinedData = [
        ...documents,
        {
          docTypeDesc: "DPO",
          uploadedDoc: draftPayOrder,
        },
      ];

      // Assuming advClaimData.advClaim.purchaseOrder is the document from the second API
      if (payOrder) {
        updatedCombinedData.push({
          docTypeDesc: "PO",
          uploadedDoc: payOrder,
        });
      }

      setCombinedData(updatedCombinedData);
    }
  }, [documents, advClaimData]);
  console.log("combinedData", combinedData);

  // cmpf account number is fetched on useEffect with a delay of 3sec to avoid multiple api hits
  const onChange = (e) => {
    setClaim({ ...claim, [e.target.name]: e.target.value });
  };

  /// ******** on submit function
  const objectTobeSent = {
    fromOffcrLoginId: loginId,
    Remarks: claim.remarks,
    toOffcrId: claim.assignTo,
    ClaimId: claimId,
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!objectTobeSent.toOffcrId) {
      // Show message in dialog: "Please select an officer to assign"
      //alert("Please select an officer to assign");
      setIsError(true);
      setErrorMessage("Please Select an Officer to Assign.");
      return;
    }
    setIsSubmitting(true);

    navigate("advpo", {
      state: { advClaimData, memberData, objectTobeSent, loginId },
    });
  };

  const handleAssign = () => {
    const url = process.env.REACT_APP_SERVER_URL + api.forwardUrl;
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, apiSubmitData, { headers })
      .then(function (response) {
        setIsSubmitting(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Forwarded Successfully.");
      })
      .catch((error) => {
        // setIsError(true);
        setIsSubmitting(false);
        // setErrorMessage("Sorry Could not Proceed!! Please try again later");
        console.log("error on submit", error);
      });
  };

  if (poPdf !== null && !assignCalled) {
    handleAssign();
    setAssignCalled(true); // Update the state variable to indicate that handleAssign has been called
  }

  const handleReturnToRodaOk = () => {
    handleReturnToRodaClaim();
  };
  /// ******** on Return to RODA function

  const objectTobeSentOnReturnToRoda = {
    loginId: loginId,
    remark: claim.remarks,
    claimId: claimId,
  };

  const handleReturnToRodaClick = (e) => {
    e.preventDefault();

    if (!claim.remarks.trim()) {
      // Show message in dialog: "Please enter remarks"
      // alert("Please enter remarks");
      setIsError(true);
      setErrorMessage("Please enter Remarks.");
      return;
    }

    setIsReturning(true);
    setIsReturnRodaClicked(true);
  };
  const handleReturnToRodaClaim = (e) => {
    // e.preventDefault();

    const url = process.env.REACT_APP_SERVER_URL + api.returnRodaUrl;
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSentOnReturnToRoda, { headers })
      .then(function (response) {
        setIsReturning(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Forwarded Successfully.");

        //console.log("Claim Returned Successfully");
        setIsReturnRodaClicked(false);
      })
      .catch((error) => {
        console.log("error on submit", error);
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
      });
  };

  /// ******** on Return AAO function

  const objectTobeSentOnReturn = {
    loginId: loginId,
    remark: claim.remarks,
    claimId: claimId,
  };

  const handleReturnClick = (e) => {
    e.preventDefault();

    if (!claim.remarks.trim()) {
      // Show message in dialog: "Please enter remarks"
      // alert("Please enter remarks");
      setIsError(true);
      setErrorMessage("Please enter Remarks.");
      return;
    }

    setIsReturning(true);
    setIsReturnClicked(true);
  };

  const handleReturnClaim = () => {
    let url2 = process.env.REACT_APP_SERVER_URL + api.returnUrl;
    let url1 = process.env.REACT_APP_SERVER_URL + api.returnRodaUrl;
    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(url1, objectTobeSentOnReturn, { headers })
      .then(function (response) {
        setIsReturning(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Return Successfully.");
        setIsReturnClicked(false);

        return axios.post(url2, objectTobeSentOnReturn, { headers });
      })
      .then(function (response) {
        // handle success for the second API call if you want to do something
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          console.log("error on submit", error);
          setIsError(true);
          setErrorMessage("Sorry Could not Proceed!! Please try again later");
        }
      });
  };

  ///****************integration started********************* */

  const fetchMemberDetail = async (accNo) => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + api.memberDetailsUrl;
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          if (data.length !== 0) {
            setMemberData(data[0]);
            setIsMemberFound("true");
            fetchNomineeDetail(data[0].cmpfAccNo);
          }
          setIsMemberFound("false");
          return;
        }
        setIsFailed(true);
        setIsMemberFound("false");
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        setIsMemberFound("false");
      });
  };

  /***** function to get all nominee details**************** */
  const fetchNomineeDetail = async (accNo) => {
    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");

    const url = process.env.REACT_APP_SERVER_URL + api.familyMemDetails;
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo); // check what formdata is to be sent
    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsLoading(false);
        setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          if (data.length !== 0) {
            setNomineeDetails(data);
          }

          return;
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
      });
  };
  /// error alert handler
  const handleErrorAlert = () => {
    setIsError(false);
  };

  const handleSuccess = () => {
    // setIsSuccess(false);
    navigate("/rh/advset");
  };

  const handleCancelReturn = () => {
    setIsReturnClicked(false);
  };
  const handleReturnOk = () => {
    handleReturnClaim();
  };

  // conditions fro display the dropdown

  const handleApproveOk = (e) => {
    setShowingDropdown(1);
  };

  const handleCancelReturnroda = () => {
    setIsReturnRodaClicked(false);
  };

  const maritalStatus = [
    { id: "M", title: "Married" },
    { id: "U", title: "Unmarried" },
    { id: "W", title: "Widow/Widower" },
    { id: "D", title: "Divorced" },
  ];

  const gender = [
    { id: "M", title: "Male" },
    { id: "F", title: "Female" },
    { id: "O", title: "Other" },
  ];

  const createTablePreviewDataReturn = () => {
    console.log("from preview", nomineeDetails);
    // the functions that creates a table preview
    //const tableSamples = familyMemberSamples; //familyMemberSamples must be replaced witht he response data recevied on the api request
    const tableSamples = nomineeDetails;
    let tablePreviewData = [];
    tableSamples.map((member) => {
      tablePreviewData.push({
        "Family Member ID": member.familyMemId,
        "Name of Nominee": member.name,
        Gender: gender.find((item) => item.id === member.gender)?.title,
        Relation: member.relationName,
        "Date of Birth": member.dateOfBirth,
        "Marital Status": maritalStatus.find(
          (item) => item.id === member.maritalStatus
        )?.title,
      });
    });
    return tablePreviewData;
  };

  return (
    <div>
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error!"
        body={errorMessage}
        buttonName="Ok"
      />
      <SucessAlert
        handleOk={handleSuccess}
        open={isSuccess}
        title="Success"
        body={successMessage}
        buttonName="OK"
      />
      <ConfirmationDialogue
        handleOk={handleReturnOk}
        handleCancel={handleCancelReturn}
        open={isReturnClicked}
        title="Confirm Return"
        body="Are you sure you want to return this claim ?"
        buttonName="Confirm"
      />
      <ConfirmationDialogue
        handleOk={handleReturnToRodaOk}
        handleCancel={handleCancelReturnroda}
        open={isReturnRodaClicked}
        title="Confirm Return"
        body="Are you sure you want to return this claim ?"
        buttonName="Confirm"
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "10px" }}>
            {isFailed || isFailedDoc ? (
              <Reloader refreshHandler={fetchAllClaimDetail} />
            ) : (
              <>
                {isLoading || isLoadingDoc ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center">
                      <Grid item xs={12}>
                        <TitleText title="Advance PF Claim" />
                      </Grid>
                    </Grid>
                    <FormDivider />
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={1} mb={3}>
                        <BackButton />
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="start"
                        rowGap={3}>
                        {/* Member Details*/}
                        {/* section 1  */}
                        <Paper
                          elevation={3}
                          sx={{ padding: "3rem", borderRadius: "10px" }}>
                          <Grid item xs={12}>
                            <MemberDetails member={memberData} />
                          </Grid>
                          <Grid item xs={12} mt={4}>
                            <SubtitleText title="Nomination Status" />
                          </Grid>
                          <Grid item xs={12}>
                            <TableWithLinkAndButton
                              preview={createTablePreviewDataReturn()}
                              data={nomineeDetails}
                              passingId="familyMemberid"
                              actions=""
                              viewLink={viewLink}
                            />
                          </Grid>
                        </Paper>
                        {/* claim Details*/}
                        {/* section 2  */}

                        {/* Document Details*/}
                        <Paper
                          elevation={3}
                          sx={{ padding: "3rem", borderRadius: "10px" }}>
                          <Grid item xs={12}>
                            <AdvClaimDetails
                              advclaimdet={advClaimData}
                              beforeids={beforeids}
                            />
                          </Grid>
                        </Paper>
                        {/* section 3  */}
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={12}>
                            <Paper
                              elevation={3}
                              sx={{ padding: "3rem", borderRadius: "10px" }}>
                              <Grid item xs={12}>
                                {draftPayOrder ? (
                                  <ViewDocuments documents={combinedData} />
                                ) : (
                                  <ViewDocuments documents={documents} />
                                )}
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>

                        {/* ************************************ Remarks ***************************************** */}
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={12}>
                            <Paper
                              elevation={3}
                              sx={{ padding: "2rem", borderRadius: "10px" }}>
                              <SubtitleText title="Remarks" />
                              <Grid item xs={12} sm={6} md={8}>
                                <CustomTextArea
                                  {...remarks}
                                  onchange={onChange}
                                />
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>
                        {/* *************** process and return to aao ********* */}
                        <Grid container justifyContent="end">
                          <Grid item xs={12}>
                            <Grid
                              container
                              justifyContent="end"
                              alignItems="center"
                              columnGap={2}>
                              <Grid item md={2}>
                                <Button
                                  onClick={handleReturnClick}
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  // disabled={isReturnClicked}
                                  fullWidth>
                                  Return to aao
                                </Button>
                              </Grid>
                              <Grid item md={2.5}>
                                <Button
                                  onClick={handleReturnToRodaClick}
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  // disabled={isReturnClicked}
                                  fullWidth>
                                  Return to roda
                                </Button>
                              </Grid>
                              <Grid item md={2}>
                                <Button
                                  onClick={handleApproveOk}
                                  variant="contained"
                                  color="success"
                                  type="submit"
                                  //style={{ marginLeft: 8 }}
                                  // disabled={isSubmitting}
                                  fullWidth>
                                  Approve
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        {showingDropdown === 1 && (
                          <Grid
                            container
                            justifyContent="end"
                            alignItems={"center"}
                            spacing={2}>
                            <Grid item xs={12} sm={4}>
                              <CustomDropdown
                                {...assignTo}
                                onchange={onChange}
                              />
                            </Grid>
                            <Grid item md={1}>
                              <Button
                                onClick={handleSubmit}
                                variant="contained"
                                color="success"
                                type="submit"
                                disabled={isSubmitting}
                                fullWidth>
                                {isSubmitting ? <CircularProgress /> : "OK"}
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default ViewAdvClaimRh;
