import React from 'react';
import { Grid, Typography, Backdrop } from '@mui/material';


const ErrorPage = () => {
  return (

    <Backdrop open={true} style={{ zIndex: 9999, backgroundColor: '#fff' }}>
      <Grid container justifyContent='center' alignItems='center' direction='column'>
        <Grid item>
          <Typography variant="h2" align="center">
           Oops..! Something went wrong
          </Typography>
        </Grid>
        <Grid item>
          <  Typography variant="body1" align="center">
        We apologize for the inconvenience. Please try again later.
          </Typography>
        </Grid>
      </Grid>
    </Backdrop>
  );
};

export default ErrorPage;
