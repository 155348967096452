// const pfCalucationData = {
//   closingBalance: 200000,
//   interestRate: 8,
//   brokenPeriodEmplyrShareContri: 12000,
//   brokenPeriodMemShareContri: 12000,
//   brokenPeriodVolContri: null,
//   brokenPeriodMonths: 8,
//   disbursementPer: 50,
// };
/**
 * Custom implementation of parseInt to parse a value and handle NaN cases.
 * @param {string} value The value to be parsed.
 * @returns {number} The parsed value or 0 if the value is not a valid number.
 */
const customParseInt = (value) => {
  const parsedValue = parseInt(value);
  return isNaN(parsedValue) ? 0 : parsedValue;
};

/**
 * Custom implementation of parseFloat to parse a value and handle NaN cases.
 * @param {string} value The value to be parsed.
 * @returns {number} The parsed value or 0 if the value is not a valid number.
 */

const customParseFloat = (value) => {
  const parsedValue = parseFloat(value);
  return isNaN(parsedValue) ? 0 : parsedValue;
};

/**
 * Performs custom rounding to ensure values are rounded up to two decimal places.
 * @param {number} value - The input value to be rounded.
 * @returns {string} - The rounded value formatted to two decimal places.
 */

export function customRounding(value) {
  return Math.round(value) + ".00";
}

/**
 ** This is the function to calculate the total BrokenPeriod contribution
 ** it is just the sum of total member and employee and voluntarty contribution
 */
export const totalBrokenPeriodContribution = (memShare, empShare, volShare) =>
  customParseInt(memShare) +
  customParseInt(empShare) +
  customParseInt(volShare);

/**
 ** This is the function to calculate the total Broken interest payable
 ** it is the interest on closing balance of the previous currency ending yr for the no of broken period months on interest rate of current currency yr
 * the interest rate is entered by the RODA on his page
 */

export const calBrokenInterestPayable = (
  closingBal,
  interestRate,
  brokenPeriodMonths
) => {
  return (
    (customParseInt(closingBal) *
      customParseFloat(interestRate) *
      customParseInt(brokenPeriodMonths)) /
    1200
  );
};

/**
 * Calculates the total PF amount by adding broken interest payable, broken period contributions, and closing balance.
 * @param {number} brokenInterestPayable The broken interest payable amount.
 * @param {number} brokenPeriodContri The broken period contributions amount.
 * @param {number} closingBal The closing balance amount.
 * @returns {number} The total PF amount calculated by summing the input values
 */

export const totalPfAmount = (
  brokenInterestPayable,
  brokenPeriodContri,
  closingBal,
  totalInterestOnWithdrawal,
  totalWithdrawalAmt
) =>
  Math.round(
    customParseInt(closingBal) +
      brokenInterestPayable +
      brokenPeriodContri -
      totalInterestOnWithdrawal -
      totalWithdrawalAmt
  );

// export const netAmountPayable = (totalPfAmount, disbursementPer) =>
//   totalPfAmount * (disbursementPer / 100);

export const netAmountPayable = (totalPfAmount, disbursementPer) =>
  totalPfAmount * (customParseFloat(disbursementPer) / 100);

export function tdsCalculations(totContri, interest, noOfMonths) {
  console.log("totContri", totContri);
  console.log("int tds", interest);

  const x = ((totContri - 250000) * interest * (noOfMonths / 12)) / 100;
  console.log("x", x);
  const y = (10 * x) / 100;
  return y;
}

//** this function is for calculating the interest amount to be deducted */

export function calculateWithdrawalInterest(
  data,
  annualInterestRate,
  currentDate
) {
  const interestRatePerMonth = annualInterestRate / 12 / 100;

  function monthsToApril(withdrawalDate) {
    const withdrawalYear = withdrawalDate.getFullYear();
    const withdrawalMonth = withdrawalDate.getMonth();

    // Calculate target April 1st date
    const yearOfNextApril =
      withdrawalMonth >= 0 && withdrawalMonth <= 2
        ? withdrawalYear
        : withdrawalYear + 1;
    const nextAprilDate = new Date(currentDate); // April is month 3 in Date (0-based)

    // Calculate difference in months
    const monthsUntilApril =
      (nextAprilDate.getFullYear() - withdrawalDate.getFullYear()) * 12 +
      nextAprilDate.getMonth() -
      withdrawalDate.getMonth();
    return monthsUntilApril;
  }

  let totalInterest = 0;
  data.forEach((item) => {
    const withdrwlDate = new Date(item.withdrwlDate);
    let remainingMonths = 0;
    if (item.withdrwlTypId === 8) {
      remainingMonths = monthsToApril(withdrwlDate);
      console.log("wbr adv months", remainingMonths); // in case of wbr intrest to be deducted is till current month
    } else {
      remainingMonths = monthsToApril(withdrwlDate) - 1;
      console.log("normal adv months", remainingMonths);
    }
    const interestForThisWithdrawal =
      parseInt(item.withdrwlAmt) * interestRatePerMonth * remainingMonths;
    totalInterest += interestForThisWithdrawal;
    console.log(
      `  for withdrawal date ${item.withdrwlDate} is `,
      remainingMonths
    );
    console.log(
      `Withdrawal ID with principal amount ${
        item.withdrwlAmt
      } has interest: ${interestForThisWithdrawal.toFixed(2)}`
    );
  });

  console.log(`Total interest to be deducted: ${totalInterest.toFixed(2)}`);

  return Math.round(totalInterest); // Return the total interest calculated
}
