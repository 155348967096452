import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Box, useTheme } from "@mui/system";

const customLabelStyle = {
  fontSize: "12px",
  // color:'red',
};

function CustomTextField(props) {
  const {
    disabled,
    required,
    errorMessage,
    defaultValue,
    helperText,
    placeholder,
    name,
    type,
    pattern,
    label,
    onchange,
    transformInput,
    color,
  } = props;

  const [error, setError] = useState(false);
  const currentDate = new Date().toISOString().split("T")[0];

  const handleChange = (event) => {
    const { value } = event.target;
    setError(false);
    if (required && !value) {
      setError(true);
    } else if (pattern && !new RegExp(pattern).test(value)) {
      setError(true);
    }
    onchange(event);
  };

  const handleBlur = (event) => {
    // applying transformation to input
    const transformedValue = transformInput
      ? transformInput(event.target.value)
      : event.target.value;
    const transformedEvent = {
      ...event,
      target: {
        name: event.target.name,
        value: transformedValue,
      },
    };
    onchange(transformedEvent);
  };

  return (
    <Box textAlign={"center"}>
      <TextField
        sx={{
          "& .MuiFormHelperText-root": {
            marginLeft: 0,
            // fix based on your screen
            fontSize: {
              xs: "9px",
              sm: "9px,",
              md: "9px",
              lg: "12px",
              xl: "14px",
            },
            fontWeight: "400",
            color: error ? (color ? color : "red") : color ? color : "black",
          },
          "& .MuiInputBase-input": {
            minWidth: "80px",
            borderRadius: "5px",
            color: "black",
            background: "#ffffff",
            // fix based on your screen
            fontSize: {
              xs: 12,
              sm: 12,
              md: 13,
              lg: 14,
              xl: 16,
            },
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "5px",
            "& fieldset": {
              borderColor: "#474E68",
              // borderRadius:'0px'
            },
            "&:hover fieldset": {
              borderColor: "#6B728E",
              // borderRadius: '0px'
            },
            "&.Mui-focused fieldset": {
              borderColor: "#0F3460",
              borderWidth: "0.5px",
              // borderRadius: '0px'
            },
          },
        }}
        autoComplete="off"
        variant="outlined"
        // size='small'
        // variant='standard'
        aria-rowspan={false}
        fullWidth
        disabled={disabled}
        required={required}
        placeholder={placeholder}
        error={error}
        label={label}
        helperText={error ? errorMessage : helperText}
        value={defaultValue}
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        type={type}
        InputLabelProps={{
          style: customLabelStyle,
        }}
        inputProps={{
          maxLength: 64, // Set the maxLength property
          // ...(type === "date" ? { max: currentDate } : { pattern: pattern }),
        }} // Don't pass pattern for date field
      />
    </Box>
  );
}

export default CustomTextField;
