import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import LabelText from "../../FormComponent/LabelText";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import FormDivider from "../../FormComponent/FormDivider";
import TitleText from "../../FormComponent/TitleText";
import SubtitleText from "../../FormComponent/SubtitleText";
import CustomTextField from "../../FormComponent/CustomTextField";
import { relativeTimeRounding } from "moment";
import Upload_Documents from "../../SettlementComponents/UploadDocument";
import ValueText from "../../FormComponent/ValueText";
import { setCacheNameDetails } from "workbox-core";
import axios from "axios";

import CaptionText from "../../FormComponent/CaptionText";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import SucessAlert from "../../FormComponent/SuccessAlert";
import UploadDocuments from "./../../SettlementComponents/UploadDocument";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { trimStringUtility } from "../../Utility/UtilityMethods";
import ConfirmationDialogue from "../../FormComponent/ConfirmationDialogue";
import {
  checkNameMatch,
  dateOfCessationCheck,
  isNotCessionInApril,
} from "../../Utility/CheckerMethods";

function ClaimFormPF(props) {
  const { isLoading, setIsLoading } = "";
  const { cmpfAccNo } = props;
  const { memberData, nomineeDetails } = props;
  // state for checkbox for the claimant and member same
  const [claimantSelf, setClaimantSelf] = useState(true);
  // state for settlement para case

  const [claimTypeData, setClaimTypeData] = useState([]);
  const [settlementPara, setSettlementPara] = useState([]);

  // to store selected claimant data
  const [selectedClaimant, setSelectedClaimant] = useState({});
  // ******* for declaration ******
  const [checked, setChecked] = useState(false);
  //docs
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [fileSizeExceed, setFileSizeExceed] = useState(false);

  /***********for dropdown lists*************** */
  const [claimTypeOptions, setClaimTypeOptions] = useState([]);

  /***********for service records and aadhaar pan not matching*************** */
  const [recordErrors, setRecordErrors] = useState(false);
  const [recordErrorMessage, setRecordErrorMessage] = useState("");
  const [errorTitle, setErrorTitle] = useState("");

  /***********for Eligiblity conditions not met for raising PF claim*************** */
  const [eligiblityErrors, setEligiblityErrors] = useState(false);
  const [eligiblityErrorMessage, setEligiblityErrorMessage] = useState("");
  // const [errorTitle, setErrorTitle] = useState("");

  /***********for submit failed*************** */
  const [isFailed, setIsFailed] = useState(false);
  const [failedErrorMessage, setFailedErrorMessage] = useState("");

  /***********for submit success*************** */
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [submitSuccessMsg, setSubmitSuccessMsg] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  /***********for intrest calculation success*************** */
  //const [intrest, setIntrest] = useState(0);
  //const [totalAmountWithInterest, setTotalAmountWithInterest] = useState(0);
  /*************others*********** */
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [showingDropdown, setShowingDropdown] = useState(0);
  const [assignToOption, setAssignToOption] = useState([]);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [documentTypes, setDocumentTypes] = useState([]);
  const [validationFailed, setValidationFailed] = useState(false);
  const [validationFailedMsg, setValidationFailedMsg] = useState("");
  const [selectedOfficerName, setSelectedOfficerName] = useState("");

  const [loginId, setLoginId] = useState();

  // dummyAccountnum
  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (token) {
      token = token.replace('"', "").replace('"', "");
      let loginId = jwtDecode(token).user; // Assuming a default loginId
      setLoginId(loginId);
      console.log("loooooooooooooooooooooginId", loginId);
      // Add any further logic here based on the loginId
    } else {
      // Handle the case when token is not present
      setIsFailed(true);
      setIsLoading(false);
    }
  }, []);

  //  objec
  // const dummyNo = "HYD/170/840";
  //const loginId = "UDA-CC-OFF-3052";
  //  let token = localStorage.getItem("jwt");

  //  if (!token) {
  //    setIsFailed(true);
  //    setIsLoading(false);
  //    return;
  //  }

  // let token = token.replace('"', "").replace('"', "");
  //  let loginId = jwtDecode(token).user; // Assuming a default loginId

  //  console.log("loooooooooooooooooooooginId",loginId);

  const dateOptions = { day: "2-digit", month: "2-digit", year: "numeric" };

  // transfer the default object to model.js after confirming the object with backend
  const defaultClaim = {
    claimant_name: "",
    claim_type: "",
    mem_first_name_aadhar: "",
    mem_middle_name_aadhar: "",
    mem_last_name_aadhar: "",
    mem_first_name_bank: "",
    mem_middle_name_bank: "",
    mem_last_name_bank: "",
    mem_first_name_pan: "",
    mem_middle_name_pan: "",
    mem_last_name_pan: "",
    mem_dob_aadhar: "",
    mem_dob_pan: "",
    toLoginId: "",
    relation: "",
    fhw_name: "",
    rate_of_intrest: "",
    date_of_cessation: "",
    noOfMonths: "0",
    totMemContribution: "0",
    totEmpContribution: "0",
    totVolContribution: "0",
  };
  console.log("memberdata", memberData);

  // const docTypes = [
  //   { id: "1", title: "Application in SAHAJ Form" },
  //   { id: "2", title: "Advance Stamped Receipt" },
  //   { id: "3", title: "Copy of S.B. A/c Pass Book (F & S in case of Alive)" },
  //   {
  //     id: "4",
  //     title:
  //       "Contribution Details (PFC, FPC, 2%, Additional Increment & 7% Pension Contribution)",
  //   },
  //   { id: "5", title: "Form-O of management/company in case of Medical Unfit" },
  //   {
  //     id: "6",
  //     title:
  //       "Copy of Office Order for cessation of service (Termination Order)",
  //   },
  //   {
  //     id: "7",
  //     title: "Form-O of management/company in case of Medical Unfit.",
  //   },
  //   { id: "8", title: "Ref-4" },
  //   { id: "9", title: "Death Certificate of the Member" },
  //   {
  //     id: "10",
  //     title: "Schedule-C (Form Ref-5). (If nomination is not available)",
  //   },
  //   { id: "11", title: "Nomination Form – A" },
  //   {
  //     id: "12",
  //     title:
  //       "Copy of S.B. A/c Pass Book (In case of minor of Guardian with child) (F & S in case of Alive)",
  //   },
  //   { id: "13", title: "Joint Photograph of Claimant with Minor" },
  //   {
  //     id: "14",
  //     title: "Genuineness Certificate in leiu of 3 yrs delay in PF Claim",
  //   },
  // ];

  const [claim, setClaim] = useState(defaultClaim);

  const navigate = useNavigate();

  const claimantNameList = nomineeDetails.map((member) => ({
    id: member.familyMemId,
    title: member.name,
  }));

  // adding the self option to the list using push
  claimantNameList.push({ id: 0, title: "Self" });

  console.log("familymemerid", claimantNameList[0].id);

  const claimant_name = {
    name: "claimant_name",
    placeholder: "Claimant Name",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    // initialValue: claim.claimant_name,
    initialValue: "",

    required: true,
    //options: [{ id: '1', title: "Hindu" }, { id: '2', title: 'Christian' }, { id: '3', title: 'Muslim' }, { id: '4', title: 'Jain' }, { id: '5', title: 'Sikh' }, { id: 6, title: 'Buddhist' }, { id: 7, title: 'Other' }],
    options: claimantNameList,
  };

  const claim_type = {
    name: "claim_type",
    placeholder: "Select Claim Type",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: claim.claim_type,
    required: true,
    //options: [{ id: '1', title: "Hindu" }, { id: '2', title: 'Christian' }, { id: '3', title: 'Muslim' }, { id: '4', title: 'Jain' }, { id: '5', title: 'Sikh' }, { id: 6, title: 'Buddhist' }, { id: 7, title: 'Other' }],
    options: claimTypeOptions,
  };
  // const relation_type = {
  //   name: "relation_type",
  //   placeholder: "Relation with Member",
  //   //label: "Select CC",
  //   helperText: "",
  //   //disabled: readOnly,
  //   initialValue: claim.relation_type,
  //   required: true,
  //   //options: [{ id: '1', title: "Hindu" }, { id: '2', title: 'Christian' }, { id: '3', title: 'Muslim' }, { id: '4', title: 'Jain' }, { id: '5', title: 'Sikh' }, { id: 6, title: 'Buddhist' }, { id: 7, title: 'Other' }],
  //   options: relationList,
  // };
  const relation = {
    name: "relation",
    placeholder: "Select Relation",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: claim.relation,
    required: true,
    options: [
      { id: "1", title: "W/O" },
      { id: "2", title: "D/O" },
      { id: "3", title: "S/O" },
      { id: "4", title: "H/O" },
    ],
    // options: claimTypeList,
    //options: claimTypeOptions, // uncomment on integration
  };

  const mem_first_name_aadhar = {
    name: "mem_first_name_aadhar",
    type: "text",
    helperText: "",
    placeholder: "First Name",
    defaultValue: claim.mem_first_name_aadhar,
    errorMessage: "Invalid",
    pattern: /^[A-Za-z. ]+$/,
    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const mem_middle_name_aadhar = {
    name: "mem_middle_name_aadhar",
    type: "text",
    helperText: "",
    placeholder: "Middle Name",
    defaultValue: claim.mem_middle_name_aadhar,
    errorMessage: "Invalid",
    pattern: /^[A-Za-z. ]+$/,
    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_last_name_aadhar = {
    name: "mem_last_name_aadhar",
    type: "text",
    helperText: "",
    placeholder: "Last Name",
    defaultValue: claim.mem_last_name_aadhar,
    errorMessage: "Invalid",
    pattern: /^[A-Za-z. ]+$/,
    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_first_name_pan = {
    name: "mem_first_name_pan",
    type: "text",
    helperText: "",
    placeholder: "First Name",
    defaultValue: claim.mem_first_name_pan,
    errorMessage: "Invalid",
    pattern: /^[A-Za-z. ]+$/,
    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_middle_name_pan = {
    name: "mem_middle_name_pan",
    type: "text",
    helperText: "",
    placeholder: "Middle Name",
    defaultValue: claim.mem_middle_name_pan,
    errorMessage: "Invalid",
    pattern: /^[A-Za-z. ]+$/,
    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_last_name_pan = {
    name: "mem_last_name_pan",
    type: "text",
    helperText: "",
    placeholder: "Last Name",
    defaultValue: claim.mem_last_name_pan,
    errorMessage: "Invalid",
    pattern: /^[A-Za-z. ]+$/,
    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_first_name_bank = {
    name: "mem_first_name_bank",
    type: "text",
    helperText: "",
    placeholder: "First Name",
    defaultValue: claim.mem_first_name_bank,
    errorMessage: "Invalid",
    pattern: /^[A-Za-z. ]+$/,
    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_middle_name_bank = {
    name: "mem_middle_name_bank",
    type: "text",
    helperText: "",
    placeholder: "Middle Name",
    defaultValue: claim.mem_middle_name_bank,
    errorMessage: "Invalid",
    pattern: /^[A-Za-z. ]+$/,
    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_last_name_bank = {
    name: "mem_last_name_bank",
    type: "text",
    helperText: "",
    placeholder: "Last Name",
    defaultValue: claim.mem_last_name_bank,
    errorMessage: "Invalid",
    pattern: /^[A-Za-z. ]+$/,
    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const mem_dob_aadhar = {
    name: "mem_dob_aadhar",
    type: "date",
    helperText: "",
    placeholder: "Select Date of Birth",
    defaultValue: claim.mem_dob_aadhar,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const mem_dob_pan = {
    name: "mem_dob_pan",
    type: "date",
    helperText: "",
    placeholder: "Select Date of Birth",
    defaultValue: claim.mem_dob_pan,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const fhw_name = {
    name: "fhw_name",
    type: "text",
    helperText: "",
    placeholder: "Father/Husband/Wife Name",
    defaultValue: claim.fhw_name,
    errorMessage: "Invalid",
    pattern: /^[A-Za-z. ]+$/,
    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const date_of_cessation = {
    name: "date_of_cessation",
    type: "date",
    helperText: "",
    placeholder: "Date of Cessation",
    defaultValue: claim.date_of_cessation,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const date_of_death = {
    name: "date_of_death",
    type: "date",
    helperText: "",
    placeholder: "Date of Death",
    defaultValue: claim.date_of_death,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const date_of_birth_claimant = {
    name: "date_of_birth_claimant",
    type: "date",
    helperText: "",
    placeholder: "Dob claimant",
    defaultValue: "",
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const para = {
    name: "para",
    type: "text",
    helperText: "",
    placeholder: "Option Under para 15",
    defaultValue: "",
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const toLoginId = {
    name: "toLoginId",
    placeholder: "Select Officer",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: claim.toLoginId,
    required: true,
    options: assignToOption,
  };

  const noOfMonths = {
    name: "noOfMonths",
    type: "number",
    helperText: "",
    placeholder: " Months ",
    defaultValue: claim.noOfMonths,
    errorMessage: "No of months can be 0 to 11 only ",
    pattern: /^(0?[0-9]|1[0-2])$/,
    transformInput: trimStringUtility,
  };
  const totMemContribution = {
    name: "totMemContribution",
    type: "number",
    helperText: "",
    placeholder: "Member Contribution ",
    defaultValue: claim.totMemContribution,
    errorMessage: "Invalid",
    pattern: /^[0-9]+$/,
    transformInput: trimStringUtility,
  };
  const totEmpContribution = {
    name: "totEmpContribution",
    type: "number",
    helperText: "",
    placeholder: "Employer Contribution ",
    defaultValue: claim.totEmpContribution,
    errorMessage: "Invalid",
    pattern: /^[0-9]+$/,
    transformInput: trimStringUtility,
  };
  const totVolContribution = {
    name: "totVolContribution",
    type: "number",
    helperText: "",
    placeholder: "Voluantry Contribution ",
    defaultValue: claim.totVolContribution,
    errorMessage: "Invalid",
    pattern: /^[0-9]+$/,
    transformInput: trimStringUtility,
  };
  //console.log("the nomiationd detais form on claim form", nomineeDetails);

  // checkbox handler
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  // Handler function to update the DOCS from child
  const handleStateUpdate = (documentData) => {
    const requestBody = {
      cmpfAcNo: cmpfAccNo,
      uploadAdvPfDocumentsDTO: documentData.map((upload) => ({
        fileContent: upload.uploadedDocument,
        docTypeId: upload.selectedDocumentType,
      })),
    };
    setUploadedDocs(requestBody.uploadAdvPfDocumentsDTO);
    console.log("requestBody", requestBody);
  };
  // function to check wheater the genuniess by management certificate is present or not if the name is not matching wiht aadhar
  const checkGenCertificate = () => {
    console.log("uploadedDocs", uploadedDocs);

    return uploadedDocs.some((docs) => {
      console.log("mapped docs", docs);
      return docs.docTypeId === 25;
    });
  };

  // handler function for fileExcced child upload docs
  const handleFileSizeUpdate = (newState) => {
    setFileSizeExceed(newState);
  };
  //************************validations************************** */
  const patternChecker = (param, pattern) => {
    //console.log("param in patten cheker", param);
    return pattern.test(param);
  };

  //console.log("theClaim", claim);

  const validate = () => {
    // when the ro enrollment begins changed are needed in the field
    console.log("Claimant Name", claim.claimant_name);
    const requiredFields = [
      { field: "Claimant Name", value: claim.claimant_name.toString() },
      { field: "Cause of Cessation", value: claim.claim_type },
      { field: "Date of Cessation", value: claim.date_of_cessation },
      {
        field: "First Name as per Aadhaar",
        value: claim.mem_first_name_aadhar,
      },
      {
        field: "First Name as per Bank",
        value: claim.mem_first_name_bank,
      },
      {
        field: "First Name as per pan",
        value: claim.mem_first_name_pan,
      },
      {
        field: "Date of birth as per Aadhaar",
        value: claim.mem_dob_aadhar,
      },
      {
        field: "Date of birth as per PAN",
        value: claim.mem_dob_pan,
      },

      {
        field: "Date of birth as per PAN",
        value: claim.mem_dob_pan,
      },

      //** pension feilds are differnt form here */
      // {
      //   field: "No of broken period months",
      //   value: claim.noOfMonths,
      // },
      // {
      //   field: "Total Member contribution for broken period",
      //   value: claim.totMemContribution,
      // },
      // {
      //   field: "Total Employer contribution for broken period",
      //   value: claim.totEmpContribution,
      // },
      // {
      //   field: "Total Voluntary contribution for broken period",
      //   value: claim.totVolContribution,
      // },
    ];

    const fieldsToValidate = [
      {
        field: "First Name as per Aadhaar",
        value: claim.mem_first_name_aadhar,
        pattern: /^[A-Za-z. ]+$/,
      },
      {
        field: "First Name as per PAN",
        value: claim.mem_first_name_pan,
        pattern: /^[A-Za-z. ]+$/,
      },
      {
        field: "First Name as per Bank",
        value: claim.mem_first_name_pan,
        pattern: /^[A-Za-z. ]+$/,
      },
      {
        field: " Middle Name as per Aadhaar",
        value: claim.mem_middle_name_aadhar,
        pattern: /^[A-Za-z. ]+$/,
      },
      {
        field: "Middle Name as per PAN",
        value: claim.mem_middle_name_pan,
        pattern: /^[A-Za-z. ]+$/,
      },
      {
        field: "Middle Name as per Bank",
        value: claim.mem_middle_name_pan,
        pattern: /^[A-Za-z. ]+$/,
      },
      {
        field: "No of Months",
        value: claim.noOfMonths,

        pattern: /^(0?[0-9]|1[0-2])$/,
      },
      {
        field: "Total Member Contribution",
        value: claim.totMemContribution,

        pattern: /^[0-9]+$/,
      },
      {
        field: "Total Employer Contribution",
        value: claim.totEmpContribution,

        pattern: /^[0-9]+$/,
      },
      {
        field: "Total Voluantry Contribution",
        value: claim.totVolContribution,

        pattern: /^[0-9]+$/,
      },
      {
        field: "Father/Husband/Wife Name",
        value: claim.fhw_name,

        pattern: /^[A-Za-z. ]+$/,
      },
    ];

    for (const fieldData of requiredFields) {
      const { field, value } = fieldData;

      if (!value) {
        console.log(`${field} is required.`);
        setValidationFailed(true);
        setValidationFailedMsg(`${field} is required.`);
        return false; // Return false if any required field is empty
      }
    }

    for (const fieldData of fieldsToValidate) {
      const { field, value, pattern } = fieldData;

      if (value && pattern && !patternChecker(value, pattern)) {
        // console.log("validate values", typeof value);
        console.log(`${field} has invalid values. Please Check.`);
        setValidationFailed(true);
        setValidationFailedMsg(`${field} has invalid values. Please Check.`);
        return false; // If validation fails for any field, return false
      }
    }

    return true; // If all required fields are non-empty and patterns are valid, return true
  };

  //****************Dropdown******************* */
  const fetchAssignOfficer = async () => {
    try {
      console.log("in oooooooooooooooooooooo");
      // if (!(await sessionExist())) {
      //   setIsFailed(true);
      //   setIsLoading(false);
      //   return;
      // }
      // console.log("tokensssssssssssssssssss");
      // let token = localStorage.getItem("jwt");

      // if (!token) {
      //   setIsFailed(true);
      //   setIsLoading(false);
      //   return;
      // }

      //token = token.replace('"', "").replace('"', "");
      // const loginId = jwtDecode(token).user;
      // const loginId = "UDA-CC-OFF-2001";

      console.log("loginId", loginId);
      const url =
        process.env.REACT_APP_SERVER_URL +
        "/dropdown/for/show/next/authorized/offcr/adv/pf/pen/uda";

      // "/dropdown/for/adv/pf/claim/aao/to/ids";
      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      const options = data.map((option) => ({
        id: option.toOfficerlogin,
        title: `${option.officerName} (${option.toOfficerlogin})`,
      }));
      setAssignToOption(options); // Assuming setAssignToOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };

  console.log("added docs", uploadedDocs);
  console.log("fileSize", fileSizeExceed);
  /***********Integrations functions***************** */

  const fetchDocumentsToUploadList = async () => {
    const url =
      process.env.REACT_APP_SERVER_URL + "/get/pf/claim/mandatory/documents";

    axios.get(url).then((response) => {
      const documentList = response.data.data;
      console.log("response in documentList", response);

      const options = documentList.map((option) => ({
        id: option.id, // change here after integration
        title: option.title,
      }));
      setDocumentTypes(options);

      console.log("document types", options);
      // setClaimTypeOptions(options);
    });
  };

  const fetchClaimTypes = async () => {
    const url =
      process.env.REACT_APP_SERVER_URL +
      "/get/all/pf/claim/type/settlement/para";

    axios.get(url).then((response) => {
      const claimSamples = response.data.data;
      console.log("response in claimList", response);

      let options = [];
      if (!claimantSelf) {
        // If claimantSelf is true
        options = claimSamples
          .filter(
            (option) => option.claimTypeId === 1 || option.claimTypeId === 2
          ) // Filter based on claimTypeId 1 or 2
          .map((option) => ({
            id: option.claimTypeId,
            title: option.claimTypDesc,
          }));
      } else {
        // If claimantSelf is false
        options = claimSamples
          .filter(
            (option) => option.claimTypeId !== 1 && option.claimTypeId !== 2
          ) // Filter based on claimTypeId other than 1 and 2
          .map((option) => ({
            id: option.claimTypeId,
            title: option.claimTypDesc,
          }));
      }

      setClaimTypeData(response.data.data);
      console.log("claim List", options);
      setClaimTypeOptions(options);
    });
  };

  //*********function to fetch the documents to be populated on the dropdown list******** */

  //*********function to check the name in document proofs vs service records********* */
  // function checkNameMatch(fullName, firstName, middleName, lastName) {
  //   const fullNameParts = fullName.toLowerCase();
  //   const first = firstName.toLowerCase();
  //   const middle = middleName.toLowerCase();
  //   const last = lastName.toLowerCase();
  //   console.log("name functions", fullNameParts, first, middle, last);

  //   if (
  //     fullNameParts.includes(first) &&
  //     fullNameParts.includes(middle) &&
  //     fullNameParts.includes(last)
  //   ) {
  //     console.log( "name check status",
  //       fullNameParts.includes(first) &&
  //         fullNameParts.includes(middle) &&
  //         fullNameParts.includes(last)
  //     );
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  // function checkNameMatch(fullName, firstName, middleName, lastName) {
  //   const fullNameParts = fullName.split(" ");
  //   const first = firstName ? firstName.toLowerCase() : "";
  //   const middle = middleName ? middleName.toLowerCase() : "";
  //   const last = lastName ? lastName.toLowerCase() : "";
  //   console.log("first", first, middle, last);
  //   const firstMatch =
  //     first === "" ||
  //     fullNameParts.filter((name) => name.toLowerCase() === first).length === 1;
  //   const middleMatch =
  //     middle === "" ||
  //     fullNameParts.filter((name) => name.toLowerCase() === middle).length ===
  //       1;
  //   const lastMatch =
  //     last === "" ||
  //     fullNameParts.filter((name) => name.toLowerCase() === last).length === 1;
  //   console.log("inside name check", firstMatch, middleMatch, lastMatch);

  //   return firstMatch && middleMatch && lastMatch;
  // }

  /********* function to check the age of son ******** */

  function checkEligibility(claimantRelation, claimant) {
    console.log("selectedClaimant", selectedClaimant);

    console.log("relation", claimantRelation);
    if (claimantRelation) {
      switch (claimantRelation.toLowerCase()) {
        case "wife":
          // Check condition 1 for wife
          console.log(
            "selectedClaimant.marital",
            selectedClaimant.maritalStatus
          );
          if (claimant.maritalStatus !== "W") {
            setEligiblityErrorMessage(
              "Marital Status of Wife is not Widow. Claim cannot proceed."
            );
            return false;
          }
          break;
        case "son":
          // Check condition 1 for son
          if (claimant.age > 18) {
            setEligiblityErrorMessage(
              "Age of Son is more than 18. Claim cannot proceed."
            );
            return false;
          }
          break;
        case "daughter":
          // Check condition 2 for son or daughter
          if (claimant.marital === "M") {
            setEligiblityErrorMessage(
              "Marital Status of Daughter is Married. Claim cannot proceed."
            );
            return false;
          }
          break;
        default:
          return true; // Return true for other relations
      }
      return true; // Return true if all conditions are met
    }
  }

  /**** checking the names ******/
  function nameCheck() {
    const fullName = memberData.fullName;
    const aadhaarCheck = checkNameMatch(
      fullName,
      claim.mem_first_name_aadhar,
      claim.mem_middle_name_aadhar,
      claim.mem_last_name_aadhar
    );
    const panCheck = checkNameMatch(
      fullName,
      claim.mem_first_name_pan,
      claim.mem_middle_name_pan,
      claim.mem_last_name_pan
    );
    const bankStateCheck = checkNameMatch(
      fullName,
      claim.mem_first_name_bank,
      claim.mem_middle_name_bank,
      claim.mem_last_name_bank
    );

    return aadhaarCheck && panCheck && bankStateCheck;
  }

  /*********function to check the dates enterd*********/
  function checkDateOfBirthMatch(enteredDate, givenDate) {
    if (enteredDate) {
      // const enteredDateParts = enteredDate.split("-");
      // const givenDateParts = givenDate.split("-");
      console.log("givendate,entereddate", givenDate, enteredDate);
      const enteredDateParts = enteredDate.split("-");
      const givenDateParts = givenDate.split("-");

      const enteredDateObj = new Date(
        enteredDateParts[2],
        enteredDateParts[1] - 1,
        enteredDateParts[0]
      );
      const givenDateObj = new Date(
        givenDateParts[2],
        givenDateParts[1] - 1,
        givenDateParts[0]
      );

      if (enteredDateObj.getTime() === givenDateObj.getTime()) {
        return true; // Date of birth matches
      } else {
        return false; // Date of birth does not match
      }
    } else {
      return false;
    }
  }

  /*****************checking dob from aadhaar and pan******************/
  function dobCheck() {
    const memberDob = new Date(memberData.dateOfBirth)
      .toLocaleDateString("en-GB", dateOptions)
      .split("/")
      .reverse()
      .join("-");
    const aadhaarDobCheck = checkDateOfBirthMatch(
      memberDob,
      claim.mem_dob_aadhar
    );
    const panDobCheck = checkDateOfBirthMatch(claim.mem_dob_pan, memberDob);
    return aadhaarDobCheck && panDobCheck;
  }

  ///*** checking mandatory documents***** */
  ///******* function to get the mandatory document for the given claimId as an array*/

  const getMandatoryDocIdsByClaimTypeId = (claimTypeId) => {
    const mandatoryDocs = claimTypeData.find(
      (type) => type.claimTypeId === claimTypeId
    );
    console.log("mandatory docs", mandatoryDocs);
    return claimTypeId.mandatoryDocIds
      ? claimTypeId.mandatoryDocIds.split(",")
      : [];
  };
  ///******* function to get the uploaded documents  */
  //**this function returns the document id of the documents which are selected and has a document uploaded in choose file */
  // const getDocTypes = (documents) => documents.map((file) => file.id);// this didnt check the fileContent
  const getDocTypes = (documents) => {
    console.log("docuemnetnt uploeasd", uploadedDocs);
    return (
      documents
        // .filter((file) => file.fileContent != null)
        // .map((file) => file.id);
        .filter((file) => file.fileContent != null)
        .map((file) => file.docTypeId)
    );
  };

  ///******* function to get missing mandatory documents  */
  const getMissingMandatoryDocs = (uploadedDocs, mandatoryDocs) => {
    const uploadedDocTypes = getDocTypes(uploadedDocs);
    return mandatoryDocs.filter((docId) => !uploadedDocTypes.includes(docId));
  };

  ///******* function to get the *Name* of missing mandatory Doc */
  const getMissingMandatoryDocNames = (missingMandatoryDocs, docTypes) => {
    const missingDocs = missingMandatoryDocs.map((docId) => {
      const matchingDoc = docTypes.find((doc) => doc.id === docId);
      return matchingDoc
        ? matchingDoc.title
        : `Unknown documents with id ${docId}`;
    });

    const missingDocsName = missingDocs.join(`,\n`);
    return missingDocsName;
  };

  const checkDocuments = () => {
    console.log("uploaded documents", uploadedDocs);
    console.log("claimData", claimTypeData);
    console.log("claimtype", claim.claim_type);
    const mandatoryDocs = getMandatoryDocIdsByClaimTypeId(claim.claim_type);
    console.log("mandatoryDocs", mandatoryDocs);
    //const getDocTypes = (documents) => documents.map((file) => file.id);
    if (mandatoryDocs.length !== 0) {
      const missingMandatoryDocs = getMissingMandatoryDocs(
        uploadedDocs,
        mandatoryDocs
      );
      if (missingMandatoryDocs.length !== 0) {
        console.log("missingMandatoryDocs", missingMandatoryDocs);
        const missingDocName = getMissingMandatoryDocNames(
          missingMandatoryDocs,
          documentTypes
        );
        console.log("missingDocName", missingDocName);
        setRecordErrorMessage(
          `${missingDocName}   are missing, Please upload.`
        );
        setErrorTitle("Mandatory Documents are Missing");
        setRecordErrors(true);
      }
    } else return true;
    console.log("getDocTypes", getDocTypes(uploadedDocs));
  };

  //////////*****final check before submit*********/
  const handleRecordErrorAlert = () => {
    setRecordErrors(false);
  };
  const handleIsFailedAlert = () => {
    setIsFailed(false);
  };

  const handleEligiblityError = () => {
    setIsFailed(false);
    navigate("/uda/pfset");
  };

  const handleSuccessOk = () => {
    setIsSubmitSuccess(false);
    navigate("/uda/pfset");
  };

  useEffect(() => {
    fetchClaimTypes();
  }, [claimantSelf]);

  useEffect(() => {
    console.log("fetchAssignOfficer");
    fetchAssignOfficer();
  }, [loginId]);

  useEffect(() => {
    fetchDocumentsToUploadList();
  }, []);

  useEffect(() => {
    //console.log("claimTypeData", claimTypeData);
    //console.log("claimTypeIn", claim.claim_type);
    const selectedClaimId = claim.claim_type;
    const selectedClaimType = claimTypeData.find(
      (claim) => claim.claimTypeId === selectedClaimId
    );
    console.log("SelectedClaim", selectedClaimType);
    if (selectedClaimType) {
      setSettlementPara(selectedClaimType.settlementParaNumber);
    } else {
      setSettlementPara("");
    }
  }, [claim.claim_type, claimTypeData]);

  // not using this function anymore kept here for reference

  // const updateFormattedData = (data) => {
  //   console.log("data", data);

  //   const formatted = data.map((item) => {
  //     return `${item.month} - ${item.memberContribution}, ${item.employerContribution}, ${item.volunataryContribution}`;
  //   });
  //   console.log("fomatted data", formatted);

  //   const concatenatedString = formatted.join(", ");

  //   console.log("concatenated data", concatenatedString);

  //   setFormattedString(concatenatedString);
  // };

  // onChange for all textfeilds
  const onChange = (e) => {
    setClaim({ ...claim, [e.target.name]: e.target.value });

    if (e.target.name === "claimant_name") {
      console.log("claimant_name", e.target.value);
      const id = e.target.value;
      if (id === 0) {
        setClaimantSelf(true);
        setSelectedClaimant({});
      } else {
        setClaimantSelf(false);
        const selectedFamilyMember = nomineeDetails.find(
          (member) => member.familyMemId === id
        );
        if (
          !checkEligibility(
            selectedFamilyMember.relationName,
            selectedFamilyMember
          )
        ) {
          setEligiblityErrors(true);
        }
        // console.log("familymemerid", member.familyMemId);
        console.log("selected family member", selectedFamilyMember);
        setSelectedClaimant(selectedFamilyMember);
        //setSelectedClaimant(e.target.value);
      }
    }
    if (
      e.target.name === "noOfMonths" ||
      e.target.name === " totMemContribution" ||
      e.target.name === "totEmpContribution" ||
      e.target.name === "totVolContribution"
    ) {
      // console.log("in broken check", e.target.value);
      if (e.target.value !== "0" && e.target.value !== "") {
        //  console.log("in broken check inside", e.target.value);
        if (!isNotCessionInApril(claim.date_of_cessation)) {
          setRecordErrors(true);
          setErrorTitle("Invalid");
          setRecordErrorMessage(
            "Broken period months is 0 when the date of cessation is in month of April. Cannot Enter Broken Period Contributions."
          );
        }
      }
    }
    if (e.target.name === "toLoginId") {
      const selectedOption = assignToOption.find(
        (option) => option.id === e.target.value
      );
      if (selectedOption) {
        setSelectedOfficerName(selectedOption.title); // Set the selected officer's name in the state
      }
    }
  };

  //******* for table to enter the broken period *************/
  const [tableData, setTableData] = useState([]);

  // Called when a new row is added
  const handleRowAddition = useCallback((currentRows) => {
    const newRowData = {}; // Define the structure of the new row
    setTableData([...currentRows, newRowData]);
    return true; // or return false if you want to prevent the row from being added
  }, []);

  // Called when a row is deleted
  const handleRowDeletion = useCallback((deleteIndex, currentRows) => {
    const updatedRows = currentRows.filter(
      (row, index) => index !== deleteIndex
    );
    setTableData(updatedRows);
  }, []);

  // Called when any row data has changed
  const handleRowChange = useCallback((rowIndex, field, value, currentRows) => {
    const updatedRows = [...currentRows];
    const updatedRow = { ...updatedRows[rowIndex], [field]: value };
    updatedRows[rowIndex] = updatedRow;
    setTableData(updatedRows);
  }, []);

  // function to get the month options in format "APF 2023"
  const getFinancialYearMonths = (selectedDateStr) => {
    const selectedDateParts = selectedDateStr.split("-");
    const selectedDate = new Date(
      selectedDateParts[2],
      selectedDateParts[1] - 1,
      selectedDateParts[0]
    ); // Create a Date object from the selected date

    const options = [];
    let startYear, endYear;

    if (selectedDate.getMonth() < 3) {
      startYear = selectedDate.getFullYear() - 1;
      endYear = selectedDate.getFullYear();
    } else {
      startYear = selectedDate.getFullYear();
      endYear = selectedDate.getFullYear() + 1;
    }

    for (let i = 3; i < 15; i++) {
      // Looping through the next 12 months starting from April
      const monthIndex = i % 12;
      const year = monthIndex < 3 ? endYear : startYear;
      const month = new Date(year, monthIndex, 1).toLocaleString("default", {
        month: "short",
      });
      const formattedOption = `${month} ${year}`;
      options.push(formattedOption);
    }
    return options;
  };

  //*** Field configrations for the table****/
  const fieldConfig = [
    {
      name: "month",
      headerName: "Month",
      render: (value, onChange) => (
        <Autocomplete
          options={getFinancialYearMonths("07 - 1 - 2023")} // ener date of cessaion here
          value={value}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ width: 170 }}
              placeholder="Select Month "
            />
          )}
          onChange={(event, newValue) => onChange(newValue)}
        />
      ),
    },
    {
      name: "memberContribution",
      headerName: "Member Contribution",
      render: (value, onChange) => (
        <TextField
          fullWidth
          variant="outlined"
          type="number"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Enter Member Contribution"
        />
      ),
    },
    {
      name: "employerContribution",
      headerName: "Employer Contribution",
      render: (value, onChange) => (
        <TextField
          fullWidth
          variant="outlined"
          type="number"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Enter Employer Contribution"
        />
      ),
    },
    {
      name: "volunataryContribution",
      headerName: "Voluntary Contribution",
      render: (value, onChange) => (
        <TextField
          fullWidth
          variant="outlined"
          type="number"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Enter Voluntary Contribution"
        />
      ),
    },
    // {
    //   name: "rateOfIntrest",
    //   headerName: "Rate of Interest",
    //   render: (value, onChange) => (
    //     <TextField
    //       fullWidth
    //       variant="outlined"
    //       type="number"
    //       value={value}
    //       onChange={(e) => onChange(e.target.value)}
    //       placeholder="Enter Rate of Intrest"
    //     />
    //   ),
    // },

    // Continue adding other fields' configurations as necessary
  ];

  const handleErrorAlert = () => {
    setIsError(false);
  };

  const handleValidationFailedAlert = () => {
    setValidationFailed(false);
    setIsLoading(false);
  };

  const handleSubmitOk = () => {
    if (!checked) {
      // Show message in dialog: "Please select check Box"
      // alert("Please select the check box");
      setIsError(true);
      setErrorMessage("Please Select the Declaration Checkbox.");

      return;
    }
    if (showingDropdown === 1) {
      if (!claim.toLoginId) {
        // Show message in dialog: "Please select an officer to assign"
        // alert("Please select an officer to assign");
        setIsError(true);
        setErrorMessage("Please Select an Officer to Assign.");
        return;
      }
    }
    if (!validate()) {
      //uncomment
      console.log("validation falied");
      return;
    }
    if (claim.claim_type === 7) {
      if (
        !dateOfCessationCheck(claim.date_of_cessation, memberData.dateOfBirth)
      ) {
        setRecordErrors(true);
        setRecordErrorMessage(
          "Age of the member is not 60 on entered Date of Cessation. Kindly Check. "
        );
        setErrorTitle("Invalid Values");
        return;
      }
    }

    if (checkDocuments()) {
      if (checked === false) {
        setRecordErrors(true);
        setRecordErrorMessage("Please Check the declaration");
        setErrorTitle("Declaration not Selected");
        // setIsSubmitting(false);

        return;
      }
    }
    // uncomment
    // if (nameCheck() && dobCheck()) {
    if (!isNotCessionInApril(claim.date_of_cessation)) {
      if (
        claim.noOfMonths !== "0" ||
        claim.totMemContribution !== "0" ||
        claim.totEmpContribution !== "0" ||
        claim.totVolContribution !== "0"
      ) {
        setRecordErrors(true);
        setErrorTitle("Invalid");
        setRecordErrorMessage(
          "Broken period months is 0 when the date of cessation is in month of April. Cannot Enter Broken Period Contributions."
        );
        return;
      }
    }
    if (nameCheck()) {
      if (!claimantSelf) {
        if (checkEligibility(selectedClaimant.relationName, selectedClaimant)) {
          // const url =
          //   process.env.REACT_APP_SERVER_URL + "/submit/pf/claim/settlement/uda";
          // const headers = {
          //   //'Authorization': `Bearer ${token}`,
          //   "Content-Type": "application/json",
          // };
          // axios
          //   .post(url, formData, { headers })
          //   .then(function (response) {
          //     console.log("Submit response", response);
          //     postUploadedDoc();
          //   })
          //   .catch((error) => {
          //     console.log("error on submit", error);
          //     setIsFailed(true);
          //     setFailedErrorMessage("Something went Wrong!!");
          //   });
          setIsSubmitClicked(true);
        } else {
          setEligiblityErrorMessage(true);
          //setErrorTitle("Eligiblity conditions not met!!");
          // setRecordErrorMessage(
          //   "Eligiblity conditions not met. Please check the nominee details."
          // );
        }
      } else {
        setIsSubmitClicked(true); // do not apply eligiblity checks if the claimant is self
      }
    } else {
      // setRecordErrors(true);
      // setErrorTitle("Service Record Mismatch");
      // setRecordErrorMessage(
      //   "Entered Data does not match with service records please recheck or update the member details"
      // );
      //*** check the certificate of geniuness if names are not matching  */
      if (checkGenCertificate()) {
        console.log("checkGenCertificate", checkGenCertificate());
        setIsSubmitClicked(true);
      } else {
        // console.log("is it here", checkGenCertificate());

        setRecordErrors(true);
        setErrorTitle("Genuineness Certificate not Uploaded");
        setRecordErrorMessage(
          "Name entered does not match with the service records therefore it is mandatory to upload the Genuineness Certificate for Member's name "
        );
      }
    }
  };

  // todo handleAssignToOk calling handle submit on click of ok confirmation dialouge box
  const handleAssignToOk = (e) => {
    handleSubmit(e);
    setIsSubmitClicked(false);
  };

  const handleAssignToCancel = () => {
    setIsSubmitClicked(false);
  };

  const sample = [
    {
      month: "May 2022",
      memberContribution: "122",
      employerContribution: "123",
      volunataryContribution: "124",
    },
    {
      month: "May 2023",
      memberContribution: "123",
      employerContribution: "124",
      volunataryContribution: "1255",
    },
  ];

  // console.log("stringify", JSON.stringify(tableata));

  const postUploadedDoc = (responseStatus) => {
    const uploadDocData = {
      cmpfAccNo: cmpfAccNo,
      claimFlag: "PF",
      claimantId: claim.claimant_name,
      uploadedDocuments: uploadedDocs,
    };
    console.log("uploadDocData", uploadDocData);
    const url =
      process.env.REACT_APP_SERVER_URL + "/upload/pf/supported/documents";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, uploadDocData, { headers })
      .then(function (response) {
        console.log("Submit response", response);
        if (responseStatus === 202) {
          setSubmitSuccessMsg(
            "Claim Submitted Successfully. Notification not sent."
          );
        } else {
          setSubmitSuccessMsg("Claim Submitted Successfully.");
        }

        setIsSubmitSuccess(true);
        setIsSubmitting(false); // the loader stops only after upload document is success
      })
      .catch((error) => {
        setIsFailed(true);
        setFailedErrorMessage("Something went Wrong!!");
        setIsSubmitting(false); // the loader stops  after upload document is fails

        console.log("error on submit", error);
      });
  };
  console.log("tabledata", tableData);

  // const brokenperiodcontribution = () => {
  //   console.log("in this command");
  //   tableData.map((item) => {
  //     const total =
  //       item.memberContribution +
  //       item.employerContribution +
  //       item.volunataryContribution;
  //     console.log("total", total);
  //   });
  // };

  // useEffect(() => {
  //   const brokenperiodcontribution = () => {
  //     console.log("in this command");

  //     let totalInterest = 0;
  //     let totalAmountWithInterest = 0;
  //     let monthToCal = 0;
  //     tableData.forEach((item) => {
  //       console.log("month in object", item.month);
  //       if (monthObj.hasOwnProperty(item.month)) {
  //         monthToCal = monthObj[item.month];
  //         console.log("month to calculate", monthToCal);
  //       }
  //       console.log(
  //         "month to calculate outside",
  //         monthObj.hasOwnProperty(item.month)
  //       );

  //       const total =
  //         (parseInt(item.memberContribution, 10) || 0) +
  //         (parseInt(item.employerContribution, 10) || 0) +
  //         (parseInt(item.volunataryContribution, 10) || 0);

  //       console.log("total", total);

  //       const interestCalculated =
  //         (total * claim.rate_of_intrest * monthToCal) / 1200;

  //       totalInterest += interestCalculated;
  //       totalAmountWithInterest += total + interestCalculated;
  //       console.log("totalAmountWithInterest", totalAmountWithInterest);
  //       console.log("totalInterest", interestCalculated);
  //     });
  //     setIntrest(totalInterest);
  //     setTotalAmountWithInterest(totalAmountWithInterest);

  //     return {
  //       totalInterest,
  //       totalAmountWithInterest,
  //     };
  //   };
  //   brokenperiodcontribution();
  // }, [tableData, claim.rate_of_intrest]);

  const submitForm = (formData) => {
    setIsSubmitting(true);
    //setIsSubmitClicked(true);
    const url =
      process.env.REACT_APP_SERVER_URL + "/submit/pf/claim/settlement/uda";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, formData, { headers })
      .then(function (response) {
        console.log("Submit response", response);
        postUploadedDoc(response.status);
        //setIsSubmitClicked(false);
        //setIsSubmitting(false);
      })
      .catch((error) => {
        console.log("error on submit", error);
        //setIsSubmitClicked(false);
        setIsSubmitting(false);
        setIsFailed(true);
        if (error.response.data.http_status_code === 404) {
          setFailedErrorMessage(error.response.data.error_message);
        } else {
          setFailedErrorMessage("Something went Wrong!!");
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!claim.toLoginId) {
      // Show message in dialog: "Please select an officer to assign"
      // alert("Please select an officer to assign");
      setIsError(true);
      setErrorMessage("Please Select an Officer to Assign.");
      return;
    }

    console.log("i am in submit");
    // if (!(await sessionExist())) {
    //   setIsFailed(true);
    //   setIsLoading(false);
    //   return;
    // }

    // let token = localStorage.getItem("jwt");

    // if (!token) {
    //   setIsFailed(true);
    //   setIsLoading(false);
    //   return;
    // }

    // token = token.replace('"', "").replace('"', "");
    // const loginId = jwtDecode(token).user;
    // const loginId = "UDA-CC-OFF-2001";

    //*****Creating Form data to submit****/
    const formData = {
      cmpfAccNo: cmpfAccNo,
      // udaLoginId: "UDA-CC-OFF-101", // hardcoded
      // toLoginId: "UAO-CC-OFF-103",
      udaLoginId: loginId, // hardcoded

      toLoginId: claim.toLoginId,

      nameAadhaar: {
        firstname: claim.mem_first_name_aadhar,
        middlename: claim.mem_middle_name_aadhar,
        lastname: claim.mem_last_name_aadhar,
      },
      namePan: {
        firstname: claim.mem_first_name_pan,
        middlename: claim.mem_middle_name_pan,
        lastname: claim.mem_last_name_aadhar,
      },
      nameBank: {
        firstname: claim.mem_first_name_bank,
        middlename: claim.mem_middle_name_bank,
        lastname: claim.mem_last_name_bank,
      },
      dobAadhaar: claim.mem_dob_aadhar,
      dobPan: claim.mem_dob_pan,
      sfwhName: claim.fhw_name,
      sfwhRelationType: claim.relation,
      pfClaimTypId: claim.claim_type,
      declarationUda: checked,
      remarks: "",
      claimantId: claim.claimant_name,
      dateOfCessation: claim.date_of_cessation,
      ledgerBalance: 0, //caution hardcoded
      sanctionedAmt: 0, /// hardcoded
      dateOfdeath: claim.date_of_death ? claim.date_of_death : "",
      brokenPeriodInterestPayble: 0, /// hard coded
      brokenPeriodContri: 0, // hard coded
      brokenPeriodContriDet: "jan",
      brokenPeriodInterestRate: 0.0,
      brokenPeriodMonths: claim.noOfMonths,
      brokenPeriodMemShareContri: claim.totMemContribution,
      brokenPeriodEmplyrShareContri: claim.totEmpContribution,
      brokenPeriodVolContri: claim.totVolContribution,

      modeOfRemmit: "bank/cheque",
      remmitBankDetails: "name : sbi , ifsc : 223456",
      chequeNo: null,
    };

    console.log("formData", formData);
    //---------------------------------------------//

    //  console.log("save button Clicked data=>):", claimData);

    // console.log("fromdata to be sent", objectTobeSent);

    console.log("namecheck and dobcheck", nameCheck(), dobCheck());
    submitForm(formData);

    // if (uploadedDocs.length() === 0) {
    //   setRecordErrors(true);
    //   setRecordErrorMessage(
    //     "No documents uploaded. Make sure you click the upload button."
    //   );
    // }

    // if (nameCheck() && dobCheck()) {
    //   if (!claimantSelf) {
    //     if (checkEligibility(selectedClaimant.relationName)) {
    //       // const url =
    //       //   process.env.REACT_APP_SERVER_URL + "/submit/pf/claim/settlement/uda";
    //       // const headers = {
    //       //   //'Authorization': `Bearer ${token}`,
    //       //   "Content-Type": "application/json",
    //       // };
    //       // axios
    //       //   .post(url, formData, { headers })
    //       //   .then(function (response) {
    //       //     console.log("Submit response", response);
    //       //     postUploadedDoc();
    //       //   })
    //       //   .catch((error) => {
    //       //     console.log("error on submit", error);
    //       //     setIsFailed(true);
    //       //     setFailedErrorMessage("Something went Wrong!!");
    //       //   });
    //
    //     } else {
    //       setRecordErrors(true);
    //       setErrorTitle("Eligiblity conditions not met!!");
    //       // setRecordErrorMessage(
    //       //   "Eligiblity conditions not met. Please check the nominee details."
    //       // );
    //     }
    //   } else {
    //     submitForm(formData); // do not apply eligiblity checks if the claimant is self
    //   }
    // } else {
    //   setRecordErrors(true);
    //   setErrorTitle("Service Record Mismatch");
    //   setRecordErrorMessage(
    //     "Entered Data does not match with service records please recheck or update the member details"
    //   );
    // }
  };

  const handleProcessOk = (e) => {
    setShowingDropdown(1);
  };
  return (
    <>
      <ErrorAlert
        handleOk={handleRecordErrorAlert}
        open={recordErrors}
        title={errorTitle}
        body={recordErrorMessage}
        buttonName="Ok"
      />
      <ErrorAlert
        handleOk={handleIsFailedAlert}
        open={isFailed}
        title="Error"
        body={failedErrorMessage}
        buttonName="Ok"
      />
      <ErrorAlert
        handleOk={handleEligiblityError}
        open={eligiblityErrors}
        title="Eligiblity Conditions not Met!"
        body={eligiblityErrorMessage}
        buttonName="Ok"
      />
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error!"
        body={errorMessage}
        buttonName="Ok"
      />
      <ErrorAlert
        handleOk={handleValidationFailedAlert}
        open={validationFailed}
        title="Error!"
        body={validationFailedMsg}
        buttonName="Ok"
      />

      <SucessAlert
        handleOk={handleSuccessOk}
        open={isSubmitSuccess}
        title="Success"
        // body="Claim Submitted Successfully"
        body={submitSuccessMsg}
        buttonName="Ok"
      />
      <ConfirmationDialogue
        handleOk={handleAssignToOk}
        handleCancel={handleAssignToCancel}
        open={isSubmitClicked}
        title="Confirm Assignment"
        body={`Are you sure you want to assign the claim to ${selectedOfficerName} ?`}
        buttonName="Confirm"
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                columnGap={2}
                justifyContent="space-between"
                alignItems="center">
                <Grid item xs={12} sm={5}>
                  <TitleText title="Claim Submission Form" />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    spacing={1}
                    //columnGap={1}
                    justifyContent="end"
                    alignItems="center">
                    <Grid
                      item
                      xs={9}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}>
                      <LabelText title="Settlement Para: " />
                    </Grid>
                    <Grid item xs={2}>
                      <ValueText title={settlementPara} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <FormDivider />

              <Grid
                container
                spacing={2}
                columnGap={2}
                justifyContent="center"
                alignItems="center">
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    columnGap={2}
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item xs={12} sm={5}>
                      {/* column 1 */}
                      <Grid
                        container
                        spacing={2}
                        columnGap={2}
                        justifyContent="start"
                        alignItems="center">
                        {/* ---Relationship with member--- */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Select Claimant  " />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomDropdown
                            {...claimant_name}
                            onchange={onChange}
                          />
                        </Grid>
                        {!claimantSelf && (
                          <>
                            {/* ------------------ */}
                            {/* ---Relationship with member--- */}
                            <Grid item xs={12} sm={4}>
                              <LabelText title="Date of Birth of Claimant " />
                            </Grid>
                            <Grid item xs={12} sm={7}>
                              {/* <CustomDropdown {...claim_type} onchange={onChange} /> */}
                              <ValueText title={selectedClaimant.dateOfBirth} />
                            </Grid>
                            {/* ------------------ */}
                          </>
                        )}
                        {/* ---Relationship with member--- */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Cause of Cessation " />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          {/* <CustomDropdown {...claim_type} onchange={onChange} /> */}
                          <CustomDropdown {...claim_type} onchange={onChange} />
                        </Grid>
                        {/* ------------------ */}
                      </Grid>
                    </Grid>
                    {/* column2 */}
                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        spacing={2}
                        columnGap={2}
                        justifyContent="start"
                        alignItems="center">
                        {!claimantSelf && (
                          <>
                            {/* ---Relationship with member--- */}
                            <Grid item xs={12} sm={4}>
                              <LabelText title="Relationship With Member " />
                            </Grid>
                            <Grid item xs={12} sm={7}>
                              <ValueText
                                title={selectedClaimant.relationName}
                              />
                            </Grid>
                            {/* ------------------ */}
                            {/* ---Date of Death of Member--- */}
                            <Grid item xs={12} sm={4}>
                              <LabelText title="Date of Death of Member " />
                            </Grid>
                            <Grid item xs={12} sm={7}>
                              <CustomTextField
                                {...date_of_death}
                                onchange={onChange}
                              />
                            </Grid>
                          </>
                        )}
                        {/* ------------------ */}
                        {/* ---Date of Cessation of member--- */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Date of Cessation: " />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...date_of_cessation}
                            onchange={onChange}
                          />
                        </Grid>
                        {/* ------------------ */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* ---Names as per Aadhaar Pan and Bank Statement-- */}
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    columnGap={2}
                    justifyContent="start"
                    alignItems="center">
                    <Grid item xs={12}>
                      <SubtitleText title="Enter Member Name as per " />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={3}>
                          <LabelText title="Aadhaar Card  " />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_first_name_aadhar}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_middle_name_aadhar}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_last_name_aadhar}
                            onchange={onChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={3}>
                          <LabelText title="PAN Card  " />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_first_name_pan}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_middle_name_pan}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_last_name_pan}
                            onchange={onChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={3}>
                          <LabelText title=" Bank Statement  " />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_first_name_bank}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_middle_name_bank}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_last_name_bank}
                            onchange={onChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* ******* Name fields ended ***** */}
                    <Grid item xs={12}>
                      <SubtitleText title="Enter Member Date of Birth as per " />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={1}
                        columnGap={2}
                        justifyContent="space-between">
                        {/* here we are creating two columns as the label and textfield were not aligning properly */}
                        {/* Column 1 */}
                        <Grid item xs={12} md={5}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={5}>
                              <LabelText title="Aadhaar Card  " />
                            </Grid>
                            <Grid item xs={12} sm={12} md={7}>
                              <CustomTextField
                                {...mem_dob_aadhar}
                                onchange={onChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* Column 2 */}
                        <Grid item xs={12} md={5}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={4}>
                              <LabelText title="PAN Card  " />
                            </Grid>
                            <Grid item xs={12} sm={12} md={8}>
                              <CustomTextField
                                {...mem_dob_pan}
                                onchange={onChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* ********* Dob fields ends here******** */}
                    <Grid item xs={12}>
                      <SubtitleText title="Enter S/O D/O W/O H/O as per " />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={3}>
                          <LabelText title="Aadhaar Card  " />
                        </Grid>

                        <Grid item xs={12} sm={12} md={9}>
                          <Grid
                            container
                            spacing={1}
                            justifyContent="end
                          ">
                            <Grid item xs={12} sm={12} md={5}>
                              <CustomDropdown
                                {...relation}
                                onchange={onChange}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                              <CustomTextField
                                {...fhw_name}
                                onchange={onChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* ********** W/H/d/s fields ends here************ */}
                    {/* ********** Fields for the broken period values************ */}
                    <Grid item xs={12}>
                      <SubtitleText title="Enter Broken Period Details" />
                    </Grid>

                    <Grid
                      container
                      justifyContent={"space-evenly"}
                      alignItems="end"
                      rowGap={2}
                      mt={3}>
                      <Grid item xs={12} md={2}>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          justifyContent={"space-between"}>
                          <Grid item xs={12}>
                            <LabelText title="Number of Months" />
                          </Grid>
                          <Grid item xs={12} mt={1}>
                            <CustomTextField
                              {...noOfMonths}
                              onchange={onChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          justifyContent="space-between">
                          <Grid item xs={12}>
                            <LabelText title="Total Member Contribution" />
                          </Grid>
                          <Grid item xs={12} mt={1}>
                            <CustomTextField
                              {...totMemContribution}
                              onchange={onChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          justifyContent="space-between">
                          <Grid item xs={12}>
                            <LabelText title="Total Employer Contribution" />
                          </Grid>
                          <Grid item xs={12} mt={1}>
                            <CustomTextField
                              {...totEmpContribution}
                              onchange={onChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          justifyContent="space-between">
                          <Grid item xs={12}>
                            <LabelText title="Total Voluntary Contribution" />
                          </Grid>
                          <Grid item xs={12} mt={1}>
                            <CustomTextField
                              {...totVolContribution}
                              onchange={onChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* Document Upload  */}
                    <Grid item xs={12}>
                      <SubtitleText title="Upload Documents" />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {/* <Upload_Documents
                        options={documentTypes}
                        fileSize={handleFileSizeUpdate}
                        stateUpdate={handleStateUpdate}
                      /> */}
                      <UploadDocuments
                        documentTypes={documentTypes}
                        onSave={handleStateUpdate}
                      />
                    </Grid>

                    {/* *************** Declaration ********* */}
                    <Grid item xs={12} mt={3}>
                      <Paper
                        elevation={0}
                        sx={{ padding: "3rem", borderRadius: "8px" }}>
                        <TitleText title="Declaration" />
                        <FormDivider />
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center", // Center vertically
                              gap: 2, // Adjust the gap as needed
                              padding: 2, // Add padding for better visualization
                              //border: "1px solid #ccc", // Add border for better visualization
                              justifyContent: "flex-start",
                            }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checked}
                                  onChange={handleChange}
                                  color="success"
                                  sx={{
                                    marginTop: -1, // Adjust the negative margin to move the Checkbox up
                                    marginRight: -4,
                                  }}
                                />
                              }
                              // label={<ValueText title="I Agree" />}
                            />
                            <CaptionText
                              title="All details have been verified with the service record and all documents have been verified with the original documents of the claim and found correct."
                              sx={{
                                margin: 0, // Reset margin for better alignment
                              }}
                            />
                          </Box>
                        </Grid>
                      </Paper>
                    </Grid>
                    {/* *************** Submit Button ********* */}

                    <Grid container justifyContent="center">
                      <Grid
                        item
                        xs={12}
                        sm="auto"
                        sx={{ justifyContent: "center" }}>
                        <Button
                          onClick={handleProcessOk}
                          variant="contained"
                          color="success"
                          //disabled={true}
                          //fullWidth
                          type="submit">
                          {"Submit"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {showingDropdown === 1 && (
                  <Grid
                    container
                    justifyContent="end"
                    alignItems={"center"}
                    spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <CustomDropdown {...toLoginId} onchange={onChange} />
                    </Grid>
                    <Grid item md={1}>
                      <Button
                        onClick={handleSubmitOk}
                        variant="contained"
                        color="success"
                        type="submit"
                        disabled={isSubmitting}
                        fullWidth>
                        {isSubmitting ? <CircularProgress /> : "OK"}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default ClaimFormPF;
