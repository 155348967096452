import React, { useEffect, useState, useContext } from 'react';
import { Grid, Paper, Divider } from '@mui/material';
import TitleText from '../../FormComponent/TitleText';
import LabelText from '../../FormComponent/LabelText';
import CaptionText from '../../FormComponent/CaptionText';
import ValueText from '../../FormComponent/ValueText';
import { padding } from '@mui/system';
import FormDivider from '../../FormComponent/FormDivider';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import LoadingIndicator from '../../FormComponent/LoadingIndicator';
import { UserContext } from '../../UiManager/UserProvider';
import AmountFormatter from '../../FormComponent/AccountFormatter';
import Reloader from '../../FormComponent/Reloader';
import {validateSession} from '../../session/EventManager';


//TO DO=>): remove local storage and use userContext

function AccountSummary() {
   
    const userContext =  useContext(UserContext); 
     
    const date = new Date();
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-GB', options);

    const [isLoading, setIsLoading] = useState(true);

    const [isFailed, setIsFailed] = useState(false);

    const [accountSummary, setAccountSummary] = useState({});
  

    const sessionExist = async () => {
        const status = await validateSession();
        console.log("session status", status);
        return status;
    };
  
    const fetchData = async (signal) => {
        setIsLoading(true);

        if (!(await sessionExist())) {
            console.log('session extending having trouble');
            setIsLoading(false);
            setIsFailed(true);
            return;
        }

        let token = localStorage.getItem('jwt');
        console.log("token",token);
        if (!token) {
            setIsFailed(true);
            setIsLoading(false);  
            return;
        }

        token = token.replace("\"", "").replace("\"", "");
        console.log("tokentoken",token);
        console.log("jwtDecode(token).user",jwtDecode(token).user);
        const url = process.env.REACT_APP_SERVER_URL + '/getledgeraccountsummaryyear';

        const formData = new FormData();
        formData.append('loginId', jwtDecode(token).user);

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
            signal: signal,
        };

        try {
            const response = await axios.post(url, formData, config ,);
            const data = response.data.data[0];
            updateUser(data);

            data.currPeriodEnd = new Date(data.currPeriodEnd).getFullYear();
            setAccountSummary(data);
            setIsLoading(false);
            setIsFailed(false);
            console.log(response.data.data);
        } catch (error) {
            setIsLoading(false);
            setIsFailed(true);
            console.log('error', error);
        }
    };

    function updateUser(data){
     const currentUser = useContext.currentUser;
        userContext.updateUser({...currentUser,name: data.fullName})
    }

   

    useEffect(() => {
        console.info('account sum activated');
        const unsubscribe = new AbortController();
        const signal = unsubscribe.signal;
        
        fetchData(signal);

        return ()=>{
            console.info("unsubscribing summary api");
            unsubscribe.abort();
        }
},[]);



    return (
        <Grid container justifyContent="center" sx={{ wordWrap: 'break-word' }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper elevation={3} sx={{ padding: '3rem', borderRadius: '8px' }}>
                    {
                        isFailed ? <Reloader refreshHandler={fetchData} />:
                        <>
                                {
                                    isLoading ? <LoadingIndicator /> : <>

                                        <Grid container justifyContent="space-between" alignItems='center'>
                                            <Grid item>
                                                <TitleText title='Account Summary' />
                                            </Grid>
                                            <Grid item>
                                                <LabelText title={'Date: ' + `${formattedDate}`} />
                                            </Grid>
                                        </Grid>

                                        <FormDivider />

                                        <Grid container spacing={2} columnGap={2} justifyContent="center" alignItems="center" >

                                            <Grid item xs={12} sm={4}>
                                                <LabelText title='CMPF Account Number' />
                                            </Grid>

                                            <Grid item sm={1} sx={{ display: { xs: 'none', sm: 'initial' } }}>
                                                :
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <ValueText title={accountSummary.cmpfAccNo} />
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <LabelText title='Full Name of the Member' />
                                            </Grid>
                                            <Grid item sm={1} sx={{ display: { xs: 'none', sm: 'initial' } }}>
                                                :
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <ValueText title={accountSummary.fullName} />
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <LabelText title='Last Currency Period' />
                                            </Grid>
                                            <Grid item sm={1} sx={{ display: { xs: 'none', sm: 'initial' } }}>
                                                :
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <ValueText title={`${accountSummary.currPeriodEnd - 1} - ${accountSummary.currPeriodEnd - 2000}`} />
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <LabelText title='Current PF Balance' />
                                            </Grid>
                                            <Grid item sm={1} sx={{ display: { xs: 'none', sm: 'initial' } }}>
                                                :
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {/* <ValueText title={`INR. ${accountSummary.closingBal}`} /> */}
                                                <AmountFormatter amount={accountSummary.closingBal} />
                                            </Grid>

                                           {/* <Grid item xs={12} sm={4}>
                                                <LabelText title='Total Pension Contribution' />
                                            </Grid>
                                            <Grid item sm={1} sx={{ display: { xs: 'none', sm: 'initial' } }}>
                                                :
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <AmountFormatter amount={accountSummary.closingPen} />
                                                
                                            </Grid>*/}
                                            {/* <ValueText title={`INR. ${accountSummary.closingPen}`} /> */}

                                        </Grid>
                                    </>
                                }
                        </>
                    }
                </Paper>
            </Grid>
        </Grid>
    );
}

export default AccountSummary;
