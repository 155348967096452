import { Typography } from '@mui/material';
import React from 'react'

function CaptionText(props) {
  return (
    <Typography variant="body2" align="left" gutterBottom>
      {props.title}
    </Typography>
  );
}

export default CaptionText;
