import React, { useState } from "react";
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { v4 as uuidv4 } from "uuid";
import ErrorAlert from "../FormComponent/ErrorAlert";

const UploadDocument = ({ documentTypes, onSave }) => {
  const [uploads, setUploads] = useState([
    { id: uuidv4(), selectedDocumentType: "", uploadedDocument: null },
  ]);
  const [uploaded, setUploaded] = useState(false);
  const [filesizeexceeded, setFileSizeExceeded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorMessage, setIsErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const handleAddRow = () => {
    setUploaded(false);
    setUploads((prevUploads) => [
      ...prevUploads,
      { id: uuidv4(), selectedDocumentType: "", uploadedDocument: null },
    ]);
  };

  const handleDocumentTypeChange = (event, id) => {
    const newUploads = uploads.map((upload) => {
      if (upload.id === id) {
        return { ...upload, selectedDocumentType: event.target.value };
      }
      return upload;
    });
    setUploads(newUploads);
  };

  const handleFileUpload = (event, id) => {
    const file = event.target.files[0];
    const fileSizeInMB = file.size / (1024 * 1024);

    if (fileSizeInMB > 0.293) {
      setUploads((prevUploads) => {
        return prevUploads.map((upload) => {
          if (upload.id === id) {
            return {
              ...upload,
              fileSizeExceeded: true,
              fileSizeError: "File size cannot exceed more than 300KB",
            };
          }
          return upload;
        });
      });
      return;
    } else if (!file) {
      setUploads((prevUploads) => {
        return prevUploads.map((upload) => {
          if (upload.id === id) {
            return {
              ...upload,
              fileSizeExceeded: true,
              fileSizeError: "Please choose a file",
            };
          }
          return upload;
        });
      });
      return;
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        const newUploads = uploads.map((upload) => {
          let base64Data = reader.result;
          let mimeType;

          if (file.type === "application/pdf") {
            mimeType = "application/pdf";
          } else if (file.type === "image/jpeg") {
            mimeType = "image/jpeg";
          } else if (file.type === "image/png") {
            mimeType = "image/png";
          } else {
            setIsError(true);
            setIsErrorMessage("Please upload the PDF, JPEG, and PNG files.");
            event.target.value = "";
          }

          base64Data = base64Data.replace(
            /^data:.+;base64,/,
            `data:${mimeType};base64,JVBERi0xLjQKJdPr6eEKMSAwIG9iago8PC9`
          );

          if (upload.id === id) {
            return {
              ...upload,
              uploadedDocument: base64Data,
              fileSizeExceeded: false,
              fileSizeError: "",
            };
          }
          return upload;
        });
        setUploads(newUploads);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveRow = (id) => {
    const newUploads = uploads.filter((upload) => upload.id !== id);
    setUploads(newUploads);
    setUploaded(false);
  };

  const handleSaveClick = () => {
    const hasEmptyFile = uploads.some((upload) => !upload.uploadedDocument);
    //const hasEmptyFile = uploads.some((upload) => !upload.uploadedDocument);
    const hasEmptyDocumentType = uploads.some(
      (upload) => !upload.selectedDocumentType
    );
    // if (hasEmptyFile) {
    //   setIsError(true);
    //   setIsErrorMessage("Please upload file or remove this row");
    //   return;
    // }

    if (hasEmptyFile) {
      setIsError(true);
      setIsErrorMessage("Please upload file for each row");
      return;
    }

    if (hasEmptyDocumentType) {
      setIsError(true);
      setIsErrorMessage("Please select document type for each row");
      return;
    }
    const isFileSize = uploads.some((upload) => upload.fileSizeError);
    if (isFileSize) {
      setIsError(true);
      setIsErrorMessage("File size cannot exceed more than 300KB");
      return;
    }

    onSave(uploads, !filesizeexceeded);
    setUploaded(true);
  };

  const handleErrorAlert = () => {
    setIsError(false);
  };
  return (
    <>
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error"
        body={isErrorMessage}
        buttonName="Ok"
      />
      <Grid container rowGap={2}>
        <Grid
          container
          display={"flex"}
          justifyContent={{ xs: "center", md: "flex-end" }}
          alignItems={"center"}
        ></Grid>

        {uploads.map((upload, index) => (
          <Grid item xs={12} key={upload.id}>
            <Grid
              container
              marginTop={2}
              justifyContent="space-between"
              alignItems="center"
              rowGap={2}
            >
              <Grid item xs={12} md={5}>
                <FormControl fullWidth>
                  <InputLabel htmlFor={`document-type-select-${upload.id}`}>
                    Select Document
                  </InputLabel>
                  <Select
                    value={upload.selectedDocumentType}
                    onChange={(event) =>
                      handleDocumentTypeChange(event, upload.id)
                    }
                    label="Select Document Type"
                  >
                    {documentTypes.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <input
                  type="file"
                  onChange={(event) => handleFileUpload(event, upload.id)}
                />
                {/* <div style={{ color: "red" }}>{fileSizeError}</div>{" "}
                Display the error message in red color */}
                {upload.fileSizeExceeded && ( // Conditionally display the error message
                  <div style={{ color: "red" }}>{upload.fileSizeError}</div>
                )}
              </Grid>

              <Grid item xs={12} md={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleRemoveRow(upload.id)}
                  disabled={uploads.length === 1}
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))}
        <Grid
          container
          // display={"flex"}
          //justifyContent={{ xs: "center", md: "flex-end" }}
          justifyContent="center"
          alignItems={"center"}
          spacing={2}
          mt={2}
        >
          <Grid item>
            <Button variant="contained" color="success" onClick={handleAddRow}>
              Add More
            </Button>
          </Grid>
          <Grid item>
            {errorMessage && <p>{errorMessage}</p>}
            {uploaded ? (
              <Typography>
                <CheckCircleIcon
                  style={{ color: "green", verticalAlign: "middle" }}
                />
                {" Uploaded"}
              </Typography>
            ) : (
              <Button
                variant="contained"
                color="success"
                onClick={handleSaveClick}
              >
                upload
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UploadDocument;
