import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AiOutlineEye } from "react-icons/ai";
import axios from "axios";

function VirtualizedList() {
  const pageSize = 5;
  const [documents, setDocuments] = useState([]);
  const [visibleDocuments, setVisibleDocuments] = useState([]);
  let claimId = "AD3000015";
  const [isLoading, setIsLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const fetchDocuments = async () => {
    try {
      const url =
        process.env.REACT_APP_SERVER_URL +
        "/fetch/advance/pf/claim/Documents/by/id";
      const formData = new FormData();
      formData.append("ClaimId", claimId);

      const config = {
        headers: {
          // Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(url, formData, config);

      setIsLoading(false);
      setIsFailed(false);

      if (response.status === 200) {
        const data = response.data.data.data;
        setDocuments(data);
      } else {
        setIsFailed(true);
      }
    } catch (error) {
      setIsLoading(false);
      setIsFailed(true);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  useEffect(() => {
    if (documents.length > 0) {
      const documentsWithSerial = documents.map((item, index) => ({
        ...item,
        serial: index + 1,
      }));
      setVisibleDocuments(documentsWithSerial.slice(0, pageSize));
    }
  }, [documents]);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      const startIndex = visibleDocuments.length;
      const newVisibleDocuments = documents.slice(
        startIndex,
        startIndex + pageSize
      );
      setVisibleDocuments((prev) => [...prev, ...newVisibleDocuments]);
    }
  };

  const handleEyeClick = (index) => {
    const utf8Data = visibleDocuments[index].Document; // Assuming there is a property called "documentUrl" in the document object
    let documentUrl = atob(utf8Data);
    if (documentUrl) {
      // const openDocumentInNewTab = (base64Data) => {
      let type = "pdf"; // Default type set to PDF
      if (documentUrl.includes("data:image/jpeg;base64")) {
        type = "jpeg";
      } else if (documentUrl.includes("data:image/png;base64")) {
        type = "png";
      }

      let base;
      if (type === "pdf") {
        base = documentUrl.replace(
          "data:application/pdf;base64,JVBERi0xLjQKJdPr6eEKMSAwIG9iago8PC9",
          "data:application/pdf;base64,"
        );
      } else {
        base = documentUrl.replace(
          `data:image/${type};base64,JVBERi0xLjQKJdPr6eEKMSAwIG9iago8PC9`,
          `data:image/${type};base64,`
        );
      }
      console.log("abc", base);
      console.log("type", type);
      switch (type) {
        case "pdf":
          return (
            <iframe title="PDF Viewer" src={base} width="50%" height="400" />
          );
        case "jpg":
        case "png":
          return (
            <img
              src={`data:image/${type};base64,${btoa(base)}`}
              alt="Document"
            />
          );
        default:
          return <p>Unsupported document type</p>;
      }
      window.open(base, type);

      setVisibleDocuments((prevDocuments) =>
        prevDocuments.map((doc, idx) =>
          idx === index
            ? { ...doc, clicked: true, selectedDocument: base }
            : doc
        )
      );
      // }; // Open the document in a new tab
    } else {
      // Handle the case where the document URL is not available
      console.log("Document URL is not available");
    }

    const updatedVisibleDocuments = [...visibleDocuments];
    updatedVisibleDocuments[index].clicked =
      !updatedVisibleDocuments[index].clicked;
    setVisibleDocuments(updatedVisibleDocuments);
  };

  return (
    <Box
      sx={{
        width: "50%",
        bgcolor: "background.paper",
        height: "50%",
        overflowY: "auto",
      }}
      onScroll={handleScroll}
    >
      <TableContainer component={Paper}>
        <Table aria-label="virtualized table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontSize: "20px" }}>Serial number</TableCell>
              <TableCell style={{ fontSize: "20px" }}>Document Type</TableCell>
              <TableCell style={{ fontSize: "20px" }}>Link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleDocuments.map((row, index) => (
              <TableRow key={row.DocTypeid}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontSize: "20px" }}
                >
                  {row.serial}
                </TableCell>
                <TableCell style={{ fontSize: "20px" }}>
                  {row.DocTypeid}
                </TableCell>
                <TableCell style={{ fontSize: "40px" }}>
                  <AiOutlineEye
                    style={{
                      fontSize: "40px",
                      color: row.clicked ? "red" : "black",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEyeClick(index)}
                  />
                  {row.clicked &&
                    row.selectedDocument &&
                    (row.selectedDocument.includes("pdf") ? (
                      <iframe
                        title="PDF Viewer"
                        src={row.selectedDocument}
                        width="100%"
                        height="400"
                      />
                    ) : (
                      <img
                        src={row.selectedDocument}
                        alt="Document"
                        style={{ width: "100%" }}
                      />
                    ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default VirtualizedList;
