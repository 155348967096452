import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserLock} from '@fortawesome/free-solid-svg-icons';
import { Avatar, Button, Grid, Paper, TextField, Typography } from '@mui/material'

const ChangePasswordOtp = () => {
    const avatarStyle = { backgroundColor: '#1bbd7e' }
    const paperStyle = { padding: 20, width: 400, margin: "20px auto" }
    const btstyle = { fontWeight: '600', margin: '20px 0' }

    return (
        <>
            <Grid>
                <Paper elevation={10} style={paperStyle}>
                    <Grid align='center'>
                        <Avatar style={avatarStyle}>
                        <FontAwesomeIcon icon={faUserLock} />
                        </Avatar>
                        <h2>Change Password</h2>
                    </Grid>                                      
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        style={btstyle}
                    >Send OTP</Button>
                </Paper>
            </Grid>
        </>
        
  );
}

export default ChangePasswordOtp;
