import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import ValueText from './ValueText';
import SubtitleText from './SubtitleText';



function ErrorAlert(props) {
  const { handleOk, open, title, body, buttonName } = props;
  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
    >
      <DialogTitle>
        <SubtitleText title={title} />
      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ValueText title={body} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk}>{buttonName}</Button>
      </DialogActions>
    </Dialog>
  );
}


export default ErrorAlert;
