import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Paper, Box, Button, Divider } from "@mui/material";
import { memberModel } from "../../Models/defaultModel";

import TitleText from "../../FormComponent/TitleText";
import LabelText from "../../FormComponent/LabelText";
import CustomTextField from "../../FormComponent/CustomTextField";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import GroupedRadioButton from "../../FormComponent/GroupedRadioButton";
import SubtitleText from "../../FormComponent/SubtitleText";
import CustomTextArea from "../../FormComponent/CustomTextArea";
import LoadingIndicator from "../../FormComponent/LoadingIndicator";
import FormDivider from "../../FormComponent/FormDivider";
import { faClosedCaptioning } from "@fortawesome/free-solid-svg-icons";
import ValueText from "../../FormComponent/ValueText";
import jwtDecode from "jwt-decode";
import axios from "axios";
import Reloader from "../../FormComponent/Reloader";
import { validateSession } from "../../session/EventManager";

function Profile() {
  const [member, setMember] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const [isFailed, setIsFailed] = useState(false);

  // write it in default model and use based on needs
  const salutation = [
    { id: 1, title: "Mr" },
    { id: 2, title: "Mrs" },
    { id: 3, title: "" },
    { id: 4, title: "Ms" },
    { id: 5, title: "Late" },
    { id: 6, title: "Kumar" },
    { id: 7, title: "Kumari" },
    { id: 8, title: "Dr" },
  ];
  const gender = [
    { id: "M", title: "Male" },
    { id: "F", title: "Female" },
    { id: "O", title: "Other" },
  ];
  const maritalStatus = [
    { id: "M", title: "Married" },
    { id: "W", title: "Widow/Widower" },
    { id: "U", title: "Unmarried" },
  ];
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };

  const sessionExist = async () => {
    const status = await validateSession();
    console.log("session status", status);
    return status;
  };

  const fetchUserDetail = async () => {
    setIsLoading(true);

    if (!(await sessionExist())) {
      console.log("session extending having trouble");
      setIsFailed(true);
      setIsLoading(false);
      return;
    }

    let token = localStorage.getItem("jwt");

    if (!token) {
      setIsFailed(true);
      setIsLoading(false);
      return;
    }

    token = token.replace('"', "").replace('"', "");

    const url = process.env.REACT_APP_SERVER_URL + "/member/details/for/pf";
    const formData = new FormData();
    formData.append("cmpfAccNo", jwtDecode(token).user);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsLoading(false);
        setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          setMember(data[0]);
          return;
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        console.log("error", error);
      });
  };

  useEffect(() => {
    fetchUserDetail();
  }, []);

  return (
    <Grid container justifyContent="center" sx={{ wordWrap: "break-word" }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
          {isFailed ? (
            <Reloader refreshHandler={fetchUserDetail} />
          ) : (
            <>
              {isLoading ? (
                <LoadingIndicator />
              ) : (
                <>
                  <Grid item xs={12}>
                    <TitleText title="My Profile" />
                  </Grid>

                  <FormDivider />

                  {/* <Form> */}
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="start"
                    rowGap={3}
                  >
                    {/* section 1  */}
                    <Grid item xs={12} sm={12}>
                      <SubtitleText title="Personal Information" />
                    </Grid>

                    {/* column 1  */}

                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Salutation" />
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          sx={{ display: { xs: "none", sm: "initial" } }}
                        >
                          :
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <ValueText
                            title={
                              salutation.find(
                                (item) => item.id === member.titleId
                              )?.title
                            }
                          />
                        </Grid>

                        {/* row  */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Full Name" />
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          sx={{ display: { xs: "none", sm: "initial" } }}
                        >
                          :
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <ValueText title={member.fullName} />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Date of Birth" />
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          sx={{ display: { xs: "none", sm: "initial" } }}
                        >
                          :
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          {/* <ValueText title={member.dateOfBirth} /> */}
                          <ValueText
                            title={
                              member.dateOfBirth
                                ? new Date(
                                    member.dateOfBirth
                                  ).toLocaleDateString("en-GB", options)
                                : ""
                            }
                          />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="PAN Number" />
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          sx={{ display: { xs: "none", sm: "initial" } }}
                        >
                          :
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <ValueText
                            title={`XXXXXX${member.panNo?.slice(-4)}`}
                          />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Aadhaar Number" />
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          sx={{ display: { xs: "none", sm: "initial" } }}
                        >
                          :
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <ValueText
                            title={`XXXX XXXX ${member.aadhaarNumber?.slice(
                              -4
                            )}`}
                          />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Marital Status" />
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          sx={{ display: { xs: "none", sm: "initial" } }}
                        >
                          :
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <ValueText
                            title={
                              maritalStatus.find(
                                (item) => item.id === member.maritalStatus
                              )?.title
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* column 2  */}

                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Religion" />
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          sx={{ display: { xs: "none", sm: "initial" } }}
                        >
                          :
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <ValueText title={member.religionName} />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Gender" />
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          sx={{ display: { xs: "none", sm: "initial" } }}
                        >
                          :
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <ValueText
                            title={
                              gender.find((item) => item.id === member.gender)
                                ?.title
                            }
                          />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Name of Father/Husband" />
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          sx={{ display: { xs: "none", sm: "initial" } }}
                        >
                          :
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <ValueText title={member.fatherHusbandName} />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Email ID" />
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          sx={{ display: { xs: "none", sm: "initial" } }}
                        >
                          :
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <ValueText title={member.email} />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Mobile Number" />
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          sx={{ display: { xs: "none", sm: "initial" } }}
                        >
                          :
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <ValueText
                            title={`XXXXXX${member.mobileNo
                              ?.toString()
                              .slice(-4)}`}
                          />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Landline Number" />
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          sx={{ display: { xs: "none", sm: "initial" } }}
                        >
                          :
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <ValueText title={member.landNo} />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Permanant Address" />
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          sx={{ display: { xs: "none", sm: "initial" } }}
                        >
                          :
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <ValueText
                            title={`
     ${member.addressL1 ? member.addressL1 + "," : ""}
      
     ${member.addressL2 ? member.addressL2 + "," : ""}
      
     ${member.village ? member.village + "," : ""}
      
     ${member.city ? member.city + "," : ""}
      
     ${member.district ? member.district + "," : ""}
      
     ${member.state ? member.state + "," : ""}
      
     ${member.country ? member.country + "," : ""}
      
     ${member.pincode ? member.pincode : ""}
     `}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* ************************************** */}
                    {/* section 2  */}
                    <Grid item xs={12} sm={12}>
                      <SubtitleText title="Employement Details" />
                    </Grid>

                    {/* column 1  */}

                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* row  */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="CMPF Account Number" />
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          sx={{ display: { xs: "none", sm: "initial" } }}
                        >
                          :
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <ValueText title={member.cmpfAccNo} />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Colliery ID" />
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          sx={{ display: { xs: "none", sm: "initial" } }}
                        >
                          :
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <ValueText title={member.unitId} />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Coal Company Name" />
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          sx={{ display: { xs: "none", sm: "initial" } }}
                        >
                          :
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <ValueText title={member.name} />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="PF Start Date" />
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          sx={{ display: { xs: "none", sm: "initial" } }}
                        >
                          :
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <ValueText
                            title={
                              member.pfStartDate
                                ? new Date(
                                    member.dateOfBirth
                                  ).toLocaleDateString("en-GB", options)
                                : ""
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* column 2  */}

                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Employee ID" />
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          sx={{ display: { xs: "none", sm: "initial" } }}
                        >
                          :
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <ValueText title={member.employeeId} />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Colliery Name" />
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          sx={{ display: { xs: "none", sm: "initial" } }}
                        >
                          :
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <ValueText title={member.unitName} />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Joining Date" />
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          sx={{ display: { xs: "none", sm: "initial" } }}
                        >
                          :
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <ValueText
                            title={
                              member.joiningDate
                                ? new Date(
                                    member.dateOfBirth
                                  ).toLocaleDateString("en-GB", options)
                                : ""
                            }
                          />
                        </Grid>

                        {/* row */}
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Profile;
