import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
} from "@mui/material";
import LabelText from "../../FormComponent/LabelText";
import ValueText from "../../FormComponent/ValueText";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { PDFExport } from "@progress/kendo-react-pdf";
import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
// import AdvDpo from "./AdvDpo";

const GeneratePDFButton = () => {
  const pdfExportComponent = useRef(null);

  const exportPDF = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  return (
    <div style={{ justifyContent: "flex-end" }}>
      <PDFExport ref={pdfExportComponent}>
        <advDpo />
      </PDFExport>
      <button onClick={exportPDF} >Generate DPO</button>
    </div>
  );
};

export default GeneratePDFButton;
