import React from 'react';
import { Grid, Typography, Backdrop } from '@mui/material';

const PageNotFound = () => {
  return (

    <Backdrop open={true} style={{ zIndex: 9999, backgroundColor: '#fff' }}>
      <Grid container justifyContent='center' alignItems='center' direction='column'>
        <Grid item>
          <Typography variant="h1" align="center">
            Page Not Found
          </Typography>
        </Grid>
        <Grid item>
          <  Typography variant="body1" align="center">
           Ooops..! This Page Not Exist.
          </Typography>
        </Grid>
      </Grid>
    </Backdrop>
  );
};

export default PageNotFound;
