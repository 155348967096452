import MemberEnrollmentFirst from "./MemberEnrollmentPages/MemberEnrollmentFirst";
import MemberEnrollmentGuidelines from "./MemberEnrollmentPages/MemberEnrollmentGuidelines";

const MemberEnrollment=()=>{
    return(
        <div id="member-enrolment">
        
        <MemberEnrollmentFirst />
        <MemberEnrollmentGuidelines />
        </div>
    );
}
export default MemberEnrollment;