import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Autocomplete,
  TextField,
  CircularProgress,
  Button,
  Typography,
} from "@mui/material";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import SubtitleText from "../../FormComponent/SubtitleText";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import LabelText from "../../FormComponent/LabelText";
import { validateSession } from "../../session/EventManager";

import SearchIcon from "@mui/icons-material/Search";
import Search from "@mui/icons-material/Search";
import CustomTextField from "../../FormComponent/CustomTextField";
import FormTable from "../../FormComponent/FormTable";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import axios from "axios";
import { Message } from "@mui/icons-material";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function ViewConsent() {
  const defaultController = {
    controller: "1",
    searchParam: "",
  };

  const [searchControl, setSearchControl] = useState(defaultController);

  const tableTitles = ["Name", "CMPF Account No.", "Unit Code", "Consent Type"];
  const leftAlign = ["name", "cmpfAccNo", "unitName", "consentType"];

  const [unitNoLoading, setunitNoLoading] = useState(true);
  const [unitNumberList, setUnitNumberList] = useState([]);
  const [errorMessageAlert, setErrorMessageAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isDataPresent, setIsDataPresent] = useState(false);
  const [tableMessage, setTableMessage] = useState("");
  const [isRefresh, setIsRefresh] = useState(false);

  const defaultTableData = [
    {
      name: "",
      cmpfAccNo: "",
      unitName: "",
      ConsentType: true,
    },
  ];
  const [tableData, setTableData] = useState(defaultTableData);

  const sampleTableData = [
    {
      name: "Nandu",
      cmpfAccNo: "HYD/101/122",
      unitName: "HYD/12",
      ConsentType: true,
    },
    {
      name: "Sangeeta",
      cmpfAccNo: "JHA/108/12",
      unitName: "JHA/108",
      ConsentType: false,
    },
    {
      name: "Artika",
      cmpfAccNo: "KJY/12/8",
      unitName: "KJY/12",
      ConsentType: true,
    },
  ];

  const searchDropdown = {
    name: "controller",
    // label: "salutation",
    helperText: "",
    disabled: false,
    initialValue: searchControl.controller,
    options: [
      { id: "1", title: "Unit Code." },
      { id: "2", title: "Account No." },
    ],
  };

  const accountNumberField = {
    name: "searchParam",
    type: "text",
    helperText: "",
    placeholder: "Enter account number",
    defaultValue: searchControl.searchParam,
    errorMessage: "Invalid Account No.",
    pattern: "^[a-zA-Z0-9/]{1,20}$",
    required: false,
    disabled: false,
  };

  // useEffect for loading unit number =>> TO DO =>): load based on the serach control
  // useEffect(() => {
  //   (async () => {
  //     await sleep(1e3); // For demo purposes.
  //     const url=process.env.REACT_APP_SERVER_URL + "/allunits";

  //     axios.get(url).then((response)=>{

  //     })

  //     const unitNumberListSamples = [
  //       "HYD/12",
  //       "HYD/15",
  //       "NGP/12",
  //       "KJP/12",
  //       "KJD",
  //       "HGD",
  //       "JJU",
  //       "GSD",
  //       "GGD",
  //     ];

  //     const options = unitNumberListSamples.map((option) => {
  //       const firstID = option[0]; // Get the first letter
  //       // const unitName = option.split('/')[0]; // Extract the city name
  //       return {
  //         firstID: /[0-9]/.test(firstID) ? "0-9" : firstID,
  //         unitName: option,
  //         value: option,
  //       };
  //     });

  //     setunitNoLoading(false);
  //     setUnitNumberList(options);
  //   })();

  //   return () => {
  //     setunitNoLoading(true);
  //   };
  // }, []);

  const sessionExist = async () => {
    const status = await validateSession();
    console.log("session status", status);
    return status;
  };
  
  function parseunit(unitCombined) {
    const [unitCode, unitName] = unitCombined.split("-");
    //console.log(unitCode);
    return unitCode;
  }
  const createUnitList = async () => {
    try {
      const data = await getUnit();
      //console.log(data);
      const concatenatedArray = data.map(
        (item) => `${item.unitCode}-${item.unitName}`
      );
      //console.log(concatenatedArray);
      return concatenatedArray;
    } catch (error) {}
  };

  const getUnit = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + "/allunits";
      const response = await axios.get(url);
      //console.log("unitlist", response.data.data);
      return response.data.data;
    } catch (error) {
      console.log("error - unit", error);
      return [];
    }
  };
  const fetchUnitNo = async () => {
    try {
      const unitNumberListSamples = await createUnitList();
      //console.log("unitListSample", unitNumberListSamples);

      const options = unitNumberListSamples
        .filter((option) => typeof option === "string")
        .map((option) => {
          //const firstID = option.match(/[0-9]/) ? "0-9" : option.charAt(0); // Get the first letter
          const firstID = option[0];

          return {
            firstID: /[0-9]/.test(firstID) ? "0-9" : firstID,
            unitName: option,
            value: parseunit(option),
          };
        });

      setunitNoLoading(false);
      setUnitNumberList(options);
    } catch (error) {
      // Handle error if needed.
    }
  };
  useEffect(() => {
    fetchUnitNo();
  }, []);

  const onChange = (e) => {
    // console.log(e.target.value);
    setIsDataPresent(false);
    setTableMessage("");
    if (e.target.name === "controller") {
      setSearchControl({
        ...searchControl,
        [e.target.name]: e.target.value.toUpperCase(),
        searchParam: "",
      });

      //console.log("contoller changed", e.target.name, e.target.value);
      //console.log("search control when changed", searchControl);
    } else {
      setSearchControl({
        ...searchControl,
        [e.target.name]: e.target.value.toUpperCase(),
      });
      //console.log("value changed", e.target.name, e.target.value);
    }
    // setSearchControl({
    //   ...searchControl,
    //   [searchControl.searchParam]: "",
    // });

    // setSearchControl({
    //   ...searchControl,
    //   [e.target.name]: e.target.value.toUpperCase(),
    // });
  };

  const handleUnitNoSearch = (event, newValue) => {
    setSearchControl({
      ...searchControl,
      searchParam: newValue ? newValue.value : "",
    });
    setIsDataPresent(false);
    setTableMessage("");
  };

  const validateAccountNo = (accountNo) => {
    const regex = /^[a-zA-Z0-9/]{1,20}$/;
    return regex.test(accountNo);
    //return true when password met the criteria
  };

  const validate = () => {
    if (!searchControl.searchParam) {
      setErrorMessage("Please Enter all the Fields");
      setErrorMessageAlert(true);
      return false;
    }
    // validate accountno regex
    if (searchControl.controller === "2") {
      if (!validateAccountNo(searchControl.searchParam)) {
        console.log("invalid accno");
        setErrorMessage("Please Enter Valid Account Number");
        setErrorMessageAlert(true);
        return false;
      }
    }
    return true;
  };

  const handleAlert = () => {
    setErrorMessageAlert(false);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (validate()) {
      console.log("validation true");
      setIsDataPresent(false);
      setTableMessage("");
      setIsSearching(true);

      if (searchControl.controller === "1") {
        //console.log("searching on unitno");
        fetchConsentUnitNo();
      } else if (searchControl.controller === "2") {
        fetchConsentAccNo();
        //console.log("searching on accountNo");
      }
    }

    //console.log("search param", searchControl);
  };
  const fetchConsentAccNo = async () => {
    if (!(await sessionExist())) {
      console.log("session extending having trouble");
      //setIsFailed(true);
      //setIsLoading(false);
      return;
    }
    let token = localStorage.getItem("jwt");

    if (!token) {
      //setIsFailed(true);
      //setIsLoading(false);
      console.log("token not found");
      return;
    }
    token = token.replace('"', "").replace('"', "");

    const url = process.env.REACT_APP_SERVER_URL + "/getconsentoncmpfaccno";

    const formData = new FormData();
    formData.append("cmpfAccNo", searchControl.searchParam);
    // const formData = {
    //   cmpfAccNo: searchControl.searchParam,
    // };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        console.log("response ", response);
        setIsSearching(false);
        const responseData = response.data.data;
        if (responseData.length !== 0) {
          setIsDataPresent(true);
          const data = response.data.data.map((item) => {
            item.consentType = item.consentType ? "Positive" : "Negative";
            console.log("item", item);
            return item;
          });
          setTableData(data);
        } else {
          setTableMessage("No Records Found");
          setTableData(defaultTableData);
        }
      })

      .catch(function (error) {
        console.log("error-unitNo", error);
      });
  };
  const fetchConsentUnitNo = async () => {
    if (!(await sessionExist())) {
      console.log("session extending having trouble");
      //setIsFailed(true);
      //setIsLoading(false);
      return;
    }
    let token = localStorage.getItem("jwt");

    if (!token) {
      //setIsFailed(true);
      //setIsLoading(false);
      console.log("token not found");
      return;
    }
    token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + "/getconsentonunitcode";

    const formData = new FormData();
    formData.append("unitCode", searchControl.searchParam);
    // const formData = {
    //   cmpfAccNo: searchControl.searchParam,
    // };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        //console.log("unit response ", response.data.data);
        setIsSearching(false);
        const responseData = response.data.data;
        // console.log("responseData Lenght", responseData.length);
        if (responseData.length !== 0) {
          //console.log("response data has records");
          setIsDataPresent(true);
          const data = responseData.map((item) => {
            item.consentType = item.consentType ? "Positive" : "Negative";

            return item;
          });
          setTableData(data);
        } else {
          setTableMessage("No Records Found");
          setTableData(defaultTableData);
        }
      })
      .catch(function (error) {
        console.log("error-unitNo", error);
      });
  };

  return (
    <>
      <ErrorAlert
        handleOk={handleAlert}
        open={errorMessageAlert}
        title="Error!"
        body={errorMessage}
        buttonName="Ok"
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            <Grid item xs={12}>
              <TitleText title="Consent Details" />
            </Grid>
            <FormDivider />

            {/* Form Wrapper grid */}
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              rowGap={2}
            >
              {/* Form Divider main Grid */}
              <Grid
                container
                justifyContent="space-between"
                alignItems="flex-start"
                rowGap={2}
              >
                {/* section 1 column 1 - regional*/}
                <Grid item xs={12} sm={4}>
                  <Grid container justifyContent="space-between" rowGap={2}>
                    {/* row */}
                    <Grid item xs={12} sm={3}>
                      <LabelText title="Search By" />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <CustomDropdown {...searchDropdown} onchange={onChange} />
                    </Grid>
                  </Grid>
                </Grid>

                {/* section 1 column 2 - regional*/}
                <Grid item xs={12} sm={7}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    rowGap={2}
                  >
                    {/* row - unitno*/}
                    {searchControl.controller === "1" && (
                      <Grid item xs={12} sm={7}>
                        <Autocomplete
                          onChange={handleUnitNoSearch}
                          options={unitNumberList.sort(
                            (a, b) => -b.firstID.localeCompare(a.firstID)
                          )}
                          groupBy={(unitNumberList) => unitNumberList.firstID}
                          getOptionLabel={(unitNumberList) =>
                            unitNumberList.unitName
                          }
                          loading={unitNoLoading} // Set loading prop
                          renderInput={(params) => (
                            <TextField
                              sx={{
                                "& .MuiFormHelperText-root": {
                                  marginLeft: 0,
                                  // fix based on your screen
                                  fontSize: {
                                    xs: "9px",
                                    sm: "9px,",
                                    md: "9px",
                                    lg: "12px",
                                    xl: "14px",
                                  },
                                  fontWeight: "400",
                                  color: "black",
                                },
                                "& .MuiInputBase-input": {
                                  borderRadius: "5px",
                                  color: "black",
                                  background: "#ffffff",
                                  // fix based on your screen
                                  fontSize: {
                                    xs: 12,
                                    sm: 12,
                                    md: 13,
                                    lg: 14,
                                    xl: 16,
                                  },
                                },
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "5px",
                                  "& fieldset": {
                                    borderColor: "#474E68",
                                    // borderRadius:'0px'
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "#6B728E",
                                    // borderRadius: '0px'
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#0F3460",
                                    borderWidth: "0.5px",
                                    // borderRadius: '0px'
                                  },
                                },
                              }}
                              autoComplete="off"
                              // size='small'

                              {...params}
                              placeholder="Enter Unit Code"
                              // label="Unit No."

                              fullWidth
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {unitNoLoading && (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    )}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                              // helperText={<div style={{ height: "18px" }}></div>}
                            />
                          )}
                        />
                      </Grid>
                    )}

                    {searchControl.controller === "2" && (
                      <Grid item xs={12} sm={7}>
                        <CustomTextField
                          {...accountNumberField}
                          onchange={onChange}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} sm={4}>
                      <Button
                        disabled={isSearching}
                        onClick={handleSearch}
                        variant="contained"
                        startIcon={<Search />}
                        color="primary"
                        // disabled={true}
                        fullWidth
                        type="submit"
                      >
                        {isSearching ? (
                          <CircularProgress color="secondary" />
                        ) : (
                          "Search"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* Form Divider main Grid end */}
              {isDataPresent ? (
                <Grid item xs="auto" sm={12}>
                  <FormTable
                    titles={tableTitles}
                    leftAlign={leftAlign}
                    comma={[]}
                    //data={sampleTableData}
                    data={tableData}
                  />{" "}
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Arial sans-serif",
                      fontSize: {
                        xs: "10px",
                        sm: "11px",
                        md: "12px",
                        lg: "14px",
                        xl: "15px",
                      },
                      fontWeight: "500",
                    }}
                    color={"#1c632f"}
                    align="left"
                  >
                    {tableMessage}
                  </Typography>
                </Grid>
              )}
            </Grid>
            {/* Form Wrapper grid end*/}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default ViewConsent;
