/** this methods are for checking the name with the service records */
/** This method matches the element  */

export const isMatching = (concatArray, referenceArray) => {
  for (const element of concatArray) {
    const found = referenceArray.find((refElem) => refElem === element);
    console.log("referenceArray", referenceArray);
    if (found) {
      referenceArray = referenceArray.filter((refElem) => refElem !== found);
    }
    concatArray = concatArray.filter((elem) => elem !== found);
    console.log("concatArray", concatArray);
  }
  return concatArray.length === 0 && referenceArray.length === 0;
};

// export function checkNameMatch(fullName, firstName, middleName, lastName) {
//   let refArray = fullName.split(" ");

//   const concatinatedNameList = [firstName, middleName, lastName].filter(
//     (name) => name !== null && name !== undefined
//   );

//   const concatinatedName = concatinatedNameList.join(" ");

//   let concatinatedArray = concatinatedName.split(" ");

//   console.log("return in matching", isMatching(concatinatedArray, refArray));
//   return isMatching(concatinatedArray, refArray);
// }

export function checkNameMatch(fullName, firstName, middleName, lastName) {
  // Split the full name into an array of its parts and convert to lowercase

  const fullNameArray = fullName.toLowerCase().split(" ").filter(Boolean);

  // Split the first, middle, and last names into arrays, convert to lowercase, and remove null elements
  const firstNameArray = firstName.toLowerCase().split(" ").filter(Boolean);
  const middleNameArray = middleName.toLowerCase().split(" ").filter(Boolean);
  const lastNameArray = lastName.toLowerCase().split(" ").filter(Boolean);

  // Concatenate the first, middle, and last name arrays
  const concatenatedArray = [
    ...firstNameArray,
    ...middleNameArray,
    ...lastNameArray,
  ];

  // Check if every element of fullNameArray is present in concatenatedArray
  const allElementsPresent = fullNameArray.every((element) =>
    concatenatedArray.includes(element)
  );

  console.log("fullNameArray", fullNameArray);
  console.log("concatenatedArray", concatenatedArray);

  console.log("namecheckmatchgives", allElementsPresent);
  return allElementsPresent;
}
//*** Date of cessation check on SuperAnnuation */
export function dateOfCessationCheck(dateOfCessation, dateOfBirth) {
  const dob = new Date(dateOfBirth);

  const cessation = new Date(dateOfCessation);
  console.log("dateOfCessation", dateOfCessation);

  const ageAtCessation = cessation.getFullYear() - dob.getFullYear();
  console.log("ageAtCessation", ageAtCessation);
  const monthDiff = cessation.getMonth() - dob.getMonth();
  console.log("monthDiff", monthDiff);

  const day = dob.getDate();
  console.log("days", day);

  if (
    (ageAtCessation === 60 && monthDiff === 0 && day !== 1) ||
    (ageAtCessation === 60 && monthDiff === -1 && day === 1) ||
    (ageAtCessation === 59 && monthDiff === 11 && day === 1)
  ) {
    return true;
  } else {
    return false;
  }
}

//**********Pension Service Check*********** */


 export function periodOfService(dateOfJoining, dateCessation) {
  const joinDate = new Date(dateOfJoining);
  const cessation = new Date(dateCessation);

  let years = 0;
  let months = 0;



  const yearsOfService = cessation.getFullYear() - joinDate.getFullYear();
  const joinMonth = joinDate.getMonth()+1;
  const cessationMonth = cessation.getMonth() + 1;
//console.log("joinMonth",joinMonth);
//console.log("cessationMonth",cessationMonth);
  if(joinMonth > cessationMonth) {
    years = yearsOfService-1;
    months = 12 + cessationMonth - joinMonth;

  }
  else {
     years = yearsOfService;
    months = cessationMonth - joinMonth;
  }

  //console.log("Years",years);
  //console.log("Months",months);

  if (
    years >= 30 ||
    (years === 29 && months  >= 6)
  ) {
    return true;
  } else {
    return false;
  }
}

// export function periodOfService(joiningDate, exitDate) {
//   // Parse the date strings into Date objects
//   const joinDate = new Date(joiningDate);
//   const endDate = new Date(exitDate);

//   // Calculate the time difference in milliseconds
//   const timeDiff = endDate.getTime() - joinDate.getTime();

//   // Convert the time difference to years
//   const yearsOfService = timeDiff / (1000 * 3600 * 24 * 365.25);

//   if (Math.round(yearsOfService) >= 29.5) {
//     return true;
//   } else {
//     return false;
//   }
// }




//********Pension on Service in months ****** */

export function periodOfServiceInMonths(dateOfJoining, dateCessation) {
  const joinDate = new Date(dateOfJoining);
  const cessationDate = new Date(dateCessation);

  const totalMonthsOfService =
    (cessationDate.getFullYear() - joinDate.getFullYear()) * 12 +
    (cessationDate.getMonth() - joinDate.getMonth());

  if (totalMonthsOfService > 120) {
    return true;
  } else {
    return false;
  }
}


export function isNotCessionInApril(dateString) {
    // Parse the date string into a date object
    const date = new Date(dateString);

    // Get the month from the date object (January is 0, December is 11)
    const month = date.getMonth();

    // Check if the month is April (3, because the count starts from 0)
    if (month === 3) {
        return false;
    }

    // Return true if the month is not April
    return true;
}