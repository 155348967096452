import { useState, useEffect } from "react";

const AdvanceCalculation = (cmpfno) => {
  //   console.log("cmpfno", cmpfno);
  const [ledgerDetails, setLedgerDetails] = useState([]);
  const [advanceDetails, setAdvanceDetails] = useState([]);
  const [memberShare, setMemberShare] = useState(0); // Initialized with a default value
  const [employeeShare, setEmployeeShare] = useState(0); // Initialized with a default value
  const [totalClosingBalance, setTotalClosingBalance] = useState(0); // Initialized with a default value

  const roundOffToTwoDecimals1 = (value) => {
    if (typeof value === "number") {
      return isNaN(value) ? "00" : Math.round(value).toString();
    }
    return value;
  };

  const fetchAPI = async (cmpfno) => {
    const formdata = new FormData();
    formdata.append("cmpfAcNO", cmpfno);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    const url1 = process.env.REACT_APP_SERVER_URL + "/get/pf/ledger/details";
    const url2 =
      process.env.REACT_APP_SERVER_URL + "/get/pf/advance/withd/details";
    const url3 = process.env.REACT_APP_SERVER_URL + "/get/intrest/with/yr";

    try {
      const responses = await Promise.all([
        fetch(url1, requestOptions).then((response) => response.json()),
        fetch(url2, requestOptions).then((response) => response.json()),
        fetch(url3, requestOptions).then((response) => response.json()),
      ]);

      setLedgerDetails(responses[0].data);
      addDummyRow(responses[0].data);
      setAdvanceDetails(
        responses[1].data.map((detail) => ({
          ...detail,
          key: detail.pfWthdrwlId,
        }))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (cmpfno !== null && cmpfno !== undefined) {
      fetchAPI(cmpfno);
    }
  }, [cmpfno]);

  const addDummyRow = (lazerData) => {
    const lastRow =
      lazerData.length > 0 ? lazerData[lazerData.length - 1] : null;

    const nextCurrPeriodEnd = lastRow
      ? new Date(lastRow.currPeriodEnd)
      : new Date();
    nextCurrPeriodEnd.setFullYear(nextCurrPeriodEnd.getFullYear() + 1);

    const dummyRow = {
      interestAmt: 0,
      employerPfContri: 0,
      closingBal: 0,
      memPfContri: 0,
      volContri: 0,
      adminChg: 0,
      ledgerDetId: 0,
      noOfMonthWorked: 0,
      currPeriodEnd: nextCurrPeriodEnd.toISOString().split("T")[0], // Format as YYYY-MM-DD
      unitCode: "",
      totalEmol: 0,
      interestPerc: 0,
      unitId: "",
      openingBalance: 0,
      memberId: "",
    };

    setLedgerDetails((prevData) => [...prevData, dummyRow]);
  };

  useEffect(() => {
    const newTableData = attachAdvanceDetailsToLedgers(
      ledgerDetails,
      advanceDetails
    );

    if (JSON.stringify(newTableData) !== JSON.stringify(ledgerDetails)) {
      setLedgerDetails(newTableData);
    }
  }, [ledgerDetails, advanceDetails]);

  const attachAdvanceDetailsToLedgers = (ledgerDetails, advanceDetails) => {
    let updatedLedgerDetails = [...ledgerDetails];

    for (let index = 0; index < updatedLedgerDetails.length; index++) {
      const ledger = updatedLedgerDetails[index];
      const advancesForYear = advanceDetails.filter((advance) => {
        const advanceDate = new Date(advance.withdrwlDate);
        const advanceFinancialYear =
          advanceDate.getMonth() <= 2
            ? advanceDate.getFullYear()
            : advanceDate.getFullYear() + 1;

        const ledgerFinancialYear = new Date(
          ledger.currPeriodEnd
        ).getFullYear();

        return advanceFinancialYear === ledgerFinancialYear;
      });

      let x = roundOffToTwoDecimals1(
        (parseFloat(ledger.memopeningbalance || 0) *
          parseFloat(ledger.interestPerc)) /
          100
      );
      let y = roundOffToTwoDecimals1(
        (parseFloat(ledger.empopeningbalance || 0) *
          parseFloat(ledger.interestPerc)) /
          100
      );
      const int_disp = parseFloat(x) + parseFloat(y);
      let member_closing_balance =
        parseFloat(ledger.memopeningbalance || 0) +
        parseFloat(x) +
        parseFloat(ledger.memPfContri) +
        parseFloat(ledger.volContri);

      let emp_closing_balance =
        parseFloat(ledger.empopeningbalance || 0) +
        parseFloat(y) +
        parseFloat(ledger.employerPfContri);

      for (const advance of advancesForYear) {
        const amount = advance.withdrwlAmt;
        let reducevalue = 0;

        const monthOfAdvance = new Date(advance.withdrwlDate).getMonth() + 1;
        let monthRemaining = 12 - monthOfAdvance + 3;

        if (monthRemaining === 12) {
          reducevalue = 0;
        } else if (monthRemaining === 13) {
          reducevalue = 1;
        } else if (monthRemaining === 14) {
          reducevalue = 2;
        } else {
          reducevalue = monthRemaining;
        }

        const interestRate = ledger.interestPerc;

        const interest_deduct = roundOffToTwoDecimals1(
          (parseFloat(advance.withdrwlAmt) *
            interestRate *
            (reducevalue / 12)) /
            100
        );

        const total_deduct = parseFloat(amount) + parseFloat(interest_deduct);
        if (
          advance.withdrwlTypId === 1 ||
          advance.withdrwlTypId === 2 ||
          advance.withdrwlTypId === 3 ||
          advance.withdrwlTypId === 4
        ) {
          member_closing_balance -= (4 / 7) * parseFloat(total_deduct);
          emp_closing_balance -= (3 / 7) * parseFloat(total_deduct);
        } else if (advance.withdrwlTypId === 8) {
          member_closing_balance = 0;
          emp_closing_balance = 0;
        } else {
          member_closing_balance -= parseFloat(total_deduct);
        }
      }

      let closing =
        parseFloat(member_closing_balance) + parseFloat(emp_closing_balance);
      updatedLedgerDetails[index].memclosingbalance = parseFloat(
        member_closing_balance
      );
      updatedLedgerDetails[index].empclosingbalance =
        parseFloat(emp_closing_balance);

      setTotalClosingBalance(
        updatedLedgerDetails[index].memclosingbalance +
          updatedLedgerDetails[index].empclosingbalance
      );
      setEmployeeShare(updatedLedgerDetails[index].empclosingbalance);
      setMemberShare(updatedLedgerDetails[index].memclosingbalance);
      let closingBal = closing;

      if (index < updatedLedgerDetails.length - 1) {
        updatedLedgerDetails[index + 1].openingBalance = closingBal;
        updatedLedgerDetails[index + 1].memopeningbalance =
          updatedLedgerDetails[index].memclosingbalance;
        updatedLedgerDetails[index + 1].empopeningbalance =
          updatedLedgerDetails[index].empclosingbalance;
      }

      updatedLedgerDetails[index] = {
        ...updatedLedgerDetails[index],
        advanceDetails: advancesForYear,
        interestAmt: int_disp,
        closingBal: closingBal,
      };
    }

    return updatedLedgerDetails;
  };

  // Render nothing, as the component just calculates and updates state
  return { memberShare, employeeShare, totalClosingBalance };
};

export default AdvanceCalculation;
