import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Appbar from '../Pages/LandingPage/Appbar';
import Footbar from '../Pages/LandingPage/Footbar';
import Footer from '../Pages/LandingPage/Footer';

function LandingLayout() {
  return (
    <div>
         <Header />
         <Appbar />
         <Outlet/>
         <Footbar />
         <Footer />
    </div>
  );
}

export default LandingLayout
