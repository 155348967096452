import React, { createContext, useRef } from 'react';
// import axios from 'axios';
export const CaptchaVerifyContext = createContext();

export function captchaVerifier() {
    return CaptchaVerifyContext;
}

export function CaptchaProvider(props) {

    const tokenRef = useRef(null);

    const resetCaptchaRef = useRef(null);

    const verifyCaptcha = (inputCode) => {
        try {
            if (inputCode !== '' && tokenRef.current.value === inputCode) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    const setResetCaptcha = (resetMethodCode) =>{
        resetCaptchaRef.current = resetMethodCode;
           console.log("captcha reset attached");
    }

    const resetCaptcha = () => {
        if (resetCaptchaRef.current) {
            resetCaptchaRef.current();
        }
    };


    return (
        <CaptchaVerifyContext.Provider value={{ "token": tokenRef, "verifier": verifyCaptcha, 'setResetCde':setResetCaptcha, 'resetCaptcha':resetCaptcha }}>
            {props.children}
        </CaptchaVerifyContext.Provider>
    );
}

