import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  Button,
} from "@mui/material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import axios from "axios";

const data = {
  modeOfRemittance: "A/C PAYEE CHEQUE/BANK TRANSFER",
};

const AdvDpo = () => {
  const location = useLocation();
  const { state } = location;
  const { advClaimData, memberData } = state;
  const { advClaim, claimType, paraNumber, tAdvSpecificDetails, unitCode } =
    advClaimData;
  let formattedCorresAddress = null;

  if (memberData.pfAddress) {
    const add = JSON.parse(memberData.pfAddress);
    formattedCorresAddress = `${add.addr_l1}, ${add.addr_l2}, ${add["village/town/city"]}, ${add.district}, ${add.state} - ${add.pin_code}`;

  }

  const claimId = advClaim.claimId;
  const [ros, setRos] = useState([]);
  const fetchAssignOfficer = async () => {
    try {
      const url =
        process.env.REACT_APP_SERVER_URL + "/get/ro/code/name/by/claimId";
      const formData = new FormData();
      formData.append("claimId", claimId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;

      setRos(data); // Assuming setAssignToOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchAssignOfficer();
  }, []);

  let givenAmount = 0;
  let firstInt = 0;
  let secondInt = 0;

  switch (advClaim.advClaimTypId) {
    case 2:
    case 3:
    case 4:
      givenAmount = advClaim.amountAllowed / 2;
      firstInt = advClaim.amountAllowed / 2;
      secondInt = advClaim.amountAllowed / 2;
      break;

    case 1:
    case 5:
    case 6:
    case 7:
    case 8:
      givenAmount = advClaim.amountAllowed;
      firstInt = advClaim.amountAllowed;
      break;

    default:
      // Handle unexpected advClaimTypId values, if needed
      break;
  }
  const [duration, setDuration] = useState("");
  const periodOfMembership = advClaim.periodOfMembship;
  useEffect(() => {
    // Check if periodOfMembership is defined and not an empty string
    if (periodOfMembership && periodOfMembership.trim() !== "") {
      try {
        const data = JSON.parse(periodOfMembership);
        console.log("data", data);

        let years = data.years;
        let months = data.months;

        // Format the output
        const duration = `${years} Years and ${months} Months`;
        setDuration(duration);
        console.log("Duration:", duration);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.error("Invalid or empty JSON string");
    }
  }, [periodOfMembership]);

  // print("Period Of Membership =", formatted_duration)

  return (
    <div>
      <div className="actual-receipt">
        <Box mt={8}>
          <div>
            <Grid>
              <Grid
                item
                lg={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              ></Grid>
              <Grid>
                <p> </p>
              </Grid>
            </Grid>
          </div>
          <Typography variant="h6" gutterBottom fontFamily={"monospace"}>
            Draft Pay Order
          </Typography>
          <Box mt={4} />
          <Typography variant="body2" gutterBottom>
            Date of Settlement :{" "}
            {advClaim.settlementDate
              ? new Date(advClaim.settlementDate).toLocaleDateString("en-GB")
              : ""}
          </Typography>

          <Typography variant="h6" gutterBottom>
            <span style={{ textDecoration: "underline" }}>
              COAL MINES PROVIDENT FUND ORGANISATION
            </span>
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            style={{ textDecoration: "underline" }}
          >
            DRAFT PAY ORDER FOR {claimType.toUpperCase()}
          </Typography>
          {/* <Typography variant="h6" gutterBottom>
        <span style={{ textDecoration: "underline"}}>
        DRAFT PAY ORDER FOR  {claimType.toUpperCase()}
        </span>
      </Typography> */}
          <Typography variant="body2" paragraph>
            (Settlement under {paraNumber} of C.M.P.F. SCHEME)
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            fontWeight="bolder"
            fontFamily="monospace"
          >
            <u>Region: {ros ? ros.roName : ""}</u>
          </Typography>
          <Grid
            container
            rowSpacing={1}
            // columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            style={{ paddingLeft: 100 }}
          >
            <Grid item xs={12} lg={12}>
              <List dense>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid
                        item
                        lg={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item lg={6}>
                          1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Name of the Member
                        </Grid>
                        <Grid item lg={6}>
                          :&nbsp;&nbsp;&nbsp;&nbsp;{memberData.fullName}
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid
                        item
                        lg={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item lg={6}>
                          2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Father's/Husband Name
                        </Grid>
                        <Grid item lg={6}>
                          :&nbsp;&nbsp;&nbsp;&nbsp;
                          {memberData.fatherHusbandName}
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid
                        item
                        lg={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item lg={6}>
                          3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CMPF A/C No.
                        </Grid>
                        <Grid item lg={6}>
                          :&nbsp;&nbsp;&nbsp;&nbsp;{memberData.cmpfAccNo}{" "}
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid
                        item
                        lg={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item lg={6}>
                          4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Unit No.
                        </Grid>
                        <Grid item lg={6}>
                          :&nbsp;&nbsp;&nbsp;&nbsp;{unitCode}
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid
                        item
                        lg={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item lg={6}>
                          5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Date of Appointment
                        </Grid>
                        <Grid item lg={6}>
                          :&nbsp;&nbsp;&nbsp;&nbsp;
                          {memberData.joiningDate
                            ? new Date(
                                memberData.joiningDate
                              ).toLocaleDateString(
                                "en-GB"
                                // options
                              )
                            : ""}
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid
                        item
                        lg={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item lg={6}>
                          6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Period of Membership
                        </Grid>
                        <Grid item lg={6}>
                          :&nbsp;&nbsp;&nbsp;&nbsp;{duration}
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid
                        item
                        lg={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item lg={6}>
                          7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ledger Balance
                        </Grid>
                        <Grid item lg={6}>
                          :&nbsp;&nbsp;&nbsp;&nbsp;{advClaim.ledgerBalance}
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid
                        item
                        lg={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item lg={6}>
                          8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Member's own share
                          with interest as per Ledger Card
                        </Grid>
                        <Grid item lg={6}>
                          :&nbsp;&nbsp;&nbsp;&nbsp;{advClaim.memShare}
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid
                        item
                        lg={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item lg={6}>
                          9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Employer's own share
                          with interest as per Ledger Card
                        </Grid>
                        <Grid item lg={6}>
                          :&nbsp;&nbsp;&nbsp;&nbsp;{advClaim.employerShare}
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid
                        item
                        lg={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item lg={6}>
                          10.&nbsp;&nbsp;&nbsp; Emoluments for 36 months
                        </Grid>
                        <Grid item lg={6}>
                          :&nbsp;&nbsp;&nbsp;&nbsp;{advClaim.emolumentsLast36m}
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid
                        item
                        lg={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item lg={6}>
                          11.&nbsp;&nbsp;&nbsp; Cost of House/Site
                        </Grid>
                        <Grid item lg={6}>
                          :&nbsp;&nbsp;&nbsp;&nbsp;
                          {tAdvSpecificDetails.estimatedCost}
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid
                        item
                        lg={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item lg={6}>
                          12.&nbsp;&nbsp;&nbsp; Amount of advance that may be
                          sanctioned
                        </Grid>
                        <Grid item lg={6}>
                          :&nbsp;&nbsp;&nbsp;&nbsp;
                          {advClaim.amountAllowed}
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item lg={6}>
                          <span>(a)&nbsp;&nbsp;&nbsp; as 1st installment</span>
                        </Grid>
                        <Grid item lg={6}>
                          <span>
                            :&nbsp;&nbsp;&nbsp;&nbsp;
                            {firstInt}
                          </span>
                        </Grid>
                      </span>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item lg={6}>
                          <span>(b)&nbsp;&nbsp;&nbsp; as 2nd installment</span>
                        </Grid>
                        <Grid item lg={6}>
                          <span>
                            :&nbsp;&nbsp;&nbsp;&nbsp;
                            {secondInt}
                          </span>
                        </Grid>
                      </span>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item lg={6}>
                          <span>(c)&nbsp;&nbsp;&nbsp; as 3rd installment</span>
                        </Grid>
                        <Grid item lg={6}>
                          <span>
                            :&nbsp;&nbsp;&nbsp;&nbsp;
                            {0}
                          </span>
                        </Grid>
                      </span>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item lg={6}>
                          <span>(d)&nbsp;&nbsp;&nbsp; as Last installment</span>
                        </Grid>
                        <Grid item lg={6}>
                          <span>
                            :&nbsp;&nbsp;&nbsp;&nbsp;
                            {0}
                          </span>
                        </Grid>
                      </span>
                    }
                  />
                </ListItem>
                <ListItem>
                  <div style={{ paddingLeft: 20 }}>
                    <ListItemText
                      primary={
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {/* <span style={{ paddingLeft: 105 }}> Total</span> */}
                          <span style={{ paddingLeft: 300 }}>
                            {" "}
                            Total Amount
                          </span>
                          {/* <span>:&nbsp;&nbsp;&nbsp;&nbsp;{data.totalAmount}</span> */}
                          <span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;
                            <u>{advClaim.amountAllowed}</u>
                          </span>
                        </span>
                      }
                    />
                  </div>
                </ListItem>
                {/* <ListItem>
              <ListItemText
                primary={
                  <span
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span>
                      10. &nbsp;&nbsp; Amount of advance that may be sanctioned
                    </span>
                    <span>: &nbsp;&nbsp; Rs. 150000</span>
                  </span>
                }
              />
              <div style={{ paddingLeft: 20 }}>
                <ListItemText primary="(a) 1st installment: Rs. 100000" />
                <ListItemText primary="(b) 2nd installment: Rs. 50000" />
              </div>
            </ListItem> */}
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid
                        item
                        lg={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item lg={6}>
                          13.&nbsp;&nbsp;&nbsp; Present purpose for which
                          advance applied for
                        </Grid>
                        <Grid item lg={6}>
                          :&nbsp;&nbsp;&nbsp;&nbsp;
                          <u>{claimType}</u>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid
                        item
                        lg={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item lg={6}>
                          14.&nbsp;&nbsp;&nbsp; Amount applied for
                        </Grid>
                        <Grid item lg={6}>
                          :&nbsp;&nbsp;&nbsp;&nbsp;
                          <u>{advClaim.amountApplied}</u>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid
                        item
                        lg={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item lg={6}>
                          15.&nbsp;&nbsp;&nbsp; Amount allowed for
                        </Grid>
                        <Grid item lg={6}>
                          :&nbsp;&nbsp;&nbsp;&nbsp;
                          <u>{advClaim.amountAllowed}</u>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid
                        item
                        lg={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item lg={6}>
                          16.&nbsp;&nbsp;&nbsp; Amount already Paid
                        </Grid>
                        <Grid item lg={6}>
                          :&nbsp;&nbsp;&nbsp;&nbsp;
                          <u>{tAdvSpecificDetails.amtAlrdyPaid}</u>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid
                        item
                        lg={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item lg={6}>
                          17.&nbsp;&nbsp;&nbsp; Mode of remittance
                        </Grid>
                        <Grid item lg={6}>
                          :&nbsp;&nbsp;&nbsp;&nbsp;
                          <u>{data.modeOfRemittance}</u>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Grid style={{ paddingLeft: 115 }}>
            <Typography variant="body2" paragraph align="left">
              <span>
                18.&nbsp;&nbsp;&nbsp; Address where remittance is to be made
                Bank Details:
              </span>
            </Typography>
            <Grid style={{ paddingLeft: 50 }}>
              <Typography variant="body2" paragraph align="left">
                <span>Bank A/C No.</span>
                <span style={{ paddingLeft: 35 }}>
                  :&nbsp;&nbsp;&nbsp;&nbsp;{memberData.bankAccNoPf}
                </span>
              </Typography>
              {/* IFSC CODE  */}
              <Typography variant="body2" paragraph align="left">
                <span>IFSC CODE</span>
                <span style={{ paddingLeft: 50 }}>
                  :&nbsp;&nbsp;&nbsp;&nbsp;{memberData.pfBankIfsc}
                </span>
              </Typography>
              {/* IFSC CODE KHTM */}
              <Grid style={{ paddingLeft: 75 }}>
                {/* Bank ka Naam  */}
                <Typography variant="body2" paragraph align="left">
                  <span style={{ paddingLeft: 49 }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;{memberData.pfBankName}
                  </span>
                </Typography>
                {/* Bank ka Naam khtm*/}

                {/* Corresponding Adress  */}
                <Typography variant="body2" paragraph align="left">
                  <span style={{ paddingLeft: 49 }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;{formattedCorresAddress}
                  </span>
                </Typography>
                {/* Corresponding Adress Khtm */}
              </Grid>
              <Typography variant="body2" paragraph align="left">
                <span>Postal Address</span>
                <span style={{ paddingLeft: 20 }}>
                  {" "}
                  :&nbsp;&nbsp;&nbsp;&nbsp;
                  {`
     ${memberData.addressL1 ? memberData.addressL1 + "," : ""}
      
     ${memberData.addressL2 ? memberData.addressL2 + "," : ""}
      
     ${memberData.village ? memberData.village + "," : ""}
      
     ${memberData.city ? memberData.city + "," : ""}
      
     ${memberData.district ? memberData.district + "," : ""}
      
     ${memberData.state ? memberData.state + "," : ""}
      
     ${memberData.country ? memberData.country + "," : ""}
      
     ${memberData.pincode ? memberData.pincode : ""}
     `}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <div>
            ==============================================================================================================
          </div>
        </Box>
      </div>
    </div>
  );
};

const GenerateAdvPO = () => {
  const { state } = useLocation();
  const { advClaimData } = state;
  const { advClaim } = advClaimData;
  // const [base64, setBase64] = useState();
  const AdvPORefElement = useRef(null);

  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/roda/advset/viewadvclaimroda", {
      state: { passingId: advClaim.claimId },
    });
  };

  const exportPDF = () => {
    const compressionQuality = 0.4; // adjust the quality level
    html2canvas(AdvPORefElement.current, { scale: 2.2, allowTaint: true }).then(
      (canvas) => {
        const imageData = canvas.toDataURL("image/jpeg", compressionQuality);
        const pdf = new jsPDF("p", "mm", "a4");
        // const contentHeight1 = 580 * 2; // Double the content height for the second page
        // const pageWidth = 180;
        // const pageHeight1 = 480;
        // const contentHeight = canvas.height / 4.377;
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        // console.log("contentHeight1", contentHeight);
        // console.log("pageWidth1", pageWidth);
        // console.log("pageHeight1", pageHeight);

        // Add content to the first page
        pdf.addImage(
          imageData,
          "JPEG",
          15,
          15,
          pageWidth - 30,
          pageHeight - 30
        );

        const base64PDF = pdf.output("datauristring");
        let base64PDFf = base64PDF.replace(
          /^data:.+;base64,/,
          `data:application/pdf;base64,`
        );

        navigate("/roda/advset/viewadvclaimroda", {
          state: { passingId: advClaim.claimId, pdf: base64PDFf },
        });
      }
    );
  };

  return (
    <div>
      <div style={{ justifyContent: "flex-end" }}>
        <div ref={AdvPORefElement}>
          <AdvDpo />
        </div>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid
            item
            // marginTop={3}
            // marginBottom={3}
            // marginRight="20px"
            // style={{ display: "flex", justifyContent: "center" }}
          >
            <Button onClick={handleCancel} variant="contained" color="primary">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={exportPDF} variant="contained" color="success">
              Generate dpo
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default GenerateAdvPO;
