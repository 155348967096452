//using the react to print
import React, { useEffect, useState, useRef } from "react";
// import {PropagateLoader} from "react-spinners";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";
import { Grid, Box, Divider, Typography } from "@mui/material";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import LabelText from "../../FormComponent/LabelText";
import ValueText from "../../FormComponent/ValueText";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import axios from "axios";
import { useLocation } from "react-router-dom";

const PrintableGrievanceForm = ({ url, id }) => {
  //for  the grievance view api
  const grievanceData = {
    grievanceId: "",
    grievanceCategDesc: "",
    grievanceSubCatDesc: "",
    grievanceDesc: "",
    // upldedDoc : "",
    docTypeDesc: "",
    submitDate: "",
    grievanceStatusDesc: "",
  };
  const [grievanceDetails, setGrievanceDetails] = useState(grievanceData);

  // console.log("grievance Dtaiasnn f d vhsdfv", grievanceDetails);
  //note this line
  const { state } = useLocation();
  // console.log("state log", state);
  const grievanceId = state.passedData.grievanceId;

  //calling api here directly
  const fetchGrievanceDetails = async () => {
    const url = process.env.REACT_APP_SERVER_URL + "/showAllGrievanceToMember";
    const formData = new FormData();
    formData.append("grievanceId", grievanceId);
    // console.log("grivance ID", grievanceId);
    const response = await axios.post(url, formData);
    // console.log(" response data ", response.data.data);
    const data = response.data.data;
    setGrievanceDetails(data[0]);
  };

  useEffect(() => {
    fetchGrievanceDetails();
  }, []);

  const printRef = useRef();
  const [student, setStudent] = useState({});
  useEffect(() => {}, [url, id]);

  const { studentID, Course, ApplicantName } = student;
  //handle go back
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); //navigate back to the previous page
    window.scrollTo(0, 0); // scroll to the top of the page
  };
  //handling print
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  return (
    <div className="WMCContainer">
      {/* <PageTitle title={`Welcome ${ApplicantName}`}/> */}
      {student.length === 0 ? (
        "..."
      ) : (
        <>
          {/* <div className="WMPrint">
                <button onClick={handlePrint}> 
                    <PrintIcon/>
                </button>
            </div> */}
          <div ref={printRef} className="WMmwssage">
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper
                  elevation={3}
                  sx={{ padding: "3rem", borderRadius: "8px" }}
                >
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        align="left"
                        onClick={handleGoBack}
                        // style={{ marginTop: "3px" }}
                      >
                        Back
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePrint}
                        startIcon={<PrintIcon />}
                      >
                        Print
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    {/* <TitleText title="PF Claim Submission" /> */}
                  </Grid>
                  <FormDivider />

                  <div sx={{ wordWrap: "break-word" }} id="print-content">
                    <Grid
                      container
                      spacing={2}
                      justifyContent="center"
                      style={{ margin: "20px" }}
                      alignItems="start"
                      id="printableArea"
                    >
                      <Grid item xs={12} lg={12} md={12} textAlign={"left"}>
                        <Typography variant="h5">Grievance Detail</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          container
                          item
                          xs={12}
                          justifyContent={"flex-start"}
                          marginTop={"2%"}
                          rowSpacing={"3"}
                        >
                          <Grid
                            item
                            xs={4}
                            justifyContent="flex-start"
                            container
                          >
                            {/* <Typography variant="body1">Document Description</Typography> */}
                            <LabelText title="Grievance Status" />
                          </Grid>
                          <Grid item xs={1} container justifyContent="center">
                            <LabelText title=":" />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            container
                            justifyContent="flex-start"
                          >
                            {/* <Typography variant="body1">{printableData.docTypeDesc}</Typography> */}
                            <ValueText
                              title={grievanceDetails.grievanceStatusDesc}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={4}
                            justifyContent="flex-start"
                            container
                          >
                            {/* <Typography variant="body1">Grievance ID</Typography> */}
                            <LabelText title="Grievance ID" />
                          </Grid>
                          <Grid item xs={1} container justifyContent="center">
                            :
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            container
                            justifyContent="flex-start"
                          >
                            {/* <Typography variant="body1">{printableData.grievanceId}</Typography> */}
                            <ValueText title={grievanceDetails.grievanceId} />
                          </Grid>

                          <Grid
                            item
                            xs={4}
                            justifyContent="flex-start"
                            container
                          >
                            {/* <Typography variant="body1">Submit Date</Typography> */}
                            <LabelText title="Submit Date" />
                          </Grid>
                          <Grid item xs={1} container justifyContent="center">
                            :
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            container
                            justifyContent="flex-start"
                          >
                            {/* <Typography variant="body1">{printableData.submitDate}</Typography> */}
                            <ValueText title={grievanceDetails.submitDate} />
                          </Grid>

                          <Grid
                            item
                            xs={4}
                            justifyContent="flex-start"
                            container
                          >
                            {/* <Typography variant="body1">Grievance Category</Typography> */}
                            <LabelText title="Grievance Category" />
                          </Grid>

                          <Grid item xs={1} container justifyContent="center">
                            :
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            container
                            justifyContent="flex-start"
                          >
                            {/* <Typography variant="body1">{printableData.grievanceCategDesc}</Typography> */}
                            <ValueText
                              title={grievanceDetails.grievanceCategDesc}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={4}
                            justifyContent="flex-start"
                            container
                          >
                            {/* <Typography variant="body1">Grievance SubCategory</Typography> */}
                            <LabelText title="Grievance SubCategory" />
                          </Grid>
                          <Grid item xs={1} container justifyContent="center">
                            <LabelText title=":" />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            container
                            justifyContent="flex-start"
                          >
                            {/* <Typography variant="body1">{printableData.grievanceSubCatDesc}</Typography> */}
                            <ValueText
                              title={grievanceDetails.grievanceSubCatDesc}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={4}
                            justifyContent="flex-start"
                            container
                          >
                            {/* <Typography variant="body1">Document Description</Typography> */}
                            <LabelText title="Document Description" />
                          </Grid>
                          <Grid item xs={1} container justifyContent="center">
                            <LabelText title=":" />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            container
                            justifyContent="flex-start"
                          >
                            {/* <Typography variant="body1">{printableData.docTypeDesc}</Typography> */}
                            <ValueText title={grievanceDetails.docTypeDesc} />
                          </Grid>

                          <Grid
                            item
                            xs={4}
                            // justifyContent="flex-start"
                            container
                            alignItems="start"
                            // display="block"
                            style={{ display: "block" }}
                          >
                            {/* <Typography variant="body1">Grievance Description</Typography> */}
                            <LabelText
                              title="Grievance Description"
                              style={{ display: "block" }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            container
                            justifyContent="center"
                            // style={{ display: "block" }}
                          >
                            <LabelText title=":" />
                          </Grid>
                          <Grid
                            item
                            xs={7}
                            container
                            // style={{ display: "block" }}
                            sx={{
                              maxHeight: "300px",
                              overflowY: "auto",
                              marginTop: "15px",
                              // marginLeft: "20%",
                              width: "300%",
                              // marginLeft: { lg: "30%" },
                            }}
                          >
                            {/* <Typography variant="body1">{printableData.grievanceDesc}</Typography> */}
                            <ValueText
                              // display= "block"
                              title={grievanceDetails.grievanceDesc}
                              // alignment="start"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <br></br>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </div>
  );
};
export default PrintableGrievanceForm;
