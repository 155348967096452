import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Paper, Button, Autocomplete } from "@mui/material";
import axios from "axios";

import TitleText from "../../FormComponent/TitleText";
import LabelText from "../../FormComponent/LabelText";
import CustomTextField from "../../FormComponent/CustomTextField";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import GroupedRadioButton from "../../FormComponent/GroupedRadioButton";
import SubtitleText from "../../FormComponent/SubtitleText";
import CustomTextArea from "../../FormComponent/CustomTextArea";
import FormDivider from "../../FormComponent/FormDivider";
import { formPatternValidation } from "../../Utility/UtilityMethods";
import CustomDropdownWithValue from "../../FormComponent/CustomDropdownWithValue";
import SucessAlert from "../../FormComponent/SuccessAlert";
import ConfirmationDialogue from "../../FormComponent/ConfirmationDialogue";

import {
  familyMemberModel,
  familyMandatoryFields,
} from "../../Models/defaultModel";

import UploadDocument from "../../SettlementComponents/UploadDocument";
import { TextField, CircularProgress } from "@mui/material";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import jwtDecode from "jwt-decode";

function AddFamMemForm() {
  const defaultMemData = {
    // unitCode: "",
    titleName: "",
    religionName: "",
    relationName: "",
  };
  const navigate = useNavigate();

  const location = useLocation();
  // const  {state}  = location;
  console.log("location cmpfaccno", location);

  const { pathname } = location;
  const cmpfAccNo = location.state.cmpfAccNo;

  // const {
  //   //   state: { passedData, passingId },
  // } = location;

  //console.log("passedData , passingId", passedData, passingId);
  // depending upon what has arrived passed data or passingId the familymemberId to be passed on the api request is to be changed

  // const famMemId = passedData ? passedData.familyMemId : passingId;
  //console.log("famMemId", famMemId);

  // const defaultData = memberModel;
  const defaultData = familyMemberModel;
  const mandatory = familyMandatoryFields;

  const [family, setFamily] = useState(defaultData);
  //for document upload
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // for state autocomplete
  const [stateLoading, setStateLoading] = useState(false);
  const [stateList, setStateList] = useState([]);
  //for dropdown of title
  const [titleList, setTitleList] = useState([]);
  //for religion list
  const [religionList, setReligionList] = useState([]);

  //for relation list
  const [relationList, setRelationList] = useState([]);
  const [validationFailed, setValidationFailed] = useState(false);
  const [validationFailedMsg, setValidationFailedMsg] = useState("");
  const [memberDet, setMemberDet] = useState(defaultMemData);
  const [ackId, setAckId] = useState();
  const defaultClaim = {
    remarks: "",
    assignTo: "",
  };

  const [showingDropdown, setShowingDropdown] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [assignToOption, setAssignToOption] = useState([]);
  const [claim, setClaim] = useState(defaultClaim);
  const [selectedOfficerName, setSelectedOfficerName] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const [loginId, setLoginId] = useState();

  /********fetching and setting token********* */
  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (token) {
      token = token.replace('"', "").replace('"', "");
      let loginId = jwtDecode(token).user; // Assuming a default loginId
      setLoginId(loginId);

      console.log("loooooooooooooooooooooginId", loginId);
      // Add any further logic here based on the loginId
    } else {
      // Handle the case when token is not present
      // setIsFailed(true);
      // setIsLoading(false);
    }
  }, []);

  const assignTo = {
    name: "assignTo",
    placeholder: "Select Officer",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "",
    required: true,
    options: assignToOption,
  };
  //****************Dropdown******************* */
  const fetchAssignOfficer = async () => {
    try {
      //  if (!(await sessionExist())) {
      //    setIsFailed(true);
      //    setIsLoading(false);
      //    return;
      //  }

      // let token = localStorage.getItem("jwt");

      // if (!token) {
      //   setIsFailed(true);
      //   setIsLoading(false);
      //   return;
      // }

      // token = token.replace('"', "").replace('"', "");
      // const loginId = jwtDecode(token).user;

      const url =
        process.env.REACT_APP_SERVER_URL +
        "/dropdown/for/update/member/details/by/uda";

      // "/dropdown/for/adv/pf/claim/aao/to/ids";
      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      const options = data.map((option) => ({
        id: option.loginId,
        title: `${option.name} (${option.loginId})`,
      }));
      setAssignToOption(options); // Assuming setAssignToOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    // fetchAllClaimDetail();
    fetchAssignOfficer();
  }, [loginId]);

  //! cmpf account number is fetched on useEffect with a delay of 3sec to avoid multiple api hits
  // const onChange = (e) => {
  //   if (e.target.name === "assignTo") {
  //     const selectedOption = assignToOption.find(
  //       (option) => option.id === e.target.value
  //     );
  //     if (selectedOption) {
  //       setSelectedOfficerName(selectedOption.title); // Set the selected officer's name in the state
  //       console.log("selctess", selectedOption.title);
  //     }
  //   }
  //   setClaim({ ...claim, [e.target.name]: e.target.value });
  // };

  /// ******** on submit function*****************
  const objectTobeSent = {
    // cmpfAccNo: family.cmpfAccNo,
    cmpfAccNo: cmpfAccNo,

    // memberId: null,
    titleId: family.titleId,
    firstName: family.firstName,
    middleName: family.middleName,
    lastName: family.lastName,
    fathHusName: family.fathHusName,
    relationId: family.relationId,
    gender: family.gender,
    maritalStatus: family.maritalStatus,
    religionId: family.religionId,
    dateOfBirth: family.dateOfBirth,
    addressL1: family.addressL1,
    addressL2: family.addressL2,
    district: family.district,
    pincode: 1 * family.pincode,
    mobileNo: family.mobileNo ? family.mobileNo : 0,
    landNo: family.landNo ? family.landNo :0 ,
    emailId: family.emailId,
    // panNo: family.panNo ? family.panNo : "",
    panNo: family.panNo ? family.panNo:null,
    aadhaarNo: family.aadhaarNo,
    city: family.city,
    country: family.country,
    village: family.village,
    state: family.state,
    pfNominee: family.pfNominee,
    pensionNominee: family.pensionNominee,
    pfNomSharePerc: 1 * family.pfNomSharePerc,
    penNomSharePerc: 1 * family.penNomSharePerc,
    remarks: family.remarks,
    loginId: loginId,
    toOffcrLoginId: family.assignTo,
  };

  const handleSubmitOk = () => {
    if (!validate()) {
      console.log("validation falied");
      return;
    }

    if (!objectTobeSent.toOffcrLoginId) {
      // if (!claim.assignTo) {
      // Show message in dialog: "Please select an officer to assign"
      // alert("Please select an officer to assign");
      setIsError(true);
      setErrorMessage("Please Select an Officer to Assign.");
      return;
    }
    console.log("shivani", objectTobeSent);
    console.log("percentage", family);
    if (objectTobeSent.pfNominee === "true" && family.pfNomSharePerc === "") {
      setIsError(true);
      setErrorMessage("Please provide PF Nominee percentage share.");
      return;
    }
    if (
      objectTobeSent.pensionNominee === "true" &&
      family.penNomSharePerc === ""
    ) {
      setIsError(true);
      setErrorMessage("Please provide Pension Nominee percentage share.");
      return;
    }
    setIsSubmitClicked(true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // if (!validate()) {
    //   console.log("validation falied");
    //   // return;
    //   //alert("Please Fill out all the required fields.");
    //   //  setMessage("Kindly fill all fields!!");
    //   // setMessage("Please fill all the required fields.");
    //   // setErrorAlert(true);
    //   console.log("Validation failed");
    //   return;
    // }

    console.log("sangeeta will give party");

    setIsSubmitting(true);

    const url =
      // process.env.REACT_APP_SERVER_URL + "/update/family/member/details/by/UDA";
      process.env.REACT_APP_SERVER_URL + "/add/new/family/member/uda";

    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSent, { headers })
      .then(function (response) {
        //setIsSubmitting(false);
        //setIsSuccess(true);
        console.log("response", response);
        const ackId = response.data.data;

        postUploadedDoc(ackId);
        // setSuccessMessage("Member details updated successfully.");
        setIsSubmitting(false);
      })
      .catch((error) => {
        setIsError(true);
        console.log("error on family uda", error);
        const errorCode = error.response.data.http_status_code;
        console.log("errorCode", errorCode);
        // if (errorCode === 409) {
        if (error.response.status === 409) {
          console.log("Iam in error");
          setErrorMessage(
            "Family Member already present.Kindly Check Aadhaar Number and Pan  of the Added Family Member."
          );
        } else {
          setErrorMessage("Sorry Could not Proceed!! Please try again later");
          console.log("error on submit", error);
        }
        setIsSubmitting(false);
      });
  };
  console.log("ackId", ackId);
  //Handle save for documemt upload
  const handleSave = async (documentData) => {
    const requestBody = {
      famReqAckId: family.famReqAckId,
      uploadDocusNewFamilyMember: documentData.map((upload) => ({
        upldDocProof: upload.uploadedDocument,
        docTypeId: upload.selectedDocumentType,
        remarks: "",
        famMemId: ackId,
      })),
    };
    setUploadedDocs(requestBody.uploadDocusNewFamilyMember);
  };
  console.log("jai", family.famReqAckId);
  //uploadedDocs
  const postUploadedDoc = (ackId) => {
    const uploadDocData = {
      //famReqAckId: family.famReqAckId,
      famReqAckId: ackId,
      uploadDocusNewFamilyMember: uploadedDocs,
    };
    console.log("uploadDocData", uploadDocData);
    const url =
      process.env.REACT_APP_SERVER_URL +
      "/add/new/family/member/upload/documents";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, uploadDocData, { headers })
      .then(function (response) {
        console.log("Submit response", response);
        setIsSuccess(true);

        setSuccessMessage("Add Family Member Request Forwarded Successfully");
      })
      .catch((error) => {
        setIsError(true);
        setErrorMessage("Error in submitting document. Please Try again");
        console.log("error on submit", error);
      });
  };

  const handleProcessOk = (e) => {
    if (!validate()) {
      console.log("validation falied");
      return;
    }

    if (pfNominee === "true" && family.pfNomSharePerc === "") {
      setIsError(true);
      setErrorMessage("Please provide PF Nominee percentage share.");
      return;
    }
    if (pensionNominee === "true" && family.penNomSharePerc === "") {
      setIsError(true);
      setErrorMessage("Please provide Pension Nominee percentage share.");
      return;
    }

    setShowingDropdown(1);
  };

  const handleAssignToCancel = () => {
    setIsSubmitClicked(false);
  };

  const handleAssignToOk = (e) => {
    handleSubmit(e);
    setIsSubmitClicked(false);
  };
  const handleSuccess = () => {
    navigate("/uda");
  };
  const handleErrorAlert = () => {
    setIsError(false);
  };

  //the below object is for state dropdown
  const defaultValue = {
    firstID: "",
    stateName: "",
    value: "",
    // firstID: "A",
    // stateName: "ANDHRA PRADESH",
    // value: 1,
  };
  const [selectedState, setSelectedState] = useState(defaultValue);
  function getStateId(stateName) {
    const selectedState = stateList.find(
      (state) => state.stateName === stateName
    ); // Find the state object with the stateName

    if (selectedState) {
      return selectedState.value; // Return the ID of the selected state
    } else {
      return null; // Return null if the state is not found in the state list
    }
  }

  const handleStateSearch = (newValue, fieldName) => {
    console.log("state", newValue);
    console.log("event on state change", fieldName);
    // setMember({ ...member, state: newValue.value });
    setFamily({ ...family, [fieldName]: newValue.stateName });
  };
  const documentTypes = [
    { id: 1, title: "Aadhaar Card" },
    { id: 2, title: "Marriage Certificate" },
    { id: 3, title: "Death Certificate" },
    { id: 4, title: "Pan Card" },
    { id: 5, title: "Electricity Bill" },
    { id: 5, title: "Ration Card" },

    //     1	"Aadhaar Id"
    // 2	"Marriage Certificate"
    // 3	"Death Certificate"
    // 4	"PAN Card"
    // 5	"Electricity Bill"
    // 6	"Ration Card"
  ];

  useEffect(() => {
    // fetchUserDetail();
    fetchStates();
    fetchTitleList();
    fetchReligionList();
    fetchRelationList();
    // fetchCCHeads();
    // fetchCCDesig();
  }, [pathname]);
  // useEffect(() => {
  //   // switchUi();
  // setIsProgramLoaded(true);
  // }, []);

  //Configuraton

  const titleName = {
    name: "titleName",
    nameId: "titleId",

    placeholder: "Title",
    helperText: "",
    // disabled: editable,
    initialValue: family.titleId,
    options: titleList,
  };
  const religionName = {
    name: "religionName",
    nameId: "religionId",
    placeholder: "Religion",
    helperText: "",
    //disabled: editable,
    initialValue: family.religionId,
    options: religionList,
  };

  const gender = {
    options: [
      { value: "M", label: "Male" },
      { value: "F", label: "Female" },
      { value: "O", label: "Other" },
    ],
    name: "gender",
    initialValue: family.gender,
    // disabled: readOnly,
    // disabled: editable,
  };
  //****************************************************** */
  const maritalStatus = {
    name: "maritalStatus",
    placeholder: "Marital Status",
    helperText: "",
    // disabled: editable,
    initialValue: family.maritalStatus,
    options: [
      { id: "M", title: "Married" },
      { id: "U", title: "Unmarried" },
      { id: "W", title: "Widow/Widower" },
      { id: "D", title: "Divorced" },
    ],
  };

  const pfNominee = {
    options: [
      { value: false, label: "No" },
      { value: true, label: "Yes" },
    ],
    name: "pfNominee",

    initialValue: family.pfNominee,
  };

  const pensionNominee = {
    options: [
      { value: false, label: "No" },
      { value: true, label: "Yes" },
    ],
    name: "pensionNominee",
    // label: 'Name of Father/Husband',
    initialValue: family.pensionNominee,
  };

  const firstName = {
    name: "firstName",
    type: "text",
    helperText: "",
    // defaultValue: family.firstName,
    placeholder: "First Name",
    errorMessage:
      "First Name must be characters only and not allowed special characters except whitespace.",
    // label: "First Name",
    pattern: "^[a-zA-Z ]{1,64}$",
    required: mandatory.includes("firstName"),
    // disabled: readOnly,
    //disabled: editable,
  };

  const middleName = {
    name: "middleName",
    type: "text",
    helperText: "",
    defaultValue: family.middleName,
    placeholder: "Middle Name",
    errorMessage:
      "Middle Name must be characters only and not allowed special characters except whitespace.",
    // label: "Middle Name",
    pattern: "^[a-zA-Z ]{0,64}$",
    required: mandatory.includes("middleName"),

    // disabled: editable,
  };

  const lastName = {
    name: "lastName",
    type: "text",
    helperText: "",
    placeholder: "Last Name",
    defaultValue: family.lastName,
    errorMessage:
      "Last Name must be characters only and not allowed special characters except whitespace.",
    // label: "Last Name",
    pattern: "^[a-zA-Z ]{0,64}$",
    required: mandatory.includes("lastName"),

    // disabled: editable,
  };

  const dateOfBirth = {
    name: "dateOfBirth",
    type: "date",
    helperText: "",
    defaultValue: family.dateOfBirth,
    errorMessage: "Date Of Birth should not be null",
    // label: "Date Of Birth",
    pattern: "",
    required: mandatory.includes("dateOfBirth"),

    // disabled: editable,
  };

  const panNo = {
    name: "panNo",
    type: "text",
    placeholder: "Pan",
    helperText: "",
    defaultValue: family.panNo,
    errorMessage: "Pan should be 10 digits without spaces",
    // label: "Pan ID",
    pattern: "^[A-Z]{5}[0-9]{4}[A-Z]{1}$",
    required: mandatory.includes("panNo"),

    // disabled: editable,
  };

  const aadhaarNo = {
    name: "aadhaarNo",
    type: "text",
    placeholder: "Aadhaar Number",
    helperText: "",
    defaultValue: family.aadhaarNo,
    errorMessage: "Aadhaar should be 12 digits without spaces",
    // label: "Aadhar ID",
    pattern: "^[1-9][0-9]{11}$",
    required: mandatory.includes("aadhaarNo"),

    //disabled: editable,
  };

  const fathHusName = {
    name: "fathHusName",
    type: "text",
    placeholder: "Father/Husband Name",
    helperText: "",
    defaultValue: family.fathHusName,
    errorMessage:
      "Father/Husband name must be characters only and not allowed special characters except whitespace.",

    pattern: "^[a-zA-Z ]{1,64}$",
    required: mandatory.includes("fathHusName"),

    //disabled: editable,
  };

  const emailId = {
    name: "emailId",
    type: "text",
    placeholder: "Email ID",
    helperText: "",
    defaultValue: family.emailId,
    errorMessage: "Invalid emailId should be example@mail.com",
    // label: "email_id",
    pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$",

    required: mandatory.includes("emailId"),

    // disabled: editable,
  };

  const mobileNo = {
    name: "mobileNo",
    type: "text",
    placeholder: "Mobile Number",
    helperText: "",
    defaultValue: family.mobileNo,
    errorMessage: "Mobile No. should be 10 digit long",
    // label: "mobile_no",
    pattern: "^(?!s*$)[6-9][0-9]{9}$",
    required: mandatory.includes("mobileNo"),

    //disabled: editable,
  };

  const landNo = {
    name: "landNo",
    type: "text",
    placeholder: "LandLine Number",
    helperText: "",
    defaultValue: family.landNo,
    errorMessage: "Invalid Input",

    pattern: "^(?!s*$)[6-9][0-9]{9}$",
    required: mandatory.includes("landNo"),

    //disabled: editable,
  };
  //const percentvalue = family.pfNomSharePerc ? family.pfNomSharePerc : "";

  const pfNomSharePerc = {
    name: "pfNomSharePerc",
    type: "text",
    placeholder: "% PF",
    helperText: "",
    // defaultValue: percentvalue,
    required: mandatory.includes("pfNomSharePerc"),
    errorMessage: "Please choose number between 0-100,no other characters ",

    pattern: "^$|^(100|[1-9]?[0-9])$",

    // disabled: editable,
  };
  //const penShare = family.penNomSharePerc ? family.penNomSharePerc : "";
  const penNomSharePerc = {
    name: "penNomSharePerc",
    type: "text",
    placeholder: "% Pension",
    helperText: "",
    // defaultValue: penShare,
    required: mandatory.includes("penNomSharePerc"),
    errorMessage: "Please choose number between 0-100,no other characters ",

    pattern: "^$|^(100|[1-9]?[0-9])$",

    // disabled: editable,
  };

  const addressL1 = {
    name: "addressL1",
    placeholder: "Address Line1",
    helperText: "",
    defaultValue: family.addressL1,
    errorMessage:
      "Invalid characters in the address. Please use only letters, digits, dot, commas, dash, and spaces.",

    pattern: "[a-zA-Z0-9., -]+$",
    required: false,

    // disabled: editable,
  };
  const addressL2 = {
    name: "addressL2",
    placeholder: "Address Line2",
    helperText: "",
    defaultValue: family.addressL2,
    errorMessage:
      "Invalid characters in the address. Please use only letters, digits, dot, commas, dash, and spaces.",

    pattern: "^[a-zA-Z0-9., -]+$",
    required: false,

    // disabled: editable,
  };
  const village = {
    name: "village",
    type: "text",
    helperText: "",
    placeholder: "Village",
    defaultValue: family.village,
    errorMessage: "Invalid Input",
    pattern: "^[a-zA-Z0-9., -]+$",
    required: false,

    // disabled: editable,
  };
  const city = {
    name: "city",
    type: "text",
    helperText: "",
    placeholder: "City",
    defaultValue: family.city,
    errorMessage: "Invalid Input",
    pattern: "^[a-zA-Z ]{1,64}$",
    required: false,

    //disabled: editable,
  };
  const country = {
    name: "country",
    type: "text",
    helperText: "",
    placeholder: "Country",
    defaultValue: family.country,
    errorMessage: "Invalid Input",
    pattern: "^[A-Za-zs]{1,50}",
    required: false,

    // disabled: editable,
  };

  const pincode = {
    name: "pincode",
    type: "number",
    helperText: "",
    placeholder: "Pincode",
    defaultValue: family.pincode,
    errorMessage: "Invalid Pincode.",
    pattern: "^[0-9]{6}$",
    required: false,

    // disabled: editable,
  };
  const district = {
    name: "district",
    type: "text",
    helperText: "",
    placeholder: "District",
    defaultValue: family.district,
    errorMessage: "Invalid Input",
    pattern: "^[a-zA-Z0-9., -]+$",
    required: false,

    // disabled: editable,
  };
  //******************************************************** */

  // const relationId = {
  //   name: "relationId",
  //   // label: "relation",
  //   helperText: "",
  //   // disabled: readOnly,
  //   initialValue: family.relationId,
  //   // options: relationList,
  //   disabled: editable,
  // };
  const relationName = {
    name: "relationName",
    nameId: "relationId",
    placeholder: "Relation",
    // label: "relation",
    helperText: "",

    initialValue: family.relationId,
    options: relationList,
    // disabled: editable,
  };

  const onChange = (e) => {
    console.log("event on onChange", e.target);

    if (e.target.name === "pfNominee" || e.target.name === "pensionNominee") {
      if (e.target.value === "true") {
        setFamily({ ...family, [e.target.name]: true });
      }
      if (e.target.value === "false") {
        setFamily({ ...family, [e.target.name]: false });
      }
    } else {
      setFamily({ ...family, [e.target.name]: e.target.value });
    }
    if (e.target.name === "assignTo") {
      const selectedOption = assignToOption.find(
        (option) => option.id === e.target.value
      );
      if (selectedOption) {
        setSelectedOfficerName(selectedOption.title); // Set the selected officer's name in the state
        console.log("selctess", selectedOption.title);
      }
    }
    setFamily({ ...family, [e.target.name]: e.target.value });
  };

  const validate = () => {
    // validation of required field of member
    const requiredFields = [
      {
        field: "Title",
        value: memberDet.titleName ? memberDet.titleName : family.titleName,
      },
      // { field: "Title", value: family.titleId },
      { field: "First Name ", value: family.firstName },
      { field: "Aadhaar", value: family.aadhaarNo },
      // { field: "PAN", value: family.panNo },
      { field: "Address Line1", value: family.addressL1 },
      { field: "State", value: family.state },
      { field: "City", value: family.city },
      { field: "District", value: family.district },
      { field: "Pincode", value: family.pincode },
      // { field: "Religion", value: family.religionName },
      { field: "Country", value: family.country },
      { field: "Date Of Birth", value: family.dateOfBirth },
      { field: "Father/Husband Name ", value: family.fathHusName },
      { field: "Marital Status ", value: family.maritalStatus },
      // { field: "Relation with member? ", value: family.relationName },

      {
        field: "Religion",
        value: memberDet.religionName
          ? memberDet.religionName
          : family.religionName,
      },
      {
        field: "Relation",
        value: memberDet.relationName
          ? memberDet.relationName
          : family.relationName,
      },
      //  { field: "Mobile Number ", value: member.mobileNo },
      // { field: "Email ID", value: member.emailId },
      { field: "Gender", value: family.gender },
    ];
    for (const fieldData of requiredFields) {
      const { field, value } = fieldData;
      if (!value) {
        // alert("kindly fill all the required field");
        setValidationFailed(true);
        setValidationFailedMsg(`${field} is required.`);

        return false; // Return false if any required field is empty
      }
    }
    // Validation of Personal Information
    if (!formPatternValidation(family.firstName, "^[a-zA-Z ]{1,64}$")) {
      // console.log("In validate off  nameOfField", member.firstName);
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries. Please Check First Name field.`
      );
      return false;
    }
    if (!formPatternValidation(family.middleName, "^[a-zA-Z ]{0,64}$")) {
      // console.log("In validate off  middleName", member.middleName);
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries. Please Check Middle Name field.`
      );
      return false;
    }
    if (!formPatternValidation(family.lastName, "^[a-zA-Z ]{0,64}$")) {
      //console.log("In validate off  lastName");
      setValidationFailed(true);
      setValidationFailedMsg(`Invalid  Entries. Please Check lastName field.`);
      return false;
    }

    // if (!formPatternValidation(family.panNo, "^[A-Z]{5}[0-9]{4}[A-Z]{1}$")) {

    //   setValidationFailed(true);
    //   setValidationFailedMsg(`Invalid  Entries. Please Check Pan No. field.`);
    //   return false;
    // }
    if (family.panNo) {
      if (!formPatternValidation(family.panNo, "^[A-Z]{5}[0-9]{4}[A-Z]{1}$")) {
        setValidationFailed(true);
        setValidationFailedMsg(
          `Invalid Entries. Please Check PAN Number field.`
        );
        return false;
      }
    }

    if (!formPatternValidation(family.aadhaarNo, "^[1-9][0-9]{11}$")) {
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries. Please Check Aadhaar Number field.`
      );
      return false;
    }

    if (!formPatternValidation(family.fathHusName, "^[a-zA-Z ]{1,64}$")) {
      // console.log("In validate off  fathHusName");
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries. Please Check Name of Father/Husband fields.`
      );
      return false;
    }

    if (family.emailId) {
      if (
        !formPatternValidation(
          family.emailId,
          "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
        )
      ) {
        setValidationFailed(true);
        setValidationFailedMsg(
          `Invalid Entries. Please Check PAN Number field.`
        );
        return false;
      }
    }
    // if (
    //   !formPatternValidation(
    //     member.emailId,
    //     "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
    //   )
    // ) {
    //   // console.log("In validate off  emailId");
    //   // setValidationFailed(true);
    //   // setValidationFailedMsg(`Invalid  Entries. Please Check Email fields.`);
    //   return false;
    // }
    //  if (!formPatternValidation(family.nameOfField, "regax pattern")) {
    //    console.log("In validate off  nameOfField");
    //    return false;
    //  }

    if (family.mobileNo) {
      if (!formPatternValidation(family.mobileNo, "^(?!s*$)[6-9][0-9]{9}$")) {
        setValidationFailed(true);
        setValidationFailedMsg(
          `Invalid Entries. Please Check Mobile Number field.`
        );
        return false;
      }
    }

    // if (!formPatternValidation(family.landNo, "^d{12}$|^s*$")) {
    //   console.log("In validate off  landNo", member.landNo);
    // setValidationFailed(true);
    // setValidationFailedMsg(`Invalid  Entries. Please Check all the fields.`);
    //   return false;
    // }

    if (family.landNo) {
      if (!formPatternValidation(family.landNo, "^(?!s*$)[6-9][0-9]{9}$")) {
        setValidationFailed(true);
        setValidationFailedMsg(
          `Invalid Entries. Please Check Landline Number field.`
        );
        return false;
      }
    }

    if (!formPatternValidation(family.addressL1, "^[a-zA-Z0-9., -]+$")) {
      //console.log("In validate off  addressL1");
      setValidationFailed(true);
      setValidationFailedMsg(`Invalid  Entries. Please check addressL1 field.`);
      return false;
    }
    // if (!formPatternValidation(family.addressL2, "^[a-zA-Z0-9., -]+$")) {
    //   setValidationFailed(true);
    //   setValidationFailedMsg(`Invalid  Entries. Please check addressL2 field.`);
    //   return false;
    // }

    if (family.addressL2) {
      if (!formPatternValidation(family.addressL2, "^[a-zA-Z0-9., -]+$")) {
        setValidationFailed(true);
        setValidationFailedMsg(
          `Invalid Entries. Please Check Address Line2 field.`
        );
        return false;
      }
    }

    if (!formPatternValidation(family.city, "^[a-zA-Z ]{1,64}$")) {
      setValidationFailed(true);
      setValidationFailedMsg(`Invalid  Entries. Please check city field.`);
      return false;
    }
    if (!formPatternValidation(family.pincode, "^[0-9]{6}$")) {
      setValidationFailed(true);
      setValidationFailedMsg(`Invalid  Entries. Please check pincode field.`);
      return false;
    }
    if (!formPatternValidation(family.country, "^[A-Za-zs]{1,50}$")) {
      setValidationFailed(true);
      setValidationFailedMsg(`Invalid  Entries. Please check country field.`);
      return false;
    }
    // if (!formPatternValidation(family.village, "^[a-zA-Z0-9., -]+$")) {
    //   setValidationFailed(true);
    //   setValidationFailedMsg(`Invalid  Entries. Please check village field.`);
    //   return false;
    // }

    if (family.village) {
      if (!formPatternValidation(family.village, "^[a-zA-Z0-9., -]+$")) {
        setValidationFailed(true);
        setValidationFailedMsg(`Invalid Entries. Please Check Village field.`);
        return false;
      }
    }
    if (!formPatternValidation(family.district, "^[a-zA-Z0-9., -]+$")) {
      setValidationFailed(true);
      setValidationFailedMsg(`Invalid  Entries. Please check district field.`);
      return false;
    }

    if (
      !formPatternValidation(family.pfNomSharePerc, "^$|^(100|[1-9]?[0-9])$")
    ) {
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries. Please check Pf Nominee field.`
      );
      return false;
    }
    if (
      !formPatternValidation(family.penNomSharePerc, "^$|^(100|[1-9]?[0-9])$")
    ) {
      setValidationFailed(true);
      setValidationFailedMsg(
        `Invalid  Entries. Please check Pf Nominee field.`
      );
      return false;
    } else return true;
  };

  //const navigate = useNavigate(); //inilitialize the navigate function
  const handleGoBack = () => {
    navigate(-1); //navigate back to the previous page

    //window.scrollTo(0, 0); // scroll to the top of the page
  };

  const fetchTitleList = async () => {
    const url = process.env.REACT_APP_SERVER_URL + "/get/all/title";

    axios.get(url).then((response) => {
      const titleListSamples = response.data;
      console.log("response in ccCodeList", response);
      const options = titleListSamples.map((option) => ({
        id: option.titleId,
        title: option.titleName,
      }));
      console.log("ccHeadList", options);
      setTitleList(options);
    });
  };
  const fetchReligionList = async () => {
    const url = process.env.REACT_APP_SERVER_URL + "/get/all/religion";
    axios.get(url).then((response) => {
      const religionListSamples = response.data;
      console.log("response in ccCodeList", response);
      const options = religionListSamples.map((option) => ({
        id: option.religionId,
        title: option.religionName,
      }));
      console.log("ccHeadList", options);
      setReligionList(options);
    });
  };
  const fetchStates = async () => {
    try {
      console.log("in fetch states");
      const statesListSamples = await getStates();
      console.log("statesListSamples", statesListSamples);

      const options = statesListSamples.map((option) => {
        //const firstID = option.match(/[0-9]/) ? "0-9" : option.charAt(0); // Get the first letter
        // console.log("option", option);
        const firstID = option.stateName;
        // console.log("firstId", firstID);

        return {
          firstID: /[0-9]/.test(firstID.charAt(0)) ? "0-9" : firstID.charAt(0),
          stateName: option.stateName.toUpperCase(),
          value: option.stateId,
        };
      });

      setStateLoading(false);
      setStateList(options);
      //console.log("unit options", options);
    } catch (error) {
      // Handle error if needed.
    }
  };
  const getStates = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + "/get/all/states";
      const response = await axios.get(url);
      console.log("states", response);
      return response.data;
    } catch (error) {
      console.log("error - states", error);
      return [];
    }
  };

  const fetchRelationList = async () => {
    const url = process.env.REACT_APP_SERVER_URL + "/get/all/relations";

    axios.get(url).then((response) => {
      const titleListSamples = response.data;
      console.log("response in ccCodeList", response);
      const options = titleListSamples.map((option) => ({
        id: option.relationId,
        title: option.relationName,
      }));
      console.log("ccHeadList", options);
      setRelationList(options);
    });
  };

  const handleValidationFailedAlert = () => {
    setValidationFailed(false);
    setIsLoading(false);
  };

  const onHandleChange = (e) => {
    const { name, value, id, nameId } = e;
    console.log("jay", e);
    setFamily((prevState) => ({ ...prevState, [nameId]: id }));
    setMemberDet((prevState) => ({ ...prevState, [name]: value }));
    // if (nameId === "unitId") {
    //   fetchccname(value);
    // }
  };
  console.log("family", family);
  console.log("familyy", memberDet);

  return (
    <>
      <ErrorAlert
        handleOk={handleValidationFailedAlert}
        open={validationFailed}
        title="Error!"
        body={validationFailedMsg}
        buttonName="OK"
      />
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error!"
        body={errorMessage}
        buttonName="Ok"
      />
      <SucessAlert
        handleOk={handleSuccess}
        open={isSuccess}
        title="Success"
        body={successMessage}
        buttonName="OK"
      />
      <ConfirmationDialogue
        handleOk={handleAssignToOk}
        handleCancel={handleAssignToCancel}
        open={isSubmitClicked}
        title="Confirm Assignment"
        body={`Are you sure you want to forward Add Family request to ${selectedOfficerName}?`}
        buttonName="Confirm"
      />
      <Grid container justifyContent="center" sx={{ wordWrap: "break-word" }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            {/* {!isProgramLoaded  ( */}

            <>
              {/*Button with back functionality */}
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    align="left"
                    onClick={handleGoBack}
                    // sx={{ backgroundColor: "darkviolet" }}
                  >
                    Back
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: 2 }}>
                <TitleText title="Add Family Member" />
              </Grid>

              <FormDivider />

              {/* <Form> */}
              <Grid
                container
                justifyContent="space-between"
                alignItems="start"
                rowGap={2}>
                {/* section 1  */}
                <Paper
                  elevation={3}
                  sx={{ padding: "3rem", borderRadius: "8px" }}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="start"
                    rowGap={2}>
                    <Grid item xs={12} sm={12}>
                      <SubtitleText title="Personal Information" />
                    </Grid>

                    {/* column 1  */}

                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        columnGap={1}>
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Title" required={true} />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomDropdownWithValue
                            {...titleName}
                            onchange={onHandleChange}
                          />
                        </Grid>

                        {/* row  */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="First Name" required={true} />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...firstName}
                            onchange={onChange}
                            defaultValue={family.firstName}
                          />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Middle Name" />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...middleName}
                            onchange={onChange}
                          />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Last Name" />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField {...lastName} onchange={onChange} />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Date of Birth" required={true} />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...dateOfBirth}
                            onchange={onChange}
                          />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={3}>
                          <LabelText
                            title="Relation With Member"
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomDropdownWithValue
                            {...relationName}
                            onchange={onHandleChange}
                          />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Pan" />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField {...panNo} onchange={onChange} />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Aadhaar Number" required={true} />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField {...aadhaarNo} onchange={onChange} />
                        </Grid>
                        {/* row */}
                        <Grid item xs={12} sm={3}>
                          <LabelText title="Address Line1" required={true} />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextArea {...addressL1} onchange={onChange} />
                        </Grid>
                        {/* row  */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="City" required={true} />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField {...city} onchange={onChange} />
                        </Grid>
                        {/* row  */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Pincode" required={true} />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField {...pincode} onchange={onChange} />
                        </Grid>
                        {/* row  */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Country" required={true} />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField {...country} onchange={onChange} />
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* column 2  */}

                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        columnGap={1}>
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Religion" required={true} />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomDropdownWithValue
                            {...religionName}
                            onchange={onHandleChange}
                          />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Gender" required={true} />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <GroupedRadioButton {...gender} onchange={onChange} />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={3}>
                          <LabelText
                            title="Father/Husband Name"
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...fathHusName}
                            onchange={onChange}
                          />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Email ID" />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField {...emailId} onchange={onChange} />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Mobile Number" />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField {...mobileNo} onchange={onChange} />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Landline Number" />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField {...landNo} onchange={onChange} />
                        </Grid>
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Marital Status" required={true} />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomDropdown
                            {...maritalStatus}
                            onchange={onChange}
                          />
                        </Grid>
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Address Line2" />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextArea {...addressL2} onchange={onChange} />
                        </Grid>
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Village" />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextArea {...village} onchange={onChange} />
                        </Grid>
                        {/* row  */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="District" required={true} />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField {...district} onchange={onChange} />
                        </Grid>
                        {/* row  */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="State" required={true} />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <Autocomplete
                            sx={{
                              minWidth: "150px",
                            }}
                            ListboxProps={{
                              style: {
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                maxHeight: 180, // Change this to your desired height
                              },
                            }}
                            // freeSolo
                            // inputValue={cc.state}
                            value={selectedState}
                            // disabled={editable}
                            disableClearable
                            onChange={(e, newValue) => {
                              setSelectedState(newValue);
                              handleStateSearch(newValue, "state");
                            }}
                            options={stateList.sort(
                              (a, b) => -b.firstID.localeCompare(a.firstID)
                            )}
                            groupBy={(stateList) => stateList.firstID}
                            getOptionLabel={(stateList) => stateList.stateName}
                            loading={stateLoading} // Set loading prop
                            renderInput={(params) => (
                              <TextField
                                name="state"
                                sx={{
                                  "& .MuiFormHelperText-root": {
                                    marginLeft: 0,
                                    // fix based on your screen
                                    fontSize: {
                                      xs: "9px",
                                      sm: "9px,",
                                      md: "9px",
                                      lg: "12px",
                                      xl: "14px",
                                    },
                                    fontWeight: "400",
                                    color: "black",
                                  },
                                  "& .MuiInputBase-input": {
                                    minWidth: "80px",
                                    borderRadius: "5px",
                                    color: "black",
                                    background: "#ffffff",
                                    // fix based on your screen
                                    fontSize: {
                                      xs: 12,
                                      sm: 12,
                                      md: 13,
                                      lg: 14,
                                      xl: 16,
                                    },
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    borderRadius: "5px",
                                    "& fieldset": {
                                      borderColor: "#474E68",
                                      // borderRadius:'0px'
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#6B728E",
                                      // borderRadius: '0px'
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#0F3460",
                                      borderWidth: "0.5px",
                                      // borderRadius: '0px'
                                    },
                                  },
                                }}
                                autoComplete="off"
                                // size='small'

                                {...params}
                                placeholder="Enter State"
                                // label="Unit No."

                                fullWidth
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {stateLoading && (
                                        <CircularProgress
                                          color="inherit"
                                          size={20}
                                        />
                                      )}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                                // helperText={
                                //   <div style={{ height: "18px" }}></div>
                                // }
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* section 2  */}
                    <Grid item xs={12} sm={12}>
                      <SubtitleText title="Claim Eligibility Details" />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}>
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="PF Nominee" />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <GroupedRadioButton
                            {...pfNominee}
                            onchange={onChange}
                          />
                        </Grid>

                        {/* row */}
                        {/* {family.pfNominee === "yes" && ( */}
                        <>
                          <Grid item xs={12} sm={4}>
                            <LabelText title="% PF Share" />
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <CustomTextField
                              {...pfNomSharePerc}
                              onchange={onChange}
                            />
                          </Grid>
                        </>
                        {/* )} */}
                        {/* {family.pfNominee === "No" && ( */}
                        {/* <>
                              <Grid item xs={12} sm={4}>
                                <LabelText title="% PF Share" />
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <CustomTextField
                                  {...pfNomSharePerc}
                                  onchange={onChange}
                                />
                              </Grid>
                            </> */}
                        {/* )} */}

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Pension Nominee" />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <GroupedRadioButton
                            {...pensionNominee}
                            onchange={onChange}
                          />
                        </Grid>

                        {/* row */}

                        <>
                          <Grid item xs={12} sm={4}>
                            <LabelText title="% Pension Share" />
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <CustomTextField
                              {...penNomSharePerc}
                              onchange={onChange}
                            />
                          </Grid>
                        </>

                        {/* submit button */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>

                {/* ************** */}
                {/* section 2 */}
                <Grid item xs={12} sm={6} md={12}>
                  <Paper
                    elevation={3}
                    sx={{ padding: "3rem", borderRadius: "8px" }}>
                    <Grid item xs={12}>
                      <SubtitleText title="Upload Documents" />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <UploadDocument
                        documentTypes={documentTypes}
                        onSave={handleSave}
                      />
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item md={12} xs={12}>
                  <Button
                    onClick={handleProcessOk}
                    variant="contained"
                    color="success"
                    align="right"
                    // sx={{ marginTop: 3, marginLeft: "50%" }}
                  >
                    SUBMIT
                  </Button>

                  {showingDropdown === 1 && (
                    <Grid
                      container
                      justifyContent="end"
                      alignItems={"center"}
                      spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <CustomDropdown {...assignTo} onchange={onChange} />
                      </Grid>
                      <Grid item md={1}>
                        <Button
                          onClick={handleSubmitOk}
                          variant="contained"
                          color="success"
                          type="submit"
                          disabled={isSubmitting}
                          fullWidth>
                          {isSubmitting ? <CircularProgress /> : "OK"}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </>
            {/* )}
             )} */}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default AddFamMemForm;
