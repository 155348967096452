import { Container, Paper } from '@mui/material';
import React from 'react';


import Overview from './Content/Titles/Overview';
import GettingStarted from './Content/Titles/GettingStarted';
import AppendixA from './Content/Titles/AppendixA';
import MemberDashboard from './Content/Titles/MemberDashboard';
import SystemOverview from './Content/Titles/OverviewPages/SystemOverview';
import Purpose from './Content/Titles/OverviewPages/Purpose';
import Contact from './Content/Titles/OverviewPages/Contact';

const AllSectionsContent = () => {
 
  return (
    <>
    
    {/* <Paper > */}
   
        <Container >
        <Overview />
        <GettingStarted />
        <MemberDashboard />
        <AppendixA />

        {/* <SystemOverview />
        <Purpose />
        <Contact /> */}


 




      </Container>
     
      {/* </Paper> */}
    </>
  );
};

export default AllSectionsContent;
