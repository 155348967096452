import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { AiOutlineEye } from "react-icons/ai"; // Corrected import statement
import SubtitleText from "../FormComponent/SubtitleText";

const ViewDocuments = (props) => {
  const pageSize = 50;
  const { documents } = props;
  const [visibleDocuments, setVisibleDocuments] = useState([]);
  // let claimId = "PF1000001";
  // const [isLoading, setIsLoading] = useState(false);
  // const [isFailed, setIsFailed] = useState(false); // Corrected syntax for useState declaration

  useEffect(() => {
    if (documents.length > 0) {
      // Add a serial number to each document for display
      const documentsWithSerial = documents.map((item, index) => ({
        ...item,
        serial: index + 1,
        clicked: false, // Added a default value for the "clicked" property
        selectedDocument: null, // Added a default value for the "selectedDocument" property
      }));
      setVisibleDocuments(documentsWithSerial.slice(0, pageSize));
    }
  }, [documents, pageSize]);

  const handleScroll = (e) => {
    // Improve the logic to load more when scrolling to the bottom
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      const startIndex = visibleDocuments.length;
      const newVisibleDocuments = documents.slice(
        startIndex,
        startIndex + pageSize
      );
      setVisibleDocuments((prev) => [...prev, ...newVisibleDocuments]);
    }
  };

  const handleEyeClick = (index) => {
    const clickedDocument = visibleDocuments[index];

    if (!clickedDocument.clicked && !clickedDocument.selectedDocument) {
      // Assuming the document contains a property called "documentUrl"
      let documentUrl = atob(clickedDocument.uploadedDoc); // Decode the base64 encoded document URL
      if (documentUrl) {
        // let base64Data = documentUrl.split(",")[1];
        // Logic for determining document type and extracting base64 content
        let type = "pdf"; // Default type set to PDF
        if (documentUrl.includes("data:image/jpeg;base64")) {
          type = "jpeg";
        } else if (documentUrl.includes("data:image/png;base64")) {
          type = "png";
        }
        let base;
        if (type === "pdf") {
          base = documentUrl.replace(
            "data:application/pdf;base64,JVBERi0xLjQKJdPr6eEKMSAwIG9iago8PC9",
            "data:application/pdf;base64,"
          );
        } else {
          base = documentUrl.replace(
            `data:image/${type};base64,JVBERi0xLjQKJdPr6eEKMSAwIG9iago8PC9`,
            `data:image/${type};base64,`
          );
        }

        // Update the state with the selected document content
        setVisibleDocuments((prevVisible) =>
          prevVisible.map((doc, idx) =>
            idx === index
              ? { ...doc, clicked: true, selectedDocument: base }
              : doc
          )
        );
      } else {
        // Handle the case where the document URL is not available
        console.log("Document URL is not available");
      }
    } else {
      // Toggle the clicked state to expand/collapse the document view
      const updatedVisibleDocuments = [...visibleDocuments];
      updatedVisibleDocuments[index].clicked = !clickedDocument.clicked;
      setVisibleDocuments(updatedVisibleDocuments);
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <>
          {/* ************************************** */}
          {/* section 1  */}
          <Grid
            container
            justifyContent="space-between"
            alignItems="start"
            rowGap={3}
          >
            <Grid item xs={12} sm={12}>
              <SubtitleText title="Documents" />
            </Grid>
            <Box
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                height: "50%",
                overflowY: "auto",
              }}
              onScroll={handleScroll}
            >
              <TableContainer component={Paper}>
                <Table aria-label="virtualized table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontSize: 15 }}>
                        Serial number
                      </TableCell>
                      <TableCell style={{ fontSize: 15 }}>
                        Document Type
                      </TableCell>
                      <TableCell style={{ fontSize: 15 }}>View</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {visibleDocuments.map((row, index) => (
                      <TableRow key={row.docTypeDesc}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ fontSize: 15 }}
                        >
                          {row.serial}
                        </TableCell>
                        <TableCell style={{ fontSize: 15 }}>
                          {row.docTypeDesc}
                        </TableCell>
                        <TableCell style={{ fontSize: 15 }}>
                          <AiOutlineEye
                            style={{
                              fontSize: 25,
                              color: row.clicked ? "red" : "black",
                              cursor: "pointer",
                            }}
                            onClick={() => handleEyeClick(index)}
                          />
                          {row.clicked &&
                            row.selectedDocument &&
                            (row.selectedDocument.includes("pdf") ? (
                              <iframe
                                title="PDF Viewer"
                                src={row.selectedDocument}
                                width="200%"
                                height="500"
                              />
                            ) : (
                              <img
                                src={row.selectedDocument}
                                alt="Document"
                                style={{ width: "100%" }}
                              />
                            ))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </>
      </Grid>
    </Grid>
  );
};

export default ViewDocuments;
