import { Grid, Paper, Typography } from "@mui/material";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import TableWithLinkAndButton from "../../FormComponent/TableWithLinkAndButton";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import SuccessAlert from "../../FormComponent/SuccessAlert";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Button } from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import LoadingIndicator from "../../FormComponent/LoadingIndicator";
import Tooltip from "@mui/material/Tooltip";
import { useLocation, useNavigate } from "react-router-dom";
// import PrintButton from "./PrintButton";
// import PrintableGrievanceForm from ".GrievancePages/PrintButtonTwo";
import PrintableGrievanceForm from "./PrintButtonTwo";
import { getToken } from "../../UiManager/SessionChecker";
import jwtDecode from "jwt-decode";
function GrievanceTable() {
  const navigate = useNavigate();
  const [isSubmitSucess, setIsSubmmitSucess] = useState(false);
  const defaultTableData = [
    {
      grievanceId: "",
      submitDate: "",
      grievanceStatusDesc: "",
    },
  ];
  const location = useLocation();
  const [tableData, setTableData] = useState(defaultTableData);
  // const [tableData, setTableData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  //declaration man le
  // const tableSamples = [
  //   {
  //     grievanceId: 1,
  //     submitDate: "26/07/2001",
  //     grievanceStatusDesc: "pending",
  //   },
  // ];

  //creting new data
  // const [createTablePreviewData, setcreateTablePreviewData] = useState([]);

  //handle delete row start from here

  //handle delete row ends here

  const createTablePreviewData = () => {
    let tablePreviewData = [];
    // const previewData = tableSamples;
    const previewData = tableData;
    if (tableData) {
      tableData.forEach((grievance) => {
        tablePreviewData.push({
          "Grievance ID": grievance.grievanceId,
          "Submitted On": grievance.submitDate,
          Status: grievance.grievanceStatusDesc,
        });
      });
    } else {
      tablePreviewData = [];
    }
    // console.log("tablePreviewData", tablePreviewData);
    return tablePreviewData;
  };

  //     fetchTableData();
  //   }, []);

  // const handlePrint = () => {
  //   window.print();
  // };

  const tableActions = {
    //yha grievanceStatusDesc wali condition chahiye
    edit: {
      action: "edit",
      icon: <EditRoundedIcon fontSize="small" sx={{ margin: "0px 5px" }} />,
    },
    print: {
      // action: (id) => handlePrintDocument(id),
      action: "print",
      icon: <PrintRoundedIcon fontSize="small" sx={{ margin: "0px 5px" }} />,
      // onClick : () => setIsSubmmitSucess(true),
      // onclick : () => handlePrint,
      // onClick: () => handlePrint(tableData.grievanceId),
      //onClick: setIsSubmmitSucess(true) // Assuming handlePrintClick is the function to handle the print action
    },
    // revoke: {
    //   action: "revoke",
    //   icon: (
    //     <CloseRoundedIcon
    //       fontSize="small"
    //       sx={{ color: "red", margin: "0px 5px" }}

    //     />
    //   ),

    // },
    revoke: {
      action: "revoke",
      icon: (
        <CloseRoundedIcon
          fontSize="small"
          sx={{ color: "red", margin: "0px 5px" }}
        />
      ),
    },
    //onClick: (grievanceId) => handleRevokeGrievance(grievanceId) // Define the function to handle the revoke action
    // Add more actions as needed
  };

  //to fetch the id,submitted on,status start from here

  const fetchTableData = async () => {
    try {
      const formData = new FormData();
      // uncomment this to enable session
      let token = getToken();
      // console.log("token on greviance", token);
      if (!token) {
        setIsLoading(false);
        //setIsFailed(true);
        return;
      }
      token = token.replace('"', "").replace('"', "");
      const cmpfAccNo = jwtDecode(token).user;
      // console.log("cmpfAccNo", cmpfAccNo);
      //  const cmpfAccNo = "DGR/5/2061";
      formData.append("searchFieldValue", cmpfAccNo);
      const url = process.env.REACT_APP_SERVER_URL + "/member/grievences";
      // console.log("url", url);
      const response = await axios.post(url, formData);
      // console.log("response", response.data.data);
      if (response.status === 200) {
        setTableData(response.data.data); // Set the fetched data to the tablePreview state
      } else {
        console.error("Failed to fetch claims. Status: " + response.status);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching table data: ", error);
    }
  };

  useEffect(() => {
    fetchTableData();
  }, [location.pathname]);
  const handleAddGrievanceClick = () => {
    // Redirect to the desired page
    navigate("raisenewgrievance");
  };

  //to fetch the id,submitted on,status ends  here
  const handleSubmitSucess = () => {
    setIsSubmmitSucess(false);
    navigate("/grievancetable");
  };

  // console.log("tableData", tableData);
  return (
    <div>
      {/* {!isLoading && tableData ? ( */}
      {/* <> */}
      {/* code for sucess Alert */}
      <SuccessAlert
        handleOk={handleSubmitSucess}
        open={isSubmitSucess}
        title="Success"
        body={<PrintableGrievanceForm />}
        buttonName="Ok"
      />

      {/* code for sucess Alert Ends here  */}
      <Grid container alignItems={"center"}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            {/* <Grid item xs={12}></Grid> */}
            <Grid container spacing={2} justifyContent="flex-end" marginBottom={"-2%"}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                // marginRight={"20%"}
                // marginTop={"1%"}
              >
                <TitleText title="Grievance Details" />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={4} mr={-5}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddGrievanceClick}
                >
                  Raise New Grievance
                </Button>
              </Grid> */}
            </Grid>
            <FormDivider />
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              marginTop={"-3%"}
              marginBottom={"-1%"}
            >
              <Grid item xs={12} sm={6} md={4} sx={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddGrievanceClick}
                >
                  Raise New Grievance
                </Button>
              </Grid>
            </Grid>

            <Grid container justifyContent={"center"}>
              <Grid item xs={12} mt={3}>
                <div
                // style={{
                //   display: "flex",
                //   justifyContent: "space-between",
                //   marginTop: "1rem",
                // }}
                >
                  {isLoading ? (
                    <LoadingIndicator />
                  ) : (
                    <TableWithLinkAndButton
                      preview={createTablePreviewData()}
                      data={tableData}
                      passingId="id"
                      actions={tableActions}
                      viewLink="grievancedetails"
                      size="small"
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* </> */}
      {/* )  */}
      {/* : (
         <p>Loading...</p>
       )} */}
    </div>
  );
}
export default GrievanceTable;
