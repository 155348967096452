import React, { useRef, useEffect, useState, useContext } from "react";
import {
  CaptchaProvider,
  captchaVerifier,
  CaptchaVerifyContext,
} from "./CaptchaProvider";
import { Box, Grid, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

function genetateCaptcha(max) {
  const excludedLetters = ["0", "O", "o", "l", "i", "I"];
  const alphanumeric =
    "123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghjkmnpqqrstuvwxyz";
  let text = "";
  let i;
  for (i = 0; i < max; i += 1) {
    let char;
    do {
      char = alphanumeric.charAt(getRandomInt(0, alphanumeric.length));
    } while (excludedLetters.includes(char));
    text += char;
  }
  return text;
}

const CaptchaBox = (props) => {
  //eslint-disable-next-line
  const [canvaState, setToken] = useState(null);

  const canvasRef = useRef(null);
  const setResetCaptchaContext = useContext(CaptchaVerifyContext);

  const captchaContext = useContext(captchaVerifier());

  const handleReset = () => {
    // e.preventDefault();
    console.log("reseting captcha");
    drawCaptcha(5);
    setToken(canvasRef.current.value);
  };

  useEffect(() => {
    setResetCaptchaContext.setResetCde(handleReset);
  }, []);

  //eslint-disable-next-line

  function drawCaptcha(length) {
    const code = genetateCaptcha(length);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "#16213E";
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.font = `bold oblique ${getRandomInt(13, 18)}px MonoFont`;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.lineWidth = 1;
    ctx.fillText(code, ctx.canvas.width / 2, ctx.canvas.height / 2 + 3);
    ctx.strokeStyle = "black";

    ctx.beginPath();
    // ctx.moveTo(getRandomInt(5, 20), getRandomInt(5, 20))
    // ctx.lineTo(ctx.canvas.width - getRandomInt(5, 10), ctx.canvas.height - getRandomInt(5, 10))
    // ctx.stroke()
    ctx.moveTo(getRandomInt(15, 30), getRandomInt(15, 30));
    ctx.lineTo(
      ctx.canvas.width - getRandomInt(15, 30),
      ctx.canvas.height - getRandomInt(15, 30)
    );
    ctx.stroke();
    ctx.closePath();
    canvasRef.current.value = code;
    captchaContext.token.current = canvasRef.current;
  }

  useEffect(() => {
    drawCaptcha(5);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
          <canvas
            ref={canvasRef}
            {...props}
            style={{
              border: "1px solid #C4DFDF",
              padding:'0px',
              backgroundColor: "rgb(255, 255, 255)",
              width: "120px", // Increase the width
              height: "40px", // Increase the height
              // display: "flex",
              // justifyContent: "start",
              // alignItems: "center",
            }}
          />
          <IconButton onClick={handleReset}>
            <FontAwesomeIcon
              icon={faArrowsRotate}
              size="sm"
              style={{ color: "#616161" }}
            />
          </IconButton>
    </Box>
  );
};

export default CaptchaBox;
