import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

function LoadingIndicator() {
  return (
   
      <Box
          sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '80vh',
          }}
      >
          <CircularProgress />

      </Box>

  );
}

export default LoadingIndicator;
