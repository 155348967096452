import {
  Box,
  Typography,
  List,
  ListItem,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const index = 0;

const Pfo = () => {
  const { state } = useLocation();
  const data = state;
  console.log("data", data);
  return (
    <Box mt={8}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: "65.6%",
          // marginRight: "60px",
          marginBottom: "20px",
        }}>
        <Typography
          variant="body2"
          gutterBottom
          fontWeight="bolder"
          fontFamily="monospace">
          Date of Settlement: {data.dateOfClaim}
        </Typography>
      </Box>

      {/* Annexure  */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: "76.6%",
          // marginRight: "60px",
          marginTop: "-2%",
          marginBottom: "50px",
        }}>
        <Typography
          variant="body2"
          gutterBottom
          fontWeight="bolder"
          fontFamily="monospace">
          Annexure - V
        </Typography>
      </Box>
      {/* Annexure  */}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: "76.6%",
          // marginRight: "60px",
          marginTop: "-4.3%",
          marginBottom: "1%",
        }}>
        <Typography
          variant="body2"
          gutterBottom
          fontWeight="bolder"
          fontFamily="monospace">
          PEN SET - 1
        </Typography>
      </Box>

      <Typography variant="h6" gutterBottom>
        <span style={{ textDecoration: "underline" }}>
          Coal Mines Provident Fund Organization
        </span>
      </Typography>

      <Typography variant="h6" gutterBottom>
        <span
          style={{
            textDecoration: "underline",
            fontFamily: "monospace",
            fontWeight: "bold",
          }}>
          {`Office of the Coal Mines Provident Fund, ${data.regionalOfficeName}`}
        </span>
      </Typography>

      <Typography variant="body2" paragraph>
        (Form for sanctioning the Payment under paragraph{" "}
        <span style={{ fontWeight: "bold" }}>{data.settlementParaNumber}</span>{" "}
        of the Coal Mines Pension Scheme ,1998 )
      </Typography>

      <Grid
        container
        rowSpacing={1}
        // columnSpacing={{ xs: 1, sm: 2, md: 2 }}
        style={{ paddingLeft: 100 }}>
        <Grid item lg={12}>
          <Box justifyContent={"start"}>
            <Typography
              variant="body1"
              className="labelForPdf"
              justifyContent="center"
              marginLeft="-8%"
              marginBottom="4%">
              <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
                PART - A
              </span>
            </Typography>
          </Box>

          <Grid item xs={12} lg={12}>
            <List dense sx={{ listStyle: "decimal", pl: 4 }}>
              <ListItem sx={{ display: "list-item" }}>
                <Grid container display="flex" justifyContent="space-between">
                  <Grid item xs={6} lg={6}>
                    <Typography variant="body2" className="labelForPdf">
                      Member's Name
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <Typography
                      variant="body2"
                      fontWeight="500"
                      className="valueForPdf"
                      justifyContent={"left"}>
                      {" "}
                      :&nbsp;&nbsp;{data.fullName}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem
                sx={{ display: "list-item" }}
                justifyContent="space-between">
                <Grid
                  container
                  item
                  lg={12}
                  xs={12}
                  justifyContent="space-between">
                  <Grid item lg={6} xs={6}>
                    <Typography variant="body2" className="labelForPdf">
                      Father/Husband Name
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <Typography
                      variant="body2"
                      fontWeight="500"
                      className="valueForPdf">
                      {" "}
                      :&nbsp;&nbsp;{data.fatherHusbandName}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>

              {/* <ListItem
                sx={{ display: "list-item" }}
                justifyContent="space-between"
              >
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="body2" className="labelForPdf">
                      Old CMPF A/C No.
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <Typography
                      variant="body2"
                      fontWeight="500"
                      className="valueForPdf"
                    >
                      {" "}
                      :&nbsp;&nbsp;{data.cmpfAccNo}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem> */}

              {/* <ListItem
                sx={{ display: "list-item" }}
                justifyContent="space-between"
              >
                <Grid container justifyContent="space-between">
                  <Grid item lg={6} xs={6}>
                    <Typography variant="body2" className="labelForPdf">
                      CSS No.
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <Typography
                      variant="body2"
                      fontWeight="500"
                      className="valueForPdf"
                    >
                      {" "}
                      :&nbsp;&nbsp;{data.cssno}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem> */}

              <ListItem
                sx={{ display: "list-item" }}
                justifyContent="space-between">
                <Grid container justifyContent="space-between">
                  <Grid item lg={6} xs={6}>
                    <Typography variant="body2" className="labelForPdf">
                      Name of the Colliery( last employed)
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <Typography
                      variant="body2"
                      fontWeight="500"
                      className="valueForPdf">
                      {" "}
                      :&nbsp;&nbsp;{data.unitName}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem
                sx={{ display: "list-item" }}
                justifyContent="space-between">
                <Grid container justifyContent="space-between">
                  <Grid item lg={6} xs={6}>
                    <Typography variant="body2" className="labelForPdf">
                      Registration Number of the Unit
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <Typography
                      variant="body2"
                      fontWeight="500"
                      className="valueForPdf">
                      {" "}
                      :&nbsp;&nbsp;{data.unitCode}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem
                sx={{ display: "list-item" }}
                justifyContent="space-between">
                <Grid container justifyContent="space-between">
                  <Grid item lg={6} xs={6}>
                    <Typography variant="body2" className="labelForPdf">
                      CMPF Account No.
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <Typography
                      variant="body2"
                      fontWeight="500"
                      className="valueForPdf">
                      {" "}
                      :&nbsp;&nbsp;{data.cmpfAccNo}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              {/* <ListItem
                sx={{ display: "list-item" }}
                justifyContent="space-between"
              >
                <Grid container justifyContent="space-between">
                  <Grid item lg={6} xs={6}>
                    <Typography variant="body2" className="labelForPdf">
                      Registration Number of the Unit Old
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <Typography
                      variant="body2"
                      fontWeight="500"
                      className="valueForPdf"
                    >
                      {" "}
                      :&nbsp;&nbsp;{data.RgUnOld}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem> */}

              {/* Member's Address start from here*/}
              <ListItem
                sx={{ display: "list-item" }}
                justifyContent="space-between">
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="body2" className="labelForPdf">
                      Member's Address
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <Grid item lg={6} xs={6}>
                      <Typography
                        variant="body2"
                        fontWeight="500"
                        className="valueForPdf">
                        {" "}
                        :&nbsp;&nbsp;{data.postalAddress}&nbsp;&nbsp;{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
              {/* Member's Address  ends here*/}

              <ListItem
                sx={{ display: "list-item" }}
                justifyContent="space-between">
                <Grid container justifyContent="space-between">
                  <Grid item lg={6} xs={6}>
                    <Typography variant="body2" className="labelForPdf">
                      Date of Birth of Member
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <Typography
                      variant="body2"
                      fontWeight="500"
                      className="valueForPdf">
                      {" "}
                      :&nbsp;&nbsp;{data.dateOfBirth}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem
                sx={{ display: "list-item" }}
                justifyContent="space-between">
                <Grid container justifyContent="space-between">
                  <Grid item lg={6} xs={6}>
                    <Typography variant="body2" className="labelForPdf">
                      Date of Appointment
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <Typography
                      variant="body2"
                      fontWeight="500"
                      className="valueForPdf">
                      {" "}
                      :&nbsp;&nbsp;{data.dateOfAppointment}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem
                sx={{ display: "list-item" }}
                justifyContent="space-between">
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="body2" className="labelForPdf">
                      Date of Cessation
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <Typography
                      variant="body2"
                      fontWeight="500"
                      className="valueForPdf">
                      {" "}
                      :&nbsp;&nbsp;{data.dateOfCessation}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem
                sx={{ display: "list-item" }}
                justifyContent="space-between">
                <Grid container justifyContent="space-between">
                  <Grid item lg={6} xs={6}>
                    <Typography variant="body2" className="labelForPdf">
                      Cause of Cessation
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <Typography
                      variant="body2"
                      fontWeight="500"
                      className="valueForPdf">
                      {" "}
                      :&nbsp;&nbsp;{data.kindOfPension}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem
                sx={{ display: "list-item" }}
                justifyContent="space-between">
                <Grid container justifyContent="space-between">
                  <Grid item lg={6} xs={6}>
                    <Typography variant="body2" className="labelForPdf">
                      Date of Death
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <Typography
                      variant="body2"
                      fontWeight="500"
                      className="valueForPdf">
                      {" "}
                      :&nbsp;&nbsp;{data.dateOfDeath}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem
                sx={{ display: "list-item" }}
                justifyContent="space-between">
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="body2" className="labelForPdf">
                      Average emoluments (Last ten months)
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <Typography
                      variant="body2"
                      fontWeight="500"
                      className="valueForPdf">
                      {" "}
                      :&nbsp;&nbsp;{data.emoluments}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem
                sx={{ display: "list-item" }}
                justifyContent="space-between">
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="body2" className="labelForPdf">
                      Pensionable Services :
                    </Typography>
                  </Grid>
                  {/* Subcategory of  Pensionable Services : */}
                  <Grid
                    container
                    rowSpacing={1}
                    // columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                    style={{ paddingLeft: 1 }}>
                    <Grid item xs={12} lg={12}>
                      <List dense sx={{ listStyle: "lower-alpha", pl: 4 }}>
                        <ListItem sx={{ display: "list-item" }}>
                          <Grid
                            container
                            display="flex"
                            justifyContent="space-between">
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="body2"
                                className="labelForPdf">
                                2/3 of services from 01 March 1971 to 31 March
                                1989
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="body2"
                                fontWeight="500"
                                className="valueForPdf"
                                justifyContent={"left"}>
                                {" "}
                                :&nbsp;&nbsp;{data.membershipUpto.toFixed(
                                  2
                                )}{" "}
                              </Typography>
                            </Grid>
                          </Grid>
                        </ListItem>

                        <ListItem sx={{ display: "list-item" }}>
                          <Grid
                            container
                            display="flex"
                            justifyContent="space-between">
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="body2"
                                className="labelForPdf">
                                Full service from 01 April 1989 to{" "}
                                {data.dateOfCessation}
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="body2"
                                fontWeight="500"
                                className="valueForPdf"
                                justifyContent={"left"}>
                                {" "}
                                :&nbsp;&nbsp;{data.membershipFrom.toFixed(
                                  2
                                )}{" "}
                              </Typography>
                            </Grid>
                          </Grid>
                        </ListItem>
                        <div>
                          ------------------------------------------------------------------------------------------------------------------------------------------
                        </div>
                      </List>

                      <List>
                        <ListItem sx={{ display: "list-item" }}>
                          <Grid
                            container
                            display="flex"
                            justifyContent="space-between"
                            marginTop="-1.6%"
                            marginLeft="3%">
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="body2"
                                className="labelForPdf">
                                Total Pensionable Service{" "}
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="body2"
                                fontWeight="500"
                                className="valueForPdf"
                                justifyContent={"left"}>
                                {" "}
                                <span style={{ marginLeft: "-3%" }}>
                                  :&nbsp;&nbsp;
                                  {`${data.totalPensionableService}  \u00A0  \u00A0  \u00A0 (Subject to maximum 30 years)`}{" "}
                                </span>
                              </Typography>
                            </Grid>
                          </Grid>
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>

              {/* Kind Of Pension */}
              <ListItem
                sx={{ display: "list-item" }}
                justifyContent="space-between">
                <Grid container justifyContent="space-between">
                  <Grid item lg={6} xs={6}>
                    <Typography variant="body2" className="labelForPdf">
                      Kind of Pension
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <Typography
                      variant="body2"
                      fontWeight="500"
                      className="valueForPdf">
                      {" "}
                      :&nbsp;&nbsp;{data.causeOfCessation}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem
                sx={{ display: "list-item" }}
                justifyContent="space-between">
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="body2" className="labelForPdf">
                      Settlement Para
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <Typography
                      variant="body2"
                      fontWeight="500"
                      className="valueForPdf">
                      {" "}
                      :&nbsp;&nbsp;{data.settlementParaNumber}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem
                sx={{ display: "list-item" }}
                justifyContent="space-between">
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="body2" className="labelForPdf">
                      Option Under Para 15
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <Typography
                      variant="body2"
                      fontWeight="500"
                      className="valueForPdf">
                      :&nbsp;&nbsp;{"15(1) a "}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>

              {/* claimant details */}

              <ListItem
                sx={{ display: "list-item" }}
                justifyContent="space-between">
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="body2" className="labelForPdf">
                      Claimant Details
                    </Typography>
                  </Grid>
                </Grid>

                <Grid>
                  <List>
                    <ListItem sx={{ display: "list-item" }}>
                      <Grid
                        container
                        display="flex"
                        justifyContent="space-between">
                        <Grid item lg={6} xs={6}>
                          <Typography
                            variant="body2"
                            className="labelForPdf"
                            style={{ marginLeft: "6%" }}>
                            Name of Claimant{" "}
                          </Typography>
                        </Grid>
                        <Grid item lg={6} xs={6}>
                          <Typography
                            variant="body2"
                            fontWeight="500"
                            className="valueForPdf"
                            justifyContent={"left"}>
                            {" "}
                            <span style={{ marginLeft: "3.5%" }}>
                              :&nbsp;&nbsp;{data.fullName}{" "}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>

                    <ListItem sx={{ display: "list-item" }}>
                      <Grid
                        container
                        display="flex"
                        justifyContent="space-between">
                        <Grid item lg={6} xs={6}>
                          <Typography
                            variant="body2"
                            className="labelForPdf"
                            style={{ marginLeft: "6%" }}>
                            Relationship With Member{" "}
                          </Typography>
                        </Grid>
                        <Grid item lg={6} xs={6}>
                          <Typography
                            variant="body2"
                            fontWeight="500"
                            className="valueForPdf"
                            justifyContent={"left"}>
                            {" "}
                            <span style={{ marginLeft: "3.5%" }}>
                              :&nbsp;&nbsp;{data.relationShip}{" "}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>

                    <ListItem sx={{ display: "list-item" }}>
                      <Grid
                        container
                        display="flex"
                        justifyContent="space-between">
                        <Grid item lg={6} xs={6}>
                          <Typography
                            variant="body2"
                            className="labelForPdf"
                            style={{ marginLeft: "6%" }}>
                            Date of Birth{" "}
                          </Typography>
                        </Grid>
                        <Grid item lg={6} xs={6}>
                          <Typography
                            variant="body2"
                            fontWeight="500"
                            className="valueForPdf"
                            justifyContent={"left"}>
                            {" "}
                            <span style={{ marginLeft: "3.5%" }}>
                              :&nbsp;&nbsp;{data.dateOfBirth}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </List>
                </Grid>
              </ListItem>

              <ListItem
                sx={{ display: "list-item" }}
                justifyContent="space-between">
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="body2" className="labelForPdf">
                      Details of Family member as on the date of cessation
                    </Typography>
                  </Grid>

                  {/* Table yha aavegi */}
                  <TableContainer>
                    <Table style={{ borderCollapse: "collapse" }}>
                      <TableHead>
                        <TableRow>
                          {/* Headers */}
                          {[
                            "Sr. No",
                            "Name",
                            "Relationship with member",
                            "Date of Birth",
                          ].map((header, index) => (
                            <TableCell
                              key={index}
                              style={{
                                border: "1px dashed black",
                                padding: 8,
                              }}>
                              {header}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* Row 1 */}
                        <TableRow>
                          <TableCell
                            style={{ border: "1px dashed black", padding: 8 }}>
                            {index + 1}
                          </TableCell>
                          <TableCell
                            style={{ border: "1px dashed black", padding: 8 }}>
                            {data.familyMemberName}
                          </TableCell>
                          <TableCell
                            style={{ border: "1px dashed black", padding: 8 }}>
                            {data.familyMemberRelationName}
                          </TableCell>
                          <TableCell
                            style={{ border: "1px dashed black", padding: 8 }}>
                            {data.familyMemberDOB}
                          </TableCell>
                        </TableRow>

                        {/* Row 3 (Merged) */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const Pfo1 = () => {
  const { state } = useLocation();
  const data = state;
  console.log("data", data);
  return (
    <Box mt={6} ml={12}>
      <Grid item lg={12} xs={12}>
        <Box justifyContent={"start"}>
          <Typography
            variant="body1"
            className="labelForPdf"
            justifyContent="center"
            marginLeft="-7%"
            marginBottom="4%"
            marginTop="0%">
            <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
              PART - B({data.causeOfCessation})
            </span>
          </Typography>
        </Box>

        <List dense sx={{ pl: 4 }} style={{ marginTop: "-3%" }}>
          <ListItem sx={{ display: "list-item" }}>
            <Grid container display="flex" justifyContent="space-between">
              <Grid item lg={6} xs={6}>
                <Typography
                  variant="body2"
                  className="labelForPdf"
                  fontWeight="500">
                  <h6>Pension Payment Order No.</h6>
                </Typography>
              </Grid>
              <Grid item lg={6} xs={6}>
                <Typography
                  variant="body2"
                  fontWeight="500"
                  className="valueForPdf"
                  justifyContent={"left"}>
                  {" "}
                  <h6>:&nbsp;&nbsp;{data.PayNo} </h6>
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        </List>

        <List dense sx={{ listStyle: "decimal", pl: 4 }}>
          <ListItem sx={{ display: "list-item" }}>
            <Grid container justifyContent="space-between">
              <Grid item lg={6} xs={6}>
                <Typography variant="body2" className="labelForPdf">
                  Full Monthly Pension in rupees
                </Typography>
              </Grid>
              <Grid item lg={6} xs={6}>
                <Typography
                  variant="body2"
                  fontWeight="500"
                  className="valueForPdf">
                  {" "}
                  : &nbsp;&nbsp;{Math.round(data.monthlyPension)}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Grid container justifyContent="space-between">
              <Grid item lg={6} xs={6}>
                <Typography variant="body2" className="labelForPdf">
                  Reduced Pension under {data.para15}
                </Typography>
              </Grid>
              <Grid item lg={6} xs={6}>
                <Typography
                  variant="body2"
                  fontWeight="500"
                  className="valueForPdf">
                  {" "}
                  : &nbsp;&nbsp;{" "}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Grid container justifyContent="space-between">
              <Grid item lg={6} xs={6}>
                <Typography variant="body2" className="labelForPdf">
                  Monthly Pension Payable
                </Typography>
              </Grid>
              <Grid item lg={6} xs={6}>
                <Typography
                  variant="body2"
                  fontWeight="500"
                  className="valueForPdf">
                  {" "}
                  :&nbsp;&nbsp;{Math.round(data.monthlyPension)}{" "}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="body2" className="labelForPdf">
                  Date of Commencement of Pension
                </Typography>
              </Grid>
              <Grid item lg={6} xs={6}>
                <Typography
                  variant="body2"
                  fontWeight="500"
                  className="valueForPdf">
                  {" "}
                  :&nbsp;&nbsp;{data.dateOfCommencement}{" "}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Grid container justifyContent="space-between">
              <Grid item lg={6} xs={6}>
                <Typography variant="body2" className="labelForPdf">
                  Arrear Amount from {data.arrearfrom} to {data.arrearto}
                </Typography>
              </Grid>
              <Grid item lg={6} xs={6}>
                <Typography
                  variant="body2"
                  fontWeight="500"
                  className="valueForPdf"
                  textAlign={"left"}>
                  {" "}
                  :&nbsp;&nbsp;{data.arrearamount}{" "}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Grid container justifyContent="space-between">
              <Grid item lg={6} xs={6}>
                <Typography variant="body2" className="labelForPdf">
                  Recovery Amount
                </Typography>
              </Grid>
              <Grid item lg={6} xs={6}>
                <Typography
                  variant="body2"
                  fontWeight="500"
                  className="valueForPdf">
                  {" "}
                  :&nbsp;&nbsp;{data.recoveryamount}{" "}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Grid container justifyContent="space-between">
              <Grid item lg={6} xs={6}>
                <Typography variant="body2" className="labelForPdf">
                  Return of Contribution
                </Typography>
              </Grid>
              <Grid item lg={6} xs={6}>
                <Typography
                  variant="body2"
                  fontWeight="500"
                  className="valueForPdf">
                  {" "}
                  :&nbsp;&nbsp;{data.recoveryamount}{" "}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="body2" className="labelForPdf">
                  Net Arrear Payable
                </Typography>
              </Grid>
              <Grid item lg={6} xs={6}>
                <Typography
                  variant="body2"
                  fontWeight="500"
                  className="valueForPdf">
                  {" "}
                  :&nbsp;&nbsp;{data.arrearamount}{" "}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="body2" className="labelForPdf">
                  Bank / Post Office Details
                </Typography>
              </Grid>

              <TableContainer>
                <Table style={{ borderCollapse: "collapse" }}>
                  <TableHead>
                    <TableRow>
                      {/* Headers */}
                      {[
                        "Bank",
                        "Bank Key",
                        "Address",
                        "SB A/C No.",
                        "Mode of Operation",
                      ].map((header, index) => (
                        <TableCell
                          key={index}
                          style={{ border: "1px dashed black", padding: 8 }}>
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* Row 1 */}
                    <TableRow>
                      <TableCell
                        style={{ border: "1px dashed black", padding: 8 }}>
                        {data.bankName}
                      </TableCell>
                      <TableCell
                        style={{ border: "1px dashed black", padding: 8 }}>
                        {data.ifsc}
                      </TableCell>
                      <TableCell
                        style={{ border: "1px dashed black", padding: 8 }}>
                        {data.bankAddress}
                      </TableCell>
                      <TableCell
                        style={{ border: "1px dashed black", padding: 8 }}>
                        {data.acc}
                      </TableCell>
                      <TableCell
                        style={{ border: "1px dashed black", padding: 8 }}>
                        {data.modeOfRemittance}
                      </TableCell>
                    </TableRow>

                    {/* Row 3 (Merged) */}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </ListItem>
        </List>
      </Grid>
      {/* </Grid>
      </Grid> */}
      <Grid item xs={8}>
        <Box justifyContent={"start"}>
          <Typography
            variant="body1"
            className="labelForPdf"
            justifyContent="center"
            //   fontFamily={"monospace"}
            //   mb={2}
            marginLeft="1%%"
            marginBottom="4%"
            marginTop="2%">
            <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
              PART - C
            </span>
          </Typography>

          <Grid
            container
            rowSpacing={1}
            // columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            style={{ paddingLeft: 100, marginTop: "-4%" }}>
            <Grid item lg={12} xs={12}>
              <List dense sx={{ listStyle: "lower-alpha", pl: 4 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <Grid container display="flex" justifyContent="space-between">
                    <Grid item lg={12}>
                      <Typography variant="body2" className="labelForPdf">
                        Arrear of Pension for a sum of Rs. {data.arrearamount}{" "}
                        and Monthly pension for sum of Rs.{" "}
                        {Math.round(data.monthlyPension)} after expiration of{" "}
                        {data.expirationMonth} is put up for approval.
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>

                <ListItem sx={{ display: "list-item" }}>
                  <Grid container display="flex" justifyContent="space-between">
                    <Grid item lg={6} xs={6}>
                      <Typography variant="body2" className="labelForPdf">
                        Return of contribution in case of {data.para}
                      </Typography>
                    </Grid>
                    <Grid item lg={6} xs={6}>
                      <Typography
                        variant="body2"
                        fontWeight="500"
                        className="valueForPdf"
                        justifyContent={"left"}>
                        {" "}
                        :&nbsp;&nbsp;{data.returnofcontincase}{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
            </Grid>{" "}
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
};
const GeneratePDFButton = () => {
  const { state } = useLocation();
  const data = state;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const AdvPORefElement = useRef(null);
  const AdvPORefElement1 = useRef(null);
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/roda/penset/viewpenclaimroda", {
      state: { passingId: data.claimId, passingloginId: data.loginId },
    });
  };

  const exportPDF = () => {
    const compressionQuality = 0.4; // adjust the quality level
    // Capture content of first HTML element
    html2canvas(AdvPORefElement.current, {
      scale: 1.9,
      allowTaint: true,
    }).then((canvas1) => {
      const imageData1 = canvas1.toDataURL("image/jpeg", compressionQuality);
      // Capture content of second HTML element
      html2canvas(AdvPORefElement1.current, {
        scale: 1.9,
        allowTaint: true,
      }).then((canvas2) => {
        const imageData2 = canvas2.toDataURL("image/jpeg", compressionQuality);

        const pdf = new jsPDF("p", "mm", "a4");
        // pdf.addImage(imageData, "JPEG", 15, 15, 180, 180);
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(
          imageData1,
          "JPEG",
          15,
          15,
          pageWidth - 30,
          pageHeight - 30
        );

        // Add a new page for the second image
        pdf.addPage();
        // Add second image to the PDF
        pdf.addImage(
          imageData2,
          "JPEG",
          15,
          15,
          pageWidth - 30,
          pageHeight - 180
        );

        pdf.save("penset.pdf");

        // Convert the PDF to base64
        const base64PDF = pdf.output("datauristring");
        let base64PDFf = base64PDF.replace(
          /^data:.+;base64,/,
          `data:application/pdf;base64,JVBERi0xLjQKJdPr6eEKMSAwIG9iago8PC9`
        );

        // Now you can use the base64PDF data as needed
        console.log("base64PDF", base64PDFf);
        // setBase64(base64PDF);
        navigate("/roda/penset/viewpenclaimroda", {
          state: { passingId: data.claimId, pdf: base64PDFf },
        });
      });
    });
  };

  return (
    <div>
      <div style={{ justifyContent: "flex-end" }}>
        <div ref={AdvPORefElement}>
          <Pfo />
        </div>
        <div ref={AdvPORefElement1}>
          <Pfo1 />
        </div>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid
            item
            // marginTop={3}
            // marginBottom={3}
            // marginRight="20px"
            // style={{ display: "flex", justifyContent: "center" }}
          >
            <Button onClick={handleCancel} variant="contained" color="primary">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={exportPDF}
              disable={isSubmitting}
              variant="contained"
              color="success">
              Generate Penset
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default GeneratePDFButton;
