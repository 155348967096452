import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import {
  Avatar,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import ErrorAlert from "../../FormComponent/ErrorAlert";

const ForgotPasswordOtpOfficer = () => {
  const navigate = useNavigate();
  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const paperStyle = { padding: 20, width: 400, margin: "20px auto" };
  const btstyle = { fontWeight: "600", margin: "20px 0" };

  const [loginId, setLoginId] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);

  //loading handler
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [userNameError, setUserNameError] = useState("");

  //otp message ref
  const otpMessageRef = useRef(null);

  const sendOTP = () => {
    sessionStorage.removeItem("messageRef");
    setIsButtonDisabled(true);
    setIsLoading(true);
    const sendData = qs.stringify({ loginId: loginId });
    axios
      .post(
        // process.env.REACT_APP_SERVER_URL + "/officer/send/login/otp",
        process.env.REACT_APP_SERVER_URL + "/officer/forgot/passsword/send/otp",
        sendData
      )
      .then((resp) => {
        if (resp.status === 200) {
          setIsLoading(false);
          setIsButtonDisabled(false);
          //clear form
          otpMessageRef.current = resp.data.message;
          navigate("/forgotpassword/otp", {
            replace: true,
            state: {
              data: { loginId: loginId },
              purpose: "FORGOTPASSWORDOFCR",
              messageRef: otpMessageRef,
            },
          });
          return;
        }
        //check the otp msg
        otpMessageRef.current = resp.data.error_message;
        navigate("/forgotpassword/otp", {
          replace: true,
          state: {
            data: { loginId: loginId },
            purpose: "FORGOTPASSWORDOFCR",
            messageRef: otpMessageRef,
          },
        });
      })
      .catch(function (error) {
        console.log("error otp", error);
        setIsLoading(false);
        setIsButtonDisabled(false);
        otpMessageRef.current = error.response
          ? error.response.data.error_message
          : "Server is not responding. Please try again later.";
        navigate("/forgotpassword/otp", {
          replace: true,
          state: {
            data: { loginId: loginId },
            purpose: "FORGOTPASSWORDOFCR",
            messageRef: otpMessageRef,
          },
        });
        return;
      });
    return;
  };

  const verifyUser = async () => {
    setIsButtonDisabled(true);
    const data = qs.stringify({
      //cmpfoAccNO: loginId,
      loginId: loginId,
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    return await axios
      .post(
        process.env.REACT_APP_SERVER_URL +
          "/officer/forget/password/find/officer",
        data,
        config
      )
      .then((resp) => {
        if (resp.status === 200) {
          return true;
        }
        setErrorAlert(true);
        return false;
      })
      .catch(function (error) {
        console.log("member find error", error);
        setErrorAlert(true);
        return false;
      });
  };

  const validateUserName = (username) => {
    // Validate userName: alphanumeric,/ and max 20char
    const regex = /^[a-zA-Z0-9-]{1,20}$/;
    return regex.test(username);
    //return true when password met the criteria
  };

  const validateUserNameField = (username) => {
    if (!validateUserName(username)) {
      setUserNameError("Enter valid username.");
      return false;
    }
    setUserNameError("");
    return true;
  };

  const handleAlert = () => {
    setErrorAlert(false);
  };

  const handleSubmit = async () => {
    console.log("loginId", loginId);
    verifyUser().then((resp) => {
      if (resp) {
        sendOTP();
        return;
      }
      setIsButtonDisabled(false);
    });
  };

  const handleChange = (e) => {
    // setLoginId(e.target.value.toUpperCase());
    setLoginId(e.target.value);

    if (validateUserNameField(e.target.value)) {
      setIsButtonDisabled(false);
      return;
    }
    setIsButtonDisabled(true);
  };

  return (
    <>
      <ErrorAlert
        handleOk={handleAlert}
        open={errorAlert}
        title="Error"
        body="Member not Found"
        buttonName="Ok"
      />
      <Grid>
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center">
            <Avatar style={avatarStyle}>
              <FontAwesomeIcon icon={faLock} />
            </Avatar>
            <h2>Forgot Password</h2>
          </Grid>
          <Typography style={{ fontWeight: "600" }}>Login ID</Typography>
          <TextField
            id="loginId"
            label="Login ID"
            variant="outlined"
            placeholder="Enter your Login ID"
            fullWidth
            value={loginId}
            error={Boolean(userNameError)}
            helperText={
              Boolean(userNameError)
                ? userNameError
                : "Account number cannot exceed 20 characters."
            }
            required
            onChange={handleChange}
            style={{ marginTop: "20px" }}
            autoComplete="off"
            inputProps={{
              maxLength: 20,
            }}
          ></TextField>
          {/* <Typography style={{ fontWeight: "600" , paddingTop:"20px"}}>
                        CAPTCHA
                    </Typography>                     */}
          <Button
            type="submit"
            color="success"
            fullWidth
            variant="contained"
            style={btstyle}
            disabled={isButtonDisabled}
            onClick={handleSubmit}
          >
            {isLoading ? (
              <CircularProgress sx={{ color: "green" }} />
            ) : (
              "Send OTP"
            )}
          </Button>
        </Paper>
      </Grid>
    </>
  );
};
export default ForgotPasswordOtpOfficer;
