import React from "react";
import AccountSummary from "./MemberDashboardPages/AccountSummary";
import AddFamilyMember from "./MemberDashboardPages/AddFamilyMember";
import ChangePassword from "./MemberDashboardPages/ChangePassword";
import ForgetPassword from "./MemberDashboardPages/ForgetPassword";
import ViewFamilyMember from "./MemberDashboardPages/ViewFamilyMember";
import ViewProfile from "./MemberDashboardPages/ViewProfile";
import PFStatement from "./MemberDashboardPages/PFStatement";
import { Typography } from "@mui/material";

const MemberDashboard=()=>{
    return(<>
        <div id="member-dashboard">
        <Typography variant="h5">MEMBER DASHBOARD</Typography>
        <AccountSummary />
        <PFStatement />
        <ChangePassword />
        <ViewProfile />
        <AddFamilyMember />
        <ViewFamilyMember />
         <ForgetPassword />
         </div>
        

    </>);
}
export default MemberDashboard;