import React, { useState, useEffect } from 'react';
import FormDivider from '../../FormComponent/FormDivider';
import GrievanceDetailComponent from '../GrievanceComponents/GrievanceDetailComponent';
import TitleText from '../../FormComponent/TitleText';
import { Grid, Paper, Typography, Button, TextField, Select, MenuItem } from '@mui/material';
import { Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import ConfirmationDialogue from '../../FormComponent/ConfirmationDialogue';
import SucessAlert from '../../FormComponent/SuccessAlert';
import BackButton from '../GrievanceComponents/BackButton';
import LabelText from '../../FormComponent/LabelText';
import ErrorAlert from '../../FormComponent/ErrorAlert';
import Divider from '@mui/material/Divider';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
const statusValues = {
  0: 'Submitted By Member',
  1: 'Assigned to C-CARES Support',
  2: 'Resolved',
  3: 'Closed',
};


function GrievanceById() {
  const [loginId, setLoginId] = useState();
const [role, setRole] = useState();

  const { state } = useLocation();
  const grievanceId1 = state.passingId;
  const selectedTabName = state.parentData.parentData;

  const [dataOfGrievance, setDataOfGrievance] = useState({});
  const [remarkss, setRemarks] = useState([]);
  const [roccOfficers, setRoccOfficers] = useState([]);

  const [dialogOpenResolve, setDialogOpenResolve] = useState(false);
  const [dialogOpenClose, setDialogOpenClose] = useState(false);
  const [dialogOpenAssign, setDialogOpenAssign] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedValue, setSelectedValue] = useState('');
  const [isErrorAlertOpen, setErrorAlertOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  
  const [successMessage, setSuccessMessage] = useState(null);
  const [isSuccessAlertOpen, setSuccessAlertOpen] = useState(false);
  const [remark, setRemark] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(''); // State variable to store selected dropdown value

  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (token) {
      token = token.replace('"', "").replace('"', "");
      let loginId = jwtDecode(token).user; // Assuming a default loginId
      let role = jwtDecode(token).role; // Assuming a default loginId
      const roleInLowerCase = role.toLowerCase();
      console.log(roleInLowerCase); 
      
      setLoginId(loginId);
      setRole(roleInLowerCase);
      console.log("loooooooooooooooooooooginId", loginId);
      // Add any further logic here based on the loginId
    } else {
      // Handle the case when token is not present
      // setIsLoading(false);
      console.log("token not found");
      // setIsFailed(true);
    }
  }, []);

  useEffect(() => {
    const byid = async () => {
      try {
        const formData = new FormData();
        formData.append('grievanceId', grievanceId1);
        console.log("gid", grievanceId1);
        
        const url = `${process.env.REACT_APP_SERVER_URL}/grievance/get/by/id/for/${role}`;
        
        
        const response = await axios.post(url, formData);
        console.log("response", response);
        const data = response.data.data.grievanceDetails[0];
        const remarksData = response.data.data.remarks;
        setDataOfGrievance(data);
        setRemarks(remarksData);
        console.log("hey there", remarkss);
        console.log("yashyash", dataOfGrievance);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data: ', error);
        setLoading(false);
      }
    };

    byid();
  }, [grievanceId1,role,loginId]);

  const handleButtonClick = () => {
    // Implement logic for button click
    setShowDropdown(true); // Show dropdown when button is clicked
  };
  
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown); // Toggle the state of the dropdown
  };
const navigate = useNavigate();
  const handleSuccessAlertOk = () => {
    navigate("/" + role);
    setSuccessAlertOpen(false);
  };
  const handleErrorAlertOk = () => {
    // Close the success alert
    setErrorAlertOpen(false);

    // Reload the page
  };
  const ResolveGrievance = async (enteredRemarks) => {
    try {
      const formData = {
        
        "grievanceId": grievanceId1,
        "remarks": enteredRemarks,
        "fromOffcrLoginId": loginId,
        
        
      };

      const url = `${process.env.REACT_APP_SERVER_URL}/mark/grievances/as/resolved/by/${role}`;

      const response = await axios.post(url, formData);
      setSuccessMessage("resolved successfully");
      setSuccessAlertOpen(true);
    } catch (error) {
      setErrorMessage("Something Went Wrong");
      setErrorAlertOpen(true);
      console.log('Error marking grievance as resolved:', error);
    }
  };

  const CloseGrievance = async (remark) => {
    try {
      const formData = {
        "grievanceId": grievanceId1,
        "remarks": remark,
        "fromOffcrLoginId": loginId
      };
      console.log("formdata data", formData);

      const url = `${process.env.REACT_APP_SERVER_URL}/mark/grievances/as/closed/by/${role}`;

      const response = await axios.post(url, formData);
      setSuccessMessage("closed successfully");
      setSuccessAlertOpen(true);
    } catch (error) {
      setErrorMessage("Something Went Wrong");
      setErrorAlertOpen(true);
      console.log('Error marking grievance as closed:', error);
    }
  };

  const handleChange = (event) => {
    setDropdownValue(event.target.value);
  };
  const MAX_WORDS_LIMIT = 500;
  useEffect(() => {
    fetchRoccData();
  }, [role]);

  const fetchRoccData = async () => {
    try {
      const formData = new FormData();
      formData.append('loginId', loginId);
      // const url = role === "gch"
      //  ? process.env.REACT_APP_SERVER_URL +'/assign/grievances/to/ro/cc/go/by/gcm'
      //  : process.env.REACT_APP_SERVER_URL +'/assign/grievances/to/ro/cc/go/by/gch';
       const url = `${process.env.REACT_APP_SERVER_URL}/assign/grievances/to/ro/cc/go/by/${role}`;
      const response = await axios.post(url, formData);
      const roccData = response.data.data;

      setRoccOfficers(roccData);
      console.log("officers are",roccData);
    } catch (error) {
      console.error('Error fetching ROCC data:', error);
    }
  };





//Resolve.................................................................
  const handleOkResolve = () => {
    ResolveGrievance(remark);
    handleCloseDialogResolve();
  };

  const handleCloseDialogResolve = () => {
    setDialogOpenResolve(false);
  };

  const handleOpenDialogResolve = (remark) => {
    setRemark(remark);
    setDialogOpenResolve(true);
  };

//Close.......................................................................
  const handleOkClose = () => {
    CloseGrievance(remark);
    handleCloseDialogClose();
  };

  const handleCloseDialogClose = () => {
    setDialogOpenClose(false);
  };

  const handleOpenDialogClose = (remark) => {
    setRemark(remark); 
    setDialogOpenClose(true);
  };

  //Assign........................................................................
  const handleOkAssign = () => {
    AssignGrievance(remark);
    handleCloseDialogAssign();
  };

  const handleCloseDialogAssign = () => {
    setDialogOpenAssign(false);
  };

  const handleOpenDialogAssign = (remark) => {
    setRemark(remark); 
    setDialogOpenAssign(true);
  };

  const AssignGrievance = async (remarks) => {
    try {
      // Capture the selected RO and entered remarks
      const selectedRO = dropdownValue;
      const enteredRemarks = remarks;

      // Make an API call to submit the data
      const formData = {
        grievanceId: grievanceId1,
        fromOffcrLoginId : loginId,
        remarks: enteredRemarks,
        toOffcrLoginId: selectedRO, // Use the selected officer ID here
      };
      console.log("yash received form data", formData )
      // const url =  process.env.REACT_APP_SERVER_URL + '/assign/grievances/to/ro/cc/go/by/"${role}"/throw/assign/to';
      const url = `${process.env.REACT_APP_SERVER_URL}/assign/grievances/to/ro/cc/go/by/gch/throw/assign/to`;
       
        
        
      const response = await axios.post(url, formData);
      setSuccessMessage("Grievance Assigned Successfully")
      setSuccessAlertOpen(true);   

      // Store the HTTP status in the state
      

      // Check the HTTP status and open the corresponding modal
    
    } catch (error) {
      setErrorMessage("Something Went Wrong");
      setErrorAlertOpen(true);
      console.error('Error submitting data:', error);
      // Open the error modal
      // setOpenErrorModal(true);
    } 
  };







  const handleRemarkChange = (event) => {
    const inputValue = event.target.value;
  
    // Check if the input length exceeds the maximum limit
    if (inputValue.length <= MAX_WORDS_LIMIT) {
      // Set the remark if it's within the word limit
      setRemark(inputValue);
    }
  };

  const handleDropdownChange = (event) => {
    setDropdownValue(event.target.value);
    // Close dropdown when an option is selected
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <ConfirmationDialogue
        open={dialogOpenResolve}
        title="Confirm?"
        body="Resolve this Grievance?"
        buttonName="OK"
        handleOk={handleOkResolve}
        handleCancel={handleCloseDialogResolve}
      />
      <ConfirmationDialogue
        open={dialogOpenClose}
        title="Confirm?"
        body="Close this Grievance?"
        buttonName="OK"
        handleOk={handleOkClose}
        handleCancel={handleCloseDialogClose}
      />
        <ConfirmationDialogue
        open={dialogOpenAssign}
        title="Confirm?"
        body="Assign this Grievance?"
        buttonName="OK"
        handleOk={handleOkAssign}
        handleCancel={handleCloseDialogAssign}
      />
      <SucessAlert
        open={isSuccessAlertOpen}
        title="Success!"
        body={successMessage}
        buttonName="OK"
        handleOk={handleSuccessAlertOk}
      />
      <ErrorAlert
              open={isErrorAlertOpen}
              title="Error!"
              body={errorMessage}
              buttonName="OK"
              handleOk={handleErrorAlertOk}
            />

<Grid container justifyContent="center">
  <Grid item xs={12} sm={12} md={12} lg={12 }>
    <Paper elevation={3} sx={{ padding: '3rem', borderRadius: '8px' }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={6}>
          <TitleText title="Grievance Details" />
        </Grid>
        <Grid item xs={6} textAlign="right">
          <Typography variant="subtitle1">{`Status: ${[dataOfGrievance.currentStatus] || 'N/A'}`}</Typography>
        </Grid>
      </Grid>

      <FormDivider />

      <Grid item xs={1} style={{ marginBottom: '40px' }}>
        <BackButton/>
      </Grid>

      <Grid item style={{marginLeft:'30px'}}>
      <GrievanceDetailComponent dataOfGrievance={dataOfGrievance} remarkss={remarkss} />

      </Grid>
      {
  remarkss && remarkss.length > 0 ? (
    <>
      <Grid item xs={12} sm={4} marginLeft={4}>
      <TitleText title="Remarks " />
      </Grid>
  
      <Grid item xs={12} marginLeft={4}>
        {remarkss.map((item, index) => {
          if (item.remark !== "") {
            return (
              <Typography key={index} align="left" mb={1}>
                <b>
                  <u>{item.role}</u>
                </b>{" "}
                :- {item.remark}
              </Typography>
            );
          }
          return null; // Return null for items with empty remarks
        })}
      </Grid>
    </>
  ) : null
}
    <Divider variant="fullWidth" color="black" style={{ margin: '20px 0' }} />
    <Grid container justifyContent="center" style={{ marginBottom: '40px', marginLeft:'30px' }}>
  {selectedTabName !== 1 && selectedTabName !== 2  && (
    <Grid item xs={1}>
      <LabelText title="Enter Remarks" />
    </Grid>
  )}
  {selectedTabName !== 1 && selectedTabName !== 2 &&  (
    <Grid item xs={6}>
      <div>
        <TextField
          label="Enter Remarks"
          variant="outlined"
          fullWidth
          value={remark}
          onChange={handleRemarkChange}
          inputProps={{ maxLength: MAX_WORDS_LIMIT }} // Limit the input length
          multiline
          rows={4}
        />
        {remark.length >= MAX_WORDS_LIMIT && (
          <Typography variant="caption" color="error">
            Maximum limit reached.
          </Typography>
        )}
      </div>
    </Grid>
  )}
</Grid>


      <Grid container justifyContent="center" style={{ marginTop: '20px', marginBottom: '20px' }}>
{selectedTabName !== 1 && selectedTabName !== 2  && role !== 'rogo'  && role !== 'ccgo' && selectedTabName !== 3  && (
        <Grid item xs={3}>
          <Button variant="contained" color='warning' onClick={toggleDropdown}>Assign to RO/CC</Button>
        </Grid>
)}
        {selectedTabName !== 1 &&selectedTabName !== 2 && selectedTabName !== 3 && (
          <Grid item xs={3}>
            <Button variant="contained" color='success' onClick={() => handleOpenDialogResolve(remark)}>Mark as Resolved</Button>
          </Grid>
        )}
        {selectedTabName !== 1 &&selectedTabName !== 3 && selectedTabName !== 2 && (
          <Grid item xs={3}>
            <Button variant="contained" onClick={() => handleOpenDialogClose(remark)}>Mark as Closed</Button>
          </Grid>
        )}
{selectedTabName !== 0 
&& selectedTabName !== 2 
&& selectedTabName !== 1 && role !== 'gcm'  && role !== 'rogo'  && role !== 'ccgo' && selectedTabName !== 1 && (
        <Grid item xs={3}>
          <Button variant="contained" color='warning' onClick={toggleDropdown}>Reassign to RO/CC</Button>
        </Grid>
)}
      </Grid>

      <div>
        {showDropdown && (
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={6}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Select
                  value={dropdownValue}
                  onChange={handleDropdownChange}
                  fullWidth
                  displayEmpty // Allow the Select to display an empty option
                >
                  <MenuItem value="" disabled>Select Officer</MenuItem> {/* Helper text */}
                  {roccOfficers.map((officer) => (
                    <MenuItem key={officer.loginId} value={officer.loginId}>
                      {`${officer.name} - ${officer.loginId}`}
                    </MenuItem>
                  ))}
                </Select>
                <Button variant="contained" color='success' style={{marginLeft:'5px'}} onClick={() => handleOpenDialogAssign(remark)}>OK</Button>
              </div>
            </Grid>
          </Grid>
        )}
      </div>

    </Paper>
  </Grid>
</Grid>


    </div>
  );
}

export default GrievanceById;
