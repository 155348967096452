import React, { Fragment, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import LabelText from "../FormComponent/LabelText";
import SubtitleText from "../FormComponent/SubtitleText";
import ValueText from "../FormComponent/ValueText";

function AdvClaimDetails(props) {
  const { advclaimdet, beforeids } = props;
  const {
    advClaim,
    tAdvSpecificDetails,
    claimType,
    familyMemName,
    formattedRemarks,
  } = advclaimdet;

  const [duration, setDuration] = useState("");

  let claim_type = advClaim.advClaimTypId;
  let status = beforeids;

  let formattedCorresAddress = null;
  let formattedLocationAddress = null;

  if (advClaim.correspondanceAddress) {
    const add = JSON.parse(advClaim.correspondanceAddress);
    formattedCorresAddress = `${add.address}`;
  }

  if (tAdvSpecificDetails.locationDet) {
    const add = JSON.parse(tAdvSpecificDetails.locationDet);
    formattedLocationAddress = `${add.address}`;
  }
  const periodOfMembership = advClaim.periodOfMembship;
  useEffect(() => {
    // Check if periodOfMembership is defined and not an empty string
    if (periodOfMembership && periodOfMembership.trim() !== "") {
      try {
        const data = JSON.parse(periodOfMembership);
        let years = data.years;
        let months = data.months;
        // Format the output
        const duration = `${years} Years and ${months} Months`;
        setDuration(duration);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.error("Invalid or empty JSON string");
    }
  }, [periodOfMembership]);

  function isValidJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  return (
    <Grid>
      <Grid container justifyContent="center" sx={{ wordWrap: "break-word" }}>
        <Grid>
          <>
            {/* ************************************** */}
            {/* section 1  */}
            <Grid
              container
              justifyContent="space-between"
              alignItems="start"
              rowGap={3}>
              <Grid item xs={12} sm={12}>
                <SubtitleText title="Claim Details" />
              </Grid>

              {/* column 1  */}
              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  alignItems="start"
                  justifyContent="space-between"
                  spacing={2}
                  columnGap={1}>
                  {/* Rows for Claim Details */}
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Claim ID" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValueText title={advClaim.claimId} />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Name as per Aadhaar Card" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={
                        isValidJsonString(advClaim.nameAadhaar)
                          ? `${JSON.parse(advClaim.nameAadhaar).firstname} ${
                              JSON.parse(advClaim.nameAadhaar).middlename
                            } ${JSON.parse(advClaim.nameAadhaar).lastname}`
                          : advClaim.nameAadhaar
                      }
                    />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Name as per Bank " />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={
                        isValidJsonString(advClaim.nameBank)
                          ? `${JSON.parse(advClaim.nameBank).firstname} ${
                              JSON.parse(advClaim.nameBank).middlename
                            } ${JSON.parse(advClaim.nameBank).lastname}`
                          : advClaim.nameBank
                      }
                    />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="DOB as per Aadhaar Card" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {/* <ValueText title={advClaim.dobAadhaar} /> */}
                    <ValueText
                      title={
                        advClaim.dobAadhaar
                          ? new Date(advClaim.dobAadhaar).toLocaleDateString(
                              "en-GB"
                              // options
                            )
                          : ""
                      }
                    />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Amount Required" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValueText title={advClaim.amountApplied} />
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Submission Date " />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={
                        advClaim.dateReceived
                          ? new Date(advClaim.dateReceived).toLocaleDateString(
                              "en-GB"
                              // options
                            )
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* column 2  */}
              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="start"
                  spacing={2}
                  columnGap={1}>
                  {/* Rows for claim Details */}

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Claim Type" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={claimType} />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Name as per Pan" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={
                        isValidJsonString(advClaim.namePan)
                          ? `${JSON.parse(advClaim.namePan).firstname} ${
                              JSON.parse(advClaim.namePan).middlename
                            } ${JSON.parse(advClaim.namePan).lastname}`
                          : advClaim.namePan
                      }
                    />
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="DOB as per Pan" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={
                        advClaim.dobPan
                          ? new Date(advClaim.dobPan).toLocaleDateString(
                              "en-GB"
                              // options
                            )
                          : ""
                      }
                    />
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    {/* <LabelText title="D/O" /> */}
                    <LabelText title={advClaim.sfwhRelationType} />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValueText title={advClaim.sfwhName} />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Total Emoluments of 36 Months" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={advClaim.emolumentsLast36m} />
                  </Grid>
                </Grid>
              </Grid>
              {/* ************************************** */}
            </Grid>
          </>
        </Grid>
        <Grid>
          <>
            {/* section 1  */}
            <Grid
              container
              justifyContent="space-between"
              alignItems="start"
              rowGap={3}>
              {status === 1 ? (
                <>
                  {/* column 1  */}
                  <Grid item xs={12} sm={5}>
                    <Grid
                      container
                      alignItems="start"
                      justifyContent="space-between"
                      spacing={2}
                      columnGap={1}>
                      {/* Rows for Claim Details */}
                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title=" Period of Membership" />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}>
                        :
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ValueText title={duration} />
                      </Grid>

                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title="Member Share" />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}>
                        :
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ValueText title={advClaim.memShare} />
                      </Grid>

                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title="Employer Share" />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}>
                        :
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ValueText title={advClaim.employerShare} />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* column 2  */}
                  <Grid item xs={12} sm={5}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="start"
                      spacing={2}
                      columnGap={1}>
                      {/* Rows for claim Details */}

                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title="Ledger Balance" />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}>
                        :
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <ValueText title={advClaim.ledgerBalance} />
                      </Grid>

                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title="Amount Allowed" />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}>
                        :
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ValueText title={advClaim.amountAllowed} />
                      </Grid>

                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title="Amount Paid" />
                        {/* <LabelText title={advClaim.sfwhRelationType} /> */}
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}>
                        :
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ValueText title={advClaim.amtAlrdyPaid} />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  {/* <Typography>No data available for the selected claim type.</Typography> */}
                </Grid>
              )}
            </Grid>
          </>
        </Grid>
        {/* ******************************************************************************************* */}
        <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
          <>
            {/* section 1  */}
            <Grid
              container
              justifyContent="space-between"
              alignItems="start"
              rowGap={3}>
              {claim_type === 1 ||
              claim_type === 2 ||
              claim_type === 3 ||
              claim_type === 4 ||
              claim_type === 5 ? (
                <>
                  {/* column 1  */}

                  <Grid item xs={12} sm={5}>
                    <Grid
                      container
                      alignItems="start"
                      justifyContent="space-between"
                      spacing={2}
                      columnGap={1}>
                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title="Estimated Cost " />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}>
                        :
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <ValueText
                          title={
                            tAdvSpecificDetails &&
                            tAdvSpecificDetails.estimatedCost
                          }
                        />
                      </Grid>

                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title="Area" />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}>
                        :
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <ValueText
                          title={`${
                            tAdvSpecificDetails &&
                            tAdvSpecificDetails.siteMeasurementVal
                          } ${
                            tAdvSpecificDetails &&
                            tAdvSpecificDetails.siteMeasurementUnit
                          }`}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <LabelText title="Correspondence Address " />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}>
                        :
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {/* <ValueText title={advClaim.correspondanceAddress} /> */}
                        <ValueText title={formattedCorresAddress} />
                      </Grid>
                      {/* row */}

                      {/* row */}
                      {/* <Grid item xs={12} sm={4}>
                      <LabelText title="Remarks " />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      {/* <ValueText title={formattedRemarks} /> */}
                      {/* {formattedRemarks.map((remark, index) => (
                        <div key={index}>
                          {remark.remark && (
                            <ValueText
                              title={`${remark.status}: ${remark.remark}`}
                            />
                          )}
                          {/* <ValueText title={`UAO: ${remark.UAO}`} /> */}
                      {/* </div> */}
                      {/* ))} */}
                      {/* </Grid> */}
                    </Grid>
                  </Grid>

                  {/* column 2  */}

                  <Grid item xs={12} sm={5}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="start"
                      spacing={2}
                      columnGap={1}>
                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title="Sale Agreement Amount " />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}>
                        :
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <ValueText
                          title={
                            tAdvSpecificDetails &&
                            tAdvSpecificDetails.saleAgreeAmt
                          }
                        />
                      </Grid>

                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title="Location Details " />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}>
                        :
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <ValueText
                          title={
                            // tAdvSpecificDetails && tAdvSpecificDetails.locationDet
                            tAdvSpecificDetails ? formattedLocationAddress : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : claim_type === 6 ? (
                <>
                  {/* section 2  */}
                  {/* column 1  */}

                  <Grid item xs={12} sm={5}>
                    <Grid
                      container
                      alignItems="start"
                      justifyContent="space-between"
                      spacing={2}
                      columnGap={1}>
                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title="Anticipated Date of Marriage " />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}>
                        :
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <ValueText
                          title={
                            tAdvSpecificDetails.anticptdDateOfMarriage
                              ? new Date(
                                  tAdvSpecificDetails.anticptdDateOfMarriage
                                ).toLocaleDateString(
                                  "en-GB"
                                  // options
                                )
                              : ""
                          }
                          // title={(
                          //   // tAdvSpecificDetails.anticptdDateOfMarriage
                          //   //   ? new Date(
                          //   //       tAdvSpecificDetails.anticptdDateOfMarriage
                          //   //     ).toLocaleDateString(
                          //   //       "en-GB"
                          //   //       // options
                          //   //     )
                          //   //   : ""
                          //   tAdvSpecificDetails &&
                          //     tAdvSpecificDetails.anticptdDateOfMarriage)
                          // }
                        />
                      </Grid>

                      {/* row */}
                      {/* <Grid item xs={12} sm={4}>
                      <LabelText title=" Submission Date:" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <ValueText title={advClaim.dateReceived} />
                    </Grid> */}

                      {/* row */}
                      {/* <Grid item xs={12} sm={4}>
                      <LabelText title=" Remarks:" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <ValueText
                        title={tAdvSpecificDetails.anticptdDateOfMarriage}
                      />
                    </Grid> */}
                    </Grid>
                  </Grid>

                  {/* column 2  */}

                  <Grid item xs={12} sm={5}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="start"
                      spacing={2}
                      columnGap={1}>
                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title=" For Marriage of " />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}>
                        :
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <ValueText title={familyMemName} />
                      </Grid>

                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title=" Correspondence Address" />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}>
                        :
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <ValueText title={formattedCorresAddress} />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : claim_type === 7 ? (
                <>
                  {/* section 2  */}
                  <Grid item xs={12} sm={5}>
                    <Grid
                      container
                      alignItems="start"
                      justifyContent="space-between"
                      spacing={2}
                      columnGap={1}>
                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title="Name of Student " />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}>
                        :
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ValueText
                          title={
                            tAdvSpecificDetails &&
                            tAdvSpecificDetails.dependentId
                          }
                        />
                      </Grid>
                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title="Year of Passing Exam" />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}>
                        :
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <ValueText
                          title={
                            tAdvSpecificDetails && tAdvSpecificDetails.examYear
                          }
                        />
                      </Grid>

                      {/* row */}
                      {/* <Grid item xs={12} sm={4}>
                      <LabelText title="Submission Date" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <ValueText title={advClaim.dateReceived} />
                    </Grid> */}

                      {/* row */}
                      {/* <Grid item xs={12} sm={4}>
                      <LabelText title="Remarks" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ValueText title={tAdvSpecificDetails.examYear} />
                    </Grid> */}
                    </Grid>
                  </Grid>
                  {/* column 2  */}

                  <Grid item xs={12} sm={5}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="start"
                      spacing={2}
                      columnGap={1}>
                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title="Examination Passed " />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}>
                        :
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <ValueText
                          title={
                            tAdvSpecificDetails &&
                            tAdvSpecificDetails.examPassed
                          }
                        />
                      </Grid>

                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title=" Fee Paid " />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}>
                        :
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <ValueText
                          title={
                            tAdvSpecificDetails && tAdvSpecificDetails.feesPaid
                          }
                        />
                      </Grid>

                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title=" Correspondence Address " />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "initial" } }}>
                        :
                      </Grid>
                      {/* <ValueText title={advClaim.correspondanceAddress} /> */}
                      <Grid item xs={12} sm={6}>
                        <ValueText title={formattedCorresAddress} />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  {/* <Typography>No data available for the selected claim type.</Typography> */}
                </Grid>
              )}
            </Grid>
          </>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="flex-start"
        mt={2}
        alignItems="flex-start">
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}>
            {/* <Grid item>
              <LabelText title="Remarks" />
            </Grid> */}
            <Grid item xs={12} sm={12}>
              <SubtitleText title="Remarks" />
            </Grid>
            <Grid item xs={12}>
              {formattedRemarks.map((item, index) => {
                if (item.remark !== "") {
                  return (
                    <Typography key={index} align="left" mb={1}>
                      {/* Display the status and remark in the desired format */}
                      <b>
                        <u>{item.role}</u>
                      </b>{" "}
                      :- {item.remark}
                    </Typography>
                  );
                }
                return null; // Return null for items with empty remarks
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AdvClaimDetails;
