import { Box, List, ListItem, Typography, Button, Grid } from "@mui/material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const date = new Date();
const options = { year: "numeric", month: "2-digit", day: "2-digit" };
const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(date);
const data = {
  // para: "Para 63(1)(a)",
  //advClaimTyp: "Purchase of Site for House Building ADV-5",
  //fullName: "E Rajanna",
  //fatherHusbandName: "E Rajamallu",
  // unitName: "M.D.Office",
  // joiningDate: "25-10-1990",
  // cmpfAccNo: "HYD/170/840",
  // dateOfCessation: "31-05-2023",
  // dateOfAppointment: "25-10-1990",
  // dateOfDeath: "00-00-0000",
  // membershipPeriod: "32 Years 07 Months",
  // ledgerBalance: 2231862.0,
  dateOfClaim: formattedDate,
  // dateOfBirth: "1987-10-12",
  // causeOfCessation: "SuperAnnuation",
  // claimTypDesc: "SuperAnnuation",
  // name: "BaijunathSharma",
  // relationName: "Brother",
  // sanctionedAmt: 0,
  // brokenPeriodInterestRate: 8,
  // brokenPeriodInterestPayable: 247043,
  // brokenPeriodContri: 180804,
  // brokenPeriodContriDet: null,
  // voucherNo: 64573456,
  //Godavarikhani
  // region: "",
  // modeOfRemittance: "A/C PAYEE CHEQUE/BANK TRANSFER",
  // acc: "52196074233",
  // ifsc: "SBIN0020909",
  // bankName: "STATE BANK OF INDIA",
  // bankAddress: "Branch KALYANKHANI, SRINAGAR, GARHWAL, UTTARAKHAND, 246174",
  // postalAddress: "C/O KK-1, MANDAMARRI, MMR MANCHERIAL, Telangana,INDIA",
  // feilds to be calculated
  // age: "60",
  // dateUptoWhichInterestIsPayable: "31-07-2023",
  // closingBalance: 2231862.0,
  // dateFrom: "",
  // dateTo: "31.03.2023",
  // rateOfInterest: 8.0,
  // brokenPeriodInterestPayableFrom: "01-04-2023",
  // brokenPeriodInterestPayableTo: "31-07-2023",
  // brokenPeriodContribution: 180804.0,
  // brokenPeriodInterestPayable: 247043.0,
  // totalAmountpayable: 2659709.0,
};

const AdvPo = () => {
  const location = useLocation();
  const { advClaimData, memberData } = location.state;
  const { advClaim, paraNumber, claimType, tAdvSpecificDetails, unitCode } =
    advClaimData;
  const claimId = advClaim.claimId;
  const [ros, setRos] = useState("");
  const [track, setTrack] = useState([]);
  const fetchAssignOfficer = async () => {
    try {
      const url =
        process.env.REACT_APP_SERVER_URL + "/get/ro/code/name/by/claimId";
      const formData = new FormData();
      formData.append("claimId", claimId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;

      setRos(data); // Assuming setAssignToOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };
  const fetchtrack = async () => {
    try {
      const url =
        process.env.REACT_APP_SERVER_URL + "/get/ro/cc/name/by/claimId";
      const formData = new FormData();
      formData.append("claimId", claimId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const trackdata = response.data.data;

      setTrack(trackdata); // Assuming setAssignToOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };
  console.log("trackdata", track);

  useEffect(() => {
    fetchAssignOfficer();
    fetchtrack();
  }, []);
  const desiredSequence = [
    "UDA",
    "UAO",
    "AAO",
    "IDS",
    "AGM",
    "DA",
    "SI",
    "BO",
    "RH",
    "CA",
  ];
  const filteredAndSortedTrack = desiredSequence
    .filter((role) => track.some((item) => item.shName === role))
    .map((role) => track.find((item) => item.shName === role));

  console.log("advClaimData", memberData);
  let formattedPfAddress = null;
  let poNo = "PO" + advClaim.claimId;
  if (memberData.pfAddress) {
    try {
      const add = JSON.parse(memberData.pfAddress);
      formattedPfAddress = `${add.addr_l1}, ${add.addr_l2}, ${add["village/town/city"]}, ${add.district}, ${add.state}, ${add.pin_code}`;
    } catch (error) {}
  }

  // const formattedAmount = new Intl.NumberFormat("en-IN", {
  //   style: "currency",
  //   currency: "INR",
  // }).format(advClaim.amountAllowed );

  // const amountInWords = numberToWords.toWords(
  //   Math.floor(advClaim.amountAllowed )
  // );
  let givenAmount = 0;

  switch (advClaim.advClaimTypId) {
    case 2:
    case 3:
    case 4:
      givenAmount = advClaim.amountAllowed / 2;
      break;

    case 1:
    case 5:
    case 6:
    case 7:
    case 8:
      givenAmount = advClaim.amountAllowed;
      break;

    default:
      // Handle unexpected advClaimTypId values, if needed
      break;
  }

  const toWords = (number) => {
    const rupeesWords = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];

    const tensWords = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    if (number === 0) return "Zero";

    let words = "";
    if (number > 10000000) {
      const croreParts = Math.floor(number / 10000000);
      number %= 10000000;
      words += toWords(croreParts) + " Crore ";
    }

    if (number > 100000) {
      const lakhParts = Math.floor(number / 100000);
      number %= 100000;
      words += toWords(lakhParts) + " Lakh ";
    }

    if (number > 1000) {
      const thousandParts = Math.floor(number / 1000);
      number %= 1000;
      words += toWords(thousandParts) + " Thousand ";
    }

    if (number > 0) {
      if (words !== "") {
        words += " ";
      }

      if (number < 20) {
        words += rupeesWords[number];
      } else if (number < 100) {
        words +=
          tensWords[Math.floor(number / 10)] + " " + rupeesWords[number % 10];
      } else {
        words += rupeesWords[Math.floor(number / 100)] + " Hundred ";
        number %= 100;

        if (number > 0) {
          if (words !== "") {
            words += " ";
          }

          if (number < 20) {
            words += rupeesWords[number];
          } else if (number < 100) {
            words +=
              tensWords[Math.floor(number / 10)] +
              " " +
              rupeesWords[number % 10];
          }
        }
      }
    }

    return words.trim();
  };

  const givenAmountInWords = toWords(givenAmount);
  // Format the number using Indian numbering system
  const formattedNumber = givenAmount.toLocaleString("en-IN");

  // console.log(`Given amount in words is: ${givenAmountInWords}`);

  // console.log("formattedAmount", formattedAmount);
  return (
    <div>
      <div className="actual-receipt">
        <Box mt={8}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",

              marginBottom: "20px",
            }}
          >
            <Typography
              variant="body2"
              gutterBottom
              fontWeight="bolder"
              fontFamily="monospace"
            >
              .
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              fontWeight="bolder"
              fontFamily="monospace"
            >
              .
            </Typography>
          </Box>
          <Typography variant="h6" gutterBottom>
            Pay Order
          </Typography>

          <Box mt={4} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginLeft: "60px",
              marginRight: "60px",
            }}
          >
            <Typography
              variant="body2"
              gutterBottom
              fontWeight="bolder"
              fontFamily="monospace"
              marginLeft={3}
            ></Typography>

            <Typography
              variant="body2"
              gutterBottom
              fontWeight="bolder"
              fontFamily="monospace"
            ></Typography>

            <Typography
              variant="body2"
              gutterBottom
              fontWeight="bolder"
              fontFamily="monospace"
              marginRight={3}
            >
              Form No: -Ref.3
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginLeft: "60px",
              marginRight: "60px",
              marginBottom: "20px",
            }}
          >
            <Typography
              variant="body2"
              gutterBottom
              fontWeight="bolder"
              fontFamily="monospace"
              marginLeft={3}
            >
              PO No: {poNo}
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              fontWeight="bolder"
              fontFamily="monospace"
            ></Typography>

            <Typography
              variant="body2"
              gutterBottom
              fontWeight="bolder"
              fontFamily="monospace"
              marginRight={3}
            >
              Dated: {data.dateOfClaim}
            </Typography>
          </Box>

          {/* <Typography variant="h6" gutterBottom>
        <span>Coal Mines Provident Fund Organization</span>
      </Typography> */}

          <Typography variant="h6" gutterBottom>
            <span
              style={{
                textDecoration: "underline",
                fontFamily: "monospace",
                fontWeight: "bold",
              }}
            >
              OFFICE OF THE REGIONAL COMMISSIONER, COAL MINES PROVIDENT FUND
              ORGANIZATION
            </span>
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            fontWeight="bolder"
            fontFamily="monospace"
          >
            <u>Region: {ros ? ros.roName : ""}</u>
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",

              marginBottom: "20px",
            }}
          >
            <Typography
              variant="body2"
              gutterBottom
              fontWeight="bolder"
              fontFamily="monospace"
            ></Typography>
            <Typography
              variant="body2"
              gutterBottom
              fontWeight="bolder"
              fontFamily="monospace"
            ></Typography>
          </Box>
          <Typography
            variant="body2"
            align="left"
            marginLeft={"120px"}
            gutterBottom
            fontWeight="bolder"
            fontFamily="monospace"
          >
            [PAY ORDER FOR Adv. - {claimType})] Pay Order No. xxxx Dated{" "}
            {data.dateOfClaim}
          </Typography>
          <Typography>‎ </Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            style={{ paddingLeft: 120 }}
          >
            <Grid item xs={8}>
              {/* <List dense sx={{ pl: 4 }}>
                <ListItem sx={{ display: "list-item" }}> */}
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                    style={{ textAlign: "left" }}
                  >
                    CMPF A/C No
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                    style={{ textAlign: "left" }}
                  >
                    {memberData.cmpfAccNo}
                  </Typography>
                </Grid>
              </Grid>
              {/* </ListItem>
              </List> */}
            </Grid>

            <Grid item xs={8}>
              {/* <ListItem sx={{ display: "list-item" }}> */}
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                    style={{ textAlign: "left" }}
                  >
                    Member Name
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                    style={{ textAlign: "left" }}
                  >
                    {memberData.fullName}
                  </Typography>
                </Grid>
              </Grid>
              {/* </ListItem> */}
            </Grid>

            <Grid item xs={8}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                    style={{ textAlign: "left" }}
                  >
                    Son/Daughter/Wife of
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                    style={{ textAlign: "left" }}
                  >
                    {memberData.fatherHusbandName}
                  </Typography>
                </Grid>
              </Grid>
              {/* </ListItem> */}
            </Grid>
            <Grid item xs={8}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                    style={{ textAlign: "left" }}
                  >
                    Unit code
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                    style={{ textAlign: "left" }}
                  >
                    {unitCode}
                  </Typography>
                </Grid>
              </Grid>
              {/* </ListItem> */}
            </Grid>
            <Grid item xs={8}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                    style={{ textAlign: "left" }}
                  >
                    Unit Name
                  </Typography>
                </Grid>

                <Grid item md={3}>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                    style={{ textAlign: "left" }}
                  >
                    {memberData.unitName}
                  </Typography>
                </Grid>
              </Grid>
              {/* </ListItem> */}
            </Grid>
          </Grid>
          <Typography>‎ </Typography>

          <Typography
            variant="body2"
            align="left"
            marginLeft={"120px"}
            marginRight={"80px"}
            gutterBottom
            fontWeight="bolder"
            fontFamily="monospace"
          >
            Pay to {memberData.fullName} . by credit to his /her savings Bank
            Account No. {memberData.bankAccNoPf} standing at{" "}
            {memberData.pfBankName} {formattedPfAddress} IFSC Code:
            {memberData.pfBankIfsc}
          </Typography>

          <Typography
            variant="body2"
            align="left"
            marginLeft={"120px"}
            marginRight={"80px"}
            gutterBottom
            fontWeight="bolder"
            fontFamily="monospace"
          >
            As per details below:
          </Typography>
          <Typography>‎ </Typography>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            style={{ paddingLeft: 120 }}
          >
            <Grid item xs={8}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    className="labelForPdf"
                    style={{ textAlign: "left" }}
                  >
                    Total : <u>Rs {formattedNumber}</u>/-
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography
                    fontFamily="monospace"
                    variant="body2"
                    fontWeight="500"
                    className="valueForPdf"
                    style={{ textAlign: "left" }}
                  ></Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Typography
            variant="body2"
            align="left"
            marginLeft={"120px"}
            marginRight={"80px"}
            gutterBottom
            fontFamily="monospace"
          >
            <b> Total Amount In Words :</b> {givenAmountInWords} rupees only.
          </Typography>

          <Typography
            variant="body2"
            align="left"
            marginLeft={"120px"}
            marginRight={"80px"}
            gutterBottom
            fontFamily="monospace"
          >
            <b> Complete Address Of The Payee :</b>
            {"   "}
            {`
     ${memberData.addressL1 ? memberData.addressL1 + "," : ""}
      
     ${memberData.addressL2 ? memberData.addressL2 + "," : ""}
      
     ${memberData.village ? memberData.village + "," : ""}
      
     ${memberData.city ? memberData.city + "," : ""}
      
     ${memberData.district ? memberData.district + "," : ""}
      
     ${memberData.state ? memberData.state + "," : ""}
      
     ${memberData.country ? memberData.country + "," : ""}
      
     ${memberData.pincode ? memberData.pincode : ""}
     `}
          </Typography>
          <Typography>‎ </Typography>

          {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "120px",
            marginRight: "400px",
            marginBottom: "20px",
          }}
        >
            <Typography
            variant="body2"
            gutterBottom
            fontWeight="bolder"
            fontFamily="monospace"
            
          >
          ------------
          </Typography>
        
          <Typography
            variant="body2"
            gutterBottom
            fontWeight="bolder"
            fontFamily="monospace"
          
            
          >
          ------------
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            fontWeight="bolder"
            fontFamily="monospace"
          
            
          >
          ------------
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            fontWeight="bolder"
            fontFamily="monospace"
          
            
          >
          ------------
          </Typography>
          
      
        </Box> */}

          {/* <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: "120px",
          marginRight: "400px",
          marginBottom: "20px",
        }}
      >
           <Typography
          variant="body2"
          gutterBottom
          fontWeight="bolder"
          fontFamily="monospace"
          
        >
        Dealing Asstt.
        </Typography>
      
        <Typography
          variant="body2"
          gutterBottom
          fontWeight="bolder"
          fontFamily="monospace"
         
          
        >
         Incharge/Supdt.
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          fontWeight="bolder"
          fontFamily="monospace"
         
          
        >
         Asstt Comm.
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          fontWeight="bolder"
          fontFamily="monospace"
         
          
        >
         Reg.Commissioner
        </Typography>
        
     
      </Box> */}

          <Typography
            variant="body2"
            align="left"
            marginLeft={"120px"}
            gutterBottom
            fontWeight="bolder"
            fontFamily="monospace"
          >
            Paid by cheque No./ Transaction Id / UTR No. xxxxxxxxxxxx Dated{" "}
            {data.dateOfClaim} for Rs.
            {formattedNumber}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginLeft: "120px",
              marginRight: "400px",
              marginBottom: "20px",
            }}
          >
            <Typography
              variant="body2"
              gutterBottom
              fontWeight="bolder"
              fontFamily="monospace"
            ></Typography>
          </Box>
          <Typography
            component="div"
            style={{ display: "flex", alignItems: "center" }}
          >
            {filteredAndSortedTrack.map((item, index) => (
              <React.Fragment key={index}>
                <Typography
                  variant="body1"
                  component="span"
                  fontFamily="monospace"
                >
                  <b>{`${item.shName}:`}</b>
                  {` ${item.name}`}
                </Typography>
                {index !== filteredAndSortedTrack.length - 1 && (
                  <Typography variant="body1" component="span">
                    &rarr;
                  </Typography>
                )}
              </React.Fragment>
            ))}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginLeft: "120px",
              marginRight: "400px",
              marginBottom: "20px",
            }}
          >
            <Typography
              variant="body2"
              gutterBottom
              fontWeight="bolder"
              fontFamily="monospace"
            >
              ------------
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              fontWeight="bolder"
              fontFamily="monospace"
            >
              ------------
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginLeft: "120px",
              marginRight: "400px",
              marginBottom: "20px",
            }}
          >
            <Typography
              variant="body2"
              gutterBottom
              fontWeight="bolder"
              fontFamily="monospace"
            >
              Cashier
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              fontWeight="bolder"
              fontFamily="monospace"
            >
              Cheque Signing Officer
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",

              marginBottom: "20px",
            }}
          >
            <Typography
              variant="body2"
              gutterBottom
              fontWeight="bolder"
              fontFamily="monospace"
            >
              .
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              fontWeight="bolder"
              fontFamily="monospace"
            >
              .
            </Typography>
          </Box>
        </Box>
      </div>
    </div>
  );
};

const GenerateAdvPO = () => {
  const { state } = useLocation();
  const { advClaimData, memberData, objectTobeSent, loginId } = state;
  const { advClaim, claimType, tAdvSpecificDetails } = advClaimData;
  // const [base64, setBase64] = useState();
  const AdvPORefElement = useRef(null);
  console.log("jay", objectTobeSent);
  const loginOffcr = loginId.substring(0, 2);
  const navigate = useNavigate();

  const handleCancel = () => {
    if (loginOffcr == "RH") {
      navigate("/rh/advset/viewadvclaimrh", {
        state: { passingId: advClaim.claimId },
      });
    } else if (loginOffcr == "BO") {
      navigate("/bo/advset/viewadvclaimbo", {
        state: { passingId: advClaim.claimId },
      });
    }
  };

  const exportPDF = () => {
    const compressionQuality = 0.4; // adjust the quality level
    html2canvas(AdvPORefElement.current, { scale: 2.2, allowTaint: true }).then(
      (canvas) => {
        const imageData = canvas.toDataURL("image/jpeg", compressionQuality);
        const pdf = new jsPDF("p", "mm", "a4");
        // const contentHeight1 = 580 * 2; // Double the content height for the second page
        // const pageWidth = 180;
        // const pageHeight1 = 480;
        // const contentHeight = canvas.height / 4.377;
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        // console.log("contentHeight1", contentHeight);
        // console.log("pageWidth1", pageWidth);
        // console.log("pageHeight1", pageHeight);

        // Add content to the first page
        pdf.addImage(
          imageData,
          "JPEG",
          15,
          15,
          pageWidth - 30,
          pageHeight - 90
        );
        let apiRun = 1;
        const base64PDF = pdf.output("datauristring");
        let base64PDFf = base64PDF.replace(
          /^data:.+;base64,/,
          `data:application/pdf;base64,JVBERi0xLjQKJdPr6eEKMSAwIG9iago8PC9`
        );
        //  pdf.save("output.pdf");
        // console.log(base64PDF);
        // e.preventDefault();
        const objectofPo = {
          claimId: advClaim.claimId,
          poNo: "PO" + advClaim.claimId,
          payOrder: base64PDFf,
        };
        const url = process.env.REACT_APP_SERVER_URL + "/upload/advance/po";
        const headers = {
          //'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        axios
          .post(url, objectofPo, { headers })
          .then(function (response) {
            // setIsReturning(false);
            if (loginOffcr == "RH") {
              navigate("/rh/advset/viewadvclaimrh", {
                state: {
                  passingId: advClaim.claimId,
                  pdf: base64PDFf,
                  apiData: objectTobeSent,
                  apiRun: apiRun,
                },
              });
            } else if (loginOffcr == "BO") {
              navigate("/bo/advset/viewadvclaimbo", {
                state: {
                  passingId: advClaim.claimId,
                  pdf: base64PDFf,
                  apiData: objectTobeSent,
                  apiRun: apiRun,
                },
              });
            }
            // setIsSuccess(true);
            // setSuccessMessage("Claim Forwarded Successfully.");
            console.log("Claim Returned Successfully");
            // setIsReturnRodaClicked(false);
          })
          .catch((error) => {
            console.log("error on submit", error);
            if (loginOffcr == "RH") {
              navigate("/rh/advset/viewadvclaimrh", {
                state: {
                  passingId: advClaim.claimId,
                  pdf: base64PDFf,
                  apiData: objectTobeSent,
                },
              });
            } else if (loginOffcr == "BO") {
              navigate("/bo/advset/viewadvclaimbo", {
                state: {
                  passingId: advClaim.claimId,
                  pdf: base64PDFf,
                  apiData: objectTobeSent,
                },
              });
            }
            // setIsError(true);
            // setErrorMessage(
            //   "Sorry Could not Proceed!! Please try again later"
            // );
          });
      }
    );
  };

  return (
    <div>
      <div style={{ justifyContent: "flex-end" }}>
        <div ref={AdvPORefElement}>
          <AdvPo />
        </div>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid
            item
            // marginTop={3}
            // marginBottom={3}
            // marginRight="20px"
            // style={{ display: "flex", justifyContent: "center" }}
          >
            <Button onClick={handleCancel} variant="contained" color="primary">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={exportPDF} variant="contained" color="success">
              submit
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default GenerateAdvPO;
