import React from "react";
import { Typography } from "@mui/material";
const ViewFamilyMember=()=>{
    return(<div id="view-family-member">
     <Typography variant="h6">VIEW FAMILY MEMBER</Typography>
    <Typography varient="body1" align="justify">
    <p>To be implemented in Phase - II</p>
    </Typography>
    </div>);
}
export default ViewFamilyMember;