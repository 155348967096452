/**
 * @returns a table with Headers, first column as link, and checkboxes
 * props : preview , data, passingId, actions, viewLink
 * for table without checkboxes, send actions prop as null from the parent
 * for table without the first column as link, send viewLink as null
 * for further details refer to the documentation
 */

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import uuid from "react-uuid";
import { TablePagination, Checkbox } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#526D82",
    color: theme.palette.common.white,
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.3,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF9FF",
  },
  "&:last-child td, &:last-child th": {
    border: 1,
    backgroundColor: "#FFFFFF",
  },
}));

function TableWithLinkAndCheckbox(props) {
  const {
    preview,
    data,
    passingId,
    actions,
    viewLink,
    size,
    checkboxChange,
    onSelectedChange,
    resetSelectedClaims,
  } = props;

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);

  const tableHeaders = preview && preview[0] ? Object.keys(preview[0]) : [];

  function createHeader() {
    return (
      <TableRow>
        {tableHeaders.map((title) => (
          <StyledTableCell key={title} align="center">
            {title}
          </StyledTableCell>
        ))}
        {actions && preview.length > 0 && (
          <StyledTableCell align="center">Select</StyledTableCell>
        )}
      </TableRow>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      console.log("in check box", event.target.checked);
      setSelected([...selected, id]);
      console.log("selected claim in component", selected);
      checkboxChange([selected, id]);
    } else {
      setSelected(selected.filter((selectedId) => selectedId !== id));
      //  console.log("selected claim in component", selected);
    }
  };

  useEffect(() => {
    // Trigger the callback whenever `selected` state changes
    if (resetSelectedClaims) {
      setSelected([]);
      // console.log("jai");
    }
  }, [selected, resetSelectedClaims]);

  useEffect(() => {
    // Trigger the callback whenever `selected` state changes

    onSelectedChange(selected);
  }, [selected, onSelectedChange]);

  function createRows() {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return preview.slice(startIndex, endIndex).map((row, index) => {
      return (
        <StyledTableRow key={index}>
          {tableHeaders.map((key, i) => (
            <StyledTableCell key={uuid()} align="center">
              {i === 0 && viewLink ? (
                <Link to={viewLink} state={{ passingId: row[key] }}>
                  {row[key]}
                </Link>
              ) : (
                row[key]
              )}
            </StyledTableCell>
          ))}
          {actions && (
            <StyledTableCell key={uuid()} align="center">
              <Checkbox
                size="small"
                sx={{ padding: "0 0 0 0" }}
                checked={selected.includes(data[index + startIndex])}
                onChange={(event) =>
                  handleCheckboxChange(event, data[index + startIndex])
                }
              />
            </StyledTableCell>
          )}
        </StyledTableRow>
      );
    });
  }

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} size={size} aria-label="a dense table">
        <TableHead>{createHeader()}</TableHead>
        <TableBody>{createRows()}</TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={preview.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

export default TableWithLinkAndCheckbox;
