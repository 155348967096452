import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, styled } from '@mui/material';

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: '50px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
        minHeight: '50px',
    },
    [theme.breakpoints.up('md')]: {
        minHeight: '50px',
    },
}));

const Footbar = () => {
    return (
        <div>
            <AppBar position="static" sx={{ backgroundColor: '#484d53', marginTop:'20px' }}>
                <CustomToolbar>
                    <Link href="#" color="inherit" style={{ textDecoration: 'none', fontWeight: '600', borderRight: '1px solid white', padding: '0 10px', fontSize: '14px', color: 'white' }}>
                        Privacy Policy
                    </Link>
                    <Link to="/termsandcondition" color="inherit" style={{ textDecoration: 'none', fontWeight: '600', borderRight: '1px solid white', padding: '0 10px', fontSize: '14px', color: 'white' }}>
                        Terms and Conditions
                    </Link>
                    <Link to="/contact" color="inherit" style={{ textDecoration: 'none', fontWeight: '600', borderRight: '1px solid white', padding: '0 10px', fontSize: '14px', color: 'white' }}>
                        Contact Us
                    </Link>
                </CustomToolbar>
            </AppBar>
        </div>
    );
};

export default Footbar;
