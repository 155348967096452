import { Grid, Paper, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import SubtitleText from "../../FormComponent/SubtitleText";
import MemberDetails from "./MemberDetatils";
import TableWithLinkAndButton from "../../FormComponent/TableWithLinkAndButton";
import axios from "axios";
import { useLocation } from "react-router-dom";
import EditAdvClaimForm from "./EditAdvClaimForm";

function EditAdvancePF() {
  const { state } = useLocation();
  // const claimId = state.passingId;
  const claimId = "AD3000001";
  //   const claimType = claimId.substring(0, 2);

  const [accNo, setAccNo] = useState("");
  const sampleMemberData = {
    // This is the sample claimData data to be displed for demo purpose only
    titleId: "",
    fullName: "",
    fatherHusbandName: "",
    gender: "",
    maritalStatus: "",
    religionName: "",
    dateOfBirth: "",
    addressL1: "",
    addressL2: "",
    village: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
    mobileNo: "",
    landNo: "",
    email: "",
    panNo: "",
    aadhaarNumber: "",
    cmpfAccNo: "",
    employeeId: "",
    unitId: "",
    unitName: "",
    name: "",
    joiningDate: "",
    pfStartDate: "",
    pfEndDate: "",
    pfBankId: "",
    pfBankName: "",
    pfBankIfsc: "",
    pfAddress: "",
    penBankId: "",
    penBankName: "",
    penBankIfsc: "",
    penAddress: "",
    bankAccNoPf: "",
    bankAccNoPen: "",
  };

  const sampleClaimData = {
    advclaim: {
      claimDetails: {
        aaoConfrmPayment: "",
        advClaimStatusId: "",
        advClaimTypeId: "",
        amountApplied: "",
        claimId: "",
        claimType: "",
        cmpfAccNo: "",
        correspondanceAddress: "",
        dateReceived: "",
        declarationUda: "",
        dobAadhaar: "",
        dobPan: "",
        emolumentsLast36m: "",
        memberId: "",
        nameAadhaar: "",
        nameBank: "",
        namePan: "",
        nmAadhaarMatch: "",
        nmBankMatch: "",
        nmPanMatch: "",
        periodOfMembship: "",
        settlementDate: "",
        sfwhName: "",
        sfwhRelationType: "",
        udaLoginId: "",
      },
      tAdvSpecificDetails: {
        advClaimSrno: "",
        advSpecificDetSrno: "",
        amtAlrdyPaid: "",
        anticptdDateOfMarriage: "",
        dependentId: "",
        estimatedCost: "",
        examPassed: "",
        examYear: "",
        feesPaid: "",
        locationDet: "",
        saleAgreeAmt: "",
        sanctionedAmtDet: "",
        siteMeasurementUnit: "",
        siteMeasurementVal: "",
      },
    },
  };

  const [advClaimData, setAdvClaimData] = useState(sampleClaimData);
  const [memberData, setMemberData] = useState(sampleMemberData);
  const [lazerData, setLazerData] = useState();
  const [widhrolData, setWidhrolData] = useState();
  const [isMemberFound, setIsMemberFound] = useState(" ");
  const [mappedData, setMappedData] = useState([]);

  //**********for integration************* */
  const [isLoading, setIsLoading] = useState(false); // set this to true
  const [isFailed, setIsFailed] = useState(false);

  //********DEFAULT for family details*********/
  const familyDefault = {
    familyMemId: "",
    name: "",
    gender: "",
    relationName: "",
    dateOfBirth: "",
    pfNomSharePerc: "",
  };

  const [nomineeDetails, setNomineeDetails] = useState([familyDefault]);

  // ******** setting props for the TableWithLinkAndButton component*******

  const viewLink = "/familydetails"; // Link for family members details

  const createTablePreviewDataReturn = () => {
    // console.log("from preview",nomineeDetails);
    // the functions that creates a table preview
    //const tableSamples = familyMemberSamples; //familyMemberSamples must be replaced witht he response data recevied on the api request
    const tableSamples = nomineeDetails;
    let tablePreviewData = [];
    tableSamples.map((claimData) => {
      tablePreviewData.push({
        "Family Member ID": claimData.familyMemId,
        "Name of Nominee": claimData.name,
        Gender: claimData.gender,
        Relation: claimData.relationName,
        "Date of Birth": claimData.dateOfBirth,
        "Share in percentage": claimData.pfNomSharePerc,
      });
    });
    return tablePreviewData;
  };

  const handleAccSearch = () => {
    fetchMemberDetail();
    fetchNomineeDetail();
    fetchLazerData();
    fetchwidhrolData();
  };
  //   const onChange = (e) => {
  //     const value = e.target.value.toUpperCase().trim();
  //     setAccNo(value);
  //   };

  useEffect(() => {
    fetchAllClaimDetail();
  }, []);

  const fetchClaimDetailAdv = async () => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    // const url = process.env.REACT_APP_SERVER_URL + "/show/pf/claim/on/claimid";
    const url =
      process.env.REACT_APP_SERVER_URL +
      "/fetch/advance/pf/claim/details/by/id";

    const formData = new FormData();
    formData.append("claimId", claimId);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;

          // setDpoData(data.claimDetails[0].draftPayOrder);
          setAdvClaimData(data);
          setAccNo(data.cmpfAcNo);
          fetchMemberDetail(data.cmpfAcNo);
          return;
        }
        // setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
      });
  };

  const fetchAllClaimDetail = () => {
    fetchClaimDetailAdv();
  };
  ///****************integration started********************* */

  const fetchMemberDetail = async (accNo) => {
    setIsLoading(true);
    // console.log("in fetch Member detail");

    //  if (!(await sessionExist())) {
    //    console.log("session extending having trouble");
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    // console.log("abc");
    const url = process.env.REACT_APP_SERVER_URL + "/member/details/for/pf";
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsLoading(false);
        setIsFailed(false);
        //console.log("response", response);
        if (response.status === 200) {
          const data = response.data.data;
          console.log("jat", data);
          if (data.length !== 0) {
            setMemberData(data[0]);
            setIsMemberFound("true");
          } else {
            setIsFailed(true);
            setIsMemberFound("false");
          }
          // setIsMemberFound("true");
          return;
        } else {
          setIsFailed(true);
          setIsMemberFound("false");
        }
        // setIsFailed(true);
        // setIsMemberFound("false");
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        // console.log("error", error);
        setIsMemberFound("false");
      });
  };

  const fetchLazerData = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + "/get/pf/ledger/details";
      const formData = new FormData();
      formData.append("cmpfAcNO", accNo);
      const config = {
        headers: {
          // Include the Authorization header if req uired
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      // console.log("fetchLazerData", data);
      setLazerData(data); // Set the fetched data to the tablePreview state // we are taking data[0] as the response recevied is an array of object and we need only the first element
      //   setIsLoading(false);
    } catch (error) {}
  };

  const fetchwidhrolData = async () => {
    try {
      const url =
        process.env.REACT_APP_SERVER_URL + "/get/pf/advance/withd/details";
      const formData = new FormData();
      formData.append("cmpfAcNO", accNo);
      const config = {
        headers: {
          // Include the Authorization header if req uired
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      // console.log("fetchwidhrolData", data);
      setWidhrolData(data); // Set the fetched data to the tablePreview state // we are taking data[0] as the response recevied is an array of object and we need only the first element

      //   setIsLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    const mapped = (lazerData ?? []).map((lazerItem, index) => {
      // Calculate financial year for lazerData
      const lazerFinancialYear = new Date(
        lazerItem.currPeriodEnd
      ).getFullYear();

      const matchingWidhrolData = (widhrolData ?? [])
        .filter((widhrolItem) => {
          // Calculate financial year for widhrolData
          let widhrolFinancialYear = new Date(
            widhrolItem.withdrwlDate
          ).getFullYear();

          if (new Date(widhrolItem.withdrwlDate).getMonth() >= 3) {
            // If the date is after March, add 1 to the financial year
            widhrolFinancialYear += 1;
          }

          return lazerFinancialYear === widhrolFinancialYear;
        })
        .map((widhrolItem) => ({
          ...widhrolItem,
          serialNumber: index + 1,
        }));

      return {
        serialNumber: index + 1,
        ...lazerItem,
        widhrolData: matchingWidhrolData,
      };
    });

    const mappedDataObject = {};
    mapped.forEach((item) => {
      mappedDataObject[item.serialNumber] = item;
    });

    setMappedData(mappedDataObject);
  }, [lazerData, widhrolData]);

  // console.log("Mapped Data:", mappedData);

  /***** function to get all nominee details**************** */
  const fetchNomineeDetail = async () => {
    setIsLoading(true);
    // console.log("in fetch Member detail");

    //  if (!(await sessionExist())) {
    //    console.log("session extending having trouble");
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");

    const url = process.env.REACT_APP_SERVER_URL + "/get/all/nominee/details";
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo); // check what formdata is to be sent
    // console.log("formData", formData);
    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsLoading(false);
        setIsFailed(false);
        // console.log("api is getting called");
        if (response.status === 200) {
          const data = response.data.data;
          if (data.length !== 0) {
            setNomineeDetails(data);
          }

          return;
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        // console.log("error", error);
      });
  };

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            <Grid item xs={12}>
              <TitleText title="PF Advance Claim Submission" />
              <FormDivider />
              {isMemberFound === "true" && (
                <>
                  {/* Member Details*/}
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="start"
                    rowGap={3}
                  >
                    {/* section 1  */}
                    <Grid item xs={12}>
                      <MemberDetails member={memberData} />
                    </Grid>
                    {/* ************************************** */}
                    {/* Nominee status */}

                    {/* section 3  */}
                    <Grid item xs={12}>
                      <SubtitleText title="Nomination Status" />
                    </Grid>
                    <Grid item xs={12}>
                      <TableWithLinkAndButton
                        preview={createTablePreviewDataReturn()}
                        data={nomineeDetails}
                        passingId="familyMemberid"
                        actions=""
                        viewLink={viewLink}
                      />
                    </Grid>
                    {/* **************Claim submission******** */}
                    <Grid item xs={12}>
                      {mappedData && (
                        <EditAdvClaimForm
                          memberData={memberData}
                          nomineeDetails={nomineeDetails}
                          mappedData={mappedData}
                          advClaimData={advClaimData}
                          // lazerData={lazerData}
                        />
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
              {isMemberFound === "false" && (
                <Typography color="red">Member not found</Typography>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default EditAdvancePF;
