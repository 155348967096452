import React, { useState } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import ValueText from "./ValueText";
import SubtitleText from "./SubtitleText";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

function LogoutAlert(props) {
  const navigate = useNavigate();

  const [isLogouting, setIsLogouting] = useState(false);

  const { title, open, body, closeAlert, user } = props;

  const handleCancel = () => {
    closeAlert();
  };

  const logoutLocally = () => {
    localStorage.clear();
    sessionStorage.clear();
    setIsLogouting(false);
    closeAlert();
    navigate("/cmpfo", { replace: true });
  };

  const memberLogout = () => {
    let token = localStorage.getItem("jwt");
    if (!token) {
      logoutLocally();
      return;
    }
    token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + "/member/logout";

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .post(url, null, config)
      .then((resp) => {
        logoutLocally();
      })
      .catch(function (error) {
        memberLogout();
        console.log("error.. in logout", error);
      });
  };

  const officerLogout = () => {
    let token = localStorage.getItem("jwt");
    if (!token) {
      logoutLocally();
      return;
    }
    token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + "/officer/logout";

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    
    axios
   .post(url,null,config)
      //  .post(url, null)
      .then((resp) => {
        logoutLocally();
      })
      .catch(function (error) {
        officerLogout();
        console.log("error.. in logout", error);
      });
  };

  const handleLogout = () => {
    if (user === "member") {
      setIsLogouting(true);
      memberLogout();
    }
    if (user === "officer") {
      setIsLogouting(true);
      officerLogout();
    }
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
    >
      <DialogTitle>
        <SubtitleText title={title} />
      </DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ValueText title={body} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLogouting} autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          disabled={isLogouting}
          variant="outlined"
          onClick={handleLogout}
        >
          {isLogouting ? <CircularProgress sx={{ color: "red" }} /> : "Log Out"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LogoutAlert;
