import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Paper, Box, Button, Divider } from "@mui/material";
import { memberModel } from "../../Models/defaultModel";

import TitleText from "../../FormComponent/TitleText";
import LabelText from "../../FormComponent/LabelText";
import CustomTextField from "../../FormComponent/CustomTextField";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import GroupedRadioButton from "../../FormComponent/GroupedRadioButton";
import SubtitleText from "../../FormComponent/SubtitleText";
import CustomTextArea from "../../FormComponent/CustomTextArea";
import LoadingIndicator from "../../FormComponent/LoadingIndicator";
import FormDivider from "../../FormComponent/FormDivider";
import { faClosedCaptioning } from "@fortawesome/free-solid-svg-icons";

function MemberForm() {
  const navigate = useNavigate();

  const defaultData = memberModel;

  const [member, setMember] = useState(defaultData);

  const [readOnly, setReadOnly] = useState(true);

  const [isProgramLoaded, setIsProgramLoaded] = useState(true);

  const memberSample = {};

  useEffect(() => {
    // switchUi();
    setMember(memberSample);
    setIsProgramLoaded(true);
  }, []);

  //Configuraton

  const title_id = {
    name: "title_id",
    // label: "salutation",
    helperText: "",
    disabled: readOnly,
    initialValue: member.title_id,
    options: [
      { id: "1", title: "Mr" },
      { id: "2", title: "Ms" },
      { id: "3", title: "Mrs" },
      { id: "4", title: "Dr" },
      { id: "5", title: "Other" },
    ],
  };

  const religion_id = {
    name: "religion_id",
    placeholder: "religion",
    helperText: "",
    disabled: readOnly,
    initialValue: member.religion_id,
    options: [
      { id: "1", title: "Hindu" },
      { id: "2", title: "Christian" },
      { id: "3", title: "Muslim" },
      { id: "4", title: "Jain" },
      { id: "5", title: "Sikh" },
      { id: 6, title: "Buddhist" },
      { id: 7, title: "Other" },
    ],
  };

  const gender = {
    options: [
      { value: "Male", label: "Male" },
      { value: "Female", label: "Female" },
      { value: "Other", label: "Other" },
    ],
    name: "gender",
    initialValue: member.gender,
    disabled: readOnly,
  };

  const marital_status = {
    name: "marital_status",
    placeholder: "Marital_status",
    helperText: "",
    disabled: readOnly,
    initialValue: member.marital_status,
    options: [
      { id: "Single", title: "Single" },
      { id: "Married", title: "Married" },
      { id: "Divorced", title: "Divorced" },
      { id: "Widow", title: "Widow" },
      { id: "Widower", title: "Widower" },
    ],
  };

  const firstName = {
    name: "firstName",
    type: "text",
    helperText: "",
    placeholder: "First Name",
    defaultValue: member.firstName,
    errorMessage:
      "First Name must be characters only and not allowed special characters except white space.",
    // label: "First Name",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const middleName = {
    name: "middleName",
    type: "text",
    helperText: "",
    defaultValue: member.middleName,
    placeholder: "Middle Name",
    errorMessage:
      "Middle Name must be characters only and not allowed special characters except white space.",
    // label: "Middle Name",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const lastName = {
    name: "lastName",
    type: "text",
    helperText: "",
    placeholder: "Last name",
    defaultValue: member.lastName,
    errorMessage:
      "Last Name must be characters only and not allowed special characters except white space.",
    // label: "Last Name",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const date_of_birth = {
    name: "date_of_birth",
    type: "date",
    helperText: "",
    defaultValue: member.date_of_birth,
    errorMessage: "date Of Birth",
    // label: "Date Of Birth",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const pan_no = {
    name: "pan_no",
    type: "text",
    placeholder: "PAN No.",
    helperText: "",
    defaultValue: member.pan_no,
    errorMessage: "Pan should be 12 digis with out spaces",
    // label: "Pan ID",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const aadhaar_no = {
    name: "aadhaar_no",
    type: "text",
    placeholder: "Aadhaar No",
    helperText: "",
    defaultValue: member.aadhaar_no,
    errorMessage: "aadhar should be 16 digits with out spaces",
    // label: "Aadhar ID",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const fath_hus_name = {
    name: "fath_hus_name",
    type: "text",
    placeholder: "Father/Hus Name",
    helperText: "",
    defaultValue: member.fath_hus_name,
    errorMessage: "email should be correct format",
    // label: "pension_percentage",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const email_id = {
    name: "email_id",
    type: "text",
    placeholder: "Email ID",
    helperText: "",
    defaultValue: member.email_id,
    errorMessage: "email should be correct format",
    // label: "email_id",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const mobile_no = {
    name: "mobile_no",
    type: "text",
    placeholder: "Mobile Number",
    helperText: "",
    defaultValue: member.mobile_no,
    errorMessage: "email should be correct format",
    // label: "mobile_no",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const land_no = {
    name: "land_no",
    type: "text",
    placeholder: "LandLine Number",
    helperText: "",
    defaultValue: member.land_no,
    errorMessage: "email should be correct format",
    // label: "mobile_no",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const cmpf_acc_no = {
    name: "cmpf_acc_no",
    type: "text",
    placeholder: "CMPF Account Number.",
    helperText: "",
    defaultValue: member.cmpf_acc_no,
    errorMessage: "Pan should be 12 digis with out spaces",
    // label: "Pan ID",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const employee_id = {
    name: "employee_id",
    type: "text",
    placeholder: "Employee Id.",
    helperText: "",
    defaultValue: member.employee_id,
    errorMessage: "Pan should be 12 digis with out spaces",
    // label: "Pan ID",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const colliery_id = {
    name: "colliery_id",
    type: "text",
    placeholder: "Colliery Id.",
    helperText: "",
    defaultValue: member.colliery_id,
    errorMessage: "Pan should be 12 digis with out spaces",
    // label: "Pan ID",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const colliery_name = {
    name: "colliery_name",
    type: "text",
    placeholder: "Colliery Name.",
    helperText: "",
    defaultValue: member.colliery_name,
    errorMessage: "Pan should be 12 digis with out spaces",
    // label: "Pan ID",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const coal_company_name = {
    name: "coal_company_name",
    type: "text",
    placeholder: "Colliery Company Name.",
    helperText: "",
    defaultValue: member.coal_company_name,
    errorMessage: "Pan should be 12 digis with out spaces",
    // label: "Pan ID",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const joining_date = {
    name: "joining_date",
    type: "date",
    helperText: "",
    defaultValue: member.joining_date,
    errorMessage: "date Of Birth",
    // label: "Date Of Birth",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const pf_start_date = {
    name: "pf_start_date",
    type: "date",
    helperText: "",
    defaultValue: member.pf_start_date,
    errorMessage: "date Of Birth",
    // label: "Date Of Birth",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const pf_end_date = {
    name: "pf_end_date",
    type: "date",
    helperText: "",
    defaultValue: member.pf_end_date,
    errorMessage: "date Of Birth",
    // label: "Date Of Birth",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const address_l1 = {
    name: "address_l1",
    placeholder: "Home Address",
    helperText: "",
    defaultValue: member.address_l1,
    errorMessage: "email should be correct format",
    // label: "pension_percentage",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const onChange = (e) => {
    setMember({ ...member, [e.target.name]: e.target.value });
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
          {!isProgramLoaded ? (
            <LoadingIndicator />
          ) : (
            <>
              <Grid item xs={12}>
                <TitleText title="My MemberForm" />
              </Grid>

              <FormDivider />

              {/* <Form> */}
              <Grid
                container
                justifyContent="space-between"
                alignItems="start"
                rowGap={2}
              >
                {/* section 1  */}
                <Grid item xs={12} sm={12}>
                  <SubtitleText title="Personal Information" />
                </Grid>

                {/* column 1  */}

                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                    columnGap={1}
                  >
                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Salutation" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomDropdown {...title_id} onchange={onChange} />
                    </Grid>

                    {/* row  */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="First Name" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...firstName} onchange={onChange} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Middle Name" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...middleName} onchange={onChange} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Last Name" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...lastName} onchange={onChange} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Date of Birth" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...date_of_birth} onchange={onChange} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="PAN Number" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...pan_no} onchange={onChange} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Aadhaar Number" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...aadhaar_no} onchange={onChange} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Marital Status" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomDropdown {...marital_status} onchange={onChange} />
                    </Grid>
                  </Grid>
                </Grid>

                {/* column 2  */}

                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    columnGap={1}
                  >
                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Religion" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomDropdown {...religion_id} onchange={onChange} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Gender" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <GroupedRadioButton {...gender} onchange={onChange} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Name of Father/Husband" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...fath_hus_name} onchange={onChange} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Email ID" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...email_id} onchange={onChange} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Mobile Number" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...mobile_no} onchange={onChange} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Landline Number" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...land_no} onchange={onChange} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Permanant Address" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextArea {...address_l1} onchange={onChange} />
                    </Grid>
                  </Grid>
                </Grid>

                {/* ************************************** */}
                {/* section 2  */}
                <Grid item xs={12} sm={12}>
                  <SubtitleText title="Employement Details" />
                </Grid>

                {/* column 1  */}

                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                    columnGap={1}
                  >
                    {/* row  */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="CMPF Account Number" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...cmpf_acc_no} onchange={onChange} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Colliery ID" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...colliery_id} onchange={onChange} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Coal Company Name" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField
                        {...coal_company_name}
                        onchange={onChange}
                      />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="PF Start Date" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...pf_start_date} onchange={onChange} />
                    </Grid>
                  </Grid>
                </Grid>

                {/* column 2  */}

                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    columnGap={1}
                  >
                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Employee ID" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...employee_id} onchange={onChange} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Colliery Name" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...colliery_name} onchange={onChange} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Joining Date" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...joining_date} onchange={onChange} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="PF End Date" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...pf_end_date} onchange={onChange} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default MemberForm;
