import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const ExampleComponent = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item) => {
    setSelectedItem(item);
    setAnchorEl(null);
  };

  const handleGoButtonClick = () => {
    if (selectedItem) {
      console.log("Go button clicked with item: ", selectedItem);
      // Add your logic for handling the go button click here
    }
  };

  const handleRevertClick = () => {
    // Add your logic for handling the revert button here
    console.log("Revert button clicked");
  };

  const handleAssignClick = () => {
    // Add your logic for handling the assign button click here
    console.log("Assign button clicked");
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Return
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleClose("Office A")}>Office A</MenuItem>
        <MenuItem onClick={() => handleClose("Office B")}>Office B</MenuItem>
        <MenuItem onClick={() => handleClose("Office C")}>Office C</MenuItem>
      </Menu>
      {selectedItem && (
        <div>
          <p>Selected Office: {selectedItem}</p>
          <Button onClick={handleGoButtonClick} disabled={!selectedItem}>
            Go
          </Button>
        </div>
      )}
      <div style={{ marginTop: "10px" }}>
        <Button variant="contained" color="primary" onClick={handleRevertClick}>
          Revert
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleAssignClick}
        >
          Assign
        </Button>
      </div>
    </div>
  );
};

export default ExampleComponent;
