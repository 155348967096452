import { Button, CircularProgress, Grid, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import SubtitleText from "../../FormComponent/SubtitleText";
import MemberDetails from "../UDAPages/MemberDetatils";
import BackButton from "../../components/button/BackButton";
import axios from "axios";
import AdvClaimDetails from "../../SettlementComponents/AdvClaimDetails";
import { useLocation, useNavigate } from "react-router-dom";
import ViewDocuments from "../../SettlementComponents/ViewDocuments";
import CustomTextArea from "../../FormComponent/CustomTextArea";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import Reloader from "../../FormComponent/Reloader";
import LoadingIndicator from "../../FormComponent/LoadingIndicator";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import SucessAlert from "../../FormComponent/SuccessAlert";
import ConfirmationDialogue from "../../FormComponent/ConfirmationDialogue";
import jwtDecode from "jwt-decode";
import TableWithLinkAndButton from "../../FormComponent/TableWithLinkAndButton";
// import AdvPo from "../DpoAndPoPages/AdvPo";

function ViewAdvClaimBo() {
  const { state } = useLocation();
  const claimId = state.passingId;
  const claimType = claimId.substring(0, 2);
  const poPdf = state.pdf;
  const apiRun = state.apiRun;
  const apiSubmitData = state.apiData;

  const navigate = useNavigate();
  const sampleMemberData = {
    // This is the sample claimData data to be displed for demo purpose only
    title: "",
    fullName: "",
    dateOfBirth: "",
    panNo: "",
    aadhaarNumber: "",
    maritalStatus: "",
    religionName: "",
    gender: "",
    fatherHusbandName: "",
    email: "",
    mobileNo: "",
    landNo: "",
    addressL1: "",
    addressL2: "",
    village: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  };

  const sampleClaimData = {
    advclaim: {
      claimDetails: {
        aaoConfrmPayment: "",
        advClaimStatusId: "",
        advClaimTypeId: "",
        amountApplied: "",
        claimId: "",
        claimType: "",
        cmpfAccNo: "",
        correspondanceAddress: "",
        dateReceived: "",
        declarationUda: "",
        dobAadhaar: "",
        dobPan: "",
        emolumentsLast36m: "",
        memberId: "",
        nameAadhaar: "",
        nameBank: "",
        namePan: "",
        nmAadhaarMatch: "",
        nmBankMatch: "",
        nmPanMatch: "",
        periodOfMembship: "",
        settlementDate: "",
        sfwhName: "",
        sfwhRelationType: "",
        udaLoginId: "",
      },
      tAdvSpecificDetails: {
        advClaimSrno: "",
        advSpecificDetSrno: "",
        amtAlrdyPaid: "",
        anticptdDateOfMarriage: "",
        dependentId: "",
        estimatedCost: "",
        examPassed: "",
        examYear: "",
        feesPaid: "",
        locationDet: "",
        saleAgreeAmt: "",
        sanctionedAmtDet: "",
        siteMeasurementUnit: "",
        siteMeasurementVal: "",
      },
    },
  };

  const sampleDocumentData = {
    data: {
      0: {
        DocTypeid: "",
        Document: "",
      },
      1: {
        DocTypeid: "",
        Document: "",
      },
    },
  };
  const [advClaimData, setAdvClaimData] = useState(sampleClaimData);
  const [memberData, setMemberData] = useState(sampleMemberData);
  const [isMemberFound, setIsMemberFound] = useState(" ");
  const [documents, setDocuments] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [assignToOption, setAssignToOption] = useState([]);
  const [assignToChOption, setAssignToChOption] = useState([]);
  //**********for integration************* */
  const [isLoading, setIsLoading] = useState(true); // set this to true
  const [isLoadingDoc, setIsLoadingDoc] = useState(true); // set this to true
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isFailedDoc, setIsFailedDoc] = useState(false);
  const [isReturning, setIsReturning] = useState(false);
  const [isReturnClicked, setIsReturnClicked] = useState(false);
  const [isReturnRodaClicked, setIsReturnRodaClicked] = useState(false);
  const [showingDropdown, setShowingDropdown] = useState(0);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [selectedOfficerName, setSelectedOfficerName] = useState("");
  const [assignCalled, setAssignCalled] = useState(false);
  // we have set a value beforeids = 0 for show some field till ids
  let beforeids = 1;
  // let loginId = "BO-RO-OFF-451"; // Assuming a default loginId
  const [loginId, setLoginId] = useState();

  ///********fetching and setting token********* */
  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (token) {
      token = token.replace('"', "").replace('"', "");
      let loginId = jwtDecode(token).user; // Assuming a default loginId
      setLoginId(loginId);
      console.log("loooooooooooooooooooooginId", loginId);
      // Add any further logic here based on the loginId
    } else {
      // Handle the case when token is not present
      setIsFailed(true);
      setIsLoading(false);
    }
  }, []);
  const defaultClaim = {
    remarks: "",
    assignTo: "",
    assignToCh: "",
  };

  const [claim, setClaim] = useState(defaultClaim);
  const assignTo = {
    name: "assignTo",
    placeholder: "Select RH",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "",
    required: true,
    options: assignToOption,
  };

  const assignToCh = {
    name: "assignToCh",
    placeholder: "Select Ch",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "",
    required: true,
    options: assignToChOption,
  };

  const remarks = {
    name: "remarks",
    type: "text",
    multiline: true,
    helperText: "Enter your remarks (maximum 200 words)",
    placeholder: "Enter your remarks here",
    defaultValue: claim.remarks,
    errorMessage: "Cannot exceed 200 characters",
    pattern: "^.{0,200}$",
    maxLength: 200, // Limiting to 200
  };

  //********DEFAULT for family details*********/
  const familyDefault = {
    familyMemId: "",
    name: "",
    gender: "",
    relationName: "",
    dateOfBirth: "",
    pfNomSharePerc: "",
  };

  const [nomineeDetails, setNomineeDetails] = useState([familyDefault]);

  // ******** setting props for the TableWithLinkAndButton component*******

  const viewLink = "/familydetails"; // Link for family members details

  // Advance claim type integration]
  //****************Dropdown******************* */
  const fetchAssignOfficer = async () => {
    try {
      const url =
        process.env.REACT_APP_SERVER_URL +
        "/dropdown/for/show/next/authorized/offcr/adv/pf/pen/bo/to/rh";
      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      const options = data.map((option) => ({
        id: option.OffcrId,
        title: `${option.Name} (${option.OffcrId})`,
      }));
      setAssignToOption(options); // Assuming setAssignToOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchAssignOfficer();
  }, [loginId]);

  const fetchCashierOfficer = async () => {
    try {
      const url =
        process.env.REACT_APP_SERVER_URL +
        "/dropdown/for/advance/pf/claim/bo/to/ca";
      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      const options = data.map((option) => ({
        id: option.OffcrId,
        title: `${option.Name} (${option.OffcrId})`,
      }));
      setAssignToChOption(options); // Assuming setAssignToChOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchCashierOfficer();
  }, [loginId]);

  const fetchClaimDetailAdv = async () => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url =
      process.env.REACT_APP_SERVER_URL +
      "/fetch/advance/pf/claim/details/by/id";
    const formData = new FormData();
    formData.append("claimId", claimId);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          setAdvClaimData(data);
          fetchMemberDetail(data.cmpfAcNo);
          return;
        }
        // setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
      });
  };

  const fetchAllClaimDetail = () => {
    fetchClaimDetailAdv();
    fetchDocuments();
  };

  const fetchDocuments = async () => {
    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/fetch/advance/pf/claim/Documents/by/id`;
      const formData = new FormData();
      formData.append("claimId", claimId);

      const config = {
        headers: {
          // Include the Authorization header if required
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(url, formData, config);

      setIsLoadingDoc(false);
      setIsFailedDoc(false);

      if (response.status === 200) {
        const data = response.data.data.data;
        setDocuments(data);
      } else {
        setIsFailed(true);
      }
    } catch (error) {
      // Corrected syntax for the catch block
      setIsLoading(false);
      setIsFailed(true);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  useEffect(() => {
    fetchAllClaimDetail();
    // fetchDocumnetAdv();
  }, []);

  const { advClaim } = advClaimData;
  let draftPayOrder = advClaim?.draftPayOrder; // Use optional chaining here
  let payOrder = advClaim?.payOrder;
  // Combine data from the two APIs
  useEffect(() => {
    if (draftPayOrder) {
      const updatedCombinedData = [
        ...documents,
        {
          docTypeDesc: "DPO",
          uploadedDoc: draftPayOrder,
        },
      ];

      // Assuming advClaimData.advClaim.purchaseOrder is the document from the second API
      if (payOrder) {
        updatedCombinedData.push({
          docTypeDesc: "PO",
          uploadedDoc: payOrder,
        });
      }

      setCombinedData(updatedCombinedData);
    }
  }, [documents, advClaimData]);
  console.log("combinedData", combinedData);

  // cmpf account number is fetched on useEffect with a delay of 3sec to avoid multiple api hits
  const onChange = (e) => {
    if (e.target.name === "assignTo") {
      const selectedOption = assignToOption.find(
        (option) => option.id === e.target.value
      );
      if (selectedOption) {
        setSelectedOfficerName(selectedOption.title); // Set the selected officer's name in the state
        console.log("selctess", selectedOption.title);
      }
    } else if (e.target.name === "assignToCh") {
      const selectedOption = assignToOption.find(
        (option) => option.id === e.target.value
      );
      if (selectedOption) {
        setSelectedOfficerName(selectedOption.title); // Set the selected officer's name in the state
        console.log("selctess", selectedOption.title);
      }
    }
    setClaim({ ...claim, [e.target.name]: e.target.value });
  };

  /// ******** on submit function
  const objectTobeSentToRh = {
    fromOffcrLoginId: loginId,
    Remarks: claim.remarks,
    toOffcrId: claim.assignTo,
    ClaimId: claimId,
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    const url =
      process.env.REACT_APP_SERVER_URL + "/forward/advance/pf/claim/BO/to/RH";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSentToRh, { headers })
      .then(function (response) {
        setIsSubmitting(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Forwarded Successfully.");
      })
      .catch((error) => {
        setIsError(true);
        setIsSubmitting(false);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
        console.log("error on submit", error);
      });
  };

  /// ******** on submit function
  const objectTobeSent = {
    fromOffcrLoginId: loginId,
    Remarks: claim.remarks,
    toOffcrId: claim.assignToCh,
    ClaimId: claimId,
  };

  console.log("objectTobeSent", objectTobeSent);
  const handleAssign = (e) => {
    e.preventDefault();

    if (!objectTobeSent.toOffcrId) {
      // Show message in dialog: "Please select an officer to assign"
      //alert("Please select an officer to assign");
      setIsError(true);
      setErrorMessage("Please Select an Officer to Assign.");
      return;
    }
    setIsSubmitting(true);

    navigate("advpo", {
      state: { advClaimData, memberData, objectTobeSent, loginId },
    });
  };

  const handleApprove = () => {
    setIsSubmitting(true);

    const url =
      process.env.REACT_APP_SERVER_URL + "/forward/advance/pf/claim/BO/to/CA";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, apiSubmitData, { headers })
      .then(function (response) {
        setIsSubmitting(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Forwarded Successfully.");
      })
      .catch((error) => {
        setIsError(true);
        setIsSubmitting(false);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
        console.log("error on submit", error);
      });
  };

  useEffect(() => {
    // Check if poPdf is not null and handleAssign has not been called
    if (apiRun == 1) {
      // Call the handleApprove function
      handleApprove();
      // Update the state variable assignCalled to indicate that handleAssign has been called
      setAssignCalled(true);
    }
  }, []);

  /// ******** on Return to aao function

  const objectTobeSentOnReturn = {
    loginId: loginId,
    remark: claim.remarks,
    claimId: claimId,
  };

  const handleReturnClick = (e) => {
    e.preventDefault();

    if (!claim.remarks.trim()) {
      // Show message in dialog: "Please enter remarks"
      // alert("Please enter remarks");
      setIsError(true);
      setErrorMessage("Please enter Remarks.");
      return;
    }

    setIsReturning(true);
    setIsReturnClicked(true);
  };

  const handleReturnClaim = (e) => {
    // e.preventDefault();

    let url1 =
      process.env.REACT_APP_SERVER_URL + "/return/adv/pf/claim/bo/to/aao";
    let url2 =
      process.env.REACT_APP_SERVER_URL + "/return/adv/pf/claim/bo/to/roda";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url1, objectTobeSentOnReturn, { headers })
      .then(function (response) {
        setIsReturning(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Return Successfully.");
        setIsReturnClicked(false);

        return axios.post(url2, objectTobeSentOnReturn, { headers });
      })
      .then(function (response) {
        // handle success for the second API call if you want to do something
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          console.log("error on submit", error);
          setIsError(true);
          setErrorMessage("Sorry Could not Proceed!! Please try again later");
        }
      });
  };

  ///****************integration started********************* */

  const fetchMemberDetail = async (accNo) => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + "/member/details/for/pf";
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          console.log("member data", data);
          if (data.length !== 0) {
            setMemberData(data[0]);
            setIsMemberFound("true");
            fetchNomineeDetail(data[0].cmpfAccNo);
          }
          setIsMemberFound("false");
          return;
        }
        setIsFailed(true);
        setIsMemberFound("false");
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        setIsMemberFound("false");
      });
  };

  /***** function to get all nominee details**************** */
  const fetchNomineeDetail = async (accNo) => {
    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");

    const url = process.env.REACT_APP_SERVER_URL + "/get/all/nominee/details";
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo); // check what formdata is to be sent
    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsLoading(false);
        setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          console.log("nominee details", data);
          if (data.length !== 0) {
            setNomineeDetails(data);
          }

          return;
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
      });
  };
  /// error alert handler
  const handleErrorAlert = () => {
    setIsError(false);
  };

  const handleSuccess = () => {
    // setIsSuccess(false);
    navigate("/bo/advset");
  };

  const handleCancelReturn = () => {
    setIsReturnClicked(false);
  };
  const handleReturnOk = () => {
    handleReturnClaim();
  };

  const handleCancelReturnroda = () => {
    setIsReturnRodaClicked(false);
  };
  const handleReturnToRodaOk = () => {
    handleReturnToRodaClaim();
  };

  /// ******** on Return to RODA function

  const objectTobeSentOnReturnToRoda = {
    loginId: loginId,
    remark: claim.remarks,
    claimId: claimId,
  };

  const handleReturnToRodaClick = (e) => {
    e.preventDefault();

    if (!claim.remarks.trim()) {
      // Show message in dialog: "Please enter remarks"
      // alert("Please enter remarks");
      setIsError(true);
      setErrorMessage("Please enter Remarks.");
      return;
    }

    setIsReturning(true);
    setIsReturnRodaClicked(true);
  };
  const handleReturnToRodaClaim = (e) => {
    // e.preventDefault();

    const url =
      process.env.REACT_APP_SERVER_URL + "/return/adv/pf/claim/bo/to/roda";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSentOnReturnToRoda, { headers })
      .then(function (response) {
        setIsReturning(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Return Successfully.");

        //console.log("Claim Returned Successfully");
        setIsReturnRodaClicked(false);
      })
      .catch((error) => {
        console.log("error on submit", error);
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
      });
  };

  // todo handleAssignToOk calling handle submit on click of ok confirmation dialouge box
  const handleAssignToOk = (e) => {
    if (showingDropdown === 1) {
      handleSubmit(e);
    }
    setIsSubmitClicked(false);
  };

  const handleAssignToCancel = () => {
    setIsSubmitClicked(false);
  };

  const handleSubmitOk = () => {
    if (showingDropdown === 1) {
      if (!objectTobeSentToRh.toOffcrId) {
        // Show message in dialog: "Please select an officer to assign"
        // alert("Please select an officer to assign");
        setIsError(true);
        setErrorMessage("Please Select an Officer to Assign.");
      } else {
        setIsSubmitClicked(true);
      }
    }
  };

  // conditions fro display the dropdown
  const handleProcessOk = (e) => {
    setShowingDropdown(1);
  };

  const handleApproveOk = (e) => {
    setShowingDropdown(2);
  };

  const createTablePreviewDataReturn = () => {
    console.log("from preview", nomineeDetails);
    // the functions that creates a table preview
    //const tableSamples = familyMemberSamples; //familyMemberSamples must be replaced witht he response data recevied on the api request
    const tableSamples = nomineeDetails;
    let tablePreviewData = [];
    tableSamples.map((member) => {
      tablePreviewData.push({
        "Family Member ID": member.familyMemId,
        "Name of Nominee": member.name,
        Gender: member.gender,
        Relation: member.relationName,
        "Date of Birth": member.dateOfBirth,
        "Share in percentage": member.pfNomSharePerc,
      });
    });
    return tablePreviewData;
  };

  return (
    <div>
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error!"
        body={errorMessage}
        buttonName="Ok"
      />
      <SucessAlert
        handleOk={handleSuccess}
        open={isSuccess}
        title="Success"
        body={successMessage}
        buttonName="OK"
      />
      <ConfirmationDialogue
        handleOk={handleReturnOk}
        handleCancel={handleCancelReturn}
        open={isReturnClicked}
        title="Confirm Return"
        body="Are you sure you want to return this claim ?"
        buttonName="Confirm"
      />
      <ConfirmationDialogue
        handleOk={handleAssignToOk}
        handleCancel={handleAssignToCancel}
        open={isSubmitClicked}
        title="Confirm Assignment"
        body={`Are you sure you want to assign the claim to ${selectedOfficerName} ?`}
        buttonName="Confirm"
      />
      <ConfirmationDialogue
        handleOk={handleReturnToRodaOk}
        handleCancel={handleCancelReturnroda}
        open={isReturnRodaClicked}
        title="Confirm Return"
        body="Are you sure you want to return this claim ?"
        buttonName="Confirm"
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "10px" }}>
            {isFailed || isFailedDoc ? (
              <Reloader refreshHandler={fetchAllClaimDetail} />
            ) : (
              <>
                {isLoading || isLoadingDoc ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        <TitleText title="Advance Pf Claim" />
                      </Grid>
                    </Grid>
                    <FormDivider />
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={1} mb={3}>
                        <BackButton />
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="start"
                        rowGap={3}
                      >
                        {/* Member Details*/}
                        {/* section 1  */}
                        <Paper
                          elevation={3}
                          sx={{ padding: "3rem", borderRadius: "10px" }}
                        >
                          <Grid item xs={12}>
                            <MemberDetails member={memberData} />
                          </Grid>
                          <Grid item xs={12}>
                            <TableWithLinkAndButton
                              preview={createTablePreviewDataReturn()}
                              data={nomineeDetails}
                              passingId="familyMemberid"
                              actions=""
                              viewLink={viewLink}
                            />
                          </Grid>
                        </Paper>
                        {/* claim Details*/}
                        {/* section 2  */}
                        <Paper
                          elevation={3}
                          sx={{ padding: "3rem", borderRadius: "10px" }}
                        >
                          <Grid item xs={12}>
                            <AdvClaimDetails
                              advclaimdet={advClaimData}
                              beforeids={beforeids}
                            />
                          </Grid>
                        </Paper>
                        {/* Document Details*/}
                        {/* section 3  */}
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={12}>
                            <Paper
                              elevation={3}
                              sx={{ padding: "3rem", borderRadius: "10px" }}
                            >
                              <Grid item xs={12}>
                                {draftPayOrder ? (
                                  <ViewDocuments documents={combinedData} />
                                ) : (
                                  <ViewDocuments documents={documents} />
                                )}
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>

                        {/* ************************************ Remarks ***************************************** */}
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={12}>
                            <Paper
                              elevation={3}
                              sx={{ padding: "2rem", borderRadius: "10px" }}
                            >
                              <SubtitleText title="Remarks" />
                              <Grid item xs={12} sm={6} md={8}>
                                <CustomTextArea
                                  {...remarks}
                                  onchange={onChange}
                                />
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>
                        {/* *************** process and return to aao ********* */}

                        <Grid container justifyContent="end">
                          <Grid item xs={12}>
                            <Grid
                              container
                              justifyContent="end"
                              alignItems="center"
                              columnGap={2}
                            >
                              <Grid item md={2}>
                                <Button
                                  onClick={handleReturnClick}
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  // disabled={isReturnClicked}
                                  fullWidth
                                >
                                  Return to aao
                                </Button>
                              </Grid>
                              <Grid item md={2.5}>
                                <Button
                                  onClick={handleReturnToRodaClick}
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  // disabled={isReturnClicked}
                                  fullWidth
                                >
                                  Return to roda
                                </Button>
                              </Grid>
                              <Grid item md={2}>
                                <Button
                                  onClick={handleProcessOk}
                                  variant="contained"
                                  color="success"
                                  type="submit"
                                  // disabled={isReturnClicked}
                                  fullWidth
                                >
                                  Process
                                </Button>
                              </Grid>
                              <Grid item md={2}>
                                <Button
                                  onClick={handleApproveOk}
                                  variant="contained"
                                  color="success"
                                  type="submit"
                                  //style={{ marginLeft: 8 }}
                                  // disabled={isSubmitting}
                                  fullWidth
                                >
                                  Approve
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        {showingDropdown === 1 && (
                          <Grid
                            container
                            justifyContent="end"
                            alignItems={"center"}
                            spacing={2}
                          >
                            <Grid item xs={12} sm={4}>
                              <CustomDropdown
                                {...assignTo}
                                onchange={onChange}
                              />
                            </Grid>
                            <Grid item md={1}>
                              <Button
                                onClick={handleSubmitOk}
                                variant="contained"
                                color="success"
                                type="submit"
                                disabled={isSubmitting}
                                fullWidth
                              >
                                {isSubmitting ? <CircularProgress /> : "OK"}
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                        {showingDropdown === 2 && (
                          <Grid
                            container
                            justifyContent="end"
                            alignItems={"center"}
                            spacing={2}
                          >
                            <Grid item xs={12} sm={4}>
                              <CustomDropdown
                                {...assignToCh}
                                onchange={onChange}
                              />
                            </Grid>
                            <Grid item md={1}>
                              <Button
                                onClick={handleAssign}
                                variant="contained"
                                color="success" // Change "success" to "primary" if you want the primary color
                                type="submit"
                                disabled={isSubmitting}
                                fullWidth
                              >
                                {isSubmitting ? <CircularProgress /> : "OK"}
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default ViewAdvClaimBo;
