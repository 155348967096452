import React from "react";
import { Typography } from "@mui/material";
import MemberProfile from '../../../images/MemberProfile.png';
const ViewProfile=()=>{
    return(<div id="view-profile">
     <Typography variant="h6">VIEW PROFILE</Typography>
    <Typography varient="body1" align="justify">
    Click on View Profile tab on app bar. Member’s personal details and employment details are mentioned here. (Figure 33)
    
    <p align="center"><img  className="bordered-image" src={MemberProfile} height="55%" width="90%"/><br />
                Figure 33: Member Profile</p>
    
                </Typography>
    
    </div>);
}
export default ViewProfile;