import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import FormDivider from "../../FormComponent/FormDivider";
import TitleText from "../../FormComponent/TitleText";
import ValueText from "../../FormComponent/ValueText";
import LabelText from "../../FormComponent/LabelText";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"; // Import PDF icon

const GrievanceDetailComponent = ({ dataOfGrievance, remarkss }) => {
  console.log("this is the data", remarkss);
  // console.log("this is the doc", dataOfGrievance.supportDocument);

  const maxDescriptionLength = 50;
  const wordsToShow = 5; // Number of words to show initially

  const [showDocument, setShowDocument] = useState(false);

  const handleDocumentClick = () => {
    setShowDocument(!showDocument);
  };

  const renderInitialDescription = (description) => {
    const words = description.split(" ").slice(0, wordsToShow).join(" ");
    const remainingText = description.split(" ").slice(wordsToShow).join(" ");

    return (
      <div style={{ maxHeight: "100px", overflowY: "auto" }}>
        <Typography
          variant="body1"
          style={{
            whiteSpace: "normal",
            overflowWrap: "break-word",
            wordBreak: "break-word",
          }}>
          {description}
        </Typography>
      </div>
    );
  };
  const x = dataOfGrievance && dataOfGrievance.supportDocument ? dataOfGrievance.supportDocument : ""
  const decodedSupportDocument = atob(x);

const modifiedSupportDocument = decodedSupportDocument.replace(
  "data:application/pdf;base64,JVBERi0xLjQKJdPr6eEKMSAwIG9iago8PC9",
  "data:application/pdf;base64,"
);
const iframeSrc = `data:application/pdf;base64,${btoa(modifiedSupportDocument)}`;

  const displayNameMapping = {
    submittedBy: "Member's Name",
    cmpfAcNo: "CMPF Acc. No.",
    mobileNo: "Mobile No.",
    unitCode: "Unit Code",
    ccCode: "CC Code",
    roCode: "RO Code",
    grievanceId: "Grievance Id",
    grievanceType: "Grievance Category",
    "Grievance Sub-Category": "Grievance Sub-Category",
    submittedOn: "Submitted On",
    currentStatus: "Current Status",
    grievanceDescription: "Grievance Description",
    supportDocument: "Support Document",
  };

  const renderDetails = (title, details) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitleText title={title} />
        </Grid>
        {details.map((key, index) => (
          <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={5} sm={4} md={4} lg={3} textAlign="left">
                <LabelText title={displayNameMapping[key] || key} />
              </Grid>
              <Grid item xs={1} textAlign="left">
                <LabelText title=":" />
              </Grid>
              <Grid item xs={6} sm={7} md={7} lg={8} textAlign="left">
                {dataOfGrievance &&
                Object.keys(dataOfGrievance).length > 0 &&
                dataOfGrievance[key] !== undefined ? (
                  key === "grievanceDescription" &&
                  dataOfGrievance[key]?.length > maxDescriptionLength ? (
                    <Box width="250%">
                      {renderInitialDescription(dataOfGrievance[key])}
                    </Box>
                  ) : key === "supportDocument" ? (
                    <IconButton onClick={handleDocumentClick}>
                      <PictureAsPdfIcon />
                    </IconButton>
                  ) : (
                    <ValueText title={dataOfGrievance[key]} />
                  )
                ) : (
                  <ValueText title="N/A" />
                )}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  };

  useEffect(() => {
    console.log("dataOfGrievance:", dataOfGrievance);
  }, []);

  return (
    <Box p={2} mt={2}>
      <Grid container spacing={2}>
        {renderDetails("Member Details", [
          "submittedBy",
          "cmpfAcNo",
          "mobileNo",
          "unitCode",
          "ccCode",
          "roCode",
        ])}
        <FormDivider />
        <Box mt={2}>
          {renderDetails("Grievance Details", [
            "grievanceId",
            "grievanceType",
            "Grievance Sub-Category",
            "submittedOn",
            "currentStatus",
            "supportDocument",
            "grievanceDescription",
          ])}
          {showDocument &&
            dataOfGrievance &&
            dataOfGrievance.supportDocument && (
              <iframe
  title="Support Document"
  src={iframeSrc}
  width="100%"
  height="500px">
</iframe>
            )}
        </Box>
      </Grid>
    </Box>
  );
};

export default GrievanceDetailComponent;
