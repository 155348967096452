import React, { useState, useEffect } from "react";
import { useNavigation, useRoutes } from "react-router-dom";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { Paper } from "@mui/material";
import LabelText from "../FormComponent/LabelText";
import TitleText from "../FormComponent/TitleText";
import FormDivider from "../FormComponent/FormDivider";
import SucessAlert from "../FormComponent/SuccessAlert";
import BackButton from "../components/button/BackButton";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material"; // Import Material-UI components here
import ErrorAlert from "../FormComponent/ErrorAlert";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import ConfirmationDialogue from "../FormComponent/ConfirmationDialogue";
import { useNavigate, useLocation } from "react-router-dom";
const CustomForm = () => {
  const [isSuccessAlertOpen, setSuccessAlertOpen] = useState(false);
  const [isErrorAlertOpen, setErrorAlertOpen] = useState(false);
  const [rows, setRows] = useState([{ id: 1 }]); // Initial row
  const [options, setOptions] = useState([]);
  const [editableCell, setEditableCell] = useState(null);
  const [advance, setAdvance] = useState([]);
  const [amountErrors, setAmountErrors] = useState([]);
  const [advanceDetails, setAdvanceDetails] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [memberData, setMemberData] = useState([]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [dialogOpen1, setDialogOpen1] = useState(false);
  const [newAdvVisible, setNewAdvVisible] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState("");
  const [isSuccessAlertOpen1, setSuccessAlertOpen1] = useState(false);
  const [successMessage1, setSuccessMessage1] = useState(null);
  const [memberIdToDelete, setMemberIdToDelete] = useState();
  const [advanceIdToDelete, setAdvanceIdToDelete] = useState();

  const [isProgressvisible, setIsProgressVisible] = useState(true);
  const navigate = useNavigate();
  const handleBackButtonClick = () => {
    // Navigate to the "/LedgerTable" URL
    // window.location.replace('/LedgerTable');
    navigate("/roda/viewledger/updateldgr", { state: cmpfno });
  };
  const handleDeleteRow = async (advanceId, memberId) => {
    try {
      const formData = new FormData();
      formData.append("pfWithdrwlId", advanceId);
      formData.append("memberId", memberId);
      const deleteUrl = process.env.REACT_APP_SERVER_URL + "/delete/advance";

      const response = axios.post(deleteUrl, formData);
      console.log("response delete", response);
      setSuccessMessage1("Advance withdrawal deleted successfully");
      setSuccessAlertOpen1(true);

      // Assuming the API call returns success
      // Update UI to reflect the deletion
      // For example, you can refetch data using fetchAPI()
      // fetchAPI();
    } catch (error) {
      console.error("Error deleting row:", error);
      // Handle error if deletion fails
    }
  };
  const location = useLocation();
  const { state } = location;
  const { cmpfno, passingmemberid } = state;

  // console.log("heelo", cmpfno,"heh", passingmemberid);

  // const cmpfAccNo = "BBSR/23/131";
  const handleOk = () => {
    // Handle OK action
    // For example, you can perform some logic or call a function
    handleSubmit();
    handleCloseDialog();
  };
  const handleOk1 = () => {
    // Handle OK action
    // For example, you can perform some logic or call a function
    handleUpdate();
    handleCloseDialog1();
  };
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const handleOpenDialog1 = () => {
    setDialogOpen1(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const handleCloseDialog1 = () => {
    setDialogOpen1(false);
  };

  const handleOpenDeleteDialog = async (advanceId, memberId) => {
    setDeleteDialogOpen(true);
    setMemberIdToDelete(memberId);
    setAdvanceIdToDelete(advanceId);
  };
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };
  const handleOkDelete = () => {
    handleDeleteRow(advanceIdToDelete, memberIdToDelete);

    setDeleteDialogOpen(false);
  };

  // ...............................................

  const fetchAPI = async () => {
    setIsLoading("true");
    console.log("fetch api running");
    const formdata = new FormData();
    formdata.append("cmpfAcNO", cmpfno);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    const url1 = process.env.REACT_APP_SERVER_URL + "/get/pf/ledger/details";
    const url2 =
      process.env.REACT_APP_SERVER_URL + "/get/pf/advance/withd/details";
    const url3 = process.env.REACT_APP_SERVER_URL + "/get/intrest/with/yr";
    const [
      { data: ledgerDetails },
      { data: advanceDetails },
      { data: interestDetail },
    ] = await Promise.all([
      fetch(url1, requestOptions).then((response) => response.json()),

      fetch(url2, requestOptions).then((response) => response.json()),
      fetch(url3, requestOptions).then((response) => response.json()),
    ]);
    console.log("interest detail", advanceDetails);
    let intrestObject = interestDetail.reduce((acc, item) => {
      const year = new Date(item.intYear).getFullYear();
      acc[year] = item.intPercentage;
      return acc;
    }, {});
    // const intrestObject = interestDetail.map((item) => ({
    //   interestDetail: item.interestPerc
    // }));
    console.log("intrestObject", intrestObject);

    setAdvanceDetails(advanceDetails);
    setIsLoading("false");
    setIsNotFound(true);
  };

  useEffect(() => {
    fetchAPI();
  }, [cmpfno]);

  useEffect(() => {
    const fetchMemDetails = async () => {
      try {
        // Create FormData object
        const formData = new FormData();
        formData.append("cmpfAcNo", cmpfno);
        const memurl =
          process.env.REACT_APP_SERVER_URL +
          "/get/member/id/unit/code/by/cmpf/acno";

        // Make API request using Axios
        const response = await axios.post(memurl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        // Update state with response data
        console.log("mmeemm", response.data.data);
        const memData = response.data.data;
        setMemberData(memData);
        console.log("member data", memberData);
      } catch (error) {
        // Handle error
        console.log("errrrrorrrr");
      }
    };

    // Only trigger API call when cmpfaccno changes
    if (cmpfno) {
      fetchMemDetails();
    }
  }, [cmpfno]);
  //.......................................................................

  useEffect(() => {
    const url = process.env.REACT_APP_SERVER_URL + "/type/of/advance";

    // Fetch data for the dropdown using Axios
    axios
      .post(url)
      .then((response) => {
        setOptions(response.data.data.data);
        console.log("options", options);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []); // The empty dependency array ensures that this effect runs only once when the component mounts

  const handleDateChange = (event, rowIndex) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex].withdrwlDate = event.target.value;
    setRows(updatedRows);
  };

  const handleOptionChange = (event, rowIndex) => {
    const selectedTypeId = event.target.value;
    console.log("test id", selectedTypeId);
    const selectedType = options.find((option) => option.id === selectedTypeId);

    const occurrences = advanceDetails.filter(
      (data) => data.withdrwlTypId === selectedTypeId
    ).length;
    console.log("test occurence", occurrences);

    const updatedRows = [...rows];

    if (
      (selectedTypeId === 1 ||
        selectedTypeId === 2 ||
        selectedTypeId === 3 ||
        selectedTypeId === 4 ||
        selectedTypeId === 5 ||
        selectedTypeId === 7 ||
        selectedTypeId === 8) &&
      occurrences >= 1
    ) {
      console.log("test first condition");
      // Display an error message and clear the selection
      // You can replace the alert with your preferred way of showing an error to the user
      setErrorMessage("You can select this advance type only once");
      console.log("bbbbbbaaaahhhhhh");
      setErrorAlertOpen(true);
      updatedRows[rowIndex].withdrwlTypId = ""; // Clear the selected type
    } else if (selectedTypeId === 6 && occurrences >= 2) {
      console.log("test second condition");
      setErrorMessage("You can select this advance type only twice");
      console.log("bbbbbbaaaa");
      setErrorAlertOpen(true);
      updatedRows[rowIndex].withdrwlTypId = ""; // Clear the selected type
    } else {
      updatedRows[rowIndex].withdrwlTypId = selectedTypeId;
    }

    setRows(updatedRows);
  };

  const handleAmountChange = (event, rowIndex) => {
    const updatedRows = [...rows];
    const enteredAmount = event.target.value;

    // Validate that the entered amount is a positive number
    if (enteredAmount >= 0 || enteredAmount === "") {
      updatedRows[rowIndex].withdrwlAmt = enteredAmount;
      setAmountErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[rowIndex] = null; // Clear any previous errors
        return newErrors;
      });
    } else {
      // Display an error message for negative amounts
      setAmountErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[rowIndex] = "Please enter a valid amount";
        return newErrors;
      });
    }

    setRows(updatedRows);
  };

  const handleAddRow = () => {
    setRows([...rows, { id: rows.length + 1 }]);
  };
  const handleRemoveRow = (index) => {
    setRows(rows.filter((row, i) => i !== index));
  };
  const handleSuccessAlertOk = () => {
    // navigate("/roda/viewledger/updateldgr", { state: cmpfno });
    // Close the success alert
    setSuccessAlertOpen(false);
    fetchAPI();
    setRows([{ id: 1 }]);

    // If there is no opener, go back to the previous page
    // window.history.back();

    // Reload the page
    // window.location.reload();
  };
  const handleSuccessAlertOk1 = () => {
    setSuccessAlertOpen1(false);
    fetchAPI();
    // setRows([{ id: 1 }]);
  };
  const handleViewLedger = () => {
    navigate("/roda/viewledger/updateldgr", { state: cmpfno });
  };
  const handleErrorAlertOk = () => {
    // Close the success alert
    setErrorAlertOpen(false);

    // Reload the page
  };
  const handleAddAdv = () => {
    setNewAdvVisible(true);
  };
  const handleUpdate = () => {
    let isValidData = true; // Flag to track if all details are valid

    // Function to check if a date is valid and not in the future
    const isValidDate = (dateString) => {
      const date = new Date(dateString);
      const currentDate = new Date();
      return !isNaN(date.getTime()) && date <= currentDate;
    };

    const roundOffWithdrawalAmount = (amount) => {
      // Round off the amount to the nearest integer
      return Math.round(amount);
    };

    const updateData = advanceDetails.map((detail) => {
      // Validate date before adding to updateData
      if (!isValidDate(detail.withdrwlDate)) {
        isValidData = false; // Set flag to false if any detail is invalid
        if (new Date(detail.withdrwlDate) > new Date()) {
          // If date is in the future, display error message
          console.error("Future date selected:", detail.withdrwlDate);
          setErrorMessage("You cannot select a future date.");
        } else {
          // If date is invalid, display error message
          console.error("Invalid date:", detail.withdrwlDate);
          setErrorMessage("Invalid date selected. Please choose a valid date.");
        }
        setErrorAlertOpen(true);
        return null;
      }

      // Round off withdrawal amount if it's a decimal
      const roundedAmount = roundOffWithdrawalAmount(detail.withdrwlAmt);

      return {
        memberId: passingmemberid,
        pfWthdrwlId: detail.pfWthdrwlId,
        withEmplrShare: 0,
        withMemShare: 0,
        withdrwlAmt: roundedAmount, // Rounded off amount
        withdrwlDate: detail.withdrwlDate,
        withdrwlTyp: detail.withdrwlTyp,
        withdrwlTypId: detail.withdrwlTypId,
      };
    });

    // If any detail is invalid, abort the update process
    if (!isValidData) {
      return;
    }

    // Filter out null entries (invalid dates)
    const validUpdateData = updateData.filter((entry) => entry !== null);

    console.log("updated", validUpdateData);

    const advanceUrl =
      process.env.REACT_APP_SERVER_URL + "/update/pf/Advance/Withdrawl/details";

    axios
      .post(advanceUrl, validUpdateData)
      .then((response) => {
        // Handle success
        console.log("Updated successful:", response.data);
        setSuccessMessage1("Advance Withdrawals Updated Successfully");
        setSuccessAlertOpen1(true);
        // Optionally, you can show a success message or navigate to another page
        // navigate("/success-page");
      })
      .catch((error) => {
        // Handle error
        console.error("Error updating data:", error);
        setErrorMessage(
          "Failed to update advance details. Please check the entered details. "
        );
        setErrorAlertOpen(true);
      });
  };

  const handleSubmit = () => {
    // Check if any field is empty
    const isAnyFieldEmpty = rows.some(
      (row) => !row.withdrwlDate || !row.withdrwlTypId || !row.withdrwlAmt
    );

    if (isAnyFieldEmpty) {
      // Display an error message for empty fields
      setErrorMessage("Please fill in all fields before submitting.");
      setErrorAlertOpen(true);
      return; // Prevent further execution
    }

    // Proceed with API call if all fields are filled
    const apiData = rows.map((row) => ({
      memberId: passingmemberid,
      withdrwlDate: row.withdrwlDate,
      withdrwlTypId: row.withdrwlTypId,
      withMemShare: 0, // Example value, replace with actual data
      withEmplyrShr: 0, // Example value, replace with actual data
      withdrwlAmt: row.withdrwlAmt,
    }));

    const url = process.env.REACT_APP_SERVER_URL + "/submit/pf/withdrawl/data";
    // Make API call with apiData
    axios
      .post(url, apiData)
      .then((response) => {
        // Handle success
        console.log("API response:", response);
        console.log("data", apiData);
        setSuccessMessage("Advance Withdrawals Added Successfully");
        setSuccessAlertOpen(true);
      })
      .catch((error) => {
        // Handle error
        console.error("Error submitting data:", error);
        console.log("api data", apiData);
        setErrorMessage("Something Went Wrong");
        setErrorAlertOpen(true);
      });
  };
  const handleCellClick = (rowIndex, columnIndex) => {
    setEditableCell({ rowIndex, columnIndex });
  };

  const handleDateChange1 = (event, rowIndex) => {
    const updatedDetails = [...advanceDetails];
    updatedDetails[rowIndex].withdrwlDate = event.target.value;
    setAdvanceDetails(updatedDetails);
  };

  const handleOptionChange1 = (event, rowIndex) => {
    const updatedDetails = [...advanceDetails];
    updatedDetails[rowIndex].withdrwlTyp = event.target.value;
    setAdvanceDetails(updatedDetails);
  };

  const handleAmountChange1 = (event, rowIndex) => {
    const updatedDetails = [...advanceDetails];
    updatedDetails[rowIndex].withdrwlAmt = event.target.value;
    setAdvanceDetails(updatedDetails);
    console.log("data hai", updatedDetails);
  };
  const formatDate = (dateString) => {
    // Check if the dateString is provided and not empty
    if (dateString && dateString.trim() !== "") {
      const [year, month, day] = dateString.split("-");
      return `${day}/${month}/${year}`; // Format the date as dd/mm/yyyy
    }
    return ""; // Return empty string if the dateString is empty
  };
  const notFound = () => {
    return isNotFound ? (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "10vh" }}>
        <Grid item xs={12}>
          <Typography align="center" style={{ color: "red" }}>
            ** No Data Found **
          </Typography>
        </Grid>
      </Grid>
    ) : null;
  };
  const progress = () => {
    return isProgressvisible ? <LinearProgress /> : null;
  };

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "1rem", borderRadius: "4px" }}>
            {/* Back Button */}
            <Grid item xs={12}>
              <TitleText title=" Edit Advance Withdrawal Details" />
            </Grid>
            <FormDivider />
            <SucessAlert
              open={isSuccessAlertOpen}
              title="Success!"
              body={successMessage}
              buttonName="OK"
              handleOk={handleSuccessAlertOk}
            />
            <SucessAlert
              open={isSuccessAlertOpen1}
              title="Success!"
              body={successMessage1}
              buttonName="OK"
              handleOk={handleSuccessAlertOk1}
            />
            <ErrorAlert
              open={isErrorAlertOpen}
              title="Error!"
              body={errorMessage}
              buttonName="OK"
              handleOk={handleErrorAlertOk}
            />
            <ConfirmationDialogue
              open={dialogOpen}
              title="Add Advance"
              body="Are you sure you want add this Advance?"
              buttonName="OK"
              handleOk={handleOk}
              handleCancel={handleCloseDialog}
            />
            <ConfirmationDialogue
              open={dialogOpen1}
              title="Update Changes?"
              body="Are you sure you want to update changes?"
              buttonName="OK"
              handleOk={handleOk1}
              handleCancel={handleCloseDialog1}
            />
            <ConfirmationDialogue
              open={deleteDialogOpen}
              title="Delete Row ? "
              body="Are you sure you want to delete this row?"
              buttonName="OK"
              handleOk={handleOkDelete}
              handleCancel={handleCloseDeleteDialog}
            />
            <Grid item xs={6} alignContent={"center"} marginBottom={"20px"}>
              <TitleText title={`Member Name : ${memberData.name}`} />
            </Grid>
            <Grid container alignItems="center">
              <Grid item>
                <BackButton />
              </Grid>
              <Grid item xs />
              <Grid item>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={handleViewLedger}
                  // style={{ marginTop: "20px" }}
                >
                  View Ledger
                </Button>
              </Grid>
              <Grid item sx={{ marginLeft: "10px" }}>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleAddAdv}
                  // style={{ marginTop: "20px" }}
                >
                  Add New Advance
                </Button>
              </Grid>
            </Grid>

            <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Withdrawal Date</TableCell>
                    <TableCell>Type of Advance</TableCell>
                    <TableCell>Withdrawal Amount</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading === "true" ? (
                    <Box sx={{ width: "300%" }}>
                      <LinearProgress />
                    </Box>
                  ) : (
                    <>
                      {advanceDetails.length === 0 ? (
                        <TableRow>
                          <TableCell
                            colSpan={3}
                            style={{ textAlign: "center", color: "red" }}>
                            No Advance Withdrawals Found
                          </TableCell>
                        </TableRow>
                      ) : (
                        advanceDetails.map((advance, rowIndex) => (
                          <TableRow key={rowIndex}>
                            <TableCell
                              style={{ width: "33%" }}
                              onClick={() => handleCellClick(rowIndex, 0)}>
                              {editableCell &&
                              editableCell.rowIndex === rowIndex &&
                              editableCell.columnIndex === 0 ? (
                                <TextField
                                  value={advance.withdrwlDate} // Keep the original value for editing
                                  onChange={(event) =>
                                    handleDateChange1(event, rowIndex)
                                  }
                                  type="date" // Ensure it's rendered as a date input
                                />
                              ) : (
                                formatDate(advance.withdrwlDate) // Display the formatted date
                              )}
                            </TableCell>

                            <TableCell style={{ width: "33%" }}>
                              {editableCell &&
                              editableCell.rowIndex === rowIndex &&
                              editableCell.columnIndex === 1 ? (
                                <TextField
                                  value={advance.withdrwlTyp}
                                  onChange={(event) =>
                                    handleOptionChange1(event, rowIndex)
                                  }
                                />
                              ) : (
                                advance.withdrwlTyp
                              )}
                            </TableCell>

                            <TableCell
                              style={{ width: "34%" }}
                              onClick={() => handleCellClick(rowIndex, 2)}>
                              {editableCell &&
                              editableCell.rowIndex === rowIndex &&
                              editableCell.columnIndex === 2 ? (
                                <TextField
                                  value={advance.withdrwlAmt}
                                  onChange={(event) =>
                                    handleAmountChange1(event, rowIndex)
                                  }
                                />
                              ) : (
                                advance.withdrwlAmt
                              )}
                            </TableCell>
                            <TableCell>
                            <IconButton
                                onClick={() =>
                                  handleOpenDeleteDialog(
                                    advance.pfWthdrwlId,
                                    passingmemberid
                                  )
                                }>
                                <DeleteIcon />
                              </IconButton> 
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="success"
                onClick={handleOpenDialog1}
                style={{ marginTop: "20px" }}>
                UPDATE
              </Button>
            </Grid>
          </Paper>
          {newAdvVisible ? (
            <>
              <Paper
                elevation={3}
                sx={{
                  padding: "1rem",
                  borderRadius: "4px",
                  marginTop: "20px",
                }}>
                <Grid item xs={12}>
                  <TitleText title="Add Advance Withdrawal Details" />
                </Grid>
                <FormDivider />

                {rows.map((row, index) => (
                  <Grid container spacing={2} key={row.id} alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <TextField
                        id={`date-${row.id}`}
                        label="Select Date"
                        type="date"
                        value={row.withdrwlDate || ""}
                        onChange={(event) => handleDateChange(event, index)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        id={`custom-dropdown-${row.id}`}
                        select
                        label="Select Type of Advance"
                        value={row.withdrwlTypId || ""}
                        onChange={(event) => handleOptionChange(event, index)}
                        fullWidth
                        margin="normal">
                        {options.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.title}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4} container alignItems="center">
                      <TextField
                        id={`amount-${row.id}`}
                        label="Enter Amount"
                        type="number"
                        value={row.withdrwlAmt || ""}
                        onChange={(event) => handleAmountChange(event, index)}
                        fullWidth
                        margin="normal"
                        error={amountErrors[index] !== null}
                        helperText={amountErrors[index]}
                        style={{ width: "80%" }} // Adjusted width here
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleRemoveRow(index)}
                        style={{ marginLeft: "8px" }} // Adjusted margin here
                      >
                        X
                      </Button>
                    </Grid>
                  </Grid>
                ))}

                <Grid container spacing={1} justifyContent="center">
                  <Grid item xs={6} sm={3}>
                    {" "}
                    {/* Adjust the width based on your design */}
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={handleAddRow}
                      style={{ marginTop: "20px" }}>
                      Add Row
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    {" "}
                    {/* Adjust the width based on your design */}
                    <Button
                      variant="contained"
                      color="success"
                      onClick={handleOpenDialog}
                      style={{ marginTop: "20px" }}>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </>
          ) : null}

          {/* </Paper> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomForm;
