import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { Route, Navigate } from 'react-router-dom';

const RouterProtector = (props) => {

  const { element, requiredRoles, } = props;

  const [isAuthenticated, setISAuthenticated] = useState();
  const [isAuthorized, setIsAuthorized] = useState();
  const [protectorActive , setProtectorActive] = useState(true);

  const navigateUser = () => {
    const token = localStorage.getItem('jwt');
    if (!token) {
      setISAuthenticated(false);
      setIsAuthorized(false);
      setProtectorActive(false);
      return;
    }
    const data_decoded = jwtDecode(token);
    setIsAuthorized(requiredRoles.includes(data_decoded.role));
    setISAuthenticated(true)
    setProtectorActive(false);
  }

  useEffect(() => (
    navigateUser()
  ), []);

  return isAuthenticated && isAuthorized ? (
    element
  ) :
    isAuthenticated && !isAuthorized ?
      (
        <Navigate to="/accessdenied" replace={true} />
      ) :
      (
        <Navigate to="/login" state={{ page: 'member' }} replace={true} />
      );
};

export default RouterProtector;
