import React from "react";
// import { Typography } from "@mui/material";
// import Link from '@mui/material/Link';
import { Typography, Link, List, ListItem, ListItemText } from "@mui/material";
const AppendixA=()=>{

    const AppendixAItem = ({ question, answer }) => (
        <ListItem>
          <ListItemText>
            <Typography variant="h6" component="span">
              {question}
            </Typography>
            <br />
            <Typography variant="body1" align='justify'>{answer}</Typography>
          </ListItemText>
        </ListItem>
      );



    return(<div id="appendix-a">
    <Typography variant="h5" align="center">APPENDIX</Typography>
    <Typography variant="" align="center"><b>ANNEXURE-A: FREQUENTLY ASKED QUESTIONS</b></Typography>
    <Typography variant="body1" align='justify'>


    <List>
    
    <AppendixAItem
        question="1. What is C-CARES?"
        answer=" It is a Centralized Digital Platform for CMPFO. It serves as a single access point for CMPFO and its regional offices to access the employees’ database as well as offer the requisite services to employers as well as employees w.r.t. PF, Pension and Advance claims and settlements."

        
    />
   <AppendixAItem
        question="2. How do I access C-CARES portal?"
        answer=" Open any internet browser (i.e. Chrome, Microsoft Edge, Safari etc.) and type the following URL https://cmpfo.gov.in on the browser’s address bar to access C-CARES portal."

        
    />
    <AppendixAItem
        question="3. Can I access C-CARES account through the mobile as well?"
        answer=" Yes. C-CARES web portal can be accessed on the mobile devices also."
        
    />
    <AppendixAItem
        question="4. Why am I not able to login to my account?"
        answer=" Firstly, the member should be enrolled on the C-CARES portal. Only after successful enrolment a member can login to account. Make sure you are entering correct User Id and Password."

        
    />
    <AppendixAItem
        question="5. What is my User-Id?"
        answer=" The CMPFO account number is the user-id/login id of the member. Using this account number, a member can enrol and/or login to the C-CARES portal."

        
    />
    <AppendixAItem
        question="6. How do I create a new account?"
        answer=" A member can open account by enrolling himself on C-CARES portal."
        
    />
    <AppendixAItem
        question="7. What happens if I forget my login password?"
        answer=" The member can reset his password by using the Forgot Password facility given in the member login page."
        
    />
    <AppendixAItem
        question="8. How many times can I login to my account in a day?"
        answer=" A member can login any number of times in a day given that he logs in successfully in first two attempts every time he wants to access his account.However, if he fails to login in third attempt, the account is blocked for 24hrs and it will automatically restored at 12:00 AM on the subsequent day."
        
    />
    <AppendixAItem
        question="9. How do I change my password?"
        answer=" Login to your account. In the sidebar you can find Change Password tab. Click on it to change your password."
        
    />
    <AppendixAItem
        question="10. How can I view my account details online?"
        answer=" Login to your account. By default, Account Summary is displayed to the member. This has the basic details of the member. Click on View Profile tab to see detailed information of the member."
        
    />
    <AppendixAItem
        question="11. What to do if my account is blocked?"
        answer=" If account is blocked after invalid login attempt, it will restore automatically after 24hrs i.e. 12:00AM on the subsequent day."
        
    />
    <AppendixAItem
        question="12. How do I view my PF details?"
        answer=" Login to your account. Click on PF Statement tab to see the PF details."
        
    />
    <AppendixAItem
        question="13. Why did my account logout automatically?"
        answer={
            <div>
               There are two reasons for which a member is logged out of the account.
               <ol type="1">
                <li>If he reloads the page.</li>
                <li>If there is no activity on the webpage for 5 minutes.</li>
              </ol>
              
                These measures are taken for the security of the member’s data.
             
            </div>
          }
        
    />
    <AppendixAItem
        question="14. Whom do I contact for assistance?"
        
        answer=" In case of any ambiguity or doubts, users are advised to contact on below email addresses."
    />
</List>


           

    </Typography>
    
    
    
    
    <Typography component="span" align='left'sx={{ color: 'skyblue' }}>
        For Grievances:
      </Typography>{' '}
       
      <Link href="https://cares-cmpfo.in/contact" target="_blank">grievance@cmpfo.gov.in</Link> <br /> 
      <Typography component="span" align='left' sx={{ color: 'skyblue' }}>
      For Other Queries/Information:
    </Typography>{' '}
      <Link href="mailto:support@cmpfo.gov.in">support@cmpfo.gov.in</Link>
    </div>);
}
export default AppendixA;