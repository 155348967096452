import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import LabelText from "../../FormComponent/LabelText";
import CustomTextField from "../../FormComponent/CustomTextField";
import SubtitleText from "../../FormComponent/SubtitleText";
import ValueText from "../../FormComponent/ValueText";
import MemberDetails from "./MemberDetatils";
import TableWithLinkAndButton from "../../FormComponent/TableWithLinkAndButton";
import ClaimForm from "./ClaimForm";
import CaptionText from "../../FormComponent/CaptionText";
import ClaimFormPF from "./ClaimFormPF";
import ClaimFormPension from "./ClaimFormPension";

import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect } from "react";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import { useLocation, useNavigate } from "react-router-dom";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";

function NewPfClaim() {
  const [accNo, setAccNo] = useState("");
  const [member, setMember] = useState({});

  //********DEFAULT for family details*********/
  const familyDefault = {
    familyMemId: "",
    name: "",
    gender: "",
    relationName: "",
    dateOfBirth: "",
    pfNomSharePerc: "",
  };

  const [nomineeDetails, setNomineeDetails] = useState([familyDefault]);

  //const [memberFound, setMemberFound] = useState(" ");
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  // ******* for declaration ******
  const [checked, setChecked] = useState(false);

  //**********for Hiding details************* */

  const [isMemberFound, setIsMemberFound] = useState("");

  //**********for integration************* */
  const [isLoading, setIsLoading] = useState(false); // set this to true
  const [isFailed, setIsFailed] = useState(false);

  //**********for Error Alerts************* */
  const [bankErrors, setBankErrors] = useState(false); // the same state is used for set errors if family member is not present in pension
  const [bankErrorMessage, setBankErrorMessage] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [familyErrorMessage, setFamilyErrorMessage] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;
  const path = pathname.split("/"); // spliting the path to check weather the page came form the pension path or the pf path from app.js
  let type = path[2];
  let title = type === "pfset" ? "PF" : "Pension";
  console.log("title is here", title);

  let apis = {
    nomineeUrl: "",
  };

  const pfApis = {
    nomineeUrl: "/get/all/nominee/details",
  };

  const penApis = {
    nomineeUrl: "/get/all/nominee/details/pension",
  };

  
  if (type === "pfset") {
    apis = { ...pfApis };
  } else if (type === "penset") {
    apis = { ...penApis };
  }

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleBankErrorAlert = () => {
    setBankErrors(false);
    navigate(-1);
  };

  // ******** setting props for the TableWithLinkAndButton component*******

  const viewLink = "familydetails"; // Link for family members details

  // const familyMemberSamples = [
  //   // samples to be displayed on table
  //   {
  //     familyMemId: "1",
  //     name: "Neha",
  //     gender: "f",
  //     relationName: "Wife",
  //     dateOfBirth: "22-02-1996",
  //     pfNomSharePerc: "80",
  //   },
  //   {
  //     familyMemId: "2",
  //     name: "Raayn",
  //     gender: "m",
  //     relationName: "Son",
  //     dateOfBirth: "02-09-2020",
  //     pfNomSharePerc: "20",
  //   },
  // ];

  const createTablePreviewDataReturn = () => {
    console.log("from preview", nomineeDetails);
    // the functions that creates a table preview
    //const tableSamples = familyMemberSamples; //familyMemberSamples must be replaced witht he response data recevied on the api request
    const tableSamples = nomineeDetails;
    let tablePreviewData = [];
    tableSamples.map((member) => {
      tablePreviewData.push({
        "Family Member ID": member.familyMemId,
        "Name of Nominee": member.name,
        Gender: member.gender,
        Relation: member.relationName,
        "Date of Birth": member.dateOfBirth,
        "Share in percentage": member.pfNomSharePerc,
      });
    });
    return tablePreviewData;
  };

  const handleAccSearch = () => {
    setIsSearching(true);
    fetchMemberDetail();
  };

  const onChange = (e) => {
    const value = e.target.value.toUpperCase().trim();
    setAccNo(value);
  };

  // cmpf account number is fetched on useEffect with a delay of 3sec to avoid multiple api hits

  const sampleMemberData = {
    // This is the sample member data to be displed for demo purpose only
    title: "Miss",
    fullName: "Shreya",
    dateOfBirth: "",
    panNo: "",
    aadhaarNumber: "",
    maritalStatus: "",
    religionName: "",
    gender: "",
    fatherHusbandName: "",
    email: "",
    mobileNo: "",
    landNo: "",
    addressL1: "",
    addressL2: "",
    village: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  };
  ///****************integration started********************* */

  const fetchMemberDetail = async () => {
    setIsLoading(true);
    // console.log("in fetch Member detail");

    //  if (!(await sessionExist())) {
    //    console.log("session extending having trouble");
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");

    const url = process.env.REACT_APP_SERVER_URL + "/member/details/for/pf";
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo);
    //console.log("formData", accNo);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        console.log("api is getting called", response);
        setIsLoading(false);
        setIsFailed(false);

        if (response.status === 200) {
          const data = response.data.data;
          console.log("response in member", response.data.data.length);
          if (data.length !== 0) {
            setIsMemberFound("true");
            if (type === "pfset") {
              if (data[0].pfBankId === 1) {
                setBankErrors(true);
                setErrorTitle("NOT FOUND! ");
                setBankErrorMessage(
                  "Bank account details for PF are not found. Please update the same from Update Member Details and then proceed."
                );
              }
            } else if (type === "penset") {

              if (!data[0].maritalStatus) {

                // console.log("maritalStatus is null")
                setBankErrors(true);
                setErrorTitle("NOT FOUND! ");
                setBankErrorMessage(
                  "Marital Status  of the Member is not found. Please update the same from Update Member Details and then proceed."
                );
                return
              }

              if (data[0].penBankId === 1) {
                setBankErrors(true);
                setErrorTitle("NOT FOUND! ");
                setBankErrorMessage(
                  "Bank account details for Pension are not found. Please update the same from Update Member Details and then proceed."
                );
                return
              }
            } else {
              if (data[0].penBankId === 1 || data[0].penBankId === 1) {
                setBankErrors(true);
                setErrorTitle("NOT FOUND! ");
                setBankErrorMessage(
                  "Bank account details for PF or Pension are not available. Please update the same from Update Member Details and then proceed."
                );
              }
            }
            fetchNomineeDetail(data[0].maritalStatus);
            setMember(data[0]);
            setIsSearching(false);
            return;
          }
          setIsMemberFound("false");
          setIsSearching(false);

          return;
        }
        setIsFailed(true);
        setIsSearching(false);

        setIsMemberFound("false");
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsSearching(false);

        setIsFailed(true);
        console.log("error", error);
        setIsMemberFound("false");
      });
  };

  /***** function to get all nominee details**************** */
  const fetchNomineeDetail = async (maritalStatus) => {
    setIsLoading(true);
    // console.log("in fetch Member detail");

    //  if (!(await sessionExist())) {
    //    console.log("session extending having trouble");
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");

    //const url = process.env.REACT_APP_SERVER_URL + "/get/all/nominee/details";
    const url = process.env.REACT_APP_SERVER_URL + apis.nomineeUrl;

    const formData = new FormData();
    formData.append("cmpfAccNo", accNo); // check what formdata is to be sent
    console.log("formData", formData);
    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsLoading(false);
        setIsFailed(false);
        console.log("api is getting called");
        if (response.status === 200) {
          const data = response.data.data;
          console.log("marital Status", data);

          if (maritalStatus === "M") {
            console.log("memberIsMarried");
            if (data.length !== 0) {
              setNomineeDetails(data);
              // if (!data.aadhaarNo) {
              //   setBankErrors(true);
              //   setErrorTitle("NOT FOUND!");
              //   setBankErrorMessage(
              //     "Sorry claim cannot proceed. Aadhaar No for this Nominee is not present ."
              //   );

              // }

              if (type === "penset") {
                console.log("in aadhaar mapppp ");

                data.map((nominee) => {
                  console.log("aadhaar no is", nominee.aadhaarNo);
                  if (!nominee.aadhaarNo) {
                    setBankErrors(true);
                    setErrorTitle("NOT FOUND!");
                    setBankErrorMessage(
                      `Sorry claim cannot proceed. Aadhaar No for spouse (${nominee.name}) not present . Please Update Family Member Details`
                    );
                  }
                });
              }
            } else {
              if (type === "penset") {
                setBankErrors(true);
                setErrorTitle("NOT FOUND!");
                setBankErrorMessage(
                  "Sorry claim cannot proceed. Spouse details for this member is not present."
                );
                return;
              }
            }
          } else {
            if (data.length !== 0) {
              setNomineeDetails(data);
            }
          }
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        console.log("error", error);
      });
  };

  return (
    <div>
      <ErrorAlert
        handleOk={handleBankErrorAlert}
        open={bankErrors}
        title={errorTitle}
        body={bankErrorMessage}
        buttonName="Ok"
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            <Grid item xs={12}>
              <TitleText title={`${title} Claim Submission`} />
              <FormDivider />
              {isMemberFound !== "true" && (
                <Grid
                  container
                  spacing={2}
                  columnGap={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  {/* ---cmpf accno textfield--- */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title=" Enter CMPF Account Number" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* not using custom textbox as i need to call specfic functions on Blur and onChange which is n ot possible with CustomTextFeild component */}
                    <TextField
                      sx={{
                        "& .MuiFormHelperText-root": {
                          marginLeft: 0,
                          // fix based on your screen
                          fontSize: {
                            xs: "9px",
                            sm: "9px,",
                            md: "9px",
                            lg: "12px",
                            xl: "14px",
                          },
                          fontWeight: "400",
                          // color: error
                          //   ? color
                          //     ? color
                          //     : "red"
                          //   : color
                          //   ? color
                          //   : "black",
                        },
                        "& .MuiInputBase-input": {
                          minWidth: "80px",
                          borderRadius: "5px",
                          color: "black",
                          background: "#ffffff",
                          // fix based on your screen
                          fontSize: {
                            xs: 12,
                            sm: 12,
                            md: 13,
                            lg: 14,
                            xl: 16,
                          },
                        },
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "5px",
                          "& fieldset": {
                            borderColor: "#474E68",
                            // borderRadius:'0px'
                          },
                          "&:hover fieldset": {
                            borderColor: "#6B728E",
                            // borderRadius: '0px'
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#0F3460",
                            borderWidth: "0.5px",
                            // borderRadius: '0px'
                          },
                        },
                      }}
                      autoComplete="off"
                      // id="userName"
                      value={accNo}
                      // onBlur={onAccBlur}
                      // label="Username"
                      name="accNo"
                      onChange={onChange}
                      variant="outlined"
                      placeholder="CMPF Account Number "
                      // error={Boolean(userNameError)}
                      // helperText={
                      //   Boolean(userNameError)
                      //     ? userNameError
                      //     : "Account number cannot exceed 20 characters."
                      // }
                      fullWidth
                      required
                      inputProps={{
                        maxLength: 20,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Button
                      variant="contained"
                      startIcon={<SearchIcon />}
                      sx={{ "& .MuiButton-startIcon": { margin: "0 auto" } }}
                      onClick={handleAccSearch}
                      disabled={isLoading}
                    >
                      {isSearching ? <CircularProgress /> : ""}
                    </Button>
                  </Grid>
                </Grid>
              )}
              {/* --- cmpf acc no field end --- */}

              {isMemberFound === "true" && (
                <>
                  {/* Member Details*/}

                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="start"
                    rowGap={3}
                  >
                    {/* section 1  */}
                    <Grid item xs={12}>
                      <MemberDetails member={member} />
                    </Grid>

                    {/* ************************************** */}
                    {/* Nominee status */}

                    {/* section 3  */}
                    <Grid item xs={12}>
                      <SubtitleText title="Nomination Status" />
                    </Grid>

                    {/* <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    columnGap={1}
                  > */}
                    <Grid item xs={12}>
                      <TableWithLinkAndButton
                        preview={createTablePreviewDataReturn()}
                        data={nomineeDetails}
                        passingId="familyMemberid"
                        actions=""
                        viewLink={viewLink}
                      />
                    </Grid>
                    {/* Remove the commmented part after finalizing with sir */}
                    {/* row */}
                    {/* <Grid item xs={12} sm={4}>
                      <LabelText title="Nominee" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <ValueText title={member.nominee} />
                    </Grid> */}

                    {/* row */}
                    {/* </Grid>
                </Grid> */}
                    {/* column 2  */}

                    {/* <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                    columnGap={1}
                  > */}
                    {/* row  */}
                    {/* <Grid item xs={12} sm={4}>
                      <LabelText title="Nominee Percent" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ValueText title={member.nomineePercent} />
                    </Grid>
                  </Grid>
                </Grid> */}
                    {/* **************Claim submission******** */}
                    <Grid item xs={12}>
                      {/* <SubtitleText title="Claim Submission" /> */}
                      {type === "pfset" ? (
                        <ClaimFormPF
                          cmpfAccNo={accNo}
                          memberData={member}
                          // nomineeDetails={nomineeDetails}
                          nomineeDetails={nomineeDetails}
                        />
                      ) : (
                        <ClaimFormPension
                          cmpfAccNo={accNo}
                          memberData={member}
                          // nomineeDetails={nomineeDetails}
                          nomineeDetails={nomineeDetails}
                        />
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
              {isMemberFound === "false" && (
                <Typography color="red">Member not found</Typography>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default NewPfClaim;
