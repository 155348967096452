import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Location, useLocation } from "react-router-dom";

import { Stepper, Step, StepLabel, StepIcon } from "@mui/material";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const CurrentStatusGreivance = () => {
  const location = useLocation();
  const { state } = location;
  const { grievanceId } = state;
  //const history = useHistory();
  const navigate = useNavigate();
  //console.log('search param', searchParam)

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [completedSteps, setCompletedSteps] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loadingSteps, setLoadingSteps] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);

  const [error, setError] = useState(null); // New state for error handling
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);

  useEffect(() => {
    fetchCompletedSteps();
    fetchTableData();
  }, []);

  const fetchTableData = async () => {
    try {
      const newResponse = new FormData();
      newResponse.append("grievanceId", grievanceId); //GRIEV_121
      const url = process.env.REACT_APP_SERVER_URL + "/recentStatusDescription";
      const response = await axios.post(url, newResponse);
      const responseData = response.data.data;

      let tablePreviewData = responseData.map((tableData) => ({
        "Grievance Id": tableData.grievanceId,
        "Submit Date": tableData.submitDate,
        "Current Status": tableData.grievanceStatusDesc,
        "Prev Status": tableData.statusDate,
      }));
      setTableData(tablePreviewData);
      setLoadingTable(false);
    } catch (error) {
      console.error("Error fetching table data:", error);

      setError("Grievance ID not found");
      setErrorAlertOpen(true);
      //history.push("/search");
      //navigate('/search');
      // setTimeout(() => {
      //   // history.push("/search");
      //   navigate('/search');
      // }, 2000);
    }
  };

  const fetchCompletedSteps = async () => {
    try {
      const newResponse = new FormData();
      newResponse.append("grievanceId", grievanceId);
      const url = `${process.env.REACT_APP_SERVER_URL}/recentStatusDescription`;
      const response = await axios.post(url, newResponse);
      console.log("steps", response.data.data);
      if (response.data && response.data.data) {
        const filteredSteps = response.data.data.filter(
          (step) => step.grievanceStatusId !== 2 && step.grievanceStatusId !== 3
        );
        setCompletedSteps(filteredSteps);
      } else {
        console.error("Failed to retrieve completed steps data");
      }
      setLoadingSteps(false);
    } catch (error) {
      console.error("Error fetching completed steps:", error);
    }
  };

  const lastItemTable =
    tableData.length > 0 ? tableData[tableData.length - 1] : null;

  const checkStatusExists = (statusId) => {
    // return completedSteps.some((step) => step.grievanceStatusDesc === statusId);
    const lowercaseStatusId = statusId.toLowerCase();
    //console.log("status id lower case",lowercaseStatusId)
    return completedSteps.some((step) => {
      return (
        step.grievanceStatusDesc &&
        step.grievanceStatusDesc.toLowerCase() === lowercaseStatusId
      );
    });
  };

  const getROCCStatus = () => {
    if (checkStatusExists("Assigned to RO/CC"))
      return ["Assigned to RO/CC", true];
    if (checkStatusExists("Assigned to C-CARES Support"))
      return ["Assigned to C-CARES Support", true];
    return ["Forwarded to RO/CC/C-CARES", false];
  };
  const getResolveStatus = () => {
    if (checkStatusExists("Resolved")) return ["Resolved", true];
    if (checkStatusExists("Closed")) return ["Closed", true];
    return ["Closed/Resolved", false];
  };

  const isMemberStep = checkStatusExists("Submitted by Member");
  const isGCHStep = checkStatusExists("Submitted to GCH");
  //console.log("isGCH", isGCHStep)
  const isGCMStep = checkStatusExists("Submitted to GCM");
  const [roccStatus, isROCCStep] = getROCCStatus();
  //const isResolvedStep = checkStatusExists("Resolved") || checkStatusExists("Closed");
  const [resolvedStatus, isResolvedStep] = getResolveStatus();

  const memberColor = isMemberStep ? "green" : "green";
  const gchColor = isGCHStep ? "green" : "gray";
  const gcmColor = isGCMStep ? "green" : "gray";
  const roccColor = isROCCStep ? "green" : "gray";
  const resolvedColor = isResolvedStep ? "green" : "gray";

  const handleOkErrorAlert = () => {
    setErrorAlertOpen(false); // Close the error alert
    // You can add additional error handling logic here as needed
  };

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper
            elevation={3}
            sx={{ padding: isMobile ? "1rem" : "3rem", borderRadius: "8px" }}
          >
            <Grid item xs={12}>
              <TitleText title="Grievance Claim Status " />
            </Grid>
            <FormDivider />

            {loadingSteps ? (
              <p>Loading steps...</p>
            ) : (
              <Stepper
                orientation={isMobile ? "vertical" : "horizontal"}
                alternativeLabel={!isMobile}
              >
                <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon {...props} style={{ color: memberColor }} />
                    )}
                  >
                    <div style={{ color: memberColor, fontWeight: 400 }}>
                      {isMemberStep
                        ? "Submitted by Member"
                        : "Submitted by Member"}
                    </div>
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon {...props} style={{ color: gchColor }} />
                    )}
                  >
                    <div style={{ color: gchColor }}>
                      {isGCHStep ? "Submitted to GCH" : "Forwarded to GCH"}
                    </div>
                  </StepLabel>
                </Step>

                <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon {...props} style={{ color: gcmColor }} />
                    )}
                  >
                    <div style={{ color: gcmColor }}>
                      {isGCMStep ? "Submitted to GCM" : "Forwarded to GCM"}
                    </div>
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon {...props} style={{ color: roccColor }} />
                    )}
                  >
                    <div style={{ color: roccColor }}>{roccStatus}</div>
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon {...props} style={{ color: resolvedColor }} />
                    )}
                  >
                    <div style={{ color: resolvedColor }}>{resolvedStatus}</div>
                  </StepLabel>
                </Step>
              </Stepper>
            )}

            <br />
            <Grid container justifyContent="center">
              <Grid item xs={6}>
                <Paper
                  elevation={3}
                  sx={{
                    borderRadius: "8px",
                    padding: "1rem",
                    border: "1px solid #CCCCC",
                    maxHeight: "400px",
                    overflow: "auto",
                  }}
                >
                  {loadingTable ? (
                    <p>Loading table data...</p>
                  ) : (
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ backgroundColor: "#ecf9ff" }}>
                            <b>Grievance Id:</b>
                          </TableCell>
                          <TableCell style={{ backgroundColor: "#ecf9ff" }}>
                            {" "}
                            {lastItemTable && lastItemTable["Grievance Id"]}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ backgroundColor: "#ffffff" }}>
                            <b>Submit Date:</b>
                          </TableCell>
                          <TableCell style={{ backgroundColor: "#ffffff" }}>
                            {lastItemTable && lastItemTable["Submit Date"]}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ backgroundColor: "#ecf9ff" }}>
                            <b>Current Status:</b>
                          </TableCell>
                          <TableCell style={{ backgroundColor: "#ecf9ff" }}>
                            {lastItemTable && lastItemTable["Current Status"]}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ backgroundColor: "#ffffff" }}>
                            <b>Current Status Since:</b>
                          </TableCell>
                          <TableCell style={{ backgroundColor: "#ffffff" }}>
                            {lastItemTable && lastItemTable["Prev Status"]}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <ErrorAlert
        open={errorAlertOpen}
        handleOk={handleOkErrorAlert}
        title="Error"
        body={error}
        buttonName="OK"
      />
    </div>
  );
};

export default CurrentStatusGreivance;
