import jwtDecode from "jwt-decode";
import { useCallback, useEffect, useState } from "react";

function DashboardOfficer() {
  const [loginId, setLoginId] = useState();
  const [role, setRole] = useState();
  const [shouldDisplayIframe, setShouldDisplayIframe] = useState(false);
  const [tokenn, setTokenn] = useState();
  // Fetch user token and determine role
  const fetchTokenAndUserRole = useCallback(() => {
    const token = localStorage.getItem("jwt");
    if (token) {
      setTokenn(token);
      const cleanedToken = token.replace(/"/g, "");
      const decoded = jwtDecode(cleanedToken);
      const loginId = decoded.user;
      const role = decoded.role;
      setRole(role);
      setLoginId(loginId);
    } else {
      // Handle token not found
    }
  }, []);

  // Fetch token and user role on component mount
  useEffect(() => {
    fetchTokenAndUserRole();
  }, [fetchTokenAndUserRole]);

  // Set state to display iframe if role is "RH" or "AGM"
  useEffect(() => {
    if (role === "RH" || role === "AGM" || role === "CM" || role === "CVO") {
      setShouldDisplayIframe(true);
    }
  }, [role]);

  return (
    <div>
      {shouldDisplayIframe ? (
        <iframe
          src={`https://cares-cmpfo.in/dashboard/claims-stats/claim_status_chart?tokenn=${tokenn}`}
          style={{ width: "100%", height: "100vh", border: "none" }}
          title="Claims Status Chart"
        />
      ) : (
        <p>Unauthorized access</p>
      )}
    </div>
  );
}

export default DashboardOfficer;
