import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import LabelText from "../../FormComponent/LabelText";
import SubtitleText from "../../FormComponent/SubtitleText";
import ValueText from "../../FormComponent/ValueText";

function MemberDetails(props) {
  const { member } = props;

  let formattedPfAddress = null;
  let formattedPenAddress = null;

  if (member.pfAddress) {
    try {
      const add = JSON.parse(member.pfAddress);
      formattedPfAddress = `${add.addr_l1}, ${add.addr_l2}, ${add["village/town/city"]}, ${add.district}, ${add.state}, ${add.pin_code}`;
    } catch (error) {}
  }

  if (member.penAddress) {
    try {
      const add = JSON.parse(member.penAddress);
      formattedPenAddress = `${add.addr_l1}, ${add.addr_l2}, ${add["village/town/city"]}, ${add.district}, ${add.state}, ${add.pin_code}`;
    } catch (error) {}
  }

  const options = { day: "2-digit", month: "2-digit", year: "numeric" };

  const salutation = [
    { id: 1, title: "Mr" },
    { id: 2, title: "Mrs" },
    { id: 3, title: "" },
    { id: 4, title: "Ms" },
    { id: 5, title: "Late" },
    { id: 6, title: "Kumar" },
    { id: 7, title: "Kumari" },
    { id: 8, title: "Dr" },
  ];

  const gender = [
    { id: "M", title: "Male" },
    { id: "F", title: "Female" },
    { id: "O", title: "Other" },
  ];
  const maritalStatus = [
    { id: "M", title: "Married" },
    { id: "U", title: "Unmarried" },
    { id: "W", title: "Widow/Widower" },
    { id: "D", title: "Divorced" },
  ];

  // if (){}
  return (
    <Grid container justifyContent="center" sx={{ wordWrap: "break-word" }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {/* <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}> */}
        <>
          {/* <Grid item xs={12}>
              <TitleText title="PF Claim Submission" />
            </Grid> */}
          {/* <FormDivider /> */}
          {/* <Form> */}
          <Grid
            container
            justifyContent="space-between"
            alignItems="start"
            rowGap={3}>
            {/* section 1  */}
            <Grid item xs={12} sm={12}>
              <SubtitleText title="Personal Information" />
            </Grid>
            {/* column 1  */}
            <Grid item xs={12} sm={5}>
              <Grid
                container
                alignItems="start"
                justifyContent="space-between"
                spacing={2}
                columnGap={1}>
                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Salutation" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText
                    title={
                      salutation.find((item) => item.id === member.titleId)
                        ?.title
                    }
                  />
                </Grid>
                {/* row  */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Full Name" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText title={member.fullName} />
                </Grid>
                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Date of Birth" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* <ValueText title={member.dateOfBirth} /> */}
                  <ValueText
                    title={
                      member.dateOfBirth
                        ? new Date(member.dateOfBirth).toLocaleDateString(
                            "en-GB",
                            options
                          )
                        : ""
                    }
                  />
                </Grid>
                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Pan" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText title={`XXXXXX${member.panNo?.slice(-4)}`} />
                </Grid>
                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Aadhaar Number" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText
                    title={`XXXX XXXX ${member.aadhaarNumber?.slice(-4)}`}
                  />
                </Grid>
                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Marital Status" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText
                    title={
                      maritalStatus.find(
                        (item) => item.id === member.maritalStatus
                      )?.title
                    }
                  />
                </Grid>
                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Address" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText
                    title={`
     ${member.addressL1 ? member.addressL1 + "," : ""}
      
     ${member.addressL2 ? member.addressL2 + "," : ""}
      
     ${member.village ? member.village + "," : ""}
      
     ${member.city ? member.city + "," : ""}
      
     ${member.district ? member.district + "," : ""}
      
     ${member.state ? member.state + "," : ""}
      
     ${member.country ? member.country + "," : ""}
      
     ${member.pincode ? member.pincode : ""}
     `}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* column 2  */}
            <Grid item xs={12} sm={5}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="start"
                spacing={2}
                columnGap={1}>
                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Religion" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText title={member.religionName} />
                </Grid>
                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Gender" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText
                    title={
                      gender.find((item) => item.id === member.gender)?.title
                    }
                  />
                </Grid>
                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Name of Father/Husband" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText title={member.fatherHusbandName} />
                </Grid>
                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Email ID" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText title={member.email} />
                </Grid>
                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Mobile Number" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText
                    title={
                      member.mobileNo !== 0
                        ? `XXXXXX${member.mobileNo?.toString().slice(-4)}`
                        : ""
                    }
                  />
                </Grid>
                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Landline Number" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText
                    title={
                      member.landNo !== 0
                        ? `XXXXXX${member.landNo?.toString().slice(-4)}`
                        : ""
                    }
                  />
                </Grid>
                {/* row */}
                {/* <Grid item xs={12} sm={4}>
                  <LabelText title="Permanant Address" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}
                >
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText
                    title={`
     ${member.addressL1 ? member.addressL1 + "," : ""}
      
     ${member.addressL2 ? member.addressL2 + "," : ""}
      
     ${member.village ? member.village + "," : ""}
      
     ${member.city ? member.city + "," : ""}
      
     ${member.district ? member.district + "," : ""}
      
     ${member.state ? member.state + "," : ""}
      
     ${member.country ? member.country + "," : ""}
      
     ${member.pincode ? member.pincode : ""}
     `}
                  />
                </Grid> */}
              </Grid>
            </Grid>
            {/* ************************************** */}
            {/* section 2  */}
            <Grid item xs={12} sm={12}>
              <SubtitleText title="Employment Details" />
            </Grid>
            {/* column 1  */}
            <Grid item xs={12} sm={5}>
              <Grid
                container
                alignItems="start"
                justifyContent="space-between"
                spacing={2}
                columnGap={1}>
                {/* row  */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="CMPF Account Number" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText title={member.cmpfAccNo} />
                </Grid>
                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Colliery Code" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText title={member.unitId} />
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Coal Company" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText title={member.name} />
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="PF Start Date" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText
                    title={
                      member.pfStartDate
                        ? new Date(member.pfStartDate).toLocaleDateString(
                            "en-GB",
                            options
                          )
                        : ""
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* column 2  */}

            <Grid item xs={12} sm={5}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="start"
                spacing={2}
                columnGap={1}>
                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Employee ID" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText title={member.employeeId} />
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Colliery Name" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText title={member.unitName} />
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Joining Date" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText
                    title={
                      member.joiningDate
                        ? new Date(member.joiningDate).toLocaleDateString(
                            "en-GB",
                            options
                          )
                        : ""
                    }
                  />
                </Grid>

                {/* row */}
              </Grid>
            </Grid>
            {/* ********************Remittance Details PF****************** */}

            {/* section 3  */}
            <Grid item xs={12} sm={12}>
              <SubtitleText title="Bank Details for PF" />
            </Grid>

            {/* column 1  */}

            <Grid item xs={12} sm={5}>
              <Grid
                container
                alignItems="start"
                justifyContent="space-between"
                spacing={2}
                columnGap={1}>
                {/* row  */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Name of the Bank" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText title={member.pfBankName} />
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Account No." />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText title={member.bankAccNoPf} />
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="IFSC" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText title={member.pfBankIfsc} />
                </Grid>
              </Grid>
            </Grid>

            {/* column 2  */}

            <Grid item xs={12} sm={5}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="start"
                spacing={2}
                columnGap={1}>
                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Address " />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText title={formattedPfAddress} />
                </Grid>

                {/* row */}
              </Grid>
            </Grid>
            {/* ********************Remittance Details Pension ****************** */}

            {/* section 3  */}
            <Grid item xs={12} sm={12}>
              <SubtitleText title="Bank Details for Pension" />
            </Grid>

            {/* column 1  */}

            <Grid item xs={12} sm={5}>
              <Grid
                container
                alignItems="start"
                justifyContent="space-between"
                spacing={2}
                columnGap={1}>
                {/* row  */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Name of the Bank" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText title={member.penBankName} />
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Account No." />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText title={member.bankAccNoPen} />
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="IFSC" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText title={member.penBankIfsc} />
                </Grid>
              </Grid>
            </Grid>

            {/* column 2  */}

            <Grid item xs={12} sm={5}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="start"
                spacing={2}
                columnGap={1}>
                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title=" Address " />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText title={formattedPenAddress} />
                </Grid>

                {/* row */}
              </Grid>
            </Grid>
          </Grid>
        </>
        {/* )}
            </>
          )} */}
        {/* </Paper> */}
      </Grid>
    </Grid>
  );
}

export default MemberDetails;
