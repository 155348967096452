import React, { Fragment, useState, useEffect } from "react";
import AddTask from "@mui/icons-material/AddTask";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Paper, Box, Button, Divider } from "@mui/material";
import CustomConfirmationDialogue from "../../FormComponent/CustomConfirmationDialogue";
// ALERT TITLE HERE
import AlertTitle from "@mui/material/AlertTitle";
//ALERT TITLE ENDS HERE
import {
  formPatternValidation,
  trimStringUtility,
} from "../../Utility/UtilityMethods";
// import GrievanceTable from "./GrievanceTable";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { memberModel } from "../../Models/defaultModel";
import ValueText from "../../FormComponent/ValueText";
import TitleText from "../../FormComponent/TitleText";
import LabelText from "../../FormComponent/LabelText";
import CustomTextField from "../../FormComponent/CustomTextField";
import SucessAlert from "../../FormComponent/SuccessAlert";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import GroupedRadioButton from "../../FormComponent/GroupedRadioButton";
import SubtitleText from "../../FormComponent/SubtitleText";
import CustomTextArea from "../../FormComponent/CustomTextArea";
import LoadingIndicator from "../../FormComponent/LoadingIndicator";
import FormDivider from "../../FormComponent/FormDivider";
import { faClosedCaptioning } from "@fortawesome/free-solid-svg-icons";
import { Link, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
// import Stack from "@mui/material/Stack";

import axios from "axios";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import { getToken } from "../../UiManager/SessionChecker";
import jwtDecode from "jwt-decode";

function RaiseNewGrievance() {
  // create a default object
  // const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const defaultGrievance = {
    cmpfAccNo: "",
    grievanceCategDesc: "",
    grievanceSubCatDesc: "",
    grievanceDesc: "",
    docTypeDesc: "",
  };

  //Grievance Category
  // const defaultGrievanceCategory = {
  //   grievanceCategDesc: "",
  // };
  //state for the custiomconfigurationDialogue
  const [confirmation, setConfirmation] = useState(false);
  // const [confirmationmessage, setConfirmationmessage] = useState("");

  // create a state for grevience  and set its default value to defaultGrievance
  //done
  const [isGrievanceCategorySelected, setIsGrievanceCategorySelected] =
    useState(false); //state for the grivance type

  // state for the subcategory value
  const [isGrievanceSubCategorySelected, setIsGrievanceSubCategorySelected] =
    useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false); // creating  a state for submit button
  const [isErrorAlert, setIsErrorAlert] = useState(false);
  const [isErrorAlertsub, setIsErrorAlertsub] = useState(false);
  const [raiseGrievance, setRaiseGrievance] = useState({ defaultGrievance }); //Raise Grievance
  const [grievanceCategoryOption, setGrievanceCategoryOption] = useState([]); //GrievanceCategory
  const [grievanceSubCategoryOptions, setGrievanceSubCategoryOptions] =
    useState([]);
  const navigate = useNavigate();
  const [readOnly, setReadOnly] = useState(false);
  const [isProgramLoaded, setIsProgramLoaded] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  //const sampleMemberModel = {} taken from defaultmodel.js (folder:Model)
  //put values to each variable itself

  //Configuraton
  // dropdown cofigs

  const location = useLocation();

  //Grievance category

  const grievanceCategDesc = {
    name: "grievanceCategDesc",
    // label: "salutation",
    helperText: "",
    // disabled: readOnly,
    initialValue: raiseGrievance.grievanceCategDesc,
    //options : [{ id: "", title: "" },]
    options: grievanceCategoryOption,
  };

  //Grievance Subcategory
  const grievanceSubCatDesc = {
    name: "grievanceSubCatDesc",
    // label: "salutation",
    helperText: "",
    // disabled: readOnly,
    initialValue: raiseGrievance.grievanceSubCatDesc,
    options: grievanceSubCategoryOptions,
  };

  //document description config
  // const [documentDescription, setDocumentDescription] = useState("");

  const docTypeDesc = {
    name: "docTypeDesc",
    type: "text",
    helperText: "Maximum 80 words",
    placeholder: "Document Description",
    defaultValue: raiseGrievance.docTypeDesc,
    // defaultValue: "",

    errorMessage: "Don't use Special Character",
    // pattern: "^[a-zA-Z0-9., -]+$",
    required: false,
    disabled: readOnly,
    multiline: true,
    maxLength: 80,
    //here maxlength is not working
  };

  //Grievance description config
  const grievanceDesc = {
    name: "grievanceDesc",
    type: "text",
    helperText: "Maximum 500 words",
    placeholder: "Grievance Description",
    defaultValue: raiseGrievance.grievanceDesc,
    errorMessage: " Don't use Special Character ",
    // label: "First Name",
    // pattern: "^[a-zA-Z0-9., -]+$",
    required: false,
    multiline: true,
    // disabled: readOnly,
    maxLength: "500",
  };

  //time to  give the api's
  const fetchGrievanceCategory = async () => {
    try {
      const url =
        process.env.REACT_APP_SERVER_URL + "/display/grievance/category";

      const response = await axios.get(url);
      // console.log("response", response);
      const data = response.data.data;
      // console.log("data", data);
      const options = data.map((option) => ({
        id: option.grievanceCategId.toString(),
        title: option.grievanceCategDesc,
      }));
      // console.log("options", options);
      setGrievanceCategoryOption(options); // Set the fetched data to the tablePreview state // we are taking data[0] as the response recevied is an array of object and we need only the first element

      //   setIsLoading(false);
    } catch (error) {
      // console.error("Error in fetching Grievance Category: ", error);
    }
  };

  useEffect(() => {
    fetchGrievanceCategory();
  }, []);

  const onChangeone = async (e) => {
    const selectedGrievanceCategId = e.target.value; // Assuming the value of the selected option is the grievanceCategId
    setIsGrievanceCategorySelected(selectedGrievanceCategId !== "");
    // Create a new URLSearchParams object to hold the form data
    const formData = new URLSearchParams();
    formData.append("grievanceCategId", selectedGrievanceCategId);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/display/grievance/subcategory`,
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      const subCategoryOptions = response.data.data.map((option) => ({
        id: option.grievanceSubCategId.toString(),
        title: option.grievanceSubCatDesc,
      }));
      // Update the state with the fetched subCategoryOptions
      setGrievanceSubCategoryOptions(subCategoryOptions);
    } catch (error) {
      // console.error("Error in fetching Grievance Subcategory: ", error);
    }
    setRaiseGrievance({
      ...raiseGrievance,
      [e.target.name]: e.target.value,
    });
  };

  //____________________________

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const allowedTypes = ["application/pdf", "image/jpg"];
    // if (selectedFile && !selectedFile.type.includes("pdf", "docx", "jpeg"))
    if (
      !selectedFile ||
      !allowedTypes.some((type) => selectedFile.type.includes(type))
    ) {
      // console.error('Please select a PDF file.');
      // <p> plz select required format </p>
      // alert('Please select a PDF file.');
      event.target.value = null;
      setIsError(true);
      setErrorMessage("Please select a PDF file.");
      return;
    }

    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
    if (selectedFile && selectedFile.size > maxSizeInBytes) {
      // console.error('File size exceeds the maximum limit of 2 MB.');
      // alert('Please  select a PDF file.');
      event.target.value = null;
      setIsError(true);
      setErrorMessage("File size exceeds the maximum limit of 2 MB.");
      return;
    }
    setRaiseGrievance({
      ...raiseGrievance,
      defaultGrievance: {
        ...raiseGrievance.defaultGrievance,
        file: selectedFile,
      },
    });
  };

  const handleUpload = () => {
    setConfirmation(true);
    setConfirmation(false);
    setIsDisabled(true);

    // console.log("handle upload is", raiseGrievance.defaultGrievance);
    const file = raiseGrievance.defaultGrievance.file
      ? raiseGrievance.defaultGrievance.file
      : "";
    // console.log("file", file);

    if (!raiseGrievance.grievanceCategDesc) {
      // Display an error message or handle the validation as per your UI/UX requirements
      setIsErrorAlert(true);
      // console.error("Please select a grievance category before submitting.");
      return;
    }
    //   if(!isGrievanceSubCategorySelected)
    //  {
    //   setIsErrorAlertsub(true);
    //   console.error("Please select a grievance subcategory before submitting.");
    //   return;
    //  }
    if (!raiseGrievance.grievanceSubCatDesc) {
      setIsErrorAlertsub(true);
      // console.error("Please select a grievance subcategory before submitting.");
      return;
    }

    // if(!file)
    // {
    //   console.log("slect file")
    // }
    let base64Data = "";
    const allowedTypes = ["application/pdf"];
    // , "image/jpg"]
    if (file) {
      // console.log("File Conversion Working");
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        base64Data = reader.result;
        let mimeType;
        if (file.type === "application/pdf") {
          mimeType = "application/pdf";
        } else if (file.type === "image/jpg") {
          mimeType = "image/jpg";
        } else if (file.type === "image/png") {
          mimeType = "image/png";
        } else {
          // Handle other file types if necessary
        }
        // base64Data = base64Data.replace(/^data:.+;base64,/, data:${mimeType};base64,JVBERi0xLjQKJdPr6eEKMSAwIG9iago8PC9);
        base64Data = base64Data.replace(
          /^data:.+;base64,/,
          `data:${mimeType};base64,JVBERi0xLjQKJdPr6eEKMSAwIG9iago8PC9`
        );

        if (!allowedTypes) {
          // console.log("inside first if");
          // console.log("Selected file");
          setIsError("");
        } else {
          // console.log("inside 1st else ");
          handleSubmit(base64Data);
        }
      };

      //console.log("base64Data is getting converted", base64Data);
    } else {
      // console.log("in else");
      // console.log("inside 2nd else");
      handleSubmit("");
      // allowedTypes?setIsError(true) setErrorMessage("Provide correct file"):handleSubmit("");
    }
    //    if(!file)
    // {
    //   console.log("slect file")
    // }
    // else {

    // }
    // }

    // setIsLoading(true);
    //  };
    // reader.onerror = (error) => {
    //   console.error("Error converting file to base64", error);
    // };
  };

  // submit api start from here
  const handleSubmit = async (base64Data) => {
    setIsLoading(true);
    // if(10)
    // {

    // }

    // console.log("file",file.type);

    try {
      const url = process.env.REACT_APP_SERVER_URL + "/member/new/grievence";
      const grievanceSubCategId = raiseGrievance.grievanceSubCatDesc;
      // uncomment this
      let token = getToken();
      // console.log("token on aao", token);
      if (!token) {
        setIsLoading(false);
        //setIsFailed(true);
        return;
      }
      token = token.replace('"', "").replace('"', "");
      const cmpfAccNo = jwtDecode(token).user;
      // console.log("loginId on aao", cmpfAccNo);
      //  const cmpfAccNo = "DGR/5/2061"; //comment this

      const objectToBeSent = {
        cmpfAccNo: cmpfAccNo,
        grievanceSubCategId: parseInt(grievanceSubCategId),
        grievanceDesc: raiseGrievance.grievanceDesc
          ? raiseGrievance.grievanceDesc
          : "",
        //upldedDoc: raiseGrievance.upldedDoc,
        upldedDoc: base64Data,
        docTypeDesc: raiseGrievance.docTypeDesc
          ? raiseGrievance.docTypeDesc
          : "",
      };
      // console.log("objectToBeSent", objectToBeSent);
      const response = await axios.post(url, objectToBeSent);
      // console.log("response Status", response.status);
      if (response.status === 200) {
        // console.log("status wala response", response);
        setIsSubmitSuccess(true);
        setIsLoading(false);
      } else {
      }
    } catch (error) {
      // console.error("Error in submitting grievance: ", error);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Sorry Could not Proceed!! Please try again later");
      // setIsSubmitSuccess(true);
      // setIsErrorAlert(true);
      // Handle error
    }
  };

  //submmit api ends here

  //the api ends here
  const onChange = (e) => {
    // console.log("onchange", e.target.value);
    setRaiseGrievance({ ...raiseGrievance, [e.target.name]: e.target.value });
    setIsGrievanceSubCategorySelected(e.target.value !== "");
  };

  const handleSubmitSucess = () => {
    setIsSubmitSuccess(false);
    setIsDisabled(false);
    navigate("/member/grievances");
  };

  const handleError = () => {
    //window.location.reload();
    setIsDisabled(false);

    setIsErrorAlert(false);
    setIsLoading(false);
  };

  const handleErrorsub = () => {
    // window.location.reload();
    setIsDisabled(false);

    setIsLoading(false);
    setIsErrorAlertsub(false);
  };

  const handleGoBack = () => {
    navigate(-1); //navigate back to the previous page
    window.scrollTo(0, 0); // scroll to the top of the page
  };

  // validation start from

  const validate = () => {
    if (
      !formPatternValidation(raiseGrievance.grievanceDesc, "^[a-zA-Z0-9., -]+$")
    ) {
      // console.log("In validate off  grievance description");
      return false;
    }
  };

  /// error alert handler
  const handleErrorAlert = () => {
    setIsError(false);
    setIsLoading(false);
  };
  //Confirmation alert
  const handleConfirmation = () => {
    setConfirmation(true);
  };

  const handleDialogueCancel = () => {
    // setIsDisabled(true);
    setConfirmation(false);
  };
  return (
    <>
      <SucessAlert
        handleOk={handleSubmitSucess}
        open={isSubmitSuccess}
        title="Success"
        body="Grievance Submitted Successfully"
        buttonName="Ok"
      />

      <ErrorAlert
        handleOk={handleError}
        open={isErrorAlert}
        title="Error!"
        body=" Choose Grievance Category "
        buttonName="Ok"
      />
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error!"
        body={errorMessage}
        buttonName="Ok"
      />
      <ErrorAlert
        handleOk={handleErrorsub}
        open={isErrorAlertsub}
        title="Error!"
        body=" Choose Grievance SubCategory "
        buttonName="Ok"
      />
      <CustomConfirmationDialogue
        handleOk={handleUpload}
        handleCancel={handleDialogueCancel}
        title="Confirmation"
        body="Are you sure you want to submit?"
        open={confirmation}
        isDisabled={isDisabled}
        buttonName="Yes"
        buttonName2="No"
      />

      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            {!isProgramLoaded ? (
              <LoadingIndicator />
            ) : (
              <>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      align="left"
                      onClick={handleGoBack}
                      //sx={{ backgroundColor: "darkviolet" }}
                      style={{ marginTop: "-30px" }}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <TitleText title="Raise New Grievance" />
                </Grid>

                <FormDivider />

                {/* <Form> */}

                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="start"
                  rowGap={2}
                >
                  {/* section 1  */}

                  {/* column 1  */}

                  <Grid item xs={12} sm={5}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                      columnGap={1}
                    >
                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title="Grievance Category *" />
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <CustomDropdown
                          {...grievanceCategDesc}
                          onchange={onChangeone}
                        />
                      </Grid>

                      {/* row */}
                      {/* secound row */}
                      {/* row  */}
                    </Grid>
                  </Grid>

                  {/* column 2  */}

                  <Grid item xs={12} sm={5}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                      columnGap={1}
                    >
                      {/* row */}
                      <Grid item xs={12} sm={4}>
                        <LabelText title="Grievance Subcategory" />
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <CustomDropdown
                          {...grievanceSubCatDesc}
                          onchange={onChange}
                        />
                      </Grid>

                      {/* row */}

                      {/* row */}

                      {/* row */}
                    </Grid>
                  </Grid>

                  {/* ****** */}

                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="start"
                    rowGap={3}
                  >
                    {/* column */}
                    <Grid item xs={12} sm={3}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* rows */}
                        <Grid item xs={12} sm={7} style={{ marginTop: "5%" }}>
                          <LabelText title="Grievance Description" />
                        </Grid>

                        <Grid item sm={1}></Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        columnGap={1}
                      ></Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                          maxHeight: "150px",
                          overflowY: "auto",
                          marginTop: "15px",
                          marginLeft: { sm: "-23%" },
                        }}
                      >
                        <CustomTextArea
                          {...grievanceDesc}
                          onchange={onChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* grievance Description new code will lie here  */}

                  <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      Upload Document
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                      columnGap={1}
                    >
                      {/* row */}

                      <Grid item xs={12} sm={4}>
                        <LabelText title="Document Description" />
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <CustomTextArea {...docTypeDesc} onchange={onChange} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                      columnGap={1}
                    >
                      {/* row */}
                      <Grid item xs={12} sm={4} style={{ paddingTop: "30px" }}>
                        <LabelText title="Select Document" />
                      </Grid>
                      <Grid item xs={12} sm={7} style={{ marginTop: "2.5%" }}>
                        <input type="file" onChange={handleFileChange} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} style={{}}>
                    {/* POP UP */}
                    {/* pop up ends here  */}
                    <Button
                      title="Submit"
                      variant="contained"
                      color="primary"
                      onClick={handleConfirmation}
                      disabled={
                        isLoading
                        //  ||
                        // !raiseGrievance.grievanceCategDesc ||
                        // !raiseGrievance.grievanceSubCatDesc
                        // !raiseGrievance.grievanceDesc ||
                        // !raiseGrievance.docTypeDesc
                      }
                    >
                      {isLoading ? (
                        <CircularProgress color="secondary" />
                      ) : (
                        <>
                          &nbsp;Submit
                          {/* Render the icon when isLoading is false */}
                        </>
                      )}
                      {/* Submit */}
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
export default RaiseNewGrievance;
