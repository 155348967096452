import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';

const Reloader = (props) => {
    const { refreshHandler } = props;
    return (

        <Box sx={{ padding: '3rem', border: 0, background: 'white' }}>
            <Grid container justifyContent='center' alignItems='center' direction='column' rowGap={2}>
                <Grid item>
                    <Typography variant="h3" align="center" color='red' sx={{
                        fontSize: {
                            xs: '14px',
                            sm: '16px',
                            md: '16px',
                            lg: '18px',
                            xl: '19px',
                        }
                    }}>
                        Something Went Wrong!
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1" align="center">
                        Ooops! Sorry try again.
                    </Typography>
                </Grid>
                <Grid item>
                    <Button onClick={refreshHandler} variant="outlined">Refresh</Button>
                </Grid>
            </Grid>
        </Box>

    );
};

export default Reloader;