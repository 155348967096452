import React from "react";
import { useLocation } from "react-router-dom";

const ViewClaimDetails = () => {
  const { state } = useLocation(); // paste this
  const claimId = state.passingId;
  console.log(state);
  console.log("claimId", claimId);

  return <div>ViewClaimDetails {state.passingId}</div>;
};

export default ViewClaimDetails;

//where ever you need the id you have to use state.passingId
//const grivinceId = state.passingId;
