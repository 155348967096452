import React from "react";
import { Typography } from "@mui/material";
import  InvalidUser from '../../../../images/InvalidUser.png';
import  InvalidCredentials from '../../../../images/InvalidCredentials.png';
import AccountBlocked from '../../../../images/AccountBlocked.png';
import  InvalidOtpLogin from '../../../../images/InvalidOtpLogin.png';
import OTPBlocked from '../../../../images/OTPBlocked.png';
 const MemberLoginGuidelines=()=>{
    return(
    <>
    <Typography varient="h6"><b>MEMBER LOGIN GUIDELINES</b></Typography>
    <Typography varient="body1" align='justify'>
        <ol type="1">
            <li>
            If username/password is incorrect a pop up comes to enter valid credentials.<br />(Figure 16 and Figure 17)

            <p align="center"> <img  className="bordered-image" src={InvalidUser} height="55%" width="75%"/><br />
                Figure 16: Invalid User</p>

                <p align="center"> <img  className="bordered-image" src={InvalidCredentials} height="55%" width="75%"/><br />
                Figure 17: Invalid Credentials</p>


            </li>
            <li>
            A member has only three attempts to enter valid credentials, after that the member account is blocked for 24 hours and the message as shown is figure 18 is displayed.
            <p align="center"> <img  className="bordered-image" src={AccountBlocked} height="55%px" width="75%"/><br />
                Figure 18: Account Blocked</p>


            </li>
            <li>
            The member can make maximum 3 attempts to enter correct OTP, after which he/she is blocked for 10 minutes. Each OTP is valid for 3 minutes. (Figure. 19)
            <p align="center"><img  className="bordered-image" src={InvalidOtpLogin} height="35%" width="75%"/><br />
                Figure 19: Invalid OTP</p>


            </li>
            <li>
            If OTP is not received, member can resend the OTP two more times after which the account is locked for 10 minutes as shown in figure 20.
            <p align="center"><img  className="bordered-image" src={OTPBlocked} height="35%" width="75%"/><br />
                Figure 20: OTP Blocked</p>


            </li>
        </ol>

    </Typography>
    </>
    );}
 export default MemberLoginGuidelines;