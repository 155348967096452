import React, { useState, useEffect, useLayoutEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { keyframes } from "@mui/material";
import otpImage from "../../images/otp-mobile.png";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { faBoxesAlt } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import qs from "qs";
import { UserContext } from "../../UiManager/UserProvider";
import jwtDecode from "jwt-decode";
import SucessAlert from "../../FormComponent/SuccessAlert";
import ValueText from "../../FormComponent/ValueText";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import { useRef } from "react";

const useStyles = makeStyles({
  otpInput: {
    // width: 40,
    width: "100%",
    maxWidth: 60,
    textAlign: "center",
    "& input": {
      textAlign: "center",
    },
  },
  modalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "90vh",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "1rem",
  },
  responsiveImage: {
    width: "50px",
  },
});

const OtpComponent = (props) => {
  const nextPage = useLocation();
  // const userContext = UserContext(UserContext);

  const navigate = useNavigate();
  const nextPath = useLocation();

  const rollInLeft = keyframes`


    
    0% {
        -webkit-transform: translateX(-800px) rotate(-540deg);
                transform: translateX(-800px) rotate(-540deg);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateX(0) rotate(0deg);
                transform: translateX(0) rotate(0deg);
        opacity: 1;
      }
    `;

  const flipInHorBottom = keyframes`
    0% {
        -webkit-transform: rotateX(80deg);
                transform: rotateX(80deg);
        opacity: 0;
      }
      100% {
        -webkit-transform: rotateX(0);
                transform: rotateX(0);
        opacity: 1;
      }
`;

  const shakeHorizontal = keyframes`
    0%,
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
      -webkit-transform: translateX(-10px);
              transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
      -webkit-transform: translateX(10px);
              transform: translateX(10px);
    }
    80% {
      -webkit-transform: translateX(8px);
              transform: translateX(8px);
    }
    90% {
      -webkit-transform: translateX(-8px);
              transform: translateX(-8px);
    }
    `;

  // useRef for message
  const messageRef = useRef("");
  const currentState = window.history;

  const classes = useStyles();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [otpValue, setOtpValue] = useState("");
  const [otpCall, setOtpCall] = useState(false);
  const [performAnimation, setPerformAnimation] = useState(false);
  const [apiMessage, setApiMessage] = useState("");

  const [isVerifying, setIsVerifying] = useState(false);

  const [isChangePasswordSucess, setIsChangePasswordSucess] = useState(false);
  const [isEnrolSuccess, setIsEnrolSuccess] = useState(false);
  const [isEnrolFailed, setIsEnrolFailed] = useState(false);

  const [isChangePasswordError, setIsChangePasswordError] = useState(false);
  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [isUnauthorized, setIsUnathourized] = useState(false);

  const updateHistoryState = (newMessage) => {
    console.log("newMessage", newMessage);
    sessionStorage.setItem("messageRef", newMessage);
  };

  const handleOtpChange = (index, e) => {
    const value = e.target.value;
    if (!isNaN(value)) {
      if (value === "") {
        // Handle backspace/delete key press
        const updatedOtp = [...otp];
        updatedOtp[index] = "";
        setOtp(updatedOtp);
        if (index > 0) {
          const prevSibling = document.querySelector(`#otp-input-${index - 1}`);
          if (prevSibling) {
            prevSibling.focus();
          }
        }
      } else {
        const sanitizedValue = value.replace(/\D/g, "");
        const updatedOtp = [...otp];
        updatedOtp[index] = sanitizedValue.slice(0, 1);
        setOtp(updatedOtp);
        if (value !== "") {
          const nextSibling = document.querySelector(`#otp-input-${index + 1}`);
          if (nextSibling) {
            nextSibling.focus();
          }
        }
      }
      const joinedOtpValue = otp.join("");
      setOtpValue(joinedOtpValue);
    }
  };

  const logOtp = () => {
    const filledDigits = otp.filter((digit) => digit !== "");
    if (filledDigits.length === otp.length) {
      // console.log('OTP:', otp.join(''));
      console.log("OTP Value:", otpValue);
    }
  };

  const login = () => {
    //move this api to login page
    setIsVerifying(true);
    if (nextPath.state.data.role === "Member or Employee") {
      axios
        .post(process.env.REACT_APP_SERVER_URL + "/member/login", {
          loginId: nextPath.state.data.loginId,
          password: nextPath.state.data.password,
          role: nextPath.state.data.role,
        })
        .then((resp) => {
          console.log("login res", resp);
          if (resp.status === 200) {
            setIsVerifying(false);
            console.log("login success resp", resp);
            sessionStorage.removeItem("messageRef");
            sessionStorage.setItem("jwt", resp.data.data.token);
            localStorage.setItem("jwt", resp.data.data.token);
            localStorage.setItem("refresh", resp.data.data.refreshToken);
            navigate("/redirector", { replace: true });
            return;
          }
          setIsVerifying(false);
          setIsLoginFailed(true);
        })
        .catch(function (error) {
          // show error message of failure login
          setIsVerifying(false);
          setIsLoginFailed(true);
          console.log("login error", error);
        });
    } else {
      axios
        .post(process.env.REACT_APP_SERVER_URL + "/officer/login", {
          loginId: nextPath.state.data.loginId,
          password: nextPath.state.data.password,
          role: nextPath.state.data.role,
        })
        .then((resp) => {
          console.log("officer login res", resp);
          if (resp.status === 200) {
            setIsVerifying(false);
            console.log("login success resp", resp);
            sessionStorage.removeItem("messageRef");
            sessionStorage.setItem("jwt", resp.data.data.token);
            localStorage.setItem("jwt", resp.data.data.token);
            localStorage.setItem("refresh", resp.data.data.refreshToken);
            navigate("/redirector", { replace: true });
            return;
          }
          setIsVerifying(false);
          setIsLoginFailed(true);
        })
        .catch(function (error) {
          // show error message of failure login
          if(error.response.data.http_status_code === 401){
            setIsVerifying(false);
          setIsUnathourized(true);
          console.log("unauthorized error", error);
          }
          setIsVerifying(false);
          setIsLoginFailed(true);
          console.log("login error", error);
        });
    }
  };

  const ForgotPassword = () => {
    console.log("login id forg", nextPath.state.data.loginId);
    localStorage.setItem("loginId", nextPath.state.data.loginId);
    navigate("/forgotpassword/update", { replace: true });
  };
  //remove this on creation of single page forgot password
  const ForgotPasswordOfcr = () => {
    console.log("login id forg", nextPath.state.data.loginId);
    localStorage.setItem("loginId", nextPath.state.data.loginId);
    navigate("/forgotpasswordofcr/update", { replace: true });
  };

  const officerEnrol = () => {
    setIsVerifying(true);
    const passedData = nextPath.state.data;
    // const urlData = {
    //   loginId: passedData.login_id,
    //   password: window.btoa(passedData.password),
    //   officerName: passedData.officer_name,
    //   designation: passedData.designation,
    //   actor: "ccRep",
    //   actorName: passedData.actor_name,
    //   actorCode: passedData.actor_code,
    //   emailId: passedData.email_id,
    // };
    const urlData = {
      loginId: passedData.login_id,
      password: window.btoa(passedData.password),
      officerName: passedData.officer_name,
      designation: passedData.designation,
      actor: passedData.actor,
      //actor: "RO",
      actorName: passedData.actor_name,
      actorCode: passedData.actor_code,
      emailId: passedData.email_id,
    };
   // if (passedData.actor === "ccRep") {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios
        .post(
          process.env.REACT_APP_SERVER_URL + "/officer/enrollment",
          urlData,
          config
        )
        .then((resp) => {
          console.log("enrol res", resp);
          if (resp.status === 200) {
            setIsVerifying(false);
            console.log("enrol success", resp);
            // show success alert with signin button
            setIsEnrolSuccess(true);
            return;
          }
          setIsEnrolFailed(true);
          setIsVerifying(false);
        })
        .catch(function (error) {
          // show error message of failure login
          setIsVerifying(false);
          setIsEnrolFailed(true);
          console.log("enrol error", error);
        });
   // } else {
     // console.log("Ro enrolment under construction");
    }
 // };

  const updatePassword = () => {
    const data = nextPath.state.data;
    let token = localStorage.getItem("jwt");
    if (!token) {
      setIsVerifying(false);
      console.log("token not exist");
      return;
    }

    token = token.replace('"', "").replace('"', "");
    const formData = new FormData();
    formData.append("loginId", jwtDecode(token).user);
    formData.append("oldPassword", window.btoa(data.oldPassword));
    formData.append("newPassword", window.btoa(data.newPassword));
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/member/update/passsword",
        formData,
        config
      )
      .then((resp) => {
        if (resp.status === 200) {
          setIsVerifying(false);
          setIsChangePasswordSucess(true);
          return false;
        }
        setIsVerifying(false);
      })
      .catch(function (error) {
        setIsVerifying(false);
        setIsChangePasswordError(true);
      });
  };
  const updatePasswordOfcr = () => {
    const data = nextPath.state.data;
    let token = localStorage.getItem("jwt");
    if (!token) {
      setIsVerifying(false);
      console.log("token not exist");
      return;
    }

    token = token.replace('"', "").replace('"', "");
    const formData = new FormData();
    formData.append("loginId", jwtDecode(token).user);

    formData.append("newPassword", window.btoa(data.newPassword));
    //formData.append("newPassword", data.newPassword);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/officer/update/old/password",
        formData,
        config
      )
      .then((resp) => {
        if (resp.status === 200) {
          setIsVerifying(false);
          setIsChangePasswordSucess(true);
          return false;
        }
        setIsVerifying(false);
      })
      .catch(function (error) {
        console.log("cahnge password error", error);
        setIsVerifying(false);
        setIsChangePasswordError(true);
      });
  };

  const fetchOtp = (otp) => {
    if (
      nextPath.state.purpose === "LOGIN" ||
      nextPath.state.purpose === "FORGOTPASSWORD" 
      // || nextPath.state.purpose === "OFCRLOGIN" ||
      // nextPath.state.purpose === "FORGOTPASSWORDOFCR"
    ) {
      const data = nextPath.state.data;
      const userName = data.loginId;
      const sendDataOtp = qs.stringify({ loginId: userName, otp: otp });
      axios
        .post(
          process.env.REACT_APP_SERVER_URL + "/member/verify/login/otp",
          sendDataOtp
        )
        .then((resp) => {
          console.log("true logotp", resp);
          if (resp.status === 200) {
            if (
              nextPath.state.purpose === "LOGIN" 
              // || nextPath.state.purpose === "OFCRLOGIN"
            ) {
              login();
              return;
            }

            if (nextPath.state.purpose === "FORGOTPASSWORD") {
              ForgotPassword();
            }
            // if (nextPath.state.purpose === "FORGOTPASSWORDOFCR") {
            //   ForgotPasswordOfcr();
            // }
          }
        })
        .catch(function (error) {
          updateHistoryState(
            error.response
              ? error.response.data.error_message
              : "Server is not responding, try again later"
          );
          setApiMessage(
            error.response
              ? error.response.data.error_message
              : "Server is not responding, try again later"
          );
          console.log("true error", error);
        });
      return;
    }
    //----------------------------------------------------------//
    if (
      nextPath.state.purpose === "OFCRLOGIN" ||
      nextPath.state.purpose === "FORGOTPASSWORDOFCR" ){
        const data = nextPath.state.data;
      const userName = data.loginId;
      const sendDataOtp = qs.stringify({ loginId: userName, otp: otp });
      axios
        .post(
          process.env.REACT_APP_SERVER_URL + "/officer/verify/login/otp",
          sendDataOtp
        )
        .then((resp) => {
          console.log("true logotp", resp);
          if (resp.status === 200) {
            if (

              nextPath.state.purpose === "OFCRLOGIN"
            ) {
              login();
              return;
            }
            if (nextPath.state.purpose === "FORGOTPASSWORDOFCR") {
              ForgotPasswordOfcr();
            }

      } })
      .catch(function (error) {
        updateHistoryState(
          error.response
            ? error.response.data.error_message
            : "Server is not responding, try again later"
        );
        setApiMessage(
          error.response
            ? error.response.data.error_message
            : "Server is not responding, try again later"
        );
        console.log("true error", error);
      });
    return;
  }

    if (nextPath.state.purpose === "OFFICERENROL") {
      const data = nextPath.state.data;
      const userName = data.login_id;
      const sendDataOtp = qs.stringify({ loginId: userName, otp: otp });
      axios
        .post(
          process.env.REACT_APP_SERVER_URL + "/officer/verify/otp",
          sendDataOtp
        )
        .then((resp) => {
          console.log("true logotp", resp);
          if (resp.status === 200) {
            officerEnrol();
          }
        })
        .catch(function (error) {
          updateHistoryState(
            error.response
              ? error.response.data.error_message
              : "Server is not responding, try again later"
          );
          setApiMessage(
            error.response
              ? error.response.data.error_message
              : "Server is not responding, try again later"
          );
          console.log("true error", error);
        });
      return;
    }

    if (nextPath.state.purpose === "CHANGEPASSWORD") {
      setIsVerifying(true);
      let token = localStorage.getItem("jwt");
      if (!token) {
        console.log("token not exist");
        return;
      }

      token = token.replace('"', "").replace('"', "");
      const formData = new FormData();
      formData.append("loginId", jwtDecode(token).user);
      formData.append("otp", otp);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .post(
          process.env.REACT_APP_SERVER_URL +
            "/member/update/passsword/verify/otp",
          formData,
          config
        )
        .then((resp) => {
          if (resp.status === 200) {
            updatePassword();
            return;
          }
          setIsVerifying(false);
          // setApiMessage(resp.data.error_message);
        })
        .catch(function (error) {
          setIsVerifying(false);
          updateHistoryState(
            error.response
              ? error.response.data.error_message
              : "Server is not responding, try again later"
          );
          setApiMessage(
            error.response
              ? error.response.data.error_message
              : "Server is not responding, try again later"
          );
        });
    }
    //// for officer Change Password
    if (nextPath.state.purpose === "CHANGEPASSWORDOFCR") {
      setIsVerifying(true);
      let token = localStorage.getItem("jwt");
      if (!token) {
        console.log("token not exist");
        return;
      }

      token = token.replace('"', "").replace('"', "");
      const formData = new FormData();
      formData.append("loginId", jwtDecode(token).user);
      formData.append("otp", otp);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .post(
          process.env.REACT_APP_SERVER_URL +
            "/officer/update/passsword/verify/otp",
          formData,
          config
        )
        .then((resp) => {
          if (resp.status === 200) {
            updatePasswordOfcr();
            return;
          }
          setIsVerifying(false);
          // setApiMessage(resp.data.error_message);
        })
        .catch(function (error) {
          setIsVerifying(false);
          updateHistoryState(
            error.response
              ? error.response.data.error_message
              : "Server is not responding, try again later"
          );
          setApiMessage(
            error.response
              ? error.response.data.error_message
              : "Server is not responding, try again later"
          );
        });
    }
  };

  const resendHandler = () => {
    if (
      nextPath.state.purpose === "LOGIN" ||
      nextPath.state.purpose === "FORGOTPASSWORD"
    ) {
      console.log("otp calling useeffect");
      const data = nextPath.state.data;
      const userName = data.loginId;
      const sendData = qs.stringify({ loginId: userName });
      axios
        .post(
          process.env.REACT_APP_SERVER_URL + "/member/send/login/otp",
          sendData
        )
        .then((resp) => {
          if (resp.status === 200) {
            updateHistoryState(resp.data.message);
            setApiMessage(resp.data.message);
            return;
          }
          // setApiMessage(resp.data.error_message);
        })
        .catch(function (error) {
          updateHistoryState(
            error.response
              ? error.response.data.error_message
              : "Server is not responding, try again later"
          );
          setApiMessage(
            error.response
              ? error.response.data.error_message
              : "Server is not responding, try again later"
          );
        });
      // const sendData2 = qs.stringify({ "acc": userName })
      //  axios.post(process.env.REACT_APP_SERVER_URL + "/summary", sendData2)
      return;
    }
    if (nextPath.state.purpose === "CHANGEPASSWORD") {
      let token = localStorage.getItem("jwt");
      if (!token) {
        console.log("token not exist");
        return;
      }

      token = token.replace('"', "").replace('"', "");
      const formData = new FormData();
      formData.append("loginId", jwtDecode(token).user);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .post(
          process.env.REACT_APP_SERVER_URL +
            "/member/update/passsword/send/otp",
          formData,
          config
        )
        .then((resp) => {
          if (resp.status === 200) {
            updateHistoryState(resp.data.message);
            setApiMessage(resp.data.message);
            return;
          }
          // setApiMessage(resp.data.error_message);
        })
        .catch(function (error) {
          updateHistoryState(
            error.response
              ? error.response.data.error_message
              : "Server is not responding, try again later"
          );
          setApiMessage(
            error.response
              ? error.response.data.error_message
              : "Server is not responding, try again later"
          );
        });
    }
    if (nextPath.state.purpose === "OFCRLOGIN") {
      console.log("otp calling useeffect");
      const data = nextPath.state.data;
      const userName = data.loginId;
      const sendData = qs.stringify({ loginId: userName });
      axios
        .post(
          process.env.REACT_APP_SERVER_URL + "/officer/send/login/otp",
          sendData
        )
        .then((resp) => {
          if (resp.status === 200) {
            updateHistoryState(resp.data.message);
            setApiMessage(resp.data.message);
            return;
          }
          // setApiMessage(resp.data.error_message);
        })
        .catch(function (error) {
          updateHistoryState(
            error.response
              ? error.response.data.error_message
              : "Server is not responding, try again later"
          );
          setApiMessage(
            error.response
              ? error.response.data.error_message
              : "Server is not responding, try again later"
          );
        });
    }
  };

  useEffect(() => {
    const savedMessage = sessionStorage.getItem("messageRef");
    console.log("savedMessage", savedMessage);
    setApiMessage(
      savedMessage ? savedMessage : nextPath.state.messageRef.current
    );
  }, []);

  const [otpSend, setOtpsSend] = useState("");

  const handleMUIForm = () => {
    const filledDigits = otp.filter((digit) => digit !== "");
    if (filledDigits.length === otp.length) {
      fetchOtp(otp.join(""));
    }
    props.setIsLoginButtonDisabledGlobal(false);
  };

  const isVerifyButtonDisabled = otp.some((digit) => digit === "");

  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [resendTimer, setResendTimer] = useState(60);

  useEffect(() => {
    const timer = setInterval(() => {
      setResendTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
      setIsResendDisabled(true);
    };
  }, []);

  useEffect(() => {
    if (resendTimer === 0) {
      setIsResendDisabled(false);
      resendTimers();
    }
  }, [resendTimer]);

  const resendTimers = () => {
    setResendTimer(60);
  };

  const handleResendClick = () => {
    setIsResendDisabled(true);
    resendTimers();
    // Perform API call or any other logic for resending OTP
    resendHandler();
    // Simulate delay
  };

  const loggoutServer = () => {
    //logout axios
    let token = localStorage.getItem("jwt");
    if (!token) {
      return;
    }
 
    

    token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + "/member/logout";
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    axios.post(url, config).then((resp) => {
      console.log(resp.data.data);
    });
  };

  const handleLogout = () => {
    loggoutServer();
    localStorage.clear();
    navigate("/cmpfo", { replace: true });
  };

  const handleChangePasswordSuccess = () => {
    handleLogout();
    setIsChangePasswordSucess(false);
    // navigate('/', { replace: true });
  };

  const handleChangePasswordError = () => {
    setIsChangePasswordError(false);
    navigate("/member/changepassword", { replace: true });
  };

  const handleLoginFailureError = () => {
    setIsLoginFailed(false);
    navigate("/cmpfo", { replace: true });
  };

  const handleUnauhtorized = () => {
    setIsUnathourized(false);
    navigate("/cmpfo", { replace: true });
  };

  const handleEnrolFailureError = () => {
    setIsEnrolFailed(false);
    navigate("/cmpfo", { replace: true });
  };

  //officer
  const handleEnrolSuccess = () => {
    setIsEnrolSuccess(false);
    // navigate("/cmpfo", { replace: true });
    navigate("/login/officer", { replace: true });
  };

  return (
    <>
      <ErrorAlert
        handleOk={handleEnrolFailureError}
        open={isEnrolFailed}
        title="Error"
        body="Failed to enrol officer."
        buttonName="Ok"
      />
      <ErrorAlert
        handleOk={handleLoginFailureError}
        open={isLoginFailed}
        title="Error"
        body="Something went wrong"
        buttonName="Ok"
      />
       <ErrorAlert
        handleOk={handleUnauhtorized}
        open={isUnauthorized}
        title="Unauthorized"
        body="User unauthorized for selected role"
        buttonName="Ok"
      />
      <ErrorAlert
        handleOk={handleChangePasswordError}
        open={isChangePasswordError}
        title="Error"
        body="something Went wrong, try again"
        buttonName="Ok"
      />
      <SucessAlert
        handleOk={handleEnrolSuccess}
        open={isEnrolSuccess}
        title="Success"
        body="Successfully enroled."
        buttonName="Sign In"
      />
      <SucessAlert
        handleOk={handleChangePasswordSuccess}
        open={isChangePasswordSucess}
        title="Success"
        body="Password changed successfully"
        buttonName="Sign In"
      />
      <Container maxWidth="sm" sx={{ marginTop: "25px", marginBottom: "25px" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {/* <Grid item>
                        <Typography variant="body2" color={'red'} component="h2">
                            {apiMessage}
                        </Typography>
                  
                  </Grid> */}

          <Grid item xs={12}>
            <Paper
              elevation={21}
              sx={{
                padding: "6%",
                width: "100%",
                // animation: performAnimation ? `${shakeHorizontal} 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both` : `${flipInHorBottom} 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`
                // animation: `${flipInHorBottom} 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`
              }}
            >
              <div className={classes.imageContainer}>
                <img
                  src={otpImage}
                  alt="Responsive Image"
                  className={classes.responsiveImage}
                />
              </div>

              <Typography
                sx={{
                  fontWeight: "400",
                  marginBottom: "1%",
                  textAlign: "center",
                }}
                variant="h4"
                color="textSecondary"
                fontWeight="normal"
              >
                OTP Verification
              </Typography>
              <Typography
                variant="caption"
                sx={{ marginBottom: "6%" }}
                color={"red"}
                component="h2"
              >
                {apiMessage}
              </Typography>

              <Box display="flex" justifyContent="center" alignItems="center">
                {otp.map((digit, index) => (
                  <Box
                    key={index}
                    sx={{
                      margin: { xs: "0.3%", sm: "1%", md: "2%" },
                    }}
                  >
                    <TextField
                      autoComplete="off"
                      id={`otp-input-${index}`}
                      variant="outlined"
                      value={digit}
                      onChange={(e) => handleOtpChange(index, e)}
                      onFocus={logOtp} // Log OTP when input field is focused
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        maxLength: 1,
                      }}
                      className={classes.otpInput}
                    />
                  </Box>
                ))}
              </Box>

              <Box marginTop={1}>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                  marginY={1}
                >
                  <Grid item xs={12} sm={6}>
                    {/* <Button
                                            fullWidth
                                            variant="outlined"
                                            color="primary"
                                            onClick={logOtp}
                                            className={classes.button}
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor: '#1d00c1',
                                                    color: '#eee',
                                                },
                                            }}
                                        >
                                            Resend
                                        </Button> */}
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      // onClick={logOtp}
                      disabled={isResendDisabled}
                      onClick={handleResendClick}
                      className={classes.button}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#1d00c1",
                          color: "#eee",
                        },
                      }}
                    >
                      {isResendDisabled ? `Resend (${resendTimer}s)` : "Resend"}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      // onClick={logOtp}
                      className={classes.button}
                      onClick={handleMUIForm}
                      // disabled={isVerifyButtonDisabled}
                      disabled={isVerifying || isVerifyButtonDisabled}
                    >
                      {isVerifying ? (
                        <CircularProgress sx={{ color: "green" }} />
                      ) : (
                        "Verify"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      {/* </Modal> */}
    </>
  );
};

export default OtpComponent;
