import React from "react";
import { Typography } from "@mui/material";
import DeclarationofCorrectnessImage from '../../../../images/DeclarationofCorrectnessImage.png';
import ConsentSubmittedSuccessfully from '../../../../images/ConsentSubmittedSuccessfully.png';
import MissingIncorrectData from '../../../../images/MissingIncorrectData.png';
import ConsentSubmitted from '../../../../images/ConsentSubmitted.png';
const DecelarationOfCorrectness=()=>{
    return(<>
     <Typography variant="h6"> DECLARATION OF CORRECTNESS</Typography>
<Typography variant="body1" align='justify'>
In addition to viewing the PF statement, member has to verify the correctness of the information shown by giving his/her “Declaration of Correctness” at the bottom of the page. The member has to select either “Correct” or “Incorrect” as shown in figure 24.

<p align="center"><img  className="bordered-image" src={DeclarationofCorrectnessImage} height="75%" width="90%"/><br />
            Figure 24 Declaration of Correctness</p>
            <ul>
                <li>
               <b> Correct: </b>If the member finds that information provided is correct, he/she can select this option and click on the submit button (Figure 24 and Figure 25).
               <p align="center"><img  className="bordered-image" src={ConsentSubmittedSuccessfully} height="55%" width="75%"/><br />
            Figure 25: Consent Submitted Successfully</p>

                </li>
                <li>
                <b> Incorrect: </b>If the member finds any missing/incorrect data, he/she can click on Incorrect option. In this case, a form needs to be filled by the member. The member is to provide specific information, including the start date, end date, unit code/unit name, regional office details, and any remarks they may have for the specified date range. If the member finds multiple discrepancies, he/she can click on the ‘Add Row’ button. This action will replicate the same form, allowing the user to fill in additional rows for incorrect data. 

               <p> After filling the details the member has to submit the details (Figure 26). On clicking Submit button a pop message appears mentioning the successful submission of the details provided by the member (Figure 27).</p>
               <p align="center"> <img  className="bordered-image" src={MissingIncorrectData} height="90%" width="99%"/><br />
                        Figure 26: Details to be filled for missing/incorrect Data</p>
                        <p align="center"> <img  className="bordered-image" src={ConsentSubmitted} height="55%" width="75%"/><br />
                       Figure 27: Consent Submitted Successfully</p>
                </li>
            </ul>
   


    
    </Typography>
    
    </>);
}
export default DecelarationOfCorrectness;