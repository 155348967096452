import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Paper, Button } from "@mui/material";

// import {
//   familyMemberModel,
//   familyMandatoryFields,
// } from "../../../Models/defaultModel";
import TitleText from "../../../FormComponent/TitleText";
import LabelText from "../../../FormComponent/LabelText";
import SubtitleText from "../../../FormComponent/SubtitleText";
import FormDivider from "../../../FormComponent/FormDivider";
import ValueText from "../../../FormComponent/ValueText";
import axios from "axios";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";

function FamilyMemberDetails() {
  const [member, setMember] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const [isFailed, setIsFailed] = useState(false);

  const location = useLocation();
  const { pathname } = location;
  console.log("location", location);

  const {
    state: { passedData, passingId },
  } = location;
  console.log("passedData , passingId", passedData, passingId);
  // depending upon what has arrived passed data or passingId the familymemberId to be passed on the api request is to be changed
  const famMemId = passedData ? passedData.familyMemId : passingId;
  console.log("famMemId", famMemId);

  // write it in default model and use based on needs

  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  const submitTableActions = {
    viewAction: {
      action: "view",
      icon: (
        <VisibilityRoundedIcon
          fontSize="small"
          sx={{ color: "black", margin: "0px 0px" }}
        />
      ),
    },

    editAction: {
      action: "edit",
      icon: (
        <EditRoundedIcon
          fontSize="small"
          sx={{ color: "black", margin: "0px 0px" }}
        />
      ),
    },

    deleteAction: {
      action: "delete",
      icon: (
        <DeleteRoundedIcon
          fontSize="small"
          sx={{ color: "red", margin: "0px 0px" }}
        />
      ),
    },
  };
  const familyMemberSamples = [
    {
      cmpfAccNo: "HYD/170/840",
      familyMemberId: "1234",
      fullName: "E Rajanna",
      relationWithMember: "Father",
      age: "23",
      martialStatus: "n",
      memberStatus: "ACTIVE ",
      //  claim_id:1,

      //  claimant_name:"Baijunath Sharma",

      // cmpf_acc_no:"A/1/1",

      //  unit_code:"A/1",

      // claim_submission_date:"22-09-2023",

      remarks: "The data found is in order and is OK.",
    },
  ];
  const familyMemberCreatePreviewData = () => {
    let tablePreviewData = [];

    familyMemberSamples.map((family) => {
      tablePreviewData.push({
        "CMPF ACC NO/Name": family.cmpfAccNo,
        " Family member ID": family.familyMemberId,
        " Full Name": family.fullName,
        " Relation with member": family.relationWithMember,
        " Age": family.age,
        "Marital Status": family.martialStatus,
        " Member Status": family.memberStatus,
      });
    });

    return tablePreviewData;
  };

  // const sessionExist = async () => {
  //   const status = await validateSession();
  //   console.log("session status", status);
  //   return status;
  // };

  const fetchUserDetail = async () => {
    setIsLoading(true);
    //session related uncomment for session
    // if (!(await sessionExist())) {
    //   console.log("session extending having trouble");
    //   setIsFailed(true);
    //   setIsLoading(false);
    //   return;
    // }

    // let token = localStorage.getItem("jwt");

    // if (!token) {
    //   setIsFailed(true);
    //   setIsLoading(false);
    //   return;
    // }

    // token = token.replace('"', "").replace('"', "");

    const url =
      process.env.REACT_APP_SERVER_URL + "/search/viewmorefamilydetails";
    const formData = new FormData();
    // formData.append("searchField", jwtDecode(token).user);
    formData.append("familyId", famMemId);
    // formData.append("searchField", passedData.familyMemId);

    const config = {
      headers: {
        //  Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsLoading(false);
        setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          setMember(data[0]);
          console.log("data", data);
          return;
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        console.log("error", error);
      });
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo(0, 0);
    fetchUserDetail();
  }, [pathname]);
  const navigate = useNavigate(); //inilitialize the navigate function
  const handleGoBack = () => {
    navigate(-1); //navigate back to the previous page
  };

  const maritalStatusOptions = {
    U: "Unmarried",
    M: "Married",
    W: "Widow/Widower",
    D: "Divorced",
    // Add more status options here
  };
  const gender = [
    { id: "M", title: "Male" },
    { id: "F", title: "Female" },
    { id: "O", title: "Other" },
  ];
  const maritalStatus = [
    { id: "M", title: "Married" },
    { id: "U", title: "Unmarried" },
    { id: "W", title: "Widow/Widower" },
    { id: "D", title: "Divorced" },
  ];

  return (
    <Grid container justifyContent="center" sx={{ wordWrap: "break-word" }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
          {/* {isFailed ? (
            <Reloader refreshHandler={fetchUserDetail} />
          ) : (
            <>
              {isLoading ? (
                <LoadingIndicator />
              ) : ( */}
          <>
            {/*Button with back functionality */}
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  align="left"
                  onClick={handleGoBack}
                  // sx={{ backgroundColor: "darkviolet" }}
                >
                  Back
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <TitleText title="Family Member's Details" />
            </Grid>

            <FormDivider />

            {/* <Form> */}
            <Grid
              container
              justifyContent="space-between"
              alignItems="start"
              rowGap={3}>
              {/* section 1  */}
              <Grid item xs={12} sm={12}>
                <SubtitleText title="Basic Details" />
              </Grid>

              {/* column 1  */}

              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  alignItems="start"
                  justifyContent="space-between"
                  spacing={2}
                  columnGap={1}>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Title" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.titleName} />
                  </Grid>

                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="First Name" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.firstName} />
                  </Grid>
                  {/*row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Middle Name" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.middleName} />
                  </Grid>

                  {/*row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Last Name" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.lastName} />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Name of Father/Husband" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.fathHusName} />
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Relation" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.relationName} />
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Gender" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {/* <ValueText title={member.gender} /> */}
                    <ValueText
                      title={
                        gender.find((item) => item.id === member.gender)?.title
                      }
                    />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Marital Status" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={
                        maritalStatus.find(
                          (item) => item.id === member.maritalStatus
                        )?.title
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* column 2  */}

              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="start"
                  spacing={2}
                  columnGap={1}>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Religion" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.religionName} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Date of Birth" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {/* <ValueText title={member.dateOfBirth} /> */}
                    <ValueText
                      title={new Date(member.dateOfBirth).toLocaleDateString(
                        "en-GB",
                        options
                      )}
                    />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Email ID" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.emailId} />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Mobile Number" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={
                       ( member.mobileNo !== "0" && member.mobileNo !== 0 && member.mobileNo !== null)
                          ? `XXXXXX${member.mobileNo?.toString().slice(-4)}`
                          : ""
                      }
                    />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Landline Number" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={
                        ( member.landNo !== "0" && member.landNo !== 0 && member.landNo !== null)
                          ? `XXXXXX${member.landNo?.toString().slice(-4)}`
                          : ""
                      }
                    />
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Aadhaar Number" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                  <ValueText
                      title={
                        ( member.aadhaarNo !== "0" && member.aadhaarNo !== 0 && member.aadhaarNo !== null)
                          ? `XXXXXX${member.aadhaarNo?.toString().slice(-4)}`
                          : ""
                      }
                    />
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Pan" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                  <ValueText
                      title={
                        ( member.panNo !== "0" && member.panNo !== 0 && member.panNo !== null)
                          ? `XXXXXX${member.panNo?.toString().slice(-4)}`
                          : ""
                      }
                    />
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Address" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={`
                                    ${
                                      member.addressL1
                                        ? member.addressL1 + ","
                                        : ""
                                    }
                                    
                                    ${
                                      member.addressL2
                                        ? member.addressL2 + ","
                                        : ""
                                    }
                                    
                                    ${
                                      member.village ? member.village + "," : ""
                                    }
                                    
                                    ${member.city ? member.city + "," : ""}
                                    
                                    ${
                                      member.district
                                        ? member.district + ","
                                        : ""
                                    }
                                    
                                    ${member.state ? member.state + "," : ""}
                                    
                                    ${
                                      member.country ? member.country + "," : ""
                                    }
                                    
                                    ${member.pincode ? member.pincode : ""}
                                `}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* ************************************** */}
              {/* section 2  */}
              <Grid item xs={12} sm={12}>
                <SubtitleText title="Claim Eligibility Details" />
              </Grid>

              {/* column 1  */}

              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  alignItems="start"
                  justifyContent="space-between"
                  spacing={2}
                  columnGap={1}>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="PF nominee" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {/* <ValueText title={member.pfNominee} /> */}
                    <ValueText
                      title={
                        member.pfNominee == true
                          ? "Yes"
                          : "" || member.pfNominee == false
                          ? "No"
                          : ""
                      }
                    />
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="PF nominee%" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.pfNomShare} />
                  </Grid>
                </Grid>
              </Grid>

              {/* column 2  */}

              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="start"
                  spacing={2}
                  columnGap={1}>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Pension nominee" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {/* <ValueText title={member.pensionNominee} /> */}
                    <ValueText
                      title={
                        member.pensionNominee == true
                          ? "Yes"
                          : "" || member.pensionNominee == false
                          ? "No"
                          : ""
                      }
                    />
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Pension nominee%" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.penNomShare} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
          {/* )}
            </>
          )} */}

          {/* <TableWithLinkAndButton preview={familyMemberCreatePreviewData()} data={familyMemberSamples} passingId='familyMemberId' actions={submitTableActions} viewLink="" /> */}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default FamilyMemberDetails;
