import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Stepper, Step, StepLabel, StepIcon } from "@mui/material";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import StepConnector from "@mui/material/StepConnector";
import { Location, useLocation } from "react-router-dom";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import { useNavigate } from "react-router-dom";

const PfStatusTracking = () => {
  const location = useLocation();
  const { state } = location;
  const { claimId } = state;
  console.log("locationn ", location.claimId);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [completedSteps, setCompletedSteps] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loadingSteps, setLoadingSteps] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);

  const [error, setError] = useState(null); // New state for error handling
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCompletedSteps();
    fetchTableData();
  }, []);

  const fetchTableData = async () => {
    try {
      const newResponse = new FormData();
      newResponse.append("claimId", claimId); //"PF1000001"
      const url =
        process.env.REACT_APP_SERVER_URL + "/recent/pf/Status/Description";
      const response = await axios.post(url, newResponse);
      const responseData = response.data.data;
      console.log("table data", responseData);
      let tablePreviewData = responseData.map((tableData) => ({
        "Grievance Id": tableData.claimId,
        "Submit Date": tableData.activityDate,
        "Current Status": tableData.pfClaimStatusTyp,
        "Prev Status": tableData.activityDate,
      }));
      setTableData(tablePreviewData);
      setLoadingTable(false);
    } catch (error) {
      console.error("Error fetching table data:", error);
      // Set the error state and open the error alert
      setError("Clim ID not found");
      setErrorAlertOpen(true);
      // setTimeout(() => {
      //   // history.push("/search");
      //   navigate('/search');
      // }, 2000);
    }
  };

  const fetchCompletedSteps = async () => {
    try {
      const newResponse = new FormData();
      newResponse.append("claimId", claimId);
      const url =
        process.env.REACT_APP_SERVER_URL + "/recent/pf/Status/Description";
      const response = await axios.post(url, newResponse);
      const data = response.data.data;
      console.log("steps", data);
      if (response.data && response.data.data) {
        const filteredSteps = response.data.data;
        setCompletedSteps(filteredSteps);
      } else {
        console.error("Failed to retrieve completed steps data");
      }
      setLoadingSteps(false);
    } catch (error) {
      console.error("Error fetching completed steps:", error);
    }
  };

  const lastItemTable =
    tableData.length > 0 ? tableData[tableData.length - 1] : null;
  //console.log("last item", lastItemTable);
  const FirstItemTable = tableData.length > 0 ? tableData[0] : null;

  const checkStatusExists = (statusId) => {
    //return completedSteps.some((step) => step.penClaimStatusTyp === statusId);
    const lowercaseStatusId = statusId.toLowerCase();
    console.log("lowar case status", lowercaseStatusId);
    return completedSteps.some((step) => {
      return (
        step.pfClaimStatusTyp &&
        step.pfClaimStatusTyp.toLowerCase() === lowercaseStatusId
      );
    });
  };

  const checkStatusExistsLast = (statusId) => {
    //const lowercaseStatusId = statusId.toLowerCase();
    const lowercaseStatusId = statusId.toLowerCase();
    const lastStep = completedSteps[completedSteps.length - 1];
    if (
      !lastStep ||
      !lastStep.pfClaimStatusTyp.toLowerCase().includes("return")
    ) {
      return false; // If last step doesn't contain "returned", return false directly
    }

    for (let i = completedSteps.length - 1; i >= 0; i--) {
      const step = completedSteps[i];
      if (
        step &&
        step.pfClaimStatusTyp &&
        step.pfClaimStatusTyp.toLowerCase() === lowercaseStatusId
      ) {
        return true;
      }
    }

    return false;
    // const lowercaseStatusId = statusId.toLowerCase();
    // const lastStep = completedSteps[completedSteps.length - 1];
    // console.log("last step", lastStep);
    // return (
    //   lastStep &&
    //   lastStep.pfClaimStatusTyp &&
    //   lastStep.pfClaimStatusTyp.toLowerCase() === lowercaseStatusId
    // );
  };

  const getUaoStatus = () => {
    const isForwardedToUao = checkStatusExists("Submitted to UAO");
    const isReturnedByUao = checkStatusExistsLast("Returned by UAO");
    console.log("returnd uao", isReturnedByUao);
    if (isReturnedByUao || isForwardedToUao) {
      return [isReturnedByUao ? "Returned by UAO" : "Submitted to UAO", true];
    }

    return ["Forwarded to UAO", false];
  };

  const getAaoStatus = () => {
    const isForwardedToAao = checkStatusExists("Forwarded to AAO");
    const isReturnedByAao = checkStatusExistsLast("Returned by AAO");

    if (isReturnedByAao || isForwardedToAao) {
      return [isReturnedByAao ? "Returned by AAO" : "Forwarded to AAO", true];
    }

    return ["Forwarded to AAO", false];
  };

  const getRodaStatus = () => {
    const isReturnedByRoda = checkStatusExistsLast(
      "Processed for return by RODA"
    );
    const isAssignedToRoda = checkStatusExists("Assigned to RODA");
    const isVerifiedByRoda = checkStatusExists("Verified by RODA");
    const isForwardedToRoIds = checkStatusExists("Forwarded to RO-IDS");

    if (isReturnedByRoda) {
      return ["Returned by RODA", true];
    } else if (isVerifiedByRoda) {
      return ["Verified by RODA", true];
    } else if (isAssignedToRoda) {
      return ["Assigned to RODA", true];
    } else if (isForwardedToRoIds) {
      return ["Forwarded to RO-IDS", true];
    }

    return ["Forwarded to RODA", false];
  };

  const getSiStatus = () => {
    //if (checkStatusExists("Assigned to SI")) return ["Assigned to SI", true];
    if (checkStatusExists("Verified by SI")) return ["Verified by SI", true];
    if (checkStatusExistsLast("Processed for return by SI"))
      return ["Returned by SI", true];

    return ["Forwarded to SI", false];
  };

  const getBoStatus = () => {
    if (checkStatusExists("Verified by BO")) return ["Verified by BO", true];
    if (checkStatusExistsLast("Returned by BO"))
      return ["Returned by BO", true];
    return ["Forwarded to BO", false];
  };

  const getRhStatus = () => {
    if (checkStatusExists("Approved by RH")) return ["Approved by RH", true];
    if (checkStatusExistsLast("Returned by RH"))
      return ["Returned by RH", true];
    return ["Forwarded to RH", false];
  };

  const getAgmStatus = () => {
    if (checkStatusExists("Returned by AGM")) {
      return ["Returned by AGM", true];
    }
    if (checkStatusExists("Verified by AGM")) {
      return ["Verified by AGM", true];
    }
    return ["Forwarded to AGM", false];
  };

  // const getCashierStatus = () => {
  //   if (checkStatusExists("Verified By Cashier")) {
  //     return ["Verified By Cashier", true];
  //   }
  //   return ["Verified By Cashier", false];
  // };

  const getAaoStatusCompleted = () => {
    const isForwardedToAao = checkStatusExists("Confirmed by AAO");
    const isReturnedByAao = checkStatusExistsLast("Returned by AAO");

    if (isReturnedByAao || isForwardedToAao) {
      return [isReturnedByAao ? "Returned by AAO" : "Confirmed by AAO", true];
    }

    return ["Confirmed by AAO", false];
  };
  const getCashierStatus = () => {
    if (checkStatusExists("Ready for payment"))
      return ["Ready for payment", true];
    if (checkStatusExistsLast("Return by Cashier"))
      return ["Returned by Cashier", true];
    return ["Verified By Cashier", false];
  };

  //uda
  const isMemberStep = checkStatusExists("Submitted by UDA");

  const [uaoStatus, isUaoStep] = getUaoStatus();
  const [aaoStatus, isAaoStep] = getAaoStatus();
  const [rodaStatus, isRodaStep] = getRodaStatus();
  const [siStatus, isSiStep] = getSiStatus();
  const [boStatus, isBoStep] = getBoStatus();
  const [rhStatus, isRhStep] = getRhStatus();
  const [aaoStatusCompleted, isAaoStepCompleted] = getAaoStatusCompleted();
  const [cashierStatus, isCashierStep] = getCashierStatus();
  //const isCashierStep = checkStatusExists("Verified By Cashier")

  const memberColor = isMemberStep ? "green" : "green"; //uda

  let uaoColor = isUaoStep
    ? uaoStatus !== "Returned by UAO"
      ? "green"
      : "red"
    : uaoStatus !== "Returned by UAO"
    ? "gray"
    : undefined;

  let aaoColor = isAaoStep
    ? aaoStatus !== "Returned by AAO"
      ? "green"
      : "red"
    : aaoStatus !== "Returned by AAO"
    ? "gray"
    : undefined;

  let rodaColor = isRodaStep
    ? rodaStatus !== "Returned by RODA"
      ? "green"
      : "red"
    : rodaStatus !== "Returned by RODA"
    ? "gray"
    : undefined;

  let siColor = isSiStep
    ? siStatus !== "Returned by SI"
      ? "green"
      : "red"
    : siStatus !== "Returned by SI"
    ? "gray"
    : undefined;

  let boColor = isBoStep
    ? boStatus !== "Returned by BO"
      ? "green"
      : "red"
    : boStatus !== "Returned by BO"
    ? "gray"
    : undefined;

  let rhColor = isRhStep
    ? rhStatus !== "Returned by RH"
      ? "green"
      : "red"
    : rhStatus !== "Returned by RH"
    ? "gray"
    : undefined;

  let aaoCompletedColor = isAaoStepCompleted
    ? aaoStatusCompleted !== "Returned by AAO"
      ? "green"
      : "red"
    : aaoStatusCompleted !== "Returned by AAO"
    ? "gray"
    : undefined;

  let cashierColor = isCashierStep
    ? cashierStatus !== "Returned by Cashier"
      ? "green"
      : "red"
    : cashierStatus !== "Returned by Cashier"
    ? "gray"
    : undefined;

  const handleOkErrorAlert = () => {
    setErrorAlertOpen(false); // Close the error alert
    // You can add additional error handling logic here as needed
  };

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper
            elevation={3}
            sx={{ padding: isMobile ? "1rem" : "3rem", borderRadius: "8px" }}
          >
            <Grid item xs={12}>
              <TitleText title="PF Claim Status" />
            </Grid>
            <FormDivider />

            {loadingSteps ? (
              <p>Loading steps...</p>
            ) : (
              // <Stepper alternativeLabel orientation={isMobile ? 'vertical' : 'horizontal'}>
              <Stepper
                orientation={isMobile ? "vertical" : "horizontal"}
                alternativeLabel={!isMobile}
              >
                {/* UDA */}
                {/* <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon {...props} style={{ color: memberColor }} />
                    )}
                  >
                    <div style={{ color: memberColor, fontWeight: 400 }}>
                      {isMemberStep ? "Submitted by UDA" : "Forwarded to UDA"}
                    </div>
                  </StepLabel>
                </Step> */}
                {/* UAO */}
                <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon {...props} style={{ color: uaoColor }} />
                    )}
                  >
                    <div style={{ color: uaoColor, fontWeight: 400 }}>
                      {uaoStatus}
                    </div>
                  </StepLabel>
                </Step>

                {/* AAO */}
                <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon {...props} style={{ color: aaoColor }} />
                    )}
                  >
                    <div style={{ color: aaoColor }}>{aaoStatus}</div>
                  </StepLabel>
                </Step>
                {/* <Step>
                    {isAgmStep && agmStatus === "Returned by AGM" ? (
                      <StepLabel>
                        <div style={{ color: 'red' }}>{agmStatus}</div>
                      </StepLabel>
                    ) : (
                      isAgmStep && (
                        <StepLabel>
                          <div style={{ color: 'green' }}>{agmStatus}</div>
                        </StepLabel>
                      )
                    )}
                </Step> */}

                <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon {...props} style={{ color: rodaColor }} />
                    )}
                  >
                    <div style={{ color: rodaColor }}>{rodaStatus}</div>
                  </StepLabel>
                </Step>

                <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon {...props} style={{ color: siColor }} />
                    )}
                  >
                    <div style={{ color: siColor }}>{siStatus}</div>
                  </StepLabel>
                </Step>

                <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon {...props} style={{ color: boColor }} />
                    )}
                  >
                    <div style={{ color: boColor }}>{boStatus}</div>
                  </StepLabel>
                </Step>

                <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon {...props} style={{ color: rhColor }} />
                    )}
                  >
                    <div style={{ color: rhColor }}>{rhStatus}</div>
                  </StepLabel>
                </Step>

                <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon
                        {...props}
                        style={{ color: aaoCompletedColor }}
                      />
                    )}
                  >
                    <div style={{ color: aaoCompletedColor }}>
                      {aaoStatusCompleted}
                    </div>
                  </StepLabel>
                </Step>

                <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon {...props} style={{ color: cashierColor }} />
                    )}
                  >
                    <div style={{ color: cashierColor }}>{cashierStatus}</div>
                  </StepLabel>
                </Step>
              </Stepper>
            )}

            <br />
            <Grid container justifyContent="center">
              <Grid item xs={6}>
                <Paper
                  elevation={3}
                  sx={{
                    borderRadius: "8px",
                    padding: "1rem",
                    border: "1px solid #CCCCC",
                    maxHeight: "400px",
                    overflow: "auto",
                  }}
                >
                  {loadingTable ? (
                    <p>Loading table data...</p>
                  ) : (
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ backgroundColor: "#ecf9ff" }}>
                            <b>Claim Id:</b>
                          </TableCell>
                          <TableCell style={{ backgroundColor: "#ecf9ff" }}>
                            {" "}
                            {lastItemTable && lastItemTable["Grievance Id"]}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ backgroundColor: "#ffffff" }}>
                            <b>Submit Date:</b>
                          </TableCell>
                          <TableCell style={{ backgroundColor: "#ffffff" }}>
                            {FirstItemTable && FirstItemTable["Submit Date"]}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ backgroundColor: "#ecf9ff" }}>
                            <b>Current Status:</b>
                          </TableCell>
                          <TableCell style={{ backgroundColor: "#ecf9ff" }}>
                            {lastItemTable && lastItemTable["Current Status"]}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ backgroundColor: "#ffffff" }}>
                            <b>Current Status Since:</b>
                          </TableCell>
                          <TableCell style={{ backgroundColor: "#ffffff" }}>
                            {lastItemTable && lastItemTable["Prev Status"]}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <ErrorAlert
        open={errorAlertOpen}
        handleOk={handleOkErrorAlert}
        title="Error"
        body={error}
        buttonName="OK"
      />
    </div>
  );
};

export default PfStatusTracking;
