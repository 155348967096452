import React,{useState} from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import ValueText from './ValueText';
import { CircularProgress } from '@mui/material';

function CustomConfirmationDialogue(props) {
  
  const {handleOk, handleCancel, open, title, body, buttonName,buttonName2, isDisabled} = props;
  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
       <ValueText title={body}/>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
        {buttonName2}
        </Button>
        <Button onClick={handleOk} disabled={isDisabled}>
        {isDisabled ? (
                          <CircularProgress color="secondary" />
                        ) : (
                          <>
                             &nbsp;{buttonName}
                            {/* Render the icon when isLoading is false */}
                          </>
                        )}
          {/* {buttonName} */}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default CustomConfirmationDialogue;