import React from 'react';
import { Grid, Typography,Backdrop } from '@mui/material';

const AccessDenied = () => {
  return (
   
    <Backdrop open={true} style={{ zIndex: 9999, backgroundColor: '#fff' }}>
    <Grid container justifyContent='center' alignItems='center' direction='column'>
      <Grid item>
           <Typography variant="h1" align="center">
          Access Denied
        </Typography>
      </Grid>
      <Grid item>
      <  Typography variant="body1" align="center">
        You do not have permission to access this page.
      </Typography>
      </Grid>
    </Grid>
    </Backdrop>
  );
};

export default AccessDenied;
