import React from "react";
import { Typography } from "@mui/material";
import  AccountSummaryImage from '../../../images/AccountSummaryImage.png';
const AccountSummary=()=>{
    return(<div id="account-summary">
    
     <Typography varient="body1" align="justify">
    The member dashboard has 7 different functionalities which member can access just by clicking on them.</Typography>
    <br /> <Typography variant="h6">ACCOUNT SUMMARY</Typography>
    <Typography varient="body1" align="justify">
    Details such as CMPF Account Number, Full Name of the member, Last Currency Period and current PF Balance are displayed. (Figure 21)
    </Typography>
   <p align="center"><img  className="bordered-image" src={AccountSummaryImage} height="75%" width="90%"/> <br />
                Figure 21: Account Summary</p>

    </div>);
}
export default AccountSummary;