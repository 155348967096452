import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import {
  Avatar,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import axios from "axios";
import qs from "qs";
import { useNavigate, useLocation } from "react-router-dom";
import SucessAlert from "../../FormComponent/SuccessAlert";
import ErrorAlert from "../../FormComponent/ErrorAlert";

const ForgotPasswordOfficer = () => {
  const navigate = useNavigate();
  const [cmpfAccNo, setCMPF] = useState();

  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const paperStyle = { padding: 20, width: 400, margin: "20px auto" };
  const btstyle = { fontWeight: "600", margin: "20px 0" };

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorMessageOne, setErrorMessageOne] = useState("");
  const [errorMessageTwo, setErrorMessageTwo] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // update handling
  const [isUploading, setISUploading] = useState(false);
  const [isFailedUpdate, setISFailedUpdate] = useState(false);
  const [failedUpdateMessage, setFailedUpdateMessage] = useState(
    "Something Went Erong"
  );
  const [isUpdatedSuccess, setIsUpdetedSuccess] = useState(false);

  const validatePassword = (password) => {
    // Validate password: minimum uppercase, lowercase, special character, and number
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_])[^\s]{8,20}$/;
    return regex.test(password);
    //return true when password met the criteria
  };

  const handleNewPasswordChange = (event) => {
    const password = event.target.value;
    setNewPassword(password);
    if (!validatePassword(password)) {
      console.log("invalid");
      setIsButtonDisabled(true);
      setErrorMessageOne(
        "Password must contain at least 8 characters and maximum of 20 characters, including uppercase, lowercase,number and special character except space."
      );
    } else {
      if (confirmNewPassword) {
        if (confirmNewPassword === event.target.value) {
          setErrorMessageTwo("");
          setErrorMessageOne("");
          setIsButtonDisabled(false);
          return;
        }
        setErrorMessageTwo("Passwords don't match.");
        setErrorMessageOne("");
        setIsButtonDisabled(true);
        return;
      }
      setErrorMessageOne("");
      setIsButtonDisabled(true);
      console.log("valid");
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const confirmpassword = event.target.value;
    setConfirmNewPassword(confirmpassword);
    if (confirmpassword === newPassword) {
      setErrorMessageTwo("");
      if (errorMessageOne) {
        console.log("message one active");
        setIsButtonDisabled(true);
      } else {
        console.log("message one in active");
        setIsButtonDisabled(false);
      }
    } else {
      setErrorMessageTwo("Passwords not match.");
      setIsButtonDisabled(true);
    }
  };

  const resetForm = () => {
    setConfirmNewPassword("");
    setNewPassword("");
    setIsButtonDisabled(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setISUploading(true);
    setIsButtonDisabled(true);
    const data = qs.stringify({
      loginId: cmpfAccNo,
      newPassword: window.btoa(newPassword),
      // newPassword: newPassword,
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    await axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/officer/forgot/old/password",
        data,
        config
      )
      .then((resp) => {
        console.log("forgot resp", resp);
        if (resp.status === 200) {
          setISUploading(false);
          resetForm();
          localStorage.removeItem("loginId");
          setIsUpdetedSuccess(true);
          setIsButtonDisabled(false);
          return "";
        }
        setISUploading(false);

        setFailedUpdateMessage("Something Went wrong");
        setISFailedUpdate(true);
        return;
      })
      .catch(function (error) {
        console.log(error);
        setISUploading(false);
        setIsButtonDisabled(false);
        resetForm();
        setFailedUpdateMessage(error.response.data.error_message);
        setISFailedUpdate(true);
      });
  };

  useEffect(() => {
    let loginId = localStorage.getItem("loginId");
    if (!loginId) {
      navigate("/cmpfo", { replace: true });
      return;
    }
    setCMPF(localStorage.getItem("loginId"));
    console.log("useEffect forg", localStorage.getItem("loginId"));
  }, []);

  const handleSuccess = () => {
    setIsUpdetedSuccess(false);
    navigate("/cmpfo", { replace: true });
  };

  const handleFailure = () => {
    setISFailedUpdate(false);
    navigate("/cmpfo", { replace: true });
  };

  const handleCopy = (event) => {
    event.preventDefault();
  };

  const handleKeyDown = (event) => {
    if (event.code === "Space") {
      event.preventDefault();
    }
  };

  return (
    <>
      <ErrorAlert
        handleOk={handleFailure}
        open={isFailedUpdate}
        title="Error!"
        body={failedUpdateMessage}
        buttonName="Ok"
      />
      <SucessAlert
        handleOk={handleSuccess}
        open={isUpdatedSuccess}
        title="Success"
        body="Password is updated"
        buttonName="Ok"
      />
      <Grid>
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center">
            <Avatar style={avatarStyle}>
              <FontAwesomeIcon icon={faLock} />
            </Avatar>
            <h2>Forgot Password</h2>
          </Grid>
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#474E68",
                },
                "&:hover fieldset": {
                  borderColor: "#6B728E",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#0F3460",
                },
              },
            }}
            id="newPassword"
            label="New Password"
            variant="outlined"
            type="password"
            placeholder="Enter your New Password"
            fullWidth
            required
            onKeyDown={handleKeyDown}
            inputProps={{ maxLength: 20, onCopy: handleCopy }}
            autoComplete="off"
            style={{ marginTop: "20px" }}
            value={newPassword}
            error={Boolean(errorMessageOne)}
            helperText={Boolean(errorMessageOne) ? errorMessageOne : ""}
            onChange={handleNewPasswordChange}
          ></TextField>
          {/* {errorMessageOne && <span style={{ color: 'red' }}>{errorMessageOne}</span>} */}
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#474E68",
                },
                "&:hover fieldset": {
                  borderColor: "#6B728E",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#0F3460",
                },
              },
            }}
            id="confirmNewPassword"
            type="password"
            label="Confirm New Password"
            variant="outlined"
            placeholder="Confirm New Password"
            fullWidth
            required
            onKeyDown={handleKeyDown}
            inputProps={{ maxLength: 20, onCopy: handleCopy }}
            autoComplete="off"
            style={{ marginTop: "20px" }}
            value={confirmNewPassword}
            error={Boolean(errorMessageTwo)}
            helperText={Boolean(errorMessageTwo) ? errorMessageTwo : ""}
            onChange={handleConfirmPasswordChange}
          ></TextField>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={btstyle}
            disabled={isButtonDisabled}
            onClick={handleSubmit}
          >
            {isUploading ? (
              <CircularProgress sx={{ color: "green" }} />
            ) : (
              "Change Password"
            )}
          </Button>
        </Paper>
      </Grid>
    </>
  );
};

export default ForgotPasswordOfficer;
