// import jwtDecode from "jwt-decode";
// import axios from "axios";
// import { CompressOutlined } from "@mui/icons-material";

// let isRefreshing = false;
// let timeoutGlobelAction = () => {}; // it will reset with actual ideal time
// const eventHandlers = {};
// let timeoutId = null;
// let handleUserActivity = null; // Declare handleUserActivity outside the trackUserActivity function

// export const addEventHandler = (eventName, handler) => {
//   if (!eventHandlers[eventName]) {
//     eventHandlers[eventName] = [];
//   }

//   eventHandlers[eventName].push(handler);
// };

// export const removeEventHandler = (eventName, handler) => {
//   if (eventHandlers[eventName]) {
//     eventHandlers[eventName] = eventHandlers[eventName].filter(
//       (existingHandler) => existingHandler !== handler
//     );
//   }
// };

// export const startSessionTimeout = (
//   timeoutDuration,
//   timeoutAction,
//   activityEvents
// ) => {
//   clearTimeout(timeoutId);
//   timeoutId = setTimeout(() => {
//     // Perform the timeout action (log out the user)
//     clearUserActivityTracking(activityEvents);
//     timeoutAction();
//   }, timeoutDuration);
// };

// const extendToken = () => {
//   return new Promise((resolve, reject) => {
//     // api call for refresh token on success refresh
//     isRefreshing = true;
//     let refreshToken = localStorage.getItem("refresh");
//     let token = localStorage.getItem("jwt");

//     const url = process.env.REACT_APP_SERVER_URL + "/member/refersh/token";

//     let formData = new FormData();
//     formData.append("refreshToken", refreshToken);

//     const config = {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "multipart/form-data",
//       },
//     };

//     axios
//       .post(url, formData, config)
//       .then(function (response) {
//         if (response.status === 200) {
//           localStorage.setItem("jwt", response.data.data.token);
//           sessionStorage.setItem("jwt", response.data.data.token);
//           localStorage.setItem("refresh", response.data.data.refreshToken);
//           isRefreshing = false;
//           resolve(true);
//         }
//         isRefreshing = false;
//         resolve(true);
//       })
//       .catch(function (error) {
//         isRefreshing = false;
//         //console.log("error", error);
//         reject(false);
//       });
//   });
// };

// export const validateSession = async () => {
//   const activityEvents = [
//     "mousemove",
//     "click",
//     "keydown",
//     "scroll",
//     "touchstart",
//   ];

//   let token = localStorage.getItem("jwt");
//   let sessionId = sessionStorage.getItem("jwt");
//   if (sessionId !== token) {
//     //one more active session is running
//     clearTimeout(timeoutId);
//     clearUserActivityTracking(activityEvents);
//     timeoutGlobelAction();
//     return false;
//   }

//   const data_decoded = jwtDecode(token);

//   const sessionStartTime = new Date(data_decoded.iat * 1000); //start time
//   const sessionEndTime = new Date(data_decoded.exp * 1000); //start time

//   const lastActivityTime = new Date();

//   const timeDifference =
//     lastActivityTime.getTime() - sessionStartTime.getTime();
//   const sessionTimeDifference =
//     sessionEndTime.getTime() - sessionStartTime.getTime();

//   const timeDifferenceMinute = Math.floor(timeDifference / (1000 * 60)); // Convert milliseconds to minutes
//   const sessionTimeDifferenceMinute = Math.floor(
//     sessionTimeDifference / (1000 * 60)
//   ); // Convert milliseconds to minutes

//   // if session refresh failed

//   if (timeDifferenceMinute > sessionTimeDifferenceMinute - 1) {
//     // perform loggout whne session not extended
//     clearTimeout(timeoutId);
//     clearUserActivityTracking(activityEvents);
//     timeoutGlobelAction();
//     return false;
//   }

//   // refresh token before current token is expired so do it when half of ideal time is reached
//   if (timeDifferenceMinute > data_decoded.idealTime / 2) {
//     if (!isRefreshing) {
//       return await extendToken(); // Wait for extendToken() to complete
//     } else {
//       return false;
//     }
//   }
//   return true;
// };

// export const resetSessionTimeout = (timeoutDuration, timeoutAction) => {
//   // this method handle idele session log out
//   const activityEvents = [
//     "mousemove",
//     "click",
//     "keydown",
//     "scroll",
//     "touchstart",
//   ];
//   let token = localStorage.getItem("jwt");
//   if (!token) {
//     clearTimeout(timeoutId);
//     clearUserActivityTracking(activityEvents);
//     timeoutAction();
//   }
//   // idealTime = timeoutDuration;
//   timeoutGlobelAction = timeoutAction;
//   startSessionTimeout(timeoutDuration, timeoutAction, activityEvents);

//   validateSession();
// };

// export const trackUserActivity = (
//   activityEvents,
//   timeoutDuration,
//   timeoutAction
// ) => {
//   handleUserActivity = () => {
//     resetSessionTimeout(timeoutDuration, timeoutAction);
//   };

//   activityEvents.forEach((event) => {
//     window.addEventListener(event, handleUserActivity);
//   });

//   resetSessionTimeout(timeoutDuration, timeoutAction);
// };

// export const clearUserActivityTracking = (activityEvents) => {
//   activityEvents.forEach((event) => {
//     window.removeEventListener(event, handleUserActivity); // Use handleUserActivity here
//   });

//   clearTimeout(timeoutId);
// };


import jwtDecode from "jwt-decode";
import axios from 'axios';
import { CompressOutlined } from "@mui/icons-material";


let isRefreshing = false;
let timeoutGlobelAction = () => { };// it will reset with actual ideal time
const eventHandlers = {};
let timeoutId = null;
let handleUserActivity = null; // Declare handleUserActivity outside the trackUserActivity function

export const addEventHandler = (eventName, handler) => {
  if (!eventHandlers[eventName]) {
    eventHandlers[eventName] = [];
  }

  eventHandlers[eventName].push(handler);
};

export const removeEventHandler = (eventName, handler) => {
  if (eventHandlers[eventName]) {
    eventHandlers[eventName] = eventHandlers[eventName].filter(
      (existingHandler) => existingHandler !== handler
    );
  }
};

export const startSessionTimeout = (timeoutDuration, timeoutAction, activityEvents) => {
  clearTimeout(timeoutId);
  timeoutId = setTimeout(() => {
    // Perform the timeout action (log out the user)
    clearUserActivityTracking(activityEvents);
    timeoutAction();
  }, timeoutDuration);
};


const extendToken = () => {
  return new Promise((resolve, reject) => {
    // api call for refresh token on success refresh
    isRefreshing = true;
    //console.log("api for refresh token is called");
    let refreshToken = localStorage.getItem('refresh');
    let token = localStorage.getItem('jwt');

    const url = process.env.REACT_APP_SERVER_URL + '/member/refersh/token';
    //console.log('refresh token', refreshToken);

    let formData = new FormData();
    formData.append('refreshToken', refreshToken);

    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    axios.post(url, formData, config)
      .then(function (response) {
        //console.log('refreshing token');
        if (response.status === 200) {
          //console.log("new token storing..");
          localStorage.setItem('jwt', response.data.data.token);
          sessionStorage.setItem('jwt', response.data.data.token)
          localStorage.setItem('refresh', response.data.data.refreshToken);
          isRefreshing = false;
          resolve(true);
        }
        isRefreshing = false;
        resolve(true);
      })
      .catch(function (error) {
        isRefreshing = false;
        //console.log('error', error);
        reject(false);
      });
  });
};

export const validateSession = async () => {

  const activityEvents = ['mousemove', 'click', 'keydown', 'scroll', 'touchstart'];

  let token = localStorage.getItem('jwt');
  let sessionId = sessionStorage.getItem('jwt');
  //console.log("sessionId", sessionId);
  //console.log("session token", token);
  if (sessionId !== token) {
    //one more active session is running
    clearTimeout(timeoutId);
    clearUserActivityTracking(activityEvents);
    timeoutGlobelAction();
    return false;
  }

  const data_decoded = jwtDecode(token);
  //console.log(data_decoded);

  const sessionStartTime = new Date(data_decoded.iat * 1000); //start time
  const sessionEndTime = new Date(data_decoded.exp * 1000); //start time

  const lastActivityTime = new Date();

  const timeDifference = lastActivityTime.getTime() - sessionStartTime.getTime();
  const sessionTimeDifference = sessionEndTime.getTime() - sessionStartTime.getTime();

  const timeDifferenceMinute = Math.floor(timeDifference / (1000 * 60)); // Convert milliseconds to minutes
  const sessionTimeDifferenceMinute = Math.floor(sessionTimeDifference / (1000 * 60)); // Convert milliseconds to minutes
  //console.log("last session start time", sessionStartTime);
  //console.log('Time difference in minutes:', timeDifferenceMinute);
  //console.log("last activity time:", lastActivityTime);
  //console.log('session max Time limit:', sessionTimeDifferenceMinute);

  // if session refresh failed 


  if (timeDifferenceMinute > (sessionTimeDifferenceMinute - 1)) {
    // perform loggout whne session not extended
    //console.log("forced clossing of session");
    clearTimeout(timeoutId);
    clearUserActivityTracking(activityEvents);
    timeoutGlobelAction();
    return false;
  }


  // refresh token before current token is expired so do it when half of ideal time is reached 
  if (timeDifferenceMinute > (data_decoded.idealTime / 2)) {
    //console.log("session need to extend");
    if (!isRefreshing) {
      return await extendToken(); // Wait for extendToken() to complete
    } else {
      return false;
    }
  }
  //console.log("ideal time not reached half");
  return true;
};


export const resetSessionTimeout = (timeoutDuration, timeoutAction) => {
  // this method handle idele session log out
  //console.log("session start");
  const activityEvents = ['mousemove', 'click', 'keydown', 'scroll', 'touchstart'];
  let token = localStorage.getItem('jwt');
  if (!token) {
    clearTimeout(timeoutId);
    clearUserActivityTracking(activityEvents);
    timeoutAction();
  }
  // idealTime = timeoutDuration;
  timeoutGlobelAction = timeoutAction;
  startSessionTimeout(timeoutDuration, timeoutAction, activityEvents);

  validateSession();
};

export const trackUserActivity = (activityEvents, timeoutDuration, timeoutAction) => {
  handleUserActivity = () => {
    resetSessionTimeout(timeoutDuration, timeoutAction);
  };

  activityEvents.forEach((event) => {
    window.addEventListener(event, handleUserActivity);
  });

  resetSessionTimeout(timeoutDuration, timeoutAction);
};

export const clearUserActivityTracking = (activityEvents) => {
  activityEvents.forEach((event) => {
    window.removeEventListener(event, handleUserActivity); // Use handleUserActivity here
  });

  clearTimeout(timeoutId);
};



