import React from "react";
import Requirement from "./GettingStartedPages/Requirements";
import AccessingPortal from "./GettingStartedPages/AccessingPortal";
import MemberEnrollment from "./GettingStartedPages/MemberEnrollment";
import MemberLogin from "./GettingStartedPages/MemberLogin";
import { Typography } from "@mui/material";
const GettingStarted=()=>{
    return(
        <div id="getting-started">

             <br /><Typography variant="h5">GETTING STARTED</Typography>
        <Requirement />
        <AccessingPortal />
        <MemberEnrollment />
        <MemberLogin />
        
        </div>
    );
}
export default GettingStarted;
