// PreviewPage.js
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Paper, Button, CircularProgress } from "@mui/material";
//import LabelText from "./YourPathTo/LabelText"; // Replace with the correct path
//import CustomTextField from "./YourPathTo/CustomTextField"; // Replace with the correct path
import LabelText from "../../../FormComponent/LabelText";
import CustomTextField from "../../../FormComponent/CustomTextField";

import ValueText from "../../../FormComponent/ValueText";
import FormDivider from "../../../FormComponent/FormDivider";
import SubtitleText from "../../../FormComponent/SubtitleText";
import ConfirmationDialogue from "../../../FormComponent/ConfirmationDialogue";
import SucessAlert from "../../../FormComponent/SuccessAlert";
import { useEffect } from "react";
import CustomDropdown from "../../../FormComponent/CustomDropdown";
import axios from "axios";
import ErrorAlert from "../../../FormComponent/ErrorAlert";
import ViewDocuments from "../../../SettlementComponents/ViewDocuments";
import jwtDecode from "jwt-decode";

const PreviewPage = (props) => {
  const { member, memberDet, uploadedDocs, ccData } = props;
  console.log("props", props);
  const navigate = useNavigate(); //inilitialize the navigate function
  const handleGoBack = () => {
    // navigate(-0.5); //navigate back to the previous page
    props.onBackPreview();
  };
  let formattedPenAddress = null;
  let formattedPfAddress = null;
  // if (member.pfAddress) {
  //   try {
  //     const add = JSON.parse(member.pfAddress);
  formattedPfAddress = `${member.addressL1Pf}, ${member.addressL2Pf}, ${member.cityPf}, ${member.districtPf}, ${member.statePf}, ${member.pincodePf}`;
  //   } catch (error) {}
  // }
  // if (member.penAddress) {
  //   try {
  //     const add = JSON.parse(member.penAddress);
  formattedPenAddress = `${member.addressL1Pension}, ${member.addressL2Pension}, ${member.cityPension}, ${member.districtPension}, ${member.statePension}, ${member.pincodePension}`;
  //   } catch (error) {}
  // }

  const defaultClaim = {
    remarks: "",
    assignTo: "",
  };

  //let loginId = "UDA-CC-OFF-101";
  const [loginId, setLoginId] = useState();

  ///********fetching and setting token********* */
  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (token) {
      token = token.replace('"', "").replace('"', "");
      let loginId = jwtDecode(token).user; // Assuming a default loginId
      setLoginId(loginId);
      console.log("loooooooooooooooooooooginId", loginId);
      // Add any further logic here based on the loginId
    } else {
      // Handle the case when token is not present
      // setIsFailed(true);
      // setIsLoading(false);
    }
  }, []);

  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showingDropdown, setShowingDropdown] = useState(0);
  const [claim, setClaim] = useState(defaultClaim);
  const [assignToOption, setAssignToOption] = useState([]);
  const [selectedOfficerName, setSelectedOfficerName] = useState("");
  const [isError, setIsError] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [documents, setDocuments] = useState([]);

  const assignTo = {
    name: "assignTo",
    placeholder: "Select Officer",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "",
    required: true,
    options: assignToOption,
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when the component is mounted
  }, []);

  //****************Dropdown******************* */
  const fetchAssignOfficer = async () => {
    try {
      //  if (!(await sessionExist())) {
      //    setIsFailed(true);
      //    setIsLoading(false);
      //    return;
      //  }

      // let token = localStorage.getItem("jwt");

      // if (!token) {
      //   setIsFailed(true);
      //   setIsLoading(false);
      //   return;
      // }

      // token = token.replace('"', "").replace('"', "");
      // const loginId = jwtDecode(token).user;

      const url =
        process.env.REACT_APP_SERVER_URL +
        "/dropdown/for/update/member/details/by/uda";

      // "/dropdown/for/adv/pf/claim/aao/to/ids";
      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      const options = data.map((option) => ({
        id: option.loginId,
        title: `${option.name} (${option.loginId})`,
      }));
      setAssignToOption(options); // Assuming setAssignToOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };

  const fetchDocuments = async (claimantId) => {
    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/submit/member/docs/by/uda`;
      const formData = new FormData();
      //formData.append("claimId", claimId);
      // formData.append("claimantId", claimantId);

      //formData.append("cmpfAccNo", cmpfAccNo);
      //commented cmpfAccNo to run code
      const config = {
        headers: {
          // Include the Authorization header if required
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(url, formData, config);

      //setIsLoadingDoc(false);
      // setIsFailedDoc(false);

      if (response.status === 200) {
        const data = response.data.data;
        console.log("documents fetched in roda", data);
        setDocuments(data);
      } else {
        // setIsFailed(true); // uncomment this
      }
    } catch (error) {
      // Corrected syntax for the catch block
      // setIsLoading(false);
      //setIsFailed(true); // uncomment this
    }
  };

  useEffect(() => {
    //fetchAllClaimDetail();
    // fetchDocumnetAdv();
  }, []);

  useEffect(() => {
    // fetchAllClaimDetail();
    fetchAssignOfficer();
  }, [loginId]);

  //! cmpf account number is fetched on useEffect with a delay of 3sec to avoid multiple api hits
  const onChange = (e) => {
    if (e.target.name === "assignTo") {
      const selectedOption = assignToOption.find(
        (option) => option.id === e.target.value
      );
      if (selectedOption) {
        setSelectedOfficerName(selectedOption.title); // Set the selected officer's name in the state
        console.log("selctess", selectedOption.title);
      }
    }
    setClaim({ ...claim, [e.target.name]: e.target.value });
  };

  /// ******** on submit function*****************

  const objectTobeSent = {
    loginId: loginId,
    updateRemark: claim.remarks,
    toOffcrloginId: claim.assignTo,
    titleName: memberDet.titleName,
    firstName: member.firstName,
    middleName: member.middleName,
    lastName: member.lastName,
    fatherHusbandName: member.fatherHusbandName,
    gender: member.gender,
    maritalStatus: member.maritalStatus,
    religionName: memberDet.religionName,
    dateOfBirth: member.dateOfBirth,
    addressL1: member.addressL1,
    addressL2: member.addressL2,
    mobileNo: member.mobileNo,
    landNo: member.landNo,
    email: member.email,
    panNo: member.panNo,
    aadhaarNumber: member.aadhaarNumber,
    titleId: member.titleId,
    religionId: member.religionId,
    aadharId: member.aadharId,
    panId: member.panId,
    city: member.city,
    district: member.district,
    state: member.state,
    pincode: member.pincode,
    village: member.village,
    country: member.country,
    employeeId: member.employeeId,
    unitId: member.unitId,
    unitName: member.unitName,
    name: member.name,
    joiningDate: member.joiningDate,
    pfStartDate: member.pfStartDate,
    pfEndDate: member.pfEndDate,
    ifscPf: member.pfBankIfsc,
    bankAccNoPf: member.bankAccNoPf,
    ifscPen: member.penBankIfsc,
    bankAccNoPen: member.bankAccNoPen,
    cmpfAccNo: member.cmpfAccNo,
    // bankAddressPf: bankAddressPf,
    // bankAddressPen: bankAddressPen,
    bankNamePf: member.bankNamePf,
    bankNamePen: member.bankNamePen,
    addressL1Pf: member.addressL1Pf,
    addressL2Pf: member.addressL2Pf,
    cityPf: member.cityPf,
    districtPf: member.districtPf,
    statePf: member.statePf,
    pincodePf: member.pincodePf,
    addressL1Pension: member.addressL1Pension,
    addressL2Pension: member.addressL2Pension,
    cityPension: member.cityPension,
    districtPension: member.districtPension,
    statePension: member.statePension,
    pincodePension: member.pincodePension,
    unitCode: member.unitCode,
  };
  if (memberDet && memberDet.titleName) {
    objectTobeSent.titleName = memberDet.titleName;
  } else {
    objectTobeSent.titleName = member.titleName;
  }

  if (memberDet && memberDet.unitCode) {
    objectTobeSent.unitCode = memberDet.unitCode;
  } else {
    objectTobeSent.unitCode = member.unitCode;
  }

  if (memberDet && memberDet.religionName) {
    objectTobeSent.religionName = memberDet.religionName;
  } else {
    objectTobeSent.religionName = member.religionName;
  }
  // Conditionally include unitId based on the existence and truthiness of unitCo.unitId
  // if (unitCo && unitCo.unitId) {
  //   objectTobeSent.unitId = unitCo.unitId;
  // } else {
  //   objectTobeSent.unitId = member.unitCode;
  // }

  if (ccData && ccData.unitName) {
    objectTobeSent.unitName = ccData.unitName;
  } else {
    objectTobeSent.unitName = member.unitName;
  }

  if (ccData && ccData.coalCompName) {
    objectTobeSent.name = ccData.coalCompName;
  } else {
    objectTobeSent.name = member.name;
  }

  console.log("objectTobeSent", objectTobeSent);
  console.log("objectTobeSent1", member);
  console.log("objectTobeSent2", memberDet);
  const handleSubmitOk = () => {
    if (!objectTobeSent.toOffcrloginId) {
      // Show message in dialog: "Please select an officer to assign"
      // alert("Please select an officer to assign");
      setIsError(true);
      setErrorMessage("Please Select an Officer to Assign.");
      return;
    }
    setIsSubmitClicked(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("sangeeta will give party");
    setIsSubmitting(true);

    const url =
      process.env.REACT_APP_SERVER_URL + "/update/memberdetails/byUDA";

    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSent, { headers })
      .then(function (response) {
        //setIsSubmitting(false);
        //setIsSuccess(true);
        postUploadedDoc();
        // setSuccessMessage("Member details updated successfully.");
        setIsSubmitting(false);
      })
      .catch((error) => {
        setIsSubmitting(false);
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
        console.log("error on submit", error);
      });
  };
  //uploadedDocs
  const postUploadedDoc = () => {
    const uploadDocData = {
      cmpfAcNo: member.cmpfAccNo,
      upldDocsMemDto1: uploadedDocs,
    };
    console.log("uploadDocData", uploadDocData);
    const url = process.env.REACT_APP_SERVER_URL + "/submit/member/docs/by/uda";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, uploadDocData, { headers })
      .then(function (response) {
        console.log("Submit response", response);
        setIsSuccess(true);

        setSuccessMessage("Update Member Details Forwarded Successfully.");
      })
      .catch((error) => {
        setIsError(true);
        setErrorMessage("Error in submitting document. Please Try again");
        console.log("error on submit", error);
      });
  };

  // todo handleAssignToOk calling handle submit on click of ok confirmation dialouge box
  const handleAssignToOk = (e) => {
    handleSubmit(e);
    setIsSubmitClicked(false);
  };

  const handleAssignToCancel = () => {
    setIsSubmitClicked(false);
  };

  //* error alert handler
  const handleErrorAlert = () => {
    setIsError(false);
  };

  const handleProcessOk = (e) => {
    setShowingDropdown(1);
  };

  const handleSuccess = () => {
    navigate("/uda");
  };
  const gender = [
    { id: "M", title: "Male" },
    { id: "F", title: "Female" },
    { id: "O", title: "Other" },
  ];
  const maritalStatus = [
    { id: "M", title: "Married" },
    { id: "U", title: "Unmarried" },
    { id: "W", title: "Widow/Widower" },
    { id: "D", title: "Divorced" },
  ];


  return (
    <div>
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error!"
        body={errorMessage}
        buttonName="Ok"
      />
      <SucessAlert
        handleOk={handleSuccess}
        open={isSuccess}
        title="Success"
        body={successMessage}
        buttonName="OK"
      />
      <ConfirmationDialogue
        handleOk={handleAssignToOk}
        handleCancel={handleAssignToCancel}
        open={isSubmitClicked}
        title="Confirm Assignment"
        body={`Are you sure you want to forward Member Update request to ${selectedOfficerName}?`}
        buttonName="Confirm"
      />
      <Grid container justifyContent="center" sx={{ wordWrap: "break-word" }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/* <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}> */}
          {/* {isFailed ? (
            <Reloader refreshHandler={fetchUserDetail} />
          ) : (
            <>
              {isLoading ? (
                <LoadingIndicator />
              ) : ( */}
          <>
            {/*Button with back functionality */}
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  align="left"
                  onClick={handleGoBack}
                  // sx={{ backgroundColor: "darkviolet" }}
                >
                  Back
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <SubtitleText title=" Preview Member's Details" />
            </Grid>

            <FormDivider />

            {/* <Form> */}
            <Grid
              container
              justifyContent="space-between"
              alignItems="start"
              rowGap={3}
            >
              {/* section 1  */}
              <Grid item xs={12} sm={12} sx={{ marginTop: 2 }}>
                <SubtitleText title="Personal Information" />
              </Grid>
              {/* column 1  */}
              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  alignItems="start"
                  justifyContent="space-between"
                  spacing={2}
                  columnGap={1}
                >
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Title" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {/* <ValueText title={member.titleName} /> */}
                    <ValueText
                      title={
                        memberDet.titleName
                          ? memberDet.titleName
                          : member.titleName
                      }
                    />
                  </Grid>

                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Full Name" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={`${member.firstName} ${member.middleName} ${member.lastName}`}
                    />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Date of Birth" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {/* <ValueText title={member.dateOfBirth} /> */}
                    <ValueText
                      title={new Date(member.dateOfBirth).toLocaleDateString(
                        "en-GB"
                        // options
                      )}
                    />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Pan" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={`XXXXXX${member.panNo?.slice(-4)}`} />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Aadhaar Number" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={`XXXX XXXX ${member.aadhaarNumber?.slice(-4)}`}
                    />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Marital Status" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                  <ValueText
                    title={
                      maritalStatus.find(
                        (item) => item.id === member.maritalStatus
                      )?.title
                    }
                  />
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Address" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={`
                                    ${
                                      member.addressL1
                                        ? member.addressL1 + ","
                                        : ""
                                    }
                                    
                                    ${
                                      member.addressL2
                                        ? member.addressL2 + ","
                                        : ""
                                    }
                                    
                                    ${
                                      member.village ? member.village + "," : ""
                                    }
                                    
                                    ${member.city ? member.city + "," : ""}
                                    
                                    ${
                                      member.district
                                        ? member.district + ","
                                        : ""
                                    }
                                    
                                    ${member.state ? member.state + "," : ""}
                                    
                                    ${
                                      member.country ? member.country + "," : ""
                                    }
                                    
                                    ${member.pincode ? member.pincode : ""}
                                `}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* column 2  */}
              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="start"
                  spacing={2}
                  columnGap={1}
                >
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Religion" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={
                        memberDet.religionName
                          ? memberDet.religionName
                          : member.religionName
                      }
                    />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Gender" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                  <ValueText
                    title={
                      gender.find((item) => item.id === member.gender)?.title
                    }
                  />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Name of Father/Husband" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.fatherHusbandName} />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Email ID" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.email} />
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Mobile Number" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <ValueText
                       title={
                       member.mobileNo !== 0
                       ? member.mobileNo
                      : ""
                      }
                  />

                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Landline Number" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <ValueText
                       title={
                       member.landNo !== 0
                       ? member.landNo
                      : ""
                      }
                  />
                  </Grid>
                </Grid>
              </Grid>
              {/* ************************************** */}
              {/* section 2  */}
              <Grid item xs={12} sm={12}>
                <SubtitleText title="Employment Details" />
              </Grid>
              {/* column 1  */}
              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  alignItems="start"
                  justifyContent="space-between"
                  spacing={2}
                  columnGap={1}
                >
                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="CMPF Account Number" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.cmpfAccNo} />
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Colliery Code" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* <ValueText title={unitCo.unitId} /> */}
                    {memberDet && memberDet.unitCode ? (
                      <ValueText title={memberDet.unitCode} />
                    ) : (
                      <ValueText title={member.unitCode} />
                    )}
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Coal Company" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {ccData && ccData.coalCompName ? (
                      <ValueText title={ccData.coalCompName} />
                    ) : (
                      <ValueText title={member.name} />
                    )}
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="PF Start Date" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={new Date(member.pfStartDate).toLocaleDateString(
                        "en-GB"
                        // options
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* column 2  */}
              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="start"
                  spacing={2}
                  columnGap={1}
                >
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Employee Id" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.employeeId} />
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Colliery Name" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {ccData && ccData.unitName ? (
                      <ValueText title={ccData.unitName} />
                    ) : (
                      <ValueText title={member.unitName} />
                    )}
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Joining Date" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={new Date(member.joiningDate).toLocaleDateString(
                        "en-GB"
                        // options
                      )}
                    />
                  </Grid>


                  {/* row */}
                </Grid>
              </Grid>
              {/* ************************************** */}
              {/* section 3  */}
              <Grid item xs={12} sm={12}>
                <SubtitleText title="Account Details of PF" />
              </Grid>

              {/* column 1  */}

              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  alignItems="start"
                  justifyContent="space-between"
                  spacing={2}
                  columnGap={1}
                >
                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Name of the Bank" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.bankNamePf} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Account No" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.bankAccNoPf} />
                  </Grid>

                  {/* row */}
                  {/* <Grid item xs={12} sm={4}>
                    <LabelText title=" IFSC – Pension" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.ifscPen} />
                  </Grid> */}
                </Grid>
              </Grid>

              {/* column 2  */}

              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="start"
                  spacing={2}
                  columnGap={1}
                >
                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="IFSC" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.pfBankIfsc} />
                  </Grid>

                  {/* row */}

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Address " />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={formattedPfAddress} />
                  </Grid>
                </Grid>
              </Grid>
              {/* ************************************** */}
              {/* section 4  */}
              <Grid item xs={12} sm={12}>
                <SubtitleText title="Account Details of Pension" />
              </Grid>

              {/* column 1  */}

              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  alignItems="start"
                  justifyContent="space-between"
                  spacing={2}
                  columnGap={1}
                >
                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Name of the Bank" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.bankNamePen} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Account No" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.bankAccNoPen} />
                  </Grid>

                  {/* row */}
                  {/* <Grid item xs={12} sm={4}>
                    <LabelText title=" IFSC – Pension" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.ifscPen} />
                  </Grid> */}
                </Grid>
              </Grid>

              {/* column 2  */}

              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="start"
                  spacing={2}
                  columnGap={1}
                >
                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="IFSC" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.penBankIfsc} />
                  </Grid>

                  {/* row */}

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title=" Address " />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={formattedPenAddress} />
                  </Grid>
                </Grid>
              </Grid>

              {/* Document Details*/}
              {/* section 3  */}
              {/* <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={12}>
                  <Paper
                    elevation={3}
                    sx={{ padding: "3rem", borderRadius: "10px" }}
                  >
                    <Grid item xs={12}>
                      <ViewDocuments documents={uploadedDocs} />
                    </Grid>
                  </Paper>
                </Grid>
              </Grid> */}

              {/* Button to confirm and proceed */}

              <Grid item xs={12} style={{ marginTop: "2rem" }}>
                <Button
                  onClick={handleProcessOk}
                  variant="contained"
                  color="success"
                  disabled={isSubmitting}
                >
                  SUBMIT
                </Button>
              </Grid>
            </Grid>
            {showingDropdown === 1 && (
              <Grid
                container
                justifyContent="end"
                alignItems={"center"}
                spacing={2}
              >
                <Grid item xs={12} sm={4}>
                  <CustomDropdown {...assignTo} onchange={onChange} />
                </Grid>
                <Grid item md={1}>
                  <Button
                    onClick={handleSubmitOk}
                    variant="contained"
                    color="success"
                    type="submit"
                    disabled={isSubmitting}
                    fullWidth
                  >
                    {isSubmitting ? <CircularProgress /> : "OK"}
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        </Grid>
      </Grid>
    </div>
  );
};

export default PreviewPage;
