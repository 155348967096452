import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import LoadingIndicator from "../../FormComponent/LoadingIndicator";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { validateSession } from "../../session/EventManager";

function TrackTable() {
  const location = useLocation();
  const [tableData, setTableData] = useState([]);
  //const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [isLoading, setIsLoading] = useState(true);

  const [isFailed, setIsFailed] = useState(false);

  const sessionExist = async () => {
    const status = await validateSession();
    console.log("session status", status);
    return status;
  };

  const fetchTableData = async () => {
    setIsLoading(true);

    if (!(await sessionExist())) {
      console.log("session extending having trouble");
      setIsLoading(false);
      setIsFailed(true);
      return;
    }

    try {
      let token = localStorage.getItem("jwt");
      console.log("token", token);
      if (!token) {
        setIsFailed(true);
        setIsLoading(false);
        return;
      }

      token = token.replace('"', "").replace('"', "");

      const formData = new FormData();
      //formData.append("cmpfAcNo", "HYD/170/840");
      formData.append("cmpfAcNo", jwtDecode(token).user);
      const url =
        process.env.REACT_APP_SERVER_URL + "/show/submitted/claim/by/member";
      const response = await axios.post(url, formData);

      if (response.status === 200) {
        const filteredData = response.data.data.filter((claim) =>
          claim.claimId.startsWith("GRIEV")
        );
        // setTableData(response.data.data);
        setTableData(filteredData);
      } else {
        console.error("Failed to fetch claims. Status: " + response.status);
      }
      setIsLoading(false);
    } catch (error) {
      setIsFailed(true); // Set failure state if there's an error
      setIsLoading(false); // Turn off loading state

      console.error("Error fetching table data: ", error);
    }
  };

  useEffect(() => {
    fetchTableData();
  }, [location.pathname]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClaimIdClick = (grievanceId) => {
    const prefix = grievanceId.substring(0, 2); // Get the first two characters of the claimId
    switch (prefix) {
      case "GR":
        navigate(`currentStatusGreivance`, {
          state: { grievanceId: grievanceId },
        });
        break;
      //   case "PF":
      //     navigate(`/pfStatusTracking`, { state: { claimId: claimId } });
      //     break;
      //   case "AD":
      //     navigate(`/advanceStatusTracking`, { state: { claimId: claimId } });
      //     break;
      default:
        console.error("Unknown claim type:", prefix);
    }
  };

  return (
    <div>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} style={{ padding: "3rem", borderRadius: "8px" }}>
            <Grid item xs={12}></Grid>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                textAlign="right"
                marginRight={"70%"}
                marginTop={"1%"}
              >
                <TitleText title="Grievance Claim Tracking" />
              </Grid>
            </Grid>
            <FormDivider />
            <Grid container justifyContent="center">
              <Grid item xs={12} mt={3}>
                <div>
                  {isLoading ? (
                    <LoadingIndicator />
                  ) : tableData.length === 0 ? (
                    <p>No data available</p>
                  ) : (
                    <TableContainer>
                      <Table
                        style={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Claim ID</TableCell>
                            <TableCell>Claim Type</TableCell>
                            <TableCell>Submission Date</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tableData
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => (
                              <TableRow key={row.claimId}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  onClick={() =>
                                    handleClaimIdClick(row.claimId)
                                  }
                                  style={{
                                    cursor: "pointer",
                                    color: "blue",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {row.claimId}
                                </TableCell>
                                <TableCell>{row.claimType}</TableCell>
                                <TableCell>
                                  {new Date(
                                    row.SubmissionDate
                                  ).toLocaleString()}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                      <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={tableData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableContainer>
                  )}
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default TrackTable;
