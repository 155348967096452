import React from "react";
import { Typography } from "@mui/material";
import MemberLoginLink from '../../../../images/MemberLoginLink.png';
import MemberLoginPage from '../../../../images/MemberLoginPage.png';
import SignIn from '../../../../images/SignIn.png';
import  OtpVerification from '../../../../images/OtpVerification.png';
import MemberDashboard from '../../../../images/MemberDashboard.png';
const MemberLoginFirst=()=>{return(
<>
<Typography variant="h6">MEMBER LOGIN</Typography>
<Typography variant="body1" align='justify'>
Successful enrolment of the users enables them to subsequently login to the portal and perform the respective tasks as defined in the CMPFO processes. To login to the C-CARES portal:<br />
<ol type="1">
    <li>
    Click on the Member Login. (Figure 11)
     </li>
    <li>
    Member login page, as shown in figure 12, requires input fields like, Username (CMPF account number), Password and Captcha. The guidelines for the format of Username are also listed alongside.
    <p align="center"> <img  className="bordered-image" src={MemberLoginLink } height="35%" width="95%"/>
          <br /> Figure 11: Link to Member Login</p>
           <p align="center"><img  className="bordered-image" src={MemberLoginPage} height="55%" width="95%"/><br />
                         Figure 12: Member Login Page</p>


    </li>
    <li>
    After entering all the correct details, SIGN IN button is enabled. Click the SIGN IN button (Figure 13). On successful form submission, member is redirected to OTP verification page.
    <p align="center">
    <img  className="bordered-image" src={SignIn} height="400px" width="70%"/><br />
            Figure 13: Sign In</p>

    </li>
   
    <li>
    An OTP is sent to the member’s registered mobile number. Enter the OTP correctly and hit the verify button.(Figure 14)<br />
    <p align="center"> <img  className="bordered-image" src={ OtpVerification} height="300px" width="75%"/><br />
            Figure 14: OTP Verification</p>


    </li>
    <li>
    On successful OTP verification, member is logged into his account and member dashboard opens as shown in figure 15. Member’s Account Summary is displayed by default.
    <p align="center"> <img  className="bordered-image" src={ MemberDashboard} height="65%" width="90%" /><br />
            Figure 15: Member Dashboard</p>



    </li>
</ol>
</Typography>
</>
);}
export default MemberLoginFirst;