import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Paper,
  Button,
  CircularProgress,
  Autocomplete,
  TextField,
} from "@mui/material";
import {
  officerEnrolModel,
  officerEnrolMandatoryFields,
} from "../../Models/defaultModel";
import { trimStringUtility } from "../../Utility/UtilityMethods";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import SubtitleText from "../../FormComponent/SubtitleText";
import LabelText from "../../FormComponent/LabelText";
import CustomTextField from "../../FormComponent/CustomTextField";
import GroupedRadioButton from "../../FormComponent/GroupedRadioButton";
import { CaptchaProvider } from "../captcha/CaptchaProvider";
import Form from "../captcha/CaptchaOfficerForm";
import { Box } from "@mui/system";
import axios from "axios";
import qs from "qs";
import { useLocation, useNavigate } from "react-router-dom";

function OfficerEnrol() {
  const officerDefaultModel = officerEnrolModel;
  const [officerState, setOfficerState] = useState(officerDefaultModel);

  const [isOTPButtonDisabled, setIsOTPButtonDisabled] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [memberNotPresent, setMemberNotPresent] = useState(false);
  const [memberNotPresentMessage, setMemberNotPresentMessage] = useState("");

  const [validationFailed, setValidationFailed] = useState(false);
  const [validationFailedMsg, setValidationFailedMsg] = useState("");

  const [unitNoLoading, setunitNoLoading] = useState(true);
  const [unitNumberList, setUnitNumberList] = useState([]);

  const [ccLoading, setCCLoading] = useState(true);
  const [ccList, setCCList] = useState([]);
  // useRef to pass otp msg
  const otpMessageRef = useRef(null);

  const currentPage = useLocation();
  const navigate = useNavigate();

  // captcha controller

  const otpButtonDisableFuncEnable = () => {
    setIsOTPButtonDisabled(false);
  };
  const otpButtonDisableFuncDisable = () => {
    setIsOTPButtonDisabled(true);
  };

  const buttonDisabledfunc = () => {
    setButtonDisabled(false);
  };

  const buttonEnablefunc = () => {
    setButtonDisabled(true);
  };

  const actor = {
    options: [
      { value: "ccRep", label: "Coal Company" },
      { value: "RO", label: "Regional Office" },
    ],
    name: "actor",
    // label:'Gender',
    initialValue: officerState.actor,

    disabled: false,
  };

  const login_id = {
    name: "login_id",
    type: "text",
    helperText: "",
    placeholder: "Login ID*",
    defaultValue: officerState.login_id,
    errorMessage:
      "Should contain only AlphaNumeric Characters.underscore is accepted",
    pattern: /^[a-zA-Z0-9-]{0,25}$/,
    required: officerEnrolMandatoryFields.includes("login_id"),
    transformInput: trimStringUtility,
    disabled: false,
  };

  const password = {
    name: "password",
    type: "password",
    helperText: "",
    placeholder: "New Password*",
    defaultValue: officerState.password,
    errorMessage:
      "Password must be 8-20 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character (excluding spaces)",
    pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_])[^\s]{8,20}$/,
    required: officerEnrolMandatoryFields.includes("password"),
    transformInput: trimStringUtility,
    disabled: false,
  };

  const conf_password = {
    name: "conf_password",
    type: "password",
    helperText: "",
    placeholder: "Confirm Password*",
    defaultValue: officerState.conf_password,
    errorMessage: "Passwords doesn't match",
    pattern: `^${officerState.password}$`,
    required: officerEnrolMandatoryFields.includes("conf_password"),
    transformInput: trimStringUtility,
    disabled: false,
  };

  const officer_name = {
    name: "officer_name",
    type: "text",
    helperText: "",
    placeholder: "Name of Officer*",
    defaultValue: officerState.officer_name,
    errorMessage: "Invalid Value",
    pattern: /^[A-Za-z. ]+$/,
    required: officerEnrolMandatoryFields.includes("officer_name"),
    transformInput: trimStringUtility,
    disabled: false,
  };

  const designation = {
    name: "designation",
    type: "text",
    helperText: "",
    placeholder: "Designation*",
    //defaultValue: officerState.designation,
    defaultValue: "CC Representative",
    errorMessage:
      "Should contain only AlphaNumeric Characters.underscore is accepted",
    pattern: "",
    required: officerEnrolMandatoryFields.includes("designation"),
    transformInput: trimStringUtility,
    disabled: true,
  };

  const actor_name = {
    name: "actor_name",
    type: "text",
    helperText: "",
    placeholder: "Name*",
    defaultValue: officerState.actor_name,
    errorMessage:
      "Should contain only AlphaNumeric Characters.underscore is accepted",
    pattern: "^[a-zA-Z0-9-]{0,25}$",
    required: officerEnrolMandatoryFields.includes("actor_name"),
    transformInput: trimStringUtility,
    disabled: false,
  };

  const actor_code = {
    name: "actor_code",
    type: "text",
    helperText: "",
    placeholder: "Code*",
    defaultValue: officerState.actor_code,
    errorMessage:
      "Should contain only AlphaNumeric Characters.underscore is accepted",
    pattern: "^[a-zA-Z0-9-]{0,25}$",
    required: officerEnrolMandatoryFields.includes("actor_code"),
    transformInput: trimStringUtility,
    disabled: false,
  };

  const email_id = {
    name: "email_id",
    type: "email",
    helperText: "",
    placeholder: "Email ID*",
    defaultValue: officerState.email_id,
    errorMessage: "Invalid Email ID",
    pattern: /^[\w\.-]+@[\w\.-]+\.\w+$/,
    required: officerEnrolMandatoryFields.includes("email_id"),
    transformInput: trimStringUtility,
    disabled: false,
  };

  const mob_no = {
    name: "mob_no",
    type: "text",
    helperText: "",
    placeholder: "Mobile Number*",
    defaultValue: officerState.mob_no,
    errorMessage:
      "Should contain only AlphaNumeric Characters.underscore is accepted",
    pattern: "^[a-zA-Z0-9-]{0,25}$",
    required: officerEnrolMandatoryFields.includes("mob_no"),
    transformInput: trimStringUtility,
    disabled: false,
  };

  function findId() {
    // console.log(cmpfAccountNumber);
    //Axiost
    const formData = new FormData();
    formData.append("loginId", officerState.login_id);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/officer/find",
        formData,
        config
      )
      .then((response) => {
        sendOTP();
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);

        console.log("officer found", response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error reponse", error);
        if (error.response.data.http_status_code === 409) {
          console.log("officer already enrolled");
          setMemberNotPresent(true);
          setMemberNotPresentMessage("Officer already enrolled.");
        }
        if (error.response.data.http_status_code === 404) {
          console.log("officer not found");
          setMemberNotPresent(true);
          setMemberNotPresentMessage("Officer not found.");
        }
      });
  }

  const onChange = (e) => {
    setOfficerState({
      ...officerState,
      [e.target.name]:
        //  [e.target.name] === "password"
        //  ? window.btoa(e.target.value)
        e.target.value,
    });
  };

  // const onChange = (e) => {
  //   setOfficerState({
  //     ...officerState,
  //     [e.target.name]:
  //       [e.target.name] === "login_id"
  //         ? findId(e.target.value)
  //         : e.target.value,
  //   });
  // };

  const sendOTP = () => {
    sessionStorage.removeItem("messageRef");
    const sendData = qs.stringify({ loginId: officerState.login_id });
    axios
      .post(process.env.REACT_APP_SERVER_URL + "/officer/send/otp", sendData)
      .then((resp) => {
        if (resp.status === 200) {
          setIsLoading(false);
          //clear form
          otpMessageRef.current = resp.data.message;
          navigate("otp", {
            replace: true,
            state: {
              data: officerState,
              purpose: "OFFICERENROL",
              messageRef: otpMessageRef,
            },
          });
          return;
        }
        //check the otp msg
        otpMessageRef.current = "Something went wrong. Try agin";
        navigate("otp", {
          replace: true,
          state: {
            data: officerState,
            purpose: "OFFICERENROL",
            messageRef: otpMessageRef,
          },
        });
      })
      .catch(function (error) {
        console.log("error otp", error);
        setIsLoading(false);
        otpMessageRef.current = error.response.data.error_message;
        navigate("otp", {
          replace: true,
          state: {
            data: officerState,
            purpose: "OFFICERENROL",
            messageRef: otpMessageRef,
          },
        });
        return;
      });
    return;
  };
  /////  validation function /////

  const checkEmpty = (param) => {
    if (!param) {
      return false;
    } else return true;
  };
  const patternChecker = (param, pattern) => {
    //console.log("param in patten cheker", param);
    return pattern.test(param);
  };

  const validate = () => {
    // when the ro enrollment begins changed are needed in the field
    const requiredFields = [
      { field: "Login id", value: officerState.login_id },
      { field: "Password", value: officerState.password },
      { field: "Confirm Password", value: officerState.conf_password },
      { field: "CC Name", value: officerState.actor_name },
      { field: "Unit Code", value: officerState.actor_code },
      { field: "Officer Name", value: officerState.officer_name },
      { field: "Email id", value: officerState.email_id },
    ];

    const fieldsToValidate = [
      { field: "Login Id", value: officerState.login_id, pattern: "" },
      {
        field: "Password",
        value: officerState.password,

        pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_])[^\s]{8,20}$/,
      },
      {
        field: "Officer Name",
        value: officerState.officer_name,
        pattern: /^[A-Za-z. ]+$/,
      },
      {
        field: "Email Id",
        value: officerState.email_id,
        pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      },
    ];

    for (const fieldData of requiredFields) {
      const { field, value } = fieldData;

      if (!value) {
        console.log(`${field} is required.`);
        setValidationFailed(true);
        setValidationFailedMsg(`${field} is required.`);
        return false; // Return false if any required field is empty
      }
    }

    for (const fieldData of fieldsToValidate) {
      const { field, value, pattern } = fieldData;

      if (value && pattern && !patternChecker(value, pattern)) {
        // console.log("validate values", typeof value);
        console.log(`${field} has invalid values. Please Check.`);
        setValidationFailed(true);
        setValidationFailedMsg(`${field} has invalid values. Please Check.`);
        return false; // If validation fails for any field, return false
      }
    }

    if (officerState.conf_password !== officerState.password) {
      console.log("New Password and Confirm Password do not match");
      setValidationFailed(true);
      setValidationFailedMsg(
        "New Password and Confirm Password do not match. Please Check."
      );
      return false;
    }

    return true; // If all required fields are non-empty and patterns are valid, return true
  };

  // const checkLoginId = () => {};
  // const checkPassword = () => {};
  // const checkConfirmPassword = () => {};
  // const checkOfficerName = () => {
  //   if (/^[A-Za-z. ]+$/.test(officerState.officer_name)) {
  //     return true;
  //   }
  // };

  // const checkEmailName = () => {
  //   if (/^[A-Za-z.]+( [A-Za-z.]+)?$/.test(officerState.email_id)) {
  //     return true;
  //   }
  // };

  const memberNotPresentAlert = () => {
    setMemberNotPresent(false);
    //setMemberNotPresentMessage("");
  };

  const handleValidationFailedAlert = () => {
    setValidationFailed(false);
    setIsLoading(false);
  };

  const getUnit = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + "/allunits";
      const response = await axios.get(url);
      //console.log("unitlist", response.data.data);
      return response.data.data;
    } catch (error) {
      console.log("error - unit", error);
      return [];
    }
  };
  const getCC = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + "/all/coal/comp";
      console.log(url);
      const response = await axios.post(url);
      // console.log("cclist", response.data.data);
      return response.data.data;
    } catch (error) {
      console.log("error - cc", error);
      return [];
    }
  };
  ////// parsing unitcode from the unitcode-unitname dropdown value ////
  function parseunit(unitCombined) {
    const [unitCode, unitName] = unitCombined.split("-");
    console.log(unitCode);
    return unitCode;
  }
  /////////////
  /////////////
  const createUnitList = async () => {
    try {
      const data = await getUnit();
      //console.log(data);
      const concatenatedArray = data.map((item) => `${item.unitCode}`);
      console.log(concatenatedArray);
      return concatenatedArray;
    } catch (error) {}
  };
  const createCCList = async () => {
    try {
      const data = await getCC();
      //console.log(data);
      const concatenatedArray = data.map((item) => `${item.name}`);
      //console.log(concatenatedArray);
      return concatenatedArray;
    } catch (error) {}
  };

  const fetchUnitNo = async () => {
    try {
      // console.log("in fetch unit no");
      const unitNumberListSamples = await getUnit();
      //console.log("unitListSample", unitNumberListSamples);

      const options = unitNumberListSamples.map((option) => {
        //const firstID = option.match(/[0-9]/) ? "0-9" : option.charAt(0); // Get the first letter
        // console.log("option", option);
        const firstID = option.unitCode;
        //console.log("firstId", firstID);

        return {
          firstID: /[0-9]/.test(firstID.charAt(0)) ? "0-9" : firstID.charAt(0),
          unitName: option.unitCode,
          value: option.unitId.toString(),
        };
      });

      setunitNoLoading(false);
      setUnitNumberList(options);
      //console.log("unit options", options);
    } catch (error) {
      // Handle error if needed.
    }
  };

  const fetchCC = async () => {
    try {
      // console.log("in fetch cc");
      // await sleep(1e3); // For demo purposes.
      // getRo();

      const ccListSamples = await createCCList();
      // console.log("ccListSample", ccListSamples);
      // const ccListSamples = [
      //   "Bilaspur",
      //   "Nagpur",
      //   "Dhanbad",
      //   "Sigraulli",
      //   "Asansol",
      // ];

      const options = ccListSamples
        .filter((option) => typeof option === "string")
        .map((option) => {
          const firstID = option[0]; // Get the first letter
          // const unitName = option.split('/')[0]; // Extract the city name
          //console.log("firstId in CC", firstID);
          return {
            firstID: /[0-9]/.test(firstID) ? "0-9" : firstID,
            ccName: option,
            value: option,
          };
        });

      setCCLoading(false);
      setCCList(options);
      //console.log("cclist", ccList);
    } catch (error) {
      // catch error if any
    }
  };

  // unitNo serach
  useEffect(() => {
    fetchUnitNo();
    fetchCC();
  }, []);

  const handleUnitNoSearch = (event, newValue) => {
    // console.log("event newValue", event, newValue);

    // const updated = [...officerState];
    // console.log("officerState", officerState);

    // updated["actor_code"] = newValue ? newValue.value : "";

    setOfficerState({ ...officerState, actor_code: newValue.value });
    //props.consentData(updatedRows);
    // setSearchControl({ ...searchControl, "searchParam": newValue ? newValue.value : '' });
  };
  const handleCCSearch = (event, newValue) => {
    console.log("newVa", newValue);
    setOfficerState({ ...officerState, actor_name: newValue.ccName });
    // setSearchControl({ ...searchControl, "searchParam": newValue ? newValue.value : '' });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    //console.log("save button Clicked data=>):", officerState);
    // axios request for finding loginid exists or not
    if (validate()) {
      if (officerState.actor === "RO") {
        console.log("RO enrolment is not active right now !!");
        setValidationFailed(true);
        setValidationFailedMsg("RO enrolment not active right now.");
      } else {
        findId();
      }
    }

    // sendOTP();
  };

  return (
    <div>
      <ErrorAlert
        handleOk={memberNotPresentAlert}
        open={memberNotPresent}
        title="Error!"
        body={memberNotPresentMessage}
        buttonName="Ok"
      />
      <ErrorAlert
        handleOk={handleValidationFailedAlert}
        open={validationFailed}
        title="Error!"
        body={validationFailedMsg}
        buttonName="Ok"
      />

      <Grid
        container
        justifyContent="center"
        alignContent="center"
        sx={{ margin: "20px", wordWrap: "break-word" }}
      >
        <Grid item xs={10} sm={12} md={10} lg={10}>
          <Paper
            elevation={3}
            sx={{ padding: "3rem", borderRadius: "8px", cursor: "default" }}
          >
            <Grid item xs={12}>
              <TitleText title="Officer Enrolment" />
            </Grid>
            <FormDivider />

            {/* Form Wrapper grid */}
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              rowGap={2}
            >
              {/* Form Divider main Grid */}
              <Grid
                container
                justifyContent="space-between"
                alignItems="flex-start"
                rowGap={2}
              >
                {/* section 1 column 1 - cred*/}
                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    justifyContent="space-between"
                    rowGap={2}
                    columnGap={2}
                  >
                    {/* row  */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Login ID" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...login_id} onchange={onChange} />
                    </Grid>

                    {/* row  */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="New Password" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...password} onchange={onChange} />
                    </Grid>

                    {/* row  */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Confirm Password" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...conf_password} onchange={onChange} />
                    </Grid>

                    {/* row  */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Type" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <GroupedRadioButton {...actor} onchange={onChange} />
                    </Grid>

                    {/* ************ Ro Name and code for RO Radio*********** */}

                    {officerState.actor === "RO" && (
                      <>
                        <Grid item xs={12} sm={4}>
                          <LabelText title="RO Name" />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...actor_name}
                            onchange={onChange}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <LabelText title="RO Code" />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...actor_code}
                            onchange={onChange}
                          />
                        </Grid>
                      </>
                    )}

                    {/* ************ CC Name and code for CC Radio*********** */}

                    {officerState.actor === "ccRep" && (
                      <>
                        <Grid item xs={12} sm={4}>
                          <LabelText title="CC Name" />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <Autocomplete
                            sx={{
                              minWidth: "150px",
                            }}
                            ListboxProps={{
                              style: {
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                maxHeight: 180, // Change this to your desired height
                              },
                            }}
                            // freeSolo
                            //inputValue={officerState.actor_name}
                            disableClearable
                            onChange={(e, newValue) =>
                              handleCCSearch(e, newValue)
                            }
                            options={ccList.sort(
                              (a, b) => -b.firstID.localeCompare(a.firstID)
                            )}
                            // groupBy={(ccList) => ccList.firstID}
                            getOptionLabel={(ccList) => ccList.ccName}
                            loading={ccLoading} // Set loading prop
                            renderInput={(params) => (
                              <TextField
                                name="actor_name"
                                // onChange={(e) => handleUnitNoSearchFreeSolo(index, e)}
                                sx={{
                                  "& .MuiFormHelperText-root": {
                                    marginLeft: 0,
                                    // fix based on your screen
                                    fontSize: {
                                      xs: "9px",
                                      sm: "9px,",
                                      md: "9px",
                                      lg: "12px",
                                      xl: "14px",
                                    },
                                    fontWeight: "400",
                                    color: "black",
                                  },
                                  "& .MuiInputBase-input": {
                                    minWidth: "80px",
                                    borderRadius: "5px",
                                    color: "black",
                                    background: "#ffffff",
                                    // fix based on your screen
                                    fontSize: {
                                      xs: 12,
                                      sm: 12,
                                      md: 13,
                                      lg: 14,
                                      xl: 16,
                                    },
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    borderRadius: "5px",
                                    "& fieldset": {
                                      borderColor: "#474E68",
                                      // borderRadius:'0px'
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#6B728E",
                                      // borderRadius: '0px'
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#0F3460",
                                      borderWidth: "0.5px",
                                      // borderRadius: '0px'
                                    },
                                  },
                                }}
                                autoComplete="off"
                                // size='small'

                                {...params}
                                placeholder="Enter CC Name."
                                // label="Unit No."

                                fullWidth
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {ccLoading && (
                                        <CircularProgress
                                          color="inherit"
                                          size={20}
                                        />
                                      )}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                                helperText={
                                  <div style={{ height: "18px" }}></div>
                                }
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <LabelText title="Unit Code" />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <Autocomplete
                            sx={{
                              minWidth: "160px",
                            }}
                            ListboxProps={{
                              style: {
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                maxHeight: 180, // Change this to your desired height
                                width: "auto",
                                fontSize: 14,
                              },
                            }}
                            //freeSolo
                            //inputValue={row.unit.toUpperCase()}
                            disableClearable
                            onChange={(e, newValue) =>
                              handleUnitNoSearch(e, newValue)
                            }
                            options={unitNumberList.sort(
                              (a, b) => -b.firstID.localeCompare(a.firstID)
                            )}
                            groupBy={(unitNumberList) => unitNumberList.firstID}
                            getOptionLabel={(unitNumberList) =>
                              unitNumberList.unitName
                            }
                            loading={unitNoLoading} // Set loading prop
                            renderInput={(params) => (
                              <TextField
                                name="actor_code"
                                onChange={(e) => handleUnitNoSearch(e)}
                                sx={{
                                  "& .MuiFormHelperText-root": {
                                    marginLeft: 0,
                                    // fix based on your screen
                                    fontSize: {
                                      xs: "9px",
                                      sm: "9px,",
                                      md: "9px",
                                      lg: "12px",
                                      xl: "14px",
                                    },
                                    fontWeight: "400",
                                    color: "black",
                                  },
                                  "& .MuiInputBase-input": {
                                    minWidth: "80px",
                                    borderRadius: "5px",
                                    color: "black",
                                    background: "#ffffff",
                                    // fix based on your screen
                                    fontSize: {
                                      xs: 12,
                                      sm: 12,
                                      md: 13,
                                      lg: 14,
                                      xl: 16,
                                    },
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    borderRadius: "5px",
                                    "& fieldset": {
                                      borderColor: "#474E68",
                                      // borderRadius:'0px'
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#6B728E",
                                      // borderRadius: '0px'
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#0F3460",
                                      borderWidth: "0.5px",
                                      // borderRadius: '0px'
                                    },
                                  },
                                }}
                                autoComplete="off"
                                // size='small'

                                {...params}
                                placeholder="Unit Code"
                                // label="Unit No."

                                fullWidth
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {unitNoLoading && (
                                        <CircularProgress
                                          color="inherit"
                                          size={20}
                                        />
                                      )}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                                // error={
                                //   !/^[A-Za-z]{3,4}\/\d{1,4}$/.test(
                                //     params.inputProps.value
                                //   ) && params.inputProps.value !== ""
                                // }
                                helperText={
                                  <div style={{ height: "18px" }}>
                                    {/* {!/^[A-Za-z]{3,4}\/\d{1,4}$/.test(
                              params.inputProps.value
                            ) && params.inputProps.value !== ""
                              ? "Invalid format"
                              : ""} */}
                                  </div>
                                }
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>

                {/* section 1 column 2 */}
                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    justifyContent="space-between"
                    rowGap={2}
                    columnGap={2}
                  >
                    {/* row  */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Name of the Officer" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...officer_name} onchange={onChange} />
                    </Grid>

                    {/* row  */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Designation" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...designation} onchange={onChange} />
                    </Grid>

                    {/* row  */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Email Id" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...email_id} onchange={onChange} />
                    </Grid>

                    {/* row  */}
                    {/* <Grid item xs={12} sm={4}>
                    <LabelText title="Mobile No" />
                  </Grid> */}
                    {/* <Grid item xs={12} sm={7}>
                    <CustomTextField {...mob_no} onchange={onChange} />
                  </Grid> */}

                    <Grid item xs={12} sm={4}>
                      <LabelText title="Verify Captcha" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CaptchaProvider>
                        <Form
                          buttonEnablefunc={buttonEnablefunc}
                          buttonDisabledfunc={buttonDisabledfunc}
                          otpButtonDisableFuncEnable={
                            otpButtonDisableFuncEnable
                          }
                          otpButtonDisableFuncDisable={
                            otpButtonDisableFuncDisable
                          }
                        />
                      </CaptchaProvider>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* ***************** save button left aligned  *********************/}

              <Grid item xs={12} sm="auto">
                <Button
                  type="submit"
                  color="success"
                  fullWidth
                  variant="contained"
                  // style={btstyle}
                  onClick={handleSubmit}
                  disabled={isLoading || buttonDisabled}
                >
                  {isLoading ? (
                    <CircularProgress size={30} sx={{ color: "primary" }} />
                  ) : (
                    "Send OTP"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default OfficerEnrol;
