import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import uuid from "react-uuid";
import { TablePagination } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#526D82",
    color: theme.palette.common.white,
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.3,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF9FF",
  },
  "&:last-child td, &:last-child th": {
    border: 1,
    backgroundColor: "#FFFFFF",
  },
}));

function TableWithLinks(props) {
  const { preview, data, passingId, viewLink, size, onSelectedChange } = props;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

   const tableHeaders = preview && preview[0] ? Object.keys(preview[0]) : [];

  function createHeader() {
    return (
      <TableRow>
        {tableHeaders.map((title) => (
          <StyledTableCell key={title} align="center">
            {title}
          </StyledTableCell>
        ))}
      </TableRow>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function createRows() {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return preview.slice(startIndex, endIndex).map((row, index) => {
      return (
        <StyledTableRow key={index}>
          {tableHeaders.map((key, i) => (
            <StyledTableCell key={uuid()} align="center">
              {i === 0 && viewLink ? (
                <Link to={viewLink} state={{ passingId: row[key] }}>
                  {row[key]}
                </Link>
              ) : (
                row[key]
              )}
            </StyledTableCell>
          ))}
        </StyledTableRow>
      );
    });
  }

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} size={size} aria-label="a dense table">
        <TableHead>{createHeader()}</TableHead>
        <TableBody>{createRows()}</TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={preview.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

export default TableWithLinks;
