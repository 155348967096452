import React, { useState, useEffect } from "react";
import axios from "axios";
import TableWithLinkAndButton from "../../FormComponent/TableWithLinkAndButton";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import { Tabs, Tab, Container, Grid, Paper, Box } from "@mui/material";
import jwtDecode from "jwt-decode";
import { getToken } from "../../UiManager/SessionChecker";

const AddFamMemUao = () => {
  const defaultSubmittedMemDetls = {
    // updReqAckId: "",
    enrolAckId: "",
    unitCode: "",
    name: "",
    joiningDate: "",
    mobileNo: "",
  };

  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [tabData, setTabData] = useState({
    received: [defaultSubmittedMemDetls],
    processed: [defaultSubmittedMemDetls],
    returned: [defaultSubmittedMemDetls],
  });

  //let loginId = "UAO-CC-OFF-103"; // Assuming a default loginId
  const [pageLink, setPageLink] = useState("viewaddfammemuao");

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (newValue === 0) {
      setPageLink("viewaddfammemuao");
    } else if (newValue === 1) {
      setPageLink("proceedaddfammemdtls");
    } else if (newValue === 2) {
      setPageLink("proceedaddfammemdtls");
    }
  };

  useEffect(() => {
    fetchReceived();
  }, []);

  const fetchReceived = async () => {
    try {
      setIsLoading(true);
      let token = getToken();
      console.log("token on aao", token);
      if (!token) {
        setIsLoading(false);
        //setIsFailed(true);
        return;
      }
      token = token.replace('"', "").replace('"', "");
      const loginId = jwtDecode(token).user;
      console.log("loginId on aao", loginId);

      const url1 =
        process.env.REACT_APP_SERVER_URL +
        "/show/assigned/list/new/family/member/to/officers";
      const url2 =
        process.env.REACT_APP_SERVER_URL +
        "/show/processed/list/new/family/member/to/officers";
      const url3 =
        process.env.REACT_APP_SERVER_URL +
        "/show/returned/list/new/family/member/to/officers";

      // Uncomment the following lines if 'loginId' is used
      const formData = new FormData();
      formData.append("loginId", loginId);

      const config = {
        headers: {
          // Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response1 = await axios.post(url1, formData, config);
      const response2 = await axios.post(url2, formData, config);
      const response3 = await axios.post(url3, formData, config);
      console.log("response1", response1);
      console.log("response2", response2);
      console.log("response3", response3);
      if (response1.status === 200) {
        setTabData((prevTabData) => ({
          ...prevTabData,
          received: response1.data.data,
        }));
      }
      if (response2.status === 200) {
        // Remove the unnecessary 'if' condition here
        setTabData((prevTabData) => ({
          ...prevTabData,
          processed: response2.data.data,
        }));
      }
      if (response3.status === 200) {
        setTabData((prevTabData) => ({
          ...prevTabData,
          returned: response3.data.data,
        }));
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error - received", error);
      return [];
    }
  };

  const createTablePreviewData = () => {
    let tablePreviewData = [];

    selectedTabData.forEach((mem) => {
      tablePreviewData.push({
        // "Enroll Req Ack Id": mem.enrolAckId,
        "Enroll Req Ack Id": mem.updReqAckId,
        Name: mem.fullName,
        "Family Name": mem.name,
        "Relation with member": mem.relation,
        "Unit Code": mem.unitCode,
        "Submission Date": mem.submiDate
          ? new Date(mem.submiDate).toLocaleDateString("en-GB")
          : "",
      });
    });

    return tablePreviewData;
  };

  const selectedTabData =
    tabData[
      value === 0
        ? "received"
        : value === 1
        ? "processed"
        : value === 2
        ? "returned"
        : ""
    ] || [];

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            <Grid item xs={12}>
              <TitleText title={"Add Family Member Details Approval "} />
            </Grid>
            <FormDivider />
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <Container>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Tabs value={value} onChange={handleChange}>
                      <Tab
                        label="Received"
                        style={{
                          backgroundColor: value === 0 ? "#831f76" : "", //bisqu
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          color: value === 0 ? "#ffffff" : "#000000", // Set text color to white when selected
                        }}
                      />
                      <Tab
                        label="Processed"
                        style={{
                          backgroundColor: value === 1 ? "#831f76" : "",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          color: value === 1 ? "#ffffff" : "#000000", // Set text color to white when selected
                        }}
                      />
                      <Tab
                        label="Returned"
                        style={{
                          backgroundColor: value === 2 ? "#831f76" : "",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          color: value === 2 ? "#ffffff" : "#000000", // Set text color to white when selected
                        }}
                      />
                    </Tabs>
                  </Grid>
                  <Grid item xs={12} marginTop={-2}>
                    <Box
                      p={3}
                      style={{
                        backgroundColor: "#fffaff",
                        border: "1px solid #831f76",
                      }}
                    >
                      <TableWithLinkAndButton
                        preview={createTablePreviewData()}
                        data={selectedTabData}
                        passingId="Pf ID"
                        viewLink={pageLink}
                        parentData={value}
                        size="small"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddFamMemUao;
