import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Paper, Box, Button, Divider, Typography } from "@mui/material";
import { memberModel } from "../Models/defaultModel";

import TitleText from "../FormComponent/TitleText";
import LabelText from "../FormComponent/LabelText";
import CustomTextField from "../FormComponent/CustomTextField";
import CustomDropdown from "../FormComponent/CustomDropdown";
import GroupedRadioButton from "../FormComponent/GroupedRadioButton";
import SubtitleText from "../FormComponent/SubtitleText";
import CustomTextArea from "../FormComponent/CustomTextArea";
import LoadingIndicator from "../FormComponent/LoadingIndicator";
import FormDivider from "../FormComponent/FormDivider";
import { faClosedCaptioning } from "@fortawesome/free-solid-svg-icons";
import ValueText from "../FormComponent/ValueText";
import jwtDecode from "jwt-decode";
import axios from "axios";
import Reloader from "../FormComponent/Reloader";
// import { validateSession } from "../../session/EventManager";

function ClaimDetailsPf(props) {
  const { pfclaimdetails } = props;
  const { claimDetails, officerRemarks } = pfclaimdetails;
  console.log("claimDetails", claimDetails);
  console.log("officerRemarks", officerRemarks);
  // const [claimData] = claimDetails;

  const claimData = claimDetails[0];
  //const extractedRemarks = officerRemarks.map((remark) => remark.remarks);
  // const extractedRemarks = officerRemarks.map(
  //   (remark) => remark.status + ": " + remark.remarks
  // );

  const location = useLocation();
  const { pathname } = location;
  console.log("pppppppppppppppppppppppppppppp", pathname);
  let type = "";

  if (pathname.includes("penset")) {
    type = "PN";
  } else if (pathname.includes("pfset")) {
    type = "PF";
  }

  console.log("claimData", claimData);

  const parseName = (name) => {
    try {
      //console.log("name", claimData);
      const parsedName = JSON.parse(name);
      const { firstname, middlename, lastname } = parsedName;
      const fullName = `${firstname} ${
        middlename ? middlename + " " : ""
      }${lastname}`;
      return fullName;
    } catch (error) {
      console.error("Error parsing name:", error);
      return "";
    }
  };

  return (
    <Grid container justifyContent="center" sx={{ wordWrap: "break-word" }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <>
          {/* ************** */}
          {/* section 1  */}
          <Grid
            container
            justifyContent="space-between"
            alignItems="start"
            rowGap={3}
          >
            <Grid item xs={12} sm={12}>
              <SubtitleText title="Claim Details" />
            </Grid>

            {/* column 1  */}
            <Grid item xs={12} sm={5}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
                columnGap={1}
              >
                {/* Rows for Claim Details */}
                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Claim ID" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}
                >
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText title={claimData.claimId} />
                </Grid>

                {/* row */}
                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Claim Type" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}
                >
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  {type === "PF" ? (
                    <ValueText title={claimData.pfClaimType} />
                  ) : (
                    <ValueText title={claimData.penClaimType} />
                  )}
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Name as per Aadhaar Card" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}
                >
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* <ValueText title={parseName(claimData.nameAadhaar)} /> */}
                  <ValueText title={parseName(claimData.nameAadhaar)} />
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Name as per Bank " />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}
                >
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText title={parseName(claimData.nameBank)} />
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="DOB as per Aadhaar Card" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}
                >
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText title={claimData.dobInAadhaar} />
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  {/* <LabelText title="Total broken period member contribution" /> */}
                  {type === "PF" && (
                    <LabelText title="Total broken period member contribution" />
                  )}
                </Grid>
                {type === "PF" && (
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  {/*   Need to change claim type api /> */}
                  {/* <ValueText
                    title={parseInt(claimData.brokenPeriodMemShareContri)}
                  /> */}
                  {type === "PF" && (
                    <ValueText title={claimData.brokenPeriodMemShareContri} />
                  )}
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  {/* <LabelText title="Total broken period voluntary contribution" /> */}
                  {type === "PF" && (
                    <LabelText title="Total broken period voluntary contribution" />
                  )}
                </Grid>
                {type === "PF" && (
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  {/*   Need to change claim type api /> */}
                  {/* <ValueText
                    title={parseInt(claimData.brokenPeriodVolContri)}
                  /> */}
                  {type === "PF" && (
                    <ValueText title={claimData.brokenPeriodVolContri} />
                  )}
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Remarks" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}
                >
                  :
                </Grid>

                <Grid item xs={12}>
                  {officerRemarks.map((item, index) => {
                    if (item.remarks !== "") {
                      return (
                        <Typography key={index} align="left" mb={1}>
                          <b>
                            <u>{item.fromLoginId}</u>
                          </b>{" "}
                          :- {item.remarks}
                        </Typography>
                      );
                    }
                    return null; // Return null for items with empty remarks
                  })}
                </Grid>
              </Grid>
            </Grid>

            {/* column 2  */}
            <Grid item xs={12} sm={5}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                columnGap={1}
              >
                {/* Rows for claim Details */}

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Cessation Date" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}
                >
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/*   Need to change claim type api /> */}
                  {type === "PF" ? (
                    <ValueText
                      title={
                        claimData.dateOfCessation
                          ? new Date(
                              claimData.dateOfCessation
                            ).toLocaleDateString(
                              "en-GB"
                              // options
                            )
                          : ""
                      }
                    />
                  ) : (
                    <ValueText
                      title={
                        claimData.dateofCessation
                          ? new Date(
                              claimData.dateofCessation
                            ).toLocaleDateString(
                              "en-GB"
                              // options
                            )
                          : ""
                      }
                    />
                  )}
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Submission Date" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}
                >
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText
                    title={
                      claimData.submissionDate
                        ? new Date(claimData.submissionDate).toLocaleDateString(
                            "en-GB"
                            // options
                          )
                        : ""
                    }
                  />
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Name as per Pan Card" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}
                >
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText title={parseName(claimData.namePan)} />
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}
                ></Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText title="" />
                </Grid>

                {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="DOB as per Pan" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}
                >
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText title={claimData.dobInPan} />
                </Grid>

                {/* row */}
                {type === "PF" && (
                  <Grid item xs={12} sm={4}>
                    <LabelText title="No. of Broken period months" />
                  </Grid>
                )}
                {type === "PF" && (
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>
                )}
                {type === "PF" && (
                  <Grid item xs={12} sm={6}>
                    {/*   Need to change claim type api /> */}

                    <ValueText title={parseInt(claimData.brokenPeriodMonths)} />
                  </Grid>
                )}
                {/* row */}
                {type === "PF" && (
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Total broken period employer contribution" />
                    {/* {type === "PF" && (
                    <LabelText title="Total broken period employer contribution" />
                  )} */}
                  </Grid>
                )}
                {type === "PF" && (
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}
                  >
                    :
                  </Grid>
                )}
                {type === "PF" && (
                  <Grid item xs={12} sm={6}>
                    {/*   Need to change claim type api /> */}
                    <ValueText
                      title={parseInt(claimData.brokenPeriodEmplyrShareContri)}
                    />
                    {/* {type === "PF" && (
                    <ValueText
                      title={parseInt(claimData.brokenPeriodEmplyrShareContri)}
                    />
                  )} */}
                  </Grid>
                )}
              </Grid>
            </Grid>
            {/* ************** */}
          </Grid>
        </>
        {/* </Paper> */}
      </Grid>
    </Grid>
  );
}

export default ClaimDetailsPf;
