import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import { Avatar, Button, Grid, Paper, TextField } from "@mui/material";
import jwtDecode from "jwt-decode";
import axios from "axios";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import { validateSession } from "../../session/EventManager";

const ChangePasswordCCRep = () => {
  const paperStyle = { padding: 20, width: 400, margin: "20px auto" };
  const btstyle = { fontWeight: "600", margin: "20px 0" };

  const navigate = useNavigate();
  const location = useLocation();

  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const [errorAlert, setErrorAlert] = useState(false);

  const [msg, setMsg] = useState("");

  // useRef to pass otp msg
  const otpMessageRef = useRef(null);

  // control button visibility
  const oldPasswordRef = useRef("");
  const [otherFieldController, setOtherFieldController] = useState({
    new: false,
    confirm: false,
  });

  const [inputController, setInputController] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    // replacing previous stack
    const handlePopState = () => {
      if (location.pathname === "/change-password-officer") {
        // Replace OTP page with Change Password page in history
        navigate("/officer/changepassword", { replace: true });
      }
    };

    window.addEventListener("popstate", handlePopState);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [location, navigate]);

  const validatePassword = (password) => {
    // Validate password: minimum uppercase, lowercase, special character, and number
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_])[^\s]{8,20}$/;
    return regex.test(password);
    //return true when password met the criteria
  };

  const handleButtonVisibility = (e) => {
    if (e.target.name === "oldPassword") {
      console.log("control", otherFieldController.new);
      if (otherFieldController.new && otherFieldController.confirm) {
        if (isOldPasswordFilled()) {
          setIsButtonDisabled(false);
        } else {
          setIsButtonDisabled(true);
        }
        return;
      }
      setIsButtonDisabled(true);
    }
  };

  const onChange = (e) => {
    handleButtonVisibility(e);
    setInputController({ ...inputController, [e.target.name]: e.target.value });
  };

  const isOldPasswordFilled = () => {
    if (oldPasswordRef.current.value) {
      return true;
    }
    return false;
  };

  const handleNewPasswordChange = (event) => {
    const password = event.target.value;
    onChange(event);
    if (!validatePassword(password)) {
      console.log("invalid");
      setIsButtonDisabled(true);
      setOtherFieldController({ ...otherFieldController, new: false });
      setNewPasswordError(
        "Password must contain at least 8 characters and maximum of 20 characters, including uppercase, lowercase,number and special character except space."
      );
    } else {
      if (inputController.confirmPassword) {
        if (inputController.confirmPassword === event.target.value) {
          setNewPasswordError("");
          setConfirmPasswordError("");
          setOtherFieldController({
            ...otherFieldController,
            new: true,
            confirm: true,
          });
          if (isOldPasswordFilled()) {
            setIsButtonDisabled(false);
          } else {
            setIsButtonDisabled(true);
          }
          setIsButtonDisabled(false);
          return;
        }
        setConfirmPasswordError("Passwords don't match.");
        setNewPasswordError("");
        setOtherFieldController({
          ...otherFieldController,
          new: false,
          confirm: false,
        });
        setIsButtonDisabled(true);
        return;
      }
      setNewPasswordError("");
      setOtherFieldController({
        ...otherFieldController,
        new: false,
        confirm: false,
      });
      setIsButtonDisabled(true);
      console.log("valid");
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const confirmpassword = event.target.value;
    onChange(event);
    if (confirmpassword === inputController.newPassword) {
      if (newPasswordError) {
        setIsButtonDisabled(true);
        return;
      }
      setConfirmPasswordError("");
      setOtherFieldController({
        ...otherFieldController,
        confirm: true,
        new: true,
      });
      if (isOldPasswordFilled()) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    } else {
      setConfirmPasswordError("Passwords not match.");
      setOtherFieldController({
        ...otherFieldController,
        confirm: false,
        new: false,
      });
      setIsButtonDisabled(true);
    }
  };

  const sessionExist = async () => {
    const status = await validateSession();
    console.log("session status", status);
    return status;
  };

  const sendOTP = async () => {
    console.log("in send Otp");
    sessionStorage.removeItem("messageRef");
    setIsLoading(true);
    if (!(await sessionExist())) {
      console.log("session extending having trouble");
      setIsLoading(false);
      // setIsFailed(true);
      return;
    }

    let token = localStorage.getItem("jwt");
    if (!token) {
      console.log("token not exist");
      return;
    }

    token = token.replace('"', "").replace('"', "");
    const formData = new FormData();
    formData.append("loginId", jwtDecode(token).user);

    // let data = new FormData();
    // data.append("loginId", jwtDecode(token).user);
    // data.append("oldPassword", inputController.oldPassword);
    // console.log("newData", data);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    //for checking the old password with database
    //const checkOldPassword = new FormData();
    // Create a new FormData object

    const checkOldPassword = new FormData();
    console.log(
      "checkold password append",
      jwtDecode(token).user,
      inputController.oldPassword
    );
    checkOldPassword.append("loginId", jwtDecode(token).user);
    checkOldPassword.append(
      "oldPassword",
      window.btoa(inputController.oldPassword)
    );
    //checkOldPassword.append("loginId", "baijunath");
    console.log("oldPassword Form data here here", checkOldPassword);
    const entriesIterator = checkOldPassword.entries();

    // Loop through the iterator and log the key/value pairs
    for (const [key, value] of entriesIterator) {
      console.log(`${key}: ${value}`);
    }

    axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/officer/update/check/old/password",
        checkOldPassword,
        config
      )
      .then(
        (resp) => {
          console.log("change pasword match resp", resp);
          if (resp.status === 200) {
            axios
              .post(
                process.env.REACT_APP_SERVER_URL +
                  "/officer/update/passsword/send/otp",
                formData,
                config
              )
              .then((resp) => {
                if (resp.status === 200) {
                  setIsLoading(false);
                  //clear form
                  otpMessageRef.current = resp.data.message;
                  navigate("/ccrep/changepassword/otp", {
                    replace: true,
                    state: {
                      data: inputController,
                      purpose: "CHANGEPASSWORDOFCR",
                      messageRef: otpMessageRef,
                    },
                  });
                  return;
                }
                //check the otp msg
                otpMessageRef.current = resp.data.error_message;
                navigate("/ccrep/changepassword/otp", {
                  replace: true,
                  state: {
                    data: inputController,
                    purpose: "CHANGEPASSWORDOFCR",
                    messageRef: otpMessageRef,
                  },
                });
              })
              .catch(function (error) {
                console.log("error otp", error);
                setIsLoading(false);
                otpMessageRef.current = error.response.data.error_message;
                navigate("/ccrep/changepassword/otp", {
                  replace: true,
                  state: {
                    data: inputController,
                    purpose: "CHANGEPASSWORDOFCR",
                    messageRef: otpMessageRef,
                  },
                });
                return;
              });
          }
        },
        (error) => {
          console.log("fdcttttctr");
          setIsLoading(false);
          setMsg("Old Password Not Matched");
          setErrorAlert(true);
          //console.log("error message", error.response.data.error_message);
          console.log("error data this this this", error);
        }
      );
  };

  const handleErrorAlert = () => {
    setErrorAlert(false);
  };

  const verifyPasswordMatch = () => {
    console.log("In jVerify pass");
    console.log("oldPasswordRef", oldPasswordRef.current.value);
    console.log("inputcontroller newPasswor", inputController.newPassword);
    if (oldPasswordRef.current.value === inputController.newPassword) {
      setMsg("Old password and new password must not be same.");
      setErrorAlert(true);
      return true;
    }
    return false;
  };

  const handleChangePassword = () => {
    const object = verifyPasswordMatch();
    if (object === true) {
      console.log("objct true", object);
    } else {
      console.log("objct false", object);
      sendOTP();
    }
    console.log("In handleCahnge");
    if (verifyPasswordMatch()) {
      // old and new password match error
      return;
    }

    // sendOTP();
    //    navigate('/member/changepassword/otpverification', { state: { data: inputController, purpose: 'CHANGEPASSWORD' } })
  };

  const handleCopy = (event) => {
    event.preventDefault();
  };

  const handleKeyDown = (event) => {
    if (event.code === "Space") {
      event.preventDefault();
    }
  };
  return (
    <>
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={errorAlert}
        title="Error"
        body={msg}
        buttonName="Ok"
      />
      <Grid>
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center">
            <Avatar style={{ backgroundColor: "#1bbd7e" }}>
              <FontAwesomeIcon icon={faUserLock} />
            </Avatar>
            <h2>Change Password</h2>
          </Grid>

          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#474E68",
                },
                "&:hover fieldset": {
                  borderColor: "#6B728E",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#0F3460",
                },
              },
            }}
            id="oldPassword"
            inputRef={oldPasswordRef}
            type="password"
            label="Old Password"
            variant="outlined"
            name="oldPassword"
            placeholder="Enter your Old Password"
            inputProps={{ maxLength: 20, onCopy: handleCopy }}
            fullWidth
            required
            onChange={onChange}
            style={{ marginTop: "20px" }}
            value={inputController.oldPassword}
            autoComplete="off"
          ></TextField>

          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#474E68",
                },
                "&:hover fieldset": {
                  borderColor: "#6B728E",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#0F3460",
                },
              },
            }}
            id="newPassword"
            label="New Password"
            type="password"
            variant="outlined"
            name="newPassword"
            placeholder="Enter your New Password"
            fullWidth
            inputProps={{ maxLength: 20, onCopy: handleCopy }}
            autoComplete="off"
            required
            onKeyDown={handleKeyDown}
            error={Boolean(newPasswordError)}
            helperText={Boolean(newPasswordError) ? newPasswordError : ""}
            style={{ marginTop: "20px" }}
            onChange={handleNewPasswordChange}
            value={inputController.newPassword}
          ></TextField>

          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#474E68",
                },
                "&:hover fieldset": {
                  borderColor: "#6B728E",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#0F3460",
                },
              },
            }}
            id="confirmNewPassword"
            label="Confirm New Password"
            type="password"
            name="confirmPassword"
            variant="outlined"
            placeholder="Confirm New Password"
            onKeyDown={handleKeyDown}
            fullWidth
            required
            inputProps={{
              maxLength: 20,
              onCopy: handleCopy,
            }}
            autoComplete="off"
            error={Boolean(confirmPasswordError)}
            helperText={
              Boolean(confirmPasswordError) ? confirmPasswordError : ""
            }
            style={{ marginTop: "20px" }}
            onChange={handleConfirmPasswordChange}
            value={inputController.confirmPassword}
          ></TextField>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isButtonDisabled}
            style={btstyle}
            onClick={handleChangePassword}
          >
            Change Password
          </Button>
        </Paper>
      </Grid>
    </>
  );
};

export default ChangePasswordCCRep;
