import { Grid, Typography, Paper, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

export default function Footer() {
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState("");
  const [isHide, setIsHide] = useState(true);
  const location = useLocation();

  const countFetchApi = async (id) => {
    setIsLoading(true);
    const url =
      process.env.REACT_APP_SERVER_URL + "/increasevisitorcountbeforelogin";

    const requestBody = new FormData();
    requestBody.append("vId", parseInt(id));

    console.log("passed data", requestBody);

    try {
      const response = await axios.post(url, requestBody); // Use axios.post instead of fetch
      const data = response.data.data;
      console.log(response);
      setCount(data.toString());
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("current location", location.pathname);

    if (location.pathname === "/login/member") {
      // setCount by calling member visit count api
      setIsHide(false);
      countFetchApi(1);
      return;
    }

    if (location.pathname === "/login/officer") {
      setIsHide(false);
      countFetchApi(2);
      return;
    }
  }, [location]);

  return (
    <Paper>
      <Box
        sx={{
          backgroundColor: "#333",
          color: "white",
          p: { xs: 2, md: 2 },
        }}>
        <Grid container spacing={2} direction="column" alignContent="center">
          <Grid item>
            <Typography variant="caption">
              Website Content Managed by Coal Mines Provident Fund Organisation
            </Typography>
            <br />
            <Typography variant="caption">
              Designed, Developed and Hosted by Centre for Development of
              Advanced Computing (C-DAC)
            </Typography>
            <Typography variant="subtitle2" align="center">
              Last Updated - 30-April-2024
            </Typography>
            {!isHide && (
              <Typography
                variant="caption"
                align="center"
                sx={{ color: "yellow" }}>
                Visitors:{" "}
                {isLoading ? (
                  <CircularProgress sx={{ color: "white" }} size={13} />
                ) : (
                  count
                )}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
