import React from "react";
import ChangePasswordFirst from "./ChangePasswordPages/ChangePasswordFirst";
import ChangePasswordGuidelines from "./ChangePasswordPages/ChangePasswordGuidelines";

const ChangePassword=()=>{
    return(<div id="change-password">
        <ChangePasswordFirst />
        <ChangePasswordGuidelines />
    
    </div>);
}
export default ChangePassword;