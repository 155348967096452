import { createTheme } from '@mui/material/styles';

const UserGuideTheme = createTheme({
  palette: {
    primary: {
      main: '#032B3D', // Change the primary color to your desired color
    },
    secondary: {
        main: '#FFFFFF', // Your secondary color
      },
  },
  typography: {
    h5: {
      fontWeight: 700, // Customize the font weight for h5 Typography
      textAlign: 'left',
    },
    h6: {
      fontWeight: 600, // Customize the font weight for h6 Typography
      textAlign: 'left',
    },
    body1: {
      textAlign: 'left', // Text alignment for body1 Typography
    },
  },
  
//   overrides: {
//     MuiPaper: {
//       root: {
//         background: 'linear-gradient(0deg, rgba(128,17,59,1) 0%, rgba(146,22,176,1) 62%, rgba(148,23,189,1) 69%, rgba(13,1,39,1) 100%)', // Customize Paper background
//       },
//     },
//     // Add other overrides for components here
//   },
});

export default UserGuideTheme;
