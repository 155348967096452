import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
import TitleText from "../FormComponent/TitleText";
import jwtDecode from "jwt-decode";

function Redirector() {
  const navigate = useNavigate();

  const location = useLocation();

  const member_db_roles = ["MEM"];
  const ccrep_db_roles = ["CCREP"];
  const heads_db_roles = ["CCH"];
  const uda_db_roles = ["UDA"];
  const rh_db_roles = ["RH"];
  const agm_db_roles = ["AGM"];
  const uao_db_roles = ["UAO"];
  const aao_db_roles = ["AAO"];
  const ids_db_roles = ["IDS"];
  const rogo_db_roles = ["ROGO"];
  const ccgo_db_roles = ["CCGO"];
  const gmf_db_roles = ["GMF"];
  const gch_db_roles = ["GCH"];
  const gcm_db_roles = ["GCM"];
  const roda_db_roles = ["DA"];
  const si_db_roles = ["SI"];
  const bo_db_roles = ["BO"];
  const cashier_db_roles = ["CA", "IPDS"];
  const commissioner_db_roles = ["CM", "CVO"];
  //const ipds_db_roles = ["IPDS"];

  //navigate user
  const navigateUser = () => {
    const token = localStorage.getItem("jwt");
    if (!token) {
      navigate("/cmpfo", { replace: true });
      return;
    }

    const data_decoded = jwtDecode(token);
    if (member_db_roles.includes(data_decoded.role)) {
      navigate("/member", { replace: true });
      return;
    }
    if (ccrep_db_roles.includes(data_decoded.role)) {
      navigate("/ccrep", { replace: true });
      return;
    }
    if (heads_db_roles.includes(data_decoded.role)) {
      navigate("/heads", { replace: true });
      return;
    }
    if (uda_db_roles.includes(data_decoded.role)) {
      navigate("/uda", { replace: true });
      return;
    }
    if (rh_db_roles.includes(data_decoded.role)) {
      navigate("/rh", { replace: true });
      return;
    }
    if (agm_db_roles.includes(data_decoded.role)) {
      navigate("/agm", { replace: true });
      return;
    }
    if (uao_db_roles.includes(data_decoded.role)) {
      navigate("/uao", { replace: true });
      return;
    }
    if (aao_db_roles.includes(data_decoded.role)) {
      navigate("/aao", { replace: true });
      return;
    }
    if (gcm_db_roles.includes(data_decoded.role)) {
      navigate("/gcm", { replace: true });
      return;
    }
    if (ids_db_roles.includes(data_decoded.role)) {
      navigate("/ids", { replace: true });
      return;
    }
    if (roda_db_roles.includes(data_decoded.role)) {
      navigate("/roda", { replace: true });
      return;
    }
    if (gch_db_roles.includes(data_decoded.role)) {
      navigate("/gch", { replace: true });
      return;
    }
    if (gch_db_roles.includes(data_decoded.role)) {
      navigate("/gcm", { replace: true });
      return;
    }
    if (rogo_db_roles.includes(data_decoded.role)) {
      navigate("/rogo", { replace: true });
      return;
    }
    if (ccgo_db_roles.includes(data_decoded.role)) {
      navigate("/ccgo", { replace: true });
      return;
    }
    if (si_db_roles.includes(data_decoded.role)) {
      navigate("/si", { replace: true });
      return;
    }
    if (bo_db_roles.includes(data_decoded.role)) {
      navigate("/bo", { replace: true });
      return;
    }
    if (bo_db_roles.includes(data_decoded.role)) {
      navigate("/gmf", { replace: true });
      return;
    }

    if (cashier_db_roles.includes(data_decoded.role)) {
      navigate("/cr", { replace: true });
      return;
    }

    if (commissioner_db_roles.includes(data_decoded.role)) {
      navigate("/cm", { replace: true });
      return;
    }

    // if (cashier_db_roles.includes(data_decoded.role)) {
    //   navigate("/ipds", { replace: true });
    //   return;
    // }

    navigate("/cmpfo", { replace: true });
  };

  useEffect(() => {
    navigateUser();
  }, []);

  return (
    <Backdrop open={true} style={{ zIndex: 9999, backgroundColor: "#fff" }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        direction="column"
      >
        <Grid item>
          <CircularProgress color="primary" />
        </Grid>
        <Grid item>
          <TitleText title="Initialising Dashboard" />
        </Grid>
      </Grid>
    </Backdrop>
  );
}

export default Redirector;
