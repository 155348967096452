import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import LabelText from "../../FormComponent/LabelText";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import FormDivider from "../../FormComponent/FormDivider";
import TitleText from "../../FormComponent/TitleText";
import SubtitleText from "../../FormComponent/SubtitleText";
import CustomTextField from "../../FormComponent/CustomTextField";
import { relativeTimeRounding } from "moment";
import Upload_Documents from "../../SettlementComponents/UploadDocument";
import ValueText from "../../FormComponent/ValueText";
import { setCacheNameDetails } from "workbox-core";
import axios from "axios";

import CaptionText from "../../FormComponent/CaptionText";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import SucessAlert from "../../FormComponent/SuccessAlert";
import UploadDocuments from "./../../SettlementComponents/UploadDocument";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { dateConvert, trimStringUtility } from "../../Utility/UtilityMethods";
import { getToken } from "../../UiManager/SessionChecker";
import ConfirmationDialogue from "../../FormComponent/ConfirmationDialogue";
//import {checkNameMatch} from "../../Utility/CheckerMethods"

function ClaimFormPensionPrefilled(props) {
  const { isLoading, setIsLoading } = "";

  const { memberData, nomineeDetails, claimDetails, additionalData, nameData } =
    props;
  console.log("data for normal details", claimDetails);
  console.log("data for all claim details", additionalData);
  console.log("data for names and salary", nameData);

  // state for checkbox for the claimant and member same
  const [claimantSelf, setClaimantSelf] = useState(true);
  // state for death case
  const [deathCase, setdeathCase] = useState(false);

  /***********for dropdown lists*************** */
  // state for settlement para case

  const [claimTypeData, setClaimTypeData] = useState([]);
  const [settlementPara, setSettlementPara] = useState([]);
  const [claimTypeOptions, setClaimTypeOptions] = useState([]);
  // to store selected claimant data
  const [selectedClaimant, setSelectedClaimant] = useState({});
  // ******* for declaration ******
  const [checked, setChecked] = useState(false);
  //docs
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [fileSizeExceed, setFileSizeExceed] = useState(false);

  /***********for service records and aadhaar pan not matching*************** */
  const [recordErrors, setRecordErrors] = useState(false);
  const [recordErrorMessage, setRecordErrorMessage] = useState("");
  const [errorTitle, setErrorTitle] = useState("");

  /***********for submit failed*************** */
  const [isFailed, setIsFailed] = useState(false);
  const [failedErrorMessage, setFailedErrorMessage] = useState("");

  /***********for submit success*************** */
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [submitSuccessMsg, setSubmitSuccessMsg] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  /**************others************** */
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [showingDropdown, setShowingDropdown] = useState(0);
  const [assignToOption, setAssignToOption] = useState([]);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [validationFailed, setValidationFailed] = useState(false);
  const [validationFailedMsg, setValidationFailedMsg] = useState("");
  const [documentTypes, setDocumentTypes] = useState([]);
  const [selectedOfficerName, setSelectedOfficerName] = useState("");

  // dummyAccountnum
  //const dummyNo = "HYD/170/840";
  //let token = localStorage.getItem("jwt");

  //  if (!token) {
  //    setIsFailed(true);
  //    setIsLoading(false);
  //    return;
  //  }
  //
  //token = token.replace('"', "").replace('"', "");
  //let loginId = jwtDecode(token).user; // Assuming a default loginId

  //console.log("loooooooooooooooooooooginId",loginId);

  const [loginId, setLoginId] = useState();

  // // dummyAccountnum
  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (token) {
      token = token.replace('"', "").replace('"', "");
      let loginId = jwtDecode(token).user; // Assuming a default loginId
      setLoginId(loginId);
      console.log("loooooooooooooooooooooginId", loginId);
      // Add any further logic here based on the loginId
    } else {
      // Handle the case when token is not present
      setIsFailed(true);
      setIsLoading(false);
    }
  }, []);
  // const loginId = "UDA-CC-OFF-3052";

  // transfer the default object to model.js after confirming the object with backend
  const upto89yr =
    additionalData && additionalData.serviceYearsUpto89
      ? additionalData.serviceYearsUpto89
      : null;
  const upto89mo =
    additionalData && additionalData.serviceMonthsUpto89
      ? additionalData.serviceMonthsUpto89
      : null;
  const after89yr =
    additionalData && additionalData.serviceYearsAfter89
      ? additionalData.serviceYearsAfter89
      : null;
  const after89mo =
    additionalData && additionalData.serviceMonthsAfter89
      ? additionalData.serviceMonthsAfter89
      : null;
  // const claimant =  additionalData && additionalData.serviceMonthsAfter89 ? additionalData.serviceMonthsAfter89 : 0;
  const paraid =
    additionalData && additionalData.settlementParaId
      ? additionalData.settlementParaId
      : null;
  const claimantid =
    claimDetails && claimDetails.claimantId > 0 ? claimDetails.claimantId : 0;
  console.log("claimanttt", claimantid);

  const cessationdate =
    additionalData && additionalData.dateOfCessation
      ? additionalData.dateOfCessation
      : null;
  const salary1 = nameData.salary_1;
  const salary2 = nameData.salary_2;
  const salary3 = nameData.salary_3;
  const salary4 = nameData.salary_4;
  const salary5 = nameData.salary_5;
  const salary6 = nameData.salary_6;
  const salary7 = nameData.salary_7;
  const salary8 = nameData.salary_8;
  const salary9 = nameData.salary_9;
  const salary10 = nameData.salary_10;

  const Salary = [
    salary1,
    salary2,
    salary3,
    salary4,
    salary5,
    salary6,
    salary7,
    salary8,
    salary9,
    salary10,
  ];

  const defaultClaim = {
    toLoginId: "",
    claimant_name: claimantid,
    claim_type: paraid,
    mem_first_name_aadhar: nameData.firstNameAdhaar,
    mem_middle_name_aadhar: nameData.middleNameAdhaar,
    mem_last_name_aadhar: nameData.lastNameAdhaar,
    mem_first_name_bank: nameData.firstNameBank,
    mem_middle_name_bank: nameData.middleNameBank,
    mem_last_name_bank: nameData.lastNameBank,
    mem_first_name_pan: nameData.firstNamePan,
    mem_middle_name_pan: nameData.middleNamePan,
    mem_last_name_pan: nameData.lastNamePan,
    mem_dob_aadhar: claimDetails.dobInAadhaar,
    mem_dob_pan: claimDetails.dobInPan,
    relation: claimDetails.sfwhRelationType,
    fhw_name: claimDetails.sfwhName,
    service_upto_31031989_yrs: parseInt(upto89yr),
    service_upto_31031989_mnths: parseInt(upto89mo),
    service_from_01041989_yrs: parseInt(after89yr),
    service_from_01041989_mnths: parseInt(after89mo),
    date_of_cessation: cessationdate,
  };
  const claimTypeList = [
    { id: "1", title: "Alive: Superannuation (>30 years of service)" },
    { id: "2", title: "Alive: Superannuation (<30 years of service)" },
    { id: "3", title: "Alive: Resign/VRS (Resignation >10 years of service)" },
    {
      id: "4",
      title:
        "Alive: One Time Payment (<10 years of service) (>1 year pensionable service)",
    },
    {
      id: "5",
      title:
        "Alive: Disablement Pension (Medical Unfit) (<10 years of service)",
    },
    {
      id: "6",
      title: "Death Case: (In Service) - Two wives of deceased member",
    },
    {
      id: "7",
      title:
        "Death Case: To wife/husband (Death of member after cessation of his/her service)",
    },
    {
      id: "8",
      title: "Death Case: To wife/husband (Death of member in service)",
    },
    {
      id: "9",
      title: "Death Case: Children Pension - Son/Daughter",
    },
  ];

  const relationList = [
    { id: "1", title: "Wife" },
    { id: "2", title: "Son" },
    { id: "3", title: "Daughter" },
  ];

  //   const claimantNameList = [
  //     { id: "1", title: "Self" },
  //     { id: "2", title: "xyz" },
  //     { id: "3", title: "abc" },

  const [claim, setClaim] = useState(defaultClaim);

  //   ];
  const claimantNameList = nomineeDetails.map((member) => ({
    id: member.familyMemId,
    title: member.name,
  }));

  claimantNameList.push({ id: 0, title: "Self" });

  const claimant_name = {
    name: "claimant_name",
    placeholder: "Claimant Name",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: claim.claimant_name,
    required: true,
    //options: [{ id: '1', title: "Hindu" }, { id: '2', title: 'Christian' }, { id: '3', title: 'Muslim' }, { id: '4', title: 'Jain' }, { id: '5', title: 'Sikh' }, { id: 6, title: 'Buddhist' }, { id: 7, title: 'Other' }],
    options: claimantNameList,
  };

  const claim_type = {
    name: "claim_type",
    placeholder: "Select Claim Type",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: claim.claim_type,
    required: true,
    //options: [{ id: '1', title: "Hindu" }, { id: '2', title: 'Christian' }, { id: '3', title: 'Muslim' }, { id: '4', title: 'Jain' }, { id: '5', title: 'Sikh' }, { id: 6, title: 'Buddhist' }, { id: 7, title: 'Other' }],
    //options: claimTypeList,
    options: claimTypeOptions,
  };
  //   const relation_type = {
  //     name: "relation_type",
  //     placeholder: "Relation with Member",
  //     //label: "Select CC",
  //     helperText: "",
  //     //disabled: readOnly,
  //     initialValue: claim.relation_type,
  //     required: true,
  //     //options: [{ id: '1', title: "Hindu" }, { id: '2', title: 'Christian' }, { id: '3', title: 'Muslim' }, { id: '4', title: 'Jain' }, { id: '5', title: 'Sikh' }, { id: 6, title: 'Buddhist' }, { id: 7, title: 'Other' }],
  //     options: relationList,
  //   };
  const relation = {
    name: "relation",
    placeholder: "Select Relation",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: claim.relation,
    required: true,
    options: [
      { id: "1", title: "W/O" },
      { id: "2", title: "D/O" },
      { id: "3", title: "S/O" },
      { id: "4", title: "H/O" },
    ],
    //options: claimTypeList,
  };

  const mem_first_name_aadhar = {
    name: "mem_first_name_aadhar",
    type: "text",
    helperText: "",
    placeholder: "First Name",
    defaultValue: claim.mem_first_name_aadhar,
    errorMessage: "Invalid",
    pattern: /^[A-Za-z. ]+$/,
    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const mem_middle_name_aadhar = {
    name: "mem_middle_name_aadhar",
    type: "text",
    helperText: "",
    placeholder: "Middle Name",
    defaultValue: claim.mem_middle_name_aadhar,
    errorMessage: "Invalid",
    pattern: /^[A-Za-z. ]+$/,
    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_last_name_aadhar = {
    name: "mem_last_name_aadhar",
    type: "text",
    helperText: "",
    placeholder: "Last Name",
    defaultValue: claim.mem_last_name_aadhar,
    errorMessage: "Invalid",
    pattern: /^[A-Za-z. ]+$/,
    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_first_name_pan = {
    name: "mem_first_name_pan",
    type: "text",
    helperText: "",
    placeholder: "First Name",
    defaultValue: claim.mem_first_name_pan,
    errorMessage: "Invalid",
    pattern: /^[A-Za-z. ]+$/,
    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_middle_name_pan = {
    name: "mem_middle_name_pan",
    type: "text",
    helperText: "",
    placeholder: "Middle Name",
    defaultValue: claim.mem_middle_name_pan,
    errorMessage: "Invalid",
    pattern: /^[A-Za-z. ]+$/,
    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_last_name_pan = {
    name: "mem_last_name_pan",
    type: "text",
    helperText: "",
    placeholder: "Last Name",
    defaultValue: claim.mem_last_name_pan,
    errorMessage: "Invalid",
    pattern: /^[A-Za-z. ]+$/,
    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_first_name_bank = {
    name: "mem_first_name_bank",
    type: "text",
    helperText: "",
    placeholder: "First Name",
    defaultValue: claim.mem_first_name_bank,
    errorMessage: "Invalid",
    pattern: /^[A-Za-z. ]+$/,
    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_middle_name_bank = {
    name: "mem_middle_name_bank",
    type: "text",
    helperText: "",
    placeholder: "Middle Name",
    defaultValue: claim.mem_middle_name_bank,
    errorMessage: "Invalid",
    pattern: /^[A-Za-z. ]+$/,
    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_last_name_bank = {
    name: "mem_last_name_bank",
    type: "text",
    helperText: "",
    placeholder: "Last Name",
    defaultValue: claim.mem_last_name_bank,
    errorMessage: "Invalid",
    pattern: /^[A-Za-z. ]+$/,
    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const mem_dob_aadhar = {
    name: "mem_dob_aadhar",
    type: "date",
    helperText: "",
    placeholder: "Select Date of Birth",
    defaultValue: claim.mem_dob_aadhar,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const mem_dob_pan = {
    name: "mem_dob_pan",
    type: "date",
    helperText: "",
    placeholder: "Select Date of Birth",
    defaultValue: claim.mem_dob_pan,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const fhw_name = {
    name: "fhw_name",
    type: "text",
    helperText: "",
    placeholder: "Father/Husband/Wife Name",
    defaultValue: claim.fhw_name,
    errorMessage: "Invalid",
    pattern: /^[A-Za-z. ]+$/,

    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const date_of_cessation = {
    name: "date_of_cessation",
    type: "date",
    helperText: "",
    placeholder: "Date of Cessation",
    defaultValue: claim.date_of_cessation,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const date_of_death = {
    name: "date_of_death",
    type: "date",
    helperText: "",
    placeholder: "Date of Death",
    defaultValue: claim.date_of_death,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const date_of_birth_claimant = {
    name: "date_of_birth_claimant",
    type: "date",
    helperText: "",
    placeholder: "Dob claimant",
    defaultValue: "",
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const para = {
    name: "para",
    type: "text",
    helperText: "",
    placeholder: "Option Under para 15",
    defaultValue: "",
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const service_upto_31031989_yrs = {
    name: "service_upto_31031989_yrs",
    type: "number",
    helperText: "",
    placeholder: "Enter Years",
    defaultValue: claim.service_upto_31031989_yrs,
    errorMessage: "Invalid",
    pattern: /^[0-9]+$/,
    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const service_upto_31031989_mnths = {
    name: "service_upto_31031989_mnths",
    type: "text",
    helperText: "",
    placeholder: "Enter Months",
    defaultValue: claim.service_upto_31031989_mnths,
    errorMessage: "Invalid",
    transformInput: trimStringUtility,

    pattern: /^[0-9]+$/,
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const service_from_01041989_yrs = {
    name: "service_from_01041989_yrs",
    type: "text",
    helperText: "",
    placeholder: "Enter Years",
    defaultValue: claim.service_from_01041989_yrs,
    errorMessage: "Invalid",
    pattern: /^[0-9]+$/,
    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const service_from_01041989_mnths = {
    name: "service_from_01041989_mnths",
    type: "text",
    helperText: "",
    placeholder: "Enter Months",
    defaultValue: claim.service_from_01041989_mnths,
    errorMessage: "Invalid",
    pattern: /^[0-9]+$/,
    transformInput: trimStringUtility,

    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const navigate = useNavigate();

  const toLoginId = {
    name: "toLoginId",
    placeholder: "Select Officer",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: claim.toLoginId,
    required: true,
    options: assignToOption,
  };
  const lastMonthsSalarydefault = {};

  for (let i = 1; i <= 10; i++) {
    const key = `salary_${i}`;
    lastMonthsSalarydefault[key] = ""; // initial value is
  }

  const [lastMonthsSalary, setLastMonthsSalary] = useState(
    lastMonthsSalarydefault
  );
  const [averageEmoluments, setAverageEmoluments] = useState(0);
  /// creating the props to send to Salary textfields
  const salaryObjects = [];
  const { cmpfAccNo } = props;

  for (let i = 0; i <= 10; i++) {
    const salary = {
      name: `salary_${i}`,
      type: "text",
      helperText: "",
      placeholder: `Month ${i} Salary`,
      defaultValue: claim[`salary_${i}`],
      errorMessage: "Invalid",
      pattern: /^[0-9]+$/,
      transformInput: trimStringUtility,

      // required: ccEnrolmentMandatoryFields.includes("off_email"),
      // disabled: readOnly,
    };

    salaryObjects.push(salary);
  }

  /// creating an array for salary object validation this array will be appended to the required and validation objects while validation
  const salaryObjectsForValidation = [];

  for (let i = 1; i <= 10; i++) {
    const salaryValidation = {
      field: `Month ${i} Salary`,
      value: lastMonthsSalary[`salary_${i}`],
      pattern: /^[0-9]+$/,
      // required: ccEnrolmentMandatoryFields.includes("off_email"),
      // disabled: readOnly,
    };

    // console.log("salaryObjectsForValidation", salaryObjectsForValidation);

    salaryObjectsForValidation.push(salaryValidation);
  }
  // function to handle onChange of Salary
  const onChangeSalary = (e) => {
    setLastMonthsSalary({
      ...lastMonthsSalary,
      [e.target.name]: e.target.value,
    });
  };
  console.log(lastMonthsSalary);

  useEffect(() => {
    console.log(lastMonthsSalary);

    const newTotalSalary = calculateTotalSalary(lastMonthsSalary);
    console.log("totalS6666", newTotalSalary);

    setAverageEmoluments(newTotalSalary / 10);
  }, [lastMonthsSalary]);

  const calculateTotalSalary = (salaryObj) => {
    return Object.values(salaryObj).reduce(
      (total, current) => total + (current === "" ? 0 : parseInt(current)),
      0
    );
  };

  // checkbox handler
  const handleChange = (event) => {
    setChecked(event.target.checked);
    console.log("lastMonthsSalary", lastMonthsSalary);
  };

  // Handler function to update the DOCS from child
  const handleStateUpdate = (documentData) => {
    const requestBody = {
      cmpfAcNo: cmpfAccNo,
      uploadAdvPfDocumentsDTO: documentData.map((upload) => ({
        fileContent: upload.uploadedDocument,
        docTypeId: upload.selectedDocumentType,
      })),
    };
    setUploadedDocs(requestBody.uploadAdvPfDocumentsDTO);
    console.log("requestBody", requestBody);
  };
    // function to check wheater the genuniess by management certificate is present or not if the name is not matching wiht aadhar
 const checkGenCertificate = () => {
    console.log("uploadedDocs", uploadedDocs);
    
    return uploadedDocs.some((docs) => {
        console.log("mapped docs", docs);
        return docs.docTypeId === 25;
    });
}
  // handler function for fileExcced child upload docs
  const handleFileSizeUpdate = (newState) => {
    setFileSizeExceed(newState);
  };
  const handleErrorAlert = () => {
    setIsError(false);
  };

  const handleValidationFailedAlert = () => {
    setValidationFailed(false);
    setIsLoading(false);
  };
  const handleSubmitOk = () => {
    if (!checked) {
      // Show message in dialog: "Please select check Box"
      // alert("Please select the check box");
      setIsError(true);
      setErrorMessage("Please Select the Declaration Checkbox.");

      return;
    }
    if (showingDropdown === 1) {
      if (!claim.toLoginId) {
        // Show message in dialog: "Please select an officer to assign"
        // alert("Please select an officer to assign");
        setIsError(true);
        setErrorMessage("Please Select an Officer to Assign.");
        return;
      }
    }
    if (checkDocuments()) {
      if (checked === false) {
        setRecordErrors(true);
        setRecordErrorMessage("Please Check the declaration");
        setErrorTitle("Declaration not Selected");
        // setIsSubmitting(false);

        return;
      }
    }
    if (!validate()) {
      console.log("validation falied");
      return;
    }
    // if (nameCheck() && dobCheck()) {
        if (nameCheck()) {
      setIsSubmitClicked(true);
      // if (!claimantSelf) {
      //   if (checkEligibility(selectedClaimant.relationName)) {
      // const url =
      //   process.env.REACT_APP_SERVER_URL + "/submit/pf/claim/settlement/uda";
      // const headers = {
      //   //'Authorization': `Bearer ${token}`,
      //   "Content-Type": "application/json",
      // };
      // axios
      //   .post(url, formData, { headers })
      //   .then(function (response) {
      //     console.log("Submit response", response);
      //     postUploadedDoc();
      //   })
      //   .catch((error) => {
      //     console.log("error on submit", error);
      //     setIsFailed(true);
      //     setFailedErrorMessage("Something went Wrong!!");
      //     //   });
      //     setIsSubmitClicked(true);
      //   } else {
      //     setRecordErrors(true);
      //     setErrorTitle("Eligiblity conditions not met!!");
      //     // setRecordErrorMessage(
      //     //   "Eligiblity conditions not met. Please check the nominee details."
      //     // );
      //   }
      // } else {
      //   setIsSubmitClicked(true); // do not apply eligiblity checks if the claimant is self
      // }
    } else {
      // setRecordErrors(true);
      // setErrorTitle("Service Record Mismatch");
      // setRecordErrorMessage(
      //   "Entered Data does not match with service records please recheck or update the member details"
      // );
         //*** check the certificate of geniuness if names are not matching  */
      if(checkGenCertificate()) {
        console.log("checkGenCertificate",checkGenCertificate())
        setIsSubmitClicked(true);
      }
      else {
      setRecordErrors(true);
      setErrorTitle("Genuineness Certificate not Uploaded");
      setRecordErrorMessage(
        "Name entered does not match with the service records therefore it is mandatory to upload the Genuineness Certificate for Member's name "
      );

      }
    }
  };

  // todo handleAssignToOk calling handle submit on click of ok confirmation dialouge box
  const handleAssignToOk = (e) => {
    handleSubmit(e);
    setIsSubmitClicked(false);
  };

  const handleAssignToCancel = () => {
    setIsSubmitClicked(false);
  };

  // const documentTypes = [
  //   { id: "1", title: "Application in SAHAJ Form" },
  //   { id: "2", title: "Advance Stamped Receipt" },
  //   { id: "3", title: "Copy of S.B. A/c Pass Book (F & S in case of Alive)" },
  //   {
  //     id: "4",
  //     title:
  //       "Contribution Details (PFC, FPC, 2%, Additional Increment & 7% Pension Contribution)",
  //   },
  //   { id: "5", title: "Form-YY for broken period contribution." },
  //   {
  //     id: "6",
  //     title:
  //       "Copy of Office Order for cessation of service (Termination Order)",
  //   },
  //   {
  //     id: "7",
  //     title: "Form-O of management/company in case of Medical Unfit.",
  //   },
  //   { id: "8", title: "Ref-4" },
  //   { id: "9", title: "Death Certificate of the Member" },
  //   {
  //     id: "10",
  //     title: "Schedule-C (Form Ref-5). (If nomination is not available)",
  //   },
  //   { id: "11", title: "Nomination Form – A" },
  //   {
  //     id: "12",
  //     title:
  //       "Copy of S.B. A/c Pass Book (In case of minor of Guardian with child) (F & S in case of Alive)",
  //   },
  //   { id: "13", title: "Joint Photograph of Claimant with Minor" },
  //   {
  //     id: "14",
  //     title: "Genuineness Certificate in leiu of 3 yrs delay in PF Claim",
  //   },
  // ];

  // useEffect to set the value of the textFeild when the checkbox is clicked
  //   useEffect(() => {
  //     if (claimantSelf) {
  //       // Set the name state to the value received as a prop when the checkbox is checked
  //       setClaim({ ...claim, claimant_name: memberData.fullName });
  //     } else {
  //       setClaim({ ...claim, claimant_name: "" });
  //     }
  //   }, [claimantSelf]);

  // useEffect to set the value of the textFeild when the checkbox is clicked
  //************************validations************************** */
  const patternChecker = (param, pattern) => {
    //console.log("param in patten cheker", param);
    return pattern.test(param);
  };

  const validate = () => {
    // when the ro enrollment begins changed are needed in the field
    console.log("Claimant Name", claim.claimant_name);
    const requiredFieldsPension = [
      { field: "Claimant Name", value: claim.claimant_name.toString() },
      { field: "Cause of Cessation", value: claim.claim_type },
      { field: "Date of Cessation", value: claim.date_of_cessation },
      {
        field: "First Name as per Aadhaar",
        value: claim.mem_first_name_aadhar,
      },
      {
        field: "First Name as per Bank",
        value: claim.mem_first_name_bank,
      },
      {
        field: "First Name as per pan",
        value: claim.mem_first_name_pan,
      },
      {
        field: "Date of birth as per Aadhaar",
        value: claim.mem_dob_aadhar,
      },
      {
        field: "Date of birth as per PAN",
        value: claim.mem_dob_pan,
      },

      {
        field: "Date of birth as per PAN",
        value: claim.mem_dob_pan,
      },

      //** pension feilds are differnt form here */

      {
        field: "Service upto 31 August 1989 in years ",
        value: claim.service_upto_31031989_yrs,
      },
      {
        field: "Service upto 31 August 1989 in months",
        value: claim.service_upto_31031989_mnths,
      },
      {
        field: "Service from 01 April 1989 in years",
        value: claim.service_from_01041989_yrs,
      },
      {
        field: "Service from 01 April 1989 in months",
        value: claim.service_from_01041989_mnths,
      },
    ]; // this is an intermediate array which is appended with the salary objects array for validation

    const requiredFields = [
      ...requiredFieldsPension,
      ...salaryObjectsForValidation,
    ];

    const fieldsToValidatePension = [
      {
        field: "First Name as per Aadhaar",
        value: claim.mem_first_name_aadhar,
        pattern: /^[A-Za-z. ]+$/,
      },
      {
        field: "First Name as per PAN",
        value: claim.mem_first_name_pan,
        pattern: /^[A-Za-z. ]+$/,
      },
      {
        field: "First Name as per Bank",
        value: claim.mem_first_name_pan,
        pattern: /^[A-Za-z. ]+$/,
      },
      {
        field: " Middle Name as per Aadhaar",
        value: claim.mem_middle_name_aadhar,
        pattern: /^[A-Za-z. ]+$/,
      },
      {
        field: "Middle Name as per PAN",
        value: claim.mem_middle_name_pan,
        pattern: /^[A-Za-z. ]+$/,
      },
      {
        field: "Middle Name as per Bank",
        value: claim.mem_middle_name_pan,
        pattern: /^[A-Za-z. ]+$/,
      },
      {
        field: "Father/Husband/Wife Name",
        value: claim.fhw_name,

        pattern: /^[A-Za-z. ]+$/,
      },
      {
        field: "Service upto 31 August 1989 in years ",
        value: claim.service_upto_31031989_yrs,
        pattern: /^[0-9]+$/,
      },
      {
        field: "Service upto 31 August 1989 in months",
        value: claim.service_upto_31031989_mnths,
        pattern: /^[0-9]+$/,
      },
      {
        field: "Service from 01 April 1989 in years",
        value: claim.service_from_01041989_yrs,
        pattern: /^[0-9]+$/,
      },
      {
        field: "Service from 01 April 1989 in months",
        value: claim.service_from_01041989_mnths,
        pattern: /^[0-9]+$/,
      },
    ]; // this is an intermidiate array

    const fieldsToValidate = [
      ...fieldsToValidatePension,
      ...salaryObjectsForValidation,
    ];

    for (const fieldData of requiredFields) {
      const { field, value } = fieldData;

      if (!value) {
        console.log(`${field} is required.`);
        setValidationFailed(true);
        setValidationFailedMsg(`${field} is required.`);
        return false; // Return false if any required field is empty
      }
    }

    for (const fieldData of fieldsToValidate) {
      const { field, value, pattern } = fieldData;

      if (value && pattern && !patternChecker(value, pattern)) {
        // console.log("validate values", typeof value);
        console.log(`${field} has invalid values. Please Check.`);
        setValidationFailed(true);
        setValidationFailedMsg(`${field} has invalid values. Please Check.`);
        return false; // If validation fails for any field, return false
      }
    }

    return true; // If all required fields are non-empty and patterns are valid, return true
  };

  //****************Dropdown******************* */

  useEffect(() => {
    // fetchOfficers();
    console.log("this useEffect is working.....");
  }, []);

  const fetchAssignOfficer = async () => {
    try {
      console.log("in oooooooooooooooooooooo");

      // setIsLoading(true);
      let token = getToken();
      console.log("token on udaaaa", token);
      if (!token) {
        // setIsLoading(false);
        //setIsFailed(true);
        return;
      }
      token = token.replace('"', "").replace('"', "");
      const loginId = jwtDecode(token).user;
      console.log("loginId on udaaa", loginId);
      // if (!(await sessionExist())) {
      //   setIsFailed(true);
      //   setIsLoading(false);
      //   return;
      // }
      // console.log("tokensssssssssssssssssss");
      // let token = localStorage.getItem("jwt");

      // if (!token) {
      //   setIsFailed(true);
      //   setIsLoading(false);
      //   return;
      // }

      // token = token.replace('"', "").replace('"', "");
      // const loginId = jwtDecode(token).user;
      // console.log("loginId", loginId);
      //const loginId = "UDA-CC-OFF-101";
      const url =
        process.env.REACT_APP_SERVER_URL +
        "/dropdown/for/show/next/authorized/offcr/adv/pf/pen/uda";
      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      const options = data.map((option) => ({
        id: option.toOfficerlogin,
        title: `${option.officerName} (${option.toOfficerlogin})`,
      }));
      console.log("options", options);
      setAssignToOption(options); // Assuming setAssignToOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };
  console.log("added docs", uploadedDocs);
  console.log("fileSize", fileSizeExceed);
  console.log("assignToOption", assignToOption);

  ///************document to upoad fetch******************************** */

  const fetchDocumentsToUploadList = async () => {
    const url =
      process.env.REACT_APP_SERVER_URL + "/get/pf/claim/mandatory/documents";

    axios.get(url).then((response) => {
      const documentList = response.data.data;
      console.log("response in documentList", response);

      const options = documentList.map((option) => ({
        id: option.id, // change here after integration
        title: option.title,
      }));
      setDocumentTypes(options);

      console.log("document types", options);
      // setClaimTypeOptions(options);
    });
  };

  const fetchOfficers = () => {
    console.log("in fetch offficers");
    fetchAssignOfficer();
  };

  useEffect(() => {
    // fetchAssignOfficer();
    fetchOfficers();
    console.log("calling fetchofficers");
    fetchDocumentsToUploadList();
  }, []);
  //***********Integrations functions***************** */
  /**
   * TODO: change the api here as soon as created need and he alive or death case check and do
   */
  const fetchClaimTypes = async () => {
    const url =
      process.env.REACT_APP_SERVER_URL +
      "/get/all/pen/claim/type/settlement/para";

    axios.get(url).then((response) => {
      const claimSamples = response.data.data;
      console.log("response in claimList", response);

      let options = [];
      if (!claimantSelf) {
        // If claimantSelf is true
        options = claimSamples
          .filter(
            (option) =>
              option.claimTypeId === 1 ||
              option.claimTypeId === 2 ||
              option.claimTypeId === 3 ||
              option.claimTypeId === 4 ||
              option.claimTypeId === 5
          ) // Filter based on claimTypeId 1 or 2
          .map((option) => ({
            id: option.claimTypeId,
            title: option.claimTypDesc,
          }));
      } else {
        // If claimantSelf is false
        options = claimSamples
          .filter(
            (option) =>
              option.claimTypeId !== 6 &&
              option.claimTypeId !== 7 &&
              option.claimTypeId !== 8 &&
              option.claimTypeId !== 9 &&
              option.claimTypeId !== 9
          ) // Filter based on claimTypeId other than 1 and 2
          .map((option) => ({
            id: option.claimTypeId,
            title: option.claimTypDesc,
          }));
      }

      setClaimTypeData(response.data.data);
      console.log("claim List", options);
      setClaimTypeOptions(options);
    });
  };
  // onChange for all textfeilds
  //   const onChange = (e) => {
  //     setClaim({ ...claim, [e.target.name]: e.target.value });
  //   };

  //*********function to check the name in document proofs vs service records********* */
  function checkNameMatch(fullName, firstName, middleName, lastName) {
    const fullNameParts = fullName.split(" ");
    const first = firstName ? firstName.toLowerCase() : "";
    const middle = middleName ? middleName.toLowerCase() : "";
    const last = lastName ? lastName.toLowerCase() : "";

    const firstMatch =
      first === "" ||
      fullNameParts.filter((name) => name.toLowerCase() === first).length === 1;
    const middleMatch =
      middle === "" ||
      fullNameParts.filter((name) => name.toLowerCase() === middle).length ===
        1;
    const lastMatch =
      last === "" ||
      fullNameParts.filter((name) => name.toLowerCase() === last).length === 1;
    console.log("inside name check", firstMatch, middleMatch, lastMatch);

    return firstMatch && middleMatch && lastMatch;
  }
  /**** checking the names ******/
  function nameCheck() {
    const fullName = memberData.fullName;
    const aadhaarCheck = checkNameMatch(
      fullName,
      claim.mem_first_name_aadhar,
      claim.mem_middle_name_aadhar,
      claim.mem_last_name_aadhar
    );
    const panCheck = checkNameMatch(
      fullName,
      claim.mem_first_name_pan,
      claim.mem_middle_name_pan,
      claim.mem_last_name_pan
    );
    const bankStateCheck = checkNameMatch(
      fullName,
      claim.mem_first_name_bank,
      claim.mem_middle_name_bank,
      claim.mem_last_name_bank
    );

    return aadhaarCheck && panCheck && bankStateCheck;
  }

  /*********function to check the dates enterd*********/
  function checkDateOfBirthMatch(enteredDate, givenDate) {
    if (enteredDate) {
      // const enteredDateParts = enteredDate.split("-");
      // const givenDateParts = givenDate.split("-");
      console.log("givendate,entereddate", givenDate, enteredDate);
      const enteredDateParts = enteredDate.split("-");
      const givenDateParts = givenDate.split("-");

      const enteredDateObj = new Date(
        enteredDateParts[2],
        enteredDateParts[1] - 1,
        enteredDateParts[0]
      );
      const givenDateObj = new Date(
        givenDateParts[2],
        givenDateParts[1] - 1,
        givenDateParts[0]
      );

      if (enteredDateObj.getTime() === givenDateObj.getTime()) {
        return true; // Date of birth matches
      } else {
        return false; // Date of birth does not match
      }
    } else {
      return false;
    }
  }

  /*****************checking dob from aadhaar and pan******************/
  const dateOptions = { day: "2-digit", month: "2-digit", year: "numeric" };
  function dobCheck() {
    const memberDob = new Date(memberData.dateOfBirth)
      .toLocaleDateString("en-GB", dateOptions)
      .split("/")
      .reverse()
      .join("-");
    const aadhaarDobCheck = checkDateOfBirthMatch(
      memberDob,
      claim.mem_dob_aadhar
    );
    const panDobCheck = checkDateOfBirthMatch(claim.mem_dob_pan, memberDob);
    return aadhaarDobCheck && panDobCheck;
  }
  /**
   * Todo: Add Mandatory docs checks
   */
  ///*** checking mandatory documents***** */
  ///******* function to get the mandatory document for the given claimId as an array*/

  const getMandatoryDocIdsByClaimTypeId = (claimTypeId) => {
    const mandatoryDocs = claimTypeData.find(
      (type) => type.claimTypeId === claimTypeId
    );
    console.log("mandatory docs", mandatoryDocs);
    return claimTypeId.mandatoryDocIds
      ? claimTypeId.mandatoryDocIds.split(",")
      : [];
  };
  ///******* function to get the uploaded documents  */
  //**this function returns the document id of the documents which are selected and has a document uploaded in choose file */
  // const getDocTypes = (documents) => documents.map((file) => file.id);// this didnt check the fileContent
  const getDocTypes = (documents) => {
    console.log("docuemnetnt uploeasd", uploadedDocs);
    return (
      documents
        // .filter((file) => file.fileContent != null)
        // .map((file) => file.id);
        .filter((file) => file.fileContent != null)
        .map((file) => file.docTypeId)
    );
  };

  ///******* function to get missing mandatory documents  */
  const getMissingMandatoryDocs = (uploadedDocs, mandatoryDocs) => {
    const uploadedDocTypes = getDocTypes(uploadedDocs);
    return mandatoryDocs.filter((docId) => !uploadedDocTypes.includes(docId));
  };

  ///******* function to get the *Name* of missing mandatory Doc */
  const getMissingMandatoryDocNames = (missingMandatoryDocs, docTypes) => {
    const missingDocs = missingMandatoryDocs.map((docId) => {
      const matchingDoc = docTypes.find((doc) => doc.id === docId);
      return matchingDoc
        ? matchingDoc.title
        : `Unknown documents with id ${docId}`;
    });

    const missingDocsName = missingDocs.join(`,\n`);
    return missingDocsName;
  };

  const checkDocuments = () => {
    console.log("uploaded documents", uploadedDocs);
    console.log("claimData", claimTypeData);
    console.log("claitype", claim.claim_type);
    const mandatoryDocs = getMandatoryDocIdsByClaimTypeId(claim.claim_type);
    console.log("mandatoryDocs", mandatoryDocs);
    //const getDocTypes = (documents) => documents.map((file) => file.id);
    if (mandatoryDocs.length !== 0) {
      const missingMandatoryDocs = getMissingMandatoryDocs(
        uploadedDocs,
        mandatoryDocs
      );
      if (missingMandatoryDocs.length !== 0) {
        console.log("missingMandatoryDocs", missingMandatoryDocs);
        const missingDocName = getMissingMandatoryDocNames(
          missingMandatoryDocs,
          documentTypes
        );
        console.log("missingDocName", missingDocName);
        setRecordErrorMessage(
          `${missingDocName}   are missing, Please upload.`
        );
        setErrorTitle("Mandatory Documents are Missing");
        setRecordErrors(true);
      }
    } else return true;
    console.log("getDocTypes", getDocTypes(uploadedDocs));
  };

  //////////*****final check before submit*********/
  const handleRecordErrorAlert = () => {
    setRecordErrors(false);
  };
  const handleIsFailedAlert = () => {
    setIsFailed(false);
  };

  const handleSuccessOk = () => {
    setIsSubmitSuccess(false);
    navigate("/uda/penset");
  };

  useEffect(() => {
    fetchClaimTypes();
  }, [claimantSelf]);

  // useEffect(() => {
  //   console.log("fetchAssignOfficer");
  //   // fetchAssignOfficer();
  // }, [loginId]);

  useEffect(() => {
    //console.log("claimTypeData", claimTypeData);
    //console.log("claimTypeIn", claim.claim_type);
    const selectedClaimId = claim.claim_type;
    const selectedClaimType = claimTypeData.find(
      (claim) => claim.claimTypeId === selectedClaimId
    );
    console.log("SelectedClaim", selectedClaimType);
    if (selectedClaimType) {
      setSettlementPara(selectedClaimType.settlementParaNumber);
    } else {
      setSettlementPara("");
    }
  }, [claim.claim_type, claimTypeData]);

  const onChange = (e) => {
    setClaim({ ...claim, [e.target.name]: e.target.value });

    if (e.target.name === "claimant_name") {
      console.log("claimant_name", e.target.value);
      const id = e.target.value;
      if (id === 0) {
        setClaimantSelf(true);
        setSelectedClaimant({});
      } else {
        setClaimantSelf(false);
        const selectedFamilyMember = nomineeDetails.find(
          (member) => member.familyMemId === id
        );
        // console.log("familymemerid", member.familyMemId);
        console.log("selected family member", selectedFamilyMember);
        setSelectedClaimant(selectedFamilyMember);
        //setSelectedClaimant(e.target.value);
      }
    }
    if (e.target.name === "toLoginId") {
      const selectedOption = assignToOption.find(
        (option) => option.id === e.target.value
      );
      if (selectedOption) {
        setSelectedOfficerName(selectedOption.title); // Set the selected officer's name in the state
      }
    }
  };

  //**** function to post uploaded documents****** */

  const postUploadedDoc = (responseStatus) => {
    const uploadDocData = {
      claimId: claimDetails.claimId,
      cmpfAccNo: cmpfAccNo,
      claimFlag: "PN",
      claimantId: claim.claimant_name,
      uploadedDocuments: uploadedDocs,
    };
    console.log("uploadDocData", uploadDocData);
    const url =
      process.env.REACT_APP_SERVER_URL + "/update/pen/claim/docs/by/uda";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, uploadDocData, { headers })
      .then(function (response) {
        console.log("Submit response", response);
        if (responseStatus === 202) {
          setSubmitSuccessMsg(
            "Claim Submitted Successfully. Notification not sent."
          );
        } else {
          setSubmitSuccessMsg("Claim Updated Successfully.");
        }
        setIsSubmitSuccess(true);
        setIsSubmitting(false); // the loader stops after upload document is successful
      })
      .catch((error) => {
        setIsSubmitting(false);
        setIsFailed(true);
        setFailedErrorMessage("Something went Wrong!!");
        console.log("error on submit", error);
      });
  };

  //***********to submit form ***** */
  const submitForm = (formData) => {
    setIsSubmitting(true);
    setIsSubmitClicked(true);
    const url = process.env.REACT_APP_SERVER_URL + "/update/pen/claim/by/uda";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, formData, { headers })
      .then(function (response) {
        console.log("Submit response", response);
        postUploadedDoc(response.status);
        setIsSubmitSuccess(true);
        setIsSubmitClicked(false);
        // setIsSubmitting(false);
      })
      .catch((error) => {
        console.log("error on submit", error);
        setIsSubmitClicked(false);

        setIsFailed(true);
        setIsSubmitting(false);

        if (error.response.data.http_status_code === 404) {
          setFailedErrorMessage(error.response.data.error_message);
        } else {
          setFailedErrorMessage("Something went Wrong!!");
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // if (!(await sessionExist())) {
    //   setIsFailed(true);
    //   setIsLoading(false);
    //   return;
    // }

    // let token = localStorage.getItem("jwt");

    // if (!token) {
    //   setIsFailed(true);
    //   setIsLoading(false);
    //   return;
    // }

    // token = token.replace('"', "").replace('"', "");
    //const loginId = "UDA-CC-OFF-101";
    console.log("ccmm", cmpfAccNo);
    //*****Creating Form data to submit****/
    const pension =
      additionalData && additionalData.monthly_pension
        ? additionalData.monthly_pension
        : 0;
    const formData = {
      cmpfAccNo: claimDetails.cmpfAccNo,

      // udaLoginId: "UDA-CC-OFF-101", // hardcoded
      // toLoginId: "UAO-CC-OFF-103",
      udaLoginId: loginId, // hardcoded

      toLoginId: claim.toLoginId,
      claimId: claimDetails.claimId,

      nameAadhaar: {
        firstname: claim.mem_first_name_aadhar,
        middlename: claim.mem_middle_name_aadhar,
        lastname: claim.mem_last_name_aadhar,
      },
      namePan: {
        firstname: claim.mem_first_name_pan,
        middlename: claim.mem_middle_name_pan,
        lastname: claim.mem_last_name_aadhar,
      },
      nameBank: {
        firstname: claim.mem_first_name_bank,
        middlename: claim.mem_middle_name_bank,
        lastname: claim.mem_last_name_bank,
      },
      dobAadhaar: claim.mem_dob_aadhar,
      dobPan: claim.mem_dob_pan,
      sfwhName: claim.fhw_name,
      sfwhRelationType: claim.relation,
      penClaimTypId: claim.claim_type,
      declarationUda: checked,
      remarks: "",
      claimantId: claim.claimant_name,
      dateOfCessation: claim.date_of_cessation,
      averageEmoluments: averageEmoluments,
      serviceYearsUpto89: claim.service_upto_31031989_yrs,
      serviceMonthsUpto89: claim.service_upto_31031989_mnths,
      serviceYearsAfter89: claim.service_from_01041989_yrs,
      serviceMonthsAfter89: claim.service_from_01041989_mnths,
      last10MonthWages: lastMonthsSalary,

      monthlyPension: pension,
      modeOfRemmit: "Bank Transfer",
      remmitBankDetails: {
        bank: "",
        ifsc: "",
        account_no: "",
        address: "",
      },
      chequeNo: null,
      reducedPen: 0,
      arrearAmount: 0,
      recoveryAmount: 0,
      oneTimePayment: 0,
      exGratiaPayment: 0,
    };
    console.log("formdata", formData);

    // const formData = {
    //   cmpfAccNo: "HYD/160/223",
    //   udaLoginId: "UDA-CC-OFF-101",
    //   toLoginId: "UAO-CC-OFF-103",
    //   nameAadhaar: {
    //     firstname: "Baiju",
    //     middlename: "nath",
    //     lastname: "sharma",
    //   },
    //   namePan: {
    //     firstname: "Baiju",
    //     middlename: "nath",
    //     lastname: "sharma",
    //   },
    //   nameBank: {
    //     firstname: "Baiju",
    //     middlename: "nath",
    //     lastname: "sharma",
    //   },
    //   dobAadhaar: "1998-05-25",
    //   dobPan: "1998-05-25",
    //   sfwhName: "Jaisurya",
    //   sfwhRelationType: 1,
    //   pfClaimTypId: 1,
    //   declarationUda: true,
    //   remarks: "Hello",
    //   claimantId: 0,
    //   dateOfCessation: null,
    //   ledgerBalance: 0,
    //   sanctionedAmt: 0,
    //   dateOfdeath: null,
    //   brokenPeriodInterestPayble: 0,
    //   brokenPeriodContri: 0,
    //   brokenPeriodContriDet: "jan2023 - 10 ,20 ,30 , feb2023 - 30, 40 ,50",
    //   brokenPeriodInterestRate: 0.0,
    //   modeOfRemmit: "bank/cheque",
    //   remmitBankDetails: "name : sbi , ifsc : 223456",
    //   chequeNo: null,
    //   brokenPeriodMonths: 12,
    //   brokenPeriodMemShareContri: 12000,
    //   brokenPeriodEmplyrShareContri: 12000,
    //   brokenPeriodVolContri: 12000,
    // };

    console.log("formData", formData);
    //---------------------------------------------//
    submitForm(formData);
    // console.log("fromdata to be sent", objectTobeSent);
  };

  const handleProcessOk = (e) => {
    setShowingDropdown(1);
  };

  return (
    // <Grid container>
    //   <Grid item xs={12}>
    //     <Grid container justifyContent="center" alignItems="center" rowGap={3}>
    //       {/* Claimtype dropdown  */}

    //       {/* row */}
    //       <Grid item xs={12} sm={3}></Grid>
    //       <Grid item xs={12} sm={3}>
    //         <LabelText title="Select Claim Type" />
    //       </Grid>

    //       <Grid item xs={12} sm={3}>
    //         <CustomDropdown {...claim_type} onchange={onChange} />
    //       </Grid>
    //       <Grid item xs={12} sm={3}></Grid>

    //       {/* row  */}
    //     </Grid>
    //     <Grid item xs={12}>
    //       <Grid container></Grid>
    //     </Grid>
    //   </Grid>
    // </Grid>
    <>
      <ErrorAlert
        handleOk={handleRecordErrorAlert}
        open={recordErrors}
        title={errorTitle}
        body={recordErrorMessage}
        buttonName="Ok"
      />
      <ErrorAlert
        handleOk={handleIsFailedAlert}
        open={isFailed}
        title="Error"
        body={failedErrorMessage}
        buttonName="Ok"
      />
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error!"
        body={errorMessage}
        buttonName="Ok"
      />
      <ErrorAlert
        handleOk={handleValidationFailedAlert}
        open={validationFailed}
        title="Error!"
        body={validationFailedMsg}
        buttonName="Ok"
      />
      <SucessAlert
        handleOk={handleSuccessOk}
        open={isSubmitSuccess}
        title="Success"
        // body="Claim Submitted Successfully"
        body={submitSuccessMsg}
        buttonName="Ok"
      />
      <ConfirmationDialogue
        handleOk={handleAssignToOk}
        handleCancel={handleAssignToCancel}
        open={isSubmitClicked}
        title="Confirm Assignment"
        body={`Are you sure you want to assign the claim to ${selectedOfficerName} ?`}
        buttonName="Confirm"
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                columnGap={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12} sm={5}>
                  <TitleText title="Claim Submission Form" />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    spacing={1}
                    //columnGap={1}
                    justifyContent="end"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={9}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <LabelText title="Settlement Para: " />
                    </Grid>
                    <Grid item xs={2}>
                      <ValueText title={settlementPara} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <FormDivider />

              <Grid
                container
                spacing={2}
                columnGap={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    columnGap={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={5}>
                      {/* column 1 */}
                      <Grid
                        container
                        spacing={2}
                        columnGap={2}
                        justifyContent="start"
                        alignItems="center"
                      >
                        {/* ---Relationship with member--- */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Select Claimant  " />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomDropdown
                            {...claimant_name}
                            onchange={onChange}
                          />
                        </Grid>
                        {!claimantSelf && (
                          <>
                            {/* ------------------ */}
                            {/* ---Relationship with member--- */}
                            <Grid item xs={12} sm={4}>
                              <LabelText title="Date of Birth of Claimant " />
                            </Grid>
                            <Grid item xs={12} sm={7}>
                              {/* <CustomDropdown {...claim_type} onchange={onChange} /> */}
                              <ValueText
                                title={dateConvert(
                                  selectedClaimant.dateOfBirth
                                )}
                              />
                            </Grid>
                            {/* ------------------ */}
                          </>
                        )}
                        {/* ------------------ */}
                        {/* ---Relationship with member--- */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Cause of Cessation " />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          {/* <CustomDropdown {...claim_type} onchange={onChange} /> */}
                          <CustomDropdown {...claim_type} onchange={onChange} />
                        </Grid>
                        {/* ------------------ */}
                      </Grid>
                    </Grid>
                    {/* column2 */}
                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        spacing={2}
                        columnGap={2}
                        justifyContent="start"
                        alignItems="center"
                      >
                        {!claimantSelf && (
                          <>
                            {/* ---Relationship with member--- */}
                            <Grid item xs={12} sm={4}>
                              <LabelText title="Relationship With Member " />
                            </Grid>
                            <Grid item xs={12} sm={7}>
                              <ValueText title="Wife" />
                            </Grid>
                            {/* ------------------ */}
                            {/* ---Relationship with member--- */}
                            <Grid item xs={12} sm={4}>
                              <LabelText title="Date of Death of Member " />
                            </Grid>
                            <Grid item xs={12} sm={7}>
                              <CustomTextField
                                {...date_of_death}
                                onchange={onChange}
                              />
                            </Grid>
                          </>
                        )}
                        {/* ------------------ */}
                        {/* ---Relationship with member--- */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Date of Cessation: " />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...date_of_cessation}
                            onchange={onChange}
                          />
                        </Grid>
                        {/* ------------------ */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    columnGap={2}
                    justifyContent="start"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <SubtitleText title="Enter Member Name as per :" />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={3}>
                          <LabelText title="Aadhaar Card : " />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_first_name_aadhar}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_middle_name_aadhar}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_last_name_aadhar}
                            onchange={onChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={3}>
                          <LabelText title="PAN Card : " />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_first_name_pan}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_middle_name_pan}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_last_name_pan}
                            onchange={onChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={3}>
                          <LabelText title=" Bank Statement : " />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_first_name_bank}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_middle_name_bank}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_last_name_bank}
                            onchange={onChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* ******* Name fields ended ***** */}
                    <Grid item xs={12}>
                      <SubtitleText title="Enter Member Date of Birth as per :" />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={1}
                        columnGap={2}
                        justifyContent="space-between"
                      >
                        {/* here we are creating two columns as the label and textfield were not aligning properly */}
                        {/* Column 1 */}
                        <Grid item xs={12} md={5}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={5}>
                              <LabelText title="Aadhaar Card : " />
                            </Grid>
                            <Grid item xs={12} sm={12} md={7}>
                              <CustomTextField
                                {...mem_dob_aadhar}
                                onchange={onChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* Column 2 */}
                        <Grid item xs={12} md={5}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={4}>
                              <LabelText title="PAN Card : " />
                            </Grid>
                            <Grid item xs={12} sm={12} md={8}>
                              <CustomTextField
                                {...mem_dob_pan}
                                onchange={onChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* ********* Dob fields ends here******** */}
                    <Grid item xs={12}>
                      <SubtitleText title="Enter S/O D/O W/O H/O as per :" />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={3}>
                          <LabelText title="Aadhaar Card : " />
                        </Grid>

                        <Grid item xs={12} sm={12} md={9}>
                          <Grid
                            container
                            spacing={1}
                            justifyContent="end
                            "
                          >
                            <Grid item xs={12} sm={12} md={5}>
                              <CustomDropdown
                                {...relation}
                                onchange={onChange}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                              <CustomTextField
                                {...fhw_name}
                                onchange={onChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* ********** W/H/d/s fields ends here************ */}
                    {/* ****************** pension fields **************** */}
                    <Grid item xs={12}>
                      <SubtitleText title="Pension Details" />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={1}
                        justifyContent="center"
                        alignItems={"center"}
                      >
                        {/* *************************next two***************** */}
                        <Grid item xs={12}>
                          <Grid
                            container
                            spacing={1}
                            columnGap={2}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            {/* here we are creating two columns as the label and textfield were not aligning properly */}
                            {/* Column 1 */}
                            {/* <Grid item xs={12} md={5}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={12} md={5}>
                                    <LabelText title="Kind Of Pension " />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={7}> */}
                            {/* <CustomDropdown
                                    {...claim_type}
                                    onchange={onChange}
                                  /> */}
                            {/* <ValueText title="Death"></ValueText>
                                  </Grid>
                                </Grid>
                              </Grid> */}
                            {/* Column 2 */}
                            {/* <Grid item xs={12} md={5}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={12} md={5}>
                                    <LabelText title="Option Under Para 15 " />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={7}>
                                    <CustomTextField
                                      {...para}
                                      onchange={onChange}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid> */}
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid
                              container
                              item
                              md={12}
                              justifyContent="space-around"
                            >
                              <Grid item xs={12} mt={2} mb={1}>
                                <LabelText title="Enter the Salary of Last 10 months " />
                              </Grid>
                            </Grid>
                            {/* First Row */}
                            <Grid
                              container
                              item
                              md={12}
                              justifyContent="space-around"
                            >
                              {[1, 2, 3, 4, 5].map((item) => (
                                <Grid key={item} item md={2}>
                                  <CustomTextField
                                    {...salaryObjects[item]}
                                    onchange={onChangeSalary}
                                  />
                                </Grid>
                              ))}
                            </Grid>

                            {/* Second Row */}
                            <Grid
                              container
                              item
                              md={12}
                              justifyContent="space-around"
                            >
                              {[6, 7, 8, 9, 10].map((item) => (
                                <Grid key={item} item md={2}>
                                  <CustomTextField
                                    {...salaryObjects[item]}
                                    onchange={onChangeSalary}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                        {/** 10 months salary objects ended here */}
                        {/************Next two fields starts here*************** */}

                        <Grid item xs={12}>
                          <Grid
                            container
                            spacing={1}
                            columnGap={2}
                            justifyContent="space-between"
                          >
                            {/* here we are creating two columns as the label and textfield were not aligning properly */}
                            {/* Column 1 */}
                            <Grid item xs={12} md={5} mt={3} mb={1}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={5}>
                                  <LabelText title="Average emoluments:  " />
                                </Grid>
                                <Grid item xs={12} sm={12} md={7}>
                                  <ValueText title={averageEmoluments} />
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* Column 2 */}
                            {/* <Grid item xs={12} md={5} mt={3} mb={1}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={5}>
                                  <LabelText title="Total Pensionable Service " />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                  <ValueText title="" />
                                </Grid>
                              </Grid>
                            </Grid> */}
                            {/* total pensionable service */}
                            {/** outer container */}
                            <Grid item xs={12}>
                              <SubtitleText title="Total Pensionable Service" />
                            </Grid>
                            <Grid item xs={12}>
                              <Grid
                                container
                                spacing={1}
                                columnGap={2}
                                justifyContent="start"
                                alignItems="center"
                              >
                                <Grid item xs={12}>
                                  <Grid
                                    container
                                    justifyContent="start"
                                    alignItems="center"
                                  >
                                    <Grid item xs={12} md={4}>
                                      <LabelText title="Upto 31.03.1989" />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                      <Grid
                                        container
                                        spacing={1}
                                        justifyContent="space_between"
                                        alignItems="center"
                                      >
                                        <Grid item xs={8}>
                                          <CustomTextField
                                            {...service_upto_31031989_yrs}
                                            onchange={onChange}
                                          />
                                        </Grid>
                                        <Grid item xs={2}>
                                          <ValueText title="years"></ValueText>
                                        </Grid>
                                      </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                      <Grid
                                        container
                                        spacing={1}
                                        justifyContent="space_between"
                                        alignItems="center"
                                      >
                                        <Grid item xs={8}>
                                          <CustomTextField
                                            {...service_upto_31031989_mnths}
                                            onchange={onChange}
                                          />
                                        </Grid>
                                        <Grid item xs={2}>
                                          <ValueText title="months"></ValueText>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid
                                    container
                                    justifyContent="space_between"
                                    alignItems="center"
                                  >
                                    <Grid item xs={12} md={4}>
                                      <LabelText title="From 1.04.1989" />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                      <Grid
                                        container
                                        spacing={1}
                                        justifyContent="space_between"
                                        alignItems="center"
                                      >
                                        <Grid item xs={8}>
                                          {" "}
                                          <CustomTextField
                                            {...service_from_01041989_yrs}
                                            onchange={onChange}
                                          />
                                        </Grid>
                                        <Grid item xs={2}>
                                          <ValueText title="years"></ValueText>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                      <Grid
                                        container
                                        spacing={1}
                                        justifyContent="space_between"
                                        alignItems="center"
                                      >
                                        <Grid item xs={8}>
                                          {" "}
                                          <CustomTextField
                                            {...service_from_01041989_mnths}
                                            onchange={onChange}
                                          />
                                        </Grid>
                                        <Grid item xs={2}>
                                          <ValueText title="months"></ValueText>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* ********************pension fields ends here ************* */}
                    <Grid item xs={12}>
                      <SubtitleText title="Upload Documents" />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <UploadDocuments
                        documentTypes={documentTypes}
                        onSave={handleStateUpdate}
                      />
                    </Grid>
                    {/* Document Upload  */}
                    {/* *************** Declaration ********* */}
                    <Grid item xs={12} mt={3}>
                      <Paper
                        elevation={0}
                        sx={{ padding: "3rem", borderRadius: "8px" }}
                      >
                        <TitleText title="Declaration" />
                        <FormDivider />
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center", // Center vertically
                              gap: 2, // Adjust the gap as needed
                              padding: 2, // Add padding for better visualization
                              //border: "1px solid #ccc", // Add border for better visualization
                              justifyContent: "flex-start",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checked}
                                  onChange={handleChange}
                                  color="success"
                                  sx={{
                                    marginTop: -1, // Adjust the negative margin to move the Checkbox up
                                    marginRight: -4,
                                  }}
                                />
                              }
                              // label={<ValueText title="I Agree" />}
                            />
                            <CaptionText
                              title="All details have been verified with the service record and all documents have been verified with the original documents of the claim and found correct."
                              sx={{
                                margin: 0, // Reset margin for better alignment
                              }}
                            />
                          </Box>
                        </Grid>
                      </Paper>
                    </Grid>
                    {/* *************** Submit Button ********* */}

                    <Grid container justifyContent="center">
                      <Grid
                        item
                        xs={12}
                        sm="auto"
                        sx={{ justifyContent: "center" }}
                      >
                        <Button
                          onClick={handleProcessOk}
                          variant="contained"
                          color="success"
                          //disabled={true}
                          //fullWidths
                          type="submit"
                        >
                          {"Submit"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {showingDropdown === 1 && (
                  <Grid
                    container
                    justifyContent="end"
                    alignItems={"center"}
                    spacing={2}
                  >
                    <Grid item xs={12} sm={4}>
                      <CustomDropdown {...toLoginId} onchange={onChange} />
                    </Grid>
                    <Grid item md={1}>
                      <Button
                        onClick={handleSubmitOk}
                        variant="contained"
                        color="success"
                        type="submit"
                        disabled={isSubmitting}
                        fullWidth
                      >
                        {isSubmitting ? <CircularProgress /> : "OK"}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default ClaimFormPensionPrefilled;
