import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Stepper, Step, StepLabel, StepIcon } from "@mui/material";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import StepConnector from "@mui/material/StepConnector";
import { useLocation } from "react-router-dom";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import { useNavigate } from "react-router-dom";

const AdvanceStatusTracking = () => {
  const location = useLocation();
  const { state } = location;
  const { claimId } = state;
  const navigate = useNavigate();
  //console.log('search param', searchParam)

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [completedSteps, setCompletedSteps] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loadingSteps, setLoadingSteps] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);

  const [error, setError] = useState(null); // New state for error handling
  const [errorAlertOpen, setErrorAlertOpen] = useState(false); //

  useEffect(() => {
    fetchCompletedSteps();
    fetchTableData();
  }, []);

  const fetchTableData = async () => {
    try {
      const newResponse = new FormData();
      newResponse.append("claimId", claimId); //"ADV_CLM_000001"
      const url = process.env.REACT_APP_SERVER_URL + "/advance/status/tracking";
      const response = await axios.post(url, newResponse);
      const responseData = response.data.data;
      console.log("table data", responseData);
      let tablePreviewData = responseData.map((tableData) => ({
        "Grievance Id": tableData.claimId,
        "Submit Date": tableData.activityDate,
        "Current Status": tableData.advClaimStatusType,
        "Prev Status": tableData.activityDate,
      }));
      setTableData(tablePreviewData);
      setLoadingTable(false);
    } catch (error) {
      console.error("Error fetching table data:", error);
      // Set the error state and open the error alert
      setError("Clim ID not found");
      setErrorAlertOpen(true);
      // setTimeout(() => {
      //   // history.push("/search");
      //   navigate('/search');
      // }, 2000);
    }
  };

  const fetchCompletedSteps = async () => {
    try {
      const newResponse = new FormData();
      newResponse.append("claimId", claimId);
      const url = process.env.REACT_APP_SERVER_URL + "/advance/status/tracking";
      const response = await axios.post(url, newResponse);

      const responseData = response.data.data;
      console.log("....the step data ", responseData);

      if (response.data && response.data.data) {
        const filteredSteps = response.data.data;
        setCompletedSteps(filteredSteps);
      } else {
        console.error("Failed to retrieve completed steps data");
      }
      setLoadingSteps(false);
    } catch (error) {
      console.error("Error fetching completed steps:", error);
    }
  };

  const lastItemTable =
    tableData.length > 0 ? tableData[tableData.length - 1] : null;
  console.log("last item", lastItemTable);
  const FirstItemTable = tableData.length > 0 ? tableData[0] : null;

  const checkStatusExists = (statusId) => {
    //return completedSteps.some((step) => step.penClaimStatusType === statusId);
    const lowercaseStatusId = statusId.toLowerCase();
    // console.log("lower case status", lowercaseStatusId);
    return completedSteps.some((step) => {
      console.log("step.advClaimStatusType", step.advClaimStatusType);
      console.log("lowercaseStatusId", lowercaseStatusId);
      console.log(step.advClaimStatusType.toLowerCase() === lowercaseStatusId);
      return (
        step.advClaimStatusType &&
        step.advClaimStatusType.toLowerCase() === lowercaseStatusId
      );
    });
  };

  const checkStatusExistsLast = (statusId) => {
    const lowercaseStatusId = statusId.toLowerCase();

    const lastStep = completedSteps[completedSteps.length - 1];
    if (
      !lastStep ||
      !lastStep.advClaimStatusType.toLowerCase().includes("return")
    ) {
      return false; // If last step doesn't contain "returned", return false directly
    }

    for (let i = completedSteps.length - 1; i >= 0; i--) {
      const step = completedSteps[i];
      if (
        step &&
        step.advClaimStatusType &&
        step.advClaimStatusType.toLowerCase() === lowercaseStatusId
      ) {
        return true;
      }
    }

    return false;
    // const lowercaseStatusId = statusId.toLowerCase();
    // const lastStep = completedSteps[completedSteps.length - 1];
    // return (
    //   lastStep &&
    //   lastStep.advClaimStatusType &&
    //   lastStep.advClaimStatusType.toLowerCase() === lowercaseStatusId
    // );
  };

  const getUaoStatus = () => {
    const isForwardedToUao = checkStatusExists("Submitted to UAO");
    const isReturnedByUao = checkStatusExistsLast("Returned by UAO");

    if (isReturnedByUao || isForwardedToUao) {
      return [isReturnedByUao ? "Returned by UAO" : "Forwarded to UAO", true];
    }

    return ["Forwarded to UAO", false];
  };

  const getAaoStatus = () => {
    // console.log("checkStatusExists Returned by AAO", checkStatusExists("Returned by AAO"));
    // if (checkStatusExists("Forwarded to AAO")) return ["Forwarded to AAO", true];
    // if(checkStatusExistsLast("Returned by AAO")) return ["Returned by AAO", true];

    // return ["Forwarded to AAO", false];
    const isForwardedToAao = checkStatusExists("Forwarded to AAO");
    console.log("isForwardedToAao2", isForwardedToAao);
    const isReturnedByAao = checkStatusExistsLast("Returned by AAO");

    if (isReturnedByAao || isForwardedToAao) {
      return [isReturnedByAao ? "Returned by AAO" : "Forwarded to AAO", true];
    }

    return ["Forwarded to AAO", false];
  };

  const getRodaStatus = () => {
    const isReturnedByRoda = checkStatusExistsLast(
      // "Processed for Return by RODA"
      "Processed for return by RODA"
    );
    const isAssignedToRoda = checkStatusExists("Assigned to RODA");
    const isVerifiedByRoda = checkStatusExists("Verified by RODA");
    const isForwardedToRoIds = checkStatusExists("Forwarded to RO");

    if (isReturnedByRoda) {
      return ["Returned by RODA", true];
    } else if (isVerifiedByRoda) {
      return ["Verified by RODA", true];
    } else if (isAssignedToRoda) {
      return ["Assigned to RODA", true];
    } else if (isForwardedToRoIds) {
      return ["Forwarded to RO", true];
    }

    return ["Forwarded to RODA", false];
  };

  const getSiStatus = () => {
    //if (checkStatusExists("Assigned to SI")) return ["Assigned to SI", true];
    if (checkStatusExists("Verified by SI")) return ["Verified by SI", true];
    if (checkStatusExistsLast("Processed for return by SI"))
      return ["Returned by SI", true];

    return ["Forwarded to SI", false];
  };

  const getBoStatus = () => {
    if (checkStatusExists("Verified by BO")) return ["Verified by BO", true];
    if (checkStatusExistsLast("Returned by BO"))
      return ["Returned by BO", true];
    return ["Forwarded to BO", false];
  };

  const getRhStatus = () => {
    if (checkStatusExists("Approved by RH")) return ["Approved by RH", true];
    if (checkStatusExistsLast("Returned by RH"))
      return ["Returned by RH", true];
    return ["Forwarded to RH", false];
  };

  const getAaoStatusCompleted = () => {
    const isForwardedToAao = checkStatusExists("Confirmed by AAO");
    console.log("isConfirmedAao2", isForwardedToAao);
    const isReturnedByAao = checkStatusExistsLast("Returned by AAO");

    if (isReturnedByAao || isForwardedToAao) {
      return [isReturnedByAao ? "Returned by AAO" : "Confirmed by AAO", true];
    }

    return ["Confirmed by AAO", false];
  };

  const getCashierStatus = () => {
    // console.log(
    //   "checkStatusExistsLast Returned by Cashier",
    //   checkStatusExistsLast("Returned by Cashier")
    // );
    if (checkStatusExists("Ready for payment"))
      return ["Verified by Cashier", true];
    if (checkStatusExistsLast("Return by Cashier"))
      return ["Returned by Cashier", true];
    return ["Verified By Cashier", false];
  };

  const getAgmStatus = () => {
    if (checkStatusExists("Returned by AGM")) {
      return ["Returned by AGM", true];
    }
    if (checkStatusExists("Verified by AGM")) {
      return ["Verified by AGM", true];
    }
    return ["Forwarded to AGM", false];
  };

  const isUdaStep = checkStatusExists("submitted by uda");

  //const [agmStatus, isAgmStep] = getAgmStatus();
  //const isGCMStep = checkStatusExists("Submitted to GCM");
  //const isUaoStep = checkStatusExists("Verified by UAO")

  const [uaoStatus, isUaoStep] = getUaoStatus();
  const [aaoStatus, isAaoStep] = getAaoStatus();
  const [rodaStatus, isRodaStep] = getRodaStatus();
  const [siStatus, isSiStep] = getSiStatus();
  const [boStatus, isBoStep] = getBoStatus();
  const [rhStatus, isRhStep] = getRhStatus();
  const [aaoStatusCompleted, isAaoStepCompleted] = getAaoStatusCompleted();
  const [cashierStatus, isCashierStep] = getCashierStatus();

  const udaColor = isUdaStep ? "green" : "green"; //uda
  //const uaoColor = isUaoStep && uaoStatus !== 'Returned by UAO' ? 'green' : 'red'; // Modified here
  let uaoColor = isUaoStep
    ? uaoStatus !== "Returned by UAO"
      ? "green"
      : "red"
    : uaoStatus !== "Returned by UAO"
    ? "gray"
    : undefined;

  //const aaoColor = isAaoStep && aaoStatus !== 'Returned by AAO' ? 'green' : 'red';
  let aaoColor = isAaoStep
    ? aaoStatus !== "Returned by AAO"
      ? "green"
      : "red"
    : aaoStatus !== "Returned by AAO"
    ? "gray"
    : undefined;

  //const rodaColor = isRodaStep ? 'green' : 'gray';
  let rodaColor = isRodaStep
    ? rodaStatus !== "Returned by RODA"
      ? "green"
      : "red"
    : rodaStatus !== "Returned by RODA"
    ? "gray"
    : undefined;

  let siColor = isSiStep
    ? siStatus !== "Returned by SI"
      ? "green"
      : "red"
    : rodaStatus !== "Returned by SI"
    ? "gray"
    : undefined;

  //const boColor = isBoStep ? 'green' : 'gray';
  let boColor = isBoStep
    ? boStatus !== "Returned by BO"
      ? "green"
      : "red"
    : boStatus !== "Returned by BO"
    ? "gray"
    : undefined;

  //const rhColor = isRhStep ? 'green' : 'gray';
  let rhColor = isRhStep
    ? rhStatus !== "Returned by RH"
      ? "green"
      : "red"
    : rodaStatus !== "Returned by RH"
    ? "gray"
    : undefined;

  let aaoCompletedColor = isAaoStepCompleted
    ? aaoStatusCompleted !== "Returned by AAO"
      ? "green"
      : "red"
    : aaoStatusCompleted !== "Returned by AAO"
    ? "gray"
    : undefined;
  // let aaoCompletedColor =
  //   isAaoStepCompleted && aaoStatusCompleted === "Confirmed by AAO"
  //     ? "green"
  //     : aaoStatusCompleted !== "Returned by AAO"
  //     ? "red"
  //     : "gray";

  let cashierColor = isCashierStep
    ? cashierStatus !== "Returned by Cashier"
      ? "green"
      : "red"
    : cashierStatus !== "Returned by Cashier"
    ? "gray"
    : undefined;

  // console.log("AAO Status Completed:", aaoStatusCompleted);
  // console.log("Is AAO Step Completed:", isAaoStepCompleted);

  const handleOkErrorAlert = () => {
    setErrorAlertOpen(false); // Close the error alert
    // You can add additional error handling logic here as needed
  };

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper
            elevation={3}
            sx={{ padding: isMobile ? "1rem" : "3rem", borderRadius: "8px" }}
          >
            <Grid item xs={12}>
              <TitleText title="Advance Claim Status" />
            </Grid>
            <FormDivider />

            {loadingSteps ? (
              <p>Loading steps...</p>
            ) : (
              // <Stepper alternativeLabel orientation={isMobile ? 'vertical' : 'horizontal'}>
              <Stepper
                orientation={isMobile ? "vertical" : "horizontal"}
                alternativeLabel={!isMobile}
              >
                <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon {...props} style={{ color: udaColor }} />
                    )}
                  >
                    <div style={{ color: udaColor, fontWeight: 400 }}>
                      {isUdaStep ? "Submitted by UDA" : "Forwarded to UDA"}
                    </div>
                  </StepLabel>
                </Step>

                {/* UAO */}
                <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon {...props} style={{ color: uaoColor }} />
                    )}
                  >
                    <div style={{ color: uaoColor }}>{uaoStatus}</div>
                  </StepLabel>
                </Step>

                {/* AAO */}
                <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon {...props} style={{ color: aaoColor }} />
                    )}
                  >
                    <div style={{ color: aaoColor }}>{aaoStatus}</div>
                  </StepLabel>
                </Step>
                {/* <Step>
                    {isAgmStep && agmStatus === "Returned by AGM" ? (
                      <StepLabel>
                        <div style={{ color: 'red' }}>{agmStatus}</div>
                      </StepLabel>
                    ) : (
                      isAgmStep && (
                        <StepLabel>
                          <div style={{ color: 'green' }}>{agmStatus}</div>
                        </StepLabel>
                      )
                    )}
                </Step> */}
                {/* step for RO/DA */}
                <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon {...props} style={{ color: rodaColor }} />
                    )}
                  >
                    <div style={{ color: rodaColor }}>{rodaStatus}</div>
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon {...props} style={{ color: siColor }} />
                    )}
                  >
                    <div style={{ color: siColor }}>{siStatus}</div>
                  </StepLabel>
                </Step>

                <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon {...props} style={{ color: boColor }} />
                    )}
                  >
                    <div style={{ color: boColor }}>{boStatus}</div>
                  </StepLabel>
                </Step>

                <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon {...props} style={{ color: rhColor }} />
                    )}
                  >
                    <div style={{ color: rhColor }}>{rhStatus}</div>
                  </StepLabel>
                </Step>

                <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon
                        {...props}
                        style={{ color: aaoCompletedColor }}
                      />
                    )}
                  >
                    <div style={{ color: aaoCompletedColor }}>
                      {aaoStatusCompleted}
                    </div>
                  </StepLabel>
                </Step>

                <Step>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <StepIcon {...props} style={{ color: cashierColor }} />
                    )}
                  >
                    <div style={{ color: cashierColor }}>{cashierStatus}</div>
                  </StepLabel>
                </Step>
              </Stepper>
            )}

            <br />
            <Grid container justifyContent="center">
              <Grid item xs={6}>
                <Paper
                  elevation={3}
                  sx={{
                    borderRadius: "8px",
                    padding: "1rem",
                    border: "1px solid #CCCCC",
                    maxHeight: "400px",
                    overflow: "auto",
                  }}
                >
                  {loadingTable ? (
                    <p>Loading table data...</p>
                  ) : (
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ backgroundColor: "#ecf9ff" }}>
                            <b>Claim Id:</b>
                          </TableCell>
                          <TableCell style={{ backgroundColor: "#ecf9ff" }}>
                            {" "}
                            {lastItemTable && lastItemTable["Grievance Id"]}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ backgroundColor: "#ffffff" }}>
                            <b>Submit Date:</b>
                          </TableCell>
                          <TableCell style={{ backgroundColor: "#ffffff" }}>
                            {FirstItemTable && FirstItemTable["Submit Date"]}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ backgroundColor: "#ecf9ff" }}>
                            <b>Current Status:</b>
                          </TableCell>
                          <TableCell style={{ backgroundColor: "#ecf9ff" }}>
                            {lastItemTable && lastItemTable["Current Status"]}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ backgroundColor: "#ffffff" }}>
                            <b>Current Status Since:</b>
                          </TableCell>
                          <TableCell style={{ backgroundColor: "#ffffff" }}>
                            {lastItemTable && lastItemTable["Prev Status"]}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <ErrorAlert
        open={errorAlertOpen}
        handleOk={handleOkErrorAlert}
        title="Error"
        body={error}
        buttonName="OK"
      />
    </div>
  );
};

export default AdvanceStatusTracking;
