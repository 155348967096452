import React from 'react';
import { List, ListItem, ListItemButton, ListItemText, Collapse, ThemeProvider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import UserGuideTheme from './UserGuideTheme';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';


const TableOfContents = ({ sections, selectedSection, onSectionSelect }) => {
  const handleSectionToggle = (section) => {

    onSectionSelect(section === selectedSection ? null : section);
  };

  const scrollIntoView = (section) => {
    
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    //<div class="containertoc">
    <ThemeProvider theme={UserGuideTheme}>
         <List component="nav" >
      {sections.map((section, index) => (
        <React.Fragment key={index}>
          <ListItemButton  style={{ color: UserGuideTheme.palette.secondary.main}}  onClick={() => {handleSectionToggle(section.title); scrollIntoView(section.title);}}>
            <ListItemText  
           //style={{ color: UserGuideTheme.palette.secondary.main}}  primary={section.title.replace('-', '\u00AD')} 
           style={{ color: UserGuideTheme.palette.secondary.main}}
           sx={{textAlign : 'left' , display:'inline'}}
           primary={section.title.replace('-', '\u00AD')}
            />

            {selectedSection === section.title ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButton>
          <Collapse in={selectedSection === section.title} timeout="auto" unmountOnExit>
          
            <List component="div" disablePadding>
              {section.details.map((detail, detailIndex) => (
                
                
                <ListItem key={detailIndex}>
          <ListItemButton  style={{ color: UserGuideTheme.palette.secondary.main , padding: '0px 8px 0px 16px'}}  onClick={() => {handleSectionToggle(section.details[detailIndex]);
             scrollIntoView(section.details[detailIndex]);
          // console.log("detailIndex",detailIndex);
          // console.log("detail Mapped",section.details[detailIndex])
          }}>
                  
                  <ListItemText  
                  //style={{ color: UserGuideTheme.palette.secondary.main}} primary={detail}
                 style={{ color: UserGuideTheme.palette.secondary.main }}
                 sx={{textAlign : 'left' , display:'inline'}}
                 primary={detail}
                  
                  />
          </ListItemButton>
          {/* <Collapse in={selectedSection === section.details[detailIndex]} timeout="auto" unmountOnExit></Collapse> */}

                </ListItem>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      ))}
    </List>
    </ThemeProvider>
   
    //</div>
  );
};

export default TableOfContents;
// scrollIntoView(section.detail[detailIndex]);