import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import Modal from "@mui/material/Modal";
import SubtitleText from "../../FormComponent/SubtitleText";
import MemberDetails from "../UDAPages/MemberDetatils";
import CaptionText from "../../FormComponent/CaptionText";
import BackButton from "../../components/button/BackButton";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ViewDocuments from "../../SettlementComponents/ViewDocuments";
import CustomTextArea from "../../FormComponent/CustomTextArea";
import LabelText from "../../FormComponent/LabelText";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import Reloader from "../../FormComponent/Reloader";
import LoadingIndicator from "../../FormComponent/LoadingIndicator";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import SucessAlert from "../../FormComponent/SuccessAlert";
import ConfirmationDialogue from "../../FormComponent/ConfirmationDialogue";
import ClaimDetailsPf from "../../SettlementComponents/ClaimDetailsPf";
import UploadDocuments from "./../../SettlementComponents/UploadDocument";
import TableWithLinkAndButton from "../../FormComponent/TableWithLinkAndButton";
import jwtDecode from "jwt-decode";

function ViewPfClaimAgm() {
  const { state } = useLocation();
  const claimId = state.passingId;
  // const claimId = "PF1000001";
  const claimType = claimId.substring(0, 2);

  const navigate = useNavigate();
  const sampleMemberData = {
    // This is the sample claimData data to be displed for demo purpose only
    title: "",
    fullName: "",
    dateOfBirth: "",
    panNo: "",
    aadhaarNumber: "",
    maritalStatus: "",
    religionName: "",
    gender: "",
    fatherHusbandName: "",
    email: "",
    mobileNo: "",
    landNo: "",
    addressL1: "",
    addressL2: "",
    village: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  };

  const sampleDocumentData = {
    data: {
      0: {
        DocTypeid: "",
        Document: "",
      },
      1: {
        DocTypeid: "",
        Document: "",
      },
    },
  };

  const sampleClaimPf = {
    claimDetails: [
      {
        claimId: "",
        fullName: "",
        cmpfAccNo: "",
        unitCode: "",
        submissionDate: "",
        remarks: "",
        nameAadhaar: "",
        nameBank: "",
        namePan: "",
        dobInAadhaar: "",
        dobInPan: "",
        sfwhName: "",
        sfwhRelationDesc: "",
        pfClaimType: "",
        pfClaimStatus: "",
        claimantId: "",
      },
    ],
    officerRemarks: [
      {
        remarks: "",
        status: "",
        fromLoginId: "",
      },
    ],
  };

  const [pfClaimData, setPfClaimData] = useState(sampleClaimPf);
  const [memberData, setMemberData] = useState(sampleMemberData);
  const [isMemberFound, setIsMemberFound] = useState(" ");
  const [documents, setDocuments] = useState([]);
  const [assignToOption, setAssignToOption] = useState([]);
  const [assignToOptionAgm, setAssignToOptionAgm] = useState([]);

  //**********for integration************* */
  const [isLoading, setIsLoading] = useState(true); // set this to true
  const [isLoadingDoc, setIsLoadingDoc] = useState(true); // set this to true
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isFailedDoc, setIsFailedDoc] = useState(false);
  const [isReturning, setIsReturning] = useState(false);
  const [isReturnClicked, setIsReturnClicked] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [showingDropdown, setShowingDropdown] = useState(0);
  const [selectedOfficerName, setSelectedOfficerName] = useState("");
  const [uploadedDocs, setUploadedDocs] = useState({});

  // const [remarks, setRemarks] = useState("");
  const defaultClaim = {
    remarks: "",
    assignTo: "",
    assignToAgmm: "",
  };

  // declaring the default api object

  let api = {
    claimUrl: "",
    docsUrl: "",
    returnUrl: "",
    forwardUrl: "",
    forwordAgmUrl: "",
    officerDropdownUrl: "",
    agmDropdown: "",
    memberDetailsUrl: "",
    familyMemDetails: "",
  };
  // object for pfapis
  //let loginId = "AGM-CC-OFF-106"; //! Assuming a default loginId
  const [loginId, setLoginId] = useState();

  /********fetching and setting token********* */
  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (token) {
      token = token.replace('"', "").replace('"', "");
      let loginId = jwtDecode(token).user; // Assuming a default loginId
      setLoginId(loginId);
      console.log("loooooooooooooooooooooginId", loginId);
      // Add any further logic here based on the loginId
    } else {
      // Handle the case when token is not present
      setIsLoading(false);
      setIsFailed(true);
    }
  }, []);
  const pfApis = {
    // claimUrl: "/show/pf/claim/on/claimid",
    claimUrl: "/show/pf/claim/details/to/SI/on/claimid",
    docsUrl: "/show/pf/claim/uploaded/documents/claimid",
    returnUrl: "/verification/pf/claim/officers/agm/to/ids",
    forwardUrl: "/verification/pf/claim/officers/agm/to/ids",
    officerDropdownUrl:
      "/dropdown/for/show/next/authorized/offcr/adv/pf/pen/agm",
    memberDetailsUrl: "/member/details/for/pf",
    familyMemDetails: "/get/all/nominee/details",
    documentUpload: "/documents/upload/by/roda",
  };

  const penApis = {
    claimUrl: "/show/pen/claim/on/claimid",
    docsUrl: "/show/submitted/documents/pen/claim",
    returnUrl: "/verification/pen/claim/officers/agm/to/ids",
    forwardUrl: "/verification/pen/claim/officers/agm/to/ids",
    officerDropdownUrl:
      "/dropdown/for/show/next/authorized/offcr/adv/pf/pen/agm",
    memberDetailsUrl: "/member/details/for/pf",
    familyMemDetails: "/get/all/nominee/details",
    documentUpload: "/upload/document/at/roda",
  };

  let title = "";
  if (claimType === "PF") {
    api = { ...pfApis };
    title = "PF";
  } else if (claimType === "PN") {
    api = { ...penApis };
    title = "Pension";
  }

  const header = `${title} Settlement`;

  const documentTypes = [{ id: "14", title: "Genuine Certificate" }];
  const [claim, setClaim] = useState(defaultClaim);

  const assignTo = {
    name: "assignTo",
    placeholder: "Select Officer",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "",
    required: true,
    options: assignToOption,
  };

  const remarks = {
    name: "remarks",
    type: "text",
    multiline: true,
    helperText: "Enter your remarks (maximum 200 words)",
    placeholder: "Enter your remarks here",
    defaultValue: claim.remarks,
    errorMessage: "Cannot exceed 200 characters",
    pattern: "^.{0,200}$",
    maxLength: 200, // Limiting to 200
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  //********DEFAULT for family details*********/
  const familyDefault = {
    familyMemId: "",
    name: "",
    gender: "",
    relationName: "",
    dateOfBirth: "",
    pfNomSharePerc: "",
  };

  const [nomineeDetails, setNomineeDetails] = useState([familyDefault]);

  const viewLink = "familydetails"; // Link for family members details

  //****************Dropdown******************* */
  const fetchAssignOfficer = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + api.officerDropdownUrl;

      // "/dropdown/for/adv/pf/claim/aao/to/ids";
      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      const options = data.map((option) => ({
        id: option.toOfficerlogin,
        title: `${option.officerName} (${option.toOfficerlogin})`,
      }));
      setAssignToOption(options); // Assuming setAssignToOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchAssignOfficer();
  }, [loginId]);

  const fetchClaimDetailAdv = async () => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");

    const url = process.env.REACT_APP_SERVER_URL + api.claimUrl;

    const formData = new FormData();
    formData.append("claimId", claimId);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          setPfClaimData(data);
          fetchMemberDetail(data.claimDetails[0].cmpfAccNo);
          fetchDocuments(data.claimDetails[0].claimantId);
        }
        //setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
      });
  };

  const fetchAllClaimDetail = () => {
    fetchClaimDetailAdv();
  };

  const postUploadedDoc = () => {
    let uploadDocData;
    if (claimType === "PF") {
      uploadDocData = {
        claimId: claimId,
        claimFlag: "PF",
        claimantId: pfClaimData.claimDetails[0].claimantId,
        uploadDocumentsByRoda: uploadedDocs,
      };
    } else {
      uploadDocData = {
        claimId: claimId,
        // claimFlag: "PN",
        // claimantId: pfClaimData.claimDetails[0].claimantId,
        uploadedDocuments: uploadedDocs,
      };
    }
    console.log("uploadDocData", uploadDocData);
    const url = process.env.REACT_APP_SERVER_URL + api.documentUpload;
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, uploadDocData, { headers })
      .then(function (response) {
        console.log("Submit response", response);
        setIsSubmitting(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Forwarded Successfully.");
      })
      .catch((error) => {
        setIsError(true);

        setErrorMessage(
          "Sorry Error while submitting Documents. Please Try again."
        );

        console.log("error on submit documents", error);
      });
  };

  const fetchDocuments = async (claimantId) => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + api.docsUrl;

      const formData = new FormData();
      formData.append("claimId", claimId);
      formData.append("claimantId", claimantId);

      const config = {
        headers: {
          // Include the Authorization header if req uired
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(url, formData, config);

      setIsLoadingDoc(false);
      setIsFailedDoc(false);

      if (response.status === 200) {
        const data = response.data.data;
        setDocuments(data);
      } else {
        // setIsFailed(true);
      }
    } catch (error) {
      // Corrected syntax for the catch block
      setIsLoading(false);
      setIsFailed(true);
    }
  };

  useEffect(() => {
    fetchAllClaimDetail();
  }, []);

  //! cmpf account number is fetched on useEffect with a delay of 3sec to avoid multiple api hits
  const onChange = (e) => {
    if (e.target.name === "assignTo") {
      const selectedOption = assignToOption.find(
        (option) => option.id === e.target.value
      );
      if (selectedOption) {
        setSelectedOfficerName(selectedOption.title); // Set the selected officer's name in the state
        console.log("selctess", selectedOption.title);
      }
    }
    setClaim({ ...claim, [e.target.name]: e.target.value });
  };

  /// ******** on forword to ids function

  const objectTobeSent = {
    fromLoginId: loginId,
    remarks: claim.remarks,
    toLoginId: claim.assignTo,
    claimId: claimId,
    approvalStatus: true,
  };

  const handleSubmitOk = () => {
    if (!checked) {
      // Show message in dialog: "Please select check Box"
      // alert("Please select the check box");
      setIsError(true);
      setErrorMessage("Please Select the Declaration Checkbox.");
      return;
    }

    if (!objectTobeSent.toLoginId) {
      // Show message in dialog: "Please select an officer to assign"
      // alert("Please select an officer to assign");
      setIsError(true);
      setErrorMessage("Please Select an Officer to Assign.");
      return;
    }
    setIsSubmitClicked(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    const url = process.env.REACT_APP_SERVER_URL + api.forwardUrl;

    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSent, { headers })
      .then(function (response) {
        if (Object.keys(uploadedDocs).length !== 0) {
          postUploadedDoc();
        }
        setIsSubmitting(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Forwarded Successfully.");
      })
      .catch((error) => {
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
        console.log("error on submit", error);
      });
  };

  // todo handleAssignToOk calling handle submit on click of ok confirmation dialouge box
  const handleAssignToOk = (e) => {
    handleSubmit(e);
    setIsSubmitClicked(false);
  };

  const handleAssignToCancel = () => {
    setIsSubmitClicked(false);
  };

  /// ******** on Return function

  const objectTobeSentOnReturn = {
    fromLoginId: loginId,
    remarks: claim.remarks,
    toLoginId: "",
    claimId: claimId,
    approvalStatus: false,
  };

  const handleReturnClick = (e) => {
    e.preventDefault();

    if (!claim.remarks.trim()) {
      // Show message in dialog: "Please enter remarks"
      // alert("Please enter remarks");
      setIsError(true);
      setErrorMessage("Please enter Remarks.");
      return;
    }

    if (!checked) {
      // Show message in dialog: "Please select check Box"
      //alert("Please select the check box");
      setIsError(true);
      setErrorMessage("Please Select the Declaration Checkbox.");
      return;
    }

    setIsReturning(true);
    setIsReturnClicked(true);
  };
  const handleReturnClaim = (e) => {
    // e.preventDefault();

    const url = process.env.REACT_APP_SERVER_URL + api.returnUrl;
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSentOnReturn, { headers })
      .then(function (response) {
        setIsReturning(false);
        setIsSuccess(true);
        setSuccessMessage("Claim Return Successfully.");
        setIsReturnClicked(false);
      })
      .catch((error) => {
        console.log("error on submit", error);
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
      });
  };

  ///****************integration started********************* */

  const fetchMemberDetail = async (accNo) => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + api.memberDetailsUrl;
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          if (data.length !== 0) {
            setMemberData(data[0]);
            setIsMemberFound("true");
            fetchNomineeDetail(data[0].cmpfAccNo);
          }
          setIsMemberFound("false");
          return;
        }
        // setIsFailed(true);
        setIsMemberFound("false");
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        setIsMemberFound("false");
      });
  };

  /***** function to get all nominee details**************** */
  const fetchNomineeDetail = async (accNo) => {
    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");

    const url = process.env.REACT_APP_SERVER_URL + api.familyMemDetails;
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo); // check what formdata is to be sent
    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsLoading(false);
        setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          if (data.length !== 0) {
            setNomineeDetails(data);
          }

          return;
        }
        // setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
      });
  };

  const createTablePreviewDataReturn = () => {
    console.log("from preview", nomineeDetails);
    // the functions that creates a table preview
    //const tableSamples = familyMemberSamples; //familyMemberSamples must be replaced witht he response data recevied on the api request
    const tableSamples = nomineeDetails;
    let tablePreviewData = [];
    tableSamples.map((member) => {
      tablePreviewData.push({
        "Family Member ID": member.familyMemId,
        "Name of Nominee": member.name,
        Gender: member.gender,
        Relation: member.relationName,
        "Date of Birth": member.dateOfBirth,
        "Share in percentage": member.pfNomSharePerc,
      });
    });
    return tablePreviewData;
  };

  /// error alert handler
  const handleErrorAlert = () => {
    setIsError(false);
  };

  const handleSuccess = () => {
    // setIsSuccess(false);
    if (claimType === "PF") {
      navigate("/agm/pfset");
    } else if (claimType === "PN") {
      navigate("/agm/penset");
    }
  };

  const handleCancelReturn = () => {
    setIsReturnClicked(false);
  };
  const handleReturnOk = () => {
    handleReturnClaim();
  };

  const handleIdsOk = (e) => {
    setShowingDropdown(1);
  };

  // Handler function to update the DOCS from child
  const handleStateUpdate = (documentData) => {
    const requestBody = {
      cmpfAcNo: "",
      uploadAdvPfDocumentsDTO: documentData.map((upload) => ({
        fileContent: upload.uploadedDocument,
        docTypeId: upload.selectedDocumentType,
      })),
    };
    setUploadedDocs(requestBody.uploadAdvPfDocumentsDTO);
    // console.log("requestBody", requestBody);
  };

  return (
    <div>
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error!"
        body={errorMessage}
        buttonName="Ok"
      />
      <SucessAlert
        handleOk={handleSuccess}
        open={isSuccess}
        title="Success"
        body={successMessage}
        buttonName="OK"
      />
      <ConfirmationDialogue
        handleOk={handleAssignToOk}
        handleCancel={handleAssignToCancel}
        open={isSubmitClicked}
        title="Confirm Assignment"
        body={`Are you sure you want to assign the claim to ${selectedOfficerName} ?`}
        buttonName="Confirm"
      />
      <ConfirmationDialogue
        handleOk={handleReturnOk}
        handleCancel={handleCancelReturn}
        open={isReturnClicked}
        title="Confirm Return"
        body="Are you sure you want to return this claim ?"
        buttonName="Confirm"
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "10px" }}>
            {isFailed || isFailedDoc ? (
              <Reloader refreshHandler={fetchAllClaimDetail} />
            ) : (
              <>
                {isLoading || isLoadingDoc ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        <TitleText title={header} />
                      </Grid>
                    </Grid>
                    <FormDivider />
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={1} mb={3}>
                        <BackButton />
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="start"
                        rowGap={3}
                      >
                        {/* Member Details*/}
                        {/* section 1  */}
                        <Paper
                          elevation={3}
                          sx={{ padding: "3rem", borderRadius: "10px" }}
                        >
                          <Grid item xs={12}>
                            <MemberDetails member={memberData} />
                          </Grid>
                          <Grid item xs={12} mt={4}>
                            <SubtitleText title="Nomination Status" />
                          </Grid>
                          <Grid item xs={12}>
                            <TableWithLinkAndButton
                              preview={createTablePreviewDataReturn()}
                              data={nomineeDetails}
                              passingId="familyMemberid"
                              actions=""
                              viewLink={viewLink}
                            />
                          </Grid>
                        </Paper>
                        {/* claim Details*/}
                        {/* section 2  */}
                        <Paper
                          elevation={3}
                          sx={{ padding: "3rem", borderRadius: "10px" }}
                        >
                          <Grid item xs={12}>
                            <ClaimDetailsPf pfclaimdetails={pfClaimData} />
                          </Grid>
                        </Paper>
                        {/* Document Details*/}
                        {/* section 3  */}
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={12}>
                            <Paper
                              elevation={3}
                              sx={{ padding: "3rem", borderRadius: "10px" }}
                            >
                              <Grid item xs={12}>
                                <ViewDocuments documents={documents} />
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>

                        {/* ************************************ upload documents ***************************************** */}
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={12}>
                            <Paper
                              elevation={3}
                              sx={{ padding: "3rem", borderRadius: "10px" }}
                            >
                              <Grid item xs={12}>
                                <SubtitleText title="Upload Genuine Certificate" />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <UploadDocuments
                                  documentTypes={documentTypes}
                                  onSave={handleStateUpdate}
                                />
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>

                        {/* ************************************ Remarks ***************************************** */}
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={12}>
                            <Paper
                              elevation={3}
                              sx={{ padding: "2rem", borderRadius: "10px" }}
                            >
                              <SubtitleText title="Remarks" />
                              <Grid item xs={12} sm={6} md={8}>
                                <CustomTextArea
                                  {...remarks}
                                  onchange={onChange}
                                />
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>
                        {/* *************** Declaration ********* */}
                        <Grid item xs={12} mt={3}>
                          <Paper
                            elevation={0}
                            sx={{ padding: "3rem", borderRadius: "8px" }}
                          >
                            <TitleText title="Declaration" />
                            <FormDivider />
                            <Grid item xs={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 2,
                                  padding: 2,
                                  justifyContent: "flex-start",
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={checked}
                                      onChange={handleChange}
                                      color="success"
                                      sx={{
                                        marginTop: -1,
                                        marginRight: -4,
                                      }}
                                    />
                                  }
                                />
                                <CaptionText
                                  title="All details have been verified with the service record and all documents have been verified with the original documents of the claim and found correct."
                                  sx={{
                                    margin: 0,
                                  }}
                                />
                              </Box>
                            </Grid>
                          </Paper>
                        </Grid>
                        {/* *************** Submit Button ********* */}
                        <Grid container justifyContent="space-between" mb={2}>
                          <Grid item>
                            <Button
                              onClick={handleReturnClick}
                              variant="contained"
                              color="primary"
                              type="submit"
                              disabled={isReturnClicked}
                            >
                              {isReturnClicked ? (
                                <CircularProgress />
                              ) : (
                                "Return"
                              )}
                            </Button>
                          </Grid>
                          <Grid item md={6}>
                            <Grid
                              container
                              justifyContent="end"
                              mb={2}
                              spacing={2}
                            >
                              <Grid item md={4.5}>
                                <Button
                                  onClick={handleIdsOk}
                                  variant="contained"
                                  color="success"
                                  type="submit"
                                  // disabled={isReturnClicked}
                                  fullWidth
                                >
                                  Forward to ro
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {showingDropdown === 1 && (
                      <Grid
                        container
                        justifyContent="end"
                        alignItems={"center"}
                        spacing={2}
                      >
                        <Grid item xs={12} sm={4}>
                          <CustomDropdown {...assignTo} onchange={onChange} />
                        </Grid>
                        <Grid item md={1}>
                          <Button
                            onClick={handleSubmitOk}
                            variant="contained"
                            color="success"
                            type="submit"
                            disabled={isSubmitting}
                            fullWidth
                          >
                            {isSubmitting ? <CircularProgress /> : "OK"}
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default ViewPfClaimAgm;
