import React from "react";
import { Typography } from "@mui/material";
import ForgotPasswordImage from '../../../../images/ForgotPasswordImage.png';
import LoginforForgotPassword from '../../../../images/LoginforForgotPassword.png';
import VerifyOTP from '../../../../images/VerifyOTP.png';
import SetNewPassword from '../../../../images/SetNewPassword.png';
import PasswordUpdation from '../../../../images/PasswordUpdation.png';
const ForgetPasswordFirst=()=>{
    return(<>
    <Typography variant="h6">FORGOT PASSWORD</Typography>
    <Typography varient="body1" align="justify">
    If member forgets the password, it can be reset using forget password feature.


    <ol type="1">

        <li>Go to Member Login page. Below the SIGN-IN button one can find FORGOT PASSWORD written in orange. Click on it. (Figure 34)

        </li>
        <li>
        Forgot Password page opens having Login Id field. Enter the correct login id/account no. and click on send OTP button. (Figure 35)
        <p align="center"> <img  className="bordered-image" src={ForgotPasswordImage} height="400px" width="75%"/><br />
       Figure 34: Forgot Password</p>
       <p align="center"> <img  className="bordered-image" src={LoginforForgotPassword} height="400px" width="75%"/><br />
            Figure 35: Login for Forgot Password</p>

        </li>
        <li>
        Enter the valid OTP and click verify button (Figure 36). On successful OTP verification, password reset page appears (Figure 37).
        <p align="center"><img  className="bordered-image" src={VerifyOTP} height="350px" width="75%"/><br />
            Figure 36: Verify OTP</p>
            <p align="center"><img  className="bordered-image" src={SetNewPassword} height="390px" width="70%"/><br />
           Figure 37: Set New Password</p>


        </li>
        <li>
        Enter the New Password in the fields given and click Change Password button. (Figure 37). For setting New Password, follow the password format described earlier in section 2.3.1.

        </li>
        <li>
        On success, a message is displayed stating “Password is updated” (Figure 38)
        <p align="center"><img  className="bordered-image" src={PasswordUpdation} height="220px" width="75%"/><br />
            Figure 38: Password Updation</p>
            After successful password change, user/member is redirected to home page and an email is sent on the registered email id of the member for successful password updation.
            

        </li>
    </ol>
    </Typography>
    
    </>);
}
export default ForgetPasswordFirst;