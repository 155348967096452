import Header from "../../components/Header";
import Footer from "../LandingPage/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Tab,
  Avatar,
  Card,
  CardContent,
  CircularProgress,
  Typography,
  Tabs,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Grid,
  Paper,
  Divider,
  Container,
  TextField,
  Box,
  AppBar,
} from "@mui/material";
import {
  CaptchaProvider,
  CaptchaVerifyContext,
} from "../../components/captcha/CaptchaProvider";
import Form from "../../components/captcha/CaptchaFormLogin";
import ValueText from "../../FormComponent/ValueText";
// import FormDivider from '../../../FormComponent/FormDivider';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import qs from "qs";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import LogoutAlert from "../../FormComponent/LogOutAlert";
import Footbar from "../LandingPage/Footbar";
import './OfficerBar.css';
import CampaignIcon from '@mui/icons-material/Campaign';


const TabPanel = ({ children, value, index }) => (
  <div hidden={value !== index} role="tabpanel">
    {value === index && <Box>{children}</Box>}
  </div>
);

function MemberLogin() {
  const theme = useTheme();
  const currentPage = useLocation();
  const navigate = useNavigate();

  const [login, setLogin] = useState();
  const [title, setTitle] = useState("Member Login");

  //password validation
  const [passwordError, setPasswordError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [failedErrorMsg, setFailedErrorMessage] = useState(
    "Something went wrong"
  );
  const [failedErrorMsgAlert, setFailedErrorMessageAlert] = useState(false);
  const [sessionActiveAlert, setSessionActiveAlert] = useState(false);

  // useRef to pass otp msg
  const otpMessageRef = useRef(null);

  const dataFormate = {
    loginId: "",
    password: "",
    role: "Member or Employee",
  };
  const [formData, setFormData] = useState(dataFormate);

  const [isOTPButtonDisabled, setIsOTPButtonDisabled] = useState(true);

  const otpButtonDisableFuncEnable = () => {
    setIsOTPButtonDisabled(false);
  };
  const otpButtonDisableFuncDisable = () => {
    setIsOTPButtonDisabled(true);
  };

  const validatePassword = (password) => {
    // Validate password: minimum uppercase, lowercase, special character, and number without space
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_])[^\s]{8,20}$/;
    return regex.test(password);
    //return true when password met the criteria
  };

  const validateUserName = (username) => {
    // Validate userName: alphanumeric,/ and max 20char
    const regex = /^[a-zA-Z0-9/]{1,20}$/;
    return regex.test(username);
    //return true when password met the criteria
  };

  const validatePasswordField = (password) => {
    if (!validatePassword(password)) {
      setPasswordError(
        "Password must contain at least 8 characters and maximum of 20 characters, including uppercase, lowercase,number and special character except space."
      );
      return false;
    }
    setPasswordError("");
    return true;
  };

  const validateUserNameField = (username) => {
    if (!validateUserName(username)) {
      setUserNameError("Enter valid username.");
      return false;
    }
    setUserNameError("");
    return true;
  };

  const buttonDisabledfunc = () => {
    setButtonDisabled(false);
  };

  const buttonEnablefunc = () => {
    setButtonDisabled(true);
  };

  const onChange = (e) => {
    console.log("username", e.target.value);
    if (e.target.name === "loginId") {
      validateUserNameField(e.target.value);
    }
    if (e.target.name === "password") {
      validatePasswordField(e.target.value);
    }
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.name === "password"
          ? window.btoa(e.target.value)
          : e.target.value.toUpperCase(),
    });
  };

  const onLoginIdBlur = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value.toUpperCase() });
  };

  const sendOTP = () => {
    sessionStorage.removeItem("messageRef");
    const sendData = qs.stringify({ loginId: formData.loginId });
    axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/member/send/login/otp",
        sendData
      )
      .then((resp) => {
        if (resp.status === 200) {
          setIsLoading(false);
          //clear form
          otpMessageRef.current = resp.data.message;
          navigate("otp", {
            replace: true,
            state: {
              data: formData,
              purpose: "LOGIN",
              messageRef: otpMessageRef,
            },
          });
          return;
        }
        //check the otp msg
        otpMessageRef.current = "Something went wrong. Try agin";
        navigate("otp", {
          replace: true,
          state: {
            data: formData,
            purpose: "LOGIN",
            messageRef: otpMessageRef,
          },
        });
      })
      .catch(function (error) {
        console.log("error otp", error);
        setIsLoading(false);
        otpMessageRef.current = error.response.data.error_message;
        navigate("otp", {
          replace: true,
          state: {
            data: formData,
            purpose: "LOGIN",
            messageRef: otpMessageRef,
          },
        });
        return;
      });
    return;
  };

  const isAuthValid = () => {
    return axios
      .post(process.env.REACT_APP_SERVER_URL + "/member/login/find", formData)
      .then((resp) => {
        console.log("find", resp);
        if (resp.status === 200) {
          return true;
        }
        //    setFailedErrorMessage(resp.data.error_message);
        //     setFailedErrorMessageAlert(true);
        //      setButtonDisabled(false);
        //     return false;
      })
      .catch(function (error) {
        setIsLoading(false);
        setFailedErrorMessage(
          error.response
            ? error.response.data.error_message
            : "Server is not responding. Please try again later."
        );
        setFailedErrorMessageAlert(true);
        setButtonDisabled(false);
        return false;
      });
  };

  const validRequiredFilds = () => {
    let flag = true;
    let mandatory = ["loginId", "password"];
    for (let key in formData) {
      if (mandatory.includes(key)) {
        if (!formData[key]) {
          flag = false;
        }
      }
    }
    return flag;
  };

  const memberLogin = () => {
    setButtonDisabled(true);
    setIsLoading(true);
    isAuthValid().then((resp) => {
      console.log("auth valid", resp);
      if (!resp) {
        setIsLoading(false);
        // captchaContext.resetCaptcha();
        //member not found
        return;
      }
      //member found
      sendOTP();
    });
  };

  const isAnotherSessionActive = () => {
    const token = localStorage.getItem("jwt");
    if (!token) {
      return false;
    }
    return true;
  };

  const handleLogOut = () => {
    //logout button clicked show popup
    setSessionActiveAlert(true);
  };

  const handleLogoutCancel = () => {
    setSessionActiveAlert(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validRequiredFilds()) {
      setFailedErrorMessage("Provide Username and Password");
      setFailedErrorMessageAlert(true);
      return;
    }
    if (formData.role === "Member or Employee") {
      if (isAnotherSessionActive()) {
        //show alert
        handleLogOut();
        return;
      }
      memberLogin();
      return;
    }
  };

  const handleAlert = () => {
    setFailedErrorMessageAlert(false);
    window.location.reload();
  };

  const handleKeyDown = (event) => {
    if (event.code === "Space") {
      event.preventDefault();
    }
  };

  const handleForgotpasswordClick = () => {
    navigate("/forgotpassword", { replace: true });
  };

  const handleCopy = (event) => {
    event.preventDefault();
  };

  return (
    <>
     <div className="pattern-overlay"></div>

      <LogoutAlert
        open={sessionActiveAlert}
        closeAlert={handleLogoutCancel}
        title="Alert!!"
        body="Already logged in. Our policy does not allow multiple sessions."
        user="member"
      />
      <ErrorAlert
        handleOk={handleAlert}
        open={failedErrorMsgAlert}
        title="Error!"
        body={failedErrorMsg}
        buttonName="Ok"
      />
      <Header />
      <AppBar
        position="static"
        sx={{
          height: "40px",
          background:
            "linear-gradient(90deg, rgba(128,17,59,1) 0%, rgba(148,23,189,1) 69%, rgba(13,1,39,1) 100%)",
          alignItems:"left",
          height: '80%',
            
        }}
        
      >
       
        <Typography variant="h5" align="left"  sx={{
          fontSize:{
            xs: '17px',
            sm: '18px',
            md: '19px',
            lg: '20px',
            xl: '21px',
          },
           fontWeight:"500" ,
          padding: ".2%",
          marginRight:"10px",
           '&:hover': {
            fontSize:{
              xs: '19px',
              sm: '20px',
              md: '21px',
              lg: '22px',
              xl: '23px',
            },
            fontWeight: '700', // Change font weight on hover
          },
        }}>
          
           <CampaignIcon align="right" sx={{ fontSize: '48px',paddingBottom:"5px", transition:"transform:0.3s" ,
           '&:hover': 
           {transform: 'translateX(5px)'}
          }}/>Notifications
           
        </Typography>
        
        </AppBar>
      <Divider />
      <Grid
        container
        justifyContent="center"
        sx={{ marginTop: "20px", wordWrap: "break-word" }}
      >
        <Grid item xs={10} sm={12} md={11} lg={10}>
          <Paper elevation={0} sx={{ borderRadius: "8px" }}>
            <Grid container justifyContent="center" alignItems="start">
              <Grid item xs={12}>
                <div style={{ padding: "0px 8px" }}>
                  <marquee
                    direction="left"
                    scrollamount="4"
                    style={{ color: "red", fontSize: "14px" }}
                  >
                    Member data is being uploaded in batches on daily basis. If
                    you are not able to enrol, kindly try after some days.
                  </marquee>
                </div>
              </Grid>

              <Grid item xs={12} sm={7}>
                <Container sx={{ paddingTop: "2rem" }}>
                  <Grid container justifyContent="center" rowSpacing={2}>
                    <Grid item xs={12} sm={12}></Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        component="div"
                        align="left"
                        sx={{
                          color: "#1e1e1e",
                          fontWeight: "500",
                        }}
                      >
                        Guidelines
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Divider
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          height: "3px",
                          margin: 0,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Box
                        sx={{
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          paddingTop: "10px",
                        }}
                      >
                        <ul style={{ padding: 0 }}>
                          <li>
                            <ValueText title="Username is your CMPF Account Number." />
                          </li>
                          <li>
                            <ValueText title="Enter your full CMPF Account Number." />
                          </li>
                          <li>
                            <ValueText title="General format of CMPF Account Number is ABC/11/111" />
                            <ul>
                              <li>
                                <ValueText title="First three/four characters are Area Code" />
                              </li>
                              <li>
                                <ValueText title="Next two/three digits are Unit Code" />
                              </li>
                              <li>
                                <ValueText title="Next two/three/four digits are Serial Number" />
                              </li>
                            </ul>
                          </li>
                          <li>
                            <ValueText title="All leading zeros should be removed from the account number. For example: ABC/011/00111 should be entered as ABC/11/111. " />
                          </li>
                          <li>
                            <ValueText title="Any '-' should be replaced by '/'. For example: ABC-11-111 should be entered as ABC/11/111" />
                          </li>

                          <li>
                            <ValueText title="Other valid examples of CMPF Account Number:" />
                            <ul>
                              <li>
                                <ValueText title="H/9/99/999" />
                              </li>
                              <li>
                                <ValueText title="ABC/99/A/99" />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </Grid>

              <Grid
                item
                xs={12}
                sm={5}
                sx={{ borderLeft: "1px solid #e6e8e3" }}
              >
                <Container sx={{ padding: "2rem" }}>
                  <Grid container justifyContent="center" rowSpacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Avatar
                          sx={{
                            backgroundColor: "#1bbd7e",
                            width: 40,
                            height: 40,
                          }}
                        >
                          <FontAwesomeIcon icon={faLock} />
                        </Avatar>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        component="div"
                        align="center"
                        sx={{
                          color: "#1e1e1e",
                          fontWeight: "500",
                        }}
                      >
                        Member Login
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        autoComplete="off"
                        id="userName"
                        value={formData.loginId}
                        onBlur={onLoginIdBlur}
                        label="Username"
                        name="loginId"
                        onChange={onChange}
                        variant="outlined"
                        placeholder="Enter Username"
                        error={Boolean(userNameError)}
                        helperText={
                          Boolean(userNameError)
                            ? userNameError
                            : "Account number cannot exceed 20 characters."
                        }
                        fullWidth
                        required
                        inputProps={{
                          maxLength: 20,
                          onCopy: handleCopy,
                          // style:{textTransform:'uppercase'}
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        autoComplete="off"
                        id="password"
                        type="password"
                        name="password"
                        onChange={onChange}
                        label="Password"
                        variant="outlined"
                        placeholder="Enter Password"
                        fullWidth
                        onKeyDown={handleKeyDown}
                        inputProps={{ maxLength: 20, onCopy: handleCopy }}
                        error={Boolean(passwordError)}
                        helperText={Boolean(passwordError) ? passwordError : ""}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <CaptchaProvider>
                        <Form
                          buttonEnablefunc={buttonEnablefunc}
                          buttonDisabledfunc={buttonDisabledfunc}
                          otpButtonDisableFuncEnable={
                            otpButtonDisableFuncEnable
                          }
                          otpButtonDisableFuncDisable={
                            otpButtonDisableFuncDisable
                          }
                        />
                      </CaptchaProvider>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Button
                        type="submit"
                        color="success"
                        fullWidth
                        variant="contained"
                        // style={btstyle}
                        onClick={handleSubmit}
                        disabled={buttonDisabled}
                      >
                        {isLoading ? (
                          <CircularProgress sx={{ color: "green" }} />
                        ) : (
                          "SIGN IN"
                        )}
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Grid item xs={6}>
                        <Typography
                          align="left"
                          sx={{ color: "red", cursor: "pointer" }}
                          onClick={handleForgotpasswordClick}
                        >
                          Forgot Password?
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Footbar />
      <Footer />
    </>
  );
}

export default MemberLogin;
