import { schedule2 } from "./PensionSchedules";

export function lastDayOfPreviousMonth() {
  const today = new Date();
  const firstDayOfThisMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    1
  );
  const lastDayOfPreviousMonth = new Date(firstDayOfThisMonth - 1);
  const options = { day: "numeric", month: "long", year: "numeric" };
  return lastDayOfPreviousMonth.toLocaleDateString("en-GB", options);
}

export function pensionableService(uptoYr, uptoMnt, fromYr, fromMon) {
  //console.log("values entered",uptoYr, uptoMnt, fromYr, fromMon)

  let period = (2 / 3) * (uptoYr + uptoMnt / 12) + (fromYr + fromMon / 12);

  if (period >= 29.5) {
    return 30;
  } else {
    return Math.round(period);
  }
}

//**** Pension on Superannuation *** */

export function monthlyPensionSuperannuation(avgEmouments, pensionableService) {
  let monthlyPension = (avgEmouments / 4) * (pensionableService / 30);
  if (monthlyPension < 350) {
    return 350;
  } else return monthlyPension;
}
//*************************** Resignation *************************************************** */
export function resignationPension(
  avgEmouments,
  pensionableService,
  dateOfCessation,
  dob
) {
  
  let monthlyPension = monthlyPensionSuperannuation(
    avgEmouments,
    pensionableService
  );
  let serviceLeft = calculateYearsOfServiceLeft(dateOfCessation, dob);
  let pensionAmt = monthlyPension * (schedule2[serviceLeft] / 100);
  console.log("factor applicable", schedule2[serviceLeft]);
  return pensionAmt;
}

//************** death case -  widow pension calculations***************/
export function widowPensionInService(montlyPension) {
  return (200 / 3) * montlyPension;
}

export function widowPensionAfterCessation(montlyPension) {
  return (60 / 100) * montlyPension;
}

//**** Pension on disablement *** */

export function disablementPension(avgEmouments) {
  let monthlyPension = avgEmouments / 4;
  if (monthlyPension < 350) {
    return 350;
  } else return monthlyPension;
}
//***** Pension Calculation objects***** */

const superannuation = {
  pensionableServiceInMonths: 0,
  minPension: 0,
  monthlyPension: 0,
  pensionCalculation: function () {
    return this.monthlyPension;
  },
};

const deathInServiceWidow = {
  pensionableServiceInMonths: 0,
  minPension: 0,
  monthlyPension: 0,
  pensionCalculation: function () {
    return (200 / 3) * this.monthlyPension;
  },
};

const deathAfterCessationWidow = {
  pensionableServiceInMonths: 0,
  minPension: 0,
  monthlyPension: 0,
  pensionCalculation: function () {
    return (60 / 100) * this.monthlyPension;
  },
};

const resignationAfter120months = {
  pensionableServiceInMonths: 0,
  minPension: 0,
  monthlyPension: 0,
  pensionCalculation: function () {
    return (60 / 100) * this.monthlyPension;
  },
};

export function calculateYearsOfServiceLeft(dateOfCessation, dob) {
  const RETIREMENT_AGE = 60; // Retirement age defined as 65
  const birthDate = new Date(dob);
  const cessationDate = new Date(dateOfCessation);
  // Convert the dob input into a date object

  // Calculate the difference in years
  let ageOnCessation = cessationDate.getFullYear() - birthDate.getFullYear();
  const monthDifference = cessationDate.getMonth() - birthDate.getMonth();

  // Adjust the age if the current month is before the birth month
  // if (
  //   monthDifference < 0 ||
  //   (monthDifference === 0 && cessationDate.getDate() < birthDate.getDate())
  // ) {
  //   ageOnCessation--;
  // }

  // Calculate years of service left
  const yearsLeft = RETIREMENT_AGE - ageOnCessation;
  console.log("years Left", yearsLeft);

  return yearsLeft > 0 ? yearsLeft : 0; // Return years left or 0 if the retirement age is passed
}
