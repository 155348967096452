import React from 'react';
import { Grid, Paper } from '@mui/material';
import SubtitleText from '../../FormComponent/SubtitleText';
import ValueText from '../../FormComponent/ValueText';
import LabelText from '../../FormComponent/LabelText';
import TitleText from '../../FormComponent/TitleText';
import FormDivider from '../../FormComponent/FormDivider';

function TermsAndCondition() {
  return (
    <Grid container justifyContent="center" sx={{ margin: "20px", wordWrap:'break-word' }}>
      <Grid item xs={10} sm={10} md={8} lg={8}>
        <Paper elevation={3} sx={{ padding: '3rem', borderRadius: '8px' }}>

          <Grid container justifyContent='center' alignContent="center" spacing={2}>
            <Grid item xs={12} sm={12}>
              <TitleText title="TERMS AND CONDITIONS" />
            </Grid>
            <Grid item xs={12}> 
            <FormDivider />
            </Grid>

            <Grid item xs={12} sm={12}>
              <ValueText
                title=
                "The contents of this website are maintained by CMPFO. Though all efforts have been made to ensure the accuracy and currency of the content on this website, the same should not be construed as a statement of law or used for any legal purpose. In case of any ambiguity or doubt, users are advised to verify/check with the department(s) and/or other sources, to obtain appropriate professional advice. Since the contents of the website are dynamic in nature, users are advised, in their own interest, to refer to relevant Government Publications before arriving at any final conclusion. Under no circumstances this department will be liable for any expenses, loss or damage including, without limitation, indirect or consequential loss or damage or any expense, loss or damage whatsoever arising from use, or loss of use, of data arising out of or in connection with the use of this website. These terms and conditions shall be governed by and construed in accordance with the Indian Laws. Any dispute arising under these terms and conditions shall be subject to the jurisdiction of the courts of Dhanbad and Ranchi High Court."
              
              />
            </Grid>
          </Grid>

        </Paper>
      </Grid>
    </Grid>
  )
}

export default TermsAndCondition;
