import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Paper, Button } from "@mui/material";
import TitleText from "../../../FormComponent/TitleText";
import LabelText from "../../../FormComponent/LabelText";
import SubtitleText from "../../../FormComponent/SubtitleText";
import FormDivider from "../../../FormComponent/FormDivider";
import ValueText from "../../../FormComponent/ValueText";
import axios from "axios";
import { validateSession } from "../../../session/EventManager";
import TableWithLinkAndButton from "../../../FormComponent/TableWithLinkAndButton";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";

function FamMemDetSer(props) {
  //const { member } = props;

  const defaultFamilyMemberDetailsData = {
    cmpfAccNo: "",
    familyMemId: "",
    fullName: "",
    relationName: "",
    age: "",
    maritalStatusFamily: "",
    famMemStatus: "",
  };
  const activeOptions = {
    Y: "Active",
    N: "Inactive",
     A: "Active",
    I: "Inactive",
  };
  const [member, setMember] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const [isFailed, setIsFailed] = useState(false);
  const [familyMembersDetails, setFamilyMembersDetails] = useState([
    defaultFamilyMemberDetailsData,
  ]);

  const location = useLocation();
  const path = location.pathname;
  console.log("the path is", path);

  const {
    state: { passedData, passingId },
  } = location;
  console.log("passedData , passingId", passedData, passingId);
  // depending upon what has arrived passed data or passingId the familymemberId to be passed on the api request is to be changed
  const cmpfAccNo = passedData ? passedData.cmpfAccNo : passingId;
  console.log("cmpfAccNo", cmpfAccNo);
  //account detalils of pf and pension after new backend (account details need to be modified i.e. new fields need to be added)
  let formattedPenAddress = null;
  let formattedPfAddress = null;
  if (member.pfAddress) {
    try {
      const add = JSON.parse(member.pfAddress);
      formattedPfAddress = `${add.addr_l1}, ${add.addr_l2}, ${add["village/town/city"]}, ${add.district}, ${add.state}, ${add.pin_code}`;
    } catch (error) {}
  }
  if (member.penAddress) {
    try {
      const add = JSON.parse(member.penAddress);
      formattedPenAddress = `${add.addr_l1}, ${add.addr_l2}, ${add["village/town/city"]}, ${add.district}, ${add.state}, ${add.pin_code}`;
    } catch (error) {}
  }

  const gender = [
    { id: "M", title: "Male" },
    { id: "F", title: "Female" },
    { id: "O", title: "Other" },
  ];
  const maritalStatus = [
    { id: "M", title: "Married" },
    { id: "U", title: "Unmarried" },
    { id: "W", title: "Widow/Widower" },
    { id: "D", title: "Divorced" },
  ];

  // write it in default model and use based on needs
  let submitTableActions;
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  if (path.includes("/updatemember/view")) {
    submitTableActions = {
      viewAction: {
        action: "view",
        icon: (
          <VisibilityRoundedIcon
            fontSize="small"
            sx={{ color: "black", margin: "0px 5px" }}
          />
        ),
      },

      editAction: {
        action: "edit",
        icon: (
          <EditRoundedIcon
            fontSize="small"
            sx={{ color: "black", margin: "0px 5px" }}
          />
        ),
      },

      deleteAction: {
        action: "delete",
        icon: (
          <DeleteRoundedIcon
            fontSize="small"
            sx={{ color: "red", margin: "0px 5px" }}
          />
        ),
      },
    };
  } else if (path.includes("updatefamilymember/searchmem/view")) {
    submitTableActions = {
      viewAction: {
        action: "view",
        icon: (
          <VisibilityRoundedIcon
            fontSize="small"
            sx={{ color: "black", margin: "0px 5px" }}
          />
        ),
      },

      editAction: {
        action: "edit",
        icon: (
          <EditRoundedIcon
            fontSize="small"
            sx={{ color: "black", margin: "0px 5px" }}
          />
        ),
      },

      deleteAction: {
        action: "delete",
        icon: (
          <DeleteRoundedIcon
            fontSize="small"
            sx={{ color: "red", margin: "0px 5px" }}
          />
        ),
      },
    };
  } else {
    submitTableActions = {
      viewAction: {
        action: "view",

        icon: (
          <VisibilityRoundedIcon
            fontSize="small"
            sx={{ color: "black", margin: "0px 5px" }}
            // viewLink="view"
          />
        ),
      },
    };
  }

  const familyMemberCreatePreviewData = () => {
    let tablePreviewData = [];
    // const familydata = familyMemberSamples;
    const familydata = familyMembersDetails;

    familydata.map((family) => {
      tablePreviewData.push({
        // "CMPF ACC NO": family.cmpfAccNo,
        "Family Member Id": family.familyMemId,
        "Full Name": family.nameFamily,
        "Relation With Member": family.relationName,
        " Date of Birth": family.dateOfBirthFamily,
        // "Marital Status": family.maritalStatusFamily,
        // "Family Member Status": activeOptions[family.famMemStatus] || "",
      });
    });

    return tablePreviewData;
  };

  const sessionExist = async () => {
    const status = await validateSession();
    console.log("session status", status);
    return status;
  };

  const fetchUserDetail = async () => {
    setIsLoading(true);
    //session related uncomment for session
    // if (!(await sessionExist())) {
    //   console.log("session extending having trouble");
    //   setIsFailed(true);
    //   setIsLoading(false);
    //   return;
    // }

    // let token = localStorage.getItem("jwt");

    // if (!token) {
    //   setIsFailed(true);
    //   setIsLoading(false);
    //   return;
    // }

    // token = token.replace('"', "").replace('"', "");

    const url =
      process.env.REACT_APP_SERVER_URL + "/search/editablefields/forUDA";
    const formData = new FormData();
    // formData.append("searchFieldValue", jwtDecode(token).user);
    //formData.append("searchFieldValue", accNum);
    formData.append("searchFieldValue", cmpfAccNo);
    const config = {
      headers: {
        //  Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsLoading(false);
        setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          setMember(data[0]);
          fetchFamilyMembersDetail(data[0].memberId);
          return;
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        console.log("error", error);
      });
  };
  const navigate = useNavigate(); //inilitialize the navigate function
  const handleGoBack = () => {
    navigate(-1); //navigate back to the previous page
    // scroll to the top of the page
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo(0, 0);
    fetchUserDetail();
  }, [path]);

  // api request to fetch list of all active and inactive family members
  const fetchFamilyMembersDetail = async (memberId) => {
    setIsLoading(true);
    //session related uncomment for session
    // if (!(await sessionExist())) {
    //   console.log("session extending having trouble");
    //   setIsFailed(true);
    //   setIsLoading(false);
    //   return;
    // }

    // let token = localStorage.getItem("jwt");

    // if (!token) {
    //   setIsFailed(true);
    //   setIsLoading(false);
    //   return;
    // }

    // token = token.replace('"', "").replace('"', "");

    const url = process.env.REACT_APP_SERVER_URL + "/search/viewfamilydetails";
    const formData = new FormData();
    //formData.append("searchFieldValue", jwtDecode(token).user);
    formData.append("searchField", memberId);
    //formData.append("searchFieldValue", "HYD/170/840");
    const config = {
      headers: {
        //  Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsLoading(false);
        setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          console.log("familymemberdata", data);
          setFamilyMembersDetails(data);
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setFamilyMembersDetails([]);
        setIsLoading(false);
        setIsFailed(true);
        console.log("error", error);
      });
  };

  return (
    <Grid container justifyContent="center" sx={{ wordWrap: "break-word" }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
          <>
            {/*Button with back functionality */}
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  align="left"
                  onClick={handleGoBack}>
                  Back
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <TitleText title="Member's Details" />
            </Grid>

            <FormDivider />

            {/* <Form> */}
            <Grid
              container
              justifyContent="space-between"
              alignItems="start"
              rowGap={3}>
              {/* section 1  */}
              <Grid item xs={12} sm={12}>
                <SubtitleText title="Member Details" />
              </Grid>
              {/* column 1  */}
              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  alignItems="start"
                  justifyContent="space-between"
                  spacing={2}
                  columnGap={1}>
                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Name" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.fullName} />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Marital Status" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={
                        maritalStatus.find(
                          (item) => item.id === member.maritalStatus
                        )?.title
                      }
                    />
                  </Grid>

 {/* row */}
 <Grid item xs={12} sm={4}>
                  <LabelText title="CMPF Account Number" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText title={member.cmpfAccNo} />
                </Grid>
                   {/* row  */}
                   <Grid item xs={12} sm={4}>
                  <LabelText title="Colliery Name" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueText title={member.unitName} />
                </Grid>
                </Grid>
              </Grid>
              {/* column 2  */}
              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="start"
                  spacing={2}
                  columnGap={1}>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Gender" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={
                        gender.find((item) => item.id === member.gender)?.title
                      }
                    />
                  </Grid>

                   {/* row */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Date of Birth" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* <ValueText title={member.dateOfBirth} /> */}
                  <ValueText
                    title={
                      member.dateOfBirth
                        ? new Date(member.dateOfBirth).toLocaleDateString(
                            "en-GB",
                            options
                          )
                        : ""
                    }
                  />
                </Grid>

                                {/* row */}
                                <Grid item xs={12} sm={4}>
                  <LabelText title="Employee ID" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText title={member.employeeId} />
                </Grid>


                                {/* row */}
                                <Grid item xs={12} sm={4}>
                  <LabelText title="Coal Company" />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: { xs: "none", sm: "initial" } }}>
                  :
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ValueText title={member.name} />
                </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* ************************************** */}
            {/* section 2 */}
            <Grid item xs={12} sm={12} sx={{marginBottom:2}}>
              <SubtitleText title="Family Members Details" />
            </Grid>
            {/* column 1  */}
            <Grid item xs={12} sm={12}>
              {familyMembersDetails.length !== 0 ? (
                <TableWithLinkAndButton
                  preview={familyMemberCreatePreviewData()}
                  data={familyMembersDetails}
                  passingId="familyMemId"
                  actions={ submitTableActions }
                  viewLink=""
                />
              ) : (
                <TableWithLinkAndButton
                  preview={familyMemberCreatePreviewData()}
                  data={[]}
                  passingId="familyMemId"
                  viewLink=""
                />
              )}
            </Grid>
            {/* <Grid>
                {familyMembersDetails === null && (
                  <SubtitleText title="No record" />
                )}
              </Grid> */}
          </>
          {/* )}
            </>
          )} */}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default FamMemDetSer;
