import React, { Fragment, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Paper, Box, Button, Divider, } from '@mui/material';
import { familyMemberModel, familyMandatoryFields } from '../../Models/defaultModel';


import TitleText from '../../FormComponent/TitleText';
import LabelText from '../../FormComponent/LabelText';
import CustomTextField from '../../FormComponent/CustomTextField';
import CustomDropdown from '../../FormComponent/CustomDropdown';
import GroupedRadioButton from '../../FormComponent/GroupedRadioButton';
import SubtitleText from '../../FormComponent/SubtitleText';
import CustomTextArea from '../../FormComponent/CustomTextArea';
import LoadingIndicator from '../../FormComponent/LoadingIndicator';
import FormDivider from '../../FormComponent/FormDivider';

import Test from '../../Test';

function FamilyForm() {



    const mode = useLocation();
    const navigate = useNavigate();

    const [UIMODE, setUIMODE] = useState('CREATE');

    const defaultData = familyMemberModel;
    const mandatory = familyMandatoryFields;

    const [family, setFamily] = useState(defaultData);
    const [readOnly, setReadOnly] = useState(false);
    const [isProgramLoaded, setIsProgramLoaded] = useState(false);

    const switchUi = () => {
        if (mode.state.edit) {
            setUIMODE('EDIT');
            setReadOnly(false);
            let passedData = mode.state.passedData;
            setFamily((family) => ({ ...family, ...passedData }));  //This ensures that the previous state (prevFamily) is correctly captured and merged with the new data (passedData) using the spread operator.
            return;
        }
        if (mode.state.view) {
            setUIMODE('VIEW');
            setReadOnly(true);
            let passedData = mode.state.passedData;
            console.log("current ui View")
            console.log("passed data", passedData);
            setFamily((family) => ({ ...family, ...passedData }));
            return;
        }
    }



    useEffect(() => {
        switchUi();
        setIsProgramLoaded(true);
    }, []);


    //Configuraton 

    const title_id =
    {
        name: "title_id",
        // label: "salutation",
        helperText: '',
        disabled: readOnly,
        initialValue: family.title_id,
        options: [{ id: '1', title: 'Mr' }, { id: '2', title: 'Ms' }, { id: '3', title: 'Mrs' }, { id: '4', title: 'Dr' }, { id: '5', title: 'Other' }],
    };

    const relation_id = {
        name: "relation_id",
        // label: "relation",
        helperText: '',
        disabled: readOnly,
        initialValue: family.relation_id,
        options: [{ id: '1', title: "Father" }, { id: '2', title: 'Mother' }, { id: '3', title: 'Son' }, { id: '4', title: 'Daughter' }]
    };

    const religion_id =
    {
        name: "religion_id",
        placeholder: "religion",
        // label: "religion",
        helperText: '',
        disabled: readOnly,
        initialValue: family.religion_id,
        options: [{ id: '1', title: "Hindu" }, { id: '2', title: 'Christian' }, { id: '3', title: 'Muslim' }, { id: '4', title: 'Jain' }, { id: '5', title: 'Sikh' }, { id: 6, title: 'Buddhist' }, { id: 7, title: 'Other' }],
    };


    const gender = {
        options: [
            { value: 'Male', label: 'Male' },
            { value: 'Female', label: 'Female' },
            { value: 'Other', label: 'Other' }
        ],
        name: 'gender',
        // label:'Gender',
        initialValue: family.gender,
        disabled: readOnly,
    };


    const marital_status = {
        options: [
            { value: 'Single', label: 'Single' },
            { value: 'Married', label: 'Married' },
            { value: 'Divorced', label: 'Divorced' },
            { value: 'Widow', label: 'Widow' },
            { value: 'Widower', label: 'Widower' }
        ],
        name: 'marital_status',
        // label: 'Marital Status',
        initialValue: family.marital_status,
        disabled: readOnly,
    };

    const fath_hus_flag = {
        options: [
            { value: 'Father', label: 'Father' },
            { value: 'Husband', label: 'Husband' },
        ],
        name: 'fath_hus_flag',
        // label: 'Name of Father/Husband',
        initialValue: family.fath_hus_flag,
        disabled: readOnly,
    };

    const pf_flag = {
        options: [
            { value: 'No', label: 'No' },
            { value: 'Yes', label: 'Yes' },
        ],
        name: 'pf_flag',
        // label: 'Name of Father/Husband',
        initialValue: family.pf_flag,
        disabled: readOnly,
    };

    const pension_flag = {
        options: [
            { value: 'No', label: 'No' },
            { value: 'Yes', label: 'Yes' },
        ],
        name: 'pension_flag',
        // label: 'Name of Father/Husband',
        initialValue: family.pension_flag,
        disabled: readOnly,
    };



    const firstName = {
        name: "firstName",
        type: "text",
        helperText: 'please enter your first name',
        // defaultValue: family.firstName,
        placeholder: 'First Name',
        errorMessage:
            "First Name must be characters only and not allowed special characters except white space.",
        // label: "First Name",
        pattern: "",
        required: mandatory.includes('firstName'),
        disabled: readOnly,
    };

    const middleName = {
        name: "middleName",
        type: "text",
        helperText: 'please enter your middle name',
        defaultValue: family.middleName,
        placeholder: 'Middle Name',
        errorMessage: "Middle Name must be characters only and not allowed special characters except white space.",
        // label: "Middle Name",
        pattern: "",
        required: mandatory.includes('middleName'),
        disabled: readOnly,
    };

    const lastName = {
        name: "lastName",
        type: "text",
        helperText: 'please enter your last name',
        placeholder: 'Last name',
        defaultValue: family.lastName,
        errorMessage:
            "Last Name must be characters only and not allowed special characters except white space.",
        // label: "Last Name",
        pattern: "",
        required: mandatory.includes('lastName'),
        disabled: readOnly,
    };

    const date_of_birth = {
        name: "date_of_birth",
        type: "date",
        helperText: 'Please select your Birthdate',
        defaultValue: family.date_of_birth,
        errorMessage:
            "date Of Birth",
        // label: "Date Of Birth",
        pattern: "",
        required: mandatory.includes('date_of_birth'),
        disabled: readOnly,
    };


    const pan_no = {
        name: "pan_no",
        type: "text",
        placeholder: "PAN No.",
        helperText: 'please provide pan number',
        defaultValue: family.pan_no,
        errorMessage:
            "Pan should be 12 digis with out spaces",
        // label: "Pan ID",
        pattern: "",
        required: mandatory.includes('pan_no'),
        disabled: readOnly,
    };


    const aadhaar_no = {
        name: "aadhaar_no",
        type: "text",
        placeholder: "Aadhaar No",
        helperText: 'please provide aadhaar number',
        defaultValue: family.aadhaar_no,
        errorMessage:
            "aadhar should be 16 digits with out spaces",
        // label: "Aadhar ID",
        pattern: "",
        required: mandatory.includes('aadhaar_no'),
        disabled: readOnly,
    };


    const fath_hus_name = {
        name: "fath_hus_name",
        type: "text",
        placeholder: "Father/Hus Name",
        helperText: 'please provide name of father/husband',
        defaultValue: family.fath_hus_name,
        errorMessage:
            "email should be correct format",
        // label: "pension_percentage",
        pattern: "",
        required: mandatory.includes('fath_hus_name'),
        disabled: readOnly,
    };


    const email_id = {
        name: "email_id",
        type: "text",
        placeholder: "Email ID",
        helperText: 'please provide email id',
        defaultValue: family.email_id,
        errorMessage:
            "email should be correct format",
        // label: "email_id",
        pattern: "",
        required: mandatory.includes('email_id'),
        disabled: readOnly,
    };


    const mobile_no = {
        name: "mobile_no",
        type: "text",
        placeholder: "Mobile Number",
        helperText: 'please provide mobile number',
        defaultValue: family.mobile_no,
        errorMessage:
            "email should be correct format",
        // label: "mobile_no",
        pattern: "",
        required: mandatory.includes('mobile_no'),
        disabled: readOnly,
    };

    const land_no = {
        name: "land_no",
        type: "text",
        placeholder: "LandLine Number",
        helperText: 'please provide landline number',
        defaultValue: family.land_no,
        errorMessage:
            "email should be correct format",
        // label: "mobile_no",
        pattern: "",
        required: mandatory.includes('land_no'),
        disabled: readOnly,
    };

    const pf_percentage = {
        name: "pf_percentage",
        type: "text",
        placeholder: "% PF",
        helperText: 'please provide a number between 0-100',
        defaultValue: family.pf_percentage,
        errorMessage:
            "email should be correct format",
        // label: "pf_percentage",
        pattern: "",
        required: mandatory.includes('pf_percentage'),
        disabled: readOnly,
    };

    const pension_percentage = {
        name: "pension_percentage",
        type: "text",
        placeholder: "% Pension",
        helperText: 'please provide a number between 0-100',
        defaultValue: family.pf_percentage,
        errorMessage:
            "email should be correct format",
        // label: "pension_percentage",
        pattern: "",
        required: mandatory.includes('pension_percentage'),
        disabled: readOnly,
    };


    const address_l1 = {
        name: "address_l1",
        placeholder: "Home Address",
        helperText: 'please provide  your address.',
        defaultValue: family.address_l1,
        errorMessage:
            "email should be correct format",
        // label: "pension_percentage",
        pattern: "",
        required: mandatory.includes('address_l1'),
        disabled: readOnly,
    };


    const onChange = (e) => {
        setFamily({ ...family, [e.target.name]: e.target.value });
    };

    const validatePfShare = () => {
        if (family.pf_flag === 'No') {
            family.pf_percentage = 0;
            return true;
        }
        if (family.pf_percentage && family.pf_percentage > 0) {
            return true;
        }
        return false;
    }

    const validatePensionShare = () => {
        if (family.pension_flag === 'No') {
            family.pension_percentage = 0;
            return true;
        }
        if (family.pension_percentage && family.pension_percentage > 0) {
            return true;
        }
        return false;
    }


    const validRequiredFilds = () => {
        let flag = true;
        for (let key in family) {
            if (mandatory.includes(key)) {
                if (!family[key]) {
                    flag = false;
                }
            }
        }
        return flag;
    }



    const validateForm = () => {
        if (validatePfShare() && validatePensionShare() && validRequiredFilds()) {
            return true;
        }
        return false;
    }

    const handleCreate = (e) => {
        e.preventDefault();
        // if (!validateForm()) {
        //     alert("Please Fill out all the required fields.\nIf pension/pf enabled make sure the percentage of share greater than zero");
        //     return;
        // }

        //code for create api
        console.log("form data", family);
    }



    const handleEdit = (e) => {
        e.preventDefault();
        if (!validateForm()) {
            alert("Please Fill out all the required fields.\nIf pension/pf enabled make sure the percentage of share greater than zero");
            return;
        }

        //code for create api
        console.log("form data", family);
    }


    const handleView = (e) => {
        e.preventDefault();
        navigate(-1);
    }



    return (
        <Grid container justifyContent="center" sx={{ wordWrap: 'break-word' }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper elevation={3} sx={{ padding: '3rem', borderRadius: '8px' }}>

                    {
                        !isProgramLoaded ?
                            <LoadingIndicator />
                            : <>

                                
                                    <Grid item xs={12}>

                                        {
                                            UIMODE === 'CREATE' &&
                                            <TitleText title='Add New Family Member' />
                                        }

                                        {
                                            UIMODE === 'EDIT' &&
                                            <TitleText title='Update Details' />
                                        }

                                        {
                                            UIMODE === 'VIEW' &&
                                            <TitleText title='Detailed Information' />
                                        }

                                    </Grid>


                                    <FormDivider />

                                    {/* <Form> */}
                                    <Grid container justifyContent="space-between" alignItems="start" rowGap={2}>
                                        {/* section 1  */}
                                        <Grid item xs={12} sm={12}>
                                            <SubtitleText title="Personal Information" />
                                        </Grid>


                                        {/* column 1  */}

                                        <Grid item xs={12} sm={5} >
                                            <Grid container alignItems='center' justifyContent='space-between' spacing={2} columnGap={1}>
                                                {/* row */}
                                                <Grid item xs={12} sm={4} >
                                                    <LabelText title="Salutation" />
                                                </Grid>
                                                <Grid item xs={12} sm={7} >
                                                    <CustomDropdown {...title_id} onchange={onChange} />
                                                </Grid>


                                                {/* row  */}
                                                <Grid item xs={12} sm={4}>
                                                    <LabelText title="First Name" />
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                    <CustomTextField {...firstName} onchange={onChange} defaultValue={family.firstName} />
                                                </Grid>


                                                {/* row */}
                                                <Grid item xs={12} sm={4} >
                                                    <LabelText title="Middle Name" />
                                                </Grid>
                                                <Grid item xs={12} sm={7} >
                                                    <CustomTextField {...middleName} onchange={onChange} />
                                                </Grid>


                                                {/* row */}
                                                <Grid item xs={12} sm={4}>
                                                    <LabelText title="Last Name" />
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                    <CustomTextField {...lastName} onchange={onChange} />
                                                </Grid>


                                                {/* row */}
                                                <Grid item xs={12} sm={4}>
                                                    <LabelText title="Date of Birth" />
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                    <CustomTextField {...date_of_birth} onchange={onChange} />
                                                </Grid>

                                                {/* row */}
                                                <Grid item xs={12} sm={4}>
                                                    <LabelText title="Relation with member?" />
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                    <CustomDropdown {...relation_id} onchange={onChange} />
                                                </Grid>



                                                {/* row */}
                                                <Grid item xs={12} sm={4}>
                                                    <LabelText title="PAN Number" />
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                    <CustomTextField {...pan_no} onchange={onChange} />
                                                </Grid>


                                                {/* row */}
                                                <Grid item xs={12} sm={4}>
                                                    <LabelText title="Aadhaar Number" />
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                    <CustomTextField {...aadhaar_no} onchange={onChange} />
                                                </Grid>

                                                {/* row */}
                                                <Grid item xs={12} sm={4}>
                                                    <LabelText title="Marital Status" />
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                    <GroupedRadioButton {...marital_status} onchange={onChange} />
                                                </Grid>

                                            </Grid>
                                        </Grid>


                                        {/* column 2  */}


                                        <Grid item xs={12} sm={5}>
                                            <Grid container justifyContent='space-between' alignItems='center' spacing={2} columnGap={1}>

                                                {/* row */}
                                                <Grid item xs={12} sm={4}>
                                                    <LabelText title="Religion" />
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                    <CustomDropdown {...religion_id} onchange={onChange} />
                                                </Grid>

                                                {/* row */}
                                                <Grid item xs={12} sm={4}>
                                                    <LabelText title="Gender" />
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                    <GroupedRadioButton {...gender} onchange={onChange} />
                                                </Grid>

                                                {/* row */}
                                                <Grid item xs={12} sm={4}>
                                                    <LabelText title="Name of Father/Husband" />
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                    <CustomTextField {...fath_hus_name} onchange={onChange} />
                                                </Grid>

                                                {/* row */}
                                                <Grid item xs={12} sm={4}>
                                                    <LabelText title="Who's Name You given above?" />
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                    <GroupedRadioButton {...fath_hus_flag} onchange={onChange} />
                                                </Grid>

                                                {/* row */}
                                                <Grid item xs={12} sm={4}>
                                                    <LabelText title="Email ID" />
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                    <CustomTextField {...email_id} onchange={onChange} />
                                                </Grid>

                                                {/* row */}
                                                <Grid item xs={12} sm={4}>
                                                    <LabelText title="Mobile Number" />
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                    <CustomTextField {...mobile_no} onchange={onChange} />
                                                </Grid>

                                                {/* row */}
                                                <Grid item xs={12} sm={4}>
                                                    <LabelText title="Landline Number" />
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                    <CustomTextField {...land_no} onchange={onChange} />
                                                </Grid>

                                                {/* row */}
                                                <Grid item xs={12} sm={4}>
                                                    <LabelText title="Permanant Address" />
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                    <CustomTextArea {...address_l1} onchange={onChange} />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {/* section 2  */}
                                        <Grid item xs={12} sm={12}>
                                            <SubtitleText title="Claim Eligibility Details" />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justifyContent='center' alignItems='center' spacing={2}>

                                                {/* row */}
                                                <Grid item xs={12} sm={4}>
                                                    <LabelText title="PF Nominee?" />
                                                </Grid>
                                                <Grid item xs={12} sm={8}>
                                                    <GroupedRadioButton {...pf_flag} onchange={onChange} />
                                                </Grid>

                                                {/* row */}
                                                {
                                                    family.pf_flag === 'Yes' &&
                                                    <>
                                                        <Grid item xs={12} sm={4}>
                                                            <LabelText title="% PF Share" />
                                                        </Grid>
                                                        <Grid item xs={12} sm={8}>
                                                            <CustomTextField {...pf_percentage} onchange={onChange} />
                                                        </Grid>
                                                    </>
                                                }

                                                {/* row */}
                                                <Grid item xs={12} sm={4}>
                                                    <LabelText title="Pension Nominee?" />
                                                </Grid>
                                                <Grid item xs={12} sm={8}>
                                                    <GroupedRadioButton {...pension_flag} onchange={onChange} />
                                                </Grid>

                                                {/* row */}
                                                {
                                                    family.pension_flag === 'Yes' &&
                                                    <>
                                                        <Grid item xs={12} sm={4}>
                                                            <LabelText title="% Pension Share" />
                                                        </Grid>
                                                        <Grid item xs={12} sm={8}>
                                                            <CustomTextField {...pension_percentage} onchange={onChange} />
                                                        </Grid>
                                                    </>
                                                }

                                                {/* submit button */}
                                                <Grid item xs={12} sm={4}>
                                                    {
                                                        UIMODE === 'CREATE' && <Button
                                                            onClick={handleCreate}
                                                            variant="contained"
                                                            color="primary"
                                                       // disabled={true}
                                                            fullWidth
                                                            type="submit"
                                                        >
                                                            Create New Member
                                                        </Button>
                                                    }

                                                    {
                                                        UIMODE === 'EDIT' && <Button
                                                            onClick={handleEdit}
                                                            variant="contained"
                                                            color="success"
                                                            fullWidth
                                                            disabled={true}
                                                            type="submit"
                                                        >
                                                            Submit Changes
                                                        </Button>
                                                    }

                                                    {
                                                        UIMODE === 'VIEW' && <Button
                                                            onClick={handleView}
                                                            variant="contained"
                                                            color="primary"
                                                            fullWidth
                                                            type="submit"
                                                        >
                                                            Go Back
                                                        </Button>
                                                    }
                                                </Grid>

                                            </Grid>
                                        </Grid>


                                    </Grid>
                              
                            </>

                    }

                </Paper>
            </Grid>
        </Grid>
    );
}

export default FamilyForm;
