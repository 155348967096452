import React from "react";
import { Typography } from "@mui/material";
const Requirement=()=>{
    return(
        <div id="system-requirements">
            <p></p>
        <Typography variant="h6">SYSTEM REQUIREMENT </Typography>
        <Typography variant="body1" align='justify'>
        <ol type="a">
            <li><b>Hardware Requirements</b> </li>
           
            It can be accessed on most of the electronic devices like computers, laptops, tablets & mobile phones that have internet connectivity, with 16GB or more internal storage space and 4GB or more RAM size.
            
        
            <li><b>Software Requirements</b></li>
           
            Compatible Operating systems like Windows, iOS, Android etc. with latest versions of web browsers such as Chrome, Safari, and Microsoft Edge etc.

    
        </ol>
        </Typography>
 
  </div>
    );
}
export default Requirement;