import React from "react";
import { Fragment, useState, useEffect } from "react";
import { Grid, Box, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TitleText from "../../../FormComponent/TitleText";
import FormDivider from "../../../FormComponent/FormDivider";

import CustomConfirmationDialogue from "../../../FormComponent/CustomConfirmationDialogue";

import axios from "axios";
import { useLocation } from "react-router-dom";
import SucessAlert from "../../../FormComponent/SuccessAlert";
import ErrorAlert from "../../../FormComponent/ErrorAlert";
const DeleteFamilyMember = () => {
  // const [isRevoke, setIsRevoke] = useState(false);
  const [issubmitSucess, setIsSubmmitSucess] = useState(true); // creating  a state for submit button
  const [isUpdatedSuccess, setIsUpdatedSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const[isLoading,setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false);
  const printableData = {
    // grievanceId: "",
    famMemId: "",
    grievanceCategDesc: "",
    grievanceSubCatDesc: "",
    grievanceDesc: "",
    upldedDoc: "",
    docTypeDesc: "",
    submitDate: "",
    grievanceStatusDesc: "",
  };

  const [grievanceDetails, setGrievanceDetails] = useState({ printableData });
  const [isLoading, setIsLoading] = useState(false);

  const { state } = useLocation();

  console.log("location data", state.passedData);
  const famMemId = state.passedData.familyMemId;

  const handleSubmitSucess = () => {
    setIsSubmmitSucess(true);
  };

  const handleRevokeGrievance = async () => {
    try {
      setIsSubmmitSucess(false);
      setIsSubmmitSucess(true);
      setIsDisabled(true);
      const formData = new FormData();
      formData.append("famMemId", famMemId);
      const url = `${process.env.REACT_APP_SERVER_URL}/delete/family/member/by/id`;

      const response = await axios.post(url, formData);

      setIsUpdatedSuccess(true);
    } catch (error) {
      setIsSubmmitSucess(true); // Set the submit success state to false if an error occurs
      setIsError(true);
      setIsSubmmitSucess(false);
      setErrorMessage(error.response.data.error_message);
    }
  };

  const navigate = useNavigate();

  const handleDialogueCancel = () => {
    setIsSubmmitSucess(false);
    navigate(-1);
  };

  const handleGoBack = () => {
    navigate(-1); //navigate back to the previous page
    window.scrollTo(0, 0); // scroll to the top of the page
  };

  const handleUpdateSucess = () => {
    setIsUpdatedSuccess(false);
    navigate(-1);
  };

  /// error alert handler
  // const handleErrorAlert = () => {
  //   setIsError(false);
  //   setIsLoading(false);
  // };

  return (
    <div>
      {/* <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Alert!"
        body={errorMessage}
        buttonName="Ok"
      /> */}

      <CustomConfirmationDialogue
        handleOk={handleRevokeGrievance}
        handleCancel={handleDialogueCancel}
        title="Confirm Deletion"
        body="Are you sure, you want to Delete this Family Member?"
        open={issubmitSucess}
        // isDisabled={isDisabled}
        buttonName="Yes"
        buttonName2="No"
      />

      <SucessAlert
        handleOk={handleUpdateSucess}
        open={isUpdatedSuccess}
        title="Success"
        body="Family Member Deleted Successfully."
        buttonName="Ok"
      />
    </div>
  );
};
export default DeleteFamilyMember;
