import { Button, CircularProgress, Grid, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import SubtitleText from "../../FormComponent/SubtitleText";
import MemberDetails from "../UDAPages/MemberDetatils";
import BackButton from "../../components/button/BackButton";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ViewDocuments from "../../SettlementComponents/ViewDocuments";
import CustomTextArea from "../../FormComponent/CustomTextArea";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import SucessAlert from "../../FormComponent/SuccessAlert";
import ConfirmationDialogue from "../../FormComponent/ConfirmationDialogue";
import UpdatedFieldTable from "../UaoPages/UpdatedFieldsTable";
import jwtDecode from "jwt-decode";
import TableWithLinkAndButton from "../../FormComponent/TableWithLinkAndButton";
import LabelText from "../../FormComponent/LabelText";
import ValueText from "../../FormComponent/ValueText";
import AddFamilyMemberDetails from "../AddFamilyMemberPages/AddFamilyMemberDetails";
import LimitedMemberDetatils from "../UDAPages/LimitedMemberDetatils";

function ViewAddFamMemAao() {
  const { state } = useLocation();
  console.log("stateeeeeeeeeee", state);

  const memId = state.passingId;
  const memType = memId.substring(0, 3);
  const navigate = useNavigate();
  const sampleMemberData = {
    // This is the sample claimData data to be displed for demo purpose only
    title: "",
    fullName: "",
    dateOfBirth: "",
    panNo: "",
    aadhaarNumber: "",
    maritalStatus: "",
    religionName: "",
    gender: "",
    fatherHusbandName: "",
    email: "",
    mobileNo: "",
    landNo: "",
    addressL1: "",
    addressL2: "",
    village: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  };

  const sampleClaimData = [
    {
      titleId: 0,
      titleName: "",
      firstName: "",
      middleName: "",
      lastName: "",
      name: "",
      fathHusName: "",
      relationName: "",
      relationId: 0,
      gender: "",
      maritalStatus: "",
      religionName: "",
      religionId: 1,
      dateOfBirth: "",
      addressL1: "",
      addressL2: "",
      mobileNo: "",
      landNo: "",
      emailId: "",
      state: "",
      district: "",
      pincode: 0,
      village: "",
      city: "",
      country: "",
      panId: null,
      panNo: "",
      aadharId: null,
      aadhaarNo: "",
      familyMemId: 0,
      pfNominee: true,
      pensionNominee: true,
      pfNomShare: 0,
      penNomShare: 0,
      memberId: 0,
    },
  ];

  const defaultFamilyMemberDetailsData = {
    cmpfAccNo: "",
    familyMemId: "",
    fullName: "",
    relationName: "",
    age: "",
    maritalStatusFamily: "",
    famMemStatus: "",
  };

  const [familyMembersDetails, setFamilyMembersDetails] = useState([
    defaultFamilyMemberDetailsData,
  ]);
  const [memUpdReqdetails, SetMemUpdReqdetails] = useState(sampleClaimData);
  const [memberData, setMemberData] = useState(sampleMemberData);
  const [isMemberFound, setIsMemberFound] = useState(" ");
  const [documents, setDocuments] = useState([]);
  const [assignToOption, setAssignToOption] = useState([]);

  //**********for integration************* */
  const [isLoading, setIsLoading] = useState(true); // set this to true
  const [isLoadingDoc, setIsLoadingDoc] = useState(true); // set this to true
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isFailedDoc, setIsFailedDoc] = useState(false);
  const [isReturning, setIsReturning] = useState(false);
  const [isReturnClicked, setIsReturnClicked] = useState(false);
  const [showingDropdown, setShowingDropdown] = useState(0);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [selectedOfficerName, setSelectedOfficerName] = useState("");
  const [accNum, setAccNum] = useState("");

  const defaultClaim = {
    remarks: "",
    assignTo: "",
  };

  // declaring the default api object
  let api = {
    memUrl: "/show/new/family/member/details/ackid",
    docsUrl: "/get/new/family/member/upload/documents",
    returnUrl: "/add/new/family/member/aao",
    forwardUrl: "/add/new/family/member/aao",
    officerDropdownUrl: "/dropdown/for/update/member/details/by/uao",
    memberDetailsUrl: "/member/details/for/pf",

    // femdeturl: "/show/new/family/member/details/ackid",
  };
  // object for pfapis
  //let loginId = "UAO-CC-OFF-103"; // Assuming a default loginId
  const [loginId, setLoginId] = useState();

  /********fetching and setting token********* */
  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (token) {
      token = token.replace('"', "").replace('"', "");
      let loginId = jwtDecode(token).user; // Assuming a default loginId
      setLoginId(loginId);
      // console.log("loooooooooooooooooooooginId", loginId);
      // Add any further logic here based on the loginId
    } else {
      // Handle the case when token is not present
      setIsLoading(false);
      setIsFailed(true);
    }
  }, []);
  // const memApis = {
  //   memUrl: "/get/update/family/member/details/by/id",
  //   docsUrl: "/fetch/update/member/details/docs/service",
  //   returnUrl: "/add/new/family/member/uao",
  //   forwardUrl: "/add/new/family/member/uao",
  //   officerDropdownUrl: "/dropdown/for/update/member/details/by/uao",
  //   memberDetailsUrl: "/member/details/for/pf",
  //   // familyDetailsUrl: "/member/details/for/pf",
  // };

  // if (memType === "FAM") {
  //   api = { ...memApis };
  // }

  const [claim, setClaim] = useState(defaultClaim);

  const assignTo = {
    name: "assignTo",
    placeholder: "Select Officer",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "",
    required: true,
    options: assignToOption,
  };

  const remarks = {
    name: "remarks",
    type: "text",
    multiline: true,
    helperText: "Enter your remarks (maximum 200 words)",
    placeholder: "Enter your remarks here",
    defaultValue: claim.remarks,
    errorMessage: "Cannot exceed 200 characters",
    pattern: "^.{0,200}$",
    maxLength: 200, // Limiting to 200
  };

  //****************Dropdown******************* */
  const fetchAssignOfficer = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + api.officerDropdownUrl;
      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      const options = data.map((option) => ({
        id: option.loginId,
        title: `${option.name} (${option.loginId})`,
      }));
      setAssignToOption(options); // Assuming setAssignToOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchAssignOfficer();
    fetchAllClaimDetail();
  }, [loginId]);

  const fetchMemUpdDetail = async () => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    // const url = process.env.REACT_APP_SERVER_URL + "/show/pf/claim/on/claimid";
    const url = process.env.REACT_APP_SERVER_URL + api.memUrl;

    const formData = new FormData();
    // formData.append("updReqAckId", memId);
    formData.append("famReqAckId", memId);
    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          // console.log("jai", data);
          SetMemUpdReqdetails(data);
          fetchMemberDetail(data[0].cmpfAccNo);

          fetchDocuments(memId);
          fetchFamilyMembersDetail(data[0].cmpfAccNo);
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
      });
  };

  const fetchAllClaimDetail = () => {
    fetchMemUpdDetail();
  };
  // console.log("jaii", accNum);
  const fetchDocuments = async (famAckId) => {
    try {
      // const url = `${process.env.REACT_APP_SERVER_URL}/show/pf/claim/uploaded/documents/claimid`;
      const url = process.env.REACT_APP_SERVER_URL + api.docsUrl;

      const formData = new FormData();
      formData.append("famAckId", famAckId);
      const config = {
        headers: {
          // Include the Authorization header if req uired
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(url, formData, config);

      setIsLoadingDoc(false);
      setIsFailedDoc(false);

      if (response.status === 200) {
        const data = response.data.data;
        setDocuments(data);
      } else {
        setIsFailed(true);
      }
    } catch (error) {
      // Corrected syntax for the catch block
      setIsLoading(false);
      setIsFailed(true);
    }
  };

  // cmpf account number is fetched on useEffect with a delay of 3sec to avoid multiple api hits
  const onChange = (e) => {
    if (e.target.name === "assignTo") {
      const selectedOption = assignToOption.find(
        (option) => option.id === e.target.value
      );
      if (selectedOption) {
        setSelectedOfficerName(selectedOption.title); // Set the selected officer's name in the state
        // console.log("selctess", selectedOption.title);
      }
    }

    setClaim({ ...claim, [e.target.name]: e.target.value });
  };

  /// ******** on forword to AAO function

  const objectTobeSent = {
    fromOffcrLoginId: loginId,
    remarks: claim.remarks,
    toOffcrLoginId: claim.assignTo,
    enrolAckId: memId,
    status: true,
    // updReqAckId:memId,
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    const url = process.env.REACT_APP_SERVER_URL + api.forwardUrl;

    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSent, { headers })
      .then(function (response) {
        setIsSubmitting(false);
        setIsSuccess(true);
        setSuccessMessage("Family Member Added Successfully.");
      })
      .catch((error) => {
        setIsError(true);
        console.log("error on family uda", error);
        const errorCode = error.response.data.http_status_code;
        console.log("errorCode", errorCode);
        // if (errorCode === 409) {
        if (error.response.status === 409) {
          console.log("Iam in error");
          setErrorMessage(
            "Family Member already present. Kindly Check Aadhaar No. and Pan No.of the Added Family Member."
          );
        } else {
          setErrorMessage("Sorry Could not Proceed!! Please try again later");
          console.log("error on submit", error);
        }
      });
  };

  /// ******** on Return function

  const objectTobeSentOnReturn = {
    fromOffcrLoginId: loginId,
    remarks: claim.remarks,
    // toOffcrLoginId: claim.assignTo,
    // updReqAckId: memId,
    enrolAckId: memId,
    status: false,
  };

  const handleReturnClick = (e) => {
    e.preventDefault();

    if (!claim.remarks.trim()) {
      // Show message in dialog: "Please enter remarks"
      // alert("Please enter remarks");
      setIsError(true);
      setErrorMessage("Please enter Remarks.");
      return;
    }

    setIsReturning(true);
    setIsReturnClicked(true);
  };

  const handleReturnClaim = (e) => {
    // e.preventDefault();

    // const url =
    //   process.env.REACT_APP_SERVER_URL + "/verification/pf/claim/officers";
    const url = process.env.REACT_APP_SERVER_URL + api.returnUrl;
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSentOnReturn, { headers })
      .then(function (response) {
        setIsReturning(false);
        setIsSuccess(true);
        setSuccessMessage("Add Family Member Request Returned Successfully.");
        setIsReturnClicked(false);
      })
      .catch((error) => {
        console.log("error on submit", error);
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
      });
  };

  ///****************integration started********************* */
  const fetchMemberDetail = async (accNo) => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + api.memberDetailsUrl;
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          if (data.length !== 0) {
            setMemberData(data[0]);
            setIsMemberFound("true");
          }
          setIsMemberFound("false");
          return;
        }
        setIsFailed(true);
        setIsMemberFound("false");
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        setIsMemberFound("false");
      });
  };

  const fetchFamilyMembersDetail = async (memberId) => {
    setIsLoading(true);
    //session related uncomment for session
    // if (!(await sessionExist())) {
    //   console.log("session extending having trouble");
    //   setIsFailed(true);
    //   setIsLoading(false);
    //   return;
    // }

    // let token = localStorage.getItem("jwt");

    // if (!token) {
    //   setIsFailed(true);
    //   setIsLoading(false);
    //   return;
    // }

    // token = token.replace('"', "").replace('"', "");

    const url =
      process.env.REACT_APP_SERVER_URL + "/show/all/existing/family/member";
    const formData = new FormData();
    //formData.append("searchFieldValue", jwtDecode(token).user);
    formData.append("cmpfAccNo", memberId);
    //formData.append("searchFieldValue", "HYD/170/840");
    const config = {
      headers: {
        //  Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsLoading(false);
        setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          console.log("familymemberdata", data);
          setFamilyMembersDetails(data);
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        console.log("error", error);
      });
  };

  /// error alert handler
  const handleErrorAlert = () => {
    setIsError(false);
  };

  const handleSuccess = () => {
    // setIsSuccess(false);
    navigate("/aao/addfammemaao");
  };

  const handleCancelReturn = () => {
    setIsReturnClicked(false);
  };
  const handleReturnOk = () => {
    handleReturnClaim();
  };

  const handleIdsOk = (e) => {
    setIsSubmitClicked(true);
  };

  const handleAssignToCancel = () => {
    setIsSubmitClicked(false);
  };

  const handleSubmitOk = () => {
    if (showingDropdown === 1) {
      if (!objectTobeSent.toOffcrLoginId) {
        // Show message in dialog: "Please select an officer to assign"
        // alert("Please select an officer to assign");
        setIsError(true);
        setErrorMessage("Please Select an Officer to Assign.");
      } else {
        setIsSubmitClicked(true);
      }
    }
  };
  // todo handleAssignToOk calling handle submit on click of ok confirmation dialouge box
  const handleAssignToOk = (e) => {
    handleSubmit(e);
    setIsSubmitClicked(false);
  };

  const familyMemberCreatePreviewData = () => {
    let tablePreviewData = [];
    // const familydata = familyMemberSamples;
    const familydata = familyMembersDetails;

    familydata.map((family) => {
      tablePreviewData.push({
        " Family Member Id": family.familyMemId,
        " Full Name": family.name,
        " Relation With Member": family.relationName,
        " Date of Birth": family.dateOfBirth
        ? new Date(family.dateOfBirth).toLocaleDateString("en-GB")
        : "",
        // " Age": family.age,
        // "Marital Status": family.maritalStatusFamily,
        // "Family Member Status": family.famMemStatus,
      });
    });

    return tablePreviewData;
  };
  const viewLink = "familydetails";

  return (
    <div>
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error!"
        body={errorMessage}
        buttonName="Ok"
      />
      <SucessAlert
        handleOk={handleSuccess}
        open={isSuccess}
        title="Success"
        body={successMessage}
        buttonName="OK"
      />
      <ConfirmationDialogue
        handleOk={handleAssignToOk}
        handleCancel={handleAssignToCancel}
        open={isSubmitClicked}
        title="Confirm Assignment"
        body={`Are you sure you want to approve Add Family Member request?`}
        buttonName="Confirm"
      />
      <ConfirmationDialogue
        handleOk={handleReturnOk}
        handleCancel={handleCancelReturn}
        open={isReturnClicked}
        title="Confirm Return"
        body="Are you sure you want to return Add Family Member request?"
        buttonName="Confirm"
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "10px" }}>
            {/* {isFailed || isFailedDoc ? (
              <Reloader refreshHandler={fetchAllClaimDetail} />
            ) : (
              <>
                {isLoading || isLoadingDoc ? (
                  <LoadingIndicator />
                ) : ( */}
            <>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <TitleText title="Add Family Member Details Approval" />
                </Grid>
              </Grid>
              <FormDivider />
              <Grid container spacing={1}>
                <Grid item xs={12} md={1} mb={3}>
                  <BackButton />
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="start"
                  rowGap={3}
                >
                  {/* Member Details*/}
                  {/* section 1  */}
                  <Paper
                    elevation={3}
                    sx={{ padding: "3rem", borderRadius: "10px" }}
                  >
                    <Grid item xs={12}>
                      <LimitedMemberDetatils member={memberData} />
                    </Grid>
                    <Grid item xs={12} sm={12} mt={2}>
                      <SubtitleText title="Family Members Details" />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TableWithLinkAndButton
                        preview={familyMemberCreatePreviewData()}
                        data={familyMembersDetails}
                        passingId="familyMemId"
                        // actions={submitTableActions}
                        viewLink={viewLink}
                      />
                    </Grid>
                  </Paper>
                  {/* claim Details*/}
                  {/* section 2  */}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={12}>
                      <Grid item xs={12}>
                        <AddFamilyMemberDetails
                          memUpdReqdetails={memUpdReqdetails}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Document Details*/}
                  {/* section 3  */}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={12}>
                      <Paper
                        elevation={3}
                        sx={{ padding: "3rem", borderRadius: "10px" }}
                      >
                        <Grid item xs={12}>
                          <ViewDocuments documents={documents} />
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>

                  {/* ************************************ Remarks ***************************************** */}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={12}>
                      <Paper
                        elevation={3}
                        sx={{ padding: "2rem", borderRadius: "10px" }}
                      >
                        <SubtitleText title="Remarks" />
                        <Grid item xs={12} sm={6} md={8}>
                          <CustomTextArea {...remarks} onchange={onChange} />
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                  {/* *************** Submit Button ********* */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Grid item>
                      <Button
                        onClick={handleReturnClick}
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isReturnClicked}
                      >
                        {isReturnClicked ? <CircularProgress /> : "Return"}
                      </Button>
                    </Grid>
                 
                  <Grid item md={6}>
                  <Grid container justifyContent="end" mb={2} spacing={2}>
                  <Grid item md={5}>
                    <Button
                      onClick={handleIdsOk}
                      variant="contained"
                      color="success"
                      type="submit"
                      disabled={isSubmitting}
                      fullWidth
                    >
                      {isSubmitting ? <CircularProgress /> : "approve"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              </Grid>
              </Grid>
              </Grid>



              {/* {showingDropdown === 1 && (
                <Grid
                  container
                  justifyContent="end"
                  alignItems={"center"}
                  spacing={2}
                >
                  <Grid item xs={12} sm={4}>
                    <CustomDropdown {...assignTo} onchange={onChange} />
                  </Grid>
                  <Grid item md={1}>
                    <Button
                      onClick={handleSubmitOk}
                      variant="contained"
                      color="success"
                      type="submit"
                      disabled={isSubmitting}
                      fullWidth
                    >
                      {isSubmitting ? <CircularProgress /> : "OK"}
                    </Button>
                  </Grid>
                </Grid>
              )} */}


            </>
            {/* )}
              </>
                  ) */}
            {/* } */}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default ViewAddFamMemAao;
