import { Grid } from "@mui/material";

import React from "react";

import MemberDetails from "../Pages/UDAPages/MemberDetatils";
import SubtitleText from "../FormComponent/SubtitleText";
import TableWithLinkAndButton from "../FormComponent/TableWithLinkAndButton";

function ClaimDetails() {
  const sampleMemberData = {
    // This is the sample member data to be displed for demo purpose only
    title: "Mr",
    fullName: "Baijunath Sharma",
    dateOfBirth: "",
    panNo: "",
    aadhaarNumber: "",
    maritalStatus: "",
    religionName: "",
    gender: "",
    fatherHusbandName: "",
    email: "",
    mobileNo: "",
    landNo: "",
    addressL1: "",
    addressL2: "",
    village: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  };

  const createTablePreviewDataReturn = () => {
    // the functions that creates a table preview
    const tableSamples = familyMemberSamples; //familyMemberSamples must be replaced witht he response data recevied on the api request

    let tablePreviewData = [];
    tableSamples.map((member) => {
      tablePreviewData.push({
        "Family Member ID": member.family_member_id,
        "Name of Nominee": member.member_name,
        Gender: member.gender,
        Relation: member.relation,
        "Date of Birth": member.dob,
        "Share in percentage": member.nominee_percent,
      });
    });
    return tablePreviewData;
  };
  const viewLink = "/familydetails"; // Link for family members details

  const familyMemberSamples = [
    // samples to be displayed on table
    {
      family_member_id: 1,
      member_name: "ABC",
      gender: "Female",
      relation: "Wife",
      dob: "1991-07-31",
      nominee_percent: "50",
    },
    {
      family_member_id: 2,
      member_name: "xyz",
      gender: "Male",
      relation: "Son",
      dob: "2013-03-09",
      nominee_percent: "50",
    },
  ];

  return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        alignItems="start"
        rowGap={3}
      >
        {/* section 1  */}
        <Grid item xs={12}>
          <MemberDetails member={sampleMemberData} />
        </Grid>

        {/* ************************************** */}
        {/* Nominee status */}

        {/* section 3  */}
        <Grid item xs={12}>
          <SubtitleText title="Nomination Status" />
        </Grid>

        {/* <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    columnGap={1}
                  > */}
        <Grid item xs={12}>
          <TableWithLinkAndButton
            preview={createTablePreviewDataReturn()}
            data={familyMemberSamples}
            passingId="familyMemberid"
            actions=""
            viewLink={viewLink}
          />
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </div>
  );
}

export default ClaimDetails;
