import {
  Paper,
  Grid,
  Button,
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import {
  roEnrolmentModel,
  roEnrolmentMandatoryFields,
} from "../../Models/defaultModel";
import LabelText from "../../FormComponent/LabelText";
import CustomTextField from "../../FormComponent/CustomTextField";
import SubtitleText from "../../FormComponent/SubtitleText";
import { trimStringUtility } from "../../Utility/UtilityMethods";
import { formPatternValidation } from "../../Utility/UtilityMethods";
import axios from "axios";
import CustomDropdown from "../../FormComponent/CustomDropdown";

function ROEnrolment() {
  const roDefaultModel = roEnrolmentModel;
  const [ro, setRo] = useState(roDefaultModel);
  const [readOnly] = useState(false);

  // for Regional Officer List
  const [roCodeList, setROcodeList] = useState([]);
  const [roNameList, setRONameList] = useState([]);
  const [roHeadList, setROHeadList] = useState([]);

  // for state autocomplete
  const [stateLoading, setStateLoading] = useState(false);
  const [stateList, setStateList] = useState([]);

  // for CC rep auto complete
  const [noLoading, setNOLoading] = useState(false);
  const [noNameList, setnoNameList] = useState([]);

  // const ro_name = {
  //   name: "ro_name",
  //   type: "text",
  //   helperText: '',
  //   placeholder: 'Regional Office Name*',
  //   defaultValue: ro.ro_name,
  //   errorMessage:
  //     "Should contain only AlphaNumeric Characters.underscore is accepted",
  //   pattern: '^[a-zA-Z0-9-]{0,25}$',
  //   required: roEnrolmentMandatoryFields.includes('ro_name'),
  //   transformInput: trimStringUtility,
  //   disabled: readOnly,
  // };
  //dropdown
  const ro_name = {
    name: "ro_name",
    placeholder: "Regional Office Name*",
    //label: "Select CC",
    helperText: "",
    disabled: readOnly,
    initialValue: ro.ro_name,
    required: roEnrolmentMandatoryFields.includes("ro_name"),
    //options: [{ id: '1', title: "Hindu" }, { id: '2', title: 'Christian' }, { id: '3', title: 'Muslim' }, { id: '4', title: 'Jain' }, { id: '5', title: 'Sikh' }, { id: 6, title: 'Buddhist' }, { id: 7, title: 'Other' }],
    options: roNameList,
  };

  // const ro_code = {
  //   name: "ro_code",
  //   type: "text",
  //   helperText: '',
  //   placeholder: 'Regional Office Code*',
  //   defaultValue: ro.ro_code,
  //   errorMessage:
  //     "Invalid RO code.",
  //   pattern: "",
  //   required: roEnrolmentMandatoryFields.includes('ro_code'),
  //   transformInput: trimStringUtility,
  //   disabled: readOnly,
  // };

  // dropdown
  const ro_code = {
    name: "ro_code",
    placeholder: "Regional Office Code*",
    //label: "Select CC",
    helperText: "",
    disabled: readOnly,
    initialValue: ro.ro_code,
    //options: [{ id: '1', title: "Hi
    required: roEnrolmentMandatoryFields.includes("ro_code"),

    options: roCodeList,
  };

  // const ro_head_name = {
  //   name: "ro_head_name",
  //   type: "text",
  //   helperText: '',
  //   placeholder: 'Regional Head Name',
  //   defaultValue: ro.ro_head_name,
  //   errorMessage:
  //     "Invalid Name.",
  //   pattern: "^[a-zA-Z- ]+$",
  //   required: roEnrolmentMandatoryFields.includes('ro_head_name'),
  //   transformInput: trimStringUtility,
  //   disabled: readOnly,
  // };

  // dropdown
  const ro_head_name = {
    name: "ro_head_name",
    placeholder: "Regional Head Name",
    // label: "Select CC head",
    helperText: "",
    disabled: readOnly,
    initialValue: ro.ro_head_name,
    required: roEnrolmentMandatoryFields.includes("ro_head_name"),
    //options: [{ id: '1', title: "Hindu" }, { id: '2', title: 'Christian' }, { id: '3', title: 'Muslim' }, { id: '4', title: 'Jain' }, { id: '5', title: 'Sikh' }, { id: 6, title: 'Buddhist' }, { id: 7, title: 'Other' }],
    options: roHeadList,
  };
  const ro_head_email = {
    name: "ro_head_email",
    type: "email",
    helperText: "",
    placeholder: "RO Head Official Email",
    defaultValue: ro.ro_head_email,
    errorMessage: "Invalid Email.",
    pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$",
    required: roEnrolmentMandatoryFields.includes("ro_head_email"),
    transformInput: trimStringUtility,
    disabled: readOnly,
  };

  const no_name = {
    name: "no_name",
    type: "text",
    helperText: "",
    placeholder: "Nodal Officer Name",
    defaultValue: ro.no_name,
    errorMessage: "Invalid Name",
    pattern: "^[a-zA-Z- ]+$",
    required: roEnrolmentMandatoryFields.includes("no_name"),
    disabled: readOnly,
  };

  const no_designation = {
    name: "no_designation",
    type: "text",
    helperText: "",
    placeholder: "Nodal Officer Designation",
    defaultValue: ro.no_designation,
    errorMessage:
      "First Name must be characters only and not allowed special characters except white space.",
    pattern: "",
    required: roEnrolmentMandatoryFields.includes("no_designation"),
    disabled: readOnly,
  };

  const address_l1 = {
    name: "address_l1",
    type: "text",
    helperText: "",
    placeholder: "Street Address1*",
    defaultValue: ro.address_l1,
    errorMessage:
      "First Name must be characters only and not allowed special characters except white space.",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const address_l2 = {
    name: "address_l2",
    type: "text",
    helperText: "",
    placeholder: "Street Address2",
    defaultValue: ro.address_l2,
    errorMessage:
      "First Name must be characters only and not allowed special characters except white space.",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const city = {
    name: "city",
    type: "text",
    helperText: "",
    placeholder: "City",
    defaultValue: ro.city,
    errorMessage:
      "First Name must be characters only and not allowed special characters except white space.",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  // const state = {
  //   name: "state",
  //   type: "text",
  //   helperText: '',
  //   placeholder: 'State',
  //   defaultValue: ro.state,
  //   errorMessage:
  //     "First Name must be characters only and not allowed special characters except white space.",
  //   pattern: "",
  //   required: false,
  //   disabled: readOnly,
  // };

  // using autocomplete component for states

  const pincode = {
    name: "pincode",
    type: "text",
    helperText: "",
    placeholder: "Pincode",
    defaultValue: ro.pincode,
    errorMessage:
      "First Name must be characters only and not allowed special characters except white space.",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const off_email = {
    name: "off_email",
    type: "email",
    helperText: "",
    placeholder: "Official Email*",
    defaultValue: ro.off_email,
    errorMessage: "Invalid Email",
    pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$",
    required: roEnrolmentMandatoryFields.includes("off_email"),
    disabled: readOnly,
  };

  const off_contact_no = {
    name: "off_contact_no",
    type: "email",
    helperText: "",
    placeholder: "Official Contact",
    defaultValue: ro.off_contact_no,
    errorMessage:
      "First Name must be characters only and not allowed special characters except white space.",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const mob_no = {
    name: "mob_no",
    type: "text",
    helperText: "",
    placeholder: "Mobile Number",
    defaultValue: ro.mob_no,
    errorMessage:
      "First Name must be characters only and not allowed special characters except white space.",
    pattern: "",
    required: false,
    disabled: readOnly,
  };

  const fetchROcodes = async () => {
    const url = process.env.REACT_APP_SERVER_URL + "/allRo";

    axios.get(url).then((response) => {
      const roCodeListSamples = response.data.data;
      console.log("response in roCodeList", response);
      const options = roCodeListSamples.map((option) => ({
        id: option.roId,
        title: option.roCode,
      }));
      console.log("ro code List", options);
      setROcodeList(options);
      const roNameOptions = roCodeListSamples.map((option) => ({
        id: option.roId,
        title: option.roName,
      }));
      console.log("ro name List", roNameOptions);
      setRONameList(roNameOptions);
    });
  };

  const fetchROHeads = async () => {
    const url = process.env.REACT_APP_SERVER_URL + "/ro/head";

    axios.get(url).then((response) => {
      const roHeadListSamples = response.data.data;
      console.log("response in roCodeList", response);
      const options = roHeadListSamples.map((option) => ({
        id: option.roHeadId,
        title: option.roHeadName,
      }));
      console.log("roHeadList", options);
      setROHeadList(options);
    });
  };

  const fetchStates = async () => {
    try {
      console.log("in fetch states");
      const statesListSamples = await getStates();
      console.log("statesListSamples", statesListSamples);

      const options = statesListSamples.map((option) => {
        //const firstID = option.match(/[0-9]/) ? "0-9" : option.charAt(0); // Get the first letter
        console.log("option", option);
        const firstID = option.stateName;
        console.log("firstId", firstID);

        return {
          firstID: /[0-9]/.test(firstID.charAt(0)) ? "0-9" : firstID.charAt(0),
          stateName: option.stateName,
          value: option.stateId,
        };
      });

      setStateLoading(false);
      setStateList(options);
      //console.log("unit options", options);
    } catch (error) {
      // Handle error if needed.
    }
  };

  const getNo = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + "/ro/officer";
      const response = await axios.get(url);
      console.log("nodal officer", response);
      return response.data.data;
    } catch (error) {
      console.log("error - nodalOfficer", error);
      return [];
    }
  };
  const fetchNo = async () => {
    try {
      console.log("in fetch no");
      const noListSamples = await getNo();
      console.log("noListSamples", noListSamples);

      const options = noListSamples.map((option) => {
        //const firstID = option.match(/[0-9]/) ? "0-9" : option.charAt(0); // Get the first letter
        console.log("option", option);
        const firstID = option.name;
        console.log("firstId", firstID);
        console.log("officerloginid", option.loginId);

        return {
          firstID: /[0-9]/.test(firstID.charAt(0)) ? "0-9" : firstID.charAt(0),
          name: option.name,
          value: option.loginId,
        };
      });

      setNOLoading(false);
      setnoNameList(options);
      //console.log("unit options", options);
    } catch (error) {
      // Handle error if needed.
    }
  };

  const getStates = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + "/cc/states";
      const response = await axios.get(url);
      console.log("states", response);
      return response.data.data;
    } catch (error) {
      console.log("error - states", error);
      return [];
    }
  };

  const config = {
    ro_name: ro_name,
    ro_code: ro_code,
    ro_head_name: ro.ro_head_name,
    ro_head_email: ro_head_email,
    no_name: ro.no_name,
    no_designation: no_designation,
    address_l1: address_l1,
    address_l2: address_l2,
    city: city,
    state: ro.state,
    pincode: pincode,
    off_email: off_email,
    off_contact_no: off_contact_no,
    mob_no: mob_no,
  };
  const handleStateSearch = (event, newValue) => {
    console.log("state", newValue);
    setRo({ ...ro, state: newValue.value });
  };

  const handleNoNameSearch = (event, newValue) => {
    console.log("Nodal officer name", newValue);
    setRo({ ...ro, no_name: newValue.value });
  };
  const validatePattern = () => {
    let flag = true;
    for (let key in ro) {
      if (!formPatternValidation(ro[key], config[key].pattern)) {
        flag = false;
      }
    }
    return flag;
  };
  useEffect(() => {
    fetchROcodes();
    fetchStates();
    fetchROHeads();
    fetchNo();
  }, []);

  const onChange = (e) => {
    setRo({ ...ro, [e.target.name]: e.target.value });
  };
  const handleRoCodeSelect = (e) => {
    console.log("select ro ", e.target.value);
    setRo({ ...ro, ro_code: e.target.value, ro_name: e.target.value });
  };

  const formData = {
    addressL1: ro.address_l1,
    addressL2: ro.address_l2,
    officialContact: ro.off_contact_no,
    roHeadId: ro.ro_head_name,
    nodalOffLId: ro.no_name,
    officialEmail: ro.off_email,
    mobile: ro.mob_no,
    stateId: ro.state,
    roId: ro.ro_code,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("form pattern Validation status", validatePattern());
    console.log("save button Clicked data=>):", ro);

    console.log("fromdata to be sent", formData);
    const url = process.env.REACT_APP_SERVER_URL + "/enrolro";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, formData, { headers })
      .then(function (response) {
        console.log(response);
      })
      .catch((error) => {
        console.log("error on submit", error);
      });
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
          <Grid item xs={12}>
            <TitleText title="RO Enrolment" />
          </Grid>
          <FormDivider />

          {/* Form Wrapper grid */}
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            rowGap={2}
          >
            {/* Form Divider main Grid */}
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-start"
              rowGap={2}
            >
              <Grid item xs={12} sm={12}>
                <SubtitleText title="Regional Office Details" />
              </Grid>

              {/* section 1 column 1 - regional*/}
              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  rowGap={2}
                  columnGap={2}
                >
                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Regional Office Name" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomDropdown
                      {...ro_name}
                      onchange={handleRoCodeSelect}
                    />
                  </Grid>

                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Official Email" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomTextField {...off_email} onchange={onChange} />
                  </Grid>

                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Official Contact" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomTextField {...off_contact_no} onchange={onChange} />
                  </Grid>

                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="State" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Autocomplete
                      sx={{
                        minWidth: "150px",
                      }}
                      ListboxProps={{
                        style: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          maxHeight: 180, // Change this to your desired height
                        },
                      }}
                      // freeSolo
                      // inputValue={cc.state}
                      disableClearable
                      onChange={(e, newValue) => handleStateSearch(e, newValue)}
                      options={stateList.sort(
                        (a, b) => -b.firstID.localeCompare(a.firstID)
                      )}
                      groupBy={(stateList) => stateList.firstID}
                      getOptionLabel={(stateList) => stateList.stateName}
                      loading={stateLoading} // Set loading prop
                      renderInput={(params) => (
                        <TextField
                          name="state"
                          sx={{
                            "& .MuiFormHelperText-root": {
                              marginLeft: 0,
                              // fix based on your screen
                              fontSize: {
                                xs: "9px",
                                sm: "9px,",
                                md: "9px",
                                lg: "12px",
                                xl: "14px",
                              },
                              fontWeight: "400",
                              color: "black",
                            },
                            "& .MuiInputBase-input": {
                              minWidth: "80px",
                              borderRadius: "5px",
                              color: "black",
                              background: "#ffffff",
                              // fix based on your screen
                              fontSize: {
                                xs: 12,
                                sm: 12,
                                md: 13,
                                lg: 14,
                                xl: 16,
                              },
                            },
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "5px",
                              "& fieldset": {
                                borderColor: "#474E68",
                                // borderRadius:'0px'
                              },
                              "&:hover fieldset": {
                                borderColor: "#6B728E",
                                // borderRadius: '0px'
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#0F3460",
                                borderWidth: "0.5px",
                                // borderRadius: '0px'
                              },
                            },
                          }}
                          autoComplete="off"
                          // size='small'

                          {...params}
                          placeholder="Enter State"
                          // label="Unit No."

                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {stateLoading && (
                                  <CircularProgress color="inherit" size={20} />
                                )}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                          // helperText={
                          //   <div style={{ height: "18px" }}></div>
                          // }
                        />
                      )}
                    />
                  </Grid>

                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Pincode" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomTextField {...pincode} onchange={onChange} />
                  </Grid>
                </Grid>
              </Grid>

              {/* section 1 column 2 */}
              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  rowGap={2}
                  columnGap={2}
                >
                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Regional Office Code" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomDropdown
                      {...ro_code}
                      onchange={handleRoCodeSelect}
                    />
                  </Grid>

                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="City" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomTextField {...city} onchange={onChange} />
                  </Grid>

                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Street Address1" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomTextField {...address_l1} onchange={onChange} />
                  </Grid>

                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Street Address2" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomTextField {...address_l2} onchange={onChange} />
                  </Grid>
                </Grid>
              </Grid>

              {/* ******************** RO Head ********************* */}

              <Grid item xs={12} sm={12}>
                <SubtitleText title="RO Head Details" />
              </Grid>

              {/* section 2 column 1 - ro head*/}
              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  rowGap={2}
                  columnGap={2}
                >
                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="RO Head Name" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomDropdown {...ro_head_name} onchange={onChange} />
                  </Grid>
                </Grid>
              </Grid>

              {/* section 2 column 2 */}
              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  rowGap={2}
                  columnGap={2}
                >
                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="RO Head Email" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomTextField {...ro_head_email} onchange={onChange} />
                  </Grid>
                </Grid>
              </Grid>

              {/* ******************   nodal officer ******************* */}
              <Grid item xs={12} sm={12}>
                <SubtitleText title="Nodal Officer Details" />
              </Grid>

              {/* section 3 column 1 - nodal*/}
              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  rowGap={2}
                  columnGap={2}
                >
                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Nodal Officer's Name" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    {/* <CustomTextField {...no_name} onchange={onChange} /> */}
                    <Autocomplete
                      sx={{
                        minWidth: "150px",
                      }}
                      ListboxProps={{
                        style: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          maxHeight: 180, // Change this to your desired height
                        },
                      }}
                      // freeSolo
                      // inputValue={cc.cc_rep_name}
                      disableClearable
                      onChange={(e, newValue) => {
                        console.log("value at no name", newValue);
                        handleNoNameSearch(e, newValue);
                      }}
                      options={noNameList.sort(
                        (a, b) => -b.firstID.localeCompare(a.firstID)
                      )}
                      groupBy={(noNameList) => noNameList.firstID}
                      getOptionLabel={(noNameList) => noNameList.name}
                      loading={noLoading} // Set loading prop
                      renderInput={(params) => (
                        <TextField
                          name="no_name"
                          // onChange={(e) => handleUnitNoSearchFreeSolo(index, e)}
                          sx={{
                            "& .MuiFormHelperText-root": {
                              marginLeft: 0,
                              // fix based on your screen
                              fontSize: {
                                xs: "9px",
                                sm: "9px,",
                                md: "9px",
                                lg: "12px",
                                xl: "14px",
                              },
                              fontWeight: "400",
                              color: "black",
                            },
                            "& .MuiInputBase-input": {
                              minWidth: "80px",
                              borderRadius: "5px",
                              color: "black",
                              background: "#ffffff",
                              // fix based on your screen
                              fontSize: {
                                xs: 12,
                                sm: 12,
                                md: 13,
                                lg: 14,
                                xl: 16,
                              },
                            },
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "5px",
                              "& fieldset": {
                                borderColor: "#474E68",
                                // borderRadius:'0px'
                              },
                              "&:hover fieldset": {
                                borderColor: "#6B728E",
                                // borderRadius: '0px'
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#0F3460",
                                borderWidth: "0.5px",
                                // borderRadius: '0px'
                              },
                            },
                          }}
                          autoComplete="off"
                          // size='small'

                          {...params}
                          placeholder="Nodal Officer's Name"
                          // label="Unit No."

                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {noLoading && (
                                  <CircularProgress color="inherit" size={20} />
                                )}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                          // helperText={
                          //   <div style={{ height: "18px" }}></div>
                          // }
                        />
                      )}
                    />
                  </Grid>

                  {/* row  */}
                  {/* mobile no shifted to coulmn2 as the nodal officer designation field is removed */}
                  {/* <Grid item xs={12} sm={4}>
                    <LabelText title="Mobile Number" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomTextField {...mob_no} onchange={onChange} />
                  </Grid> */}
                </Grid>
              </Grid>

              {/* section 3 column 2 */}
              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  rowGap={2}
                  columnGap={2}
                >
                  {/* row  */}
                  {/* <Grid item xs={12} sm={4}>
                    <LabelText title="Nodal Officer Designation" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomTextField {...no_designation} onchange={onChange} />
                  </Grid> */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Mobile Number" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <CustomTextField {...mob_no} onchange={onChange} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* ***************** save button left aligned  *********************/}
            <Grid item xs={12} sm="auto">
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="success"
                // disabled={true}
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default ROEnrolment;
