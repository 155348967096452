// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useState, useContext, useEffect } from "react";
import LandingLayout from "./Layouts/LandingLayout";
import Home from "./Pages/LandingPage/Home";
import ForgotPassword from "./Pages/LoginPages/ForgotPassword";
import ForgotPasswordOtp from "./Pages/LoginPages/ForgotPasswordOtp";
import ChangePassword from "./Pages/LoginPages/ChangePassword";
import ChangePasswordOtp from "./Pages/LoginPages/ChangePasswordOtp";
import OTPVerification from "./Pages/LoginPages/OTPVerification";
import Redirector from "./UiManager/Redirector";
import AccessDenied from "./UiManager/AccessDenied";
import RouterProtector from "./UiManager/RouterProtector";
import MemberDbLayout from "./Layouts/MemberDbLayout";
import AccountSummary from "./Pages/MemberPages/AccountSummary";
import PfStatementView from "./Pages/MemberPages/PfStatementView";
import PensionStatementView from "./Pages/MemberPages/PensionStatementView";
import FamilyForm from "./Pages/MemberPages/FamilyForm";
// import FamilyListView from "./Pages/MemberPages/FamilyListView";

import { CssBaseline } from "@mui/material";
import Test from "./Test";
import YearDropdown from "./Test";
import Member from "./components/enrollment/Member";
import { UserProvider } from "./UiManager/UserProvider";
import Profile from "./Pages/MemberPages/Profile";
import PageNotFound from "./UiManager/PageNotFound";
import UnderDev from "./UiManager/UnderDev";
import { UserContext } from "./UiManager/UserProvider";
import { CaptchaProvider } from "./components/captcha/CaptchaProvider";
import axios from "axios";
import MemberLogin from "./Pages/LoginPages/MemberLogin";
import OfficerLogin from "./Pages/LoginPages/OfficerLogin";
import Contact from "./Pages/LandingPage/Contact";
import TermsAndCondition from "./Pages/LandingPage/TermsAndCondition";
import PrivacyPolicy from "./Pages/LandingPage/PrivacyPolicy";
import NODbLayout from "./Layouts/NODbLayout";
import ROEnrolment from "./Pages/NodalOfficerPages/ROEnrolment";
import CcRepDbLayout from "./Layouts/CcRepDbLayout";
import CCEnrolment from "./Pages/CcRepPages/CCEnrolment";
import OfficerEnrol from "./components/enrollment/OfficerEnrol";
import OfficialWeb from "./Pages/LandingPage/OfficialWeb";
//import Dashboard from "./Pages/LandingPage/Dashboard";
import ViewConsent from "./Pages/CcRepPages/ViewConsent";
import ChangePasswordCCRep from "./Pages/LoginPages/ChangePasswordCCRep";
import ForgotPasswordOfficer from "./Pages/LoginPages/ForgotPasswordOfficer";
import ForgotPasswordOtpOfficer from "./Pages/LoginPages/ForgotPasswordOtpOfficer";
import OfficerEnrolNew from "./components/enrollment/OfficerEnrolNew";
import HeadsDbLayout from "./Layouts/HeadsDbLayout";
import AssignRoles from "./Pages/RhCchPages/AssignRoles";
import MemberForm from "./Pages/UDAPages/MemberForm";
import UserGuide from "./UserManual/UserGuide";
import ClaimsTable from "./SettlementComponents/ClaimsTable";
import ViewClaimDetails from "./Pages/PfSettelement/ViewClaimDetails";
import PfSettlement from "./Pages/UDAPages/PfSettlementUda";
import NewPfClaim from "./Pages/UDAPages/NewPfClaim";
import UdaDbLayout from "./Layouts/UdaDbLayout";
import MemberDetails from "./Pages/UDAPages/MemberDetatils";
import ClaimForm from "./Pages/UDAPages/ClaimForm";
import ClaimDetails from "./SettlementComponents/ClaimDetails";
import ViewDocuments from "./SettlementComponents/ViewDocuments";
import TestDoc from "./Pages/UaoPages/TestDoc";
import NewPfAdvanceClaim from "./Pages/UDAPages/NewPfAdvanceClaim";
import UploadDocument from "./SettlementComponents/UploadDocument";
import Abc from "./Pages/UaoPages/Abc";
import AdditionalAdvPf from "./Pages/UDAPages/AddtionalAdvPf";

import VeiwPFClaimUDA from "./Pages/UDAPages/VeiwPFClaimUDA";
import SearchMember from "./Pages/OfficerPages/SearchMember";
import UpdateMemberDetails from "./Pages/OfficerPages/UpdatePages/UpdateMemberDetails";
import UpdateFamilyMemberDetails from "./Pages/OfficerPages/UpdatePages/UpdateFamilyMemberDetails";
import DeleteFamilyMember from "./Pages/OfficerPages/DeletePages/DeleteFamilyMember";
import MemberDetailsSearch from "./Pages/OfficerPages/Fetch/MemberDetailsSearch";
import FamilyMemberDetails from "./Pages/OfficerPages/Fetch/FamilyMemberDetails";
import EditReturnedClaimUda from "./Pages/UDAPages/EditReturnedClaimUda";
import FamMemDetSer from "./Pages/OfficerPages/Fetch/FamMemDetSer";
//import EditReturnedAddFamMem from "./Pages/UDAPages/EditReturnedAddFamMem";
import EditPen from "./Pages/UDAPages/EditReturnedPenClaim";
import AddFamilyMemberUda from "./Pages/UDAPages/AddFamilyMemberUda";
import NewAddFam from "./Pages/UDAPages/NewAddFam";
import AddFamMemForm from "./Pages/AddFamilyMemberPages/AddFamMemForm";

//import AddFamilyMemberUda from "./Pages/UDAPages/AddFamilyMemberUda";
//***************************************************************** */
// In this page have details of ADVANCE claim
// In this page can access all officer for view only (without button)
import ProceedAdvClaimAao from "./Pages/AaoPages/ProceedAdvClaimAao";
import EditReturnedAdvClaim from "./Pages/UDAPages/EditReturnedAdvClaim";
//***************************************************************** */
import AdvSettlementUda from "./Pages/UDAPages/AdvSettlementUda";
//import EditAddNewFamilyMember from "./Pages/AddFamilyMemberPages/EditAddNewFamilyMember";

import UpdatedFamilyMemberUda from "./Pages/UDAPages/UpdatedFamilyMemberUda";
import NewUpdFam from "./Pages/UDAPages/NewUpdFam";
import UpdateMemberDetailsUda from "./Pages/UDAPages/UpdateMemberDetailsUda";
//import OtherProjectRoutes from "./Pages/OtherProject/OtherProjectRoutes";
//************************** UAO **************************
import UaoDbLayout from "./Layouts/UaoDbLayout";

import AdvSettlementUao from "./Pages/UaoPages/AdvSettlementUao";
import ViewAdvClaimUao from "./Pages/UaoPages/ViewAdvClaimUao";

import PfSettlementUao from "./Pages/UaoPages/PfSettlementUao";
import ViewPfClaimUao from "./Pages/UaoPages/ViewPfClaimUao";
import UpdMemUao from "./Pages/UaoPages/UpdMemUao";
import ViewUpdMemUao from "./Pages/UaoPages/ViewUpdMemUao";
import ProceedUpdMemDetails from "./Pages/UaoPages/ProceedUpdMemDetails";

import UpdFamMemUao from "./Pages/UaoPages/UpdFamMemUao";
import ViewUpdFamMemUao from "./Pages/UaoPages/ViewUpdFamMemUao";
import ProceedUpdFamMemDetails from "./Pages/UaoPages/ProceedUpdFamMemDetails";
import ProceedAddFamMemDetails from "./Pages/UaoPages/ProceedAddFamMemDetails";
import AddFamMemUao from "./Pages/UaoPages/AddFamMemUao";
import ViewAddFamMemUao from "./Pages/UaoPages/ViewAddFamMemUao";

//************************** AAO **************************
import AaoDbLayout from "./Layouts/AaoDbLayout";

import AdvSettlementAao from "./Pages/AaoPages/AdvSettlementAao";
import ViewAdvClaimAao from "./Pages/AaoPages/ViewAdvClaimAao";

import PfSettlementAao from "./Pages/AaoPages/PfSettlementAao";
import ViewPfClaimAao from "./Pages/AaoPages/ViewPfClaimAao";
import ProceedPfClaimAao from "./Pages/AaoPages/ProceedPfClaimAao";
import RecivedForConfm from "./Pages/AaoPages/RecivedForConfm";
import RecivedForConfmAdv from "./Pages/AaoPages/RecivedForConfmAdv";
import UpdMemAao from "./Pages/AaoPages/UpdMemAao";
import ViewUpdMemAao from "./Pages/AaoPages/ViewUpdMemAao";

import UpdFamMemAao from "./Pages/AaoPages/UpdFamMemAao";
import ViewUpdFamMemAao from "./Pages/AaoPages/ViewUpdFamMemAao";
import AddFamMemAao from "./Pages/AaoPages/AddFamMemAao";
import ViewAddFamMemAao from "./Pages/AaoPages/ViewAddFamMemAao";

//************************** AGM **************************
import AgmDbLayout from "./Layouts/AgmDbLayout";

import PfSettlementAgm from "./Pages/AgmPages/PfSettlementAgm";
import ViewPfClaimAgm from "./Pages/AgmPages/ViewPfClaimAgm";

import AdvSettlementAgm from "./Pages/AgmPages/AdvSettlementAgm";
import ViewAdvClaimAgm from "./Pages/AgmPages/ViewAdvClaimAgm";
//************************** IDS **************************
import IdsDbLayout from "./Layouts/IdsDbLayout";

import AdvSettlementIds from "./Pages/IdsPages/AdvSettlementIds";
import PfSettlementIds from "./Pages/IdsPages/PfSettlementIds";
import ViewPfClaimIds from "./Pages/IdsPages/ViewPfClaimIds";
import ViewAdvClaimIds from "./Pages/IdsPages/ViewAdvClaimIds";

//************************** RODA **************************
import RodaDbLayout from "./Layouts/RodaDbLayout";

import AdvSettlementRoda from "./Pages/RoDaPages/AdvSettlementRoda";
import ViewAdvClaimRoda from "./Pages/RoDaPages/ViewAdvClaimRoda";
import PDFGenerator from "./Pages/RoDaPages/PDFGenerator";
import AdvDpo from "./Pages/RoDaPages/AdvDpo";
import AdvMarrigeDpo from "./Pages/RoDaPages/AdvMarrigeDpo";

import PfSettlementRoda from "./Pages/RoDaPages/PfSettlementRoda";
import ViewPfClaimRoda from "./Pages/RoDaPages/ViewPfClaimRoda";
import PfDpo from "./Pages/RoDaPages/PfDpo";
import Pfo from "./Pages/DpoAndPoPages/Pfo";
import Ppo from "./Pages/DpoAndPoPages/Ppo";

import PfSettlementRh from "./Pages/RhPages/PfSettlementRh";

import UpdFamMemRoda from "./Pages/RoDaPages/UpdFamMemRoda";
import ViewUpdFamMemRoda from "./Pages/RoDaPages/ViewUpdFamMemRoda";
//************************** SI **************************
import SiDbLayout from "./Layouts/SiDbLayout";

import AdvSettlementSi from "./Pages/SiPages/AdvSettlementSi";
import ViewAdvClaimSi from "./Pages/SiPages/ViewAdvClaimSi";

import PfSettlementSi from "./Pages/SiPages/PfSettlementSi";
import ViewPfClaimSi from "./Pages/SiPages/ViewPfClaimSi";

//************************** BO **************************
import BoDbLayout from "./Layouts/BoDbLayout";

import AdvSettlementBo from "./Pages/BoPages/AdvSettlementBo";
import ViewAdvClaimBo from "./Pages/BoPages/ViewAdvClaimBo";

import PfSettlementBo from "./Pages/BoPages/PfSettlementBo";
import ViewPfClaimBo from "./Pages/BoPages/ViewPfClaimBo";
import PfPo from "./Pages/DpoAndPoPages/PfPo";

//************************** RH **************************
import RhDbLayout from "./Layouts/RhDbLayout";

import AdvSettlementRh from "./Pages/RhPages/AdvSettlementRh";
import ViewAdvClaimRh from "./Pages/RhPages/ViewAdvClaimRh";
import RevokeAdvClaimRh from "./Pages/RhPages/RevokeAdvClaimRh";
import AdvPo from "./Pages/DpoAndPoPages/AdvPo";

//import PfSettlementRh from "./Pages/RhPages/PfSettlementRh";
import ViewPfClaimRh from "./Pages/RhPages/ViewPfClaimRh";
import RevokePfClaimRh from "./Pages/RhPages/RevokePfClaimRh";

import SampleTable from "./ledger/Ledgertable";
import ViewLedger from "./ledger/ViewLedger";
import ViewLedgerAllOff from "./ledger/ViewLedgerWithoutUpdate";
import CustomForm from "./ledger/AdvanceForm";

import ViewUpdFamMemRh from "./Pages/RhPages/ViewUpdFamMemRh";
import UpdFamMemRh from "./Pages/RhPages/UpdFamMemRh";

//************************** Cashier **************************
import CashierDbLayout from "./Layouts/CashierDbLayout";

import AdvSettlementCr from "./Pages/CrPages/AdvSettlementCr";
import ViewAdvClaimCr from "./Pages/CrPages/ViewAdvClaimCr";

import PfSettlementCr from "./Pages/CrPages/PfSettlementCr";
import ViewPfClaimCr from "./Pages/CrPages/ViewPfClaimCr";
import PfFinallSettlement from "./Pages/CrPages/PfFinallSettlement";
import AdvFinallSettlement from "./Pages/CrPages/AdvFinallSettlement";

//******************************** */
import Testingg from "./Pages/Testingg";
import PfSettlementUda from "./Pages/UDAPages/PfSettlementUda";
import ViewPenClaimRoda from "./Pages/RoDaPages/ViewPenClaimRoda";
import ViewPensionClaimIds from "./Pages/IdsPages/ViewPensionClaimIds";
import PensionIds from "./Pages/IdsPages/PensionIds";

/* ******************************GRIVANCE************************** */
import GrievanceTable from "./Pages/GrievancePages/GrievanceTable";
import GrievaceViewMember from "./Pages/GrievancePages/GrievanceViewMember";
import GrievanceEditMember from "./Pages/GrievancePages/GrievanceEditMember";
import PrintableGrievanceForm from "./Pages/GrievancePages/PrintButtonTwo";
import RevokeGriv from "./Pages/GrievancePages/RevokeGriv";
import RaiseNewGrievance from "./Pages/GrievancePages/RaiseNewGrievance";
import GchDbLayout from "./Layouts/GchDbLayout";
import GcmDbLayout from "./Layouts/GcmDbLayout";
import RogoDbLayout from "./Layouts/RogoDbLayout";
import CcgoDbLayout from "./Layouts/CcgoDbLayout";
import AllGrievances from "./GrievanceList/GrievancePages/GCMAllGrievances";
import GoAllGrievances from "./GrievanceList/GrievancePages/GoAllGrievancves";
import GrievanceByIdGo from "./GrievanceList/GrievancePages/GrievanceByIdGo";
import GrievanceById from "./GrievanceList/GrievancePages/GrievanceById";
import EditAdvancePF from "./Pages/UDAPages/EditAdvancePF";
/* ****************************** Add new family member************************** */
// import AddFamilyMemberUda from "./Pages/UDAPages/AddFamilyMemberUda";
import ViewUpdFamMemSi from "./Pages/SiPages/ViewUpdFamMemSi";
import UpdFamMemSi from "./Pages/SiPages/UpdFamMemSi";

// import ViewMemFamDtlsBySearch from "./Pages/AddFamilyMemberPages/ViewMemFamDtlsBySearch" stat
//import StatsRo from "./Pages/Stats/StatsRo";
//*******************************TRACKING************************************ */
import CurrentStatusGreivance from "./Pages/Tracking/CurrentStatusGreivance";
import PfStatusTracking from "./Pages/Tracking/PfStatusTracking";
import PensionStatusTracking from "./Pages/Tracking/PensionStatusTracking";
import AdvanceStatusTracking from "./Pages/Tracking/AdvanceStatusTracking";
import TrackTable from "./Pages/Tracking/TrackTable";
import TrackTableGrivence from "./Pages/Tracking/TrackTableGrivence";
import ErrorPage from "./UiManager/ErrorPage";
// import DashboardOfficer from "./Pages/Dashboard/DashboardOfficer";
// import GmfDbLayout from "./Layouts/GmfDbLayout";
// import AdvSettlementGmf from "./Pages/GmfPages/AdvSettlementGmf";
// import ViewAdvClaimGmf from "./Pages/GmfPages/ViewAdvClaimGmf";
//******************************************************************* */
//************************** Commissioner **************************
import CommissionerDbLayout from "./Layouts/CommissionerDbLayout";
import DashboardOfficer from "./Pages/Dashboard/DashboardOfficer";
//******************************************************************* */

const theme = createTheme({
  palette: {
    primary: {
      // main: "#ff3729",
      main: "#de0101",
    },
    secondary: {
      main: "#fff",
    },
  },
  // typography: {
  //   body3: {
  //     // Define the properties for the body3 variant
  //     fontFamily: "Times New Roman, Times, serif",
  //     fontWeight: 600,
  //     fontSize: "1rem",
  //   },
  // },
});

export default function App() {
  const userContext = useContext(UserContext);

  const [isLoginButtonDisabledGlobal, setIsLoginButtonDisabledGlobal] =
    useState(true);

  const isAuthenticated = () => false; //this should set using context where context set from redirect page

  const loggoutServer = () => {
    //logout axios
    let token = localStorage.getItem("jwt");
    if (!token) {
      return;
    }
    token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + "/member/logout";

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios.post(url, null, config).then((resp) => {
      console.log(resp.data.data);
    });
  };

  const location = useLocation();
  const navigate = useNavigate();

  const logoutUser = () => {
    console.log("logout user on page refresh");
    loggoutServer();
    localStorage.clear();
    sessionStorage.clear();
    navigate("/cmpfo", { replace: true });
  };

  // uncomment this for logging again after loggin comment it

  useEffect(() => {
    localStorage.clear();
    sessionStorage.removeItem("loginId");
  }, []);

  useEffect(() => {
    if (window.self !== window.top) {
      // If the application is loaded within an iframe
      window.top.location.href = window.self.location.href;
    }
  }, []);

  // uncomment it remove ccrep location path if ccrep not having token

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (
        location.pathname.includes("/member") &&
        location.pathname.includes("/ccrep") &&
        !location.pathname.includes("/login/member") &&
        !location.pathname.includes("/enrol/member")
      ) {
        event.preventDefault();

        // Customize the alert message
        const confirmationMessage = "Are you sure you want to leave this page?";
        event.returnValue = confirmationMessage; // For Chrome
        return confirmationMessage; // For other browsers
      }
    };

    const handleUnload = () => {
      // Logout the user here
      logoutUser();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleBeforeUnload);

    return () => {
      window.removeEventListener("unload", handleUnload);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location]);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CaptchaProvider
          children={
            <>
              <CssBaseline />
              <Routes>
                <Route exact path="/" element={<LandingLayout />}>
                  <Route index element={<Home />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route
                    path="/termsandcondition"
                    element={<TermsAndCondition />}
                  />
                  <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                  <Route
                    path="/forgotpassword/update"
                    element={<ForgotPassword />}
                  />
                  <Route
                    path="forgotpassword"
                    element={<ForgotPasswordOtp />}
                  />
                  {/* delete this routes when you create a single page for Forgot password */}
                  <Route
                    path="/forgotpasswordofcr/update"
                    element={<ForgotPasswordOfficer />}
                  />
                  <Route
                    path="forgotpasswordofcr"
                    element={<ForgotPasswordOtpOfficer />}
                  />
                  <Route
                    path="forgotpassword/otp"
                    element={<OTPVerification />}
                  />
                  <Route
                    path="/login/member/otp"
                    element={
                      <OTPVerification
                        setIsLoginButtonDisabledGlobal={
                          setIsLoginButtonDisabledGlobal
                        }
                      />
                    }
                  />
                  <Route
                    path="/login/officer/otp"
                    element={
                      <OTPVerification
                        setIsLoginButtonDisabledGlobal={
                          setIsLoginButtonDisabledGlobal
                        }
                      />
                    }
                  />
                  {/* enrol routes */}
                  <Route path="/enrol">
                    <Route
                      path="member"
                      element={
                        <Member
                          setIsLoginButtonDisabledGlobal={
                            setIsLoginButtonDisabledGlobal
                          }
                        />
                      }
                    />
                    <Route
                      path="officer"
                      element={
                        <OfficerEnrolNew
                          setIsLoginButtonDisabledGlobal={
                            setIsLoginButtonDisabledGlobal
                          }
                        />
                      }
                    />
                    <Route
                      path="officer/otp"
                      element={
                        <OTPVerification
                          setIsLoginButtonDisabledGlobal={
                            setIsLoginButtonDisabledGlobal
                          }
                        />
                      }
                    />
                  </Route>
                </Route>
                {/* userManual  route is here*/}
                <Route path="/manual" element={<UserGuide />} />
                <Route path="/error" element={<ErrorPage />} />

                {/* login */}
                <Route path="/cmpfo" element={<OfficialWeb />} />
                {/* <Route path="/dashboard" element={<Dashboard />} /> */}
                <Route path="/redirector" element={<Redirector />} />
                <Route path="/login/member" element={<MemberLogin />} />
                <Route path="/login/officer" element={<OfficerLogin />} />
                {/* <Route path="/login/officer" element={<UnderDev />} /> */}
                <Route path="/accessdenied" element={<AccessDenied />} />
                {/* member dshboard routing  */}
                <Route path="/member" element={<MemberDbLayout />}>
                  <Route index element={<AccountSummary />} />
                  <Route path="pf" element={<PfStatementView />} />
                  <Route path="pension" element={<PensionStatementView />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="newfam" element={<FamilyForm />} />
                  <Route path="track">
                    <Route index element={<TrackTable />} />
                    <Route
                      path="pensionStatusTracking"
                      element={<PensionStatusTracking />}
                    />
                    <Route
                      path="pfStatusTracking"
                      element={<PfStatusTracking />}
                    />
                    <Route
                      path="advanceStatusTracking"
                      element={<AdvanceStatusTracking />}
                    />
                  </Route>
                  <Route
                    path="currentStatusGreivance"
                    element={<CurrentStatusGreivance />}
                  />
                  <Route
                    path="pensionStatusTracking"
                    element={<PensionStatusTracking />}
                  />
                  <Route path="trackTableGriv">
                    <Route index element={<TrackTableGrivence />} />
                    <Route
                      path="currentStatusGreivance"
                      element={<CurrentStatusGreivance />}
                    />
                  </Route>
                  {/* <Route path="newfam" element={<UnderDev />} /> */}

                  {/* change password */}
                  <Route path="changepassword">
                    <Route index element={<ChangePassword />} />
                    <Route path="otp" element={<OTPVerification />} />
                  </Route>

                  {/* change password */}
                  <Route path="changepassword">
                    <Route index element={<ChangePassword />} />
                    <Route path="otp" element={<OTPVerification />} />
                  </Route>

                  <Route path="grievances">
                    <Route index element={<GrievanceTable />} />
                    <Route
                      path="raisenewgrievance"
                      element={<RaiseNewGrievance />}
                    />
                    <Route
                      path="grievancedetails"
                      element={<GrievaceViewMember />}
                    />
                    <Route path="edit" element={<GrievanceEditMember />} />
                    <Route path="print" element={<PrintableGrievanceForm />} />
                    <Route path="revoke" element={<RevokeGriv />} />
                  </Route>

                  <Route path="famlist">
                    {/* edit,view,delete */}
                    {/* <Route index element={<FamilyListView />} />
                    <Route path="info" element={<FamilyForm />} />
                    <Route path="edit" element={<FamilyForm />} />
                    <Route path="delete" element={<FamilyListView />} /> */}
                    <Route index element={<UnderDev />} />
                    <Route path="info" element={<UnderDev />} />
                    <Route path="edit" element={<UnderDev />} />
                    <Route path="delete" element={<UnderDev />} />
                  </Route>
                </Route>
                {/* no dashboard  */}
                <Route path="nodalofficer" element={<NODbLayout />}>
                  <Route index element={<ROEnrolment />} />
                </Route>
                {/* cc rep dashboard */}
                {/* <Route path='/ccrep' element={<CcRepDbLayout />}>
                 <Route index element={<CCEnrolment/>}/>
                 <Route path='consent' element={<ViewConsent/>}
                  />
                  <Route path="changepassword">
                    <Route index element={<ChangePassword />} />
                    <Route path="otp" element={<OTPVerification />} />
                  </Route>
                  />

              // </Route> */}
                <Route path="/ccrep" element={<CcRepDbLayout />}>
                  <Route index element={<ViewConsent />} />
                  <Route path="ccenrol" element={<CCEnrolment />} />
                  <Route path="changepassword">
                    <Route index element={<ChangePasswordCCRep />} />
                    <Route path="otp" element={<OTPVerification />} />
                  </Route>
                </Route>
                {/* Dashboard for CCH and RH */}
                <Route path="/heads" element={<HeadsDbLayout />}>
                  <Route index element={<AssignRoles />} />

                  <Route path="changepassword">
                    <Route index element={<ChangePasswordCCRep />} />
                    <Route path="otp" element={<OTPVerification />} />
                  </Route>
                </Route>
                {/* Dashboard for UDA */}
                <Route path="/uda" element={<UdaDbLayout />}>
                  <Route index element={<SearchMember />} />
                  <Route path="view">
                    <Route index element={<MemberDetailsSearch />} />
                    <Route path="view" element={<FamilyMemberDetails />} />
                  </Route>
                  {/* <Route path="deshbord">
                    <Route index element={<OtherProjectRoutes />} />
                  </Route> */}
                  {/* <Route path="updatemember">
                    <Route index element={<SearchMember />} />
                 
                    <Route path="edit" element={<UpdateMemberDetails />} />
                   
                    <Route path="view">
                      <Route index element={<MemberDetailsSearch />} />
                      <Route path="view" element={<FamilyMemberDetails />} />
                      <Route
                        path="edit"
                        element={<UpdateFamilyMemberDetails />}
                      />
                    
                    </Route>
                  </Route> */}
                  <Route path="updatemember">
                    <Route index element={<UpdateMemberDetailsUda />} />

                    <Route
                      path="proceedmemupdrq"
                      element={<ProceedUpdMemDetails />}
                    />
                    <Route path="searchmem">
                      {/* <Route path="searchmem" element={<SearchMember />} /> */}
                      <Route index element={<SearchMember />} />

                      <Route path="edit" element={<UpdateMemberDetails />} />

                      <Route path="view">
                        <Route index element={<MemberDetailsSearch />} />
                        <Route path="view" element={<FamilyMemberDetails />} />
                        <Route
                          path="edit"
                          element={<UpdateFamilyMemberDetails />}
                        />
                      </Route>
                    </Route>
                  </Route>

                  {/* ************************** update family member *********************** */}
                  <Route path="updatefamilymember">
                    <Route index element={<UpdatedFamilyMemberUda />} />

                    <Route path="proceedfammemupdrq">
                      <Route index element={<ProceedUpdFamMemDetails />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>

                    {/* <Route
                      path="editAddNewFamilyMemberByBaiju"
                      element={<EditAddNewFamilyMember />}
                    /> */}
                    <Route path="searchmem">
                      {/* <Route path="searchmem" element={<SearchMember />} /> */}
                      <Route index element={<SearchMember />} />

                      <Route path="view">
                        <Route index element={<FamMemDetSer />} />
                        <Route path="view" element={<FamilyMemberDetails />} />
                        <Route
                          path="edit"
                          element={<UpdateFamilyMemberDetails />}
                        />
                        <Route path="delete" element={<DeleteFamilyMember />} />
                      </Route>
                    </Route>

                    {/* <Route path="newupdfam">
                      <Route index element={<NewUpdFam />} />

                      <Route path="view" element={<FamilyMemberDetails />} />
                      <Route
                        path="edit"
                        element={<UpdateFamilyMemberDetails />}
                      />
                      <Route path="delete" element={<DeleteFamilyMember />} />
                     

                  
                    </Route> */}
                  </Route>
                  <Route path="SearchMember">
                    <Route index element={<SearchMember />} />
                    {/* <Route path="edit"> */}
                    {/* <Route path="edit" element={<UpdateMemberDetails />} /> */}
                    {/* </Route> */}
                    <Route path="view">
                      <Route index element={<MemberDetailsSearch />} />
                      <Route path="view" element={<FamilyMemberDetails />} />
                      {/* <Route
                        path="edit"
                        element={<UpdateFamilyMemberDetails />}
                      /> */}
                    </Route>
                  </Route>
                  <Route path="addnewfammem">
                    <Route index element={<AddFamilyMemberUda />} />

                    <Route
                      path="proceedfammemupdrq"
                      // path="proceedfammemupdrq"
                      // element={<ProceedAddFamMemDetails />}
                    >
                      <Route index element={<ProceedAddFamMemDetails />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>

                    {/* <Route
                      path="editreturnedaddfammem"
                      element={<EditReturnedAddFamMem />}
                    /> */}

                    {/* <Route
                      path="editAddNewFamilyMemberByBaiju"
                      element={<EditAddNewFamilyMember />}
                    /> */}

                    <Route path="newaddfam">
                      <Route index element={<NewAddFam />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                      <Route path="addfammem" element={<AddFamMemForm />} />
                    </Route>
                  </Route>

                  <Route path="pfset">
                    <Route index element={<PfSettlementUda type="Pf" />} />
                    {/* <Route path="newpfclaim" element={<NewPfClaim />} /> */}
                    <Route path="newpfclaim">
                      <Route index element={<NewPfClaim />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>

                    <Route
                      path="viewpfclaimuda"
                      element={<ProceedPfClaimAao />}
                    />
                    <Route
                      path="returnclaimuda"
                      element={<EditReturnedClaimUda />}
                    />
                  </Route>
                  <Route path="penset">
                    <Route index element={<PfSettlementUda type="PN" />} />
                    {/* <Route path="newpfclaim" element={<NewPfClaim />} /> */}
                    <Route path="newpfclaim">
                      <Route index element={<NewPfClaim />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>

                    <Route
                      path="viewpfclaimuda"
                      element={<ProceedPfClaimAao />}
                    />
                    <Route path="returnclaimuda" element={<EditPen />} />
                  </Route>
                  <Route path="pfpenset">
                    <Route index element={<UnderDev />} />

                    {/* <Route path="viewpfclaimaao" element={<ViewPfClaimAao />} />
                    <Route
                      path="proceedpfclaimaao"
                      element={<ProceedPfClaimAao />}
                    /> */}
                  </Route>
                  <Route path="advset">
                    <Route index element={<AdvSettlementUda type="AD" />} />

                    <Route path="advpfclaimsub">
                      <Route index element={<NewPfAdvanceClaim />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                    <Route path="viewadvclaimuda">
                      <Route index element={<ProceedAdvClaimAao />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                    <Route
                      path="returnadvclaimuda"
                      element={<EditReturnedAdvClaim />}
                    />
                  </Route>
                  {/* <Route path="stat">
                    <Route index element={<StatsRo />} />
                  </Route> */}
                  {/* <Route path="stat" element={<StatsRo />} /> */}
                </Route>
                {/* remove this later */}
                <Route path="ccenrol" element={<CCEnrolment />} />
                <Route path="memform" element={<MemberForm />} />
                <Route path="roenrol" element={<ROEnrolment />} />
                <Route path="claimdetails" element={<ViewClaimDetails />} />
                <Route path="pfset" element={<PfSettlement />} />
                <Route path="pfclaimsub" element={<NewPfClaim />} />
                <Route path="viewcons" element={<ViewConsent />} />
                <Route path="memdetails" element={<MemberDetails />} />
                <Route path="claimform" element={<ClaimForm />} />
                <Route path="claimstable" element={<ClaimsTable />} />
                <Route path="claimd" element={<ClaimDetails />} />
                <Route path="viewdocuments" element={<ViewDocuments />} />
                <Route path="viewdoc" element={<TestDoc />} />
                <Route path="advpfclaimsub" element={<NewPfAdvanceClaim />} />
                <Route path="uploaddocuments" element={<UploadDocument />} />
                <Route path="abc" element={<Abc />} />
                <Route path="additionaladvpf" element={<AdditionalAdvPf />} />
                <Route path="advpo" element={<AdvPo />} />

                {/* ************************* Dashboard for UAO ************************** */}
                <Route path="/uao" element={<UaoDbLayout />}>
                  {/* <Route path="SearchMember"> */}
                  <Route index element={<SearchMember />} />
                  {/* <Route path="edit"> */}
                  {/* <Route path="edit" element={<UpdateMemberDetails />} /> */}
                  {/* </Route> */}
                  <Route path="view">
                    <Route index element={<MemberDetailsSearch />} />
                    <Route path="view" element={<FamilyMemberDetails />} />
                    {/* <Route
                        path="edit"
                        element={<UpdateFamilyMemberDetails />}
                      /> */}
                  </Route>
                  {/* </Route> */}
                  <Route path="updmemreq">
                    <Route index element={<UpdMemUao />} />

                    <Route path="viewupdmemuao" element={<ViewUpdMemUao />} />
                    <Route
                      path="proceedmemupdrq"
                      element={<ProceedUpdMemDetails />}
                    />
                  </Route>

                  <Route path="updfammemreq">
                    <Route index element={<UpdFamMemUao />} />

                    <Route path="viewupdfammemuao">
                      <Route index element={<ViewUpdFamMemUao />} />

                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                    <Route path="proceedfammemupdrq">
                      <Route index element={<ProceedUpdFamMemDetails />} />

                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                  </Route>

                  <Route path="addfammemuao">
                    <Route index element={<AddFamMemUao />} />

                    <Route path="viewaddfammemuao">
                      <Route index element={<ViewAddFamMemUao />} />

                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                    <Route path="proceedaddfammemdtls">
                      <Route index element={<ProceedAddFamMemDetails />} />

                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                  </Route>

                  <Route path="addfammemaao">
                    <Route index element={<AddFamMemAao />} />

                    <Route
                      path="viewaddfammemaao"
                      element={<ViewAddFamMemAao />}
                    />

                    <Route path="proceedaddfammemdtls">
                      <Route index element={<ProceedAddFamMemDetails />} />

                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                  </Route>

                  <Route path="pfset">
                    <Route index element={<PfSettlementUao type="PF" />} />

                    <Route
                      path="proceedpfclaim"
                      element={<ProceedPfClaimAao />}
                    />
                    <Route path="viewpfclaimuao">
                      <Route index element={<ViewPfClaimUao />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                  </Route>
                  <Route path="penset">
                    <Route index element={<PfSettlementUao type="PF" />} />

                    <Route path="viewpfclaimuao">
                      <Route index element={<ViewPfClaimUao />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                    <Route
                      path="proceedpfclaim"
                      element={<ProceedPfClaimAao />}
                    />
                  </Route>
                  <Route path="pfpenset">
                    <Route index element={<UnderDev />} />

                    {/* <Route path="viewpfclaimaao" element={<ViewPfClaimAao />} />
                    <Route
                      path="proceedpfclaimaao"
                      element={<ProceedPfClaimAao />}
                    /> */}
                  </Route>
                  <Route path="advset">
                    <Route index element={<AdvSettlementUao type="AD" />} />
                    <Route path="viewadvclaimuao">
                      <Route index element={<ViewAdvClaimUao />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                    <Route path="proceedadvclaim">
                      <Route index element={<ProceedAdvClaimAao />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                  </Route>
                </Route>
                <Route path="gch" element={<GchDbLayout />}>
                  <Route index element={<AllGrievances />} />
                  <Route path="grievancebyid" element={<GrievanceById />} />
                </Route>
                {/* <Route path="grievancebyid" element={<GrievanceById />} />
                <Route path="allgrievances" element={<AllGrievances  />} />
                <Route path="gch" element={<GchDbLayout />} /> */}
                <Route path="gcm" element={<GcmDbLayout />}>
                  <Route index element={<AllGrievances />} />
                  <Route path="grievancebyid" element={<GrievanceById />} />
                </Route>
                <Route path="rogo" element={<RogoDbLayout />}>
                  <Route index element={<GoAllGrievances />} />
                  <Route path="grievancebyid" element={<GrievanceByIdGo />} />
                </Route>
                <Route path="ccgo" element={<CcgoDbLayout />}>
                  <Route index element={<GoAllGrievances />} />
                  <Route path="grievancebyid" element={<GrievanceByIdGo />} />
                </Route>

                {/* <Route path="gmf" element={<GmfDbLayout />}>
                  <Route index element={<AdvSettlementGmf />} />
                  <Route path="viewadvclaim" element={<ViewAdvClaimGmf />} />
                </Route> */}
                {/* ************************* Dashboard for AAO ************************** */}
                <Route path="/aao" element={<AaoDbLayout />}>
                  <Route index element={<SearchMember />} />

                  <Route path="view">
                    <Route index element={<MemberDetailsSearch />} />
                    <Route path="view" element={<FamilyMemberDetails />} />
                  </Route>

                  <Route path="updmemreq">
                    <Route index element={<UpdMemAao />} />

                    <Route path="viewupdmemaao" element={<ViewUpdMemAao />} />
                    <Route
                      path="proceedmemupdrq"
                      element={<ProceedUpdMemDetails />}
                    />
                  </Route>
                  <Route path="updfammemreq">
                    <Route index element={<UpdFamMemAao />} />

                    <Route path="viewupdfammemaao">
                      <Route index element={<ViewUpdFamMemAao />} />

                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>

                    <Route path="proceedfammemupdrq">
                      <Route index element={<ProceedUpdFamMemDetails />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                  </Route>

                  <Route path="addfammemaao">
                    <Route index element={<AddFamMemAao />} />

                    <Route path="viewaddfammemaao">
                      <Route index element={<ViewAddFamMemAao />} />

                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                    <Route path="proceedaddfammemdtls">
                      <Route index element={<ProceedAddFamMemDetails />} />

                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                  </Route>

                  <Route path="pfset">
                    <Route index element={<PfSettlementAao type="PF" />} />

                    <Route path="viewpfclaimaao">
                      <Route index element={<ViewPfClaimAao />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                    <Route
                      path="proceedpfclaim"
                      element={<ProceedPfClaimAao />}
                    />
                    <Route path="recvdcnfrmaao" element={<RecivedForConfm />} />
                  </Route>
                  <Route path="penset">
                    <Route index element={<PfSettlementAao type="PN" />} />

                    <Route path="viewpfclaimaao" element={<ViewPfClaimAao />} />
                    <Route
                      path="proceedpfclaim"
                      element={<ProceedPfClaimAao />}
                    />
                    <Route path="recvdcnfrmaao" element={<RecivedForConfm />} />
                  </Route>
                  <Route path="pfpenset">
                    <Route index element={<UnderDev />} />

                    {/*
                    <Route path="viewpfclaimaao" element={<ViewPfClaimAao />} />
                    <Route
                      path="proceedpfclaimaao"
                      element={<ProceedPfClaimAao />}
                    /> */}
                  </Route>
                  <Route path="advset">
                    <Route index element={<AdvSettlementAao type="AD" />} />

                    <Route path="viewadvclaimaao">
                      <Route index element={<ViewAdvClaimAao />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>

                    <Route path="recvdcnfrmaao">
                      <Route index element={<RecivedForConfmAdv />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                    <Route path="proceedadvclaim">
                      <Route index element={<ProceedAdvClaimAao />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                  </Route>
                </Route>
                {/* ************************* Dashboard for AGM ************************** */}
                <Route path="/agm" element={<AgmDbLayout />}>
                  <Route index element={<AssignRoles />} />

                  <Route path="pfset">
                    <Route index element={<PfSettlementAgm type="PF" />} />

                    <Route path="viewpfclaimagm" element={<ViewPfClaimAgm />} />

                    <Route
                      path="proceedpfclaim"
                      element={<ProceedPfClaimAao />}
                    />
                  </Route>
                  <Route path="penset">
                    <Route index element={<PfSettlementAgm type="PN" />} />

                    <Route path="viewpfclaimagm" element={<ViewPfClaimAgm />} />
                    <Route
                      path="proceedpfclaim"
                      element={<ProceedPfClaimAao />}
                    />
                  </Route>
                  <Route path="pfpenset">
                    {/* <Route index element={<PfSettlementAao type="PP" />} /> */}
                    <Route index element={<UnderDev />} />

                    {/* <Route path="viewpfclaimaao" element={<ViewPfClaimAao />} />
                    <Route
                      path="proceedpfclaimaao"
                      element={<ProceedPfClaimAao />}
                    /> */}
                  </Route>
                  <Route path="advset">
                    <Route index element={<AdvSettlementAgm type="AD" />} />

                    <Route path="viewadvclaimagm">
                      <Route index element={<ViewAdvClaimAgm />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                    <Route path="proceedadvclaim">
                      <Route index element={<ProceedAdvClaimAao />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                  </Route>
                  {/* ledger display without update functionality */}

                  <Route path="viewledgeroff">
                    <Route index element={<ViewLedgerAllOff />} />
                  </Route>
                </Route>
                {/* ************************* Dashboard for IDS ************************** */}
                {/* <Route path="advsetids" element={<AdvSettlementIds />} /> */}
                <Route path="/ids" element={<IdsDbLayout />}>
                  <Route index element={<SearchMember />} />

                  <Route path="view">
                    <Route index element={<MemberDetailsSearch />} />
                    <Route path="view" element={<FamilyMemberDetails />} />
                  </Route>

                  <Route path="pfset">
                    <Route index element={<PfSettlementIds type="PF" />} />

                    <Route path="viewpfclaimids" element={<ViewPfClaimIds />} />
                    <Route
                      path="proceedpfclaim"
                      element={<ProceedPfClaimAao />}
                    />
                  </Route>
                  <Route path="penset">
                    <Route index element={<PensionIds type="PN" />} />

                    <Route
                      path="viewpenclaimids"
                      element={<ViewPensionClaimIds />}
                    />
                    <Route
                      path="proceedpenclaim"
                      element={<ProceedPfClaimAao />}
                    />
                  </Route>
                  <Route path="pfpenset">
                    <Route index element={<UnderDev />} />

                    {/* <Route path="viewpfclaimaao" element={<ViewPfClaimAao />} />
                    <Route
                      path="proceedpfclaimaao"
                      element={<ProceedPfClaimAao />}
                    /> */}
                  </Route>
                  <Route path="advset">
                    <Route index element={<AdvSettlementIds type="AD" />} />

                    <Route path="viewadvclaimids">
                      <Route index element={<ViewAdvClaimIds />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                    <Route path="proceedadvclaim">
                      <Route index element={<ProceedAdvClaimAao />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                  </Route>
                </Route>
                {/* ************************* Dashboard for RODA ************************** */}
                {/* <Route path="pdfgenerator" element={<PDFGenerator />} />
                <Route path="advsetroda" element={<AdvSettlementRoda />} />
                <Route path="viewadvclaimroda" element={<ViewAdvClaimRoda />} />
                <Route path="advdpo" element={<AdvDpo />} /> */}
                {/* ************************* Dashboard for RODA ************************** */}
                {/* <Route path="pdfgenerator" element={<PDFGenerator />} />
                <Route path="advsetroda" element={<AdvSettlementRoda />} />
                <Route path="viewadvclaimroda" element={<ViewAdvClaimRoda />} />
                <Route path="advdpo" element={<AdvDpo />} /> */}
                <Route path="/roda" element={<RodaDbLayout />}>
                  {/* <Route index element={<PfSettlementRoda type="PF" />} />
                  <Route path="SearchMember"> */}
                  <Route index element={<SearchMember />} />
                  {/* <Route path="edit"> */}
                  {/* <Route path="edit" element={<UpdateMemberDetails />} /> */}
                  {/* </Route> */}
                  <Route path="view">
                    <Route index element={<MemberDetailsSearch />} />
                    <Route path="view" element={<FamilyMemberDetails />} />
                    {/* <Route
                        path="edit"
                        element={<UpdateFamilyMemberDetails />}
                      /> */}

                    {/* </Route> */}
                  </Route>
                  <Route path="updfammemreq">
                    <Route index element={<UpdFamMemRoda />} />

                    <Route
                      path="viewupdfammemroda"
                      element={<ViewUpdFamMemRoda />}
                    />
                    <Route
                      path="proceedfammemupdrq"
                      element={<ProceedUpdFamMemDetails />}
                    />
                  </Route>

                  <Route path="pfset">
                    <Route index element={<PfSettlementRoda type="PF" />} />
                    <Route path="viewpfclaimroda">
                      <Route index element={<ViewPfClaimRoda />} />
                      <Route path="pfdpo" element={<PfDpo />} />
                    </Route>

                    <Route
                      path="proceedpfclaim"
                      element={<ProceedPfClaimAao />}
                    />
                  </Route>
                  <Route path="penset">
                    <Route index element={<PfSettlementRoda type="PN" />} />

                    <Route path="viewpenclaimroda">
                      <Route index element={<ViewPenClaimRoda />} />
                      <Route path="pendpo" element={<Pfo />} />
                    </Route>
                    <Route
                      path="proceedpfclaim"
                      element={<ProceedPfClaimAao />}
                    />
                  </Route>
                  <Route path="pfpenset">
                    <Route index element={<UnderDev />} />

                    {/* <Route path="viewpfclaimaao" element={<ViewPfClaimAao />} />
                    <Route
                      path="proceedpfclaimaao"
                      element={<ProceedPfClaimAao />}
                    /> */}
                  </Route>
                  <Route path="advset">
                    <Route index element={<AdvSettlementRoda type="AD" />} />
                    <Route path="viewadvclaimroda">
                      <Route index element={<ViewAdvClaimRoda />} />
                      <Route path="advdpo" element={<AdvDpo />} />
                      <Route path="advmarrigedpo" element={<AdvMarrigeDpo />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                    <Route path="proceedadvclaim">
                      <Route index element={<ProceedAdvClaimAao />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                  </Route>
                  <Route path="viewledger">
                    <Route index element={<ViewLedger />} />
                    <Route path="updateldgr">
                      <Route index element={<SampleTable />} />
                      <Route path="adv" element={<CustomForm />} />
                      {/* <Route path="updateldgr" element={<SampleTable />} /> */}
                    </Route>
                  </Route>
                </Route>

                {/* ************************* Dashboard for SI ************************** */}
                {/* <Route path="advsetsi" element={<AdvSettlementSi />} />
                <Route path="viewadvclaimsi" element={<ViewAdvClaimSi />} /> */}
                <Route path="/si" element={<SiDbLayout />}>
                  <Route index element={<SearchMember />} />

                  <Route path="view">
                    <Route index element={<MemberDetailsSearch />} />
                    <Route path="view" element={<FamilyMemberDetails />} />
                  </Route>
                  {/* <Route path="updmemreq">
                    <Route index element={<MemberUpdatedReqSi />} />

                    <Route
                      path="viewupdmemrequao"
                      element={<ViewMemberUpdateSi />}
                    />
                    <Route
                      path="proceedmemupdrq"
                      element={<ViewMemberUpdateSi />}
                    />
                  </Route> */}
                  <Route path="updfammemreq">
                    <Route index element={<UpdFamMemSi />} />

                    <Route
                      path="viewupdfammemsi"
                      element={<ViewUpdFamMemSi />}
                    />
                    <Route
                      path="proceedfammemupdrq"
                      element={<ProceedUpdFamMemDetails />}
                    />
                  </Route>

                  <Route path="pfset">
                    <Route index element={<PfSettlementSi type="PF" />} />

                    <Route path="viewpfclaimsi" element={<ViewPfClaimSi />} />
                    <Route
                      path="proceedpfclaim"
                      element={<ProceedPfClaimAao />}
                    />
                  </Route>
                  <Route path="penset">
                    <Route index element={<PfSettlementSi type="PN" />} />

                    <Route path="viewpfclaimsi" element={<ViewPfClaimSi />} />
                    <Route
                      path="proceedpfclaim"
                      element={<ProceedPfClaimAao />}
                    />
                  </Route>
                  <Route path="pfpenset">
                    <Route index element={<UnderDev />} />

                    {/* <Route path="viewpfclaimaao" element={<ViewPfClaimAao />} />
                    <Route
                      path="proceedpfclaimaao"
                      element={<ProceedPfClaimAao />}
                    /> */}
                  </Route>
                  <Route path="advset">
                    <Route index element={<AdvSettlementSi type="AD" />} />

                    <Route path="viewadvclaimsi">
                      <Route index element={<ViewAdvClaimSi />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                    <Route path="proceedadvclaim">
                      <Route index element={<ProceedAdvClaimAao />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                  </Route>
                  {/* ledger display without update functionality */}

                  <Route path="viewledgeroff">
                    <Route index element={<ViewLedgerAllOff />} />
                  </Route>
                </Route>
                {/* ************************* Dashboard for BO ************************** */}
                {/* <Route path="advsetbo" element={<AdvSettlementBo />} />
                <Route path="viewadvclaimbo" element={<ViewAdvClaimBo />} /> */}
                <Route path="/bo" element={<BoDbLayout />}>
                  {/* <Route index element={<PfSettlementBo type="PF" />} /> */}
                  <Route index element={<SearchMember />} />

                  <Route path="view">
                    <Route index element={<MemberDetailsSearch />} />
                    <Route path="view" element={<FamilyMemberDetails />} />
                  </Route>

                  <Route path="pfset">
                    <Route index element={<PfSettlementBo type="PF" />} />

                    <Route path="viewpfclaimbo" element={<ViewPfClaimBo />} />
                    <Route path="pfpo" element={<PfPo />} />
                    <Route
                      path="proceedpfclaim"
                      element={<ProceedPfClaimAao />}
                    />
                  </Route>
                  <Route path="penset">
                    <Route index element={<PfSettlementBo type="PN" />} />

                    <Route path="viewpfclaimbo" element={<ViewPfClaimBo />} />
                    <Route path="ppo" element={<Ppo />} />
                    <Route
                      path="proceedpfclaim"
                      element={<ProceedPfClaimAao />}
                    />
                  </Route>
                  <Route path="pfpenset">
                    <Route index element={<UnderDev />} />

                    {/* <Route index element={<PfSettlementAao type="PP" />} />

                    <Route path="viewpfclaimaao" element={<ViewPfClaimAao />} />
                    <Route
                      path="proceedpfclaimaao"
                      element={<ProceedPfClaimAao />}
                    /> */}
                  </Route>
                  <Route path="advset">
                    <Route index element={<AdvSettlementBo type="AD" />} />

                    <Route path="viewadvclaimbo">
                      <Route index element={<ViewAdvClaimBo />} />
                      <Route path="advpo" element={<AdvPo />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                    <Route path="proceedadvclaim">
                      <Route index element={<ProceedAdvClaimAao />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                  </Route>
                  {/* ledger display without update functionality */}

                  <Route path="viewledgeroff">
                    <Route index element={<ViewLedgerAllOff />} />
                  </Route>
                </Route>
                {/* ************************* Dashboard for RH ************************** */}
                {/* <Route path="advsetrh" element={<AdvSettlementRh />} />
                <Route path="viewadvclaimrh" element={<ViewAdvClaimRh />} />
                <Route path="revokeadvclaimrh" element={<RevokeAdvClaimRh />} /> */}
                <Route path="/rh" element={<RhDbLayout />}>
                  <Route path="dashboard">
                    <Route index element={<DashboardOfficer />} />
                  </Route>
                  <Route index element={<AssignRoles />} />

                  <Route path="SearchMember">
                    <Route index element={<SearchMember />} />
                    {/* <Route path="edit"> */}
                    {/* <Route path="edit" element={<UpdateMemberDetails />} /> */}
                    {/* </Route> */}
                    <Route path="view">
                      <Route index element={<MemberDetailsSearch />} />
                      <Route path="view" element={<FamilyMemberDetails />} />
                      {/* <Route
                        path="edit"
                        element={<UpdateFamilyMemberDetails />}
                      /> */}
                    </Route>
                  </Route>
                  {/* <Route path="updmemreq">
                    <Route index element={<MemberUpdatedReqRh />} />

                    <Route
                      path="viewupdmemrequao"
                      element={<ViewMemberUpdateRh />}
                    />
                    <Route
                      path="proceedmemupdrq"
                      element={<ViewMemberUpdateRh />}
                    />
                  </Route> */}
                  <Route path="updfammemreq">
                    <Route index element={<UpdFamMemRh />} />

                    <Route
                      path="viewupdfammemrh"
                      element={<ViewUpdFamMemRh />}
                    />
                    <Route
                      path="proceedfammemupdrq"
                      element={<ProceedUpdFamMemDetails />}
                    />
                  </Route>
                  <Route path="pfset">
                    <Route index element={<PfSettlementRh type="PF" />} />

                    <Route path="viewpfclaimrh" element={<ViewPfClaimRh />} />
                    <Route path="pfpo" element={<PfPo />} />
                    <Route
                      path="proceedpfclaim"
                      element={<ProceedPfClaimAao />}
                    />
                    <Route
                      path="revokepfclaimrh"
                      element={<RevokePfClaimRh />}
                    />
                  </Route>
                  <Route path="penset">
                    <Route index element={<PfSettlementRh type="PN" />} />

                    <Route path="viewpfclaimrh" element={<ViewPfClaimRh />} />
                    <Route path="ppo" element={<Ppo />} />

                    <Route
                      path="proceedpfclaim"
                      element={<ProceedPfClaimAao />}
                    />
                    <Route
                      path="revokepfclaimrh"
                      element={<RevokePfClaimRh />}
                    />
                  </Route>
                  <Route path="pfpenset">
                    <Route index element={<UnderDev />} />

                    {/* <Route index element={<PfSettlementAao type="PP" />} />

                    <Route path="viewpfclaimaao" element={<ViewPfClaimAao />} />
                    <Route
                      path="proceedpfclaimaao"
                      element={<ProceedPfClaimAao />}
                    /> */}
                  </Route>
                  <Route path="advset">
                    <Route index element={<AdvSettlementRh type="AD" />} />
                    <Route path="viewadvclaimrh">
                      <Route index element={<ViewAdvClaimRh />} />
                      <Route path="advpo" element={<AdvPo />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                    <Route path="revokeadvclaimrh">
                      <Route index element={<RevokeAdvClaimRh />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                    <Route path="proceedadvclaim">
                      <Route index element={<ProceedAdvClaimAao />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                  </Route>
                  {/* ledger display without update functionality */}

                  <Route path="viewledgeroff">
                    <Route index element={<ViewLedgerAllOff />} />
                  </Route>
                </Route>
                {/* ************************* Dashboard for Cashier ************************** */}
                {/* <Route path="advsetcr" element={<AdvSettlementCr />} />
                <Route path="viewadvclaimcr" element={<ViewAdvClaimCr />} /> */}
                <Route path="/cr" element={<CashierDbLayout />}>
                  <Route index element={<SearchMember />} />

                  <Route path="view">
                    <Route index element={<MemberDetailsSearch />} />
                    <Route path="view" element={<FamilyMemberDetails />} />
                  </Route>

                  <Route path="pfset">
                    <Route index element={<PfSettlementCr type="PF" />} />

                    <Route path="viewpfclaimcr" element={<ViewPfClaimCr />} />
                    <Route path="settlclim" element={<PfFinallSettlement />} />
                    <Route
                      path="proceedpfclaim"
                      element={<ProceedPfClaimAao />}
                    />
                  </Route>
                  <Route path="penset">
                    <Route index element={<PfSettlementCr type="PN" />} />

                    <Route path="viewpfclaimcr" element={<ViewPfClaimCr />} />
                    <Route path="settlclim" element={<PfFinallSettlement />} />
                    <Route
                      path="proceedpfclaim"
                      element={<ProceedPfClaimAao />}
                    />
                  </Route>
                  <Route path="pfpenset">
                    <Route index element={<UnderDev />} />

                    {/* <Route index element={<PfSettlementAao type="PP" />} />

                    <Route path="viewpfclaimaao" element={<ViewPfClaimAao />} />
                    <Route
                      path="proceedpfclaimaao"
                      element={<ProceedPfClaimAao />}
                    /> */}
                  </Route>
                  <Route path="advset">
                    <Route index element={<AdvSettlementCr type="AD" />} />

                    <Route path="viewadvclaimcr">
                      <Route index element={<ViewAdvClaimCr />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                    <Route path="settlclim">
                      <Route index element={<AdvFinallSettlement />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                    <Route path="proceedadvclaim">
                      <Route index element={<ProceedAdvClaimAao />} />
                      <Route
                        path="familydetails"
                        element={<FamilyMemberDetails />}
                      />
                    </Route>
                  </Route>
                  {/* ledger display without update functionality */}

                  <Route path="viewledgeroff">
                    <Route index element={<ViewLedgerAllOff />} />
                  </Route>
                </Route>
                {/* ************************* Dashboard for CM ************************** */}
                <Route path="/cm" element={<CommissionerDbLayout />}>
                  <Route index element={<DashboardOfficer />} />
                </Route>
                {/* ********************************************************************* */}
                {/* ******************************GRIVANCE************************** */}
                {/* <Route path="grievancetable">
                  <Route index element={<GrievanceTable />} />
                  <Route path="edit" element={<GrievanceEditMember />}></Route>

                  <Route
                    path="raisenewgrievance"
                    element={<RaiseNewGrievance />}
                  />

                  <Route
                    path="grievancedetails"
                    element={<GrievaceViewMember />}
                  />
                  <Route path="print" element={<PrintableGrievanceForm />} />
                  <Route path="revoke" element={<RevokeGriv />} />
                </Route> */}
                {/* <Route path="cdacuser" element={<StatsRo />} /> */}
                <Route path="editadvancepf" element={<EditAdvancePF />} />
                {/* ******************************************************** */}
                <Route path="testing" element={<Testingg />} />
                {/* page not found */}
                <Route path="*" element={<PageNotFound />} />
              </Routes>

              {/* <YearDropdown /> */}
            </>
          }
        />
      </ThemeProvider>
    </div>
  );
}
