import React from 'react';
import { Typography } from '@mui/material';
const SystemOverview=()=>{
    return(
      
        <div id="system-overview">
        <section>
          <Typography variant="h6">SYSTEM OVERVIEW</Typography>
          <Typography variant="body1" align='justify'>
           <p>
           The following functionalities have been implemented on the C-CARES Portal:
            <ol type="a">
  <li><b>Enrolment & Login</b>
  
  <ol type="i">
  
    <li>Enrolment of a Member on C-CARES Portal</li>
    <li>Login mechanism for members</li>
    <li>Login for CMPFO/CC officials</li>
    <li>Forgot Password for Officers/Members</li>
    <li> Change Password for Officers/Members</li>
    
  </ol>
  
  </li>

  <li><b>View PF Balances</b>
  <ol type="i">
    <li> View PF Statements of Member</li>
    <li> Submit Consent for Correctness/Incorrectness of data</li>
  </ol>
  </li>
  <li><b>View Submitted Consents</b>
  <ol type="i">
    <li>View the consents by CC Official</li>
    <li>Redirecting consents to respective CC</li>
  </ol>
  </li>
  <li><b>Raise Grievance</b>
  <ol type="i">
  <li>Raise online grievance by the member</li>
  </ol>
  </li>
  </ol>

       
       
        
        
           </p>
    
          </Typography>
        </section>
        </div> 
        
    );
}
export default SystemOverview;