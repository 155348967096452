import { validateSession } from "../session/EventManager";

const sessionExist = async () => {
  const status = await validateSession();
  console.log("session status", status);
  return status;
};

export function getToken() {
  if (!sessionExist()) {
    console.log("session extending having trouble");
    return "";
  }
  let token = localStorage.getItem("jwt");
  
  console.log("token in checker",token);

  if (!token) {
    console.log("token not found");
    return "";
  }
  return token;
}
