import React from 'react';
import Contact from './OverviewPages/Contact';
import Purpose from './OverviewPages/Purpose';
import SystemOverview from './OverviewPages/SystemOverview';
import { Typography } from "@mui/material";
function Overview() {
  return (
    <>
 
  <div id="an-overview-of-c-cares">
  <Typography variant="h5">OVERVIEW</Typography>
  <Typography variant="body1" align='justify'>
        <p>
        C-CARES Portal is envisioned to enable CMPFO and its regional offices, process and track the claims of the members w.r.t. Provident Fund, Pension and PF Advance and enable the employees to view their PF balances and the status of their claims. Additionally, it also has the details of the family members/nominees of the employee.
        </p>
         <p>
         One of the most significant uses of this platform is that it brings down the burden of manual claim settlement of the employee by CMPFO and digitally makes available all information pertaining to employees and their claims.
        </p>
        <p>
        C-CARES for CMPFO serves as a single access point for CMPFO and its regional offices as well as CIL Subsidiaries to access the employees’ database as well as offer the requisite claims-related services to employers and their respective employees. These services are accessible through APIs provided within the portal. The APIs interact with the Data Repositories configured in the portal to fetch the data and serve user’s request
          </p>
          <p>
          Additionally, the portal serves the purpose of bridging the gap between the employers and the employees as it offers transparency in the CMPFO processes by enabling the members to view their PF balances online. Additional feature of tracking the processing of the claim adds another level of transparency.
          </p>
          <p>
          This detailed User Manual is the technical communication document that will assist CMPFO and its regional officers and its employees with the operation of C-CARES Portal. The User Manual will be updated as and when new features/functionalities are added to the C-CARES Portal.
          </p>
       

      </Typography>

  </div>

<Purpose />
<SystemOverview />
<Contact />

 </>
   );
}
export default Overview;
