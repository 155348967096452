import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Paper, Button } from "@mui/material";

// import {
//   familyMemberModel,
//   familyMandatoryFields,
// } from "../../../Models/defaultModel";
import TitleText from "../../FormComponent/TitleText";
import LabelText from "../../FormComponent/LabelText";
import SubtitleText from "../../FormComponent/SubtitleText";
import FormDivider from "../../FormComponent/FormDivider";
import ValueText from "../../FormComponent/ValueText";
import axios from "axios";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";

function AddFamilyMemberDetails(props) {
  // const { member } = props;
  const member = props.memUpdReqdetails[0];
  console.log("member on ", props);

  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  const nominee = {
    true: "Yes",
    false: "No",
  };

  const maritalStatusOptions = {
    U: "Unmarried",
    M: "Married",
    W: "Widow/Widower",
    D: "Divorced",
    // Add more status options here
  };
  const gender = [
    { id: "M", title: "Male" },
    { id: "F", title: "Female" },
    { id: "O", title: "Other" },
  ];
  const maritalStatus = [
    { id: "M", title: "Married" },
    { id: "U", title: "Unmarried" },
    { id: "W", title: "Widow/Widower" },
    { id: "D", title: "Divorced" },
  ];

  return (
    <Grid container justifyContent="center" sx={{ wordWrap: "break-word" }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
          {/* {isFailed ? (
            <Reloader refreshHandler={fetchUserDetail} />
          ) : (
            <>
              {isLoading ? (
                <LoadingIndicator />
              ) : ( */}
          <>
            {/*Button with back functionality */}
            <Grid container spacing={2}></Grid>
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <TitleText title="New Family Member's Details" />
            </Grid>

            <FormDivider />

            {/* <Form> */}
            <Grid
              container
              justifyContent="space-between"
              alignItems="start"
              rowGap={3}>
              {/* section 1  */}
              <Grid item xs={12} sm={12}>
                <SubtitleText title="Basic Details" />
              </Grid>

              {/* column 1  */}

              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  alignItems="start"
                  justifyContent="space-between"
                  spacing={2}
                  columnGap={1}>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Title" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.titleName} />
                  </Grid>

                  {/* row  */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="First Name" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.firstName} />
                  </Grid>
                  {/*row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Middle Name" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.middleName} />
                  </Grid>

                  {/*row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Last Name" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.lastName} />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Name of Father/Husband" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.fathHusName} />
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Relation" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.relationName} />
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Gender" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={
                        gender.find((item) => item.id === member.gender)?.title
                      }
                    />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Marital Status" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={
                        maritalStatus.find(
                          (item) => item.id === member.maritalStatus
                        )?.title
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* column 2  */}

              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="start"
                  spacing={2}
                  columnGap={1}>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Religion" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.religionName} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Date of Birth" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {/* <ValueText title={member.dateOfBirth} /> */}
                    <ValueText
                      title={new Date(member.dateOfBirth).toLocaleDateString(
                        "en-GB",
                        options
                      )}
                    />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Email ID" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.emailId} />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Mobile Number" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={
                        member.mobileNo !== "0"
                          ? `XXXXXX${member.mobileNo?.toString().slice(-4)}`
                          : ""
                      }
                    />
                  </Grid>

                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Landline Number" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={
                        member.landNo !== "0"
                          ? `XXXXXX${member.landNo?.toString().slice(-4)}`
                          : ""
                      }
                    />
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Aadhaar Number" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.aadhaarNo} />
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Pan" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.panNo} />
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Address" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={`
                                    ${
                                      member.addressL1
                                        ? member.addressL1 + ","
                                        : ""
                                    }
                                    
                                    ${
                                      member.addressL2
                                        ? member.addressL2 + ","
                                        : ""
                                    }
                                    
                                    ${
                                      member.village ? member.village + "," : ""
                                    }
                                    
                                    ${member.city ? member.city + "," : ""}
                                    
                                    ${
                                      member.district
                                        ? member.district + ","
                                        : ""
                                    }
                                    
                                    ${member.state ? member.state + "," : ""}
                                    
                                    ${
                                      member.country ? member.country + "," : ""
                                    }
                                    
                                    ${member.pincode ? member.pincode : ""}
                                `}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* ************************************** */}
              {/* section 2  */}
              <Grid item xs={12} sm={12}>
                <SubtitleText title="Claim Eligibility Details" />
              </Grid>

              {/* column 1  */}

              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  alignItems="start"
                  justifyContent="space-between"
                  spacing={2}
                  columnGap={1}>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="PF nominee" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText
                      title={member.pfNominee ? nominee[member.pfNominee] : ""}
                    />
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="PF nominee %" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.pfNomShare} />
                  </Grid>
                </Grid>
              </Grid>

              {/* column 2  */}

              <Grid item xs={12} sm={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="start"
                  spacing={2}
                  columnGap={1}>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Pension nominee" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {/* <ValueText title={member.pensionNominee} /> */}
                    <ValueText
                      title={
                        member.pensionNominee
                          ? nominee[member.pensionNominee]
                          : ""
                      }
                    />
                  </Grid>
                  {/* row */}
                  <Grid item xs={12} sm={4}>
                    <LabelText title="Pension nominee %" />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: { xs: "none", sm: "initial" } }}>
                    :
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ValueText title={member.penNomShare} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
          {/* )}
            </>
          )} */}

          {/* <TableWithLinkAndButton preview={familyMemberCreatePreviewData()} data={familyMemberSamples} passingId='familyMemberId' actions={submitTableActions} viewLink="" /> */}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default AddFamilyMemberDetails;
