// PreviewPage.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Paper, Button, CircularProgress } from "@mui/material";
import LabelText from "../../../FormComponent/LabelText";

import ValueText from "../../../FormComponent/ValueText";
import FormDivider from "../../../FormComponent/FormDivider";
import SubtitleText from "../../../FormComponent/SubtitleText";
import ConfirmationDialogue from "../../../FormComponent/ConfirmationDialogue";
import CustomDropdown from "../../../FormComponent/CustomDropdown";
import axios from "axios";
import jwtDecode from "jwt-decode";
import SucessAlert from "../../../FormComponent/SuccessAlert";
import ErrorAlert from "../../../FormComponent/ErrorAlert";

const PreviewPageFamilyMember = (props) => {
  const { family, onOk, uploadedDocs, memberDet } = props;
  console.log("props", props);
  // console.log("familyy", memberDet);
  const navigate = useNavigate(); //inilitialize the navigate function
  const handleGoBack = () => {
    props.onBackPreview();
  };

  const defaultClaim = {
    remarks: "",
    assignTo: "",
  };

  const [showingDropdown, setShowingDropdown] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [assignToOption, setAssignToOption] = useState([]);
  const [claim, setClaim] = useState(defaultClaim);
  const [selectedOfficerName, setSelectedOfficerName] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const [loginId, setLoginId] = useState();

  ///********fetching and setting token********* */
  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (token) {
      token = token.replace('"', "").replace('"', "");
      let loginId = jwtDecode(token).user; // Assuming a default loginId
      setLoginId(loginId);
      console.log("loooooooooooooooooooooginId", loginId);
      // Add any further logic here based on the loginId
    } else {
      // Handle the case when token is not present
      // setIsFailed(true);
      // setIsLoading(false);
    }
  }, []);

  const assignTo = {
    name: "assignTo",
    placeholder: "Select Officer",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "",
    required: true,
    options: assignToOption,
  };

  //****************Dropdown******************* */
  const fetchAssignOfficer = async () => {
    try {
      //  if (!(await sessionExist())) {
      //    setIsFailed(true);
      //    setIsLoading(false);
      //    return;
      //  }

      // let token = localStorage.getItem("jwt");

      // if (!token) {
      //   setIsFailed(true);
      //   setIsLoading(false);
      //   return;
      // }

      // token = token.replace('"', "").replace('"', "");
      // const loginId = jwtDecode(token).user;

      const url =
        process.env.REACT_APP_SERVER_URL +
        "/dropdown/for/update/member/details/by/uda";

      // "/dropdown/for/adv/pf/claim/aao/to/ids";
      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      const options = data.map((option) => ({
        id: option.loginId,
        title: `${option.name} (${option.loginId})`,
      }));
      setAssignToOption(options); // Assuming setAssignToOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    // fetchAllClaimDetail();
    fetchAssignOfficer();
  }, [loginId]);

  //! cmpf account number is fetched on useEffect with a delay of 3sec to avoid multiple api hits
  const onChange = (e) => {
    if (e.target.name === "assignTo") {
      const selectedOption = assignToOption.find(
        (option) => option.id === e.target.value
      );
      if (selectedOption) {
        setSelectedOfficerName(selectedOption.title); // Set the selected officer's name in the state
        console.log("selctess", selectedOption.title);
      }
    }
    setClaim({ ...claim, [e.target.name]: e.target.value });
  };

  /// ******** on submit function*****************
  const objectTobeSent = {
    loginId: loginId,
    remarks: claim.remarks,
    toOffcrLoginId: claim.assignTo,
    familyMemId: family.familyMemId,
    titleId: family.titleId,
    // titleName: family.titleName,
    titleName: memberDet.titleName,
    firstName: family.firstName,
    middleName: family.middleName,
    lastName: family.lastName,
    fullName: family.fullName,
    fathHusName: family.fathHusName,
    relationId: family.relationId,
    relationName: family.relationName,
    gender: family.gender,
    maritalStatus: family.maritalStatus,
    religionId: family.religionId,
    // religionName: family.religionName,
    religionName: memberDet.religionName,
    dateOfBirth: family.dateOfBirth,
    dob: family.dob,
    addressL1: family.addressL1,
    addressL2: family.addressL2,
    mobileNo: family.mobileNo ? family.mobileNo : 0,
    landNo: family.landNo ? family.landNo : 0,
    emailId: family.emailId,
    state: family.state,
    district: family.district,
    pincode: 1 * family.pincode,
    panId: family.panId ? family.panId : 0,
    pfNominee: family.pfNominee,
    pensionNominee: family.pensionNominee,
    pfNomSharePerc: 1 * family.pfNomSharePerc,
    penNomSharePerc: 1 * family.penNomSharePerc,
    aadhaarId: family.aadhaarId,
    village: family.village,
    city: family.city,
    country: family.country,
    aadhaarNo: family.aadhaarNo,
    panNo: family.panNo,
  };

  objectTobeSent.dateOfBirth = objectTobeSent.dob;
  objectTobeSent.loginId = loginId;
  // if (family.pfNominee) {
  //   if (family.pfNominee === "Yes") {
  //     objectTobeSent.pfNominee = true;
  //   } else {
  //     objectTobeSent.pfNominee = false;
  //   }
  // }

  // if (family.pensionNominee) {
  //   if (family.pensionNominee === "Yes") {
  //     objectTobeSent.pensionNominee = true;
  //   } else {
  //     objectTobeSent.pensionNominee = false;
  //   }
  // }

  if (memberDet && memberDet.titleName) {
    objectTobeSent.titleName = memberDet.titleName;
  } else {
    objectTobeSent.titleName = family.titleName;
  }

  if (memberDet && memberDet.religionName) {
    objectTobeSent.religionName = memberDet.religionName;
  } else {
    objectTobeSent.religionName = family.religionName;
  }

  if (memberDet && memberDet.relationName) {
    objectTobeSent.relationName = memberDet.relationName;
  } else {
    objectTobeSent.relationName = family.relationName;
  }

  console.log("objectTobeSent", objectTobeSent);
  console.log("family", family);

  const handleSubmitOk = () => {
    if (!objectTobeSent.toOffcrLoginId) {
      // Show message in dialog: "Please select an officer to assign"
      // alert("Please select an officer to assign");
      setIsError(true);
      setErrorMessage("Please Select an Officer to Assign.");
      return;
    }
    setIsSubmitClicked(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("sangeeta will give party");
    setIsSubmitting(true);

    const url =
      process.env.REACT_APP_SERVER_URL + "/update/family/member/details/by/UDA";

    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, objectTobeSent, { headers })
      .then(function (response) {
        //setIsSubmitting(false);
        //setIsSuccess(true);
        postUploadedDoc();
       
        setIsSubmitting(false);
      })
      .catch((error) => {
        setIsSubmitting(false);
        setIsError(true);
        setErrorMessage("Sorry Could not Proceed!! Please try again later");
        console.log("error on submit", error);
      });
  };
  console.log("cmpfAccNo", family.cmpfAccNo);
  console.log("family", family);
  console.log("props", props);
  //uploadedDocs
  const postUploadedDoc = () => {
    const uploadDocData = {
      cmpfAcNo: family.cmpfAccNo,
      upldDocsMemDto1: uploadedDocs,
    };
    console.log("uploadDocData", uploadDocData);
    const url =
      process.env.REACT_APP_SERVER_URL + "/submit/fam/member/docs/by/uda";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, uploadDocData, { headers })
      .then(function (response) {
        console.log("Submit response", response);
        setIsSuccess(true);

        setSuccessMessage("Update Family Member Details Forwarded Successfully.");
      })
      .catch((error) => {
        setIsError(true);
        setErrorMessage("Error in submitting document. Please Try again");
        console.log("error on submit", error);
      });
  };

  const handleProcessOk = (e) => {
    setShowingDropdown(1);
  };

  const handleAssignToCancel = () => {
    setIsSubmitClicked(false);
  };

  const handleAssignToOk = (e) => {
    handleSubmit(e);
    setIsSubmitClicked(false);
  };

  const handleSuccess = () => {
    navigate("/uda");
  };

  const handleErrorAlert = () => {
    setIsError(false);
  };
  const gender = [
    { id: "M", title: "Male" },
    { id: "F", title: "Female" },
    { id: "O", title: "Other" },
  ];
  const maritalStatus = [
    { id: "M", title: "Married" },
    { id: "U", title: "Unmarried" },
    { id: "W", title: "Widow/Widower" },
    { id: "D", title: "Divorced" },
  ];


  return (
    <div>
      {/* <ConfirmationDialogue
        handleOk={handleConfirmationDialogOk}
        handleCancel={handleConfirmationDialogCancel}
        open={isConfirmationDialogOpen}
        title="Verify Submission"
        body="Are you sure, you want to update?"
        buttonName="OK"
      /> */}
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error!"
        body={errorMessage}
        buttonName="Ok"
      />
      <SucessAlert
        handleOk={handleSuccess}
        open={isSuccess}
        title="Success"
        body={successMessage}
        buttonName="OK"
      />
      <ConfirmationDialogue
        handleOk={handleAssignToOk}
        handleCancel={handleAssignToCancel}
        open={isSubmitClicked}
        title="Confirm Assignment"
        body={`Are you sure you want to forward Family Member Update request to ${selectedOfficerName}?`}
        buttonName="Confirm"
      />

      <Grid container justifyContent="center" sx={{ wordWrap: "break-word" }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            {/* {isFailed ? (
            <Reloader refreshHandler={fetchUserDetail} />
          ) : (
            <>
              {isLoading ? (
                <LoadingIndicator />
              ) : ( */}
            <>
              {/*Button with back functionality */}
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    align="left"
                    onClick={handleGoBack}
                    // sx={{ backgroundColor: "darkviolet" }}
                  >
                    Back
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: 2 }}>
                <SubtitleText title=" Preview Family Member Details" />
              </Grid>

              <FormDivider />

              {/* <Form> */}
              <Grid
                container
                justifyContent="space-between"
                alignItems="start"
                rowGap={3}
              >
                {/* section 1  */}
                <Grid item xs={12} sm={12}>
                  <SubtitleText title="Basic Details" />
                </Grid>

                {/* column 1  */}

                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                    columnGap={1}
                  >
                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Title" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      {/* <ValueText title={family.titleId} /> */}
                      <ValueText
                        title={
                          memberDet.titleName
                            ? memberDet.titleName
                            : family.titleName
                        }
                      />
                    </Grid>

                    {/* row  */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="First Name" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <ValueText title={family.firstName} />
                    </Grid>
                    {/*row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Middle Name" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <ValueText title={family.middleName} />
                    </Grid>

                    {/*row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Last Name" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <ValueText title={family.lastName} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Name of Father/Husband" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <ValueText title={family.fathHusName} />
                    </Grid>
                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Relation" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {/* <ValueText title={family.relationName} /> */}
                      <ValueText
                        title={
                          memberDet.relationName
                            ? memberDet.relationName
                            : family.relationName
                        }
                      />
                    </Grid>
                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Gender" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                    <ValueText
                    title={
                      gender.find((item) => item.id === family.gender)?.title
                    }
                  />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Marital Status" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                    <ValueText
                    title={
                      maritalStatus.find(
                        (item) => item.id === family.maritalStatus
                      )?.title
                    }
                  />
                    </Grid>
                  </Grid>
                </Grid>

                {/* column 2  */}

                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    columnGap={1}
                  >
                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Religion" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      {/* <ValueText title={family.religionName} /> */}
                      <ValueText
                        title={
                          memberDet.religionName
                            ? memberDet.religionName
                            : family.religionName
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Date of Birth" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <ValueText title={family.dob} />
                      {/* <ValueText
                        title={new Date(family.dateOfBirth).toLocaleDateString(
                          "en-GB",
                          options
                        )}
                      /> */}
                      {/* <ValueText
                        title={
                          family
                            ? new Date(family.dateOfBirth).toLocaleDateString(
                                "en-GB",
                                options
                              )
                            : ""
                        }
                      /> */}
                    </Grid>
                    {/* row */}


                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Email ID" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <ValueText title={family.emailId} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Mobile Number" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <ValueText
                      
                        title={family.mobileNo}
                      />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Landline Number" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <ValueText title={family.landNo} />
                    </Grid>
                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Aadhaar Number" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <ValueText
                        // title={`XXXX XXXX ${member.aadhaarNo?.slice(-4)}`}
                        title={family.aadhaarNo}
                      />
                    </Grid>
                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Pan" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                     
                      <ValueText title={family.panNo} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Address" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <ValueText
                        title={`
                                    ${family ? family.addressL1 + "," : ""}
                                    
                                    ${
                                      family.addressL2
                                        ? family.addressL2 + ","
                                        : ""
                                    }
                                    
                                    ${
                                      family.village ? family.village + "," : ""
                                    }
                                    
                                    ${family.city ? family.city + "," : ""}
                                    
                                    ${
                                      family.district
                                        ? family.district + ","
                                        : ""
                                    }
                                    
                                    ${family.state ? family.state + "," : ""}
                                    
                                    ${
                                      family.country ? family.country + "," : ""
                                    }
                                    
                                    ${family.pincode ? family.pincode : ""}
                                `}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* ************************************** */}
                {/* section 2  */}
                <Grid item xs={12} sm={12}>
                  <SubtitleText title="Claim Eligibility Details" />
                </Grid>

                {/* column 1  */}

                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                    columnGap={1}
                  >
                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="PF nominee" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <ValueText
                        title={
                          family.pfNominee == true
                            ? "Yes"
                            : "" || family.pfNominee == false
                            ? "No"
                            : ""
                        }
                      />
                      {/* <ValueText title={family.pfNominee} /> */}
                    </Grid>
                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="PF nominee %" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <ValueText title={family.pfNomSharePerc} />
                    </Grid>
                  </Grid>
                </Grid>

                {/* column 2  */}

                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    columnGap={1}
                  >
                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Pension nominee" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <ValueText
                        title={
                          family.pensionNominee == true
                            ? "Yes"
                            : "" || family.pensionNominee == false
                            ? "No"
                            : ""
                        }
                      />
                      {/* <ValueText title={family.pensionNominee} /> */}
                    </Grid>
                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Pension nominee %" />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{ display: { xs: "none", sm: "initial" } }}
                    >
                      :
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <ValueText title={family.penNomSharePerc} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "2rem" }}>
                <Button
                  onClick={handleProcessOk}
                  variant="contained"
                  color="success"
                  disabled={isSubmitting}
                >
                  SUBMIT
                </Button>
              </Grid>
              {showingDropdown === 1 && (
                <Grid
                  container
                  justifyContent="end"
                  alignItems={"center"}
                  spacing={2}
                >
                  <Grid item xs={12} sm={4}>
                    <CustomDropdown {...assignTo} onchange={onChange} />
                  </Grid>
                  <Grid item md={1}>
                    <Button
                      onClick={handleSubmitOk}
                      variant="contained"
                      color="success"
                      type="submit"
                      disabled={isSubmitting}
                      fullWidth
                    >
                      {isSubmitting ? <CircularProgress /> : "OK"}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default PreviewPageFamilyMember;
