import { Grid, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import TitleText from "../../FormComponent/TitleText";
import FormDivider from "../../FormComponent/FormDivider";
import MemberDetails from "../UDAPages/MemberDetatils";
import BackButton from "../../components/button/BackButton";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ViewDocuments from "../../SettlementComponents/ViewDocuments";
import UpdatedFieldTable from "./UpdatedFieldsTable";
import jwtDecode from "jwt-decode";

function ProceedUpdMemDetails() {
  const { state } = useLocation();
  const memId = state.passingId;
  // const memId = "MEM-UPD-ACK-113";
  // console.log("memId", memId);
  const memType = memId.substring(0, 3);

  // console.log("memType", memType);
  const navigate = useNavigate();
  const sampleMemberData = {
    // This is the sample claimData data to be displed for demo purpose only
    title: "",
    fullName: "",
    dateOfBirth: "",
    panNo: "",
    aadhaarNumber: "",
    maritalStatus: "",
    religionName: "",
    gender: "",
    fatherHusbandName: "",
    email: "",
    mobileNo: "",
    landNo: "",
    addressL1: "",
    addressL2: "",
    village: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  };

  const sampleClaimData = {
    tUpdMemDetls: {
      updMemId: "",
      verifyStatus: "",
      verifyDate: "",
      updStatus: "",
      updatedDate: "",
      updReqAckId: "",
      fieldName: "",
      fieldsUpdated: "",
      oldField_val: "",
      newFieldVal: "",
      updateRemarks: "",
      loginId: "",
      memProfId: "",
      nameProof: "",
      dobProof: "",
      miscProof: null,
      maritalProof: "",
      addressProof: "",
      updReqTrkNo: "",
      statusId: "",
    },
    cmpfAcNo: "",
  };

  const [memUpdReqdetails, SetMemUpdReqdetails] = useState(sampleClaimData);
  const [memberData, setMemberData] = useState(sampleMemberData);
  const [isMemberFound, setIsMemberFound] = useState(" ");
  const [documents, setDocuments] = useState([]);

  //**********for integration************* */
  const [isLoading, setIsLoading] = useState(true); // set this to true
  const [isLoadingDoc, setIsLoadingDoc] = useState(true); // set this to true
  const [isFailed, setIsFailed] = useState(false);
  const [isFailedDoc, setIsFailedDoc] = useState(false);

  // declaring the default api object
  let api = {
    memUrl: "",
    docsUrl: "",
    returnUrl: "",
    forwardUrl: "",
    forwordAgmUrl: "",
    officerDropdownUrl: "",
    memberDetailsUrl: "",
  };
  // object for pfapis
  //let loginId = "UAO-CC-OFF-1050"; // Assuming a default loginId
  const [loginId, setLoginId] = useState();

  /********fetching and setting token********* */
  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (token) {
      token = token.replace('"', "").replace('"', "");
      let loginId = jwtDecode(token).user; // Assuming a default loginId
      setLoginId(loginId);
      // console.log("loooooooooooooooooooooginId", loginId);
      // Add any further logic here based on the loginId
    } else {
      // Handle the case when token is not present
      setIsLoading(false);
      setIsFailed(true);
    }
  }, []);
  const memApis = {
    memUrl: "/get/update/member/details/by/id",
    docsUrl: "/fetch/update/member/details/docs/service",
    returnUrl: "/return/adv/pf/claim/uao/to/uda",
    forwardUrl: "/forward/update/member/details/uao/to/aao",
    officerDropdownUrl: "/dropdown/for/update/member/details/by/uao",
    memberDetailsUrl: "/member/details/for/pf",
  };

  if (memType === "MEM") {
    api = { ...memApis };
  }

  useEffect(() => {
    fetchAllClaimDetail();
  }, [loginId]);

  const fetchMemUpdDetail = async () => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    // const url = process.env.REACT_APP_SERVER_URL + "/show/pf/claim/on/claimid";
    const url = process.env.REACT_APP_SERVER_URL + api.memUrl;

    const formData = new FormData();
    formData.append("updReqAckId", memId);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          // console.log("jai", data);
          SetMemUpdReqdetails(data);
          fetchMemberDetail(data.cmpfAcNo);
          fetchDocuments(data.tUpdMemDetls.memProfId);
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
      });
  };

  const fetchAllClaimDetail = () => {
    fetchMemUpdDetail();
  };
  const fetchDocuments = async (memberId) => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + api.docsUrl;
      const formData = new FormData();
      formData.append("memberId", memberId);
      const config = {
        headers: {
          // Include the Authorization header if req uired
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      setIsLoadingDoc(false);
      setIsFailedDoc(false);
      if (response.status === 200) {
        const data = response.data.data;
        setDocuments(data);
      } else {
        setIsFailed(true);
      }
    } catch (error) {
      // Corrected syntax for the catch block
      setIsLoading(false);
      setIsFailed(true);
    }
  };

  console.log("documents", documents);
  ///****************integration started********************* */
  const fetchMemberDetail = async (accNo) => {
    setIsLoading(true);

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");
    const url = process.env.REACT_APP_SERVER_URL + api.memberDetailsUrl;
    const formData = new FormData();
    formData.append("cmpfAccNo", accNo);

    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        // setIsLoading(false);
        // setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          if (data.length !== 0) {
            setMemberData(data[0]);
            setIsMemberFound("true");
          }
          setIsMemberFound("false");
          return;
        }
        setIsFailed(true);
        setIsMemberFound("false");
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
        setIsMemberFound("false");
      });
  };

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "10px" }}>
            {/* {isFailed || isFailedDoc ? (
              <Reloader refreshHandler={fetchAllClaimDetail} />
            ) : (
              <>
                {isLoading || isLoadingDoc ? (
                  <LoadingIndicator />
                ) : ( */}
            <>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <TitleText title="Update Member Details Approval" />
                </Grid>
              </Grid>
              <FormDivider />
              <Grid container spacing={1}>
                <Grid item xs={12} md={1} mb={3}>
                  <BackButton />
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="start"
                  rowGap={3}
                >
                  {/* Member Details*/}
                  {/* section 1  */}
                  <Paper
                    elevation={3}
                    sx={{ padding: "3rem", borderRadius: "10px" }}
                  >
                    <Grid item xs={12}>
                      <MemberDetails member={memberData} />
                    </Grid>
                  </Paper>
                  {/* claim Details*/}
                  {/* section 2  */}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={12}>
                      <Paper
                        elevation={3}
                        sx={{ padding: "3rem", borderRadius: "10px" }}
                      >
                        <Grid item xs={12}>
                          <UpdatedFieldTable
                            memUpdReqdetails={memUpdReqdetails}
                          />
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                  {/* Document Details*/}
                  {/* section 3  */}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={12}>
                      <Paper
                        elevation={3}
                        sx={{ padding: "3rem", borderRadius: "10px" }}
                      >
                        <Grid item xs={12}>
                          <ViewDocuments documents={documents} />
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default ProceedUpdMemDetails;
