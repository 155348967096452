import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import LabelText from "../../FormComponent/LabelText";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import FormDivider from "../../FormComponent/FormDivider";
import TitleText from "../../FormComponent/TitleText";
import SubtitleText from "../../FormComponent/SubtitleText";
import CustomTextField from "../../FormComponent/CustomTextField";
import UploadDocument from "../../SettlementComponents/UploadDocument";
import AdditionalAdvPf from "../UDAPages/AddtionalAdvPf";
import axios from "axios";
import CaptionText from "../../FormComponent/CaptionText";
import SucessAlert from "../../FormComponent/SuccessAlert";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import CustomTextArea from "../../FormComponent/CustomTextArea";
import ConfirmationDialogue from "../../FormComponent/ConfirmationDialogue";
import ValueText from "../../FormComponent/ValueText";
import { useNavigate } from "react-router-dom";
import ErrorInList from "../../FormComponent/ErrorInList";
import jwtDecode from "jwt-decode";

function ClaimForm(props) {
  const { memberData, mappedData, accNo } = props;
  console.log("member details", memberData);
  let count = Object.keys(mappedData).length;
  const navigate = useNavigate();
  const familyDefault = {
    familyMemId: "",
    nameFamily: "",
  };
  // ******* for declaration ******
  const [checked, setChecked] = useState(false);
  //docs
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [showingDropdown, setShowingDropdown] = useState(0);
  const [assignToOption, setAssignToOption] = useState([]);
  const [selectedOfficerName, setSelectedOfficerName] = useState("");
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [settlementParaList, setSettlementParaList] = useState([]);
  const [mandatoryDoc, setMandatoryDoc] = useState();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorOnSubmit, setIsErrorOnSubmit] = useState(false);
  const [errorMessageOnSubmit, setErrorMessageOnSubmit] = useState("");
  const [isDocError, setIsDocError] = useState(false);
  const [docErrorMessage, setDocErrorMessage] = useState("");
  const [ismemError, setIsmemError] = useState(false);
  const [errormemMessage, setErrormemMessage] = useState("");
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [failedErrorMessage, setFailedErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [settlementPara, setSettlementPara] = useState([]);
  const [missingDocuments, setMissingDocuments] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [dependentFamily, setDependentFamily] = useState([familyDefault]);
  const [selectedDocTypeIds, setSelectedDocTypeIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [membershipPeriod, setMembershipPeriod] = useState({
    years: 0,
    months: 0,
  });

  const [loginId, setLoginId] = useState();

  /********fetching and setting token********* */
  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (token) {
      token = token.replace('"', "").replace('"', "");
      let loginId = jwtDecode(token).user; // Assuming a default loginId
      setLoginId(loginId);
      // Add any further logic here based on the loginId
    } else {
      // Handle the case when token is not present
      setIsLoading(false);
      setIsFailed(true);
    }
  }, []);

  useEffect(() => {
    const calculateMembershipPeriod = () => {
      const joiningDate = memberData.joiningDate
        ? new Date(memberData.joiningDate)
        : null;

      if (!joiningDate) {
        setIsmemError(true);
        setErrormemMessage(
          "Ensure your member details are up to date, as the joining date is missing."
        );
      } else {
        const currentDate = new Date();
        let yearsDiff = currentDate.getFullYear() - joiningDate.getFullYear();
        let monthsDiff = currentDate.getMonth() - joiningDate.getMonth();

        // Adjust for cases where the current month is earlier than the joining month
        if (monthsDiff < 0) {
          yearsDiff--;
          monthsDiff += 12;
        }

        setMembershipPeriod({ years: yearsDiff, months: monthsDiff });
      }
    };
    calculateMembershipPeriod(); // Call the function when the component mounts
    if (
      memberData.bankAccNoPf === null ||
      memberData.bankAccNoPf.trim() === "" ||
      memberData.pfBankName === null ||
      memberData.pfBankName.trim() === "" ||
      memberData.pfBankIfsc === null ||
      memberData.pfBankIfsc.trim() === "" ||
      memberData.pfAddress === null ||
      Object.keys(memberData.pfAddress).length === 0
    ) {
      setIsmemError(true);
      setErrormemMessage(
        "Ensure your member details are up to date, as the PF Bank details is missing."
      );
    }
  }, [memberData]); // Add memberData as a dependency to the useEffect hook

  let closingBalance = 0;
  let ledgerBalDate;
  if (count > 0) {
    closingBalance = mappedData[count].closingBal;
    ledgerBalDate = mappedData[count].currPeriodEnd;
  }

  const defaultClaim = {
    claim_type: "",
    mem_first_name_aadhar: "",
    mem_middle_name_aadhar: "",
    mem_last_name_aadhar: "",
    mem_first_name_bank: "",
    mem_middle_name_bank: "",
    mem_last_name_bank: "",
    mem_first_name_pan: "",
    mem_middle_name_pan: "",
    mem_last_name_pan: "",
    mem_dob_aadhar: "",
    mem_dob_pan: "",
    relation: "",
    fhw_name: "",
    requiredAmount: "",
    employeeGrossAmount: "",
    estimatedCost: "",
    remarks: "",
  };
  const [claim, setClaim] = useState(defaultClaim);

  const [advClaimTypeOption, setAdvClaimTypeOption] = useState([]);
  // state to store addition fields
  const [addField, setAddField] = useState("");

  const claim_type = {
    name: "claim_type",
    placeholder: "Select Claim Type",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "",
    required: true,
    options: advClaimTypeOption,
  };
  const relation = {
    name: "relation",
    placeholder: "Select Relation",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: claim.relation,
    required: true,
    options: [
      { id: "1", title: "W/O" },
      { id: "2", title: "D/O" },
      { id: "3", title: "S/O" },
      { id: "4", title: "H/O" },
    ],
    //options: claimTypeList,
  };

  const mem_first_name_aadhar = {
    name: "mem_first_name_aadhar",
    type: "text",
    helperText: "",
    placeholder: "First Name",
    defaultValue: claim.mem_first_name_aadhar,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_middle_name_aadhar = {
    name: "mem_middle_name_aadhar",
    type: "text",
    helperText: "",
    placeholder: "Middle Name",
    defaultValue: claim.mem_middle_name_aadhar,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_last_name_aadhar = {
    name: "mem_last_name_aadhar",
    type: "text",
    helperText: "",
    placeholder: "Last Name",
    defaultValue: claim.mem_last_name_aadhar,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_first_name_pan = {
    name: "mem_first_name_pan",
    type: "text",
    helperText: "",
    placeholder: "First Name",
    defaultValue: claim.mem_first_name_pan,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_middle_name_pan = {
    name: "mem_middle_name_pan",
    type: "text",
    helperText: "",
    placeholder: "Middle Name",
    defaultValue: claim.mem_middle_name_pan,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_last_name_pan = {
    name: "mem_last_name_pan",
    type: "text",
    helperText: "",
    placeholder: "Last Name",
    defaultValue: claim.mem_last_name_pan,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_first_name_bank = {
    name: "mem_first_name_bank",
    type: "text",
    helperText: "",
    placeholder: "First Name",
    defaultValue: claim.mem_first_name_bank,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_middle_name_bank = {
    name: "mem_middle_name_bank",
    type: "text",
    helperText: "",
    placeholder: "Middle Name",
    defaultValue: claim.mem_middle_name_bank,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const mem_last_name_bank = {
    name: "mem_last_name_bank",
    type: "text",
    helperText: "",
    placeholder: "Last Name",
    defaultValue: claim.mem_last_name_bank,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const mem_dob_aadhar = {
    name: "mem_dob_aadhar",
    type: "date",
    helperText: "",
    placeholder: "Select Date of Birth",
    defaultValue: claim.mem_dob_aadhar,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const mem_dob_pan = {
    name: "mem_dob_pan",
    type: "date",
    helperText: "",
    placeholder: "Select Date of Birth",
    defaultValue: claim.mem_dob_pan,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const fhw_name = {
    name: "fhw_name",
    type: "text",
    helperText: "",
    placeholder: "Fahter/Husband/Wife Name",
    defaultValue: claim.fhw_name,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const remarks = {
    name: "remarks",
    type: "text",
    multiline: true,
    helperText: "Enter your remarks (maximum 200 Character)",
    placeholder: "Enter your remarks here",
    defaultValue: claim.remarks,
    errorMessage: "Invalid",
    pattern: "",
    // maxLength: 1000, // Limiting to 200 words
  };

  const assignTo = {
    name: "assignTo",
    placeholder: "Select Officer",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "",
    required: true,
    options: assignToOption,
  };

  //****************Dropdown******************* */
  const fetchAssignOfficer = async () => {
    try {
      const url =
        process.env.REACT_APP_SERVER_URL +
        "/dropdown/for/show/next/authorized/offcr/adv/pf/pen/uda";

      const formData = new FormData();
      formData.append("loginId", loginId);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      const options = data.map((option) => ({
        id: option.OffcrId,
        title: `${option.Name} (${option.OffcrId})`,
      }));
      setAssignToOption(options); // Assuming setAssignToOption is a state setter function
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchAssignOfficer();
  }, [loginId]);

  // *****************mandatory docs****************************
  const fetchDocumentsToUploadList = async () => {
    try {
      const url =
        process.env.REACT_APP_SERVER_URL + "/advance/pf/claim/mandatory/docs";

      const formData = new FormData();
      formData.append("claimTypeId", claim.claim_type);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      const data = response.data.data;
      setMandatoryDoc(data);
    } catch (error) {
      // Handle error
    }
  };

  //*******************Settlement Para List************************ */
  const fetchClaimTypes = async () => {
    const url = process.env.REACT_APP_SERVER_URL + "/advance/pf/claim/Type";

    axios.post(url).then((response) => {
      setSettlementParaList(response.data.data);
    });
  };

  useEffect(() => {
    // fetchAssignOfficer();
    fetchClaimTypes();
    fetchDocumentsToUploadList(claim.claim_type);
  }, [claim.claim_type]);

  useEffect(() => {
    const selectedClaimId = claim.claim_type;
    const selectedClaimType = settlementParaList.find(
      (claim) => claim.id == selectedClaimId
    );

    // If a matching claim type is found, set the settlementPara to the "para" value
    // Otherwise, set settlementPara to an empty string
    setSettlementPara(selectedClaimType ? selectedClaimType.para : "");
  }, [claim.claim_type]);

  // const handleClaimTypeChange = (event) => {
  //   const claimType = event.target.value;
  //   setClaim((prevClaim) => ({ ...prevClaim, claim_type: claimType }));
  // };

  //! cmpf account number is fetched on useEffect with a delay of 3sec to avoid multiple api hits
  const onChange = (e) => {
    if (e.target.name === "assignTo") {
      const selectedOption = assignToOption.find(
        (option) => option.id === e.target.value
      );
      if (selectedOption) {
        setSelectedOfficerName(selectedOption.title); // Set the selected officer's name in the state
      }
    }
    setClaim({ ...claim, [e.target.name]: e.target.value });
  };
  // to pass to additionpf
  const updateAddField = (fieldName, value) => {
    setAddField({ ...addField, [fieldName]: value });
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  // Advance claim type integration]
  const fetchAdvClaimType = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + "/advance/pf/claim/Type";

      const response = await axios.post(url);
      const data = response.data.data;
      const options = data.map((option) => ({
        id: option.id.toString(),
        title: option.title,
      }));
      setAdvClaimTypeOption(options); // Set the fetched data to the tablePreview state // we are taking data[0] as the response recevied is an array of object and we need only the first element

      //   setIsLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    fetchAdvClaimType();
  }, []);

  //**************************************************** Calculation *************************************************************** */
  const calculateTotalMemberShare = (mappedData) => {
    let totalMemberShare = 0;
    let totalEmployeeShare = 0;

    for (let i = 0; i < Object.keys(mappedData).length; i++) {
      // for (let i = 0; i < 34; i++) {
      // let currentDetails = "";
      // if (i > 0) {
      //   currentDetails = mappedData[Object.keys(mappedData).length];
      // }
      let currentData = mappedData[i + 1];
      if (currentData.widhrolData.length != 0) {
        let advClaimDateParts =
          currentData.widhrolData[0].withdrwlDate.split("-"); // Assuming date format is YYYY-MM-DD
        let withdrwlDate = new Date(
          advClaimDateParts[0],
          advClaimDateParts[1] - 1,
          advClaimDateParts[2]
        );
        // Example date format: "2022-09-15"

        let isCarryForward = withdrwlDate.getMonth() > 2;
        let month = 1 + withdrwlDate.getMonth();
        if (
          [1, 2, 3, 4].includes(mappedData[i + 1].widhrolData[0].withdrwlTypId)
        ) {
          if (!isCarryForward) {
            totalMemberShare = Number(
              (
                totalMemberShare +
                (totalMemberShare * mappedData[i + 1].interestPerc) / 100 +
                mappedData[i + 1].memPfContri +
                mappedData[i + 1].volContri -
                Number(
                  (mappedData[i + 1].widhrolData[0].withdrwlAmt * 4) / 7
                ).toFixed(0) *
                  (1 + (mappedData[i + 1].interestPerc * (3 - month)) / 1200)
              ).toFixed(0) // Round off to 2 decimal places
            );
            totalEmployeeShare = Number(
              (
                totalEmployeeShare +
                (totalEmployeeShare * mappedData[i + 1].interestPerc) / 100 +
                mappedData[i + 1].employerPfContri -
                Number(
                  (mappedData[i + 1].widhrolData[0].withdrwlAmt * 3) / 7
                ).toFixed(0) *
                  (1 + (mappedData[i + 1].interestPerc * (3 - month)) / 1200)
              ).toFixed(0) // Round off to 2 decimal places
            );
          } else {
            totalMemberShare = Number(
              (
                totalMemberShare +
                (totalMemberShare * mappedData[i + 1].interestPerc) / 100 +
                mappedData[i + 1].memPfContri +
                mappedData[i + 1].volContri -
                Number(
                  (mappedData[i + 1].widhrolData[0].withdrwlAmt * 4) / 7
                ).toFixed(0) -
                (Number(
                  (mappedData[i + 1].widhrolData[0].withdrwlAmt * 4) / 7
                ).toFixed(0) *
                  mappedData[i + 1].interestPerc *
                  (15 - month)) /
                  1200
              ).toFixed(0) // Round off to 2 decimal places
            );

            totalEmployeeShare = Number(
              (
                totalEmployeeShare +
                (totalEmployeeShare * mappedData[i + 1].interestPerc) / 100 +
                mappedData[i + 1].employerPfContri -
                Number(
                  (mappedData[i + 1].widhrolData[0].withdrwlAmt * 3) / 7
                ).toFixed(0) -
                (Number(
                  (mappedData[i + 1].widhrolData[0].withdrwlAmt * 3) / 7
                ).toFixed(0) *
                  mappedData[i + 1].interestPerc *
                  (15 - month)) /
                  1200
              ).toFixed(0) // Round off to 2 decimal places
            );
          }
        } else if (
          [5, 6, 7].includes(mappedData[i + 1].widhrolData[0].withdrwlTypId)
        ) {
          if (!isCarryForward) {
            totalMemberShare = Number(
              (
                totalMemberShare +
                (totalMemberShare * mappedData[i + 1].interestPerc) / 100 +
                mappedData[i + 1].memPfContri +
                mappedData[i + 1].volContri -
                mappedData[i + 1].widhrolData[0].withdrwlAmt *
                  (1 + (mappedData[i + 1].interestPerc * (3 - month)) / 1200)
              ).toFixed(0) // Round off to 2 decimal places
            );
            totalEmployeeShare = Number(
              (
                mappedData[i + 1].employerPfContri +
                totalEmployeeShare +
                (totalEmployeeShare * mappedData[i + 1].interestPerc) / 100
              ).toFixed(0) // Round off to 2 decimal places
            );
          } else {
            totalMemberShare = Number(
              (
                totalMemberShare +
                (totalMemberShare * mappedData[i + 1].interestPerc) / 100 +
                mappedData[i + 1].memPfContri +
                mappedData[i + 1].volContri -
                mappedData[i + 1].widhrolData[0].withdrwlAmt -
                (mappedData[i + 1].widhrolData[0].withdrwlAmt *
                  mappedData[i + 1].interestPerc *
                  (15 - month)) /
                  1200
              ).toFixed(0) // Round off to 2 decimal places
            );
            totalEmployeeShare = Number(
              (
                mappedData[i + 1].employerPfContri +
                totalEmployeeShare +
                (totalEmployeeShare * mappedData[i + 1].interestPerc) / 100
              ).toFixed(0) // Round off to 2 decimal places
            );
          }
        }
      } else {
        totalMemberShare = Number(
          (
            totalMemberShare +
            mappedData[i + 1].memPfContri +
            mappedData[i + 1].volContri +
            (totalMemberShare * mappedData[i + 1].interestPerc) / 100
          ).toFixed(0) // Round off to 2 decimal places
        );
        totalEmployeeShare = Number(
          (
            mappedData[i + 1].employerPfContri +
            totalEmployeeShare +
            (totalEmployeeShare * mappedData[i + 1].interestPerc) / 100
          ).toFixed(0) // Round off to 2 decimal places
        );
      }
    }
    return [totalMemberShare, totalEmployeeShare];
  };

  const [totalMemberShare, totalEmployeeShare] =
    calculateTotalMemberShare(mappedData);

  //******************************************************************************************************************************** */
  const formatAddress = (addField) => {
    const addressLines = [];

    if (addField.addressline1) addressLines.push(addField.addressline1);
    if (addField.addressline2) addressLines.push(addField.addressline2);
    // if (addField.village) addressLines.push(addField.village);
    if (addField.city) addressLines.push(addField.city);
    if (addField.district) addressLines.push(addField.district);
    if (addField.state) addressLines.push(addField.state);
    // if (addField.country) addressLines.push(addField.country);
    const addressParts = [...addressLines, addField.pincode || ""];
    return addressParts.join(", ");
  };

  const formatAdd = (addField) => {
    const addressLines = [];

    if (addField.locationaddressline1)
      addressLines.push(addField.locationaddressline1);
    if (addField.locationaddressline2)
      addressLines.push(addField.locationaddressline2);
    // if (addField.village) addressLines.push(addField.village);
    if (addField.villageCity) addressLines.push(addField.villageCity);
    if (addField.districtLocation) addressLines.push(addField.districtLocation);
    if (addField.stateLocation) addressLines.push(addField.stateLocation);
    // if (addField.country) addressLines.push(addField.country);
    const addressParts = [...addressLines, addField.pinCodeLocation || ""];
    return addressParts.join(", ");
  };

  const formattedAddress = formatAddress(addField);
  const locationAdd = formatAdd(addField);
  const [sfwhRelationty, setSfwhRelationType] = useState("");
  // Assume this is inside a React functional component

  useEffect(() => {
    if (claim.relation == 1) {
      setSfwhRelationType("W/O");
    } else if (claim.relation == 2) {
      setSfwhRelationType("D/O");
    } else if (claim.relation == 3) {
      setSfwhRelationType("S/O");
    } else if (claim.relation == 4) {
      setSfwhRelationType("H/O");
    } else {
      console.error("Invalid claim relation value:", claim.relation);
    }
  }, [claim.relation]); // Ensure the effect runs only when claim.relation changes

  const [areaUnitt, setAreaUnitt] = useState("Sq. ft.");
  useEffect(() => {
    if (addField.areaUnit == 2) {
      setAreaUnitt("Sq. yd.");
    } else {
      console.error("Invalid claim relation value:", addField.areaUnit);
    }
  }, [addField.areaUnit]); // Ensure the effect runs only when claim.relation changes
  const objectTobeSent = {
    udaLoginId: loginId,
    cmpfAccNo: memberData.cmpfAccNo,
    toOffcrId: claim.assignTo,
    nameAadhaar: {
      firstname: claim.mem_first_name_pan,
      middlename: claim.mem_middle_name_pan,
      lastname: claim.mem_last_name_pan,
    },
    namePan: {
      firstname: claim.mem_first_name_pan,
      middlename: claim.mem_middle_name_pan,
      lastname: claim.mem_last_name_pan,
    },
    nameBank: {
      firstname: claim.mem_first_name_bank,
      middlename: claim.mem_middle_name_bank,
      lastname: claim.mem_last_name_bank,
    },
    dobAadhaar: claim.mem_dob_aadhar,
    dobPan: claim.mem_dob_pan,
    sfwhName: claim.fhw_name,
    sfwhRelationType: sfwhRelationty,
    declarationUda: checked,
    emolumentsLast36M: 36 * addField.employeeGrossAmount,
    ledgerBalance: closingBalance,
    memShare: totalMemberShare,
    employeeShare: totalEmployeeShare,
    amountAllowed: addField.amountAllowed,
    correspondanceAddress: {
      address: formattedAddress,
    },
    periodOfMemship: membershipPeriod,
    ledgerBalDate: ledgerBalDate,
    remarks: claim.remarks,
    estimatedCost: 1 * addField.estimatedCost,
    saleAgreeAmt: 1 * addField.saleAgreementAmount,
    locationDet: {
      address: locationAdd,
    },
    anticptdDateOfMarriage: addField.anticptdDateOfMarriage,
    examPassed: addField.examPassed,
    examYear: addField.examYear,
    feesPaid: addField.feesPaid,
    areaUnit: areaUnitt,
    areaValue: addField.area,
    modeOfRemmit: "A/C PAYEE CHEQUE/BANK TRANSFER",
    // remmitBankDetails: resultMap.address,
    // cheqeNo
    dependentId: addField.dependentId,
    advReqdDate: addField.amountRequiredDate,
    plotNo: addField.plotNo,
    subPlotNo: addField.subPlotNo,
    advClaimTypeId: 1 * claim.claim_type,
    amountApplied: 1 * addField.requiredAmount,
  };

  console.log("objectTobeSent", objectTobeSent);
  const dependentPerson = addField.dependentId;
  const handleSuccessOk = () => {
    navigate("/uda/advset");
    setIsSubmitSuccess(false);
  };

  const [isRoleAssigned, setIsRoleAssigned] = useState(false);

  /***** function to get all dependent family member details**************** */
  const fetchNomineeDetail = async () => {
    setIsLoading(true);
    // console.log("in fetch Member detail");

    //  if (!(await sessionExist())) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  let token = localStorage.getItem("jwt");

    //  if (!token) {
    //    setIsFailed(true);
    //    setIsLoading(false);
    //    return;
    //  }

    //  token = token.replace('"', "").replace('"', "");

    const url = process.env.REACT_APP_SERVER_URL + "/dependent/family/member";
    const formData = new FormData();
    formData.append("cmpfAccNum", accNo); // check what formdata is to be sent
    const config = {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then(function (response) {
        setIsLoading(false);
        setIsFailed(false);
        if (response.status === 200) {
          const data = response.data.data;
          setDependentFamily(data);
          return;
        }
        setIsFailed(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsFailed(true);
      });
  };

  const fetchDocumentTypes = async () => {
    try {
      const url =
        process.env.REACT_APP_SERVER_URL + "/all/document/for/adv/pf/claim";
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, config);
      const data = response.data.data;
      setDocumentTypes(data); // Assuming the data is an array of document types

      // Handle error
    } catch (error) {
      console.error("Error fetching document types:", error);
    }
  };

  // Fetch document types when the component mounts
  useEffect(() => {
    fetchDocumentTypes();
    fetchNomineeDetail();
  }, []);

  /// ******** on submit function*********

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const url =
      process.env.REACT_APP_SERVER_URL + "/submit/advance/pf/claim/by/uda";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    axios
      .post(url, objectTobeSent, { headers })
      .then(function (response) {
        setIsSubmitting(false);
        postUploadedDoc();

        if (response.request.status === 200) {
          setIsRoleAssigned(true);
          setSuccessMessage("Claim Submit Successful...");
          // Display a generic success message or handle other status codes
          console.log("Unexpected response status:", response.status);
        }
        // Check if the response status is 208
        if (response.data.http_status_code === 208) {
          // Display the error message from the API response
          const errorMessage = response.data.error_message || "Unknown error";
          // Set the error message to be displayed in your ErrorAlert component
          setIsErrorOnSubmit(true);
          setErrorMessageOnSubmit(errorMessage);
        }
      })
      .catch((error) => {
        if (error.response.data.http_status_code === 406) {
          // Display the error message from the API response
          const errorMessage =
            error.response.data.error_message || "Unknown error";
          // Set the error message to be displayed in your ErrorAlert component
          setIsErrorOnSubmit(true);
          setErrorMessageOnSubmit(errorMessage);
        }
        if (error.response.data.http_status_code === 400) {
          const errorMessage =
            error.response.data.error_message || "Unknown error";
          setIsErrorOnSubmit(true);
          setErrorMessageOnSubmit(errorMessage);
          // setErrorMessage("Claim Submit Successful...");
          // Display a generic success message or handle other status codes
          console.log("Unexpected response status:", error.response.status);
        }
        console.log("error on submit", error);
      });
  };

  const handleSave = (documentData) => {
    const filteredDocuments = documentData.filter(
      (upload) => upload.uploadedDocument !== null
    );

    const requestBody = {
      cmpfAcNo: memberData.cmpfAccNo,
      uploadAdvPfDocumentsDTO: documentData.map((upload) => ({
        documentData: upload.uploadedDocument,
        docTypeId: upload.selectedDocumentType,
      })),
    };

    // Extracting docTypeIds from filtered documents and storing in state
    const docTypeIds = filteredDocuments.map(
      (upload) => upload.selectedDocumentType
    );
    setSelectedDocTypeIds(docTypeIds);

    setUploadedDocs(requestBody.uploadAdvPfDocumentsDTO);
  };

  const handleUnmatchedTitles = (selectedDocTypeIds, mandatoryDoc) => {
    try {
      // Extracting ids from the API data
      const apiDocTypeIds = new Set(mandatoryDoc.map((docType) => docType.id));

      // Finding ids that are in API data but not in selectedDocTypeIds
      const unmatchedIds = [...apiDocTypeIds].filter(
        (id) => !selectedDocTypeIds.includes(id)
      );

      // Creating an array to store unmatched titles with serial numbers
      const unmatchedTitlesList = [];

      // Finding titles corresponding to unmatched ids and storing them in the array
      mandatoryDoc.forEach((docType, index) => {
        if (unmatchedIds.includes(docType.id)) {
          // const serialNumber = index + 1;
          // const titleWithSerial = `${serialNumber}.${docType.title}`;
          //const titleWithSerial = `${serialNumber}.${docType.title}`;
          const titleWithSerial = `${docType.title}`;

          unmatchedTitlesList.push(titleWithSerial);
        }
      });

      // Set the unmatched titles in the state or handle them as needed
      setMissingDocuments(unmatchedTitlesList);
    } catch (error) {
      console.error("Error processing data:", error.message);
      // Handle the error as needed, e.g., set an error state
    }
  };

  const postUploadedDoc = () => {
    const uploadDocData = {
      cmpfAcNo: memberData.cmpfAccNo,
      uploadAdvPfDocumentsDTO: uploadedDocs,
    };
    const url =
      process.env.REACT_APP_SERVER_URL +
      "/submit/advance/pf/mandatory/docs/by/uda";
    const headers = {
      //'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(url, uploadDocData, { headers })
      .then(function (response) {
        setIsSubmitSuccess(true);
      })
      .catch((error) => {
        setIsFailed(true);
        setFailedErrorMessage("Something went Wrong!!");
        console.log("error on submit", error);
      });
  };

  //*********function to check the name in document proofs vs service records********* */
  function checkNameMatch(fullName, firstName, middleName, lastName) {
    const fullNameParts = fullName.split(" ");
    const first = firstName ? firstName.toLowerCase() : "";
    const middle = middleName ? middleName.toLowerCase() : "";
    const last = lastName ? lastName.toLowerCase() : "";

    const firstMatch =
      first === "" ||
      fullNameParts.filter((name) => name.toLowerCase() === first).length === 1;
    const middleMatch =
      middle === "" ||
      fullNameParts.filter((name) => name.toLowerCase() === middle).length ===
        1;
    const lastMatch =
      last === "" ||
      fullNameParts.filter((name) => name.toLowerCase() === last).length === 1;

    return firstMatch && middleMatch && lastMatch;
  }

  /**** checking the names ******/
  function nameCheck() {
    const fullName = memberData.fullName;
    const aadhaarCheck = checkNameMatch(
      fullName,
      claim.mem_first_name_aadhar,
      claim.mem_middle_name_aadhar,
      claim.mem_last_name_aadhar
    );
    const panCheck = checkNameMatch(
      fullName,
      claim.mem_first_name_pan,
      claim.mem_middle_name_pan,
      claim.mem_last_name_pan
    );
    const bankStateCheck = checkNameMatch(
      fullName,
      claim.mem_first_name_bank,
      claim.mem_middle_name_bank,
      claim.mem_last_name_bank
    );

    return aadhaarCheck && panCheck && bankStateCheck;
  }

  const dateOptions = { day: "2-digit", month: "2-digit", year: "numeric" };

  /*********function to check the dates enterd*********/
  function checkDateOfBirthMatch(enteredDate, givenDate) {
    if (enteredDate) {
      // const enteredDateParts = enteredDate.split("-");
      // const givenDateParts = givenDate.split("-");
      const enteredDateParts = enteredDate.split("-");
      const givenDateParts = givenDate.split("-");

      const enteredDateObj = new Date(
        enteredDateParts[2],
        enteredDateParts[1] - 1,
        enteredDateParts[0]
      );
      const givenDateObj = new Date(
        givenDateParts[2],
        givenDateParts[1] - 1,
        givenDateParts[0]
      );

      if (enteredDateObj.getTime() === givenDateObj.getTime()) {
        return true; // Date of birth matches
      } else {
        return false; // Date of birth does not match
      }
    } else {
      return false;
    }
  }

  /*****************checking dob from aadhaar and pan******************/
  function dobCheck() {
    const memberDob = new Date(memberData.dateOfBirth)
      .toLocaleDateString("en-GB", dateOptions)
      .split("/")
      .reverse()
      .join("-");
    const aadhaarDobCheck = checkDateOfBirthMatch(
      memberDob,
      claim.mem_dob_aadhar
    );
    const panDobCheck = checkDateOfBirthMatch(claim.mem_dob_pan, memberDob);
    return aadhaarDobCheck && panDobCheck;
  }

  const checkGenCertificate = () => {
    return uploadedDocs.some((docs) => {
      return docs.docTypeId === 18;
    });
  };

  /// error alert handler
  const handleErrorAlert = () => {
    // setAddField("amountRequiredDate":null);
    setAddField({ ...addField, amountRequiredDate: "" });

    setIsError(false);
  };

  const handleDocErrorAlert = () => {
    setIsDocError(false);
  };

  const handleErrorAlertOnSubmit = () => {
    navigate("/uda/advset");
    // isSubmitting(false);
    setIsErrorOnSubmit(false);
  };
  /// error alert handler
  const handleMemErrorAlert = () => {
    navigate("/uda/advset");
    setIsError(false);
  };

  // todo handleAssignToOk calling handle submit on click of ok confirmation dialouge box
  const handleAssignToOk = (e) => {
    handleSubmit(e);
    setIsSubmitClicked(false);
  };

  const handleAssignToCancel = () => {
    setIsSubmitClicked(false);
  };

  useEffect(() => {
    handleUnmatchedTitles(selectedDocTypeIds, mandatoryDoc);
  }, [selectedDocTypeIds, mandatoryDoc]);

  //! final checcks
  const handleProcessOk = (e) => {
    if (!nameCheck()) {
      if (!checkGenCertificate()) {
        setIsError(true);
        setErrorMessage(
          "Name entered does not match with the service records therefore it is mandatory to upload the Genuineness Certificate for Member's name"
        );
        return;
      }
    }
    if (
      !addField.pincode ||
      addField.pincode.length !== 6 ||
      /[eE]/.test(addField.pincode)
    ) {
      setIsError(true);
      setErrorMessage(
        "Please Enter correct Pincode (6 digits without 'e' or 'E')."
      );
      return;
    }

    if (!addField.requiredAmount || /[eE]/.test(addField.requiredAmount)) {
      setIsError(true);
      setErrorMessage("Please Enter correct Amount.");
      return;
    }
    if (!claim.claim_type) {
      setIsError(true);
      setErrorMessage("Please Select claim type.");
      return;
    }
    if (
      claim.claim_type == 1 ||
      claim.claim_type == 2 ||
      claim.claim_type == 3 ||
      claim.claim_type == 5 ||
      claim.claim_type == 7
    ) {
      if (
        !addField.employeeGrossAmount ||
        /[eE]/.test(addField.employeeGrossAmount)
      ) {
        setIsError(true);
        setErrorMessage("Please Enter correct Employee Gross Amount.");
        return;
      }
    }

    if (claim.claim_type == 1 || claim.claim_type == 2) {
      if (
        !addField.saleAgreementAmount ||
        /[eE]/.test(addField.saleAgreementAmount)
      ) {
        setIsError(true);
        setErrorMessage("Please Enter correct Sale Agreement Amount.");
        return;
      }
    }

    if (claim.claim_type == 3 || claim.claim_type == 5) {
      if (!addField.estimatedCost || /[eE]/.test(addField.estimatedCost)) {
        setIsError(true);
        setErrorMessage("Please Enter correct Estimated Cost.");
        return;
      }
    }

    if (claim.claim_type == 2) {
      if (!addField.plotNo) {
        setIsError(true);
        setErrorMessage("Please Enter Plot Number.");
        return;
      }
      if (!addField.subPlotNo) {
        setIsError(true);
        setErrorMessage("Please Enter Sub-Plot No .");
        return;
      }
    }

    if (claim.claim_type == 6) {
      console.log("surya", dependentPerson);
      if (!addField.anticptdDateOfMarriage) {
        setIsError(true);
        setErrorMessage("Please Enter Date Of Marriage.");
        return;
      }
      if (!dependentPerson && dependentPerson !== 0) {
        setIsError(true);
        setErrorMessage("Please select a Member.");
        return;
      }
    }
    if (claim.claim_type == 7) {
      if (!dependentPerson && dependentPerson !== 0) {
        setIsError(true);
        setErrorMessage("Please select a Member.");
        return;
      }

      if (!addField.feesPaid) {
        setIsError(true);
        setErrorMessage("Please enter the Fee paid.");
        return;
      }
    }

    // const checkAmountRequiredDate = (amountRequiredDate) => {
    //   if (amountRequiredDate !== null) {
    //     const parsedAmountRequiredDate = new Date(amountRequiredDate);

    //     const currentDate = new Date();
    //     const threeMonthsFromNow = new Date();
    //     threeMonthsFromNow.setMonth(currentDate.getMonth() + 3);

    //     if (parsedAmountRequiredDate < threeMonthsFromNow) {
    //       setIsError(true);
    //       setErrorMessage("Amount Required Date cannot be less than 3 months.");
    //     } else {
    //       // Reset error state if the date is valid
    //       setIsError(false);
    //       setErrorMessage("");
    //     }
    //   }
    // };

    if (!checked) {
      setIsError(true);
      setErrorMessage("Please Select the Declaration Checkbox.");
      return;
    }
    if (missingDocuments && missingDocuments.length > 0) {
      // Display an alert or handle the unmatchedTitles as needed
      //alert("There are missing documents: " + missingDocuments.join(", "));
      setIsDocError(true);
      setDocErrorMessage(missingDocuments.join(", "));
    } else {
      setShowingDropdown(1);
    }
  };

  const handleSubmitOk = () => {
    if (showingDropdown === 1) {
      if (!objectTobeSent.toOffcrId) {
        // Show message in dialog: "Please select an officer to assign"
        // alert("Please select an officer to assign");
        setIsError(true);
        setErrorMessage("Please Select an Officer to Assign.");
      } else {
        setIsSubmitClicked(true);
      }
    }
  };

  return (
    <>
      <ErrorAlert
        handleOk={handleErrorAlert}
        open={isError}
        title="Error"
        body={errorMessage}
        buttonName="Ok"
      />
      <ErrorInList
        handleOk={handleDocErrorAlert}
        open={isDocError}
        title="Mandatory documents missing:"
        body={docErrorMessage}
        buttonName="Ok"
      />
      <ErrorAlert
        handleOk={handleErrorAlertOnSubmit}
        open={isErrorOnSubmit}
        title="Error"
        body={errorMessageOnSubmit}
        buttonName="Ok"
      />
      <ErrorAlert
        handleOk={handleMemErrorAlert}
        open={ismemError}
        title="Error"
        body={errormemMessage}
        buttonName="Ok"
      />
      <SucessAlert
        handleOk={handleSuccessOk}
        open={isRoleAssigned}
        title="Success"
        body={successMessage}
        buttonName="Ok"
      />
      <ConfirmationDialogue
        handleOk={handleAssignToOk}
        handleCancel={handleAssignToCancel}
        open={isSubmitClicked}
        title="Confirm Assignment"
        body={`Are you sure you want to assign the claim to ${selectedOfficerName} ?`}
        buttonName="Confirm"
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                columnGap={2}
                justifyContent="space-between"
                alignItems="center">
                <Grid item xs={12} sm={4}>
                  <TitleText title="Claim Submission Form" />
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Grid
                    container
                    spacing={1}
                    //columnGap={1}
                    justifyContent="end"
                    alignItems="center">
                    <Grid
                      item
                      xs={7}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}>
                      <LabelText title="Settlement Para: " />
                    </Grid>
                    <Grid item xs={5}>
                      <ValueText title={settlementPara} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <FormDivider />
              <Grid
                container
                spacing={2}
                columnGap={2}
                justifyContent="center"
                alignItems="center">
                {/* ---claim type dropdown--- */}
                <Grid item xs={12} sm={4}>
                  <LabelText title="Select Claim type  " />
                </Grid>
                {/* <Grid mt={2}>:</Grid> */}
                <Grid item xs={12} sm={6}>
                  <CustomDropdown {...claim_type} onchange={onChange} />
                </Grid>
                {/* ------------------ */}
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    columnGap={2}
                    justifyContent="start"
                    alignItems="center">
                    <Grid item xs={12}>
                      <SubtitleText title="Enter Member Name as per " />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={2.5}>
                          <LabelText title="Aadhaar Card" />
                        </Grid>
                        {/* <Grid mt={2.5} mr={3.5}>
                          :
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_first_name_aadhar}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_middle_name_aadhar}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_last_name_aadhar}
                            onchange={onChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={2.5}>
                          <LabelText title="PAN Card" />
                        </Grid>
                        {/* <Grid mt={2.5} mr={3.5}>
                          :
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_first_name_pan}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_middle_name_pan}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_last_name_pan}
                            onchange={onChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={2.5}>
                          <LabelText title="Bank Statement" />
                        </Grid>
                        {/* <Grid mt={2.5} mr={3.5}>
                          :
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_first_name_bank}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_middle_name_bank}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <CustomTextField
                            {...mem_last_name_bank}
                            onchange={onChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* ******* Name fields ended ***** */}
                    <Grid item xs={12} mt={1}>
                      <SubtitleText title="Enter Member Date of Birth as per" />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={1}
                        columnGap={1}
                        justifyContent="space-between">
                        {/* here we are creating two columns as the label and textfield were not aligning properly */}
                        {/* Column 1 */}
                        <Grid item xs={12} md={5}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={4}>
                              <LabelText title="Aadhaar Card" />
                            </Grid>
                            <Grid mt={2.5}>:</Grid>
                            <Grid item xs={12} sm={12} md={7}>
                              <CustomTextField
                                {...mem_dob_aadhar}
                                onchange={onChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* Column 2 */}
                        <Grid item xs={12} md={5}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={4}>
                              <LabelText title="PAN Card" />
                            </Grid>
                            <Grid mt={2.5}>:</Grid>
                            <Grid item xs={12} sm={12} md={7}>
                              <CustomTextField
                                {...mem_dob_pan}
                                onchange={onChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* ********* Dob fields ends here******** */}
                    <Grid item xs={12} mt={1}>
                      <SubtitleText title="Enter S/O D/O W/O H/O as per" />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={2.5}>
                          <LabelText title="Aadhaar Card" />
                        </Grid>
                        <Grid mt={2.5}>:</Grid>
                        <Grid item xs={12} sm={12} md={9}>
                          <Grid
                            container
                            spacing={1}
                            justifyContent="end
                          ">
                            <Grid item xs={12} sm={12} md={5}>
                              <CustomDropdown
                                {...relation}
                                onchange={onChange}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                              <CustomTextField
                                {...fhw_name}
                                onchange={onChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <AdditionalAdvPf
                        location={{
                          claim_type: claim.claim_type,
                          memberShare: totalMemberShare,
                          empShare: totalEmployeeShare,
                          closingBalance: closingBalance,
                        }}
                        updateAddField={updateAddField}
                        dependentFamily={dependentFamily}
                      />
                    </Grid>
                    <Grid item xs={12} mt={1}>
                      <SubtitleText title="Upload Documents" />
                    </Grid>
                    <Grid item xs={12} sm={12} mt={-3}>
                      <UploadDocument
                        documentTypes={documentTypes}
                        onSave={handleSave}
                      />
                    </Grid>
                    {/* Document Upload  */}
                  </Grid>
                  {/* ************************************ Remarks ***************************************** */}
                  <Grid item xs={12} sm={12} style={{ paddingTop: 20 }}>
                    <SubtitleText title="Remarks" />
                  </Grid>
                  <Grid item xs={12} sm={7} style={{ paddingTop: 20 }}>
                    <CustomTextArea {...remarks} onchange={onChange} />
                  </Grid>

                  {/* ******************************* Declaration ***************************************** */}
                  <Grid item xs={12} mt={3}>
                    <Paper
                      elevation={0}
                      sx={{ padding: "3rem", borderRadius: "8px" }}>
                      <TitleText title="Declaration" />
                      <FormDivider />
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center", // Center vertically
                            gap: 2, // Adjust the gap as needed
                            padding: 2, // Add padding for better visualization
                            //border: "1px solid #ccc", // Add border for better visualization
                            justifyContent: "flex-start",
                          }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checked}
                                onChange={handleChange}
                                color="success"
                                sx={{
                                  marginTop: -1, // Adjust the negative margin to move the Checkbox up
                                  marginRight: -4,
                                }}
                              />
                            }
                            // label={<ValueText title="I Agree" />}
                          />
                          <CaptionText
                            title="All details have been verified with the service record and all documents have been verified with the original documents of the claim and found correct."
                            sx={{
                              margin: 0, // Reset margin for better alignment
                            }}
                          />
                        </Box>
                      </Grid>
                      {/* <CaptionText title="All documents are verified from the service record of the member and found correct and in order." />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    > */}
                      {/* <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            color="success"
                          />
                        }
                        label={<ValueText title="I Agree" />}
                      />
                    </Box> */}
                    </Paper>
                  </Grid>
                  {/* *************** Submit Button ********* */}
                  <Grid
                    item
                    xs={12}
                    sm="auto"
                    sx={{ justifyContent: "center" }}>
                    <Button
                      onClick={handleProcessOk}
                      variant="contained"
                      color="success"
                      disabled={isSubmitting}
                      //fullWidth
                      type="submit">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {showingDropdown === 1 && (
              <Grid
                container
                justifyContent="end"
                alignItems={"center"}
                spacing={2}>
                <Grid item xs={12} sm={4}>
                  <CustomDropdown {...assignTo} onchange={onChange} />
                </Grid>
                <Grid item md={1}>
                  <Button
                    onClick={handleSubmitOk}
                    variant="contained"
                    color="success"
                    type="submit"
                    disabled={isSubmitting}
                    fullWidth>
                    {isSubmitting ? <CircularProgress /> : "OK"}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default ClaimForm;
