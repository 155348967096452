import Header from "../../components/Header";
import Footer from "../LandingPage/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef, useContext } from "react";

import {
  Tab,
  Avatar,
  Card,
  CardContent,
  CircularProgress,
  Typography,
  Tabs,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Grid,
  Paper,
  Divider,
  Container,
  TextField,
  Box,
  AppBar,
} from "@mui/material";
import {
  CaptchaProvider,
  CaptchaVerifyContext,
} from "../../components/captcha/CaptchaProvider";
import Form from "../../components/captcha/CaptchaFormLogin";
import ValueText from "../../FormComponent/ValueText";
// import FormDivider from '../../../FormComponent/FormDivider';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import qs from "qs";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import ErrorAlert from "../../FormComponent/ErrorAlert";
import LogoutAlert from "../../FormComponent/LogOutAlert";
import Footbar from "../LandingPage/Footbar";
import Gif from "../../images/officerLoginSample4.gif";
import LabelText from "../../FormComponent/LabelText";
import CustomDropdown from "../../FormComponent/CustomDropdown";

import "./OfficerBar.css";

function OfficerLogin() {
  const theme = useTheme();
  const currentPage = useLocation();
  const navigate = useNavigate();

  //password validation
  const [passwordError, setPasswordError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [failedErrorMsg, setFailedErrorMessage] = useState(
    "Something went wrong"
  );
  const [failedErrorMsgAlert, setFailedErrorMessageAlert] = useState(false);
  const [sessionActiveAlert, setSessionActiveAlert] = useState(false);
  const [rolesList, setRolesList] = useState([]);

  // useRef to pass otp msg
  const otpMessageRef = useRef(null);

  const dataFormate = {
    loginId: "",
    password: "",
    //role: "CC Representative",
    role: "",
  };
  const rolesDropdown = {
    name: "role",
    // label: "Select Role",
    helperText: "",
    disabled: false,
    initialValue: "1",
    options: rolesList,
  };
  const [formData, setFormData] = useState(dataFormate);

  const [isOTPButtonDisabled, setIsOTPButtonDisabled] = useState(true);

  const otpButtonDisableFuncEnable = () => {
    setIsOTPButtonDisabled(false);
  };
  const otpButtonDisableFuncDisable = () => {
    setIsOTPButtonDisabled(true);
  };

  const validatePassword = (password) => {
    // Validate password: minimum uppercase, lowercase, special character, and number without space
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_])[^\s]{8,20}$/;
    return regex.test(password);
    //return true when password met the criteria
  };

  const validateUserName = (username) => {
    // Validate userName: alphanumeric,/ and max 20char
    // const regex = /^[a-zA-Z0-9/]{1,20}$/;
    // return regex.test(username);
    return true;
    //return true when password met the criteria
  };

  const validatePasswordField = (password) => {
    //console.log("password in function", password); // remove it
    if (!validatePassword(password)) {
      setPasswordError(
        "Password must contain at least 8 characters and maximum of 20 characters, including uppercase, lowercase,number and special character except space."
      );
      return false;
    }
    setPasswordError("");
    return true;
    // return false;
  };

  const validateUserNameField = (username) => {
    if (!validateUserName(username)) {
      setUserNameError("Enter valid username.");
      return false;
    }
    setUserNameError("");
    return true;
  };

  const buttonDisabledfunc = () => {
    setButtonDisabled(false);
  };

  const buttonEnablefunc = () => {
    setButtonDisabled(true);
  };

  const onChange = (e) => {
    if (e.target.name === "loginId") {
      validateUserNameField(e.target.value);
    }
    if (e.target.name === "password") {
      validatePasswordField(e.target.value);
    }
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.name === "password"
          ? window.btoa(e.target.value)
          : // ? e.target.value

            e.target.value,
    });
  };

  useEffect(() => {
    (async () => {
      //  await sleep(1e3); // For demo purposes.
      const url = process.env.REACT_APP_SERVER_URL + "/get/all/roles";

      axios.get(url).then((response) => {
        const officerRoleList = response.data;
        console.log("response in roles", response);
        const options = officerRoleList.map((option) => ({
          id: option.roleId,
          title: option.roleName,
        }));
        console.log("rolesList", options);
        setRolesList(options);
      });
    })();
  }, []);

  const sendOTP = () => {
    sessionStorage.removeItem("messageRef");
    const sendData = qs.stringify({ loginId: formData.loginId });
    axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/officer/send/login/otp",
        sendData
      )
      .then((resp) => {
        if (resp.status === 200) {
          setIsLoading(false);
          //clear form
          otpMessageRef.current = resp.data.message;
          navigate("otp", {
            replace: true,
            state: {
              data: formData,
              purpose: "OFCRLOGIN",
              messageRef: otpMessageRef,
            },
          });
          return;
        }
        //check the otp msg
        otpMessageRef.current = "Something went wrong. Try agin";
        navigate("otp", {
          replace: true,
          state: {
            data: formData,
            purpose: "OFCRLOGIN",
            messageRef: otpMessageRef,
          },
        });
      })
      .catch(function (error) {
        console.log("error otp", error);
        setIsLoading(false);
        otpMessageRef.current = error.response.data.error_message;
        navigate("otp", {
          replace: true,
          state: {
            data: formData,
            purpose: "OFCRLOGIN",
            messageRef: otpMessageRef,
          },
        });
        return;
      });
    return;
  };

  const isAuthValid = () => {
    return axios
      .post(process.env.REACT_APP_SERVER_URL + "/officer/login/find", formData)
      .then((resp) => {
        console.log("find", resp);
        if (resp.status === 200) {
          return true;
        }
        //    setFailedErrorMessage(resp.data.error_message);
        //     setFailedErrorMessageAlert(true);
        //      setButtonDisabled(false);
        //     return false;
      })
      .catch(function (error) {
        setIsLoading(false);
        setFailedErrorMessage(
          error.response
            ? error.response.data.error_message
            : "Server is not responding. Please try again later."
        );
        setFailedErrorMessageAlert(true);

        setButtonDisabled(false);

        return false;
      });
  };

  const validRequiredFilds = () => {
    let flag = true;
    let mandatory = ["loginId", "password"];
    for (let key in formData) {
      if (mandatory.includes(key)) {
        if (!formData[key]) {
          flag = false;
        }
      }
    }
    return flag;
  };

  const checkRoleFields = () => {
    if (!formData.role) {
      return false;
    } else return true;
  };

  const officerLogin = () => {
    setButtonDisabled(true);
    setIsLoading(true);
    isAuthValid().then((resp) => {
      console.log("auth valid", resp);
      if (!resp) {
        setIsLoading(false);
        // captchaContext.resetCaptcha();
        //member not found
        return;
      }
      //member found
      sendOTP();
    });
  };

  const isAnotherSessionActive = () => {
    const token = localStorage.getItem("jwt");
    if (!token) {
      return false;
    }
    return true;
  };

  const handleLogOut = () => {
    //logout button clicked show popup
    setSessionActiveAlert(true);
  };

  const handleLogoutCancel = () => {
    setSessionActiveAlert(false);
  };

  const handleSubmit = async () => {
    // if (!validRequiredFilds()) {
    //   setFailedErrorMessage("Provide Username and Password");
    //   setFailedErrorMessageAlert(true);
    //   return;
    // }
    // if(passwordError){
    //   setFailedErrorMessage(" Please provide valid password");
    //   setFailedErrorMessageAlert(true);
    //   return;

    // }
    if (!checkRoleFields()) {
      setFailedErrorMessage("Please Select a Role");
      setFailedErrorMessageAlert(true);
      return;
    }
    // if (formData.role === "CC Representative") {
    if (isAnotherSessionActive()) {
      //show alert
      handleLogOut();
      return;
    }
    officerLogin();
    return;
    // }
  };

  const handleAlert = () => {
    setFailedErrorMessageAlert(false);
    window.location.reload();
  };

  const handleKeyDown = (event) => {
    if (event.code === "Space") {
      event.preventDefault();
    }
  };

  const handleForgotpasswordClick = () => {
    navigate("/forgotpasswordofcr", { replace: true });
  };

  const handleSelect = (event) => {
    onChange(event);
  };

  const handleCopy = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <LogoutAlert
        open={sessionActiveAlert}
        closeAlert={handleLogoutCancel}
        title="Alert!!"
        body="Already logged in. Our policy does not allow multiple sessions."
        user="member"
      />
      <ErrorAlert
        handleOk={handleAlert}
        open={failedErrorMsgAlert}
        title="Error!"
        body={failedErrorMsg}
        buttonName="Ok"
      />
      <Header />

      <AppBar
        position="relative"
        sx={{
          height: "40px",
          background:
            "linear-gradient(90deg, rgba(128,17,59,1) 0%, rgba(148,23,189,1) 69%, rgba(13,1,39,1) 100%)",
        }}
      />
      {/* <AppBar
        position="relative"
        sx={{
          height: "40px",
          background:
            "linear-gradient(90deg, rgba(128,17,59,1) 0%, rgba(148,23,189,1) 69%, rgba(13,1,39,1) 100%)",
          display: "flex",
          alignItems: "center", // Vertically center the content
        }}
      >
        <img
          src={Emblem}
          alt="Emblem of India"
          style={{
            marginLeft: "10px", // Adjust this value as needed
            marginRight: "auto", // Push the emblem to the leftmost corner
            
          }}
        /> */}
      {/* Add other AppBar content here */}
      {/* </AppBar> */}
      <div className="pattern-overlay"></div>
      <Divider />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: "20px", marginBottom: "20px" }}
      >
        <Grid item xs={10} sm={12} md={10}>
          <Paper elevation={0} sx={{ borderRadius: "8px" }}>
            <Grid container justifyContent="center" alignItems="start">
              <Grid
                item
                xs={12}
                sm={7}
                md={8}
                lg={7}
                sx={{
                  display: { xs: "none", sm: "none", md: "block" },
                  marginTop: "10px",
                }}
              >
                <Container sx={{ paddingTop: "2rem" }}>
                  <Grid container justifyContent="center" rowSpacing={2}>
                    <Grid item xs={12} sm={12}>
                      <img
                        src={Gif}
                        alt="Map of India displaying coal company headquarters and Cmpfo Ros"
                        height={500}
                        width={500}
                      />
                    </Grid>
                  </Grid>
                </Container>
              </Grid>

              <Grid
                item
                xs={12}
                sm={5}
                md={4}
                lg={5}
                sx={{ borderLeft: "1px solid #e6e8e3" }}
              >
                <Container sx={{ padding: "2rem" }}>
                  <Grid container justifyContent="center" rowSpacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Avatar
                          sx={{
                            backgroundColor: "#1bbd7e",
                            width: 40,
                            height: 40,
                          }}
                        >
                          <FontAwesomeIcon icon={faLock} />
                        </Avatar>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        component="div"
                        align="center"
                        sx={{
                          color: "#1e1e1e",
                          fontWeight: "500",
                        }}
                      >
                        Officer Login
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        autoComplete="off"
                        id="userName"
                        label="Username"
                        name="loginId"
                        onChange={onChange}
                        variant="outlined"
                        placeholder="Enter Username"
                        error={Boolean(userNameError)}
                        helperText={
                          Boolean(userNameError)
                            ? userNameError
                            : "Account number cannot exceed 20 characters."
                        }
                        fullWidth
                        required
                        inputProps={{
                          maxLength: 20,
                          onCopy: handleCopy,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        autoComplete="off"
                        id="password"
                        type="password"
                        name="password"
                        onChange={onChange}
                        label="Password"
                        variant="outlined"
                        placeholder="Enter Password"
                        fullWidth
                        onKeyDown={handleKeyDown}
                        inputProps={{ maxLength: 20, onCopy: handleCopy }}
                        error={Boolean(passwordError)}
                        helperText={Boolean(passwordError) ? passwordError : ""}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Role
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formData.role}
                          name="role"
                          label="Select Role"
                          onChange={handleSelect}
                          MenuProps={{
                            style: { maxHeight: "300px", margin: "0 auto" },
                          }}
                        >
                          {/* <div style={{ maxHeight: '150px', margin: '0 auto' }}> */}

                          {rolesList.map((option) => (
                            <MenuItem key={option.id} value={option.title}>
                              {option.title}
                            </MenuItem>
                          ))}
                          {/* </div> */}
                          {/*                      
                            Coal Company Representative
                          </MenuItem>})
                          <MenuItem value={"CC Representative"}>
                            Coal Company Representative
                          </MenuItem>
                          <MenuItem value={"UDA"}>
                            Unit Dealing Assistant
                          </MenuItem>
                          <MenuItem value={"UPO"}>
                            Unit Personnel Officer
                          </MenuItem>
                          <MenuItem value={"RODA"}>
                            RO Dealing Assistant
                          </MenuItem>
                          <MenuItem value={"SO"}>Section Officer</MenuItem>
                          <MenuItem value={"BO"}>Branch Officer</MenuItem> */}
                        </Select>
                      </FormControl>

                      {/* <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    rowGap={2}
                  >
                    <Grid item xs={12} md={3}>
                      <LabelText title="Select Role" />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <CustomDropdown {...rolesDropdown} onchange={onChange} />
                    </Grid>
                  </Grid> */}
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <CaptchaProvider>
                        <Form
                          buttonEnablefunc={buttonEnablefunc}
                          buttonDisabledfunc={buttonDisabledfunc}
                          otpButtonDisableFuncEnable={
                            otpButtonDisableFuncEnable
                          }
                          otpButtonDisableFuncDisable={
                            otpButtonDisableFuncDisable
                          }
                        />
                      </CaptchaProvider>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Button
                        type="submit"
                        color="success"
                        fullWidth
                        variant="contained"
                        //disabled={true}
                        onClick={handleSubmit}
                        disabled={buttonDisabled}
                      >
                        {isLoading ? (
                          <CircularProgress sx={{ color: "green" }} />
                        ) : (
                          "SIGN IN"
                        )}
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Grid item xs={6}>
                        <Typography
                          align="left"
                          sx={{ color: "red", cursor: "pointer" }}
                          onClick={handleForgotpasswordClick}
                        >
                          Forgot Password?
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Footbar />
      <Footer />
    </>
  );
}

export default OfficerLogin;
