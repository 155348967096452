import React from "react";
import { Typography } from "@mui/material";
import MemberEnrollment from '../../../../images/MemberEnrollment.png';
import cmpfoHomePage from '../../../../images/cmpfoHomePage.png';
import { Height } from "@mui/icons-material";
import enrollmentDetails from '../../../../images/enrollmentDeatils.png';
import captcha from '../../../../images/captcha.png';
import otp from '../../../../images/otp.png';
import successfullEnrollment from '../../../../images/successfullEnrollment.png'

const MemberEnrollmentFirst=()=>{
    return(<>
    <Typography variant="h6">MEMBER ENROLMENT</Typography>
    <Typography variant="body1" align="justify">
            Each member of CMPFO and coal companies is required to enrol on the portal. C-CARES follows a simple yet effective enrolment process in order to authenticate and authorize the users. Successful enrolment of the user allows them to subsequently login to the portal and perform the respective tasks as defined in the CMPFO processes.
           <ol type="1">
            <li>Click on Member Enrolment at the Home page. (Figure 2)<br />
                <p align="center"><img className="bordered-image" src={cmpfoHomePage } height="35%" width="95%" /></p>
                <p align="center">Figure 2: CMPFO’s Home Page</p>
            </li>
            <li>On the Member Enrolment page, (Figure 3), enter the correct CMPFO account number.</li>
            <li>On entering the correct Account number other fields appear, like New Password, Confirm Password and Enter Captcha (Figure 4). Please enter the new password in accordance to the password guidelines.</li>
            <p align="center"><img  className="bordered-image" src={MemberEnrollment } height="350px" width="75%" /></p>
                <p align="center">Figure 3: Member Enrolment</p>
                <p align="center"><img className="bordered-image" src={enrollmentDetails } height="500px" width="75%" /></p>
                <p align="center">Figure 4: Enrolment Details</p>
            <li>Enter the correct CAPTCHA. As soon as member enters the correct Captcha, ‘Send OTP’ button (orange in colour) gets activated. Click on Send OTP. (Figure 5)<br />
            <p align="center"> <img  className="bordered-image" src={ captcha} height="400px" width="75%" /></p>
                <p align="center">Figure 5: Captcha</p>
                <p align="center"><img  className="bordered-image" src={otp} height="350px" width="75%" /></p>
                <p align="center">Figure 6: OTP Verification Page</p>
            </li>
            <li>An OTP is sent to the member’s registered mobile number. If OTP is not received within 60 seconds, the member can click on Resend OTP to receive the OTP again. “Resend” is enabaled not more than two times. (Figure 6)</li>
            <li>Enter the OTP correctly and press VERIFY button. On successful OTP verification, member is enrolled and can proceed to LOGIN page. (Figure 7)
            <p align="center"> <img  className="bordered-image" src={successfullEnrollment} height="300px" width="75%" /></p>
                <p align="center">Figure 7: Successful Enrolment</p>
            </li>
           </ol>
            </Typography>

    </>);
}
export default MemberEnrollmentFirst;