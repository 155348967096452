import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Link,
  Button,
  Box,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleUser,
  faLock,
  faBars,
  faHome,
  faBookOpenReader
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

const Appbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [loginAnchorEl, setLoginAnchorEl] = useState(null);
  const [enrolAnchorEl, setEnrolAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  /// login menu functions

  const handleLoginOpen = (event) => {
    event.preventDefault();
    setLoginAnchorEl(event.currentTarget);
  };

  const handleLoginClose = () => {
    setLoginAnchorEl(null);
  };

  const handleLogin = (role) => {
    handleLoginClose();
    if (role === "member") {
      const url = "/login/member";
      const memberTab = window.open(url, "_blank");
      memberTab.focus();
    }
    if (role === "officer") {
      const url = "/login/officer";
      const memberTab = window.open(url, "_blank");
      memberTab.focus();
    }
  };
  // enrollment menu functions
  const handleEnrolOpen = (event) => {
    event.preventDefault();
    setEnrolAnchorEl(event.currentTarget);
  };

  const handleEnrolClose = () => {
    setEnrolAnchorEl(null);
  };

  const handleEnrol = (role) => {
    handleLoginClose();
    if (role === "member") {
      const url = "/enrol/member";
      const memberTab = window.open(url, "_blank");
      memberTab.focus();
    }
    if (role === "officer") {
      const url = "/enrol/officer";
      const memberTab = window.open(url, "_blank");
      memberTab.focus();
    }
  };

  const menuItems = [
    { text: "Home", href: "/cmpfo", icon: faHome },
    // { text: 'About', href: '/#about', icon: faStar },
    // { text: 'Contact', href: '/#contact', icon: faLocationDot },
    // { text: "Member Enrolment", href: "/enrol/member", icon: faCircleUser },
    // { text: 'For any grievance', href: '/contact', icon: faPaperPlane },
    // { text: "Officer Enrolment", href: "/enrol/officer", icon: faCircleUser },
  ];

  const menuItemsMobile = [
    { text: "Home", href: "/cmpfo", icon: faHome },
    // { text: 'About', href: '/#about', icon: faStar },
    // { text: 'Contact', href: '/#contact', icon: faLocationDot },
    { text: "Member Enrolment", href: "/enrol/member", icon: faCircleUser },
    { text: "Officer Enrolment", href: "/enrol/officer", icon: faCircleUser },
    { text: "For any grievance", href: "/contact", icon: faPaperPlane },
    { text: "Users' Manual", href: "/manual", icon: faBookOpenReader },
  ];

  const handleClick = () => {
    window.location.href = "/contact";
  };

  return (
    <>
    

   
      <AppBar
      
        position="static"
        sx={{
          height: "50px",
          // background:
          //   "linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(235,68,68,1) 50%, rgba(252,176,69,1) 96%)",
          background:
            "linear-gradient(90deg, rgba(128,17,59,1) 0%, rgba(148,23,189,1) 69%, rgba(13,1,39,1) 100%)",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
        }}
      >
        
        {/* <AlertWithCancel handleCancel={handleCancel} handleOk={handleLogout} open={logginAtemptAlert} title='Already Logged In' body='You are already logged in another tab.' buttonName="Logout" /> */}
        <Toolbar
          sx={{
            "@media all": {
              minHeight: 50,
            },
          }}
        >
          {isDesktop ? (
            <>

              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                 // marginLeft: "auto",
                 marginLeft: "-20px",
                  // paddingLeft: {
                  //   md: "28%",
                    // lg: "32%",
                  //   lg: "39%",
                  // },
                }}
              >
                <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "30%",
                  fontWeight: "600",
                }}
              >  <Button
                 
              color="inherit"
              onClick={() => {
               window.open ("/manual","_blank");
              }}
              sx={{ color: "white", fontWeight: "600", mx: 2,paddingLeft:"0px" }}
            >
              <FontAwesomeIcon icon={faBookOpenReader} />
              <span>&nbsp;Users' Manual</span>
           </Button></div>
                {menuItems.map((item, index) => (
                  <Button
                  key={index}
                  color="inherit"
                  onClick={() => {
                    window.location.href = item.href;
                  }}
                  sx={{ color: "white", fontWeight: "600", mx: 2,paddingLeft: {
                      md: "18%",
                     lg: "21%",
                     xl: "25%",
                     }, }}
                >
                  <FontAwesomeIcon icon={item.icon} />
                  <span>&nbsp;{item.text}</span>
                </Button>
                ))}

                <Button
                  color="inherit"
                  onClick={handleEnrolOpen}
                  sx={{ color: "white", fontWeight: "600" }}
                >
                  <FontAwesomeIcon icon={faCircleUser} />
                  <span>&nbsp;Enrolment</span>
                </Button>
                <Menu
                  variant="menu"
                  autoFocus={false}
                  anchorEl={enrolAnchorEl}
                  open={Boolean(enrolAnchorEl)}
                  onClose={handleEnrolClose}
                >
                  <MenuItem onClick={() => handleEnrol("member")}>
                    Member Enrolment
                  </MenuItem>
                  <MenuItem onClick={() => handleEnrol("officer")}>
                    Officer Enrolment
                  </MenuItem>
                </Menu>
                <span>&nbsp;</span>
                <Button
                  color="inherit"
                  onClick={handleLoginOpen}
                  sx={{ color: "white", fontWeight: "600" }}
                >
                  <FontAwesomeIcon icon={faLock} />
                  <span>&nbsp;Login</span>
                </Button>
                <Menu
                  variant="menu"
                  autoFocus={false}
                  anchorEl={loginAnchorEl}
                  open={Boolean(loginAnchorEl)}
                  onClose={handleLoginClose}
                >
                  <MenuItem onClick={() => handleLogin("member")}>
                    Member Login
                  </MenuItem>
                  <MenuItem onClick={() => handleLogin("officer")}>
                    Officer Login
                  </MenuItem>
                </Menu>
              </Box>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "30%",
                  fontWeight: "600",
                }}
              >
                <span>For any grievance: &nbsp;</span>
                <Button
                  variant="contained"
                  sx={{
                    background: "#873bb0",
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                  onClick={handleClick}
                >
                  Click here
                </Button>
              </div>
            </>
          ) : (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
                sx={{ mr: 2 }}
              >
                <FontAwesomeIcon icon={faBars} />
              </IconButton>

              <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
              >
                 {menuItemsMobile.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      if (item.href === "/manual") {
                        window.open(item.href, "_blank");
                      } else {
                        handleMenuClose();
                      }
                    }}
                    
                    component={Link}
                    href={item.href}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon icon={item.icon} />
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                  </MenuItem>
                ))}
              </Menu>

              <div>
                <Button
                  color="inherit"
                  onClick={handleLoginOpen}
                  sx={{ color: "white", fontWeight: "600" }}
                >
                  <FontAwesomeIcon icon={faLock} />
                  <span>&nbsp;Login</span>
                </Button>

                <Menu
                  anchorEl={loginAnchorEl}
                  open={Boolean(loginAnchorEl)}
                  onClose={handleLoginClose}
                >
                  <MenuItem onClick={() => handleLogin("member")}>
                    Member Login
                  </MenuItem>
                  <MenuItem onClick={() => handleLogin("officer")}>
                    Officer Login
                  </MenuItem>
                </Menu>
              </div>
            </>
          )}
        </Toolbar>
       
      </AppBar>
     
    </>
  );
};

export default Appbar;
