import React, { Fragment, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import CustomDropdown from "../../FormComponent/CustomDropdown";
import LabelText from "../../FormComponent/LabelText";
import CustomTextField from "../../FormComponent/CustomTextField";
import SubtitleText from "../../FormComponent/SubtitleText";
import LoadingIndicator from "../../FormComponent/LoadingIndicator";
import axios from "axios";

const AdditionalAdvPfPrefilled = (props) => {
  const { location, updateAddField, dependentFamily, claimId } = props;
  const sampleAdvClaimDataById = {
    aaoConfrmPayment: "",
    advClaimStatusId: "",
    advClaimTypeId: "",
    advReqdDate: "",
    amountApplied: "",
    claimId: "",
    claimType: "",
    cmpfAccNo: "",
    correspondanceAddress: "",
    dateReceived: "",
    declarationUda: "",
    dobAadhaar: "",
    dobPan: "",
    emolumentsLast36m: "",
    memberId: "",
    nameAadhaar: "",
    nameBank: "",
    namePan: "",
    nmAadhaarMatch: "",
    nmBankMatch: "",
    nmPanMatch: "",
    periodOfMembship: "",
    settlementDate: "",
    sfwhName: "",
    sfwhRelationType: "",
    udaLoginId: "",
  };
  const sampleTAdvSpecificDetails = {
    advClaimSrno: "",
    advSpecificDetSrno: "",
    amtAlrdyPaid: "",
    anticptdDateOfMarriage: "",
    dependentId: "",
    estimatedCost: "",
    examPassed: "",
    examYear: "",
    feesPaid: "",
    locationDet: "",
    saleAgreeAmt: "",
    sanctionedAmtDet: "",
    siteMeasurementUnit: "",
    siteMeasurementVal: "",
    plotNo: "",
    subPlotNo: "",
  };

  const [isProgramLoaded, setIsProgramLoaded] = useState(true);
  const [advClaimDataById, setAdvClaimDataById] = useState(
    sampleAdvClaimDataById
  );
  const [tAdvSpecificDetails, setTAdvSpecificDetails] = useState(
    sampleTAdvSpecificDetails
  );

  const [corresAddress, setCorresAddress] = useState({
    addressline1: "",
    addressline2: "",
    city: "",
    district: "",
    state: "",
    pincode: "",
  });

  const [locationDetails, setLocationDetails] = useState({
    locationaddressline1: "",
    locationaddressline2: "",
    villageCity: "",
    districtLocation: "",
    stateLocation: "",
    pinCodeLocation: "",
  });

  const claimantNameList = dependentFamily.map((member) => ({
    id: member.familyMemId,
    title: member.nameFamily,
  }));
  claimantNameList.push({ id: 0, title: "Self" });

  const GetData1 = async () => {
    try {
      const url =
        process.env.REACT_APP_SERVER_URL +
        "/fetch/advance/pf/claim/details/by/id";

      const formData = new FormData();
      formData.append("claimId", claimId); // Remove the extra space before claimId
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      setAdvClaimDataById(response.data.data.advClaim);
      setTAdvSpecificDetails(response.data.data.tAdvSpecificDetails);
      if (response.data.data.advClaim.correspondanceAddress) {
        const parsedAddress = JSON.parse(
          response.data.data.advClaim.correspondanceAddress
        );
        const addressLinesArray = parsedAddress.address.split(",");
        setCorresAddress({
          addressline1: addressLinesArray[0].trim(),
          addressline2: addressLinesArray[1] ? addressLinesArray[1].trim() : "",
          city: addressLinesArray[2].trim(),
          district: addressLinesArray[3].trim(),
          state: addressLinesArray[4].trim(),
          pincode: addressLinesArray[5].trim(),
        });
      }
      if (response.data.data.tAdvSpecificDetails.locationDet) {
        const locationAddress = JSON.parse(
          response.data.data.tAdvSpecificDetails.locationDet
        );
        const addressArray = locationAddress.address.split(",");
        setLocationDetails({
          locationaddressline1: addressArray[0].trim(),
          locationaddressline2: addressArray[1] ? addressArray[1].trim() : "",
          villageCity: addressArray[2].trim(),
          districtLocation: addressArray[3].trim(),
          stateLocation: addressArray[4].trim(),
          pinCodeLocation: addressArray[5].trim(),
        });
      }
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    GetData1();
  }, [claimId]);

  const dateofmrg = tAdvSpecificDetails.anticptdDateOfMarriage;
  const dateofMarriage = dateofmrg
    ? new Date(dateofmrg).toISOString().split("T")[0]
    : "";
  const reqDate = advClaimDataById.advReqdDate;
  const amountRequiredDate = reqDate
    ? new Date(reqDate).toISOString().split("T")[0]
    : "";

  const exaDate = tAdvSpecificDetails.examYear;
  const examDate = exaDate ? new Date(exaDate).toISOString().split("T")[0] : "";

  const emoluments = advClaimDataById.emolumentsLast36m / 36 ;
  const emolumentsLast = Math.round(emoluments);

  const onChange = (e) => {
    setAdvClaimDataById({
      ...advClaimDataById,
      [e.target.name]: e.target.value,
    });
    setTAdvSpecificDetails({
      ...tAdvSpecificDetails,
      [e.target.name]: e.target.value,
    });
    setCorresAddress({ ...corresAddress, [e.target.name]: e.target.value });
    setLocationDetails({ ...locationDetails, [e.target.name]: e.target.value });
    updateAddField(e.target.name, e.target.value);
  };

  const plotNo = {
    name: "plotNo",
    type: "text",
    helperText: "",
    placeholder: "",
    defaultValue: tAdvSpecificDetails.plotNo,
    errorMessage: "Invalid",
    pattern: "",
  };
  const subPlotNo = {
    name: "subPlotNo",
    type: "text",
    helperText: "",
    placeholder: "",
    defaultValue: tAdvSpecificDetails.subPlotNo,
    errorMessage: "Invalid",
    pattern: "",
  };

  const siteMeasurementVal = {
    name: "siteMeasurementVal",
    type: "number",
    helperText: "",
    placeholder: "",
    defaultValue: tAdvSpecificDetails.siteMeasurementVal,
    errorMessage: "Invalid",
    pattern: "^[0-9]*$",
  };

  const areaUnit = {
    name: "areaUnit",
    placeholder: "Select unit",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: "1",
    required: true,
    options: [
      { id: "1", title: "Sq. ft." },
      { id: "2", title: "Sq. yd." },
    ],
    //options: claimTypeList,
  };

  const district = {
    name: "district",
    type: "text",
    helperText: "",
    placeholder: "",
    defaultValue: corresAddress.district,
    errorMessage: "Invalid",
    pattern: "",
  };

  const state = {
    name: "state",
    type: "text",
    helperText: "",
    placeholder: "",
    defaultValue: corresAddress.state,
    errorMessage: "Invalid",
    pattern: "",
  };

  const addressline1 = {
    name: "addressline1",
    type: "text",
    helperText: "",
    placeholder: "",
    defaultValue: corresAddress.addressline1,
    errorMessage: "Invalid",
    pattern: "",
  };

  //**********Location******* */
  const locationaddressline1 = {
    name: "locationaddressline1",
    type: "text",
    helperText: "",
    placeholder: "",
    defaultValue: locationDetails.locationaddressline1,
    errorMessage: "Invalid",
    pattern: "",
  };

  const locationaddressline2 = {
    name: "locationaddressline2",
    type: "text",
    helperText: "",
    placeholder: "",
    defaultValue: locationDetails.locationaddressline2,
    errorMessage: "Invalid",
    pattern: "",
  };

  const villageCity = {
    name: "villageCity",
    type: "text",
    helperText: "",
    placeholder: "",
    defaultValue: locationDetails.villageCity,
    errorMessage: "Invalid",
    pattern: "",
  };

  const districtLocation = {
    name: "districtLocation",
    type: "text",
    helperText: "",
    placeholder: "",
    defaultValue: locationDetails.districtLocation,
    errorMessage: "Invalid",
    pattern: "",
  };

  const stateLocation = {
    name: "stateLocation",
    type: "text",
    helperText: "",
    placeholder: "",
    defaultValue: locationDetails.stateLocation,
    errorMessage: "Invalid",
    pattern: "",
  };

  const pinCodeLocation = {
    name: "pinCodeLocation",
    type: "number",
    helperText: "",
    placeholder: "",
    defaultValue: locationDetails.pinCodeLocation,
    errorMessage: "Invalid. Please enter a valid six-digit pincode.",
    pattern: "^[0-9]{6}$",
  };

  //*************Location**end********** */

  const dependentId = {
    name: "dependentId",
    placeholder: "",
    //label: "Select CC",
    helperText: "",
    //disabled: readOnly,
    initialValue: tAdvSpecificDetails.dependentId,
    // defaultValue: 0,
    required: true,
    options: claimantNameList,
  };

  const addressline2 = {
    name: "addressline2",
    type: "text",
    helperText: "",
    placeholder: "",
    defaultValue: corresAddress.addressline2,
    errorMessage: "Invalid",
    pattern: "",
  };

  const pincode = {
    name: "pincode",
    type: "number",
    helperText: "",
    placeholder: "",
    defaultValue: corresAddress.pincode,
    errorMessage: "Invalid. Please enter a valid six-digit pincode.",
    pattern: "^[0-9]{6}$",
  };

  const city = {
    name: "city",
    type: "text",
    helperText: "",
    placeholder: "",
    defaultValue: corresAddress.city,
    errorMessage: "Invalid",
    pattern: "",
  };

  const amountApplied = {
    name: "amountApplied",
    type: "number",
    helperText: "",
    placeholder: "",
    defaultValue: advClaimDataById.amountApplied,
    errorMessage: "Invalid",
    pattern: "^[0-9]*$",
    // disabled: readOnly,
  };
  const emolumentsLast36m = {
    name: "emolumentsLast36m",
    type: "number",
    helperText: "",
    placeholder: "",
    // defaultValue: emolumentsLast,
    errorMessage: "Invalid",
    pattern: "^[0-9]*$",
    // disabled: readOnly,
  };
  const estimatedCost = {
    name: "estimatedCost",
    type: "number",
    helperText: "",
    placeholder: "",
    defaultValue: tAdvSpecificDetails.estimatedCost,
    // initialValue: estimatedCostt,
    errorMessage: "Invalid",
    pattern: "^[0-9]*$",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const saleAgreeAmt = {
    name: "saleAgreeAmt",
    type: "number",
    helperText: "",
    placeholder: "",
    defaultValue: tAdvSpecificDetails.saleAgreeAmt,
    errorMessage: "Invalid",
    pattern: "^[0-9]*$",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const examPassed = {
    name: "examPassed",
    type: "text",
    helperText: "",
    placeholder: "",
    defaultValue: tAdvSpecificDetails.examPassed,
    errorMessage: "Invalid",
    pattern: "",
    // disabled: readOnly,
  };

  const examYear = {
    name: "examYear",
    type: "Date",
    helperText: "",
    placeholder: "",
    defaultValue: examDate,
    errorMessage: "Invalid",
    pattern: "",
    // pattern: "^[0-9]*$",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };

  const feesPaid = {
    name: "feesPaid",
    type: "number",
    helperText: "",
    placeholder: "",
    defaultValue: tAdvSpecificDetails.feesPaid,
    errorMessage: "Invalid",
    pattern: "^[0-9]*$",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const advReqdDate = {
    name: "advReqdDate",
    type: "date",
    helperText: "",
    placeholder: "Select Date ",
    defaultValue: amountRequiredDate,
    errorMessage: "Invalid",
    pattern: "",
    //required: ccEnrolmentMandatoryFields.includes("off_email"),
    //disabled: readOnly,
  };
  const anticptdDateOfMarriage = {
    name: "anticptdDateOfMarriage",
    type: "date",
    helperText: "",
    placeholder: "",
    defaultValue: dateofMarriage,
    errorMessage: "Invalid",
    pattern: "",
    // disabled: readOnly,
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/* <Paper elevation={3} sx={{ padding: "3rem", borderRadius: "8px" }}> */}
          {!isProgramLoaded ? (
            <LoadingIndicator />
          ) : (
            <>
              {/* <Form> */}
              <Grid
                container
                justifyContent="space-between"
                alignItems="start"
                rowGap={2}
              >
                {/* ************************************** */}
                {/* section 1  */}

                {/* column 1  */}

                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                    columnGap={1}
                  >
                    {/* row  */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Required Amount " />
                    </Grid>
                    <Grid mt={2}>:</Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...amountApplied} onchange={onChange} />
                    </Grid>
                  </Grid>
                </Grid>
                {location.claim_type === "1" ||
                location.claim_type === "2" ||
                location.claim_type === "3" ||
                location.claim_type === "4" ||
                location.claim_type === "5" ||
                location.claim_type === "7" ? (
                  <>
                    {/* column 2  */}

                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Member Notional Salary " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...emolumentsLast36m}
                            onchange={onChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    {/* <Typography>No data available for the selected claim type.</Typography> */}
                  </Grid>
                )}
                {/* ************************************** */}
                {location.claim_type === "1" ||
                location.claim_type === "2" ||
                location.claim_type === "3" ||
                location.claim_type === "4" ||
                location.claim_type === "5" ? (
                  <>
                    {/* column 1  */}

                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Estimated Cost " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...estimatedCost}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Area " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...siteMeasurementVal}
                            onchange={onChange}
                          />
                        </Grid>
                        {location.claim_type === "2" ? (
                          <>
                            <Grid item xs={12} sm={4}>
                              <LabelText title="Plot No. " />
                            </Grid>
                            <Grid mt={2}>:</Grid>
                            <Grid item xs={12} sm={7}>
                              <CustomTextField
                                {...plotNo}
                                onchange={onChange}
                              />
                            </Grid>
                          </>
                        ) : (
                          <Grid item xs={12}>
                            {/* <Typography>No data available for the selected claim type.</Typography> */}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {/* column 2  */}
                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Sale Agreement Amount " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...saleAgreeAmt}
                            onchange={onChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Area Unit " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomDropdown {...areaUnit} onchange={onChange} />
                        </Grid>

                        {location.claim_type === "2" ? (
                          <>
                            <Grid item xs={12} sm={4}>
                              <LabelText title="Sub-Plot No. " />
                            </Grid>
                            <Grid mt={2}>:</Grid>
                            <Grid item xs={12} sm={7}>
                              <CustomTextField
                                {...subPlotNo}
                                onchange={onChange}
                              />
                            </Grid>
                          </>
                        ) : (
                          <Grid item xs={12}>
                            {/* <Typography>No data available for the selected claim type.</Typography> */}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {/* section 2  */}

                    <Grid item xs={12} sm={12} mt={-1}>
                      <SubtitleText title="Location Details" />
                    </Grid>

                    {/* column 1  */}

                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Address Line1  " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...locationaddressline1}
                            onchange={onChange}
                          />
                        </Grid>

                        {/* row  */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Village/City " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...villageCity}
                            onchange={onChange}
                          />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="State " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...stateLocation}
                            onchange={onChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* column 2  */}

                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title=" Address Line2 " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...locationaddressline2}
                            onchange={onChange}
                          />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="District " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...districtLocation}
                            onchange={onChange}
                          />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Pin Code " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...pinCodeLocation}
                            onchange={onChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : location.claim_type === "6" ? (
                  <>
                    {/* section 2  */}
                    {/* column 1  */}

                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Anticipated Date of Marriage " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...anticptdDateOfMarriage}
                            onchange={onChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* column 2  */}

                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title=" For Marriage of " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomDropdown
                            {...dependentId}
                            onchange={onChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : location.claim_type === "7" ? (
                  <>
                    {/* section 2  */}
                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Name of Student " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomDropdown
                            {...dependentId}
                            onchange={onChange}
                          />
                        </Grid>
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Year of Passing Exam " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField {...examYear} onchange={onChange} />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* column 2  */}
                    <Grid item xs={12} sm={5}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        columnGap={1}
                      >
                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title="Examination Passed " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField
                            {...examPassed}
                            onchange={onChange}
                          />
                        </Grid>

                        {/* row */}
                        <Grid item xs={12} sm={4}>
                          <LabelText title=" Fee Paid " />
                        </Grid>
                        <Grid mt={2}>:</Grid>
                        <Grid item xs={12} sm={7}>
                          <CustomTextField {...feesPaid} onchange={onChange} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    {/* <Typography>No data available for the selected claim type.</Typography> */}
                  </Grid>
                )}
                {/* ----------------------------------------------------------------------------------------- */}

                {/* column 1  */}

                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                    columnGap={1}
                  >
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Amount Required Date " />
                    </Grid>
                    <Grid mt={2}>:</Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...advReqdDate} onchange={onChange} />
                    </Grid>
                  </Grid>
                </Grid>

                {/* column 2  */}

                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    columnGap={1}
                  >
                    {/* row */}
                    {/* <Grid item xs={12} sm={4}>
                      <LabelText title="Address :" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...address} onchange={onChange} />
                    </Grid> */}

                    {/* row */}
                    {/* <Grid item xs={12} sm={4}>
                      <LabelText title="IFSC Code :" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...address} onchange={onChange} />
                    </Grid> */}
                  </Grid>
                </Grid>

                {/* ************************************** */}
                {/* section 3  */}
                <Grid item xs={12} sm={12} mt={2}>
                  <SubtitleText title="Correspondence Address" />
                </Grid>
                {/* column 1  */}

                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                    columnGap={1}
                  >
                    {/* row  */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Address Line1" />
                    </Grid>
                    <Grid mt={2}>:</Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...addressline1} onchange={onChange} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Address Line2 " />
                    </Grid>
                    <Grid mt={2}>:</Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...addressline2} onchange={onChange} />
                    </Grid>

                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="Pin Code " />
                    </Grid>
                    <Grid mt={2}>:</Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...pincode} onchange={onChange} />
                    </Grid>
                  </Grid>
                </Grid>
                {/* column 2  */}
                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                    columnGap={1}
                  >
                    {/* row  */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="City " />
                    </Grid>
                    <Grid mt={2}>:</Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...city} onchange={onChange} />
                    </Grid>
                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="District " />
                    </Grid>
                    <Grid mt={2}>:</Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...district} onchange={onChange} />
                    </Grid>
                    {/* row */}
                    <Grid item xs={12} sm={4}>
                      <LabelText title="State " />
                    </Grid>
                    <Grid mt={2}>:</Grid>
                    <Grid item xs={12} sm={7}>
                      <CustomTextField {...state} onchange={onChange} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {/* </Paper> */}
        </Grid>
      </Grid>
    </>
  );
};

export default AdditionalAdvPfPrefilled;
